import { useFeature } from 'flagged';
import i18n from 'i18next';
import React, { useEffect } from 'react';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { UserRole, Module } from '@hobt/constants';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useDefaultFormActionsContext } from 'Feature/DefaultsInventory/components/DefaultFormContext';

import { LinedCard, AccordionContainer } from '../../../../CommonComponents/AccordionComponents';
import { DefaultHistoryProps } from './History.types';
import { FormInputRadioType } from '../../../models/typeCode.types';
import FormInputRadio from '../../FormInputRadio';
import FormInputMultiLineText from '../../FormInputMultiLineText';
import { scrollIntoView } from '../../CardReuseableFunctions';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

export const HistoryDetails: React.FC<DefaultHistoryProps> = ({
  register,
  errors,
  setValueHandler,
  control,
  watch,
  getValues,
  trigger,
  ...props
}) => {
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);
  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;

  const defaultFormActions = useDefaultFormActionsContext();

  const defaultHistory = watch && watch('history.defaultHistory');
  const description = watch && watch('history.description');

  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  const scrollToCardN = (e: React.MouseEvent) => {
    e.preventDefault();
    scrollIntoView('attachmentsAccordionAccordionHeader');
  };

  const reasonsCharacterLimitText: string = i18n
    .t('DefaultSubmission-Card-ReasonsCharacterLimitText')
    .replace('{textLimit}', props.maxLength.toString());

  useEffect(() => {
    if (defaultFormActions.isCardComplete('defaultHistory')) {
      if (!defaultHistory) {
        defaultFormActions?.removeCompleteCard('defaultHistory');
      }
    } else if (defaultHistory) {
      defaultFormActions?.addCompleteCard('defaultHistory');
    }
  }, [defaultHistory, description]);

  return (
    <LinedCard
      id={props.id}
      testId={props.testId}
      linePosition={props.linePosition}
      lineColor={props.accordionLineColor}
    >
      <AccordionContainer accordionId={`${props.id}Accordion`} title={props.title.field}>
        <div className="row card__body-row">
          <div className="form__element col-12">
            <FormInputRadio
              id={`${props.id}History`}
              name="history.defaultHistory"
              title={{
                value: i18n.t('DefaultSubmission-CardM-DefaultManagementToolUse')
              }}
              options={[
                {
                  label: {
                    value: i18n.t('DefaultSubmission-Card-RadioButtonYes')
                  },
                  value: FormInputRadioType.Yes
                },
                {
                  label: {
                    value: i18n.t('DefaultSubmission-Card-RadioButtonNo')
                  },
                  value: FormInputRadioType.No
                }
              ]}
              register={register}
              errors={errors}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
        <div className="row card__body-row">
          <div className="form__element col-12">
            <FormInputMultiLineText
              id={`${props.id}Description`}
              name="history.description"
              title={{ value: i18n.t('DefaultSubmission-CardM-Specify') }}
              textAreaLimit={{
                value: reasonsCharacterLimitText
              }}
              hideLimit={props.hideLimit}
              maxLength={props.maxLength}
              errors={errors}
              register={register}
              getValues={getValues}
              limitCountText={`/ ${props.maxLength}`}
              cardName={props.title.field.value}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
        <p className="card__body-subHeading">
          <Text
            field={{
              value: i18n.t('DefaultSubmission-CardM-AdditionComments')
            }}
          />{' '}
          <a
            href={props.ifCommentRequiredLinkURL.value.href}
            onClick={(e: React.MouseEvent) => {
              scrollToCardN(e);
            }}
          >
            {props.ifCommentRequiredLinkURL.value.text}
          </a>
        </p>
      </AccordionContainer>
    </LinedCard>
  );
};
