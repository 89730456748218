import i18n from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { Button } from 'Components/Common/Button';
import FormDropdown from 'Components/Inputs/FormDropdown';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import { convertToSitecoreListItem } from 'Constants/Utils/ConvertToSitecoreListItem';

import { ArrearsFileReportingPeriodSelectorProps, SelectedReportingPeriod } from './types';
import styles from './styles.module.scss';

export const ArrearsFileReportingPeriodSelector: React.FC<
  ArrearsFileReportingPeriodSelectorProps
> = ({
  helpText,
  reportingPeriodData,
  reportingPeriodLabel,
  onSubmit
}: ArrearsFileReportingPeriodSelectorProps) => {
  const formMethods = useForm();

  useEffect(() => {
    const reportingPeriodID = reportingPeriodData[0]?.reportingPeriodID;
    if (reportingPeriodID != null) {
      formMethods.register('reportingPeriodID');
      formMethods.setValue('reportingPeriodID', reportingPeriodID);

      // set the reporting period by id when page loads
      onSubmit(
        {
          reportingPeriodID
        },
        true
      );
    }
  }, [reportingPeriodData]);

  const reportingPeriodOptions: SitecoreListItem[] = useMemo(() => {
    return reportingPeriodData?.map(({ reportingPeriod, reportingPeriodID }) =>
      convertToSitecoreListItem(reportingPeriod, reportingPeriodID)
    );
  }, [reportingPeriodData]);

  const handleReportingPeriodSubmit: any = (data: SelectedReportingPeriod): void => {
    if (data.reportingPeriodID === reportingPeriodData[0]?.reportingPeriodID) {
      onSubmit(data, true);
      return;
    }

    onSubmit(data, false);
  };
  const handleReportingPeriodIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();

    formMethods.setValue('reportingPeriodID', event.target.value);
  };

  return (
    <div className={styles.selectorWrapper}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleReportingPeriodSubmit)}>
          <Text tag="p" field={helpText} />
          <div className={styles.reportingPeriodDropdowns}>
            <FormDropdown
              label={reportingPeriodLabel}
              name="reportingPeriodID"
              options={reportingPeriodOptions}
              setAsNumber={false}
              handleChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleReportingPeriodIdChange(e)
              }
            />
            <Button name="selectButton" type="submit" text={{ value: i18n.t('Globals-Select') }} />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
