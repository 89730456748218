import { useState } from 'react';

const usePreclaimsPropertyRepairs = () => {
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
  const [showSideDrawer, setShowSideDrawer] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const onAddPropertyRepairsButtonClick = () => {
    setIsEdit(false);
    setShowSideDrawer(true);
    setShowSuccessToast(false);
    setShowErrorToast(false);
  };

  const onErrorSubmit = () => {
    setShowSideDrawer(false);
    setShowErrorToast(true);
  };

  const onCancelForm = () => {
    setShowSideDrawer(false);
  };

  const onSuccessToastDismiss = () => {
    setShowSuccessToast(false);
  };

  const onErrorToastDismiss = () => {
    setShowErrorToast(false);
  };

  const onSuccessSubmit = () => {
    setShowSideDrawer(false);
    setShowSuccessToast(true);
  };

  return {
    showSuccessToast,
    isEdit,
    showSideDrawer,
    showErrorToast,
    onSuccessSubmit,
    setShowSuccessToast,
    setShowErrorToast,
    setShowSideDrawer,
    setIsEdit,
    onCancelForm,
    onErrorSubmit,
    onAddPropertyRepairsButtonClick,
    onSuccessToastDismiss,
    onErrorToastDismiss
  };
};

export default usePreclaimsPropertyRepairs;
