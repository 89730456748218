import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { Scrollbars } from 'react-custom-scrollbars-2';
import i18n from 'i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { IssueToManagementStatus } from '@hobt/claim-domain';

import { AddItmFlagFormProps } from 'Feature/PreClaims/components/PreClaimsItmFlags/AddItmFlagForm/types';
import useAddItmFlagFunctions from 'Feature/PreClaims/components/PreClaimsItmFlags/AddItmFlagForm/useAddItmFlagFunctions';
import { Button } from 'Components/Common/Button';
import FormNumber from 'Components/Inputs/FormNumber';
import { NotificationBanner } from 'Components/Common/Notifications/NotificationBanner';
import { FormCheckboxList } from 'Components/Inputs/FormCheckboxList';

import CSSTransition from 'react-transition-group/CSSTransition';
import styles from './styles.module.scss';

export const AddItmFlagForm: React.FC<AddItmFlagFormProps> = ({
  name,
  fields,
  onSuccessCallback,
  onErrorCallback,
  onCancelCallback,
  setIsLoadingCallback,
  loadingStatus,
  edit,
  editableItem
}: AddItmFlagFormProps) => {
  const {
    onCloseValidationErrors,
    handleSubmit,
    onSubmitErrors,
    setIssueToManagementRecordID,
    showValidationError,
    hookForm
  } = useAddItmFlagFunctions(onSuccessCallback, onErrorCallback, setIsLoadingCallback, edit);

  useEffect(() => {
    hookForm.register('statusCode');
    hookForm.register('typeCodes');
    hookForm.setValue('statusCode', IssueToManagementStatus.Approved);
    if (edit) {
      hookForm.setValue('cmhcLoanAccountNumber', editableItem?.cmhcLoanAccountNumber);
      hookForm.setValue('referenceNumber', editableItem?.referenceNumber);
      setIssueToManagementRecordID(editableItem?.issueToManagementRecordID);
    }
  }, []);

  return (
    <Scrollbars className="form-progress__scrollableContent">
      <div className={styles.addItmForm} data-testid={`${name}AddItemForm`}>
        <Text tag="h2" field={edit === true ? fields?.editHeading : fields?.heading} />
        <div className={styles.closeButton}>
          <button
            type="button"
            onClick={() => {
              hookForm.reset();
              if (onCancelCallback) onCancelCallback();
            }}
            aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
          >
            <span className="material-icons align-self-center">close</span>
          </button>
        </div>
        <FormProvider {...hookForm}>
          <form onSubmit={hookForm.handleSubmit(handleSubmit, onSubmitErrors)} id="addFlagItmForm">
            <Text field={fields.enterItm} />
            <CSSTransition
              in={showValidationError}
              classNames={{
                enterActive: styles.validationErrorEnterActive,
                exitActive: styles.validationErrorExitActive
              }}
              timeout={450}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              <NotificationBanner
                error
                title={{ value: i18n.t('Globals-Toast-Form-Error-Title') }}
                content={{
                  value: i18n.t('Errors-HBT_ERR_5012')
                }}
                onCloseCallback={onCloseValidationErrors}
              />
            </CSSTransition>
            <div className={`${styles.twoColumns} ${styles.loanItmRef}`}>
              <div>
                <FormNumber
                  label={fields.cmhcLoan}
                  className={styles.loanTextInput}
                  name="cmhcLoanAccountNumber"
                />
              </div>
              <div>
                <FormNumber
                  label={fields.itmReferenceNum}
                  className={styles.itmRefTextInput}
                  name="referenceNumber"
                  isReadOnly={!!edit}
                />
              </div>
            </div>

            <div className={styles.itmFlagList}>
              <FormCheckboxList
                label={fields.selectAllITMFlag}
                className={'flagsList'}
                name="typeCodes"
                checkBoxList={fields.itmflagList}
                selectedCheckbox={edit ? editableItem?.typeCodes : []}
                editITM={edit}
                customClassName={styles.formCheckboxListWrapper}
                setAsNumber={true}
              />
            </div>

            <div className={`${styles.twoColumns} ${styles.formButtons}`}>
              <div>
                <Button
                  responsive
                  loading
                  type="submit"
                  ariaText={fields.saveButton}
                  name={`SubmitButton`}
                  text={fields.saveButton}
                  disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
                  icon={
                    loadingStatus?.isLoading && loadingStatus?.isFlyout
                      ? () => (
                          <span className={styles.saveIconWrapper}>
                            <span className={`material-icons align-self-center`}>loop</span>
                          </span>
                        )
                      : undefined
                  }
                />
              </div>
              <div>
                <Button
                  secondaryButton
                  responsive
                  onClick={() => {
                    hookForm.reset();
                    if (onCancelCallback) onCancelCallback();
                  }}
                  ariaText={fields.cancelButton}
                  name={`CancelButton`}
                  text={fields.cancelButton}
                  type="button"
                  disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </Scrollbars>
  );
};
