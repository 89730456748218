import React from 'react';
import { FormProvider } from 'react-hook-form';
import CSSTransition from 'react-transition-group/CSSTransition';
import { Scrollbars } from 'react-custom-scrollbars-2';
import i18n from 'i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { Button } from 'Components/Common/Button';
import FormNumber from 'Components/Inputs/FormNumber';
import FormRadioGroup from 'Components/Inputs/FormRadioGroup';
import { NotificationBanner } from 'Components/Common/Notifications/NotificationBanner';

import useAddUnderReviewFlagFunctions from './useAddUnderwritingReview';
import { AddUnderwritingReviewFormProps } from './types';
import styles from './styles.module.scss';

export const AddUnderwritingReviewForm: React.FC<AddUnderwritingReviewFormProps> = ({
  name,
  fields,
  loadingStatus,
  onSuccessCallback,
  onErrorCallback,
  onCancelCallback,
  setIsLoadingCallback
}: AddUnderwritingReviewFormProps) => {
  const { onCloseValidationErrors, handleSubmit, onSubmitErrors, showValidationError, hookForm } =
    useAddUnderReviewFlagFunctions(onSuccessCallback, setIsLoadingCallback, onErrorCallback);
  return (
    <>
      <Scrollbars className="formAddUnderwritingReview">
        <div
          className={styles.addUnderwritingReviewWrapper}
          data-testid={`${name}AddUnderwritingReviewWrapper`}
        >
          <Text tag="h2" field={fields.formTitle} />
          <div className={styles.closeButton}>
            <button
              type="button"
              onClick={() => {
                hookForm.reset();
                if (onCancelCallback) onCancelCallback();
              }}
              aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
              data-testid="closeButton"
            >
              <span className="material-icons align-self-center">close</span>
            </button>
          </div>
          <FormProvider {...hookForm}>
            <form
              onSubmit={hookForm.handleSubmit(handleSubmit, onSubmitErrors)}
              id="addUnderWritingReviewForm"
            >
              <Text field={fields.formSubtitle} />
              <CSSTransition
                in={showValidationError}
                classNames={{
                  enterActive: styles.validationErrorEnterActive,
                  exitActive: styles.validationErrorExitActive
                }}
                timeout={450}
                unmountOnExit={true}
                mountOnEnter={true}
              >
                <NotificationBanner
                  error
                  title={{ value: i18n.t('Globals-Toast-Form-Error-Title') }}
                  content={{
                    value: i18n.t('Errors-HBT_ERR_5008')
                  }}
                  onCloseCallback={onCloseValidationErrors}
                />
              </CSSTransition>
              <div className={`${styles.formRow}`}>
                <div>
                  <FormNumber
                    label={fields.loanNumber}
                    className={styles.loanTextInput}
                    name="cmhcLoanAccountNumber"
                  />
                </div>
              </div>
              <div className={`${styles.radioWrapper}`}>
                <FormRadioGroup
                  testid="flaggedBy"
                  name="discloserIndicator"
                  label={fields.flaggedBy}
                  className={styles['radio-group']}
                  radioLabels={fields.flaggedByOptions}
                />
              </div>

              <div className={`${styles.twoColumns} ${styles.formButtonsWrapper}`}>
                <div>
                  <Button
                    name={'saveButton'}
                    responsive
                    loading
                    type="submit"
                    ariaText={fields.saveButton}
                    text={fields.saveButton}
                    disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
                    icon={
                      loadingStatus?.isLoading && loadingStatus?.isFlyout
                        ? () => (
                            <span className={styles.saveIconWrapper}>
                              <span className={`material-icons align-self-center`}>loop</span>
                            </span>
                          )
                        : undefined
                    }
                  />
                </div>
                <div>
                  <Button
                    secondaryButton
                    responsive
                    onClick={() => {
                      hookForm.reset();
                      if (onCancelCallback) onCancelCallback();
                    }}
                    ariaText={fields.cancelButton}
                    name={`cancelButton`}
                    text={fields.cancelButton}
                    type="button"
                    disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </Scrollbars>
    </>
  );
};
