import { HbtFileExtensionType } from '@hobt/constants';

import { AttachmentsViewPropsFields } from 'Components/Common/AttachmentsView/types';
import { SitecoreDropdownList } from 'Constants/Types/SitecoreListItem';

export interface AttachmentsViewComponentProps {
  fields: AttachmentsViewPropsFields;
}

export default interface ApproverReviewProps {
  fields: ApproverReviewPropsFields;
  approverLevel?: number;
  isArrears?: boolean;
  isClaimApproved?: boolean;
  data?: any;
}

export interface ApproverReviewPropsFields {
  name: FieldValue;
  titleL1: FieldValue;
  titleL2: FieldValue;
  radioButtonNo: FieldValue;
  radioButtonYes: FieldValue;
  limitNumber: FieldValue;
  characterLimit: FieldValue;
  commentsLabel: FieldValue;
  attachmentsLabel: FieldValue;
  approvalBehalfL1: FieldValue;
  approvalBehalfL2: FieldValue;
  approvalDecisionL1: FieldValue;
  approvalDecisionL2: FieldValue;
  commentsOptionalL1: FieldValue;
  commentsOptionalL2: FieldValue;
  attachmentsView: AttachmentsViewComponentProps;
  radioButtonApproveOptions: SitecoreDropdownList[];
  approverNameL1: FieldValue;
  approverRoleL1: FieldValue;
  approvalDateL1: FieldValue;
  approvalAttachmentsL1: FieldValue;
  approverNameL2: FieldValue;
  approverRoleL2: FieldValue;
  approvalDateL2: FieldValue;
  approvalAttachmentsL2: FieldValue;
  approvalType: SitecoreDropdownList;
  userLevelList: FieldList;
  notAvailable: FieldValue;
}

export const AllowedFileTypes = [
  HbtFileExtensionType.CSV,
  HbtFileExtensionType.XLSX,
  HbtFileExtensionType.XLSM,
  HbtFileExtensionType.XLSB,
  HbtFileExtensionType.XLTX,
  HbtFileExtensionType.XLTM,
  HbtFileExtensionType.XLS,
  HbtFileExtensionType.XLT,
  HbtFileExtensionType.XML,
  HbtFileExtensionType.XLAM,
  HbtFileExtensionType.XLA,
  HbtFileExtensionType.XLW,
  HbtFileExtensionType.XLR,
  HbtFileExtensionType.PDF,
  HbtFileExtensionType.JPG,
  HbtFileExtensionType.DOC,
  HbtFileExtensionType.DOCX
];

export type DataObject = {
  primaryName: string;
  primaryRole: string;
  primaryDecision: string;
  primaryDate: string;
  primaryComments: string;
  primaryAttachments: string[];
  secondaryName?: string;
  secondaryRole?: string;
  secondaryDecision?: string;
  secondaryDate?: string;
  secondaryComments?: string;
  secondaryAttachments?: string[];
};
