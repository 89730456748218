import * as React from 'react';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import PageWelcomeBarProps from 'Feature/PageComponents/models/PageWelcomeBarProps';

import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const PageWelcomeBar = (props: PageWelcomeBarProps) => {
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const firstName =
    sitecoreContext && sitecoreContext.user && sitecoreContext.user.contact
      ? sitecoreContext.user.contact.firstName
      : '';
  const greetingMessage =
    props.fields.greeting && props.fields.greeting.value
      ? props.fields.greeting.value.replace('{firstName}', ` ${firstName}`)
      : '';

  return (
    <section className={styles.pageWelcomeBar}>
      <Text tag="h2" field={{ value: greetingMessage }} />
      <Text tag="p" field={props.fields.welcome} />
    </section>
  );
};

export default PageWelcomeBar;
