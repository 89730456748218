import i18n from 'i18next';
import React, { FC, useEffect, useState } from 'react';

import KeyValuePairDisplay from 'Components/Common/KeyValuePairDisplay';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { getLatestDefaultRequests } from './DefaultRequestService';

import { ArrearsHistoryPropsFields, DefaultRequest, ArrearsHistoryDataFields } from './types';

import styles from './styles.module.scss';
import { useArrearsHistoryFunction } from './useArrearsHistroyFunction';

import { config } from '../../../../../../config';

const APPROVED_MANAGEMENT_TOOLS_LABEL = 'Approved Default Management Tool(s)';
const DATE_OF_DECISION_LABEL = 'Date of Decision';
const DEFAULT_DECISION_LABEL = 'Default Decision';

const ArrearsHistory: FC<ArrearsHistoryPropsFields> = ({
  titleArrearsHistory,
  dueDateInstallmentPaid,
  firstReportedDate,
  initialReasonforArrears,
  dueDateFirstPayment,
  firstArrearsAmount,
  lastArrearsReported,
  currentReasonforArrears,
  dueDateLastPayment,
  lastArrearsAmount,
  titleDefaultRequest,
  decision,
  approvedManagementTools,
  dateofDecision
}) => {
  const authContext = useAuthenticationContext();
  const { claimData } = useHBTFormContext();

  const { getArrearsHistory } = useArrearsHistoryFunction<ArrearsHistoryDataFields>(authContext, {
    timeout: config.claimApi.requestTimeout
  });
  const [arrearsHistoryData, setArrearsHistoryData] = useState<ArrearsHistoryDataFields>();

  const getArrearsHistoryData = async () => {
    try {
      const response = await getArrearsHistory(claimData?.cmhcLoanAccountNumber);
      setArrearsHistoryData(response);
    } catch (error) {
      // @ts-ignore
      throw new Error(`Could not get Arrears history data. ${error?.message}`);
    }
  };

  const arrearsHistoryDictionary = {
    [dueDateInstallmentPaid.value ?? 'Due Date of Last Completed Installment Paid']:
      arrearsHistoryData?.lastInstallmentFullyPaidDueDate ?? i18n.t('Global-Not-Available'),
    [firstReportedDate.value ?? 'First Reported Date']:
      arrearsHistoryData?.currentFirstReportedDate ?? i18n.t('Global-Not-Available'),
    [initialReasonforArrears.value ?? 'Initial Reason for Arrears']:
      arrearsHistoryData?.firstArrearsReason ?? i18n.t('Global-Not-Available'),
    [dueDateFirstPayment.value ?? 'Due Date of First Payment']:
      arrearsHistoryData?.firstPaymentDueDate ?? i18n.t('Global-Not-Available'),
    [firstArrearsAmount.value ?? 'First Arrears Amount Reported']:
      arrearsHistoryData?.firstArrearsAmountReported ?? i18n.t('Global-Not-Available'),
    [lastArrearsReported.value ?? 'Last Reported Date']:
      arrearsHistoryData?.lastArrearsEffectiveDate ?? i18n.t('Global-Not-Available'),
    [currentReasonforArrears.value ?? 'Current Reason for Arrears']: i18n.t('Global-Not-Available'),
    [dueDateLastPayment.value ?? 'Due Date of Last Payment']:
      arrearsHistoryData?.lastPaymentDueDate ?? i18n.t('Global-Not-Available'),
    [lastArrearsAmount.value ?? 'Last Arrears Amount Reported']:
      arrearsHistoryData?.lastArrearsAmountReported ?? i18n.t('Global-Not-Available')
  };

  const [defaultRequests, setDefaultRequests] = useState<DefaultRequest[]>([
    {
      defaultDecision: '',
      decisionDate: '',
      managementTool: ''
    }
  ]);

  useEffect(() => {
    getLatestDefaultRequests(claimData?.cmhcLoanAccountNumber, authContext).then(
      setDefaultRequests
    );
    if (claimData?.cmhcLoanAccountNumber != null) {
      getArrearsHistoryData();
    }
  }, []);

  const fallbackDecisionObject = {
    [decision.value ?? DEFAULT_DECISION_LABEL]: i18n.t('Global-Not-Available'),
    [approvedManagementTools.value ?? APPROVED_MANAGEMENT_TOOLS_LABEL]:
      i18n.t('Global-Not-Available'),
    [dateofDecision.value ?? DATE_OF_DECISION_LABEL]: i18n.t('Global-Not-Available')
  };

  const mostRecentDecision = Object.assign(fallbackDecisionObject, {
    [decision.value ?? DEFAULT_DECISION_LABEL]:
      defaultRequests?.[0]?.defaultDecision ?? i18n.t('Global-Not-Available'),
    [approvedManagementTools.value ?? APPROVED_MANAGEMENT_TOOLS_LABEL]:
      defaultRequests?.[0]?.managementTool,
    [dateofDecision.value ?? DATE_OF_DECISION_LABEL]:
      defaultRequests?.[0]?.decisionDate !== i18n.t('Global-Not-Available')
        ? defaultRequests?.[0]?.decisionDate.split(' ')[0]
        : i18n.t('Global-Not-Available')
  });
  const secondMostRecentDecision = Object.assign(
    fallbackDecisionObject,
    defaultRequests?.length === 2 && {
      [decision.value ?? DEFAULT_DECISION_LABEL]:
        defaultRequests?.[1]?.defaultDecision ?? i18n.t('Global-Not-Available'),
      [approvedManagementTools.value ?? APPROVED_MANAGEMENT_TOOLS_LABEL]:
        defaultRequests?.[1]?.managementTool,
      [dateofDecision.value ?? DATE_OF_DECISION_LABEL]:
        defaultRequests?.[1]?.decisionDate !== i18n.t('Global-Not-Available')
          ? defaultRequests?.[1]?.decisionDate.split(' ')[0]
          : i18n.t('Global-Not-Available')
    }
  );

  return (
    <div className={styles.section}>
      <div>
        <KeyValuePairDisplay
          heading={<h2 className={'header3'}>{titleArrearsHistory.value}</h2>}
          dictionary={arrearsHistoryDictionary}
        />
      </div>
      <div>
        <KeyValuePairDisplay
          heading={<h2 className={'header3'}>{titleDefaultRequest.value}</h2>}
          dictionary={mostRecentDecision}
        />
        <KeyValuePairDisplay dictionary={secondMostRecentDecision} />
      </div>
    </div>
  );
};

export default ArrearsHistory;
