import React, { useCallback, useState, useEffect, ReactElement, memo, useMemo } from 'react';
import { HttpResponseStatusCodes, LanguageShort } from '@hobt/constants';
import { Box, Stack } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Button } from 'Components/Core/Button';
import { ButtonSize, ButtonVariant } from 'Components/Core/Button/types';
import Checkbox from 'Components/Core/Checkbox/Checkbox';
import DataTableColumn from 'Components/Core/DataTable/DataTableColumn';
import DataTable from 'Components/Core/DataTable/index';
import {
  ContentAlignment,
  DataTableColumnProps,
  ErrorType,
  InferDataModel
} from 'Components/Core/DataTable/types';
import { FlyoutContainer } from 'Components/Core/Flyout/FlyoutContainer';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import { ToastNotification } from 'Components/Core/Notifications/ToastNotification';
import { ToastNotificationVariant } from 'Components/Core/Notifications/ToastNotification/types';
import Pagination from 'Components/Core/Pagination';
import { PaginationPosition } from 'Components/Core/Pagination/types';
import { Tag } from 'Components/Core/Tag';
import { TagType } from 'Components/Core/Tag/types';
import { FilterValue } from 'Feature/V2/HbtPIFileTransfer/components/HbtPIFileTransferDownloadTabView/types';
import { useCheckboxKeyDownHandler } from 'Feature/V2/Helper';
import { useAuthenticationContext } from 'Foundation/Authentication';
import i18n from 'i18next';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useForm, Controller } from 'react-hook-form';
import { convertFilesize, getHeaderFileName } from '../../../Helper';
import { MemoizedHbtDataTableCell } from '../../../Shared/MemoizedHbtDataTableCell';
import { downloadInventory, getInventory } from './InventoryInformationService';
import styles from './styles.module.scss';
import { Category, HbtPIPurposeTestReportProps, PurposeTestFileReport } from './types';

interface FormValues {
  [key: string]: boolean;
}

type DownloadFileItem = {
  id: number;
  documentID: string;
  fileName: string;
  url: string;
  uploadedDateTime: string;
  size: string;
  lastDownloadedDateTime: string;
  lastDownloadedBy: string;
};

const HbtPurposeTestReport = ({ fields }: HbtPIPurposeTestReportProps) => {
  const authenticationContext = useAuthenticationContext();
  const reportTypeLabel = i18n.t('PI-Purpose-Test-Reports-Filter-ReportType-Label', 'Report Type');
  const reportTypeFilterList = [
    {
      id: '1',
      label: i18n.t('PI-Purpose-Test-Reports-Filter-ReportType-Monthly-Option-Label', 'Monthly')
    },
    {
      id: '2',
      label: i18n.t('PI-Purpose-Test-Reports-Filter-ReportType-Quarterly-Option-Label', 'Quarterly')
    }
  ];

  const [columns, setColumns] = useState<
    ReactElement<DataTableColumnProps<PurposeTestFileReport>>[]
  >([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [inventoryData, setInventoryData] = useState<PurposeTestFileReport[] | null>(null);
  const [dataSource, setDataSource] = useState<PurposeTestFileReport[] | null>(null);
  const [openFlyout, setOpenFlyout] = React.useState<boolean>(false);
  const [isNeedHelpInfoVisible, setIsNeedHelpInfoVisible] = useState(false);
  const [filterCategory, setFilterCategory] = useState<Category[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [accordionLenderExpanded, setAccordionLenderExpanded] = useState<boolean>(true);
  const [accordionReportTypeExpanded, setAccordionReportTypeExpanded] = useState<boolean>(true);

  const [downloadToastMessage, setDownloadToastMessage] = useState('');
  const [downloadToastVisible, setDownloadToastVisible] = useState<boolean>(false);
  const [datatableErrorType, setDatatableErrorType] = useState<ErrorType | null>(null);
  const [tableError, setTableError] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [showPagination, setShowPagination] = React.useState<boolean>(false);
  const [paginatedInventoryData, setPaginatedInventoryData] = useState<
    PurposeTestFileReport[] | null
  >(null);
  const [sortedColumn, setSortedColumn] = useState<
    keyof InferDataModel<PurposeTestFileReport> | null
  >(null);
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [clearSelection, setClearSelection] = useState<boolean>(false);
  const [isSubmittingData, setIsSubmittingData] = useState<boolean>(false);

  const handleItemsPerPageChange = (itemsPerPage: number) => {
    setItemsPerPage(itemsPerPage);
    setCurrentPage(1);
    setClearSelection(true);
  };

  const downloadErrorMessage = i18n.t(
    'PI-Purpose-Test-Reports-General-File-Selected-Download-Error',
    'One or more of the selected files could not be downloaded.'
  );
  const downloadSuccessMessage = i18n.t(
    'PI-Purpose-Test-Reports-File-Download-Complete-NotificationBanner-body',
    'Your file was downloaded successfully.'
  );
  const noSelectedFileError = i18n.t(
    'PI-Purpose-Test-Reports-No-File-Selected-Download-Error',
    'Please select at least one file to download.'
  );
  const emptyTableMessage = i18n.t(
    'PI-Purpose-Test-Reports-Table-Empty-Data-Message',
    'There are no files to display.'
  );

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) =>
      setAccordionLenderExpanded(isExpanded);

  const handleAccordionReportTypeChange =
    () => (event: React.SyntheticEvent, isExpanded: boolean) =>
      setAccordionReportTypeExpanded(isExpanded);

  const handleSort = (column: keyof InferDataModel<PurposeTestFileReport>) => {
    const isAsc = sortedColumn === column && sortAscending;
    setSortAscending(!isAsc);
    setSortedColumn(column);
    if (inventoryData) {
      const sortedData = [...inventoryData].sort((a, b) => {
        const aValue = a[column] ?? ''; // Fallback to empty string if undefined
        const bValue = b[column] ?? ''; // Fallback to empty string if undefined

        if (column === 'fileByteCount') {
          const parseSize = (size: string) => Number(size?.match(/^\d+/)?.[0]) || 0;

          const sizeA = parseSize(aValue as string);
          const sizeB = parseSize(bValue as string);

          return isAsc ? sizeA - sizeB : sizeB - sizeA;
        }

        if (aValue < bValue) return isAsc ? 1 : -1;
        if (aValue > bValue) return isAsc ? -1 : 1;
        return 0;
      });
      setInventoryData(sortedData);
      setCurrentPage(1);
      setClearSelection(true);
    }
  };

  const toggleDrawer = (newOpen: boolean) => {
    setOpenFlyout(newOpen);
  };

  const getUniqueCategories = (data: PurposeTestFileReport[] | null) => {
    if (data) {
      return _.uniq(data.map((x) => x?.approvedLenderCode))
        .sort()
        .map((x) => ({
          id: x,
          label: data.find((y) => y?.approvedLenderCode === x)?.lenderName ?? ''
        }));
    }
    return [];
  };
  const getSelectedReportType = () => {
    const data = selectedFilters.filter((x) => x === '1' || x === '2');
    if (data && data.length === 1) {
      const selectedreportType = reportTypeFilterList.find((item) => item.id === data[0]);
      return [selectedreportType];
    }
    if (data && data.length > 1) {
      return reportTypeFilterList;
    }
    return [];
  };

  const downloadFile = (ids: string[], fileName: string) => {
    setIsSubmittingData(true);
    downloadInventory({ documentIDs: ids }, authenticationContext, {
      responseType: 'blob'
    })
      .then((res: any) => {
        if (res?.status === HttpResponseStatusCodes.OK) {
          const blob = new Blob([res?.data], { type: 'arraybuffer' });

          const url = window.URL.createObjectURL(blob);
          const downloadElement = document.createElement('a');
          downloadElement.setAttribute('href', url);
          downloadElement.setAttribute('download', fileName);
          downloadElement.click();
          downloadElement.remove();
          window.URL.revokeObjectURL(url);

          setTableError('');
          setDatatableErrorType(null);
          setIsSubmittingData(false);
          setDownloadToastVisible(true);
          setDownloadToastMessage(downloadSuccessMessage);
        }
        if (!res) {
          setTableError(downloadErrorMessage);
          setDatatableErrorType(ErrorType.CannotDownload);
        }
      })
      .catch((error) => {
        setIsSubmittingData(false);
        setTableError(downloadErrorMessage);
        setDatatableErrorType(ErrorType.CannotDownload);
      });
  };

  const downloadAllFiles = (data: string[]) => {
    setIsSubmittingData(true);
    downloadInventory({ documentIDs: data }, authenticationContext, {
      responseType: 'arraybuffer'
    })
      .then((res: any) => {
        if (res?.status === HttpResponseStatusCodes.OK) {
          const fileName = getHeaderFileName('purpose-test-report-downloaded-file', res);
          const blob = new Blob([res?.data], { type: 'arraybuffer' });
          const url = window.URL.createObjectURL(blob);

          const downloadElement = document.createElement('a');
          downloadElement.setAttribute('href', url);
          downloadElement.setAttribute('download', fileName);
          downloadElement.click();
          downloadElement.remove();
          window.URL.revokeObjectURL(url);

          setTableError('');
          setDatatableErrorType(null);
          setIsSubmittingData(false);
          setDownloadToastVisible(true);
          setDownloadToastMessage(downloadSuccessMessage);
        }

        if (!res) {
          setTableError(downloadErrorMessage);
          setDatatableErrorType(ErrorType.CannotDownload);
        }
      })
      .catch((error) => {
        setIsSubmittingData(false);
        setTableError(downloadErrorMessage);
        setDatatableErrorType(ErrorType.CannotDownload);
      });
  };

  const handleDTSubmit = (selectedRows: object[]) => {
    setDatatableErrorType(null);

    let temp: string[] = [];
    let fileName: string = 'report.pdf';

    if (selectedRows && Array.isArray(selectedRows) && selectedRows.length > 0) {
      selectedRows.forEach((item: any) => {
        fileName = item?.fileName.toString();
        temp.push(item?.documentID.toString());
      });
    }

    if (temp.length > 1) downloadAllFiles(temp);
    else if (temp.length == 1) downloadFile(temp, fileName);
    else {
      setTableError(noSelectedFileError);
      setDatatableErrorType(ErrorType.NoSelectedCheckbox);
    }
  };

  const onFormSubmit = (values: { [key: string]: boolean }) => {
    const { output, selectedFiltersId } = filterDT(values);

    setOpenFlyout(false);
    setInventoryData(output);
    setSelectedFilters(selectedFiltersId);
  };

  const filterDT = (values: { [key: string]: boolean }) => {
    const selectedFiltersId = Object.entries(values)
      .filter(([_, value]) => value)
      .map(([key, _]) => key);

    if (selectedFiltersId.length === 0) {
      setIsFiltered(false);
      return { output: dataSource, selectedFiltersId: [] };
    }

    setIsFiltered(true);

    if (!dataSource) {
      return { output: null, selectedFiltersId };
    }

    const selectedLenders = selectedFiltersId.filter((key) => key !== '1' && key !== '2');
    const selectedReportTypes = selectedFiltersId.filter((key) => key === '1' || key === '2');

    let output = dataSource;

    if (selectedLenders.length > 0) {
      output = output.filter((item) => selectedLenders.includes(item.approvedLenderCode));
    }

    if (selectedReportTypes.length > 0) {
      output = output.filter((item) =>
        selectedReportTypes.includes(String(item?.purposeTestReportType))
      );
    }

    setCurrentPage(1);
    setClearSelection(true);

    return { output, selectedFiltersId };
  };

  const onDeleteTag = (id: string) => {
    let array: PurposeTestFileReport[] | null = inventoryData;
    let tempSelectedFilterId: string[] = selectedFilters.filter((x) => x !== id);

    if (array) {
      if (tempSelectedFilterId.length === 0) {
        reset(defaultValues);
        setIsFiltered(false);
        setInventoryData(dataSource);
      } else if (id === '1' || id === '2') {
        let temp: { [key: string]: boolean } = selectedFilters.reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {} as { [key: string]: boolean });

        const { output, selectedFiltersId } = filterDT(temp);
        setInventoryData(output);
      } else {
        setInventoryData(
          array.filter((obj) => tempSelectedFilterId.includes(obj?.approvedLenderCode.toString()))
        );
      }
      setSelectedFilters(tempSelectedFilterId);
    }
  };

  const clearFilters = () => {
    reset(defaultValues);
    setIsFiltered(false);
    setSelectedFilters([]);
    setInventoryData(dataSource);
    filterCategory.map((item) => setValue(item?.id, false));
    setClearSelection(true);
  };

  const handleClearSelection = useCallback(() => {
    setClearSelection(false);
  }, []);

  const processInventory = async () => {
    try {
      let inventory: PurposeTestFileReport[] = [];

      let data = await getInventory(authenticationContext);
      if (data && Array.isArray(data)) {
        data.forEach((item) => {
          inventory.push({
            documentID: item?.documentID,
            approvedLenderCode: item?.approvedLenderCode,
            lenderName:
              i18n.language === LanguageShort.English
                ? item?.approvedLenderEnglishName
                : item?.approvedLenderFrenchName,
            fileName: item?.fileName,
            url: '#',
            recordCreatedTimestamp: item?.recordCreatedTimestamp,
            fileByteCount: item?.fileByteCount,
            recordUpdatedTimestamp: item?.recordUpdatedTimestamp,
            updatedUserEmail: item?.recordUpdatedUserNameEmail?.email ?? '-',
            purposeTestReportType: item?.purposeTestReportType,
            recordCreatedUserID: item?.recordCreatedUserID
          });
        });
      }
      setDataSource(inventory);
      setInventoryData(inventory);
      setFilterCategory(getUniqueCategories(inventory));
    } catch (err) {
      console.error('Error processing data', err);
      setDatatableErrorType(ErrorType.CannotDownload);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    setClearSelection(true);
  };

  useEffect(() => {
    (async function () {
      await processInventory();
    })();
  }, [i18n?.language]);

  useEffect(() => {
    if (inventoryData && inventoryData.length > 10) {
      const paginatedData = inventoryData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );
      setTotalItems(inventoryData.length);
      setTotalPages(Math.ceil(inventoryData.length / itemsPerPage));
      setShowPagination(true);
      setPaginatedInventoryData(paginatedData);
    } else if (inventoryData) {
      setTotalItems(inventoryData.length);
      setTotalPages(1);
      setShowPagination(true);
      setPaginatedInventoryData(inventoryData);
    }
  }, [inventoryData, currentPage, itemsPerPage]);

  const toggleNeedHelpInfoContent = useCallback(() => {
    setIsNeedHelpInfoVisible((prev) => !prev);
  }, []);

  const handleCheckboxKeyDown = useCheckboxKeyDownHandler();

  const defaultValues = reportTypeFilterList.reduce((acc, item) => {
    acc[item.id] = false; // default unchecked state
    return acc;
  }, {} as FilterValue);

  const purposeTestInventoryFilterButtonText = useMemo(() => {
    const filterText = fields?.purposeTestReportFilterBtn?.value ?? 'Filter';
    return selectedFilters.length === 0
      ? filterText
      : `${fields.purposeTestReportFilterBtn.value} (${selectedFilters.length})`;
  }, [selectedFilters.length, fields]);

  const memoizedPurposeTestInventoryFilterButton = useMemo(() => {
    return (
      <Button
        name={fields.purposeTestReportFilterBtn.value ?? 'Filter'}
        size={ButtonSize.SMALL}
        variant={ButtonVariant.SECONDARY}
        onClick={() => toggleDrawer(true)}
        text={{ value: purposeTestInventoryFilterButtonText }}
        ariaText={{ value: purposeTestInventoryFilterButtonText }}
        leadingIcon={() => <HbtIcon size={HbtIconSize.SMALL} type="icon_filter" />}
      />
    );
  }, [purposeTestInventoryFilterButtonText, toggleDrawer]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<FormValues>({ defaultValues });

  return (
    <div className={styles.mainContent}>
      <div className={styles.layoutContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.header}>
            <div className={styles.title} id="purposeTestReportHeading" tabIndex={0}>
              <Text field={fields.purposeTestReportheading} />
            </div>
          </div>
          <div className={styles.info}>
            <Text field={fields.purposeTestReportheadingInfo} />
          </div>
          <div className={styles.purposeTestCMHCSupportBtnContainer}>
            <Button
              name={fields.purposeTestReportSupportBtn.value ?? 'CMHC support?'}
              text={fields.purposeTestReportSupportBtn}
              ariaText={{
                value: `${fields.purposeTestReportSupportBtn.value}`
              }}
              variant={ButtonVariant.TERTIARY}
              onClick={toggleNeedHelpInfoContent}
            />
            {isNeedHelpInfoVisible && (
              <ul className={styles.cmhcSupportListContainer}>
                <li>
                  <span className={styles.piPurposeTestUserGuideContent}>
                    <Text field={fields.purposeTestUserGuideAccessLabel} />
                    &nbsp;
                    <a
                      href={
                        fields.purposeTestUserGuideLink?.[0].fields.guideHref.value?.href || '#'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <RichText field={fields.purposeTestUserGuideLink?.[0].fields.guideName} />
                      <HbtIcon
                        className={styles.piPurposeTestUserGuideExternalIconLink}
                        size={HbtIconSize.SMALL}
                        type="icon_external_link"
                      />
                    </a>
                  </span>
                </li>
                <li>
                  <span className={styles.needHelpInfoContent}>
                    <RichText field={fields.purposeTestReportSupportInfoContent} />
                  </span>
                </li>
              </ul>
            )}
          </div>
        </div>

        <Stack
          direction="column"
          justifyContent="space-between"
          className={styles.piPurposeTestFilterTagWrapper}
        >
          <Stack direction="row" justifyContent="flex-end">
            {memoizedPurposeTestInventoryFilterButton}
          </Stack>
          <Stack direction="row" className={styles.tagContainer}>
            {isFiltered &&
              getUniqueCategories(dataSource)
                .filter((x) => selectedFilters.includes(x?.id.toString()))
                .map((item) => (
                  <Tag
                    key={item?.id}
                    label={item?.label}
                    variant={TagType.INTERACTIVE}
                    onDelete={() => onDeleteTag(item?.id.toString())}
                  />
                ))}
            {isFiltered &&
              selectedFilters.filter((x) => x === '1' || x === '2').length !== 0 &&
              getSelectedReportType().map((item) => (
                <Tag
                  key={item?.id}
                  label={item?.label ? item?.label : ''}
                  variant={TagType.INTERACTIVE}
                  onDelete={() => onDeleteTag(item?.id ? item?.id : '')}
                />
              ))}
            {isFiltered && (
              <Button
                data-testid="btnClearFilterTags"
                name="clearFilterTags"
                size={ButtonSize.SMALL}
                onClick={() => clearFilters()}
                variant={ButtonVariant.TERTIARY}
                text={{ value: fields.purposeTestReportClearFiltersBtn.value }}
                ariaText={{
                  value: fields.purposeTestReportClearFiltersBtn.value
                }}
                leadingIcon={() => <HbtIcon size={HbtIconSize.SMALL} type="icon_clear_circle" />}
              />
            )}
          </Stack>
        </Stack>

        <div className={styles.purposeTestDatatableContainer}>
          <DataTable<PurposeTestFileReport>
            data={paginatedInventoryData ?? []}
            uniqueKey="documentID"
            clearSelectionRows={clearSelection}
            columnResize={false}
            sortedColumn={sortedColumn}
            sortAscending={sortAscending}
            stickyFirstColumn={false}
            multipleRowSubmission={true}
            errorType={datatableErrorType}
            tableErrorMessage={tableError}
            isLoading={inventoryData === null}
            isSubmitButtonLoading={isSubmittingData}
            submitButtonLabel={fields.purposeTestReportDownloadBtn.value}
            emptyTableMessage={emptyTableMessage}
            onColumnsReady={setColumns}
            onRowSelectionCleared={handleClearSelection}
            onSort={handleSort}
            onSubmit={handleDTSubmit}
          >
            <DataTableColumn
              name="lenderName"
              displayName={
                fields.purposeTestReportLenderName.value ?? 'Insured Approved Lender Name'
              }
              sortable={true}
              filterable={true}
              rowAlignment={ContentAlignment.Start}
            >
              {(data) => {
                return (
                  <MemoizedHbtDataTableCell
                    dataValue={data as string}
                    cellContent={<span>{data}</span>}
                  />
                );
              }}
            </DataTableColumn>
            <DataTableColumn
              name="fileName"
              displayName={fields.purposeTestReportFileName.value ?? 'File name'}
              sortable={true}
              filterable={true}
              rowAlignment={ContentAlignment.Start}
            >
              {(cellData, rowData) => {
                return (
                  <MemoizedHbtDataTableCell
                    dataValue={cellData as string}
                    cellContent={
                      <Button
                        type="button"
                        className={styles.tableCellFileDownloadBtn}
                        variant={ButtonVariant.TERTIARY}
                        name={`button-${(rowData as DownloadFileItem)?.documentID}`}
                        text={{ value: (rowData as DownloadFileItem)?.fileName }}
                        ariaText={{
                          value: `Download ${(rowData as DownloadFileItem)?.fileName}`
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          downloadFile(
                            [(rowData as DownloadFileItem).documentID],
                            (rowData as DownloadFileItem).fileName
                          );
                        }}
                      />
                    }
                  />
                );
              }}
            </DataTableColumn>
            <DataTableColumn
              name="recordCreatedTimestamp"
              displayName={fields.purposeTestReportUploadDate.value ?? 'Uploaded Date'}
              sortable={true}
              filterable={true}
              rowAlignment={ContentAlignment.Start}
            >
              {(data) => (
                <MemoizedHbtDataTableCell
                  dataValue={data as string}
                  cellContent={<span>{moment(data).format('YYYY-MM-DD hh:mm:ss A')}</span>}
                />
              )}
            </DataTableColumn>
            <DataTableColumn
              sortable={true}
              filterable={false}
              name="fileByteCount"
              displayName={fields.purposeTestReportSize.value ?? 'Size'}
              rowAlignment={ContentAlignment.Start}
            >
              {(data) => (
                <MemoizedHbtDataTableCell
                  dataValue={data as string}
                  cellContent={<span>{convertFilesize(data)}</span>}
                />
              )}
            </DataTableColumn>
            <DataTableColumn
              name="recordUpdatedTimestamp"
              displayName={fields.purposeTestReportLastDownloadDate.value ?? 'Last Downloaded Date'}
              sortable={true}
              filterable={true}
              rowAlignment={ContentAlignment.Start}
            >
              {(data) => (
                <MemoizedHbtDataTableCell
                  dataValue={data as string}
                  cellContent={<span>{moment(data).format('YYYY-MM-DD hh:mm:ss A')}</span>}
                />
              )}
            </DataTableColumn>
            <DataTableColumn
              name="updatedUserEmail"
              displayName={fields.purposeTestReportLastDownloadedBy.value ?? 'Last Downloaded By'}
              sortable={true}
              filterable={true}
              rowAlignment={ContentAlignment.Start}
            >
              {(data) => (
                <MemoizedHbtDataTableCell
                  dataValue={data as string}
                  cellContent={<span>{data}</span>}
                />
              )}
            </DataTableColumn>
          </DataTable>
          {showPagination && (
            <div className={styles.purposeTestDatatablePaginationContainer}>
              <Pagination
                currentPageNumber={currentPage}
                totalPageNumber={totalPages}
                isCondensed={true}
                isOnTablet={false}
                paginationPosition={PaginationPosition.RIGHT}
                onPageChange={handlePageChange}
                numberOfItemsOnPage={itemsPerPage}
                onItemsPerPageChange={handleItemsPerPageChange}
                totalItemNumber={totalItems}
              />
            </div>
          )}
        </div>
        <div>
          <FlyoutContainer
            id="purpose-test-filter-flyout-container"
            isHeaderRequired={true}
            headerText={fields.purposeTestReportFilterBtn.value}
            open={openFlyout}
            onClose={() => toggleDrawer(false)}
            content={
              <Box className={styles.flyoutContainer} role="presentation">
                <form onSubmit={handleSubmit(onFormSubmit)}>
                  <Box className={styles.flyoutBody}>
                    <Box>
                      <Accordion
                        className={`${styles.accordion} ${styles.piPurposeTestFlyoutAccordion}`}
                        expanded={accordionLenderExpanded}
                        onChange={handleAccordionChange('panel1')}
                      >
                        <AccordionSummary
                          aria-controls="panel-content"
                          className={`${styles.accordionHeader} ${styles.piPurposeTestFlyoutAccordionSummary}`}
                          expandIcon={
                            <HbtIcon
                              type="icon_chevron_down"
                              className={styles.icon}
                              size={HbtIconSize.MEDIUM}
                            />
                          }
                        >
                          <Stack>
                            <Box className={styles.accordionTitle}>
                              <Text field={fields.purposeTestReportLenderName} />
                              &nbsp;
                              {selectedFilters.filter((x) => x !== '1' && x !== '2').length !== 0 &&
                                `(${selectedFilters.filter((x) => x !== '1' && x !== '2').length})`}
                            </Box>

                            {selectedFilters.length !== 0 && !accordionLenderExpanded && (
                              <Box className={styles.accordionSubtitle}>
                                {getUniqueCategories(dataSource)
                                  .filter((x) => selectedFilters.includes(x?.id))
                                  .map((x) => x?.label)
                                  .join(', ')}
                              </Box>
                            )}
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: '8px 0px 16px' }}>
                          {filterCategory.map((item) => (
                            <Stack key={item?.id} className={styles.checkboxContainer}>
                              <Controller
                                name={item?.id}
                                control={control}
                                render={({ field }) => (
                                  <Checkbox
                                    {...field}
                                    checked={!!field.value}
                                    defaultChecked={false}
                                    label={{ value: item?.label }}
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    onKeyDown={handleCheckboxKeyDown}
                                  />
                                )}
                              />
                            </Stack>
                          ))}
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        className={`${styles.accordion} ${styles.piPurposeTestFlyoutAccordion}`}
                        expanded={accordionReportTypeExpanded}
                        onChange={handleAccordionReportTypeChange()}
                      >
                        <AccordionSummary
                          aria-controls="panel-content"
                          className={`${styles.accordionHeader} ${styles.piPurposeTestFlyoutAccordionSummary}`}
                          expandIcon={
                            <HbtIcon
                              type="icon_chevron_down"
                              className={styles.icon}
                              size={HbtIconSize.MEDIUM}
                            />
                          }
                        >
                          <Stack>
                            <Box className={styles.accordionTitle}>
                              <Text field={{ value: reportTypeLabel }} />
                              &nbsp;
                              {selectedFilters.filter((x) => x === '1' || x === '2').length !== 0 &&
                                `(${selectedFilters.filter((x) => x === '1' || x === '2').length})`}
                            </Box>

                            {selectedFilters.filter((x) => x === '1' || x === '2').length !== 0 &&
                              !accordionReportTypeExpanded && (
                                <Box className={styles.accordionSubtitle}>
                                  {selectedFilters.filter((x) => x === '1' || x === '2').length !==
                                    0 &&
                                    getSelectedReportType()
                                      .map((x) => x?.label)
                                      .join(', ')}
                                </Box>
                              )}
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: '8px 0px 16px' }}>
                          {reportTypeFilterList.map((item) => (
                            <Stack key={item?.id} className={styles.checkboxContainer}>
                              <Controller
                                name={item?.id}
                                control={control}
                                render={({ field }) => (
                                  <Checkbox
                                    {...field}
                                    checked={!!field.value}
                                    defaultChecked={false}
                                    label={{ value: item?.label }}
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    onKeyDown={handleCheckboxKeyDown}
                                  />
                                )}
                              />
                            </Stack>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Box>
                  <Box className={styles.flyoutFooter}>
                    <Stack direction="row" spacing={3}>
                      <Button
                        type="button"
                        name={`${fields.purposeTestReportClearFiltersBtn.value}-btn`}
                        onClick={() => clearFilters()}
                        className={styles.fullWidth}
                        variant={ButtonVariant.SECONDARY}
                        text={{
                          value: fields.purposeTestReportClearFiltersBtn.value
                        }}
                        ariaText={{
                          value: fields.purposeTestReportClearFiltersBtn.value
                        }}
                      />

                      <Button
                        type="submit"
                        name={`${fields.purposeTestReportApplyFiltersBtn.value}-btn`}
                        className={styles.fullWidth}
                        variant={ButtonVariant.PRIMARY}
                        text={{
                          value: fields.purposeTestReportApplyFiltersBtn.value
                        }}
                        ariaText={{
                          value: fields.purposeTestReportApplyFiltersBtn.value
                        }}
                      />
                    </Stack>
                  </Box>
                </form>
              </Box>
            }
          />
        </div>
      </div>

      <ToastNotification
        actionable
        icon="icon_check_circle"
        open={downloadToastVisible}
        header={{
          value: i18n.t(
            'PI-Purpose-Test-Reports-File-Download-Complete-NotificationBanner-Title',
            'Download Complete'
          )
        }}
        variant={ToastNotificationVariant.INFO}
        onClose={() => setDownloadToastVisible(false)}
        subHeader={{
          value: downloadToastMessage
        }}
      />
    </div>
  );
};

export default memo(HbtPurposeTestReport);
