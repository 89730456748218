import React, { FC } from 'react';
import { validString } from 'Constants/Utils/ValidString/validString';

import styles from './styles.module.scss';

type Props = {
  onClick: ($event: React.MouseEvent) => void;
  comment?: string;
  isDisabled?: boolean;
  className?: string;
  hidden: boolean;
  addCommentLabel?: string;
  editCommentLabel?: string;
  commentLabel?: string;
};

const CollapsedComment: FC<Props> = ({
  onClick,
  comment,
  className,
  commentLabel,
  editCommentLabel,
  addCommentLabel,
  hidden,
  isDisabled
}) => {
  const hasComment = validString(comment);

  return (
    <fieldset
      className={hidden ? 'd-none' : `d-flex justify-content-end align-items-center ${className}`}
    >
      {hasComment && (
        <span className="mr-auto">
          <span className={styles['collapsed-label']}>{commentLabel}</span>
          <span className={styles['display-only-input']}>{comment}</span>
        </span>
      )}
      <button
        type="button"
        className={styles['add-button']}
        onClick={onClick}
        {...(isDisabled != null && { disabled: isDisabled })}
      >
        <span className="material-icons align-self-center">{hasComment ? 'edit' : 'add'}</span>
        {hasComment ? editCommentLabel : addCommentLabel}
      </button>
    </fieldset>
  );
};

export default CollapsedComment;
