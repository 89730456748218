import i18n from 'i18next';
import React, { createContext, FC, useContext, useEffect, useReducer } from 'react';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { ToastNotificationProps } from 'Feature/CommonComponents/ContentComponents/ToastNotification/ToastNotification.types';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { AuthenticationContextType, useAuthenticationContext } from 'Foundation/Authentication';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import {
  deleteRefundLetter,
  getRefundDetails,
  getRefundDocument,
  updateRefundLetter
} from '../RefundManagementService';
import type { RefundDetail, RefundDocument, RequestLetter } from './types';
import type {
  FinalizedNetRefundBalance,
  ReceivedAdjustment
} from '../Finalized Refund Balance/types';

type RefundLetterState = {
  showForm: boolean;
  toast: any;
  finalizedRefunds: FinalizedNetRefundBalance[];
  receivedAdjustments: ReceivedAdjustment[];
  requestedAmount: number;
  refundID: string | undefined;
  requestLetters: RequestLetter[];
  reasonCode: number | undefined;
};
type Action =
  | { type: 'showForm'; value: boolean }
  | { type: 'toast'; value: Partial<Omit<ToastNotificationProps, 'onCloseCallback'>> }
  | { type: 'updateRefundsReceived'; value: any[] }
  | { type: 'updateReasonCode'; value: number }
  | { type: 'setDetails'; value: RefundDetail }
  | { type: 'setRequestAmount'; value: number };

type Dispatch = (action: Action) => void;
type RefundLetterContextType = {
  state: RefundLetterState;
  dispatch: Dispatch;
};

const initialState: RefundLetterState = {
  showForm: false,
  toast: {},
  finalizedRefunds: [],
  receivedAdjustments: [],
  requestedAmount: 0,
  refundID: undefined,
  requestLetters: [],
  reasonCode: 0
};

const RefundLetterContext = createContext<RefundLetterContextType | undefined>(undefined);

let localContext: RefundLetterContextType = { state: initialState, dispatch: () => {} };
let localRefundReasons: SitecoreListItem[] = [];
let localLanguages: { english: FieldValue; french: FieldValue } | null = null;

function refundLetterReducer(state: RefundLetterState, action: Action): RefundLetterState {
  switch (action?.type) {
    case 'showForm': {
      return { ...state, showForm: action?.value };
    }
    case 'toast': {
      return { ...state, toast: { ...state?.toast, ...action?.value } };
    }
    case 'updateRefundsReceived': {
      return {
        ...state,
        receivedAdjustments: action?.value?.map((adjustment) => ({
          amount: adjustment?.amount,
          addedDate: adjustment?.date ?? adjustment?.addedDate
        }))
      };
    }
    case 'updateReasonCode': {
      return { ...state, reasonCode: Number.isNaN(action?.value) ? 0 : Number(action?.value ?? 0) };
    }
    case 'setDetails': {
      const mostRecentLetter =
        action?.value?.requestLetters[action?.value?.requestLetters?.length - 1];
      return {
        ...state,
        ...action?.value,
        requestedAmount: Number(mostRecentLetter?.requestedAmount ?? 0),
        reasonCode: Number(mostRecentLetter?.reasonCode ?? 0),
        finalizedRefunds: (action?.value?.requestLetters || [])?.map((letter: RequestLetter) => ({
          refundReason:
            localRefundReasons?.find(
              (item) => Number(item?.fields?.itemValue?.value) === letter?.reasonCode
            )?.fields?.itemName?.value || '',
          dateFinalized: letter?.finalizedDate || '',
          refundOwed: letter?.requestedAmount || 0,
          letterGenerated: letter?.fileName || null,
          documentId: letter?.documentID || null,
          language:
            (letter?.languageCode?.toString() === '1'
              ? localLanguages?.english.value
              : localLanguages?.french.value) || '',
          sentToLender: letter?.sentFlag || false,
          requestLetterID: letter?.requestLetterID || ''
        }))
      };
    }
    case 'setRequestAmount': {
      return {
        ...state,
        requestedAmount: action.value
      };
    }
    default: {
      // @ts-ignore
      throw new Error(`RefundLetterContext: Unhandled Action Type: ${action?.type}`);
    }
  }
}

const RefundLetterProvider: FC<{
  refundReasons: SitecoreListItem[];
  languages: {
    english: FieldValue;
    french: FieldValue;
  };
  children?: React.ReactNode;
}> = ({ refundReasons, languages, children }) => {
  const authenticationContext = useAuthenticationContext();
  const { uuid } = useHBTFormContext();
  const [state, dispatch] = useReducer(refundLetterReducer, initialState);
  localContext = { state, dispatch };
  localRefundReasons = refundReasons;
  localLanguages = languages;

  useEffect(() => {
    getRefundDetails(uuid, authenticationContext).then((refundDetailsData: RefundDetail | null) => {
      if (
        refundDetailsData?.receivedAdjustments != null &&
        refundDetailsData?.requestLetters != null
      ) {
        dispatch({ type: 'setDetails', value: refundDetailsData });
      }
    });
  }, []);

  return (
    <RefundLetterContext.Provider value={localContext}>{children}</RefundLetterContext.Provider>
  );
};

function useRefundLetter() {
  const context = useContext(RefundLetterContext);
  if (context === undefined) {
    throw new Error('useRefundLetter must be used within a RefundLetterProvider');
  }
  return context;
}

// Helper Functions //

let documentIDPoll: number | null = null;
let retryCount = 0;
const refundLetterDetails = (setLoadingStatus: any) => {
  const downloadLetter = async (
    uuid: string,
    authenticationContext: any,
    documentId: string | null,
    index: number
  ) => {
    if (documentId == null && documentIDPoll === null) {
      retryCount = 0;
      setLoadingStatus?.({
        isLoading: true
      });
      documentIDPoll = window.setInterval(() => {
        getRefundDetails(uuid, authenticationContext).then((refundDetailsData) => {
          if (
            refundDetailsData?.receivedAdjustments != null &&
            refundDetailsData?.requestLetters != null
          ) {
            localContext.dispatch({ type: 'setDetails', value: refundDetailsData });
          }
          const docID = refundDetailsData?.requestLetters?.[index]?.documentID ?? null;
          if (retryCount + 1 < 20) {
            downloadLetter(uuid, authenticationContext, docID, index);
          } else {
            window.clearInterval(documentIDPoll!);
            documentIDPoll = null;
            localContext.dispatch({
              type: 'toast',
              value: {
                isActive: true,
                type: ApplicationStates.ERROR,
                title: ApplicationStates[ApplicationStates.ERROR],
                content: i18n.t('DefaultSubmission-Card-DownloadAttachmentErrorMessage')
              }
            });
          }
        });
      }, 3000);
    } else if (documentId != null) {
      window.clearInterval(documentIDPoll!);
      documentIDPoll = null;
      const documentDetails: RefundDocument | null = await getRefundDocument(
        documentId,
        uuid,
        authenticationContext
      );
      if (documentDetails != null) {
        setLoadingStatus?.({
          isLoading: false
        });
        const downloadElement = document.createElement('a');
        downloadElement.setAttribute('href', documentDetails.documentEntityJson.documentLink);
        downloadElement.click();
        downloadElement.remove();
      } else {
        setLoadingStatus?.({
          isLoading: false
        });
        localContext.dispatch({
          type: 'toast',
          value: {
            isActive: true,
            type: ApplicationStates.ERROR,
            title: ApplicationStates[ApplicationStates.ERROR],
            content: i18n.t('DefaultSubmission-Card-DownloadAttachmentErrorMessage')
          }
        });
      }
    }
  };
  return { downloadLetter };
};

async function sendLetter(
  uuid: string,
  authenticationContext: AuthenticationContextType,
  index: number,
  sentFlag: boolean,
  onSuccessCallback?: () => void
) {
  const { refundID, receivedAdjustments, requestLetters } = localContext.state;
  const payload: RefundDetail = {
    refundID,
    receivedAdjustments,
    requestLetters
  };
  payload.requestLetters[index].sentFlag = sentFlag;
  payload.requestLetters[index].sentDate = sentFlag
    ? new Date().toISOString().substring(0, 10)
    : payload.requestLetters[index].sentDate;
  const updatedRefundResponse: RefundDetail | null = await updateRefundLetter(
    uuid,
    authenticationContext,
    payload
  );

  if (updatedRefundResponse != null) {
    localContext.dispatch({ type: 'setDetails', value: updatedRefundResponse });
    // localContext.dispatch({
    //   type: 'toast',
    //   value: {
    //     isActive: true,
    //     type: ApplicationStates.SUCCESS,
    //     title: i18n.t('Globals-Toast-Success-Title'),
    //     content: i18n.t('Refund-Letter-Sent-To-Lender')
    //   }
    // });

    if (onSuccessCallback != null) {
      onSuccessCallback();
    }
  } else {
    localContext.dispatch({
      type: 'toast',
      value: {
        isActive: true,
        type: ApplicationStates.ERROR,
        title: i18n.t('Globals-Toast-Error-Title'),
        content: i18n.t('DefaultSubmission-OtherErrors')
      }
    });
  }
}

async function deleteLetter(
  uuid: string,
  authenticationContext: AuthenticationContextType,
  requestLetterID: string | number
) {
  const refundDetailsData = await deleteRefundLetter(uuid, authenticationContext, requestLetterID);

  if (refundDetailsData?.receivedAdjustments != null && refundDetailsData?.requestLetters != null) {
    localContext.dispatch({ type: 'setDetails', value: refundDetailsData });
  }
}

export { RefundLetterProvider, refundLetterDetails, useRefundLetter, sendLetter, deleteLetter };
