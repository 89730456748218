import { ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { HttpResponseStatusCodes } from '@hobt/constants';
import { config } from '../../../config';

export const useDocumentDownloader = (module: string, onErrorCallback?: Function) => {
  const authenticationContext = useAuthenticationContext();

  const { getWithAuth } = ApiClient(authenticationContext, {
    timeout: config.documentApi.requestTimeout
  });

  const documentDownloadUrl = config.documentApi.urls.get;

  const getDownloadUrl = async (
    documentId: string,
    entityId: string,
    correspondenceId?: string
  ) => {
    let reqUrl = `${documentDownloadUrl}${documentId}?module=${module}&entityID=${entityId}`;
    reqUrl += correspondenceId != null ? `&correspondenceID=${correspondenceId}` : '';

    return getWithAuth(reqUrl);
  };

  const downloadDocument = async (
    documentId: string,
    entityId: string,
    correspondenceId?: string
  ) => {
    try {
      const documentUrlResponse = await getDownloadUrl(documentId, entityId, correspondenceId);

      if (
        documentUrlResponse?.status === HttpResponseStatusCodes.OK &&
        documentUrlResponse?.data?.data?.documentEntityJson?.documentLink != null
      ) {
        const { documentLink, fileName } = documentUrlResponse.data.data.documentEntityJson;
        const downloadElem = document.createElement('a');
        downloadElem.setAttribute('href', documentLink);

        if (fileName != null) {
          downloadElem.setAttribute('download', fileName);
        }

        downloadElem.click();
        downloadElem.remove();
      }
    } catch (e) {
      if (onErrorCallback != null) {
        onErrorCallback();
      }
    }
  };

  return { downloadDocument, getDownloadUrl };
};
