import * as React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { NotificationHeaderProps } from 'Feature/Header/components/Notifications/NotificationHeader/types';

import styles from './styles.module.scss';

export const NotificationHeader: React.FC<NotificationHeaderProps> = ({
  title,
  onCloseAria,
  onRefreshAria,
  refreshLoading,
  onClose,
  onRefresh
}: NotificationHeaderProps) => (
  <div className={styles.notificationHeader}>
    <Text tag="h2" field={title} />
    <ul>
      <li>
        <button
          type="button"
          className={styles.notificationHeaderButton}
          onClick={onRefresh}
          aria-label={onRefreshAria?.value}
        >
          <span
            className={`material-icons align-middle icon--size-24 ${
              refreshLoading ? styles.refreshLoading : ''
            }`.trim()}
          >
            sync
          </span>
        </button>
      </li>
      <li>
        <button
          className={styles.notificationHeaderButton}
          onClick={onClose}
          aria-label={onCloseAria?.value}
        >
          <span className="material-icons align-middle icon--size-24">close</span>
        </button>
      </li>
    </ul>
  </div>
);
