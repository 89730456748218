import React, { memo } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import styles from './styles.module.scss';
import HbtDashboardLayoutProps from './types';

const HbtDashboardLayout = (props: HbtDashboardLayoutProps) => (
  <div className={styles.dashboardLayoutContainer}>
    <div>
      <Placeholder name="hbt-dashboard-side-navigation" rendering={props.rendering} />
    </div>
    <div>
      <Placeholder name="hbt-dashboard-main-content" rendering={props.rendering} />
    </div>
  </div>
);

export default memo(HbtDashboardLayout);
