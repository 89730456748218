// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import i18n from 'i18next';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { requestLetterSchema } from '@hobt/claim-domain';
import { Status, Language, LanguageShort } from '@hobt/constants';
import { hbtResolver } from '@hobt/schema-validator';

import { useAuthenticationContext } from 'Foundation/Authentication';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

import { PrimaryButton, SecondaryButton } from 'Components/Common/Button';
import { postalCodeFormat, telephoneFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormDatepicker from 'Components/Inputs/FormDatepicker';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormNumber from 'Components/Inputs/FormNumber';
import FormRadioGroup from 'Components/Inputs/FormRadioGroup';
import FormText, { Formatted as FormattedInput } from 'Components/Inputs/FormText';
import FormTextArea from 'Components/Inputs/FormTextArea';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';

import { getPrefillDetails, updateRefundLetter } from '../RefundManagementService';
import { useRefundLetter } from './RefundLetterContext';
import RequestLetterfields, { PrefillData, RefundDetail, RequestLetter } from './types';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import styles from './styles.module.scss';

const RefundRequestLetter: React.FC<RequestLetterfields> = ({
  fields,
  refundLetterGeneratedToastMessage
}: RequestLetterfields) => {
  const authenticationContext = useAuthenticationContext();
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const userDetails = sitecoreContext && sitecoreContext.user;
  const { uuid, claimData, setLoadingStatus } = useHBTFormContext();
  const { state, dispatch } = useRefundLetter();

  const [prefillData, setPrefillData] = useState<PrefillData | null>(null);

  const refundLetterFormMethods = useForm({
    resolver: hbtResolver(requestLetterSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  } as Record<string, any>);
  const additionalNoteCheck = refundLetterFormMethods.watch('detail.additionalNote');

  // Setup Logic
  useEffect(() => {
    // Hidden Form Component Registration
    refundLetterFormMethods.register('detail.approvedLenderTransitNumber');

    refundLetterFormMethods.setValue(
      'detail.approvedLenderTransitNumber',
      claimData?.approvedLenderTransitNumber
    );

    refundLetterFormMethods.register('sentFlag');
    refundLetterFormMethods.setValue('sentFlag', true);

    // TODO: Why are we setting both created and finalized date to the same date?
    refundLetterFormMethods.register('createdDate');
    refundLetterFormMethods.setValue('createdDate', new Date().toISOString().substring(0, 10));

    refundLetterFormMethods.register('finalizedDate');
    refundLetterFormMethods.setValue(
      'finalizedDate',
      moment(new Date().toLocaleString()).tz('America/Toronto').format('YYYY-MM-DD')
    );

    // TODO: temp mapping untill schema fix
    refundLetterFormMethods.register('languageCode');
    refundLetterFormMethods.setValue('languageCode', Language.English);
  }, []);

  useEffect(() => {
    refundLetterFormMethods.register('reasonCode');
    refundLetterFormMethods.setValue('reasonCode', state.reasonCode);

    refundLetterFormMethods.register('requestedAmount');
    refundLetterFormMethods.setValue('requestedAmount', state.requestedAmount);
  }, [state.reasonCode, state.requestedAmount]);

  useEffect(() => {
    refundLetterFormMethods.setValue(
      'finalizedDate',
      moment(new Date().toLocaleString()).tz('America/Toronto').format('YYYY-MM-DD')
    );
    if (!state.showForm) return;
    // TODO: check if getRefundDetails can be used for prefill
    refundLetterFormMethods.register('detail.approvedLenderName');
    refundLetterFormMethods.register('detail.firstAuthorizedOfficerName');
    refundLetterFormMethods.register('detail.lenderAddressLineOneDescription');
    refundLetterFormMethods.register('detail.lenderAddressLineTwoDescription');
    refundLetterFormMethods.register('detail.lenderAddressMunicipalityName');
    refundLetterFormMethods.register('detail.lenderAddressPostalCode');
    refundLetterFormMethods.register('detail.lenderAddressProvinceName');

    if (prefillData != null) {
      refundLetterFormMethods.setValue('detail', prefillData, { shouldDirty: true });
    } else {
      getPrefillDetails(uuid, authenticationContext).then((prefillDataRes: any) => {
        // Convert the lenderAddressProvinceName value to a string, by default it's taking the type as number.
        if (prefillDataRes && typeof prefillDataRes.lenderAddressProvinceName === 'number') {
          prefillDataRes.lenderAddressProvinceName =
            prefillDataRes.lenderAddressProvinceName.toString();
        }
        prefillDataRes.approvedLenderTransitNumber = claimData?.approvedLenderTransitNumber;
        setPrefillData(prefillDataRes);
        refundLetterFormMethods.setValue('detail', prefillDataRes, { shouldDirty: true });
      });
    }
  }, [state.showForm]);

  useEffect(() => {
    if (additionalNoteCheck === '') {
      refundLetterFormMethods.setValue('detail.additionalNote', undefined);
    }
    if (additionalNoteCheck) {
      if (!additionalNoteCheck.replace(/\s/g, '').length) {
        refundLetterFormMethods.setValue('detail.additionalNote', undefined);
      }
    }
  }, [additionalNoteCheck]);

  const updateRefundDetails = async (data: RequestLetter) => {
    const payload: RefundDetail = {
      receivedAdjustments: state.receivedAdjustments,
      requestLetters: [
        ...state.requestLetters,
        {
          accountsReceivableInvoiceNumber: data.accountsReceivableInvoiceNumber,
          createdDate: data.createdDate,
          detail: data.detail,
          finalizedDate: data.finalizedDate,
          languageCode: data.languageCode,
          reasonCode: data.reasonCode,
          requestedAmount: data.requestedAmount,
          sentFlag: false,
          submitterUserID: `${userDetails.userID}`,
          uploadStatusCode: Status.Uploading,
          requestLetterID: data.requestLetterID
        }
      ]
    };

    // Optionally include `refundID` to change from Create to Update
    if (state.refundID != null) {
      payload.refundID = `${state.refundID}`;
    }

    setLoadingStatus?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description',
      isFlyout: true
    });

    const updatedRefundResponse: RefundDetail | null = await updateRefundLetter(
      uuid,
      authenticationContext,
      payload
    );

    // setState being used instead of dispatch since this state exists in parent component
    setLoadingStatus?.({
      isLoading: false
    });

    if (updatedRefundResponse != null) {
      dispatch({ type: 'setDetails', value: updatedRefundResponse });
      dispatch({ type: 'showForm', value: false });
      dispatch({
        type: 'toast',
        value: {
          isActive: true,
          type: ApplicationStates.SUCCESS,
          title: i18n.t('Globals-Toast-Success-Title'),
          content: refundLetterGeneratedToastMessage.value ?? ''
        }
      });
    } else {
      dispatch({ type: 'showForm', value: false });
      dispatch({
        type: 'toast',
        value: {
          isActive: true,
          type: ApplicationStates.ERROR,
          title: i18n.t('Globals-Toast-Error-Title'),
          content: i18n.t('DefaultSubmission-OtherErrors')
        }
      });
    }
  };

  return (
    <>
      <ToastNotification
        {...state.toast}
        onCloseCallback={() => dispatch({ type: 'toast', value: { isActive: false } })}
      />
      <SideDrawer
        isActive={state.showForm}
        handleOutsideClick={() => {
          dispatch({ type: 'showForm', value: false });
        }}
      >
        <Scrollbars>
          <FormProvider {...refundLetterFormMethods}>
            <form
              id="refundLetterForm"
              onSubmit={refundLetterFormMethods.handleSubmit(
                (data: any) => {
                  updateRefundDetails(data);
                },
                (errors) => {
                  console.log('Refund request letter', errors);
                }
              )}
              noValidate
            >
              <div className={styles.header}>
                <Text tag="h2" field={fields.heading} />
                <button
                  className={`material-icons ${styles.cancelIcon} `}
                  onClick={() => {
                    dispatch({ type: 'showForm', value: false });
                  }}
                  aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
                >
                  close
                </button>
              </div>
              <div className={styles.body}>
                <Text tag="b" field={fields.subheading} />

                <div className={styles.detailRequestedWrapper}>
                  <FormDatepicker
                    className={`${styles.halfLeft} ${styles.refundLetterPaddingTop} ${
                      i18n.language === LanguageShort.French ? styles.pullDown : ''
                    }`.trim()}
                    label={fields.dateRequested}
                    name="finalizedDate"
                  />
                  <FormText
                    className={`${styles.half} ${styles.refundLetterPaddingTop}`}
                    label={fields.invoiceNumber}
                    name="accountsReceivableInvoiceNumber"
                  />
                </div>
                <hr />

                <h3>Lender Details</h3>
                <br />

                <div className="row">
                  <FormText
                    className={styles.halfLeft}
                    label={fields.lenderName}
                    name="detail.approvedLenderName"
                  />
                </div>

                <div className="row">
                  <FormText
                    className={styles.halfLeft}
                    label={fields.authorizedOfficer}
                    name="detail.firstAuthorizedOfficerName"
                  />
                </div>

                <div className="row">
                  <FormText
                    className={styles.full}
                    label={fields.lenderAddress}
                    name="detail.lenderAddressLineOneDescription"
                  />
                </div>

                <div className="row">
                  <FormText
                    className={styles.full}
                    label={fields.lenderAddress2}
                    name="detail.lenderAddressLineTwoDescription"
                  />
                </div>

                <div className="row">
                  <FormText
                    className={styles.halfLeft}
                    label={fields.townCity}
                    name="detail.lenderAddressMunicipalityName"
                  />
                  <FormDropdown
                    className={styles.half}
                    label={fields.province}
                    name="detail.lenderAddressProvinceName"
                    options={fields.provinceList[0].fields.listItems}
                    setAsNumber={false}
                  />
                </div>

                <div className="row">
                  <FormattedInput
                    className={styles.halfLeft}
                    formatProps={postalCodeFormat}
                    label={fields.postalCode}
                    name="detail.lenderAddressPostalCode"
                  />
                </div>

                <hr />

                <h3>{fields.additionalNotesTitle.value}</h3>
                <br />
                <div className="row">
                  <FormTextArea
                    className={styles.full}
                    label={fields.additionalNotes}
                    name="detail.additionalNote"
                    textAreaHelperText={fields.textAreaHelperText.value}
                    charLimit={Number(fields.charLimit.value)}
                  />
                </div>

                <hr />

                <h3>{fields.senderDetailsTitle.value}</h3>
                <br />

                <div className="row">
                  <FormText
                    className={styles.halfLeft}
                    label={fields.userName}
                    name="detail.recordSentUserName"
                  />
                  <FormText
                    className={styles.half}
                    label={fields.userTitle}
                    name="detail.recordSentUserTitleName"
                  />
                </div>

                <div className="row">
                  <FormText
                    className={styles.halfLeft}
                    label={fields.userDepartment}
                    name="detail.recordSentUserDepartmentName"
                  />
                  <FormText
                    className={styles.half}
                    label={fields.userSector}
                    name="detail.recordSentUserSectorName"
                  />
                </div>

                <div className="row">
                  <FormattedInput
                    className={styles.halfLeft}
                    label={fields.userEmail}
                    name="detail.recordSentUserEmailID"
                  />
                  <FormNumber
                    className={styles.half}
                    formatProps={telephoneFormat}
                    label={fields.userTelephone}
                    name="detail.recordSentUserTelephoneNumber"
                  />
                </div>

                <div className="row">
                  <FormNumber
                    className={styles.halfLeft}
                    formatProps={telephoneFormat}
                    label={fields.userFax}
                    name="detail.recordSentUserFaxNumber"
                  />
                </div>

                <div className="row">
                  <FormRadioGroup
                    className={styles['radio-group']}
                    label={fields.language}
                    name="languageCode"
                    radioLabels={[
                      {
                        fields: {
                          listName: {},
                          listItems: [
                            {
                              fields: {
                                itemName: fields.english,
                                itemValue: { value: '1' }
                              }
                            },
                            {
                              fields: {
                                itemName: fields.french,
                                itemValue: { value: '2' }
                              }
                            }
                          ]
                        }
                      }
                    ]}
                  />
                </div>
              </div>

              <hr />
              <div className={`row ${styles.buttonGroup} `}>
                <PrimaryButton
                  data-testid="refund-letter-proceed"
                  className={styles.responsiveButton}
                  type="submit"
                >
                  {fields.proceedButton.value}
                </PrimaryButton>
                <SecondaryButton
                  data-testid="refund-letter-cancel"
                  className={styles.responsiveButton}
                  onClick={() => {
                    dispatch({ type: 'showForm', value: false });
                  }}
                  type="button"
                >
                  {fields.cancelButton.value}
                </SecondaryButton>
              </div>
            </form>
          </FormProvider>
        </Scrollbars>
      </SideDrawer>
    </>
  );
};

export default RefundRequestLetter;
