import { useState, useEffect } from 'react';
import {
  ClaimTypeCode,
  CommentType,
  CorrespondenceType,
  correspondenceSchema
} from '@hobt/claim-domain';
import { HbtServiceErrorCodes, HttpResponseStatusCodes } from '@hobt/constants';
import { hbtResolver } from '@hobt/schema-validator';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { AxiosError, AxiosResponse } from 'axios';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import SitecoreListItem, { SitecoreDropdownList } from 'Constants/Types/SitecoreListItem';
import {
  ExternalTypeOfClaim,
  ExternalTypeOfClaimFr
} from 'Feature/Claims/components/ClaimsInventory/types';
import { DataObject } from 'Feature/Claims/components/Details/types';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { ErrorObject } from 'Feature/UserManagement/models/types';
import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import i18n from 'i18next';
import { UseFormReturn, useForm } from 'react-hook-form';
import { config } from '../../../../../../config';
import { CancelClaimFlyoutData, CancelClaimRequestData } from './types';

const useCancelClaimFunctions = (
  onSuccessCallback?: Function,
  onErrorCallback?: Function,
  onCancelCallback?: (() => void) | undefined,
  requestTemplate?: string,
  reasonsList?: SitecoreDropdownList,
  cancelClaimData?: CancelClaimFlyoutData,
  cancellationNoteToken?: string,
  cancellationReasonToken?: string,
  setIsLoadingCallback?: React.Dispatch<React.SetStateAction<Spinner>>
) => {
  const { uuid, claimData } = useHBTFormContext();

  let claimID = uuid;
  const claimTypeCode = (cancelClaimData?.claimTypeCode ??
    claimData?.detail?.claimTypeCode) as ClaimTypeCode;
  const claimTypeCodeText =
    i18n.language == 'en'
      ? ExternalTypeOfClaim[claimTypeCode]
      : ExternalTypeOfClaimFr[claimTypeCode];
  const cmhcLoanAccountNumber =
    cancelClaimData?.cmhcLoanAccountNumber ?? (claimData?.cmhcLoanAccountNumber?.toString() || '');

  const initRequestTemplate =
    requestTemplate
      ?.replace('[Type of Claim]', claimTypeCodeText)
      .replace('[CMHC Loan #]', cmhcLoanAccountNumber) || '';

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const userDetails = sitecoreContext?.user;

  const [showValidationError, setShowValidationError] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');
  const [displayedRequestTemplate, setDisplayedRequestTemplate] =
    useState<string>(initRequestTemplate);

  const hookForm = useForm({
    resolver: hbtResolver(correspondenceSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  } as Record<string, any>);

  useEffect(() => {
    hookForm.register('submitterUserID');
    hookForm.register('submitterUserTypeCode');
    hookForm.register('correspondenceTypeCode');
    hookForm.register('commentTypeCode');

    hookForm.setValue('commentTypeCode', CommentType.Cancel);
    hookForm.setValue('submitterUserID', userDetails?.userID);
    hookForm.setValue('submitterUserTypeCode', userDetails?.userTypeCode);
    hookForm.setValue('correspondenceTypeCode', CorrespondenceType.External);
  }, []);

  // API Client Config
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  const authContext = useAuthenticationContext();
  const { postWithAuth } = ApiClient(authContext, apiClientConfig);

  const watchNotes = hookForm.watch('remarkText');
  const watchCancelReason = hookForm.watch('cancellationReasonCode');

  const handleSubmit: any = (data: CancelClaimRequestData) => {
    if (claimID == null || claimID === '') {
      claimID = cancelClaimData?.claimId ?? '';
    }

    const payloadData = {
      claimID,
      cancelPayload: {
        commentTypeCode: CommentType.Cancel,
        correspondenceTypeCode: CorrespondenceType.External,
        cancellationReasonCode: data.cancellationReasonCode,
        submitterUserID: userDetails?.userID,
        submitterUserTypeCode: userDetails?.userTypeCode,
        attachments: data.attachments,
        remarkText: data?.remarkText
      }
    };

    const url = config.claimApi.urls.cancelClaim;

    setIsLoadingCallback?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description',
      isFlyout: true
    });

    postWithAuth(url, payloadData)
      .then((responseData: AxiosResponse<DataObject>) => {
        if (onSuccessCallback) {
          onSuccessCallback(responseData?.data?.data);
        }

        setIsLoadingCallback?.({
          isLoading: false
        });
      })
      .catch((error: ErrorObject) => {
        if (
          error.response?.status === HttpResponseStatusCodes.BadRequest &&
          error.response?.data?.error?.errorCode === HbtServiceErrorCodes.HBT_ERR_VALIDATION.code
        ) {
          setShowValidationError(true);
        } else if (onErrorCallback != null) {
          onErrorCallback();
        }

        setIsLoadingCallback?.({
          isLoading: false
        });
      });
  };

  const onCancelReasonChange = () => {
    let newRequestTemplate = initRequestTemplate;
    const cancelReason = reasonsList?.fields?.listItems?.find(
      (item: SitecoreListItem) => item?.fields.itemValue?.value === watchCancelReason
    )?.fields.itemName?.value;
    if (cancelReason != null && cancelReason.trim() !== '' && cancellationReasonToken) {
      newRequestTemplate = newRequestTemplate.replace(cancellationReasonToken, cancelReason);
    }
    if (notes != null && notes.trim() !== '' && cancellationNoteToken) {
      newRequestTemplate = newRequestTemplate.replace(cancellationNoteToken, notes);
    }
    setDisplayedRequestTemplate(newRequestTemplate);
  };

  const onNotesChange = (updateNotes: string) => {
    setNotes(updateNotes);
    let newRequestTemplate = initRequestTemplate;
    const cancelReason = reasonsList?.fields?.listItems?.find(
      (item: SitecoreListItem) => item?.fields.itemValue?.value === watchCancelReason
    )?.fields.itemName?.value;
    if (cancelReason != null && cancelReason.trim() !== '' && cancellationReasonToken) {
      newRequestTemplate = newRequestTemplate.replace(cancellationReasonToken, cancelReason);
    }
    if (updateNotes != null && updateNotes.trim() !== '' && cancellationNoteToken) {
      newRequestTemplate = newRequestTemplate.replace(cancellationNoteToken, updateNotes);
    }
    setDisplayedRequestTemplate(newRequestTemplate);
  };

  const onSubmitErrors: any = (errs: Record<string, Object>) => {
    // TODO: Remove once validations are in place
    // eslint-disable-next-line no-console
    console.log('FORM ERRORS: ', errs);
  };

  const onCloseValidationErrors = () => {
    setShowValidationError(false);
  };

  const cancelFlyout = () => {
    hookForm.reset();
    if (onCancelCallback != null) {
      onCancelCallback();
    }
  };

  return {
    handleSubmit,
    onSubmitErrors,
    cancelFlyout,
    onCloseValidationErrors,
    onCancelReasonChange,
    onCancelCallback,
    showValidationError,
    displayedRequestTemplate,
    hookForm,
    onNotesChange,
    watchNotes,
    watchCancelReason
  };
};

export default useCancelClaimFunctions;
