import * as React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import styles from './styles.module.scss';

export const NotificationButton: React.FC<{
  text: FieldValue;
  hasNewItems: boolean;
  label: FieldValue;
  newItemText: FieldValue;
  onClick?: () => void;
}> = ({ text, hasNewItems, label, newItemText, onClick }) => (
  <button
    className={styles.notificationButton}
    onClick={onClick}
    aria-label={hasNewItems ? `${newItemText.value} - ${label.value}` : label.value}
  >
    <span className={`${styles.notificationIcon} ${hasNewItems ? styles.hasNew : ''}`.trim()}>
      <span aria-hidden={true} className="material-icons-outlined icon-24">
        notifications
      </span>
    </span>
    <Text tag="p" field={text} />
  </button>
);
