import React, { memo, useEffect, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import { LinkFieldValue } from '@sitecore-jss/sitecore-jss-manifest';
import { Image, LinkField, Text } from '@sitecore-jss/sitecore-jss-react';
import { pathNames } from 'Constants/pathNames';
import i18n from 'i18next';
import { HbtFooterNavLink } from './HbtFooterNavLink';
import styles from './styles.module.scss';
import { HbtFooterProps, ItemWrapper } from './types';

const HbtFooterComponent = ({ fields }: HbtFooterProps) => {
  const [isFixedFooterRequired, setIsFixedFooterRequired] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsFixedFooterRequired(window.location.pathname === `${pathNames.landing}${i18n.language}`);
    }
  }, []);

  return (
    <div
      className={`${styles.footerWrapper} ${
        isFixedFooterRequired ? styles.fixedFooterPosition : ''
      }`}
      data-testid="footer"
    >
      <footer aria-label="Footer" className={styles.footer} id="footer">
        <div className={styles.footerTextLinkContainer}>
          <div>
            {fields.footerText ? (
              <span className={styles.footerText} id="footer-text">
                <Text field={fields.footerText} />
              </span>
            ) : (
              <Skeleton
                sx={{
                  display: 'flex',
                  height: '24px',
                  width: '110px',
                  borderRadius: '4px',
                  backgroundColor: '#F0F1F3',
                  color: '#F0F1F3'
                }}
                animation={false}
              />
            )}
          </div>
          {fields.footerLinks && (
            <nav className={styles.footerLinks}>
              <ul>
                {fields.footerLinks.map((footerLink: ItemWrapper, index) => (
                  <li key={`footer-nav-item-${index}`}>
                    <HbtFooterNavLink
                      footerNavLink={footerLink.fields.navItem as LinkField | LinkFieldValue}
                    />
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </div>
        <Box className={styles.logoContainer} id="footer-logo">
          <Image field={fields.canadaLogo} />
        </Box>
      </footer>
    </div>
  );
};

export default memo(HbtFooterComponent);
