import { ImageProps } from '@sitecore-jss/sitecore-jss-react/types/components/Image';

export interface LoginPageProps {
  fields: LoginPageFields;
}

export interface LoginPageFields {
  title: FieldValue;
  subTitle: FieldValue;
  loginButtonText: FieldValue;
  loginHelpText: FieldValue;
  loginHelpDescription: FieldValue;
  loginMainBgImageLg?: ImageProps['field'];
  loginOverlayImageLg?: ImageProps['field'];
  loginMainBgImageMd?: ImageProps['field'];
  loginOverlayImageMd?: ImageProps['field'];
  loginCmhcNotchLg?: ImageProps['field'];
  loginCmhcNotchMd?: ImageProps['field'];
  companyLogo?: ImageProps['field'];
  loginButtonLoadingIcon?: ImageProps['field'];
  loginImage?: ImageItem;
  skipContentLabel?: FieldValue;
}
export interface ImageItem {
  value: {
    src: string;
    alt: string;
  };
}

export enum Language {
  EN = 'en',
  FR = 'fr'
}

export type StyleProps = Record<string, unknown>;
