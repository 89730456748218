import React from 'react';
import i18n from 'i18next';
import { UserRole, Module, LanguageShort } from '@hobt/constants';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { PageCardContainer } from 'Components/PageComponents/PageCardContainer';
import { Tabs } from 'Components/Navigation/Tabs';
import { CardHeader } from 'Components/Card/CardHeader';
import { SearchBar } from 'Components/PageComponents/SearchBar';
import { SearchFieldOptions } from 'Components/PageComponents/SearchBar/types';
import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { DataTableFooter } from 'Components/Common/DataTableFooter';
import { Button } from 'Components/Common/Button';
import { useDataGridEngine } from 'Components/Hooks/DataGridEngine';
import { handleEmpty } from 'Components/Common/Api/utils/HandleEmpty';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';
import useArrearSummaryReportFunctions from './useArrearsSummaryReportFunctions';
import ArrearsSummaryReportProps, {
  ArrearsSummaryReportInventoryRecords,
  ArrearsSummaryReportInventoryRecordsItems
} from './types';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { config } from '../../../../config';
import styles from './styles.module.scss';

const ArrearsSummaryReport = ({ fields }: ArrearsSummaryReportProps) => {
  const { toCurrency } = useTranslationHelpers();

  const {
    items,
    isTab,
    isInventoryToast,
    isSummaryReportToast,
    isSummaryReportNotReady,
    tabQueryFilterLookup,
    onTabClick,
    onDownloadHandler,
    onApiErrorCallback,
    onToastCloseHandler
  } = useArrearSummaryReportFunctions(fields);

  const {
    rowData,
    currentPage,
    itemsPerPage,
    onItemsPerPageChange,
    totalItems,
    totalPages,
    itemRangeFrom,
    itemRangeTo,
    isLoading: isInventoryLoading,
    onPagerClick,
    onSort,
    getSortDirectionForField,
    onSearch,
    onSearchReset
  } = useDataGridEngine<ArrearsSummaryReportInventoryRecords>({
    apiBaseUrl: config.arrearsApi.urls.summaryReports,
    currentTab: isTab,
    onApiErrorCallback,
    defaultItemsPerPage: 10,
    tabFilterQueryLookup: tabQueryFilterLookup
  });

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const isReadOnlyUser =
    sitecoreContext?.user?.moduleRoleMapping[Module.Arrears][0] === UserRole.ReadOnly;

  const searchByFields: SearchFieldOptions[] = [
    {
      displayText: fields.lenderName.value || '',
      value: 'ApprovedLenderName',
      type: 'text'
    }
  ];

  return (
    <>
      <ToastNotification
        isActive={isInventoryToast}
        type={ApplicationStates.ERROR}
        title={i18n.t('Globals-Toast-Server-Error-Title')}
        content={{
          value: i18n.t('DefaultsInventoryTable-SystemErrorToast-Message')
        }}
        onCloseCallback={onToastCloseHandler}
      />
      <ToastNotification
        isActive={isSummaryReportToast}
        type={ApplicationStates.ERROR}
        title={i18n.t('Globals-Toast-Error-Title')}
        content={{
          value: i18n.t('Globals-Toast-Server-Error-Title')
        }}
        onCloseCallback={onToastCloseHandler}
      />
      <ToastNotification
        isActive={isSummaryReportNotReady}
        type={ApplicationStates.ERROR}
        title={fields.summaryReportNotAvailableTitle?.value}
        content={fields.summaryReportNotAvailableMessage?.value}
        onCloseCallback={onToastCloseHandler}
      />
      <PageCardContainer name="arrearsReportInventory">
        <CardHeader>
          <div className={styles.cardHeader}>
            <div>
              <Text tag="h2" field={fields.heading} />
            </div>
            <div className={styles.cardHeaderItem}>
              <Button
                name="downloadReport"
                text={fields.downloadBtn}
                ariaText={fields.downloadBtn}
                type="button"
                onClick={onDownloadHandler}
                responsive
                disabled={isReadOnlyUser}
              />
            </div>
          </div>
        </CardHeader>
        <Tabs
          items={items}
          initialActiveTabName={isTab.name}
          tabOnClickCallback={onTabClick}
          ariaControlsName="arrearsSummaryReportTable"
          label={fields.reportTypeLabel}
          className={styles.summaryReportTabs}
        />

        <div className={styles.tableToolsWrapper}>
          <div className={styles.searchBarWrapper}>
            {/* TODO: Replace with sitecore Fields */}
            <SearchBar
              name="arrearsSummaryReportSearchBar"
              fieldLabelAriaText={i18n.t('SearchBar-SearchField')}
              clearButtonAriaText={i18n.t('SearchBar-ClearButton')}
              searchButtonAriaText={i18n.t('SearchBar-SearchButton')}
              placeholderText={i18n.t('SearchBar-PlaceholderText')}
              searchFieldOptions={searchByFields}
              onSearch={onSearch}
              onClear={onSearchReset}
              count={totalItems}
            />
          </div>
        </div>

        <div className={styles.dataTable}>
          <DataTable
            name="arrearsSummaryReport"
            isLoading={isInventoryLoading}
            caption={{ value: 'TestCaption' }}
            scrollOnHorizontalOverflow
          >
            <DataTableHeader>
              <DataTableHeaderItem name="lenderName" displayText={fields.lenderName} />
              <DataTableHeaderItem name="location" displayText={fields.location} />
              <DataTableHeaderItem
                sortable
                onSortCallback={onSort}
                sortDirection={getSortDirectionForField('numberOfCurrentRecords')}
                name="numberOfCurrentRecords"
                displayText={{
                  value: rowData != null && handleEmpty(rowData[0]?.currentReportingPeriod)
                }}
              />
              <DataTableHeaderItem
                sortable
                onSortCallback={onSort}
                sortDirection={getSortDirectionForField('currentTotalArrearsAmount')}
                name="currentTotalArrearsAmount"
                displayText={{
                  value: fields.totalReported.value ?? ''
                }}
              />
              <DataTableHeaderItem
                sortable
                onSortCallback={onSort}
                sortDirection={getSortDirectionForField('numberOfPreviousRecords')}
                name="numberOfPreviousRecords"
                displayText={{
                  value: rowData != null && handleEmpty(rowData[0]?.previousReportingPeriod)
                }}
              />
              <DataTableHeaderItem
                sortable
                onSortCallback={onSort}
                sortDirection={getSortDirectionForField('previousTotalArrearsAmount')}
                name="previousTotalArrearsAmount"
                displayText={{
                  value: fields.totalReported.value ?? ''
                }}
              />
              <DataTableHeaderItem
                sortable
                onSortCallback={onSort}
                sortDirection={getSortDirectionForField('recordNumberChange')}
                name="recordNumberChange"
                displayText={fields.changeInRecords}
              />
            </DataTableHeader>

            <DataTableBody zebra>
              {rowData &&
                rowData?.[0]?.records?.map(
                  (row: ArrearsSummaryReportInventoryRecordsItems, idx: number) => (
                    <DataTableRow
                      name={handleEmpty(row.approvedLenderEnglishName)}
                      key={`${row.approvedLenderEnglishName}-${idx}`}
                    >
                      <TextCell
                        name="lenderName"
                        text={handleEmpty(
                          i18n.language === LanguageShort.English
                            ? row.approvedLenderEnglishName
                            : row.approvedLenderFrenchName
                        )}
                      />
                      <TextCell name="location" text={handleEmpty(row.locationName)} />
                      <TextCell
                        name="numberOfCurrentRecords"
                        text={handleEmpty(row.numberOfCurrentRecords)}
                      />
                      <TextCell
                        name="currentTotalArrearsAmount"
                        text={toCurrency(row.currentTotalArrearsAmount, false, 'Not Reported')}
                      />
                      <TextCell
                        name="numberOfPreviousRecords"
                        text={handleEmpty(row.numberOfPreviousRecords)}
                      />
                      <TextCell
                        name="previousTotalArrearsAmount"
                        text={toCurrency(row.previousTotalArrearsAmount)}
                      />
                      <TextCell
                        name="recordNumberChange"
                        text={handleEmpty(row.recordNumberChange)}
                      />
                    </DataTableRow>
                  )
                )}
              {rowData?.[0]?.totals != null && (
                <DataTableRow name="arrearsSummaryReportSubtotals" subtotal>
                  <TextCell name="arrearsSummaryReportSubtotal" text="Total" />
                  <TextCell name="subTotalEmptyCell" text={''} />
                  <TextCell
                    name="subTotalcurrentRecordsReported"
                    text={handleEmpty(rowData?.[0]?.totals?.currentRecordsReportedTotal)}
                  />
                  <TextCell
                    name="subTotalcurrentTotalReported"
                    text={toCurrency(rowData?.[0]?.totals?.currentAmountReportedTotal)}
                  />
                  <TextCell
                    name="subTotalpreviousRecordsReported2"
                    text={handleEmpty(rowData?.[0]?.totals?.previousRecordsReportedTotal)}
                  />
                  <TextCell
                    name="subTotalpreviousTotalReported"
                    text={toCurrency(rowData?.[0]?.totals?.previousAmountReportedTotal)}
                  />
                  <TextCell
                    name="subTotalchangeInRecords"
                    text={handleEmpty(rowData?.[0]?.totals?.recordsReportedChangeTotal)}
                  />
                </DataTableRow>
              )}
            </DataTableBody>
          </DataTable>
        </div>

        <div className={styles.dataTableFooter}>
          <DataTableFooter
            name="arrearsSummaryReportList"
            currentPage={currentPage}
            totalPages={totalPages || 1}
            itemsPerPageLabel={{
              value: i18n.t('DefaultsInventoryTable-ResultsPerPageDropdown-Title')
            }}
            itemsXofYText={`${itemRangeFrom || 0} - ${itemRangeTo || 0} ${i18n.t(
              'Globals-Grid-ResultsPerPageDropdown-Of'
            )}`}
            totalItems={totalItems}
            pagerClickCallback={onPagerClick}
            itemsPerPageOnChangeCallback={onItemsPerPageChange}
            itemsPerPageValue={itemsPerPage.toString()}
          />
        </div>
      </PageCardContainer>
    </>
  );
};

export default ArrearsSummaryReport;
