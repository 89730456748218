/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Select, { ActionMeta, Options, components, OptionProps } from 'react-select';
import { useFormContext } from 'react-hook-form';
import i18n from 'i18next';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import { Formatted as FormattedInput } from 'Components/Inputs/FormText';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormInputWrapper from 'Components/Inputs/FormInputWrapper';
import NewUserDetailsProps from 'Feature/UserManagement/models/NewUserDetailsProps';
import { UserAccessType } from 'Feature/UserManagement/models/types';
import { useModifyUserContext } from '../../ModifyUser/ModifyUserContext';
import {
  DropdownOptionType,
  DropdownOptionFields,
  FiTransitMapping,
  ModuleFiTransitMapping
} from './types';
import { transitStyles } from './selectTransitStyles';
import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const NewUserDetails: React.FC<NewUserDetailsProps> = ({
  fields,
  isModify = false
}: NewUserDetailsProps) => {
  const {
    register,
    setValue,
    trigger,
    watch,
    getValues,
    formState: { isDirty }
  } = useFormContext();
  const { userData } = useModifyUserContext();

  const [fiCodes, setFiCodes] = useState<Options<DropdownOptionType>>([]);
  const [transitNums, setTransitNums] = useState<Options<DropdownOptionType>>([]);
  const [multiSelectTransitOptions, setMultiSelectTransitOptions] = useState<DropdownOptionType[]>(
    []
  );

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const userDetails = sitecoreContext && sitecoreContext.user;
  const defaultTransitNumber = getValues('userAccesses[0].defaultTransitNumber');

  const selectedFiCodes = watch('userAccesses[0].financialInstitutionCodes');
  const selectedDefaultFiCode = watch('userAccesses[0].defaultFinancialInstitutionCode');

  // get FI transit mapping from the modulefitransitmapping data in user details
  const fiTransitMapping: FiTransitMapping = Object.values(
    (userDetails?.moduleFiTransitMapping || []) as unknown as ModuleFiTransitMapping
  ).reduce(
    (total: FiTransitMapping, obj: FiTransitMapping) => ({
      ...total,
      ...obj
    }),
    {}
  );

  const multiSelectFiOptions: DropdownOptionType[] = Object.keys(fiTransitMapping).map(
    (fiCode: string) => ({ label: fiCode, value: fiCode })
  );

  const dropdownFiOptions: DropdownOptionFields[] = Object.keys(fiTransitMapping).map(
    (fiCode: string) => ({
      fields: {
        itemName: { value: fiCode },
        itemValue: { value: fiCode }
      }
    })
  );

  const dropdownTransitOptions:SitecoreListItem[] = fiTransitMapping[selectedDefaultFiCode]?.allowedTransitNumbers.map((transitNum: string) => ({
    fields: {
      itemName: { value: transitNum },
      itemValue: { value: transitNum }
    }
  }))

  const CustomOption = (innerProps: OptionProps<DropdownOptionType, true>) => {
    return (
      <components.Option
        {...innerProps}
        className={innerProps.isFocused ? styles.optionSelected : styles.optionUnselected}
      >
        <div {...innerProps} ref={innerProps.innerRef}>
          {innerProps.label}
        </div>
      </components.Option>
    );
  };

  useEffect(() => {
    register('userAccesses[0].defaultTransitNumber');
    register('userAccesses[0].financialInstitutionCodes');
    register('userAccesses[0].defaultFinancialInstitutionCode');
  }, []);

  const getTransitsFromSelectedFis = (selectedFis: string[]) => {
    return selectedFis
      .reduce(
        (total: string[], fiCode: string) =>
          total.concat(fiTransitMapping[fiCode].allowedTransitNumbers),
        []
      )
      .filter(
        (transit: string, index: Number, allowedTransits: string[]) =>
          allowedTransits.indexOf(transit) === index
      );
  };

  useEffect(() => {
    if (selectedFiCodes !== undefined) {
      const newAllowedTransits: string[] = getTransitsFromSelectedFis(selectedFiCodes);
      
      // update the selected transit numbers to remove all the selected transit numbers associated with the removed fi code
      let selectedTransits = watch('userAccesses[0].allowedTransitNumbers');

      if (selectedTransits !== undefined) {
        selectedTransits = selectedTransits.filter((transitNum: string) =>
          newAllowedTransits.includes(transitNum)
        );

        setValue('userAccesses[0].allowedTransitNumbers', selectedTransits);
        setTransitNums(
          selectedTransits.map((transitNum: string) => ({ label: transitNum, value: transitNum }))
        );
      }

      // update transit number options displayed based on fi code
      setMultiSelectTransitOptions(
        newAllowedTransits?.map((transitNum: string) => ({ label: transitNum, value: transitNum }))
      );
    }
  }, [selectedFiCodes]);

  useEffect(() => {
    // update transit number field selected values to be removed on change of default fi code
    if (isDirty === true) {
      setValue('userAccesses[0].defaultTransitNumber', '');
    }
  }, [selectedDefaultFiCode]);

  const onSelectValue = (
    list: Options<DropdownOptionType>,
    action: ActionMeta<DropdownOptionType>
  ) => {
    const filtered: string[] = [];
    list.filter((el: DropdownOptionType) => {
      filtered.push(Object.values(el)[0]);
      return null;
    });

    if (action.name === 'fiCodes') {
      setFiCodes(list);
    } else {
      setTransitNums(list);
    }

    setValue(
      `userAccesses[0].${
        action.name === 'fiCodes' ? 'financialInstitutionCodes' : 'allowedTransitNumbers'
      }`,
      filtered
    );
  };

  useEffect(() => {
    if (userData !== undefined && userData.length > 0) {
      const selectedFis: string[] = userData.map(
        (userAccess: UserAccessType) => userAccess.financialInstitutionCode
      );
      const selectedTransits: string[] = userData[0].allowedTransitNumbers ?? [];
      
      // update transit number options displayed based on fi code
      const newAllowedTransits: string[] = getTransitsFromSelectedFis(selectedFis);

      setMultiSelectTransitOptions(
        newAllowedTransits?.map((transitNum: string) => ({ label: transitNum, value: transitNum }))
      );

      // intiialize state
      setFiCodes(
        selectedFis.map((code: string) => ({
          label: code,
          value: code
        }))
      );

      setTransitNums(
        selectedTransits.map((transit: string) => ({
          label: transit,
          value: transit
        }))
      );
      setValue('userAccesses[0].defaultTransitNumber', defaultTransitNumber);
    }
  }, [userData]);

  useEffect(() => {
    (async () => {
      await trigger('userAccesses[0].allowedTransitNumbers');
    })();
  }, [watch('userAccesses[0].allowedTransitNumbers')]);

  useEffect(() => {
    (async () => {
      await trigger('userAccesses[0].financialInstitutionCodes');
    })();
  }, [watch('userAccesses[0].financialInstitutionCodes')]);

  return (
    <div>
      <h2 className="card-title">
        <Text field={fields.sectionTitle} />
      </h2>
      <p>
        <Text field={fields.sectionSubtitle} />
      </p>

      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormattedInput
            className={styles.emailIDReadOnly}
            name="emailID"
            label={fields.userIDLabel}
            isReadOnly={isModify}
          />
        </div>
        {isServer() === false && (
          <div className="form__element form__element--2-column col-6">
            <span className={styles.inputColor}>
              <FormInputWrapper
                name="userAccesses[0].financialInstitutionCodes"
                label={fields.fiCodeLabel}
              >
                <Select
                  id="NewUserDetails_FICodeLabel"
                  isMulti={true}
                  name="fiCodes"
                  value={fiCodes}
                  isClearable={true}
                  options={multiSelectFiOptions}
                  styles={transitStyles}
                  onChange={onSelectValue}
                  components={{ Option: CustomOption }}
                  openMenuOnFocus={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  placeholder={i18n.t('Dropdown-Option-PleaseSelect')}
                  noOptionsMessage={() => fields.noFiCodesAvailable.value ?? ''}
                />
              </FormInputWrapper>
            </span>
          </div>
        )}
      </div>

      <div className="row card__body-row">
        {isServer() === false && (
          <div className="form__element form__element--2-column col-6">
            <span className={styles.inputColor}>
              <FormInputWrapper
                name="userAccesses[0].allowedTransitNumbers"
                label={fields.allowedNumbersLabel}
              >
                <Select
                  isMulti={true}
                  name="transitNums"
                  isClearable={true}
                  value={transitNums}
                  styles={transitStyles}
                  onChange={onSelectValue}
                  components={{ Option: CustomOption }}
                  openMenuOnFocus={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  options={multiSelectTransitOptions}
                  placeholder={i18n.t('Dropdown-Option-PleaseSelect')}
                  noOptionsMessage={() => fields.noTransitsAvailable.value ?? ''}
                />
              </FormInputWrapper>
            </span>
          </div>
        )}
        <div className="form__element form__element--2-column col-6">
          <FormDropdown
            setAsNumber={false}
            options={dropdownFiOptions}
            label={fields.defaultFiNewAppLabel}
            name="userAccesses[0].defaultFinancialInstitutionCode"
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormDropdown
            setAsNumber={false}
            options={dropdownTransitOptions}
            label={fields.defaultTransitNewAppLabel}
            name="userAccesses[0].defaultTransitNumber"
          />
        </div>
      </div>

      <hr />
    </div>
  );
};

export default NewUserDetails;
