/* eslint-disable */
import React, { useEffect, useState, useMemo, useReducer } from 'react';
import { Element } from 'react-scroll';
import { Prompt, useHistory } from 'react-router-dom';
import i18n from 'i18next';
import { useFeature } from 'flagged';
import { FormProvider, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { AccountStatus } from '@hobt/constants';
import { frontEndAddUserSchema } from '@hobt/form-schema-validators';

import { Card } from 'Components/Card';
import { Button } from 'Components/Common/Button';
import { CardBody } from 'Components/Card/CardBody';
import { CardHeader } from 'Components/Card/CardHeader';
import { CardFooter } from 'Components/Card/CardFooter';
import { Modal } from 'Components/Common/Modal';

import FormToggle from 'Components/Inputs/FormToggle';
import { LoaderAnimation } from 'Feature/CommonComponents/UserControls';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';
import { modifyUserReducer } from 'Feature/UserManagement/components/ModifyUser/modifyUserReducer';
import { ModifyUserReducerState } from 'Feature/UserManagement/components/ModifyUser/types';
import { useUserApiFunctions } from 'Feature/UserManagement/components/ModifyUser/useUserApiFunctions';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import ModifyUserProps from 'Feature/UserManagement/models/ModifyUserProps';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { reInviteUserRequest, reSyncUserRequest } from '../api/UserApiHandler.service';
import { UserApiResponse } from 'Feature/UserManagement/models/types';
import ModifyUserContextProvider from './ModifyUserContext';
import { useModifyUserFunctions } from './useModifyUserFunctions';

import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

// Ignoring next block as it is used to init reducer state
/* istanbul ignore next */
const initialReducerState: ModifyUserReducerState = {
  isBypass: false,
  modalActive: false,
  isLoading: false,
  isModalLoading: false,
  isPageLoading: false,
  toastProps: {
    isActive: false,
    type: ApplicationStates.DEFAULT,
    title: '',
    content: '',
    onCloseCallback: () => true
  },
  updateModalActive: false
};

const ModifyUser = ({ fields, rendering }: ModifyUserProps) => {
  const isServerSSR = isServer();
  const userId = useMemo(() => {
    const urlParams = new URLSearchParams(
      !isServerSSR ? window.location.search : ''
    );
    return urlParams.get('id') ?? undefined;
  }, []);

  const isInternal = useFeature(FeatureFlags.INTERNAL);
  const authenticationContext = useAuthenticationContext();
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext =
    sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const { push } = useHistory();

  const methods = useForm({
    resolver: joiResolver(frontEndAddUserSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const [toastState, setToastState] = useState<ApplicationStates>(ApplicationStates.DEFAULT);
  const [showResyncToast, setShowResyncToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string | FieldValue>('');
  const [showToast, setShowToast] = useState<boolean>(false);
  const onUserToastCloseHandler = () => setShowResyncToast(false);

  const [state, dispatch] = useReducer(modifyUserReducer, initialReducerState);
  const [showUserActive, setShowUserActive] = useState<boolean>(true);

  const {
    isLoading,
    isPageLoading,
    isModalLoading,
    modalActive,
    isBypass,
    toastProps,
    updateModalActive
  } = state;

  const { userData, onUserDelete, onSubmit, onError, onUserUpdate } = useUserApiFunctions(
    dispatch,
    push,
    authenticationContext,
    userId,
    sitecoreContext
  );

  const {
    isReadOnlyUser,
    sectionsLookup,
    isEditingOwnProfile,
    initializeFormValues,
    onDismissModal,
    onDeleteAccountButtonClick,
    onCancel,
    onDismissUpdateModal
  } = useModifyUserFunctions(
    {
      reset: methods.reset,
      setValue: methods.setValue,
      register: methods.register
    },
    isInternal as boolean,
    dispatch,
    push,
    userData,
    sitecoreContext
  );

  const additionalProps: Record<string, any> = {
    UserPermissions: { isModify: true },
    LevelAccessInternal: { isModify: true, userData },
    UserDetailsInternal: { isModify: true },
    NewUserDetails: { isModify: true },
  };

  const { dirtyFields } = methods.formState;

  useEffect(() => {
    if (isServer() === false) {
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, []);

  function unRegisterUserAccessesFieldsNotInSchema() {
    if (userData && userData.userAccesses) {
      userData.userAccesses.forEach((userAccess, index) => {
        const fieldsToUnregister = [
          `userAccesses[${index}].access.arrears.roleCode`,
          `userAccesses[${index}].access.claim.roleCode`,
          `userAccesses[${index}].access.default.roleCode`,
          `userAccesses[${index}].financialInstitutionCode`,
          `userAccesses[${index}].recordCreatedTimestamp`,
          `userAccesses[${index}].recordCreatedUserID`,
          `userAccesses[${index}].recordUpdatedTimestamp`,
          `userAccesses[${index}].recordUpdatedUserID`,
          `userAccesses[${index}].userAccessID`,
          `userAccesses[${index}].userAuthorizationScope`,
          `userAccesses[${index}].userID`,
        ];

        fieldsToUnregister.forEach((field) => {
          methods.unregister(field);
        });
      });
    }
  }

  const fieldsToUnregister = [
    'adOID',
    'adContact',
    'operation',
    'recordCreatedTimestamp',
    'recordCreatedUserID',
    'recordUpdatedBy',
    'recordUpdatedTimestamp',
    'recordUpdatedUserID',
    'internalContact.employeeID',
  ];

  function unRegisterFieldsNotInSchema() {
    fieldsToUnregister.forEach((field) => {
      methods.unregister(field);
    });
  }

  useEffect(() => {
    unRegisterFieldsNotInSchema();
    unRegisterUserAccessesFieldsNotInSchema();
  }, [dirtyFields]);

  const onToggleShowUserActive = (showUserActive: any) => {
    setShowUserActive(!showUserActive);
    showUserActive && onDeleteAccountButtonClick();
  };

  useEffect(() => {
    methods.setValue('userAccesses[0].statusCode', AccountStatus.ACTIVE);
  }, [showUserActive]);

  useEffect(() => {
    if (userData?.userAccesses?.[0]?.statusCode == 2) {
      setShowUserActive(false);
    }
  }, [userData]);

  // isDirty check
  useEffect(() => {
    // onbeforeunload should trigger on back, forward, refresh, and link button clicks
    if (!isServerSSR) {
      window.onbeforeunload = () => {
        // trigger browser warning
        // returning anything, will trigger the prompt,
        // return string only read on IE:
        return Object.keys(dirtyFields).length > 0 && !isBypass
          ? i18n.t('DefaultSubmission-IsDirty')
          : null;
      };
    }
  }, [dirtyFields, isBypass]);

  useEffect(initializeFormValues, [userData]);

  if (isPageLoading) {
    return <LoaderAnimation />;
  }

  const onReSyncUser = async () => {
    const user = [methods.getValues('emailID')];
    const result = await reSyncUserRequest(authenticationContext, user);
    if (result === UserApiResponse.Success) {
      setShowResyncToast(true);
      setToastMsg(i18n.t('UserManagement-Users-ResyncSuccessToast'));
      setToastState(ApplicationStates.SUCCESS);
    } else {
      setShowResyncToast(true);
      setToastMsg(i18n.t('UserManagement-Users-ResyncErrorToast'));
      setToastState(ApplicationStates.ERROR);
    }
  };

  const onReinviteUser = async () => {
    const user = {
      emailID: methods.getValues('emailID')
    };
    const result = await reInviteUserRequest(authenticationContext, user);
    if (result === UserApiResponse.Success) {
      setShowToast(true);
      setToastMsg(i18n.t('UserManagement-Users-ReInviteSuccessToast'));
      setToastState(ApplicationStates.SUCCESS);
    } else {
      setShowToast(true);
      setToastMsg(i18n.t('UserManagement-Users-ReInviteErrorToast'));
      setToastState(ApplicationStates.ERROR);
    }
  };

  const onToastCloseHandler = () => setShowToast(false);

  return (
    <>
      <ContentLoadingModal
        display={isLoading}
        fields={{
          heading: { value: i18n.t('Globals-Saving-Heading') },
          description: { value: i18n.t('Globals-Saving-Description') },
        }}
      />
      <ToastNotification
        type={toastState}
        isActive={showToast}
        title={
          toastState === ApplicationStates.ERROR
            ? i18n.t('DefaultSubmission-ErrorToastTitle')
            : i18n.t('DefaultActions-SystemSuccessToast-Action-Title')
        }
        content={toastMsg}
        onCloseCallback={() => onToastCloseHandler()}
      />
      <Card>
        <CardHeader separator>
          <Text
            tag='h2'
            field={{
              value: fields.pageTitle?.value?.replace(
                '{username}',
                `${userData?.contact?.firstName} ${userData?.contact?.lastName}`
              ),
            }}
          />
        </CardHeader>

        <ModifyUserContextProvider
          value={{
            userData: userData?.userAccesses,
          }}
        >
          <FormProvider {...methods}>
            <form
              id='accountInformationForm'
              onSubmit={methods.handleSubmit(onSubmit, onError)}
              noValidate
              className={`form add-user-form ${styles.formWrapper}`}
            >
              <fieldset disabled={isEditingOwnProfile}>
                <CardBody className={styles.cardItem}>
                  {rendering?.placeholders?.sections?.map(
                    (card: any, index: number) => (
                      <Element
                        name={card.componentName}
                        key={card.componentName + index}
                      >
                        {sectionsLookup[card.componentName] != null &&
                          React.createElement(
                            sectionsLookup[card.componentName],
                            {
                              key: index,
                              fields: card.fields,
                              ...additionalProps[card.componentName],
                            }
                          )}
                      </Element>
                    )
                  )}
                </CardBody>

                <CardFooter className={styles.buttonsLayout} separator>
                  <div>
                    <Button
                      name='updateSettingsBtn'
                      text={fields.updateBtnText}
                      ariaText={fields.updateBtnText}
                      type='submit'
                      disabled={isReadOnlyUser}
                    />
                    {isInternal && (
                      <Button
                        secondaryButton
                        name="addUserResyncBtn"
                        text={fields.resyncBtnText}
                        ariaText={fields.resyncBtnText}
                        onClick={onReSyncUser}
                        type="button"
                      />
                    )}
                    {!isInternal &&
                      (<Button
                      secondaryButton
                      name="reInviteUserBtn"
                      text={fields.reInviteUserBtnText}
                      ariaText={fields.reInviteUserBtnText}
                      onClick={onReinviteUser}
                      type="button"
                      disabled={!showUserActive}
                    />)
                    }
                    
                    <Button
                      secondaryButton
                      name='cancelEditBtn'
                      text={fields.cancelBtnText}
                      ariaText={fields.cancelBtnText}
                      onClick={onCancel}
                      type='button'
                    />
                  </div>
                  {isInternal ? (
                    <div>
                      <Button
                        textButton
                        type="button"
                        name="deleteAccountBtn"
                        text={fields.deleteBtnText}
                        ariaText={fields.deleteBtnText}
                        onClick={onDeleteAccountButtonClick}
                        disabled={isReadOnlyUser}
                      />
                    </div>
                  ) : (
                    <div className={styles.showUserActive}>
                      <FormToggle
                        onChange={() => {
                          onToggleShowUserActive(showUserActive);
                        }}
                        isChecked={showUserActive}
                        id="showUserActive"
                        testId="showUserActive"
                        staticText={fields.activeUserBtnText?.value}
                      />
                    </div>
                  )}
                </CardFooter>
              </fieldset>
            </form>
          </FormProvider>
        </ModifyUserContextProvider>
      </Card>

      <Prompt
        when={Object.keys(dirtyFields)?.length > 0 && !isBypass}
        message={i18n.t('DefaultSubmission-IsDirty') || ''}
      />
      <ToastNotification
        type={toastState}
        isActive={showResyncToast}
        title={
          toastState === ApplicationStates.ERROR
            ? i18n.t('DefaultSubmission-ErrorToastTitle')
            : i18n.t('DefaultActions-SystemSuccessToast-Action-Title')
        }
        content={toastMsg}
        onCloseCallback={() => onUserToastCloseHandler()}
      />
      <ToastNotification
        isActive={toastProps.isActive}
        type={toastProps.type}
        title={toastProps.title}
        content={toastProps.content}
        onCloseCallback={toastProps.onCloseCallback}
      />

      <Modal
        type={ApplicationStates.SUCCESS}
        style='success'
        isActive={modalActive}
        title={fields.deleteAccount?.fields?.heading?.value || ''}
        content={fields.deleteAccount?.fields?.content?.value}
        isLoading={isModalLoading}
        onCloseCallback={onDismissModal}
        onPrimaryCallback={onUserDelete}
        onSecondaryCallback={onDismissModal}
        fields={{
          saveButton: {
            value: fields.deleteAccount?.fields?.acceptButton?.value,
          },
          cancelButton: {
            value: fields.deleteAccount?.fields.cancelButton?.value,
          },
        }}
      />
      <Modal
        type={ApplicationStates.SUCCESS}
        style="success"
        isActive={updateModalActive}
        title={fields.confirmationModal?.fields?.heading?.value || ''}
        content={fields.confirmationModal?.fields.content?.value}
        isLoading={isModalLoading}
        onCloseCallback={onDismissUpdateModal}
        onPrimaryCallback={onUserUpdate}
        onSecondaryCallback={onDismissUpdateModal}
        fields={{
          saveButton: {
            value: fields.confirmationModal?.fields.acceptButton.value
          },
          cancelButton: {
            value: fields.confirmationModal?.fields.cancelButton?.value
          }
        }}
      />
    </>
  );
};
export default ModifyUser;
