import React, { useEffect, useState } from 'react';
import { CashReceipt, CashReceiptTypeCode } from '@hobt/claim-domain';
import { LanguageShort } from '@hobt/constants';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import ConditionalRender from 'Components/Common/ConditionalRender';
import FormCard from 'Components/Common/FormCard';
import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';
import { currencyFormat, getCurrencyFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormComment from 'Components/Inputs/FormComment';
import FormDatepicker from 'Components/Inputs/FormDatepicker';
import FormNumber from 'Components/Inputs/FormNumber';
import FormYesNoRadios from 'Components/Inputs/FormYesNoRadios';
import { TooltipIcon } from 'Components/Inputs/TooltipIcon';
import type { PartialPayment } from 'Feature/Claims/components/ClaimsSubmissionForm/types';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import ClaimsPartialPaymentDetailsProps from 'Feature/Claims/models/ClaimsPartialPaymentDetailsProps';
import { ButtonType } from 'Feature/CommonComponents/UserControls';
import { GlossaryNames } from 'Feature/PageComponents/components/GlossaryComponent/types';
import i18n from 'i18next';
import { useFieldArray, useWatch, useFormContext } from 'react-hook-form';
import { LanguageSwitchTypeCode } from '../ClaimsLenderDetails/types';
import styles from './styles.module.scss';
import { TPartialPaymentsArray } from './types';

const DEFAULT_PARTIAL_PAYMENT: PartialPayment = {
  receivedAmount: 0,
  receivedDate: undefined,
  comment: ''
};

export default ({ fields }: ClaimsPartialPaymentDetailsProps) => {
  const {
    claimData,
    isInEditMode,
    isMasterUserEditingPostAdjudicationClaim,
    isClaimsDetails,
    isFieldDisabled
  } = useHBTFormContext();
  const { getValues, setValue, register, unregister, watch } = useFormContext();
  const { toCurrency } = useTranslationHelpers();

  const netOperatingIncomesRentalIncomes = watch('netOperatingIncomesRentalIncomes');

  const {
    fields: partialPayments,
    append,
    remove
  }: TPartialPaymentsArray = useFieldArray({ name: 'partialPayments' });
  const partialPaymentReceived = useWatch({ name: 'partialPaymentReceivedFlag' }) as string;
  const partialPaymentsWatch = useWatch({ name: 'partialPayments' }) as PartialPayment[];
  const [isOptionDisabled, setIsOptionDisabled] = useState(false);
  const rowAdditions: Array<[number, FieldValue]> = [
    [1, fields.rowOne],
    [5, fields.rowFive],
    [10, fields.rowTen]
  ];

  const totalPartialPayments = (partialPaymentsWatch ?? [])
    ?.map((partialPayment) => partialPayment?.receivedAmount ?? 0)
    ?.reduce((total, payment) => total + payment, 0)
    ?.toFixed?.(2);

  const handleRemove = (index: number) => {
    if (Array.isArray(partialPaymentsWatch) && partialPaymentsWatch.length >= 1) {
      const hasPreviousCashFields =
        partialPaymentsWatch[index]?.previouslyApprovedAmount != null &&
        partialPaymentsWatch[index]?.previouslyApprovedDate != null;
      // Only Master Users can remove the last partial payment
      // Only remove when there is no previous cash
      if (
        hasPreviousCashFields === false &&
        (isMasterUserEditingPostAdjudicationClaim === true || partialPaymentsWatch.length > 1)
      ) {
        remove(index);
      }
    }
  };

  useEffect(() => {
    // We use a setValue here since the remove function from useFieldArray deletes not just the specified index but other data/fields in a nested array field
    if (isClaimsDetails === true || isInEditMode === false) {
      const partialPaymentsData = claimData?.cashReceipts?.filter?.(
        (receipt: CashReceipt) => receipt.typeCode === CashReceiptTypeCode.PartialPayment
      );
      if (partialPaymentReceived === 'true') {
        setValue('partialPayments', partialPaymentsData);

        if (Array.isArray(partialPaymentsData) && partialPaymentsData.length > 0) {
          partialPaymentsData.forEach((partialPayment: PartialPayment, index: number) => {
            if (partialPayment.indexNumber != null) {
              register(`partialPayments.${index}.indexNumber`);
              setValue(`partialPayments.${index}.indexNumber`, partialPayment.indexNumber);
            }
          });
        }
      }
    }
  }, [isClaimsDetails, isInEditMode, partialPaymentReceived]);

  useEffect(() => {
    if (isClaimsDetails === false) {
      // On claim submission form, claimData is a draft-claim which can have partial payments.
      const partialPaymentsData = getValues()?.partialPayments;
      if (partialPaymentReceived === 'true') {
        if (Array.isArray(partialPaymentsData) && partialPaymentsData?.length > 0) {
          setValue('partialPaymentReceivedFlag', 'true');
          partialPaymentsData?.forEach((partialPayment: PartialPayment, index: number) => {
            if (partialPayment?.previouslyApprovedAmount != null) {
              register(`partialPayments.${index}.previouslyApprovedAmount`);
              setValue(
                `partialPayments.${index}.previouslyApprovedAmount`,
                partialPayment?.previouslyApprovedAmount?.toString()
              );
            }
            if (partialPayment.previouslyApprovedDate != null) {
              register(`partialPayments.${index}.previouslyApprovedDate`);
              setValue(
                `partialPayments.${index}.previouslyApprovedDate`,
                partialPayment.previouslyApprovedDate
              );
            }
            if (partialPayment.receivedAmount !== null) {
              register(`partialPayments.${index}.receivedAmount`);
              setValue(
                `partialPayments.${index}.receivedAmount`,
                partialPayment.receivedAmount === undefined
                  ? null
                  : Number(partialPayment.receivedAmount)
              );
              setValue(
                `partialPayments.${index}.receivedDate`,
                partialPayment.receivedDate === undefined ? null : partialPayment.receivedDate
              );
            }
          });
        }
      }
    }
  }, [partialPayments, partialPaymentReceived]);

  // If a user ever sets the flag to No, then we need to unregister indexNumber
  // This logic only applies to the claim details page
  useEffect(() => {
    if (partialPaymentReceived === 'false') {
      unregister('partialPayments');
    }
    if (partialPaymentReceived === 'false' && isClaimsDetails === true) {
      partialPayments.forEach((_item, index: number) => {
        unregister(`partialPayments.${index}.indexNumber`);
      });
    }
  }, [partialPaymentReceived]);

  useEffect(() => {
    if (Array.isArray(partialPayments) && partialPayments.length > 0) {
      if (partialPayments.some((payment) => payment.previouslyApprovedDate)) {
        setValue('partialPaymentReceivedFlag', 'true');
        setIsOptionDisabled(true);
      }
    }
  }, [partialPayments, partialPaymentReceived, isFieldDisabled]);

  return (
    <FormCard
      title={fields.cardTitle}
      headingLevel={2}
      sectionId="claims-partial-payments-body"
      fieldToValidate="partialPaymentReceivedFlag"
      toolTipButton={{
        id: 'paymentGlossaryTooltip',
        name: 'paymentGlossaryTooltip',
        ariaText: fields.cardGlossaryAriaText?.value ?? '',
        onClick: () => {
          fields?.openGlossary?.(GlossaryNames.PartialPaymentDetails);
        },
        buttonType: ButtonType.TEXT
      }}
    >
      <FormYesNoRadios
        className={styles.full}
        testid="partialPaymentReceivedFlag"
        data-testid="partialPaymentReceivedFlag"
        label={fields.partialPayments}
        name="partialPaymentReceivedFlag"
        isReadOnly={isOptionDisabled || isFieldDisabled}
      />

      <ConditionalRender
        condition={
          partialPaymentReceived === 'true' || isMasterUserEditingPostAdjudicationClaim === true
        }
      >
        <>
          <div className={styles.headings}>
            <span id="cpp_datePaid" className={styles.heading}>
              <Text field={fields.datePaid} />
            </span>
            <span id="cpp_amount" className={styles.heading}>
              <Text field={fields.amount} />
            </span>
            <span className="sr-only" id="cpp_comment">
              <Text field={fields.comments} />
            </span>
            <span className="sr-only" id="cpp_delete">
              Delete
            </span>
          </div>
          <hr className={styles.separator} />

          {partialPayments?.map((field, index) => (
            <React.Fragment key={field.id}>
              <div className={styles['income-row']}>
                <FormDatepicker
                  className={`${styles.partialReceivedDate} ${
                    i18n.language === LanguageSwitchTypeCode.French ? styles.datePaidPullDown : ''
                  }`.trim()}
                  label={fields.datePaid}
                  name={`partialPayments.${index}.receivedDate`}
                  isReadOnly={isFieldDisabled}
                />
                <FormNumber
                  className={styles.partialReceivedAmount}
                  formatProps={getCurrencyFormat(
                    getValues(`partialPayments.${index}.receivedAmount`),
                    {
                      ...currencyFormat,
                      allowNegative: false
                    },
                    i18n.language as LanguageShort
                  )}
                  label={fields.amount}
                  name={`partialPayments.${index}.receivedAmount`}
                  allowNegative={false}
                  isReadOnly={isFieldDisabled}
                />
                <FormComment
                  className={styles['comment-row']}
                  name={`partialPayments.${index}.comment`}
                  label={fields.comments?.value ?? ''}
                  updateLabel={fields.updateButton?.value ?? ''}
                  cancelLabel={fields.cancelButton?.value ?? ''}
                  characterLimitLabel={fields.characterLimit?.value ?? ''}
                  addCommentLabel={fields.addComment?.value ?? ''}
                  editCommentLabel={fields.editComment?.value ?? ''}
                  isDisabled={isFieldDisabled}
                />
                {(field.previouslyApprovedAmount == null ||
                  field.previouslyApprovedDate == null) && (
                  <button
                    className={styles['delete-row-button']}
                    onClick={() => handleRemove(index)}
                    type="button"
                    disabled={isFieldDisabled}
                  >
                    <span>
                      <TooltipIcon
                        icon={fields.deleteIcon}
                        text={i18n.t('DefaultSubmission-Card-Delete')}
                        className="icon-16"
                      />
                    </span>
                  </button>
                )}
              </div>
              <hr className={styles.separator} />
            </React.Fragment>
          ))}
          <div className={`${styles.full} d-flex flex-column align-items-end`}>
            <span>
              <Text field={fields.totalLabel} />
            </span>
            <output htmlFor="partialPayments">
              {toCurrency(parseFloat(totalPartialPayments))}
            </output>
          </div>
          <hr className={styles['full-separator']} />
          <div className={`${styles.full} d-flex`}>
            {rowAdditions?.map(([count, fieldText]) => (
              <button
                className={styles['add-rows-button']}
                key={count}
                type="button"
                onClick={() => append(Array(count).fill(DEFAULT_PARTIAL_PAYMENT))}
                disabled={
                  isFieldDisabled ||
                  (count === 10 &&
                    (partialPayments?.length ?? 0) +
                      (netOperatingIncomesRentalIncomes?.length ?? 0) >
                      90) ||
                  (count === 5 &&
                    (partialPayments?.length ?? 0) +
                      (netOperatingIncomesRentalIncomes?.length ?? 0) >
                      95) ||
                  (count === 1 &&
                    (partialPayments?.length ?? 0) +
                      (netOperatingIncomesRentalIncomes?.length ?? 0) >
                      99)
                }
              >
                <span
                  aria-hidden={true}
                  className={`${styles['add-rows-button_icon']} material-icons`}
                >
                  add_circle_outline
                </span>
                <Text field={fieldText} />
              </button>
            ))}
          </div>
        </>
      </ConditionalRender>
    </FormCard>
  );
};
