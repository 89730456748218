import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import axios from 'axios';
import { useFeature } from 'flagged';
import i18n from 'i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { HbtFileExtensionType, Status } from '@hobt/constants';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { FileUploadError } from './FileUploadError';
import { FileUploadSuccess } from './FileUploadSuccess';
import { FileInputDropChoose } from './FileInputDropChoose';
import { AttachmentObject, AttachmentsViewProps, AttachmentWrapper } from './types';
import { config } from '../../../config';
import styles from './styles.module.scss';
import { Tab } from 'Feature/UserManagement/models/ManageUsersInternalProps';

const sectionId = 'CommonAttachmentsComponent';
const elapsedUploadTimes: number[] = [];
const isAttachmentUploading: boolean[] = [];
const cancelTokenSources: { token: any; cancel: any }[] = [];
let attachmentCounter = 0;

export const AttachmentsView: React.FC<AttachmentsViewProps> = (props: AttachmentsViewProps) => {
  const {
    endpoint,
    isArrears = false,
    isAllowed = true,
    isOptional = false,
    registeredVal,
    isMockUpload,
    deleteDocumentId,
    allowedFileTypes,
    fieldArray,
    fields,
    showCounter = true,
    attachmentFiles,
    onUploadSuccessCallback,
    onErrorCallBack,
    isUserFile = false,
    onUploadBulkUserSuccessCallback,
    handleLoadingIndicatorValueCallback
  } = props;

  const {
    register,
    setValue,
    watch,
    formState: { errors },
    unregister,
    clearErrors
  } = useFormContext();
  const [uploadTrigger, setUploadTrigger] = useState(false);
  const [attachments, setAttachments] = useState<AttachmentWrapper[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number[]>([]);
  const isExternal = useFeature(FeatureFlags.EXTERNAL);

  const authContext = useAuthenticationContext();
  const apiClientConfig: ApiClientConfig = { timeout: config.documentApi.requestTimeout };
  let errorMessage = '';

  const checkFile = (size: number, name: string) => {
    // check size
    if (size > parseInt(config.documentApi.maxFileSize!, 10)) {
      errorMessage = i18n
        .t('Errors-HBT_ERR_6004')
        .replace('{maxSize}', String(parseInt(config.documentApi.maxFileSize!, 10) / 1024 ** 2));
      return true;
    }

    // check type
    if (
      allowedFileTypes !== undefined &&
      allowedFileTypes.length > 0 &&
      !allowedFileTypes.includes(
        name
          .slice((Math.max(0, name.lastIndexOf('.')) || Infinity) + 1)
          .toLowerCase() as HbtFileExtensionType
      )
    ) {
      errorMessage = i18n.t('Errors-HBT_ERR_6003');
      return true;
    }
    return false;
  };

  // Simulate an upload that takes three seconds
  const mockUpload = (attachmentWrapper: AttachmentWrapper) => {
    setTimeout(() => {
      setAttachments((prevAttachments: AttachmentWrapper[]) => {
        const attachmentsCopy = [...prevAttachments];
        attachmentsCopy.map((attachmentWrapperCopy: AttachmentWrapper) => {
          if (attachmentWrapper.id === attachmentWrapperCopy.id) {
            attachmentWrapperCopy.isUploaded = false;
            // don't need to set this back to false; it's just to be semantically tidy
            isAttachmentUploading[attachmentWrapper.id] = false;
            // don't need this anymore; only need the document ID
            delete attachmentWrapperCopy.attachment;
          }
          return attachmentWrapperCopy;
        });
        return attachmentsCopy;
      });
    }, 3000);
  };

  const onUploadBulkUserSuccess = (file: AttachmentWrapper) => {
    const index = attachments
      .filter(
        (attachment: AttachmentWrapper) =>
          attachment.id === file.id || attachment.isUploaded === true
      )
      .findIndex((attachment: AttachmentWrapper) => attachment.id === file.id);
    const attachmentObj: AttachmentObject = {
      fileName: file.fileName ?? '',
      documentID: '',
      fileByteCount: file.fileSize ?? '',
      uploadStatusCode: Status.UploadSuccessful
    };
    register(`${registeredVal}[${index}]`);
    setValue(`${registeredVal}[${index}]`, attachmentObj);
    if (onUploadBulkUserSuccessCallback !== null) {
      onUploadBulkUserSuccessCallback?.();
    }
  };

  const onUploadSuccess = (file: AttachmentWrapper, documentId: string, fileType: string) => {
    /**
     * Get indices for all successful attachments first then get specific index (js filter method preserves order)
     * at the same time, also keep the index of file even if it is listed as unsuccessful currently as state update
     * might be taking some time and may not list file uploaded as success.
     */
    isExternal && clearErrors('attachments');
    const index = attachments
      .filter(
        (attachment: AttachmentWrapper) =>
          attachment.id === file.id || attachment.isUploaded === true
      )
      .findIndex((attachment: AttachmentWrapper) => attachment.id === file.id);

    const attachmentObj: AttachmentObject = {
      fileName: file.fileName ?? '',
      documentID: documentId ?? '',
      fileByteCount: file.fileSize ?? '',
      description: file.description ?? '',
      fileType: fileType ?? '',
      uploadStatusCode: Status.UploadSuccessful
    };

    if (isArrears === true) {
      const transitNumber = watch(`arrearsFiles[${index}].transitNumber`);
      const { remove, insert, processFileOnUploadSuccess } = fieldArray!;

      // add file to field array, remove any existing index in case there is any
      remove(index);
      insert(index, {
        attachment: attachmentObj,
        transitNumber
      });

      if (processFileOnUploadSuccess != null) processFileOnUploadSuccess();
    } else {
      register(`${registeredVal}[${index}]`);
      setValue(`${registeredVal}[${index}]`, attachmentObj);
    }

    if (onUploadSuccessCallback != null) {
      onUploadSuccessCallback(attachmentObj, index);
    }
  };

  function deleteAttachment(attachmentId: number) {
    const index = attachmentId - 1;
    const newAttachments = (attachments as AttachmentWrapper[]).filter(
      (attachmentWrapper: AttachmentWrapper) =>
        attachmentWrapper != null && attachmentWrapper.id !== attachmentId
    );

    unregister(`${registeredVal}[${index}]`);
    setAttachments(newAttachments);
  }

  const realUpload = (attachmentWrapper: AttachmentWrapper) => {
    const formData = new FormData();
    formData.append(
      'uploadedFileObject',
      attachmentWrapper.attachment!,
      attachmentWrapper.fileName
    );

    elapsedUploadTimes[attachmentWrapper.id] = 0;
    setInterval(() => {
      elapsedUploadTimes[attachmentWrapper.id] += 1;
    }, 1000);

    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSources[attachmentWrapper.id] = cancelTokenSource;

    ApiClient(authContext, apiClientConfig)
      .postWithHeaderAuth(endpoint, formData, isUserFile, {
        cancelToken: cancelTokenSource.token,
        onUploadProgress: (progressEvent: any) => {
          setUploadProgress((prevUploadProgress: number[]) => {
            const uploadProgressCopy = [...prevUploadProgress];
            uploadProgressCopy[attachmentWrapper.id] =
              (progressEvent.loaded / attachmentWrapper.fileSize) * 100;
            return uploadProgressCopy;
          });
        }
      })
      .then((response: any) => {
        setAttachments((prevAttachments: AttachmentWrapper[]) => {
          const attachmentsCopy = [...prevAttachments];
          attachmentsCopy.map((attachmentWrapperCopy: AttachmentWrapper) => {
            if (attachmentWrapper.id === attachmentWrapperCopy.id) {
              attachmentWrapperCopy.isUploaded = true;
              // Don't actually need to set this back to false; it's just to be semantically tidy
              isAttachmentUploading[attachmentWrapper.id] = false;
              attachmentWrapperCopy.documentId = isUserFile
                ? ''
                : response.data && response.data.data[0].documentId;
            }
            return attachmentWrapperCopy;
          });
          return attachmentsCopy;
        });
        isUserFile
          ? onUploadBulkUserSuccess(attachmentWrapper)
          : onUploadSuccess(
              attachmentWrapper,
              response.data.data[0].documentId,
              response.data.data[0].fileType
            );
      })
      .catch((error: any) => {
        setAttachments((prevAttachments: AttachmentWrapper[]) => {
          const attachmentsCopy = [...prevAttachments];
          attachmentsCopy.map((attachmentWrapperCopy: AttachmentWrapper) => {
            if (attachmentWrapper.id === attachmentWrapperCopy.id) {
              attachmentWrapperCopy.isError = true;
              isAttachmentUploading[attachmentWrapper.id] = false;

              attachmentWrapperCopy.errorMessage = i18n
                .t(`Errors-${error?.response?.data?.error?.errorCode || 'HBT_ERR_DEFAULT'}`)
                .replace(
                  '{maxSize}',
                  String(parseInt(config.documentApi.maxFileSize!, 10) / 1024 ** 2)
                );
            }
            return attachmentWrapperCopy;
          });
          return attachmentsCopy;
        });
        if (isUserFile) {
          onErrorCallBack && onErrorCallBack(error);
        }
      });
  };

  useEffect(() => {
    attachments
      .filter(
        (attachmentWrapper: AttachmentWrapper) =>
          !attachmentWrapper.isUploaded &&
          !isAttachmentUploading[attachmentWrapper.id] &&
          !checkFile(attachmentWrapper.fileSize, attachmentWrapper.fileName)
      )
      .forEach((attachmentWrapper: AttachmentWrapper) => {
        isAttachmentUploading[attachmentWrapper.id] = true;

        if (isMockUpload === true) mockUpload(attachmentWrapper);
        else realUpload(attachmentWrapper);
      });
  }, [uploadTrigger]);

  useEffect(() => {
    attachments.forEach((attachmentWapper: AttachmentWrapper) => {
      if (attachmentWapper.documentId === deleteDocumentId) {
        deleteAttachment(attachmentWapper.id);
      }
    });
  }, [deleteDocumentId]);

  useEffect(() => {
    attachmentFiles && attachmentFiles(attachments);
  }, [attachments]);

  function handleAttachmentUpload(rawAttachments: File[]) {
    if (handleLoadingIndicatorValueCallback !== null) {
      handleLoadingIndicatorValueCallback?.();
    }
    isAttachmentUploading[attachmentCounter] = false;
    const newAttachments = rawAttachments.map((attachment: File) => {
      attachmentCounter += 1;
      return {
        attachment,
        isUploaded: false,
        id: attachmentCounter,
        fileName: attachment.name,
        fileSize: attachment.size,
        isError: checkFile(attachment.size, attachment.name),
        errorMessage
      };
    });

    setAttachments(attachments.concat(newAttachments));
    setUploadTrigger(!uploadTrigger);
  }

  function handleAttachmentDrop(rawAttachments: File[]) {
    if (rawAttachments.length > 1) return;
    handleAttachmentUpload(rawAttachments);
  }

  function handleAttachmentInput(event: React.ChangeEvent<HTMLInputElement>) {
    const emptyFile = new File([''], 'empty');

    if (event.target.files != null) {
      if (event.target.files.length !== 1) return;
      handleAttachmentUpload(
        Object.keys(event.target.files).map((key: any) =>
          event.target.files ? event.target.files[key] : emptyFile
        )
      );
      (document.getElementById(`${sectionId}UploadFiles`) as HTMLInputElement).value = '';
    }
  }

  function handleCancelUpload(attachmentId: number) {
    isAttachmentUploading[attachmentId] = false;
    deleteAttachment(attachmentId);
  }

  return (
    <>
      {!isArrears && (
        <div className={`mb-1 reduceFont`}>
          <Text
            tag="strong"
            field={isOptional ? fields.attachmentLabelOptional : fields.attachmentLabel}
          />
        </div>
      )}
      {showCounter && (
        <div className={styles.counterWrapper}>
          <div className={styles.counterWrapperCount}>
            <Text
              field={{
                value: fields.uploadFileCountText?.value?.replace(
                  '{indexCounter}',
                  attachments.length.toString()
                )
              }}
            />
          </div>

          {attachments.length >= 20 && (
            <div className={styles.counterWrapperWarn}>
              <Text field={fields.fileCountWarningText} />
            </div>
          )}
        </div>
      )}

      <FileInputDropChoose
        errors={errors}
        sectionId={sectionId}
        fields={fields}
        isArrears={isArrears}
        isAllowed={isAllowed && (isUserFile ? attachments.length < 1 : attachments.length < 20)}
        handleAttachmentDrop={handleAttachmentDrop}
        handleAttachmentInput={handleAttachmentInput}
        registeredVal={registeredVal}
      />
      <div className="row card__body-row">
        {attachments.length > 0 &&
          attachments.map((attachment: AttachmentWrapper) =>
            attachment.isError ? (
              <FileUploadError
                fields={fields}
                attachment={attachment}
                deleteAttachment={deleteAttachment}
              />
            ) : (
              !isArrears && (
                <FileUploadSuccess
                  fields={fields}
                  attachment={attachment}
                  uploadProgress={uploadProgress}
                  deleteAttachment={deleteAttachment}
                  handleCancelUpload={handleCancelUpload}
                />
              )
            )
          )}
      </div>
    </>
  );
};
