import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';

export interface HbtPIFileTransferPageProps {
  fields: HbtPIFileTransferFields;
  rendering: ComponentRendering;
}

export interface HbtPIFileTransferFields {
  fileTransferTitle: FieldValue;
  fileTransferTitleInfo: FieldValue;
  cmhcSupportBtn: FieldValue;
  needSupportInfoContent: FieldValue;
  fileTransferUserGuideAccessLabel: FieldValue;
  fileTransferUserGuideLink: PIUserGuideFields[];
}

export interface PIUserGuideFields {
  fields: {
    guideName: FieldValue;
    guideHref: LinkField;
  };
}
export interface FileTransferTabList {
  fields: {
    label: FieldValue;
  };
  content: ComponentRendering;
}

export type HbtPIFileTransferPageContextType = {
  fileTransferPageContextData: FileTransferPageContextData;
};

export type FileTransferPageContextData = {
  showNotificationBanner: boolean;
  notificationBannerInfo: NotificationBannerInfo;
  setShowNotificationBanner: (newValue: boolean) => void;
  setNotificationBannerInfo: (newValue: NotificationBannerInfo) => void;
  hasUnSavedChanges: boolean;
  setHasUnSavedChanges: (newValue: boolean) => void;
};
export interface NotificationBannerInfo {
  notificationBannerHeader: string;
  notificationBannerType: NotificationBannerType;
  notificationBannerBody: string;
}

export enum NotificationBannerType {
  SUCCESS = 'Success',
  ERROR = 'Error',
  INFO = 'Info',
  WARNING = 'Warning'
}

export interface FileTransferTabItem {
  fields: TabItemFields;
  componentName: string;
}

// Need to Add all individual Tab component fields to TabItemFields Type
export interface TabItemFields {
  tabLabel: FieldValue;
  fileInstructionInputFieldLabel?: FieldValue;
  fileTransferLenderNameLabel?: FieldValue;
  fileTransferLenderNameStepTitle?: FieldValue;
  fileTransferAttachmentsTitle?: FieldValue;
  fileInstructionInputFieldExternalLabel?: FieldValue;
  fileTransferAttachmentsExternalTitle?: FieldValue;
  fileTransferAttachmentsTitleInfo?: FieldValue;
  fileTransferAttachmentSupportedFileFormatText?: FieldValue;
  fileTransferAttachmentInputBrowserLabelText?: FieldValue;
  fileTransferAttachmentInputBrowserLabel?: FieldValue;
  fileTransferNoAttachmentTableMessage?: FieldValue;
}
