import React, { useState } from 'react';
import { CommentCellProps } from 'Components/Common/DataTable/DataTableRow/CommentCell/types';
import { CommentPopout } from 'Components/Common/CommentPopout';
import styles from './styles.module.scss';

export const CommentCell: React.FC<CommentCellProps> = ({
  comment,
  icon,
  name,
  disabled = false
}: CommentCellProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  return (
    <td data-testid={`${name}TextCell`}>
      {disabled === false && (
        <div className={styles.commentCell}>
          <button
            name={`${name}Button`}
            onClick={(e) => {
              e.stopPropagation();
              setIsActive(!isActive);
            }}
            aria-label={`${name} Button`}
            type="button"
            data-testid="openCommentButtonTest"
          >
            <span className="material-icons-outlined icon--v-align-middle">
              {icon || 'comment'}
            </span>
          </button>
          <div className={styles.commentContainer}>
            <CommentPopout
              isActive={isActive}
              content={() => <>{comment}</>}
              onCloseCallback={() => setIsActive(!isActive)}
            />
          </div>
        </div>
      )}
    </td>
  );
};
