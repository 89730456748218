import { Status as UploadStatusCode } from '@hobt/portfolio-domain';

export interface HbtPIFileTransferUploadTabViewProps {
  fields: HbtPIFileTransferUploadTabViewFields;
}

export interface HbtPIFileTransferUploadTabViewFields {
  tabLabel: FieldValue;
  fileInstructionInputFieldLabel: FieldValue;
  fileInstructionInputFieldExternalLabel?: FieldValue;
  fileTransferAttachmentsExternalTitle?: FieldValue;
  fileTransferLenderNameLabel?: FieldValue;
  fileTransferLenderNameStepTitle?: FieldValue;
  fileTransferAttachmentsTitle: FieldValue;
  fileTransferAttachmentsTitleInfo: FieldValue;
  fileTransferAttachmentSupportedFileFormatText: FieldValue;
  fileTransferAttachmentInputBrowserLabelText: FieldValue;
  fileTransferAttachmentInputBrowserLabel: FieldValue;
  fileTransferNoAttachmentTableMessage: FieldValue;
}

export const AllowedFileTypes = ['xlsm', 'xlsx', 'xls', 'csv', 'pdf', 'txt', 'zip'];

export enum PortfolioToolType {
  PiPurposeTest = 1,
  PiFileShare = 2,
  PiRequest = 3
}

export interface AttachmentFile {
  attachment: File;
  fileName: string;
  fileSize: number;
  fileType: string;
  errorMessage: string;
  percentage: number;
  timeLeft: number;
  uploadStatusCode: UploadStatusCode;
}
export interface DocumentEntity {
  documentId: string;
  description?: string;
  documentLink: string;
  documentStatus: number;
  fileName: string;
  fileSize: number;
  fileType: string;
  recordCreatedTimestamp: string;
  recordCreatedUserID: string;
  recordUpdatedTimestamp: string;
  recordUpdatedUserID: string;
  uniqueFileName: string;
  portfolioToolType: number;
}

export enum FileShareType {
  FileSharedWithPortfolioAnalyst = 1,
  FileSharedWithApprovedLender = 2
}
