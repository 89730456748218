import React, { memo, PropsWithChildren, useCallback } from 'react';
import { Button } from '../Button';
import { ModalActionButtonProps } from './types';

export const ModalActionButtons: React.FC<ModalActionButtonProps> = memo(
  ({ modalActionButton, buttonVariant }: PropsWithChildren<ModalActionButtonProps>) => {
    const onButtonClick = useCallback((e: any) => {
      e.preventDefault();
    }, []);

    return (
      <Button
        variant={buttonVariant}
        name={modalActionButton.name ?? `modal ${buttonVariant} button`}
        text={modalActionButton.text ?? { value: `modal ${buttonVariant}` }}
        ariaText={modalActionButton.ariaText ?? { value: `modal ${buttonVariant} button` }}
        onClick={modalActionButton.onClick ?? onButtonClick}
      />
    );
  }
);
