import React, { useEffect, useState } from 'react';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import { CSSTransition } from 'react-transition-group';
import styles from './styles.module.scss';
import { ToastNotificationProps } from './types';

export const ToastNotification: React.FC<ToastNotificationProps> = ({
  open,
  header,
  subHeader,
  variant,
  icon,
  onClose,
  actionable
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);

    // Calculate duration based on the length of the subHeader text
    if (!actionable && subHeader.value) {
      const duration = Math.min(Math.max(subHeader.value.length * 100, 4000), 10000);
      const timer = setTimeout(() => setIsOpen(false), duration);

      return () => clearTimeout(timer);
    }
  }, [actionable, open, actionable, subHeader]);

  if (!isOpen) return null;

  return (
    <CSSTransition
      in={isOpen}
      timeout={0}
      className={`${styles.toastNotification} ${styles.slideUp} ${styles[variant]}`}
      unmountOnExit
      data-testid="toast-notification-container"
    >
      <div>
        <div className={styles.iconWrapper} data-testid="toast-notification-icon">
          {icon && <HbtIcon size={HbtIconSize.MEDIUM} type={icon} />}
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.header} data-testid="toast-notification-header">
            {header.value}
          </div>
          <div className={styles.subHeader} data-testid="toast-notification-sub-header">
            {subHeader.value}
          </div>
        </div>
        {actionable && (
          <button
            aria-label="close"
            className={styles.closeButton}
            onClick={onClose}
            data-testid="toast-notification-close-button"
          >
            <HbtIcon size={HbtIconSize.MEDIUM} type="icon_close" />
          </button>
        )}
      </div>
    </CSSTransition>
  );
};

export default ToastNotification;
