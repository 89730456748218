import { useFeature } from 'flagged';
import i18n from 'i18next';
import React, { useEffect, useState, useRef } from 'react';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { UserRole, Module, LanguageShort } from '@hobt/constants';

import { postalCodeFormat } from 'Components/Inputs/CommonFormFieldFormats';
import { TooltipIcon } from 'Components/Inputs/TooltipIcon';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useDefaultFormActionsContext } from 'Feature/DefaultsInventory/components/DefaultFormContext';

import { AccordionContainer, LinedCard } from '../../../../CommonComponents/AccordionComponents';
import {
  deleteCardRepopulateValues,
  scrollIntoView,
  prepopulateCalculations
} from '../../CardReuseableFunctions';

import FormInputDropdownText from '../../FormInputDropdownText';
import FormInputTextInput, {
  Formatted as FormInputFormattedTextInput
} from '../../FormInputTextInput';
import FormInputCurrency from '../../FormInputCurrency';
import FormInputRadio from '../../FormInputRadio';
import FormInputWholeNumber from '../../FormInputWholeNumber';
import FormNumberInput from '../../FormNumberInput';
import { BorrowerProps } from './Borrower.types';
import {
  BorrowerTypeCode,
  ComponentType,
  FormInputRadioType
} from '../../../models/typeCode.types';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const BorrowerContainer: React.FC<BorrowerProps> = ({
  register,
  errors,
  setValueHandler,
  control,
  watch,
  getValues,
  ...props
}) => {
  const commonCardProps = {
    register,
    errors,
    setValueHandler,
    control,
    watch,
    getValues
  };

  const index = props.index || 0;
  const cardInstance = (watch && watch(`borrower[${index}].submittedMonthlyIncomeSource`)) || {};
  const browerFields = (watch && watch(`borrower[${index}]`)) || {};

  const grossSalary = cardInstance.grossSalaryEmployment || 0;
  const employmentInsurance = cardInstance.employmentInsurance || 0;
  const investmentIncome = cardInstance.investmentIncome || 0;
  const otherIncome = cardInstance.other || 0;

  const borrowerFirstName = browerFields.borrowerFirstName || 0;
  const borrowerLastName = browerFields.borrowerLastName || 0;
  const borrowerTypeID = browerFields.borrowerTypeID || 0;
  const currentEmployment = browerFields.employment?.currentEmployment || 0;
  const industry = browerFields.employment?.industry || 0;
  const numberOfYearsInIndustry = browerFields.employment?.numberOfYearsInIndustry || 0;
  const occupation = browerFields.employment?.occupation || 0;
  const creditObtained = browerFields.creditObtained || 0;
  const numberOfDependents = browerFields.numberOfDependents || 0;

  const [singleCardTotalMonthlyGrossIncome, setSingleCardTotalMonthlyGrossIncome] = useState(0);

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;

  const defaultFormActions = useDefaultFormActionsContext();

  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);
  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  useEffect(() => {
    const total = grossSalary + employmentInsurance + investmentIncome + otherIncome;

    setSingleCardTotalMonthlyGrossIncome(total);
    const borrowerGrossIncomeArray = props.borrowerGrossIncomeArray && [
      ...props.borrowerGrossIncomeArray
    ];
    if (borrowerGrossIncomeArray) {
      borrowerGrossIncomeArray[index] = total;
      // Update calculations
      props.calculateMonthlyGrossIncome &&
        props.calculateMonthlyGrossIncome(borrowerGrossIncomeArray);
    }
  }, [grossSalary, employmentInsurance, investmentIncome, otherIncome]);

  useEffect(() => {
    if (defaultFormActions.isCardComplete('borrower')) {
      if (
        !(
          !!borrowerFirstName &&
          !!borrowerLastName &&
          !!borrowerTypeID &&
          !!currentEmployment &&
          !!industry &&
          !!numberOfYearsInIndustry &&
          !!occupation &&
          !!creditObtained &&
          !!numberOfDependents
        )
      ) {
        defaultFormActions?.removeCompleteCard('borrower');
      }
    } else if (
      !!borrowerFirstName &&
      !!borrowerLastName &&
      !!borrowerTypeID &&
      !!currentEmployment &&
      !!industry &&
      !!numberOfYearsInIndustry &&
      !!occupation &&
      !!creditObtained &&
      !!numberOfDependents
    ) {
      defaultFormActions?.addCompleteCard('borrower');
    }
  }, [
    borrowerFirstName,
    borrowerLastName,
    borrowerTypeID,
    currentEmployment,
    industry,
    numberOfYearsInIndustry,
    occupation,
    creditObtained,
    numberOfDependents
  ]);

  return (
    <>
      <h3 className="card__body-heading" id={`${props.cardId}${index + 1}`}>
        {index === 0
          ? props?.primaryBorrower?.field?.value
          : index === 1
          ? props?.secondaryBorrower?.field?.value
          : `${props?.borrowerOrGuarantor?.field?.value} ${index + 1}`}
      </h3>
      <div className="row card__body-row">
        <div
          className={`form__element form__element--2-column col-6 ${
            i18n.language === LanguageShort.French ? 'pullDown' : ''
          }`.trim()}
        >
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.cardId}borrowerFirstName${index}`}
            name={`borrower[${index}].borrowerFirstName`}
            title={props.inputFields.borrowerFirstName.field}
            type={ComponentType.Text}
            cardName={props.title.field.value}
            isRequired={true}
            autoFocus={props.isAutoFocus}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.cardId}borrowerLastName${index}`}
            name={`borrower[${index}].borrowerLastName`}
            title={props.inputFields.borrowerLastName.field}
            type={ComponentType.Text}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputDropdownText
            {...commonCardProps}
            id={`${props.cardId}BorrowerType${index}`}
            name={`borrower[${index}].borrowerTypeID`}
            title={props.inputFields.borrowerType.field}
            options={[
              { label: '', value: '' },
              { label: props.borrower, value: BorrowerTypeCode.Borrower },
              { label: props.coBorrower, value: BorrowerTypeCode.CoBorrower },
              { label: props.guarantor, value: BorrowerTypeCode.Guarantor }
            ]}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <h3 className="card__body-heading">{props?.mailingAddressDifferent?.field?.value}</h3>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.cardId}BorrowerMailingAddressStreetNumber${index}`}
            name={`borrower[${index}].mailingAddress.streetNumber`}
            title={props.inputFields.mailingAddressStNumber.field}
            type={ComponentType.Text}
            cardName={props.title.field.value}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.cardId}BorrowerMailingAddressUnitNumber${index}`}
            name={`borrower[${index}].mailingAddress.unitNumber`}
            title={props.inputFields.mailingAddressUnitNumber.field}
            type={ComponentType.Text}
            cardName={props.title.field.value}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.cardId}BorrowerMailingAddressStreetName${index}`}
            name={`borrower[${index}].mailingAddress.streetName`}
            title={props.inputFields.mailingAddressStreetName.field}
            type={ComponentType.Text}
            cardName={props.title.field.value}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.cardId}BorrowerMailingAddressStreetType${index}`}
            name={`borrower[${index}].mailingAddress.streetType`}
            title={props.inputFields.mailingAddressStreetType.field}
            type={ComponentType.Text}
            cardName={props.title.field.value}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.cardId}BorrowerMailingAddressStreetDir${index}`}
            name={`borrower[${index}].mailingAddress.streetDirection`}
            title={props.inputFields.mailingAddressStDirection.field}
            type={ComponentType.Text}
            cardName={props.title.field.value}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.cardId}BorrowerMailingAddressTownCity${index}`}
            name={`borrower[${index}].mailingAddress.municipalityName`}
            title={props.inputFields.mailingAddressCity.field}
            type={ComponentType.Text}
            cardName={props.title.field.value}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputDropdownText
            {...commonCardProps}
            id={`${props.cardId}BorrowerMailingAddressProvince${index}`}
            name={`borrower[${index}].mailingAddress.provinceCode`}
            title={props.inputFields.mailingAddressProvince.field}
            options={props.provinceOptions}
            cardName={props.title.field.value}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputFormattedTextInput
            {...commonCardProps}
            id={`${props.cardId}BorrowerMailingAddressPostalCode${index}`}
            formatProps={postalCodeFormat}
            title={props.inputFields.mailingAddressPostalCode.field}
            type={ComponentType.Text}
            name={`borrower[${index}].mailingAddress.postalCode`}
            cardName={props.title.field.value}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <hr className="card__divider" />
      <h3 className="card__body-heading">{props?.monthlySourceOfIndividualIncome?.field?.value}</h3>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.cardId}BorrowerGrossSalary${index}`}
            name={`borrower[${index}].submittedMonthlyIncomeSource.grossSalaryEmployment`}
            title={props.inputFields.grossSalary.field}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div
          className={`form__element form__element--2-column col-6 ${
            i18n.language === LanguageShort.French ? 'pullDown' : ''
          }`.trim()}
        >
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.cardId}BorrowerEmploymentInsurance${index}`}
            name={`borrower[${index}].submittedMonthlyIncomeSource.employmentInsurance`}
            title={props.inputFields.employmentInsurance.field}
            cardName={props.title.field.value}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.cardId}BorrowerInvestmentIncome${index}`}
            name={`borrower[${index}].submittedMonthlyIncomeSource.investmentIncome`}
            title={props.inputFields.investmentIncome.field}
            cardName={props.title.field.value}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.cardId}BorrowerMonthlyIncomeOther${index}`}
            name={`borrower[${index}].submittedMonthlyIncomeSource.other`}
            title={props.inputFields.otherIncome.field}
            cardName={props.title.field.value}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <hr className="card__divider" />
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-12">
          <FormInputCurrency
            id={`${props.cardId}BorrowerTotalMonthlyGross${index}`}
            title={props.inputFields.totalMonthlyGrossIncome.field}
            isDisabled={isReadOnlyUser || true}
            value={singleCardTotalMonthlyGrossIncome}
            cardName={props.title.field.value}
          />
        </div>
      </div>
      <hr className="card__divider" />
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6 pullDown">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.cardId}BorrowerOccupation${index}`}
            name={`borrower[${index}].employment.occupation`}
            title={props.inputFields.occupation.field}
            type={ComponentType.Text}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.cardId}BorrowerCurrentEmployment${index}`}
            name={`borrower[${index}].employment.currentEmployment`}
            title={props.inputFields.currentEmployment.field}
            description={props.currentEmploymentDescription.field}
            type={ComponentType.Text}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormNumberInput
            {...commonCardProps}
            id={`${props.cardId}BorrowerNumOfYearInIndustry${index}`}
            name={`borrower[${index}].employment.numberOfYearsInIndustry`}
            title={props.inputFields.numberOfYearsInIndustry.field}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.cardId}BorrowerIndustry${index}`}
            name={`borrower[${index}].employment.industry`}
            title={props.inputFields.industry.field}
            type={ComponentType.Text}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-12">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.cardId}BorrowerdetailEmploymentProspects${index}`}
            name={`borrower[${index}].employment.detailedEmploymentProspects`}
            title={props.inputFields.detailedEmploymentProspects.field}
            description={props.detailEmploymentProspectsDescription.field}
            type={ComponentType.Text}
            cardName={props.title.field.value}
            isRequired={false}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputWholeNumber
            {...commonCardProps}
            id={`${props.cardId}BorrowerNumberOfDependents${index}`}
            name={`borrower[${index}].numberOfDependents`}
            title={props.inputFields.numberOfDependents.field}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputRadio
            {...commonCardProps}
            id={`${props.cardId}BorrowerCreditObtained${index}`}
            name={`borrower[${index}].creditObtained`}
            title={props.recentCreditBureauObtained.field}
            options={[
              {
                label: props.inputFields.radioButtonYes.field,
                value: FormInputRadioType.Yes
              },
              {
                label: props.inputFields.radioButtonNo.field,
                value: FormInputRadioType.No
              }
            ]}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      {index && index > 0 ? (
        <div className="row card__body-row mt-3">
          <div className="col-12 card__actionButtons">
            <button
              className="ml-4 btn btn--icon btn__delete"
              type="button"
              onClick={() => {
                props.removeCard && props.removeCard(index);
              }}
              disabled={isReadOnlyUser || props.disableDelete || false}
            >
              <span>
                <TooltipIcon
                  icon={props.deleteIcon}
                  text={i18n.t('DefaultSubmission-Card-Delete')}
                  className={'icon-24'}
                />
              </span>
            </button>
          </div>
        </div>
      ) : null}
      <hr className="card__divider card__divider--thick" />
    </>
  );
};

export const Borrower: React.FC<BorrowerProps> = (props) => {
  const initialIdsArray = [];
  const cardCount = props.cardCount || 1;
  for (let i = 0; i < cardCount; i++) {
    initialIdsArray.push(i);
  }
  const [idsArray, setIdsArray] = useState<number[]>(initialIdsArray);
  const [id, setId] = useState<number>(cardCount - 1);
  const [isAutoFocus, setAutoFocus] = useState<boolean>(false);
  const [lastIndexPreDelete, setLastIndexPreDelete] = useState<number>(cardCount - 1);
  const [disableDelete, setDisableDelete] = useState(false);
  const [borrowerGrossIncomeArray, setBorrowerGrossIncomeArray] = useState([0]);
  const isInitialMount = useRef(true);

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;

  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);
  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  useEffect(() => {
    // set props.borrowerGrossIncomeArray on mount, for calculation based on pre-populated data
    const borrowerValues = (props.watch && props.watch(`borrower`)) || {};
    const allCardValues = [];
    for (let i = 0; i < cardCount; i++) {
      allCardValues.push(borrowerValues[i].submittedMonthlyIncomeSource);
    }
    const borrowerGrossIncomeArrayCopy = prepopulateCalculations(allCardValues);
    calculateMonthlyGrossIncome(borrowerGrossIncomeArrayCopy);
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setIdsArray(idsArray.concat(id));
    }
  }, [id]);

  useEffect(() => {
    // update last index after adding/deleting a card
    setLastIndexPreDelete(idsArray.length - 1);
    setDisableDelete(false); // re-enable delete after delete operation is completed
  }, [JSON.stringify(idsArray)]);

  function addAdditionalGuarantor() {
    // id start form 0, added a check to limit the addition of Borrower/Guarantor to 10
    if (idsArray.length < 10) {
      setId(id + 1);
    }
    setAutoFocus(true);
  }

  function removeCard(index: number): void {
    // manually setting own isDirty check for card deletions on details form
    props.setCardDeleted && props.setCardDeleted(true);

    if (props.getValues && props.setValueHandler && props.watch && props.reset) {
      // prepopulate cards after delete using react-hook-form functions
      deleteCardRepopulateValues(
        props.getValues,
        props.setValueHandler,
        props.watch,
        props.reset,
        index,
        lastIndexPreDelete,
        `borrower`,
        props.register
      );
    }

    // Update cards' id array
    const numbersArrayCopy = idsArray;
    numbersArrayCopy.splice(index, 1);
    setIdsArray(numbersArrayCopy);

    // Update calculated fields after deleting a card
    const borrowerGrossIncomeArrayCopy = [...borrowerGrossIncomeArray];
    borrowerGrossIncomeArrayCopy.splice(index, 1); // remove value from borrowerGrossIncomeArray based on index of removed card
    calculateMonthlyGrossIncome(borrowerGrossIncomeArrayCopy);

    // Scroll into view after delete
    scrollIntoView(props.cardId + index);

    // Disable multiclick
    setDisableDelete(true);
  }

  function calculateMonthlyGrossIncome(borrowerGrossIncomeArray: number[]): void {
    // set borrowerGrossIncomeArray, and calculate borrowerTotalMonthlyGrossIncome
    let borrowerTotalMonthlyGrossIncome = 0;
    borrowerGrossIncomeArray.map((grossIncome) => {
      borrowerTotalMonthlyGrossIncome += grossIncome;
    });
    setBorrowerGrossIncomeArray && setBorrowerGrossIncomeArray(borrowerGrossIncomeArray);
    props.setBorrowerTotalMonthlyGrossIncome &&
      props.setBorrowerTotalMonthlyGrossIncome(borrowerTotalMonthlyGrossIncome);
  }

  return (
    <LinedCard
      id={props.cardId}
      testId={props.testId}
      linePosition={props.linePosition}
      lineColor={props.accordionLineColor}
    >
      <AccordionContainer accordionId={`${props.cardId}Accordion`} title={props.title.field}>
        {idsArray.map((id, index) => (
          <BorrowerContainer
            {...props}
            key={id}
            index={index}
            lastIndexPreDelete={lastIndexPreDelete}
            removeCard={removeCard}
            calculateMonthlyGrossIncome={calculateMonthlyGrossIncome}
            borrowerGrossIncomeArray={borrowerGrossIncomeArray}
            setBorrowerGrossIncomeArray={setBorrowerGrossIncomeArray}
            disableDelete={disableDelete}
            isAutoFocus={isAutoFocus}
          />
        ))}
        <div className="row card__body-row">
          <div className="col-12">
            <button
              className="btn btn__add"
              onClick={addAdditionalGuarantor}
              type="button"
              disabled={isReadOnlyUser || idsArray.length >= 10}
            >
              <i
                className="material-icons icon--size-32 icon--v-align-middle"
                title={props.addAdditionalBorrower.field.value}
                aria-hidden={true}
              >
                add_circle_outline
              </i>
              <Text field={props.addAdditionalBorrower.field} />
            </button>
          </div>
        </div>
      </AccordionContainer>
    </LinedCard>
  );
};
