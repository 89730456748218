/* eslint-disable no-nested-ternary */
import { Modal } from 'Components/Common/Modal';
import React, { useReducer } from 'react';
import i18n from 'i18next';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { useFeature } from 'flagged';

import { CorrespondenceApprovalAggregate } from '@hobt/claim-domain';

import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import { useDataGridEngine } from 'Components/Hooks/DataGridEngine';
import { DataTableFooter } from 'Components/Common/DataTableFooter';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { CorrespondenceRow } from './CorrespondenceRow';
import { createCorrespondenceReducer } from './CorrespondenceReducer';

import { useCorrespondenceFunctions } from './useCorrespondenceFunctions';
import AttachmentsNotesCommentsDetailsProps, {
  ActionType,
  AttachmentsNotesCommentsDetailsState,
  FilterType
} from './types';
import ActionPanel from './ActionPanel';
import { config } from '../../../../../config';

import styles from './styles.module.scss';

const initialComponentState: AttachmentsNotesCommentsDetailsState<CorrespondenceApprovalAggregate> =
  {
    filteredValue: FilterType.Default,
    showSideDrawer: false,
    showModal: false,
    showToast: false,
    toastType: ApplicationStates.DEFAULT
  };

const AttachmentsNotesCommentsDetails = (props: AttachmentsNotesCommentsDetailsProps) => {
  const siteTypeInternal = useFeature(FeatureFlags.INTERNAL);

  const stateReducer = createCorrespondenceReducer<CorrespondenceApprovalAggregate>();
  const [state, dispatch] = useReducer(stateReducer, initialComponentState);
  const { isReadOnlyAccessUser, claimData } = useHBTFormContext();

  const {
    rowData,
    totalItems,
    totalPages,
    currentPage,
    itemRangeTo,
    onPagerClick,
    itemsPerPage,
    itemRangeFrom,
    onItemsPerPageChange,
    onSetCustomFilterParams,
    refresh
  } = useDataGridEngine<any>({
    onApiErrorCallback: () => {
      dispatch({ action: ActionType.SHOW_TOAST_ERROR });
    },
    apiBaseUrl: `${config.claimApi.urls.correspondences}/${claimData?.cmhcLoanAccountNumber}`,
    defaultItemsPerPage: 10
  });

  const {
    onFilter,
    openSideDrawer,
    getCorrespondenceRowProps,
    dismissToast,
    onDocumentCancelCallback,
    onDocumentErrorCallback,
    onDocumentSuccessCallback,
    deleteCancelCorrespondenceCallback,
    deleteConfirmCorrespondenceCallback
  } = useCorrespondenceFunctions(
    props,
    onSetCustomFilterParams,
    refresh,
    state,
    dispatch,
    siteTypeInternal
  );

  const getFooterProps = () => {
    const itemsXofYText = `${itemRangeFrom || 0} - ${itemRangeTo || 0} ${i18n.t(
      'Globals-Grid-ResultsPerPageDropdown-Of'
    )}`;

    return {
      name: 'AttachmentNotesFooter',
      currentPage,
      totalPages,
      itemsXofYText,
      itemsPerPageLabel: {
        value: i18n.t('DefaultsInventoryTable-ResultsPerPageDropdown-Title')
      },
      pagerClickCallback: onPagerClick,
      itemsPerPageOnChangeCallback: onItemsPerPageChange,
      itemsPerPageValue: itemsPerPage.toString(),
      totalItems
    };
  };

  return (
    <>
      <ActionPanel
        {...props}
        setFilteredValue={onFilter}
        onFlyout={openSideDrawer}
        isReadOnlyAccessUser={isReadOnlyAccessUser}
      />

      {rowData != null && rowData.length > 0 ? (
        <section>
          <div className={styles.correspondenceList}>
            {rowData?.map((correspondenceItem, i) =>
              siteTypeInternal === false && correspondenceItem?.commentTypeCode === 8 ? (
                <></>
              ) : (
                <CorrespondenceRow
                  {...getCorrespondenceRowProps(correspondenceItem, correspondenceItem?.claimID)}
                  key={`${correspondenceItem.correspondenceID}-${i}`}
                />
              )
            )}
          </div>
        </section>
      ) : (
        // TODO: Handle when empty
        <></>
      )}
      <span className={styles.FooterStyle}>
        <DataTableFooter {...getFooterProps()} />
      </span>
      <SideDrawer
        isActive={state.showSideDrawer}
        isFocusTrapPaused={state.showToast}
        handleOutsideClick={onDocumentCancelCallback}
      >
        {state.sideDrawerComponent &&
          React.createElement(state.sideDrawerComponent, {
            fields: state.sideDrawerComponentFields,
            onCancelCallback: onDocumentCancelCallback,
            onSuccessCallback: onDocumentSuccessCallback,
            onErrorCallback: onDocumentErrorCallback
          })}
      </SideDrawer>

      <Modal
        type={ApplicationStates.WARNING}
        style=""
        title={props?.fields?.confirmDeleteModalTitle?.value}
        content={props?.fields?.confirmDeleteModalContent?.value}
        isActive={state.showModal}
        onCloseCallback={deleteCancelCorrespondenceCallback}
        onPrimaryCallback={deleteConfirmCorrespondenceCallback}
        onSecondaryCallback={deleteCancelCorrespondenceCallback}
        fields={{
          saveButton: props.fields.confirmDeleteModalButton,
          cancelButton: props.fields.cancelDeleteModalButton
        }}
      />

      <ToastNotification
        type={state.toastType}
        isActive={state.showToast}
        title={state.toastTitle}
        content={state.toastContent}
        onCloseCallback={dismissToast}
      />
    </>
  );
};

export default AttachmentsNotesCommentsDetails;
