/* eslint-disable */
import styles from './styles.module.scss';

export const getMenuStyleProps = (openedByKeyboard: boolean) => {

    return {
        classes: {
            paper: styles.menuListWrapper,
            list: styles.menuList
        },
        sx: {
            '& .Mui-selected': {
            background: 'var(--Accent-Slate-90W, #F0F2F3) !important'
            },
            '& .Mui-focusVisible::before': {
            content: '""',
            position: 'absolute !important',
            top: 'var(--Spacing-02, 4px) !important',
            right: 'var(--Spacing-02, 4px) !important',
            bottom: 'var(--Spacing-02, 4px) !important',
            left: 'var(--Spacing-02, 4px) !important',
            border: 'var(--Spacing-01, 2px) solid var(--Accent-Violet-20W, #6D65D7) !important',
            borderRadius: 'var(--Spacing-01, 2px)',
            pointerEvents: 'none !important',
            zIndex: '0 !important'
            }
        },
        autoFocus: openedByKeyboard
        };
};