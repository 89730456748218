import React, { useEffect, useState } from 'react';
import SitecoreListItem, { SitecoreDropdownList } from 'Constants/Types/SitecoreListItem';
import { useFormContext } from 'react-hook-form';
import { FormCheckbox } from '../FormCheckbox';
import FormCheckboxListWrapper from '../FormCheckboxListWrapper';
import { FormCheckboxListProps } from './types';
import styles from './styles.module.scss';

export const FormCheckboxList: React.FC<FormCheckboxListProps> = ({
  name,
  label,
  className,
  checkBoxList,
  selectedCheckbox,
  customClassName,
  isReadOnly,
  setAsNumber = false
}: FormCheckboxListProps) => {
  const {
    formState: { dirtyFields },
    register,
    setValue
  } = useFormContext();

  const checkBoxOptions: SitecoreDropdownList = Array.isArray(checkBoxList)
    ? checkBoxList[0]
    : checkBoxList;
  const [editSelectedCheckbox, setEditSelectedCheckbox] = useState<(number | string)[]>(
    selectedCheckbox ?? []
  );

  useEffect(() => {
    register(name);
  }, []);

  useEffect(() => {
    setValue(name, editSelectedCheckbox, {
      shouldValidate: dirtyFields[name] != null,
      shouldDirty: true
    });
  }, [editSelectedCheckbox]);

  function checkBooleanValue(item: SitecoreListItem): boolean | undefined {
    return editSelectedCheckbox?.includes(
      setAsNumber
        ? Number(item.fields.itemValue.value || '-1')
        : (item.fields.itemValue.value || '-1').toString()
    );
  }

  function onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const checkedInput = setAsNumber ? Number(event.target.value) : event.target.value.toString(); // Cache the value of event.target.value

    if (event.target.checked === false) {
      setEditSelectedCheckbox((prevEditSelectedCheckbox: (number | string)[]) =>
        prevEditSelectedCheckbox.filter(
          (checkboxItem: number | string) => checkboxItem !== checkedInput
        )
      );
    } else {
      setEditSelectedCheckbox((prevEditSelectedCheckbox: (number | string)[]) => [
        ...prevEditSelectedCheckbox,
        checkedInput
      ]);
    }
  }

  return (
    <>
      <FormCheckboxListWrapper name={name} label={label} className={className}>
        <div className={`${styles.checkBoxListWrapper} ${customClassName}`}>
          {checkBoxOptions?.fields?.listItems?.map((item: SitecoreListItem) => (
            <div key={`List${item.fields.itemValue.value}`}>
              <FormCheckbox
                label={item.fields.itemName}
                name=""
                key={item.fields.itemValue.value}
                value={
                  setAsNumber
                    ? Number(item.fields.itemValue.value)
                    : item.fields.itemValue.value?.toString()
                }
                checked={checkBooleanValue(item)}
                onChange={onChangeHandler}
                dontRegister={true}
                isReadOnly={isReadOnly}
              />
            </div>
          ))}
        </div>
      </FormCheckboxListWrapper>
    </>
  );
};
