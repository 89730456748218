import { ModalProps } from 'Feature/CommonComponents/ContentComponents';
import { DefaultDetailsResponseData, DefaultRequestStatus } from 'Foundation/Api';
import { FormDropdownOption } from 'Feature/DefaultSubmissionForm/components/FormInputDropdownText/FormInputDropdownText.types';

export interface DefaultDetailsInternalHeaderProps {
  detailsData: DefaultDetailsResponseData;
  watchLoanValidation: boolean;
  areDisabledCardBtns: boolean;
  handleDecisioningSubmission: (newStatus: DefaultRequestStatus, formData: any) => void;
  setPageModal: (modalProps: ModalProps) => void;
  triggerModal: (shouldTriggerModal: boolean) => void;
  emailSubjectLine: string;
  cancelOptions: FormDropdownOption[];
  declineOptions: FormDropdownOption[];
}
export interface SitecoreItem {
  componentName: string;
}

export const DECISION_RATIONALE_ENABLED_STATUSES = [
  DefaultRequestStatus.Approved,
  DefaultRequestStatus.ApprovedWithModifications,
  DefaultRequestStatus.Cancelled,
  DefaultRequestStatus.Declined,
  DefaultRequestStatus.Inactive
];
