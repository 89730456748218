import React, { FC } from 'react';
import { isNullOrUndefined } from 'Components/Common/Api/utils/EmptyValues';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import styles from '../styles.module.scss';
import { DropdownErrorMessageProps } from '../types';

export const DropdownErrorMessage: FC<DropdownErrorMessageProps> = ({
  error,
  errorMessage
}: DropdownErrorMessageProps) => {
  const hasMessage: boolean =
    (error ?? false) && !isNullOrUndefined(errorMessage) && (errorMessage ?? '').length > 0;

  return hasMessage ? (
    <div className={styles.errorContainer}>
      <HbtIcon
        size={HbtIconSize.SMALL}
        type="icon_error_outlined"
        className={styles.errorInfoIcon}
      />
      <span className={styles.errorMessageText}>{errorMessage}</span>
    </div>
  ) : null;
};
