import React, { useEffect, useState, useMemo, useReducer } from 'react';
import { AccountStatus } from '@hobt/constants';
import { frontEndAddUserSchema } from '@hobt/form-schema-validators';
import { joiResolver } from '@hookform/resolvers/joi';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Button } from 'Components/Core/Button';
import { ButtonVariant } from 'Components/Core/Button/types';
import { Modal } from 'Components/Core/Modal';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { LoaderAnimation } from 'Feature/CommonComponents/UserControls';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';
import { useFeature } from 'flagged';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import i18n from 'i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router-dom';
import { Element } from 'react-scroll';
import { UserApiResponse } from '../../models/types';
import { reSyncUserRequest } from '../HbtUserAccessManagementApi/HbtUserAccessApiHandler.service';
import ModifyUserContextProvider from './HbtModifyInternalPIUserContext';
import { HbtModifyInternalPIUserReducer } from './HbtModifyInternalPIUserReducer';
import { HbtUseInternalPIUserApiFunctions } from './HbtUseInternalPIUserApiFunctions';
import { HbtUseModifyInternalPIUserFunctions } from './HbtUseModifyInternalPIUserFunctions';
import styles from './styles.module.scss';
import HbtModifyInternalPIUserProps, { HbtModifyInternalPIUserReducerState } from './types';

// Ignoring next block as it is used to init reducer state
/* istanbul ignore next */
const initialReducerState: HbtModifyInternalPIUserReducerState = {
  isBypass: false,
  modalActive: false,
  isLoading: false,
  isModalLoading: false,
  isPageLoading: false,
  toastProps: {
    isActive: false,
    type: ApplicationStates.DEFAULT,
    title: '',
    content: '',
    onCloseCallback: () => true
  },
  updateModalActive: false
};

const HbtModifyInternalPIUser = ({ fields, rendering }: HbtModifyInternalPIUserProps) => {
  const isServerSSR = isServer();
  const userId = useMemo(() => {
    const urlParams = new URLSearchParams(!isServerSSR ? window.location.search : '');
    return urlParams.get('id') ?? undefined;
  }, []);

  const isInternal = useFeature(FeatureFlags.INTERNAL);
  const authenticationContext = useAuthenticationContext();
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const { push } = useHistory();

  const methods = useForm({
    resolver: joiResolver(frontEndAddUserSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  });

  const [toastState, setToastState] = useState<ApplicationStates>(ApplicationStates.DEFAULT);
  const [showResyncToast, setShowResyncToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string | FieldValue>('');
  const [showToast, setShowToast] = useState<boolean>(false);
  const onUserToastCloseHandler = () => setShowResyncToast(false);

  const [state, dispatch] = useReducer(HbtModifyInternalPIUserReducer, initialReducerState);
  const [showUserActive, setShowUserActive] = useState<boolean>(true);

  const { isLoading, isPageLoading, modalActive, isBypass, toastProps, updateModalActive } = state;

  const { userData, onUserDelete, onSubmit, onError, onUserUpdate } =
    HbtUseInternalPIUserApiFunctions(
      dispatch,
      push,
      authenticationContext,
      userId,
      sitecoreContext
    );

  const {
    isReadOnlyUser,
    sectionsLookup,
    isEditingOwnProfile,
    initializeFormValues,
    onDismissModal,
    onDeleteAccountButtonClick,
    onCancel,
    onDismissUpdateModal
  } = HbtUseModifyInternalPIUserFunctions(
    {
      reset: methods.reset,
      setValue: methods.setValue,
      register: methods.register
    },
    isInternal as boolean,
    dispatch,
    push,
    userData,
    sitecoreContext
  );

  const additionalProps: Record<string, any> = {
    UserPermissions: { isModify: true },
    HbtInternalPIUserLevelAccess: { isModify: true, userData },
    HbtInternalPIUserDetails: { isModify: true },
    NewUserDetails: { isModify: true }
  };

  const { dirtyFields } = methods.formState;

  useEffect(() => {
    if (isServer() === false) {
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, []);

  function unRegisterUserAccessesFieldsNotInSchema() {
    if (userData && userData.userAccesses) {
      userData.userAccesses.forEach((userAccess, index) => {
        const fieldsToUnregister = [
          `userAccesses[${index}].access.arrears.roleCode`,
          `userAccesses[${index}].access.claim.roleCode`,
          `userAccesses[${index}].access.default.roleCode`,
          `userAccesses[${index}].financialInstitutionCode`,
          `userAccesses[${index}].recordCreatedTimestamp`,
          `userAccesses[${index}].recordCreatedUserID`,
          `userAccesses[${index}].recordUpdatedTimestamp`,
          `userAccesses[${index}].recordUpdatedUserID`,
          `userAccesses[${index}].userAccessID`,
          `userAccesses[${index}].userAuthorizationScope`,
          `userAccesses[${index}].userID`
        ];

        fieldsToUnregister.forEach((field) => {
          methods.unregister(field);
        });
      });
    }
  }

  const fieldsToUnregister = [
    'adOID',
    'adContact',
    'operation',
    'recordCreatedTimestamp',
    'recordCreatedUserID',
    'recordUpdatedBy',
    'recordUpdatedTimestamp',
    'recordUpdatedUserID',
    'internalContact.employeeID'
  ];

  function unRegisterFieldsNotInSchema() {
    fieldsToUnregister.forEach((field) => {
      methods.unregister(field);
    });
  }

  useEffect(() => {
    unRegisterFieldsNotInSchema();
    unRegisterUserAccessesFieldsNotInSchema();
  }, [dirtyFields]);

  const onToggleShowUserActive = (showUserActive: any) => {
    setShowUserActive(!showUserActive);
    showUserActive && onDeleteAccountButtonClick();
  };

  useEffect(() => {
    methods.setValue('userAccesses[0].statusCode', AccountStatus.ACTIVE);
  }, [showUserActive]);

  useEffect(() => {
    if (userData?.userAccesses?.[0]?.statusCode == 2) {
      setShowUserActive(false);
    }
  }, [userData]);

  // isDirty check
  useEffect(() => {
    // onbeforeunload should trigger on back, forward, refresh, and link button clicks
    if (!isServerSSR) {
      window.onbeforeunload = () => {
        // trigger browser warning
        // returning anything, will trigger the prompt,
        // return string only read on IE:
        return Object.keys(dirtyFields).length > 0 && !isBypass
          ? i18n.t('DefaultSubmission-IsDirty')
          : null;
      };
    }
  }, [dirtyFields, isBypass]);

  useEffect(initializeFormValues, [userData]);

  if (isPageLoading) {
    return (
      <div className={styles.modifyInternalPIUserLoader}>
        <LoaderAnimation />
      </div>
    );
  }

  const onReSyncUser = async () => {
    const user = [methods.getValues('emailID')];
    const result = await reSyncUserRequest(authenticationContext, user);
    if (result === UserApiResponse.Success) {
      setShowResyncToast(true);
      setToastMsg(i18n.t('UserManagement-Users-ResyncSuccessToast'));
      setToastState(ApplicationStates.SUCCESS);
    } else {
      setShowResyncToast(true);
      setToastMsg(i18n.t('UserManagement-Users-ResyncErrorToast'));
      setToastState(ApplicationStates.ERROR);
    }
  };

  const onToastCloseHandler = () => setShowToast(false);

  return (
    <>
      <ContentLoadingModal
        display={isLoading}
        fields={{
          heading: { value: i18n.t('Globals-Saving-Heading') },
          description: { value: i18n.t('Globals-Saving-Description') }
        }}
      />
      <ToastNotification
        type={toastState}
        isActive={showToast}
        title={
          toastState === ApplicationStates.ERROR
            ? i18n.t('DefaultSubmission-ErrorToastTitle')
            : i18n.t('DefaultActions-SystemSuccessToast-Action-Title')
        }
        content={toastMsg}
        onCloseCallback={() => onToastCloseHandler()}
      />
      <div className={styles.modifyInternalPIUserMain}>
        <div className={styles.modifyInternalPIUserMainContainer}>
          <div className={styles.modifyInternalPIUserMainContent}>
            <div className={styles.modifyInternalPIUserHeaderContainer}>
              <h2 className={styles.modifyInternalPIUserTitle}>
                <Text
                  field={{
                    value: fields.pageTitle?.value?.replace(
                      '{username}',
                      `${userData?.contact?.firstName} ${userData?.contact?.lastName}`
                    )
                  }}
                />
              </h2>
            </div>
            <div className={styles.modifyInternalPIUserFormContainer}>
              <ModifyUserContextProvider
                value={{
                  userData: userData?.userAccesses
                }}
              >
                <FormProvider {...methods}>
                  <form
                    id="accountInformationForm"
                    onSubmit={methods.handleSubmit(onSubmit, onError)}
                    noValidate
                    className={`form add-user-form`}
                  >
                    <fieldset disabled={isEditingOwnProfile}>
                      <div className={styles.modifyInternalPIUserFormBody}>
                        {rendering?.placeholders?.sections?.map((card: any, index: number) => (
                          <Element name={card.componentName} key={card.componentName + index}>
                            {sectionsLookup[card.componentName] != null &&
                              React.createElement(sectionsLookup[card.componentName], {
                                key: index,
                                fields: card.fields,
                                ...additionalProps[card.componentName]
                              })}
                          </Element>
                        ))}
                        <div className={styles.modifyInternalPIUserFormActionBtnContainer}>
                          <div>
                            <Button
                              variant={ButtonVariant.PRIMARY}
                              name="updateSettingsBtn"
                              text={fields.updateBtnText}
                              ariaText={fields.updateBtnText}
                              type="submit"
                              disabled={isReadOnlyUser}
                            />
                            {isInternal && (
                              <Button
                                variant={ButtonVariant.SECONDARY}
                                name="addUserResyncBtn"
                                text={fields.resyncBtnText}
                                ariaText={fields.resyncBtnText}
                                onClick={onReSyncUser}
                                type="button"
                              />
                            )}
                            <Button
                              variant={ButtonVariant.SECONDARY}
                              name="cancelEditBtn"
                              text={fields.cancelBtnText}
                              ariaText={fields.cancelBtnText}
                              onClick={onCancel}
                              type="button"
                            />
                          </div>
                          {isInternal && (
                            <div>
                              <Button
                                variant={ButtonVariant.TERTIARY}
                                type="button"
                                name="deleteAccountBtn"
                                text={fields.deleteBtnText}
                                ariaText={fields.deleteBtnText}
                                onClick={onDeleteAccountButtonClick}
                                disabled={isReadOnlyUser}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </FormProvider>
              </ModifyUserContextProvider>
            </div>
          </div>
        </div>
      </div>

      <Prompt
        when={Object.keys(dirtyFields)?.length > 0 && !isBypass}
        message={i18n.t('DefaultSubmission-IsDirty') || ''}
      />

      <ToastNotification
        type={toastState}
        isActive={showResyncToast}
        title={
          toastState === ApplicationStates.ERROR
            ? i18n.t('DefaultSubmission-ErrorToastTitle')
            : i18n.t('DefaultActions-SystemSuccessToast-Action-Title')
        }
        content={toastMsg}
        onCloseCallback={() => onUserToastCloseHandler()}
      />

      <ToastNotification
        isActive={toastProps.isActive}
        type={toastProps.type}
        title={toastProps.title}
        content={toastProps.content}
        onCloseCallback={toastProps.onCloseCallback}
      />

      <Modal
        id="delete-internal-user-account-confirmation-modal"
        isActive={modalActive}
        title={fields.deleteAccount?.fields?.heading || ''}
        onDismissalCallback={onDismissModal}
        bodyContent={
          <span>
            <Text field={fields.deleteAccount?.fields?.content} />
          </span>
        }
        modalButtons={{
          primaryButton: {
            name: fields.deleteAccount?.fields?.acceptButton?.value ?? '',
            text: { value: fields.deleteAccount?.fields?.acceptButton?.value } as FieldValue,
            ariaText: { value: fields.deleteAccount?.fields?.acceptButton?.value } as FieldValue,
            onClick: onUserDelete,
            onKeyDown: onUserDelete
          },
          secondaryButton: {
            name: fields.deleteAccount?.fields.cancelButton?.value ?? '',
            text: { value: fields.deleteAccount?.fields.cancelButton?.value } as FieldValue,
            ariaText: { value: fields.deleteAccount?.fields.cancelButton?.value } as FieldValue,
            onClick: onDismissModal,
            onKeyDown: onDismissModal
          }
        }}
        shouldCloseOnBackgroundClick={true}
      />

      <Modal
        id="update-confirmation-modal"
        isActive={updateModalActive}
        title={fields.confirmationModal?.fields?.heading || ''}
        onDismissalCallback={onDismissUpdateModal}
        bodyContent={
          <span>
            <Text field={fields.confirmationModal?.fields.content} />
          </span>
        }
        modalButtons={{
          primaryButton: {
            name: fields.confirmationModal?.fields.acceptButton.value ?? '',
            text: { value: fields.confirmationModal?.fields.acceptButton.value } as FieldValue,
            ariaText: { value: fields.confirmationModal?.fields.acceptButton.value } as FieldValue,
            onClick: onUserUpdate,
            onKeyDown: onUserUpdate
          },
          secondaryButton: {
            name: fields.confirmationModal?.fields.cancelButton.value ?? '',
            text: { value: fields.confirmationModal?.fields.cancelButton?.value } as FieldValue,
            ariaText: { value: fields.confirmationModal?.fields.cancelButton?.value } as FieldValue,
            onClick: onDismissUpdateModal,
            onKeyDown: onDismissUpdateModal
          }
        }}
        shouldCloseOnBackgroundClick={true}
      />
    </>
  );
};
export default HbtModifyInternalPIUser;
