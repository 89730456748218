export function replaceMissingFieldsWithNull<T extends object, K extends keyof T>(
  replacableFields: K[],
  obj: T | undefined
): T | undefined {
  if (obj == null) {
    return obj;
  }

  if (replacableFields.length === 0) {
    return obj;
  }

  const updatedObjWithNull: { [key in keyof T]: unknown } = obj;

  replacableFields.forEach((key: K) => {
    if (obj[key] === undefined) {
      updatedObjWithNull[key] = null;
    }
  });

  return updatedObjWithNull as T;
}
