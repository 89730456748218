import i18n from 'i18next';
import DefaultSubmissionFormProps from 'Feature/DefaultSubmissionForm/models/DefaultSubmissionFormProps';

export const getAssetsDetailsProps = (props: DefaultSubmissionFormProps) => {
  const assetsDetailsProps = {
    testId: 'assetsTest',
    accordionLineColor: 'grey',
    linePosition: 'vertical',
    deleteIcon: props.fields.deleteIcon,
    title: {
      field: {
        value: i18n.t('DefaultSubmission-Card-AssetsHeading')
      }
    },
    primaryAssetSectionTitle: {
      field: {
        value: i18n.t('DefaultSubmission-Card-BorrowerCoborrower')
      }
    },
    guarantorAssetsSection: {
      field: {
        value: i18n.t('DefaultSubmission-Card-GuarantorAssets')
      }
    },
    AddGuarantorText: {
      field: {
        value: i18n.t('DefaultSubmission-Card-AddGuarantor')
      }
    },
    labelDelete: {
      field: {
        value: i18n.t('DefaultSubmission-Card-Delete')
      }
    },
    labelDuplicate: {
      field: {
        value: i18n.t('DefaultSubmission-Card-Duplicate')
      }
    },
    inputFields: {
      primaryResidence: {
        field: {
          value: i18n.t('DefaultSubmission-Card-PrimaryResidence')
        }
      },
      rentalProperty: {
        field: {
          value: i18n.t('DefaultSubmission-Card-RentalProperty')
        }
      },
      cashCashEquivalents: {
        field: {
          value: i18n.t('DefaultSubmission-Card-CashEquivalents')
        }
      },
      vehicle: {
        field: {
          value: i18n.t('DefaultSubmission-Card-Vehicle')
        }
      },
      bankAccount: {
        field: {
          value: i18n.t('DefaultSubmission-Card-BankAccount')
        }
      },
      savingsAccount: {
        field: {
          value: i18n.t('DefaultSubmission-Card-SavingsAccount')
        }
      },
      rrsp: {
        field: {
          value: i18n.t('DefaultSubmission-Card-RRSP')
        }
      },
      other: {
        field: {
          value: i18n.t('DefaultSubmission-Card-OtherOptional')
        }
      },
      totalAssets: {
        field: {
          value: i18n.t('DefaultSubmission-Card-TotalAssets')
        }
      }
    }
  };

  return assetsDetailsProps;
};
