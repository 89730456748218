import React, { FC, memo, useMemo } from 'react';
import Breadcrumb from 'Components/Core/Breadcrumb';
import { BreadcrumbItem } from 'Components/Core/Breadcrumb/type';
import i18n from 'i18next';
import styles from './styles.module.scss';
import { BreadcrumbItemFields, HbtBreadcurmbComponentProps } from './types';

const HbtBreadcrumbComponent: FC<HbtBreadcurmbComponentProps> = ({ fields }) => {
  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => {
    return fields.items.map(({ fields }: BreadcrumbItemFields) => {
      const href = `/${i18n.language}${fields.breadcrumbNavItem?.value?.href}`;
      const isCurrentPage =
        typeof window !== 'undefined' &&
        window.location.pathname === `/${i18n.language}${fields.breadcrumbNavItem?.value.href}`;

      return {
        label: fields.label?.value as string,
        href,
        isCurrentPage
      };
    });
  }, [fields]);

  return (
    <div className={styles.breadcrumbContainer}>
      <Breadcrumb items={breadcrumbItems} condensed={fields.condensed?.value === 'true'} />
    </div>
  );
};

export default memo(HbtBreadcrumbComponent);
