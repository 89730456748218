import React, { createContext, useContext } from 'react';
import { reactPlugin } from './AppInsights';

const AppInsightsContext = createContext(reactPlugin);

type AppInsightsContextProviderProps = {
  children: React.ReactNode;
};

const AppInsightsContextProvider = (props: AppInsightsContextProviderProps) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>{props.children}</AppInsightsContext.Provider>
  );
};

const useAppInsightsContext = () => useContext(AppInsightsContext);

export { AppInsightsContext, AppInsightsContextProvider, useAppInsightsContext };
