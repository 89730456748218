import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { PopperOwnProps } from '@mui/base/Popper/Popper.types';
import { SelectChangeEvent } from '@mui/material';

// Input Options
export interface Option {
  value: string;
  label: string;
  icon?: JSX.Element;
}

export interface MultiOption extends Option {
  isSelected: boolean;
}

export interface GroupedOption {
  groupName: string;
  options: Option[];
}

export interface MultiGroupedOption extends Pick<GroupedOption, 'groupName'> {
  options: MultiOption[];
}

// Normalized
export interface NormalizedGroup {
  isGroup: true;
  label: string;
}

export interface NormalizedNonGroup extends Option {
  isGroup: false;
}

export interface MultiNormalizedNonGroup extends NormalizedNonGroup {
  isSelected: boolean;
}

export type NormalizedOption = NormalizedGroup | NormalizedNonGroup;

export type MultiNormalizedOption = NormalizedGroup | MultiNormalizedNonGroup;

export interface Label {
  text: FieldValue;
  infoHelperText?: FieldValue;
}

export interface DropdownBaseProps {
  options: GroupedOption[] | Option[];
  ariaText?: FieldValue;
  defaultOpened?: boolean;
  readOnly?: boolean;
  className?: string;
  error?: boolean;
  errorMessage?: string;
  helpText?: FieldValue;
  placeholderText?: string;
  placeholderIcon?: JSX.Element;
  label?: Label;
  onChange?: (event: SelectChangeEvent<string>, value: NormalizedNonGroup) => void;
}

export interface DropdownProps extends DropdownBaseProps {
  initialOption?: string;
}

export interface MultiDropdownProps extends DropdownBaseProps {
  initialOptions?: string[];
  selectionNumberText: string;
}

export interface DropdownTagGroupProps {
  selectedOptions: NormalizedNonGroup[] | null;
  setSelectedOptions: Dispatch<SetStateAction<NormalizedNonGroup[] | null>>;
}

export interface DropdownCheckboxItemProps {
  value: string;
  label: string;
  isGroup: boolean;
  selectedOptions: NormalizedNonGroup[] | null;
  children: string | JSX.Element | JSX.Element[];
}

export interface DropdownErrorMessageProps {
  error?: boolean;
  errorMessage?: string;
}

export interface DropdownGroupItemProps {
  index: number;
  label: string;
  value: number; // Need this for mui Select to propagate the SelectChangeEvent
}

export interface DropdownHelpMessageProps {
  helpText?: FieldValue;
}

export interface SelectTextComponentProps {
  selectedOptions: NormalizedNonGroup[] | null;
  clearAllRef: MutableRefObject<HTMLElement>;
  selectionNumberText: string;
  placeholderText?: string;
}

// Type Guards

export const isGroupedOption = (option: GroupedOption | Option): option is GroupedOption =>
  'groupName' in option && 'options' in option;

export const isNormalizedNonGroup = (option: NormalizedOption): option is NormalizedNonGroup => {
  return !option.isGroup;
};

// Action Dropdown Types
export interface ActionItemOption {
  value: string;
  label: string;
  onClick: React.MouseEventHandler<HTMLElement>;
  icon?: JSX.Element;
}

export interface ActionDropdownProps {
  open: boolean;
  isOptionALink?: boolean;
  popperModifiers?: PopperOwnProps['modifiers'];
  onToggle: (value: boolean) => void;
  options: ActionItemOption[];
  trigger?: JSX.Element;
  className?: string;
}

export type BaseDropdownProps = MultiDropdownProps | DropdownProps;

export interface MultiDropdownElementProps {
  name: string;
}
