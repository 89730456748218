export const selectSx = {
  '& .MuiInputBase-input': {
    '&.Mui-readOnly, &.Mui-readOnly:hover': {
      border: 'none !important',
      cursor: 'default',
      background: 'var(--Accent-Slate-90W, #F0F2F3) !important',
      '[data-role="select-text"]': {
        color: 'var(--text-links-text-body, #454E56) !important'
      }
    }
  },
  '&.Mui-readOnly .MuiSelect-icon': {
    display: 'none !important',
    visibility: 'hidden !important'
  },
  '&.Mui-readOnly': {
    cursor: 'not-allowed !important'
  }
};
