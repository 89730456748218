import React from 'react';
import i18n from 'i18next';

import {
  NotificationCard,
  NotificationCardProps
} from 'Feature/CommonComponents/ContentComponents';
import { CardType } from 'Feature/CommonComponents/Enums';
import { DisplayCardProps } from './types';

export const formValidationErrorCard = {
  id: 'formValidationErrorNotification',
  notificationType: CardType.VERTICAL_ERROR,
  formWarningIcon: {
    src: 'dontuse',
    alt: 'Form Error'
  }
};

const NotificationCardComponent: React.FC<any> = ({
  errors,
  fileNames,
  warningIcon
}: DisplayCardProps) => {
  let cardTitle = 'Main';
  let submissionErrors = 0;
  let arrearsFilesErrors = 0;
  let message: string[] = [];
  let totalErrors: number = 0;
  let notificationCard: NotificationCardProps | null = null;

  if (errors != null && fileNames == null) {
    if (typeof errors === 'string') {
      message.push(
        i18n.t(`ArrearsSubmission-ErrorCard-${errors.charAt(0).toUpperCase() + errors.slice(1)}`)
      );
      totalErrors = 1;
    } else {
      Object.keys(errors)?.map((type: string) => {
        switch (type) {
          case 'submission':
            submissionErrors =
              (errors.submission.contact && Object.keys(errors.submission.contact).length) +
              (errors.submission.reportingPeriod != null ? 1 : 0);
            message.push(i18n.t('ArrearsSubmission-ErrorCard-SectionA'));
            break;
          case 'arrearsFiles':
            Object.keys(errors.arrearsFiles).forEach((index: string) => {
              arrearsFilesErrors =
                arrearsFilesErrors + errors && Object.keys(errors.arrearsFiles[index]).length;
            });
            message.push(i18n.t('ArrearsSubmission-ErrorCard-SectionB'));
            break;
          default:
            break;
        }
        return null;
      });
      totalErrors = submissionErrors + arrearsFilesErrors;
    }
  } else if (fileNames != null && errors == null) {
    cardTitle = 'File';
    message = fileNames;
  }

  notificationCard = {
    ...formValidationErrorCard,
    title: i18n
      .t(`ErrorManagement-ValidationCard-${cardTitle}Title`)
      .replace('{FieldValue}', totalErrors.toString()),
    message: message?.sort(),
    formWarningIcon: warningIcon ?? formValidationErrorCard.formWarningIcon
  };

  return (
    <>
      {notificationCard != null && (
        <div className="row col-12">
          <NotificationCard {...notificationCard} />
        </div>
      )}
    </>
  );
};

export default NotificationCardComponent;
