import React, { memo, useCallback, useMemo } from 'react';
import { AppBar, Box, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { pathNames } from 'Constants';
import HeaderComponentProps from 'Feature/Header/models/HeaderComponentProps';
import { useAuthenticationContext } from 'Foundation/Authentication';
import i18n from 'i18next';
import { useLocation } from 'react-router-dom';
import RoutableSitecoreLink from '../../../../RoutableSitecoreLink';
import HbtLanguageSwitcher from '../HbtLanguageSwitcher';
import styles from './styles.module.scss';

const HbtGenericHeaderComponent = (props: HeaderComponentProps) => {
  const location = useLocation();
  const authenticationContext = useAuthenticationContext();

  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'));

  const appBarStyles = useMemo(
    () =>
      `${styles.appBar} ${!isMediumUp ? styles.tabletBar : ''} ${
        !authenticationContext.isAuthenticated ? styles.transparentBar : ''
      }`,
    [authenticationContext.isAuthenticated, isMediumUp]
  );

  const toolbarStyles = useMemo(
    () =>
      `${styles.toolbar} ${
        !authenticationContext.isAuthenticated ? styles.rightContainerOnly : ''
      }`,
    [authenticationContext.isAuthenticated]
  );

  const handleSkipToContent = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const normalizedPathname = location?.pathname.replace(/\/+$/, '');
    const errorPaths = [
      `/${i18n.language}${pathNames.error404}`,
      `/${i18n.language}${pathNames.error500}`,
      `/${i18n.language}${pathNames.errorApplication}`,
      pathNames.error404,
      pathNames.error500,
      pathNames.errorApplication
    ];
    const isErrorPath = errorPaths.some((path) => normalizedPathname.startsWith(path));

    if (isErrorPath) {
      const headerLogoElement = document.querySelector('#header-logo') as HTMLElement;
      headerLogoElement.focus();
    } else {
      e.currentTarget.blur();
    }
  }, []);

  return (
    <>
      {authenticationContext.isAuthenticated && (
        <AppBar
          role="banner"
          position="static"
          className={appBarStyles}
          aria-label="Global Header"
          id="header-app-bar"
        >
          <Toolbar disableGutters className={toolbarStyles}>
            {authenticationContext.isAuthenticated && (
              <Box className={styles.leftContainer}>
                <a onClick={handleSkipToContent} className={styles.skipLink} href="#header-logo">
                  <u>{props?.fields?.skipContentLabel?.value ?? ''}</u>
                </a>
                <RoutableSitecoreLink link={props.fields.headerHomeLink} id="header-logo">
                  <div className={styles.logoTitleContainer}>
                    <Box className={styles.logoContainer}>
                      <Image field={props.fields.headerLogo} className={styles.headerLogoImage} />
                    </Box>
                    <div className={styles.textWrapper}>
                      <p className={styles.eyebrowHeaderText} id="eyebrow-header-text">
                        {props.fields.headerHeading.value}
                      </p>
                      <p className={styles.headerText} id="header-text">
                        {props.fields.headerSubTitle.value}
                      </p>
                    </div>
                  </div>
                </RoutableSitecoreLink>
              </Box>
            )}

            <Box className={styles.rightContainer}>
              <HbtLanguageSwitcher />
            </Box>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default memo(HbtGenericHeaderComponent);
