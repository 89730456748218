import { JSXElementConstructor, ReactElement, ReactNode } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { MouseOrKeyboardEvent } from '../Modal/types';

export type InferDataModel<T> = {
  [P in keyof T]: T[P];
};

export interface DataTableProps<T extends object> {
  children: ReactNode;
  data: T[];
  uniqueKey: keyof T;

  emptyTableMessage?: string;
  deleteButtonLabel?: string;
  sortedColumn: string | number | symbol | null; // more strict check than keyof T;
  submitButtonLabel?: string;
  tableCaption?: string;
  tableErrorMessage?: string;

  clearSelectionRows?: boolean;
  columnResize?: boolean;
  deleteItem?: boolean;
  isLoading: boolean;
  isDeleteButtonLoading?: boolean;
  isSubmitButtonLoading?: boolean;
  isInternalUser?: boolean;
  multipleRowSubmission?: boolean;
  sortAscending: boolean;
  stickyFirstColumn?: boolean;

  errorType?: ErrorType | null;
  footer?: React.ReactNode;

  onColumnsReady?: (
    columns: ReactElement<DataTableColumnProps<T>, string | JSXElementConstructor<any>>[]
  ) => void;
  onDelete?: (e?: MouseOrKeyboardEvent) => void;
  onRowClick?: (rowId: T[keyof T]) => void;
  onRowSelectionChange?: (selectedRows: T[]) => void;
  onRowSelectionCleared?: () => void;
  onSort: (column: keyof T) => void;
  onSubmit?: (selectedRows: T[]) => void;
}

export interface DataTableContainerProps<T> {
  dataModel: T;
  enableColumnResize?: boolean;
  enableMultipleRowSubmission?: boolean;
  enableStickyFirstColumn?: boolean;
  showPagination?: boolean;
  showSearchBar?: boolean;
}

export enum SortIconPosition {
  Leading = 'leading',
  Trailing = 'trailing'
}

export enum ContentAlignment {
  Center = 'Center',
  Start = 'Start',
  End = 'End'
}

interface TableCellBaseProps {
  alignment?: ContentAlignment;
  leadingIcon?: ReactJSXElement;
  trailingIcon?: ReactJSXElement;
}

interface SortIconProps {
  sortable?: boolean;
  sortIconPosition?: SortIconPosition;
}

export interface DataTableHeaderProps extends TableCellBaseProps, SortIconProps {
  label: string;
  isSorting?: boolean;
  sortAscending?: boolean;
  onSort?: () => void;
}

type CellIconProps = Omit<TableCellBaseProps, 'alignment'>;

export interface DataTableColumnProps<T> extends CellIconProps, SortIconProps {
  children?: (cellData: T[keyof T], rowData?: T) => ReactNode;
  displayName: string;
  name: string;
  width?: string;
  filterable?: boolean;
  rowAlignment?: ContentAlignment;
  headerAlignment?: ContentAlignment;
}

export enum ErrorType {
  CannotDownload = 'CannotDownload',
  NoSelectedCheckbox = 'NoSelectedCheckbox'
}
