import React from 'react';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import styles from '../styles.module.scss';
import { SelectTextComponentProps } from '../types';

export const getSelectTextReactNode = (props: SelectTextComponentProps): (() => JSX.Element) => {
  return (): JSX.Element => {
    const clearAllIcon = <HbtIcon size={HbtIconSize.SMALL} type="icon_clear_circle" />;

    const getSelectionText: () => string = () => {
      if (!props.selectedOptions) {
        return '';
      }
      const nbSelectedElements = props.selectedOptions.length ?? 0;
      if (nbSelectedElements == 0) {
        return props.placeholderText || 'Choose an option';
      }
      if (nbSelectedElements === 1) {
        return props.selectedOptions[0].label;
      }
      return `${nbSelectedElements} ${props.selectionNumberText}`;
    };

    const getSelectionTextStyles = () => {
      const nbSelectedElements = props.selectedOptions?.length ?? 0;
      return nbSelectedElements > 0
        ? `${styles.textContainerText} ${styles.selectionText}`
        : `${styles.textContainerText}`;
    };

    return (
      <div className={styles.textContainer}>
        <div data-role="select-text" className={getSelectionTextStyles()}>
          {getSelectionText()}
        </div>
        <span className={styles.textContainerIcon} id="clearAllIcon" ref={props.clearAllRef}>
          {clearAllIcon}
        </span>
      </div>
    );
  };
};
