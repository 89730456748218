import React, { useMemo } from 'react';
import i18n from 'i18next';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { LanguageShort, ModuleMapping, UserRole } from '@hobt/constants';
import { ClaimColumnName, ExternalClaimStatus, InternalClaimStatus } from '@hobt/claim-domain';

import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { MenuItemProps } from 'Components/Common/ActionMenu/types';
import { DataTable } from 'Components/Common/DataTable';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableFooter } from 'Components/Common/DataTableFooter';
import { Tabs } from 'Components/Navigation/Tabs';
import { PageCardContainer } from 'Components/PageComponents/PageCardContainer';
import { PageCardHeaderWithAddButton } from 'Components/PageComponents/PageCardHeaderWithAddButton';
import { useDataGridEngine } from 'Components/Hooks/DataGridEngine';
import { useWindowDimensions } from 'Components/Hooks/WindowDimensions';

import { Modal } from 'Components/Common/Modal';
import { SearchBar } from 'Components/PageComponents/SearchBar';
import FormToggle from 'Components/Inputs/FormToggle';
import { SearchFieldOptions } from 'Components/PageComponents/SearchBar/types';
import { ModalProps } from 'Components/Common/Modal/types';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';

import useClaimsInventoryFunctions from 'Feature/Claims/components/ClaimsInventory/useClaimsInventoryFunctions';

import {
  ClaimsInventoryProps,
  ClaimsInventoryRecord,
  ClaimsInventoryTabEnum,
  ErrorType
} from 'Feature/Claims/components/ClaimsInventory/types';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';

import { CancelClaimFlyoutData } from '../Details/AdjudicationCardHeader/CancelClaim/types';

import { CancelClaimForm } from '../Details/AdjudicationCardHeader/CancelClaim';
import { ClaimStatusType } from './types';
import { config } from '../../../../config';
import ExternalTableHeader from './ExternalTableHeader';
import ExternalTableRow from './ExternalTableRow';
import InternalTableHeader from './InternalTableHeader';
import InternalTableRow from './InternalTableRow';
import useDeleteClaimFunctions from './useDeleteClaimFunctions';
import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const EMPTY_VALUE_PLACEHOLDER = '--';
const DRAFT_CLAIM_TABS = [ClaimsInventoryTabEnum.Draft, ClaimsInventoryTabEnum.ProcessInMics];

const ClaimsInventory = ({ fields }: ClaimsInventoryProps) => {
  const {
    currentTab,
    cancelClaimShowSideDrawer,
    hasSearched,
    items,
    showCancelClaimStatusToast,
    showCancelClaimState,
    siteTypeIsInternal,
    tabCountsLoading,
    isLoading,
    onApiErrorCallback,
    onCancelClaimErrorSubmit,
    onCancelClaimSuccessSubmit,
    onCreateNew,
    onRowClick,
    onTabClick,
    closeCancelToast,
    deleteDraftClaim,
    setCurrentTab,
    setHasSearched,
    tabFilterQueryLookupInternal,
    tabFilterQueryLookupExternal,
    setIsLoading,
    getTabCounts,
    setTabCounts,
    showCancelClaimForm,
    hideCancelClaimForm,
    cancelClaimFlyoutData,
    setCancelClaimFlyoutData,
    showFinalClaimInventory
  } = useClaimsInventoryFunctions({ fields });

  const {
    deleteClaimID,
    errorType,
    showPageModal,
    showDeleteClaimToast,
    showServerErrorToast,
    isModalLoading,
    setIsModalLoading,
    onDeleteClick,
    onDraftDeleteSuccessCallback,
    primaryCallback,
    secondaryCallback,
    setShowPageModal,
    closeDeleteClaimToast
  } = useDeleteClaimFunctions();

  const isDraftClaimTab = DRAFT_CLAIM_TABS.includes(currentTab?.name as ClaimsInventoryTabEnum);

  const {
    rowData,
    currentPage,
    itemsPerPage,
    onItemsPerPageChange,
    totalItems,
    totalPages,
    itemRangeFrom,
    itemRangeTo,
    isLoading: isInventoryLoading,
    onPagerClick,
    onSort,
    getSortDirectionForField,
    onSearch,
    onSearchReset,
    toggleShowMyInventory,
    onToggleShowMyInventory,
    getTableAccessibilityText,
    refresh
  } = useDataGridEngine<ClaimsInventoryRecord>({
    apiBaseUrl:
      isDraftClaimTab === true
        ? config.claimApi?.urls?.draftClaim
        : config.claimApi?.urls?.finalClaim,
    onApiErrorCallback,
    defaultItemsPerPage: 20,
    currentTab,
    captionText: fields.tableAccessibilityText?.value,
    tabFilterQueryLookup: siteTypeIsInternal
      ? tabFilterQueryLookupInternal
      : tabFilterQueryLookupExternal
  });

  const { width } = useWindowDimensions();

  useMemo(() => {
    getTabCounts().then((counts) => setTabCounts(counts));
  }, []);

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;

  const onCancelClick = async (id: number | string) => {
    const clickedRowClaimData = rowData?.find((x) => x.claimID?.toString() === id);

    const clickedRowClaimFlyoutData: CancelClaimFlyoutData = {
      claimId: id.toString(),
      claimTypeCode: clickedRowClaimData?.claimTypeCode,
      cmhcLoanAccountNumber: clickedRowClaimData?.cmhcLoanAccountNumber
    };

    setCancelClaimFlyoutData(clickedRowClaimFlyoutData);

    showCancelClaimForm();
  };

  const internalRowActionItems: MenuItemProps[] = [
    {
      name: 'cancel',
      icon: 'clear',
      displayText: fields.cancelButtonLabel,
      onClickCallback: onCancelClick,
      actionItemId: 0
    }
  ];

  const isBusinessUser =
    isUserInRoles(ModuleMapping.claim, [UserRole.BusinessUser], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  const isReadOnlyAccessUser: boolean =
    isUserInRoles(ModuleMapping.claim, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  const isMasterUser =
    isUserInRoles(ModuleMapping.claim, [UserRole.MasterUser], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  if (isMasterUser) {
    internalRowActionItems.push({
      name: 'delete',
      icon: fields.deleteIcon,
      displayText: fields.delete,
      disable: true,
      onClickCallback: onDeleteClick,
      actionItemId: 0
    });
  }

  const pageModal: ModalProps = {
    type: ApplicationStates.SUCCESS,
    style: 'success',
    title: fields.deleteClaimModal?.fields.heading?.value || '',
    content: fields.deleteClaimModal?.fields.content?.value,
    isActive: false,
    onCloseCallback: () => {
      setShowPageModal(false);
    }
  };

  const onDeleteDraftClick = (id: number | string) => {
    const draftClaim: ClaimsInventoryRecord | undefined = rowData?.find(
      (claim: ClaimsInventoryRecord) => claim.draftClaimRecordID === id
    );

    if (draftClaim?.draftClaimRecordID == null) {
      return;
    }

    onDeleteClick(id);
  };

  const deleteDraft = async () => {
    try {
      setIsModalLoading(true);

      const response = await deleteDraftClaim(deleteClaimID.toString());
      setIsModalLoading(false);

      if (response) {
        onDraftDeleteSuccessCallback(refresh);
        setCurrentTab(items[siteTypeIsInternal ? 6 : 5]);
      }
    } catch (error) {
      setIsModalLoading(false);
      // @ts-ignore
      throw new Error(`Could not delete draft claim. ${error?.message}`);
    }
  };

  const externalRowActionMenu: MenuItemProps[] = isDraftClaimTab
    ? [
        {
          name: 'delete',
          icon: 'delete',
          displayText: fields.delete,
          onClickCallback: onDeleteDraftClick,
          actionItemId: 0
        }
      ]
    : [
        {
          name: 'cancel',
          icon: 'clear',
          displayText: fields.cancelButtonLabel,
          onClickCallback: onCancelClick,
          actionItemId: 0
        }
      ];

  const internalRowActionMenu: MenuItemProps[] = isDraftClaimTab
    ? [
        {
          name: 'delete',
          icon: 'delete',
          displayText: fields.delete,
          onClickCallback: onDeleteDraftClick,
          actionItemId: 0
        }
      ]
    : internalRowActionItems;

  const searchByFields: SearchFieldOptions[] = siteTypeIsInternal
    ? [
        {
          displayText: fields.loanLabel?.value ?? '',
          value: 'cmhcLoanAccountNumber',
          type: 'number'
        },
        {
          displayText: fields.lenderNameLabel?.value ?? '',
          value:
            i18n.language === 'en'
              ? ClaimColumnName.ApprovedLenderEnglishName
              : ClaimColumnName.ApprovedLenderFrenchName,
          type: 'text'
        }
      ]
    : [
        {
          displayText: fields.loanLabel?.value ?? '',
          value: 'cmhcLoanAccountNumber',
          type: 'number'
        },
        {
          displayText: fields.lenderReferenceLabel?.value ?? '',
          value: 'approvedLenderReferenceNumber',
          type: 'text'
        }
      ];

  const claimStatusTypes: ClaimStatusType[] = fields.claimStatusTypes?.fields?.listItems?.map(
    (listItem) => {
      const value: number = parseInt(listItem?.fields?.itemValue?.value ?? '0', 10);
      const className: string = `status-${siteTypeIsInternal ? 'internal' : 'external'}-${
        siteTypeIsInternal
          ? InternalClaimStatus[value]?.toLowerCase() ?? 'status grey20'
          : ExternalClaimStatus[value]?.toLowerCase() ?? 'status grey20'
      }`;

      return {
        value,
        text: listItem?.fields?.itemName?.value ?? '',
        className
      };
    }
  );

  const getToastNotificationTitle = () => {
    switch (showCancelClaimState || showDeleteClaimToast) {
      case ApplicationStates.SUCCESS:
        return i18n.t('Globals-Toast-Success-Title');
      case ApplicationStates.ERROR:
        return i18n.t('Globals-Toast-Error-Title');
      default:
        return '';
    }
  };

  const getToastNotificationContent = () => {
    if (showCancelClaimState === ApplicationStates.SUCCESS) {
      return i18n.t('CancelClaim-SuccessToast-Cancel');
    }

    if (showDeleteClaimToast === ApplicationStates.SUCCESS) {
      return i18n.t('DeleteClaim-SuccessToast-Delete');
    }

    if (
      showDeleteClaimToast === ApplicationStates.ERROR &&
      errorType === ErrorType.ClaimOutOfOrder
    ) {
      return i18n.t('Errors-HBT_ERR_5039');
    }

    if (
      showCancelClaimState === ApplicationStates.ERROR ||
      showDeleteClaimToast === ApplicationStates.ERROR
    ) {
      return i18n.t('Globals-Toast-Error-Action-NotComplete');
    }

    return '';
  };

  const isActionButtonHiddenProcessInMicsTab =
    !isMasterUser && currentTab.name === ClaimsInventoryTabEnum.ProcessInMics;

  return (
    <PageCardContainer name="claimsInventory">
      <ContentLoadingModal
        display={isLoading?.isLoading}
        fields={{
          heading: { value: i18n.t(isLoading?.spinnerHeading ?? 'Global-InProgress-Heading') },
          description: {
            value: i18n.t(isLoading?.spinnerDescription ?? 'Global-InProgress-Description')
          }
        }}
      />
      <div
        className={`${styles.claimsInventoryCardHeader} ${
          i18n.language === LanguageShort.French ? styles.claimsInventoryCardHeaderFrench : ''
        }`.trim()}
      >
        <PageCardHeaderWithAddButton
          title={fields.heading}
          addButtonText={fields.createNew}
          addButtonIsSecondary={false}
          addButtonCallback={onCreateNew}
          disabled={isReadOnlyAccessUser || isBusinessUser}
        />
      </div>
      <Tabs
        items={items}
        initialActiveTabName="all"
        tabOnClickCallback={onTabClick}
        ariaControlsName="claimsDataTable"
        label={fields.inventoryTypeDropdownLabel}
        className={styles.inventoryTabs}
      />

      <div className={styles.tableToolsWrapper}>
        <div
          className={styles.searchBarWrapper}
          style={!siteTypeIsInternal ? { paddingRight: '0' } : {}}
        >
          <SearchBar
            name="claimsInventorySearchBar"
            fieldLabelAriaText={i18n.t('SearchBar-SearchField')}
            clearButtonAriaText={i18n.t('SearchBar-ClearButton')}
            searchButtonAriaText={i18n.t('SearchBar-SearchButton')}
            placeholderText={i18n.t('SearchBar-PlaceholderText')}
            searchFieldOptions={searchByFields}
            onClear={() => {
              setHasSearched(false);
              onSearchReset();
            }}
            onSearch={(field: string, value: string) => {
              setHasSearched(true);
              onSearch(field, value);
            }}
            count={totalItems}
            displaySearchResultMessage={false}
          />
        </div>
        <div
          className={
            !siteTypeIsInternal || currentTab?.name === ClaimsInventoryTabEnum.Draft
              ? styles.removeToggle
              : styles.showMyInventoryWrapper
          }
        >
          <FormToggle
            onChange={onToggleShowMyInventory}
            isChecked={toggleShowMyInventory}
            id="showMyInventoryToggle"
            testId="showMyInventoryToggle"
            dynamicText={[fields.onlyShowLabel?.value ?? '', fields.onlyShowLabel?.value ?? '']}
          />
        </div>
      </div>
      {hasSearched && totalItems != null && totalItems > 0 ? (
        <div className={styles.noSearchResultsMessage}>
          <Text field={{ value: `${totalItems}  ${i18n.t('Globals-Table-Search-To-Display')}` }} />
        </div>
      ) : (
        ''
      )}
      {totalItems === 0 && !showFinalClaimInventory && (
        <div className={styles.noSearchResultsMessage}>
          <Text field={{ value: i18n.t('Globals-Table-No-Search-To-Display') }} />
        </div>
      )}

      {showFinalClaimInventory ? (
        <div className={styles.noSearchResultsMessage}>
          <Text field={{ value: i18n.t('Globals-Toast-Error-Action-NotComplete') }} />
        </div>
      ) : (
        <div className={styles.dataTable}>
          <DataTable
            name="claimsDataTable"
            caption={{ value: getTableAccessibilityText() ?? '' }}
            isLoading={isInventoryLoading || tabCountsLoading}
            hideTableShiftBtnsForDesktop={
              currentTab?.name === ClaimsInventoryTabEnum.Draft && i18n.language === 'en'
            }
            tableClass={
              currentTab?.name === ClaimsInventoryTabEnum.Draft ? styles.draftTableWithScroll : ''
            }
            scrollOnHorizontalOverflow
          >
            <DataTableHeader>
              {siteTypeIsInternal ? (
                <InternalTableHeader
                  fields={fields}
                  currentTab={currentTab}
                  onSort={onSort}
                  getSortDirectionForField={getSortDirectionForField}
                  hideRowActionMenu={isReadOnlyAccessUser || isActionButtonHiddenProcessInMicsTab}
                />
              ) : (
                <ExternalTableHeader
                  fields={fields}
                  currentTab={currentTab}
                  onSort={onSort}
                  getSortDirectionForField={getSortDirectionForField}
                />
              )}
            </DataTableHeader>

            <DataTableBody zebra>
              {rowData?.map((row: ClaimsInventoryRecord) => (
                <React.Fragment key={row.claimID}>
                  {siteTypeIsInternal === true ? (
                    <InternalTableRow
                      isDraftClaimTab={isDraftClaimTab}
                      currentTab={currentTab}
                      row={row}
                      onRowClick={onRowClick}
                      emptyValuePlaceholder={EMPTY_VALUE_PLACEHOLDER}
                      rowActionMenu={internalRowActionMenu}
                      claimStatusTypes={claimStatusTypes}
                      hideActionButtonCell={
                        isReadOnlyAccessUser || isActionButtonHiddenProcessInMicsTab
                      }
                      claimType={fields.typeofClaim?.fields?.listItems}
                      claimTypeIndicator={fields?.claimTypeIndicator}
                    />
                  ) : (
                    <ExternalTableRow
                      isDraftClaimTab={isDraftClaimTab}
                      currentTab={currentTab}
                      row={row}
                      onRowClick={onRowClick}
                      emptyValuePlaceholder={EMPTY_VALUE_PLACEHOLDER}
                      rowActionMenu={externalRowActionMenu}
                      claimStatusTypes={claimStatusTypes}
                      claimType={fields.typeofClaim?.fields?.listItems}
                      claimTypeIndicator={fields?.claimTypeIndicator}
                    />
                  )}
                </React.Fragment>
              ))}
            </DataTableBody>
          </DataTable>

          <DataTableFooter
            name="claimsInventoryTable"
            currentPage={currentPage}
            totalPages={totalPages || 1}
            itemsPerPageLabel={{
              value: i18n.t('DefaultsInventoryTable-ResultsPerPageDropdown-Title')
            }}
            // TODO: Replace with actual values when API is wired in.
            itemsXofYText={`${itemRangeFrom} - ${itemRangeTo}
          ${i18n.t('Globals-Grid-ResultsPerPageDropdown-Of')}`}
            totalItems={totalItems}
            pagerClickCallback={onPagerClick}
            itemsPerPageOnChangeCallback={onItemsPerPageChange}
            itemsPerPageOptions={[
              { fields: { itemName: { value: '10' }, itemValue: { value: '10' } } },
              { fields: { itemName: { value: '20' }, itemValue: { value: '20' } } },
              { fields: { itemName: { value: '50' }, itemValue: { value: '50' } } },
              { fields: { itemName: { value: '100' }, itemValue: { value: '100' } } }
            ]}
            itemsPerPageValue={itemsPerPage.toString()}
            width={width}
          />
          <ToastNotification
            type={showCancelClaimState}
            isActive={showCancelClaimStatusToast}
            title={getToastNotificationTitle()}
            content={{
              value: getToastNotificationContent()
            }}
            onCloseCallback={closeCancelToast}
          />
          <fieldset className={`${styles.sideDrawerCancelClaim}`}>
            <SideDrawer
              isActive={cancelClaimShowSideDrawer}
              handleOutsideClick={hideCancelClaimForm}
            >
              {/* Pass seisloading as a prop because ot's being passed in two diff places ub subsmision and details so can't just use context from details since context doesn't exist in submission */}
              <CancelClaimForm
                onCancelCallback={hideCancelClaimForm}
                onSuccessCallback={() => {
                  onCancelClaimSuccessSubmit();
                }}
                setIsLoadingCallback={setIsLoading}
                loadingStatus={isLoading}
                onErrorCallback={onCancelClaimErrorSubmit}
                fields={fields?.cancelClaimFlyout.fields}
                cancelClaimData={cancelClaimFlyoutData}
              />
            </SideDrawer>
          </fieldset>
          <ToastNotification
            type={showDeleteClaimToast}
            isActive={showDeleteClaimToast}
            title={getToastNotificationTitle()}
            content={{
              value: getToastNotificationContent()
            }}
            onCloseCallback={closeDeleteClaimToast}
          />
          <ToastNotification
            type={ApplicationStates.ERROR}
            isActive={showServerErrorToast}
            title={i18n.t('Globals-Toast-Server-Error-Title')}
            content={{
              value: i18n.t('Globals-Toast-Error-Action-NotComplete')
            }}
            onCloseCallback={closeDeleteClaimToast}
          />
          <Modal
            type={pageModal.type}
            style={pageModal.style}
            isActive={showPageModal}
            title={pageModal.title}
            onCloseCallback={() => setShowPageModal(false)}
            onPrimaryCallback={() => {
              if (isDraftClaimTab === true) {
                deleteDraft();
                setShowPageModal(false);
              } else {
                primaryCallback(refresh);
              }
            }}
            onSecondaryCallback={secondaryCallback}
            content={pageModal.content}
            fields={{
              saveButton: {
                value: fields.deleteClaimModal?.fields.acceptButton?.value
              },
              cancelButton: { value: fields.deleteClaimModal?.fields.cancelButton?.value }
            }}
            isLoading={isModalLoading}
          />
        </div>
      )}
    </PageCardContainer>
  );
};

export default ClaimsInventory;
