/* eslint-disable*/
import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import { DropdownOptionType } from 'Feature/UserManagement/models/UserAccessProps';
import { useController, useFormContext } from 'react-hook-form';
import styles from './styles.module.scss';
import { ValueType } from 'react-select/src/types';

interface CheckboxDropdownProps {
  name: string;
  options?: DropdownOptionType[];
  selectedValues?: string[]; // Add this prop for the selected values
  placeholder: string;
  isDisabled?: boolean;
}

interface CustomOptionProps {
  children: React.ReactNode;
  innerProps: any;
  data: DropdownOptionType;
  isSelected: boolean;
}

const CustomOption: React.FC<CustomOptionProps> = ({ children, data, isSelected, innerProps }) => (
  <div {...innerProps} className={`${styles.customOption}`}>
    <input type="checkbox" checked={isSelected} readOnly />
    <label>{data.label}</label>
  </div>
);

const CheckboxDropdown: React.FC<CheckboxDropdownProps> = ({
  name,
  options = [],
  selectedValues, // Use the selectedValues prop here
  placeholder,
  isDisabled = false
}) => {
  const { register, watch, setValue } = useFormContext();
  const [internalSelectedValues, setInternalSelectedValues] = useState<string[]>(
    selectedValues || []
  );

  useEffect(() => {
    selectedValues && setInternalSelectedValues(selectedValues);
  }, [selectedValues]);

  const {
    field: { onChange, ref, ...field }
  } = useController({
    name
  });

  const handleChange = (selectedOptions: ValueType<DropdownOptionType, true>) => {
    if (Array.isArray(selectedOptions)) {
      const newSelectedValues = selectedOptions.map((option) => option.value);
      setInternalSelectedValues(newSelectedValues);
      setValue(name, newSelectedValues); // Update the form field value
    }
  };

  return (
    <Select
      {...field}
      options={options}
      value={options.filter(
        (option) => internalSelectedValues && internalSelectedValues.includes(option.value)
      )}
      isMulti
      {...register}
      closeMenuOnSelect={false}
      isDisabled={isDisabled}
      placeholder={internalSelectedValues?.join(', ') || placeholder}
      components={{ Option: CustomOption }}
      hideSelectedOptions={false}
      onChange={handleChange}
      // @ts-ignore
      watch={watch}
      aria-label={name}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          minHeight: '50px',
          borderColor: state.isFocused ? '#005C84' : '#CCCCCC',
        }),
        placeholder: (defaultStyles) => {
          return {
              ...defaultStyles,
              color: '#000000',
          }
      }
      }}
    />
  );
};

export default CheckboxDropdown;
