import React, { FC, useState } from 'react';

import DynamicHeading, { HeadingLevel } from 'Components/Common/DynamicHeading';

import styles from './styles.module.scss';

const CollapsingSection: FC<{
  id?: React.HTMLAttributes<HTMLElement>['id'];
  heading: JSX.Element | string;
  headingLevel: HeadingLevel;
  defaultCollapsed?: boolean;
  sectionClass?: React.HTMLAttributes<HTMLElement>['className'];
  buttonClass?: React.HTMLAttributes<HTMLElement>['className'];
  contentClass?: React.HTMLAttributes<HTMLElement>['className'];
  children?: React.ReactNode;
}> = ({
  id,
  heading,
  headingLevel,
  defaultCollapsed = false,
  sectionClass,
  buttonClass,
  contentClass,
  children
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  const toggle = function _sectionToggle() {
    setCollapsed(!collapsed);
  };

  return (
    <section className={sectionClass}>
      <DynamicHeading headingLevel={headingLevel} className="w-100 mb-0">
        <button
          type="button"
          className={`${styles['heading-button']} ${buttonClass ?? ''}]`.trim()}
          onClick={toggle}
          aria-controls={id}
          aria-expanded={!collapsed}
          data-testid={`${id}CollapsingSectionButton`}
        >
          {heading}
          <span className="material-icons align-self-center">
            {collapsed ? 'expand_more' : 'expand_less'}
          </span>
        </button>
      </DynamicHeading>
      <div
        id={id}
        className={`${styles['collapsing-section']} ${
          collapsed ? 'd-none' : contentClass ?? ''
        }`.trim()}
      >
        {children}
      </div>
    </section>
  );
};

export default CollapsingSection;
