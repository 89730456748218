import { useTranslation } from 'react-i18next';
import { defaultEmptyString } from 'Components/Common/Api/utils/HandleEmpty';

export const useTranslationHelpers = () => {
  const { i18n } = useTranslation();

  const toCurrency = (
    amount?: number | null,
    canBeCredit?: boolean,
    emptyString?: string
  ): string => {
    if (amount == null || Number.isNaN(amount)) {
      return emptyString ?? defaultEmptyString;
    }

    const language = i18n.language ? `${i18n.language}-CA` : 'en-CA';
    const options = { style: 'currency', currency: 'CAD' };

    const formattedCurrency = new Intl.NumberFormat(language, options)
      .format(Math.abs(amount))
      .replace('CA', '')
      .trim();

    let prefix = '';
    const postfix = canBeCredit && amount < 0 ? ')' : '';

    if (amount < 0) {
      prefix = canBeCredit ? '(' : '-';
    }

    return `${prefix} ${formattedCurrency} ${postfix}`;
  };

  const toFormatNumber = (amount?: number | null, emptyString?: string): string => {
    if (amount === undefined || amount === null) {
      return emptyString ?? defaultEmptyString;
    }

    const language = i18n.language ? `${i18n.language}-CA` : 'en-CA';
    const formattedNumber = new Intl.NumberFormat(language)
      .format(Math.abs(amount))
      .replace(',', '')
      .trim();

    return `${formattedNumber}`;
  };

  const toLocaleDate = (
    dateString?: string | null,
    emptyString: string = defaultEmptyString
  ): string => {
    if (dateString === undefined || dateString === null) {
      return emptyString ?? defaultEmptyString;
    }

    try {
      const date = new Date(Date.parse(dateString.replace(/-/g, '/').replace(/T.+/, '')));
      const year = date.getFullYear();
      const month = `0${(date.getMonth() + 1).toString(10)}`.slice(-2);
      const day = `0${date.getDate().toString(10)}`.slice(-2);

      return `${year}-${month}-${day}`;
    } catch (_e) {
      return emptyString;
    }
  };

  return {
    toCurrency,
    toLocaleDate,
    toFormatNumber
  };
};
