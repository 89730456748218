/* eslint-disable */
import { useMemo } from "react";
import { isGroupedOption, MultiNormalizedOption, GroupedOption, Option, NormalizedOption } from '../types';

export type MultiOrSingleOption = MultiNormalizedOption | NormalizedOption;

export const useNormalizedOptions = (options: GroupedOption[] | Option[]) => {
    return useMemo(() => {
        const normalized: NormalizedOption[] = [];
  
        options.forEach((option) => {
          if (isGroupedOption(option)) {
            normalized.push({ isGroup: true, label: option.groupName });
            if (option.options) {
              option.options.forEach((opt) => normalized.push({ ...opt, isGroup: false }));
            }
          } else {
            normalized.push({ ...option, isGroup: false });
          }
        });
  
        return normalized;
      }, [JSON.stringify(options)]);
};
