import { HttpResponseStatusCodes } from '@hobt/constants';
import { FileTransferFile, FileTransferFilesResponse } from '@hobt/portfolio-domain';
import { AxiosRequestConfig } from 'axios';
import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { AuthenticationContextType } from 'Foundation/Authentication';
import i18n from 'i18next';
import { config } from '../../../../../config';

const apiClientConfig: ApiClientConfig = {
  timeout: Number(config.defaultApi.requestTimeout) || 2000
};

const fileTransferUrl: string = config.fileTransferApi.urls.files;
const fileTransferTimeout: number = config.fileTransferApi.requestTimeout;
const downloadUrl: string = config.fileTransferApi.urls.downloadFile;
const deleteUrl: string = config.fileTransferApi.urls.deleteFile;

const mapResponseToFile = (responseData: FileTransferFile, locale: string): FileTransferFile => {
  const lenderNames = responseData.lenderName.split('|');
  let lenderName =
    lenderNames.length === 1 ? lenderNames[0] : locale === 'fr' ? lenderNames[1] : lenderNames[0];

  return {
    id: responseData.id,
    lenderId: responseData.lenderId || '0',
    lenderName,
    fileName: responseData.fileName || '-',
    url: `${fileTransferUrl}/${responseData.id}`, // this is a pseudo url not the API endpoint
    uploadedDateTime: responseData.uploadedDateTime ?? '-',
    size: responseData.size || '0',
    lastDownloadedDateTime: responseData.lastDownloadedDateTime ?? '-',
    lastDownloadedBy: responseData.lastDownloadedBy || '-',
    uploadedBy: responseData.uploadedBy || '-',
    userType: responseData.userType
  };
};

export const getInventory = async (
  authenticationContext: AuthenticationContextType
): Promise<FileTransferFilesResponse | null> => {
  try {
    const { getWithAuth } = ApiClient(authenticationContext, apiClientConfig);
    const response = await getWithAuth(fileTransferUrl, { timeout: fileTransferTimeout });

    if (response?.data?.statusCode === HttpResponseStatusCodes.OK) {
      const locale = i18n.language; // Fetch the current language (e.g., 'en' or 'fr')
      const files: FileTransferFilesResponse = response?.data?.data || [];
      const tableData = files.map((file: FileTransferFile) => mapResponseToFile(file, locale));

      return tableData.length > 0 ? tableData : null;
    }
    return null;
  } catch (error) {
    console.error('Error fetching inventory:', error);
    return null;
  }
};

export const downloadInventory = async (
  data: object,
  authenticationContext: AuthenticationContextType,
  requestConfig?: AxiosRequestConfig
) => {
  try {
    return ApiClient(authenticationContext, apiClientConfig).postWithAuth(downloadUrl, data, {
      ...requestConfig,
      timeout: fileTransferTimeout
    });
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const deleteInventory = async (
  data: object,
  authenticationContext: AuthenticationContextType
) => {
  try {
    return ApiClient(authenticationContext, apiClientConfig).deleteWithAuth(deleteUrl, data, {
      timeout: fileTransferTimeout
    });
  } catch (err) {
    console.error(err);
    return null;
  }
};
