import i18n from 'i18next';
import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReportType, HttpResponseStatusCodes, HbtServiceErrorCodes } from '@hobt/constants';

import { defaultEmptyString } from 'Components/Common/Api/utils/HandleEmpty';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import { pathNames } from 'Constants/index';
import { useAuthenticationContext } from 'Foundation/Authentication';

import useArrearsFileListApiClient from './arrearsFileListClient';
import { DocumentDetails, DocumentEntity, ReportingPeriodField, ToastType } from './types';
import { config } from '../../../../config';

const useArrearsFileListFunctions = () => {
  const authenticationContext = useAuthenticationContext();
  const history = useHistory();
  const {
    deleteArrearsFile,
    downloadFileDocument,
    getActiveReportingPeriod,
    reAssessDocument,
    submitStatus,
    validationResult
  } = useArrearsFileListApiClient(authenticationContext, {
    timeout: config.arrearsApi.requestTimeout
  });

  const [isRequestPassed, setRequestPassed] = useState<boolean | undefined>(false);
  const [toastType, setToastType] = useState<ToastType | undefined>();
  const [isFileListToast, setFileListToast] = useState<boolean>(false);
  const [isInventoryToast, setInventoryToast] = useState<boolean>(false);
  const [isFileNotReady, setIsFileNotReady] = useState<boolean>(false);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isReportingPeriodLoading, setIsReportingPeriodLoading] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<Spinner>({
    isLoading: false
  });

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [fileID, setFileID] = useState<string>('');
  const [reportPeriodData, setReportPeriodData] = useState<ReportingPeriodField[]>([]);

  const onRowClick = (event: React.MouseEvent<HTMLElement>) => {
    const recordId: string | null = event.currentTarget.getAttribute('data-rowId');
    if (recordId != null) {
      history.push(`/${i18n.language}${pathNames.arrearsErrorRecord}?id=${recordId}`);
    }
  };

  // TODO: Handle API Error
  const onApiErrorCallback = () => setInventoryToast(true);

  const onDeleteHandler = async (id: string, refresh: Function) => {
    try {
      setIsDeleting(true);
      const response = await deleteArrearsFile(id);

      if (response === true) {
        setIsDeleting(false);
        setShowDeleteModal(false);

        // display toast
        setFileListToast(true);
        setRequestPassed(true);
        setToastType(ToastType.DELETED);
        refresh();
      } else {
        // Setting isDeleting to false inside this onDeleteHandler
        onDeleteError();
      }
    } catch (_err) {
      onDeleteError();
    }
  };

  const onSubmitHandler = async () => {
    setLoadingState({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description'
    });

    const response = await submitStatus();

    setLoadingState({
      isLoading: false
    });

    if (response?.statusCode === HttpResponseStatusCodes.OK) {
      setFileListToast(true);
      setRequestPassed(true);
      setToastType(ToastType.APPROVAL);
    } else if (response?.error?.errorCode === HbtServiceErrorCodes.HBT_ERR_DB_CLIENT.code) {
      setFileListToast(true);
      setRequestPassed(false);
      setToastType(ToastType.APPROVAL);
    }
  };

  const onFileDownload = async (documentID: string, entityID: string, reportType?: ReportType) => {
    try {
      const documentDetails: DocumentEntity | null = await downloadFileDocument(
        documentID,
        entityID,
        reportType
      );

      if (documentDetails && documentDetails !== null) {
        const downloadElement = document.createElement('a');

        downloadElement.setAttribute('href', documentDetails.documentEntityJson.documentLink);
        downloadElement.click();
        downloadElement.remove();
      } else if (documentDetails === null) {
        throw new Error('No data received');
      }
    } catch (errors) {
      setFileListToast(true);
    }
  };

  const onDownloadHandler = async () => {
    try {
      const response: DocumentDetails | null = await validationResult();

      if (response && response !== null) {
        if (response.documentID) {
          if (response.latestReportFlag == false) {
            setIsFileNotReady(true);
          }
          onFileDownload(
            response.documentID,
            response.reportingPeriodID,
            ReportType.ValidationReport
          );
        } else {
          throw new Error('There were no records to be retrieved for validation report');
        }
      } else if (response === null) {
        throw new Error('Could not retrieve validation results');
      }
    } catch (errors) {
      setFileListToast(true);
    }
  };

  const onToastCloseHandler = () => {
    setInventoryToast(false);
    setIsFileNotReady(false);
    if (isRequestPassed === true && toastType === ToastType.DELETED) {
      setFileListToast(false);
    } else if (isRequestPassed === true) {
      setFileListToast(false);
      history.push(`/${i18n.language}${pathNames.dashboard}`);
    } else {
      setRequestPassed(undefined);
      setToastType(undefined);
      setFileListToast(false);
    }
  };

  const onReassessBtn = async (uuid: string | number) => {
    try {
      setLoadingState({
        isLoading: true,
        spinnerHeading: 'Globals-InProgress-Heading',
        spinnerDescription: 'Globals-InProgress-Description'
      });

      const response = await reAssessDocument(uuid.toString());

      setLoadingState({
        isLoading: false
      });

      if (response) {
        setFileListToast(true);
        setRequestPassed(true);
        setToastType(ToastType.REASSESS);
      }
    } catch (error) {
      setRequestPassed(false);
      setToastType(undefined);
      setFileListToast(true);
      setLoadingState({
        isLoading: false
      });
    }
  };

  const checkReportingPeriodExists = (
    prevReportingPeriodList: ReportingPeriodField[],
    { reportingPeriod, reportingPeriodID }: ReportingPeriodField
  ) => {
    if (
      reportingPeriodID != null &&
      reportingPeriodID.length > 0 &&
      reportingPeriod != null &&
      reportingPeriod.length > 0
    ) {
      prevReportingPeriodList.push({
        reportingPeriod,
        reportingPeriodID
      });
    }

    return prevReportingPeriodList;
  };

  const onGetReportingPeriod = async () => {
    try {
      setIsReportingPeriodLoading(true);

      const response = await getActiveReportingPeriod();
      setIsReportingPeriodLoading(false);

      if (Array.isArray(response)) {
        const reportingPeriods = response
          .reduce(checkReportingPeriodExists, [])
          ?.sort((firstPeriod: ReportingPeriodField, secondPeriod: ReportingPeriodField) =>
            firstPeriod?.reportingPeriod > secondPeriod?.reportingPeriod ? 1 : -1
          );

        if (reportingPeriods.length > 0) {
          setReportPeriodData(reportingPeriods);
        } else {
          setReportPeriodData([]);
          // Set reporting period loading to false inside onGetReportingPeriodError
          onGetReportingPeriodError();
        }
      } else {
        onGetReportingPeriodError();
      }
    } catch (_err) {
      setIsReportingPeriodLoading(false);
      onGetReportingPeriodError();
    }
  };

  // get reporting period helpers
  const onGetReportingPeriodError = () => {
    setFileListToast(true);
    setRequestPassed(false);
    setToastType(ToastType.FETCH_REPORTING_PERIOD);
  };

  // delete helpers
  const cancelDeleteModalCallback = () => {
    setShowDeleteModal(false);
  };

  const onDeleteClick = (id: string | number) => {
    setShowDeleteModal(true);
    setFileID(id.toString());
  };

  const onDeleteError = () => {
    setIsDeleting(false);
    setShowDeleteModal(false);
    setFileListToast(true);
    setRequestPassed(false);
    setToastType(ToastType.DELETED);
  };

  const primaryDeleteCallback = (refresh: Function) => {
    onDeleteHandler(fileID, refresh);
  };

  const convertTimestampToMinandHrs = (timestamp: string | undefined): string => {
    return timestamp !== undefined
      ? moment(timestamp).tz('America/Toronto').format('h:mm A')
      : defaultEmptyString;
  };

  const convertTimestampToMinandHrsIn24HrFormat = (timestamp: string | undefined): string => {
    return timestamp !== undefined
      ? moment(timestamp).tz('America/Toronto').format('HH:mm')
      : defaultEmptyString;
  };

  return {
    isFileListToast,
    isFileNotReady,
    isInventoryToast,
    loadingState,
    isRequestPassed,
    reportPeriodData,
    showDeleteModal,
    isDeleting,
    isReportingPeriodLoading,
    toastType,
    cancelDeleteModalCallback,
    convertTimestampToMinandHrs,
    convertTimestampToMinandHrsIn24HrFormat,
    onApiErrorCallback,
    onDeleteClick,
    onDownloadHandler,
    onFileDownload,
    onGetReportingPeriod,
    onRowClick,
    onReassessBtn,
    onSubmitHandler,
    onToastCloseHandler,
    primaryDeleteCallback,
    setShowDeleteModal
  };
};

export default useArrearsFileListFunctions;
