import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { AccountStatus, UserRole, ModuleMapping, LanguageShort } from '@hobt/constants';
import { convertTimestampToDate } from '@hobt/utils';

import ManageUsersExternalProps, {
  UserMangementExternalInventoryRecords
} from 'Feature/UserManagement/models/ManageUsersExternalProps';
import { pathNames } from 'Constants/pathNames';
import { Button } from 'Components/Common/Button';
import { DataTable } from 'Components/Common/DataTable';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { useDataGridEngine } from 'Components/Hooks/DataGridEngine';
import { DataTableFooter } from 'Components/Common/DataTableFooter';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { PageCardContainer } from 'Components/PageComponents/PageCardContainer';
import { StatusCell } from 'Components/Common/DataTable/DataTableRow/StatusCell';
import { defaultEmptyString } from 'Components/Common/Api/utils/HandleEmpty';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { BuildDropDownDictionary } from 'Components/Common/Helpers';
import { downloadReportRequest } from '../api/UserApiHandler.service';
import { UploadDocuments } from './UploadDocumentsExternal/UploadDocuments';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { config } from '../../../../config';
import styles from './styles.module.scss';
import { DownloadReport } from './DownloadReport/DownloadReport';
import { SearchFieldOptions } from 'Components/PageComponents/SearchBar/types';
import { SearchBar } from 'Components/PageComponents/SearchBar';
import {
  TabMapping,
  TabType,
  ExternalStatus,
  UserApiResponse
} from 'Feature/UserManagement/models/types';

const ManageUsersExternal = ({ fields }: ManageUsersExternalProps) => {
  const history = useHistory();
  const authContext = useAuthenticationContext();
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const [rowInfo, setRowInfo] = useState<any>();
  const [attachmentToastMsg, setAttachmentToastMsg] = useState<string>('');
  const [attachmentToastTitle, setAttachmentToastTitle] = useState<string>('');
  const [showAttachmentToast, setShowAttachmentToast] = useState<boolean>(false);
  const [isInventoryToast, setInventoryToast] = useState<boolean>(false);
  const [showUploadDocument, setShowUploadDocument] = React.useState<boolean>(false);
  const [showDownloadReport, setShowDownloadReport] = React.useState<boolean>(false);
  const [toastState, setToastState] = useState<ApplicationStates>(ApplicationStates.DEFAULT);
  const [toastMsg, setToastMsg] = useState<string | FieldValue>('');
  const [showUploadToast, setShowUploadToast] = useState<boolean>(false);

  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.admin,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  // TODO: Handle API Error
  const onApiErrorCallback = () => setInventoryToast(true);

  const onToastCloseHandler = () => setInventoryToast(false);

  const {
    rowData,
    currentPage,
    itemsPerPage,
    onItemsPerPageChange,
    totalItems,
    totalPages,
    itemRangeFrom,
    itemRangeTo,
    isLoading: isInventoryLoading,
    onPagerClick,
    onSort,
    onSearch,
    onSearchReset,
    getSortDirectionForField,
    getTableAccessibilityText
  } = useDataGridEngine<UserMangementExternalInventoryRecords>({
    apiBaseUrl: config.userApi.urls.inventory,
    onApiErrorCallback,
    defaultItemsPerPage: 10,
    isAdmin: true,
    isUserModule: true
  });

  const [hasSearched, setHasSearched] = React.useState<boolean>(false);
  const searchByFields: SearchFieldOptions[] = [
    {
      displayText: fields.email.value ?? '',
      value: 'EmailID',
      type: 'text'
    },
    {
      displayText: fields.name.value ?? '',
      value: 'FullName',
      type: 'text'
    }
  ];

  const handleToast = (errs?: any) => {
    if (errs) console.log('errs', errs);
    setAttachmentToastTitle(i18n.t('Errors-HBT_VAL_ERR_20107'));
    setAttachmentToastMsg(i18n.t('Errors-HBT_VAL_ERR_20105'));
    setShowAttachmentToast(true);
  };

  const handleFileDownload = (fileUrl: string) => {
    const downloadElement = document.createElement('a');
    downloadElement.setAttribute('href', fileUrl);
    downloadElement.click();
  };

  const showHideUploadDocument = () => {
    setShowUploadDocument(false);
  };

  const handleExternalDownloadTmplate = () => {
    const { title } = fields.externalTemplateLink.fields;
    const fileUrl = title?.value?.href;
    handleFileDownload(fileUrl);
  };

  // TODO: 384 or 351 -> leaving as 002 for demo purposes
  const downloadReport = () => {
    downloadReportRequest(authContext)
      .then((res: AxiosResponse) => {
        const contentType = res.headers['content-type'];
        const fileName = res.headers['content-disposition'].split('"')[1];

        const downloadElement = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([res.data], { type: contentType }));
        downloadElement.setAttribute('href', url);
        downloadElement.setAttribute('download', fileName);
        downloadElement.click();
        downloadElement.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(handleToast);
  };

  const accountStatusDictionary = BuildDropDownDictionary(fields?.accountStatus?.fields?.listItems);

  const userRolesDictionary = BuildDropDownDictionary(fields?.userRoles?.fields?.listItems);

  // sets data as soon as we get it if it exists
  useEffect(() => {
    if (rowData && rowData.length > 0) setRowInfo(rowData);
  }, [rowData]);

  const onRowSelect = (row: any) => {
    history.push(`/${i18n.language}${pathNames.getOneUser}?id=${row.userID}`);
  };
  const showHideDownloadReport = () => {
    setShowDownloadReport(false);
  };
  const showToast = (result: any) => {
    if (result === UserApiResponse.Success) {
      setShowUploadToast(true);
      setToastMsg(i18n.t('UserManagement-Users-DownloadUserReport'));
      setToastState(ApplicationStates.SUCCESS);
      setShowDownloadReport(false);
    } else {
      setShowUploadToast(true);
      setToastMsg(i18n.t('UserManagement-UsersReport-ErrorToastMessage'));
      setToastState(ApplicationStates.ERROR);
      setShowDownloadReport(false);
    }
  };

  return (
    <>
      {showDownloadReport && (
        <DownloadReport
          fields={fields.downloadUserReport.fields}
          isActive={showHideDownloadReport}
          isSuccess={showToast}
        />
      )}
      {showUploadDocument && (
        <UploadDocuments fields={fields.uploadFiles.fields} isActive={showHideUploadDocument} />
      )}
      <ToastNotification
        isActive={isInventoryToast}
        type={ApplicationStates.ERROR}
        title={i18n.t('Globals-Toast-Server-Error-Title')}
        content={{
          value: i18n.t('DefaultsInventoryTable-SystemErrorToast-Message')
        }}
        onCloseCallback={onToastCloseHandler}
      />
      <ToastNotification
        type={ApplicationStates.DEFAULT}
        isActive={showAttachmentToast}
        title={attachmentToastTitle}
        content={attachmentToastMsg}
        onCloseCallback={() => setShowAttachmentToast(false)}
      />
      <PageCardContainer name="userManagement">
        <div className={styles.layoutContainer}>
          <div className={styles.titleContainer}>
            <Text tag="h2" field={fields.pageTitle} />
            <div className={styles.description}>
              <Text tag="small" field={fields.description} />
            </div>
          </div>
        </div>
        <hr />
        <div
          className={`${styles.buttonsContainer} ${
            i18n.language === LanguageShort.French ? styles.buttonsContainerFrench : ''
          }`.trim()}
        >
          <div>
            <div className={styles.userButtons}>
              <Button
                responsive
                name="addUserBtn"
                text={fields.addUserBtn}
                ariaText={fields.addUserBtn}
                onClick={() => history.push(`/${i18n.language}${pathNames.createUser}`)}
              />
            </div>
            <div className={styles.userButtons}>
              <Button
                secondaryButton
                responsive
                name="uploadBtn"
                text={fields.uploadBtn}
                ariaText={fields.uploadBtn}
                onClick={() => setShowUploadDocument(true)}
                disabled={isReadOnlyUser}
              />
            </div>
            <div className={styles.userButtons}>
              <Button
                responsive
                secondaryButton
                name="downloadReport"
                onClick={() => setShowDownloadReport(true)}
                text={fields.downloadReport}
                ariaText={fields.downloadReport}
              />
            </div>
          </div>
          <div className={styles.importUserButtons}>
            <Button
              text={fields.externalTemplateBtn}
              ariaText={fields.externalTemplateBtn}
              name="externalTemplateBtn"
              contextualButton
              icon={() => (
                <span
                  className={`material-icons-outlined icon--v-align-middle ${styles.actionIcons}`}
                >
                  content_copy
                </span>
              )}
              onClick={handleExternalDownloadTmplate}
              type="button"
            />
          </div>
        </div>
        <br></br>
        <div className={styles.searchBarWrapper}>
          <div className={styles.searchBarWidth}>
            <SearchBar
              name="userInventorySearchBar"
              fieldLabelAriaText={i18n.t('SearchBar-SearchField')}
              clearButtonAriaText={i18n.t('SearchBar-ClearButton')}
              searchButtonAriaText={i18n.t('SearchBar-SearchButton')}
              placeholderText={i18n.t('SearchBar-PlaceholderText')}
              searchFieldOptions={searchByFields}
              onClear={() => {
                setHasSearched(false);
                onSearchReset();
              }}
              onSearch={(field: string, value: string) => {
                setHasSearched(true);
                onSearch(field, value);
              }}
              count={totalItems}
              displaySearchResultMessage={false}
            />
          </div>
        </div>
        {hasSearched && totalItems != null && totalItems > 0 ? (
          <div className={styles.noSearchResultsMessage}>
            <Text
              field={{ value: `${totalItems}  ${i18n.t('Globals-Table-Search-To-Display')}` }}
            />
          </div>
        ) : (
          ''
        )}
        {totalItems === 0 && (
          <div className={styles.noSearchResultsMessage}>
            <Text field={{ value: i18n.t('Globals-Table-No-Search-To-Display') }} />
          </div>
        )}
        {totalItems > 0 && (
          <div>
            <div className={styles.dataTable}>
              <DataTable
                name="userManagementDataTable"
                isLoading={isInventoryLoading}
                caption={{ value: getTableAccessibilityText() }}
                scrollOnHorizontalOverflow
              >
                <DataTableHeader>
                  <DataTableHeaderItem
                    sortable
                    onSortCallback={onSort}
                    sortDirection={getSortDirectionForField('emailID')}
                    name="emailID"
                    displayText={fields.userLabel}
                  />
                  <DataTableHeaderItem
                    sortable
                    onSortCallback={onSort}
                    sortDirection={getSortDirectionForField('statusCode')}
                    name="statusCode"
                    displayText={fields.statusLabel}
                  />
                  <DataTableHeaderItem
                    sortable
                    onSortCallback={onSort}
                    sortDirection={getSortDirectionForField(
                      'defaultFinancialInstitutionCodeAndTransitNumber'
                    )}
                    name="defaultFinancialInstitutionCodeAndTransitNumber"
                    displayText={fields.defaultFITransit}
                  />
                  <DataTableHeaderItem name="telephone" displayText={fields.telephoneLabel} />
                  <DataTableHeaderItem
                    name="extensionPhoneNumber"
                    displayText={fields.extensionLabel}
                  />
                  <DataTableHeaderItem
                    sortable
                    onSortCallback={onSort}
                    sortDirection={getSortDirectionForField('lenderRoleCode')}
                    name="lenderRoleCode"
                    displayText={fields.typeOfUserLabel}
                  />
                  <DataTableHeaderItem name="lastLogIn" displayText={fields.lastLogInLabel} />
                  <DataTableHeaderItem name="arrears" displayText={fields.arrearsLabel} />
                  <DataTableHeaderItem name="default" displayText={fields.defaultLabel} />
                  <DataTableHeaderItem name="claim" displayText={fields.claimsLabel} />
                </DataTableHeader>

                <DataTableBody zebra>
                  {rowInfo &&
                    rowInfo.map((row: UserMangementExternalInventoryRecords) => (
                      <DataTableRow
                        name={row.userID}
                        key={row.userID}
                        onClick={() => onRowSelect(row)}
                      >
                        <TextCell
                          name="emailID"
                          text={row.fullName != null ? row.fullName : defaultEmptyString}
                          subtext={row.emailID ?? defaultEmptyString}
                        />
                        <StatusCell
                          name="adAccountStatusCode"
                          statusText={
                            row.statusCode != null
                              ? accountStatusDictionary.get(row.statusCode.toString()) ??
                                defaultEmptyString
                              : defaultEmptyString
                          }
                          className={
                            row.statusCode != null
                              ? ExternalStatus[row.statusCode as AccountStatus].color
                              : 'grey20'
                          }
                        />
                        <TextCell
                          name="defaultFinancialInstitutionCodeAndTransitNumber"
                          text={
                            row.defaultFinancialInstitutionCodeAndTransitNumber ??
                            defaultEmptyString
                          }
                        />
                        <TextCell name="telephone" text={row.phoneNumber ?? defaultEmptyString} />
                        <TextCell
                          name="extensionPhoneNumber"
                          text={row.extensionPhoneNumber ?? defaultEmptyString}
                        />
                        <TextCell
                          name="lenderRoleCode"
                          text={
                            row.typeOfUser != null
                              ? userRolesDictionary.get(row.typeOfUser.toString())
                              : defaultEmptyString
                          }
                        />
                        <TextCell
                          name="lastLogIn"
                          text={
                            row?.loginTimestamp !== null
                              ? convertTimestampToDate(row.loginTimestamp)!
                              : defaultEmptyString
                          }
                        />
                        {row.arrearsAccessFlag === true ? (
                          <td className={`${styles.statusWrapper} ${styles.isCheck} arrears`}>
                            <span className={styles.iconList}>
                              <span
                                className={`material-icons align-self-center ${styles.icon}`}
                                aria-label={
                                  fields?.arrearsLabel?.value != null &&
                                  fields?.checkLabel?.value != null
                                    ? `${fields.arrearsLabel.value} ${fields.checkLabel.value}`
                                    : ''
                                }
                              >
                                check
                              </span>
                            </span>
                          </td>
                        ) : (
                          <TextCell name="dash" text={defaultEmptyString} />
                        )}
                        {row.defaultAccessFlag === true ? (
                          <td className={`${styles.statusWrapper} ${styles.isCheck} default`}>
                            <span className={styles.iconList}>
                              <span
                                className={`material-icons align-self-center ${styles.icon}`}
                                aria-label={
                                  fields?.defaultLabel?.value != null &&
                                  fields?.checkLabel?.value != null
                                    ? `${fields.defaultLabel.value} ${fields.checkLabel.value}`
                                    : ''
                                }
                              >
                                check
                              </span>
                            </span>
                          </td>
                        ) : (
                          <TextCell name="dash" text={defaultEmptyString} />
                        )}
                        {row.claimAccessFlag === true ? (
                          <td className={`${styles.statusWrapper} ${styles.isCheck} claim`}>
                            <span className={styles.iconList}>
                              <span
                                className={`material-icons align-self-center ${styles.icon}`}
                                aria-label={
                                  fields?.claimsLabel?.value != null &&
                                  fields?.checkLabel?.value != null
                                    ? `${fields.claimsLabel.value} ${fields.checkLabel.value}`
                                    : ''
                                }
                              >
                                check
                              </span>
                            </span>
                          </td>
                        ) : (
                          <TextCell name="dash" text={defaultEmptyString} />
                        )}
                      </DataTableRow>
                    ))}
                </DataTableBody>
              </DataTable>
            </div>
            <div className={styles.dataTableFooter}>
              <DataTableFooter
                name="userManagementList"
                currentPage={currentPage}
                totalPages={totalPages || 1}
                itemsPerPageLabel={{
                  value: i18n.t('DefaultsInventoryTable-ResultsPerPageDropdown-Title')
                }}
                itemsXofYText={`${itemRangeFrom || 0} - ${itemRangeTo || 0} ${i18n.t(
                  'Globals-Grid-ResultsPerPageDropdown-Of'
                )}`}
                totalItems={totalItems}
                pagerClickCallback={onPagerClick}
                itemsPerPageOnChangeCallback={onItemsPerPageChange}
                itemsPerPageValue={itemsPerPage.toString()}
              />
            </div>
          </div>
        )}
      </PageCardContainer>
    </>
  );
};

export default ManageUsersExternal;
