import i18n from 'i18next';
import { useState } from 'react';

import { pathNames } from 'Constants/pathNames';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { handleEmpty } from 'Components/Common/Api/utils/HandleEmpty';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import { ArrearsErrorRecordInventoryRecord } from './types';
import useArrearsFileListApiClient from '../ArrearsFileList/arrearsFileListClient';
import { DocumentEntity } from '../ArrearsFileList/types';
import { config } from '../../../../config';
import { ArrearsSubmitResponse } from '../ArrearsSubmissionForm/types';

const useArrearsErrorRecordFunctions = (
  history: any,
  id: string,
  userDetails: any,
  rowData: ArrearsErrorRecordInventoryRecord[] | null
) => {
  const authenticationContext = useAuthenticationContext();

  const [flyoutData, setFlyoutData] = useState<any>({});
  const [isActive, setIsActive] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastState, setToastState] = useState<ApplicationStates>(ApplicationStates.DEFAULT);
  const [errorCode, setErrorCode] = useState<ArrearsSubmitResponse>();
  const [pageTitle, setPageTitle] = useState<string>();
  const [isInventoryToast, setInventoryToast] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<Spinner>({
    isLoading: false
  });

  const { downloadFileDocument } = useArrearsFileListApiClient(authenticationContext, {
    timeout: config.arrearsApi.requestTimeout
  });

  const onChevronClick = () => {
    history.push(`/${i18n.language}${pathNames.arrearsSubmittedReports}`);
  };

  const onBtnClick = () => {
    setIsActive(!isActive);
  };

  const replaceAttachment = () => {
    setIsActive(true);
    setFlyoutData({
      documentId: rowData && rowData[0]?.documentID,
      detailsId: rowData && rowData[0]?.submissionID,
      userId: userDetails?.userID,
      userType: userDetails?.userTypeCode
    });
  };

  const onToastCloseHandler = () => {
    setInventoryToast(false);
    setShowToast(false);
  };

  const onFileDownload = async (documentID: string, arrearsFileID: string) => {
    const documentDetails: DocumentEntity | null = await downloadFileDocument(
      documentID,
      arrearsFileID
    );

    if (documentDetails && documentDetails != null) {
      const downloadElement = document.createElement('a');

      downloadElement.setAttribute('href', documentDetails.documentEntityJson.documentLink);
      downloadElement.click();

      downloadElement.remove();
    } else if (documentDetails == null) {
      setToastState(ApplicationStates.ERROR);
      setShowToast(true);
    }
  };

  const fileDownloadBtn = () => {
    if (rowData != null) {
      onFileDownload(rowData[0].documentID, id);
    }
  };

  // TODO: Add Error Handler for failed submissions.
  const onErrorhandler = (errorType?: ArrearsSubmitResponse) => {
    setToastState(ApplicationStates.ERROR);
    setErrorCode(errorType);
    setShowToast(true);
  };

  const onSuccessHandler = () => {
    setToastState(ApplicationStates.SUCCESS);
    setShowToast(true);
  };

  const failInventoryHandler = () => setInventoryToast(true);

  const setPageTitleValue = (fileName: string, title?: string) => {
    setPageTitle(`${handleEmpty(title)} - ${fileName}`);
  };

  return {
    isActive,
    pageTitle,
    flyoutData,
    isInventoryToast,
    showToast,
    toastState,
    errorCode,
    loadingState,
    setLoadingState,
    onErrorhandler,
    onBtnClick,
    onChevronClick,
    onFileDownload,
    fileDownloadBtn,
    onSuccessHandler,
    replaceAttachment,
    setPageTitleValue,
    onToastCloseHandler,
    failInventoryHandler
  };
};

export default useArrearsErrorRecordFunctions;
