/* eslint-disable no-unused-expressions */
import { useState, useEffect } from 'react';
import { DeepMap, FieldError, useForm, UseFormReturn } from 'react-hook-form';
import { AxiosError } from 'axios';
import { RichTextProps } from '@sitecore-jss/sitecore-jss-react/types/components/RichText';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { hbtResolver } from '@hobt/schema-validator';
import {
  ClaimTypeIndicator,
  CommentType,
  correspondenceSchema,
  CorrespondenceType
} from '@hobt/claim-domain';
import { HbtServiceErrorCodes, Language } from '@hobt/constants';

import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';

import { config } from '../../../../../../config';

import {
  CreateDocumentRequestFormData,
  Categories,
  Category,
  DocumentRequestFormPropsFields
} from './types';
import { UserInfo } from '@hobt/user-domain';

const useDocumentRequestForm = (
  fields: DocumentRequestFormPropsFields,
  typeOfClaim?: string,
  approvedLenderReferenceNumber?: string,
  successCallBack?: Function
) => {
  const hookForm = useForm({
    resolver: hbtResolver(correspondenceSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  } as Record<string, any>);

  const englishCategory = fields.categoryEnglishToken?.value ?? '';
  const englishCategoryList = fields.categoryList?.fields.listItems;
  const frenchCategoryList = fields.categoryListFrench?.fields.listItems;
  const frenchCategory = fields.categoryFrenchToken?.value ?? '';
  const docRequestEnglishToken = fields.docRequestEnglishToken?.value ?? '';
  const docRequestFrenchToken = fields.docRequestFrenchToken?.value ?? '';
  const typeClaim = fields.typeOfClaimToken?.value ?? '';
  const lenderReference = fields.lenderReferenceToken?.value ?? '';

  // API Client Config
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  const authContext = useAuthenticationContext();
  const { postWithAuth } = ApiClient(authContext, apiClientConfig);

  const SitecoreContextFactory = useSitecoreContext();
  const userDetails = SitecoreContextFactory?.sitecoreContext?.user as UserInfo;
  const claimType = ClaimTypeIndicator[parseInt(typeOfClaim || '-1', 10)];

  const watchNotes: string = hookForm.watch('remarkText');
  const watchLanguage: number = hookForm.watch('languageCode', Language.English);

  const [showValidationError, setShowValidationError] = useState<boolean>(false);
  const [categoriesSelected, setCategoriesSelected] = useState<Categories>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');

  const initRequestTemplate = (language: number): string => {
    if (language === Language.French) {
      const frenchCategoriesData: Array<string | undefined> = [];
      frenchCategoryList?.forEach((listItems: SitecoreListItem) => {
        categoriesSelected?.forEach((selectedValue: Category) => {
          if (listItems.fields.itemValue.value === selectedValue.value) {
            frenchCategoriesData.push(listItems.fields.itemName.value);
          }
        });
      });

      return (
        fields.requestTemplateContentFrench.field?.value ||
        (fields.requestTemplateContentFrench?.value as string)
          ?.replace(typeClaim, claimType ?? '')
          ?.replace(lenderReference, approvedLenderReferenceNumber ?? '')
          ?.replace(
            frenchCategory,
            frenchCategoriesData.length > 0 ? frenchCategoriesData.join(', ') : frenchCategory
          )
      );
    }

    const englishCategoriesData: Array<string | undefined> = [];
    englishCategoryList?.forEach((x: SitecoreListItem) => {
      categoriesSelected?.forEach((selectedValue: Category) => {
        if (x.fields.itemValue.value === selectedValue.value) {
          englishCategoriesData.push(x.fields.itemName.value);
        }
      });
    });

    return (
      fields.requestTemplateContentEnglish.field?.value ||
      (fields.requestTemplateContentEnglish?.value as string)
        ?.replace(typeClaim, claimType ?? '')
        ?.replace(lenderReference, approvedLenderReferenceNumber ?? '')
        ?.replace(
          englishCategory,
          englishCategoriesData.length > 0 ? englishCategoriesData.join(', ') : englishCategory
        )
    );
  };

  const [requestTemplateContent, setRequestTemplateContent] = useState<RichTextProps>({
    field: { value: initRequestTemplate(watchLanguage) }
  });

  const onCategoriesChange = (updatedCategories: Categories | Category | null) => {
    let eventCategories: Categories = [];
    if (updatedCategories !== null && Array.isArray(updatedCategories)) {
      eventCategories =
        updatedCategories?.map((x: Category) => ({
          label: x.label || '',
          value: x.value || ''
        })) || [];
    } else if (updatedCategories !== null) {
      eventCategories = [updatedCategories as Category];
    }
    setCategoriesSelected(eventCategories);
    if (eventCategories && eventCategories.length > 0) setShowValidationError(false);

    let newRequestTemplate: string = initRequestTemplate(watchLanguage);
    const categories = eventCategories?.map((x: Category) => x.label).join(', ');
    if (categories && categories.length > 0) {
      newRequestTemplate = newRequestTemplate
        .replace(englishCategory, categories)
        .replace(frenchCategory, categories);
    }

    if (notes && notes.length > 0) {
      newRequestTemplate = newRequestTemplate
        .replace(docRequestEnglishToken, notes)
        .replace(docRequestFrenchToken, notes);
    }
    setRequestTemplateContent({ field: { value: newRequestTemplate } });

    const updatedCategoriesData = eventCategories?.map((categoryValue) => {
      return categoryValue.value;
    });
    hookForm.register('documentRequestCategoryCode');
    hookForm.setValue('documentRequestCategoryCode', updatedCategoriesData);
  };

  const onNotesChange = (updateNotes: string) => {
    setNotes(updateNotes);
    let newRequestTemplate: string = initRequestTemplate(watchLanguage);

    const categories = categoriesSelected?.map((x: Category) => x.label).join(', ');
    if (categories && categories.length > 0) {
      newRequestTemplate = newRequestTemplate
        .replace(englishCategory, categories)
        .replace(frenchCategory, categories);
    }

    if (updateNotes && updateNotes.length > 0) {
      newRequestTemplate = newRequestTemplate
        .replace(docRequestEnglishToken, updateNotes)
        .replace(docRequestFrenchToken, updateNotes);
    }
    setRequestTemplateContent({ field: { value: newRequestTemplate } });
  };

  useEffect(() => {
    onNotesChange(watchNotes);
  }, [watchNotes]);

  useEffect(() => {
    setRequestTemplateContent({ field: { value: initRequestTemplate(watchLanguage) } });
    onCategoriesChange(categoriesSelected);
    onNotesChange(notes);
  }, [watchLanguage]);

  useEffect(() => {
    onCategoriesChange(categoriesSelected);
  }, [JSON.stringify(categoriesSelected)]);

  useEffect(() => {
    if (userDetails != null) {
      hookForm.register('submitterUserID');
      hookForm.setValue('submitterUserID', userDetails?.userID);

      hookForm.register('submitterUserTypeCode');
      hookForm.setValue('submitterUserTypeCode', userDetails?.userTypeCode);

      hookForm.register('correspondenceTypeCode');
      hookForm.setValue('correspondenceTypeCode', CorrespondenceType.External);

      hookForm.register('commentTypeCode');
      hookForm.setValue('commentTypeCode', CommentType.DocumentationRequestNote);
    }
  }, [userDetails]);

  const { uuid, setLoadingStatus } = useHBTFormContext();
  const handleSubmit: any = (data: CreateDocumentRequestFormData) => {
    const url = `${config.claimApi.urls.correspondences}`;

    const payloadData = {
      claimID: uuid,
      correspondencePayload: {
        commentTypeCode: CommentType.DocumentationRequestNote,
        correspondenceTypeCode: CorrespondenceType.External,
        remarkText: data.remarkText,
        submitterUserID: userDetails.userID,
        submitterUserTypeCode: userDetails.userTypeCode,
        documentRequestCategoryCode: hookForm.getValues('documentRequestCategoryCode')
      }
    };

    setLoadingStatus?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description',
      isFlyout: true
    });

    postWithAuth(url, payloadData)
      .then(() => {
        setLoadingStatus?.({
          isLoading: false
        });

        if (successCallBack) {
          successCallBack();
        }
      })
      .catch((e: AxiosError<any>) => {
        if (
          e.response?.status === 500 &&
          e.response?.data?.error?.errorCode === HbtServiceErrorCodes.HBT_ERR_VALIDATION
        ) {
          setShowValidationError(true);
        }

        setLoadingStatus?.({
          isLoading: false
        });
      });
  };

  const onSubmitErrors: any = (errors: DeepMap<{ languageCode: number }, FieldError>) => {
    // TODO: remove console logs (added for environment troubleshooting)
    // eslint-disable-next-line no-console
    console.log('Form errors: ', errors);
  };

  return {
    showValidationError,
    hookForm,
    watchNotes,
    watchLanguage,
    categoriesSelected,
    notes,
    requestTemplateContent,
    isDisabled,
    setIsDisabled,
    onCategoriesChange,
    onNotesChange,
    handleSubmit,
    onSubmitErrors
  };
};

export default useDocumentRequestForm;
