import { useFeature } from 'flagged';
import i18n from 'i18next';
import React, { useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { convertTimestampToDate } from '@hobt/utils';

import { CardHeader } from 'Components/Card/CardHeader';
import { handleEmpty } from 'Components/Common/Api/utils/HandleEmpty';
import { DataTable } from 'Components/Common/DataTable';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableFooter } from 'Components/Common/DataTableFooter';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { StatusCell } from 'Components/Common/DataTable/DataTableRow/StatusCell';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { useDataGridEngine } from 'Components/Hooks/DataGridEngine';
import { SearchBar } from 'Components/PageComponents/SearchBar';
import { PageCardContainer } from 'Components/PageComponents/PageCardContainer';

import { LanguageSwitchTypeCode, Status } from 'Feature/Arrears/models/types';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { ComparisonOperators } from 'Foundation/Api';

import { config } from '../../../../config';
import useSubmissionListFunctions from './useSubmissionListFunctions';

import SubmissionListProps, {
  StatusCode,
  SubmissionListFieldNames,
  SubmissionListInventoryRecords
} from './types';
import styles from './styles.module.scss';

const SubmissionList = ({ fields }: SubmissionListProps) => {
  const isInternal = useFeature(FeatureFlags.INTERNAL);
  const InternalStatus: Record<StatusCode, Status> = {
    [StatusCode.Pass]: { textKey: fields.validationResultPassLabel?.value ?? '', class: 'success' },
    [StatusCode.Error]: { textKey: fields.validationResultErrorLabel?.value ?? '', class: 'error' }
  };

  const { isToastActive, searchByFields, onApiErrorCallback, onRowClick, toastHandler } =
    useSubmissionListFunctions(fields);

  const {
    rowData,
    currentPage,
    itemsPerPage,
    onItemsPerPageChange,
    totalItems,
    totalPages,
    itemRangeFrom,
    itemRangeTo,
    isLoading: isInventoryLoading,
    onPagerClick,
    onSearch,
    onSearchReset,
    onSort,
    getTableAccessibilityText,
    getSortDirectionForField,
    onSetCustomFilterParams
  } = useDataGridEngine<SubmissionListInventoryRecords>({
    onApiErrorCallback,
    defaultItemsPerPage: 10,
    apiBaseUrl: config.arrearsApi.urls.inventory,
    loadDataOnInitialLoad: false
  });

  useEffect(() => {
    onSetCustomFilterParams(
      `${SubmissionListFieldNames.Status} ${ComparisonOperators.IN} (${StatusCode.Pass},${StatusCode.Error})`
    );
  }, []);

  return (
    <>
      <ToastNotification
        type={ApplicationStates.ERROR}
        isActive={isToastActive}
        title={i18n.t('Globals-Toast-Server-Error-Title')}
        content={i18n.t('DefaultsInventoryTable-SystemErrorToast-Message')}
        onCloseCallback={toastHandler}
      />
      <PageCardContainer name="submissionsList">
        <CardHeader>
          <div className={styles.cardHeader}>
            <Text tag="h2" field={fields.pageTitle ?? { value: '' }} />
          </div>
        </CardHeader>
        <div className={styles.searchBarWrapper}>
          <SearchBar
            name="SubmissionListSearchBar"
            searchFieldOptions={searchByFields}
            fieldLabelAriaText={i18n.t('SearchBar-SearchField')}
            clearButtonAriaText={i18n.t('SearchBar-ClearButton')}
            searchButtonAriaText={i18n.t('SearchBar-SearchButton')}
            placeholderText={i18n.t('SearchBar-PlaceholderText')}
            onClear={onSearchReset}
            onSearch={onSearch}
            count={totalItems}
          />
        </div>
        <div className={styles.dataTable}>
          <DataTable
            name="arrearsSubmissionDataTable"
            caption={{ value: getTableAccessibilityText() ?? '' }}
            isLoading={isInventoryLoading}
            hideTableShiftBtnsForDesktop={true}
            scrollOnHorizontalOverflow
          >
            <DataTableHeader>
              <DataTableHeaderItem name="dateSubmitted" displayText={fields.dateSubmitted} />
              <DataTableHeaderItem
                name="reportingPeriod"
                displayText={fields.reportingPeriod ?? { value: '' }}
                onSortCallback={onSort}
                sortDirection={getSortDirectionForField('reportingPeriod')}
                sortable
              />
              <DataTableHeaderItem
                name="lenderName"
                displayText={fields.lenderName ?? { value: '' }}
              />
              {isInternal === true && (
                <DataTableHeaderItem
                  name="statusCode"
                  displayText={fields.validationResult ?? { value: '' }}
                  onSortCallback={onSort}
                  sortDirection={getSortDirectionForField('statusCode')}
                  sortable
                />
              )}
              <DataTableHeaderItem
                name="submittedBy"
                displayText={fields.submittedBy ?? { value: '' }}
              />
            </DataTableHeader>

            <DataTableBody zebra>
              {rowData?.map((row: SubmissionListInventoryRecords, index: number) => (
                <DataTableRow
                  name={row.submissionID}
                  key={`${row.submissionID}-${index}`}
                  onClick={() => onRowClick(row)}
                >
                  <TextCell
                    name="dateSubmitted"
                    text={handleEmpty(convertTimestampToDate(row.recordCreatedTimestamp))}
                  />
                  <TextCell name="reportingPeriod" text={handleEmpty(row.reportingPeriod)} />
                  <TextCell
                    name="lenderName"
                    text={handleEmpty(
                      i18n.language === LanguageSwitchTypeCode.English
                        ? row.lenderEnglishName
                        : row.lenderFrenchName
                    )}
                  />

                  {isInternal === true && (
                    <StatusCell
                      name="statusCode"
                      statusText={handleEmpty(
                        InternalStatus[row.statusCode as StatusCode]?.textKey
                      )}
                      className={InternalStatus[row.statusCode as StatusCode]?.class ?? 'grey20'}
                    />
                  )}
                  <TextCell name="submittedBy" text={handleEmpty(row.contactName)} />
                </DataTableRow>
              ))}
            </DataTableBody>
          </DataTable>
        </div>
        <div className={styles.dataTableFooter}>
          <DataTableFooter
            name="arrearsSubmissionList"
            currentPage={currentPage}
            totalPages={totalPages || 1}
            itemsPerPageLabel={{
              value: i18n.t('DefaultsInventoryTable-ResultsPerPageDropdown-Title')
            }}
            itemsXofYText={`${itemRangeFrom || 0} - ${itemRangeTo || 0} ${i18n.t(
              'Globals-Grid-ResultsPerPageDropdown-Of'
            )}`}
            totalItems={totalItems}
            pagerClickCallback={onPagerClick}
            itemsPerPageOnChangeCallback={onItemsPerPageChange}
            itemsPerPageValue={itemsPerPage.toString()}
          />
        </div>
      </PageCardContainer>
    </>
  );
};

export default SubmissionList;
