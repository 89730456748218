import React, { memo } from 'react';
import AttachmentTable from './AttachmentTable';
import ProgressBarItem from './ProgressBarItem';
import { AttachmentProps } from './types';
import UploadAttachmentWindow from './UploadAttachmentWindow';

/**
 * @param isRequired - boolean to check if the attachment upload is required
 * @param uploadErrorMessage - string that contains an error message
 * @param fileUploadUnavailable - boolean to check if attachment upload is available
 * @param attachments - array of files that are uploading
 * @param completedAttachments - array of files that are uploaded
 * @param uploadFiles - function to upload files
 * @param stopUploading - function to stop uploading files
 * @param deleteFile - function to delete file
 */

const Attachment: React.FC<AttachmentProps> = ({
  attachmentContent,
  isRequired,
  uploadErrorMessage,
  fileUploadUnavailable,
  attachments,
  completedAttachments,
  filesRemovedIndexes,
  disable,
  uploadFiles,
  stopUploading,
  deleteFile
}) => {
  return (
    <div>
      <UploadAttachmentWindow
        isRequired={isRequired}
        uploadErrorMessage={uploadErrorMessage}
        fileUploadUnavailable={fileUploadUnavailable}
        filesRemovedIndexes={filesRemovedIndexes}
        disable={disable}
        uploadFiles={uploadFiles}
        attachmentContent={attachmentContent}
      />
      <ProgressBarItem
        attachments={attachments}
        stopUploading={stopUploading}
        filesRemovedIndexes={filesRemovedIndexes}
      />
      {!fileUploadUnavailable && (
        <AttachmentTable
          attachmentContent={attachmentContent}
          completedAttachments={completedAttachments}
          deleteFile={deleteFile}
        />
      )}
    </div>
  );
};

export default memo(Attachment);
