import React, { FC, useState, useCallback, SyntheticEvent, useRef, MutableRefObject } from 'react';
import { Select, SelectChangeEvent } from '@mui/material';
import { useNormalizedOptions } from './DropdownHooks/useNormalizedOptions';
import { useIsOpenDropdown } from './DropdownHooks/useOpenDropdown';
import { getMenuStyleProps } from './getMenuStyleProps';
import { selectSx } from './selectSx';
import { wrappedDropdown } from './SharedComponents/BaseDropdown';
import { DropdownCheckboxItem } from './SharedComponents/DropdownCheckboxItem';
import { getDropdownChevronIconComponent } from './SharedComponents/DropdownChevronIcon';
import { DropdownErrorMessage } from './SharedComponents/DropdownErrorMessage';
import { DropdownGroupItem } from './SharedComponents/DropdownGroupItem';
import { DropdownHelpMessage } from './SharedComponents/DropdownHelpMessage';
import { DropdownTagGroup } from './SharedComponents/DropdownTagGroup';
import { getSelectTextReactNode } from './SharedComponents/MultiDropdownInputText';
import styles from './styles.module.scss';
import { MultiDropdownProps, NormalizedNonGroup, NormalizedOption } from './types';

/**
 *
 * @param {DropdownProps} props - list out the props.
 * @param {FieldValue} props.ariaText - aria label for the dropdown Select
 * @param {string} props.className - class to override styles of the dropdown container
 * @param {boolean} props.error - indicator of any error on dropdown. There is an error when this value is true.
 * @param {string} props.errorMessage - contains the error message to be displayed below the dropdown container when error is true
 * @param {FieldValue} props.helpText - helper text to be displayed in the dropdown container below Select to display any additional text
 * @param {Label} props.label - contains label text and label info icon tool tip text; label will be displayed (above Select bar) when this input is provided
 * @param {MultiGroupedOption[] | MultiOption[]} props.options - options that need to be displayed in the dropdown menu list. It container the label, value and icon for each option.
 * @param {string} props.placeholderText - any text to be displayed in the Select bar when there is no option selected
 * @param {string} props.selectionNumberText - any text to be displayed in the Select bar when there is one or more options selected
 * @param {boolean} props.readOnly - specifies whether the Select bar is readonly
 * @returns {Dropdown} Rendered Select Dropdown component.
 */

/**
 * Dropdown component
 *
 * This is the Select Dropdown component written using storybook. It handles different states, variants.
 */
const getInitialOptions = (options: NormalizedOption[], initialOptions?: string[]) => {
  return options.filter(
    (option) => !option.isGroup && initialOptions && initialOptions.includes(option.value)
  ) as NormalizedNonGroup[];
};

export const MultiDropdown: FC<MultiDropdownProps> = wrappedDropdown<MultiDropdownProps>(
  ({
    ariaText,
    error,
    errorMessage,
    helpText,
    options,
    initialOptions,
    placeholderText,
    selectionNumberText,
    onChange,
    readOnly = false
  }: MultiDropdownProps) => {
    const normalizedOptions = useNormalizedOptions(options ?? []);
    const initialSelectedOptions = getInitialOptions(normalizedOptions, initialOptions);

    const [selectedOptions, setSelectedOptions] = useState<NormalizedNonGroup[] | null>(
      initialSelectedOptions ?? null
    );
    const clearAllRef: MutableRefObject<HTMLElement> = useRef<HTMLElement>({} as HTMLElement);
    const { isOpen, openedByKeyboard, handleMenuClose, handleMenuOpen } = useIsOpenDropdown();

    const clearAllSelectedOptions = () => setSelectedOptions([]);
    const menuStyleProps = getMenuStyleProps(openedByKeyboard);

    const handleChange = useCallback(
      (event: SelectChangeEvent<string[]>) => {
        const selectedValue: string[] = event.target.value as string[];
        const newSelection = normalizedOptions.filter(
          (option) => !option.isGroup && selectedValue.includes(option?.value)
        ) as NormalizedNonGroup[];
        setSelectedOptions(newSelection ?? null);
      },
      [JSON.stringify(normalizedOptions)]
    );

    // Workaround to remove autofocus on first option that always happens even when menu is opened through click; this is a workaround/fix for bug in material UI library (https://github.com/mui/material-ui/issues/23747).
    const handleMenuOpenMulti = useCallback((event: SyntheticEvent | KeyboardEvent) => {
      if (event && event.target) {
        const eventTarget: HTMLElement = event.target as HTMLElement;
        const containsClearAll = clearAllRef.current.contains(eventTarget) ?? false;

        if (containsClearAll) {
          clearAllSelectedOptions();
          event.stopPropagation();
          return;
        }
      }
      handleMenuOpen(event);
    }, []);

    return (
      <>
        <Select
          data-testid="dropdown-root"
          multiple
          value={selectedOptions?.map((opt) => opt.value) as string[]}
          error={error}
          readOnly={readOnly}
          onChange={handleChange}
          onOpen={handleMenuOpenMulti}
          open={isOpen}
          onClose={handleMenuClose}
          sx={selectSx}
          IconComponent={getDropdownChevronIconComponent(isOpen)}
          displayEmpty
          disableUnderline
          MenuProps={menuStyleProps}
          renderValue={getSelectTextReactNode({
            selectedOptions,
            clearAllRef,
            placeholderText,
            selectionNumberText
          })}
          inputProps={{
            'aria-label': ariaText?.value
          }}
          classes={{
            root: styles.selectRoot,
            select: `${styles.selectInput} ${isOpen ? styles.selectInputOpen : ''}`,
            error: styles.selectInputError
          }}
        >
          {normalizedOptions.map((option, index) =>
            option.isGroup ? (
              <DropdownGroupItem key={index} index={index} value={index} label={option.label} />
            ) : (
              <DropdownCheckboxItem
                value={option.value}
                label={option.label}
                isGroup={option.isGroup}
                key={option.value}
                selectedOptions={selectedOptions}
              >
                test
              </DropdownCheckboxItem>
            )
          )}
        </Select>

        <DropdownHelpMessage helpText={helpText} />
        <DropdownErrorMessage error={error} errorMessage={errorMessage} />
        <DropdownTagGroup
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
      </>
    );
  }
);
