import { AxiosError, AxiosResponse } from 'axios';

import { HbtServiceErrorCodes, HbtValidationErrorCodes } from '@hobt/constants';

import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { AuthenticationContextType } from 'Foundation/Authentication';
import { config } from '../../../../config';

import { ArrearsSubmitResponse, DataObject, SubmittedArrearsType } from './types';

const arrearsSubmissionUrl: string = config.arrearsApi.urls.submission;
const arrearsFilesRetrievalUrl: string = config.arrearsApi.urls.files;
const arrearsFilesReplaceUrl: string = config.arrearsApi.urls.replace;
const fileDownloadUrl: string = config.documentApi.urls.base;
const activeReportingPeriodUrl: string = config.arrearsApi.urls.activeReportingPeriod;

const pathError = new Error('Invalid configuration. Could not read URL or path for API calls.');
const apiClientConfig: ApiClientConfig = {
  timeout: config.defaultApi.requestTimeout
};

// TODO: Update the error handling to fix status and codes being mixed from before
export const errorCatch = (
  err: any,
  setErrorDataHandler?: (data: Array<string> | null) => void
): number => {
  if (err && err.response && err.response.data && err.response.data.error) {
    switch (err.response.data.error.errorCode) {
      case HbtValidationErrorCodes.HBT_VAL_ERR_NO_ACCESS_TO_FI.code:
        return ArrearsSubmitResponse.NoAccessToFiCode;
      case HbtValidationErrorCodes.HBT_VAL_ERR_NO_ACCESS_TO_TRANSIT_UNDER_FI.code:
        return ArrearsSubmitResponse.NoAccessToTransit;
      case HbtValidationErrorCodes.HBT_VAL_ERR_INVALID_FI_CODE.code:
        return ArrearsSubmitResponse.InvalidFiCode;
      case HbtValidationErrorCodes.HBT_VAL_ERR_INVALID_TRANSIT_FOR_CORRESPONDING_FI_CODE.code:
        setErrorDataHandler?.(
          err.response.data.data !== undefined && Array.isArray(err.response.data.data)
            ? err.response.data.data
            : null
        );
        return ArrearsSubmitResponse.InvalidTransitForFiCode;
      case HbtServiceErrorCodes.HBT_ERR_DOCUMENT_UPLOAD.code:
        return ArrearsSubmitResponse.FileUploadError;
      case HbtServiceErrorCodes.HBT_ERR_MISSING_FILE_PARAM.code:
        return ArrearsSubmitResponse.MissingFileParam;
      case HbtServiceErrorCodes.HBT_ERR_INVALID_FILE_COUNT.code:
        return ArrearsSubmitResponse.MaxFileCountReached;
      default:
        return ArrearsSubmitResponse.Error;
    }
  } else {
    // err.response.status is undefined
    return ArrearsSubmitResponse.Error;
  }
};

export const submitArrearsRequest = async (
  authenticationContext: AuthenticationContextType,
  data: SubmittedArrearsType,
  setErrorDataHandler: (data: Array<string> | null) => void
): Promise<number> => {
  try {
    await ApiClient(authenticationContext, apiClientConfig).postWithAuth(
      arrearsSubmissionUrl,
      data
    );

    return ArrearsSubmitResponse.Success;
  } catch (err) {
    return errorCatch(err, setErrorDataHandler);
  }
};

export const replaceFilesRequest = async (
  authenticationContext: AuthenticationContextType,
  data: any
): Promise<number> => {
  try {
    await ApiClient(authenticationContext, apiClientConfig).postWithAuth(
      arrearsFilesReplaceUrl,
      data
    );
    return ArrearsSubmitResponse.Success;
  } catch (err) {
    return errorCatch(err);
  }
};

export const getDetailsRequest = async (
  authContext: AuthenticationContextType,
  id: string
): Promise<DataObject> => {
  const route = `${arrearsSubmissionUrl}/${id}`;

  if (route !== undefined) {
    return ApiClient(authContext, apiClientConfig)
      .getWithAuth(route)
      .then((res: AxiosResponse<DataObject>) => res.data)
      .catch((err: AxiosError) => Promise.reject(err));
  }
  throw pathError;
};

export const getFilesRequest = async (
  authContext: AuthenticationContextType,
  id: string
): Promise<DataObject> => {
  const route = `${arrearsFilesRetrievalUrl}/${id}`;

  if (route !== undefined) {
    return ApiClient(authContext, apiClientConfig)
      .getWithAuth(route)
      .then((res: AxiosResponse<DataObject>) => res.data)
      .catch((err: AxiosError) => Promise.reject(err));
  }
  throw pathError;
};

export const downloadFileRequest = async (
  authContext: AuthenticationContextType,
  docId: string,
  fileId: string
): Promise<AxiosResponse<DataObject>> => {
  const queryBuilder = `${docId}?module=arrears&entityID=${fileId}`;
  return ApiClient(authContext, apiClientConfig).getWithAuth(`${fileDownloadUrl}/${queryBuilder}`);
};

export const getActiveReportingPeriod = async (
  authContext: AuthenticationContextType
): Promise<DataObject> => {
  return ApiClient(authContext, apiClientConfig)
    .getWithAuth(activeReportingPeriodUrl)
    .then((res: AxiosResponse<DataObject>) => res.data)
    .catch((err: AxiosError) => Promise.reject(err));
};
