import { HbtFileExtensionType } from '@hobt/constants';
import { AttachmentsViewPropsFields } from 'Components/Common/AttachmentsView/types';

export interface AttachmentsViewComponentPropsFields {
  fields: AttachmentsViewComponentFields;
}

export interface AddContestClaimFormPropsFields {
  formTitle: FieldValue;
  content: FieldValue;
  description: FieldValue;
  characterLimit: FieldValue;
  submitBtn: FieldValue;
  cancelBtn: FieldValue;
  limitNumber: FieldValue;
  attachmentsView: AttachmentsViewComponentPropsFields;
}

export interface AttachmentsViewComponentFields extends AttachmentsViewPropsFields {
  name: FieldValue;
  heading: FieldValue;
}

export interface ContestClaimProps {
  name?: string;
  fields: AddContestClaimFormPropsFields;
  onCancelCallback?: () => void;
  onSuccessCallback?: () => void;
  onErrorCallback?: () => void;
  siteType?: string;
}

export interface ContestClaimRequestPayload {
  remarkText: string;
  attachments: AttachementFields[];
}

export type AttachementFields = {
  documentID: string;
  metadata: {
    fileName: string;
    fileSize: number;
    description: string;
    documentType: number;
    uploadStatus: number;
  };
};

export const AllowedFileTypes = [
  HbtFileExtensionType.CSV,
  HbtFileExtensionType.XLSX,
  HbtFileExtensionType.XLSM,
  HbtFileExtensionType.XLSB,
  HbtFileExtensionType.XLTX,
  HbtFileExtensionType.XLTM,
  HbtFileExtensionType.XLS,
  HbtFileExtensionType.XLT,
  HbtFileExtensionType.XML,
  HbtFileExtensionType.XLAM,
  HbtFileExtensionType.XLA,
  HbtFileExtensionType.XLW,
  HbtFileExtensionType.XLR,
  HbtFileExtensionType.PDF,
  HbtFileExtensionType.JPG,
  HbtFileExtensionType.DOC,
  HbtFileExtensionType.DOCX
];
