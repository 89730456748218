export enum FilterInternalColumnNames {
  CmhcDefaultAccountID = 'CmhcDefaultAccountID',
  ApprovedLenderCode = 'ApprovedLenderCode',
  ApprovedLenderName = 'ApprovedLenderName',
  CmhcLoanAccountNumber = 'CmhcLoanAccountNumber',
  DefaultStatus = 'DefaultStatus',
  ProvinceCode = 'ProvinceCode',
  RequestReceivedTimestamp = 'RequestReceivedTimestamp',
  LastRecordUpdatedTimestamp = 'LastRecordUpdatedTimestamp',
  CmhcOwnerFirstName = 'CmhcOwnerFirstName',
  CmhcOwnerLastName = 'CmhcOwnerLastName',
  CmhcOwnerID = 'CmhcOwnerID'
}
