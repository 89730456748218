import { useFeature } from 'flagged';
import i18n from 'i18next';
import React, { useState, useEffect, useRef } from 'react';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { UserRole, Module } from '@hobt/constants';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { TooltipIcon } from 'Components/Inputs/TooltipIcon';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useDefaultFormActionsContext } from 'Feature/DefaultsInventory/components/DefaultFormContext';

import { LiabilitiesProps } from './Liabilities.types';
import { OwnerType } from '../../../models/typeCode.types';

import { AccordionContainer, LinedCard } from '../../../../CommonComponents/AccordionComponents';
import FormInputCurrency from '../../FormInputCurrency';
import {
  deleteCardRepopulateValues,
  prepopulateCalculations,
  scrollIntoView
} from '../../CardReuseableFunctions';
import './Liabilities.css';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const LiabilitiesContainer: React.FC<LiabilitiesProps> = ({
  index,
  register,
  errors,
  setValueHandler,
  control,
  watch,
  getValues,
  trigger,
  ...props
}) => {
  const commonCardProps = {
    register,
    errors,
    setValueHandler,
    control,
    watch,
    getValues
  };

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  const detailCard = (watch && watch(`detail`)) || {};
  const firstMortgageBalance = (detailCard.firstLoan && detailCard.firstLoan.unpaidBalance) || 0;
  const firstMortgageMinimumMonthly =
    (detailCard.firstLoan && detailCard.firstLoan.requiredMonthlyPaymentPrincipalInterest) || 0;
  const secondMortgageBalance = (detailCard.secondLoan && detailCard.secondLoan.unpaidBalance) || 0;
  const secondMortgageMinimumMonthly =
    (detailCard.secondLoan && detailCard.secondLoan.requiredMonthlyPaymentPrincipalInterest) || 0;
  const cardInstance = (watch && watch(`liability[${index}]`)) || {};
  const personalLoanBalance = cardInstance.personalLoanBalance || 0;
  const lineOfCreditBalance = cardInstance.lineOfCreditBalance || 0;
  const creditCardBalance = cardInstance.creditCardBalance || 0;
  const personalLoanMinimumMonthlyPaymentField =
    cardInstance.personalLoanMinimumMonthlyPayment || 0;
  const lineOfCreditMinimumMonthlyPaymentField =
    cardInstance.lineOfCreditMinimumMonthlyPayment || 0;
  const creditCardMinimumMonthlyPaymentField = cardInstance.creditCardMinimumMonthlyPayment || 0;

  const firstCardInstance = (watch && watch(`liability[0]`)) || {};
  const personalLoanMinimumMonthlyPayment =
    firstCardInstance.personalLoanMinimumMonthlyPayment || 0;
  const lineOfCreditMinimumMonthlyPayment =
    firstCardInstance.lineOfCreditMinimumMonthlyPayment || 0;
  const creditCardMinimumMonthlyPayment = firstCardInstance.creditCardMinimumMonthlyPayment || 0;

  const defaultFormActions = useDefaultFormActionsContext();

  useEffect(() => {
    const total = personalLoanBalance + lineOfCreditBalance + creditCardBalance;
    const totalLiabilitiesArray = [...props.totalLiabilitiesArray];
    if (typeof index === 'number') {
      totalLiabilitiesArray[index] = total;
      props.calculateLiabilities && props.calculateLiabilities(totalLiabilitiesArray);
    }

    setValueHandler &&
      setValueHandler(
        'grossDebtServiceTotalDebtService.submittedOtherMonthlyExpense.creditCard',
        creditCardMinimumMonthlyPayment
      );
    setValueHandler &&
      setValueHandler(
        'grossDebtServiceTotalDebtService.submittedOtherMonthlyExpense.lineOfCredit',
        lineOfCreditMinimumMonthlyPayment
      );
    setValueHandler &&
      setValueHandler(
        'grossDebtServiceTotalDebtService.submittedOtherMonthlyExpense.personalLoan',
        personalLoanMinimumMonthlyPayment
      );
    setValueHandler && setValueHandler(`liability[${index}].owner`, OwnerType.BorrowerCoborrower);
  }, [
    firstMortgageBalance,
    secondMortgageBalance,
    personalLoanBalance,
    lineOfCreditBalance,
    creditCardBalance,
    personalLoanMinimumMonthlyPayment,
    lineOfCreditMinimumMonthlyPayment,
    creditCardMinimumMonthlyPayment
  ]);

  useEffect(() => {
    if (defaultFormActions.isCardComplete('liabilities')) {
      if (
        !(
          !!personalLoanBalance &&
          !!lineOfCreditBalance &&
          !!creditCardBalance &&
          !!personalLoanMinimumMonthlyPaymentField &&
          !!lineOfCreditMinimumMonthlyPaymentField &&
          !!creditCardMinimumMonthlyPaymentField
        )
      ) {
        defaultFormActions?.removeCompleteCard('liabilities');
      }
    } else if (
      !!personalLoanBalance &&
      !!lineOfCreditBalance &&
      !!creditCardBalance &&
      !!personalLoanMinimumMonthlyPaymentField &&
      !!lineOfCreditMinimumMonthlyPaymentField &&
      !!creditCardMinimumMonthlyPaymentField
    ) {
      defaultFormActions?.addCompleteCard('liabilities');
    }
  }, [
    personalLoanBalance,
    lineOfCreditBalance,
    creditCardBalance,
    personalLoanMinimumMonthlyPaymentField,
    lineOfCreditMinimumMonthlyPaymentField,
    creditCardMinimumMonthlyPaymentField
  ]);

  return (
    <>
      {index === 0 ? (
        <>
          <h3 className="card__body-heading" id={`liabilities-${index || 0}`}>
            {props.firstMortgage.field.value}
          </h3>
          <input
            type="hidden"
            value={OwnerType.BorrowerCoborrower}
            {...register?.(`liability[${index}].owner`)}
            disabled={isReadOnlyUser}
          />
          <div className="row card__body-row">
            <div className="form__element form__element--2-column col-6">
              <FormInputCurrency
                id={`${props.id}AssociatedBalance1${index}`}
                title={props.inputFields.associatedBalance.field}
                value={firstMortgageBalance || null}
                isDisabled={isReadOnlyUser || true}
                cardName={props.title.field.value}
              />
            </div>
            <div className="form__element form__element--2-column col-6">
              <FormInputCurrency
                id={`${props.id}AssociatedMinPay1${index}`}
                title={props.inputFields.associatedMinimumMonthly.field}
                value={firstMortgageMinimumMonthly || null}
                isDisabled={isReadOnlyUser || true}
                cardName={props.title.field.value}
              />
            </div>
          </div>
          <h3 className="card__body-heading">{props?.secondMortgage?.field?.value}</h3>
          <div className="row card__body-row">
            <div className="form__element form__element--2-column col-6">
              <FormInputCurrency
                id={`${props.id}AssociatedBalance2${index}`}
                title={props.inputFields.associatedBalance.field}
                value={secondMortgageBalance || null}
                isDisabled={isReadOnlyUser || true}
                cardName={props.title.field.value}
              />
            </div>
            <div className="form__element form__element--2-column col-6">
              <FormInputCurrency
                id={`${props.id}AssociatedMinPay2${index}`}
                title={props.inputFields.associatedMinimumMonthly.field}
                value={secondMortgageMinimumMonthly || null}
                isDisabled={isReadOnlyUser || true}
                cardName={props.title.field.value}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <h3
            className="card__body-heading guarantor-liabilities-heading"
            id={`liabilities-${index || 1}`}
          >
            {`${props?.guarantorLiabilities?.field?.value} ${index}`}
          </h3>
          <input
            type="hidden"
            value={OwnerType.Guarantor}
            {...register?.(`liability[${index}].owner`)}
            disabled={isReadOnlyUser}
          />
        </>
      )}

      <h3 className="card__body-heading">{props?.personalLoan?.field?.value}</h3>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}AssociatedBalance3${index}`}
            name={`liability[${index}].personalLoanBalance`}
            title={props.inputFields.associatedBalance.field}
            cardName={props.title.field.value}
            isRequired={true}
            autoFocus={props.isAutoFocus}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}AssociatedMinPay3${index}`}
            name={`liability[${index}].personalLoanMinimumMonthlyPayment`}
            title={props.inputFields.associatedMinimumMonthly.field}
            cardName={props.title.field.value}
            isRequired={true}
            handleChange={() =>
              queueMicrotask(
                () =>
                  trigger &&
                  trigger(
                    'grossDebtServiceTotalDebtService.submittedOtherMonthlyExpense.personalLoan'
                  )
              )
            }
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <h3 className="card__body-heading">{props?.lineOfCredit?.field?.value}</h3>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}AssociatedBalance4${index}`}
            name={`liability[${index}].lineOfCreditBalance`}
            title={props.inputFields.associatedBalance.field}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}AssociatedMinPay4${index}`}
            name={`liability[${index}].lineOfCreditMinimumMonthlyPayment`}
            title={props.inputFields.associatedMinimumMonthly.field}
            cardName={props.title.field.value}
            isRequired={true}
            handleChange={() =>
              queueMicrotask(
                () =>
                  trigger &&
                  trigger(
                    'grossDebtServiceTotalDebtService.submittedOtherMonthlyExpense.lineOfCredit'
                  )
              )
            }
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <h3 className="card__body-heading">{props?.creditCard?.field?.value}</h3>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}AssociatedBalance5${index}`}
            name={`liability[${index}].creditCardBalance`}
            title={props.inputFields.associatedBalance.field}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}AssociatedMinPay5${index}`}
            name={`liability[${index}].creditCardMinimumMonthlyPayment`}
            title={props.inputFields.associatedMinimumMonthly.field}
            cardName={props.title.field.value}
            isRequired={true}
            handleChange={() =>
              queueMicrotask(
                () =>
                  trigger &&
                  trigger(
                    'grossDebtServiceTotalDebtService.submittedOtherMonthlyExpense.creditCard'
                  )
              )
            }
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      {index && index > 0 ? (
        <div className="row card__body-row mt-3">
          <div className="col-12 card__actionButtons">
            <button
              className="ml-4 btn btn--icon btn__delete"
              type="button"
              onClick={function () {
                props.removeCard && props.removeCard(index);
              }}
              disabled={isReadOnlyUser || props.disableDelete}
            >
              <span>
                <TooltipIcon
                  icon={props.deleteIcon}
                  text={i18n.t('DefaultSubmission-Card-Delete')}
                  className={'icon-24'}
                />
              </span>
            </button>
          </div>
        </div>
      ) : null}
      <hr className="card__divider--thick" />
    </>
  );
};

export const Liabilities: React.FC<LiabilitiesProps> = (props) => {
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  const initialIdsArray = [];
  const cardCount = props.cardCount || 1;
  for (let i = 0; i < cardCount; i++) {
    initialIdsArray.push(i);
  }
  const [idsArray, setIdsArray] = useState<number[]>(initialIdsArray);
  const [id, setId] = useState<number>(cardCount - 1);
  const [isAutoFocus, setAutoFocus] = useState<boolean>(false);
  const [lastIndexPreDelete, setLastIndexPreDelete] = useState<number>(cardCount - 1);
  const [netAssets, setNetAssets] = useState(0);
  const [disableDelete, setDisableDelete] = useState(false);
  const isInitialMount = useRef(true);

  useEffect(() => {
    // Set props.totalLiabilitiesArray on mount, for calculation based on pre-populated data
    const allCardValues = (props.watch && props.watch(`liability`)) || {};
    // Retain only liability values used for calculations
    for (let i = 0; i < allCardValues.length; i++) {
      allCardValues[i] = {
        personalLoanBalance: allCardValues[i].personalLoanBalance || 0,
        lineOfCreditBalance: allCardValues[i].lineOfCreditBalance || 0,
        creditCardBalance: allCardValues[i].creditCardBalance || 0,
        personalLoanMinimumMonthlyPayment: allCardValues[i].personalLoanMinimumMonthlyPayment || 0,
        lineOfCreditMinimumMonthlyPayment: allCardValues[i].lineOfCreditMinimumMonthlyPayment || 0,
        creditCardMinimumMonthlyPayment: allCardValues[i].creditCardMinimumMonthlyPayment || 0,
        owner: allCardValues[i].owner || OwnerType?.BorrowerCoborrower
      };
    }
    const totalLiabilitiesArrayCopy = prepopulateCalculations(allCardValues);
    calculateLiabilities(totalLiabilitiesArrayCopy);
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setIdsArray(idsArray.concat(id));
    }
  }, [id]);

  useEffect(() => {
    // update last index after adding/deleting a card
    setLastIndexPreDelete(idsArray.length - 1);
    setDisableDelete(false); // re-enable delete after delete operation is completed
  }, [JSON.stringify(idsArray)]);

  useEffect(() => {
    const cardDNetAssets =
      (props.cumulativeTotalAssets || 0) - (props.cumulativeTotalLiabilities || 0);
    setNetAssets(cardDNetAssets);
  }, [props.cumulativeTotalLiabilities, props.cumulativeTotalAssets]);

  function addGuarantorAssets() {
    setId(id + 1);
    setAutoFocus(true);
  }

  function removeCard(index: number) {
    // manually setting own isDirty check for card deletions on details form
    props.setCardDeleted && props.setCardDeleted(true);

    if (props.getValues && props.setValueHandler && props.watch && props.reset) {
      // prepopulate cards after delete using react-hook-form functions
      deleteCardRepopulateValues(
        props.getValues,
        props.setValueHandler,
        props.watch,
        props.reset,
        index,
        lastIndexPreDelete,
        `liability`,
        props.register
      );
    }

    // Update cards' id array
    const numbersArrayCopy = idsArray;
    numbersArrayCopy.splice(index, 1);
    setIdsArray(numbersArrayCopy);

    // Update calculated fields
    const totalLiabilitiesArray = [...props.totalLiabilitiesArray];
    totalLiabilitiesArray.splice(index, 1);
    calculateLiabilities(totalLiabilitiesArray);

    // Scroll into view after delete
    scrollIntoView(`liabilities-${lastIndexPreDelete - 1}`);

    // Disable multiclick
    setDisableDelete(true);
  }

  function calculateLiabilities(totalLiabilitiesArray: number[]): void {
    // pick up values from detail card if they exist
    const detailCard = (props.watch && props.watch(`detail`)) || {};
    const firstMortgageBalance = (detailCard.firstLoan && detailCard.firstLoan.unpaidBalance) || 0;
    const secondMortgageBalance =
      (detailCard.secondLoan && detailCard.secondLoan.unpaidBalance) || 0;
    // set totalLiabilitiesArray and calculate cumulativeTotalLiabilities
    let cumulativeLiabilities = firstMortgageBalance + secondMortgageBalance;
    totalLiabilitiesArray.map((liabilityInstance) => {
      cumulativeLiabilities += liabilityInstance;
    });
    cumulativeLiabilities = Math.round(cumulativeLiabilities * 1e2) / 1e2; // round to two decimals
    props.setTotalLiabilitiesArray(totalLiabilitiesArray);
    props.setCumulativeTotalLiabilities &&
      props.setCumulativeTotalLiabilities(cumulativeLiabilities);
  }

  return (
    <LinedCard
      id={props.id}
      testId={props.testId}
      linePosition={props.linePosition}
      lineColor={props.accordionLineColor}
    >
      <AccordionContainer accordionId={`${props.id}Accordion`} title={props.title.field}>
        {idsArray.map((id, index) => (
          <LiabilitiesContainer
            {...props}
            key={id}
            index={index}
            lastIndexPreDelete={lastIndexPreDelete}
            removeCard={removeCard}
            calculateLiabilities={calculateLiabilities}
            disableDelete={disableDelete}
            setDisableDelete={setDisableDelete}
            isAutoFocus={isAutoFocus}
          />
        ))}
        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              id={`${props.id}TotalLiabilities`}
              title={props.totalLiabilities.field}
              isDisabled={isReadOnlyUser || true}
              value={props.cumulativeTotalLiabilities}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              id={`${props.id}NetAssets`}
              title={props.netAssets.field}
              isDisabled={isReadOnlyUser || true}
              value={netAssets}
            />
          </div>
        </div>
        <hr className="card__divider--thick" />
        <div className="row card__body-row">
          <div className="col-12">
            <button
              className="btn btn__add"
              onClick={addGuarantorAssets}
              type="button"
              disabled={isReadOnlyUser}
            >
              <i
                className="material-icons icon--size-32 icon--v-align-middle"
                title={props.AddGuarantorText.field.value}
                aria-hidden={true}
              >
                add_circle_outline
              </i>
              <Text field={props.AddGuarantorText.field} />
            </button>
          </div>
        </div>
      </AccordionContainer>
    </LinedCard>
  );
};
