import { ArrearsPermissions } from '@hobt/arrears-domain';
import {
  ClaimPermissions,
  CorrespondencePermissions,
  LoanAdministrationPermissions,
  RefundPermissions
} from '@hobt/claim-domain';
import { Module, UserRole } from '@hobt/constants';
import { DefaultsPermissions } from '@hobt/default-domain';
import { DelegationPermissions, UserPermissions } from '@hobt/user-domain';

export interface SecureRoute {
  permissions: Permission[];
  sitecoreItemExternalId?: string;
  sitecoreItemInternalId?: string;
}

export interface Permission {
  modules: Module[];
  roles: UserRole[];
}

export const SecureRouteMappings: SecureRoute[] = [
  /* Claims */
  // details page
  {
    sitecoreItemInternalId: '1442046F-EC76-5C21-9FF1-93A9CDF5FE2F',
    permissions: [
      ClaimPermissions.FinalClaim.Read,
      ClaimPermissions.Claim.Read,
      LoanAdministrationPermissions.Read,
      RefundPermissions.Read
    ]
  },
  {
    sitecoreItemExternalId: '367187F2-1407-5FB4-AA0A-E43C32B3CC54',
    permissions: [ClaimPermissions.FinalClaim.Read, CorrespondencePermissions.ReadCorrespondences]
  },
  // inventory page
  {
    sitecoreItemExternalId: 'A5DE6134-2EE3-5162-9387-7A08AE6E37EF',
    sitecoreItemInternalId: '66F85669-8EFA-5C10-884A-96ADDA91D4C9',
    permissions: [
      {
        modules: Array.from(
          new Set([
            ...ClaimPermissions.FinalClaim.Read.modules,
            ...ClaimPermissions.DraftClaim.Read.modules
          ])
        ),
        roles: Array.from(
          new Set([
            ...ClaimPermissions.FinalClaim.Read.roles,
            ...ClaimPermissions.DraftClaim.Read.roles
          ])
        )
      },
      ClaimPermissions.Claim.Read
    ]
  },
  // submission page
  {
    sitecoreItemExternalId: '97C18857-A341-5B01-B112-1B110AB9441B',
    sitecoreItemInternalId: '9D9EA75B-73C9-500B-BB5E-DF9DBEE89039',
    permissions: [
      {
        modules: ClaimPermissions.Claim.Submit.modules,
        roles: [...ClaimPermissions.Claim.Submit.roles, UserRole.MasterUser]
      }
    ]
  },
  /* Pre claims */
  // itm flags page
  {
    sitecoreItemInternalId: 'B59DE082-E5A9-5F6D-BB13-F770C2B57FE9',
    permissions: [LoanAdministrationPermissions.Read]
  },
  // property repairs page
  {
    sitecoreItemInternalId: 'DF299FFB-76F0-59AF-8A66-05C56900646C',
    permissions: [LoanAdministrationPermissions.Read]
  },
  // underwriting required flags page
  {
    sitecoreItemInternalId: 'BF88E6A8-0317-56B3-8BE4-96F47BF00709',
    permissions: [LoanAdministrationPermissions.Read]
  },
  /* Defaults */
  // inventory
  {
    sitecoreItemExternalId: '72F9FE62-369A-51B4-844B-59E7F657DE1B',
    sitecoreItemInternalId: '7C9EE6CC-F372-5F11-9FAF-FA9E2455556A',
    permissions: [DefaultsPermissions.Read]
  },
  // inventory details
  {
    sitecoreItemExternalId: 'CFB870E7-2364-5A32-97AF-935955AEE9A1',
    sitecoreItemInternalId: '984ED6BF-178B-5B6E-B310-77EA1E62B692',
    permissions: [DefaultsPermissions.Read]
  },
  // submission
  {
    sitecoreItemExternalId: 'E7912629-BC10-5803-81EA-855274D40375',
    sitecoreItemInternalId: 'D5DCBAFB-742B-5748-801F-0384AF1E5426',
    permissions: [DefaultsPermissions.Create]
  },
  /* Delegation */
  // create
  {
    sitecoreItemInternalId: '5D7A2B1D-46A7-5906-A4EE-7E931C4E7660',
    permissions: [DelegationPermissions.Create]
  },
  // authority page
  {
    sitecoreItemInternalId: '6A7CA2E2-7345-593F-9840-533869195AB5',
    permissions: [DelegationPermissions.Read]
  },
  /* User */
  // create
  {
    sitecoreItemExternalId: '9DE2C0EE-8C4D-5E9A-A4EE-18997F6648DA',
    sitecoreItemInternalId: 'DBD82E65-C486-57EF-8CBF-5C048DDCC737',
    permissions: [UserPermissions.Create]
  },
  // modify
  {
    sitecoreItemExternalId: '2A6DDD20-8A32-5290-B882-D4EA668F40B4',
    sitecoreItemInternalId: 'BF1219A8-3396-5CB7-9C6C-230D7C94560E',
    permissions: [UserPermissions.Read, UserPermissions.Update]
  },
  // management (inventory)
  {
    sitecoreItemExternalId: 'B3ED7761-B60A-5B08-83FA-577537207F16',
    permissions: [UserPermissions.ReadInventoryExternal]
  },
  {
    sitecoreItemInternalId: '6F28C016-6E60-5881-8EAC-FEFF117A1C26',
    permissions: [UserPermissions.ReadInventoryInternal]
  },
  /* Approvals */
  // approvals page
  {
    sitecoreItemInternalId: '3C889CDF-80CD-5AF0-B1D4-62102A509565',
    permissions: [
      {
        modules: Array.from(
          new Set([
            ...ClaimPermissions.FinalClaim.ReadApprovals.modules,
            ...ArrearsPermissions.ReadArrearsApprovals.modules
          ])
        ),
        roles: Array.from(
          new Set([
            ...ClaimPermissions.FinalClaim.ReadApprovals.roles,
            ...ArrearsPermissions.ReadArrearsApprovals.roles
          ])
        )
      }
    ]
  },
  /* Arrears */
  // historical records page
  {
    sitecoreItemInternalId: '396F373D-0F94-56DB-961C-AD3D116E0D56',
    permissions: [ArrearsPermissions.Read]
  },
  // arrears summary reports
  {
    sitecoreItemExternalId: '94F58123-2702-510F-AB8D-FD4C82CE98E1',
    sitecoreItemInternalId: 'E9B7512E-FD6E-57D8-9633-3BD7A967C13E',
    permissions: [ArrearsPermissions.ReadSummaryReports]
  },
  // arrears report submission (form)
  {
    sitecoreItemExternalId: '1B8D2D57-D391-508D-AFA6-F1EB1DE0F0C4',
    sitecoreItemInternalId: 'A37AF305-D2F7-55CE-BF0C-3E489702AE1D',
    permissions: [ArrearsPermissions.Create, ArrearsPermissions.ReadActiveReportingPeriods]
  },
  // arrears submission list (inventory)
  {
    sitecoreItemExternalId: '8E735EDF-1824-5F6A-A285-7ECC192D228F',
    sitecoreItemInternalId: 'A3C398C9-F3C5-5EDE-8CD8-3FB5F7F92ABF',
    permissions: [ArrearsPermissions.ReadSubmission]
  },
  // Arrears Error Records
  {
    sitecoreItemInternalId: 'C32DB891-CB28-51B8-9288-32F7DF553CAD',
    permissions: [ArrearsPermissions.ReadValidation]
  },
  // arrears submission details (aka arrears report upload details page)
  {
    sitecoreItemExternalId: 'AAA9D08D-C32D-5A4A-92EC-59DA415729E9',
    sitecoreItemInternalId: '2B72AA89-0325-57C8-A793-37CFE4211260',
    permissions: [
      ArrearsPermissions.ReadActiveReportingPeriods,
      ArrearsPermissions.ReadSubmission,
      ArrearsPermissions.ReadFilesBySubmissionID
    ]
  },
  // submitted reports page
  {
    sitecoreItemInternalId: '05F89EB1-9300-5E8C-9CF4-A2B7882F5E63',
    permissions: [ArrearsPermissions.ReadFiles, ArrearsPermissions.ReadActiveReportingPeriods]
  }
];
