export default function getTextFieldSx(
  isOpen: boolean,
  readOnly: boolean,
  error: boolean | undefined,
  isInputIcon: boolean
) {
  return {
    '& .MuiInputBase-root': {
      paddingTop: '12px',
      paddingBottom: '12px'
    },
    '& .MuiInputBase-root .MuiInputAdornment-root': {
      paddingLeft: isInputIcon ? '8px' : '0'
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '16px',
      color: 'var(--Slate-30B, #4A545D) !important',
      fontFamily: 'var(--Font-Family-Text, Roboto) !important',
      fontSize: 'var(--Font-Regular, 16px) !important',
      fontStyle: 'normal !important',
      fontWeight: 'var(--Weight-Regular, 400) !important',
      lineHeight: 'var(--Line-Height-Regular, 24px) !important',
      fontFeatureSettings: `'liga' off, 'clig' off !important`
    },
    '& .MuiOutlinedInput-root': {
      '& > fieldset': {
        padding: '0',
        borderWidth: 'var(--Spacing-01, 2px)',
        borderStyle: !readOnly ? 'solid' : 'none',
        borderRadius: 'var(--Spacing-02, 4px)',
        borderColor: !error ? 'var(--Slate-50W, #b4bcc2)' : 'var(--Red, #da291c)'
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      outline: !isOpen ? 'var(--Spacing-01, 2px) solid var(--Violet-20W, ##6D65D7)' : '0',
      outlineOffset: 'var(--Spacing-01, 2px)',
      '& > fieldset.MuiOutlinedInput-notchedOutline': {
        padding: '0',
        borderColor: isOpen
          ? !error
            ? 'var(--Slate, #697885)'
            : 'var(--Red, #da291c)'
          : !error
          ? 'var(--Slate-50W, #b4bcc2)'
          : 'var(--Red, #da291c)'
      }
    },
    '& .MuiOutlinedInput-root:hover': {
      '& > fieldset.MuiOutlinedInput-notchedOutline': {
        borderWidth: 'var(--Spacing-01, 2px)',
        borderStyle: !readOnly ? 'solid' : 'none',
        borderColor: !error ? 'var(--Slate, #697885)' : 'var(--Red, #da291c)'
      }
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
      color: 'var(--Slate, #697885) !important',
      fontFamily: 'var(--Font-Family-Text, Roboto) !important',
      fontSize: 'var(--Font-Regular, 16px) !important',
      fontStyle: 'normal !important',
      fontWeight: 'var(--Weight-Regular, 400) !important',
      lineHeight: 'var(--Line-Height-Regular, 24px) !important',
      opacity: '1'
    }
  };
}
