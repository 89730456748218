import { PopperOwnProps } from '@mui/base/Popper/Popper.types';

export type PopperModifiers = PopperOwnProps['modifiers'];
export const optionsOfItemsOnPage = [10, 30, 50, 100] as const;
export type NumberOfItemsOnPageOption = (typeof optionsOfItemsOnPage)[number];

export type PaginationRange = (number | '...')[];

interface BasePaginationProps {
  currentPageNumber: number;
  totalPageNumber: number;
  numberOfItemsOnPage: number;
  totalItemNumber: number;
}

export enum PaginationPosition {
  CENTER = 'position-center',
  LEFT = 'position-left',
  RIGHT = 'position-right'
}

export interface PaginationProps extends BasePaginationProps {
  isCondensed: boolean;
  isOnTablet: boolean;
  paginationPosition?: PaginationPosition;
  onPageChange: (pageNumber: number) => void;
  onItemsPerPageChange: (itemsPerPage: number) => void;
}

export type PaginationItemsOnPageProps = Pick<
  BasePaginationProps,
  'numberOfItemsOnPage' | 'totalItemNumber' | 'currentPageNumber'
> & {
  onItemsPerPageChange: (itemsPerPage: number) => void;
};

export type PaginationPageButtonsProps = Pick<
  BasePaginationProps,
  'currentPageNumber' | 'totalPageNumber'
> & {
  paginationRange: PaginationRange;
  onPageChange: (pageNumber: number) => void;
};
