import React, { useState, useEffect, useMemo } from 'react';
import { Element } from 'react-scroll';
import { Prompt, useHistory } from 'react-router-dom';
import { DeepMap, FieldError, FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import i18n from 'i18next';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Text, RichText, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { arrearsSubmissionFrontendSchema, arrearsFilesFrontendSchema } from '@hobt/arrears-domain';
import { hbtResolver } from '@hobt/schema-validator';
import { LanguageShort, UserRole, ModuleMapping } from '@hobt/constants';

import { pathNames } from 'Constants/pathNames';
import { SubmissionType } from 'Feature/Arrears/models/types';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { HBTFormActionsContext } from 'Feature/Claims/components/HBTFormContext';

import { useArrearsSubmissionFunctions } from 'Feature/Arrears/components/ArrearsSubmissionForm/useArrearsSubmissionFunctions';
import {
  ToastNotification,
  ToastNotificationProps
} from 'Feature/CommonComponents/ContentComponents';
import { ProgressBar } from 'Components/ProgressBar';
import { StickyContainer } from 'Components/StickyContainer';
import { StickyFooter } from 'Components/PageComponents/StickyFooter';
import { Button, ButtonType } from 'Feature/CommonComponents/UserControls';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';
import ArrearsSubmissionFormProps from 'Feature/Arrears/models/ArrearsSubmissionFormProps';

import ArrearsAdditionalRemarks from '../Cards/ArrearsAdditionalRemarks';
import {
  ArrearsSubmitResponse,
  ArrearsSubmissionObject,
  SubmittedArrearsType,
  ArrearsFilesObject,
  sitecoreComponentToFieldNameMap
} from './types';
import ArrearsContactDetails from '../Cards/ArrearsContactDetails';
import NotificationCardComponent from '../DisplayNotificationCard';
import { submitArrearsRequest } from './ArrearsSubmissionService';
import ArrearsReport from '../Cards/ArrearsReport';
import ArrearsResources from '../ArrearsResources';
import './ArrearsSubmissionForm.css';

import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const arrearsLeftMapping: Record<string, React.FC<any>> = {
  ArrearsContactDetails,
  ArrearsReport,
  ArrearsAdditionalRemarks
};

const ArrearsSubmissionForm = (props: ArrearsSubmissionFormProps) => {
  const { heading, subHeading, warningIcon } = props.fields;
  const { rightContent, leftContent } = props?.rendering?.placeholders;
  const history = useHistory();
  const authenticationContext = useAuthenticationContext();
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.arrears,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  const {
    setCompleteCards,
    isCardComplete,
    addCompleteCard,
    removeCompleteCard,
    clearCompleteCards
  } = useArrearsSubmissionFunctions([]);

  const [isBypass, setBypass] = useState<boolean>(false);
  const [errorFlag, setErrorFlag] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('');
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<DeepMap<Record<string, any>, FieldError> | null>(
    null
  );
  const [isSubmitFailed, setSubmitFailed] = useState<boolean>(false);
  const [isArrearsToast, setArrearsToast] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<Array<string> | null>(null);
  const [{ isLoading, spinnerHeading, spinnerDescription }, setIsLoading] = useState<Spinner>({
    isLoading: false
  });

  const methods = useForm({
    resolver: hbtResolver(arrearsSubmissionFrontendSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      submission: {
        contact: {
          emailID: undefined,
          extensionPhoneNumber: '',
          financialInstitutionCode: '',
          name: '',
          phoneNumber: undefined
        },
        remarkText: undefined,
        reportingPeriod: ''
      }
    }
  } as Record<string, any>);

  const filesMethods = useForm({
    resolver: hbtResolver(arrearsFilesFrontendSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  } as Record<string, any>);

  const onReset = (): void => {
    methods.clearErrors();
    history.push(`/${i18n.language}${pathNames.dashboard}`);
  };

  const handleToastAction = (type: SubmissionType) => {
    setBypass(type !== SubmissionType.ERROR);
    setSubmitFailed(type === SubmissionType.ERROR);
    setArrearsToast(type !== SubmissionType.OTHER);
  };

  const displayNotificationCard = () => {
    setErrorFlag(true);
    window.scrollTo(0, 0);
  };

  const onError = async (err?: Object): Promise<void> => {
    setIsSubmitBtnDisabled(false);
    // throw the missing attachments error if there are no attachments
    const containsAttachments: boolean = await filesMethods.trigger(`arrearsFiles[0].attachment`);
    if (!containsAttachments) {
      await filesMethods.trigger(`arrearsFiles[0].transitNumber`);
    }

    setIsLoading({ isLoading: false });
    displayNotificationCard();

    /* eslint-disable no-console */
    if (err) console.log('err', err);
  };

  const setErrorDataHandler = (data: Array<string> | null) => setErrorData(data);

  const submitArrearsForm = async (data: {
    submission?: ArrearsSubmissionObject;
  }): Promise<void> => {
    // perform attachment validation, ensure attachments are present
    const isAttachmentSuccess: boolean = await filesMethods.trigger(`arrearsFiles[0].attachment`);
    if (!isAttachmentSuccess) {
      await filesMethods.trigger(`arrearsFiles[0].transitNumber`);
      displayNotificationCard();
    }

    if (isAttachmentSuccess === true) {
      // remove the arrearsFiles array item if it was not used and does not contain an attachment
      const fileData: ArrearsFilesObject[] = filesMethods
        .getValues()
        .arrearsFiles.filter((file: ArrearsFilesObject) => file.attachment !== undefined);
      const formData: SubmittedArrearsType = {
        ...data,
        arrearsFiles: fileData
      };
      setIsSubmitBtnDisabled(true);
      setIsLoading({
        isLoading: true,
        spinnerHeading: 'Globals-Saving-Heading',
        spinnerDescription: 'Globals-Saving-Description'
      });

      const result = await submitArrearsRequest(
        authenticationContext,
        formData,
        setErrorDataHandler
      );

      switch (result) {
        case ArrearsSubmitResponse.Success:
          setIsSubmitBtnDisabled(true);
          setErrorFlag(false);
          handleToastAction(SubmissionType.SUBMIT);
          setIsLoading({
            isLoading: false
          });
          break;
        case ArrearsSubmitResponse.InvalidFiCode:
        case ArrearsSubmitResponse.NoAccessToFiCode:
          setErrorCode('ficode');
          onError();
          break;
        case ArrearsSubmitResponse.InvalidTransitForFiCode:
        case ArrearsSubmitResponse.NoAccessToTransit:
          setErrorCode('transit');
          onError();
          break;
        case ArrearsSubmitResponse.NotAuthorized:
        case ArrearsSubmitResponse.ServerError:
          handleToastAction(SubmissionType.ERROR);
          setIsLoading({
            isLoading: false
          });
          setIsSubmitBtnDisabled(false);
          break;
        default:
          onError();
          break;
      }
    }
  };

  const arrearsAdditionalPropMappings: Record<string, any> = {
    ArrearsReport: { errorCode, errorData, filesMethods, setIsLoadingCallback: setIsLoading },
    ArrearsContactDetails: { errorCode },
    ProgressBar: {
      cards: leftContent,
      title: rightContent[0].fields.heading,
      primaryButtonText: rightContent[0].fields.submitButton,
      secondaryButtonText: rightContent[0].fields.saveDraftButton,
      secondaryButtonOnClickCallback: () => onReset(),
      isDisabled: isReadOnlyUser
    }
  };

  const { dirtyFields } = methods.formState;

  useEffect(() => {
    if (isServer() === false) {
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, []);

  // isDirty check
  useEffect(() => {
    if (isServer() === false) {
      // onbeforeunload should trigger on back, forward, refresh, and link button clicks
      window.onbeforeunload = () => {
        // trigger browser warning
        if (Object.keys(dirtyFields).length > 0 && !isBypass) {
          // returning anything, will trigger the prompt,
          // return string only read on IE:
          return i18n.t('DefaultSubmission-IsDirty');
        }
        return null;
      };
    }
  }, [dirtyFields, isBypass]);

  // combine errors from both forms
  useEffect(() => {
    let errors: DeepMap<Record<string, any>, FieldError> = {};
    if (methods.formState.errors != null && Object.keys(methods.formState.errors).length > 0) {
      errors = methods.formState.errors;
    }

    if (
      filesMethods.formState.errors != null &&
      Object.keys(filesMethods.formState.errors).length > 0
    ) {
      errors = {
        ...errors,
        ...filesMethods.formState.errors
      };
    }
    setFieldErrors(errors);
  }, [methods.formState.errors, filesMethods.formState.errors]);

  const toastNotificationProps: ToastNotificationProps = {
    isActive: isArrearsToast,
    title: i18n.t(
      `Default${
        isSubmitFailed ? 'Submission-ErrorToast' : 'sInventoryTable-DecisioningToastMessage-'
      }Title`
    ),
    type: ApplicationStates[isSubmitFailed ? 'ERROR' : 'SUCCESS'],
    content: i18n.t(
      isSubmitFailed ? 'DefaultSubmission-OtherErrors' : 'ArrearsSubmission-SuccessToastMessage'
    ),
    onCloseCallback: () => {
      handleToastAction(SubmissionType.OTHER);
      if (!isSubmitFailed) {
        history.push(`/${i18n.language}${pathNames.dashboard}`);
      }
    }
  };

  const { watch, errors, formState }: any = methods;

  const formData = watch();
  const { touchedFields } = formState;

  const { watch: fileMethodsWatch } = filesMethods;
  const fileData = fileMethodsWatch();

  const hasAllElems = (arr: string[], target: string[]) =>
    target.every((elem) => arr.includes(elem));

  const checkContactDetailsErrors = () => {
    let check = false;
    Object.values(errors?.submission?.contact ?? {}).forEach((value) => {
      // @ts-ignore
      if (value.message !== '') {
        check = true;
      }
    });
    return check;
  };

  useEffect(() => {
    const touchedElements = touchedFields?.submission;
    const filledElements = methods.getValues()?.submission;

    if (filledElements?.contact !== undefined) {
      const filledContactElem = Object.entries(filledElements.contact)
        ?.filter((arr) => arr[1] !== '' && arr[1] != null && !Number.isNaN(arr[1]))
        ?.map((arr) => arr[0]);

      const contactRequired =
        arrearsSubmissionFrontendSchema?.properties?.submission?.properties?.contact.required;
      const isContactComplete = hasAllElems(filledContactElem, contactRequired ?? []);

      let contactDetailsError = false;

      if (errors?.submission?.contact) {
        contactDetailsError = checkContactDetailsErrors();
      }

      if (!contactDetailsError && isContactComplete) {
        addCompleteCard(sitecoreComponentToFieldNameMap.contactDetails);
      }
    }

    if (touchedElements?.remarkText !== undefined) {
      addCompleteCard(sitecoreComponentToFieldNameMap.additionalRemarks);
    }
  }, [formData]);

  useEffect(() => {
    const hasAttachment = fileData?.arrearsFiles?.findIndex(
      (file: ArrearsFilesObject) => file.attachment !== undefined
    );

    const fileCardComplete = isCardComplete(sitecoreComponentToFieldNameMap.arrearsReport);

    if (fileCardComplete && (hasAttachment === undefined || hasAttachment === -1)) {
      removeCompleteCard(sitecoreComponentToFieldNameMap.arrearsReport);
    }

    if (hasAttachment !== undefined && hasAttachment !== -1) {
      addCompleteCard(sitecoreComponentToFieldNameMap.arrearsReport);
    }
  }, [fileData]);

  const resources = useMemo(
    () => <ArrearsResources fields={rightContent?.[1]?.fields} />,
    [rightContent?.[1]?.fields]
  );

  return (
    <div>
      <ContentLoadingModal
        display={isLoading}
        fields={{
          heading: { value: i18n.t(spinnerHeading ?? 'Globals-InProgress-Heading') },
          description: { value: i18n.t(spinnerDescription ?? 'Globals-InProgress-Description') }
        }}
      />
      <HBTFormActionsContext.Provider
        value={{
          setUuid: () => {},
          setCompleteCards,
          isCardComplete,
          addCompleteCard,
          removeCompleteCard,
          clearCompleteCards,
          enableEditMode: () => {},
          disableEditMode: () => {}
        }}
      >
        {errorFlag ? (
          <span
            className={`${styles.notificationCard} ${
              i18n.language === LanguageShort.French ? styles.notificationCardInFrench : ''
            }`.trim()}
          >
            <NotificationCardComponent
              errors={
                fieldErrors != null && Object.keys(fieldErrors).length > 0 ? fieldErrors : errorCode
              }
              warningIcon={warningIcon}
            />
          </span>
        ) : null}
        <div className="row mb-3">
          <div className="col-12">
            <h2>
              <Text field={heading} />
            </h2>
            <small>
              <RichText field={subHeading} />
            </small>
          </div>
        </div>
        {/* React Router prompt on route navigations  */}
        <Prompt
          when={Object.keys(dirtyFields).length > 0 && !isBypass}
          message={i18n.t('DefaultSubmission-IsDirty')}
        />
        <FormProvider {...methods}>
          <form
            id="arrearsSubmissionForm"
            onSubmit={methods.handleSubmit(submitArrearsForm, onError)}
            className="form arrears-submission-form"
            noValidate
          >
            <div className={styles.layoutContainer}>
              <div>
                <Element name="arrearsResourcesMobile" className={styles.hideForDesktop}>
                  {React.createElement(ArrearsResources, {
                    fields: rightContent[1].fields
                  })}
                </Element>
                {leftContent?.map((card: any, index: number) => {
                  return (
                    <Element
                      name={card.fields.name?.value}
                      key={card.componentName + index}
                      className={styles.arrearsContactDetailsCard}
                    >
                      {React.createElement(arrearsLeftMapping[card.componentName], {
                        ...arrearsAdditionalPropMappings[card.componentName],
                        fields: card.fields,
                        key: index
                      })}
                    </Element>
                  );
                })}
              </div>
              <aside className={styles.showForDesktopOnly}>
                <StickyContainer
                  containerId="arrears-progress-bar"
                  topOffset={25}
                  shouldFillParentWidth={true}
                >
                  <ProgressBar
                    bodyClassName=""
                    cards={leftContent}
                    title={rightContent?.[0]?.fields?.heading}
                    scrollDuration={500}
                    cardNameToValidationFieldMap={sitecoreComponentToFieldNameMap}
                    primaryButtonText={rightContent?.[0]?.fields?.submitButton}
                    primaryButtonAriaLabel={rightContent?.[0]?.fields?.submitButton}
                    secondaryButtonText={rightContent?.[0].fields?.saveDraftButton}
                    secondaryButtonAriaLabel={rightContent?.[0].fields?.saveDraftButton}
                    secondaryButtonOnClickCallback={onReset}
                    isDisabled={isReadOnlyUser || isSubmitBtnDisabled}
                  />
                  {resources}
                </StickyContainer>
              </aside>
            </div>
            <StickyFooter>
              <div className={`${styles.stickyFooter} ${styles.hideForDesktop}`}>
                <Button
                  disabled={isSubmitBtnDisabled}
                  id="submitButton"
                  name="submit"
                  buttonType={ButtonType.PRIMARY}
                  displayText={arrearsAdditionalPropMappings.ProgressBar.primaryButtonText.value}
                />
                <Button
                  id="saveDraftButton"
                  name="cancel"
                  buttonType={ButtonType.SECONDARY}
                  displayText={arrearsAdditionalPropMappings.ProgressBar.secondaryButtonText.value}
                  onClick={() => {
                    arrearsAdditionalPropMappings.ProgressBar.secondaryButtonOnClickCallback();
                  }}
                />
              </div>
            </StickyFooter>
          </form>
        </FormProvider>
        <ToastNotification {...toastNotificationProps} />
      </HBTFormActionsContext.Provider>
    </div>
  );
};

export default ArrearsSubmissionForm;
