import React, { useState, useEffect } from 'react';
import { AccountStatus } from '@hobt/constants';
import { UserAndAccesses } from '@hobt/user-domain';
import axios from 'axios';
import { pathNames } from 'Constants/pathNames';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useFeature } from 'flagged';
import { ApiClient } from 'Foundation/Api';
import i18n from 'i18next';
import { config } from '../../../../../config';
import { preProcessFormData } from '../HbtUserAccessManagementApi/HbtUserAccessApiHandler.service';
import { HbtModifyInternalPIUserActionType, HbtModifyInternalPIUserAction } from './types';

export const HbtUseInternalPIUserApiFunctions = (
  dispatch: React.Dispatch<HbtModifyInternalPIUserAction>,
  push: Function,
  authenticationContext: any,
  userId?: string,
  sitecoreContext?: any
) => {
  const [userData, setUserData] = useState<UserAndAccesses | null>(null);
  let [formPayload, setFormPayload] = useState<any>();

  const cancelTokenSource = axios.CancelToken.source();
  const siteTypeIsExternal = useFeature(FeatureFlags.EXTERNAL);

  const { getWithAuth, putWithAuth } = ApiClient(authenticationContext, {
    timeout: config.userApi.requestTimeout
  });

  const getUserUrl: string = config.userApi.urls.getUser;

  const onError = (err: any) => {
    console.log('User Details Err:', err);
    console.log('User Details Data', userData);
    dispatch({
      action: HbtModifyInternalPIUserActionType.ON_ERROR,
      payload: {
        function: () => {
          dispatch({ action: HbtModifyInternalPIUserActionType.DISMISS_TOAST });
        }
      }
    });
  };

  const getUserData = async (): Promise<void> => {
    try {
      const { data: responseData } = await getWithAuth(
        `${getUserUrl}?id=${userId}&language=${i18n.language}`,
        {
          cancelToken: cancelTokenSource.token
        }
      );
      setUserData(responseData?.data?.[0]);
    } catch (e) {
      onError(e);
    }
  };

  const userUpdateApi = async () => {
    try {
      dispatch({
        action: HbtModifyInternalPIUserActionType.SET_IS_LOADING,
        payload: { value: true }
      });
      const payloadData = { ...formPayload };
      const payload = preProcessFormData(payloadData, sitecoreContext);

      const { data: responseData } = await putWithAuth(`${config.userApi.urls.modify}`, payload, {
        cancelToken: cancelTokenSource.token
      });
      setUserData(responseData?.data?.[0]);
      dispatch({
        action: HbtModifyInternalPIUserActionType.SUBMIT_SUCCESS,
        payload: {
          function: () => {
            dispatch({ action: HbtModifyInternalPIUserActionType.DISMISS_TOAST });
            push(`/${i18n.language}${pathNames.userManagement}`);
          }
        }
      });
    } catch (e) {
      onError(e);
    }
  };

  const onUserUpdate = async () => {
    dispatch({ action: HbtModifyInternalPIUserActionType.DISMISS_UPDATE_MODAL });
    userUpdateApi();
  };

  const onSubmit = async (formData: any) => {
    formPayload = formData;
    formData.internalContact = undefined;

    setFormPayload(formData);
    if (siteTypeIsExternal) {
      dispatch({ action: HbtModifyInternalPIUserActionType.SHOW_UPDATE_MODAL });
    } else {
      userUpdateApi();
    }
  };

  const onUserDelete = async () => {
    const payload = {
      statusCode: AccountStatus.DISABLED.toString(10),
      emailID: userData?.emailID
    };

    try {
      dispatch({
        action: HbtModifyInternalPIUserActionType.SET_IS_MODAL_LOADING,
        payload: { value: true }
      });
      await putWithAuth(`${config.userApi.urls.delete}`, payload, {
        cancelToken: cancelTokenSource.token
      });
      dispatch({
        action: HbtModifyInternalPIUserActionType.SET_IS_MODAL_LOADING,
        payload: { value: false }
      });
      dispatch({
        action: HbtModifyInternalPIUserActionType.SUBMIT_SUCCESS,
        payload: {
          function: () => {
            dispatch({ action: HbtModifyInternalPIUserActionType.DISMISS_TOAST });
            push(`/${i18n.language}${pathNames.userManagement}`);
          }
        }
      });
    } catch (e) {
      onError(e);
    }
  };

  useEffect(() => {
    if (userId != null) {
      dispatch({
        action: HbtModifyInternalPIUserActionType.SET_IS_PAGE_LOADING,
        payload: { value: true }
      });
      getUserData().then((_res) => {
        dispatch({
          action: HbtModifyInternalPIUserActionType.SET_IS_PAGE_LOADING,
          payload: { value: false }
        });
      });
    }
    if (userId == null) {
      onError(userId);
    }

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  return {
    userData,
    onSubmit,
    onUserDelete,
    onError,
    onUserUpdate,
    userUpdateApi
  };
};
