import { Text } from '@sitecore-jss/sitecore-jss-react';
import * as React from 'react';

import { Tabs } from 'Components/Navigation/Tabs';

import { NotificationActionBarProps } from './types';

import styles from './styles.module.scss';

export const NotificationActionBar: React.FC<NotificationActionBarProps> = ({
  markAllAsRead,
  tabs,
  tabsControlId,
  onMarkAllReadClick,
  onTabClick,
  showMarkAllButton,
  notificationTitle
}: NotificationActionBarProps) => (
  <div className={styles.notificationActionBar}>
    <Tabs
      className={styles.notificationActionBarTabs}
      items={tabs}
      tabOnClickCallback={onTabClick}
      ariaControlsName={tabsControlId}
      label={notificationTitle}
    />
    {showMarkAllButton && (
      <button
        className={styles.notificationActionBarReadAllButton}
        aria-label={markAllAsRead.value}
        onClick={onMarkAllReadClick}
      >
        <span aria-hidden className="material-icons-outlined align-middle icon--size-16">
          drafts
        </span>
        <Text tag="p" field={markAllAsRead} />
      </button>
    )}
  </div>
);
