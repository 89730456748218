export function removeEmptyFields(data: { [key: string]: any }): any {
  const d = data || {};
  return Object.keys(data).length === 0 ? d : JSON.parse(JSON.stringify(d, emptyReplacer));
}

function emptyReplacer(key: string, value: any) {
  if (value == null || value.length < 1) {
    return undefined;
  }
  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return undefined;
  }
  if (Array.isArray(value)) {
    return value.map((v) => JSON.parse(JSON.stringify(v, emptyReplacer)));
  }

  return value;
}
