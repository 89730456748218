import React, { memo, useCallback, useMemo, useState } from 'react';
import { AppBar, Box, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { FlyoutContainer } from 'Components/Core/Flyout/FlyoutContainer';
import HeaderActionLink from 'Components/Core/Header/HeaderActionLink';
import { pathNames } from 'Constants/pathNames';
import HeaderComponentProps from 'Feature/Header/models/HeaderComponentProps';
import { useAuthenticationContext } from 'Foundation/Authentication';
import i18n from 'i18next';
import { useLocation } from 'react-router-dom';
import RoutableSitecoreLink from '../../../../RoutableSitecoreLink';
import HbtHeaderSideMenu from '../HbtHeaderSideMenu';
import HbtLanguageSwitcher from '../HbtLanguageSwitcher';
import styles from './styles.module.scss';

const HbtHeaderComponent = (props: HeaderComponentProps) => {
  const location = useLocation();

  const authenticationContext = useAuthenticationContext();
  const isApplicationOr500Error = !isServer()
    ? window.location.pathname.endsWith(pathNames.errorApplication) ||
      window.location.pathname.endsWith(pathNames.error500)
    : false;

  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'));

  const appBarStyles = useMemo(
    () =>
      `${styles.appBar} ${!isMediumUp ? styles.tabletBar : ''} ${
        !authenticationContext.isAuthenticated ? styles.transparentBar : ''
      }`,
    [authenticationContext.isAuthenticated, isMediumUp]
  );

  const toolbarStyles = useMemo(
    () =>
      `${styles.toolbar} ${
        !authenticationContext.isAuthenticated ? styles.rightContainerOnly : ''
      }`,
    [authenticationContext.isAuthenticated]
  );

  const [isActive, setIsActive] = useState(false);

  const notificationBtnClickHandler = useCallback(
    (e?: React.MouseEvent | React.KeyboardEvent) => {
      if (e) {
        e.stopPropagation();
        setIsActive(!isActive);
      }
    },
    [isActive]
  );

  const handleSkipToContent = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      const normalizedPathname = location?.pathname.replace(/\/+$/, '');
      const dashboardPaths = [`/${i18n.language}${pathNames.dashboard}`, pathNames.dashboard];
      const isDashboardPath = dashboardPaths.some((path) => normalizedPathname.startsWith(path));
      const mainContentElement = document.querySelector(
        '#purposeTestReportHeading, #fileTransferTitle, #piRequestTitle, #piUserAccessManagementTitle'
      ) as HTMLElement;

      if (isDashboardPath) {
        const dashboardHeaderElement = document.querySelector(
          '#dashboard-header-text'
        ) as HTMLElement;
        dashboardHeaderElement?.focus();
      } else if (mainContentElement) {
        mainContentElement.focus();
      } else {
        e.currentTarget.blur();
      }
    },
    [location?.pathname]
  );

  return (
    <>
      {authenticationContext.isAuthenticated && (
        <AppBar
          role="banner"
          position="static"
          className={appBarStyles}
          aria-label="Global Header"
          id="header-app-bar"
        >
          <Toolbar disableGutters className={toolbarStyles}>
            <a onClick={handleSkipToContent} className={styles.skipLink} href="#maincontent">
              <u>{props?.fields?.skipContentLabel?.value ?? ''}</u>
            </a>
            {authenticationContext.isAuthenticated && (
              <Box className={styles.leftContainer}>
                <RoutableSitecoreLink link={props.fields.headerHomeLink}>
                  <div className={styles.logoTitleContainer}>
                    <Box className={styles.logoContainer}>
                      <Image field={props.fields.headerLogo} className={styles.headerLogoImage} />
                    </Box>
                    <div className={styles.textWrapper}>
                      <p className={styles.eyebrowHeaderText} id="eyebrow-header-text">
                        {props.fields.headerHeading.value}
                      </p>
                      <p className={styles.headerText} id="header-text">
                        {props.fields.headerSubTitle.value}
                      </p>
                    </div>
                  </div>
                </RoutableSitecoreLink>
              </Box>
            )}

            <Box className={styles.rightContainer}>
              <HbtLanguageSwitcher />
              {authenticationContext.isAuthenticated &&
                (!authenticationContext.displayTermsOfUseFlag ||
                  authenticationContext.isTermsAndConditionsAccepted() ||
                  isApplicationOr500Error) && (
                  <>
                    {!isApplicationOr500Error && (
                      <>
                        <HeaderActionLink
                          iconType="icon_notification_none"
                          isMediumUp={isMediumUp}
                          text={props.fields.notificationsText.value ?? 'NotificationsX'}
                          onToggle={notificationBtnClickHandler}
                        />
                        <FlyoutContainer
                          id="header-notification-flyout-container"
                          isHeaderRequired={true}
                          headerText={props.fields.notificationsText.value ?? 'NotificationsX'}
                          open={isActive}
                          onClose={() => setIsActive(false)}
                          content={<></>}
                        />
                      </>
                    )}
                    <HbtHeaderSideMenu
                      text={props.fields.menuText}
                      sideMenuItems={
                        isApplicationOr500Error === false
                          ? props.fields.sideMenuItems?.[0]?.fields?.subMenu ?? ''
                          : []
                      }
                      logoutLabel={props.fields.sideMenuItems?.[0]?.fields?.logoutLabel ?? ''}
                    />
                  </>
                )}
            </Box>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default memo(HbtHeaderComponent);
