import React, { useState, useEffect } from 'react';
import {
  UserRole,
  Module,
  HbtFileExtensionType,
  HttpResponseStatusCodes,
  ModuleKeyword
} from '@hobt/constants';
import { Text, Image, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { useAttachmentClient } from 'Components/Hooks/AttachmentClient';
import { TooltipIcon } from 'Components/Inputs/TooltipIcon';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { useDefaultFormActionsContext } from 'Feature/DefaultsInventory/components/DefaultFormContext';
import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import i18n from 'i18next';
import prettyBytes from 'pretty-bytes';
import Dropzone from 'react-dropzone';
import { withTranslation } from 'react-i18next';
import { config } from '../../../../../config';
import { LinedCard, AccordionContainer } from '../../../../CommonComponents/AccordionComponents';
import FormInputDropdownText from '../../FormInputDropdownText';
import FormInputMultiLineText from '../../FormInputMultiLineText';
import { AttachmentFormProps, AttachmentWrapper, FetchedAttachment } from './AttachmentForm.types';
import './AttachmentForm.css';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

let attachmentCounter = 0;
const elapsedUploadTimes: number[] = [];
const isAttachmentUploading: boolean[] = [];
const cancelTokenSources: { token: any; cancel: any }[] = [];
const AllowedFileTypes = [
  HbtFileExtensionType.CSV,
  HbtFileExtensionType.XLSX,
  HbtFileExtensionType.XLSM,
  HbtFileExtensionType.XLSB,
  HbtFileExtensionType.XLTX,
  HbtFileExtensionType.XLTM,
  HbtFileExtensionType.XLS,
  HbtFileExtensionType.XLT,
  HbtFileExtensionType.XML,
  HbtFileExtensionType.XLAM,
  HbtFileExtensionType.XLA,
  HbtFileExtensionType.XLW,
  HbtFileExtensionType.XLR,
  HbtFileExtensionType.PDF,
  HbtFileExtensionType.JPG,
  HbtFileExtensionType.DOC,
  HbtFileExtensionType.DOCX
];

// [EM] Commenting out as this is breaking the build
// const uploadProgressEvent = new Event('uploadprogress')

export const AttachmentForm: React.FC<any> = (props: AttachmentFormProps) => {
  const authenticationContext = useAuthenticationContext();
  const { getDownloadDocumentUrl } = useAttachmentClient(
    ModuleKeyword.Default,
    authenticationContext
  );

  const [isCardVisible, setIsCardVisible] = useState(true);
  const [attachmentType, setAttachmentType] = useState(1);
  const [description, setDescription] = useState('');
  const [attachments, setAttachments] = useState<AttachmentWrapper[]>([]);
  const [uploadTrigger, setUploadTrigger] = useState(false);
  const [resetTrigger, setResetTrigger] = useState(true);
  const [uploadProgress, setUploadProgress] = useState<number[]>([]);
  const [isFullDescriptionVisible, setIsFullDescriptionVisible] = useState<boolean[]>([]);
  const [isShowMoreVisible, setIsShowMoreVisible] = useState<boolean[]>([]);
  const [showAttachmentToast, setShowAttachmentToast] = useState(false);
  const [attachmentToastType] = useState(ApplicationStates.ERROR);
  const [attachmentToastTitle, setAttachmentToastTitle] = useState('');
  const [attachmentToastMsg, setAttachmentToastMsg] = useState('');
  const [attachmentDescriptionRefs, setAttachmentDescriptionRefs] = useState<
    React.RefObject<HTMLInputElement>[]
  >([]);

  const defaultFormActions = useDefaultFormActionsContext();

  const apiClientConfig: ApiClientConfig = { timeout: config.documentApi.requestTimeout };

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const isReadOnlyUser = isUserInRoles(
    Module.Default,
    [UserRole.ReadOnly],
    sitecoreContext?.user?.moduleRoleMapping
  );

  let errMessage = '';

  const checkFile = (size: number, name: string) => {
    // check size
    if (size > parseInt(config.documentApi.maxFileSize!, 10)) {
      errMessage = i18n
        .t('Errors-HBT_ERR_6004')
        .replace('{maxSize}', String(parseInt(config.documentApi.maxFileSize!, 10) / 1024 ** 2));
      return true;
    }

    // check type
    if (
      AllowedFileTypes !== undefined &&
      AllowedFileTypes.length > 0 &&
      !AllowedFileTypes.includes(
        name
          .slice((Math.max(0, name.lastIndexOf('.')) || Infinity) + 1)
          .toLowerCase() as HbtFileExtensionType
      )
    ) {
      errMessage = i18n.t('Errors-HBT_ERR_6003');
      return true;
    }
    return false;
  };

  function handleAttachmentTypeChange(event: any) {
    setAttachmentType(Number(event.target.value));
  }

  function handleDescriptionChange(event: any) {
    setDescription(event.target.value);
  }

  function handleAttachmentUpload(rawAttachments: File[]) {
    isAttachmentUploading[attachmentCounter] = false;
    props.indicateAttachmentUploadStatus(isAttachmentUploading.includes(true));
    const newAttachments = rawAttachments.map((attachment: File) => {
      attachmentCounter += 1;
      const updatedAttachment: AttachmentWrapper = {
        id: attachmentCounter,
        attachmentType,
        name: attachment.name,
        size: attachment.size,
        description,
        attachment,
        isUploaded: false,
        isError: checkFile(attachment.size, attachment.name),
        errorMessage: errMessage
      };

      return updatedAttachment;
    });
    setAttachments(attachments.concat(newAttachments));
    if (newAttachments.every((attachmentWrapper: AttachmentWrapper) => attachmentWrapper.isError))
      return;
    setAttachmentType(1);
    setDescription('');
    // We pull this trigger to indicate that a file is being uploaded. The
    // value of the trigger is irrelevant; the point is that the value
    // changes, triggering useEffect(). We chose to make the trigger a
    // boolean and toggle it back and forth, but anything else would
    // have done equally well. Same with the resetTrigger.
    setUploadTrigger(!uploadTrigger);
    setResetTrigger(!resetTrigger);
  }

  function getRemainingTimeEstimate(percentUploaded: number, elapsedUploadTime: number) {
    const estimate = Math.max(
      0,
      Math.round(elapsedUploadTime / (percentUploaded * 0.01) - elapsedUploadTime)
    );
    if (typeof estimate !== 'number' || Number.isNaN(estimate)) {
      return props.calculatingRemainingTime;
    }
    if (estimate === 1) return props.oneSecondLeft;
    // This means it's still in its initial stages; we set the threshold to 90 to be safe
    if (estimate === 0 && percentUploaded < 90) return props.calculatingRemainingTime;
    if (estimate > 59)
      return props.estimatedMinutesAndSeconds
        .replace('{minutesCount}', Math.floor(estimate / 60).toString())
        .replace('{secondsCount}', (estimate % 60).toString());
    return props.estimatedSeconds.replace('{secondsCount}', estimate.toString());
  }

  useEffect(() => {
    if (props.downloadMode) {
      const processedAttachments: AttachmentWrapper[] = [];
      const attachmentDescriptionRefsTemp: React.RefObject<HTMLInputElement>[] = [];

      if (props.attachments != null && props.attachments.length > 0) {
        const incomingAttachments = props.attachments?.map(({ metadata, documentID }, idx) => ({
          id: idx,
          attachmentType: metadata.documentType,
          name: metadata.fileName,
          size: metadata.fileSize,
          documentId: documentID
        }));

        attachmentCounter = props.attachments.length - 1;
        setAttachments(incomingAttachments);
        setAttachmentDescriptionRefs(incomingAttachments.map((_) => React.createRef()));
      } else {
        ApiClient(authenticationContext, apiClientConfig)
          .getWithAuth(`${config.documentApi.urls.get!}${props.defaultRequestId}`)
          .then((response: AxiosResponse) => {
            const fetchedAttachments =
              response.data &&
              response.data.data &&
              response.data.data.map(
                (rawFetchedAttachment: any) => rawFetchedAttachment.documentEntityJson
              );

            fetchedAttachments.forEach((fetchedAttachment: FetchedAttachment, index: number) => {
              processedAttachments.push({
                id: index,
                attachmentType: fetchedAttachment.documentType,
                name: fetchedAttachment.fileName,
                size: fetchedAttachment.fileSize,
                description: fetchedAttachment.description,
                documentLink: fetchedAttachment.documentLink
              });
              attachmentCounter =
                processedAttachments.length > 0 ? processedAttachments.length - 1 : 0;
              attachmentDescriptionRefsTemp.push(React.createRef());
            });
            setAttachments(processedAttachments);
            setAttachmentDescriptionRefs(attachmentDescriptionRefsTemp);
          })
          .catch(() => {
            setIsCardVisible(false);
          });
      }
    }
  }, []);

  useEffect(() => {
    attachments
      .filter(
        /*
          explicitly check isUploaded against false instead of doing a !isUploaded as
          !isUploaded would also accept undefined in addition to false. Thus, it would include older attachments that
          were already uploaded in previous update as those no longer contain the isUploaded attribut
          but we don't want those to be included in the upload below as those are already uploaded
        */
        (attachmentWrapper: AttachmentWrapper) =>
          attachmentWrapper.isUploaded === false &&
          !attachmentWrapper.isError &&
          !isAttachmentUploading[attachmentWrapper.id] &&
          !checkFile(attachmentWrapper.size, attachmentWrapper.name)
      )
      .forEach((attachmentWrapper: AttachmentWrapper) => {
        const formData = new FormData();
        formData.append(
          'uploadedFileObject',
          attachmentWrapper?.attachment!,
          attachmentWrapper.name
        );
        formData.append('description', attachmentWrapper.description || '');
        formData.append('documentType', attachmentWrapper.attachmentType.toString());

        elapsedUploadTimes[attachmentWrapper.id] = 0;
        setInterval(function () {
          elapsedUploadTimes[attachmentWrapper.id] += 1;
        }, 1000);

        isAttachmentUploading[attachmentWrapper.id] = true;
        props.indicateAttachmentUploadStatus(isAttachmentUploading.includes(true));

        const cancelTokenSource = axios.CancelToken.source();
        cancelTokenSources[attachmentWrapper.id] = cancelTokenSource;

        ApiClient(authenticationContext, apiClientConfig)
          .postWithAuth(config.documentApi.urls.upload!, formData, {
            cancelToken: cancelTokenSource.token,
            onUploadProgress: (progressEvent: any) => {
              // [EM] Commenting out as this is breaking the build
              // document.dispatchEvent(uploadProgressEvent)
              setUploadProgress((prevUploadProgress) => {
                const uploadProgressCopy = [...prevUploadProgress];
                uploadProgressCopy[attachmentWrapper.id] =
                  (progressEvent.loaded / attachmentWrapper.size) * 100;
                return uploadProgressCopy;
              });
            }
          })
          .then((response: any) => {
            setAttachments((prevAttachments) => {
              const attachmentsCopy = [...prevAttachments];
              attachmentsCopy.map((attachmentWrapperCopy: AttachmentWrapper) => {
                if (attachmentWrapper.id === attachmentWrapperCopy.id) {
                  attachmentWrapperCopy.isUploaded = true;
                  if (!props.isAttachmentDirty) {
                    props.setAttachmentDirtyCallback?.(true);
                  }
                  // Don't actually need to set this back to false; it's just to be semantically tidy
                  isAttachmentUploading[attachmentWrapper.id] = false;
                  props.indicateAttachmentUploadStatus(isAttachmentUploading.includes(true));
                  attachmentWrapperCopy.documentId = response.data.data[0].documentId;
                  setAttachmentDescriptionRefs((prevAttachmentDescriptionRefs) => {
                    const attachmentDescriptionRefsCopy = [...prevAttachmentDescriptionRefs];
                    attachmentDescriptionRefsCopy.push(React.createRef());
                    return attachmentDescriptionRefsCopy;
                  });
                  delete attachmentWrapperCopy.attachment; // don't need this anymore; only need the document ID
                }
                return attachmentWrapperCopy;
              });
              return attachmentsCopy;
            });
          })
          .catch((error: any) => {
            setAttachments((prevAttachments) => {
              const attachmentsCopy = prevAttachments.map((attachment: AttachmentWrapper) => {
                const updatedAttachment = { ...attachment };

                if (attachmentWrapper.id === attachment.id) {
                  updatedAttachment.isError = true;
                  isAttachmentUploading[attachmentWrapper.id] = false;
                  props.indicateAttachmentUploadStatus(isAttachmentUploading.includes(true));
                  if (error.response && error.response.data && error.response.data.error) {
                    const errorMessage = i18n.t(`Errors-${error.response.data.error.errorCode}`);
                    if (errorMessage !== `Errors-${error.response.data.error.errorCode}`) {
                      // Replaces {maxSize} with the size if {maxSize} is present
                      // in the error message; otherwise replace() does nothing
                      updatedAttachment.errorMessage = errorMessage.replace(
                        '{maxSize}',
                        String(parseInt(config.documentApi.maxFileSize!) / 1024 ** 2)
                      );
                    } else {
                      updatedAttachment.errorMessage = props.defaultErrorMessage;
                    }
                  } else {
                    updatedAttachment.errorMessage = props.defaultErrorMessage;
                  }
                }
                return updatedAttachment;
              });

              return attachmentsCopy;
            });
          });
      });
  }, [uploadTrigger]);

  useEffect(() => {
    const isShowMoreVisibleTemp: boolean[] = [];
    attachmentDescriptionRefs.forEach((ref: React.RefObject<HTMLInputElement>, index: number) => {
      // Check if the description has been truncated due to being too long
      isShowMoreVisibleTemp[index] = !(ref && ref!.current)
        ? false
        : ref!.current!.offsetWidth < ref!.current!.scrollWidth;
    });
    setIsShowMoreVisible(isShowMoreVisibleTemp);
  }, [attachmentDescriptionRefs]);

  useEffect(() => {
    if (defaultFormActions.isCardComplete('attachments')) {
      if (attachments.length === 0) {
        defaultFormActions?.removeCompleteCard('attachments');
      }
    } else if (attachments.length !== 0) {
      defaultFormActions?.addCompleteCard('attachments');
    }
  }, [attachments]);

  function handleAttachmentDrop(rawAttachments: File[]) {
    handleAttachmentUpload(rawAttachments);
  }

  function handleAttachmentInput(event: any) {
    if (event.target.files.length < 1) return;
    handleAttachmentUpload(
      Object.keys(event.target.files).map((key: any) => event.target.files[key])
    );
    (document.getElementById(`${props.id}UploadFiles`) as HTMLInputElement).value = '';
  }

  async function downloadAttachment(documentID: string | undefined) {
    if (documentID == null) {
      setAttachmentToastTitle(props.downloadAttachmentErrorTitle);
      setAttachmentToastMsg(props.downloadAttachmentErrorMessage);
      setShowAttachmentToast(true);
    } else if (props.defaultRequestId != null) {
      try {
        const documentResponse = await getDownloadDocumentUrl(props.defaultRequestId, documentID);

        if (documentResponse.status === HttpResponseStatusCodes.OK) {
          const downloadElement = document.createElement('a');
          downloadElement.setAttribute(
            'href',
            documentResponse.data.data.documentEntityJson.documentLink
          );
          downloadElement.click();
          downloadElement.remove();
        }
      } catch (error: any) {
        if (error?.response?.status === HttpResponseStatusCodes.ServerError) {
          setAttachmentToastTitle(props.downloadAttachmentErrorTitle);
          setAttachmentToastMsg(props.downloadAttachmentErrorMessage);
          setShowAttachmentToast(true);
        }
      }
    }
  }

  function deleteAttachment(attachmentId: number) {
    const newAttachments = (attachments as AttachmentWrapper[]).filter(
      (attachmentWrapper: AttachmentWrapper) =>
        attachmentWrapper && attachmentWrapper.id != attachmentId
    );
    setAttachments(newAttachments);
  }

  function handleCancelUpload(attachmentId: number) {
    cancelTokenSources[attachmentId].cancel();
    isAttachmentUploading[attachmentId] = false;
    props.indicateAttachmentUploadStatus(isAttachmentUploading.includes(true));
    deleteAttachment(attachmentId);
  }

  function handleClearUploadError(attachmentId: number) {
    deleteAttachment(attachmentId);
  }

  // Pretty print any file size from 0 B to 999 MB
  function prettyPrintFileSize(size: number | string) {
    // convert to float in case size is a string value
    return prettyBytes(parseFloat(size.toString()), {
      locale: i18n.language
    });
  }

  function showFullDescription(attachmentId: number) {
    setIsFullDescriptionVisible((prevIsFullDescriptionVisible) => {
      const isFullDescriptionVisibleCopy = [...prevIsFullDescriptionVisible];
      isFullDescriptionVisibleCopy[attachmentId] = true;
      return isFullDescriptionVisibleCopy;
    });
  }

  const reasonsCharacterLimitText: string = i18n
    .t('DefaultSubmission-Card-ReasonsCharacterLimitText')
    .replace('{textLimit}', config.documentApi.maxDescriptionLength!);

  const isIncomingAttachment = ({ documentId }: AttachmentWrapper) =>
    props.attachments?.find(({ documentID }) => documentID === documentId) != null;

  return props.downloadMode != null && !isCardVisible ? (
    <React.Fragment>
      <ToastNotification
        type={attachmentToastType}
        isActive={showAttachmentToast}
        title={attachmentToastTitle}
        content={attachmentToastMsg}
        onCloseCallback={() => setShowAttachmentToast(false)}
      />
      <LinedCard
        testId={props.testId}
        id={props.id}
        linePosition={props.linePosition}
        lineColor={props.accordionLineColor}
      >
        <AccordionContainer accordionId={`${props.id}Accordion`} title={props.title!.field}>
          {!props.attachmentExist ? (
            <div className="no-attachments">
              {i18n.t('DefaultsInventoryDetails-Attachment-NoAttachmentMessage')}
            </div>
          ) : (
            <div className="attachment-fetch-error">
              <div className="attachment-fetch-error-icon">
                <i className={`material-icons icon--size-40`}>error_outline</i>
              </div>
              <div>{i18n.t('DefaultsInventoryDetails-Attachment-LoadingErrorMessage')}</div>
            </div>
          )}
        </AccordionContainer>
      </LinedCard>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <ToastNotification
        type={attachmentToastType}
        isActive={showAttachmentToast}
        title={attachmentToastTitle}
        content={attachmentToastMsg}
        onCloseCallback={() => setShowAttachmentToast(false)}
      />
      <LinedCard
        testId={props.testId}
        id={props.id}
        linePosition={props.linePosition}
        lineColor={props.accordionLineColor}
      >
        <AccordionContainer accordionId={`${props.id}Accordion`} title={props.title!.field}>
          {attachments.length > 0 &&
            attachments.some(
              (attachmentWrapper: AttachmentWrapper) =>
                props.downloadMode || (!props.downloadMode && attachmentWrapper.isUploaded)
            ) && (
              <div className="row card__body-row ">
                <div className="col-12">
                  <div className="attachments-table">
                    <table className="table__container">
                      <tbody>
                        <tr className="table__row">
                          <th className="table__header">
                            <Text field={props.attachmentTypeTitle.field} />
                          </th>
                          <th className="table__header">
                            <Text field={props.fileNameTitle.field} />
                          </th>
                          <th className="table__header">
                            <Text field={props.fileSizeTitle.field} />
                          </th>
                          <th className="table__header"></th>
                        </tr>
                      </tbody>
                      {attachments.length > 0 &&
                        attachments.map((attachmentWrapper: AttachmentWrapper, index: number) => {
                          return isIncomingAttachment(attachmentWrapper) ||
                            attachmentWrapper.isUploaded ? (
                            <React.Fragment key={attachmentWrapper.name}>
                              <tbody>
                                <tr className="table__row" key={index * 3}>
                                  <td
                                    className={`table__data table__data--document-type ${
                                      attachmentWrapper.description ? ' table__data--top' : ''
                                    }`}
                                  >
                                    {props.attachmentTypeOptions.filter((attachObj) => {
                                      return attachObj.value == attachmentWrapper.attachmentType;
                                    }).length > 0
                                      ? props.attachmentTypeOptions.filter((attachObj) => {
                                          return (
                                            attachObj.value == attachmentWrapper.attachmentType
                                          );
                                        })[0].label
                                      : null}
                                  </td>
                                  <td
                                    className={`table__data table__data--file-name ${
                                      attachmentWrapper.description ? ' table__data--top' : ''
                                    }`}
                                  >
                                    {attachmentWrapper.name}
                                  </td>
                                  <td
                                    className={`table__data ${
                                      attachmentWrapper.description ? ' table__data--top' : ''
                                    }`}
                                  >
                                    {prettyPrintFileSize(attachmentWrapper.size)}
                                  </td>
                                  <td
                                    className={`table__data ${
                                      attachmentWrapper.description ? ' table__data--top' : ''
                                    }`}
                                    aria-hidden="true"
                                  >
                                    <div className="table__icon-group">
                                      {isIncomingAttachment(attachmentWrapper) ? (
                                        <span
                                          className="table__icon-group-icon btn btn--icon download-attachment-icon"
                                          onClick={(
                                            event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                                          ) => {
                                            event.preventDefault();
                                            downloadAttachment(attachmentWrapper.documentId);
                                            props.onDownloadCallback?.();
                                          }}
                                        >
                                          <Image field={props.downloadIcon} className="icon-24" />
                                        </span>
                                      ) : (
                                        <a
                                          className="table__icon-group-icon btn btn--icon delete-attachment-icon"
                                          href="#"
                                          onClick={(event) => {
                                            event.preventDefault();
                                            deleteAttachment(attachmentWrapper.id);
                                          }}
                                        >
                                          <span>
                                            <TooltipIcon
                                              icon={props.deleteIcon}
                                              text={i18n.t('DefaultSubmission-Card-Delete')}
                                              className={'icon-24'}
                                            />
                                          </span>
                                        </a>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                              {attachmentWrapper.description && (
                                <React.Fragment>
                                  <tbody>
                                    <tr className="table__row" key={index * 3 + 1}>
                                      <td className="table__data table__data--top" colSpan={4}>
                                        <div className="table__attachment-description-header">
                                          {props?.descriptionTitle?.field?.value}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className="table__row" key={index * 3 + 2}>
                                      <td className="table__data" colSpan={4}>
                                        <div className="table__attachment-description-wrapper">
                                          <table className="table__attachment-description-inner-wrapper">
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <p
                                                    className={`table__attachment-description-text${
                                                      isFullDescriptionVisible[attachmentWrapper.id]
                                                        ? ''
                                                        : '-truncated'
                                                    }`}
                                                    ref={
                                                      attachmentDescriptionRefs[
                                                        attachmentWrapper.id
                                                      ]
                                                    }
                                                  >
                                                    {attachmentWrapper.description}
                                                  </p>
                                                  {isShowMoreVisible[attachmentWrapper.id] &&
                                                    !isFullDescriptionVisible[
                                                      attachmentWrapper.id
                                                    ] && (
                                                      <p
                                                        className="table__attachment-description-show-more"
                                                        onClick={() =>
                                                          showFullDescription(attachmentWrapper.id)
                                                        }
                                                      >
                                                        {props?.showMore?.field?.value}
                                                      </p>
                                                    )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          ) : null;
                        })}
                    </table>
                  </div>
                </div>
              </div>
            )}
          <React.Fragment>
            <div className="row card__body-row">
              {attachments.length > 0 &&
                attachments.map((attachmentWrapper: AttachmentWrapper) => {
                  return !isIncomingAttachment(attachmentWrapper) && attachmentWrapper.isError ? (
                    <div className="upload-progress-bar-error">
                      <div className="upload-progress-bar-error__main">
                        <div className="upload-progress-bar-error__icon">
                          <Image field={props.warningIcon} className="icon-24" />
                        </div>
                        <div className="upload-progress-bar-error__file-name">
                          {attachmentWrapper.name}
                        </div>
                        <div className="upload-progress-bar-error__error-message">
                          {props.uploadError} {attachmentWrapper.errorMessage}
                        </div>
                        <div className="upload-progress-bar-error__cancel">
                          <Image
                            field={props.deleteIcon}
                            className="icon-24"
                            onClick={() => handleClearUploadError(attachmentWrapper.id)}
                          />
                        </div>
                      </div>
                      <div className="upload-progress-bar-error__bottom-border"></div>
                    </div>
                  ) : !isIncomingAttachment(attachmentWrapper) &&
                    attachmentWrapper.isUploaded === false ? (
                    <div className="upload-progress-bar">
                      <div className="upload-progress-bar__main">
                        <div className="upload-progress-bar__text">
                          {props.uploading} {attachmentWrapper.name}...
                        </div>
                        <div className="upload-progress-bar__percentage">
                          {Number.isNaN(uploadProgress[attachmentWrapper.id])
                            ? 0
                            : Math.round(uploadProgress[attachmentWrapper.id])}
                          % -{' '}
                          {getRemainingTimeEstimate(
                            uploadProgress[attachmentWrapper.id],
                            elapsedUploadTimes[attachmentWrapper.id]
                          )}
                        </div>
                        <div className="upload-progress-bar__cancel">
                          <Image
                            field={props.cancelIcon}
                            className="icon-24"
                            onClick={() => handleCancelUpload(attachmentWrapper.id)}
                          />
                        </div>
                        <div
                          className="upload-progress-bar__progressFill"
                          style={{
                            width: `${Math.round(uploadProgress[attachmentWrapper.id])}%`
                          }}
                        ></div>
                      </div>
                      <div
                        className="upload-progress-bar__progressBorder"
                        style={{ width: `${Math.round(uploadProgress[attachmentWrapper.id])}%` }}
                      ></div>
                    </div>
                  ) : null;
                })}
            </div>
            <h3 className="card__body-heading">
              <Text
                field={
                  props.downloadMode && (props.numLoadedAttachments > 0 || !props.isInternalSite)
                    ? props.additionalSupportingDocumentsInstructions.field
                    : props.supportingDocumentsInstructions.field
                }
              />
            </h3>
            <div className="row card__body-row card__body-row--no-flex-end">
              <div className="col-6">
                <div className="form__element attachment-form__attachment-type-selector-wrapper">
                  <FormInputDropdownText
                    id="attachmentType"
                    name="attachementType"
                    handleChange={handleAttachmentTypeChange}
                    title={props.attachmentTypeTitle.field}
                    options={props.attachmentTypeOptions}
                    resetTrigger={resetTrigger}
                    cardName={props.title.field.value}
                    value={String(props.attachmentTypeOptions[0].value)}
                    isDisabled={isReadOnlyUser}
                  />
                </div>
                <div className="form__element">
                  <FormInputMultiLineText
                    id={`${props.id}description`}
                    name="attachmentDescription"
                    title={props.descriptionTitle.field}
                    textAreaLimit={{
                      value: reasonsCharacterLimitText
                    }}
                    maxLength={parseInt(config.documentApi.maxDescriptionLength!)}
                    limitCountText={`/ ${config.documentApi.maxDescriptionLength}`}
                    handleChange={handleDescriptionChange}
                    resetTrigger={resetTrigger}
                    cardName={props.title.field.value}
                    isDisabled={isReadOnlyUser}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form__element form__element--attachment">
                  <label
                    htmlFor={`${props.id}DropFiles`}
                    id={`${props.id}DropFilesLabel`}
                    className={'sr-only'}
                  >
                    <Text field={props.dropFilesLabel.field} />
                  </label>
                  <Dropzone onDrop={handleAttachmentDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps({
                          onClick: (e) => {
                            e.stopPropagation();
                          }
                        })}
                        className="form__attachment"
                      >
                        <Image
                          field={props.uploadIcon}
                          className="form__attachment-icon"
                          aria-hidden="true"
                        />
                        <div className="form__attachment-text">
                          <label
                            htmlFor={`${props.id}UploadFiles`}
                            id={`${props.id}UploadFilesLabel`}
                            className="input-upload-label"
                          >
                            <Text field={props.uploadInstructions1.field} />
                            <span className="sr-only">
                              <Text field={props.uploadFilesLabel.field} />
                            </span>
                            <input
                              {...getInputProps()}
                              type="file"
                              id={`${props.id}UploadFiles`}
                              aria-labelledby={`${props.id}UploadFilesLabel`}
                              name="filename"
                              className="form__attachment-manual-upload"
                              onChange={handleAttachmentInput}
                              multiple
                              disabled={isReadOnlyUser}
                            />
                            <span className="upload-span">
                              {props?.uploadInstructionsButtonLabel?.field?.value}
                            </span>
                            <Text field={props.uploadInstructions2.field} />
                          </label>
                        </div>
                        <div className="form__attachment-sub-text">
                          <Text field={props.supportedFormats.field} />
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>
          </React.Fragment>
          {attachments.length > 0 &&
            attachments.map((attachmentWrapper: AttachmentWrapper, index: number) => {
              return attachmentWrapper.isUploaded === false ? null : (
                <div key={`attachment[${index}]`}>
                  <input
                    type="hidden"
                    name={`attachment[${index}].metadata.documentType`}
                    value={attachmentWrapper.attachmentType}
                    {...(props.register &&
                      props.register(`attachment[${index}].metadata.documentType`))}
                  />
                  <input
                    type="hidden"
                    name={`attachment[${index}].metadata.description`}
                    value={attachmentWrapper.description}
                    {...(props.register &&
                      props.register(`attachment[${index}].metadata.description`))}
                  />
                  <input
                    type="hidden"
                    name={`attachment[${index}].documentID`}
                    value={attachmentWrapper.documentId}
                    {...(props.register && props.register(`attachment[${index}].documentID`))}
                  />
                  <input
                    type="hidden"
                    name={`attachment[${index}].metadata.fileSize`}
                    value={attachmentWrapper.size}
                    {...(props.register &&
                      props.register(`attachment[${index}].metadata.fileSize`))}
                  />
                  <input
                    type="hidden"
                    name={`attachment[${index}].metadata.fileName`}
                    value={attachmentWrapper.name}
                    {...(props.register &&
                      props.register(`attachment[${index}].metadata.fileName`))}
                  />
                  <input
                    type="hidden"
                    name={`attachment[${index}].metadata.uploadStatusCode`}
                    value={3}
                    {...(props.register &&
                      props.register(`attachment[${index}].metadata.uploadStatusCode`))}
                  />
                </div>
              );
            })}
        </AccordionContainer>
      </LinedCard>
    </React.Fragment>
  );
};

export default withTranslation()(AttachmentForm);
