import * as React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Left4Right8Props from './types';
import styles from './styles.module.scss';

const Left4Right8: React.FC<Left4Right8Props> = ({ rendering }: Left4Right8Props) => (
  <div className={styles.layoutContainer}>
    <div>
      <Placeholder name="hbt-left-4" rendering={rendering} />
    </div>
    <div>
      <Placeholder name="hbt-right-8" rendering={rendering} />
    </div>
  </div>
);

export default Left4Right8;
