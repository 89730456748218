import { useFormContext } from 'react-hook-form';

import { CashReceiptRow } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/CashReceipts/types';
import { groupBy } from 'Components/Common/Api/utils/GroupBy';

export const useCashReceiptsFunctions = () => {
  const { watch } = useFormContext();

  const formData = watch('cashReceipts');

  const parseCashReceiptData = (claimData?: {
    [label: string]: object;
  }): { rows: CashReceiptRow[]; columnTotals: CashReceiptRow } | null => {
    if (claimData?.cashReceipts !== undefined) {
      const indexedCashReceipts: CashReceiptRow[] = (
        claimData.cashReceipts as CashReceiptRow[]
      )?.map((row, idx) => ({
        ...row,
        idx,
        admissibleAmount: formData[idx]?.admissibleAmount,
        differenceAmount:
          row.receivedAmount != null && row.admissibleAmount != null
            ? formData[idx]?.admissibleAmount -
              Math.max(row.receivedAmount, row.previouslyApprovedAmount ?? 0)
            : undefined
      }));

      // Group by typeCode
      const groupedMap: Map<number, Array<CashReceiptRow>> = groupBy<number, CashReceiptRow>(
        indexedCashReceipts ?? [],
        (item) => item.typeCode ?? 0
      );

      // Calculate column Totals
      const columnTotals: CashReceiptRow = {
        admissibleAmount:
          indexedCashReceipts.length > 0
            ? indexedCashReceipts
                ?.map((row) => row?.admissibleAmount)
                .reduce((acc = 0, val) => acc + (val ?? 0))
            : 0,
        previouslyApprovedAmount:
          indexedCashReceipts.length > 0
            ? indexedCashReceipts
                ?.map((row) => row.previouslyApprovedAmount)
                .reduce((acc = 0, val) => acc + (val ?? 0))
            : 0,
        receivedAmount:
          indexedCashReceipts.length > 0
            ? indexedCashReceipts
                ?.map((row) => row.receivedAmount)
                .reduce((acc = 0, val) => acc + (val ?? 0))
            : 0,
        differenceAmount:
          indexedCashReceipts.length > 0
            ? indexedCashReceipts
                ?.map((row) => row.differenceAmount)
                .reduce((acc = 0, val) => acc + (val ?? 0))
            : 0
      };

      // Flatten rows for cash receipts
      const rows: CashReceiptRow[] = [];
      groupedMap.forEach((value) => {
        rows.push(...value);
      });

      // return object
      return { rows, columnTotals };
    }

    return null;
  };

  return { parseCashReceiptData };
};
