import React, { useCallback } from 'react';

type KeyboardEvent = React.KeyboardEvent<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement>;

const useEnterOrSpaceKey = (callback: (e?: KeyboardEvent) => void) => {
  const ENTER_KEY = 'Enter';
  const SPACE_KEY = ' ';
  return useCallback(
    (e: KeyboardEvent) => {
      if (e.key === ENTER_KEY || e.key === SPACE_KEY) {
        callback(e);
        e.preventDefault();
      }
    },
    [callback]
  );
};

export default useEnterOrSpaceKey;
