import * as React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { CardType } from 'Feature/CommonComponents/Enums';
import { Card } from 'Feature/CommonComponents/ContentComponents/Card/Card';
import { Button } from 'Feature/CommonComponents/UserControls';
import { NotificationCardProps } from './NotificationCard.types';

export const NotificationCard: React.FC<NotificationCardProps> = (props: NotificationCardProps) => {
  let iconClass = 'notification__icon';
  let iconText = '';

  switch (props.notificationType) {
    case CardType.VERTICAL_SUCCESS:
      iconClass += ' notification__icon--success';
      iconText = 'check_circle_outline';
      break;
    case CardType.VERTICAL_WARNING:
      iconClass += ' notification__icon--warning';
      iconText = 'warning_outline';
      break;
    case CardType.VERTICAL_ERROR:
      iconClass += ' notification__icon--error';
      iconText = 'error_outline';
      break;
    default:
      iconText = 'info';
  }

  return (
    <Card type={props.notificationType || CardType.VERTICAL_BLUE}>
      <div className="notification" tabIndex={0} id={props.id}>
        <div className={iconClass}>
          <Image className="notification-icon" field={props.formWarningIcon} />
        </div>

        <div className="notification__body notification__body--has-icon">
          <h2 className="notification__title">{props.title}</h2>
          <ul>
            {props.message &&
              props.message.map((msg, index) => (
                <React.Fragment>
                  {msg && (
                    <li className="notification__text" key={index}>
                      {msg}
                    </li>
                  )}
                </React.Fragment>
              ))}
          </ul>
        </div>

        <div className="notification__body__actionButton">
          {props.actionButton && (
            <Button
              id="buttonNotificationAction"
              buttonType={props.actionButton.buttonType}
              name={props.actionButton.name}
              onClick={props.actionButton.onClick}
              isIcon={props.actionButton.isIcon}
              displayText={props.actionButton.displayText}
              icon={props.actionButton.icon}
              isDropDown={props.actionButton.isDropDown}
              iconSize={props.actionButton.iconSize}
              disabled={props.actionButton.disabled}
              otherProps={props.actionButton.otherProps}
            />
          )}
        </div>
      </div>
    </Card>
  );
};
