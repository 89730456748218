import React, { FC, memo, useEffect, useRef } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import { LoaderAnimationProps } from './types';

export const LoaderAnimation: FC<LoaderAnimationProps> = memo(
  ({ fields }: LoaderAnimationProps) => {
    const loaderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (loaderRef.current) {
        loaderRef.current.focus();
        loaderRef.current.style.outline = 'none';
      }
    }, []);

    return (
      <div
        role="status"
        aria-live="polite"
        aria-atomic="true"
        aria-busy="true"
        tabIndex={-1}
        ref={loaderRef}
      >
        <div className="spinning-loader--wrapper">
          <div className="spinning-loader" aria-hidden="true" />
        </div>
        <div className="loading-title">
          <Text
            field={{
              value:
                fields && fields.loaderTitle
                  ? fields.loaderTitle.value
                  : i18n.t('DefaultSubmission-Loading')
            }}
          />
        </div>
        <div className="loading-description">
          <Text
            field={{
              value:
                fields && fields.loaderDescription
                  ? fields.loaderDescription.value
                  : i18n.t('DefaultSubmission-Loading-Details')
            }}
          />
        </div>
      </div>
    );
  }
);
