import React from 'react';

export interface LargeFormFieldProps {
  id: string;
  name?: string;
  label?: string;
  ariaText: string;
  helperText?: string;
  placeholder?: string;
  className?: string;
  value?: string;
  rows?: number;
  tabIndex?: number;
  maxLength?: number;
  initialValue?: string;
  status?: LargeFormFieldStatus;
  isReadOnly?: boolean | undefined;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export enum LargeFormFieldStatus {
  ERROR = 'error'
}
