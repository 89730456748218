import React from 'react';
import i18n from 'i18next';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { UploadStatusProps } from '../types';

export const FileUploadSuccess: React.FC<UploadStatusProps> = ({
  fields,
  attachment,
  uploadProgress,
  deleteAttachment,
  handleCancelUpload
}: UploadStatusProps) => (
  <div className="upload-progress-bar">
    <div className="upload-progress-bar__main">
      <div className="upload-progress-bar__text">
        {attachment.isUploaded
          ? attachment.fileName
          : `${i18n.t('AttachmensView-FileUpload-UploadingText')} ${attachment.fileName}...`}
      </div>
      <div className="upload-progress-bar__percentage">
        {i18n.t(
          `AttachmentsView-FileUpload-${
            attachment.isUploaded ? 'UploadComplete' : 'HundredPercent'
          }`
        )}
      </div>
      <button
        type="button"
        className="upload-progress-bar__cancel"
        aria-label={
          (fields[attachment?.isUploaded ? 'deleteIcon' : 'cancelIcon'].field?.value as string) ??
          ''
        }
        onClick={() =>
          attachment.isUploaded
            ? deleteAttachment(attachment.id)
            : handleCancelUpload && handleCancelUpload(attachment.id)
        }
      >
        <Image
          field={fields[attachment.isUploaded ? 'deleteIcon' : 'cancelIcon']}
          className="icon-24"
        />
      </button>
      <div
        className={'style100 upload-progress-bar__progressFill'}
        style={{ width: `${Math.round(uploadProgress ? 100 : 1)}%` }}
      ></div>
    </div>
    <div
      className={`style100
        upload-progress-bar__${attachment.isUploaded ? 'complete' : 'progress'}Border
      `}
    ></div>
  </div>
);
