import { FC } from 'react';

const ABRender: FC<{ condition: boolean; thenElement: JSX.Element; elseElement: JSX.Element }> = ({
  condition,
  thenElement,
  elseElement
}) => {
  if (condition) {
    return thenElement;
  }
  return elseElement;
};

export default ABRender;
