import { useState, useEffect } from 'react';
import { ModuleKeyword, UserRole, ModuleMapping } from '@hobt/constants';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import getPathInObject from 'Constants/Utils/GetPathInObject';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { useFormContext } from 'react-hook-form';

interface ModuleActiveState {
  type: ModuleKeyword;
  activeFlag: boolean;
}

export const InternalUserLevelAccessFunctions = (
  editableModules: ModuleKeyword[],
  isModify: boolean | undefined,
  userData: any
) => {
  const { setValue, register } = useFormContext();

  // TODO: Manually tracking active flags since the FormToggle component doesn't register
  const [moduleActiveStates, setModuleActiveStates] = useState<ModuleActiveState[]>([]);

  // const [showProbationaryInput, setShowProbationaryInput] = useState<boolean>(false);

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;

  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.admin,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  const [showToast, setToast] = useState<boolean>(false);
  const [toastState, setToastState] = useState<ApplicationStates>(ApplicationStates.DEFAULT);

  useEffect(() => {
    if (!isModify) {
      // Default all to false when adding a new user
      setModuleActiveStates(editableModules.map((x) => ({ type: x, activeFlag: false })));

      // TODO: Manually registering active flags since the FormToggle component doesn't register
      editableModules.forEach((x) => {
        register(`userAccesses[0].access.${x}.activeFlag`);
        setValue(`userAccesses[0].access.${x}.activeFlag`, false);
      });

      // TODO: Admin access required for now by schema.  Remove once schema/backend is updated.
      register(`userAccesses[0].access.${ModuleKeyword.Admin}.activeFlag`);
      setValue(`userAccesses[0].access.${ModuleKeyword.Admin}.activeFlag`, false);
    }
  }, []);

  useEffect(() => {
    if (userData != null) {
      const existingActiveModules: any = [];

      editableModules.forEach((x) => {
        const userAccess = getPathInObject(userData.userAccesses[0].access, x);
        existingActiveModules.push({ type: x, activeFlag: userAccess.activeFlag });
      });

      setModuleActiveStates(existingActiveModules);
    }
  }, userData);

  const hasOtherActiveModules = (currentAccess: ModuleActiveState) => {
    const isOneModuleActive = moduleActiveStates.some(
      (activeState) =>
        activeState.type !== currentAccess.type &&
        activeState.type !== ModuleKeyword.Admin &&
        activeState.activeFlag === true
    );
    const adminStatus = moduleActiveStates.find((state) => state.type === ModuleKeyword.Admin);
    return adminStatus?.type !== currentAccess.type
      ? (isOneModuleActive && adminStatus?.activeFlag) || !adminStatus?.activeFlag
      : !(!isOneModuleActive && !currentAccess.activeFlag);
  };

  const toggleModuleActive = (accessType: ModuleKeyword) => {
    const currentAccess = moduleActiveStates?.find((x) => x.type === accessType);

    if (currentAccess !== undefined) {
      const newAccess = !currentAccess.activeFlag; // toggle
      if (!hasOtherActiveModules(currentAccess)) {
        setToastState(ApplicationStates.ERROR);
        setToast(true);
        return;
      }
      setToast(false);

      setValue(`userAccesses[0].access.${accessType}`, { activeFlag: newAccess });

      setModuleActiveStates(
        moduleActiveStates.map((x) => (x.type === accessType ? { ...x, activeFlag: newAccess } : x))
      );
    }
  };

  return {
    isReadOnlyUser,
    moduleActiveStates,
    toggleModuleActive,
    showToast,
    setToast,
    toastState
  };
};
