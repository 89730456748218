import React from 'react';
import { LinedCard, AccordionContainer } from '../../../CommonComponents/AccordionComponents';
import { HomeownerLoanCheckProps } from './HomeownerLoanCheck.types';
import { FormInputRadioType } from '../../models/typeCode.types';
import FormInputRadio from '../FormInputRadio';

const HomeownerLoanCheck: React.FC<HomeownerLoanCheckProps> = (props) => {
  const handleInputChange = (event: any) => {
    const { value } = event.target;
    props.setIsHomeOwnerLoan(value !== 'false');
  };

  return (
    <LinedCard
      id={props.id}
      testId={props.testId}
      linePosition="vertical"
      lineColor={props.accordionLineColor}
    >
      <AccordionContainer accordionId={`${props.id}Accordion`} title={props.title.field}>
        <div className="row card__body-row">
          <div className="col-12">
            <FormInputRadio
              id={props.testId}
              name="homeownerLoan"
              title={props.inputFields.homeownerloanQ.field}
              options={[
                { label: props.inputFields.radioButtonYes.field, value: FormInputRadioType.Yes },
                { label: props.inputFields.radioButtonNo.field, value: FormInputRadioType.No }
              ]}
              inputDescription={props.inputFields.smallDescription.field}
              handleChange={(e: any) => handleInputChange(e)}
              isRequired={true}
            />
          </div>
        </div>
      </AccordionContainer>
    </LinedCard>
  );
};

export default HomeownerLoanCheck;
