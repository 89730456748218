import InputField from 'cleave.js/react';
import React, { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import FormInputWrapper from 'Components/Inputs/FormInputWrapper';
import getPathInObject from 'Constants/Utils/GetPathInObject';

import { Props, FormattedProps, TUseControllerProps } from './types';
import styles from './styles.module.scss';

function Update(name: string, setValue: Function, onChange: Function, handleChange?: Function) {
  return function _Update(e: any) {
    const { rawValue } = e.target;

    setValue(name, rawValue, { shouldDirty: true });
    onChange(rawValue);
    handleChange?.(e);
  };
}

/*
  Separate components are needed for regular inputs vs formatted inputs since Cleave.js
  library does not support spaces in input by defaults and only supports spaces when the input
  format type is specified. Therefore, for unformatted input fields, a separate component exists
  to allow spaces.
*/

const FormText: FC<Props> = ({
  name,
  label,
  className,
  isReadOnly,
  value,
  watch,
  reRegisterOnRender,
  maxLength,
  handleChange,
  autocomplete = false,
  dontRegister = false,
  tabIndex
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();
  const message = isReadOnly === true ? '' : getPathInObject(errors, `${name}.message`);

  return (
    <FormInputWrapper
      name={name}
      label={label}
      className={className}
      watch={watch}
      {...(isReadOnly === true && { disabled: isReadOnly })}
    >
      <input
        id={name}
        onChange={handleChange}
        {...(dontRegister === false && register(name || ' ', { onChange: handleChange }))}
        type="text"
        maxLength={maxLength}
        className={styles['form-text']}
        aria-label={name}
        aria-errormessage={`${name}_error_message`}
        aria-invalid={Boolean(message)}
        value={value}
        {...(isReadOnly === true && { readOnly: isReadOnly })}
        autoComplete={autocomplete === true ? 'on' : 'off'}
        tabIndex={tabIndex}
      />
    </FormInputWrapper>
  );
};

const Formatted: FC<FormattedProps> = ({
  name,
  label,
  className,
  isReadOnly,
  value,
  formatProps,
  maxLength,
  watch,
  handleChange,
  autocomplete = false,
  dontRegister = false
}) => {
  const {
    formState: { errors },
    getValues,
    setValue,
    register
  } = useFormContext();
  const message = isReadOnly === true ? '' : getPathInObject(errors, `${name}.message`);

  const nameValue: string = getValues(name);

  const {
    field: { onChange, ref: controllerRef, ...field }
  }: TUseControllerProps = useController({
    name,
    defaultValue: nameValue
  });
  const update = Update(name, setValue, onChange, handleChange);

  return (
    <FormInputWrapper
      name={name}
      label={label}
      className={className}
      watch={watch}
      {...(isReadOnly === true && { disabled: isReadOnly })}
    >
      <InputField
        id={name}
        {...field}
        options={{ ...formatProps }}
        {...(dontRegister === true && { value })}
        {...(dontRegister === false && register(name || ' '))}
        type="text"
        maxLength={maxLength}
        onChange={dontRegister === true ? handleChange : update}
        className={styles['form-text']}
        aria-errormessage={`${name}_error_message`}
        aria-invalid={Boolean(message)}
        autoComplete={autocomplete === true ? 'on' : 'off'}
        {...(isReadOnly === true && { readOnly: isReadOnly })}
      />
    </FormInputWrapper>
  );
};

export default FormText;
export { Formatted };
