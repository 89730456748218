import React, { useState, useEffect } from 'react';

import { OtherAdjustment } from '@hobt/claim-domain';
import { OtherAdjustmentCode } from '@hobt/constants';

import { BuildDropDownDictionary } from 'Components/Common/Helpers';
import { OtherAdjustmentsPropsFields } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/OtherAdjustments/types';
import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';

const PREVIOUS_ADJUSTMENT_DATES_OTHER_ADJUSTMENT_CODES = [
  OtherAdjustmentCode.ProcessTime,
  OtherAdjustmentCode.DEFSLOPT1DT,
  OtherAdjustmentCode.DEFSLOPT2DT
];

const PREVIOUS_ADJUSTMENT_AMOUNT_OTHER_ADJUSTMENT_CODES = [
  OtherAdjustmentCode.OtherAdjustment,
  OtherAdjustmentCode.OngoingResponsibilities,
  OtherAdjustmentCode.FireOtherInsurablePerilDamage,
  OtherAdjustmentCode.DelayInSale,
  OtherAdjustmentCode.OngoingLoansAdminRentAdjustment,
  OtherAdjustmentCode.OtherAdjustment2
];

export const useOtherAdjustmentFunctions = (
  otherAdjustmentFields?: OtherAdjustmentsPropsFields
) => {
  const { toLocaleDate } = useTranslationHelpers();
  const [codeRowDictionary, setCodeRowDictionary] = useState<Map<string, string>>(new Map());

  useEffect(() => {
    if (otherAdjustmentFields?.codeRows.fields.listItems != null) {
      setCodeRowDictionary(
        BuildDropDownDictionary(otherAdjustmentFields?.codeRows.fields.listItems)
      );
    }
  }, [otherAdjustmentFields]);

  const controllerEmptyObject = () => <></>;

  // TODO: identify where the bool to string conversion is taking place and mitigate. That will remove the need of this fn
  const parseBoolean = (bool: boolean | string) => {
    if (typeof bool === 'boolean') {
      return bool;
    }

    return bool === 'true';
  };

  const displayPreviousValues = (code: string, otherAdjustment: OtherAdjustment) => {
    if (PREVIOUS_ADJUSTMENT_DATES_OTHER_ADJUSTMENT_CODES.includes(code as OtherAdjustmentCode)) {
      return toLocaleDate(otherAdjustment?.previousAdjustmentDate) ?? '';
    }
    if (PREVIOUS_ADJUSTMENT_AMOUNT_OTHER_ADJUSTMENT_CODES.includes(code as OtherAdjustmentCode)) {
      return `$${otherAdjustment?.previousAdjustmentAmount ?? ''}`;
    }

    return otherAdjustment?.previousAdjustmentDate != null
      ? `${otherAdjustment?.previousAdjustmentDate} Days`
      : '';
  };

  return {
    codeRowDictionary,
    controllerEmptyObject,
    displayPreviousValues,
    parseBoolean
  };
};
