import i18n from 'i18next';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AccountStatus, ClaimInventoryViewType } from '@hobt/constants';
import { UserAndAccesses } from '@hobt/user-domain';
import { ModifyUserActionType, ModifyUserAction } from './types';
import { ApiClient } from 'Foundation/Api';
import { pathNames } from 'Constants/pathNames';
import { config } from '../../../../config';
import { UserAccessRecord } from 'Feature/UserManagement/models/UserAccessProps';

export const useExternalUserApiFunctions = (
  dispatch: React.Dispatch<ModifyUserAction>,
  push: Function,
  authenticationContext: any,
  userId?: string
) => {
  const [userData, setUserData] = useState<UserAndAccesses | null>(null);
  let [formPayload, setFormPayload] = useState<any>();

  const cancelTokenSource = axios.CancelToken.source();

  const { getWithAuth, putWithAuth } = ApiClient(authenticationContext, {
    timeout: config.userApi.requestTimeout
  });

  const getUserUrl: string = config.userApi.urls.getUser;

  const onError = async (err?: any): Promise<void> => {
    console.log('formerr-submiterr', err);
    console.log('userData', userData);
    dispatch({
      action: ModifyUserActionType.ON_ERROR,
      payload: {
        function: () => {
          dispatch({ action: ModifyUserActionType.DISMISS_TOAST });
        }
      }
    });
  };

  const getUserData = async (): Promise<void> => {
    try {
      const { data: responseData } = await getWithAuth(`${getUserUrl}?id=${userId}`, {
        cancelToken: cancelTokenSource.token
      });
      setUserData(responseData?.data?.[0]);
    } catch (e) {
      onError(e);
    }
  };

  const userUpdateApi = async () => {
    try {
      dispatch({
        action: ModifyUserActionType.SET_IS_LOADING,
        payload: { value: true }
      });
      const payloadData = { ...formPayload };

      const { data: responseData } = await putWithAuth(
        `${config.userApi.urls.modify}`,
        payloadData,
        {
          cancelToken: cancelTokenSource.token
        }
      );
      setUserData(responseData?.data?.[0]);
      dispatch({
        action: ModifyUserActionType.SUBMIT_SUCCESS,
        payload: {
          function: () => {
            dispatch({ action: ModifyUserActionType.DISMISS_TOAST });
            push(`/${i18n.language}${pathNames.userInventory}`, 'external');
          }
        }
      });
    } catch (e) {
      onError(e);
    }
  };

  const onSubmit = async (formData: any): Promise<void> => {
    const { userAccesses, ...contactInfo } = formData;
    let userAccessPayload: UserAccessRecord[] = [];
    userAccesses?.forEach((element: UserAccessRecord) => {
      // Create a new object without the userAccessID property
      const { userAccessID, ...elementWithoutID } = element;
      // Update other properties as needed
      elementWithoutID.access = {
        ...elementWithoutID.access,
        admin: { activeFlag: false }
      };
      elementWithoutID.lenderRoleCode === 101
        ? (elementWithoutID.access.admin.activeFlag = true)
        : (elementWithoutID.access.admin.activeFlag = false);

      userAccessPayload.push(elementWithoutID);
    });
    formPayload = {
      users: [{ ...contactInfo, userAccesses: userAccessPayload }]
    };
    setFormPayload({
      users: [{ ...contactInfo, userAccesses: userAccessPayload }]
    });
    userUpdateApi();
  };

  const onUserDelete = async () => {
    const payload = {
      bulk: true,
      statusCode: AccountStatus.DISABLED.toString(10),
      emailIDs: [userData?.emailID]
    };

    try {
      dispatch({
        action: ModifyUserActionType.SET_IS_MODAL_LOADING,
        payload: { value: true }
      });
      await putWithAuth(`${config.userApi.urls.delete}`, payload, {
        cancelToken: cancelTokenSource.token
      });
      dispatch({
        action: ModifyUserActionType.SET_IS_MODAL_LOADING,
        payload: { value: false }
      });
      dispatch({
        action: ModifyUserActionType.SUBMIT_SUCCESS,
        payload: {
          function: () => {
            dispatch({ action: ModifyUserActionType.DISMISS_TOAST });
            push(`/${i18n.language}${pathNames.userInventory}`);
          }
        }
      });
    } catch (e) {
      onError(e);
    }
  };

  useEffect(() => {
    if (userId != null) {
      dispatch({ action: ModifyUserActionType.SET_IS_PAGE_LOADING, payload: { value: true } });
      getUserData().then((_res) => {
        dispatch({ action: ModifyUserActionType.SET_IS_PAGE_LOADING, payload: { value: false } });
      });
    }
    if (userId == null) {
      onError();
    }

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  return {
    userData,
    onSubmit,
    onUserDelete,
    onError,
    userUpdateApi
  };
};
