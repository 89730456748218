import React, { useEffect, MouseEvent } from 'react';
import { ModuleKeyword, UserRole } from '@hobt/constants';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import FormToggle from 'Components/Inputs/FormToggle';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import i18n from 'i18next';
import { useFormContext } from 'react-hook-form';
import { InternalUserLevelAccessFunctions } from './InternalUserLevelAccessFunctions';
import styles from './styles.module.scss';
import HbtInternalPIUserLevelAccessProps from './types';

const HbtInternalPIUserLevelAccess: React.FC<HbtInternalPIUserLevelAccessProps> = ({
  fields,
  isModify,
  userData
}: HbtInternalPIUserLevelAccessProps) => {
  const editableModules: ModuleKeyword[] = [
    ModuleKeyword.PiRequest,
    ModuleKeyword.PiPurposeTest,
    ModuleKeyword.PiFileShare,
    ModuleKeyword.Admin
  ];
  const { setValue, register, unregister } = useFormContext();
  const moduleLabelMap: Map<ModuleKeyword, string> = new Map([
    [ModuleKeyword.PiRequest, fields.piRequestToolAccessLabel?.value ?? ''],
    [ModuleKeyword.PiPurposeTest, fields.piPurposeTestToolAccessLabel?.value ?? ''],
    [ModuleKeyword.PiFileShare, fields.piFileTransferToolAccessLabel?.value ?? ''],
    [ModuleKeyword.Admin, fields.userIsAdmin?.value ?? '']
  ]);

  const { moduleActiveStates, toggleModuleActive, showToast, setToast, toastState } =
    InternalUserLevelAccessFunctions(
      editableModules,
      isModify,
      (userData !== undefined && userData) || null
    );

  const activeState = moduleActiveStates?.find((x) => x.type === 'admin')?.activeFlag as boolean;

  useEffect(() => {
    if (activeState) {
      register(`userAccesses[0].access.${ModuleKeyword.Admin}.roleCode`);
      setValue(
        `userAccesses[0].access.${ModuleKeyword.Admin}.roleCode`,
        UserRole.CmhcAdministrator.toString()
      );
    } else {
      unregister(`userAccesses[0].access.${ModuleKeyword.Admin}.roleCode`);
    }
  }, [activeState]);

  useEffect(() => {
    moduleActiveStates.map((state) => {
      if (state.type !== 'admin' && state.activeFlag) {
        register(`userAccesses[0].access.${state.type}.roleCode`);
        setValue(
          `userAccesses[0].access.${state.type}.roleCode`,
          UserRole.PortfolioAnalyst.toString()
        );
      } else if (state.type !== 'admin') {
        unregister(`userAccesses[0].access.${state.type}.roleCode`);
      }
    });
  }, [moduleActiveStates]);

  const onToastCloseHandler = (e?: MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
      setToast(false);
    }
  };

  return (
    <>
      <ToastNotification
        type={toastState}
        isActive={showToast}
        title={i18n.t('Globals-Toast-Error-Title')}
        content={i18n.t('PI-UAM-Admin-Access-Toggle-Error-Message')}
        onCloseCallback={(e?: MouseEvent<HTMLButtonElement>) => onToastCloseHandler(e)}
      />
      <div>
        <h2 className="card-title">
          <Text field={fields.sectionTitle} />
        </h2>
        <p>
          <Text field={fields.sectionSubtitle} />
        </p>
        {editableModules.map((module) => {
          const activeState = moduleActiveStates?.find((x) => x.type === module)?.activeFlag;

          if (activeState !== undefined) {
            return (
              <div key={module.toString()}>
                <div className="row card__body-row">
                  <div className="form__element form__element--2-column col-12">
                    <div>
                      <FormToggle
                        className={styles.internalPIUserAccessToggle}
                        id={`${module}FormToggle`}
                        testId={`${module}FormToggle`}
                        staticText={moduleLabelMap.get(module)}
                        isChecked={activeState}
                        onChange={() => toggleModuleActive(module)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </>
  );
};

export default HbtInternalPIUserLevelAccess;
