import React, { FC, KeyboardEvent, memo, MouseEvent, useCallback, useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import FocusLock from 'react-focus-lock';
import CSSTransition from 'react-transition-group/CSSTransition';
import { Button } from '../Button';
import { ButtonSize, ButtonVariant } from '../Button/types';
import { HbtIcon } from '../HbtIcon';
import { HbtIconSize } from '../HbtIcon/types';
import { ModalActionButtons } from './ModalActionButtons';
import styles from './styles.module.scss';
import { ModalProps } from './types';

/**
 *
 * @param {ModalProps} props - list out the props.
 * @param {string} props.id - id to uniquely identify the modal component.
 * @param {boolean} props.isActive - to determine if modal is visible.
 * @param {boolean} props.isDismissalRequired - to determine if dismissable button is required in modal header.
 * @param {boolean} props.shouldCloseOnBackgroundClick - to determine dismissal of modal on click outside the modal.
 * @param {FieldValue} props.title - the title text to be displayed in header part of a modal.
 * @param {React.ReactNode} props.bodyContent - the content that sits within the modal card body.
 * @param {ModalButtons} props.modalButtons - the key CTA and teritary buttons to be displayed at bottom of modal card.
 * @param {React.JSX.Element} props.leadingIcon - the leading icon that should be rendered in the component.
 * @param {function} props.onDismissalCallback - the click event handler to dismiss the modal.
 * @returns {Modal} Rendered Modal component.
 */

/**
 * Modal component
 *
 * This is the Modal functional component written using storybook. It handles different modal variants.
 */

export const Modal: FC<ModalProps> = memo(
  ({
    id,
    isActive,
    isDismissalRequired = true,
    isFocusTrapPaused = false,
    title,
    leadingIcon,
    onDismissalCallback,
    shouldCloseOnBackgroundClick,
    bodyContent,
    modalButtons
  }) => {
    const { primaryButton, secondaryButton, tertiaryButton } = modalButtons ?? {};

    const handleBackgroundClick = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
        if (shouldCloseOnBackgroundClick) onDismissalCallback(e);
      },
      [shouldCloseOnBackgroundClick, onDismissalCallback]
    );

    const handleKeyDown = useCallback(
      (e: KeyboardEvent<HTMLDivElement>) => {
        if (shouldCloseOnBackgroundClick && e.key === 'Escape') onDismissalCallback(e);
      },
      [shouldCloseOnBackgroundClick, onDismissalCallback]
    );

    useEffect(() => {
      if (isActive && isDismissalRequired) {
        const closeButton = document.getElementById('modal-close-btn') as HTMLButtonElement;
        if (closeButton) closeButton.focus();
      }
    }, [isActive, isDismissalRequired]);

    return (
      <CSSTransition
        in={isActive}
        classNames="modal"
        timeout={100}
        unmountOnExit={true}
        mountOnEnter={true}
      >
        <FocusLock className="core-modal modal" noFocusGuards disabled={isFocusTrapPaused}>
          <div
            className="modal__bg"
            data-testid="modal-bg"
            aria-label="Modal content"
            role="button"
            tabIndex={0}
            onClick={handleBackgroundClick}
            onKeyDown={handleKeyDown}
          />
          <div
            className={`modal__card ${styles.modalCard}`}
            id={`${id}-modal-card`}
            data-testid={`${id}-modal-card`}
            role="alertdialog"
            aria-labelledby={`${id}-modal-title`}
            aria-describedby={`${id}-modal-body`}
          >
            <div className={styles.modalHeaderContainer}>
              <div
                className={styles.modalCardHeader}
                id={`${id}-modal-header`}
                data-testid={`${id}-modal-header`}
              >
                {leadingIcon && (
                  <span aria-hidden={true} className={styles.withLeadingIcon}>
                    {leadingIcon()}
                  </span>
                )}
                <h2
                  className={styles.modalTitle}
                  id={`${id}-modal-title`}
                  data-testid={`${id}-modal-title`}
                >
                  <Text field={title} />
                </h2>
                {isDismissalRequired && (
                  <Button
                    tabIndex={0}
                    id="modal-close-btn"
                    className={styles.modalCloseBtn}
                    variant={ButtonVariant.ICON}
                    size={ButtonSize.LARGE}
                    icon={() => <HbtIcon size={HbtIconSize.LARGE} type="icon_close" />}
                    ariaText={{ value: i18n.t('DefaultCloseModal') ?? 'Close' }}
                    name={i18n.t('Accessibility-Close-Button')}
                    text={{ value: i18n.t('Accessibility-Close-Button') }}
                    onClick={(e: any) => onDismissalCallback(e)}
                  />
                )}
              </div>
              <hr id={`${id}-modal-header-divider`} className={styles.cardDivider} />
            </div>

            {bodyContent && (
              <div
                className={styles.modalBody}
                id={`${id}-modal-body`}
                data-testid={`${id}-modal-body`}
              >
                {bodyContent}
              </div>
            )}
            {modalButtons && (
              <div
                className={styles.modalActionButtons}
                id={`${id}-modal-action-btns`}
                data-testid={`${id}-modal-action-btns`}
              >
                {tertiaryButton && (
                  <div
                    className={styles.modalTertiaryBtn}
                    id={`${id}-action-tertiaryBtn`}
                    data-testid={`${id}-action-tertiaryBtn`}
                  >
                    <ModalActionButtons
                      modalActionButton={tertiaryButton}
                      buttonVariant={ButtonVariant.TERTIARY}
                    />
                  </div>
                )}
                {(primaryButton || secondaryButton) && (
                  <div
                    className={styles.modalPrimeBtns}
                    id={`${id}-action-prim-group`}
                    data-testid={`${id}-action-prim-group`}
                  >
                    {secondaryButton && (
                      <ModalActionButtons
                        modalActionButton={secondaryButton}
                        buttonVariant={ButtonVariant.SECONDARY}
                      />
                    )}
                    {primaryButton && (
                      <ModalActionButtons
                        modalActionButton={primaryButton}
                        buttonVariant={ButtonVariant.PRIMARY}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </FocusLock>
      </CSSTransition>
    );
  }
);
