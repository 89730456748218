import { HbtFileExtensionType } from '@hobt/constants';
import { AttachmentsViewPropsFields } from 'Components/Common/AttachmentsView/types';

export interface AttachmentsViewComponentProps {
  fields: AttachmentsViewPropsFields;
}

export interface AddAttachmentsFormPropsFields {
  content: FieldValue;
  formTitle: FieldValue;
  submitBtn: FieldValue;
  cancelBtn: FieldValue;
  limitNumber: FieldValue;
  description: FieldValue;
  categoryList: FieldList;
  categoryLabel: FieldValue;
  attachmentText: FieldValue;
  characterLimit: FieldValue;
  attachmentsView: AttachmentsViewComponentProps;
}

export interface AddAttachmentsFormProps {
  name?: string;
  fields: AddAttachmentsFormPropsFields;
  onCancelCallback?: Function;
  onSuccessCallback?: Function;
  onErrorCallback?: Function;
}

export const AllowedFileTypes = [
  HbtFileExtensionType.CSV,
  HbtFileExtensionType.XLSX,
  HbtFileExtensionType.XLSM,
  HbtFileExtensionType.XLSB,
  HbtFileExtensionType.XLTX,
  HbtFileExtensionType.XLTM,
  HbtFileExtensionType.XLS,
  HbtFileExtensionType.XLT,
  HbtFileExtensionType.XML,
  HbtFileExtensionType.XLAM,
  HbtFileExtensionType.XLA,
  HbtFileExtensionType.XLW,
  HbtFileExtensionType.XLR,
  HbtFileExtensionType.PDF,
  HbtFileExtensionType.JPG,
  HbtFileExtensionType.DOC,
  HbtFileExtensionType.DOCX
];
