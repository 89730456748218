import React, { memo, useMemo } from 'react';
import { Box } from '@mui/material';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import { HeaderActionLinkProps } from 'Components/Core/Header/types';
import useEnterOrSpaceKey from 'Components/Hooks/UseEnterOrSpaceKey';
import styles from './styles.module.scss';

const HeaderActionLink: React.FC<HeaderActionLinkProps> = ({
  iconType,
  text,
  isMediumUp,
  onFocus,
  onToggle
}) => {
  const linkStyles = useMemo(
    () => `${styles.link} ${!isMediumUp ? styles.tabletLink : ''}`,
    [isMediumUp]
  );

  return (
    <Box
      className={linkStyles}
      tabIndex={0}
      onClick={onToggle}
      onFocus={onFocus}
      onKeyDown={useEnterOrSpaceKey(onToggle)}
    >
      <HbtIcon size={HbtIconSize.MEDIUM} type={iconType} />
      {isMediumUp && <p data-testid="link-text">{text}</p>}
    </Box>
  );
};

export default memo(HeaderActionLink);
