import React, { PropsWithChildren, useRef, useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { DataTableProps } from 'Components/Common/DataTable/types';
import { TableShiftButton } from 'Components/Common/DataTable/TableShiftButton';
import { useDataTableFunctions } from 'Components/Common/DataTable/useDataTableFunctions';
import { LoaderAnimation } from 'Feature/CommonComponents/UserControls';

import styles from './styles.module.scss';

export const DataTable: React.FC<DataTableProps> = ({
  name,
  caption,
  scrollOnHorizontalOverflow,
  children,
  isLoading,
  hideTableShiftBtnsForDesktop = false,
  addTableWrapper = true,
  tableClass = ''
}: PropsWithChildren<DataTableProps>) => {
  const scrollContainer = useRef<HTMLDivElement>(null);

  const { scrollLeft, scrollRight, updateScrollButtons, showShiftLeft, showShiftRight } =
    useDataTableFunctions(scrollContainer);

  useEffect(() => {
    updateScrollButtons();
  }, []);

  const table = (
    <table className={`${styles.dataTable} ${tableClass}`} data-testid={`${name}DataTableTest`}>
      <caption className="sr-only" data-testid={`${name}DataTableCaptionTest`}>
        <Text field={caption} />
      </caption>
      {children}
    </table>
  );

  const defaultTable =
    addTableWrapper === true ? <div className={styles.dataTableFillWidth}>{table}</div> : table;

  if (isLoading === true) {
    return <LoaderAnimation />;
  }

  return scrollOnHorizontalOverflow === true ? (
    <>
      <div className={styles.hScrollWrapper}>
        <div
          ref={scrollContainer}
          className={styles.hScrollContainer}
          onScroll={updateScrollButtons}
          id={`${name}ScrollContainer`}
          data-testid={`${name}ScrollContainer`}
        >
          {table}
        </div>
        <TableShiftButton
          onClickCallback={scrollRight}
          isVisible={showShiftRight}
          hideForDesktop={hideTableShiftBtnsForDesktop}
          ariaControls={`${name}ScrollContainer`}
        />
        <TableShiftButton
          shiftLeft
          onClickCallback={scrollLeft}
          isVisible={showShiftLeft}
          hideForDesktop={hideTableShiftBtnsForDesktop}
          ariaControls={`${name}ScrollContainer`}
        />
      </div>
    </>
  ) : (
    defaultTable
  );
};
