import * as React from 'react';
import DashboardNavigationCardProps from 'Feature/DashboardComponents/models/DashboardNavigationCardProps';
import {
  LinedCard,
  AccordionContainer,
  DashboardItem
} from '../../../CommonComponents/AccordionComponents';

const DashboardNavigationCard = (props: DashboardNavigationCardProps) => {
  return (
    <div>
      <LinedCard
        id={props.cardId}
        testId="defaultsModule"
        linePosition="horizontal"
        lineColor="blue"
      >
        <AccordionContainer
          accordionId={`${props.cardId}Accordion`}
          title={props.fields.dashboardCardTitle}
          logo={props.fields.dashboardCardLogo}
        >
          <DashboardItem
            subHeadingOne={props.fields.quickLinksHeading}
            columnOne={props.fields.quickLinkItems}
            footerContent={props.fields.cardFooterContent}
          />
        </AccordionContainer>
      </LinedCard>
    </div>
  );
};

export default DashboardNavigationCard;
