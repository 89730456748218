import i18n from 'i18next';
import React, { useRef, useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { ActionButton, ButtonDropdownProps } from './types';
import styles from './styles.module.scss';

export const ButtonDropdown: React.FC<ButtonDropdownProps> = ({
  toggleIcon,
  actionItems,
  className,
  disabled
}) => {
  // Adding function and variables to handle dropdown for now
  const [isActive, setIsActive] = useState<boolean>(false);
  const actionMenuRef = useRef<HTMLDivElement | null>(null);

  const handleDropdownClick = () => {
    setIsActive(!isActive);
  };

  const handleFlyoutClick =
    (onClickCallback?: () => void) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setIsActive(false);

      if (onClickCallback != null) {
        onClickCallback();
      }
    };

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (isActive && actionMenuRef?.current?.contains(e.target as Node) === false) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  return (
    <div className={`${styles.actionMenus} ${className ?? ''}`.trim()} ref={actionMenuRef}>
      <button
        onClick={handleDropdownClick}
        type="button"
        aria-label={i18n.t('Global-MoreAction-Aria')}
        disabled={disabled}
        data-testid="dropdownButtonTest"
      >
        <i aria-hidden={true} className="material-icons icon-size-20 icon--v-align-middle">
          {toggleIcon}
        </i>
      </button>
      {isActive && (
        <div className={`${styles.subNav}`}>
          {actionItems?.map((menuItem: ActionButton) => (
            <button
              disabled={menuItem?.disable}
              name={`${menuItem.name}Button`}
              onClick={handleFlyoutClick(menuItem.onClickCallback)}
              type="button"
              key={menuItem.name}
            >
              <Text field={menuItem.text ?? {}} />
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
