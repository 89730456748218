import { useState, useEffect } from 'react';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';

export const useWindowDimensions = () => {
  const hasWindow = !isServer();

  const getWindowDimensions = () => {
    const width = hasWindow ? window.innerWidth : undefined;
    const height = hasWindow ? window.innerHeight : undefined;
    return {
      width,
      height
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const handleResize = () => setWindowDimensions(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    return undefined;
  }, [hasWindow]);

  return windowDimensions;
};
