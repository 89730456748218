import React, { FC } from 'react';
import MuiLink from '@mui/material/Link';
import styles from './styles.module.scss';
import { LinkProps } from './types';

/**
 * @param {object} props - Props object for the Link component.
 * @param {string} props.label - The text to display inside the link.
 * @param {string} props.href - The URL to navigate to when the link is clicked.
 * @param {string} [props.ariaLabel] - The aria-label attribute for accessibility.
 * @param {string} [props.variant='default'] - The variant style for the link. Default is 'default'.
 * @param {string} [props.size='body'] - The size style for the link. Default is 'body'.
 * @param {function} [props.leadingIcon] - A function that returns a React component to render as a leading icon.
 * @param {function} [props.trailingIcon] - A function that returns a React component to render as a trailing icon.
 * @param {string} [props.className=''] - Additional CSS class names to apply to the link.
 * @returns {JSX.Element} The rendered Link component.
 */

/**
 * Link component
 *
 * This is the Link component written using storybook. It handles different hover, focused and active states
 */

const Link: FC<LinkProps> = ({
  href,
  ariaLabel,
  size = 'body',
  leadingIcon,
  trailingIcon,
  className = '',
  children
}) => {
  const linkClass = `${styles.link} ${styles[size]} ${className}`;
  return (
    <MuiLink href={href} className={linkClass} aria-label={ariaLabel}>
      {leadingIcon && <span className="withLeadingIcon">{leadingIcon()}</span>}
      {children}
      {trailingIcon && <span className="withTrailingIcon">{trailingIcon()}</span>}
    </MuiLink>
  );
};

export default Link;
