import React, { FC, useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import { InternalClaimStatus } from '@hobt/claim-domain';

import { PrimaryButton } from 'Components/Common/Button';
import { useRefundLetter } from 'Feature/Claims/components/Details/RefundManagement/RefundRequestLetter/RefundLetterContext';
import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';

import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import type { ReceivedAdjustment } from '../../types';
import type { NetBalanceProps } from './types';

import styles from './styles.module.scss';

const sumReduction = (previousValue: number, currentValue: number) => previousValue + currentValue;
const paymentCalculations: Record<string, any> = {
  // Ineligible Claim
  '1': sumReduction,
  // Claims Overpayment
  '2': sumReduction,
  // Mandatory Documentation Overdue
  '3': sumReduction
};
const whichCalculation: Record<string, any> = {
  // Ineligible Claim
  '1': 'grossClaimPaymentAmount',
  // Claims Overpayment
  '2': 'currentClaimPaymentAmount',
  // Mandatory Documentation Overdue
  '3': 'grossClaimPaymentAmount'
};

const NetBalance: FC<NetBalanceProps> = ({
  totalReceivedPayment,
  grossRefundBalance,
  netRefundBalance,
  generateLetter
}) => {
  const {
    state: { receivedAdjustments: contextReceivedAdjustments },
    dispatch
  } = useRefundLetter();
  const receivedAdjustments = useWatch({
    name: 'receivedAdjustments',
    defaultValue: contextReceivedAdjustments
  }) as ReceivedAdjustment[];
  const { refundReason } = useFormContext().getValues();
  const { claimData } = useHBTFormContext();
  const { toCurrency } = useTranslationHelpers();

  const generateLetterDisabled = ![
    InternalClaimStatus.Paid,
    InternalClaimStatus.NoClaimPayable
  ].includes(claimData?.profile?.claimStatus?.code);

  const financialTransaction = Math.abs(
    claimData?.financialTransaction?.[whichCalculation[refundReason]]
  );
  useEffect(() => {
    dispatch({ type: 'setRequestAmount', value: financialTransaction });
  }, [financialTransaction]);

  const totalPaymentReceived = receivedAdjustments
    ?.map((refund) => Number(refund.amount) || 0)
    .reduce(paymentCalculations[refundReason], 0);

  return (
    <section className={styles.balanceCard}>
      <strong className={styles.balanceRow}>
        {grossRefundBalance}
        <strong>{toCurrency(financialTransaction)}</strong>
      </strong>
      <strong className={styles.balanceRow}>
        {totalReceivedPayment}
        <strong>{toCurrency(totalPaymentReceived)}</strong>
      </strong>
      <hr />
      <span className={styles.netBalanceRow}>
        {netRefundBalance}
        <span>{toCurrency(financialTransaction - totalPaymentReceived)}</span>
      </span>
      <div className={styles.buttonBar}>
        <PrimaryButton
          disabled={
            generateLetterDisabled ||
            claimData?.adjudicationCalculation
              ?.subtotalPrincipalBalanceToProcessingTimeSectionAmount <= 0
          }
          data-testid="generateLetterButton"
          onClick={() => dispatch({ type: 'showForm', value: true })}
        >
          {generateLetter}
        </PrimaryButton>
      </div>
    </section>
  );
};

export default NetBalance;
