import React, { useMemo, useEffect } from 'react';
import { ModuleMapping, UserRole } from '@hobt/constants';
import { UserAndAccesses, UserAccess as UserAccessConst } from '@hobt/user-domain';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { pathNames } from 'Constants/pathNames';
import UserAccess from 'Feature/UserManagement/components/CardsExternal/UserAccess';
import UserAdminContactInfo from 'Feature/UserManagement/components/CardsExternal/UserAdminContactInfo';
import i18n from 'i18next';
import _ from 'lodash-es';
import { ModifyUserActionType, ModifyUserAction } from './types';

export const useModifyExternalUserFunctions = (
  formMethod: { reset: Function; setValue: Function; register: Function },
  isInternal: boolean,
  dispatch: React.Dispatch<ModifyUserAction>,
  push: Function,
  userData: UserAndAccesses | null,
  sitecoreContext?: any
) => {
  const isReadOnlyUser = useMemo(() => {
    const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
    return isInternal && isUserInRoles(ModuleMapping.admin, [UserRole.ReadOnly], moduleRoleMapping);
  }, []);

  const isUserDisabled = useMemo(() => {
    if (userData) {
      return !userData.userAccesses.some((access) => access.statusCode === 1);
    }
  }, [userData]);

  const sectionsLookup: Record<string, React.FC<any>> = {
    UserAdminContactInfo,
    UserAccess
  };

  const registerAndSet = (fieldName: string, value: unknown) => {
    formMethod.register(fieldName);
    formMethod.setValue(fieldName, value);
  };

  const initializeFormValues = () => {
    if (userData != null) {
      formMethod.reset(userData);
      // shouldn't register emailID each time because this field is using the useController
      formMethod.setValue('emailID', userData.emailID);

      if (isInternal) {
        userData.userAccesses.length > 0 &&
          userData.userAccesses.map((access, index) => {
            registerAndSet(
              `userAccesses[${index}].userAccessID`,
              userData.userAccesses[index].userAccessID
            );

            registerAndSet(`userAccesses[${index}].access.admin`, {
              activeFlag: userData.userAccesses[index].access?.admin?.activeFlag ?? false
            });
            registerAndSet(`userAccesses[${index}].access.default`, {
              activeFlag: userData.userAccesses[index].access?.default?.activeFlag ?? false
            });
            registerAndSet(`userAccesses[${index}].access.claim`, {
              activeFlag: userData.userAccesses[index].access?.claim?.activeFlag ?? false
            });
            registerAndSet(`userAccesses[${index}].access.arrears`, {
              activeFlag: userData.userAccesses[index].access?.arrears?.activeFlag ?? false
            });

            registerAndSet(`userAccesses[${index}].access.piFileShare`, {
              activeFlag: userData.userAccesses[index].access?.piFileShare?.activeFlag ?? false
            });
            registerAndSet(`userAccesses[${index}].access.piPurposeTest`, {
              activeFlag: userData.userAccesses[index].access?.piPurposeTest?.activeFlag ?? false
            });
            registerAndSet(`userAccesses[${index}].access.piRequest`, {
              activeFlag: userData.userAccesses[index].access?.piRequest?.activeFlag ?? false
            });

            registerAndSet(
              `userAccesses[${index}].defaultFinancialInstitutionCode`,
              userData.userAccesses[index].defaultFinancialInstitutionCode ?? '000'
            );

            registerAndSet(
              `userAccesses[${index}].defaultTransitNumber`,
              userData.userAccesses[index].defaultTransitNumber ?? '00000'
            );

            registerAndSet(
              `userAccesses[${index}].userAuthorizationScope`,
              userData.userAccesses[index].userAuthorizationScope ?? []
            );

            registerAndSet(
              `userAccesses[${index}].allowedTransitNumbers`,
              userData.userAccesses[index].allowedTransitNumbers ?? []
            );
          });
      }
    }
  };

  const isEditingOwnProfile = sitecoreContext?.user?.userID === userData?.userID;

  useEffect(() => {
    formMethod.register('emailID');
  }, []);

  useEffect(() => {
    initializeFormValues();
  }, [userData]);

  return {
    isEditingOwnProfile,
    isReadOnlyUser,
    sectionsLookup,
    isUserDisabled,
    initializeFormValues,
    onCancel: (tab?: any) => {
      push(`/${i18n.language}${pathNames.userInventory}`, tab);
    },
    onDeleteAccountButtonClick: () => {
      dispatch({ action: ModifyUserActionType.SHOW_MODAL });
    },
    onDismissToast: () => {
      dispatch({ action: ModifyUserActionType.DISMISS_TOAST });
    },
    onDismissModal: () => {
      dispatch({ action: ModifyUserActionType.DISMISS_MODAL });
    },
    onDismissUpdateModal: () => {
      dispatch({ action: ModifyUserActionType.DISMISS_UPDATE_MODAL });
    }
  };
};
