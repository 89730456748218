import React, { FC, memo, useCallback, useMemo } from 'react';
import { ModuleMapping, UserRole, ModuleName } from '@hobt/constants';
import { Image, useSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import i18n from 'i18next';
import { Link as RouterLink } from 'react-router-dom';
import styles from './styles.module.scss';
import { CardType, HbtDashboardCardProps } from './types';

const HbtDashboardCard: FC<HbtDashboardCardProps> = ({ cardId, fields }) => {
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isToolCard = fields.cardType.value === CardType.PI_TOOLS;

  const getModuleNumber = useCallback((cardName: FieldValue) => {
    return ModuleMapping[cardName?.value as ModuleName];
  }, []);

  const isUserAdmin = useMemo(() => {
    return isUserInRoles(
      ModuleMapping.admin,
      [UserRole.CmhcAdministrator, UserRole.LenderAdministrator],
      moduleRoleMapping
    );
  }, [moduleRoleMapping]);

  const filteredPortfolioLinkItems = fields.portfolioLinkItems.filter((card) => {
    if (fields.cardType.value === CardType.PI_RESOURCES) {
      return true;
    }

    if (fields.cardType.value === CardType.PI_TOOLS) {
      if (card.fields.name.value === CardType.USER_CARD) {
        return isUserAdmin;
      }

      const moduleNumber = getModuleNumber(card.fields.name);

      return (
        moduleNumber !== undefined &&
        Object.prototype.hasOwnProperty.call(moduleRoleMapping, moduleNumber.toString(10))
      );
    }

    return false;
  });

  return (
    <div className={styles.dashboardCardsContainer} id={`dashboard-cards-container-${cardId}`}>
      {filteredPortfolioLinkItems.map((card, index) => (
        <RouterLink
          target={isToolCard ? '_self' : '_blank'}
          rel={isToolCard ? undefined : 'noopener noreferrer'}
          to={
            isToolCard
              ? `/${i18n.language}${card.fields.navItem.value.href}`
              : `${card.fields.navItem.value.href}`
          }
          key={index}
          className={styles.dashboardCardBase}
          id={`dashboard-${cardId}-${index}`}
        >
          <div className={styles.container}>
            <Image field={card.fields.icon} alt="" />
            <h3 className={styles.cardTitle}>
              <Text field={card.fields.text} />
            </h3>
          </div>
        </RouterLink>
      ))}
    </div>
  );
};

export default memo(HbtDashboardCard);
