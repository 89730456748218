import React, { useState, useEffect } from 'react';
import { FormModal } from 'Components/Common/FormModal';
import i18n from 'i18next';
import {
  AllowedFileTypes,
  BulkUserUploadErrorResp,
  UploadFileTypeProps,
  Tab
} from 'Feature/UserManagement/models/ManageUsersInternalProps';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { Text, Image, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import {
  UserRole,
  ModuleMapping,
  Status,
  HbtFileExtensionType,
  LanguageShort
} from '@hobt/constants';
import { Button } from 'Components/Common/Button';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { FormProvider, useForm } from 'react-hook-form';
import { AttachmentsView } from 'Components/Common/AttachmentsView';
import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import styles from './styles.module.scss';
import { config } from '../../../../../config';
import { downloadErrorReport } from '../../api/UserApiHandler.service';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { AttachmentObject } from 'Components/Common/AttachmentsView/types';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';

// import { writeFile } from 'xlsx'

export const UploadDocuments = ({ fields, isActive, tabName }: UploadFileTypeProps) => {
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [toastState, setToastState] = useState<ApplicationStates>(ApplicationStates.DEFAULT);
  const [isFileUploadedContainsError, setIsFileUploadedContainsError] = useState(false);
  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const authContext = useAuthenticationContext();
  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.admin,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );
  const [errorList, setErrorList] = useState<BulkUserUploadErrorResp[]>([]);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [isWrongFileUploaded, setIsWrongFileUploaded] = useState<boolean>(false);
  const [{ isLoading, spinnerHeading, spinnerDescription }, setIsLoading] = useState<Spinner>({
    isLoading: false
  });

  const [files, setFiles] = useState<AttachmentObject[]>([]);
  const hookForm = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {}
  });

  const attachmentFiles = (attachment: any) => {
    const file: AttachmentObject[] | any = attachment;
    if (file) {
      setFiles(file);
      const fileExtension = file[0]?.fileName?.split('.')[1].toLowerCase();
      file.length > 0 && AllowedFileTypes.includes(fileExtension as HbtFileExtensionType)
        ? setIsFileUploaded(true)
        : setIsFileUploaded(false);
    }
  };

  const onApiErrorCallback = (error: any) => {
    const resp = error?.response?.data;
    // To show Errors received form uploaded File
    const { errorBlobUrl, errorCount, errors, statusCode, code } = resp.data[0];
    if (resp?.statusCode === 400) {
      setIsLoading({ isLoading: false });
      // Show Errors if data inside file is wrong (For particulr user)
      if (statusCode && statusCode == 207) {
        errorBlobUrl && sessionStorage.setItem('blobUrlUploadFile', errorBlobUrl);
        errors && setErrorList(errors?.slice(0, 10));
        errorCount > 0 && setIsFileUploadedContainsError(true);
      } else {
        setIsLoading({
          isLoading: false
        });
        // Show Error if wrong file is uploaded (wrong template)
        setShowToast(true);
        code && setIsWrongFileUploaded(true);
        setToastState(ApplicationStates.ERROR);
      }
    } else {
      setIsLoading({
        isLoading: false
      });
      // Show Error if wrong file is uploaded (wrong template)
      setShowToast(true);
      setToastState(ApplicationStates.ERROR);
    }
  };
  const deleteAttachment = () => {
    setFiles([]);
  };

  const handleDownloadErrorReport = () => {
    const blobUrl = sessionStorage.getItem('blobUrlUploadFile');
    if (blobUrl) {
      downloadErrorReport(authContext, blobUrl)
        .then((res: any) => {
          const contentType = res?.headers['content-type'];
          const fileName =
            files.length > 0
              ? `${files[0].fileName}_ValidationError${Date.now()}.xlsx`
              : `UserValidationError_${Date.now()}.xlsx`;

          // Create the blob once with the correct content type
          const blob = new Blob([res?.data], { type: contentType });

          // Create URL and initiate download
          const url = window.URL.createObjectURL(blob);
          const downloadElement = document.createElement('a');
          downloadElement.setAttribute('href', url);
          downloadElement.setAttribute('download', fileName);
          downloadElement.click();

          // Clean up
          downloadElement.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch((errs?: any) => {
          setIsLoading({
            isLoading: false
          });
          setShowToast(true);
          setToastState(ApplicationStates.ERROR);
          if (errs) console.log('errs', errs);
        });
    }
  };

  const onToastCloseHandler = () => {
    setShowToast(false);
    isActive();
  };
  const onUploadBulkUserSuccessCallback = () => {
    setIsLoading({
      isLoading: false
    });
    setShowToast(true);
    setToastState(ApplicationStates.SUCCESS);
  };

  const handleLoadingIndicatorValueCallback = () => {
    setIsLoading({
      isLoading: true,
      spinnerHeading: 'Globals-Loading-Heading',
      spinnerDescription: 'Globals-Loading-Description'
    });
  };

  return (
    <div>
      <ToastNotification
        isActive={showToast}
        type={toastState}
        title={
          toastState === ApplicationStates.ERROR
            ? i18n.t('DefaultsInventoryTable-SystemErrorToast-Title')
            : i18n.t('DefaultActions-SystemSuccessToast-Action-Title')
        }
        content={
          toastState === ApplicationStates.ERROR
            ? {
                value: isWrongFileUploaded
                  ? i18n.t('Errors-HBT_ERR_6003')
                  : i18n.t('DefaultActions-SystemErrorToast-Action-Message')
              }
            : { value: i18n.t('UserManagement-Users-UploadUsersSuccessToast') }
        }
        onCloseCallback={onToastCloseHandler}
      />
      <ContentLoadingModal
        display={isLoading}
        fields={{
          heading: { value: i18n.t(spinnerHeading ?? 'Globals-InProgress-Heading') },
          description: { value: i18n.t(spinnerDescription ?? 'Globals-InProgress-Description') }
        }}
      />
      <FormModal
        isActive={true}
        icon={false}
        type={ApplicationStates.DEFAULT}
        style=""
        title={String(fields.modalTitle.value)}
        customStyle={styles.viewport}
        onCloseCallback={() => {
          isActive();
        }}
      >
        <div>
          <FormProvider {...hookForm}>
            <form id="uploadBulkUserForm" className="form">
              {isFileUploaded && (
                <div className={styles.fileView}>
                  <hr />
                  <div>{fields.uploadFileNameLabel.value}</div>
                  <hr />
                  <div className={styles.fileTableView}>
                    <span>{files[0]?.fileName}</span>
                    <span>
                      <button className={`btn btn--icon`} onClick={() => deleteAttachment()}>
                        <Image className="icon-24" field={fields.deleteIcon} />
                      </button>
                    </span>
                  </div>
                  <hr />
                </div>
              )}

              {!isFileUploaded && (
                <div>
                  {React.createElement(AttachmentsView, {
                    showCounter: false,
                    isArrears: true,
                    registeredVal: 'attachments',
                    fields: fields.attachmentsView.fields,
                    endpoint: `${config.userApi.urls.upload}/${
                      tabName == 'internal' ? Tab.internal : Tab.external
                    }`,
                    allowedFileTypes: AllowedFileTypes,
                    onErrorCallBack: onApiErrorCallback,
                    isUserFile: true,
                    attachmentFiles,
                    onUploadBulkUserSuccessCallback,
                    handleLoadingIndicatorValueCallback
                  })}
                </div>
              )}
            </form>
          </FormProvider>
        </div>
        <div className={styles.buttonsContainer}>
          <div className={styles.buttonWrapper}>
            <Button
              responsive
              name="download"
              text={fields.downloadBtn}
              ariaText={fields.downloadBtn}
              onClick={handleDownloadErrorReport}
              disabled={!isFileUploadedContainsError}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              secondaryButton
              responsive
              name="cancel"
              text={fields.cancelBtn}
              ariaText={fields.cancelBtn}
              onClick={() => {
                isActive();
              }}
            />
          </div>
        </div>
        {errorList.length >= 1 && (
          <div className={styles.dataTable}>
            <DataTable
              name="manageErrorReport"
              caption={{ value: '' }}
              scrollOnHorizontalOverflow
              addTableWrapper
              hideTableShiftBtnsForDesktop
            >
              <DataTableHeader>
                <DataTableHeaderItem sortable name="emailId" displayText={fields.email} />
                <DataTableHeaderItem sortable name="errorCode" displayText={fields.errorCode} />
                <DataTableHeaderItem
                  sortable
                  name="errorMessage"
                  displayText={fields.errorMessage}
                />
              </DataTableHeader>
              <DataTableBody zebra>
                {errorList.map((row: BulkUserUploadErrorResp, index: Number) => (
                  <DataTableRow name={row.emailID} key={`userDataError${index}`} onClick={() => {}}>
                    <TextCell name="emailId" text={row.emailID ?? ''} />
                    <TextCell name="errorCode" text={row.error.code ?? ''} />
                    <TextCell name="errorMessage" text={row.error.appErrorMessage ?? ''} />
                  </DataTableRow>
                ))}
              </DataTableBody>
            </DataTable>
          </div>
        )}
      </FormModal>
    </div>
  );
};
