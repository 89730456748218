export enum GlossaryNames {
  IndicatorDetails = '0',
  Attachments = 'j',
  BorrowerCharges = 'g',
  BorrowerDetails = 'b',
  ClaimDetails = 'e',
  DefaultManagement = 'd',
  LenderDetails = 'a',
  ListingPeriod = 'f',
  RentalIncomeDetails = 'h',
  PartialPaymentDetails = 'i',
  PropertyDetails = 'c'
}
