import i18n from 'i18next';

import {
  Action,
  ActionType,
  AttachmentsNotesCommentsDetailsState,
  FilterType
} from 'Feature/Claims/components/Details/AttachmentsNotesCommentsDetails/types';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';

export const createCorrespondenceReducer =
  <T,>() =>
  (state: AttachmentsNotesCommentsDetailsState<T>, { action, payload }: Action) => {
    const newState: AttachmentsNotesCommentsDetailsState<T> = { ...state };

    const reducers = {
      [ActionType.SHOW_SIDE_DRAWER]: () => {
        newState.showSideDrawer = true;
        newState.sideDrawerComponent = payload.sideDrawerComponent;
        newState.sideDrawerComponentFields = payload.sideDrawerComponentFields;
      },

      [ActionType.HIDE_SIDE_DRAWER]: () => {
        newState.showSideDrawer = false;
      },

      [ActionType.DISMISS_DELETE_CONFIRMATION]: () => {
        newState.showModal = false;
        newState.correspondenceSelectedToDelete = undefined;
      },

      [ActionType.SHOW_DELETE_CONFIRMATION]: () => {
        newState.showModal = true;
        newState.correspondenceSelectedToDelete = {
          documentId: payload.documentId ?? '',
          correspondenceId: payload.correspondenceId ?? '',
          cmhcLoanAccountNumber: payload.cmhcLoanAccountNumber ?? '',
          decisionTimestamp: payload.decisionTimestamp ?? '',
          userID: payload.userID ?? ''
        };
      },

      [ActionType.SHOW_TOAST_SUCCESS]: () => {
        newState.showToast = true;
        newState.toastType = ApplicationStates.SUCCESS;
        newState.toastTitle = i18n.t('Globals-Toast-Success-Title');
        newState.toastContent = i18n.t('Globals-Toast-Success-Item-Saved');
      },

      [ActionType.SHOW_TOAST_Delete_SUCCESS]: () => {
        newState.showToast = true;
        newState.toastType = ApplicationStates.SUCCESS;
        newState.toastTitle = i18n.t('Globals-Toast-Success-Title');
        newState.toastContent = i18n.t('Globals-Toast-Success-Item-Deleted');
      },

      [ActionType.SHOW_TOAST_ERROR]: () => {
        newState.showToast = true;
        newState.toastType = ApplicationStates.ERROR;
        newState.toastTitle = i18n.t('Globals-Toast-Server-Error-Title');
        newState.toastContent =
          state.sideDrawerComponent?.name != null
            ? i18n.t(`ClaimsDetails-ErrorToast-${state.sideDrawerComponent?.name}`)
            : '';
      },

      [ActionType.SHOW_TOAST_API_ERROR]: () => {
        newState.showToast = true;
        newState.toastType = ApplicationStates.ERROR;
        newState.toastTitle = i18n.t('Globals-Toast-Server-Error-Title');
        newState.toastContent = i18n.t(`ClaimsDetails-ErrorToast-General`);
      },

      [ActionType.DISMISS_TOAST]: () => {
        newState.showToast = false;
      },
      [ActionType.SET_FILTER]: () => {
        newState.filteredValue = payload.filteredValue;
      },
      [ActionType.CLEAR_FILTER]: () => {
        newState.filteredValue = FilterType.Default;
      }
    };

    reducers[action]();

    return newState;
  };
