import * as React from 'react';
import { Order } from 'Foundation/Api';
import { SortIndicatorProps } from './SortIndicator.types';

export const SortIndicator: React.FC<SortIndicatorProps> = ({ indicatorState }) => (
  <div className={`sortIndicator dir--${indicatorState}`}>
    <React.Fragment>
      <i
        className={`material-icons icon--size-24 ${
          indicatorState === Order.Ascending ? 'active' : ''
        }`}
        aria-hidden="true"
      >
        arrow_drop_up
      </i>
      <i
        className={`material-icons icon--size-24 ${
          indicatorState === Order.Descending ? 'active' : ''
        }`}
        aria-hidden="true"
      >
        arrow_drop_down
      </i>
    </React.Fragment>
  </div>
);
