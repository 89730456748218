import React, { memo, useCallback, useState } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import ResponsiveImage from '../../../Shared/ResponsiveImageLoader';
import styles from './styles.module.scss';
import { HbtSupportPageComponentProps } from './types';

const HbtSupportPageComponent = ({ fields }: HbtSupportPageComponentProps) => {
  const initialImageLoadingStates = [true, true, true];
  const [loadingStates, setIsLoadingStates] = useState(initialImageLoadingStates);
  const handleImageLoadChange = useCallback(
    (index: number, loadingState: boolean) => {
      setIsLoadingStates((prevStates) => {
        if (prevStates[index] !== loadingState) {
          const newStates = [...prevStates];
          newStates[index] = loadingState;
          return newStates;
        }
        return prevStates;
      });
    },
    [loadingStates]
  );

  return (
    <div className={styles.supportMain}>
      <div className={styles.supportHeaderImageContainer}>
        <div className={styles.supportTitleContainer}>
          <div className={styles.supportTitleTextWrapper}>
            <h1 className={styles.supportTitle}>
              <Text field={fields.supportTitle} />
            </h1>
            <span className={styles.supportTitleInfo}>
              <Text field={fields.supportTitleInfo} />
            </span>
          </div>
        </div>
        <div className={styles.supportMainImageWrapper}>
          <ResponsiveImage
            desktopImage={fields.supportMainImageLg}
            tabletImage={fields.supportMainImageMd}
            responsiveClassName={`${styles.supportMainImage}`}
            onLoadingChange={(loadingState) => handleImageLoadChange(0, loadingState)}
          />
        </div>
      </div>
      <div className={styles.supportMainContainer}>
        <div className={styles.supportContainer}>
          <div className={styles.supportContactInfoWrapper}>
            <h2 className={styles.contactInfoHeader}>
              <Text field={fields.contactInfoHeader} />
            </h2>
            <span className={styles.contactInfoContent}>
              <RichText field={fields.contactInfoContent} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(HbtSupportPageComponent);
