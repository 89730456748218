import * as React from 'react';
import { ButtonDropdown, Button, ButtonDropdownProps } from 'Feature/CommonComponents/UserControls';
import { CardHeaderProps } from './CardHeader.types';

export const CardHeader: React.FC<CardHeaderProps> = (props: CardHeaderProps) => {
  return (
    <header className="card-header card__header">
      <div className="row">
        <div className={props.actionButtons ? 'col-6' : 'col-12'}>
          <div className="card__header-title__title-wrapper">
            <div className="card__header-title__title-wrapper__item">
              <h2 className="card__header-title">
                {props.preTitle && (
                  <span className="card__header-title__pre-title">{props.preTitle}</span>
                )}
                {props.title}
              </h2>
            </div>
            {props.tagText && (
              <div className="card__header-title__title-wrapper__item">
                <div className="status-tag">{props.tagText}</div>
              </div>
            )}
          </div>
        </div>
        {props.actionButtons && (
          <div className="col-6 textAlignRight">
            <div className="card-header__action-buttons">
              {props.actionButtons.map((button, index) => {
                if ('dropdownButton' in button) {
                  const dropdownOptions = (button as ButtonDropdownProps)?.dropdownItems;
                  const allDropdownOptionsDisabled =
                    dropdownOptions?.filter((btn) => btn.disabled === true).length ===
                    dropdownOptions?.length;
                  const updatedButton = {
                    ...button.dropdownButton,
                    disabled: allDropdownOptionsDisabled
                  };
                  return (
                    <ButtonDropdown
                      id={`${button.dropdownButton}Button`}
                      key={index}
                      dropdownButton={updatedButton}
                      dropdownItems={button.dropdownItems}
                      otherDropdownButtonProps={button.otherDropdownButtonProps}
                      otherDropdownProps={button.otherDropdownProps}
                    />
                  );
                }
                return (
                  <Button
                    id={`${button.name}Button`}
                    ariaText={button.displayText}
                    key={index}
                    buttonType={button.buttonType}
                    name={button.name}
                    onClick={button.onClick}
                    disabled={button.disabled}
                    isDropDown={button.isDropDown}
                    icon={button.icon}
                    isIcon={button.isIcon}
                    iconSize={button.iconSize}
                    displayText={button.displayText}
                    otherProps={button.otherProps}
                    additionalStyles={button.additionalStyles}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </header>
  );
};
