import { isNumber } from 'Constants/Utils/isNumber';

export const removeEmptyNullOrUndefined = (data: Object | undefined) => {
  return data
    ? Object.fromEntries(
        Object.entries(data).filter(([_, val]) => {
          if (Array.isArray(val) && val.length === 0) {
            return false;
          }
          if (val && Object.keys(val).length === 0 && val.constructor === Object) {
            return false;
          }
          return !(val === '' || val === null || val === undefined);
        })
      )
    : null;
};

export const isNullOrUndefined = (val: any) => val === null || val === undefined;

export const renderSimpleValue = (
  value: string | number | null | undefined,
  prefix?: string | null,
  postfix?: string | null,
  roundToDecimalPlaces?: number,
  ifEmptyString?: string | null
): string => {
  const emptyString = ifEmptyString ?? '--';
  let displayedValue = value;

  if (value != null && isNumber(value) && roundToDecimalPlaces != null) {
    // wrap inside parseFloat to remove leading zeros
    displayedValue = parseFloat(parseFloat(value.toString()).toFixed(roundToDecimalPlaces));
  }

  return value != null
    ? `${prefix ?? ''}${displayedValue}${postfix ?? ''}`.trimStart().trimEnd()
    : emptyString;
};
