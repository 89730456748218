import React from 'react';
import i18n from 'i18next';
import { withRouter } from 'react-router-dom';

import { ErrorBoundaryProps, ErrorBoundaryState, ApplicationException } from './Error.types';
import { pathNames } from '../../Constants/pathNames';

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Display error page
    return {
      hasError: true,
      error
    };
  }

  render() {
    if (this.state.hasError) {
      let messageKey: string;

      // Display a specific message to the user
      if (this.state.error instanceof ApplicationException) {
        messageKey = this.state.error.messageKey;
        const message = i18n.t(messageKey);
        this.props.history.push(`/${i18n.language}${pathNames.errorApplication}`, { message });
      }

      // No defined message so show the generic error page.
      else {
        this.props.history.push(`/${i18n.language}${pathNames.error500}`);
      }

      this.setState({ hasError: false }); // So that we don't get into an infinite loop when the error page tries to render
      return null;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
