import React, { FC, PropsWithChildren, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import DynamicHeading, { HeadingLevel } from 'Components/Common/DynamicHeading';
import { useHBTFormActionsContext } from 'Feature/Claims/components/HBTFormContext';
import useProgress from 'Feature/Claims/components/HBTFormContext/useProgress';
import { Button } from 'Feature/CommonComponents/UserControls/Button/Button';
import { ButtonProps, ButtonType } from 'Feature/CommonComponents/UserControls';

import styles from './styles.module.scss';

type Props = {
  headingLevel: HeadingLevel;
  title: FieldValue;
  sectionId: string;
  onBlur?: () => void;
  fieldToValidate?: string;
  children?: React.ReactNode;
  toolTipButton?: ButtonProps;
};

const Card: FC<Props> = ({
  title,
  headingLevel,
  sectionId,
  onBlur,
  fieldToValidate,
  children,
  toolTipButton
}: PropsWithChildren<Props>) => {
  useProgress(fieldToValidate);
  const hbtFormActions = useHBTFormActionsContext();

  const [collapsed, setCollapsed] = useState(false);

  const toggle = function _cardToggle() {
    setCollapsed(!collapsed);
  };

  const onCardBlur = (e: React.FocusEvent) => {
    if (onBlur) {
      const { currentTarget } = e;

      setTimeout(() => {
        if (!currentTarget.contains(document.activeElement)) {
          onBlur();
        }
      }, 0);
    }
  };

  const onToolTipClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (toolTipButton?.onClick != null) {
      toolTipButton.onClick(e);
    }
  };

  return (
    <section
      className={`${styles.card} ${
        hbtFormActions?.isCardComplete(fieldToValidate) ? styles.completed : ''
      }`.trim()}
      onBlur={onCardBlur}
      data-testid={`${sectionId}Test`}
    >
      <DynamicHeading
        headingLevel={headingLevel}
        className={`${styles.sectionTitleWrapper} w-100 mb-0`}
      >
        <div className={`row ${styles.icon}`}>
          <Text field={title} />
          {toolTipButton?.onClick != null && (
            <Button
              id={toolTipButton.id}
              type="button"
              name={toolTipButton.name}
              ariaText={toolTipButton.ariaText}
              icon={'help_outline'}
              buttonType={ButtonType.TEXT}
              onClick={onToolTipClick}
              iconSize={24}
              {...(toolTipButton?.disabled != null && { disabled: toolTipButton?.disabled })}
            />
          )}
        </div>
        <button
          aria-controls={sectionId}
          aria-expanded={!collapsed}
          className={styles.collapseButton}
          data-testid={`${sectionId}CollapseButton`}
          onClick={toggle}
          type="button"
          {...(toolTipButton?.disabled != null && { disabled: toolTipButton?.disabled })}
        >
          <span className="material-icons align-self-center">
            expand_{collapsed ? 'more' : 'less'}
          </span>
        </button>
      </DynamicHeading>
      <div
        id={sectionId}
        className={`${styles['collapsing-section']} d-${collapsed ? 'none' : 'flex'}`}
      >
        {children}
      </div>
    </section>
  );
};

export default Card;
