import React, { FC, useState } from 'react';
import { useWatch } from 'react-hook-form';
import ExpandedComment from './ExpandedComment';
import CollapsedComment from './CollapsedComment';

type Props = {
  name: string;
  isDisabled?: boolean;
  className?: string;
  addCommentLabel?: string;
  editCommentLabel?: string;
  label?: string;
  updateLabel?: string;
  cancelLabel?: string;
  characterLimitLabel?: string;
};

const FormComment: FC<Props> = ({
  name,
  className,
  label,
  editCommentLabel,
  isDisabled,
  addCommentLabel,
  updateLabel,
  cancelLabel,
  characterLimitLabel
}) => {
  const comment = useWatch({ name, defaultValue: '' });
  const [isExpanded, setExpanded] = useState(false);

  return (
    <>
      <ExpandedComment
        name={name}
        className={className}
        label={label}
        hidden={!isExpanded}
        updateLabel={updateLabel}
        cancelLabel={cancelLabel}
        characterLimitLabel={characterLimitLabel}
        onClick={() => setExpanded(false)}
      />
      <CollapsedComment
        className={className}
        hidden={isExpanded}
        onClick={() => setExpanded(true)}
        comment={comment}
        addCommentLabel={addCommentLabel}
        editCommentLabel={editCommentLabel}
        commentLabel={label}
        isDisabled={isDisabled}
      />
    </>
  );
};

export default FormComment;
