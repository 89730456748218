import i18n from 'i18next';
import React from 'react';

import {
  DraftClaimStatusCodeInternalClaimStatusMapping,
  InternalClaimStatus
} from '@hobt/claim-domain';
import {
  Language,
  LanguageShort,
  ProvinceIDSymbolMapping,
  StatisticsCanadaProvinceID
} from '@hobt/constants';

import { ActionButtonCell } from 'Components/Common/DataTable/DataTableRow/ActionButtonCell';
import { convertDateForUi, convertDateOnlyForUi } from 'Components/Common/Api/utils/DateParser';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { IconCell } from 'Components/Common/DataTable/DataTableRow/IconCell';
import { MenuItemProps } from 'Components/Common/ActionMenu/types';
import { StatusCell } from 'Components/Common/DataTable/DataTableRow/StatusCell';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import { ClaimsInventoryTabEnum } from 'Feature/Claims/components/ClaimsInventory/types';

import { TableRowProps } from './types';

const InternalTableRow = ({
  currentTab,
  isDraftClaimTab,
  row,
  hideActionButtonCell,
  onRowClick,
  emptyValuePlaceholder,
  rowActionMenu,
  claimStatusTypes,
  claimType,
  claimTypeIndicator
}: TableRowProps) => {
  const claimIdToUse =
    isDraftClaimTab === true ? row?.draftClaimRecordID : row?.claimID?.toString();

  const disabledInternalStatuses = [
    InternalClaimStatus.Cancelled,
    InternalClaimStatus.ReviewRequired,
    InternalClaimStatus.Declined,
    InternalClaimStatus.ClaimPaymentInProgress,
    InternalClaimStatus.PaymentFailed
  ];

  const updatedActionMenuItems = rowActionMenu;

  const disableDeleteButtonStatuses = [InternalClaimStatus.Cancelled, InternalClaimStatus.New];

  const actionMenuItemIndex = updatedActionMenuItems?.findIndex(
    (menuItem) => menuItem.name === 'cancel'
  );
  const deleteActionItemIndex = updatedActionMenuItems?.findIndex(
    (menuItem) => menuItem.name === 'delete'
  );

  // Disable Cancel Button on these claim statuses
  if (actionMenuItemIndex > -1) {
    updatedActionMenuItems[actionMenuItemIndex].disable =
      row?.claimStatus != null && disabledInternalStatuses.includes(row?.claimStatus);
  }

  // Disable Delete buttons based on statuses
  if (deleteActionItemIndex > -1) {
    updatedActionMenuItems[deleteActionItemIndex].disable =
      row?.claimStatus != null && !disableDeleteButtonStatuses.includes(row?.claimStatus);
  }
  const getLocalizedLanguageString = (languageIdentifier: number) => {
    if (languageIdentifier === Language.English || languageIdentifier === Language.French) {
      return i18n.t(`Globals-Languages-${languageIdentifier}`);
    }
    return '---';
  };
  return (
    <>
      <DataTableRow
        name={claimIdToUse}
        key={claimIdToUse}
        onClick={onRowClick}
        testId={`${currentTab.name}-${claimIdToUse}`}
      >
        {!hideActionButtonCell && (
          <ActionButtonCell
            name="rowActionMenu"
            rowActionItems={updatedActionMenuItems?.map((action: MenuItemProps) => ({
              ...action,
              actionItemId: claimIdToUse
            }))}
            rowId={claimIdToUse}
          />
        )}
        <TextCell
          name="cmhcLoan"
          text={
            row.cmhcLoanAccountNumber != null
              ? row.cmhcLoanAccountNumber.toString(10)
              : emptyValuePlaceholder
          }
        />
        {currentTab.name !== ClaimsInventoryTabEnum.Draft && (
          <IconCell
            name="iconCell"
            icons={[
              row.newExternalAttachmentFlag === true ? 'attach_file' : '',
              row.newExternalCommentFlag === true ? 'mail_outline' : ''
            ]}
          />
        )}
        {currentTab.name === ClaimsInventoryTabEnum.Draft && <IconCell name="iconCell" />}
        <TextCell
          name="claimTypeIndicator"
          text={
            row.claimTypeIndicator != null
              ? claimTypeIndicator?.fields?.listItems?.find?.(
                  (item: SitecoreListItem) =>
                    Number(item?.fields?.itemValue?.value) === row.claimTypeIndicator
                )?.fields?.itemName?.value ?? emptyValuePlaceholder
              : emptyValuePlaceholder
          }
        />
        {currentTab.name !== ClaimsInventoryTabEnum.Draft && (
          <>
            <TextCell
              name="submittedDate"
              text={
                row.claimReceivedDate != null
                  ? convertDateOnlyForUi(row.claimReceivedDate, i18n.language as LanguageShort)
                  : emptyValuePlaceholder
              }
            />
            <TextCell
              name="age"
              text={
                row.claimDecisionDate && row.claimReceivedDate
                  ? String(
                      Math.floor(
                        (Number(new Date(String(row.claimDecisionDate))) -
                          Number(new Date(String(row.claimReceivedDate)))) /
                          86400000
                      )
                    )
                  : row.claimReceivedDate
                  ? String(
                      Math.floor(
                        (Number(new Date()) - Number(new Date(String(row.claimReceivedDate)))) /
                          86400000
                      )
                    )
                  : emptyValuePlaceholder
              }
            />

            <TextCell
              name="language"
              text={
                row.correspondenceLanguage != null
                  ? getLocalizedLanguageString(row.correspondenceLanguage)
                  : emptyValuePlaceholder
              }
            />
          </>
        )}
        <TextCell
          name="typeOfClaim"
          text={
            row.claimTypeCode != null
              ? claimType?.find?.((item) => item?.fields?.itemValue.value === row.claimTypeCode)
                  ?.fields?.itemName?.value ?? emptyValuePlaceholder
              : emptyValuePlaceholder
          }
        />
        {currentTab.name !== ClaimsInventoryTabEnum.Draft && (
          <>
            <StatusCell
              name="status"
              statusText={
                claimStatusTypes?.find?.(
                  (status) =>
                    status.value ===
                    (currentTab.name === ClaimsInventoryTabEnum.ProcessInMics
                      ? DraftClaimStatusCodeInternalClaimStatusMapping[row.statusCode!]
                      : (row?.claimStatus as InternalClaimStatus))
                )?.text ?? emptyValuePlaceholder
              }
              className={
                claimStatusTypes?.find?.(
                  (status) =>
                    status.value ===
                    (currentTab.name === ClaimsInventoryTabEnum.ProcessInMics
                      ? InternalClaimStatus.New
                      : (row?.claimStatus as InternalClaimStatus))
                )?.className ?? 'grey20'
              }
            />
            <TextCell
              name="officerName"
              text={row.ownerFullName ? row.ownerFullName : emptyValuePlaceholder}
            />
            <TextCell
              name="pendingCode"
              text={row.pendingConditions?.map((x) => x.code).join(', ') || emptyValuePlaceholder}
            />
            <TextCell
              name="province"
              text={String(
                ProvinceIDSymbolMapping[
                  String(row.propertyAddress?.provinceCode) as StatisticsCanadaProvinceID
                ]
              )}
            />
          </>
        )}
        <TextCell
          name="lenderName"
          text={
            i18n.language === 'en'
              ? row?.approvedLenderEnglishName ?? emptyValuePlaceholder
              : row?.approvedLenderFrenchName ?? emptyValuePlaceholder
          }
          subtext={row?.approvedLenderCode ?? emptyValuePlaceholder}
        />
        <TextCell
          name="lenderReference"
          text={row.approvedLenderReferenceNumber ?? emptyValuePlaceholder}
        />
        {currentTab.name !== ClaimsInventoryTabEnum.Draft && (
          <TextCell
            name="recordUpdatedUser"
            text={
              row.recordUpdatedUserFullName
                ? `${row.recordUpdatedUserFullName}`
                : emptyValuePlaceholder
            }
            subtext={
              row.recordUpdatedTimestamp != null
                ? convertDateForUi(row.recordUpdatedTimestamp, i18n.language as LanguageShort)
                : emptyValuePlaceholder
            }
          />
        )}
        {currentTab.name === ClaimsInventoryTabEnum.Draft && (
          <TextCell
            name="lastSaved"
            text={
              row.recordUpdatedTimestamp != null
                ? convertDateForUi(row.recordUpdatedTimestamp, i18n.language as LanguageShort, true)
                : emptyValuePlaceholder
            }
          />
        )}
      </DataTableRow>
    </>
  );
};

export default InternalTableRow;
