import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { ModuleMapping, UserRole } from '@hobt/constants';

import { debounce } from 'lodash';
import UserDetailsInternalProps from 'Feature/UserManagement/models/UserDetailsInternalProps';
import FormText, { Formatted as FormattedInput } from 'Components/Inputs/FormText';
import FormDropdown from 'Components/Inputs/FormDropdown';
import labelStyles from 'Components/Inputs/FormInputWrapper/styles.module.scss';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { telephoneFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormNumber from 'Components/Inputs/FormNumber';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import SearchboxDropdown from 'Components/Inputs/SearchboxDropdown';
import { positionTitleUserRequest } from '../../api/UserApiHandler.service';
import { useAuthenticationContext } from 'Foundation/Authentication';
import i18n from 'i18next';

const UserDetailsInternal: React.FC<UserDetailsInternalProps> = ({
  fields,
  isModify = false
}: UserDetailsInternalProps) => {
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const authenticationContext = useAuthenticationContext();
  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.admin,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  const { getValues, setValue, watch, register } = useFormContext();
  const phoneNumber = watch('contact.phoneNumber');
  const preferredLanguage = watch('contact.preferredLanguageCode');
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState();
  const userTitle = getValues(`internalContact.titleName`);
  const setTypedValue = (value: any) => {
    setInputValue(value);
  };

  const onSearchPositionTitleAPICall = async (
    authenticationContext: any,
    query: string,
    lang: string
  ) => {
    try {
      const result = await positionTitleUserRequest(authenticationContext, query, lang);
      setOptions(result?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const debouncedSearch = debounce(async (query) => {
    const lang = i18n.language;
    if (query && query.length >= 3) {
      await onSearchPositionTitleAPICall(authenticationContext, query, lang);
    }
  }, 500);

  useEffect(() => {
    debouncedSearch(inputValue);
    return () => {
      debouncedSearch.cancel(); // Cancel the debounce function on component unmount
    };
  }, [inputValue]);

  useEffect(() => {
    userTitle && debouncedSearch(userTitle);
  }, [userTitle]);

  useEffect(() => {
    if (phoneNumber == null) {
      setValue('contact.phoneNumber', undefined);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (preferredLanguage === undefined) {
      setValue('contact.preferredLanguageCode', null);
    }
  }, [preferredLanguage]);

  const handleTitleTypeName = (label: any) => {
    register('internalContact.titleName');
    setValue('internalContact.titleName', label);
  };

  return (
    <div>
      <h2 className="card-title">
        <Text field={fields.sectionTitle} />
      </h2>
      <p>
        <Text field={fields.sectionSubtitle} />
      </p>

      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormText
            className=""
            name="contact.firstName"
            label={fields.firstName}
            isReadOnly={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormText
            className=""
            name="contact.lastName"
            label={fields.lastName}
            isReadOnly={isReadOnlyUser}
          />
        </div>
      </div>

      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormattedInput
            className=""
            name="emailID"
            label={fields.email}
            isReadOnly={isModify || isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormDropdown
            isDisabled={isReadOnlyUser}
            name="contact.preferredLanguageCode"
            label={fields.preferredLanguage}
            options={fields.languagePreferUser?.fields?.listItems}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormNumber
            className=""
            isReadOnly={isReadOnlyUser}
            name="contact.phoneNumber"
            label={fields?.phoneNumber}
            formatProps={telephoneFormat}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormText
            className=""
            isReadOnly={isReadOnlyUser}
            name="contact.extensionPhoneNumber"
            label={fields.extension}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <SearchboxDropdown
            name={`internalContact.positionTitleID`}
            options={options}
            placeholder=""
            selectedValue={String(getValues(`internalContact.positionTitleID`))}
            typedVal={setTypedValue}
            label={fields?.level}
            isDisabled={isReadOnlyUser}
            className={`${labelStyles.formInputLabelStyle} d-flex flex-column`}
            handleTitleTypeName={handleTitleTypeName}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormNumber
            className=""
            isReadOnly={isReadOnlyUser}
            name="contact.faxNumber"
            label={fields?.faxNumber}
            formatProps={telephoneFormat}
          />
        </div>
      </div>
      <hr />
    </div>
  );
};

export default UserDetailsInternal;
