import 'core-js'; // Required for IE10 support
import 'fastestsmallesttextencoderdecoder'; // Required for IE10 support
import { Buffer } from 'buffer';

const process = require('process');

// @ts-ignore
window.Buffer = Buffer;
window.process = process;

// https://developer.mozilla.org/en-US/docs/Web/API/Element/matches#Polyfill
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
