import React, { useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { FormProvider, useForm } from 'react-hook-form';
import i18n from 'i18next';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { HttpResponseStatusCodes, LanguageShort, Module, UserRole } from '@hobt/constants';

import { Button } from 'Components/Common/Button';
import { getCurrencyFormat, currencyFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormNumber from 'Components/Inputs/FormNumber';
import FormText from 'Components/Inputs/FormText';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormDatepicker from 'Components/Inputs/FormDatepicker';
import { useAuthenticationContext } from 'Foundation/Authentication';

import { config } from '../../../../config';
// TODO: Create a custom component hook and add all the similar item there.
import useArrearsHistoricalApiClient from '../ArrearsHistoricalRecords/arrearsHistoricalClient';
import ArrearsHistoricalFlyoutProps from './types';
import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const ArrearsHistoricalFlyout = ({
  fields,
  name,
  flyoutData,
  isLoading,
  onCancelCallback,
  onErrorCallback,
  onSuccessCallback,
  setIsLoadingCallback
}: ArrearsHistoricalFlyoutProps) => {
  const sectionId = `${name}Historical`;

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const isMasterUser =
    sitecoreContext?.user?.moduleRoleMapping[Module.Arrears][0] === UserRole.MasterUser;
  const isReadOnlyUser =
    sitecoreContext?.user?.moduleRoleMapping[Module.Arrears][0] === UserRole.ReadOnly;

  const hookForm = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  });

  useEffect(() => {
    if (flyoutData != null) {
      hookForm.setValue('cmhcLoanAccountNumber', flyoutData.CmhcLoanAccountNumber);
      hookForm.setValue('reportingPeriod', flyoutData.reportingPeriod);
      hookForm.setValue('status', flyoutData.status);
      hookForm.setValue('recordID', flyoutData.recordID);

      hookForm.setValue('dueDateOfLastInstallment', flyoutData.dueDateOfLastInstallment);
      hookForm.setValue('arrearsAmount', flyoutData.arrearsAmount);
      hookForm.setValue('transitNumber', flyoutData.transitNumber);
      hookForm.setValue('institutionNumber', flyoutData.institutionNumber);
      hookForm.setValue('lenderCode', flyoutData.lenderCode);
    }
  }, [flyoutData]);

  const authenticationContext = useAuthenticationContext();
  const { updateStatus } = useArrearsHistoricalApiClient(authenticationContext, {
    timeout: config.arrearsApi.requestTimeout
  });

  // TODO: Add API once built.
  const onSubmit = async () => {
    const payload = hookForm.getValues();
    payload.recordID = flyoutData.recordID;
    setIsLoadingCallback({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description',
      isFlyout: true
    });
    const response = await updateStatus(payload);

    switch (response) {
      case HttpResponseStatusCodes.OK:
        if (onSuccessCallback) {
          onSuccessCallback();
        }
        break;
      default:
        if (onErrorCallback) {
          onErrorCallback();
        }
        break;
    }

    setIsLoadingCallback({
      isLoading: false
    });
    if (onCancelCallback) {
      onCancelCallback();
    }
  };

  return (
    <>
      <Scrollbars>
        <div className={styles.headerWrapper}>
          <Text tag="h2" field={fields.title} />
          <div className={styles.closeButton}>
            <button
              type="button"
              data-testid={`${sectionId}FormCloseButton`}
              onClick={onCancelCallback}
              aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
            >
              <span className="material-icons align-self-center">close</span>
            </button>
          </div>
        </div>

        <FormProvider {...hookForm}>
          <form
            id={`${sectionId}Form`}
            className={styles.documentRequestWrapper}
            onSubmit={hookForm.handleSubmit(onSubmit)}
            noValidate
          >
            <div>
              <p className={styles.textWrapper}>
                <Text field={fields.description} />
              </p>
            </div>

            <div className={styles.formWrapper}>
              <div className={styles.formWrapperItem}>
                <FormText
                  name="cmhcLoanAccountNumber"
                  label={fields.cmhcLoanNumberLabel}
                  className=""
                  isReadOnly={true}
                />
              </div>
              <div className={styles.formWrapperItem}>
                <FormDropdown
                  label={fields.statusLabel}
                  name="status"
                  className=""
                  options={fields.statusDropDownList.fields.listItems}
                  isDisabled={isReadOnlyUser}
                />
              </div>
              <div className={styles.formWrapperItem}>
                <FormText
                  name="reportingPeriod"
                  label={fields.reportingPeriodLabel}
                  className=""
                  isReadOnly={true}
                />
              </div>
              <div className={styles.formWrapperItem}>
                <FormDatepicker
                  name="dueDateOfLastInstallment"
                  label={fields.lastInstallmentLabel}
                  className=""
                  isReadOnly={!isMasterUser}
                />
              </div>
              <div className={styles.formWrapperItem}>
                <FormNumber
                  name="arrearsAmount"
                  label={fields.arrearsAmountLabel}
                  formatProps={getCurrencyFormat(
                    hookForm.getValues('arrearsAmount'),
                    { ...currencyFormat, thousandSeparator: true, thousandsGroupStyle: 'thousand' },
                    i18n.language as LanguageShort
                  )}
                  allowNegative={false}
                  className=""
                  isReadOnly={!isMasterUser}
                />
              </div>
              <div className={styles.formWrapperItem}>
                <FormText
                  name="transitNumber"
                  label={fields.transitLabel}
                  className=""
                  isReadOnly={!isMasterUser}
                />
              </div>
              <div className={styles.formWrapperItem}>
                <FormText
                  name="institutionNumber"
                  label={fields.institutionLabel}
                  className=""
                  isReadOnly={!isMasterUser}
                />
              </div>
              <div className={styles.formWrapperItem}>
                <FormText
                  name="lenderCode"
                  label={fields.lenderCodeLabel}
                  className=""
                  isReadOnly={!isMasterUser}
                />
              </div>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                loading={isLoading?.isLoading && isLoading?.isFlyout}
                responsive
                type="submit"
                name={'submitButton'}
                disabled={isReadOnlyUser}
                text={fields.submitButtonLabel}
                ariaText={fields.submitButtonLabel}
              />
              <Button
                responsive
                type="button"
                secondaryButton
                name={`cancelButton`}
                onClick={onCancelCallback}
                text={fields.cancelButtonLabel}
                ariaText={fields.cancelButtonLabel}
              />
            </div>
          </form>
        </FormProvider>
      </Scrollbars>
    </>
  );
};

export default ArrearsHistoricalFlyout;
