import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useFeature } from 'flagged';
import i18n from 'i18next';

import { CorrespondencePayload, getExternalStatus } from '@hobt/claim-domain';

import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import { dropdownStyles } from 'Feature/Claims/components/Details/AdjudicationCardHeader/AdjudicationAssignmentStatus/dropdownStyles';
import { colourStyles } from 'Feature/Claims/components/Details/AdjudicationCardHeader/AdjudicationAssignmentStatus/AdjudicationStatus/StatusStyles';
import { SitecoreContentContext } from 'Feature/Claims/components/Details';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';

import { StatusFields, ToastErrorType } from './types';
import useAdjudicationStatusFunctions from './useAdjudicationStatusFunctions';
import { StatusChangeReason } from './StatusChangeReasonFlyout';
import styles from './styles.module.scss';

export const AdjudicationStatus: React.FC<{}> = () => {
  const { claimData, isMasterUser } = useHBTFormContext();
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);
  const { fields } = useContext(SitecoreContentContext);

  const colorIndicatorInternal: Map<number, string> = new Map<number, string>([
    [1, '#CCCCCC'],
    [2, '#00405C'],
    [3, '#F89100'],
    [4, '#F89100'],
    [5, '#F89100'],
    [6, '#DA291C'],
    [7, '#447F00'],
    [8, '#447F00'],
    [9, '#CCCCCC'],
    [10, '#F89100'],
    [11, '#447F00'],
    [12, '#DA291C'],
    [13, '#F89100'],
    [14, '#447F00'],
    [15, '#F89100']
  ]);

  const options: StatusFields[] = fields.claimsStatusList?.fields?.listItems?.map(
    (item: SitecoreListItem) => ({
      label: item.fields?.itemName?.value,
      value: item.fields.itemValue?.value?.toString(),
      color: colorIndicatorInternal.get(Number(item.fields.itemValue?.value) ?? 0)
    })
  );

  const defaultClaimStatus: StatusFields | undefined = options?.find((item) => {
    return (
      Number(item.value) ===
      (siteTypeIsInternal
        ? claimData?.profile?.claimStatus?.code
        : getExternalStatus(claimData?.profile?.claimStatus?.code))
    );
  });

  const [status, setStatus] = useState(defaultClaimStatus);

  const {
    hookForm,
    showStatusToast,
    showStatusMessageToast,
    showStatusErrorCode,
    showStatusErrorToast,
    statusChangeShowSideDrawer,
    cancelFlyout,
    handleSubmit,
    onCancelToast,
    onSubmitErrors,
    setStatusChangeShowSideDrawer
  } = useAdjudicationStatusFunctions(() => setStatus(defaultClaimStatus));

  useEffect(() => {
    if (claimData?.profile?.claimStatus?.code !== Number(status?.value)) {
      setStatus(defaultClaimStatus);
    }
  }, [claimData?.profile?.claimStatus?.code]);

  const statusChange = (e: StatusFields | null) => {
    if (e !== null) {
      setStatus(e);
      setStatusChangeShowSideDrawer(true);
    }
  };

  const proceedSubmit = (correspondencePayload: CorrespondencePayload) => {
    handleSubmit(correspondencePayload, Number(status?.value));
  };

  return (
    <>
      <ToastNotification
        type={showStatusToast}
        isActive={showStatusMessageToast}
        title={i18n.t(
          showStatusToast === ApplicationStates.SUCCESS
            ? 'Globals-Toast-Success-Title'
            : showStatusToast === ApplicationStates.ERROR
            ? 'Globals-Toast-Error-Title'
            : ''
        )}
        content={{
          value: i18n.t(
            showStatusToast === ApplicationStates.SUCCESS
              ? 'Globals-Toast-Success-Changes-Saved'
              : showStatusToast === ApplicationStates.ERROR
              ? 'Globals-UnknownErrorMessage'
              : ''
          )
        }}
        onCloseCallback={onCancelToast}
      />
      <ToastNotification
        type={ApplicationStates.ERROR}
        isActive={showStatusErrorToast}
        title={i18n.t('Globals-Toast-Error-Title')}
        content={{
          value: i18n.t(
            showStatusErrorCode === ToastErrorType.CannotAutoApprove
              ? 'Errors-HBT_ERR_5034'
              : showStatusErrorCode === ToastErrorType.CannotUpdateToAutoApprove
              ? 'Errors-HBT_ERR_5035'
              : showStatusErrorCode === ToastErrorType.AutoAdjudicationDeclined
              ? 'Errors-HBT_ERR_5036'
              : ''
          )
        }}
        onCloseCallback={onCancelToast}
      />
      <label htmlFor="setStatus" className={styles.inlineLabel}>
        <span className={styles.labelText}>
          <Text field={fields.statusLabel} />
        </span>
        <Select
          className={styles.assignmentDropdown}
          name="setStatus"
          id="setStatus"
          aria-label="setStatus"
          styles={{ ...colourStyles, ...dropdownStyles }}
          placeholder={fields.setClaimStatusLabel.value ?? ''}
          maxMenuHeight={294}
          value={status}
          isDisabled={!isMasterUser ?? true}
          options={options}
          onChange={statusChange}
          noOptionsMessage={() => fields.noOptionsText.value ?? ''}
        />
      </label>
      <fieldset className={styles.statusChangereasonSideDrawer}>
        <SideDrawer isActive={statusChangeShowSideDrawer} handleOutsideClick={cancelFlyout}>
          <StatusChangeReason
            onCancelCallback={cancelFlyout}
            onErrorCallback={onSubmitErrors}
            proceedSubmit={proceedSubmit}
            hookForm={hookForm}
            fields={fields.statusChangeFlyout.fields}
          />
        </SideDrawer>
      </fieldset>
    </>
  );
};
