import * as React from 'react';

import { DefaultRequestStatus } from 'Foundation/Api';

import { StatusIndicatorProp } from './StatusIndicator.type';

export const StatusIndicator: React.FC<StatusIndicatorProp> = ({ type }) => (
  <span
    className={`status-indicator status-indicator-${DefaultRequestStatus[type]
      .replace(/CMHC/g, 'Cmhc')
      .replace(/[A-Z]/g, `-$&`)
      .toLowerCase()}`}
  />
);
