import React, { memo } from 'react';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import HbtHeaderPlaceholderProps from './types';

const HbtHeaderPlaceHolderComponent: React.FC<HbtHeaderPlaceholderProps> = ({
  rendering
}: HbtHeaderPlaceholderProps) => {
  const sitecoreContextFactory = useSitecoreContext();

  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  return (
    <>
      {sitecoreContext.isPortfolioSite ? (
        <Placeholder name="hbt-shared-v2-header" rendering={rendering} />
      ) : (
        <Placeholder name="hbt-shared-header" rendering={rendering} />
      )}
    </>
  );
};

export default memo(HbtHeaderPlaceHolderComponent);
