import React, { useState, useMemo } from 'react';
import i18n from 'i18next';
import { Link } from '@sitecore-jss/sitecore-jss-react';

import HelpSupportSideMenuLinkProps from 'Feature/HelpSupport/models/HelpSupportSideMenuLink';
import { HelpTopic } from 'Feature/HelpSupport/models/HelpSupportHelpTopic';
import { tracePath } from 'Feature/HelpSupport/components/menuFunctions';

import styles from './styles.module.scss';

const HelpSupportSideMenuLink: React.FC<HelpSupportSideMenuLinkProps> = ({
  topic,
  pathArray,
  level
}) => {
  const isOnPathNum = useMemo(
    () =>
      tracePath(
        topic.path.split('/').filter((item) => item !== ''),
        pathArray,
        true
      ),
    []
  );
  const childrenLevel = level + 1;

  const [expanded, setExpanded] = useState<boolean>(isOnPathNum > level);

  return (
    <li>
      <div
        className={`${styles.menuItem} ${
          isOnPathNum > level && topic.children?.length > 0 ? styles.parentActive : ''
        }`.trim()}
      >
        <Link
          field={{ text: topic.name, href: topic.path }}
          className={isOnPathNum > level ? styles.active : ''}
        />
        {topic.children?.length > 0 && (
          <button
            data-testid="toggleSubMenu"
            onClick={() => setExpanded(!expanded)}
            aria-label={`${i18n.t(
              expanded ? 'Global-Collapse-Side-Nav-Aria' : 'Global-Expand-Side-Nav-Aria'
            )} ${topic.name}`}
          >
            <span className="material-icons-outlined" data-testid="toggleIcon">
              {expanded ? 'expand_less' : 'expand_more'}
            </span>
          </button>
        )}
      </div>

      {topic.children?.length > 0 && expanded && (
        <ul>
          {topic.children?.map((subtopic: HelpTopic, idx) => (
            <HelpSupportSideMenuLink
              level={childrenLevel}
              pathArray={pathArray}
              topic={subtopic}
              key={idx}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default HelpSupportSideMenuLink;
