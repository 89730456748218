import * as React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import HelpTopicsComponentProps from 'Feature/PageComponents/models/HelpTopicsComponentProps';

const HelpTopicsComponent = (props: HelpTopicsComponentProps) => (
  <div>
    <p>HelpTopicsComponent Component</p>
    <Text field={props.fields.heading} />
  </div>
);

export default HelpTopicsComponent;
