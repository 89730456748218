/* eslint-disable */
import { useCallback, useState, useEffect, SyntheticEvent } from "react";

export const useIsOpenDropdown = () => {

    const [openedByKeyboard, setOpenedByKeyboard] = useState(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    
    const handleMenuClose = useCallback(() => {
        setOpenedByKeyboard(false);
        setIsOpen(false);
    }, []);

    useEffect(() => {
        if (openedByKeyboard) {
        setIsOpen(true);
        }
    }, [openedByKeyboard]);

    const handleMenuOpen = useCallback((event: SyntheticEvent | KeyboardEvent) => {
        if (
          (event as KeyboardEvent).key === 'ArrowDown' ||
          (event as KeyboardEvent).key === 'Enter'
        ) {
          setOpenedByKeyboard(true);
        } else {
          setIsOpen(true);
        }
      }, []);

    return {
        isOpen,
        openedByKeyboard,
        handleMenuClose,
        handleMenuOpen
    };
};