export const getDifference = <T extends number | bigint>(
  u: T | undefined | null,
  v: T | undefined | null
): number | undefined => {
  return u != null && v != null ? u - v : undefined;
};

export const getDifferenceDateInDays = (
  fromDate?: string,
  toDate?: string,
  absolute: boolean = false
): number | undefined => {
  if (fromDate == null || toDate == null) {
    return undefined;
  }
  try {
    const difference = Math.ceil(
      (new Date(fromDate).valueOf() - new Date(toDate).valueOf()) / (1000 * 3600 * 24)
    );
    if (absolute) {
      return Math.abs(difference);
    }
    return difference;
  } catch (e) {
    return undefined;
  }
};
