import React, { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import iconSvgMapping from './iconSvgMapping';
import styles from './styles.module.scss';
import { HbtIconProps, HbtIconSize } from './types';

/**
 * @param {HbtIconProps} props - list out the props of the component.
 * @param {string} props.type - the type of icon to show.
 * @param {HbtIconSize} props.size - the size of the component.
 * @returns {JSX.Element} Rendered the icon component.
 */

/**
 * HbtIcon component
 *
 * This is the HbtIcon component written using storybook. It loads different SVG icon.
 */

export const HbtIcon: FC<HbtIconProps> = ({ type, size, className, testID = '' }: HbtIconProps) => {
  const iconSizeMapping: { [key: string]: string } = {
    [HbtIconSize.SMALL]: styles.smIcon,
    [HbtIconSize.MEDIUM]: styles.mdIcon,
    [HbtIconSize.LARGE]: styles.lgIcon,
    [HbtIconSize.X_LARGE]: styles.xlIcon
  };

  return iconSvgMapping[type!] ? (
    <SvgIcon
      inheritViewBox
      data-testid={`core-svg-icon${testID}`}
      component={iconSvgMapping[type!]}
      className={`${iconSizeMapping[size!] || styles.mdIcon} ${className}`}
    />
  ) : null;
};
