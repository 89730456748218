import React from 'react';
import { ClaimTypeCode, ClaimTypeIndicator, DraftClaimStatus } from '@hobt/claim-domain';
import { ImageField } from '@sitecore-jss/sitecore-jss-react/types/components/Image';
import { MenuItemProps } from 'Components/Common/ActionMenu/types';
import { Address, PendingCondition } from 'Components/Common/Api/Common';
import { SortDirection } from 'Components/Common/Api/CommonEnums';
import { TabItemProps } from 'Components/Navigation/Tabs';
import SitecoreListItem, { SitecoreDropdownList } from 'Constants/Types/SitecoreListItem';
import { CancelClaimProps } from 'Feature/Claims/components/Details/AdjudicationCardHeader/CancelClaim/types';

export type ClaimsInventoryPropsFields = {
  ageLabel?: FieldValue;
  all: FieldValue;
  approved: FieldValue;
  cancelButtonLabel: FieldValue;
  cancelClaimFlyout: CancelClaimProps;
  cancelEllipsisBtn: FieldValue;
  claimIndicatorLabel: FieldValue;
  claimPayableLabel?: FieldValue;
  claimStatusLabel: FieldValue;
  claimStatusTypes: SitecoreDropdownList;
  claimTypeIndicator?: SitecoreDropdownList;
  claimsOfficerNameLabel?: FieldValue;
  createNew: FieldValue;
  daysInInventoryLabel?: FieldValue;
  declinedCancelled: FieldValue;
  delete: FieldValue;
  deleteClaimModal?: DeleteClaimModalProps;
  deleteIcon: ImageField;
  draftTableAccessibilityText: FieldValue;
  drafts: FieldValue;
  filter?: SitecoreDropdownList;
  filterLabel?: FieldValue;
  heading: FieldValue;
  inProgress: FieldValue;
  inReview?: FieldValue;
  inventoryTypeDropdownLabel: FieldValue;
  languageLabel?: FieldValue;
  lastSaved: FieldValue;
  lastUpdatedLabel?: FieldValue;
  lenderNameLabel: FieldValue;
  lenderReferenceLabel: FieldValue;
  loanLabel: FieldValue;
  mainSupp: FieldValue;
  onlyShowLabel: FieldValue;
  pendingCodeLabel?: FieldValue;
  primaryBorrowerNameLabel: FieldValue;
  processInEmili: FieldValue;
  processInMICSLabel?: FieldValue;
  processinMICS?: FieldValue;
  propertyAddressLabel?: FieldValue;
  provinceLabel?: FieldValue;
  receivedLabel?: FieldValue;
  refundRequest: FieldValue;
  rowActionLabel: FieldValue;
  submittedDateLabel: FieldValue;
  tableAccessibilityText: FieldValue;
  transitNumber: FieldValue;
  typeOfClaimLabel: FieldValue;
  typeofClaim?: SitecoreDropdownList;
  unassigned: FieldValue;
  userIDLabel?: FieldValue;
  userName: FieldValue;
};

export enum ErrorType {
  ClaimOutOfOrder
}

export type DeleteClaimModalProps = {
  fields: {
    heading: FieldValue;
    content: FieldValue;
    acceptButton: FieldValue;
    cancelButton: FieldValue;
  };
};

export interface ClaimsInventoryProps {
  fields: ClaimsInventoryPropsFields;
}

export type ProfileProps = {
  claimStatus: {
    code: number;
  };
};

export interface ClaimsInventoryRecord {
  claimID: number;
  draftClaimRecordID: string;
  approvedLenderCode?: string;
  claimDecisionDate?: string;
  approvedLenderEnglishName?: string;
  approvedLenderFrenchName?: string;
  approvedLenderReferenceNumber?: string;
  approvedLenderTransitNumber?: string;
  claimStatus?: number;
  claimTypeCode?: number;
  claimTypeIndicator?: number;
  cmhcLoanAccountNumber?: number;
  correspondenceLanguage?: number;
  firstName?: string;
  lastName?: string;
  newExternalAttachmentFlag?: boolean;
  newExternalCommentFlag?: boolean;
  newInternalAttachmentFlag?: boolean;
  newInternalCommentFlag?: boolean;
  ownerFullName?: string;
  pendingConditions?: PendingCondition[];
  primaryBorrowerFirstName?: string;
  primaryBorrowerLastName?: string;
  profile: ProfileProps;
  propertyAddress?: Address;
  recordCreatedTimestamp?: string;
  claimReceivedDate?: string;
  recordCreatedUserFullName?: string;
  recordCreatedUserID?: string;
  recordUpdatedTimestamp?: string;
  recordUpdatedUserFullName?: string;
  statusCode?: DraftClaimStatus;
  typeOfClaim?: number;
}

export const ExternalClaimTypeIndicator: Record<ClaimTypeIndicator, string> = {
  [ClaimTypeIndicator.Main]: 'Main',
  [ClaimTypeIndicator.Supplementary]: 'Supplementary'
};

export const ExternalTypeOfClaim: Record<ClaimTypeCode, string> = {
  [ClaimTypeCode.EnhancedClaimService]: 'Enhanced Claim Service',
  [ClaimTypeCode.WalkAway]: 'Walk Away',
  [ClaimTypeCode.DeficiencySale]: 'Deficiency Sale',
  [ClaimTypeCode.AssignmentOfMortgage]: 'Assignment Mortgage',
  [ClaimTypeCode.TitleTransfer]: 'Title Transfer'
};

export const ExternalTypeOfClaimFr: Record<ClaimTypeCode, string> = {
  [ClaimTypeCode.EnhancedClaimService]: 'Service amélioré des demandes de règlement',
  [ClaimTypeCode.WalkAway]: 'Abandon',
  [ClaimTypeCode.DeficiencySale]: 'Déficit résiduaire',
  [ClaimTypeCode.AssignmentOfMortgage]: 'Cession d’hypothèque',
  [ClaimTypeCode.TitleTransfer]: 'Transfert de titre'
};

export enum ClaimsInventoryTabEnum {
  Unassigned = 'unassigned',
  All = 'all',
  InProgress = 'inReview',
  Approved = 'approved',
  CancelledOrDeclined = 'declinedCancelled',
  ProcessInMics = 'processInMics',
  Draft = 'activeDrafts'
}

export type TableHeaderProps = {
  fields: ClaimsInventoryPropsFields;
  currentTab: TabItemProps;
  hideRowActionMenu?: boolean;
  onSort: (field: string) => void;
  getSortDirectionForField: (field: string) => SortDirection;
};

export type ClaimStatusType = {
  value: number;
  text: string;
  className: string;
};

export type TableRowProps = {
  claimStatusTypes: ClaimStatusType[];
  claimType?: SitecoreListItem[];
  claimTypeIndicator?: SitecoreDropdownList;
  currentTab: TabItemProps;
  emptyValuePlaceholder: string;
  isDraftClaimTab?: boolean;
  hideActionButtonCell?: boolean;
  onRowClick: (e: React.MouseEvent<HTMLElement>) => void;
  row: ClaimsInventoryRecord;
  rowActionMenu: MenuItemProps[];
};

export type FinalClaimCount = {
  key: string;
  query?: string;
  count: number;
};
