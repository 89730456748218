export const getHeaderFileName = (fileName: string, response: any): string => {
  const contentDisposition = response?.headers['content-disposition'];
  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)|filename="?(.*?)"?$/i);
    if (filenameMatch) {
      fileName = decodeURIComponent(filenameMatch[1] || filenameMatch[2] || '').trim();
    } else {
      console.warn('Filename could not be extracted from Content-Disposition header.');
    }
  } else {
    console.warn('Content-Disposition header is missing.');
  }
  return fileName;
};
