import React, { FC, PropsWithChildren } from 'react';
import styles from './styles.module.scss';
import { CardBodyProps } from './types';

export const CardBody: FC<CardBodyProps> = ({
  children,
  className
}: PropsWithChildren<CardBodyProps>) => {
  return <div className={`${styles.cardBody} ${className}`}>{children}</div>;
};
