import React, { FC, PropsWithChildren } from 'react';
import styles from './styles.module.scss';
import { CardFooterProps } from './types';

export const CardFooter: FC<CardFooterProps> = ({
  separator,
  children,
  className
}: PropsWithChildren<CardFooterProps>) => {
  return (
    <div
      className={`${styles.cardFooter} ${separator ? styles.cardFooterSeparator : ''} ${
        className || ''
      }`.trim()}
    >
      {children}
    </div>
  );
};
