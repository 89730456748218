import { ImageField } from '@sitecore-jss/sitecore-jss-react/types/components/Image';

import { ValidationResultStatusCode } from '@hobt/constants';

import { SitecoreDropdownList } from 'Constants/Types/SitecoreListItem';

export default interface ArrearsFileListProps {
  fields: ArrearsFileListPropsFields;
}
export interface DropdownOptionType {
  label: string;
  value: string;
}

export interface DropdownOptionFields {
  fields: DropdownField;
}

interface DropdownField {
  itemName: FieldValue;
  itemValue: FieldValue;
}
export interface ActionType {
  name?: string;
  action: string;
  option: DropdownOptionType | undefined;
}

export interface ModuleFiTransitMapping {
  [key: string]: FiTransitMapping;
}

export interface FiTransitMapping {
  [key: string]: Transits;
}

export interface Transits {
  allowedTransitNumbers: string[];
  roleCode: string;
}

export interface ArrearsFileListPropsFields {
  businessValidationResult: FieldValue;
  deleteArrearsFile: DeleteArreasFileProps;
  datereAssesed: FieldValue;
  dateSubmitted: FieldValue;
  deleteIcon: ImageField;
  deleteLabel: FieldValue;
  downloadBtn: FieldValue;
  errorIcon: ImageField;
  fileName: FieldValue;
  helpText: FieldValue;
  lenderName: FieldValue;
  location: FieldValue;
  pageTitle: FieldValue;
  reassessFile: FieldValue;
  remarks: FieldValue;
  replaceArrearsFile: FieldValue;
  reportingPeriodLabel: FieldValue;
  searchLabel: FieldValue;
  submitBtn: FieldValue;
  transit: FieldValue;
  uploadedBy: FieldValue;
  validationStatusCodes: SitecoreDropdownList;
  validationReportNotAvailableTitle: FieldValue;
  validationReportNotAvailableMessage: FieldValue;
}

export type DeleteArreasFileProps = {
  fields: {
    heading: FieldValue;
    content: FieldValue;
    acceptButton: FieldValue;
    cancelButton: FieldValue;
  };
};
export interface ArrearsFileListInventoryRecords {
  enableApproval?: boolean;
  records?: ArrearsFileListInventoryRecordsItems[];
}

export interface ArrearsFileListInventoryRecordsItems {
  submissionContactName: string;
  submissionRemarkText?: string;
  submissionID: string;
  arrearsFileID: string;
  locationName?: string;
  transitNumber: string;
  validationResult?: Object;
  approvedLenderEnglishName: string;
  approvedLenderFrenchName: string;
  attachment?: AttachmentDetails;
  recordCreatedTimestamp: string;
  recordUpdatedTimestamp: string;
  statusUpdatedTimestamp: string;
  assessmentUpdatedTimestamp?: string;
  businessValidationStatusCode: ValidationResultStatusCode;
}

export interface AttachmentDetails {
  fileName: string;
  documentID: string;
}

export interface DocumentDetails {
  fileName: string;
  documentID: string;
  latestReportFlag: boolean;
  reportingPeriodID: string;
}

export interface DocumentEntity {
  documentID: string;
  documentEntityJson: DocumentEntityItem;
}

export interface DocumentEntityItem {
  documentLink: string;
  fileName: string;
  fileSize: number;
  documentStatus: number;
  uniqueFileName: string;
  recordUpdatedUserID: string;
  recordCreatedTimestamp: string;
  recordUpdatedTimestamp: string;
}

export interface ReportingPeriodField {
  reportingPeriod: string;
  reportingPeriodID: string;
}

export enum DropdownFilterQueryParameter {
  REPORTING_PERIOD_ID = 'ReportingPeriodID'
}

export enum ToastType {
  APPROVAL = 'Approval',
  DELETED = 'Deleted',
  FETCH_REPORTING_PERIOD = 'Fetch-Reporting-Period',
  REASSESS = 'Reassess',
  SUBMITTED = 'Submitted'
}

export const StatusClassMapping: Record<ValidationResultStatusCode, string> = {
  [ValidationResultStatusCode.Pass]: 'success',
  [ValidationResultStatusCode.Warning]: 'success',
  [ValidationResultStatusCode.Pending]: 'warning',
  [ValidationResultStatusCode.Fail]: 'error'
};
