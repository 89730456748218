import { HttpResponseStatusCodes } from '@hobt/constants';
import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { config } from '../../../../config';

const useArrearsHistoricalApiClient = (authContext: any, apiClientConfig: ApiClientConfig) => {
  const updateStatus = async (payload: any) => {
    const updateStatusRoute: string = config.arrearsApi.urls.updateStatus;

    try {
      const response = await ApiClient(authContext, apiClientConfig).postWithAuth(
        updateStatusRoute,
        payload,
        undefined
      );

      return response.data.statusCode;
    } catch (error) {
      return HttpResponseStatusCodes.BadRequest;
    }
  };

  return { updateStatus };
};

export default useArrearsHistoricalApiClient;
