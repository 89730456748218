import i18n from 'i18next';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';

import { LanguageShort } from '@hobt/constants';
import { loanDetailSchema } from '@hobt/claim-domain';
import { hbtResolver } from '@hobt/schema-validator';

import { Button } from 'Components/Common/Button';
import { currencyFormat, getCurrencyFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormText from 'Components/Inputs/FormText';
import FormNumber from 'Components/Inputs/FormNumber';
import FormDropdown from 'Components/Inputs/FormDropdown';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';

import {
  useHBTFormActionsContext,
  useHBTFormContext
} from 'Feature/Claims/components/HBTFormContext';
import { useToastNotification } from 'Feature/CommonComponents/ContentComponents/ToastNotification/ToastNotificationHook';
import { LoaderAnimation } from 'Feature/CommonComponents/UserControls';

import { useAuthenticationContext } from 'Foundation/Authentication';

import { getUnderwritingLoanStatus, updateFinalClaim } from './LoanInformationService';

import { LoanInformationPropsFields } from './types';
import styles from './styles.module.scss';

const LoanInformation: FC<
  LoanInformationPropsFields & {
    save: FieldValue;
    discard: FieldValue;
    isTabLoading: boolean;
    setIsTabLoading: Function;
  }
> = ({
  titleLoanInformation,
  loanStatus,
  productCodeAndDescription,
  productCodeAndDescriptionList,
  lenderCode,
  lenderName,
  loanTermPeriod,
  loanAmortizationPeriod,
  loanStatusList,
  propertyLendingValue,
  ltvRatio,
  loanInterestRate,
  lenderLoanAmount,
  dwellingTypeCodeAndDescription,
  dwellingList,
  loanPremiumAmount,
  tenureTypeCodeAndDescription,
  tenureList,
  save,
  discard,
  isTabLoading,
  setIsTabLoading,
  toastMessageSuccessfulSave,
  toastMessageFailedSave
}) => {
  const authenticationContext = useAuthenticationContext();
  const { uuid, claimData, isReadOnlyView, updateClaimData, setLoadingStatus } =
    useHBTFormContext();
  const { disableEditMode, enableEditMode } = useHBTFormActionsContext();
  const { cmhcLoanAccountNumber, lenderName: approvedLenderName, loanDetail } = claimData;
  const { dispatch } = useToastNotification();

  const [underwritingLoanStatus, setUnderwritingLoanStatus] = useState<string | null>(null);

  useEffect(() => {
    if (cmhcLoanAccountNumber != null) {
      setIsTabLoading(true);
      getUnderwritingLoanStatus(cmhcLoanAccountNumber, authenticationContext)
        .then((loanStatusCode: string) => {
          setTimeout(() => {
            setIsTabLoading(false);
          }, 3000);
          setUnderwritingLoanStatus(loanStatusCode);
        })
        .catch((_err) => {
          setIsTabLoading(false);
        });
    }
  }, []);

  const defaultValuesData = {
    loanStatus: 'N/A',
    approvedLenderCode: loanDetail?.approvedLenderCode ?? 'N/A',
    lenderName:
      i18n.language === LanguageShort.English
        ? approvedLenderName?.loanDetailApprovedLenderEnglishName ?? 'N/A'
        : approvedLenderName?.loanDetailApprovedLenderFrenchName ?? 'N/A',
    loanTermPeriod: loanDetail?.loanTermPeriod ?? 'N/A',
    loanAmortizationPeriod: loanDetail?.loanAmortizationPeriod ?? 'N/A',
    propertyValueAmount: loanDetail?.propertyValueAmount ?? 'N/A',
    loanToValueRatio: loanDetail?.loanToValueRatio ?? 'N/A',
    underwritingInterestRatePercent: loanDetail?.underwritingInterestRatePercent ?? 'N/A',
    lenderLoanAmount: loanDetail?.lenderLoanAmount ?? 'N/A',
    loanPremiumAmount: loanDetail?.loanPremiumAmount ?? 'N/A',
    dwellingTypeCode: loanDetail?.dwellingTypeCode ?? '',
    tenureTypeCode: loanDetail?.tenureTypeCode ?? '',
    productCode: loanDetail?.productCode ?? ''
  };

  const hookForm = useForm({
    resolver: hbtResolver(loanDetailSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: defaultValuesData
  } as Record<string, any>);

  useEffect(() => {
    hookForm.setValue(
      'loanStatus',
      loanStatusList?.fields?.listItems?.find(
        (item: SitecoreListItem) => item?.fields?.itemValue?.value === underwritingLoanStatus
      )?.fields?.itemName?.value ?? 'N/A'
    );
  }, [isTabLoading]);

  useEffect(() => {
    if (underwritingLoanStatus != null) {
      hookForm.setValue(
        'loanStatus',
        loanStatusList?.fields?.listItems?.find(
          (item: SitecoreListItem) => item?.fields?.itemValue?.value === underwritingLoanStatus
        )?.fields?.itemName?.value ?? 'N/A'
      );
    }
  }, [underwritingLoanStatus]);

  const { isDirty } = hookForm.formState;
  const [backupState, setBackupState] = useState<object | null>(null);

  useEffect(() => {
    if (!isDirty) {
      setBackupState(hookForm.getValues());
    } else {
      enableEditMode();
    }
  }, [isDirty]);

  const cancel = () => {
    disableEditMode();
    hookForm.reset(backupState!);
  };

  const confirm = async () => {
    hookForm.unregister(['lenderName', 'loanStatus']);

    setLoadingStatus?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description'
    });
    const payload = hookForm.getValues();
    const finalClaimData = await updateFinalClaim(
      uuid,
      { loanDetail: payload },
      authenticationContext
    );

    setLoadingStatus?.({
      isLoading: false
    });

    if (finalClaimData == null) {
      dispatch({ type: 'error', value: toastMessageFailedSave?.value });
    } else if (updateClaimData != null) {
      disableEditMode();
      hookForm.reset({
        ...backupState,
        ...hookForm.getValues(),
        ...finalClaimData?.loanDetail,
        lenderName:
          i18n.language === LanguageShort.English
            ? finalClaimData?.lenderName?.loanDetailApprovedLenderEnglishName ?? 'N/A'
            : finalClaimData?.lenderName?.loanDetailApprovedLenderFrenchName ?? 'N/A'
      });
      dispatch({ type: 'success', value: toastMessageSuccessfulSave?.value });

      updateClaimData();
    }
  };

  if (isTabLoading) {
    return <LoaderAnimation />;
  }

  return (
    <FormProvider {...hookForm}>
      <form className="form" id="loanInformationCard" onSubmit={(e) => e.preventDefault()}>
        <h2 className={'header3'}>{titleLoanInformation.value}</h2>
        <fieldset disabled={isReadOnlyView}>
          <div className={styles.loanInfo}>
            <FormText name={'loanStatus'} label={loanStatus} className={''} isReadOnly={true} />
            <FormDropdown
              name={'productCode'}
              label={productCodeAndDescription}
              className={''}
              options={productCodeAndDescriptionList.fields.listItems}
              setAsNumber={false}
            />
            <FormText name={'approvedLenderCode'} label={lenderCode} className={''} />
            <FormText name={'lenderName'} label={lenderName} className={''} isReadOnly={true} />
            <FormText name={'loanTermPeriod'} label={loanTermPeriod} className={''} />
            <FormText
              name={'loanAmortizationPeriod'}
              label={loanAmortizationPeriod}
              className={''}
            />
            <FormNumber
              name={'propertyValueAmount'}
              label={propertyLendingValue}
              className={''}
              formatProps={getCurrencyFormat(
                hookForm.getValues('propertyValueAmount'),
                { ...currencyFormat, thousandSeparator: true },
                i18n.language as LanguageShort
              )}
            />
            <FormNumber
              name={'loanToValueRatio'}
              label={ltvRatio}
              className={''}
              formatProps={{}}
            />
            <FormNumber
              name={'underwritingInterestRatePercent'}
              label={loanInterestRate}
              className={''}
              formatProps={{}}
            />
            <FormNumber
              name={'lenderLoanAmount'}
              label={lenderLoanAmount}
              className={''}
              formatProps={getCurrencyFormat(
                hookForm.getValues('lenderLoanAmount'),
                { ...currencyFormat, thousandSeparator: true },
                i18n.language as LanguageShort
              )}
            />
            <FormDropdown
              name={'dwellingTypeCode'}
              label={dwellingTypeCodeAndDescription}
              className={''}
              options={dwellingList.fields.listItems}
              setAsNumber={false}
            />
            <FormNumber
              name={'loanPremiumAmount'}
              label={loanPremiumAmount}
              className={''}
              formatProps={getCurrencyFormat(
                hookForm.getValues('loanPremiumAmount'),
                { ...currencyFormat, thousandSeparator: true },
                i18n.language as LanguageShort
              )}
            />
            <FormDropdown
              name={'tenureTypeCode'}
              label={tenureTypeCodeAndDescription}
              className={''}
              options={tenureList.fields.listItems}
              setAsNumber={false}
            />
            <div className={styles.buttonTray}>
              <Button name={'loaninfo-save'} text={save} onClick={confirm} disabled={!isDirty} />
              <Button
                name={'loaninfo-discard'}
                text={discard}
                onClick={cancel}
                disabled={!isDirty}
                secondaryButton={true}
              />
            </div>
          </div>
        </fieldset>
      </form>
    </FormProvider>
  );
};

export default LoanInformation;
