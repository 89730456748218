import * as React from 'react';
import { PropsWithChildren } from 'react';
import styles from './styles.module.scss';
import { CardHeaderProps } from './types';

export const CardHeader: React.FC<CardHeaderProps> = ({
  separator,
  children
}: PropsWithChildren<CardHeaderProps>) => {
  return (
    <div className={`${styles.cardHeader} ${separator ? styles.cardHeaderSeparator : ''}`}>
      {children}
    </div>
  );
};
