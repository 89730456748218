/* eslint-disable */
import i18n from 'i18next';
import { useFeature } from 'flagged';
import React, { useContext, useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

import { OtherAdjustment, ClaimTypeIndicator } from '@hobt/claim-domain';
import { LanguageShort, OtherAdjustmentCode, UserRole, ModuleMapping } from '@hobt/constants';

import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { getCurrencyFormat, currencyFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormNumber from 'Components/Inputs/FormNumber';
import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';

import { FormFieldCell } from 'Components/Common/DataTable/DataTableRow/FormFieldCell';

import FormDatepicker from 'Components/Inputs/FormDatepicker';

import { AssessmentCalculationsContext } from 'Feature/Claims/components/Details/AssessmentCalculations';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';

import { useOtherAdjustmentFunctions } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/OtherAdjustments/useOtherAdjustmentsFunction';
import { Button } from 'Components/Common/Button';
import { CommentCell } from 'Components/Common/DataTable/DataTableRow/CommentCell';

import { DeleteRowCell } from 'Components/Common/DataTable/DataTableRow/DeleteRowCell';
import { isPositive } from 'Constants/Utils/isPositiveValue/isPositiveValue';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { SystemValueOverrideCell } from 'Components/Common/DataTable/DataTableRow/SystemValueOverrideCell';
import { ScrollableDataTableContainer } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/ScrollableDataTableContainer';

import { TOtherAdjustmentArray } from './types';

import styles from './styles.module.scss';

const OVERRIDABLE_CODES_LIST = [
  OtherAdjustmentCode.ProcessTime,
  OtherAdjustmentCode.LateFiling,
  OtherAdjustmentCode.LegalActionDelayFee
];
const DELETE_BUTTON_OMIT_CODES_LIST = [
  ...OVERRIDABLE_CODES_LIST,
  OtherAdjustmentCode.DEFSLOPT1DT,
  OtherAdjustmentCode.DEFSLOPT2DT
];
const OTHER_ADJUSTMENTS_ONLY_USER_INPUT_AMOUNT_LIST = [
  OtherAdjustmentCode.OtherAdjustment,
  OtherAdjustmentCode.OngoingResponsibilities,
  OtherAdjustmentCode.FireOtherInsurablePerilDamage,
  OtherAdjustmentCode.DelayInSale,
  OtherAdjustmentCode.OngoingLoansAdminRentAdjustment,
  OtherAdjustmentCode.OtherAdjustment2
];
const NUMBER_OF_DAYS_ENABLED_FIELD_OTHER_ADJUSTMENT_CODES = [
  OtherAdjustmentCode.ProcessTime,
  OtherAdjustmentCode.LateFiling,
  OtherAdjustmentCode.LegalActionDelayFee
];
const AMOUNT_FIELD_DISABLED_OTHER_ADJUSTMENT_CODES = [
  OtherAdjustmentCode.ProcessTime,
  OtherAdjustmentCode.DEFSLOPT1DT,
  OtherAdjustmentCode.DEFSLOPT2DT
];

const AMOUNT_OVERRIDE_FLAG_FIELD = 'amountOverrideFlag';
const NUMBER_OF_DAYS_OVERRIDE_FLAG_FIELD = 'numberOfDaysOverrideFlag';

export const AddIcon = () => <span className="material-icons icon--v-align-middle">add</span>;

export const OtherAdjustments: React.FC = () => {
  const { toCurrency, toLocaleDate } = useTranslationHelpers();
  const { isMasterUser, isMasterUserEditingPostAdjudicationClaim, isReadOnlyView, claimData } =
    useHBTFormContext();

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isClerkUser: boolean = isUserInRoles(
    ModuleMapping.default,
    [UserRole.Clerk],
    moduleRoleMapping
  );
  
  const { isEditing, sitecoreContent, toggleAddOtherAdjustments } = useContext(
    AssessmentCalculationsContext
    );
    const { control, setValue, watch, getValues } = useFormContext();
    const { fields, remove }: TOtherAdjustmentArray = useFieldArray({
      control,
      name: 'otherAdjustments'
    });
    
  const watchOtherAdjustments = watch('otherAdjustments');

  useEffect(() => {
    // @ts-ignore
    watchOtherAdjustments.forEach((adjustment, index, arr) => {
      arr[index] = {
        ...adjustment,
        amountOverrideFlag: parseBoolean(adjustment.amountOverrideFlag),
        numberOfDaysOverrideFlag: parseBoolean(adjustment.numberOfDaysOverrideFlag)
      };
    });
  }, [watchOtherAdjustments]);

  const { controllerEmptyObject, codeRowDictionary, displayPreviousValues, parseBoolean } =
    useOtherAdjustmentFunctions(sitecoreContent?.otherAdjustmentCardLabels?.fields);

  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const otherAdjustmentFields = sitecoreContent?.otherAdjustmentCardLabels?.fields;

  const isOtherAdjustmentCode500DateEditable = (code: string) =>
    code === OtherAdjustmentCode.ProcessTime && isMasterUserEditingPostAdjudicationClaim === true;

  const isOtherAdjustmentCode500AmountEditable = (code: string) =>
    code === OtherAdjustmentCode.ProcessTime && isMasterUser === false;

  const isOtherAdjustmentLateFilingOrLegalActionDelayFee = (code: string) =>
    OVERRIDABLE_CODES_LIST.slice(-2).includes(code as OtherAdjustmentCode);

  const allowNegativesForLateFilingOrLegalActionAdjustments = (code: string, idx: number) => {
    if (
      isOtherAdjustmentLateFilingOrLegalActionDelayFee(code) &&
      claimData?.claimTypeIndicator === ClaimTypeIndicator.Supplementary &&
      isEditing &&
      watchOtherAdjustments[idx]?.amountOverrideFlag === true
    ) {
      return false;
    }

    return undefined;
  };

  const onSystemUserValueToggle = (idx: number, amountFlag: boolean) => {
    const overrideFlag = amountFlag
      ? watchOtherAdjustments?.[idx]?.amountOverrideFlag ?? null
      : watchOtherAdjustments?.[idx]?.numberOfDaysOverrideFlag ?? null;
    const overrideFlagString = amountFlag
      ? AMOUNT_OVERRIDE_FLAG_FIELD
      : NUMBER_OF_DAYS_OVERRIDE_FLAG_FIELD;

    setValue(
      `otherAdjustments.${idx}.${overrideFlagString}`,
      overrideFlag == null ? true : !parseBoolean(overrideFlag)
    );
  };

  return (
    <>
      <div className={styles.content}>
        {!isEditing && siteTypeIsInternal && (
          <div className={styles.addOtherAdjustmentContainer}>
            <Button
              text={otherAdjustmentFields?.addOtherAdjustments ?? { value: '' }}
              ariaText={otherAdjustmentFields?.addOtherAdjustmentsAriaText ?? { value: '' }}
              name="addOtherAdjustment"
              contextualButton
              icon={AddIcon}
              onClick={toggleAddOtherAdjustments}
              type="button"
              disabled={isReadOnlyView}
            />
          </div>
        )}

        <fieldset className={styles.fieldset}>
          <ScrollableDataTableContainer className={styles.dataTable} name="otherAdjustments">
            <DataTable
              name="otherAdjustments"
              caption={otherAdjustmentFields?.addOtherAdjustments ?? {}}
              addTableWrapper={false}
            >
              <DataTableHeader>
                <DataTableHeaderItem
                  name="description"
                  displayText={otherAdjustmentFields?.description}
                />
                <DataTableHeaderItem
                  name="previous"
                  displayText={otherAdjustmentFields?.previousAdjusment}
                />
                <DataTableHeaderItem
                  name="acceptable"
                  displayText={otherAdjustmentFields?.acceptable}
                />
                <DataTableHeaderItem name="amount" displayText={otherAdjustmentFields?.amount} />
                <DataTableHeaderItem name="date" displayText={otherAdjustmentFields?.date} />
                <DataTableHeaderItem
                  name="numberOfDays"
                  displayText={otherAdjustmentFields?.numberOfDays}
                />
                <DataTableHeaderItem name="comments" />
                {isEditing && siteTypeIsInternal && (
                  <DataTableHeaderItem name="deleteButtonColumn" />
                )}
              </DataTableHeader>
              <DataTableBody zebra>
                {fields?.map((field, idx) => (
                  <DataTableRow
                    key={`otherAdjustments-row-${field.id}`}
                    name={`otherAdjustments-row-${field.id}`}
                  >
                    <Controller
                      control={control}
                      name={`otherAdjustments.${idx}.code`}
                      render={controllerEmptyObject}
                      defaultValue={field.code}
                    />
                    <Controller
                      control={control}
                      name={`otherAdjustments.${idx}.indexNumber`}
                      render={controllerEmptyObject}
                      defaultValue={field.indexNumber}
                    />
                    <Controller
                      control={control}
                      name={`otherAdjustments.${idx}.originalClaimSequenceNumber`}
                      render={controllerEmptyObject}
                      defaultValue={field.originalClaimSequenceNumber}
                    />
                    <Controller
                      control={control}
                      name={`otherAdjustments.${idx}.date`}
                      render={controllerEmptyObject}
                      defaultValue={field.date}
                    />
                    <TextCell
                      text={`${
                        (field.code && codeRowDictionary.get(field.code?.toString())) ?? ''
                      }`}
                      name={`otherAdjustments-row-${field.id}-desc`}
                    />
                    <TextCell
                      text={displayPreviousValues(field.code, field as OtherAdjustment)}
                      name={`otherAdjustments-row-${field.id}-previousAdjustmentDate`}
                    />
                    <TextCell
                      text={toLocaleDate(field.acceptableDate)}
                      name={`otherAdjustments-row-${field.id}-acceptableAdjustmentDate`}
                    />

                    {(OVERRIDABLE_CODES_LIST.indexOf(field.code as OtherAdjustmentCode) !== -1 &&
                      field.code !== OtherAdjustmentCode.ProcessTime) ||
                    (isMasterUser && field.code === OtherAdjustmentCode.ProcessTime) ? (
                      <>
                        <Controller
                          control={control}
                          name={`otherAdjustments.${idx}.amountOverrideFlag`}
                          render={controllerEmptyObject}
                          defaultValue={
                            watchOtherAdjustments[idx]?.amountOverrideFlag != null
                              ? parseBoolean(watchOtherAdjustments[idx].amountOverrideFlag)
                              : false
                          }
                        />
                        <SystemValueOverrideCell
                          name={`otherAdjustments-row-${field.id}-overridden-amount`}
                          systemValueOverridden={
                            parseBoolean(watchOtherAdjustments[idx]?.amountOverrideFlag) ||
                            OTHER_ADJUSTMENTS_ONLY_USER_INPUT_AMOUNT_LIST.includes(
                              watchOtherAdjustments[idx]?.code
                            )
                          }
                          switchLabelText={[
                            otherAdjustmentFields?.userValueLabel.value ?? '',
                            otherAdjustmentFields?.systemValueLabel.value ?? ''
                          ]}
                          className={
                            isEditing ? styles.overrideInputEditing : styles.overrideInputView
                          }
                          onToggle={() => onSystemUserValueToggle(idx, true)}
                          disabled={
                            !isEditing ||
                            (watchOtherAdjustments[idx]?.numberOfDaysOverrideFlag === true &&
                              isOtherAdjustmentLateFilingOrLegalActionDelayFee(field.code))
                          }
                        >
                          <FormNumber
                            name={`otherAdjustments.${idx}.amount`}
                            className={isEditing ? styles.isEditInput : styles.tableInput}
                            label={{
                              value: toCurrency(parseFloat(watch(`otherAdjustments.${idx}.amount`)))
                            }}
                            formatProps={getCurrencyFormat(
                              getValues(`otherAdjustments.${idx}.amount`),
                              { ...currencyFormat, thousandSeparator: true },
                              i18n.language as LanguageShort
                            )}
                            isReadOnly={
                              !isEditing ||
                              isOtherAdjustmentCode500AmountEditable(field.code) ||
                              !(
                                parseBoolean(watchOtherAdjustments[idx]?.amountOverrideFlag) ||
                                OTHER_ADJUSTMENTS_ONLY_USER_INPUT_AMOUNT_LIST.includes(
                                  watchOtherAdjustments[idx]?.code
                                )
                              )
                            }
                            tabIndex={!isEditing ? -1 : undefined}
                            allowNegative={allowNegativesForLateFilingOrLegalActionAdjustments(
                              field.code,
                              idx
                            )}
                            allowedValues={
                              isOtherAdjustmentLateFilingOrLegalActionDelayFee(field.code) &&
                              claimData?.claimTypeIndicator === ClaimTypeIndicator.Supplementary
                                ? isPositive
                                : undefined
                            }
                          />
                        </SystemValueOverrideCell>
                      </>
                    ) : isClerkUser ? (
                      <FormFieldCell>
                        {field.code === OtherAdjustmentCode.ProcessTime && (
                          <Controller
                            control={control}
                            name={`otherAdjustments.${idx}.amountOverrideFlag`}
                            render={controllerEmptyObject}
                            defaultValue={false}
                          />
                        )}
                        <FormNumber
                          name={`otherAdjustments.${idx}.amount`}
                          className={isEditing ? styles.isEditInput : styles.tableInput}
                          label={{
                            value: toCurrency(parseFloat(watch(`otherAdjustments.${idx}.amount`)))
                          }}
                          formatProps={getCurrencyFormat(
                            getValues(`otherAdjustments.${idx}.amount`),
                            { ...currencyFormat, thousandSeparator: true },
                            i18n.language as LanguageShort
                          )}
                          isReadOnly={
                            !isEditing ||
                            AMOUNT_FIELD_DISABLED_OTHER_ADJUSTMENT_CODES.includes(
                              field.code as OtherAdjustmentCode
                            )
                          }
                          tabIndex={!isEditing ? -1 : undefined}
                          allowedValues={
                            isOtherAdjustmentLateFilingOrLegalActionDelayFee(field.code) &&
                            claimData?.claimTypeIndicator === ClaimTypeIndicator.Supplementary
                              ? isPositive
                              : undefined
                          }
                        />
                      </FormFieldCell>
                    ) : (
                      <FormFieldCell>
                        {field.code === OtherAdjustmentCode.ProcessTime && (
                          <React.Fragment>
                            <Controller
                              control={control}
                              name={`otherAdjustments.${idx}.amountOverrideFlag`}
                              render={controllerEmptyObject}
                              defaultValue={
                                watchOtherAdjustments[idx]?.amountOverrideFlag != null
                                  ? parseBoolean(watchOtherAdjustments[idx].amountOverrideFlag)
                                  : false
                              }
                            />
                          </React.Fragment>
                        )}
                        <SystemValueOverrideCell
                          name={`otherAdjustments-row-${field.id}-overridden-amount`}
                          systemValueOverridden={
                            parseBoolean(watchOtherAdjustments[idx]?.amountOverrideFlag) ||
                            OTHER_ADJUSTMENTS_ONLY_USER_INPUT_AMOUNT_LIST.includes(
                              watchOtherAdjustments[idx]?.code
                            )
                          }
                          switchLabelText={[
                            otherAdjustmentFields?.userValueLabel.value ?? '',
                            otherAdjustmentFields?.systemValueLabel.value ?? ''
                          ]}
                          className={
                            isEditing ? styles.overrideInputEditing : styles.overrideInputView
                          }
                          onToggle={() => onSystemUserValueToggle(idx, true)}
                          disabled={
                            !isEditing ||
                            (watchOtherAdjustments[idx]?.numberOfDaysOverrideFlag === true &&
                              isOtherAdjustmentLateFilingOrLegalActionDelayFee(field.code))
                          }
                        >
                          <FormNumber
                            name={`otherAdjustments.${idx}.amount`}
                            className={isEditing ? styles.isEditInput : styles.tableInput}
                            isReadOnly={
                              !isEditing ||
                              !(
                                parseBoolean(watchOtherAdjustments[idx]?.amountOverrideFlag) ||
                                OTHER_ADJUSTMENTS_ONLY_USER_INPUT_AMOUNT_LIST.includes(
                                  watchOtherAdjustments[idx]?.code
                                )
                              )
                            }
                            label={{
                              value: toCurrency(parseFloat(watch(`otherAdjustments.${idx}.amount`)))
                            }}
                            formatProps={getCurrencyFormat(
                              getValues(`otherAdjustments.${idx}.amount`),
                              { ...currencyFormat, thousandSeparator: true },
                              i18n.language as LanguageShort
                            )}
                            tabIndex={!isEditing ? -1 : undefined}
                            allowedValues={
                              isOtherAdjustmentLateFilingOrLegalActionDelayFee(field.code) &&
                              claimData?.claimTypeIndicator === ClaimTypeIndicator.Supplementary
                                ? isPositive
                                : undefined
                            }
                          />
                        </SystemValueOverrideCell>
                      </FormFieldCell>
                    )}

                    {isMasterUser &&
                    field.code === OtherAdjustmentCode.ProcessTime &&
                    isOtherAdjustmentCode500DateEditable(field?.code) ? (
                      <FormFieldCell>
                        <FormDatepicker
                          name={`otherAdjustments.${idx}.date`}
                          className={`${styles.tableInput} ${styles.tableDateInput}`}
                          label={{ value: watch(`otherAdjustments.${idx}.date`) ?? '' }}
                          isReadOnly={!isEditing}
                        />
                      </FormFieldCell>
                    ) : (
                      <TextCell
                        text={toLocaleDate(field.date?.replace(' ', 'T'))}
                        name={`otherAdjustments-row-${field.id}-date`}
                      />
                    )}

                    {OVERRIDABLE_CODES_LIST.indexOf(field.code as OtherAdjustmentCode) !== -1 ? (
                      <>
                        <Controller
                          control={control}
                          name={`otherAdjustments.${idx}.numberOfDaysOverrideFlag`}
                          render={controllerEmptyObject}
                          defaultValue={
                            watchOtherAdjustments[idx]?.numberOfDaysOverrideFlag != null
                              ? parseBoolean(watchOtherAdjustments[idx]?.numberOfDaysOverrideFlag)
                              : false
                          }
                        />
                        <SystemValueOverrideCell
                          name={`otherAdjustments-row-${field.id}-days-count`}
                          systemValueOverridden={
                            parseBoolean(watchOtherAdjustments[idx]?.numberOfDaysOverrideFlag) ??
                            isOtherAdjustmentCode500DateEditable(field.code)
                          }
                          switchLabelText={[
                            otherAdjustmentFields?.userValueLabel.value ?? '',
                            otherAdjustmentFields?.systemValueLabel.value ?? ''
                          ]}
                          className={
                            isEditing ? styles.overrideInputEditing : styles.overrideInputView
                          }
                          onToggle={() => onSystemUserValueToggle(idx, false)}
                          disabled={
                            !isEditing ||
                            (watchOtherAdjustments[idx]?.amountOverrideFlag === true &&
                              isOtherAdjustmentLateFilingOrLegalActionDelayFee(field.code))
                          }
                        >
                          <FormNumber
                            name={`otherAdjustments.${idx}.numberOfDaysCount`}
                            className={isEditing ? styles.isEditInput : styles.tableInput}
                            label={{
                              value: `${
                                watch(`otherAdjustments.${idx}.numberOfDaysCount`) ?? ''
                              } ${i18n.t('Globals-Suffix-Days')}`
                            }}
                            formatProps={{
                              suffix: ` ${i18n.t('Globals-Suffix-Days')}`,
                              decimalScale: 0,
                              allowLeadingZeros: false
                            }}
                            isReadOnly={
                              !isEditing ||
                              !(
                                parseBoolean(
                                  watchOtherAdjustments[idx]?.numberOfDaysOverrideFlag
                                ) ?? isOtherAdjustmentCode500DateEditable(field.code)
                              )
                            }
                            tabIndex={!isEditing ? -1 : undefined}
                            allowNegative={allowNegativesForLateFilingOrLegalActionAdjustments(
                              field.code,
                              idx
                            )}
                          />
                        </SystemValueOverrideCell>
                      </>
                    ) : (
                      <FormFieldCell>
                        <FormNumber
                          name={`otherAdjustments.${idx}.numberOfDaysCount`}
                          className={isEditing ? styles.isEditInput : styles.tableInput}
                          label={{
                            value: `${
                              watch(`otherAdjustments.${idx}.numberOfDaysCount`) ?? ''
                            } ${i18n.t('Globals-Suffix-Days')}`
                          }}
                          formatProps={{
                            decimalScale: 0,
                            allowLeadingZeros: false
                          }}
                          isReadOnly={
                            !isEditing ||
                            !NUMBER_OF_DAYS_ENABLED_FIELD_OTHER_ADJUSTMENT_CODES.includes(
                              field.code as OtherAdjustmentCode
                            )
                          }
                          tabIndex={!isEditing ? -1 : undefined}
                        />
                      </FormFieldCell>
                    )}

                    {field.comment ? (
                      <CommentCell name={`comment-${field.id}`} comment={field.comment} />
                    ) : (
                      <TextCell text={''} name={`noComment-${field.id}`} />
                    )}

                    {isEditing && siteTypeIsInternal && (
                      <DeleteRowCell
                        name={`delete-${field.id}`}
                        idx={idx}
                        onClickCallback={remove}
                        hideButton={
                          DELETE_BUTTON_OMIT_CODES_LIST.indexOf(
                            (field.code as OtherAdjustmentCode) ?? '-1'
                          ) !== -1
                        }
                      />
                    )}
                  </DataTableRow>
                ))}
              </DataTableBody>
            </DataTable>
          </ScrollableDataTableContainer>
        </fieldset>
      </div>
    </>
  );
};
