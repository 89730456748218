import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

import HelpSupportPageProps from 'Feature/HelpSupport/models/HelpSupportPageProps';
import { Card } from 'Components/Card';

import styles from './styles.module.scss';

const HelpSupportPage = (props: HelpSupportPageProps) => {
  const { pageTitle, description, content } = props.fields;

  return (
    <Card>
      <div className={styles.main}>
        <h2 className={styles.heading}>{pageTitle?.value}</h2>
        <div className={styles.subheading}>{description?.value}</div>
        <hr />
        <RichText field={content} />
      </div>
    </Card>
  );
};

export default HelpSupportPage;
