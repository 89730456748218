export const transitStyles = {
  styles: (base: any) => ({
    ...base,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '24px'
  }),
  option: (base: any) => ({
    ...base,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '24px',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#007bff',
      color: '#FFFFFF'
    },
    color: 'black'
  }),
  control: (base: any) => ({
    ...base,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '24px',
    border: '1px solid lightgray',
    '&:hover': {
      borderColor: 'lightgray'
    },
    boxShadow: 'none',
    padding: '6px 8px'
  }),
  menu: (base: any) => ({
    ...base,
    borderColor: '#005C84',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      borderColor: '#005C84'
    },
    boxShadow: '0 0 0 1px #005C84',
    margin: 0
  })
};
