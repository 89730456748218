import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { PrimaryButton } from 'Components/Common/Button';
import FormTextArea from 'Components/Inputs/FormTextArea';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { AssessmentCalculationsContext } from 'Feature/Claims/components/Details/AssessmentCalculations';
import type { FinalClaimResponseData } from 'Feature/Claims/models/ClaimsSubmissionDetailsProps';
import useUserCommentsFunctions from './useUserCommentsFunction';

import type { UserCommentTypeProps } from './types';

import styles from './styles.module.scss';

const UserComments: React.FC<UserCommentTypeProps> = () => {
  const { claimData, updateClaimData, loadingStatus } = useHBTFormContext();

  const { sitecoreContent } = useContext(AssessmentCalculationsContext);
  const userCommentsSitecoreContent =
    sitecoreContent?.letterCommentsFlyout?.fields?.userCommentsTab?.fields;

  const hookForm = useForm({
    // resolver: hbtResolver(),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      userComment: claimData?.remark?.userComment
    }
  });

  const { handleUpdate, onSubmitErrors } = useUserCommentsFunctions(
    (data: FinalClaimResponseData[]) => {
      if (updateClaimData != null) {
        updateClaimData(data);
      }

      hookForm.reset({
        userComment: data?.[0]?.remark?.userComment
      });
    }
  );

  const { isDirty } = hookForm.formState;
  const submit = hookForm.handleSubmit(handleUpdate, onSubmitErrors);

  return (
    <>
      <FormProvider {...hookForm}>
        <div className={`${styles.userCommentsForm}`}>
          <Text
            className={`${styles.textStyle}`}
            tag="p"
            field={userCommentsSitecoreContent?.subtitleUserComment ?? { value: '' }}
          />
          <div className={styles.templateWrapper}>
            <FormTextArea
              name="userComment"
              label={userCommentsSitecoreContent?.userCommentLabel ?? ''}
              className={styles.notificationTemplate}
              placeholder={''}
              charLimit={Number(1000)}
              textAreaHelperText={
                sitecoreContent?.letterCommentsFlyout?.fields?.characterLimit?.value ?? ''
              }
            />
          </div>
          <hr className="form__divider"></hr>
          <PrimaryButton
            data-testid="userCommentsUpdateButton"
            disabled={(loadingStatus?.isLoading && loadingStatus?.isFlyout) || !isDirty}
            onClick={submit}
          >
            {loadingStatus?.isLoading && loadingStatus?.isFlyout ? (
              <span className={styles.saveIconWrapper}>
                <span className={`material-icons align-self-center`}>loop</span>
              </span>
            ) : (
              userCommentsSitecoreContent?.updateButton?.value
            )}
          </PrimaryButton>
        </div>
      </FormProvider>
    </>
  );
};

export default UserComments;
