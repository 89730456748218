import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import TermsAndConditionsProps from 'Feature/TermsAndConditionsPage/models/TermsAndConditionsProps';
import React, { useState } from 'react';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { useHistory } from 'react-router-dom';
import { Card } from 'Components/Card';
import { CardHeader } from 'Components/Card/CardHeader';
import { CardBody } from 'Components/Card/CardBody';
import FormInputCheckbox from 'Feature/DefaultSubmissionForm/components/FormInputCheckbox';

import useCustomEvent from 'Foundation/Analytics/useCustomEvent';
import { useAppInsightsContext } from 'Foundation/Analytics/AppInsightsContext';
import styles from './styles.module.scss';

const TermsAndConditions: React.FC<any> = (props: TermsAndConditionsProps) => {
  // Redirecting
  const history = useHistory();
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const authenticationContext = useAuthenticationContext();

  const handleAcknowledgementCheckboxClicked = () => {
    setIsAcknowledged(!isAcknowledged);
  };

  const reactPlugin = useAppInsightsContext();
  const trackTermsAndConditions = useCustomEvent(reactPlugin, 'Terms and Conditions', {});

  return (
    <Card>
      <CardHeader>
        <h2 className={styles.header}>
          <Text field={props.fields.heading} />
        </h2>
      </CardHeader>
      <CardBody>
        <div className="form-group">
          <small>
            <RichText field={props.fields.content} />
          </small>
        </div>
        <div className={styles.footer}>
          <div
            className={`${styles.checkboxWrapper} ${styles.awkText} form-check form-check-inline`}
          >
            <div>
              <FormInputCheckbox
                id="awk-checkbox"
                name={`acknowledgementCheckbox`}
                title={props.fields.acknowledgementText}
                checked={isAcknowledged}
                onChange={handleAcknowledgementCheckboxClicked}
                isRequired={true}
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button
              className="btn btn-primary"
              onClick={() => {
                trackTermsAndConditions({ decision: 'approved' });
                authenticationContext.acceptTermsAndConditions();
                history.push(`/${i18n.language}/dashboard`);
              }}
              disabled={!isAcknowledged}
              style={{ marginRight: '16px' }}
            >
              <Text field={props.fields.acceptButtonText} />
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                trackTermsAndConditions({ decision: 'declined' });
                authenticationContext.signOut();
                history.push('/');
              }}
            >
              <Text field={props.fields.rejectButtonText} />
            </button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default TermsAndConditions;
