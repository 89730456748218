import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Select, { ActionMeta, components, Options, OptionProps } from 'react-select';
import { LanguageShort, ModuleMapping, UserRole } from '@hobt/constants';
import { convertTimestampToDate } from '@hobt/utils';
import { MenuItemProps } from 'Components/Common/ActionMenu/types';
import { defaultEmptyString, handleEmpty } from 'Components/Common/Api/utils/HandleEmpty';
import { Button } from 'Components/Common/Button';
import { DataTable } from 'Components/Common/DataTable';
import { StatusCell } from 'Components/Common/DataTable/DataTableRow/StatusCell';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableFooter } from 'Components/Common/DataTableFooter';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { ActionButtonCell } from 'Components/Common/DataTable/DataTableRow/ActionButtonCell';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { BuildDropDownDictionary } from 'Components/Common/Helpers';
import { Modal } from 'Components/Common/Modal';
import { ModalProps } from 'Components/Common/Modal/types';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { useDataGridEngine } from 'Components/Hooks/DataGridEngine';
import { PageCardContainer } from 'Components/PageComponents/PageCardContainer';
import { SearchBar } from 'Components/PageComponents/SearchBar';
import { SearchFieldOptions } from 'Components/PageComponents/SearchBar/types';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';

import { ArrearsFileReportingPeriodSelector } from './ArrearsFileReportingPeriodSelector';
import { SelectedReportingPeriod } from './ArrearsFileReportingPeriodSelector/types';
import ArrearsFileListProps, {
  ArrearsFileListInventoryRecords,
  ArrearsFileListInventoryRecordsItems,
  DropdownFilterQueryParameter,
  DropdownOptionType,
  StatusClassMapping
} from './types';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import useArrearsFileListFunctions from './useArrearsFileListFunctions';
import { config } from '../../../../config';

import styles from './styles.module.scss';

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: 60,
    minHeight: 35
  })
};

const ArrearsFileList = (props: ArrearsFileListProps) => {
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.arrears,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  const {
    isFileListToast,
    isInventoryToast,
    isFileNotReady,
    isDeleting,
    loadingState,
    isReportingPeriodLoading,
    isRequestPassed,
    reportPeriodData,
    showDeleteModal,
    toastType,
    cancelDeleteModalCallback,
    convertTimestampToMinandHrs,
    convertTimestampToMinandHrsIn24HrFormat,
    onApiErrorCallback,
    onDeleteClick,
    onDownloadHandler,
    onFileDownload,
    onGetReportingPeriod,
    onReassessBtn,
    onRowClick,
    onSubmitHandler,
    onToastCloseHandler,
    primaryDeleteCallback
  } = useArrearsFileListFunctions();

  const {
    currentPage,
    itemsPerPage,
    itemRangeFrom,
    itemRangeTo,
    rowData,
    totalItems,
    totalPages,
    isLoading: isInventoryLoading,
    getSortDirectionForField,
    onItemsPerPageChange,
    onPagerClick,
    onSearch,
    onSearchReset,
    onSetCustomFilterParams,
    onSort,
    refresh
  } = useDataGridEngine<ArrearsFileListInventoryRecords>({
    apiBaseUrl: config.arrearsApi.urls.fileList,
    onApiErrorCallback,
    defaultItemsPerPage: 10,
    loadDataOnInitialLoad: false
  });

  const [isCurrentReportingPeriodSelected, setIsCurrentReportingPeriodSelected] =
    useState<boolean>(true);
  const [businessValidationResultList, setBusinessValidationResultList] = useState<
    Options<DropdownOptionType>
  >([]);

  const [reportingPeriodId, setReportingPeriodId] = useState('');

  useEffect(() => {
    onGetReportingPeriod();
  }, []);

  const rowActionMenu: MenuItemProps[] = [
    {
      name: 'Re-Assess File',
      icon: '',
      displayText: props.fields?.reassessFile,
      onClickCallback: onReassessBtn,
      actionItemId: 0
    },
    {
      name: 'Delete Button',
      icon: props.fields.deleteIcon ?? '',
      displayText: props.fields.deleteLabel ?? { value: '' },
      onClickCallback: onDeleteClick,
      actionItemId: 0
    }
  ];

  const deletePageModal: ModalProps = {
    type: ApplicationStates.SUCCESS,
    style: 'success',
    title: props.fields.deleteArrearsFile?.fields.heading?.value ?? '',
    content: props.fields.deleteArrearsFile?.fields.content?.value ?? '',
    isActive: false,
    onCloseCallback: cancelDeleteModalCallback
  };

  const searchByFields: SearchFieldOptions[] = [
    {
      displayText: props.fields.lenderName?.value ?? '',
      value: 'ApprovedLenderName',
      type: 'text'
    }
  ];

  const validationStatusCodeDictionary = BuildDropDownDictionary(
    props.fields.validationStatusCodes?.fields.listItems
  );

  const validationStatusCodeOptions = props.fields.validationStatusCodes?.fields.listItems
    ?.map(
      // eslint-disable-next-line consistent-return
      (_item) => {
        if (_item.fields.itemName.value && _item?.fields?.itemValue?.value) {
          return {
            label: _item.fields.itemName.value,
            value: _item?.fields?.itemValue?.value
          };
        }
      }
    )
    .reverse();

  const onReportingPeriodeSelection = (
    data: SelectedReportingPeriod,
    isCurrentReportingPeriod: boolean
  ) => {
    onSetCustomFilterParams([
      {
        paramName: DropdownFilterQueryParameter.REPORTING_PERIOD_ID,
        paramValue: data.reportingPeriodID
      }
    ]);
    setIsCurrentReportingPeriodSelected(isCurrentReportingPeriod);
    setReportingPeriodId(data.reportingPeriodID);
  };

  const CustomOption = (innerProps: OptionProps<DropdownOptionType, true>) => {
    const style = {
      alignItems: 'center',
      backgroundColor: 'red',
      color: 'inherit',
      display: 'flex '
    };

    // prop assignment
    const temp = {
      ...innerProps,
      style
    };

    return (
      <components.Option
        {...temp}
        isDisabled={innerProps.isDisabled}
        isFocused={innerProps.isFocused}
        isSelected={innerProps.isSelected}
        className={innerProps.isSelected ? styles.optionSelected : styles.optionUnselected}
      >
        <input type="checkbox" checked={innerProps.isSelected} className={styles.checkboxStyle} />
        {innerProps.children}
      </components.Option>
    );
  };

  const onSelectValue = (
    list: Options<DropdownOptionType>,
    action: ActionMeta<DropdownOptionType>
  ) => {
    const filtered: string[] = [];
    const sortingArr = ['4', '3', '2', '1'];

    list.filter((el: DropdownOptionType) => {
      filtered.push(Object.values(el)[0]);
      return null;
    });

    if (list.length === 0) {
      setBusinessValidationResultList(list);
      onSetCustomFilterParams(`ReportingPeriodID -EQ '${reportingPeriodId}'`);
      return null;
    }
    // @ts-ignore
    list?.sort(
      (a: DropdownOptionType, b: DropdownOptionType) =>
        sortingArr.indexOf(a?.value) - sortingArr.indexOf(b?.value)
    );

    if (action.name === 'businessValidationResultList') {
      setBusinessValidationResultList(list);
      onSetCustomFilterParams(
        `BusinessValidationStatusCode -IN (${list
          ?.map((i) => `${i.value}`)
          .join(',')}) -AND ReportingPeriodID -EQ '${reportingPeriodId}'`
      );
    }
  };

  return (
    <>
      <ContentLoadingModal
        display={loadingState.isLoading}
        fields={{
          heading: { value: i18n.t(loadingState.spinnerHeading ?? 'Globals-InProgress-Heading') },
          description: {
            value: i18n.t(loadingState.spinnerDescription ?? 'Globals-InProgress-Description')
          }
        }}
      />

      <ToastNotification
        isActive={isInventoryToast}
        type={ApplicationStates.ERROR}
        title={i18n.t('Globals-Toast-Server-Error-Title')}
        content={{
          value: i18n.t('DefaultsInventoryTable-SystemErrorToast-Message')
        }}
        onCloseCallback={onToastCloseHandler}
      />
      <ToastNotification
        isActive={isFileListToast}
        type={ApplicationStates[isRequestPassed ? 'SUCCESS' : 'ERROR']}
        title={i18n.t(`Globals-Toast-${isRequestPassed ? 'Success' : 'Error'}-Title`)}
        content={{
          value: i18n.t(
            isRequestPassed === true && toastType
              ? `Arrears-FileList-${toastType}-SuccessToastMessage`
              : toastType
              ? `Arrears-FileList-${toastType}-ErrorToastMessage`
              : 'Globals-Toast-Server-Error-Title'
          )
        }}
        onCloseCallback={onToastCloseHandler}
      />
      <ToastNotification
        isActive={isFileNotReady}
        type={ApplicationStates.ERROR}
        title={props.fields.validationReportNotAvailableTitle?.value}
        content={props.fields.validationReportNotAvailableMessage?.value}
        onCloseCallback={onToastCloseHandler}
      />
      <Modal
        type={deletePageModal.type}
        style={deletePageModal.style}
        isActive={showDeleteModal}
        title={deletePageModal.title}
        onCloseCallback={cancelDeleteModalCallback}
        onPrimaryCallback={() => primaryDeleteCallback(refresh)}
        onSecondaryCallback={cancelDeleteModalCallback}
        content={deletePageModal.content}
        fields={{
          saveButton: props.fields.deleteArrearsFile?.fields.acceptButton ?? { value: '' },
          cancelButton: props.fields.deleteArrearsFile?.fields.cancelButton ?? { value: '' }
        }}
        isLoading={isDeleting}
      />
      <div className={styles.card}>
        <PageCardContainer name="arrearsFileList">
          <div className={`${styles.layoutContainer}`}>
            <div className={`${styles.titleContainer}`}>
              <Text tag="h2" field={props.fields.pageTitle} />
            </div>
            <div className={`${styles.buttonsContainer}`}>
              <Button
                name="submitBtn"
                ariaText={props.fields.submitBtn}
                text={props.fields.submitBtn}
                onClick={onSubmitHandler}
                disabled={
                  isReadOnlyUser ||
                  !rowData?.[0]?.enableApproval ||
                  !isCurrentReportingPeriodSelected
                }
              />
              <Button
                name="downloadBtn"
                ariaText={props.fields.downloadBtn}
                text={props.fields.downloadBtn}
                onClick={onDownloadHandler}
                secondaryButton
                disabled={isReadOnlyUser}
              />
            </div>
          </div>

          <ArrearsFileReportingPeriodSelector
            helpText={props.fields.helpText ?? { value: '' }}
            reportingPeriodLabel={props.fields.reportingPeriodLabel ?? { value: '' }}
            reportingPeriodData={reportPeriodData}
            onSubmit={onReportingPeriodeSelection}
          />
          <div className={`${styles.searchBarWrapper}`}>
            <div className={styles.searchInput}>
              <SearchBar
                name="ArrearsListSearchBar"
                searchFieldOptions={searchByFields}
                fieldLabelAriaText={i18n.t('SearchBar-SearchField')}
                clearButtonAriaText={i18n.t('SearchBar-ClearButton')}
                searchButtonAriaText={i18n.t('SearchBar-SearchButton')}
                placeholderText={i18n.t('SearchBar-PlaceholderText')}
                onClear={onSearchReset}
                onSearch={onSearch}
                count={totalItems}
              />
            </div>

            <div className={styles.filterWrapper}>
              <div className={styles.filterLabel}>
                <p className={styles.labelHeader}>
                  {props.fields.businessValidationResult.value ?? ''}
                </p>
              </div>
              <div className={styles.selectInput}>
                <Select
                  isMulti={true}
                  name="businessValidationResultList"
                  value={businessValidationResultList}
                  isClearable={true}
                  // @ts-ignore
                  options={validationStatusCodeOptions}
                  onChange={onSelectValue}
                  components={{ Option: CustomOption }}
                  openMenuOnFocus={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  placeholder={`${i18n.t('Globals-Select')} ${
                    props.fields.businessValidationResult.value
                  }`}
                  styles={customStyles}
                  isSearchable={false}
                  aria-label="select-field"
                />
              </div>
            </div>
          </div>
          <div className={styles.dataTable}>
            <DataTable
              name="ArrearsList"
              isLoading={isInventoryLoading || isReportingPeriodLoading}
              caption={{ value: 'TestCaption' }}
              scrollOnHorizontalOverflow
              tableClass={styles.tableClass}
            >
              <DataTableHeader>
                <DataTableHeaderItem
                  name="actionMenu"
                  customStyles={styles.actionMenuHeader}
                  displayText={{}}
                />
                <DataTableHeaderItem name="lenderName" displayText={props.fields.lenderName} />
                <DataTableHeaderItem
                  name="transitNumber"
                  displayText={props.fields.transit}
                  sortable
                  onSortCallback={onSort}
                  sortDirection={getSortDirectionForField('transitNumber')}
                />
                <DataTableHeaderItem name="location" displayText={props.fields.location} />
                <DataTableHeaderItem name="fileName" displayText={props.fields.fileName} />
                <DataTableHeaderItem
                  name="businessValidationResult"
                  displayText={props.fields.businessValidationResult ?? { value: '' }}
                />
                <DataTableHeaderItem
                  name="assessmentUpdatedTimestamp"
                  displayText={props.fields.datereAssesed}
                  sortable
                  onSortCallback={onSort}
                  sortDirection={getSortDirectionForField('assessmentUpdatedTimestamp')}
                />
                <DataTableHeaderItem name="uploadedBy" displayText={props.fields.uploadedBy} />
                <DataTableHeaderItem
                  name="RecordCreatedTimestamp"
                  displayText={props.fields.dateSubmitted}
                  sortable
                  onSortCallback={onSort}
                  sortDirection={getSortDirectionForField('RecordCreatedTimestamp')}
                />
                <DataTableHeaderItem name="remarks" displayText={props.fields.remarks} />
              </DataTableHeader>

              <DataTableBody zebra>
                {rowData?.[0]?.records?.map((row: ArrearsFileListInventoryRecordsItems) => (
                  <DataTableRow
                    name={row.arrearsFileID}
                    key={row.arrearsFileID}
                    onClick={onRowClick}
                  >
                    {(!isReadOnlyUser && (
                      <ActionButtonCell
                        name={`rowAction${row.arrearsFileID}`}
                        rowActionItems={rowActionMenu?.map((i) => ({
                          ...i,
                          actionItemId: row.arrearsFileID,
                          name: `edit${row.arrearsFileID}`
                        }))}
                        rowId={row.arrearsFileID}
                      />
                    )) || (
                      <td aria-label={i18n.t('Global-MoreAction-Aria')}>
                        <span className="material-icons align-self-center" aria-hidden={true}>
                          more_vert
                        </span>
                      </td>
                    )}
                    <TextCell
                      name="lenderName"
                      text={handleEmpty(
                        i18n.language === LanguageShort.English
                          ? row.approvedLenderEnglishName
                          : row.approvedLenderFrenchName
                      )}
                    />
                    <TextCell name="transitNumber" text={row.transitNumber} />
                    <TextCell name="locationName" text={handleEmpty(row.locationName)} />
                    <td data-testid="fileNameTextCell">
                      <button
                        className={`${styles.tableButton}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          if (row.attachment?.documentID) {
                            onFileDownload(row.attachment?.documentID, row.arrearsFileID);
                          }
                        }}
                      >
                        <Text field={{ value: row.attachment?.fileName }} />
                      </button>
                    </td>
                    <StatusCell
                      name="businessValidationStatusCode"
                      statusText={handleEmpty(
                        validationStatusCodeDictionary.get(
                          row.businessValidationStatusCode?.toString()
                        )
                      )}
                      className={StatusClassMapping[row.businessValidationStatusCode] ?? 'grey20'}
                    />
                    <TextCell
                      name="dateReAssessed"
                      text={
                        row.assessmentUpdatedTimestamp
                          ? convertTimestampToDate(row.assessmentUpdatedTimestamp)!
                          : defaultEmptyString
                      }
                      subtext={
                        i18n.language === LanguageShort.English
                          ? convertTimestampToMinandHrs(row.assessmentUpdatedTimestamp)
                          : convertTimestampToMinandHrsIn24HrFormat(row.assessmentUpdatedTimestamp)
                      }
                    />
                    <TextCell name="uploadedBy" text={handleEmpty(row.submissionContactName)} />
                    <TextCell
                      name="dateSubmitted"
                      text={handleEmpty(convertTimestampToDate(row.recordCreatedTimestamp))}
                      subtext={
                        i18n.language === LanguageShort.English
                          ? convertTimestampToMinandHrs(row.recordCreatedTimestamp)
                          : convertTimestampToMinandHrsIn24HrFormat(row.recordCreatedTimestamp)
                      }
                    />
                    <TextCell name="remarks" text={handleEmpty(row.submissionRemarkText, '')} />
                  </DataTableRow>
                ))}
              </DataTableBody>
            </DataTable>
          </div>

          <div className={styles.dataTableFooter}>
            <DataTableFooter
              name="arrearsFileList"
              currentPage={currentPage}
              totalPages={totalPages || 1}
              itemsPerPageLabel={{
                value: i18n.t('Globals-Grid-ResultsPerPageDropdown-Show')
              }}
              itemsXofYText={`${itemRangeFrom || 0} - ${itemRangeTo || 0} ${i18n.t(
                'Globals-Grid-ResultsPerPageDropdown-Of'
              )}`}
              totalItems={totalItems}
              pagerClickCallback={onPagerClick}
              itemsPerPageOnChangeCallback={onItemsPerPageChange}
              itemsPerPageValue={itemsPerPage.toString()}
            />
          </div>
        </PageCardContainer>
      </div>
    </>
  );
};

export default ArrearsFileList;
