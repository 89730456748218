export const useIndicatorDetailsFields = () => {
  const convertStringFlags = (value: string | boolean): boolean | undefined => {
    if (value != null && value !== '') {
      if (typeof value === 'boolean') {
        return value;
      }

      const flagValue: string = value.toLowerCase();
      if (flagValue === 'true' || flagValue === 'false') {
        return JSON.parse(flagValue);
      }
    }
    return undefined;
  };

  return {
    convertStringFlags
  };
};
