import { useState } from 'react';
import { AxiosResponse } from 'axios';

import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { config } from '../../../../config';

export const usePreClaimsItmDelete = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDeleteErrorToast, setShowDeleteErrorToast] = useState<boolean>(false);
  const [showDeleteSuccessToast, setShowDeleteSuccessToast] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [itmID, setItmID] = useState<number | string>('');

  // API Client Config
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  const authContext = useAuthenticationContext();
  const { deleteWithAuth } = ApiClient(authContext, apiClientConfig);

  const onSuccessCallback = () => {
    setShowDeleteModal(false);
    setShowDeleteSuccessToast(true);
    setShowDeleteErrorToast(false);
  };
  const onErrorCallBack = () => {
    setShowDeleteModal(false);
    setShowDeleteErrorToast(true);
    setShowDeleteSuccessToast(false);
  };

  const deleteITMFlag = (id: number | string, refresh: Function) => {
    const baseUri = `${config.preClaimItmApi.urls.delete}${id}`;
    setIsLoading(true);
    return deleteWithAuth(baseUri)
      .then((resp: AxiosResponse) => {
        setIsLoading(false);
        setShowDeleteModal(false);
        onSuccessCallback();
        refresh();
        return resp;
      })
      .catch(() => {
        setIsLoading(false);
        setShowDeleteModal(false);

        onErrorCallBack();
      });
  };
  const onDeleteClick = (id: number | string) => {
    setShowDeleteModal(true);
    setItmID(id);
  };
  const onCloseDeleteToast = () => {
    setShowDeleteSuccessToast(false);
    setShowDeleteErrorToast(false);
  };
  const primaryDeleteCallback = (refresh: Function) => {
    deleteITMFlag(itmID, refresh);
  };
  const cancelDeleteModalCallback = () => {
    setShowDeleteModal(false);
  };
  return {
    isLoading,
    showDeleteErrorToast,
    showDeleteModal,
    showDeleteSuccessToast,
    setShowDeleteModal,
    onDeleteClick,
    onCloseDeleteToast,
    primaryDeleteCallback,
    cancelDeleteModalCallback,
    deleteITMFlag,
    onErrorCallBack
  };
};
