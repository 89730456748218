import React, { useCallback, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Scrollbars } from 'react-custom-scrollbars-2';
import i18n from 'i18next';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { getCurrentTimestampDate10Decimal6 } from '@hobt/utils';
import { UserRole, Module } from '@hobt/constants';

import { useAuthenticationContext } from 'Foundation/Authentication';
import { Button } from 'Components/Common/Button';
import { AttachmentsView } from 'Components/Common/AttachmentsView';

import { config } from '../../../../config';
import { replaceFilesRequest } from '../ArrearsSubmissionForm/ArrearsSubmissionService';
import { ArrearsSubmitResponse } from '../ArrearsSubmissionForm/types';
import { AllowedFileTypes, ReplaceArrearsFileFlyoutProps } from './types';
import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const sectionId = 'ClaimsDetailsAddComment';

const ReplaceArrears: React.FC<ReplaceArrearsFileFlyoutProps> = ({
  fields,
  flyoutData,
  loadingStatus,
  onErrorCallback,
  onCancelCallback,
  onSuccessCallback,
  setIsLoadingCallback,
  showSideDrawer
}: ReplaceArrearsFileFlyoutProps) => {
  const authContext = useAuthenticationContext();
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const isReadOnlyUser =
    sitecoreContext?.user?.moduleRoleMapping[Module.Arrears][0] === UserRole.ReadOnly;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const hookForm = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  });

  const attachmentValue = useWatch({ control: hookForm.control, name: 'attachment' });

  const onSubmit = async (formData: any) => {
    const payload = {
      attachment: {
        submitterUserID: flyoutData.userId,
        fileName: formData.attachment[0].fileName,
        submitterUserTypeCode: flyoutData.userType,
        documentID: formData.attachment[0].documentID,
        fileByteCount: formData.attachment[0].fileByteCount,
        uploadStatusCode: formData.attachment[0].uploadStatusCode,
        recordCreatedTimestamp: getCurrentTimestampDate10Decimal6()
      },
      documentID: flyoutData.documentId,
      submissionID: flyoutData.detailsId
    };

    setIsLoadingCallback({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description',
      isFlyout: true
    });

    const result = await replaceFilesRequest(authContext, payload);

    switch (result) {
      case ArrearsSubmitResponse.Success:
        if (onSuccessCallback) onSuccessCallback();
        if (onCancelCallback) onCancelCallback();
        setIsLoadingCallback({
          isLoading: false
        });
        setIsSubmitting(showSideDrawer);
        break;
      default:
        if (onErrorCallback) onErrorCallback(result);
        setIsLoadingCallback({
          isLoading: false
        });
        setIsSubmitting(showSideDrawer);
        break;
    }
  };

  const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleClick = debounce((e: any) => {
    e.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);
      onSubmit(hookForm.getValues());
    }
  }, 300);

  return (
    <Scrollbars>
      <div className={styles.headerWrapper}>
        <Text tag="h2" field={fields.title} />
        <div className={styles.closeButton}>
          <button
            data-testid={`${sectionId}FormCloseButton`}
            onClick={onCancelCallback}
            type="button"
            aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
            title={i18n.t('Accessibility-Close-Button') ?? ''}
          >
            <span
              className="material-icons align-self-center"
              aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
            >
              close
            </span>
          </button>
        </div>
      </div>

      <FormProvider {...hookForm}>
        <form
          id={`${sectionId}Form`}
          className={styles.documentRequestWrapper}
          onSubmit={hookForm.handleSubmit(onSubmit)}
          noValidate
        >
          <p className={styles.textWrapper}>
            <Text field={fields.description} />
          </p>

          {React.createElement(AttachmentsView, {
            registeredVal: 'attachment',
            fields: fields.attachmentsView.fields,
            endpoint: config.arrearsApi.urls.upload,
            allowedFileTypes: AllowedFileTypes
          })}

          <div className={styles.buttonWrapper}>
            <Button
              loading={loadingStatus?.isLoading && loadingStatus?.isFlyout}
              responsive
              type="submit"
              name={'submitButton'}
              disabled={isReadOnlyUser || !attachmentValue || isSubmitting}
              text={fields.submitButtonLabel}
              ariaText={fields.submitButtonLabel}
              // @ts-ignore
              onClick={(e: any) => {
                e.preventDefault();
                handleClick(e);
              }}
            />
            <Button
              responsive
              type="button"
              secondaryButton
              name={`cancelButton`}
              onClick={onCancelCallback}
              text={fields.cancelButtonLabel}
              ariaText={fields.cancelButtonLabel}
            />
          </div>
        </form>
      </FormProvider>
    </Scrollbars>
  );
};

export default ReplaceArrears;
