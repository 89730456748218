import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import styles from './styles.module.scss';
import { TooltipIconPropsFields } from './types';

export const TooltipIcon: React.FC<TooltipIconPropsFields> = (props: TooltipIconPropsFields) => {
  return (
    <div className={`${styles['tooltip-icon']}`}>
      <Image field={props.icon} className={props.className} />
      <div className={`${styles['hover-content']}`}>{props.text}</div>
    </div>
  );
};
