import { Text } from '@sitecore-jss/sitecore-jss-react';
import * as React from 'react';
import { ProgressBarItemStatusIndicator } from './ProgressBarItemStatusIndicator';
import styles from './styles.module.scss';
import { ProgressBarItemProps } from './types';

const ProgressBarItem: React.FC<ProgressBarItemProps> = ({
  text,
  fieldToValidate,
  current,
  onClick,
  name
}: ProgressBarItemProps) => (
  <li className={styles.progressbarItem} id={name.value}>
    <button
      // Setting attribute to any for now but this will be refactored when implementing scrollspy
      //  in Sprint 2. Needs to be any for now to allow the tests to work in current implementation.
      onClick={(e: any) => {
        e.preventDefault();
        if (onClick) onClick(e);
      }}
      data-testid={`${name.value}Test`}
    >
      <ProgressBarItemStatusIndicator fieldToValidate={fieldToValidate} current={current} />
      <span className={`${styles.progressbarItemText} ${current ? styles.currentText : ''}`}>
        <Text field={text} />
      </span>
    </button>
  </li>
);
export default ProgressBarItem;
export type { ProgressBarItemProps };
