// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from 'i18next';
import moment from 'moment-timezone';
import { LanguageShort } from '@hobt/constants';

export enum NotificationApiResponse {
  Success,
  Created,
  BadRequest,
  Updated,
  NotAuthorized,
  Error
}

export type ErrorObject = {
  response?: Response;
};
export type Response = {
  status?: number;
  data: DataObject;
};

export type DataObject = {
  statusCode: number;
  status?: string;
  data: any | any[];
  error: ErrorEntity;
};

export type ErrorEntity = {
  errorMessage?: string;
  errorCode?: any;
};

export enum ReadStatus {
  All,
  Read,
  Unread
}

export enum TypeOfClaim {
  ClaimApproved = 1002,
  NotificationFeedTitle = 1004
}

export const ReadStatusEnum: Record<ReadStatus, string> = {
  [ReadStatus.All]: 'All',
  [ReadStatus.Read]: 'Read',
  [ReadStatus.Unread]: 'Unread'
};

export const ReadStatusMap = new Map<string, ReadStatus>([
  ['All', ReadStatus.All],
  ['Read', ReadStatus.Read],
  ['Unread', ReadStatus.Unread]
]);

export const TypeOfClaimEnum: Record<TypeOfClaim, string> = {
  [TypeOfClaim.ClaimApproved]: 'Claim is Approved',
  [TypeOfClaim.NotificationFeedTitle]: 'Document Requested'
};

export const convertTimestampforNoti = (timestamp: string): string => {
  if (i18n.language === LanguageShort.French) {
    return moment(timestamp).tz('America/Toronto').format('DD/MM/YYYY, hh:mm');
  }

  return moment(timestamp).tz('America/Toronto').format('DD/MM/YYYY, h:mm a');
};

export const convertTimestampToMinandHrs = (timestamp: string): string => {
  if (i18n.language === LanguageShort.French) {
    return moment(timestamp).tz('America/Toronto').format('hh:mm');
  }
  return moment(timestamp).tz('America/Toronto').format('hh:mm a');
};

export const convertTimestampToDate = (timestamp: string): string => {
  return moment(timestamp).tz('America/Toronto').format('DD/MM/YYYY');
};

export const convertTimestampToLocalMinandHrs = (timestamp: string): string => {
  if (i18n.language === LanguageShort.French) {
    return moment.tz(timestamp, 'America/Toronto').local().format('hh:mm');
  }
  return moment.tz(timestamp, 'America/Toronto').local().format('hh:mm a');
};

export const convertTimestampToLocalDate = (timestamp: string): string => {
  return moment.tz(timestamp, 'America/Toronto').local().format('DD/MM/YYYY');
};
