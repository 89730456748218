import React from 'react';

import { AdjudicationActionButtonProps } from 'Feature/Claims/components/Details/AdjudicationCardHeader/AdjudicationActionButtons/types';
import { Button } from 'Components/Common/Button';
import { ButtonDropdown } from 'Components/Common/ButtonDropdown';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

import styles from './styles.module.scss';

export const AdjudicationActionButtons: React.FC<AdjudicationActionButtonProps> = ({
  primaryButton,
  primaryButtonDisabled,
  secondaryButton,
  secondaryButtonDisabled,
  actionDropdownItems
}: AdjudicationActionButtonProps) => {
  const { isInEditMode } = useHBTFormContext();
  return (
    <div className={styles.actionButtons}>
      {primaryButton && (
        <div>
          <Button
            text={primaryButton.text ?? {}}
            ariaText={primaryButton.text ?? {}}
            name={primaryButton.name ?? ''}
            icon={primaryButton.icon}
            onClick={primaryButton.onClickCallback}
            disabled={primaryButtonDisabled}
          />
        </div>
      )}
      {secondaryButton && (
        <div>
          <Button
            secondaryButton
            text={secondaryButton.text ?? {}}
            ariaText={secondaryButton.text ?? {}}
            name={secondaryButton.name ?? ''}
            icon={secondaryButton.icon}
            onClick={secondaryButton.onClickCallback}
            disabled={isInEditMode || secondaryButtonDisabled}
          />
        </div>
      )}
      {actionDropdownItems && actionDropdownItems?.length >= 0 && (
        <div>
          <ButtonDropdown
            toggleIcon="more_vert"
            actionItems={actionDropdownItems}
            disabled={actionDropdownItems.length <= 0}
          />
        </div>
      )}
    </div>
  );
};
