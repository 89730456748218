import React, { useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { telephoneFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormNumber from 'Components/Inputs/FormNumber';
import FormText, { Formatted } from 'Components/Inputs/FormText';
import { useFormContext } from 'react-hook-form';
import styles from './styles.module.scss';
import HbtExternalPIUserAdminContactInfoProps from './types';

const HbtExternalPIUserAdminContactInfo: React.FC<HbtExternalPIUserAdminContactInfoProps> = ({
  fields,
  isModify
}: HbtExternalPIUserAdminContactInfoProps) => {
  const { setValue, watch } = useFormContext();

  const preferredLanguage = watch('contact.preferredLanguageCode');

  useEffect(() => {
    if (preferredLanguage === undefined || Number.isNaN(preferredLanguage)) {
      setValue('contact.preferredLanguageCode', null);
    }
  }, [preferredLanguage]);

  return (
    <div>
      <h2 aria-hidden="true" className="card-title">
        <Text field={fields.sectionTitle} />
      </h2>
      <p>
        <Text field={fields.sectionSubtitle} />
      </p>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <Formatted
            className={`${styles.emailIDReadOnly} ${styles.externalUserInfoFormField}`}
            name="emailID"
            label={fields.email}
            isReadOnly={isModify}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormText
            className={styles.externalUserInfoFormField}
            name="contact.firstName"
            label={fields.firstName}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormText
            className={styles.externalUserInfoFormField}
            name="contact.lastName"
            label={fields.lastName}
          />
        </div>
      </div>

      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormNumber
            className={styles.externalUserInfoFormField}
            name="contact.phoneNumber"
            label={fields?.phoneNumber}
            formatProps={telephoneFormat}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormText
            className={styles.externalUserInfoFormField}
            name="contact.extensionPhoneNumber"
            label={fields.extension}
          />
        </div>
      </div>

      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormDropdown
            className={styles.externalUserInfoFormField}
            name="contact.preferredLanguageCode"
            label={fields.preferredLanguage}
            options={fields.languagePreferUser?.fields?.listItems}
          />
        </div>
      </div>
    </div>
  );
};

export default HbtExternalPIUserAdminContactInfo;
