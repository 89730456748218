import { AxiosResponse } from 'axios';
import React from 'react';
import { RouteData } from '@sitecore-jss/sitecore-jss-react';

export type AuthenticationContextProps = {
  Redirect?: any;
  sitecoreRouteName: string;
  sitecoreContextFactory: any;
  route: RouteData;
  children: React.ReactNode;
};

export enum MethodOfEnteringApp {
  POST_LOGIN_REDIRECT,
  DEEP_LINKED_URL_ACCESSED_DIRECTLY_WHEN_ALREADY_LOGGED_IN,
  FORCE_ACCEPT_TERMS
}

export type TermsAndConditionsFlags = {
  display: boolean;
  methodOfEnteringApp?: MethodOfEnteringApp;
};

export type AuthenticationContextType = {
  displayTermsOfUseFlag: boolean;
  isAuthenticated: boolean;
  signInPopup: () => void;
  signInWithRedirect: () => void;
  signOut: () => void;
  getUsername: () => void;
  getWithAuth: (resourceUrl: string) => Promise<AxiosResponse>;
  getToken: (requestedScopes: string[]) => Promise<string | void>;
  signInSilently: (userEmail: string) => Promise<boolean>;
  doesCurrentRouteRequireAuthentication: () => boolean;
  isTermsAndConditionsAccepted: () => boolean;
  acceptTermsAndConditions: () => void;
};
