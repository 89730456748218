import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import LanguageSwitch from 'Components/Core/Header/LanguageSwitch';
import { Language } from 'Components/Core/Header/types';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { useLocation } from 'react-router-dom';

const HbtLanguageSwitcher = () => {
  const location = useLocation();
  const sitecoreContextFactory = useSitecoreContext();
  const context = sitecoreContextFactory?.sitecoreContext;

  const authenticationContext = useAuthenticationContext();

  let userSavedLang: Language = Language.EN;
  const [openLanguageMenu, setOpenLanguageMenu] = useState<boolean>(false);
  const [language, setLanguage] = useState<Language>(Language.EN);
  const anchorRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'));

  const changeLng = useCallback(
    (key: string) => {
      sitecoreContextFactory?.updateSitecoreContext?.({
        ...context,
        language: key,
        refresh: true
      });
    },
    [sitecoreContextFactory, context]
  );

  const switchLanguage = useCallback(
    (newLang: Language, userDidClick: boolean) => {
      if (!isServer()) {
        if (newLang === Language.FR) {
          localStorage.setItem('userSavedLang', Language.FR);
          changeLng(Language.FR);
        } else {
          localStorage.setItem('userSavedLang', Language.EN);
          changeLng(Language.EN);
        }

        if (userDidClick) {
          const langVal = location.pathname.replace(/^\/([^\/]*).*$/, '$1');
          const queryString = location.search;

          let pathWithNewLanguage: string;
          if (langVal !== null && langVal !== undefined && langVal !== '') {
            if (langVal === Language.EN || langVal === Language.FR) {
              pathWithNewLanguage = location.pathname.replace(
                location.pathname.replace(/^\/([^\/]*).*$/, '$1'),
                newLang
              );
            } else {
              pathWithNewLanguage = `/${newLang}${location.pathname}`;
            }
            pathWithNewLanguage += queryString;
          } else {
            pathWithNewLanguage = `/${newLang}`;
          }

          window.location.href = pathWithNewLanguage;
        }
      }
    },
    [isServer, changeLng]
  );

  const handleLanguageMenuToggle = useCallback((value: boolean) => {
    setOpenLanguageMenu(value);
  }, []);

  const handleLanguageChange = useCallback(
    (lang: Language) => {
      setLanguage(lang);
      setOpenLanguageMenu(false);
      switchLanguage(lang, true);
    },
    [switchLanguage]
  );

  const detectLanguage = useCallback(() => {
    if (userSavedLang) {
      switchLanguage(userSavedLang === Language.FR ? Language.FR : Language.EN, false);
    } else {
      const userLang =
        typeof window !== 'undefined' ? navigator.language.substring(0, 2) : Language.EN;
      switchLanguage(userLang === Language.FR ? Language.FR : Language.EN, false);
    }
  }, [switchLanguage]);

  useEffect(() => {
    if (!isServer()) {
      userSavedLang = (localStorage.getItem('userSavedLang') as Language) || Language.EN;
      setLanguage(userSavedLang === Language.EN ? Language.EN : Language.FR);
      detectLanguage();
    }
  }, [isServer()]);

  const popperModifiers = [
    {
      name: 'offset',
      options: {
        offset: [50, 20]
      }
    }
  ];

  return (
    <LanguageSwitch
      open={openLanguageMenu}
      anchorRef={anchorRef}
      isMediumUp={isMediumUp}
      isSkeletonLoaderRequired={!authenticationContext.isAuthenticated}
      language={language}
      popperModifiers={!authenticationContext.isAuthenticated ? popperModifiers : undefined}
      onToggle={handleLanguageMenuToggle}
      onChange={handleLanguageChange}
    />
  );
};

export default memo(HbtLanguageSwitcher);
