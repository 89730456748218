import React from 'react';
import { TextField } from '@sitecore-jss/sitecore-jss-react';
import { ImageField } from '@sitecore-jss/sitecore-jss-react/types/components/Image';
import { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';

export type MenuItem = {
  fields: {
    navItem: LinkField;
    text: FieldValue;
    name?: FieldValue;
    icon?: ImageField;
    subMenu?: MenuItem[];
  };
};

export enum NestedMenuType {
  OnPage = 'OnPage',
  InFlyout = 'InFlyout'
}

export interface MenuItemComponentProps {
  item: MenuItem;
  hasChevron: boolean;
  maxSubMenuLayers: number;
  currentLayer: number;
  onNavLinkClick?: (e?: React.MouseEvent | React.KeyboardEvent) => void;
}

export interface MenuProps {
  hasChevron?: boolean;
  hasWrapperBorder?: boolean;
  isOnPage?: boolean;
  isLogoutOptionRequired?: boolean;
  logoutLabel?: FieldValue;
  items: MenuItem[];
  maxSubMenuLayers?: number;
  onClickLogout?: () => void;
  onNavLinkClick?: (e?: React.MouseEvent | React.KeyboardEvent) => void;
}

export interface FlyoutMenuProps extends MenuProps {
  hasBackIcon?: boolean;
  headerText: TextField;
  maxSubMenuLayers?: number;
  onClickBackIcon?: React.MouseEventHandler<HTMLButtonElement>;
  onClickClose: React.MouseEventHandler<HTMLButtonElement>;
}
