import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

export type ErrorBoundaryProps = RouteComponentProps & {
  children: React.ReactNode;
};

export type ErrorBoundaryState = {
  hasError: boolean;
  error: Error | null;
};

export class ApplicationException {
  constructor(private userMessageDictionaryKey: string) {}

  get messageKey(): string {
    return this.userMessageDictionaryKey;
  }
}
