import { useState } from 'react';
import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { AxiosResponse } from 'axios';
import { config } from '../../../../config';

export const usePreClaimsUnderwritingReview = () => {
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
  const [showDeleteSuccessToast, setShowDeleteSuccessToast] = useState<boolean>(false);
  const [showDeleteErrorToast, setShowDeleteErrorToast] = useState<boolean>(false);
  const [showSideDrawer, setShowSideDrawer] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // API Client Config
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  const authContext = useAuthenticationContext();
  const { deleteWithAuth } = ApiClient(authContext, apiClientConfig);

  const onSuccessSubmit = () => {
    setShowSideDrawer(false);
    setShowSuccessToast(true);
  };

  const onErrorSubmit = () => {
    setShowSideDrawer(false);
    setShowErrorToast(true);
  };
  const ondeleteSuccessCallBack = () => {
    setShowDeleteSuccessToast(true);
  };
  const ondeleteErrorCallBack = () => {
    setShowDeleteErrorToast(true);
  };
  const onSuccessToastDismiss = () => {
    setShowSuccessToast(false);
    setShowDeleteSuccessToast(false);
  };

  const onErrorToastDismiss = () => {
    setShowErrorToast(false);
    setShowDeleteErrorToast(false);
  };

  const onAddUnderWritingReviewButtonClick = () => {
    setShowSideDrawer(true);
    setShowSuccessToast(false);
    setShowErrorToast(false);
  };

  const onCancelForm = () => {
    setShowSideDrawer(false);
  };

  const deleteUnderwritingReviewFlag = (id: string, onSuccessCallback: Function) => {
    const uri = `${config.preClaimUnderwritingReviewApi.urls.delete}${id}`;

    setIsLoading(true);
    deleteWithAuth(uri)
      .then((_res: AxiosResponse) => {
        setIsLoading(false);
        onSuccessCallback();
        ondeleteSuccessCallBack();
      })
      .catch(() => {
        setIsLoading(false);

        ondeleteErrorCallBack();
      });
  };
  return {
    showSuccessToast,
    showSideDrawer,
    showErrorToast,
    showDeleteErrorToast,
    showDeleteSuccessToast,
    onCancelForm,
    onSuccessSubmit,
    onErrorSubmit,
    onAddUnderWritingReviewButtonClick,
    deleteUnderwritingReviewFlag,
    onSuccessToastDismiss,
    onErrorToastDismiss,
    isLoading
  };
};
