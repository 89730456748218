import React, { useEffect, useMemo } from 'react';
import i18n from 'i18next';
import { FormModal } from 'Components/Common/FormModal';
import { DownloadReportTypeProps } from 'Feature/UserManagement/models/ManageUsersInternalProps';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { UserRole, ModuleMapping } from '@hobt/constants';
import { Button } from 'Components/Common/Button';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { UserType } from 'Feature/UserManagement/models/types';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { useFeature } from 'flagged';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import FormDropdown from 'Components/Inputs/FormDropdown';
import { FormProvider, useForm } from 'react-hook-form';
import { convertToSitecoreListItem } from 'Constants/Utils/ConvertToSitecoreListItem';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import { downloadReportRequest } from '../../api/UserApiHandler.service';
import { AxiosResponse } from 'axios';
import styles from './styles.module.scss';

export const DownloadReport = ({ fields, isActive, isSuccess }: DownloadReportTypeProps) => {
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const isInternal = useFeature(FeatureFlags.INTERNAL);
  const authenticationContext = useAuthenticationContext();

  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.admin,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  const hookForm = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      userTypeCode: '1'
    }
  });

  const monthOptions: SitecoreListItem[] = useMemo(() => {
    const { format } = new Intl.DateTimeFormat(i18n.language, { month: 'long' });

    return Array.from(Array(12).keys()).map((m) =>
      convertToSitecoreListItem(format(Date.UTC(2021, m + 1)), m + 1)
    );
  }, []);

  useEffect(() => {
    hookForm.register('userTypeCode');

    hookForm.setValue('userTypeCode', UserType[isInternal ? 'Internal' : 'External'] as any);
  }, []);

  const currentYear = new Date().getFullYear();
  const years = Array.from(Array(5), (_, index) => currentYear - index);

  // const downlodUserReportApiCall = async (formData: any) => {
  //   const result = await downloadUserReportRequest( formData);
  //   isSuccess(result);
  // };
  const onSubmit = async (formData: any): Promise<void> => {
    const result = downloadReportRequest(authenticationContext, formData)
      .then((res: AxiosResponse) => {
        const contentType = res.headers['content-type'];
        const fileName = res.headers['content-disposition'].split('"')[1];
        const downloadElement = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([res.data], { type: contentType }));
        downloadElement.setAttribute('href', url);
        downloadElement.setAttribute('download', fileName);
        downloadElement.click();
        downloadElement.remove();
        window.URL.revokeObjectURL(url);
        isSuccess(result);
      })
      .catch();
  };
  const handleErrors = () => {};
  return (
    <div>
      <FormModal
        isActive={true}
        icon={false}
        type={ApplicationStates.DEFAULT}
        style={''}
        title={''}
        onCloseCallback={() => {
          isActive();
        }}
      >
        <div className={styles.modalTitle}>
          <Text field={fields.modalTitle} />
        </div>
        <div>
          <FormProvider {...hookForm}>
            <form
              onSubmit={hookForm.handleSubmit(onSubmit, handleErrors)}
              id="downloadReportForm"
              className="form"
            >
              <div className={styles.layoutContainer}>
                <FormDropdown
                  required={true}
                  label={fields.months}
                  disableDefaultSelect={false}
                  name="month"
                  options={monthOptions}
                />
                <FormDropdown
                  required={true}
                  disableDefaultSelect={false}
                  name="year"
                  label={fields.years}
                  options={years.map(
                    (year): SitecoreListItem => ({
                      fields: {
                        itemName: { value: year.toString() },
                        itemValue: { value: year.toString() }
                      }
                    })
                  )}
                />
              </div>
              <div className={styles.buttonsContainer}>
                <div className={styles.userButtons}>
                  <Button
                    responsive
                    name="submitButton"
                    text={fields.submitBtn}
                    ariaText={fields.submitBtn}
                    type="submit"
                    disabled={isReadOnlyUser}
                  />
                </div>
                <div className={styles.userButtons}>
                  <Button
                    secondaryButton
                    responsive
                    name="cancel"
                    text={fields.cancelBtn}
                    ariaText={fields.cancelBtn}
                    onClick={() => {
                      isActive();
                    }}
                    disabled={isReadOnlyUser}
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </FormModal>
    </div>
  );
};
