import React from 'react';
import { Link, Image } from '@sitecore-jss/sitecore-jss-react';
import MediaDocumentProps from './MediaDocumentProps';

const MediaDocument: React.FC<MediaDocumentProps> = (props: MediaDocumentProps) => {
  const { icon, title } = props.fields;
  const templateMargin = {
    marginLeft: '8px'
  };
  return (
    <>
      <Image field={icon} />
      <span style={templateMargin}>
        <Link field={title}>{title?.value?.text}</Link>
      </span>
    </>
  );
};

export default MediaDocument;
