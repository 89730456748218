import { HbtServiceErrorCodes } from '@hobt/constants';
import { AxiosError, AxiosResponse } from 'axios';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { config } from '../../../../../config';
import { UserApiResponse, DataObject, ErrorObject, UserAccessType } from '../../models/types';

const addUserUrl: string = config.userApi.urls.create;
const getUserUrl: string = config.userApi.urls.getUser;
const modifyUserUrl: string = config.userApi.urls.modify;
const deleteUserUrl: string = config.userApi.urls.delete;
const reSyncUserRequestUrl: string = config.userApi.urls.resyncUser;
const reInviteRequestUrl: string = config.userApi.urls.reinviteUser;

const apiClientConfig: ApiClientConfig = { timeout: config.userApi.requestTimeout };
const pathError = new Error('Invalid configuration. Could not read URL or path for API calls.');

let access: string[] = [];
const errorCode = (code: string): number | undefined => {
  if (code != null) {
    switch (code) {
      case HbtServiceErrorCodes.HBT_ERR_DB_DUPLICATE_RECORD.code:
        return UserApiResponse.DuplicateRecord;

      case HbtServiceErrorCodes.HBT_ERR_INCONSISTENT_EMAIL_USERTYPE.code:
        return UserApiResponse.InconsistentEmail;

      default:
        return UserApiResponse.Error;
    }
  }

  return undefined;
};

export const errorCatch = (err: ErrorObject): number => {
  if (err?.response?.data?.data?.[0]?.error?.httpCode) {
    switch (err.response.data.statusCode) {
      case 400:
        return UserApiResponse.BadRequest;
      case 401:
        return UserApiResponse.NotAuthorized;
      case 500:
        return errorCode(err.response.data.data[0].error.code) ?? UserApiResponse.Error;
      default:
        return UserApiResponse.Error;
    }
  } else {
    // err.response.status is undefined
    return UserApiResponse.Error;
  }
};

/**
 * TODO: Update this if the backend is updated to accept a different payload instead
 * of multiple user access objects
 *
 * Create one user access object per fi code where fi code is unique per object
 * but duplicate all other properties ofuser access object accross all other
 * user access objects
 */
export const preProcessFormData = (formData: any, sitecoreContext?: any) => {
  const data = { ...formData };
  const { financialInstitutionCodes } = data.userAccesses[0];
  delete data.userAccesses[0].financialInstitutionCodes;

  const userAuthorizationScopeFIMapping: any =
    sitecoreContext && sitecoreContext?.user?.userAuthorizationScopeFIMapping;

  const userAccessObjectsPerFiCode: UserAccessType[] = financialInstitutionCodes.map(
    (fiCode: string) => {
      access = [];
      if (
        config.app.siteType === FeatureFlags.EXTERNAL &&
        fiCode in userAuthorizationScopeFIMapping
      ) {
        userAuthorizationScopeFIMapping[fiCode].userAuthorizationScope.map((type: string) => {
          if (data.userAccesses[0].access[type].activeFlag === true) {
            access.push(type);
          }
        });
      }
      return config.app.siteType === FeatureFlags.EXTERNAL
        ? {
            ...data.userAccesses[0],
            financialInstitutionCode: fiCode,
            userAuthorizationScope: access
          }
        : {
            ...data.userAccesses[0],
            financialInstitutionCode: fiCode
          };
    }
  );

  const formPayload = {
    users: [
      {
        ...data,
        userAccesses: userAccessObjectsPerFiCode
      }
    ]
  };
  return formPayload;
};

// TODO: update type of 'formData' at a later date if needed
export const addUserRequest = async (
  authContext: any,
  formData: any,
  sitecoreContext: any
): Promise<number> => {
  try {
    const formPayload = preProcessFormData(formData, sitecoreContext);
    await ApiClient(authContext, apiClientConfig).postWithAuth(addUserUrl, formPayload);
    return UserApiResponse.Created;
  } catch (err: any) {
    return errorCatch(err);
  }
};

export const addExternalUserRequest = async (authContext: any, formData: any): Promise<number> => {
  try {
    await ApiClient(authContext, apiClientConfig).postWithAuth(addUserUrl, formData);
    return UserApiResponse.Created;
  } catch (err: any) {
    return errorCatch(err);
  }
};

export const getUserRequest = async (authContext: any, id: string): Promise<DataObject> => {
  const route = `${getUserUrl}?id=${id}`;

  if (route !== undefined) {
    return ApiClient(authContext, apiClientConfig)
      .getWithAuth(route)
      .then((res: AxiosResponse<DataObject>) => res.data)
      .catch((err: AxiosError) => Promise.reject(err));
  }
  throw pathError;
};

export const updateUserRequest = (authContext: any, formData: any): Promise<number> => {
  const formPayload = preProcessFormData(formData);

  const email = formPayload.users[0].emailID;
  const route = `${modifyUserUrl}?email=${email}`;

  if (route !== undefined) {
    return ApiClient(authContext, apiClientConfig)
      .putWithAuth(route, formPayload)
      .then((res: AxiosResponse<DataObject>) => {
        console.log('data', res.data);
        return UserApiResponse.Updated;
      })
      .catch((err: ErrorObject) => {
        Promise.reject(err);
        return errorCatch(err);
      });
  }
  throw pathError;
};

export const deleteUserRequest = (authContext: any, formData: any): Promise<number> => {
  if (deleteUserUrl !== undefined) {
    return ApiClient(authContext, apiClientConfig)
      .putWithAuth(deleteUserUrl, formData)
      .then((res: AxiosResponse<DataObject>) => {
        console.log('data', res.data);
        return UserApiResponse.Success;
      })
      .catch((err: ErrorObject) => {
        Promise.reject(err);
        return errorCatch(err);
      });
  }
  throw pathError;
};

export const reSyncUserRequest = async (authContext: any, user: any) => {
  try {
    await ApiClient(authContext, apiClientConfig).postWithAuth(reSyncUserRequestUrl, user);
    return UserApiResponse.Success;
  } catch (err: any) {
    return errorCatch(err);
  }
};

export const reInviteUserRequest = async (authContext: any, user: any) => {
  try {
    await ApiClient(authContext, apiClientConfig).postWithAuth(reInviteRequestUrl, user);
    return UserApiResponse.Success;
  } catch (err: any) {
    return errorCatch(err);
  }
};
