import i18n from 'i18next';
import DefaultSubmissionFormProps, {
  AttachmentValuesField
} from 'Feature/DefaultSubmissionForm/models/DefaultSubmissionFormProps';
import { FormDropdownOption } from '../../FormInputDropdownText/FormInputDropdownText.types';

export const getMortgageDetailsProps = (props: DefaultSubmissionFormProps) => {
  const provinceOptions: FormDropdownOption[] = [{ label: '', value: '' }];
  props.fields.provinceOptions[0].fields.listItems.map((option: AttachmentValuesField) => {
    provinceOptions.push({
      label: (option?.fields?.itemName?.value as string) || 'No label set',
      value: option?.fields?.itemValue?.value || -1
    });
  });

  const mortgageDetailsProps = {
    testId: 'mortgageObligationsTest',
    accordionLineColor: 'grey',
    linePosition: 'vertical',
    title: {
      field: {
        value: i18n.t('DefaultSubmission-Card-MortgageObligationsHeading')
      }
    },
    deleteIcon: props.fields.deleteIcon,
    borrower: i18n.t('DefaultSubmission-Card-Borrower'),
    coBorrower: i18n.t('DefaultSubmission-Card-Co-Borrower'),
    guarantor: i18n.t('DefaultSubmission-Card-Guarantor'),
    provinceOptions,
    inputFields: {
      purchasePriceOfHome: {
        field: {
          value: i18n.t('DefaultSubmission-Card-PurchasePriceOfHome')
        }
      },
      annualTaxes: {
        field: {
          value: i18n.t('DefaultSubmission-Card-AnnualTaxes')
        }
      },
      currentMarketValue: {
        field: {
          value: i18n.t('DefaultSubmission-Card-CurrentMarketValue')
        }
      },
      updatedPropertyTaxes: {
        field: {
          value: i18n.t('DefaultSubmission-Card-PropertyTaxUpToDate')
        }
      },
      updatedPropertyInsurance: {
        field: {
          value: i18n.t('DefaultSubmission-Card-PropertyInsuranceUpToDate')
        }
      },
      amountPropertyTaxes: {
        field: {
          value: i18n.t('DefaultSubmission-CardE-Amount')
        }
      },
      amountPropertyInsurance: {
        field: {
          value: i18n.t('DefaultSubmission-CardE-Amount')
        }
      },
      updatedUtilities: {
        field: {
          value: i18n.t('DefaultSubmission-Card-UtilitiesUpToDate')
        }
      },
      updatedCondominiumFees: {
        field: {
          value: i18n.t('DefaultSubmission-Card-CondominiumFeeUpToDate')
        }
      },
      amountUtilities: {
        field: {
          value: i18n.t('DefaultSubmission-CardE-Amount')
        }
      },
      amountCondominiumFees: {
        field: {
          value: i18n.t('DefaultSubmission-CardE-Amount')
        }
      },
      propertyAddressStreetNumber: {
        field: {
          value: i18n.t('DefaultSubmission-Card-PropertyAddressStreetNumber')
        }
      },
      propertyAddressUnitNumber: {
        field: {
          value: i18n.t('DefaultSubmission-Card-PropertyAddressUnitNumber')
        }
      },
      streetName: {
        field: {
          value: i18n.t('DefaultSubmission-Card-PropertyAddressStreetName')
        }
      },
      streetType: {
        field: {
          value: i18n.t('DefaultSubmission-Card-PropertyAddressStreetType')
        }
      },
      streetDirection: {
        field: {
          value: i18n.t('DefaultSubmission-Card-StreetDirection')
        }
      },
      city: {
        field: {
          value: i18n.t('DefaultSubmission-Card-TownCity')
        }
      },
      province: {
        field: {
          value: i18n.t('DefaultSubmission-Card-Province')
        }
      },
      postalCode: {
        field: {
          value: i18n.t('DefaultSubmission-Card-PostalCode')
        }
      },
      mortgageBorrowerType: {
        field: {
          value: i18n.t('DefaultSubmission-Card-BorrowerType')
        }
      },
      mortgageRegisteredMortgageHolder: {
        field: {
          value: i18n.t('DefaultSubmission-Card-RegisteredMortgageHolder')
        }
      },
      mortgageUnpaidBalance: {
        field: {
          value: i18n.t('DefaultSubmission-Card-UnpaidPrincipalBalance')
        }
      },
      mortgageRequiredMonthlyPayment: {
        field: {
          value: i18n.t('DefaultSubmission-Card-RequiredMonthlyPayment')
        }
      },
      mortgageCurrentMarketValue: {
        field: {
          value: i18n.t('DefaultSubmission-Card-CurrentMarketValue')
        }
      },
      mortgageRequiredAnnualTaxes: {
        field: {
          value: i18n.t('DefaultSubmission-Card-AnnualTaxes')
        }
      },
      mortgageLenderName: {
        field: {
          value: i18n.t('DefaultSubmission-Card-ApprovedLenderName')
        }
      },
      mortgageCurrentArrears: {
        field: {
          value: i18n.t('DefaultSubmission-Card-CurrentArrears')
        }
      },
      nationalHousingActInsured: {
        field: {
          value: i18n.t('DefaultSubmission-Card-MortgageIsNHAInsured')
        }
      }
    }
  };

  return mortgageDetailsProps;
};
