import { LanguageShort } from '@hobt/constants';

export const convertDateForUi = (
  inputDate: string,
  language?: LanguageShort | undefined,
  dateOnly?: boolean
) => {
  if (inputDate == null || inputDate.trim() === '') {
    return '';
  }

  // Incoming date format: 2018-08-27 02:56:45
  const normalizedDate: string = inputDate.replace(' ', 'T');
  const date: Date = new Date(normalizedDate);

  // Outgoing date is 'Mon 00, 2000 - 00:00am
  const dateString = new Intl.DateTimeFormat(`${language ?? LanguageShort.English}-CA`, {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  }).format(date);

  const timeString = new Intl.DateTimeFormat(`${language ?? LanguageShort.English}-CA`, {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric'
  }).format(date);

  return `${dateString} ${dateOnly ? '' : `- ${timeString}`}`;
};

export const convertDateOnlyForUi = (inputDate: string, language?: LanguageShort | undefined) => {
  if (inputDate == null || inputDate.trim() === '') {
    return '';
  }
  // Incoming date format: 2018-08-27 02:56:45
  const normalizedDate: string = `${inputDate}T00:00:00`;

  const date: Date = new Date(normalizedDate);
  // Outgoing date is 'Mon 00, 2000 - 00:00am
  const dateString = new Intl.DateTimeFormat(`${language ?? LanguageShort.English}-CA`, {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  }).format(date);
  return `${dateString}`;
};
/**
 * Formats the given date to a YYYY-MM-DD string.
 * @param {string | number | Date | undefined} value - The value used to create Date object to be formatted.
 * @returns {string | undefined} - The date in the format YYYY-MM-DD or undefined.
 */
export const formatDate = (value: string | number | Date | undefined): string | undefined => {
  return value ? new Date(value).toISOString().split('T')[0] : undefined;
};
