import React, { memo } from 'react';
import i18n from 'i18next';
import { HbtIcon } from '../HbtIcon';
import { HbtIconSize } from '../HbtIcon/types';
import styles from './styles.module.scss';
import { TagProps, TagType } from './types';

export const Tag: React.FC<TagProps> = memo(
  ({ label, variant, statusIndicator, leadingIcon, onDelete }: TagProps) => {
    const handleDelete = () => {
      if (onDelete) onDelete();
    };

    const statusLabel = i18n.t('Status-Label') ?? 'status';
    const readLabelCopy = i18n.t('Read-Label-Copy') ?? 'read label copy';
    const removeLabel = i18n.t('Remove-Label') ?? 'remove';

    const StatusTagMapping: Record<string, string> = {
      Grey: 'icon_grey_status',
      Orange: 'icon_orange_status',
      Red: 'icon_red_status',
      Green: 'icon_green_status',
      Blue: 'icon_blue_status'
    };

    const tagStateIcon = (
      <HbtIcon
        size={HbtIconSize.SMALL}
        type={StatusTagMapping[statusIndicator!]}
        aria-label={statusLabel}
      />
    );

    const tagCloseIcon = (
      <HbtIcon size={HbtIconSize.SMALL} type="icon_close" aria-label={removeLabel} />
    );

    return (
      <div
        aria-label={readLabelCopy}
        data-testid="tag-test"
        className={
          variant === TagType.INTERACTIVE ? styles.containerInteractive : styles.containerStatic
        }
      >
        {variant === TagType.INTERACTIVE && (
          <>
            {leadingIcon && <div className={styles.checkStyle}>{leadingIcon}</div>}
            {statusIndicator && <div>{tagStateIcon}</div>}
          </>
        )}
        <div className={styles.labelStyle}>{label}</div>
        {variant === TagType.INTERACTIVE && (
          <div onClick={handleDelete} className={styles.closeStyle}>
            {tagCloseIcon}
          </div>
        )}
      </div>
    );
  }
);
