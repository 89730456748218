import { HbtFileExtensionType } from '@hobt/constants';

import { AttachmentsViewPropsFields } from 'Components/Common/AttachmentsView/types';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';

import { ArrearsSubmitResponse } from '../ArrearsSubmissionForm/types';

export interface ReplaceArrearsFileFlyoutProps {
  name?: string;
  flyoutData?: any;
  fields: ReplaceArrearsFileFlyoutPropsFields;
  loadingStatus?: Spinner;
  showSideDrawer?: any;
  onCancelCallback?: () => void;
  onSuccessCallback?: () => void;
  onErrorCallback?: (errorType: ArrearsSubmitResponse) => void;
  setIsLoadingCallback: React.Dispatch<React.SetStateAction<Spinner>>;
}

export interface ReplaceArrearsFileFlyoutPropsFields {
  title: FieldValue;
  uploadLabel: FieldValue;
  description: FieldValue;
  submitButtonLabel: FieldValue;
  cancelButtonLabel: FieldValue;
  supportedFormatLabel: FieldValue;
  attachmentsView: AttachmentsViewComponentProps;
}

interface AttachmentsViewComponentProps {
  fields: AttachmentsViewPropsFields;
}

export const AllowedFileTypes = [
  HbtFileExtensionType.CSV,
  HbtFileExtensionType.XLSX,
  HbtFileExtensionType.XLSM,
  HbtFileExtensionType.XLSB,
  HbtFileExtensionType.XLTX,
  HbtFileExtensionType.XLTM,
  HbtFileExtensionType.XLS,
  HbtFileExtensionType.XLT,
  HbtFileExtensionType.XML,
  HbtFileExtensionType.XLAM,
  HbtFileExtensionType.XLA,
  HbtFileExtensionType.XLW,
  HbtFileExtensionType.XLR
];
