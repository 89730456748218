import React, { useEffect } from 'react';
import { useFeature } from 'flagged';
import i18n from 'i18next';

import { UserRole, Module, ModuleMapping } from '@hobt/constants';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { FormDropdownOption } from 'Feature/DefaultSubmissionForm/components/FormInputDropdownText/FormInputDropdownText.types';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { useDefaultFormActionsContext } from 'Feature/DefaultsInventory/components/DefaultFormContext';
import { LinedCard, AccordionContainer } from '../../../../CommonComponents/AccordionComponents';
import FormInputTextInput, {
  Formatted as FormInputFormattedTextInput
} from '../../FormInputTextInput';
import FormInputDropdownText from '../../FormInputDropdownText';
import FormInputWholeNumber from '../../FormInputWholeNumber';
import { LenderDetailsProps } from './LenderDetails.types';
import {
  LanguageTypeCode,
  LanguageSwitchTypeCode,
  ValidationErrorMessageType
} from '../../../models/typeCode.types';

import { getLenderDetails } from './LenderDetailsData';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

/*
  no-unused-expressions warning for optional chaining
  https://github.com/facebook/create-react-app/issues/8107
*/
export const LenderDetails: React.FC<LenderDetailsProps> = ({
  register,
  errors,
  setValueHandler,
  control,
  watch,
  getValues,
  trigger,
  ...props
}) => {
  const commonCardProps = {
    register,
    errors,
    setValueHandler,
    control,
    watch,
    getValues,
    trigger
  };

  const authContext = useAuthenticationContext();

  const financialInstitutionCode = watch && watch('lender.financialInstitutionCode');
  const approvedLenderCode = watch && watch('lender.approvedLenderCode');
  const approvedLenderName = watch && watch('lender.approvedLenderName');
  const approvedLenderReferenceNumber = watch && watch('lender.approvedLenderReferenceNumber');
  const contactEmail = watch && watch('lender.contactEmail');
  const contactName = watch && watch('lender.contactName');
  const transitNumber = watch && watch('lender.transitNumber');
  const correspondenceLanguage = watch && watch('other.correspondenceLanguage');

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  const usersFiTransitMapping = props.userInfo?.moduleFiTransitMapping?.[ModuleMapping.default];

  const isInternalSite = useFeature(FeatureFlags.INTERNAL);

  const defaultFormActions = useDefaultFormActionsContext();

  const emptyDropdown: FormDropdownOption = {
    label: i18n.t('Dropdown-Option-PleaseSelect'),
    value: ''
  };

  const financialInstitutionOptions: FormDropdownOption[] = [emptyDropdown];
  const [transitOptions, setTransitOptions] = React.useState<FormDropdownOption[]>([emptyDropdown]);

  if (usersFiTransitMapping != null && Object.keys(usersFiTransitMapping).length > 0) {
    Object.keys(usersFiTransitMapping).forEach((val) => {
      financialInstitutionOptions.push({ label: val, value: val });
    });
  }

  const setAllowedTransitOptions = (selectedFinancialInstitutionCode: string) => {
    const transitMapping = usersFiTransitMapping?.[selectedFinancialInstitutionCode];

    if (transitMapping != null) {
      const { allowedTransitNumbers } = transitMapping;
      if (allowedTransitNumbers != null && Array.isArray(allowedTransitNumbers)) {
        const dynamicOptions: FormDropdownOption[] = [];
        dynamicOptions.push(emptyDropdown);

        allowedTransitNumbers.forEach((val) => dynamicOptions.push({ label: val, value: val }));

        setTransitOptions(dynamicOptions);

        // eslint-disable-next-line no-unused-expressions
        setValueHandler?.('lender.financialInstitutionCode', selectedFinancialInstitutionCode);
      }
    } else {
      // eslint-disable-next-line no-unused-expressions
      setValueHandler?.('lender.financialInstitutionCode', '');

      // eslint-disable-next-line no-unused-expressions
      setValueHandler?.('lender.transitNumber', '');

      setTransitOptions([emptyDropdown]);
    }
  };

  useEffect(() => {
    if (defaultFormActions.isCardComplete('lender')) {
      if (
        !(
          !!approvedLenderCode &&
          approvedLenderCode.length <= 5 &&
          !!approvedLenderName &&
          !!approvedLenderReferenceNumber &&
          !!contactEmail &&
          !!contactName &&
          !!financialInstitutionCode &&
          financialInstitutionCode.length <= 3 &&
          !!transitNumber &&
          transitNumber.length <= 5 &&
          !!correspondenceLanguage
        )
      ) {
        defaultFormActions?.removeCompleteCard('lender');
      }
    } else if (
      !!approvedLenderCode &&
      approvedLenderCode.length <= 5 &&
      !!approvedLenderName &&
      !!approvedLenderReferenceNumber &&
      !!contactEmail &&
      !!contactName &&
      !!financialInstitutionCode &&
      financialInstitutionCode.length <= 3 &&
      !!transitNumber &&
      transitNumber.length <= 5 &&
      !!correspondenceLanguage
    ) {
      defaultFormActions?.addCompleteCard('lender');
    }
  }, [
    approvedLenderCode,
    approvedLenderName,
    approvedLenderReferenceNumber,
    contactEmail,
    contactName,
    financialInstitutionCode,
    transitNumber,
    correspondenceLanguage
  ]);

  const handleFinancialInstitutionCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedFinancialInstitutionCode = e.currentTarget.value;

    // eslint-disable-next-line no-unused-expressions
    setValueHandler?.('lender.approvedLenderCode', '');

    // eslint-disable-next-line no-unused-expressions
    setValueHandler?.('lender.approvedLenderName', '');

    setAllowedTransitOptions(selectedFinancialInstitutionCode);
  };

  const handleTransitCodeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const transitNum = e.currentTarget.value;

    // eslint-disable-next-line no-unused-expressions
    const selectedFinancialInstitutionCode = getValues?.(
      'lender.financialInstitutionCode'
    )?.toString();

    if (
      transitNum !== null &&
      transitNum !== '' &&
      selectedFinancialInstitutionCode !== null &&
      selectedFinancialInstitutionCode !== ''
    ) {
      const lenderDetails = await getLenderDetails(
        authContext,
        selectedFinancialInstitutionCode ?? '',
        transitNum ?? ''
      );

      if (lenderDetails != null && lenderDetails?.approvedLenderCode !== '') {
        // eslint-disable-next-line no-unused-expressions
        setValueHandler?.('lender.approvedLenderCode', lenderDetails?.approvedLenderCode);

        const lenderName =
          i18n.language === LanguageSwitchTypeCode.English
            ? lenderDetails?.approvedLenderEnglishName
            : lenderDetails?.approvedLenderFrenchName;

        // eslint-disable-next-line no-unused-expressions
        setValueHandler?.('lender.approvedLenderName', lenderName);

        // eslint-disable-next-line no-unused-expressions
        trigger?.('lender.approvedLenderCode');

        // eslint-disable-next-line no-unused-expressions
        trigger?.('lender.approvedLenderName');

        return;
      }
    }

    // eslint-disable-next-line no-unused-expressions
    setValueHandler?.('lender.approvedLenderCode', '');

    // eslint-disable-next-line no-unused-expressions
    setValueHandler?.('lender.approvedLenderName', '');
  };

  return (
    <LinedCard
      id={props.id}
      testId={props.testId}
      linePosition={props.linePosition}
      lineColor={props.accordionLineColor}
      role="form"
    >
      <AccordionContainer accordionId={`${props.id}Accordion`} title={props.title.field}>
        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            {isInternalSite || props.activeTab ? (
              <FormInputWholeNumber
                {...commonCardProps}
                id={`${props.id}InstitutionNumber`}
                name="lender.financialInstitutionCode"
                title={props.inputFields.institutionNumber.field}
                cardName={props.title.field.value}
                isRequired={true}
                allowLeadingZeros={true}
                isDisabled={isReadOnlyUser}
              />
            ) : (
              <FormInputDropdownText
                {...commonCardProps}
                id={`${props.id}InstitutionNumber`}
                name="lender.financialInstitutionCode"
                title={props.inputFields.institutionNumber.field}
                options={financialInstitutionOptions}
                handleChange={handleFinancialInstitutionCodeChange}
                cardName={props.title.field.value}
                isRequired={true}
              />
            )}
          </div>
          <div className="form__element form__element--2-column col-6">
            {isInternalSite || props.activeTab ? (
              <FormInputWholeNumber
                {...commonCardProps}
                id={`${props.id}TransitNumber`}
                name="lender.transitNumber"
                title={props.inputFields.transitNumber.field}
                cardName={props.title.field.value}
                customErrorMessage={ValidationErrorMessageType.MaxString}
                isRequired={true}
                allowLeadingZeros={true}
                isDisabled={isReadOnlyUser}
              />
            ) : (
              <fieldset disabled={Object.keys(transitOptions).length === 1}>
                <FormInputDropdownText
                  {...commonCardProps}
                  id={`${props.id}TransitNumber`}
                  name="lender.transitNumber"
                  title={props.inputFields.transitNumber.field}
                  options={transitOptions}
                  handleChange={handleTransitCodeChange}
                  cardName={props.title.field.value}
                  isRequired={true}
                  isDisabled={isReadOnlyUser}
                />
              </fieldset>
            )}
          </div>
        </div>

        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-12">
            <FormInputWholeNumber
              {...commonCardProps}
              id={`${props.id}ApprovedLenderCode`}
              name="lender.approvedLenderCode"
              title={props.inputFields.lenderCode.field}
              cardName={props.title.field.value}
              allowLeadingZeros={true}
              isDisabled={isReadOnlyUser || !isInternalSite}
            />
          </div>
        </div>

        <hr className="card__divider--t0" />

        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <fieldset disabled={!isInternalSite}>
              <FormInputTextInput
                {...commonCardProps}
                id={`${props.id}ApprovedLenderName`}
                name="lender.approvedLenderName"
                title={props.inputFields.approvedLenderName.field}
                type="text"
                cardName={props.title.field.value}
                isRequired={true}
                isDisabled={isReadOnlyUser}
              />
            </fieldset>
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputTextInput
              {...commonCardProps}
              id={`${props.id}LenderReferenceNumber`}
              name="lender.approvedLenderReferenceNumber"
              title={props.inputFields.lenderReferenceNumber.field}
              type="text"
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>

        <div className="row card__body-row">
          <div
            className={`form__element form__element--2-column col-6 ${
              i18n.language === LanguageSwitchTypeCode.French ? 'pullDown' : ''
            }`.trim()}
          >
            <FormInputTextInput
              {...commonCardProps}
              id={`${props.id}LenderContactName`}
              name="lender.contactName"
              title={props.inputFields.lenderContactName.field}
              type="text"
              cardName={props.title.field.value}
              autoComplete="section-lender name"
              isDisabled={isReadOnlyUser}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputTextInput
              {...commonCardProps}
              id={`${props.id}LenderContactPhoneNumber`}
              name="lender.contactPhoneNumber"
              title={props.inputFields.lenderContactPhoneNumber.field}
              type="tel"
              cardName={props.title.field.value}
              isRequired={false}
              autoComplete="section-lender tel"
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
        <div className="row card__body-row">
          <div
            className={`form__element form__element--2-column col-6 ${
              i18n.language === LanguageSwitchTypeCode.French ? 'pullDown' : ''
            }`.trim()}
          >
            <FormInputTextInput
              {...commonCardProps}
              id={`${props.id}LenderExtension`}
              name="lender.contactPhoneNumberExtension"
              title={props.inputFields.lenderExtension.field}
              type="text"
              cardName={props.title.field.value}
              isRequired={false}
              autoComplete="section-lender tel-extension"
              isDisabled={isReadOnlyUser}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputFormattedTextInput
              {...commonCardProps}
              id={`${props.id}LenderContactEmail`}
              name="lender.contactEmail"
              title={props.inputFields.lenderContactEmail.field}
              type="text"
              cardName={props.title.field.value}
              isRequired={true}
              autoComplete="section-lender email"
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <FormInputDropdownText
              {...commonCardProps}
              id={`${props.id}LanguageOfCorrespondence`}
              name="other.correspondenceLanguage"
              title={props.inputFields.languageOfCorrespondence.field}
              options={[
                {
                  label: i18n.t('DefaultSubmission-LanguageDropdown-Label-English'),
                  value: LanguageTypeCode.English,
                  selected: !!(i18n.language && i18n.language === LanguageSwitchTypeCode.English)
                },
                {
                  label: i18n.t('DefaultSubmission-LanguageDropdown-Label-French'),
                  value: LanguageTypeCode.French,
                  selected: !!(i18n.language && i18n.language === LanguageSwitchTypeCode.French)
                }
              ]}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
      </AccordionContainer>
    </LinedCard>
  );
};
