import React, { FC } from 'react';
import { TextProps } from '@sitecore-jss/sitecore-jss-react/types/components/Text';
import ErrorMessage from 'Constants/Utils/ErrorMessage/errorMessage';
import styles from './styles.module.scss';

type Props = {
  name: string;
  message: string | undefined;
  type: string;
  className?: string;
  label?: TextProps['field'] | string;
};

const formatLabel = (label: Props['label']) => {
  if (typeof label === 'object' && label.value != null) {
    return label.value;
  }
  const labelElement = document.getElementById(label as string);
  return labelElement?.innerText ?? '';
};

const FormError: FC<Props> = ({ name, type, message, className, label }) => {
  if (!message) return null;
  const errorMessageId = `${name}_error_message`;
  const messageClass = `${styles.message}${className ? ` ${className}` : ''}`;

  return (
    <span id={errorMessageId} className={messageClass}>
      {ErrorMessage(type, message, undefined, formatLabel(label) as string, name) || message}
    </span>
  );
};

export default FormError;
