import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Controller } from 'react-hook-form';
import fr from 'date-fns/locale/fr';
import DatePicker, { registerLocale } from 'react-datepicker';
import _ from 'lodash-es';
import i18next from 'i18next';
import { FormInputCalendarProps } from './FormInputCalendar.types';
import 'react-datepicker/dist/react-datepicker.css';
import './FormInputCalendar.css';
import { format } from '../../../../Constants/format';
import GetErrorMessage from '../ErrorMessage';

registerLocale('fr', fr);

const FormInputCalendar: React.FC<FormInputCalendarProps> = (props) => {
  const errors = props.errors && _.get(props.errors, `${props.name}`);
  // Added to address IE compatability
  const nodeRef = React.createRef<HTMLDivElement>();
  const setFocus = () => {
    const node = nodeRef.current;
    if (node) {
      node.focus();
    }
  };

  return (
    <div className="form__element form__element--2-column">
      <label
        htmlFor={`${props.id}CalendarInput`}
        id={`${props.id}Label`}
        className={(errors ? 'form-error__label ' : '') + (props.hideTitle ? ' sr-only' : '')}
      >
        <Text field={props.title} />
      </label>
      {props.description && <small>{props.description.value}</small>}
      <div className="date__container">
        <Controller
          control={props.control}
          name={props.name}
          defaultValue={(props.getValues && props.getValues(props.name)) || ''}
          render={({ field: { onBlur, value } }) => (
            <DatePicker
              id={`${props.id}CalendarInput`}
              ariaLabelledBy={`${props.id}CalendarInput`}
              className={`date__picker ${errors ? 'form-error__field' : ''}`}
              selected={value ? new Date(value) : value}
              onChange={(date) => {
                // Set date to ISOString to be compatible with JOI schema date types
                const dt = props.dateOnly
                  ? date && (date as Date).toISOString().substring(0, 10)
                  : date && (date as Date).toISOString();
                props.setValueHandler &&
                  props.setValueHandler(props.name, dt, { shouldDirty: true });
              }}
              onSelect={() => {
                onBlur();
                setFocus();
              }}
              showYearDropdown
              dropdownMode="select"
              locale={i18next.language || 'en'}
              onBlur={onBlur}
              {...props.register}
              autoComplete="off"
              shouldCloseOnSelect
              dateFormat={format.date}
              {...(props.disabled && { disabled: props.disabled })}
              {...(props.isRequired && { required: props.isRequired })}
              aria-describedby={`${props.id}ErrorDesc`}
              disabled={props.isDisabled}
            />
          )}
        />
      </div>
      {!props.hideTitle && (
        <div className="form__fieldNotificationHolder">
          {errors && (
            <span
              className="form-error__sub-text"
              aria-live="assertive"
              id={`${props.id}ErrorDesc`}
            >
              {GetErrorMessage(
                errors?.type as string,
                errors.message as string,
                undefined,
                props?.title?.value as string
              )}
              <input
                type="hidden"
                className="thisFieldHasErrors"
                value={props.cardName && props.cardName}
              />
            </span>
          )}
        </div>
      )}
      <div ref={nodeRef}></div>
    </div>
  );
};

export default FormInputCalendar;
