import React from 'react';
import i18n from 'i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { ApproverReview } from '@hobt/claim-domain';
import { ApprovalDecisionCode } from '@hobt/constants';

import { Tabs } from 'Components/Navigation/Tabs';
import { CardHeader } from 'Components/Card/CardHeader';
import { DataTable } from 'Components/Common/DataTable';
import ApprovalForm from 'Feature/ApprovalsFinancialAuthority/components/ApprovalForm';
import { SearchBar } from 'Components/PageComponents/SearchBar';
import { useDataGridEngine } from 'Components/Hooks/DataGridEngine';
import { DataTableFooter } from 'Components/Common/DataTableFooter';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { SearchFieldOptions } from 'Components/PageComponents/SearchBar/types';
import { PageCardContainer } from 'Components/PageComponents/PageCardContainer';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';

import { handleEmpty } from 'Components/Common/Api/utils/HandleEmpty';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { BuildDropDownDictionary } from 'Components/Common/Helpers';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';
import ApprovalsFinancialAuthProps, {
  ApprovalsInventoryRecords
} from '../../models/ApprovalsFinancialAuthProps';
import { ApprovalsInventoryTabEnum, convertTimestampToShortDate } from '../../models/types';
import useFinancialAuthFunctions from './useFinancialAuthFunctions';
import { config } from '../../../../config';
import styles from './styles.module.scss';

const CLAIMS_TABS = [
  ApprovalsInventoryTabEnum.ClaimsInReview,
  ApprovalsInventoryTabEnum.ClaimsApproved
];

const ApprovalsFinancialAuth = ({ fields, rendering }: ApprovalsFinancialAuthProps) => {
  const {
    currTab,
    isActive,
    isClaimApproved,
    tabItems,
    isArrears,
    approvalId,
    queryLookup,
    approverLevel,
    isInventoryToast,
    arrearsPermission,
    onTabClick,
    onRowClick,
    onApiErrorCallback,
    onToastCloseHandler
  } = useFinancialAuthFunctions(fields);

  const isClaimTab = CLAIMS_TABS.includes(currTab?.name as ApprovalsInventoryTabEnum);

  const {
    rowData,
    totalItems,
    totalPages,
    currentPage,
    itemRangeTo,
    itemsPerPage,
    itemRangeFrom,
    isLoading: isInventoryLoading,
    onSort,
    onPagerClick,
    onSearch,
    onSearchReset,
    onItemsPerPageChange,
    getSortDirectionForField
  } = useDataGridEngine<ApprovalsInventoryRecords>({
    apiBaseUrl: isClaimTab
      ? config.claimApi.urls.getApprovals
      : config.arrearsApi.urls.getApprovals,
    onApiErrorCallback,
    currentTab: currTab,
    defaultItemsPerPage: 10,
    tabFilterQueryLookup: queryLookup
  });

  const [loadingStatus, setIsLoadingStatus] = React.useState<Spinner>({
    isLoading: false
  });

  const searchByFields: SearchFieldOptions[] = [
    {
      displayText: fields.cmhcLoanNumber.value || '',
      value: 'cmhcLoanAccountNumber',
      type: 'text'
    }
  ];

  const approvalTypesDictionary = BuildDropDownDictionary(
    fields?.approvalTypeCodes?.fields?.listItems
  );

  const getApproverRole = (row: ApprovalsInventoryRecords, typeOfApprover?: string) => {
    if (typeOfApprover === 'primary') {
      return fields?.userLevelList?.fields?.listItems?.find(
        (item: FieldListItem) =>
          item?.fields?.itemValue?.value === row?.primaryApproverRole?.toString()
      )?.fields?.itemName?.value;
    }

    return fields?.userLevelList?.fields?.listItems?.find(
      (item: FieldListItem) =>
        item?.fields?.itemValue?.value === row?.secondaryApproverRole?.toString()
    )?.fields?.itemName?.value;
  };

  const getApproverName = (row: ApprovalsInventoryRecords) => {
    if (Array.isArray(row.approverReviews) && row.approverReviews.length > 0) {
      if (row.approverReviews.length > 1) {
        const index = row.approverReviews?.findIndex(
          (approverReview: ApproverReview) =>
            approverReview.approvalDecisionCode === ApprovalDecisionCode.Approve
        );

        if (index > -1) {
          return row.approverReviews[index].approverName ?? '';
        }
      }

      return row.approverReviews[0].approverName ?? '';
    }

    return null;
  };

  return (
    <>
      <ContentLoadingModal
        display={loadingStatus?.isLoading}
        fields={{
          heading: { value: i18n.t(loadingStatus?.spinnerHeading ?? 'Global-In-Progress') },
          description: { value: i18n.t(loadingStatus?.spinnerDescription ?? 'Global-In-Progress') }
        }}
      />
      <ToastNotification
        isActive={isInventoryToast}
        type={ApplicationStates.ERROR}
        title={i18n.t('Globals-Toast-Server-Error-Title')}
        content={{
          value: i18n.t('DefaultsInventoryTable-SystemErrorToast-Message')
        }}
        onCloseCallback={onToastCloseHandler}
      />
      <PageCardContainer name="approvalsInventory">
        <CardHeader>
          <div className={styles.cardHeader}>
            <Text tag="h2" field={fields.title} />
          </div>
        </CardHeader>
        <Tabs
          className={styles.approvalsTab}
          items={tabItems}
          initialActiveTabName={arrearsPermission ? 'arrearsInReview' : 'approvalRequired'}
          tabOnClickCallback={onTabClick}
          ariaControlsName={fields.inventoryType.value ?? ''}
          label={fields.inventoryType}
        />

        {isClaimTab && (
          <div className={styles.tableToolsWrapper}>
            <div className={styles.searchBarWrapper}>
              <SearchBar
                name="approvalsSearchBar"
                fieldLabelAriaText={i18n.t('SearchBar-SearchField')}
                clearButtonAriaText={i18n.t('SearchBar-ClearButton')}
                searchButtonAriaText={i18n.t('SearchBar-SearchButton')}
                placeholderText={i18n.t('SearchBar-PlaceholderText')}
                searchFieldOptions={searchByFields}
                onClear={onSearchReset}
                onSearch={onSearch}
                count={totalItems}
              />
            </div>
          </div>
        )}

        <div className={styles.dataTable}>
          <DataTable
            name="approvals"
            isLoading={isInventoryLoading}
            caption={fields.tableCaption}
            scrollOnHorizontalOverflow
          >
            <DataTableHeader>
              <DataTableHeaderItem name="approvalType" displayText={fields.approvalType} />
              {isClaimTab && (
                <DataTableHeaderItem
                  name="cmhcLoanAccountNumber"
                  displayText={fields.cmhcLoanNumber}
                />
              )}
              <DataTableHeaderItem
                sortable
                onSortCallback={onSort}
                sortDirection={getSortDirectionForField('recordUpdatedtimestamp')}
                name="recordUpdatedtimestamp"
                displayText={fields.lastModifiedBy}
              />
              <DataTableHeaderItem
                name="primaryApproverRole"
                displayText={fields.primaryApproverRole}
              />
              <DataTableHeaderItem name="primaryApprover" displayText={fields.primaryApprover} />
              <DataTableHeaderItem
                name="primaryApprovalDate"
                displayText={fields.primaryApprovalDate}
              />
              <DataTableHeaderItem
                name="secondaryApproverRole"
                displayText={fields.secondaryApproverRole}
              />
              <DataTableHeaderItem
                name="secondaryApprover"
                displayText={fields.secondaryApprover}
              />
              <DataTableHeaderItem
                name="secondaryApprovalDate"
                displayText={fields.secondaryApprovalDate}
              />
              {!isClaimTab && (
                <DataTableHeaderItem
                  name="reportingPeriod"
                  displayText={fields.reportingPeriod ?? { value: '' }}
                />
              )}
            </DataTableHeader>
            <DataTableBody zebra>
              {rowData != null &&
                rowData.map((row: ApprovalsInventoryRecords, index: number) => (
                  <DataTableRow
                    name={`${(row.claimID || row.approvalID) ?? ''}-${index}`}
                    key={`${(row.claimID || row.approvalID) ?? ''}-${index}`}
                    onClick={() => onRowClick(row)}
                  >
                    <TextCell
                      name="approvalType"
                      text={
                        row.approvalType != null &&
                        handleEmpty(
                          approvalTypesDictionary.get(row.approvalType.toString()),
                          i18n.t('Global-Not-Available')
                        )
                      }
                    />
                    {isClaimTab && (
                      <TextCell
                        name="CmhcLoanAccountNumber"
                        text={handleEmpty(row.cmhcLoanNumber)}
                      />
                    )}
                    <TextCell
                      name="recordUpdatedtimestamp"
                      text={
                        row.lastModifiedDate != null &&
                        handleEmpty(convertTimestampToShortDate(row.lastModifiedDate))
                      }
                    />
                    <TextCell
                      name="primaryApproverRole"
                      text={
                        row.primaryApproverRole != null &&
                        handleEmpty(getApproverRole(row, 'primary'))
                      }
                    />
                    <TextCell
                      name="primaryApprover"
                      text={handleEmpty(
                        row.claimID == null ? row?.primaryApproverName ?? '' : getApproverName(row)
                      )}
                    />
                    <TextCell
                      name="primaryApprovalDate"
                      text={
                        row.primaryApproverDate != null &&
                        handleEmpty(convertTimestampToShortDate(row.primaryApproverDate))
                      }
                    />
                    <TextCell
                      name="secondaryApproverRole"
                      text={row.secondaryApproverRole != null && handleEmpty(getApproverRole(row))}
                    />
                    <TextCell
                      name="secondaryApprover"
                      text={handleEmpty(row.secondaryApproverName)}
                    />
                    <TextCell
                      name="secondaryApprovalDate"
                      text={
                        row.secondaryApproverDate != null &&
                        handleEmpty(convertTimestampToShortDate(row.secondaryApproverDate))
                      }
                    />
                    {!isClaimTab && (
                      <TextCell name="reportingPeriod" text={handleEmpty(row.reportingPeriod)} />
                    )}
                  </DataTableRow>
                ))}
            </DataTableBody>
          </DataTable>
        </div>

        {rendering?.placeholders?.approvalFormFlyout &&
          React.createElement(ApprovalForm, {
            isActive,
            isArrears,
            loadingStatus,
            approvalId,
            approverLevel,
            isClaimApproved,
            setIsLoadingCallback: setIsLoadingStatus,
            sideMenuClickHandler: onRowClick,
            fields: rendering.placeholders.approvalFormFlyout[0].fields
          })}

        <div className={styles.dataTableFooter}>
          <DataTableFooter
            name="manageUsersList"
            currentPage={currentPage}
            totalPages={totalPages || 1}
            itemsPerPageLabel={{
              value: i18n.t('DefaultsInventoryTable-ResultsPerPageDropdown-Title')
            }}
            itemsXofYText={`${itemRangeFrom || 0} - ${itemRangeTo || 0} ${i18n.t(
              'Globals-Grid-ResultsPerPageDropdown-Of'
            )}`}
            totalItems={totalItems}
            pagerClickCallback={onPagerClick}
            itemsPerPageOnChangeCallback={onItemsPerPageChange}
            itemsPerPageValue={itemsPerPage.toString()}
          />
        </div>
      </PageCardContainer>
    </>
  );
};

export default ApprovalsFinancialAuth;
