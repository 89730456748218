import { useState } from 'react';
import { AxiosResponse } from 'axios';
import {
  ApiClientConfig,
  ApiClient,
  ComparisonOperators,
  FilterInternalColumnNames
} from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { config } from '../../../../../config';
import { itmFlagsGrid } from './types';

export const useItmFlagsTable = () => {
  const { claimData } = useHBTFormContext();
  const loanNumber = claimData?.cmhcLoanAccountNumber;
  const itemsPerPage = 100;
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
  const [itmFlags, setItmFlags] = useState<itmFlagsGrid[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // API Client Config
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  const authContext = useAuthenticationContext();
  const { getWithAuth } = ApiClient(authContext, apiClientConfig);

  const composeInventoryUri = () => {
    const baseUri = config.preClaimItmApi.urls.inventory;
    const pageParams = `?filter_by=${FilterInternalColumnNames.CmhcLoanAccountNumber} ${ComparisonOperators.EQ} '${loanNumber}'&limit=${itemsPerPage}`;

    return baseUri + pageParams;
  };

  const getItmFlagsTableData = () => {
    setIsLoading(true);
    getWithAuth(composeInventoryUri())
      .then((res: AxiosResponse) => {
        setIsLoading(false);
        const responseData = res.data;
        setItmFlags(responseData.data);
      })
      .catch(() => {
        setShowErrorToast(true);
      });
  };

  const onErrorToastDismiss = () => {
    setShowErrorToast(false);
  };

  return {
    itmFlags,
    showErrorToast,
    isLoading,
    onErrorToastDismiss,
    getItmFlagsTableData
  };
};
