import * as React from 'react';
import { TabsItem } from 'Components/Navigation/Tabs/types';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import styles from './styles.module.scss';

export const TabItem: React.FC<TabsItem> = ({
  displayText,
  name,
  count,
  isActive,
  tabItemOnClickCallback,
  ariaControls,
  fields
}: TabsItem) => (
  <button
    name={`${name}Button`}
    className={`${styles.tabItem} ${isActive ? styles.tabItemIsCurrent : ''}`}
    data-testid={`${name}TabItemButtonTest`}
    onClick={(e) => {
      e.preventDefault();
      tabItemOnClickCallback({ displayText, name, count, isActive, fields });
    }}
    aria-label={`${displayText?.value !== undefined ? displayText.value : ''} ${
      count ? `(${count})` : ''
    }`}
    aria-controls={ariaControls}
    key={`${name}TabItemButton`}
    type="button"
    aria-expanded="false"
  >
    <span>
      <Text field={displayText} />
    </span>
    {count !== undefined && <span>{` (${count})`}</span>}
  </button>
);
