import React, { useState } from 'react';
import { AxiosError } from 'axios';
import i18n from 'i18next';

import { LanguageShort, ModuleMapping, UserRole } from '@hobt/constants';
import { useFeature } from 'flagged';

import { PageCardContainer } from 'Components/PageComponents/PageCardContainer';
import { PageCardHeaderWithAddButton } from 'Components/PageComponents/PageCardHeaderWithAddButton';
import { AddUnderwritingReviewForm } from 'Feature/PreClaims/components/PreClaimsUnderwritingReview/AddUnderwritingReviewForm/index';
import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { ActionButtonCell } from 'Components/Common/DataTable/DataTableRow/ActionButtonCell';
import { DataTableFooter } from 'Components/Common/DataTableFooter';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import { useDataGridEngine } from 'Components/Hooks/DataGridEngine';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { Modal } from 'Components/Common/Modal';
import { SearchBar } from 'Components/PageComponents/SearchBar';
import { MenuItemProps } from 'Components/Common/ActionMenu/types';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';

import { usePreClaimsUnderwritingReview } from 'Feature/PreClaims/components/PreClaimsUnderwritingReview/usePreClaimsUndewritingReview';
import { config } from '../../../../config';
import PreClaimsUnderwritingReviewProps, { UnderwritinReviewGridRow } from './types';

import styles from './styles.module.scss';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const PreClaimsUnderwritingReview = ({ fields }: PreClaimsUnderwritingReviewProps) => {
  const {
    showSuccessToast,
    showSideDrawer,
    showErrorToast,
    showDeleteErrorToast,
    showDeleteSuccessToast,
    isLoading: isDeletionLoading,
    onSuccessToastDismiss,
    onErrorToastDismiss,
    onAddUnderWritingReviewButtonClick,
    onSuccessSubmit,
    onErrorSubmit,
    onCancelForm,
    deleteUnderwritingReviewFlag
  } = usePreClaimsUnderwritingReview();

  const onApiErrorCallback = (e: AxiosError) => {
    // TODO: Handle API Error
    console.log(e);
  };
  const {
    rowData,
    refresh,
    onSearch,
    onSearchReset,
    onItemsPerPageChange,
    isLoading: isInventoryLoading,
    currentPage,
    itemsPerPage,
    onPagerClick,
    itemRangeTo,
    itemRangeFrom,
    totalItems,
    totalPages,
    getTableAccessibilityText
  } = useDataGridEngine<UnderwritinReviewGridRow>({
    apiBaseUrl: config.preClaimUnderwritingReviewApi.urls.inventory,
    defaultItemsPerPage: 20,
    onApiErrorCallback
  });
  const [showPageModal, setShowPageModal] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<UnderwritinReviewGridRow>();
  const [loadingStatus, setLoadingStatus] = useState<Spinner>({
    isLoading: false
  });
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);
  const isReadOnlyAccessUser: boolean =
    isUserInRoles(ModuleMapping.claim, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  const pageModal = {
    type: ApplicationStates.SUCCESS,
    style: 'success',
    title: fields.deleteItemConfirmation.fields.heading.value,
    content: fields.deleteItemConfirmation.fields.content.value,
    isActive: false,
    onCloseCallback: () => {
      setShowPageModal(false);
    }
  };
  const deleteUndewritingReviewItem = () => {
    deleteUnderwritingReviewFlag(deleteItem?.underwritingReviewRequiredID || '', refresh);
  };
  const primaryCallback = () => {
    deleteUndewritingReviewItem();
    setShowPageModal(false);
  };

  const secondaryCallback = () => {
    setShowPageModal(false);
  };

  const onDeleteClick = (id: number | string) => {
    const deleteObj: UnderwritinReviewGridRow | undefined = rowData?.find(
      (item) => item.underwritingReviewRequiredID === id
    );
    setDeleteItem(deleteObj);
    setShowPageModal(true);
  };
  const rowActionMenu: MenuItemProps[] = [
    {
      name: 'deleteButton',
      icon: fields.deleteIcon,
      displayText: fields.tableDeleteButtonText,
      onClickCallback: onDeleteClick,
      actionItemId: 0
    }
  ];

  return (
    <>
      <ContentLoadingModal
        display={loadingStatus?.isLoading}
        fields={{
          heading: { value: i18n.t(loadingStatus?.spinnerHeading ?? 'Globals-InProgress-Heading') },
          description: {
            value: i18n.t(loadingStatus?.spinnerDescription ?? 'Globals-InProgress-Description')
          }
        }}
      />
      <ToastNotification
        type={ApplicationStates.SUCCESS}
        isActive={showSuccessToast}
        title={i18n.t('Globals-Toast-Success-Title')}
        content={{ value: i18n.t('Globals-Toast-Success-Item-Saved') }}
        onCloseCallback={onSuccessToastDismiss}
      />
      <ToastNotification
        type={ApplicationStates.ERROR}
        isActive={showErrorToast}
        title={i18n.t('Globals-Toast-Error-Title')}
        content={{ value: i18n.t('Globals-Toast-Error-Item-Not-Saved') }}
        onCloseCallback={onErrorToastDismiss}
      />
      <ToastNotification
        type={ApplicationStates.SUCCESS}
        isActive={showDeleteSuccessToast}
        title={i18n.t('Globals-Toast-Success-Title')}
        content={fields.toastSuccesfullyDeleted.value}
        onCloseCallback={onSuccessToastDismiss}
      />
      <ToastNotification
        type={ApplicationStates.ERROR}
        isActive={showDeleteErrorToast}
        title={i18n.t('DefaultActions-SystemErrorToast-Action-Title')}
        content={{ value: i18n.t('DefaultActions-SystemErrorToast-Action-Message') }}
        onCloseCallback={onErrorToastDismiss}
      />
      <Modal
        type={pageModal.type}
        style={pageModal.style}
        isActive={showPageModal}
        title={pageModal.title || ''}
        onCloseCallback={() => setShowPageModal(false)}
        onPrimaryCallback={primaryCallback}
        onSecondaryCallback={secondaryCallback}
        content={pageModal.content}
        fields={{
          saveButton: {
            value: fields.deleteItemConfirmation.fields.acceptButton.value
          },
          cancelButton: { value: fields.deleteItemConfirmation.fields.cancelButton.value }
        }}
        isLoading={isDeletionLoading}
      />
      <PageCardContainer name="preClaimsRequiredFlags">
        <div
          className={`${styles.preClaimsUnderwritingReviewContainer} ${
            i18n.language === LanguageShort.French
              ? styles.preClaimsUnderwritingReviewContainerFrench
              : ''
          }`.trim()}
        >
          <PageCardHeaderWithAddButton
            title={fields.pageTitle}
            addButtonText={fields.addUnderwritingBtn}
            addButtonIsSecondary={false}
            addButtonCallback={onAddUnderWritingReviewButtonClick}
            disabled={isReadOnlyAccessUser}
          />
        </div>
        <div className={styles.searchContainer}>
          <SearchBar
            name="UnderwritingSearchBar"
            fieldLabelAriaText={fields.searchPlaceholderTextPrefix?.value || ''}
            searchButtonAriaText={fields.searchButtonAriaLabel?.value || ''}
            clearButtonAriaText={fields.searchButtonClearAriaLabel?.value || ''}
            placeholderText={fields.searchPlaceholderTextPrefix?.value || ''}
            onSearch={onSearch}
            onClear={onSearchReset}
            searchFieldOptions={[
              {
                displayText:
                  fields.searchFilters?.[0]?.fields?.listItems?.[0]?.fields?.itemName?.value || '',
                value: 'CmhcLoanAccountNumber',
                type: 'number'
              }
            ]}
            count={totalItems}
          />
        </div>

        {rowData && rowData?.length > 0 && (
          <>
            <div className={styles.dataTable}>
              <DataTable
                name="preClaimsUnderwritingReviewDataTable"
                isLoading={isInventoryLoading}
                caption={{ value: getTableAccessibilityText() ?? '' }}
                scrollOnHorizontalOverflow
              >
                <DataTableHeader>
                  {!isReadOnlyAccessUser && (
                    <DataTableHeaderItem
                      name="rowAction"
                      customStyles={`${styles.rowAction}`}
                      displayText={{}}
                    />
                  )}
                  <DataTableHeaderItem
                    name="cmhcLoan"
                    displayText={fields.tableColHeaderLoanNumber}
                    customStyles={`${styles.cmhcLoan}`}
                  />
                  <DataTableHeaderItem
                    name="flaggedBy"
                    displayText={fields.tableColHeaderFlaggedBy}
                  />
                </DataTableHeader>

                <DataTableBody zebra>
                  {rowData?.map(
                    (row: UnderwritinReviewGridRow): JSX.Element => (
                      <DataTableRow
                        name={row.underwritingReviewRequiredID}
                        key={row.underwritingReviewRequiredID}
                      >
                        {!isReadOnlyAccessUser && (
                          <ActionButtonCell
                            name="rowActionMenu"
                            rowActionItems={rowActionMenu?.map((i) => ({
                              ...i,
                              actionItemId: row.underwritingReviewRequiredID
                            }))}
                            rowId={row.underwritingReviewRequiredID}
                          />
                        )}
                        <TextCell
                          name="cmhcLoanAccountNumber"
                          text={
                            row.cmhcLoanAccountNumber
                              ? row.cmhcLoanAccountNumber.toString(10)
                              : '--'
                          }
                        />
                        <TextCell
                          name="discloserIndicator"
                          text={
                            row.discloserIndicator
                              ? fields.addUnderwritingReview?.fields.flaggedByOptions?.[0].fields.listItems?.find(
                                  (item: SitecoreListItem) =>
                                    Number(item?.fields.itemValue?.value) ===
                                    Number(row.discloserIndicator)
                                )?.fields.itemName?.value || ''
                              : '--'
                          }
                        />
                      </DataTableRow>
                    )
                  )}
                </DataTableBody>
              </DataTable>
            </div>
            <DataTableFooter
              name="PropertyRepairsTableFooter"
              currentPage={currentPage}
              totalPages={totalPages || 1}
              itemsPerPageLabel={{
                value: i18n.t('DefaultsInventoryTable-ResultsPerPageDropdown-Title')
              }}
              itemsXofYText={`${itemRangeFrom} - ${itemRangeTo}
              ${i18n.t('Globals-Grid-ResultsPerPageDropdown-Of')}`}
              totalItems={totalItems}
              pagerClickCallback={onPagerClick}
              itemsPerPageOnChangeCallback={onItemsPerPageChange}
              itemsPerPageOptions={[
                { fields: { itemName: { value: '10' }, itemValue: { value: '10' } } },
                { fields: { itemName: { value: '20' }, itemValue: { value: '20' } } },
                { fields: { itemName: { value: '50' }, itemValue: { value: '50' } } },
                { fields: { itemName: { value: '100' }, itemValue: { value: '100' } } }
              ]}
              itemsPerPageValue={itemsPerPage.toString()}
            />
          </>
        )}
      </PageCardContainer>
      <SideDrawer isActive={showSideDrawer} handleOutsideClick={onCancelForm}>
        <AddUnderwritingReviewForm
          name={`addUnderwritingReviewFlagsForm`}
          setIsLoadingCallback={setLoadingStatus}
          loadingStatus={loadingStatus}
          onErrorCallback={onErrorSubmit}
          onSuccessCallback={() => {
            onSuccessSubmit();
            refresh();
          }}
          onCancelCallback={onCancelForm}
          fields={fields.addUnderwritingReview?.fields}
        />
      </SideDrawer>
    </>
  );
};

export default PreClaimsUnderwritingReview;
