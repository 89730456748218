import { useFeature } from 'flagged';
import i18n from 'i18next';
import React, { useState, useEffect, useRef } from 'react';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { UserRole, Module } from '@hobt/constants';

import { TooltipIcon } from 'Components/Inputs/TooltipIcon';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { useDefaultFormActionsContext } from 'Feature/DefaultsInventory/components/DefaultFormContext';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

import { LinedCard, AccordionContainer } from '../../../../CommonComponents/AccordionComponents';
import FormInputTextInput from '../../FormInputTextInput';
import FormInputCurrency from '../../FormInputCurrency';
import FormInputCalendar from '../../FormInputCalendar';
import FormInputCheckbox from '../../FormInputCheckbox';
import FormNumberInput from '../../FormNumberInput';
import FormInputRange from '../../FormInputRange';
import FormInputWholeNumber from '../../FormInputWholeNumber';
import {
  ComponentType,
  SchemaToUse,
  TargetSection,
  SubmittedDefaultCheckboxStage
} from '../../../models/typeCode.types';
import { DefaultManagementToolsProps, StageThreeRowsProps } from './DefaultManagementTools.types';

import './DefaultManagementTools.css';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

export const StageThreeRows: React.FC<StageThreeRowsProps> = ({
  index,
  register,
  errors,
  setValueHandler,
  control,
  watch,
  getValues,
  ...props
}) => {
  const commonCardProps = {
    register,
    errors,
    setValueHandler,
    control,
    watch,
    getValues
  };

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);
  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;

  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  const schemaToUse: string =
    props.targetSection === TargetSection.DEFAULTSUBMISSION
      ? SchemaToUse.SUBMITTED
      : SchemaToUse.PROPOSED;

  return (
    <div
      className="row card__body-row row-defaultManagementTool"
      id={`${props.id}stageThree-${index}`}
    >
      <div className="form__element form__element--2-column col-5">
        <FormInputTextInput
          {...commonCardProps}
          id={`${props.id}stage3Tool-${index}`}
          name={`managementInformation.${schemaToUse}StageThree.toolDetail[${index}].tool`}
          title={props.inputFields.stage3Tool.field}
          type={ComponentType.Text}
          cardName={props.title.field.value}
          isRequired={true}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={props.isAutoFocus}
          isDisabled={isReadOnlyUser}
        />
      </div>
      <div className="form__element form__element--2-column col-5">
        <FormInputCurrency
          {...commonCardProps}
          id={`${props.id}stage3Amount-${index}`}
          name={`managementInformation.${schemaToUse}StageThree.toolDetail[${index}].amount`}
          title={props.inputFields.stage3Amount.field}
          cardName={props.title.field.value}
          isRequired={true}
          isDisabled={isReadOnlyUser}
        />
      </div>
      {index !== 0 && (
        <div className="form__element form__element--2-column col-2 card__deleteRow">
          <button
            className="ml-4 btn btn--icon btn__delete"
            type="button"
            onClick={() => props.removeStageThreeTool(index)}
            disabled={isReadOnlyUser || props.disableToolDelete}
          >
            <span>
              <TooltipIcon
                icon={props.deleteIcon}
                text={i18n.t('DefaultSubmission-Card-Delete')}
                className={'icon-24'}
              />
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export const DefaultManagementToolsContainer = (props: DefaultManagementToolsProps) => {
  const {
    id,
    register,
    errors,
    setValueHandler,
    control,
    watch,
    getValues,
    reset,
    inputFields,
    stage1,
    stage2,
    stage3,
    stage4,
    title
  } = props;

  const commonCardProps = {
    register,
    errors,
    setValueHandler,
    control,
    watch,
    getValues,
    reset
  };

  const schemaToUse: string =
    props.targetSection === TargetSection.DEFAULTSUBMISSION
      ? SchemaToUse.SUBMITTED
      : SchemaToUse.PROPOSED;
  const isProposed = schemaToUse === SchemaToUse.PROPOSED;
  const watchManagementInformation =
    (watch && watch(`managementInformation.${schemaToUse}StageOne.prepayAndReBorrow`)) || {};
  const defaultFormActions = useDefaultFormActionsContext();

  const initialIdsArray = [];
  const cardCount = props.cardCount || 0;
  for (let i = 0; i < cardCount; i += 1) {
    initialIdsArray.push(i);
  }

  // state management for add/delete stage three rows:
  const [stageThreeRowId, setStageThreeRowId] = useState<number>(cardCount ? cardCount - 1 : 0);
  const [stageThreeRowIdsArray, setStageThreeRowIdsArray] = useState<number[]>(
    cardCount ? initialIdsArray : []
  );
  // lastToolIndexPreDelete for correct react-hook-form behavior during deletion
  const [lastToolIndexPreDelete, setLastToolIndexPreDelete] = useState<number>(
    cardCount ? cardCount - 1 : 0
  );
  // prevent multi-click deletes
  const [disableToolDelete, setDisableToolDelete] = useState<boolean>(false);
  const [isAutoFocus, setAutoFocus] = useState<boolean>(false);

  const isInitialMount = useRef(cardCount && !isProposed);
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  useEffect(() => {
    if (isInitialMount.current) {
      // prevent infinite re-renders on first mount
      isInitialMount.current = false;
    } else {
      // update array/ re-render, when stage three tool row is added
      setStageThreeRowIdsArray(stageThreeRowIdsArray.concat(stageThreeRowId));
    }
  }, [stageThreeRowId]);

  useEffect(() => {
    // update last index after adding/deleting a stage three tool row
    setLastToolIndexPreDelete(stageThreeRowIdsArray.length - 1);
    setDisableToolDelete(false); // re-enable delete after delete operation is completed
  }, [JSON.stringify(stageThreeRowIdsArray)]);

  useEffect(() => {
    register && register(`managementInformation.${schemaToUse}StageTwo.newAmortizationPeriod`);

    // Setting the defaultStage to an initial value of 0 to prevent error flag. This value will be calculated and updated
    // during submit
    register && register('managementInformation.submittedDefaultStage');
    setValueHandler && setValueHandler('managementInformation.submittedDefaultStage', 0);
  }, [watchManagementInformation]);

  function addStageThreeRow() {
    // update row id, when adding a card
    setStageThreeRowId(stageThreeRowId + 1);
    setAutoFocus(true);
  }

  function removeStageThreeTool(index: number): void {
    // manually setting own isDirty check for stage three tool deletions on details, and workout plan forms
    props.setStageThreeToolDeleted && props.setStageThreeToolDeleted(true);

    const stageThreeValues =
      (watch && watch(`managementInformation.${schemaToUse}StageThree.toolDetail`)) || {};

    if (Array.isArray(stageThreeValues)) {
      // type check
      for (let i = index; i < lastToolIndexPreDelete; i += 1) {
        // Reset values after index
        setValueHandler &&
          setValueHandler(
            `managementInformation.${schemaToUse}StageThree.toolDetail[${i}]`,
            stageThreeValues[i + 1]
          );
      }
    }

    // Remove last card values from react-hook-form
    setValueHandler &&
      setValueHandler(
        `managementInformation.${schemaToUse}StageThree.toolDetail[${lastToolIndexPreDelete}]`,
        {}
      );

    // Update cards' id array for re-render
    const stageThreeRowIdsArrayCopy = [...stageThreeRowIdsArray];
    stageThreeRowIdsArrayCopy.splice(index, 1);
    setStageThreeRowIdsArray(stageThreeRowIdsArrayCopy);

    // Disable multiclick
    setDisableToolDelete(true);
  }

  const isCombinationDefaultManagement =
    (watch && watch(`managementInformation.${schemaToUse}StageThree.combinationFlag`)) || false;

  const checkboxStage1 = (watch && watch(`managementInformation.${schemaToUse}StageOne`)) || {};
  const isConversionOfRepayment =
    checkboxStage1.conversionOfRepaymentArrangementToAFixedRateEqualPaymentMortgage || false;
  const isPrePayAndReBorrow = checkboxStage1.prepayAndReBorrow || false;
  const isMortgagePaymentDeferral = checkboxStage1.mortgagePaymentDeferral || false;

  const checkboxStage2 = (watch && watch(`managementInformation.${schemaToUse}StageTwo`)) || {};
  const isExtendedMortgagePaymentDeferral = checkboxStage2.extendedMortgagePaymentDeferral || false;
  const isExtensionOfAmortizationPeriod = checkboxStage2.extensionOfAmortizationPeriod || false;
  const isSpecialPaymentArrangement = checkboxStage2.specialPaymentArrangement || false;
  const isInterestOnlyPayment = checkboxStage2.interestOnlyPayment || false;
  const isCapitalization = checkboxStage2.capitalization || false;

  const checkboxStage3 = (watch && watch(`managementInformation.${schemaToUse}StageThree`)) || {};

  const checkboxStage4 = (watch && watch(`managementInformation.${schemaToUse}StageFour`)) || {};
  const isSaleByBorrower = checkboxStage4.saleByBorrower || false;
  const isRelocationAssistance =
    checkboxStage4.relocationAssistanceForQuitClaimOrVoluntaryTransferOfTitle || false;

  // Set submittedDefaultStage value if any of check box was selected accordingly
  if (
    isConversionOfRepayment ||
    isPrePayAndReBorrow ||
    isMortgagePaymentDeferral ||
    isExtendedMortgagePaymentDeferral ||
    isExtensionOfAmortizationPeriod ||
    isSpecialPaymentArrangement ||
    isInterestOnlyPayment ||
    isCapitalization ||
    isCombinationDefaultManagement ||
    isSaleByBorrower ||
    isRelocationAssistance
  ) {
    // Any of Stage One checkbox is selected
    if (isConversionOfRepayment || isPrePayAndReBorrow || isMortgagePaymentDeferral) {
      setValueHandler &&
        setValueHandler(
          `managementInformation.${schemaToUse}DefaultStage`,
          SubmittedDefaultCheckboxStage.CheckboxStageOne
        );
    }

    // Any of Stage Two checkbox is selected
    if (
      isExtendedMortgagePaymentDeferral ||
      isExtensionOfAmortizationPeriod ||
      isSpecialPaymentArrangement ||
      isInterestOnlyPayment ||
      isCapitalization
    ) {
      setValueHandler &&
        setValueHandler(
          `managementInformation.${schemaToUse}DefaultStage`,
          SubmittedDefaultCheckboxStage.CheckboxStageTwo
        );
    }

    // Any of Stage Three checkbox is selected
    if (isCombinationDefaultManagement) {
      setValueHandler &&
        setValueHandler(
          `managementInformation.${schemaToUse}DefaultStage`,
          SubmittedDefaultCheckboxStage.CheckboxStageThree
        );
    }

    // Any of Stage Four checkbox is selected
    if (isSaleByBorrower || isRelocationAssistance) {
      setValueHandler &&
        setValueHandler(
          `managementInformation.${schemaToUse}DefaultStage`,
          SubmittedDefaultCheckboxStage.CheckboxStageFour
        );
    }
  } else {
    // None of the checkbox is selected
    setValueHandler &&
      setValueHandler(
        `managementInformation.${schemaToUse}DefaultStage`,
        SubmittedDefaultCheckboxStage.NoCheckboxStage
      );
  }

  useEffect(() => {
    if (isExtensionOfAmortizationPeriod) {
      register && register(`managementInformation.${schemaToUse}StageTwo.newAmortizationPeriod`);
      setValueHandler &&
        setValueHandler(
          `managementInformation.${schemaToUse}StageTwo.newAmortizationPeriod`,
          '0000'
        );
    } else {
      setValueHandler &&
        setValueHandler(`managementInformation.${schemaToUse}StageTwo.newAmortizationPeriod`, '');
    }
  }, [isExtensionOfAmortizationPeriod]);

  function removeCardCheck(): boolean {
    if (checkboxStage1.conversionOfRepaymentArrangementToAFixedRateEqualPaymentMortgage) {
      if (
        checkboxStage1.newRate === undefined ||
        checkboxStage1.newRate === '' ||
        checkboxStage1.effectiveDate === null
      ) {
        return true;
      }
    }

    if (checkboxStage1.prepayAndReBorrow) {
      if (
        checkboxStage1.totalPrepay === undefined ||
        checkboxStage1.totalPrepay === null ||
        checkboxStage1.totalPrepay === '' ||
        checkboxStage1.totalReBorrowed === undefined ||
        checkboxStage1.totalReBorrowed === null ||
        checkboxStage1.totalReBorrowed === ''
      ) {
        return true;
      }
    }
    if (checkboxStage1.mortgagePaymentDeferral) {
      if (
        checkboxStage1.totalMonthsDeferred === undefined ||
        checkboxStage1.totalMonthsDeferred === null ||
        checkboxStage1.totalMonthsDeferred === '' ||
        checkboxStage1.totalDeferredAmount === undefined ||
        checkboxStage1.totalDeferredAmount === null ||
        checkboxStage1.totalDeferredAmount === ''
      ) {
        return true;
      }
    }

    if (checkboxStage2.extendedMortgagePaymentDeferral) {
      if (
        checkboxStage2.totalMonthsDeferred === undefined ||
        checkboxStage2.totalMonthsDeferred === null ||
        checkboxStage2.totalMonthsDeferred === '' ||
        checkboxStage2.totalDeferredAmount === undefined ||
        checkboxStage2.totalDeferredAmount === null ||
        checkboxStage2.totalDeferredAmount === ''
      ) {
        return true;
      }
    }
    if (checkboxStage2.extensionOfAmortizationPeriod) {
      if (
        checkboxStage2.newAmortizationPeriodYears === undefined ||
        checkboxStage2.newAmortizationPeriodYears === null ||
        checkboxStage2.newAmortizationPeriodYears === '' ||
        checkboxStage2.newAmortizationPeriodMonths === undefined ||
        checkboxStage2.newAmortizationPeriodMonths === null ||
        checkboxStage2.newAmortizationPeriodMonths === ''
      ) {
        return true;
      }
    }

    if (checkboxStage2.specialPaymentArrangement) {
      if (
        checkboxStage2.specialPaymentArrangementCalculateGrossDebtServiceRatioFrom === undefined ||
        checkboxStage2.specialPaymentArrangementCalculateGrossDebtServiceRatioFrom === null ||
        checkboxStage2.specialPaymentArrangementCalculateGrossDebtServiceRatioFrom === '' ||
        checkboxStage2.specialPaymentArrangementCalculateGrossDebtServiceRatioTo === undefined ||
        checkboxStage2.specialPaymentArrangementCalculateGrossDebtServiceRatioTo === null ||
        checkboxStage2.specialPaymentArrangementCalculateGrossDebtServiceRatioTo === '' ||
        checkboxStage2.specialPaymentArrangementCalculateTotalDebtServiceRatioFrom === undefined ||
        checkboxStage2.specialPaymentArrangementCalculateTotalDebtServiceRatioFrom === null ||
        checkboxStage2.specialPaymentArrangementCalculateTotalDebtServiceRatioFrom === '' ||
        checkboxStage2.specialPaymentArrangementCalculateTotalDebtServiceRatioTo === undefined ||
        checkboxStage2.specialPaymentArrangementCalculateTotalDebtServiceRatioTo === null ||
        checkboxStage2.specialPaymentArrangementCalculateTotalDebtServiceRatioTo === '' ||
        checkboxStage2.specialPaymentArrangementDateWhenAccountLastInCurrentPosition ===
          undefined ||
        checkboxStage2.specialPaymentArrangementDateWhenAccountLastInCurrentPosition === null ||
        checkboxStage2.specialPaymentArrangementDateWhenAccountLastInCurrentPosition === '' ||
        checkboxStage2.specialPaymentArrangementEffectiveDateForProposedPaymentFrom === undefined ||
        checkboxStage2.specialPaymentArrangementEffectiveDateForProposedPaymentFrom === null ||
        checkboxStage2.specialPaymentArrangementEffectiveDateForProposedPaymentFrom === '' ||
        checkboxStage2.specialPaymentArrangementEffectiveDateForProposedPaymentTo === undefined ||
        checkboxStage2.specialPaymentArrangementEffectiveDateForProposedPaymentTo === null ||
        checkboxStage2.specialPaymentArrangementEffectiveDateForProposedPaymentTo === '' ||
        checkboxStage2.specialPaymentArrangementProposedMonthlyPaymentChangeFrom === undefined ||
        checkboxStage2.specialPaymentArrangementProposedMonthlyPaymentChangeFrom === null ||
        checkboxStage2.specialPaymentArrangementProposedMonthlyPaymentChangeFrom === '' ||
        checkboxStage2.specialPaymentArrangementProposedMonthlyPaymentChangeTo === undefined ||
        checkboxStage2.specialPaymentArrangementProposedMonthlyPaymentChangeTo === null ||
        checkboxStage2.specialPaymentArrangementProposedMonthlyPaymentChangeTo === ''
      ) {
        return true;
      }
    }

    if (checkboxStage2.interestOnlyPayment) {
      if (
        checkboxStage2.interestOnlyPaymentAmount === undefined ||
        checkboxStage2.interestOnlyPaymentAmount === null ||
        checkboxStage2.interestOnlyPaymentAmount === '' ||
        checkboxStage2.interestOnlyPaymentDate === undefined ||
        checkboxStage2.interestOnlyPaymentDate === null ||
        checkboxStage2.interestOnlyPaymentDate === ''
      ) {
        return true;
      }
    }

    if (checkboxStage2.capitalization) {
      if (
        checkboxStage2.amountOfCapitalization === undefined ||
        checkboxStage2.amountOfCapitalization === null ||
        checkboxStage2.amountOfCapitalization === '' ||
        checkboxStage2.capitalizationCalculateGrossDebtServiceRatioFrom === undefined ||
        checkboxStage2.capitalizationCalculateGrossDebtServiceRatioFrom === null ||
        checkboxStage2.capitalizationCalculateGrossDebtServiceRatioFrom === '' ||
        checkboxStage2.capitalizationCalculateGrossDebtServiceRatioTo === undefined ||
        checkboxStage2.capitalizationCalculateGrossDebtServiceRatioTo === null ||
        checkboxStage2.capitalizationCalculateGrossDebtServiceRatioTo === '' ||
        checkboxStage2.capitalizationCalculateTotalDebtServiceRatioFrom === undefined ||
        checkboxStage2.capitalizationCalculateTotalDebtServiceRatioFrom === null ||
        checkboxStage2.capitalizationCalculateTotalDebtServiceRatioFrom === '' ||
        checkboxStage2.capitalizationCalculateTotalDebtServiceRatioTo === undefined ||
        checkboxStage2.capitalizationCalculateTotalDebtServiceRatioTo === null ||
        checkboxStage2.capitalizationCalculateTotalDebtServiceRatioTo === '' ||
        checkboxStage2.capitalizationProposedMonthlyPaymentChangeFrom === undefined ||
        checkboxStage2.capitalizationProposedMonthlyPaymentChangeFrom === null ||
        checkboxStage2.capitalizationProposedMonthlyPaymentChangeFrom === '' ||
        checkboxStage2.capitalizationProposedMonthlyPaymentChangeTo === undefined ||
        checkboxStage2.capitalizationProposedMonthlyPaymentChangeTo === null ||
        checkboxStage2.capitalizationProposedMonthlyPaymentChangeTo === '' ||
        checkboxStage2.purposeOfFunds === undefined ||
        checkboxStage2.purposeOfFunds === null ||
        checkboxStage2.purposeOfFunds === ''
      ) {
        return true;
      }
    }

    if (checkboxStage3.combinationFlag) {
      const isToolDetailValid = checkboxStage3.toolDetail?.every((value: any) => {
        if (
          value.tool === undefined ||
          value.tool === null ||
          value.tool === '' ||
          value.amount === undefined ||
          value.amount === null ||
          value.amount === ''
        ) {
          return false;
        }
        return true;
      });
      if (!isToolDetailValid) {
        return true;
      }
    }

    if (checkboxStage4.saleByBorrower) {
      if (
        checkboxStage4.appraisalValue === undefined ||
        checkboxStage4.appraisalValue === null ||
        checkboxStage4.appraisalValue === '' ||
        checkboxStage4.listPrice === undefined ||
        checkboxStage4.listPrice === null ||
        checkboxStage4.listPrice === ''
      ) {
        return true;
      }
    }

    if (checkboxStage4.relocationAssistanceForQuitClaimOrVoluntaryTransferOfTitle) {
      if (
        checkboxStage4.amountRequested === undefined ||
        checkboxStage4.amountRequested === null ||
        checkboxStage4.amountRequested === ''
      ) {
        return true;
      }
    }

    return false;
  }

  function addCardCheck(): boolean {
    let validCard = false;

    if (checkboxStage1.conversionOfRepaymentArrangementToAFixedRateEqualPaymentMortgage) {
      if (
        checkboxStage1.newRate !== undefined &&
        checkboxStage1.newRate !== '' &&
        checkboxStage1.effectiveDate !== null
      ) {
        validCard = true;
      } else {
        return false;
      }
    }
    if (checkboxStage1.prepayAndReBorrow) {
      if (
        checkboxStage1.totalPrepay !== undefined &&
        checkboxStage1.totalPrepay !== null &&
        checkboxStage1.totalPrepay !== '' &&
        checkboxStage1.totalReBorrowed !== undefined &&
        checkboxStage1.totalReBorrowed !== null &&
        checkboxStage1.totalReBorrowed !== ''
      ) {
        validCard = true;
      } else {
        return false;
      }
    }
    if (checkboxStage1.mortgagePaymentDeferral) {
      if (
        checkboxStage1.totalMonthsDeferred !== undefined &&
        checkboxStage1.totalMonthsDeferred !== null &&
        checkboxStage1.totalMonthsDeferred !== '' &&
        checkboxStage1.totalDeferredAmount !== undefined &&
        checkboxStage1.totalDeferredAmount !== null &&
        checkboxStage1.totalDeferredAmount !== ''
      ) {
        validCard = true;
      } else {
        return false;
      }
    }

    if (checkboxStage2.extendedMortgagePaymentDeferral) {
      if (
        checkboxStage2.totalMonthsDeferred !== undefined &&
        checkboxStage2.totalMonthsDeferred !== null &&
        checkboxStage2.totalMonthsDeferred !== '' &&
        checkboxStage2.totalDeferredAmount !== undefined &&
        checkboxStage2.totalDeferredAmount !== null &&
        checkboxStage2.totalDeferredAmount !== ''
      ) {
        validCard = true;
      } else {
        return false;
      }
    }
    if (checkboxStage2.extensionOfAmortizationPeriod) {
      if (
        checkboxStage2.newAmortizationPeriodYears !== undefined &&
        checkboxStage2.newAmortizationPeriodYears !== null &&
        checkboxStage2.newAmortizationPeriodYears !== '' &&
        checkboxStage2.newAmortizationPeriodMonths !== undefined &&
        checkboxStage2.newAmortizationPeriodMonths !== null &&
        checkboxStage2.newAmortizationPeriodMonths !== ''
      ) {
        validCard = true;
      } else {
        return false;
      }
    }
    if (checkboxStage2.specialPaymentArrangement) {
      if (
        checkboxStage2.specialPaymentArrangementCalculateGrossDebtServiceRatioFrom !== undefined &&
        checkboxStage2.specialPaymentArrangementCalculateGrossDebtServiceRatioFrom !== null &&
        checkboxStage2.specialPaymentArrangementCalculateGrossDebtServiceRatioFrom !== '' &&
        checkboxStage2.specialPaymentArrangementCalculateGrossDebtServiceRatioTo !== undefined &&
        checkboxStage2.specialPaymentArrangementCalculateGrossDebtServiceRatioTo !== null &&
        checkboxStage2.specialPaymentArrangementCalculateGrossDebtServiceRatioTo !== '' &&
        checkboxStage2.specialPaymentArrangementCalculateTotalDebtServiceRatioFrom !== undefined &&
        checkboxStage2.specialPaymentArrangementCalculateTotalDebtServiceRatioFrom !== null &&
        checkboxStage2.specialPaymentArrangementCalculateTotalDebtServiceRatioFrom !== '' &&
        checkboxStage2.specialPaymentArrangementCalculateTotalDebtServiceRatioTo !== undefined &&
        checkboxStage2.specialPaymentArrangementCalculateTotalDebtServiceRatioTo !== null &&
        checkboxStage2.specialPaymentArrangementCalculateTotalDebtServiceRatioTo !== '' &&
        checkboxStage2.specialPaymentArrangementDateWhenAccountLastInCurrentPosition !==
          undefined &&
        checkboxStage2.specialPaymentArrangementDateWhenAccountLastInCurrentPosition !== null &&
        checkboxStage2.specialPaymentArrangementDateWhenAccountLastInCurrentPosition !== '' &&
        checkboxStage2.specialPaymentArrangementEffectiveDateForProposedPaymentFrom !== undefined &&
        checkboxStage2.specialPaymentArrangementEffectiveDateForProposedPaymentFrom !== null &&
        checkboxStage2.specialPaymentArrangementEffectiveDateForProposedPaymentFrom !== '' &&
        checkboxStage2.specialPaymentArrangementEffectiveDateForProposedPaymentTo !== undefined &&
        checkboxStage2.specialPaymentArrangementEffectiveDateForProposedPaymentTo !== null &&
        checkboxStage2.specialPaymentArrangementEffectiveDateForProposedPaymentTo !== '' &&
        checkboxStage2.specialPaymentArrangementProposedMonthlyPaymentChangeFrom !== undefined &&
        checkboxStage2.specialPaymentArrangementProposedMonthlyPaymentChangeFrom !== null &&
        checkboxStage2.specialPaymentArrangementProposedMonthlyPaymentChangeFrom !== '' &&
        checkboxStage2.specialPaymentArrangementProposedMonthlyPaymentChangeTo !== undefined &&
        checkboxStage2.specialPaymentArrangementProposedMonthlyPaymentChangeTo !== null &&
        checkboxStage2.specialPaymentArrangementProposedMonthlyPaymentChangeTo !== ''
      ) {
        validCard = true;
      } else {
        return false;
      }
    }
    if (checkboxStage2.interestOnlyPayment) {
      if (
        checkboxStage2.interestOnlyPaymentAmount !== undefined &&
        checkboxStage2.interestOnlyPaymentAmount !== null &&
        checkboxStage2.interestOnlyPaymentAmount !== '' &&
        checkboxStage2.interestOnlyPaymentDate !== undefined &&
        checkboxStage2.interestOnlyPaymentDate !== null &&
        checkboxStage2.interestOnlyPaymentDate !== ''
      ) {
        validCard = true;
      } else {
        return false;
      }
    }
    if (checkboxStage2.capitalization) {
      if (
        checkboxStage2.amountOfCapitalization !== undefined &&
        checkboxStage2.amountOfCapitalization !== null &&
        checkboxStage2.amountOfCapitalization !== '' &&
        checkboxStage2.capitalizationCalculateGrossDebtServiceRatioFrom !== undefined &&
        checkboxStage2.capitalizationCalculateGrossDebtServiceRatioFrom !== null &&
        checkboxStage2.capitalizationCalculateGrossDebtServiceRatioFrom !== '' &&
        checkboxStage2.capitalizationCalculateGrossDebtServiceRatioTo !== undefined &&
        checkboxStage2.capitalizationCalculateGrossDebtServiceRatioTo !== null &&
        checkboxStage2.capitalizationCalculateGrossDebtServiceRatioTo !== '' &&
        checkboxStage2.capitalizationCalculateTotalDebtServiceRatioFrom !== undefined &&
        checkboxStage2.capitalizationCalculateTotalDebtServiceRatioFrom !== null &&
        checkboxStage2.capitalizationCalculateTotalDebtServiceRatioFrom !== '' &&
        checkboxStage2.capitalizationCalculateTotalDebtServiceRatioTo !== undefined &&
        checkboxStage2.capitalizationCalculateTotalDebtServiceRatioTo !== null &&
        checkboxStage2.capitalizationCalculateTotalDebtServiceRatioTo !== '' &&
        checkboxStage2.capitalizationProposedMonthlyPaymentChangeFrom !== undefined &&
        checkboxStage2.capitalizationProposedMonthlyPaymentChangeFrom !== null &&
        checkboxStage2.capitalizationProposedMonthlyPaymentChangeFrom !== '' &&
        checkboxStage2.capitalizationProposedMonthlyPaymentChangeTo !== undefined &&
        checkboxStage2.capitalizationProposedMonthlyPaymentChangeTo !== null &&
        checkboxStage2.capitalizationProposedMonthlyPaymentChangeTo !== '' &&
        checkboxStage2.purposeOfFunds !== undefined &&
        checkboxStage2.purposeOfFunds !== null &&
        checkboxStage2.purposeOfFunds !== ''
      ) {
        validCard = true;
      } else {
        return false;
      }
    }

    if (checkboxStage3.combinationFlag) {
      const isToolDetailValid = checkboxStage3.toolDetail?.every((value: any) => {
        if (
          value.tool !== undefined &&
          value.tool !== null &&
          value.tool !== '' &&
          value.amount !== undefined &&
          value.amount !== null &&
          value.amount !== ''
        ) {
          return true;
        }
        return false;
      });
      if (isToolDetailValid) {
        validCard = true;
      } else {
        return false;
      }
    }
    if (checkboxStage4.saleByBorrower) {
      if (
        checkboxStage4.appraisalValue !== undefined &&
        checkboxStage4.appraisalValue !== null &&
        checkboxStage4.appraisalValue !== '' &&
        checkboxStage4.listPrice !== undefined &&
        checkboxStage4.listPrice !== null &&
        checkboxStage4.listPrice !== ''
      ) {
        validCard = true;
      } else {
        return false;
      }
    }
    if (checkboxStage4.relocationAssistanceForQuitClaimOrVoluntaryTransferOfTitle) {
      if (
        checkboxStage4.amountRequested !== undefined &&
        checkboxStage4.amountRequested !== null &&
        checkboxStage4.amountRequested !== ''
      ) {
        validCard = true;
      } else {
        return false;
      }
    }

    return validCard;
  }

  useEffect(() => {
    if (defaultFormActions.isCardComplete('defaultManagementTools')) {
      if (
        !checkboxStage1.conversionOfRepaymentArrangementToAFixedRateEqualPaymentMortgage &&
        !checkboxStage1.prepayAndReBorrow &&
        !checkboxStage1.mortgagePaymentDeferral &&
        !checkboxStage2.extensionOfAmortizationPeriod &&
        !checkboxStage2.extendedMortgagePaymentDeferral &&
        !checkboxStage2.specialPaymentArrangement &&
        !checkboxStage2.interestOnlyPayment &&
        !checkboxStage2.capitalization &&
        !checkboxStage3.combinationFlag &&
        !checkboxStage4.saleByBorrower &&
        !checkboxStage4.relocationAssistanceForQuitClaimOrVoluntaryTransferOfTitle
      ) {
        defaultFormActions?.removeCompleteCard('defaultManagementTools');
      } else if (removeCardCheck()) {
        defaultFormActions?.removeCompleteCard('defaultManagementTools');
      }
    } else if (addCardCheck()) {
      defaultFormActions?.addCompleteCard('defaultManagementTools');
    }
  }, [checkboxStage1, checkboxStage2, checkboxStage3, checkboxStage4]);

  return (
    <div className={`${id}-container`} id={`${id}-container`}>
      <fieldset>
        <legend id={`${id}-legend-1`}>
          <h3 className="card__body-heading">
            <Text field={stage1.stage1Title.field} />
          </h3>
        </legend>
        <div className="row card__body-row">
          <div
            className={`form__element form__element--checkbox col-12 card_body-section card__body-checkboxSection ${id}-section-1-1`}
            id={`${id}Section1_1`}
          >
            <div
              className="form__element-checkbox-container checkboxBlock__section"
              id={`${id}CheckBlock1_1`}
            >
              <div className="row card__body-row mb-0">
                <div className="form__element form__element--2-column col-12">
                  <FormInputCheckbox
                    {...commonCardProps}
                    id={`${id}CheckboxS1B1`}
                    name={`managementInformation.${schemaToUse}StageOne.conversionOfRepaymentArrangementToAFixedRateEqualPaymentMortgage`}
                    title={stage1.checkboxBlock1.checkboxLabel.field}
                    cardName={title.field.value}
                    hideError={true}
                    isDisabled={isReadOnlyUser}
                  />
                </div>
              </div>
              {isConversionOfRepayment && (
                <div className="checkboxBlock__section-showContent" id={`${id}HiddenSection1_1`}>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <small className="mb-0">
                        <Text field={stage1.checkboxBlock1.blockSubText.field} />
                      </small>
                    </div>
                  </div>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <p className="mb-1">
                        <strong>
                          <Text field={stage1.checkboxBlock1.blockLenderAuthority.field} />
                        </strong>{' '}
                        <Text field={stage1.checkboxBlock1.blockLenderAuthorityText.field} />
                      </p>
                    </div>
                  </div>
                  <div className="row card__body-row">
                    <div className="form__element form__element--2-column col-6">
                      <FormNumberInput
                        {...commonCardProps}
                        id={`${id}Stage1NewRate`}
                        name={`managementInformation.${schemaToUse}StageOne.newRate`}
                        title={inputFields.stage1NewRate.field}
                        decimalScale={4}
                        cardName={title.field.value}
                        isRequired={true}
                        isDisabled={isReadOnlyUser}
                      />
                    </div>
                    <div className="form__element form__element--2-column col-6">
                      <FormInputCalendar
                        {...commonCardProps}
                        id={`${id}Stage1EffectiveDate`}
                        name={`managementInformation.${schemaToUse}StageOne.effectiveDate`}
                        title={inputFields.stage1EffectiveDate.field}
                        language={i18n.language || 'en'}
                        cardName={title.field.value}
                        disabled={isReadOnlyUser || props.disableCalendar}
                        isRequired={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row card__body-row">
          <div
            className={`form__element form__element--checkbox col-12 card_body-section card__body-checkboxSection ${id}-section-1-2`}
            id={`${id}Section1_2`}
          >
            <div
              className="form__element-checkbox-container checkboxBlock__section"
              id={`${id}CheckBlock1_2`}
            >
              <div className="row card__body-row mb-0">
                <div className="form__element form__element--2-column col-12">
                  <FormInputCheckbox
                    {...commonCardProps}
                    id={`${id}CheckboxS1B2`}
                    name={`managementInformation.${schemaToUse}StageOne.prepayAndReBorrow`}
                    title={stage1.checkboxBlock2.checkboxLabel.field}
                    cardName={title.field.value}
                    hideError={true}
                    isDisabled={isReadOnlyUser}
                  />
                </div>
              </div>
              {isPrePayAndReBorrow && (
                <div className="checkboxBlock__section-showContent" id={`${id}HiddenSection1_2`}>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <small className="mb-0">
                        <Text field={stage1.checkboxBlock2.blockSubText.field} />
                      </small>
                    </div>
                  </div>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <p className="mb-1">
                        <strong>
                          <Text field={stage1.checkboxBlock2.blockLenderAuthority.field} />
                        </strong>{' '}
                        <Text field={stage1.checkboxBlock2.blockLenderAuthorityText.field} />
                      </p>
                    </div>
                  </div>
                  <div className="row card__body-row">
                    <div className="form__element form__element--2-column col-6">
                      <FormInputCurrency
                        {...commonCardProps}
                        id={`${id}Stage1TotalPrePay`}
                        name={`managementInformation.${schemaToUse}StageOne.totalPrepay`}
                        title={inputFields.stage1TotalPrePay.field}
                        cardName={title.field.value}
                        isRequired={true}
                        isDisabled={isReadOnlyUser}
                      />
                    </div>
                    <div className="form__element form__element--2-column col-6">
                      <FormInputCurrency
                        {...commonCardProps}
                        id={`${id}Stage1TotalReBorrowed`}
                        name={`managementInformation.${schemaToUse}StageOne.totalReBorrowed`}
                        title={inputFields.stage1TotalReBorrowed.field}
                        cardName={title.field.value}
                        isRequired={true}
                        isDisabled={isReadOnlyUser}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row card__body-row">
          <div
            className={`form__element form__element--checkbox col-12 card_body-section card__body-checkboxSection ${id}-section-1-3`}
            id={`${id}Section1_3`}
          >
            <div
              className="form__element-checkbox-container checkboxBlock__section "
              id={`${id}CheckBlock1_3`}
            >
              <div className="row card__body-row mb-0">
                <div className="form__element form__element--2-column col-12">
                  <FormInputCheckbox
                    {...commonCardProps}
                    id={`${id}CheckboxS1B3`}
                    name={`managementInformation.${schemaToUse}StageOne.mortgagePaymentDeferral`}
                    title={stage1.checkboxBlock3.checkboxLabel.field}
                    cardName={title.field.value}
                    hideError={true}
                    isDisabled={isReadOnlyUser}
                  />
                </div>
              </div>
              {isMortgagePaymentDeferral && (
                <div className="checkboxBlock__section-showContent" id={`${id}HiddenSection1_3`}>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <small className="mb-0">
                        <Text field={stage1.checkboxBlock3.blockSubText.field} />
                      </small>
                    </div>
                  </div>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <p className="mb-1">
                        <strong>
                          <Text field={stage1.checkboxBlock3.blockLenderAuthority.field} />
                        </strong>{' '}
                        <Text field={stage1.checkboxBlock3.blockLenderAuthorityText.field} />
                      </p>
                    </div>
                  </div>
                  <div className="row card__body-row">
                    <div className="form__element form__element--2-column col-6">
                      <FormNumberInput
                        {...commonCardProps}
                        id={`${id}Stage1TotalMonthsDeferred`}
                        name={`managementInformation.${schemaToUse}StageOne.totalMonthsDeferred`}
                        title={inputFields.stage1TotalMonthsDeferred.field}
                        cardName={title.field.value}
                        isRequired={true}
                        isDisabled={isReadOnlyUser}
                      />
                    </div>
                    <div className="form__element form__element--2-column col-6">
                      <FormInputCurrency
                        {...commonCardProps}
                        id={`${id}Stage1TotalDeferredAmount`}
                        name={`managementInformation.${schemaToUse}StageOne.totalDeferredAmount`}
                        title={inputFields.stage1TotalDeferredAmount.field}
                        cardName={title.field.value}
                        isRequired={true}
                        isDisabled={isReadOnlyUser}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </fieldset>
      <hr className="card__divider" />

      <fieldset>
        <legend id={`${id}-legend-2`}>
          <h3 className="card__body-heading">
            <Text field={stage2.stage2Title.field} />
          </h3>
        </legend>
        <div className="row card__body-row">
          <div
            className={`form__element form__element--checkbox col-12 card_body-section card__body-checkboxSection ${id}-section-2-1`}
            id={`${id}Section2_1`}
          >
            <div
              className="form__element-checkbox-container checkboxBlock__section "
              id={`${id}CheckBlock2_1`}
            >
              <div className="row card__body-row mb-0">
                <div className="form__element form__element--2-column col-12">
                  <FormInputCheckbox
                    {...commonCardProps}
                    id={`${id}CheckboxS2B1`}
                    name={`managementInformation.${schemaToUse}StageTwo.extendedMortgagePaymentDeferral`}
                    title={stage2.checkboxBlock1.checkboxLabel.field}
                    cardName={title.field.value}
                    hideError={true}
                    isDisabled={isReadOnlyUser}
                  />
                </div>
              </div>
              {isExtendedMortgagePaymentDeferral && (
                <div className="checkboxBlock__section-showContent" id={`${id}HiddenSection2_1`}>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <small className="mb-0">
                        <Text field={stage2.checkboxBlock1.blockSubText.field} />
                      </small>
                    </div>
                  </div>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <p className="mb-1">
                        <strong>
                          <Text field={stage2.checkboxBlock1.blockLenderAuthority.field} />
                        </strong>{' '}
                        <Text field={stage2.checkboxBlock1.blockLenderAuthorityText.field} />
                      </p>
                    </div>
                  </div>
                  <div className="row card__body-row">
                    <div className="form__element form__element--2-column col-6">
                      <FormNumberInput
                        {...commonCardProps}
                        id={`${id}Stage2TotalMonthsDeferred`}
                        name={`managementInformation.${schemaToUse}StageTwo.totalMonthsDeferred`}
                        title={inputFields.stage2TotalMonthsDeferred.field}
                        cardName={title.field.value}
                        isRequired={true}
                        isDisabled={isReadOnlyUser}
                      />
                    </div>
                    <div className="form__element form__element--2-column col-6">
                      <FormInputCurrency
                        {...commonCardProps}
                        id={`${id}Stage2TotalDeferredAmount`}
                        name={`managementInformation.${schemaToUse}StageTwo.totalDeferredAmount`}
                        title={inputFields.stage2TotalDeferredAmount.field}
                        cardName={title.field.value}
                        isRequired={true}
                        isDisabled={isReadOnlyUser}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row card__body-row">
          <div
            className={`form__element form__element--checkbox col-12 card_body-section card__body-checkboxSection ${id}-section-2-2`}
            id={`${id}Section2_2`}
          >
            <div
              className="form__element-checkbox-container checkboxBlock__section "
              id={`${id}CheckBlock2_2`}
            >
              <div className="row card__body-row mb-0">
                <div className="form__element form__element--2-column col-12">
                  <FormInputCheckbox
                    {...commonCardProps}
                    id={`${id}CheckboxS2B2`}
                    name={`managementInformation.${schemaToUse}StageTwo.extensionOfAmortizationPeriod`}
                    title={stage2.checkboxBlock2.checkboxLabel.field}
                    cardName={title.field.value}
                    hideError={true}
                    isDisabled={isReadOnlyUser}
                  />
                </div>
              </div>
              {isExtensionOfAmortizationPeriod && (
                <div className="checkboxBlock__section-showContent" id={`${id}HiddenSection2-2`}>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <small className="mb-0">
                        <Text field={stage2.checkboxBlock2.blockSubText.field} />
                      </small>
                    </div>
                  </div>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <p className="mb-1">
                        <strong>
                          <Text field={stage2.checkboxBlock2.blockLenderAuthority.field} />
                        </strong>{' '}
                        <Text field={stage2.checkboxBlock2.blockLenderAuthorityText.field} />
                      </p>
                    </div>
                  </div>
                  <div className="row card__body-row">
                    <div className="form__element form__element--2-column col-12">
                      <label>
                        <Text field={inputFields.stage2NewAmortizationPeriod.field} />
                      </label>
                    </div>
                    <div className="form__element form__element--2-column col-6 mt-2">
                      <FormInputWholeNumber
                        {...commonCardProps}
                        id={`${id}Stage2NewAmortizationPeriod`}
                        name={`managementInformation.${schemaToUse}StageTwo.newAmortizationPeriodYears`}
                        title={inputFields.stage2CurrentNumberOfYears.field}
                        cardName={title.field.value}
                        isRequired={true}
                        isDisabled={isReadOnlyUser}
                        titleAsSubheading={true}
                      />
                    </div>
                    <div className="form__element form__element--2-column col-6 mt-2">
                      <FormInputWholeNumber
                        {...commonCardProps}
                        id={`${id}Stage2NewAmortizationPeriod`}
                        name={`managementInformation.${schemaToUse}StageTwo.newAmortizationPeriodMonths`}
                        title={inputFields.stage2CurrentNumberOfMonths.field}
                        cardName={title.field.value}
                        isRequired={true}
                        isDisabled={isReadOnlyUser}
                        titleAsSubheading={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row card__body-row">
          <div
            className={`form__element form__element--checkbox col-12 card_body-section card__body-checkboxSection ${id}-section-2-3`}
            id={`${id}Section2_3`}
          >
            <div
              className="form__element-checkbox-container checkboxBlock__section "
              id={`${id}CheckBlock2_3`}
            >
              <div className="row card__body-row mb-0">
                <div className="form__element form__element--2-column col-12">
                  <FormInputCheckbox
                    {...commonCardProps}
                    id={`${id}CheckboxS2B3`}
                    name={`managementInformation.${schemaToUse}StageTwo.specialPaymentArrangement`}
                    title={stage2.checkboxBlock3.checkboxLabel.field}
                    cardName={title.field.value}
                    hideError={true}
                    isDisabled={isReadOnlyUser}
                  />
                </div>
              </div>
              {isSpecialPaymentArrangement && (
                <div className="checkboxBlock__section-showContent" id={`${id}HiddenSection2_3`}>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <small className="mb-0">
                        <Text field={stage2.checkboxBlock3.blockSubText.field} />
                      </small>
                    </div>
                  </div>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <p className="mb-1">
                        <strong>
                          <Text field={stage2.checkboxBlock3.blockLenderAuthority.field} />
                        </strong>{' '}
                        <Text field={stage2.checkboxBlock3.blockLenderAuthorityText.field} />
                      </p>
                    </div>
                  </div>
                  <div className="row card__body-row">
                    <div
                      className={`form__element form__element--2-column ${
                        props.expandRange ? 'col-12' : 'col-6'
                      }`}
                    >
                      <FormInputCalendar
                        {...commonCardProps}
                        id={`${id}Stage2ADateAccountPosition`}
                        name={`managementInformation.${schemaToUse}StageTwo.specialPaymentArrangementDateWhenAccountLastInCurrentPosition`}
                        title={inputFields.stage2ADateAccountPosition.field}
                        language={i18n.language || 'en'}
                        cardName={title.field.value}
                        disabled={isReadOnlyUser || props.disableCalendar}
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="row card__body-row">
                    <div
                      className={`form__element form__element--2-column ${
                        props.expandRange ? 'col-12' : 'col-6'
                      }`}
                    >
                      <FormInputRange
                        {...commonCardProps}
                        id={`${id}_range`}
                        rangeFromName={`managementInformation.${schemaToUse}StageTwo.specialPaymentArrangementProposedMonthlyPaymentChangeFrom`}
                        rangeToName={`managementInformation.${schemaToUse}StageTwo.specialPaymentArrangementProposedMonthlyPaymentChangeTo`}
                        title={inputFields.stage2BProposedMonthlyPaymentChange.field}
                        rangeType={ComponentType.Currency}
                        cardName={title.field.value}
                        isRequired={true}
                      />
                    </div>
                    <div
                      className={`form__element form__element--2-column defaultSubEffectiveDate ${
                        props.expandRange ? 'col-12' : 'col-6'
                      }`}
                    >
                      <FormInputRange
                        {...commonCardProps}
                        id={`${id}_range`}
                        rangeFromName={`managementInformation.${schemaToUse}StageTwo.specialPaymentArrangementEffectiveDateForProposedPaymentFrom`}
                        rangeToName={`managementInformation.${schemaToUse}StageTwo.specialPaymentArrangementEffectiveDateForProposedPaymentTo`}
                        title={inputFields.stage2EffectiveDate.field}
                        rangeType={ComponentType.Date}
                        cardName={title.field.value}
                        disableCalendarRange={props.disableCalendar}
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="row card__body-row">
                    <div
                      className={`form__element form__element--2-column ${
                        props.expandRange ? 'col-12' : 'col-6'
                      }`}
                    >
                      <FormInputRange
                        {...commonCardProps}
                        id={`${id}_range`}
                        rangeFromName={`managementInformation.${schemaToUse}StageTwo.specialPaymentArrangementCalculateGrossDebtServiceRatioFrom`}
                        rangeToName={`managementInformation.${schemaToUse}StageTwo.specialPaymentArrangementCalculateGrossDebtServiceRatioTo`}
                        title={inputFields.stage2CalculateGDS_SpecialPayment.field}
                        rangeType={ComponentType.Currency}
                        disablePlaceholder={true}
                        enableSeparator={false}
                        disablePrefix={true}
                        disableSuffix={true}
                        cardName={title.field.value}
                        isRequired={true}
                      />
                    </div>
                    <div
                      className={`form__element form__element--2-column ${
                        props.expandRange ? 'col-12' : 'col-6'
                      }`}
                    >
                      <FormInputRange
                        {...commonCardProps}
                        id={`${id}_range`}
                        rangeFromName={`managementInformation.${schemaToUse}StageTwo.specialPaymentArrangementCalculateTotalDebtServiceRatioFrom`}
                        rangeToName={`managementInformation.${schemaToUse}StageTwo.specialPaymentArrangementCalculateTotalDebtServiceRatioTo`}
                        title={inputFields.stage2CalculateTDS_SpecialPayment.field}
                        rangeType={ComponentType.Currency}
                        disablePlaceholder={true}
                        enableSeparator={false}
                        disablePrefix={true}
                        disableSuffix={true}
                        cardName={title.field.value}
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="row card__body-row">
                    <div className="form__element form__element--2-column col-12">
                      <small>
                        <Text field={stage2.checkboxBlock3.blockBottomText!.field} />
                      </small>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row card__body-row">
          <div
            className={`form__element form__element--checkbox col-12 card_body-section card__body-checkboxSection ${id}-section-2-4`}
            id={`${id}Section2_4`}
          >
            <div
              className="form__element-checkbox-container checkboxBlock__section "
              id={`${id}CheckBlock2_4`}
            >
              <div className="row card__body-row mb-0">
                <div className="form__element form__element--2-column col-12">
                  <FormInputCheckbox
                    {...commonCardProps}
                    id={`${id}CheckboxS2B4`}
                    name={`managementInformation.${schemaToUse}StageTwo.interestOnlyPayment`}
                    title={stage2.checkboxBlock4.checkboxLabel.field}
                    cardName={title.field.value}
                    hideError={true}
                    isDisabled={isReadOnlyUser}
                  />
                </div>
              </div>
              {isInterestOnlyPayment && (
                <div className="checkboxBlock__section-showContent" id={`${id}HiddenSection2_4`}>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <small className="mb-0">
                        <Text field={stage2.checkboxBlock4.blockSubText.field} />
                      </small>
                    </div>
                  </div>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <p className="mb-1">
                        <strong>
                          <Text field={stage2.checkboxBlock4.blockLenderAuthority.field} />
                        </strong>{' '}
                        <Text field={stage2.checkboxBlock4.blockLenderAuthorityText.field} />
                      </p>
                    </div>
                  </div>
                  <div className="row card__body-row">
                    <div className="form__element form__element--2-column col-6">
                      <FormInputCurrency
                        {...commonCardProps}
                        id={`${id}Stage2InterestOnly`}
                        name={`managementInformation.${schemaToUse}StageTwo.interestOnlyPaymentAmount`}
                        title={inputFields.stage2InterestOnly.field}
                        cardName={title.field.value}
                        isRequired={true}
                        isDisabled={isReadOnlyUser}
                      />
                    </div>
                    <div className="form__element form__element--2-column col-6">
                      <FormInputCalendar
                        {...commonCardProps}
                        id={`${id}Stage2Date`}
                        name={`managementInformation.${schemaToUse}StageTwo.interestOnlyPaymentDate`}
                        title={inputFields.stage2Date.field}
                        language={i18n.language || 'en'}
                        cardName={title.field.value}
                        disabled={props.disableCalendar}
                        isRequired={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row card__body-row">
          <div
            className={`form__element form__element--checkbox col-12 card_body-section card__body-checkboxSection ${id}-section-2-5`}
            id={`${id}Section2_5`}
          >
            <div
              className="form__element-checkbox-container checkboxBlock__section "
              id={`${id}CheckBlock2_5`}
            >
              <div className="row card__body-row mb-0">
                <div className="form__element form__element--2-column col-12">
                  <FormInputCheckbox
                    {...commonCardProps}
                    id={`${id}CheckboxS2B5`}
                    name={`managementInformation.${schemaToUse}StageTwo.capitalization`}
                    title={stage2.checkboxBlock5.checkboxLabel.field}
                    cardName={title.field.value}
                    hideError={true}
                    isDisabled={isReadOnlyUser}
                  />
                </div>
              </div>
              {isCapitalization && (
                <div className="checkboxBlock__section-showContent" id={`${id}HiddenSection2_5`}>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <small className="mb-0">
                        <Text field={stage2.checkboxBlock5.blockSubText.field} />
                      </small>
                    </div>
                  </div>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <p className="mb-1">
                        <strong>
                          <Text field={stage2.checkboxBlock5.blockLenderAuthority.field} />
                        </strong>{' '}
                        <Text field={stage2.checkboxBlock5.blockLenderAuthorityText.field} />
                      </p>
                    </div>
                  </div>
                  <div className="row card__body-row">
                    <div
                      className={`form__element form__element--2-column ${
                        props.expandRange ? 'col-12' : 'col-6'
                      }`}
                    >
                      <FormInputRange
                        {...commonCardProps}
                        id={`${id}_range`}
                        rangeFromName={`managementInformation.${schemaToUse}StageTwo.capitalizationProposedMonthlyPaymentChangeFrom`}
                        rangeToName={`managementInformation.${schemaToUse}StageTwo.capitalizationProposedMonthlyPaymentChangeTo`}
                        title={inputFields.stage2AProposedMonthlyPaymentChange.field}
                        rangeType={ComponentType.Currency}
                        cardName={title.field.value}
                        isRequired={true}
                      />
                    </div>
                    <div
                      className={`form__element form__element--2-column ${
                        props.expandRange ? 'col-12' : 'col-6'
                      }`}
                    >
                      <FormInputRange
                        {...commonCardProps}
                        id={`${id}_range`}
                        rangeFromName={`managementInformation.${schemaToUse}StageTwo.capitalizationCalculateGrossDebtServiceRatioFrom`}
                        rangeToName={`managementInformation.${schemaToUse}StageTwo.capitalizationCalculateGrossDebtServiceRatioTo`}
                        title={inputFields.stage2CalculateGDS_Capitalization.field}
                        rangeType={ComponentType.Currency}
                        disablePlaceholder={true}
                        enableSeparator={false}
                        disablePrefix={true}
                        disableSuffix={true}
                        cardName={title.field.value}
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="row card__body-row">
                    <div
                      className={`form__element form__element--2-column ${
                        props.expandRange ? 'col-12' : 'col-6'
                      }`}
                    >
                      <FormInputRange
                        {...commonCardProps}
                        id={`${id}_range`}
                        rangeFromName={`managementInformation.${schemaToUse}StageTwo.capitalizationCalculateTotalDebtServiceRatioFrom`}
                        rangeToName={`managementInformation.${schemaToUse}StageTwo.capitalizationCalculateTotalDebtServiceRatioTo`}
                        title={inputFields.stage2CalculateTDS_Capitalization.field}
                        rangeType={ComponentType.Currency}
                        disablePlaceholder={true}
                        enableSeparator={false}
                        disablePrefix={true}
                        disableSuffix={true}
                        cardName={title.field.value}
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="row card__body-row">
                    <div className="form__element form__element--2-column col-6">
                      <FormInputCurrency
                        {...commonCardProps}
                        id={`${id}Stage2AmountOfCapitalization`}
                        name={`managementInformation.${schemaToUse}StageTwo.amountOfCapitalization`}
                        title={inputFields.stage2AmountOfCapitalization.field}
                        cardName={title.field.value}
                        isRequired={true}
                        isDisabled={isReadOnlyUser}
                      />
                    </div>
                    <div className="form__element form__element--2-column col-6">
                      <FormInputTextInput
                        {...commonCardProps}
                        id={`${id}Stage2PurposeOfFunds`}
                        name={`managementInformation.${schemaToUse}StageTwo.purposeOfFunds`}
                        title={inputFields.stage2PurposeOfFunds.field}
                        type={ComponentType.Text}
                        cardName={title.field.value}
                        isRequired={true}
                        isDisabled={isReadOnlyUser}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </fieldset>

      <hr className="card__divider" />

      <fieldset>
        <legend id={`${id}-legend-3`}>
          <h3 className="card__body-heading">
            <Text field={stage3.stage3Title.field} />
          </h3>
        </legend>
        <div className="row card__body-row">
          <div
            className={`form__element form__element--checkbox col-12 card_body-section card__body-checkboxSection ${id}-section-3-1`}
            id={`${id}Section3_1`}
          >
            <div
              className="form__element-checkbox-container checkboxBlock__section "
              id={`${id}CheckBlock3_1`}
            >
              <div className="row card__body-row mb-0">
                <div className="form__element form__element--2-column col-12">
                  <FormInputCheckbox
                    {...commonCardProps}
                    id={`${id}CheckboxS3B1`}
                    name={`managementInformation.${schemaToUse}StageThree.combinationFlag`}
                    title={stage3.checkboxBlock1.checkboxLabel.field}
                    cardName={title.field.value}
                    hideError={true}
                    isDisabled={isReadOnlyUser}
                  />
                </div>
              </div>
              {isCombinationDefaultManagement && (
                <div className="checkboxBlock__section-showContent" id={`${id}HiddenSection3_1`}>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <small className="mb-0">
                        <Text field={stage3.checkboxBlock1.blockSubText.field} />
                      </small>
                    </div>
                  </div>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <p className="mb-1">
                        <strong>
                          <Text field={stage3.checkboxBlock1.blockLenderAuthority.field} />
                        </strong>{' '}
                        <Text field={stage3.checkboxBlock1.blockLenderAuthorityText.field} />
                      </p>
                    </div>
                  </div>
                  {stageThreeRowIdsArray.map((rowId, index) => (
                    <StageThreeRows
                      {...props}
                      key={`${id}_row${index}`}
                      index={index}
                      lastIndexPreDelete={lastToolIndexPreDelete}
                      removeStageThreeTool={removeStageThreeTool}
                      disableToolDelete={disableToolDelete}
                      setDisableToolDelete={setDisableToolDelete}
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      isAutoFocus={isAutoFocus}
                    />
                  ))}
                  <div className="row card__body-row">
                    <div className="col-12">
                      <button className="btn btn__add" type="button" onClick={addStageThreeRow}>
                        <i
                          className="material-icons icon--size-32 icon--v-align-middle"
                          title={props.addToolText.field.value}
                          aria-hidden={true}
                        >
                          add_circle_outline
                        </i>
                        <Text field={props.addToolText.field} />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </fieldset>
      <hr className="card__divider" />

      <fieldset>
        <legend id={`${id}-legend-4`}>
          <h3 className="card__body-heading">
            <Text field={stage4.stage4Title.field} />
          </h3>
        </legend>
        <div className="row card__body-row">
          <div
            className={`form__element form__element--checkbox col-12 card_body-section card__body-checkboxSection ${id}-section-4-1`}
            id={`${id}Section4_1`}
          >
            <div
              className="form__element-checkbox-container checkboxBlock__section "
              id={`${id}CheckBlock4_1`}
            >
              <div className="row card__body-row mb-0">
                <div className="form__element form__element--2-column col-12">
                  <FormInputCheckbox
                    {...commonCardProps}
                    id={`${id}CheckboxS4B1`}
                    name={`managementInformation.${schemaToUse}StageFour.saleByBorrower`}
                    title={stage4.checkboxBlock1.checkboxLabel.field}
                    cardName={title.field.value}
                    hideError={true}
                    isDisabled={isReadOnlyUser}
                  />
                </div>
              </div>
              {isSaleByBorrower && (
                <div className="checkboxBlock__section-showContent" id={`${id}HiddenSection4_1`}>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <small className="mb-0">
                        <Text field={stage4.checkboxBlock1.blockSubText.field} />
                      </small>
                    </div>
                  </div>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <p className="mb-1">
                        <strong>
                          <Text field={stage4.checkboxBlock1.blockLenderAuthority.field} />
                        </strong>{' '}
                        <Text field={stage4.checkboxBlock1.blockLenderAuthorityText.field} />
                      </p>
                    </div>
                  </div>
                  <div className="row card__body-row">
                    <div className="form__element form__element--2-column col-6">
                      <FormInputCurrency
                        {...commonCardProps}
                        id={`${id}Stage4ListPrice`}
                        name={`managementInformation.${schemaToUse}StageFour.listPrice`}
                        title={inputFields.stage4ListPrice.field}
                        cardName={title.field.value}
                        isRequired={true}
                        isDisabled={isReadOnlyUser}
                      />
                    </div>
                    <div className="form__element form__element--2-column col-6">
                      <FormInputCurrency
                        {...commonCardProps}
                        id={`${id}Stage4AppraisalValue`}
                        name={`managementInformation.${schemaToUse}StageFour.appraisalValue`}
                        title={inputFields.stage4AppraisalValue.field}
                        cardName={title.field.value}
                        isRequired={true}
                        isDisabled={isReadOnlyUser}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row card__body-row">
          <div
            className={`form__element form__element--checkbox col-12 card_body-section card__body-checkboxSection ${id}-section-4-2`}
            id={`${id}Section4_2`}
          >
            <div
              className="form__element-checkbox-container checkboxBlock__section "
              id={`${id}CheckBlock4_2`}
            >
              <div className="row card__body-row mb-0">
                <div className="form__element form__element--2-column col-12">
                  <FormInputCheckbox
                    {...commonCardProps}
                    id={`${id}CheckboxS4B2`}
                    name={`managementInformation.${schemaToUse}StageFour.relocationAssistanceForQuitClaimOrVoluntaryTransferOfTitle`}
                    title={stage4.checkboxBlock2.checkboxLabel.field}
                    cardName={title.field.value}
                    hideError={true}
                    isDisabled={isReadOnlyUser}
                  />
                </div>
              </div>
              {isRelocationAssistance && (
                <div className="checkboxBlock__section-showContent" id={`${id}HiddenSection4_2`}>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <small className="mb-0">
                        <Text field={stage4.checkboxBlock2.blockSubText.field} />
                      </small>
                    </div>
                  </div>
                  <div className="row card__body-row checkboxBlock__contentRow">
                    <div className="form__element form__element--2-column col-12">
                      <p className="mb-1">
                        <strong>
                          <Text field={stage4.checkboxBlock2.blockLenderAuthority.field} />
                        </strong>{' '}
                        <Text field={stage4.checkboxBlock2.blockLenderAuthorityText.field} />
                      </p>
                    </div>
                  </div>
                  <div className="row card__body-row row-defaultManagementTool" id="">
                    <div className="form__element form__element--2-column col-6">
                      <FormInputCurrency
                        {...commonCardProps}
                        id={`${id}Stage4AmountRequested`}
                        name={`managementInformation.${schemaToUse}StageFour.amountRequested`}
                        title={inputFields.stage4AmountRequested.field}
                        cardName={title.field.value}
                        isRequired={true}
                        isDisabled={isReadOnlyUser}
                      />
                    </div>
                    <div className="form__element form__element--2-column col-6"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export const DefaultManagementTools: React.FC<DefaultManagementToolsProps> = (props) => (
  <LinedCard {...props.linedCardProps}>
    <AccordionContainer accordionId={`${props.id}Accordion`} title={props.title.field}>
      <DefaultManagementToolsContainer {...props} />
    </AccordionContainer>
  </LinedCard>
);
