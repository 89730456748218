import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import {
  DefaultDetailsResponse,
  DefaultDetailsUpdateResponse,
  DefaultRequestStatus
} from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';

import { withTranslation } from 'react-i18next';
import { DefaultsInventoryDetailsProps } from 'Feature/DefaultsInventory/models/DefaultInventoryDetails';
import { DefaultApiCallHandler } from 'Feature/DefaultsInventory/components/api/ApiCallHandlers';
import DefaultDetailsInternalCardHeader from 'Feature/DefaultsInventory/components/DefaultInventoryDetails/DetailsCardHeader/DefaultDetailsInternalCardHeader';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { useHistory } from 'react-router-dom';
import DefaultDetailsExternalCardHeader from 'Feature/DefaultsInventory/components/DefaultInventoryDetails/DetailsCardHeader/DefaultDetailsExternalCardHeader';
import { FormDropdownOption } from 'Feature/DefaultSubmissionForm/components/FormInputDropdownText/FormInputDropdownText.types';

import { Modal, ModalProps, ToastNotification } from 'Feature/CommonComponents/ContentComponents';

import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import SitecoreListItem, { SitecoreDropdownList } from 'Constants/Types/SitecoreListItem';
import ContentLoadingModal from '../../../PageComponents/components/ContentLoadingModal';
import { DefaultDetailPageContent } from './DefaultDetailPageContent/DefaultDetailPageContent';
import { config } from '../../../../config';

const DefaultInventoryDetails: React.FC<any> = (props: DefaultsInventoryDetailsProps) => {
  // Authentication
  const authContext = useAuthenticationContext();

  // Redirecting
  const history = useHistory();

  // Grab the default request ID from the URL
  const urlParams = new URLSearchParams(isServer() === false ? window.location.search : '');
  const rawUUID = urlParams.get('uuid');
  const parsedUUID = rawUUID !== null ? rawUUID : '';

  const {
    getDefaultSubmissionDetails,
    saveDefaultSubmissionChanges,
    updateSubmissionStatus,
    cancelTokenSource
  } = DefaultApiCallHandler(parsedUUID, authContext, { timeout: config.defaultApi.requestTimeout });

  // State
  const [detailsData, setDetailsData] = useState<any>();
  const [watchLoanValidation, setWatchLoanValidation] = useState<any>(false);
  const [showPageModal, setShowPageModal] = useState<boolean>(false);
  const [showToastNotification, setShowToastNotification] = useState(false);
  const [toastNotificationType, setToastNotificationType] = useState(ApplicationStates.ERROR);
  const [toastNotificationTitle, setToastNotificationTitle] = useState('');
  const [toastNotificationMsg, setToastNotificationMsg] = useState('');
  const [areDisabledCardBtns, setDisableCardBtns] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);

  const cancelOptions: FormDropdownOption[] = [];
  props.fields.cancelReasons.forEach((reasons: SitecoreDropdownList) => {
    reasons.fields.listItems.forEach((reason: SitecoreListItem) => {
      cancelOptions.push({
        label: reason.fields.itemName.value ?? '',
        value: reason.fields.itemValue.value ?? ''
      });
    });
  });

  const declineOptions: FormDropdownOption[] = [];
  props.fields.declineReasons.forEach((reasons: SitecoreDropdownList) => {
    reasons.fields.listItems.forEach((reason: SitecoreListItem) => {
      declineOptions.push({
        label: reason.fields.itemName.value ?? '',
        value: reason.fields.itemValue.value ?? ''
      });
    });
  });

  // Setting page modal state
  const [pageModal, setPageModal] = useState<ModalProps>({
    type: ApplicationStates.DEFAULT,
    title: '',
    isActive: false,
    onCloseCallback: () => {
      setShowPageModal(false);
    }
  });

  const showErrorToastNotification = () => {
    setToastNotificationType(ApplicationStates.ERROR);
    setToastNotificationTitle(i18n.t('DefaultActions-SystemErrorToast-Action-Title'));
    setToastNotificationMsg(i18n.t('DefaultActions-SystemErrorToast-Action-Message'));
    setShowToastNotification(true);
  };

  const showSuccessToastNotification = () => {
    setToastNotificationType(ApplicationStates.SUCCESS);
    setToastNotificationTitle(i18n.t('DefaultActions-SystemSuccessToast-Action-Title'));
    setToastNotificationMsg(i18n.t('DefaultActions-SystemSuccessToast-Action-Message'));
    setShowToastNotification(true);
  };

  // TODO: Define what happens when a save is successful
  // TODO: Define what happens when a save fails
  const onSubmit = async (isWorkoutPlan: boolean, data: any) => {
    setIsSaving(true);
    const payload =
      isWorkoutPlan && detailsData !== undefined
        ? { ...detailsData, managementInformation: data }
        : data;

    saveDefaultSubmissionChanges(payload)
      .then((res: DefaultDetailsUpdateResponse) => {
        setIsSaving(false);
        setDisableCardBtns(false);

        if (res.data != null) {
          // remove fields that won't be accepted by schema
          const responseData = res.data[0];
          delete responseData.documentAccessControlEntry;
          delete responseData?.managementInformation?.submittedStageTwo?.currentAmortizationPeriod;
          responseData.borrower = responseData.borrower.map((borrower) => {
            // eslint-disable-next-line no-param-reassign
            delete borrower.borrowerFullName;
            return borrower;
          });

          responseData.attachment = responseData.attachment?.map((attachment) => {
            // eslint-disable-next-line no-param-reassign
            delete attachment.acl;
            return attachment;
          });
          setDetailsData(responseData);
        }

        showSuccessToastNotification();
      })
      .catch(() => {
        setIsSaving(false);
        setDisableCardBtns(true);
        showErrorToastNotification();
      });
  };

  const isOnDetailsPage = () =>
    !isServer() ? window.location.pathname.includes('default-inventory/details') : true;

  const getData = () => {
    getDefaultSubmissionDetails()
      .then((res: DefaultDetailsResponse) => {
        if (res.data != null) {
          // remove fields that won't be accepted by schema
          const responseData = res.data;
          delete responseData.documentAccessControlEntry;
          delete responseData?.managementInformation?.submittedStageTwo?.currentAmortizationPeriod;
          responseData.borrower = responseData.borrower.map((borrower) => {
            // eslint-disable-next-line no-param-reassign
            delete borrower.borrowerFullName;
            return borrower;
          });

          responseData.attachment = responseData.attachment?.map((attachment) => {
            // eslint-disable-next-line no-param-reassign
            delete attachment.acl;
            return attachment;
          });
          setDetailsData(responseData);
        }
      })
      .catch(() => {
        if (isOnDetailsPage()) {
          showErrorToastNotification();
        }
      });
  };

  const handleDecisioningSubmission = (newStatus: DefaultRequestStatus, formModalData: any) => {
    updateSubmissionStatus(newStatus, formModalData)
      .then((res) => {
        if (res !== undefined && res.data !== undefined) {
          getData();
          const toastMsgToShow: { [key: number]: string } = {
            [DefaultRequestStatus.Approved]:
              'DefaultsInventoryTable-DecisioningToastMessage-Approve-Message',
            [DefaultRequestStatus.ApprovedWithModifications]:
              'DefaultsInventoryTable-DecisioningToastMessage-Approve-Message',
            [DefaultRequestStatus.Declined]:
              'DefaultsInventoryTable-DecisioningToastMessage-Decline-Message',
            [DefaultRequestStatus.Cancelled]:
              'DefaultsInventoryTable-DecisioningToastMessage-Cancel-Message',
            [DefaultRequestStatus.Inactive]:
              'DefaultsInventoryTable-DecisioningToastMessage-Inactive-Message'
          };

          if (isServer() === false) {
            window.localStorage.setItem(
              'DefaultsInventoryDecisioningToastMsg',
              toastMsgToShow[newStatus]
            );
            // Set tab once on default-inventory
            window.localStorage.setItem('DefaultsInventoryTab', 'all');
            window.localStorage.setItem('ToastIcon', `${newStatus}`);
            history.push(`/${i18n.language}/default-inventory`); // redirect to default inventory
          }
        } else {
          throw Error('Could not read server response');
        }
      })
      .catch(() => showErrorToastNotification());

    setShowPageModal(false);
  };

  // Get Detail Data
  useEffect(() => {
    getData();

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  return (
    <>
      <ContentLoadingModal
        display={isSaving}
        fields={{
          heading: { value: i18n.t('DefaultsInventoryDetails-Loading-Heading') },
          description: { value: i18n.t('DefaultsInventoryDetails-Loading-Description') }
        }}
      />

      <ToastNotification
        type={toastNotificationType}
        isActive={showToastNotification}
        title={toastNotificationTitle}
        content={toastNotificationMsg}
        onCloseCallback={() => setShowToastNotification(false)}
      />
      <Modal
        type={pageModal.type}
        isActive={showPageModal}
        title={pageModal.title}
        onCloseCallback={() => setShowPageModal(false)}
        content={pageModal.content}
      />
      <div className="row">
        <section className="col-12">
          <div className="card">
            <DefaultDetailsInternalCardHeader
              detailsData={detailsData}
              handleDecisioningSubmission={handleDecisioningSubmission}
              setPageModal={setPageModal}
              triggerModal={setShowPageModal}
              watchLoanValidation={watchLoanValidation}
              areDisabledCardBtns={areDisabledCardBtns}
              emailSubjectLine={props?.fields?.emailSubjectLine?.value || ''}
              cancelOptions={cancelOptions}
              declineOptions={declineOptions}
            />

            <DefaultDetailsExternalCardHeader
              detailsData={detailsData}
              handleDecisioningSubmission={() => {}}
              setPageModal={() => {}}
              triggerModal={() => {}}
              cancelOptions={cancelOptions}
              declineOptions={declineOptions}
              watchLoanValidation={null}
              emailSubjectLine={props?.fields?.emailSubjectLine?.value || ''}
            />

            <DefaultDetailPageContent
              detailsData={detailsData}
              onLoanWatchLoanValidationCallback={(watch) => {
                setWatchLoanValidation(watch);
              }}
              onDecisioningBtnsCallback={(watch) => {
                setDisableCardBtns(watch);
              }}
              onSubmitCallback={onSubmit}
              defaultSubmissionFormPropsFields={{
                downloadMode: true,
                title: props.fields.title,
                description: props.fields.description,
                uploadIcon: props.fields.uploadIcon,
                downloadIcon: props.fields.downloadIcon,
                deleteIcon: props.fields.deleteIcon,
                warningIcon: props.fields.warningIcon,
                cancelIcon: props.fields.cancelIcon,
                titleIcon: props.fields.titleIcon,
                sectionOneTitle: { value: 'test' },
                sectionTwoTitle: { value: 'test' },
                defaultRequestId: parsedUUID || '',
                attachmentTypeOptions: props.fields.attachmentTypeOptions,
                formWarningIcon: props.fields.formWarningIcon,
                formErrorIcon: props.fields.formErrorIcon,
                provinceOptions: props.fields.provinceOptions
              }}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default withTranslation()(DefaultInventoryDetails);
