import { useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { HbtServiceErrorCodes, HttpResponseStatusCodes } from '@hobt/constants';

import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

import { FinalClaimResponseData } from 'Feature/Claims/models/ClaimsSubmissionDetailsProps';
import { CommentPayloadFields } from './types';
import { config } from '../../../../../../../../config';
import { ErrorObject } from 'Feature/UserManagement/models/types';

const useUserCommentsFunctions = (
  onSuccessCallback?: Function,
  onErrorCallback?: Function,
  claimIdProps?: string
) => {
  const [showValidationError, setShowValidationError] = useState<boolean>(false);
  const { uuid, setLoadingStatus } = useHBTFormContext();

  let claimID = uuid;

  // API Client Config
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  const authContext = useAuthenticationContext();
  const { postWithAuth } = ApiClient(authContext, apiClientConfig);

  const handleUpdate = (data: CommentPayloadFields) => {
    if (claimID == null || claimID === '') {
      claimID = claimIdProps ?? '';
    }
    const payloadData = {
      claimID,
      remarkPayload: {
        userComment: data.userComment
      }
    };

    const url = config.claimApi.urls.finalClaimComments;

    setLoadingStatus?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description',
      isFlyout: true
    });

    postWithAuth(url, payloadData)
      // .then((submitData: UserCommentsRequestData) => {
      .then((submitData: AxiosResponse<{ data: FinalClaimResponseData[] }>) => {
        if (onSuccessCallback) {
          onSuccessCallback(submitData.data.data);
        }
        setLoadingStatus?.({
          isLoading: false
        });
      })
      .catch((error: ErrorObject) => {
        if (
          error?.response?.status === HttpResponseStatusCodes.BadRequest &&
          error?.response?.data.error.errorCode === HbtServiceErrorCodes.HBT_ERR_VALIDATION
        ) {
          setShowValidationError(true);
        } else if (onErrorCallback) {
          onErrorCallback();
        }

        setLoadingStatus?.({
          isLoading: false
        });
      });
  };

  const onSubmitErrors: any = (errs: Record<string, Object>) => {
    // TODO: Remove once validations are in place
    // eslint-disable-next-line no-console
    console.log('FORM ERRORS: ', errs);
  };

  const onCloseValidationErrors = () => {
    setShowValidationError(false);
  };

  return {
    handleUpdate,
    onSubmitErrors,
    onCloseValidationErrors,
    showValidationError
  };
};

export default useUserCommentsFunctions;
