import React, { FC } from 'react';
import { MenuItem, Checkbox } from '@mui/material';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import styles from '../styles.module.scss';
import { DropdownCheckboxItemProps } from '../types';

export const DropdownCheckboxItem: FC<DropdownCheckboxItemProps> = (
  props: DropdownCheckboxItemProps
) => {
  const checkboxIcon = <HbtIcon size={HbtIconSize.SMALL} type="icon_checkbox_default" />;

  const isMultiOptionSelected = (value: string, isGroup: boolean): boolean => {
    const selectedItem = props.selectedOptions?.find((opt) => !isGroup && opt.value == value);
    return !!selectedItem;
  };

  // In a custom Component inside a mui Select, the 'data-value' property is generated
  // in the props object and the value is not assigned anything. Use the data-value instead.
  const dataValue: string = (props as any)['data-value'] as string;

  return (
    <MenuItem
      {...props}
      key={dataValue}
      value={dataValue}
      className={styles.menuListItem}
      disableRipple
    >
      <Checkbox
        checked={isMultiOptionSelected(dataValue, props.isGroup)}
        sx={{
          color: '#00093A',
          '&.Mui-checked': {
            color: '#00093A'
          },

          width: '16px',
          height: '16px'
        }}
        icon={checkboxIcon}
      />
      <span className={styles.menuListItemText}>{props.label}</span>
    </MenuItem>
  );
};
