import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { DefaultRequestStatus } from 'Foundation/Api';

import DefaultSubmissionFormProps from 'Feature/DefaultSubmissionForm/models/DefaultSubmissionFormProps';
import DefaultFormContextProvider from 'Feature/DefaultsInventory/components/DefaultFormContext';
import DefaultDetailSubmissionFormExternal from 'Feature/DefaultsInventory/components/DefaultInventoryDetails/DefaultDetailSubmissionForm/DefaultDetailSubmissionFormExternal';
import { TargetSection } from 'Feature/DefaultSubmissionForm/models/typeCode.types';
import DefaultDetailSubmissionFormWorkoutPlanExternal from 'Feature/DefaultsInventory/components/DefaultInventoryDetails/DefaultDetailSubmissionForm/DefaultDetailSubmissionFormWorkoutPlanExternal';
import { Tabs } from '../../../../CommonComponents/UserControls';
import { DefaultDetailPageContentProps } from './DefaultDetailPageContent.types';
import DefaultDetailSubmissionForm from '../DefaultDetailSubmissionForm/DefaultDetailSubmissionForm';
import DefaultDetailSubmissionFormWorkoutPlan from '../DefaultDetailSubmissionForm/DefaultDetailSubmissionFormWorkoutPlan';
import './DefaultDetailPageContent.scss';

export const DefaultDetailPageContent: React.FC<DefaultDetailPageContentProps> = ({
  onLoanWatchLoanValidationCallback,
  onDecisioningBtnsCallback,
  defaultSubmissionFormPropsFields,
  detailsData,
  onSubmitCallback
}) => {
  const status = detailsData ? DefaultRequestStatus[detailsData.other.defaultStatus] : '';
  const disableFields = status === DefaultRequestStatus[DefaultRequestStatus.Cancelled];
  const disableCalendarField = status === DefaultRequestStatus[DefaultRequestStatus.Cancelled];
  const [activeTab, setActiveTab] = useState('defaultSubmission');
  const [watchLoanValidation, setWatchLoanValidation] = useState<any>(false);
  const [areDisabledCardBtns, setDisableCardBtns] = useState<any>(false);

  useEffect(() => {
    // Original watched Lender Email, we don't want do to this as someone can change the lender email, send the email and no record of this change will be recorded (backend not changed).
    // Removed this behavior for now
    onLoanWatchLoanValidationCallback(watchLoanValidation);
  }, [watchLoanValidation]);

  useEffect(() => {
    onDecisioningBtnsCallback(areDisabledCardBtns);
  }, [areDisabledCardBtns]);

  const handleTabClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActiveTab(e.currentTarget.getAttribute('data-tab-name') || '');
  };

  const DefaultSubmissionFormProps: DefaultSubmissionFormProps = {
    fields: defaultSubmissionFormPropsFields,
    t: (args) => args
  };

  return (
    <div className="card-body card__body">
      <div className="tabs">
        <div className="row card__body-row card__body-row--fluid">
          <div className="col-12" style={{ marginBottom: '48px' }}>
            <Tabs
              tabGroupName={i18n.t('DefaultsInventoryDetails-TabGroupName-FilterTabs')}
              items={[
                {
                  displayText: i18n.t(
                    'DefaultsInventoryDetails-FilterTab-DefaultRequestSubmission'
                  ),
                  tabName: TargetSection.DEFAULTSUBMISSION
                },
                {
                  displayText: i18n.t('DefaultsInventoryDetails-FilterTab-CmhcRevisedWorkoutPlan'),
                  tabName: TargetSection.WORKOUTPLAN
                }
              ]}
              onTabClick={(e: React.MouseEvent<HTMLButtonElement>) => handleTabClick(e)}
              defaultActiveItemFieldName="defaultSubmission"
            />
          </div>
        </div>
        {detailsData && (
          <div
            id={`${TargetSection.DEFAULTSUBMISSION}Content`}
            className={`content ${activeTab === TargetSection.DEFAULTSUBMISSION ? 'active' : ''}`}
            aria-hidden={activeTab !== TargetSection.DEFAULTSUBMISSION}
            role="tabpanel"
            aria-labelledby={`${TargetSection.DEFAULTSUBMISSION}Tab`}
            tabIndex={0}
          >
            <DefaultFormContextProvider>
              <fieldset disabled={disableFields} role="tabpanel">
                <DefaultDetailSubmissionForm
                  detailsData={detailsData}
                  onSubmitCallback={onSubmitCallback}
                  defaultSubmissionFormProps={DefaultSubmissionFormProps}
                  watchLoanValidation={setWatchLoanValidation}
                  setDisableCardBtns={setDisableCardBtns}
                  activeTab={activeTab}
                  disableCalendar={disableCalendarField}
                />
                <DefaultDetailSubmissionFormExternal
                  detailsData={detailsData}
                  onSubmitCallback={onSubmitCallback}
                  defaultSubmissionFormProps={DefaultSubmissionFormProps}
                  watchLoanValidation={setWatchLoanValidation}
                  setDisableCardBtns={setDisableCardBtns}
                  activeTab={activeTab}
                />
              </fieldset>
            </DefaultFormContextProvider>
          </div>
        )}

        {detailsData && (
          <div
            id={`${TargetSection.WORKOUTPLAN}Content`}
            className={`content ${activeTab === TargetSection.WORKOUTPLAN ? 'active' : ''}`}
            aria-hidden={activeTab !== TargetSection.WORKOUTPLAN}
            role="tabpanel"
            aria-labelledby={`${TargetSection.WORKOUTPLAN}Tab`}
          >
            <fieldset disabled={disableFields}>
              <DefaultDetailSubmissionFormWorkoutPlan
                detailsData={detailsData}
                onSubmitCallback={onSubmitCallback}
                defaultSubmissionFormProps={DefaultSubmissionFormProps}
                setDisableCardBtns={setDisableCardBtns}
                watchLoanValidation={setWatchLoanValidation}
              />

              <DefaultDetailSubmissionFormWorkoutPlanExternal
                detailsData={detailsData}
                onSubmitCallback={onSubmitCallback}
                defaultSubmissionFormProps={DefaultSubmissionFormProps}
                watchLoanValidation={setWatchLoanValidation}
              />
            </fieldset>
          </div>
        )}
      </div>
    </div>
  );
};
