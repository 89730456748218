import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import i18n from 'i18next';
import * as React from 'react';
import { Pagination } from '@mui/lab';

import Dropdown from 'Components/Inputs/DropDown';
import { DataTableFooterProps } from 'Components/Common/DataTableFooter/types';
import styles from './styles.module.scss';

const TOTAL_PAGES_COUNT = 7;
const MIN_WIDTH = 1285;
const TOTAL_ITEMS_LIMIT = 999;

export const DataTableFooter: React.FC<DataTableFooterProps> = ({
  name,
  currentPage,
  itemsPerPageLabel,
  itemsPerPageOptions,
  pagerClickCallback,
  hideItemsPerPage,
  hidePager,
  hideItemXofY,
  totalPages,
  itemsXofYText,
  totalItems,
  itemsPerPageOnChangeCallback,
  itemsPerPageValue,
  width = MIN_WIDTH
}: DataTableFooterProps) => (
  <div className={styles.dataTableFooter}>
    {!hidePager && (
      <div className={styles.pager}>
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          siblingCount={width < MIN_WIDTH && totalItems > TOTAL_ITEMS_LIMIT ? 0 : 1}
          onChange={(e: object, page: number) => {
            if (pagerClickCallback) pagerClickCallback(page);
          }}
        />
      </div>
    )}

    {!hideItemsPerPage && (
      <div className={styles.itemsPerPage}>
        <Dropdown
          label={itemsPerPageLabel}
          options={
            itemsPerPageOptions?.map((item: SitecoreListItem) => ({
              fields: {
                itemName: {
                  value: item.fields.itemName.value
                },
                itemValue: item.fields.itemValue
              }
            })) || [
              {
                fields: {
                  itemName: { value: '10' },
                  itemValue: { value: '10' }
                }
              },
              {
                fields: {
                  itemName: { value: '25' },
                  itemValue: { value: '25' }
                }
              },
              {
                fields: {
                  itemName: { value: '50' },
                  itemValue: { value: '50' }
                }
              },
              {
                fields: {
                  itemName: { value: '100' },
                  itemValue: { value: '100' }
                }
              }
            ]
          }
          className={styles.dataTableFooterDropdown}
          name={`${name}claimsDataTableFooterDropdown`}
          onChangeCallback={itemsPerPageOnChangeCallback}
          value={itemsPerPageValue}
        />
      </div>
    )}

    {!hideItemXofY && (
      <div className={styles.itemXofY}>
        {itemsXofYText}&nbsp;
        <span
          className={`${styles.totalItems} ${
            totalPages === TOTAL_PAGES_COUNT ? styles.totalIemsWidth : ''
          }`.trim()}
          title={totalItems?.toString()}
        >
          {totalItems ?? 0}
        </span>
        <span> {i18n.t('Globals-Grid-ResultsPerPageDropdown-Items')} </span>
      </div>
    )}
  </div>
);
