import i18n from 'i18next';

export const convertFilesize = (data: any) => {
  return (
    (data &&
      !Number.isNaN(data) &&
      `${Math.ceil(parseInt(data) / 1000)} ${i18n.t('General-FileSize-KB-Label', 'KB')}`) ||
    ''
  );
};
