import { AxiosError, AxiosResponse } from 'axios';

import { HttpResponseStatusCodes, ReportType } from '@hobt/constants';

import { ApiClient, ApiClientConfig } from 'Foundation/Api';

import { DocumentEntity, ReportingPeriodField } from './types';
import { config } from '../../../../config';

const useArrearsFileListApiClient = (authContext: any, apiClientConfig: ApiClientConfig) => {
  const submitStatus = async () => {
    const updateStatusRoute: string = config.arrearsApi.urls.initiateApproval;
    const requestPayload = {};

    const response = await ApiClient(authContext, apiClientConfig)
      .postWithAuth(updateStatusRoute, requestPayload)
      .then((res: AxiosResponse) => res.data)
      .catch((err: AxiosError) => err.response?.data);

    return response;
  };

  const getActiveReportingPeriod = async (): Promise<ReportingPeriodField[]> => {
    const response: AxiosResponse = await ApiClient(authContext, apiClientConfig).getWithAuth(
      config.arrearsApi.urls.activeReportingPeriod
    );

    return response?.data?.statusCode === HttpResponseStatusCodes.OK && response?.data?.data;
  };

  const deleteArrearsFile = async (arrearsFileID: string): Promise<boolean> => {
    const requestRoute = `${config.arrearsFilesApi.urls.delete}${arrearsFileID}`;

    const response = await ApiClient(authContext, apiClientConfig).deleteWithAuth(requestRoute);
    return response?.data?.statusCode === HttpResponseStatusCodes.OK;
  };

  const validationResult = async () => {
    const validationResultRoute: string = config.arrearsApi.urls.downloadValidationResults;

    try {
      const response: AxiosResponse = await ApiClient(authContext, apiClientConfig).getWithAuth(
        validationResultRoute,
        undefined
      );

      return response.data.statusCode === HttpResponseStatusCodes.OK && response.data.data[0];
    } catch (errors) {
      return null;
    }
  };

  const downloadFileDocument = async (
    documentID: string,
    entityID: string,
    reportType?: ReportType
  ): Promise<DocumentEntity | null> => {
    const downloadRoute: string = config.documentApi.urls.base;
    const reportTypeQueryString = reportType !== undefined ? `&reportType=${reportType}` : '';
    const queryBuilder: string =
      `${documentID}?module=arrears&entityID=${entityID}${reportTypeQueryString}`.trim();

    try {
      const response: AxiosResponse = await ApiClient(authContext, apiClientConfig).getWithAuth(
        `${downloadRoute}/${queryBuilder}`
      );

      return response.data.statusCode === HttpResponseStatusCodes.OK && response.data.data;
    } catch (errors) {
      return null;
    }
  };

  const reAssessDocument = async (uuid: string): Promise<boolean | null> => {
    const fileID = '{fileID}';
    const reAssessRoute: string = config.arrearsApi.urls.reAssessFile.replace(fileID, uuid);
    const payload = {};

    try {
      const response: AxiosResponse = await ApiClient(authContext, apiClientConfig).postWithAuth(
        reAssessRoute,
        payload
      );

      return response.data.statusCode === HttpResponseStatusCodes.OK;
    } catch (errors) {
      return null;
    }
  };

  return {
    deleteArrearsFile,
    downloadFileDocument,
    getActiveReportingPeriod,
    reAssessDocument,
    submitStatus,
    validationResult
  };
};

export default useArrearsFileListApiClient;
