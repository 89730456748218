import { RichTextProps } from '@sitecore-jss/sitecore-jss-react/types/components/RichText';
import { HbtFileExtensionType } from '@hobt/constants';
import { FinalClaim } from '@hobt/claim-domain';
import { AttachmentsViewPropsFields } from 'Components/Common/AttachmentsView/types';
import { SitecoreDropdownList } from 'Constants/Types/SitecoreListItem';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';

export interface CancelClaimProps {
  onCancelCallback?: () => void;
  onSuccessCallback?: (data: FinalClaim) => void;
  onErrorCallback?: () => void;
  setIsLoadingCallback?: React.Dispatch<React.SetStateAction<Spinner>>;
  loadingStatus?: Spinner;
  fields: CancelClaimPropsFields;
  cancelClaimData?: CancelClaimFlyoutData;
}
export interface CancelClaimPropsFields {
  formTitle: FieldValue;
  content: FieldValue;
  categoryLabel: FieldValue;
  cancellationTemplateLabel?: FieldValue;
  categoryPlaceholder: FieldValue;
  categoryList: SitecoreDropdownList;
  notificationTemplate?: RichTextProps;
  helpText?: FieldValue;
  note: FieldValue;
  notePlaceholder: FieldValue;
  submitBtn: FieldValue;
  proceedBtn: FieldValue;
  cancelBtn: FieldValue;
  uploadIcon?: FieldValue;
  limitNumber: FieldValue;
  characterLimit: FieldValue;
  attachmentsView: AttachmentsViewComponentProps;
  notificationTemplateTitle?: FieldValue;
  cancellationNoteToken: FieldValue;
  cancellationReasonToken: FieldValue;
}

export interface CancelClaimFlyoutData {
  claimId: string;
  claimTypeCode?: number;
  cmhcLoanAccountNumber?: number;
}

interface AttachmentsViewComponentPropsFields extends AttachmentsViewPropsFields {
  name?: FieldValue;
  heading?: FieldValue;
}
export interface AttachmentsViewComponentProps {
  fields: AttachmentsViewComponentPropsFields;
}
export interface CancelClaimRequestData {
  remarkText?: string;
  cancellationReasonCode: number;
  attachments: AttachmentFields[];
}

export type AttachmentFields = {
  documentID: string;
  metadata: {
    fileName: string;
    fileSize: number;
    description: string;
    documentType: number;
    uploadStatus: number;
  };
};

export const AllowedFileTypes = [
  HbtFileExtensionType.CSV,
  HbtFileExtensionType.XLSX,
  HbtFileExtensionType.XLSM,
  HbtFileExtensionType.XLSB,
  HbtFileExtensionType.XLTX,
  HbtFileExtensionType.XLTM,
  HbtFileExtensionType.XLS,
  HbtFileExtensionType.XLT,
  HbtFileExtensionType.XML,
  HbtFileExtensionType.XLAM,
  HbtFileExtensionType.XLA,
  HbtFileExtensionType.XLW,
  HbtFileExtensionType.XLR,
  HbtFileExtensionType.PDF,
  HbtFileExtensionType.JPG,
  HbtFileExtensionType.DOC,
  HbtFileExtensionType.DOCX
];
