import i18n from 'i18next';
import DefaultSubmissionFormProps, {
  AttachmentValuesField
} from 'Feature/DefaultSubmissionForm/models/DefaultSubmissionFormProps';
import { FormDropdownOption } from '../../FormInputDropdownText/FormInputDropdownText.types';

export const getBorrowerDetailsProps = (props: DefaultSubmissionFormProps) => {
  const provinceOptions: FormDropdownOption[] = [{ label: '', value: '' }];
  props.fields.provinceOptions[0].fields.listItems.map((option: AttachmentValuesField) => {
    provinceOptions.push({
      label: (option?.fields?.itemName?.value as string) || 'No label set',
      value: option?.fields?.itemValue?.value || -1
    });
  });

  const borrowerPropsData = {
    testId: 'borrowerTest',
    accordionLineColor: 'grey',
    linePosition: 'vertical',
    title: {
      field: {
        value: i18n.t('DefaultSubmission-Card-BorrowersHeading')
      }
    },
    deleteIcon: props.fields.deleteIcon,
    primaryBorrower: {
      field: {
        value: i18n.t('DefaultSubmission-Card-PrimaryBorrower')
      }
    },
    secondaryBorrower: {
      field: {
        value: i18n.t('DefaultSubmission-Card-SecondaryBorrower')
      }
    },
    addAdditionalBorrower: {
      field: {
        value: i18n.t('DefaultSubmission-Card-AddAdditionalBorrower')
      }
    },
    borrowerOrGuarantor: {
      field: {
        value: i18n.t('DefaultSubmission-Card-BorrowerOrGuarantor')
      }
    },
    borrower: i18n.t('DefaultSubmission-Card-Borrower'),
    coBorrower: i18n.t('DefaultSubmission-Card-Co-Borrower'),
    guarantor: i18n.t('DefaultSubmission-Card-Guarantor'),
    provinceOptions,
    inputFields: {
      borrowerFirstName: {
        field: {
          value: i18n.t('DefaultSubmission-Card-BorrowerFirstName')
        }
      },
      borrowerLastName: {
        field: {
          value: i18n.t('DefaultSubmission-Card-BorrowerLastName')
        }
      },
      borrowerType: {
        field: {
          value: i18n.t('DefaultSubmission-Card-BorrowerType')
        }
      },
      mailingAddressStNumber: {
        field: {
          value: i18n.t('DefaultSubmission-Card-MailingAddressStreetNumber')
        }
      },
      mailingAddressUnitNumber: {
        field: {
          value: i18n.t('DefaultSubmission-Card-MailingAddressUnitNumber')
        }
      },
      mailingAddressStreetName: {
        field: {
          value: i18n.t('DefaultSubmission-Card-StreetName')
        }
      },
      mailingAddressStreetType: {
        field: {
          value: i18n.t('DefaultSubmission-Card-StreetType')
        }
      },
      mailingAddressStDirection: {
        field: {
          value: i18n.t('DefaultSubmission-Card-StreetDirection')
        }
      },
      mailingAddressCity: {
        field: {
          value: i18n.t('DefaultSubmission-Card-TownCity')
        }
      },
      mailingAddressProvince: {
        field: {
          value: i18n.t('DefaultSubmission-Card-Province')
        }
      },
      mailingAddressPostalCode: {
        field: {
          value: i18n.t('DefaultSubmission-Card-PostalCode')
        }
      },
      grossSalary: {
        field: {
          value: i18n.t('DefaultSubmission-Card-GrossSalary')
        }
      },
      employmentInsurance: {
        field: {
          value: i18n.t('DefaultSubmission-Card-EmploymentInsurance')
        }
      },
      investmentIncome: {
        field: {
          value: i18n.t('DefaultSubmission-Card-InvestmentIncome')
        }
      },
      otherIncome: {
        field: {
          value: i18n.t('DefaultSubmission-Card-OtherOptional')
        }
      },
      totalMonthlyGrossIncome: {
        field: {
          value: i18n.t('DefaultSubmission-Card-TotalMonthlyGross')
        }
      },
      occupation: {
        field: {
          value: i18n.t('DefaultSubmission-Card-Occupation')
        }
      },
      currentEmployment: {
        field: {
          value: i18n.t('DefaultSubmission-Card-CurrentEmployment')
        }
      },
      numberOfYearsInIndustry: {
        field: {
          value: i18n.t('DefaultSubmission-Card-NumberOfYearsInIndustry')
        }
      },
      industry: {
        field: {
          value: i18n.t('DefaultSubmission-Card-Industry')
        }
      },
      detailedEmploymentProspects: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DetailEmploymentProspects')
        }
      },
      numberOfDependents: {
        field: {
          value: i18n.t('DefaultSubmission-Card-NumberOfDependents')
        }
      },
      radioButtonYes: {
        field: {
          value: i18n.t('DefaultSubmission-Card-RadioButtonYes')
        }
      },
      radioButtonNo: {
        field: {
          value: i18n.t('DefaultSubmission-Card-RadioButtonNo')
        }
      }
    },
    mailingAddressDifferent: {
      field: {
        value: i18n.t('DefaultSubmission-Card-MailingAddressDifferent')
      }
    },
    monthlySourceOfIndividualIncome: {
      field: {
        value: i18n.t('DefaultSubmission-Card-MonthlySourceOfIndividualIncome')
      }
    },
    currentEmploymentDescription: {
      field: {
        value: i18n.t('DefaultSubmission-Card-CurrentEmploymentDescription')
      }
    },
    detailEmploymentProspectsDescription: {
      field: {
        value: i18n.t('DefaultSubmission-Card-DetailEmploymentProspectsDescription')
      }
    },
    recentCreditBureauObtained: {
      field: {
        value: i18n.t('DefaultSubmission-Card-RecentCreditBureauObtained')
      }
    }
  };

  return borrowerPropsData;
};
