import React, { FC, useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { InternalClaimStatus } from '@hobt/claim-domain';

import CollapsingSection from 'Components/Common/CollapsingSection';
import { ContextualButton } from 'Components/Common/Button';
import ABRender from 'Components/Common/ABRender';

import { jsonReplacerRemoveIdField } from 'Constants/Utils/JSONReplacers';
import { areObjectArraysEqual } from 'Constants/Utils/ObjectArrayComparison';

import { useAuthenticationContext } from 'Foundation/Authentication';
import {
  useHBTFormActionsContext,
  useHBTFormContext
} from 'Feature/Claims/components/HBTFormContext';

import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';
import { updateRefundAdjustments } from '../../RefundManagementService';
import { useRefundLetter } from '../../RefundRequestLetter/RefundLetterContext';
import type { CalculationsProps, Refund, RefundAdjustmentDetail } from './types';
import CalculationRow from './CalculationRow';

import styles from './styles.module.scss';
import { ReceivedAdjustment } from '../../types';

const areObjectArraysEqualNoIds = <T,>(a: T[], b: T[]) =>
  areObjectArraysEqual(a, b, jsonReplacerRemoveIdField);

const defaultRefund: Refund = {
  amount: 0,
  addedDate: undefined
};

const Calculations: FC<CalculationsProps> = ({
  heading,
  addReceivedAdjustment,
  amount,
  dateAdded,
  totalReceivedPayment,
  applyChanges,
  receivedPayment,
  deleteButton,
  cancelButton,
  editButton,
  refundTableCaption
}) => {
  const {
    state: { receivedAdjustments: contextReceivedAdjustments, requestedAmount },
    dispatch
  } = useRefundLetter();
  const { setValue } = useFormContext();
  const [[editMode, editingRow, prevValue], setEditMode] = useState<
    [boolean, number | null, Refund | undefined]
  >([false, null, undefined]);

  const [isRefundCalcApplyChangeBtnDisabled, setIsRefundCalcApplyChangeBtnDisabled] =
    useState<boolean>(false);

  const authenticationContext = useAuthenticationContext();
  const { claimData, uuid, lockTabs, updateClaimData } = useHBTFormContext();
  const { enableEditMode, disableEditMode } = useHBTFormActionsContext();
  const { toCurrency } = useTranslationHelpers();
  const name = 'receivedAdjustments';

  const { fields: receivedAdjustments, append, remove } = useFieldArray({ name });
  const watchedAdjustments = useWatch({ name, defaultValue: [] }) as Refund[];
  const totalReceivedRefunds = watchedAdjustments
    ?.map((refund: Refund) => Number(refund.amount) || 0)
    ?.reduce((a: number, b: number) => a + b, 0)
    ?.toFixed(2);

  // Init context values
  useEffect(() => {
    append(contextReceivedAdjustments);
  }, []);

  // Push update to context from UI change
  useEffect(() => {
    if (areObjectArraysEqualNoIds(contextReceivedAdjustments, watchedAdjustments as Refund[]))
      return;
    dispatch({ type: 'updateRefundsReceived', value: watchedAdjustments });
  }, [JSON.stringify(watchedAdjustments)]);

  const updateRefundDetails = async () => {
    const payload: RefundAdjustmentDetail = {
      receivedAdjustments: watchedAdjustments as ReceivedAdjustment[],
      grossRefundBalance: requestedAmount
    };

    await updateRefundAdjustments(uuid, authenticationContext, payload);

    if (updateClaimData != null) {
      updateClaimData();
    }
  };

  const edit = (index: number) => {
    setEditMode([true, index, watchedAdjustments[index]]);
    if (lockTabs != null) lockTabs(true);
    enableEditMode();
  };

  const cancel = () => {
    // check if old received adjustment record that was being added is now getting cancelled
    if (editingRow != null && prevValue != null) {
      setValue(`${name}[${editingRow}]`, prevValue, { shouldDirty: true });
    } else if (editingRow != null) {
      remove(editingRow);
    }

    setEditMode([false, null, undefined]);
    if (lockTabs != null) lockTabs(false);
    disableEditMode();
  };

  const commit = () => {
    setEditMode([false, null, undefined]);
    if (lockTabs != null) lockTabs(false);
    disableEditMode();
    updateRefundDetails();
  };

  const removeAdjustment = (index: number) => {
    setEditMode([false, null, undefined]);
    if (lockTabs != null) lockTabs(false);
    disableEditMode();
    remove(index);
    watchedAdjustments.splice(index, 1);
    updateRefundDetails();
  };

  const addAdjustment = (value: any) => {
    const newIndex = watchedAdjustments.length;
    append(value);
    edit(newIndex);
  };
  const isRefundCalcRowFieldsValid = (value: boolean) => {
    setIsRefundCalcApplyChangeBtnDisabled(value);
  };

  const refundCalculationDisabled = ![InternalClaimStatus.RefundRequested].includes(
    claimData?.profile?.claimStatus?.code
  );

  return (
    <fieldset>
      <CollapsingSection
        id="receivedRefundCalculations"
        heading={heading.value ?? ''}
        headingLevel={3}
        sectionClass={styles.refundCalculationCard}
      >
        <ABRender
          condition={editMode}
          thenElement={
            <div className={styles.refundEdit}>
              <ContextualButton
                data-testid="applyChanges"
                onClick={commit}
                disabled={!isRefundCalcApplyChangeBtnDisabled}
              >
                <span className={`material-icons align-self-center ${styles.addIcon}`}>done</span>
                {applyChanges.value}
              </ContextualButton>
              <ContextualButton data-testid="cancelChanges" onClick={cancel}>
                <span className={`material-icons align-self-center ${styles.addIcon}`}>close</span>
                {cancelButton.value}
              </ContextualButton>
            </div>
          }
          elseElement={
            <ContextualButton
              disabled={refundCalculationDisabled}
              data-testid="addReceivedAdjustment"
              onClick={() => addAdjustment(defaultRefund)}
              className={styles.addReceivedAdjustment}
            >
              <span className={`material-icons align-self-center ${styles.addIcon}`}>add</span>
              {addReceivedAdjustment.value}
            </ContextualButton>
          }
        />
        <div className={styles.dataTableHeader}>
          <DataTable name={'Refund'} caption={refundTableCaption}>
            <DataTableHeader>
              <DataTableHeaderItem name={''} />
              <DataTableHeaderItem name={'Amount'} displayText={amount} />
              <DataTableHeaderItem name={'Date'} displayText={dateAdded} />
              <DataTableHeaderItem name={''} />
              <DataTableHeaderItem name={''} />
              <DataTableHeaderItem name={''} />
            </DataTableHeader>
            {receivedAdjustments?.map((field, index) => (
              <CalculationRow
                key={field.id}
                name={name}
                index={index}
                field={field as unknown as Refund}
                editButton={editButton}
                deleteButton={deleteButton}
                receivedPayment={receivedPayment}
                edit={edit}
                remove={removeAdjustment}
                editMode={editMode}
                selected={index === editingRow}
                isRefundCalcRowFieldsValid={isRefundCalcRowFieldsValid}
              />
            ))}
            <DataTableRow name={'subTotal'} subtotal>
              <TextCell name={'totalReceivedPayment'} text={totalReceivedPayment.value ?? ''} />
              <TextCell
                name={'totalReceivedRefunds'}
                text={toCurrency(Number(totalReceivedRefunds))}
              />
              <TextCell name={''} text={''} />
              <TextCell name={''} text={''} />
              <TextCell name={''} text={''} />
              <TextCell name={''} text={''} />
            </DataTableRow>
          </DataTable>
        </div>
      </CollapsingSection>
    </fieldset>
  );
};

export default Calculations;
