import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import NotificationBanner from 'Components/Core/BannerNotification';
import { Button } from 'Components/Core/Button';
import { ButtonVariant } from 'Components/Core/Button/types';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import { Modal } from 'Components/Core/Modal';
import { MouseOrKeyboardEvent } from 'Components/Core/Modal/types';
import { Tab } from 'Components/Core/Tab/Tab';
import { TabFields, TabsType } from 'Components/Core/Tab/types';
import i18n from 'i18next';
import HbtPIFileTransferDownloadTabView from '../HbtPIFileTransferDownloadTabView';
import { HbtPIFileTransferDownloadTabViewFields } from '../HbtPIFileTransferDownloadTabView/types';
import HbtPIFileTransferUploadTabView from '../HbtPIFileTransferUploadTabView';
import { HbtPIFileTransferUploadTabViewFields } from '../HbtPIFileTransferUploadTabView/types';
import HbtPIFileTransferPageContextProvider from './HbtPIFileTransferPageContext';
import styles from './styles.module.scss';
import {
  HbtPIFileTransferPageProps,
  FileTransferTabItem,
  NotificationBannerInfo,
  NotificationBannerType
} from './types';

const HbtPIFileTransferPage = ({ fields, rendering }: HbtPIFileTransferPageProps) => {
  const [isNeedHelpInfoVisible, setIsNeedHelpInfoVisible] = useState(false);
  const [piFileTransferTabList, setPiFileTransferTabList] = useState<TabFields[]>([
    {
      label: { value: '' },
      content: undefined
    }
  ]);
  const [showNotificationBanner, setShowNotificationBanner] = useState(false);
  const [notificationBannerInfo, setNotificationBannerInfo] = useState<NotificationBannerInfo>({
    notificationBannerHeader: '',
    notificationBannerType: '' as NotificationBannerType,
    notificationBannerBody: ''
  });
  const [hasUnSavedChanges, setHasUnSavedChanges] = useState(false);
  const [showUnsubmittedFilesModal, setShowUnsubmittedFilesModal] = useState(false);

  const fileTransferPageProviderValues = useMemo(
    () => ({
      showNotificationBanner,
      setShowNotificationBanner,
      notificationBannerInfo,
      setNotificationBannerInfo,
      hasUnSavedChanges,
      setHasUnSavedChanges
    }),
    [showNotificationBanner, notificationBannerInfo, hasUnSavedChanges]
  );
  const CancelBtnText = i18n.t('PI-File-Transfer-Upload-Tab-Modal-CancelButton', 'Cancel');
  const leaveBtnText = i18n.t('PI-File-Transfer-Upload-Tab-Unsubmitted-Modal-LeaveButton', 'Leave');

  const isServer = typeof window === 'undefined';
  const [nextTabIndex, setNextTabIndex] = useState<number>(0);
  const [activePIFileTransferTab, setActivePIFileTransferTab] = useState<number>(0);
  useEffect(() => {
    if (!isServer) {
      const savedTab = Number(localStorage.getItem('pifileTransferActiveTab')) || 0;
      setActivePIFileTransferTab(savedTab);
    }
  }, []);

  useEffect(() => {
    if (!isServer) {
      localStorage.setItem('pifileTransferActiveTab', activePIFileTransferTab.toString());
    }
  }, [activePIFileTransferTab]);

  const handlePIFileTrasferTabChange = useCallback(
    (newValue: number) => {
      if (fileTransferPageProviderValues.hasUnSavedChanges) {
        setActivePIFileTransferTab(activePIFileTransferTab);
        setShowUnsubmittedFilesModal(true);
        setNextTabIndex(newValue);
      } else {
        setShowNotificationBanner(false);
        setActivePIFileTransferTab(newValue);
        localStorage.setItem('pifileTransferActiveTab', newValue.toString());
      }
    },
    [fileTransferPageProviderValues, hasUnSavedChanges, nextTabIndex]
  );

  const toggleNeedHelpInfoContent = useCallback(() => {
    setIsNeedHelpInfoVisible((prev) => !prev);
  }, []);

  const toggleUnsubmittedFilesModal = useCallback(
    (event: MouseOrKeyboardEvent) => {
      event.preventDefault();
      setShowUnsubmittedFilesModal(!showUnsubmittedFilesModal);
    },
    [showUnsubmittedFilesModal, setShowUnsubmittedFilesModal]
  );

  const confirmTabChange = useCallback(
    (event: MouseOrKeyboardEvent, nextTabIndex: number) => {
      event.preventDefault();
      setHasUnSavedChanges(false);
      setShowUnsubmittedFilesModal(false);
      setActivePIFileTransferTab(nextTabIndex);
      localStorage.setItem('pifileTransferActiveTab', activePIFileTransferTab.toString());
    },
    [setHasUnSavedChanges, setShowUnsubmittedFilesModal]
  );

  useEffect(() => {
    if (rendering.placeholders) {
      const tabList = ((rendering.placeholders.fileTransferTabView as any[]) ?? []).map(
        (item: FileTransferTabItem) => {
          let tabComponent: React.ReactNode;
          switch (item.componentName) {
            case 'HbtPIFileTransferUploadTabView':
              tabComponent = (
                <HbtPIFileTransferUploadTabView
                  fields={{ ...item.fields } as HbtPIFileTransferUploadTabViewFields}
                />
              );
              break;
            case 'HbtPIFileTransferDownloadTabView':
              tabComponent = (
                <HbtPIFileTransferDownloadTabView
                  fields={{ ...item.fields } as HbtPIFileTransferDownloadTabViewFields}
                />
              );
              break;
            default:
              tabComponent = null;
          }

          return {
            label: item.fields.tabLabel,
            content: tabComponent
          };
        }
      );
      setPiFileTransferTabList(tabList);
    }
  }, [fields, rendering]);

  return (
    <HbtPIFileTransferPageContextProvider
      value={{ fileTransferPageContextData: fileTransferPageProviderValues }}
    >
      {fileTransferPageProviderValues.showNotificationBanner && (
        <div className={styles.fileTransferToastMsgContainer}>
          <NotificationBanner
            showNotificationBanner={fileTransferPageProviderValues.showNotificationBanner}
            onCloseNotificationBanner={() =>
              fileTransferPageProviderValues.setShowNotificationBanner(false)
            }
            headerText={{
              value: notificationBannerInfo.notificationBannerHeader
            }}
            message={{ value: notificationBannerInfo.notificationBannerBody }}
            variant={notificationBannerInfo.notificationBannerType}
          />
        </div>
      )}
      <div className={styles.fileTransferMainBody}>
        <div className={styles.fileTransferMainContainer}>
          <div className={styles.fileTransferMainContent}>
            <div className={styles.fileTransferHeaderContainer}>
              <div className={styles.fileTransferHeaderContent}>
                <div className={styles.fileTransferHeaderTitleInfoWrapper}>
                  <h1 className={styles.fileTransferTitle} id="fileTransferTitle" tabIndex={0}>
                    <Text field={fields.fileTransferTitle} />
                  </h1>
                  <span className={styles.fileTransferTitleInfo}>
                    <Text field={fields.fileTransferTitleInfo} />
                  </span>
                </div>
              </div>
              <div className={styles.cmhcSupportContainer}>
                <Button
                  name={fields.cmhcSupportBtn.value ?? 'CMHC support?'}
                  text={fields.cmhcSupportBtn}
                  ariaText={{ value: `${fields.cmhcSupportBtn.value}` }}
                  variant={ButtonVariant.TERTIARY}
                  onClick={toggleNeedHelpInfoContent}
                />
                {isNeedHelpInfoVisible && (
                  <ul className={styles.cmhcSupportListContainer}>
                    <li>
                      <span className={styles.piFileTransferUserGuideContent}>
                        <Text field={fields.fileTransferUserGuideAccessLabel} />
                        &nbsp;
                        <a
                          href={
                            fields.fileTransferUserGuideLink?.[0]?.fields?.guideHref?.value?.href ||
                            '#'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <RichText
                            field={fields.fileTransferUserGuideLink?.[0].fields.guideName}
                          />
                          <HbtIcon
                            className={styles.piFileTransferUserGuideExternalIconLink}
                            size={HbtIconSize.SMALL}
                            type="icon_external_link"
                          />
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className={styles.needHelpInfoContent}>
                        <RichText field={fields.needSupportInfoContent} />
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fileTransferTabContainer}>
            <Tab
              tabs={piFileTransferTabList}
              tabsType={TabsType.LEFT_ALIGNED}
              isEqualTabWidthRequired={true}
              preventTabChange={fileTransferPageProviderValues.hasUnSavedChanges}
              initalActiveTabIndex={activePIFileTransferTab}
              onTabChange={handlePIFileTrasferTabChange}
            />
            <Modal
              id="file-transfer-unsubmitted-files-tab-change-confirmation-modal"
              isActive={showUnsubmittedFilesModal}
              title={
                {
                  value: i18n.t(
                    'PI-File-Transfer-Upload-Tab-Unsubmitted-Modal-Title',
                    'Unsubmitted Files'
                  )
                } as FieldValue
              }
              onDismissalCallback={toggleUnsubmittedFilesModal}
              bodyContent={
                <span>
                  <Text
                    field={
                      {
                        value: i18n.t(
                          'PI-File-Transfer-Upload-Tab-Unsubmitted-Modal-Body',
                          'You have files in the queue. If you navigate away, your files will be deleted. Do you want to leave without submitting your files?'
                        )
                      } as FieldValue
                    }
                  />
                </span>
              }
              modalButtons={{
                primaryButton: {
                  name: CancelBtnText,
                  text: { value: CancelBtnText } as FieldValue,
                  ariaText: { value: CancelBtnText } as FieldValue,
                  onClick: toggleUnsubmittedFilesModal,
                  onKeyDown: toggleUnsubmittedFilesModal
                },
                secondaryButton: {
                  name: leaveBtnText,
                  text: { value: leaveBtnText } as FieldValue,
                  ariaText: { value: leaveBtnText } as FieldValue,
                  onClick: (e) => confirmTabChange(e, nextTabIndex),
                  onKeyDown: (e) => confirmTabChange(e, nextTabIndex)
                }
              }}
              shouldCloseOnBackgroundClick={true}
            />
          </div>
        </div>
      </div>
    </HbtPIFileTransferPageContextProvider>
  );
};

export default memo(HbtPIFileTransferPage);
