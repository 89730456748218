export const dropdownStyles = {
  indicatorSeparator: () => ({
    display: 'none'
  }),
  menu: (provided: any) => ({
    ...provided,
    width: '406px',
    border: '#005C84 1px solid',
    borderRadius: '4px',
    boxShadow: '0 4px 8px #CCC'
  }),
  container: (provided: any) => ({
    ...provided,
    width: '256px',
    fontSize: '14px'
  }),
  control: (provided: any) => ({
    ...provided,
    border: '#CCCCCC 1px solid',
    borderRadius: '4px',
    boxShadow: 'none',
    ':hover': { borderColor: '#CCCCCC' }
  }),
  input: (provided: any) => ({
    ...provided,
    padding: '8px',
    fontSize: '14px'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#656565'
  }),
  option: (provided: any, state: any) => {
    const isSelectedBgColor = state.isSelected ? '#005C84' : 'none';

    return {
      ...provided,
      backgroundColor: state.isFocused && !state.isSelected ? '#EDF2F4' : isSelectedBgColor,
      color: state.isSelected ? '#FFFFFF' : 'inherit',
      padding: '12px 16px'
    };
  }
};
