import InputField from 'cleave.js/react';
import React from 'react';
import { useController } from 'react-hook-form';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import _ from 'lodash-es';

import GetErrorMessage from '../ErrorMessage';
import {
  FormattedProps,
  FormInputTextInputProps,
  TUseControllerProps
} from './FormInputTextInput.types';

function Update(name: string, onChange: Function, setValue?: Function, handleChange?: Function) {
  return function _Update(e: any) {
    const { rawValue } = e.target;

    setValue?.(name, rawValue, { shouldDirty: true });
    onChange(rawValue);
    handleChange?.(e);
  };
}

const FormInputTextInput: React.FC<FormInputTextInputProps> = (props) => {
  const errors = props.errors && _.get(props.errors, `${props.name}`);

  // let isRequired = true;
  // if (props.notRequired !== undefined){
  //   isRequired = !props.notRequired;
  // }

  return (
    <div className="form__element form__element--2-column">
      <label
        htmlFor={`${props.id}TextInput`}
        id={`${props.id}Label`}
        className={errors && `form-error__label`}
      >
        <Text field={props.title} />
      </label>
      {props.description && <small>{props.description.value}</small>}
      <input
        type={props.type || 'text'}
        id={`${props.id}TextInput`}
        data-testid={`${props.id}`}
        aria-labelledby={`${props.id}Label`}
        name={props.name}
        className={errors && 'form-error__field'}
        {...((props.value || props.value === '') && { value: props.value })}
        {...(props.register && props.register(`${props.name}`, { required: props.isRequired }))}
        onChange={props.handleChange}
        disabled={props.isDisabled}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={props.autoFocus || false}
        aria-describedby={`${props.id}ErrorDesc`}
        autoComplete={props.autoComplete || 'off'}
      />
      {!props.hideError && (
        <div className="form__fieldNotificationHolder">
          {errors && (
            <span
              className={`form-error__sub-text`}
              aria-live="assertive"
              id={`${props.id}ErrorDesc`}
            >
              {GetErrorMessage(errors.type as string, errors.message as string)}
              <input
                type="hidden"
                className="thisFieldHasErrors"
                value={props.cardName && props.cardName}
              />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

const Formatted: React.FC<FormattedProps> = ({
  id,
  name,
  title,
  autoComplete,
  control,
  description,
  errors: formErrors,
  type,
  defaultValue,
  value,
  isDisabled,
  isRequired,
  cardName,
  formatProps,
  handleChange,
  getValues,
  setValueHandler,
  hideError,
  autoFocus,
  dontRegister = false
}) => {
  const errors = formErrors && _.get(formErrors, name);
  const nameValue: unknown = getValues?.(name);

  const {
    field: { onChange, ref: controllerRef, ...field }
  }: TUseControllerProps = useController({
    control,
    name,
    defaultValue: nameValue ?? defaultValue
  });

  // cleave.js does not allow empty spaces unless format type specified so use this input when empty
  const update = Update(name, onChange, setValueHandler, handleChange);

  return (
    <div className="form__element form__element--2-column">
      <label htmlFor={`${id}TextInput`} id={`${id}Label`} className={errors && 'form-error__label'}>
        <Text field={title} />
      </label>
      {description?.value != null && <small>{description.value}</small>}
      <InputField
        type={type ?? 'text'}
        id={`${id}TextInput`}
        {...field}
        options={{ ...formatProps }}
        data-testid={id}
        aria-labelledby={`${id}Label`}
        className={errors && 'form-error__field'}
        {...((value || value === '') && { value })}
        {...(dontRegister === false && { htmlRef: (ref) => (controllerRef.current = ref) })}
        onChange={dontRegister === true ? handleChange : update}
        disabled={isDisabled}
        {...(isRequired && { required: isRequired })}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus ?? false}
        aria-describedby={`${id}ErrorDesc`}
        autoComplete={autoComplete ?? 'off'}
      />
      {!hideError && (
        <div className="form__fieldNotificationHolder">
          {errors && (
            <span className="form-error__sub-text" aria-live="assertive" id={`${id}ErrorDesc`}>
              {GetErrorMessage(errors.type as string, errors.message as string)}
              <input type="hidden" className="thisFieldHasErrors" value={cardName && cardName} />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default FormInputTextInput;
export { Formatted };
