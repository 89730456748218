import React, { useEffect, useState } from 'react';

/*       
  Ensure that when scroll bar is closely near the horizontal end,
  the buttons will be updated within that area rather than reaching the
  horizontal end fully. Value (range - 3%) is enough to take care of cases
  like when scrollLeft is a bit off due to scrollbar shape.
*/
const SCROLL_LEFT_SAFETY_FACTOR = 1.03;

export const useDataTableFunctions = (scrollContainer?: React.RefObject<HTMLDivElement>) => {
  const [showShiftLeft, setShowShiftLeft] = useState(false);
  const [showShiftRight, setShowShiftRight] = useState(false);
  let [scroll, setScroll] = useState<HTMLDivElement | null | undefined>();

  useEffect(() => {
    setScroll(scroll);
  }, [scroll]);

  const updateScrollButtons = () => {
    setScroll(scrollContainer?.current);

    if (scrollContainer && scroll) {
      // if left all the way, show only scroll right
      if (scroll.scrollLeft === 0) {
        setShowShiftLeft(false);
        setShowShiftRight(true);
      }

      // if right all the way, show only scroll left
      if (
        SCROLL_LEFT_SAFETY_FACTOR * scroll.scrollLeft >=
        scroll.scrollWidth - scroll.offsetWidth
      ) {
        setShowShiftLeft(true);
        setShowShiftRight(false);
      }
      // else show both (scroll is somewhere in between)
      if (
        scroll.scrollLeft !== 0 &&
        SCROLL_LEFT_SAFETY_FACTOR * scroll.scrollLeft < scroll.scrollWidth - scroll.offsetWidth
      ) {
        setShowShiftLeft(true);
        setShowShiftRight(true);
      }
      // show right by default
    } else {
      setShowShiftLeft(false);
      setShowShiftRight(true);
    }
  };

  const hScroll = (direction: number) => {
    setScroll(scrollContainer?.current);
    scroll = scrollContainer?.current;
    if (scrollContainer && scroll) {
      if (direction === 1) {
        scroll.scrollLeft += scroll.offsetWidth * 0.9;
      }

      if (direction === -1) {
        scroll.scrollLeft -= scroll.offsetWidth * 0.9;
      }

      updateScrollButtons();
    }
  };

  const scrollRight = () => {
    hScroll(1);
  };

  const scrollLeft = () => {
    hScroll(-1);
  };

  return {
    showShiftLeft,
    showShiftRight,
    updateScrollButtons,
    scrollRight,
    scrollLeft
  };
};
