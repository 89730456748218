import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAuthenticationContext } from 'Foundation/Authentication';
import SessionTimeoutModalComponentProps from 'Feature/SessionTimeoutModal/models/SessionTimeoutModalComponentProps';

import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { Modal } from 'Feature/CommonComponents/ContentComponents';
import { Button, ButtonType } from 'Feature/CommonComponents/UserControls';
import { config } from '../../../../config';

const SessionTimeoutModalComponent = (props: SessionTimeoutModalComponentProps) => {
  const authenticationContext = useAuthenticationContext();

  const [secondsSinceActivity, setSecondsSinceActivity] = useState<number>(0);
  const [showSessionTimeoutModal, setShowSessionTimeoutModal] = useState<boolean>(false);

  function prettyPrintRemainingTime(seconds: number) {
    if (seconds > 119)
      return `${Math.floor(seconds / 60)} ${props.fields.minutes_label.value} ${
        props.fields.and.value
      } ${seconds % 60} ${
        seconds % 60 === 1 ? props.fields.second_label.value : props.fields.seconds_label.value
      }`;
    if (seconds > 59)
      return `${props.fields.one_minute.value} ${props.fields.and.value} ${seconds % 60} ${
        seconds % 60 === 1 ? props.fields.second_label.value : props.fields.seconds_label.value
      }`;
    if (seconds <= 0) return `0 ${props.fields.seconds_label.value}`;
    return `${seconds} ${
      seconds === 1 ? props.fields.second_label.value : props.fields.seconds_label.value
    }`;
  }

  // Detect activity anywhere on the page. Activity is defined as any of
  // the events captured below.
  useEffect(() => {
    if (authenticationContext.isAuthenticated) {
      setInterval(function () {
        setSecondsSinceActivity((prevSecondsSinceActivity) => {
          if (
            prevSecondsSinceActivity ===
            parseInt(config.app.sessionLengthInSeconds!) -
              parseInt(config.app.sessionTimeoutModalCountdownLengthInSeconds!)
          ) {
            setShowSessionTimeoutModal(true);
          } else if (prevSecondsSinceActivity === parseInt(config.app.sessionLengthInSeconds!)) {
            authenticationContext.signOut();
          }
          return prevSecondsSinceActivity + 1;
        });
      }, 1000);
    }
    document.addEventListener('click', (event) => {
      setSecondsSinceActivity(0);
    });
    // Triggered when the user presses the tab key
    document.addEventListener('keydown', (event) => {
      if (event.keyCode === 9) setSecondsSinceActivity(0);
    });
    document.addEventListener('uploadprogress', (event) => {
      setSecondsSinceActivity(0);
    });
  }, []);

  return (
    <Modal
      type={ApplicationStates.WARNING}
      title={props.fields.title.value!}
      isActive={showSessionTimeoutModal}
      noBottomSpace={true}
      content={() => {
        return (
          <>
            <div className="session-timeout-modal__inner">
              <div className="session-timeout-modal__message">
                {props.fields.session_expiry_message_part_1.value}{' '}
                <span className="session-timeout-modal__time-remaining">
                  {prettyPrintRemainingTime(
                    parseInt(config.app.sessionLengthInSeconds!) - secondsSinceActivity
                  )}
                </span>
                . {props.fields.session_expiry_message_part_2.value}
              </div>
            </div>
            <div className="session-timeout-modal__buttons-wrapper">
              <div className="session-timeout-modal__log-out-button">
                <Button
                  id="sessionTimeoutButtonLogOut"
                  name={props?.fields?.log_out_label?.value ?? ''}
                  displayText={props.fields.log_out_label.value}
                  buttonType={ButtonType.SECONDARY}
                  onClick={() => {
                    setSecondsSinceActivity(0);
                    setShowSessionTimeoutModal(false);
                    if (authenticationContext.isAuthenticated) authenticationContext.signOut();
                  }}
                />
              </div>
              <div className="session-timeout-modal__continue-session-button">
                <Button
                  id="sessionTimeoutButtonContinue"
                  name={props?.fields?.continue_label?.value ?? ''}
                  displayText={props.fields.continue_label.value}
                  buttonType={ButtonType.PRIMARY}
                  onClick={() => {
                    setSecondsSinceActivity(0);
                    setShowSessionTimeoutModal(false);
                  }}
                />
              </div>
            </div>
          </>
        );
      }}
      onCloseCallback={() => setShowSessionTimeoutModal(false)}
      shouldCloseOnBackgroundClick={true}
    />
  );
};

export default SessionTimeoutModalComponent;
