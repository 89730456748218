export enum DefaultRequestStatus {
  Unassigned = 0,
  PendingCmhcReview = 1,
  PendingLenderInput = 2,
  Approved = 3,
  ApprovedWithModifications = 4,
  Cancelled = 5,
  Inactive = 6,
  InReview = 7,
  Declined = 8,
  IncompleteSubmission = 9
}
