import React, { createContext, useContext, useState, ReactNode } from 'react';

import { HBTFormContextType, HBTFormActionsContextType } from './types';

const HBTFormContext = createContext<HBTFormContextType>({
  uuid: '',
  rendering: '',
  claimData: []
});
export const useHBTFormContext = () => useContext<HBTFormContextType>(HBTFormContext);

export const HBTFormActionsContext = createContext<HBTFormActionsContextType>({
  setUuid: () => {},
  setCompleteCards: () => {},
  isCardComplete: () => false,
  addCompleteCard: () => {},
  removeCompleteCard: () => {},
  clearCompleteCards: () => {},
  enableEditMode: () => {},
  disableEditMode: () => {}
});
export const useHBTFormActionsContext = () =>
  useContext<HBTFormActionsContextType>(HBTFormActionsContext);

const HBTFormConfiguredProvider = (props: { children?: ReactNode; value?: HBTFormContextType }) => {
  const [uuid, setUuid] = useState<string>(props.value?.uuid ?? '');

  const [completeCards, setCompleteCards] = useState<Array<string>>(
    props.value?.completeCards ?? []
  );

  const isCardComplete = (name: string | undefined): boolean => {
    return name != null ? completeCards.includes(name) : false;
  };

  const addCompleteCard = (name: string | undefined) => {
    if (name != null) {
      setCompleteCards((currentState: string[]) => {
        return currentState.indexOf(name) === -1 ? [...currentState, name] : currentState;
      });
    }
  };

  const removeCompleteCard = (name: string | undefined) => {
    setCompleteCards((currentState: string[]) =>
      currentState?.filter?.((cardName: string) => cardName !== name)
    );
  };

  const clearCompleteCards = () => setCompleteCards([]);

  const enableEditMode = () => {
    if (props.value?.setIsInEditMode != null) {
      props.value.setIsInEditMode(true);
    }
  };

  const disableEditMode = () => {
    if (props.value?.setIsInEditMode != null) {
      props.value.setIsInEditMode(false);
    }
  };

  return (
    <HBTFormContext.Provider
      value={{
        uuid,
        rendering: props.value?.rendering,
        claimData: props.value?.claimData,
        completeCards,
        isClaimStatusPostAdjudicationStatus: props.value?.isClaimStatusPostAdjudicationStatus,
        isInEditMode: props.value?.isInEditMode,
        isClaimsDetails: props.value?.isClaimsDetails,
        isMasterUser: props.value?.isMasterUser,
        isMasterUserEditingPostAdjudicationClaim:
          props.value?.isMasterUserEditingPostAdjudicationClaim,
        isPageLoading: props.value?.isPageLoading,
        loadingStatus: props.value?.loadingStatus,
        isReadOnlyView: props.value?.isReadOnlyView,
        isReadOnlyAccessUser: props.value?.isReadOnlyAccessUser,
        isFieldDisabled:
          props.value?.isInEditMode === false || props.value?.isReadOnlyView === true,
        setIsPageLoading: props.value?.setIsPageLoading,
        setLoadingStatus: props.value?.setLoadingStatus,
        lockTabs: props.value?.lockTabs,
        refreshData: props.value?.refreshData,
        updateClaimData: props.value?.updateClaimData,
        autoDecision: props.value?.autoDecision,
        downloadClaimCalculationLetter: props.value?.downloadClaimCalculationLetter
      }}
    >
      <HBTFormActionsContext.Provider
        value={{
          setUuid,
          setCompleteCards,
          isCardComplete,
          addCompleteCard,
          removeCompleteCard,
          clearCompleteCards,
          enableEditMode,
          disableEditMode
        }}
      >
        {props.children}
      </HBTFormActionsContext.Provider>
    </HBTFormContext.Provider>
  );
};

export default HBTFormConfiguredProvider;
