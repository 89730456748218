import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import getPathInObject from 'Constants/Utils/GetPathInObject';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import FormError from 'Components/Inputs/FormError';

import type { Props } from './types';
import styles from './styles.module.scss';

const FormRadioWrapper: FC<Props> = ({ name, label, className, children }) => {
  const {
    formState: { errors }
  } = useFormContext();
  const { message, type } = getPathInObject(errors, name) || {};
  return (
    <fieldset
      className={`d-flex flex-wrap justify-content-start align-items-start ${className}${
        message ? ' error' : ''
      }`}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="radiogroup"
    >
      <legend className={styles['radio-label']}>
        <Text field={label} />
      </legend>
      {children}
      <FormError className={styles.full} name={name} message={message} type={type} />
    </fieldset>
  );
};

export default FormRadioWrapper;
export type { Props };
