import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';

import Calculations from './Calculations';
import NetBalance from './NetBalance';

import { ReceivedRefundPropsFields } from './types';

function parseFieldValues<T extends Record<K, FieldValue>, K extends keyof T>(
  obj: T
): Record<K, string | undefined> {
  return Object.fromEntries<string | undefined>(
    Object.entries<FieldValue>(obj)?.map(([key, { value }]) => [key, value])
  ) as Record<K, string | undefined>;
}

const ReceivedRefund: FC<ReceivedRefundPropsFields> = ({
  // Calculations
  heading,
  addReceivedAdjustment,
  amount,
  dateAdded,
  applyChanges,
  deleteButton,
  cancelButton,
  editButton,
  refundTableCaption,

  // Both
  totalReceivedPayment,
  receivedPayment,

  // NetBalance
  grossRefundBalance,
  netRefundBalance,
  generateLetter
}) => {
  const calculationsProps = {
    heading,
    addReceivedAdjustment,
    amount,
    dateAdded,
    totalReceivedPayment,
    receivedPayment,
    applyChanges,
    deleteButton,
    cancelButton,
    editButton,
    refundTableCaption
  };
  const netBalanceProps = parseFieldValues({
    totalReceivedPayment,
    receivedPayment,
    grossRefundBalance,
    netRefundBalance,
    generateLetter
  });

  const refundReason = useWatch({ name: 'refundReason', defaultValue: undefined });

  if (Boolean(refundReason) === false) {
    // Our reason isn't selected yet
    return null;
  }

  return (
    <>
      <Calculations {...calculationsProps} />
      <NetBalance {...netBalanceProps} />
    </>
  );
};

export default ReceivedRefund;
