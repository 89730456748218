import React from 'react';
import { DeepMap, FieldError } from 'react-hook-form';
import i18n from 'i18next';

import { countNestedNamedProperty } from 'Constants/Utils/CountNestedNamedProperty/countNestedNamedProperty';
import {
  NotificationCard,
  NotificationCardProps
} from 'Feature/CommonComponents/ContentComponents';
import { CardType } from 'Feature/CommonComponents/Enums';
import { ImageField } from '@sitecore-jss/sitecore-jss-react/types/components/Image';

export const formValidationErrorCard = {
  id: 'formValidationErrorNotification',
  notificationType: CardType.VERTICAL_ERROR,
  title: i18n.t('ErrorManagement-ValidationCard-MainTitle'),
  message: [],
  formWarningIcon: {
    src: '/data/media/image/icons/icon_error_red.svg',
    alt: 'Form Error'
  }
};

export type SitecoreCardNameField = string;
export type ValidationObjectNamespace = string;
/**
 * Record containing keys of the Sitecore field values for the Card Names,
 *  with values of the validation schema's namespace for the cards.
 * E.g., "Lender Details" Card is returned from Sitecore with fields.name.value === 'claimsLenderDetails'
 *  and the validation schema has an object that namespaces all of the "Lender Details" form elements/inputs
 *  as 'lender' (i.e., schema.lender), therefore we use these values in the KVP of the Record below.
 *
 * This isn't sustainable as now because new cards or changes to the Sitecore names would break this.
 *
 * Depended On By:
 * - ProgressBar (to associate the card's `isComplete` value..)
 * - ClaimsSubmissionForm NotificationCard - formValidationErrorCard (to associate the `Section <Char>` value in messages)
 */
export const sitecoreCardNameToValidationFieldKeyMapCreate: Record<
  SitecoreCardNameField,
  ValidationObjectNamespace
> = {
  claimsIndicatorDetails: 'indicator',
  claimsLenderDetails: 'lender',
  claimsBorrowerDetails: 'borrowers',
  claimsPropertyDetails: 'property',
  claimDefaultManagement: 'defaultManagement',
  claimsDetails: 'detail',
  claimsListingPeriod: 'listings',
  claimsBorrowersCharges: 'borrowerCharges',
  claimsIncomeDetails: 'rentalIncomeReceivedFlag',
  claimsPartialPaymentDetails: 'partialPaymentReceivedFlag',
  claimsAttachments: 'attachments', // TODO: Attachment's validation name
  claimsManualReview: 'manualReview'
};

export const sitecoreCardNameToValidationFieldKeyMapEdit: Record<
  SitecoreCardNameField,
  ValidationObjectNamespace
> = {
  claimsIndicatorDetails: 'indicator',
  claimsLenderDetails: 'lender',
  claimsBorrowerDetails: 'borrowers',
  claimsPropertyDetails: 'property',
  claimDefaultManagement: 'defaultManagement',
  claimsDetails: 'detail',
  claimsListingPeriod: 'listings',
  claimsBorrowersCharges: 'borrowerCharges',
  claimsIncomeDetails: 'rentalIncomeReceivedFlag',
  claimsPartialPaymentDetails: 'partialPaymentReceivedFlag',
  claimsManualReview: 'manualReview'
};

export type Props = {
  errors: DeepMap<any, FieldError>;
  cards: Array<{ fields: { name: FieldValue; cardTitle: FieldValue } }>;
  isInEditMode?: boolean;
  errorIcon: ImageField;
};

const NotificationCardComponent: React.FC<Props> = ({
  errors,
  cards,
  isInEditMode,
  errorIcon
}: Props) => {
  let notificationCard: NotificationCardProps | null = null;

  const manualReviewErrorsCheck = (errors: DeepMap<any, FieldError>) => {
    if (
      errors?.indicator?.manualReviewRequiredReasonTypeCode ||
      errors?.indicator?.manualReviewRequiredFlag ||
      errors?.indicator?.otherManualReviewRequiredReasonDescription
    ) {
      errors.manualReview = {};
    }
    return errors;
  };
  const indicatorContainsOnlyManualReviewErrors = function (key: string) {
    return (
      key === 'manualReviewRequiredReasonTypeCode' ||
      key === 'manualReviewRequiredFlag' ||
      key === 'otherManualReviewRequiredReasonDescription'
    );
  };

  if (Object.keys(errors).length > 0) {
    /**
     * Since we're getting the validation key from the errors object,
     *  we want to swap the keys and values of the cardNameValidationMap
     *  so that we have validation keys as the keys, and the card name as the value.
     *
     * We do this by getting the object entries (Object.entries -> [[key, value], ...])
     *  and then mapping them (Array.map) to their key-value-swapped version ([k, v] => [v, k]).
     */
    const validationKeyToCardNameMap = Object.fromEntries(
      Object.entries(
        isInEditMode
          ? sitecoreCardNameToValidationFieldKeyMapEdit
          : sitecoreCardNameToValidationFieldKeyMapCreate
      )?.map(([k, v]) => [v, k])
    );

    /**
     * For the validation error card, we need the Card Titles, so we create an object
     *  that maps the card's name to the card title. That way we can use the card name
     *  from the swapped dictionary above to lookup its title in order to add it to the
     *  messages array for the rendered card.
     */
    const cardHeadingMap = Object.fromEntries(
      cards?.map(({ fields: { name, cardTitle } }) => [name?.value, cardTitle?.value])
    );

    // Check if indicator contains manaual review errors
    if (errors?.indicator) {
      errors = manualReviewErrorsCheck(errors);
    }

    const errorCount = countNestedNamedProperty(errors, 'message');

    const errorTitle = i18n.t('ErrorManagement-ValidationCard-MainTitle') ?? '';

    const warningIcon = {
      src: errorIcon.value?.src,
      alt: errorIcon.value?.alt
    };

    notificationCard = {
      ...formValidationErrorCard,
      title: errorTitle.replace(/{FieldValue}/, errorCount.toString()),
      message: Object.entries(errors)
        ?.map((validationKey) => {
          let heading = cardHeadingMap[validationKeyToCardNameMap[validationKey[0]]];
          // Exceptions -- Unfortunately, as now, there are a few validation fields
          //  that aren't namespaced but share an existing card. Our approach to the
          //  sitecoreCardNameToValidationFieldKeyMap needs to change to allow for
          //  multiple values. This would require changes here and to the ProgressBar
          if (heading == null) {
            switch (validationKey[0]) {
              case 'netOperatingIncomesRentalIncomes':
                heading = cardHeadingMap.claimsIncomeDetails;
                break;
              case 'partialPayments':
                heading = cardHeadingMap.claimsPartialPaymentDetails;
                break;
              default:
                break;
            }
          }
          if (validationKey[0] === 'indicator') {
            if (
              Object.keys(validationKey[1] as Record<string, unknown>).every(
                indicatorContainsOnlyManualReviewErrors
              )
            )
              heading = null;
          }
          return heading != null
            ? `${i18n
                .t('ErrorManagement-ValidationCard-SectionTitle')
                .replace(/{FieldValue}/, heading)}`
            : '';
        })
        .sort((a, b) => a.localeCompare(b)),
      formWarningIcon: warningIcon
    };
  }

  return (
    <>
      {notificationCard != null && (
        <div className="row">
          <div className="col-8">
            <NotificationCard {...notificationCard} />
          </div>
        </div>
      )}
    </>
  );
};
export default NotificationCardComponent;
