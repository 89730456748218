import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useFeature } from 'flagged';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import i18n from 'i18next';
import { UserRole, Module } from '@hobt/constants';

import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

import { ProgressBarProps } from './ProgressBar.types';
import { ProgressBarItem, ProgressBarItemProps, ProgressBarStepStatus } from './ProgressBarItem';
import _ from 'lodash-es';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

export const ProgressBar: React.FC<ProgressBarProps> = (props: ProgressBarProps) => {
  const [progressItems, setProgressItems] = useState(props.progressBarItems);
  const [higlightedItemIndex, setHiglightedItemIndex] = useState(0);
  const [preventHandleScroll, setPreventHandleScroll] = useState(false);

  const siteType = useFeature(FeatureFlags.INTERNAL);
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const isReadOnlyUser =
    sitecoreContext?.user?.moduleRoleMapping[Module.Default][0] === UserRole.ReadOnly;

  useEffect(() => {
    // setProgressItems on language toggle
    setProgressItems(() => {
      const updatedNewItemsState = props.progressBarItems;
      updatedNewItemsState[higlightedItemIndex].status = ProgressBarStepStatus.PENDING;
      return updatedNewItemsState;
    });
  }, [props.progressBarItems]);

  useEffect(() => {
    const throttledScroll = _.throttle(handleScroll, 150);
    window.addEventListener('scroll', throttledScroll);
    return () => window.removeEventListener('scroll', throttledScroll);
  }, []);

  // Handle Scroll
  const handleScroll: () => void = () => {
    if (preventHandleScroll === false) {
      const newPageOffset: number = window.pageYOffset;
      let itemIndexToHiglight = 0;

      // Go through each item and find the largest offset/item
      for (let i = 0; i < props.progressBarItems.length; i++) {
        // if progressBarItems is the last iteration of the array
        if (i + 1 == props.progressBarItems.length) {
          if (newPageOffset >= props.itemReferences![i].current.offsetTop - 100) {
            itemIndexToHiglight = i;
          }
        } else if (newPageOffset >= props.itemReferences![i].current.offsetTop) {
          itemIndexToHiglight = i;
        }
      }

      // Go through each item and find status to update
      setProgressItems((prevItemsState) => {
        const newItemsState = [...prevItemsState];
        const updatedNewItemsState = newItemsState.map((item) =>
          item.status === ProgressBarStepStatus.PENDING
            ? { ...item, status: ProgressBarStepStatus.DEFAULT }
            : { ...item }
        );
        updatedNewItemsState[higlightedItemIndex].status = ProgressBarStepStatus.DEFAULT;
        updatedNewItemsState[itemIndexToHiglight].status = ProgressBarStepStatus.PENDING;
        return updatedNewItemsState;
      });

      setHiglightedItemIndex(itemIndexToHiglight);
    }
  };

  // Handle Click Scroll
  const handleProgressSidebarClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setPreventHandleScroll(true);

    // Get progress bar item ID's
    const currentTargetId: string = e.currentTarget.getAttribute('id') || `progress-indicator-0`;
    const itemIndexToHiglight: number =
      Number(currentTargetId.replace('progress-indicator-', '')) || 0;

    // swetch through each ID's and get the references and offsetTop
    const cardRef: React.MutableRefObject<HTMLElement> = props.itemReferences![itemIndexToHiglight];
    window.scrollTo(itemIndexToHiglight, cardRef!.current.offsetTop + 30);

    // Go through each item and find status to update
    setProgressItems((prevItemsState) => {
      const newItemsState = [...prevItemsState];
      newItemsState[higlightedItemIndex].status = ProgressBarStepStatus.DEFAULT;
      newItemsState[itemIndexToHiglight].status = ProgressBarStepStatus.PENDING;
      return newItemsState;
    });

    setHiglightedItemIndex(itemIndexToHiglight);
    setPreventHandleScroll(false);
  };

  return (
    <div className="card sticky-top">
      <header className="card-header sticky-top card__header" id="progressBarHeader">
        <h2 className="card__header-title">
          <Text field={props.progressHeaderTitle} />
        </h2>
      </header>
      <div className="card-body card__body">
        <div className="row card__body-row">
          <div className="col-12 form-progress pt-3 mb-4">
            <Scrollbars className="form-progress__scrollableContent">
              {progressItems.map(
                (itemProps: ProgressBarItemProps, idx: number) =>
                  itemProps?.name?.value && (
                    <ProgressBarItem
                      key={`key-${itemProps.name.value}`}
                      index={idx}
                      clickHandler={handleProgressSidebarClick}
                      name={itemProps.name}
                      type={itemProps.type}
                      status={itemProps.status}
                      arialabelScreenReader={itemProps.arialabelScreenReader}
                      validationKey={itemProps.validationKey}
                    />
                  )
              )}
            </Scrollbars>
          </div>
        </div>
        <div className="row card__body-row">
          {siteType !== null && siteType ? (
            // For Internal default submission & inventory detail page
            <div className="col-12">
              <button
                type="submit"
                role="button"
                className="btn btn-primary mr-4"
                disabled={props.isDisabledButton || isReadOnlyUser}
                onSubmit={props.onSubmitHandler}
                aria-label={
                  props.submitButtonText && (props.submitButtonText.value as string | undefined)
                }
              >
                <label className="sr-only">{`${
                  props.submitButtonText && props.submitButtonText.value
                } ${i18n.t('DefaultHomeownerRequest')}`}</label>
                {props.submitButtonText && props.submitButtonText.value}
              </button>
              <button
                type="button"
                role="button"
                className="btn btn-secondary"
                disabled={props.isDisabledButton}
                onClick={props.onCancelHandler}
                aria-label={
                  props.cancelButtonText && (props.cancelButtonText.value as string | undefined)
                }
              >
                <label className="sr-only">{`${
                  props.cancelButtonText && props.cancelButtonText.value
                } ${i18n.t('DefaultHomeownerRequest')}`}</label>
                {props.cancelButtonText && props.cancelButtonText.value}
              </button>
            </div>
          ) : (
            // For External default submission & inventory detail page
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary mr-4"
                role="button"
                hidden={props.hideButton} // hide in inventory detail page
                disabled={props.isDisabledButton}
                onSubmit={props.onSubmitHandler}
                aria-label={
                  props.submitButtonText && (props.submitButtonText.value as string | undefined)
                }
              >
                <label className="sr-only">{`${
                  props.submitButtonText && props.submitButtonText.value
                } ${i18n.t('DefaultHomeownerRequest')}`}</label>
                {props.submitButtonText && props.submitButtonText.value}
              </button>
              <button
                type="button"
                role="button"
                hidden={props.hideButton} // hide in inventory detail page
                disabled={props.isDisabledButton}
                className="btn btn-secondary"
                onClick={props.onCancelHandler}
                aria-label={
                  props.cancelButtonText && (props.cancelButtonText.value as string | undefined)
                }
              >
                <label className="sr-only">{`${
                  props.cancelButtonText && props.cancelButtonText.value
                } ${i18n.t('DefaultHomeownerRequest')}`}</label>
                {props.cancelButtonText && props.cancelButtonText.value}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
