import { useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { UseFormReturn, useForm } from 'react-hook-form';

import { HbtServiceErrorCodes } from '@hobt/constants';
import { hbtResolver } from '@hobt/schema-validator';
import { IssueToManagement, itmSchema } from '@hobt/claim-domain';

import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import { CreateItmFlagFormData } from 'Feature/PreClaims/components/PreClaimsItmFlags/AddItmFlagForm/types';
import { ErrorObject } from 'Feature/UserManagement/models/types';
import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';

import { config } from '../../../../../config';

const useAddItmFlagFunctions = (
  onSuccessCallback?: Function,
  onErrorCallback?: Function,
  setIsLoadingCallback?: React.Dispatch<React.SetStateAction<Spinner>>,
  isEdit?: boolean
) => {
  const [showValidationError, setShowValidationError] = useState<boolean>(false);
  const [issueToManagementRecordID, setIssueToManagementRecordID] = useState<string>();

  // API Client Config
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  const authContext = useAuthenticationContext();
  const { postWithAuth, putWithAuth } = ApiClient(authContext, apiClientConfig);

  const hookForm = useForm({
    resolver: hbtResolver(itmSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  } as Record<string, any>);

  const createPreClaimsITM = (data: CreateItmFlagFormData) => {
    setIsLoadingCallback?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description',
      isFlyout: true
    });

    postWithAuth(config.preClaimItmApi.urls.submit, { ...data })
      .then(() => {
        hookForm.reset();
        if (onSuccessCallback) {
          onSuccessCallback();
        }
        setIsLoadingCallback?.({
          isLoading: false
        });
      })
      .catch((e: ErrorObject) => {
        if (e.response?.status === 500 && e.response.data.error.errorCode === 'HBT_ERR_5012') {
          setShowValidationError(true);
        } else if (onErrorCallback) {
          hookForm.reset();
          onErrorCallback();
        }

        setIsLoadingCallback?.({
          isLoading: false
        });
      });
  };

  const editPreClaimsITM = (data: CreateItmFlagFormData) => {
    const editData = {
      typeCodes: data.typeCodes,
      cmhcLoanAccountNumber: data.cmhcLoanAccountNumber,
      issueToManagementRecordID
    };

    setIsLoadingCallback?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description',
      isFlyout: true
    });

    putWithAuth(config.preClaimItmApi.urls.update, { ...editData })
      .then((_responseData: AxiosResponse<IssueToManagement>) => {
        if (onSuccessCallback) {
          onSuccessCallback();
        }
        setIsLoadingCallback?.({
          isLoading: false
        });
      })
      .catch((e: ErrorObject) => {
        if (
          e.response?.status === 400 &&
          e.response.data.error.errorCode === HbtServiceErrorCodes.HBT_ERR_VALIDATION
        ) {
          setShowValidationError(true);
        } else if (onErrorCallback) {
          onErrorCallback();
        }

        setIsLoadingCallback?.({
          isLoading: false
        });
      });
  };

  const handleSubmit: any = (data: CreateItmFlagFormData) => {
    isEdit ? editPreClaimsITM(data) : createPreClaimsITM(data);
  };

  const onSubmitErrors: any = (errs: Record<string, Object>) => {
    // TODO: Remove once validations are in place
    console.log('FORM ERRORS: ', errs);
  };

  const onCloseValidationErrors = () => {
    setShowValidationError(false);
  };

  return {
    hookForm,
    handleSubmit,
    onSubmitErrors,
    onCloseValidationErrors,
    setIssueToManagementRecordID,
    showValidationError
  };
};

export default useAddItmFlagFunctions;
