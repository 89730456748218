import React from 'react';
// import i18n from 'i18next';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { UploadStatusProps } from '../types';

export const FileUploadError: React.FC<UploadStatusProps> = ({
  fields,
  attachment,
  deleteAttachment
}: UploadStatusProps) => (
  <div className="upload-progress-bar-error">
    <div className="upload-progress-bar-error__main">
      <div className="upload-progress-bar-error__icon">
        <Image field={fields.warningIcon} className="icon-24" />
      </div>
      <div className="upload-progress-bar-error__file-name">{attachment.fileName}</div>
      <div className="upload-progress-bar-error__error-message">
        {/* TODO: change to multiple error messages on file */}
        {/* {i18n.t('DefaultSubmission-Card-FileUploadError')} */}
        {attachment.errorMessage}
      </div>
      <button
        type="button"
        className="upload-progress-bar-error__cancel"
        aria-label={(fields?.cancelIcon?.field?.value as string) ?? ''}
        onClick={() => deleteAttachment(attachment.id)}
      >
        <Image field={fields.deleteIcon} className="icon-24" />
      </button>
    </div>
    <div className="upload-progress-bar-error__bottom-border"></div>
  </div>
);
