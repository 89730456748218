import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { config } from '../../config';

const reactPlugin = new ReactPlugin() as any;

let appInsights = null;

const createTelemetryService = () => {
  // @ts-ignore
  const initialize = (userName) => {
    if (config.app.applicationInsightKey) {
      const ai = new ApplicationInsights({
        config: {
          instrumentationKey: config.app.applicationInsightKey,
          maxBatchInterval: 0,
          accountId: userName,
          disableFetchTracking: false,
          extensions: [reactPlugin]
        }
      });

      ai.loadAppInsights();
      appInsights = ai.appInsights;
    }
  };

  return { initialize };
};

ReactPlugin.prototype.trackEvent = function (event, customProperties) {
  if (config.app.applicationInsightKey) {
    // @ts-ignore
    this._analyticsPlugin && this._analyticsPlugin.trackEvent(event, customProperties);
  }
};

// @ts-ignore
export default (Component) => withAITracking(reactPlugin, Component);
export const telemetryService = createTelemetryService();
export { reactPlugin, appInsights };
