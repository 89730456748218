export const groupBy = <K extends unknown, T extends unknown>(
  items: Array<T>,
  keyGetterFn: (input: T) => K
): Map<K, Array<T>> => {
  const groupedMap: Map<K, Array<T>> = new Map();

  items.forEach((item: T) => {
    const key: K = keyGetterFn(item);
    const collection = groupedMap.get(key);

    if (collection != null) {
      collection.push(item);
    } else {
      groupedMap.set(key, [item]);
    }
  });

  return groupedMap;
};
