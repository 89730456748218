import React, { memo, useCallback } from 'react';
import { Box, Stack } from '@mui/material';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import useEnterOrSpaceKey from 'Components/Hooks/UseEnterOrSpaceKey';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { Button } from '../../../../../Components/Core/Button';
import { ButtonVariant } from '../../../../../Components/Core/Button/types';
import HbtGenericErrorPageProps from '../../models/HbtGenericErrorPageProps';
import styles from './styles.module.scss';

const HbtGenericErrorPage: React.FC<HbtGenericErrorPageProps> = (
  props: HbtGenericErrorPageProps
) => {
  const authenticationContext = useAuthenticationContext();

  const handleLogout = useCallback(() => {
    authenticationContext.signOut();
  }, [authenticationContext]);

  return (
    <div className={styles.hbtGenericErrorPageMain}>
      <Stack direction="row">
        <Box className={styles.image}>
          <Image field={props.fields.image} />
        </Box>
        <Box>
          <div className={styles.header}>
            <Text field={props.fields.heading} />
          </div>
          <div className={styles.content}>
            <RichText field={props.fields.content} />
          </div>
          <div className={styles.LogOutButton}>
            <Button
              id="logout-button"
              name={props.fields.logoutButtonText.value ?? 'Log Out'}
              variant={ButtonVariant.PRIMARY}
              ariaText={{ value: `${props.fields.logoutButtonText.value}` }}
              text={{ value: props.fields.logoutButtonText.value }}
              onClick={handleLogout}
              onKeyDown={useEnterOrSpaceKey(handleLogout)}
            />
          </div>
        </Box>
      </Stack>
    </div>
  );
};

export default memo(HbtGenericErrorPage);
