import React, { useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import UserContactInfoProps from 'Feature/UserManagement/models/UserContactInfoProps';
import { telephoneFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormText from 'Components/Inputs/FormText';
import FormNumber from 'Components/Inputs/FormNumber';
import { useFormContext } from 'react-hook-form';

const UserContactInfo: React.FC<UserContactInfoProps> = ({ fields }: UserContactInfoProps) => {
  const { setValue, watch } = useFormContext();
  const preferredLanguage = watch('contact.preferredLanguageCode');
  useEffect(() => {
    if (preferredLanguage === undefined) {
      setValue('contact.preferredLanguageCode', null);
    }
  }, [preferredLanguage]);

  return (
    <div>
      <h2 className="card-title">
        <Text field={fields.sectionTitle} />
      </h2>
      <p>
        <Text field={fields.sectionSubtitle} />
      </p>

      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormText className="" name="contact.firstName" label={fields.firstName} />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormText className="" name="contact.lastName" label={fields.lastName} />
        </div>
      </div>

      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormNumber
            className=""
            name="contact.phoneNumber"
            label={fields?.phoneNumber}
            formatProps={telephoneFormat}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormText className="" name="contact.extensionPhoneNumber" label={fields.extension} />
        </div>
      </div>

      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormNumber
            className=""
            name="contact.faxNumber"
            label={fields?.faxNumber}
            formatProps={telephoneFormat}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormDropdown
            name="contact.preferredLanguageCode"
            label={fields.preferredLanguage}
            options={fields.languagePreferUser?.fields?.listItems}
          />
        </div>
      </div>

      <hr />
    </div>
  );
};

export default UserContactInfo;
