import React, { createContext, useContext, ReactNode } from 'react';

import { ModifyUserContextType } from './types';

const ModifyUserContext = createContext<ModifyUserContextType>({
  userData: []
});
export const useModifyUserContext = () => useContext<ModifyUserContextType>(ModifyUserContext);

const ModifyUserContextProvider = (props: {
  children?: ReactNode;
  value?: ModifyUserContextType;
}) => {
  return (
    <ModifyUserContext.Provider
      value={{
        userData: props.value?.userData
      }}
    >
      {props.children}
    </ModifyUserContext.Provider>
  );
};

export default ModifyUserContextProvider;
