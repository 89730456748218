import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import i18n from 'i18next';
import { convertTimestampToDate } from '@hobt/utils';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { AccountStatus, UserRole, ModuleMapping } from '@hobt/constants';

import ManageUsersInternalProps, {
  UserMangementInternalInventoryRecords
} from 'Feature/UserManagement/models/ManageUsersInternalProps';
import { pathNames } from 'Constants/pathNames';
import { PageCardContainer } from 'Components/PageComponents/PageCardContainer';
import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { Tabs, TabItemProps } from 'Components/Navigation/Tabs';

import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { DataTableFooter } from 'Components/Common/DataTableFooter';
import { Button } from 'Components/Common/Button';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { useDataGridEngine } from 'Components/Hooks/DataGridEngine';
import {
  TabMapping,
  TabType,
  ExternalStatus,
  UserApiResponse
} from 'Feature/UserManagement/models/types';

import { SearchBar } from 'Components/PageComponents/SearchBar';
import { SearchFieldOptions } from 'Components/PageComponents/SearchBar/types';

import FormToggle from 'Components/Inputs/FormToggle';
import { StatusCell } from 'Components/Common/DataTable/DataTableRow/StatusCell';
import { defaultEmptyString } from 'Components/Common/Api/utils/HandleEmpty';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { BuildDropDownDictionary } from 'Components/Common/Helpers';
import { DownloadReport } from './DownloadReport/DownloadReport';
import { UploadDocuments } from './UploadDocuments/UploadDocuments';

import { config } from '../../../../config';
import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { ExternalTabUserInventory } from './ExternalTab/ExternalTabUserInventory';

const ManageUsersInternal = ({ fields }: ManageUsersInternalProps) => {
  const history = useHistory();
  const [rowInfo, setRowInfo] = useState<any>();
  const location = useLocation();
  const [locationData, setLocationData] = useState<any>(undefined);
  let dataLocation = location.state;
  const [isInventoryToast, setInventoryToast] = useState<boolean>(false);

  // TODO: Handle API Error
  const onApiErrorCallback = () => setInventoryToast(true);
  const onToastCloseHandler = () => setInventoryToast(false);
  const [isAdminActive, setIsAdminActive] = useState(false);

  const [items] = useState<TabItemProps[]>([
    {
      displayText: fields.internalText,
      name: TabMapping.get(TabType.Internal)!
    },
    {
      displayText: fields.externalText,
      name: TabMapping.get(TabType.External)!
    }
  ]);
  const [isTab, setIsTab] = useState<TabItemProps>(items[0]);

  const onTabClick = (tab: TabItemProps) => {
    if (tab.name == 'internal') {
      setLocationData(undefined);
    }
    setIsTab(tab);
    setHasSearched(false);
    setIsAdminActive(false);
    onSearchReset();
  };

  const [hasSearched, setHasSearched] = React.useState<boolean>(false);
  const {
    rowData,
    currentPage,
    itemsPerPage,
    onItemsPerPageChange,
    totalItems,
    totalPages,
    itemRangeFrom,
    itemRangeTo,
    isLoading: isInventoryLoading,
    onPagerClick,
    onSort,
    onSearch,
    onSearchReset,
    getSortDirectionForField,
    getTableAccessibilityText
  } = useDataGridEngine<UserMangementInternalInventoryRecords>({
    apiBaseUrl: config.userApi.urls.inventory,
    onApiErrorCallback,
    defaultItemsPerPage: 10,
    isAdmin: isAdminActive,
    isUserModule: true
  });

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const [showDownloadReport, setShowDownloadReport] = React.useState<boolean>(false);
  const [showUploadDocument, setShowUploadDocument] = React.useState<boolean>(false);
  const [toastState, setToastState] = useState<ApplicationStates>(ApplicationStates.DEFAULT);
  const [showUploadToast, setShowUploadToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string | FieldValue>('');
  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.admin,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  const onUserToastCloseHandler = () => setShowUploadToast(false);
  const accountStatusDictionary = BuildDropDownDictionary(fields?.accountStatus?.fields?.listItems);

  const userRolesDictionary = BuildDropDownDictionary(fields?.userRoles?.fields?.listItems);

  const userTitlesDictionary = BuildDropDownDictionary(fields?.userTitles?.fields?.listItems);

  const searchByFields: SearchFieldOptions[] = [
    {
      displayText: fields?.email?.value ?? '',
      value: 'EmailID',
      type: 'text'
    },
    {
      displayText: fields?.name?.value ?? '',
      value: 'FullName',
      type: 'text'
    }
  ];
  // sets data as soon as we get it if it exists
  useEffect(() => {
    if (rowData && rowData.length > 0) setRowInfo(rowData);
  }, [rowData, isAdminActive]);

  const onRowSelect = (row: any) => {
    history.push(`/${i18n.language}${pathNames.getOneUser}?id=${row.userID}`);
  };

  const onToggleShowAdminActive = () => {
    setIsAdminActive(!isAdminActive);
  };

  const handleFileDownload = (fileUrl: string) => {
    const downloadElement = document.createElement('a');
    downloadElement.setAttribute('href', fileUrl);
    downloadElement.click();
  };

  const handleInternalDownloadTmplate = () => {
    const { title } = fields.internalTemplateLink.fields;
    const fileUrl = title?.value?.href;
    handleFileDownload(fileUrl);
  };
  const handleExternalDownloadTmplate = () => {
    const { title } = fields.externalTemplateLink.fields;
    const fileUrl = title?.value?.href;
    handleFileDownload(fileUrl);
  };

  const createNewUser = () => {
    if (isTab.name === 'external') {
      return history.push(`/${i18n.language}${pathNames.createUserExternal}`);
    }
    return history.push(`/${i18n.language}${pathNames.createUser}`);
  };

  const showHideDownloadReport = () => {
    setShowDownloadReport(false);
  };

  const showHideUploadDocument = () => {
    setShowUploadDocument(false);
  };
  const showToast = (result: any) => {
    if (result === UserApiResponse.Success) {
      setShowUploadToast(true);
      setToastMsg(i18n.t('UserManagement-Users-DownloadUserReport'));
      setToastState(ApplicationStates.SUCCESS);
      setShowDownloadReport(false);
    } else {
      setShowUploadToast(true);
      setToastMsg(i18n.t('UserManagement-UsersReport-ErrorToastMessage'));
      setToastState(ApplicationStates.ERROR);
      setShowDownloadReport(false);
    }
  };
  useEffect(() => {
    if (dataLocation == 'external') {
      setLocationData(dataLocation);
      return onTabClick(items[1]);
    }
  }, [dataLocation]);

  return (
    <>
      {showDownloadReport && (
        <DownloadReport
          fields={fields.downloadUserReport.fields}
          isActive={showHideDownloadReport}
          isSuccess={showToast}
        />
      )}
      {showUploadDocument && (
        <UploadDocuments
          fields={fields.uploadFiles.fields}
          isActive={showHideUploadDocument}
          tabName={isTab.name}
        />
      )}
      <ToastNotification
        type={toastState}
        isActive={showUploadToast}
        title={
          toastState === ApplicationStates.ERROR
            ? i18n.t('DefaultSubmission-ErrorToastTitle')
            : i18n.t('DefaultActions-SystemSuccessToast-Action-Title')
        }
        content={toastMsg}
        onCloseCallback={() => onUserToastCloseHandler()}
      />
      <ToastNotification
        isActive={isInventoryToast}
        type={ApplicationStates.ERROR}
        title={i18n.t('Globals-Toast-Server-Error-Title')}
        content={{
          value: i18n.t('DefaultsInventoryTable-SystemErrorToast-Message')
        }}
        onCloseCallback={onToastCloseHandler}
      />
      <PageCardContainer name="manageUsers">
        <div className={styles.layoutContainer}>
          <div className={styles.titleContainer}>
            <div>
              <Text tag="h2" field={fields.pageTitle} />
            </div>
            <div className={styles.descriptionText}>
              <Text tag="small" field={fields.description} />
            </div>
          </div>
        </div>
        <hr />
        <Tabs
          currentTab={locationData == 'external' ? items[1] : isTab}
          items={items}
          initialActiveTabName={isTab.name}
          tabOnClickCallback={onTabClick}
          ariaControlsName="UserRoles"
          label={isTab.name === 'internal' ? fields.internalText : fields.externalText}
          className={styles.titleContainer}
        />

        <div className={styles.buttonsContainer}>
          <div className={styles.useActionBtn}>
            <div className={styles.userButtons}>
              <Button
                responsive
                name="addUserBtn"
                text={fields.addUserBtn}
                ariaText={fields.addUserBtn}
                onClick={createNewUser}
                disabled={isReadOnlyUser}
              />
            </div>
            <div className={styles.userButtons}>
              <Button
                secondaryButton
                responsive
                name="downloadBtn"
                text={fields.downloadBtn}
                ariaText={fields.downloadBtn}
                onClick={() => setShowDownloadReport(true)}
                disabled={isReadOnlyUser}
              />
            </div>
            <div className={styles.userButtons}>
              <Button
                secondaryButton
                responsive
                name="uploadBtn"
                text={fields.uploadBtn}
                ariaText={fields.uploadBtn}
                onClick={() => setShowUploadDocument(true)}
                disabled={isReadOnlyUser}
              />
            </div>
          </div>
          <div className={styles.templatebtn}>
            {isTab.name === 'internal' && (
              <div className={styles.importUserButtons}>
                <Button
                  text={fields.internalTemplateBtn}
                  ariaText={fields.internalTemplateBtn}
                  name="internalTemplateBtn"
                  contextualButton
                  icon={() => (
                    <span
                      className={`material-icons-outlined icon--v-align-middle ${styles.actionIcons}`}
                    >
                      content_copy
                    </span>
                  )}
                  onClick={handleInternalDownloadTmplate}
                  type="button"
                />
              </div>
            )}
            {isTab.name === 'external' && (
              <div className={styles.importUserButtons}>
                <Button
                  text={fields.externalTemplateBtn}
                  ariaText={fields.externalTemplateBtn}
                  name="externalTemplateBtn"
                  contextualButton
                  icon={() => (
                    <span
                      className={`material-icons-outlined icon--v-align-middle ${styles.actionIcons}`}
                    >
                      content_copy
                    </span>
                  )}
                  onClick={handleExternalDownloadTmplate}
                  type="button"
                />
              </div>
            )}
          </div>
        </div>
        <hr />
        {isTab.name === 'internal' && (
          <div className={styles.dataTable}>
            <div className={styles.searchBarWrapper}>
              <div className={styles.searchBarWidth}>
                <SearchBar
                  name="userInventorySearchBar"
                  fieldLabelAriaText={i18n.t('SearchBar-SearchField')}
                  clearButtonAriaText={i18n.t('SearchBar-ClearButton')}
                  searchButtonAriaText={i18n.t('SearchBar-SearchButton')}
                  placeholderText={i18n.t('SearchBar-PlaceholderText')}
                  searchFieldOptions={searchByFields}
                  onClear={() => {
                    setHasSearched(false);
                    onSearchReset();
                  }}
                  onSearch={(field: string, value: string) => {
                    setHasSearched(true);
                    onSearch(field, value);
                  }}
                  count={totalItems}
                  displaySearchResultMessage={false}
                />
              </div>
              <div className={styles.adminToggle}>
                <FormToggle
                  id={`adminFormToggle`}
                  testId={`adminFormToggle`}
                  staticText={fields.admin?.value}
                  isChecked={isAdminActive}
                  onChange={onToggleShowAdminActive}
                />
              </div>
            </div>
            {hasSearched && totalItems != null && totalItems > 0 && (
              <div className={styles.noSearchResultsMessage}>
                <Text
                  field={{
                    value: `${totalItems}  ${i18n.t('Globals-Table-Search-To-Display')}`
                  }}
                />
              </div>
            )}
            {totalItems === 0 && (
              <div className={styles.noSearchResultsMessage}>
                <Text
                  field={{
                    value: i18n.t('Globals-Table-No-Search-To-Display')
                  }}
                />
              </div>
            )}
            {totalItems > 0 && (
              <DataTable
                name="manageUsers"
                isLoading={isInventoryLoading}
                caption={{ value: getTableAccessibilityText() ?? '' }}
                scrollOnHorizontalOverflow
              >
                <DataTableHeader>
                  <DataTableHeaderItem
                    sortable
                    onSortCallback={onSort}
                    sortDirection={getSortDirectionForField('fullName')}
                    name="fullName"
                    displayText={fields.userLabel}
                  />
                  <DataTableHeaderItem
                    sortable
                    onSortCallback={onSort}
                    sortDirection={getSortDirectionForField('emailID')}
                    name="emailID"
                    displayText={fields.email}
                  />
                  <DataTableHeaderItem
                    sortable
                    onSortCallback={onSort}
                    sortDirection={getSortDirectionForField('statusCode')}
                    name="statusCode"
                    displayText={fields.statusLabel}
                  />
                  <DataTableHeaderItem name="phoneNumber" displayText={fields.telephoneLabel} />
                  <DataTableHeaderItem
                    name="extensionPhoneNumber"
                    displayText={fields.extensionLabel}
                  />
                  <DataTableHeaderItem name="lastLogIn" displayText={fields.lastLogInLabel} />
                  <DataTableHeaderItem name="arrearsRole" displayText={fields.arrearsLabel} />
                  <DataTableHeaderItem name="defaultRole" displayText={fields.defaultLabel} />
                  <DataTableHeaderItem name="claimRole" displayText={fields.claimsLabel} />
                  <DataTableHeaderItem name="cmhcLevel" displayText={fields.cmhcLevel} />
                </DataTableHeader>

                <DataTableBody zebra>
                  {rowInfo &&
                    rowInfo.map((row: UserMangementInternalInventoryRecords, index: Number) => (
                      <DataTableRow
                        name={row.userID}
                        key={`record${index}`}
                        onClick={() => onRowSelect(row)}
                      >
                        <TextCell name="fullName" text={row.fullName ?? defaultEmptyString} />
                        <TextCell name="emailID" text={row.emailID ?? defaultEmptyString} />
                        <StatusCell
                          name="adAccountStatusCode"
                          statusText={
                            row.statusCode != null
                              ? accountStatusDictionary.get(row.statusCode?.toString()) ??
                                defaultEmptyString
                              : defaultEmptyString
                          }
                          className={
                            row.statusCode != null
                              ? ExternalStatus[row.statusCode as AccountStatus].color
                              : 'grey20'
                          }
                        />
                        <TextCell name="phoneNumber" text={row.phoneNumber ?? defaultEmptyString} />
                        <TextCell
                          name="extensionPhoneNumber"
                          text={row.extensionPhoneNumber ?? defaultEmptyString}
                        />
                        <TextCell
                          name="lastLogIn"
                          text={
                            row?.loginTimestamp != null
                              ? convertTimestampToDate(row.loginTimestamp)!
                              : defaultEmptyString
                          }
                        />
                        <TextCell
                          name="arrearsRoleCode"
                          text={
                            row.arrearsRoleCode != null
                              ? userRolesDictionary.get(row.arrearsRoleCode.toString())
                              : defaultEmptyString
                          }
                        />
                        <TextCell
                          name="defaultRoleCode"
                          text={
                            row.defaultRoleCode != null
                              ? userRolesDictionary.get(row.defaultRoleCode.toString())
                              : defaultEmptyString
                          }
                        />
                        <TextCell
                          name="claimRoleCode"
                          text={
                            row.claimRoleCode != null
                              ? userRolesDictionary.get(row.claimRoleCode.toString())
                              : defaultEmptyString
                          }
                        />
                        <TextCell
                          name="cmhcLevel"
                          text={
                            row.titleTypeCode != null
                              ? userTitlesDictionary.get(row.titleTypeCode.toString())
                              : defaultEmptyString
                          }
                        />
                      </DataTableRow>
                    ))}
                </DataTableBody>
              </DataTable>
            )}
            {totalItems > 0 && (
              <div className={`${styles.dataTableFooter} row col-12 justify-content-end`}>
                <DataTableFooter
                  name="manageUsersList"
                  currentPage={currentPage}
                  totalPages={totalPages || 1}
                  itemsPerPageLabel={{
                    value: i18n.t('DefaultsInventoryTable-ResultsPerPageDropdown-Title')
                  }}
                  itemsXofYText={`${itemRangeFrom || 0} - ${itemRangeTo || 0} ${i18n.t(
                    'Globals-Grid-ResultsPerPageDropdown-Of'
                  )}`}
                  totalItems={totalItems}
                  pagerClickCallback={onPagerClick}
                  itemsPerPageOnChangeCallback={onItemsPerPageChange}
                  itemsPerPageValue={itemsPerPage.toString()}
                />
              </div>
            )}
          </div>
        )}
        {isTab.name === 'external' && (
          <ExternalTabUserInventory fields={fields.externalTab.fields} />
        )}
      </PageCardContainer>
    </>
  );
};

export default ManageUsersInternal;
