import React, { useState, useEffect } from 'react';
import { Button } from 'Components/Common/Button';
import { DataTable } from 'Components/Common/DataTable';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { FormCheckbox } from 'Components/Inputs/FormCheckbox';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormText from 'Components/Inputs/FormText';
import FormTextAreaNumber from 'Components/Inputs/FormTextAreaNumber';
import _ from 'lodash-es';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useHbtModifyExternalPIUserContext } from '../../HbtModifyExternalPIUser/HbtModifyExternalPIUserContext';
import styles from './styles.module.scss';
import HbtExternalPIUserLevelAccessProps, { UserAccessRecord } from './types';

const HbtExternalPIUserLevelAccess = ({
  fields,
  addAccessRow = false,
  handleAddAccessRow,
  showUserActive,
  isModify = false,
  onRowDeleteUpdateUser
}: HbtExternalPIUserLevelAccessProps) => {
  const [userAccessRow, setUserAccessRow] = useState<UserAccessRecord[]>([]);
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);
  const { register, setValue } = useFormContext();
  const { remove } = useFieldArray({
    name: 'userAccesses'
  });

  const { userData } = useHbtModifyExternalPIUserContext();

  useEffect(() => {
    addAccessRow && handleAddUserAccessRow();
    showUserActive === true ? setIsFieldDisabled(false) : setIsFieldDisabled(true);
  }, [addAccessRow, showUserActive]);

  useEffect(() => {
    userData && setUserAccessRow(userData);
  }, [userData, isModify]);

  const handleAddUserAccessRow = () => {
    const newUserAccessRow: UserAccessRecord = {
      allowedTransitNumbers: [],
      defaultFinancialInstitutionCode: '',
      defaultTransitNumber: '',
      financialInstitutionCode: '',
      lenderRoleCode: 1,
      statusCode: '',
      userAuthorizationScope: [],
      access: {
        admin: {
          activeFlag: false
        },
        arrears: {
          activeFlag: false
        },
        claim: {
          activeFlag: false
        },
        default: {
          activeFlag: false
        },
        piRequest: {
          activeFlag: false
        },
        piPurposeTest: {
          activeFlag: false
        },
        piFileShare: {
          activeFlag: false
        }
      },
      isNewRow: true
    };
    setUserAccessRow([...userAccessRow, newUserAccessRow]);
    handleAddAccessRow();
  };

  const onRowDelete = (id: string | undefined, index: number) => {
    if (id) {
      const updatedRows = userAccessRow.filter((row) => row.userAccessID !== id);
      setUserAccessRow(updatedRows);
      onRowDeleteUpdateUser && onRowDeleteUpdateUser(id);
    } else {
      // If id is undefined, delete based on index
      const updatedRows = userAccessRow.filter((row, i) => i !== index);
      remove(index);
      setUserAccessRow(updatedRows);
    }
  };

  const updateAdminAccess = (index: number) => {
    register(`userAccesses[${index}].access.admin`);
    setValue(`userAccesses[${index}].access.admin`, { activeFlag: false });

    // setting up the servicing accesses to false
    register(`userAccesses[${index}].access.arrears`);
    setValue(`userAccesses[${index}].access.arrears`, { activeFlag: false });
    register(`userAccesses[${index}].access.claim`);
    setValue(`userAccesses[${index}].access.claim`, { activeFlag: false });
    register(`userAccesses[${index}].access.default`);
    setValue(`userAccesses[${index}].access.default`, { activeFlag: false });
  };

  return (
    <div className={styles.dataTable}>
      <DataTable
        name="claimsDataTable"
        caption={{ value: '' }}
        isLoading={false}
        scrollOnHorizontalOverflow
      >
        <DataTableHeader>
          <DataTableHeaderItem
            name="allowedTransitNumbers"
            displayText={fields.allowedTransitNumbers}
          />
          <DataTableHeaderItem
            name="defaultFinancialInstitutionCode"
            displayText={fields.defaultFinancialInstitutionCode}
          />
          <DataTableHeaderItem
            name="defaultTransitNumber"
            displayText={fields.defaultTransitNumber}
          />
          <DataTableHeaderItem
            name="financialInstitutionCode"
            displayText={fields.financialInstitutionCode}
          />
          <DataTableHeaderItem name="lenderCode" displayText={fields.approvedLenderCodeLabel} />
          <DataTableHeaderItem name="lenderRoleCode" displayText={fields.lenderRoleCode} />
          <DataTableHeaderItem name="statusCode" displayText={fields.statusCode} />
          <DataTableHeaderItem name="piRequestTool" displayText={fields.piRequestToolLabel} />
          <DataTableHeaderItem name="piPurpostTest" displayText={fields.piPurpostTestToolLabel} />
          <DataTableHeaderItem
            name="piFileTransferTool"
            displayText={fields.piFileTransferToolLabel}
          />
          <DataTableHeaderItem name="action" displayText={fields.action} />
        </DataTableHeader>
        <DataTableBody zebra>
          {userAccessRow != null &&
            userAccessRow.map((row: UserAccessRecord, index: number) => (
              <DataTableRow name={`access-${row.userAccessID}`} key={`access-${row.userAccessID}`}>
                <td>
                  <FormTextAreaNumber
                    className={`${styles.form} ${styles.externalToolAccessTableFormInputs}`}
                    label={{ value: '' }}
                    name={`userAccesses[${index}].allowedTransitNumbers`}
                    isReadOnly={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <FormText
                    className={`${styles.form} ${styles.externalToolAccessTableFormInputs}`}
                    label={{ value: '' }}
                    name={`userAccesses[${index}].defaultFinancialInstitutionCode`}
                    maxLength={3}
                    isReadOnly={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <FormText
                    className={`${styles.form} ${styles.externalToolAccessTableFormInputs}`}
                    label={{ value: '' }}
                    name={`userAccesses[${index}].defaultTransitNumber`}
                    maxLength={5}
                    isReadOnly={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <FormText
                    className={`${styles.form} ${styles.externalToolAccessTableFormInputs}`}
                    label={{ value: '' }}
                    name={`userAccesses[${index}].financialInstitutionCode`}
                    maxLength={3}
                    isReadOnly={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <FormText
                    className={`${styles.form} ${styles.externalToolAccessTableFormInputs}`}
                    label={{ value: '' }}
                    name={`userAccesses[${index}].lenderCode`}
                    maxLength={5}
                    isReadOnly={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <>
                    <FormDropdown
                      label={''}
                      disableDefaultSelect={false}
                      className={`${styles.form} ${styles.externalToolAccessTableFormDropdown}`}
                      name={`userAccesses[${index}].lenderRoleCode`}
                      options={fields.lenderRoleCodeList.fields.listItems}
                      isDisabled={isFieldDisabled === true}
                    />
                    {updateAdminAccess(index)}
                  </>
                </td>
                <td>
                  <FormDropdown
                    label={''}
                    disableDefaultSelect={false}
                    className={`${styles.form} ${styles.externalToolAccessTableFormDropdown}`}
                    name={`userAccesses[${index}].statusCode`}
                    options={fields.statusCodeList.fields.listItems}
                    isDisabled={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <FormCheckbox
                    label={{ value: '' }}
                    className={styles.externalToolAccessCheckbox}
                    name={`userAccesses[${index}].access.piRequest.activeFlag`}
                    isReadOnly={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <FormCheckbox
                    label={{ value: '' }}
                    className={styles.externalToolAccessCheckbox}
                    name={`userAccesses[${index}].access.piPurposeTest.activeFlag`}
                    isReadOnly={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <FormCheckbox
                    label={{ value: '' }}
                    className={styles.externalToolAccessCheckbox}
                    name={`userAccesses[${index}].access.piFileShare.activeFlag`}
                    isReadOnly={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <Button
                    className={styles.externalToolAccessRemoveBtn}
                    text={fields.removeBtn}
                    ariaText={fields.removeBtn}
                    name="removeAccessBtn"
                    contextualButton
                    icon={() => (
                      <span
                        className={`material-icons-outlined icon--v-align-middle ${styles.actionIcons}`}
                      >
                        delete_forever
                      </span>
                    )}
                    onClick={() => onRowDelete(row.userAccessID, index)}
                    type="button"
                    disabled={isFieldDisabled === true}
                  />
                </td>
              </DataTableRow>
            ))}
        </DataTableBody>
      </DataTable>
    </div>
  );
};

export default HbtExternalPIUserLevelAccess;
