import i18n from 'i18next';
import React, { FC, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { InternalClaimStatus } from '@hobt/claim-domain';

import { NotificationBanner } from 'Components/Common/Notifications/NotificationBanner';

import LoanDetailsProps from 'Feature/Claims/models/LoanDetailsProps';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

import { config } from '../../../../../config';

import ArrearsHistory from './ArrearsHistory';
import LoanInformation from './LoanInformation';

import styles from './styles.module.scss';

const LoanDetails: FC<LoanDetailsProps> = ({
  fields: { arrearsHistory, loanInformation, insurance }
}) => {
  const [showLoading, setLoading] = useState(false);
  const [isTabLoading, setIsTabLoading] = useState<boolean>(false);
  const closeLoading = function _closeLoading() {
    setLoading(false);
  };

  const { claimData, isMasterUser } = useHBTFormContext();

  const isEditable =
    isMasterUser &&
    ![InternalClaimStatus.Declined, InternalClaimStatus.Cancelled].includes(
      claimData?.profile?.claimStatus?.code
    );

  const methods = useForm();

  return (
    <>
      {showLoading && (
        <NotificationBanner
          title={{ value: i18n.t('Notification-Banner-Title') }}
          content={{ value: i18n.t('Notification-Banner-Content') }}
          warning={true}
          onCloseCallback={closeLoading}
        />
      )}
      <ArrearsHistory {...arrearsHistory[0].fields} />
      <fieldset disabled={!isEditable} className={styles.loanInfoWrapper}>
        <LoanInformation
          {...loanInformation[0].fields}
          save={loanInformation[0].fields.save}
          discard={loanInformation[0].fields.discard}
          isTabLoading={isTabLoading}
          setIsTabLoading={setIsTabLoading}
        />
      </fieldset>
      <hr />
      <h2 className={'header3'}>{insurance[0].fields.titleInsuranceData.value}</h2>
      {claimData?.cmhcLoanAccountNumber != null && (
        <iframe
          src={`${config.iifReport.url}${claimData?.cmhcLoanAccountNumber}`}
          allowFullScreen={false}
          // TODO: Would title have to be different for accessibility?
          title={`${insurance[0].fields.titleInsuranceData.value}`}
        />
      )}
    </>
  );
};

export default LoanDetails;
