import React, { useCallback, useState, useEffect, ReactElement, memo, useMemo } from 'react';
import { UserRole, Module, HttpResponseStatusCodes, UserType } from '@hobt/constants';
import { FileTransferFile, FileTransferFilesResponse } from '@hobt/portfolio-domain';
import { Box, Stack } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Button } from 'Components/Core/Button';
import { ButtonSize, ButtonVariant } from 'Components/Core/Button/types';
import Checkbox from 'Components/Core/Checkbox/Checkbox';
import DataTableColumn from 'Components/Core/DataTable/DataTableColumn';
import DataTable from 'Components/Core/DataTable/index';
import {
  ContentAlignment,
  DataTableColumnProps,
  ErrorType,
  InferDataModel
} from 'Components/Core/DataTable/types';
import { FlyoutContainer } from 'Components/Core/Flyout/FlyoutContainer';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import { Modal } from 'Components/Core/Modal';
import { MouseOrKeyboardEvent } from 'Components/Core/Modal/types';
import { ToastNotification } from 'Components/Core/Notifications/ToastNotification';
import { ToastNotificationVariant } from 'Components/Core/Notifications/ToastNotification/types';
import Pagination from 'Components/Core/Pagination';
import { PaginationPosition } from 'Components/Core/Pagination/types';
import { Tag } from 'Components/Core/Tag';
import { TagType } from 'Components/Core/Tag/types';
import { useCheckboxKeyDownHandler } from 'Feature/V2/Helper';
import { AuthenticationContextType, useAuthenticationContext } from 'Foundation/Authentication';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import i18n from 'i18next';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useForm, Controller } from 'react-hook-form';
import { convertFilesize, getHeaderFileName } from '../../../Helper';
import { MemoizedHbtDataTableCell } from '../../../Shared/MemoizedHbtDataTableCell';
import { deleteInventory, downloadInventory, getInventory } from './FileTransferDownloadTabService';
import styles from './styles.module.scss';
import {
  AccordionPanelType,
  Category,
  HbtPIFileTransferDownloadTabViewProps,
  FilterValue
} from './types';

interface FormValues {
  [key: string]: boolean;
}

const HbtPIFileTransferDownloadTabView = ({ fields }: HbtPIFileTransferDownloadTabViewProps) => {
  const [columns, setColumns] = useState<ReactElement<DataTableColumnProps<FileTransferFile>>[]>(
    []
  );
  const [isFiltered, setIsFiltered] = useState(false);
  const [inventoryData, setInventoryData] = useState<FileTransferFilesResponse | null>(null);
  const [dataSource, setDataSource] = useState<FileTransferFilesResponse>([]);
  const [openFlyout, setOpenFlyout] = React.useState<boolean>(false);
  const [filterCategory, setFilterCategory] = useState<Category[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [tableError, setTableError] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [showPagination, setShowPagination] = React.useState<boolean>(false);
  const [paginatedInventoryData, setPaginatedInventoryData] =
    useState<FileTransferFilesResponse | null>(null);
  const [sortedColumn, setSortedColumn] = useState<keyof InferDataModel<FileTransferFile> | null>(
    null
  );
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [haveMultiFilesSelected, setHaveMultiFilesSelected] = useState(false);
  const [datatableErrorType, setDatatableErrorType] = useState<ErrorType | null>(null);

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const isUserPortfolioAnalyst =
    (sitecoreContext?.user?.moduleRoleMapping[Module.PiFileShare][0] as UserRole) ===
    UserRole.PortfolioAnalyst;
  const [accordionLenderExpanded, setAccordionLenderExpanded] = useState<boolean>(true);
  const [accordionUploadedByExpanded, setAccordionUploadedByExpanded] = useState<boolean>(false);
  const [downloadToastMessage, setDownloadToastMessage] = useState<string>('');
  const [downloadToastVisible, setDownloadToastVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDeletingData, setIsDeletingData] = useState<boolean>(false);
  const [isSubmittingData, setIsSubmittingData] = useState<boolean>(false);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);

  const handleCheckboxKeyDown = useCheckboxKeyDownHandler();

  const handleItemsPerPageChange = (itemsPerPage: number) => {
    setItemsPerPage(itemsPerPage);
    setCurrentPage(1);
  };

  const handleSort = (column: keyof InferDataModel<FileTransferFile>) => {
    const isAsc = sortedColumn === column && sortAscending;
    setSortAscending(!isAsc);
    setSortedColumn(column);
    setClearSelectedRows(true);

    if (inventoryData) {
      const sortedData = [...inventoryData].sort((a, b) => {
        const aValue = a[column];
        const bValue = b[column];

        if (column === 'size') {
          const parseSize = (size: string) => Number(size?.match(/^\d+/)?.[0]) || 0;

          const sizeA = parseSize(aValue as string);
          const sizeB = parseSize(bValue as string);

          return isAsc ? sizeA - sizeB : sizeB - sizeA;
        }

        if (aValue == null) return isAsc ? 1 : -1;
        if (bValue == null) return isAsc ? -1 : 1;

        if (aValue < bValue) return isAsc ? 1 : -1;
        if (aValue > bValue) return isAsc ? -1 : 1;
        return 0;
      });

      setInventoryData(sortedData);
      setCurrentPage(1);
    }
  };

  const downloadErrorMessage = i18n.t(
    'PI-File-Transfer-Download-Tab-Error-Message',
    'One or more of the selected files could not be downloaded.'
  );
  const downloadSuccessMessage = i18n.t(
    haveMultiFilesSelected
      ? 'PI-File-Transfer-Download-Tab-MultiFileDownload-Toast-Notification-body'
      : 'PI-File-Transfer-Download-Tab-FileDownload-Toast-Notification-body',
    'Your file was downloaded successfully'
  );
  const noSelectedFileError = i18n.t(
    'PI-File-Transfer-Download-Tab-Error-Message-NoFilesSelected',
    'Please select at least one file to download.'
  );
  const noSelectedFileToDeleteError = i18n.t(
    'PI-File-Transfer-Download-Tab-No-Files-Selected-Message',
    'No Files Selected'
  );
  const emptyTableMessage = i18n.t(
    'PI-File-Transfer-Download-Tab-Table-Empty-Data-Message',
    'There are no files to display.'
  );

  const handleAccordionChange =
    (accordion: AccordionPanelType) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      switch (accordion) {
        case 'accordionLender':
          setAccordionLenderExpanded(isExpanded);
          break;
        case 'accordionUploader':
          setAccordionUploadedByExpanded(isExpanded);
          break;
        default:
      }
    };

  const toggleDrawer = (newOpen: boolean) => {
    setOpenFlyout(newOpen);
  };

  const getUniqueCategories = (dataSource: FileTransferFilesResponse) => {
    return _.uniq(dataSource.map((x) => x?.lenderId))
      .sort()
      .map((x) => ({
        id: x,
        label: dataSource.find((y) => y?.lenderId === x)?.lenderName ?? ''
      }));
  };

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const getUniqueUserType = (
    dataSource: FileTransferFilesResponse
  ): { [key: string]: string[] } => {
    const internalUsers: string[] = [];
    const externalUsers: string[] = [];

    dataSource.forEach((report) => {
      const fullName = `${report.uploadedBy}`;
      if (report?.userType) {
        const userType = parseInt(report.userType, 10);
        if (userType === UserType.Internal && !internalUsers.includes(fullName)) {
          internalUsers.push(fullName);
        } else if (userType === UserType.External && !externalUsers.includes(fullName)) {
          externalUsers.push(fullName);
        }
      }
    });

    return {
      'Internal Users': internalUsers,
      'External Users': externalUsers
    };
  };

  const uniqueUsers = getUniqueUserType(dataSource);

  const defaultValues: FilterValue = {
    ...uniqueUsers['Internal Users'].reduce((acc, user) => {
      acc[user] = false;
      return acc;
    }, {} as FilterValue),
    ...uniqueUsers['External Users'].reduce((acc, user) => {
      acc[user] = false;
      return acc;
    }, {} as FilterValue)
  };

  const handleUserSelect = (user: string) => {
    setSelectedUsers((prevUsers) => {
      if (prevUsers.includes(user)) {
        return prevUsers.filter((u) => u !== user);
      }
      return [...prevUsers, user];
    });
  };

  const onSubmit = (values: FilterValue) => {
    filterDT(values);
  };

  const handleDownload = (selectedRows: FileTransferFile[]) => {
    if (selectedRows.length > 0) {
      setTableError('');
      setDatatableErrorType(null);
    } else {
      setDatatableErrorType(ErrorType.NoSelectedCheckbox);
      return setTableError(noSelectedFileError);
    }

    setIsSubmittingData(true);
    const ids = selectedRows.map((row) => row.id);
    downloadInventory({ documentIDs: ids }, authenticationContext, {
      responseType: ids.length > 1 ? 'arraybuffer' : 'blob'
    })
      .then((res: any) => {
        if (res?.status === HttpResponseStatusCodes.OK) {
          const fileName = getHeaderFileName('file-transfer-download-file', res);
          const blob = new Blob([res?.data], { type: 'arraybuffer' });
          const url = window.URL.createObjectURL(blob);

          const downloadElement = document.createElement('a');
          downloadElement.setAttribute('href', url);
          downloadElement.setAttribute('download', fileName);
          downloadElement.click();
          downloadElement.remove();
          window.URL.revokeObjectURL(url);

          setTableError('');
          setDatatableErrorType(null);
          setIsSubmittingData(false);
          setDownloadToastVisible(true);
          setDownloadToastMessage(downloadSuccessMessage);
        }
      })
      .catch((error) => {
        setIsSubmittingData(false);
        setTableError(downloadErrorMessage);
        setDatatableErrorType(ErrorType.CannotDownload);
      });
  };

  const filterDT = useCallback(
    (values: FilterValue) => {
      setClearSelectedRows(true);
      setIsFiltered(true);

      const selectedLenderIds = Object.keys(values).filter((key) => values[key] === true);
      const selectedUploadedByEmails = selectedUsers;

      const isFiltering = selectedLenderIds.length > 0 || selectedUploadedByEmails.length > 0;
      setIsFiltered(isFiltering);

      const output: FileTransferFilesResponse = isFiltering
        ? dataSource.filter((x: { uploadedBy: string; lenderId: string }) => {
            const hasUploadedByFilter = selectedUploadedByEmails.includes(x.uploadedBy);
            const hasLenderIdFilter = selectedLenderIds.includes(x.lenderId);

            if (selectedLenderIds.length > 0 && selectedUploadedByEmails.length > 0) {
              return hasUploadedByFilter && hasLenderIdFilter;
            }

            if (selectedLenderIds.length > 0) {
              return hasLenderIdFilter;
            }
            if (selectedUploadedByEmails.length > 0) {
              return hasUploadedByFilter;
            }

            return false;
          })
        : dataSource;

      setOpenFlyout(false);
      setInventoryData(output);
      setPaginatedInventoryData(output);
      setSelectedFilters([...selectedLenderIds, ...selectedUploadedByEmails]);
      setCurrentPage(1);
    },
    [dataSource, selectedUsers]
  );

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    setClearSelectedRows(true);
  };

  useEffect(() => {
    if (inventoryData && inventoryData.length > 10) {
      const paginatedData = inventoryData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );
      setTotalItems(inventoryData.length);
      setTotalPages(Math.ceil(inventoryData.length / itemsPerPage));
      setShowPagination(true);
      setPaginatedInventoryData(paginatedData);
    } else if (inventoryData) {
      setTotalItems(inventoryData.length);
      setTotalPages(1);
      setShowPagination(true);
      setPaginatedInventoryData(inventoryData);
    }
  }, [inventoryData, currentPage, itemsPerPage, isFiltered]);

  const onDeleteTag = (id: string) => {
    let array: FileTransferFilesResponse = inventoryData || [];
    let tempSelectedFilterId: string[] = selectedFilters.filter((x) => x !== id);

    if (tempSelectedFilterId.length === 0) {
      reset(defaultValues);
      setInventoryData(dataSource);
      setIsFiltered(false);
      setSelectedUsers([]);
    } else {
      setInventoryData(
        array.filter((obj) => tempSelectedFilterId.includes(obj?.lenderId.toString()))
      );
    }
    setSelectedFilters(tempSelectedFilterId);
  };

  const clearFilters = () => {
    reset(defaultValues);
    getInventoryCallBack(authenticationContext);
    setIsFiltered(false);
    setSelectedFilters([]);
    setSelectedUsers([]);
    filterCategory.map((item) => setValue(item?.id, false));
  };

  useEffect(() => {
    setInventoryData(dataSource);
    setFilterCategory(getUniqueCategories(dataSource));
  }, [dataSource.length]);

  useEffect(() => {
    if (paginatedInventoryData?.length && isFiltered && paginatedInventoryData.length > 10) {
      const paginatedData = paginatedInventoryData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );
      setTotalPages(Math.ceil(paginatedInventoryData?.length / itemsPerPage));
      setTotalItems(paginatedInventoryData?.length);
      setShowPagination(true);
      setPaginatedInventoryData(paginatedData);
    }
  }, [paginatedInventoryData?.length, isFiltered]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<FormValues>({ defaultValues });

  // Auth Context
  const authenticationContext: AuthenticationContextType = useAuthenticationContext();

  const getInventoryCallBack = useCallback(
    (authenticationContext: AuthenticationContextType) => {
      setLoading(true);
      getInventory(authenticationContext)
        .then((reports) => {
          if (reports) {
            setDataSource(reports);
            setInventoryData(reports);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error loading reports:', error);
          setLoading(false);
        });
    },
    [authenticationContext]
  );

  const handleSelectedRowData = useCallback(
    (selectedRows: FileTransferFilesResponse) => {
      const ids = selectedRows.map((row) => row.id);
      setSelectedIds(ids);
      if (ids.length > 1) {
        setHaveMultiFilesSelected(true);
      } else {
        setHaveMultiFilesSelected(false);
      }
    },
    [selectedIds, haveMultiFilesSelected]
  );

  const toggleDeleteFileModal = useCallback(
    (event?: MouseOrKeyboardEvent) => {
      if (event) {
        event.preventDefault();
        if (!selectedIds || selectedIds.length === 0) {
          setTableError(noSelectedFileToDeleteError);
          setDatatableErrorType(ErrorType.NoSelectedCheckbox);
        } else {
          setShowDeleteConfirmationModal(true);
        }
      }
    },
    [showDeleteConfirmationModal, selectedIds]
  );

  const onDismissDeleteFileModal = useCallback(
    (event: MouseOrKeyboardEvent) => {
      event.preventDefault();
      setShowDeleteConfirmationModal(false);
    },
    [showDeleteConfirmationModal]
  );

  const confirmDeleteFile = useCallback(
    (event: MouseOrKeyboardEvent) => {
      event.preventDefault();
      setIsDeletingData(true);
      deleteInventory({ documentIDs: selectedIds }, authenticationContext)
        .then((response) => {
          if (response?.status === 200) {
            setShowDeleteConfirmationModal(false);
            setDeletePopup(true);
            setClearSelectedRows(true);
            setDatatableErrorType(null);
            setIsDeletingData(false);
            getInventoryCallBack(authenticationContext);
          }
        })
        .catch((error) => {
          setIsDeletingData(false);
          console.error('Error loading reports:', error);
        });
    },
    [selectedIds, showDeleteConfirmationModal]
  );

  useEffect(() => {
    getInventoryCallBack(authenticationContext);
  }, [authenticationContext]);

  const fileTransferInventoryFilterButtonText = useMemo(() => {
    const filterText = fields?.filterBtn?.value ?? 'Filter';
    return selectedFilters.length === 0
      ? filterText
      : `${fields.filterBtn.value} (${selectedFilters.length})`;
  }, [selectedFilters.length, fields]);

  const memoizedFileTransferInventoryFilterButton = useMemo(() => {
    if (!isUserPortfolioAnalyst) return null;
    return (
      <Stack direction="row" justifyContent="flex-end">
        <Button
          name="Filter"
          size={ButtonSize.SMALL}
          variant={ButtonVariant.SECONDARY}
          onClick={() => toggleDrawer(true)}
          text={{ value: fileTransferInventoryFilterButtonText }}
          ariaText={{ value: fileTransferInventoryFilterButtonText }}
          leadingIcon={() => <HbtIcon size={HbtIconSize.SMALL} type="icon_filter" />}
        />
      </Stack>
    );
  }, [isUserPortfolioAnalyst, fileTransferInventoryFilterButtonText, toggleDrawer]);

  return (
    <div className={styles.mainContent}>
      <div className={styles.layoutContainer}>
        {isUserPortfolioAnalyst && (
          <Stack direction="row" className={styles.subheading}>
            <Text field={fields.fileTransferInventoryTabSubheading} />
          </Stack>
        )}
        <Stack
          direction="column"
          justifyContent="space-between"
          className={styles.piFileTransferFilterTagWrapper}
        >
          {memoizedFileTransferInventoryFilterButton}
          <Stack direction="row" className={styles.tagContainer}>
            {isFiltered && (
              <>
                {getUniqueCategories(dataSource)
                  .filter((x) => selectedFilters.includes(x?.id.toString()))
                  .map((item) => (
                    <Tag
                      key={item?.id}
                      label={item?.label}
                      variant={TagType.INTERACTIVE}
                      onDelete={() => onDeleteTag(item?.id.toString())}
                      statusIndicator={''}
                    />
                  ))}
                {Object.entries(getUniqueUserType(dataSource)) // Get both internal and external user arrays
                  .map(([userType, userArray]) =>
                    userArray
                      .filter((x) => selectedFilters.includes(x))
                      .map((userName) => (
                        <Tag
                          key={userName}
                          label={`${userName}`} // Display the userType (Internal/External) alongside the user name
                          variant={TagType.INTERACTIVE}
                          onDelete={() => onDeleteTag(userName)}
                          statusIndicator={''}
                        />
                      ))
                  )}
              </>
            )}

            {isFiltered && (
              <Button
                name="clearFilterTags"
                size={ButtonSize.SMALL}
                onClick={() => clearFilters()}
                variant={ButtonVariant.TERTIARY}
                text={{ value: fields.clearFilters.value ?? 'Clear Filters' }}
                ariaText={{ value: fields.clearFilters.value ?? 'Clear Filters' }}
                leadingIcon={() => <HbtIcon size={HbtIconSize.SMALL} type="icon_clear_circle" />}
              />
            )}
          </Stack>
        </Stack>

        <div className={styles.fileTransferDataTableContainer}>
          <DataTable<FileTransferFile>
            uniqueKey="id"
            data={paginatedInventoryData ?? []}
            isLoading={loading}
            isSubmitButtonLoading={isSubmittingData}
            isDeleteButtonLoading={isDeletingData}
            sortedColumn={sortedColumn}
            sortAscending={sortAscending}
            stickyFirstColumn={false}
            columnResize={false}
            multipleRowSubmission={true}
            emptyTableMessage={emptyTableMessage}
            submitButtonLabel={i18n.t('PI-File-Transfer-Download-Tab-Download-File', 'Download')}
            deleteItem={isUserPortfolioAnalyst}
            deleteButtonLabel={i18n.t('PI-File-Transfer-Download-Tab-Delete-File', 'Delete')}
            onColumnsReady={setColumns}
            onSort={handleSort}
            onSubmit={handleDownload}
            onRowSelectionChange={handleSelectedRowData}
            onDelete={(e?: MouseOrKeyboardEvent) => toggleDeleteFileModal(e)}
            errorType={datatableErrorType}
            tableErrorMessage={tableError}
            clearSelectionRows={clearSelectedRows}
            onRowSelectionCleared={() => {
              setClearSelectedRows(false);
              setSelectedIds([]);
            }}
          >
            <DataTableColumn
              rowAlignment={ContentAlignment.Start}
              name="lenderName"
              displayName={fields.lenderNameLabel.value ?? 'Approved Lender Name'}
              sortable={true}
              filterable={true}
            >
              {(dataSource) => (
                <MemoizedHbtDataTableCell
                  dataValue={dataSource as string}
                  cellContent={<span>{dataSource}</span>}
                />
              )}
            </DataTableColumn>
            <DataTableColumn
              rowAlignment={ContentAlignment.Start}
              name="fileName"
              displayName={fields.fileNameLabel.value ?? 'File Name'}
              sortable={true}
              filterable={true}
            >
              {(dataSource, rowData: any) => (
                <MemoizedHbtDataTableCell
                  dataValue={dataSource as string}
                  cellContent={
                    <Button
                      type="button"
                      variant={ButtonVariant.TERTIARY}
                      className={styles.tableCellFileDownloadBtn}
                      name={`button-${(rowData as FileTransferFile)?.id}`}
                      text={{ value: (rowData as FileTransferFile)?.fileName }}
                      ariaText={{
                        value: `Download ${(rowData as FileTransferFile)?.fileName}`
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDownload([rowData]);
                      }}
                    />
                  }
                />
              )}
            </DataTableColumn>
            <DataTableColumn
              rowAlignment={ContentAlignment.Start}
              name="uploadedDateTime"
              displayName={fields.uploadedDateTimeLabel.value ?? 'Uploaded Time'}
              sortable={true}
              filterable={true}
            >
              {(dataSource) => (
                <MemoizedHbtDataTableCell
                  dataValue={dataSource as string}
                  cellContent={<span>{moment(dataSource).format('YYYY-MM-DD hh:mm:ss A')}</span>}
                />
              )}
            </DataTableColumn>
            <DataTableColumn
              rowAlignment={ContentAlignment.Start}
              name="uploadedBy"
              displayName={fields.uploadedBy.value ?? 'Uploaded by'}
              sortable={true}
              filterable={true}
            >
              {(dataSource) => (
                <MemoizedHbtDataTableCell
                  dataValue={dataSource as string}
                  cellContent={<span>{dataSource}</span>}
                />
              )}
            </DataTableColumn>
            <DataTableColumn
              rowAlignment={ContentAlignment.Start}
              name="size"
              displayName={fields.sizeLabel.value ?? 'Size'}
              sortable={true}
              filterable={true}
            >
              {(dataSource) => (
                <MemoizedHbtDataTableCell
                  dataValue={dataSource as string}
                  cellContent={<span>{convertFilesize(dataSource)}</span>}
                />
              )}
            </DataTableColumn>
            <DataTableColumn
              rowAlignment={ContentAlignment.Start}
              name="lastDownloadedDateTime"
              displayName={fields.lastDownloadedDateTimeLabel.value ?? 'Last download'}
              sortable={true}
              filterable={true}
            >
              {(dataSource) => (
                <MemoizedHbtDataTableCell
                  dataValue={dataSource as string}
                  cellContent={<span>{moment(dataSource).format('YYYY-MM-DD hh:mm:ss A')}</span>}
                />
              )}
            </DataTableColumn>
            <DataTableColumn
              rowAlignment={ContentAlignment.Start}
              name="lastDownloadedBy"
              displayName={fields.lastDownloadedByLabel.value ?? 'Downloaded by'}
              sortable={true}
              filterable={true}
            >
              {(dataSource) => (
                <MemoizedHbtDataTableCell
                  dataValue={dataSource as string}
                  cellContent={<span>{dataSource}</span>}
                />
              )}
            </DataTableColumn>
          </DataTable>

          {showPagination && (
            <div className={styles.purposeTestDatatablePaginationContainer}>
              <Pagination
                currentPageNumber={currentPage}
                totalPageNumber={totalPages}
                isCondensed={true}
                isOnTablet={false}
                paginationPosition={PaginationPosition.RIGHT}
                onPageChange={handlePageChange}
                numberOfItemsOnPage={itemsPerPage}
                onItemsPerPageChange={handleItemsPerPageChange}
                totalItemNumber={totalItems}
              />
            </div>
          )}
        </div>
        <div>
          <FlyoutContainer
            id="file-transfer-download-inventory-filter-flyout-container"
            isHeaderRequired={true}
            headerText={fields.filterBtn.value}
            open={openFlyout}
            onClose={() => toggleDrawer(false)}
            content={
              <Box className={styles.flyoutContainer} role="presentation">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box className={styles.flyoutBody}>
                    <Box>
                      <Accordion
                        elevation={0}
                        disableGutters
                        className={`${styles.accordion} ${styles.piFileTransferFlyoutAccordion}`}
                        expanded={accordionLenderExpanded}
                        onChange={handleAccordionChange('accordionLender')}
                      >
                        <AccordionSummary
                          aria-controls="panel-content"
                          className={`${styles.accordionHeader} ${styles.piFileTransferFlyoutAccordionSummary}`}
                          expandIcon={
                            <HbtIcon
                              type="icon_chevron_down"
                              className={styles.icon}
                              size={HbtIconSize.MEDIUM}
                            />
                          }
                        >
                          <Stack>
                            <Box className={styles.accordionTitle}>
                              <>
                                <Text
                                  field={
                                    fields.lenderNameLabel ?? {
                                      value: 'Approved Lender Name'
                                    }
                                  }
                                />
                                &nbsp;
                                {selectedFilters.length > 0 &&
                                  selectedUsers.length === 0 &&
                                  `(${selectedFilters.length})`}
                                {selectedFilters.length > 0 &&
                                  selectedUsers.length > 0 &&
                                  `(${selectedFilters.length - selectedUsers.length})`}
                              </>
                            </Box>

                            {selectedFilters.length !== 0 && !accordionLenderExpanded && (
                              <Box className={styles.accordionSubtitle}>
                                {getUniqueCategories(dataSource)
                                  .filter((x) => selectedFilters.includes(x?.id))
                                  .map((x) => x?.label)
                                  .join(', ')}
                              </Box>
                            )}
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: '8px 0px 16px' }}>
                          {filterCategory.map((item) => (
                            <Stack key={item?.id} className={styles.checkboxContainer}>
                              <Controller
                                name={item?.id}
                                control={control}
                                render={({ field }) => (
                                  <Checkbox
                                    {...field}
                                    checked={!!field.value}
                                    defaultChecked={false}
                                    label={{ value: item?.label }}
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    onKeyDown={handleCheckboxKeyDown}
                                  />
                                )}
                              />
                            </Stack>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        elevation={0}
                        disableGutters
                        className={`${styles.accordion} ${styles.piFileTransferFlyoutAccordion}`}
                        onChange={handleAccordionChange('accordionUploader')}
                        expanded={accordionUploadedByExpanded}
                      >
                        <AccordionSummary
                          aria-controls="user-panel-content"
                          className={`${styles.accordionHeader} ${styles.piFileTransferFlyoutAccordionSummary}`}
                          expandIcon={
                            <HbtIcon
                              type="icon_chevron_down"
                              className={styles.icon}
                              size={HbtIconSize.MEDIUM}
                            />
                          }
                        >
                          <Box className={styles.accordionTitle}>
                            <>
                              <Text field={fields.uploadedBy ?? { value: 'Uploaded By' }} />
                              &nbsp;
                              {selectedUsers &&
                                selectedUsers.length > 0 &&
                                ` (${selectedUsers.length})`}
                            </>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: '8px 0px 16px' }}>
                          <Stack spacing={1}>
                            {/* Loop through internal users */}
                            <Box>
                              <strong className={styles.accordionUsersTitle}>
                                <Text
                                  field={
                                    fields.internalUsers ?? {
                                      value: 'Internal Users'
                                    }
                                  }
                                />
                              </strong>
                              {uniqueUsers['Internal Users'].map((item) => (
                                <Stack key={item} className={styles.usersCheckboxContainer}>
                                  <Controller
                                    name={item}
                                    control={control}
                                    render={({ field }) => (
                                      <Checkbox
                                        {...field}
                                        checked={!!field.value}
                                        defaultChecked={false}
                                        label={{ value: item }}
                                        onChange={(e) => {
                                          field.onChange(e.target.checked);
                                          handleUserSelect(item);
                                        }}
                                        onKeyDown={handleCheckboxKeyDown}
                                      />
                                    )}
                                  />
                                </Stack>
                              ))}
                            </Box>

                            {/* Loop through external users */}
                            <Box>
                              <strong className={styles.accordionUsersTitle}>
                                <Text
                                  field={
                                    fields.externalUsers ?? {
                                      value: 'External Users'
                                    }
                                  }
                                />
                              </strong>
                              {uniqueUsers['External Users'].map((item) => (
                                <Stack key={item} className={styles.usersCheckboxContainer}>
                                  <Controller
                                    name={item}
                                    control={control}
                                    render={({ field }) => (
                                      <Checkbox
                                        {...field}
                                        checked={!!field.value}
                                        defaultChecked={false}
                                        label={{ value: item }}
                                        onChange={(e) => {
                                          field.onChange(e.target.checked);
                                          handleUserSelect(item);
                                        }}
                                        onKeyDown={handleCheckboxKeyDown}
                                      />
                                    )}
                                  />
                                </Stack>
                              ))}
                            </Box>
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Box>

                  <Box className={styles.flyoutFooter}>
                    <Stack direction="row" spacing={3}>
                      <Button
                        type="button"
                        name="ClearFilters"
                        onClick={() => clearFilters()}
                        className={styles.fullWidth}
                        variant={ButtonVariant.SECONDARY}
                        text={{
                          value: fields.clearFilters.value ?? 'Clear Filters'
                        }}
                        ariaText={{
                          value: fields.clearFilters.value ?? 'Clear Filters'
                        }}
                      />

                      <Button
                        type="submit"
                        name="ApplyFilters"
                        className={styles.fullWidth}
                        variant={ButtonVariant.PRIMARY}
                        text={{
                          value: fields.applyFilters.value ?? 'Apply Filters'
                        }}
                        ariaText={{
                          value: fields.applyFilters.value ?? 'Apply Filters'
                        }}
                      />
                    </Stack>
                  </Box>
                </form>
              </Box>
            }
          />
        </div>
      </div>
      <ToastNotification
        actionable
        icon="icon_check_circle"
        open={downloadToastVisible || deletePopup}
        header={
          downloadToastVisible
            ? {
                value: i18n.t(
                  'PI-File-Transfer-Download-Tab-FileDownload-Toast-Notification-Title',
                  'Download Complete'
                )
              }
            : {
                value: i18n.t(
                  haveMultiFilesSelected
                    ? 'PI-File-Transfer-Download-Tab-MultiFileDeleted-Toast-Notification-Title'
                    : 'PI-File-Transfer-Download-Tab-FileDeleted-Toast-Notification-Title',
                  'File Deleted'
                )
              }
        }
        variant={ToastNotificationVariant.INFO}
        onClose={() => {
          downloadToastVisible ? setDownloadToastVisible(false) : setDeletePopup(false);
        }}
        subHeader={{
          value: downloadToastVisible
            ? downloadToastMessage
            : deletePopup
            ? i18n.t(
                haveMultiFilesSelected
                  ? 'PI-File-Transfer-Download-Tab-MultiFileDeleted-Toast-Notification-body'
                  : 'PI-File-Transfer-Download-Tab-FileDeleted-Toast-Notification-body',
                'The selected file has been successfully deleted.'
              )
            : ''
        }}
      />
      <Modal
        id="delete-file-transfer-files-confirmation-modal"
        isActive={showDeleteConfirmationModal}
        title={{
          value: i18n.t(
            haveMultiFilesSelected
              ? 'PI-File-Transfer-Download-Tab-Delete-MultiFile-Confirmation-Modal-Title'
              : 'PI-File-Transfer-Download-Tab-Delete-File-Confirmation-Modal-Title',
            'Confirm File Deletion'
          )
        }}
        onDismissalCallback={() => setShowDeleteConfirmationModal(false)}
        bodyContent={
          <span>
            <Text
              field={{
                value: i18n.t(
                  haveMultiFilesSelected
                    ? 'PI-File-Transfer-Download-Tab-Delete-MultiFile-Confirmation-body'
                    : 'PI-File-Transfer-Download-Tab-Delete-File-Confirmation-Modal-body',
                  'Do you want to delete the selected file? This action cannot be undone.'
                )
              }}
            />
          </span>
        }
        modalButtons={{
          primaryButton: {
            name: i18n.t(
              haveMultiFilesSelected
                ? 'PI-File-Transfer-Download-Tab-Delete-MultiFile-Confirmation-Modal-DeleteBtn'
                : 'PI-File-Transfer-Download-Tab-Delete-File-Confirmation-Modal-DeleteBtn',
              'Delete file'
            ),
            text: {
              value: i18n.t(
                haveMultiFilesSelected
                  ? 'PI-File-Transfer-Download-Tab-Delete-MultiFile-Confirmation-Modal-DeleteBtn'
                  : 'PI-File-Transfer-Download-Tab-Delete-File-Confirmation-Modal-DeleteBtn',
                'Delete file'
              )
            } as FieldValue,
            ariaText: {
              value: i18n.t(
                haveMultiFilesSelected
                  ? 'PI-File-Transfer-Download-Tab-Delete-MultiFile-Confirmation-Modal-DeleteBtn'
                  : 'PI-File-Transfer-Download-Tab-Delete-File-Confirmation-Modal-DeleteBtn',
                'Delete file'
              )
            } as FieldValue,
            onClick: confirmDeleteFile,
            onKeyDown: confirmDeleteFile
          },
          secondaryButton: {
            name: i18n.t(
              'PI-File-Transfer-Download-Tab-Delete-File-Confirmation-Modal-CancelBtn',
              'Cancel'
            ),
            text: {
              value: i18n.t(
                'PI-File-Transfer-Download-Tab-Delete-File-Confirmation-Modal-CancelBtn',
                'Cancel'
              )
            } as FieldValue,
            ariaText: {
              value: i18n.t(
                'PI-File-Transfer-Download-Tab-Delete-File-Confirmation-Modal-CancelBtn',
                'Cancel'
              )
            } as FieldValue,
            onClick: onDismissDeleteFileModal,
            onKeyDown: onDismissDeleteFileModal
          }
        }}
        shouldCloseOnBackgroundClick={true}
      />
    </div>
  );
};

export default memo(HbtPIFileTransferDownloadTabView);
