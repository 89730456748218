import i18n from 'i18next';
import DefaultSubmissionFormProps from 'Feature/DefaultSubmissionForm/models/DefaultSubmissionFormProps';

export const getLiabilitiesDetailsProps = (props: DefaultSubmissionFormProps) => {
  const liabilitiesDetailsProps = {
    testId: 'liabilitiesTest',
    title: {
      field: {
        value: i18n.t('DefaultSubmission-Card-LiabilitiesHeading')
      }
    },
    accordionLineColor: 'grey',
    linePosition: 'vertical',
    guarantorLiabilities: {
      field: {
        value: i18n.t('DefaultSubmission-Card-GuarantorLiabilities')
      }
    },
    firstMortgage: {
      field: {
        value: i18n.t('DefaultSubmission-Card-FirstMortgage')
      }
    },
    secondMortgage: {
      field: {
        value: i18n.t('DefaultSubmission-Card-SecondMortgage')
      }
    },
    personalLoan: {
      field: {
        value: i18n.t('DefaultSubmission-Card-PersonalLoan')
      }
    },
    lineOfCredit: {
      field: {
        value: i18n.t('DefaultSubmission-Card-LineOfCredit')
      }
    },
    creditCard: {
      field: {
        value: i18n.t('DefaultSubmission-Card-CreditCard')
      }
    },
    totalLiabilities: {
      field: {
        value: i18n.t('DefaultSubmission-Card-TotalLiabilities')
      }
    },
    netAssets: {
      field: {
        value: i18n.t('DefaultSubmission-Card-NetAssets')
      }
    },
    AddGuarantorText: {
      field: {
        value: i18n.t('DefaultSubmission-Card-AddGuarantorLiabilities')
      }
    },
    deleteIcon: props.fields.deleteIcon,
    inputFields: {
      associatedBalance: {
        field: {
          value: i18n.t('DefaultSubmission-Card-AssociatedBalance')
        }
      },
      associatedMinimumMonthly: {
        field: {
          value: i18n.t('DefaultSubmission-Card-AssociatedMinimumMonthlyPayment')
        }
      }
    }
  };

  return liabilitiesDetailsProps;
};
