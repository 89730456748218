export const calculateNewScrollPosition = (
  currentScrollPosition: number,
  targetPosition: number,
  scrollWindowHeight: number
) => {
  if (targetPosition >= currentScrollPosition + scrollWindowHeight) {
    return targetPosition - scrollWindowHeight + 45;
  }

  if (targetPosition <= currentScrollPosition) {
    return targetPosition - 25;
  }

  return currentScrollPosition;
};

export const getProgressBarNewScrollPosition = (
  scrollContainer: HTMLDivElement,
  activeItem: HTMLElement | null
): number => {
  if (activeItem) {
    const targetPosition = activeItem.offsetTop;
    const scrollContainerHeight = scrollContainer.offsetHeight;
    const currentPosition = scrollContainer.scrollTop;

    return calculateNewScrollPosition(currentPosition, targetPosition, scrollContainerHeight);
  }
  return 0;
};
