import React from 'react';

import FormCard from 'Components/Common/FormCard';
import { ButtonType } from 'Feature/CommonComponents/UserControls';
import { GlossaryNames } from 'Feature/PageComponents/components/GlossaryComponent/types';
import ClaimsIndicatorDetailsFields from '../../IndicatorDetailsFields/index';
import ClaimsIndicatorDetailsSubmissionProps from './types';
import { useHBTFormContext } from '../../HBTFormContext';

const ClaimsIndicatorDetailsSubmission = ({ fields }: ClaimsIndicatorDetailsSubmissionProps) => {
  const { isFieldDisabled } = useHBTFormContext();
  return (
    <FormCard
      title={fields.cardTitle}
      headingLevel={2}
      sectionId="claims-indicator-details-body"
      fieldToValidate="indicator"
      toolTipButton={{
        id: 'indicatorGlossaryTooltip',
        name: 'indicatorGlossaryTooltip',
        ariaText: fields.cardGlossaryAriaText?.value ?? '',
        onClick: () => {
          fields?.openGlossary?.(GlossaryNames.IndicatorDetails);
        },
        buttonType: ButtonType.TEXT
      }}
    >
      <ClaimsIndicatorDetailsFields fields={fields} />
    </FormCard>
  );
};

export default ClaimsIndicatorDetailsSubmission;
