import { useFeature } from 'flagged';
import i18n from 'i18next';
import React, { useState, useEffect } from 'react';

import { UserRole, Module, LanguageShort } from '@hobt/constants';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useDefaultFormActionsContext } from 'Feature/DefaultsInventory/components/DefaultFormContext';

import { roundAccurately, setFixedDecimalPercent } from '../../CardReuseableFunctions';
import FormInputCurrency from '../../FormInputCurrency';
import FormInputTextInput from '../../FormInputTextInput';
import { AccordionContainer, LinedCard } from '../../../../CommonComponents/AccordionComponents';

import { GdsTdsCalculationsProps } from './GdsTdsCalculations.types';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

export const GdsTdsCalculations: React.FC<GdsTdsCalculationsProps> = ({
  register,
  errors,
  setValueHandler,
  control,
  watch,
  getValues,
  reset,
  ...props
}) => {
  const commonCardProps = {
    register,
    errors,
    setValueHandler,
    control,
    watch,
    getValues
  };

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);
  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;

  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  const firstMortgage =
    (watch && watch(`detail.firstLoan.requiredMonthlyPaymentPrincipalInterest`)) || 0;
  const secondMortgage =
    (watch && watch(`detail.secondLoan.requiredMonthlyPaymentPrincipalInterest`)) || 0;
  const housingExpense =
    (watch && watch(`grossDebtServiceTotalDebtService.submittedHousingExpense`)) || {};
  const propertyTaxes = housingExpense.propertyTax || 0;
  const heatingCosts = housingExpense.heatingCost || 0;
  const condoFee = housingExpense.fiftyPercentCondoFee || 0;
  const otherMonthlyExpense =
    (watch && watch(`grossDebtServiceTotalDebtService.submittedOtherMonthlyExpense`)) || {};
  const personalLoan = otherMonthlyExpense.personalLoan || 0;
  const lineOfCredit = otherMonthlyExpense.lineOfCredit || 0;
  const creditCardAmount = otherMonthlyExpense.creditCard || 0;
  const other = otherMonthlyExpense.other || 0;

  const defaultFormActions = useDefaultFormActionsContext();

  const [GDSRatio, setGDSRatio] = useState(0);
  const [TDSRatio, setTDSRatio] = useState(0);

  const gdsTdsDecimalPlaces = 1;

  const calculateGdsTds = () => {
    const gdsTotal = firstMortgage + secondMortgage + propertyTaxes + heatingCosts + condoFee;
    const tdsTotal = gdsTotal + personalLoan + lineOfCredit + creditCardAmount + other;
    if (props.borrowerTotalMonthlyGrossIncome && props.borrowerTotalMonthlyGrossIncome > 0) {
      // @ts-ignore
      const GDS = roundAccurately(
        (gdsTotal / props.borrowerTotalMonthlyGrossIncome) * 1e2,
        gdsTdsDecimalPlaces
      );
      const TDS = roundAccurately(
        (tdsTotal / props.borrowerTotalMonthlyGrossIncome) * 1e2,
        gdsTdsDecimalPlaces
      );
      setGDSRatio(GDS);
      setTDSRatio(TDS);
    }

    props.setTotalMonthlyHouseholdHousingExpenses &&
      props.setTotalMonthlyHouseholdHousingExpenses(gdsTotal);
    props.setTotalMonthlyHouseholdUnsecuredDebt &&
      props.setTotalMonthlyHouseholdUnsecuredDebt(tdsTotal);
  };

  useEffect(() => {
    calculateGdsTds();
  }, [
    firstMortgage,
    secondMortgage,
    propertyTaxes,
    heatingCosts,
    condoFee,
    personalLoan,
    lineOfCredit,
    creditCardAmount,
    other,
    props.borrowerTotalMonthlyGrossIncome
  ]);

  useEffect(() => {
    if (defaultFormActions.isCardComplete('GDS&TDS')) {
      if (
        !(
          !!propertyTaxes &&
          !!heatingCosts &&
          !!personalLoan &&
          !!lineOfCredit &&
          !!creditCardAmount
        )
      ) {
        defaultFormActions?.removeCompleteCard('GDS&TDS');
      }
    } else if (
      !!propertyTaxes &&
      !!heatingCosts &&
      !!personalLoan &&
      !!lineOfCredit &&
      !!creditCardAmount
    ) {
      defaultFormActions?.addCompleteCard('GDS&TDS');
    }
  }, [propertyTaxes, heatingCosts, personalLoan, lineOfCredit, creditCardAmount]);

  return (
    <LinedCard
      id={props.id}
      testId={props.testId}
      linePosition={props.linePosition}
      lineColor={props.accordionLineColor}
    >
      <AccordionContainer accordionId={`${props.id}Accordion`} title={props.title.field}>
        <h3 className="card__body-heading">
          <Text field={props.gdsSubHeading1.field} />
        </h3>

        <div className="row card__body-row">
          <div
            className={`form__element form__element--2-column col-6 ${
              i18n.language === LanguageShort.French ? 'pullDown' : ''
            }`.trim()}
          >
            <FormInputCurrency
              id={`${props.id}FirstMortgagePayment`}
              title={props.inputFields.firstMortgagePayment.field}
              isDisabled={isReadOnlyUser || true}
              value={firstMortgage || null}
              cardName={props.title.field.value}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              id={`${props.id}SecondMortgagePayment`}
              title={props.inputFields.secondMortgagePayment.field}
              isDisabled={isReadOnlyUser || true}
              value={secondMortgage || null}
              cardName={props.title.field.value}
            />
          </div>
        </div>
        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              {...commonCardProps}
              id={`${props.id}PropertyTaxes`}
              name="grossDebtServiceTotalDebtService.submittedHousingExpense.propertyTax"
              title={props.inputFields.propertyTaxes.field}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              {...commonCardProps}
              id={`${props.id}HeatingCosts`}
              name="grossDebtServiceTotalDebtService.submittedHousingExpense.heatingCost"
              title={props.inputFields.heatingCosts.field}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              {...commonCardProps}
              id={`${props.id}CondoFee`}
              name="grossDebtServiceTotalDebtService.submittedHousingExpense.fiftyPercentCondoFee"
              title={props.inputFields.percentageOfCondoFee.field}
              cardName={props.title.field.value}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              id={`${props.id}TotalMonthly1`}
              title={props.inputFields.totalMonthlyHousingExpenses.field}
              isDisabled={isReadOnlyUser || true}
              value={props.totalMonthlyHouseholdHousingExpenses || ''}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputTextInput
              id={`${props.id}GDSRatio`}
              title={props.inputFields.gDSRatio.field}
              isDisabled={isReadOnlyUser || true}
              value={setFixedDecimalPercent(GDSRatio, gdsTdsDecimalPlaces)}
              type="text"
            />
          </div>
        </div>
        <hr className="card__divider" />
        <h3 className="card__body-heading">
          <Text field={props.gdsSubHeading2.field} />
        </h3>
        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              {...commonCardProps}
              id={`${props.id}PersonalLoans`}
              name="grossDebtServiceTotalDebtService.submittedOtherMonthlyExpense.personalLoan"
              title={props.inputFields.personalLoans.field}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              {...commonCardProps}
              id={`${props.id}LineOfCredit`}
              name="grossDebtServiceTotalDebtService.submittedOtherMonthlyExpense.lineOfCredit"
              title={props.inputFields.linesOfCredit.field}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              {...commonCardProps}
              id={`${props.id}CreditCard`}
              name="grossDebtServiceTotalDebtService.submittedOtherMonthlyExpense.creditCard"
              title={props.inputFields.creditCards.field}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              {...commonCardProps}
              id={`${props.id}Other`}
              name="grossDebtServiceTotalDebtService.submittedOtherMonthlyExpense.other"
              title={props.inputFields.gDSOther.field}
              cardName={props.title.field.value}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              id={`${props.id}TotalMonthly2`}
              title={props.inputFields.TotalMonthlyExpenses.field}
              isDisabled={isReadOnlyUser || true}
              value={props.totalMonthlyHouseholdUnsecuredDebt || ''}
            />
          </div>
          <div className="form__element form__element--2-column col-6 pullDown">
            <FormInputTextInput
              id={`${props.id}TDSRatio`}
              title={props.inputFields.tDSRatio.field}
              isDisabled={isReadOnlyUser || true}
              value={setFixedDecimalPercent(TDSRatio, gdsTdsDecimalPlaces)}
              type="text"
            />
          </div>
        </div>
      </AccordionContainer>
    </LinedCard>
  );
};
