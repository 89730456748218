import React, { FC } from 'react';
import { ListSubheader } from '@mui/material';
import styles from '../styles.module.scss';
import { DropdownGroupItemProps } from '../types';

export const DropdownGroupItem: React.FC<DropdownGroupItemProps> = ({
  index,
  label
}: DropdownGroupItemProps) => {
  return (
    <ListSubheader key={`group-${label}-${index}`} className={styles.menuListSubheader}>
      {label}
    </ListSubheader>
  );
};
