import React, { useEffect } from 'react';
import { ModuleMapping, UserRole } from '@hobt/constants';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { telephoneFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormNumber from 'Components/Inputs/FormNumber';
import FormText, { Formatted as FormattedInput } from 'Components/Inputs/FormText';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { useFormContext } from 'react-hook-form';
import styles from './styles.module.scss';
import HbtInternalPIUserDetailsProps from './types';

const HbtInternalPIUserDetails: React.FC<HbtInternalPIUserDetailsProps> = ({
  fields,
  isModify = false
}: HbtInternalPIUserDetailsProps) => {
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.admin,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  const { setValue, watch } = useFormContext();
  const phoneNumber = watch('contact.phoneNumber');
  const preferredLanguage = watch('contact.preferredLanguageCode');

  useEffect(() => {
    if (phoneNumber == null) {
      setValue('contact.phoneNumber', undefined);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (preferredLanguage === undefined) {
      setValue('contact.preferredLanguageCode', null);
    }
  }, [preferredLanguage]);

  return (
    <div>
      <h2 className="card-title">
        <Text field={fields.sectionTitle} />
      </h2>
      <p>
        <Text field={fields.sectionSubtitle} />
      </p>

      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormText
            className={styles.internalUserDetailsFormField}
            name="contact.firstName"
            label={fields.firstName}
            isReadOnly={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormText
            className={styles.internalUserDetailsFormField}
            name="contact.lastName"
            label={fields.lastName}
            isReadOnly={isReadOnlyUser}
          />
        </div>
      </div>

      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormattedInput
            className={styles.internalUserDetailsFormField}
            name="emailID"
            label={fields.email}
            isReadOnly={isModify || isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormDropdown
            className={styles.internalUserDetailsFormField}
            isDisabled={isReadOnlyUser}
            name="contact.preferredLanguageCode"
            label={fields.preferredLanguage}
            options={fields.languagePreferUser?.fields?.listItems}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormNumber
            className={styles.internalUserDetailsFormField}
            isReadOnly={isReadOnlyUser}
            name="contact.phoneNumber"
            label={fields?.phoneNumber}
            formatProps={telephoneFormat}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormText
            className={styles.internalUserDetailsFormField}
            isReadOnly={isReadOnlyUser}
            name="contact.extensionPhoneNumber"
            label={fields.extension}
          />
        </div>
      </div>
      <hr />
    </div>
  );
};

export default HbtInternalPIUserDetails;
