import { ImageField } from '@sitecore-jss/sitecore-jss-react/types/components/Image';
import { SitecoreDropdownList } from 'Constants/Types/SitecoreListItem';

export interface ClaimsLenderDetailsProps {
  fields: ClaimsLenderDetailsPropsFields;
  setShowSecondaryOfficer: (value: boolean) => void;
}
export interface ClaimsLenderDetailsPropsFields {
  name: FieldValue;
  cardTitle: FieldValue;
  lenderNote: FieldValue;
  lenderName: FieldValue;
  lenderCode: FieldValue;
  lenderReferenceNumber: FieldValue;
  claimPayeeTransitId: FieldValue;
  claimPayeeTypeLabel: FieldValue;
  claimPayeeName: FieldValue;
  languagePreferClaimLabel: FieldValue;
  languagePreferClaim: SitecoreDropdownList;
  lenderAddress: FieldValue;
  lenderAddress2: FieldValue;
  townCity: FieldValue;
  provinceLabel: FieldValue;
  postalCode: FieldValue;
  secondaryContactInfomation: FieldValue;
  firstName: FieldValue;
  lastName: FieldValue;
  phoneNumber: FieldValue;
  extension: FieldValue;
  faxNumber: FieldValue;
  emailAddress: FieldValue;
  addAuthorizeOffice: FieldValue;
  province: SitecoreDropdownList;
  claimPayeeType: SitecoreDropdownList;
  deleteIcon: ImageField;
  contactInfo: FieldValue;
  position: FieldValue;
  dataFieldName: FieldValue;
  openGlossary: Function;
  cardGlossaryAriaText: FieldValue;
}

export interface AuthorizedPerson {
  id?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  faxNumber: string;
  email: string;
}
export interface SecondaryAuthorizedFormProps {
  id?: string;
  fields: SecondaryAuthorizedFormPropsFields;
  showSecondaryOfficer: boolean;
  contactInfo: FieldValue;
  item: any;
  remove: (index: number) => void;
  index: number;
}
export interface SecondaryAuthorizedFormPropsFields {
  position: FieldValue;
  firstName: FieldValue;
  lastName: FieldValue;
  phoneNumber: FieldValue;
  extension: FieldValue;
  faxNumber: FieldValue;
  emailAddress: FieldValue;
  addAuthorizeOffice: FieldValue;
  secondaryContactInfomation: FieldValue;
  deleteIcon: ImageField;
}

export enum LanguageSwitchTypeCode {
  English = 'en',
  French = 'fr'
}

export interface ClaimPayee {
  approvedLenderEnglishName: string;
  approvedLenderFrenchName: string;
  payeeID: number;
  transitNumber: string;
}
