import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import _ from 'lodash-es';

import NumberFormat from 'react-number-format';
import { Controller } from 'react-hook-form';
import i18n from 'i18next';
import { CurrencyInput } from './FormInputCurrency.types';
import GetErrorMessage from '../ErrorMessage';

const languageFormats: any = {
  fr: {
    thousandSeparator: ' ',
    decimalSeparator: ',',
    suffix: '$'
  },
  en: {
    thousandSeparator: ',',
    decimalSeparator: '.',
    prefix: '$'
  }
};

const FormInputCurrency: React.FC<CurrencyInput> = ({ ...props }) => {
  const errors: any = props.errors && _.get(props.errors, `${props.name}`);

  return (
    <div className="form__element form__element--2-column">
      <label
        htmlFor={`${props.id}CurrencyInput`}
        id={`${props.id}Label`}
        className={(errors && 'form-error__label ') + (props.hideTitle ? ' sr-only' : '')}
      >
        <Text field={props.title} />
      </label>
      {props.description && <small>{props.description.value}</small>}
      {props.control ? (
        <Controller
          control={props.control}
          name={props.name || props.id}
          defaultValue={(props.getValues && props.getValues(props.name)) || ''}
          render={({ field: { onBlur, value } }) => (
            <NumberFormat
              id={`${props.id}CurrencyInput`}
              aria-labelledby={`${props.id}Label`}
              name={`${props.name}_formatted`}
              value={value}
              data-type="currency"
              placeholder={(!props.disablePlaceholder && '$') || ''}
              disabled={props.isDisabled || false}
              {...languageFormats[i18n.language]}
              prefix={(!props.disablePrefix && languageFormats[i18n.language].prefix) || ''}
              suffix={(!props.disableSuffix && languageFormats[i18n.language].suffix) || ''}
              decimalScale={props.decimalPlace || 2}
              displayType="input"
              allowLeadingZeros={false}
              onChange={props.handleChange}
              {...props.register}
              onValueChange={(values) =>
                props.name &&
                props.setValueHandler &&
                props.setValueHandler(props.name, values.floatValue, {
                  shouldDirty: true
                })
              }
              onBlur={onBlur}
              className={errors && 'form-error__field'}
              maxLength={20} // limit user to 15 numbers, the 5 extra number is to account for the placeholder
              {...(props.isRequired && { required: props.isRequired })}
              autoFocus={props.autoFocus || false}
              aria-describedby={`${props.id}ErrorDesc`}
              autoComplete={props.autoComplete || 'off'}
            />
          )}
        />
      ) : (
        // use without props.control only for summation fields, where validation isn't required
        <NumberFormat
          id={`${props.id}CurrencyInput`}
          aria-labelledby={`${props.id}Label`}
          name={`${props.name}_formatted`}
          data-type="currency"
          placeholder={(!props.disablePlaceholder && '$') || ''}
          disabled={props.isDisabled}
          {...languageFormats[i18n.language]}
          decimalScale={props.decimalPlace || 2}
          displayType="input"
          allowLeadingZeros={false}
          value={props.value}
          defaultValue=""
          {...(props.isRequired && { required: props.isRequired })}
          autoFocus={props.autoFocus || false}
          aria-describedby={`${props.id}ErrorDesc`}
        />
      )}
      {!props.hideTitle && (
        <div className="form__fieldNotificationHolder">
          {errors && (
            <span
              className={`form-error__sub-text`}
              aria-live="assertive"
              id={`${props.id}ErrorDesc`}
            >
              {GetErrorMessage(
                errors.type as string,
                errors.message as string,
                undefined,
                props?.title?.value as string
              )}
              <input
                type="hidden"
                className="thisFieldHasErrors"
                value={props.cardName && props.cardName}
              />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default FormInputCurrency;
