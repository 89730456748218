import React, { FC } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import get from 'Constants/Utils/GetPathInObject';
import FormInputWrapper, {
  Props as FormInputWrapperProps
} from 'Components/Inputs/FormInputWrapper';
import styles from './styles.module.scss';

type Props = {
  label: FormInputWrapperProps['label'];
  className: string;
  name: string;
  placeholder?: string;
  watch?: FormInputWrapperProps['watch'];
  attributeProps?: Record<string, any>;
  isReadOnly?: boolean;
};

const FormTextAreaNumber: FC<Props> = ({
  name,
  label,
  watch,
  className,
  placeholder,
  attributeProps,
  isReadOnly = false
}) => {
  const {
    formState: { errors },
    setValue,
    getValues
  } = useFormContext();
  const nameValue: string = getValues(name);

  const {
    field: { onChange, ref: controllerRef, ...field }
  } = useController({
    name,
    defaultValue: nameValue
  });

  const message = get(errors, `${name}.message`);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value.replace(/[^0-9]/g, '');
    let formattedValue = '';
    for (let i = 0; i < value.length; i++) {
      if (i > 0 && i % 5 === 0) {
        formattedValue += ',';
      }
      formattedValue += value[i];
    }
    const updatedValue = formattedValue.split(',');
    setValue(name, updatedValue);
    onChange(updatedValue);
  };

  return (
    <FormInputWrapper
      name={name}
      label={label}
      className={className}
      watch={watch}
      {...(isReadOnly === true && { disabled: isReadOnly })}
    >
      <textarea
        aria-label={name}
        {...field}
        className={styles['form-text']}
        aria-errormessage={`${name}_error_message`}
        aria-invalid={Boolean(message)}
        {...(isReadOnly === true && { readOnly: isReadOnly })}
        {...attributeProps}
        placeholder={placeholder}
        onChange={handleInputChange}
      />
    </FormInputWrapper>
  );
};

export default FormTextAreaNumber;
