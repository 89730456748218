import React from 'react';
import { ImageField } from '@sitecore-jss/sitecore-jss-react/types/components/Image';

import { SitecoreDropdownList } from 'Constants/Types/SitecoreListItem';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { AddCommentFormPropsFields } from './AddComment/types';
import { AddAttachmentsFormPropsFields } from './AddAttachments/types';
import { AddInternalNoteFormPropsFields } from './AddInternalNote/types';
import { DocumentRequestFormPropsFields } from './DocumentRequest/types';

export default interface AttachmentsNotesCommentsDetailsProps {
  fields: AttachmentsNotesCommentsDetailsPropsFields;
  onFlyout: Function;
  setFilteredValue?: (e: any) => void;
  isReadOnlyAccessUser?: boolean;
}

export interface AttachmentsNotesCommentsDetailsPropsFields {
  tagList: FieldList;
  heading: FieldValue;
  filterList: FieldList;
  typeLabel: FieldValue;
  addNoteBtn: FieldValue;
  documentIcon: ImageField;
  approvedText: FieldValue;
  sendCommentBtn: FieldValue;
  addAttachmentBtn: FieldValue;
  approvalDecision: FieldValue;
  documentRequestBtn: FieldValue;
  addCommentFlyout: {
    fields: AddCommentFormPropsFields;
  };
  addAttachmentFlyout: {
    fields: AddAttachmentsFormPropsFields;
  };
  addInternalNoteFlyout: {
    fields: AddInternalNoteFormPropsFields;
  };
  documentRequestFlyout: {
    fields: DocumentRequestFormPropsFields;
  };
  commentTypes: SitecoreDropdownList;
  adjudicationNoteCategory: SitecoreDropdownList;
  adjudicationDecisionCodes: SitecoreDropdownList;
  categoryList: SitecoreDropdownList;
  categoryLabel: FieldValue;
  deleteCorrespondenceLabel: FieldValue;
  confirmDeleteModalTitle: FieldValue;
  confirmDeleteModalContent: FieldValue;
  confirmDeleteModalButton: FieldValue;
  cancelDeleteModalButton: FieldValue;
  documentRequestCodeCategoryList: SitecoreDropdownList;
}

interface SidedrawerItem<FieldsType> {
  component: React.FC<any>;
  fields: FieldsType;
}

export enum DrawerType {
  AddCommentForm = 'AddCommentForm',
  AddAttachmentsForm = 'AddAttachmentsForm',
  AddInternalNoteForm = 'AddInternalNoteForm',
  DocumentRequestForm = 'DocumentRequestForm'
}

export interface SidedrawerData {
  [DrawerType.AddAttachmentsForm]: SidedrawerItem<AddAttachmentsFormPropsFields>;
  [DrawerType.AddCommentForm]: SidedrawerItem<AddCommentFormPropsFields>;
  [DrawerType.AddInternalNoteForm]?: SidedrawerItem<AddInternalNoteFormPropsFields>;
  [DrawerType.DocumentRequestForm]?: SidedrawerItem<DocumentRequestFormPropsFields>;
}

export enum FilterType {
  Default = 0,
  Attachments = 7
}

export enum CorrespondenceSubmitResponse {
  Error,
  Success,
  BadRequest,
  ServerError,
  NotAuthorized,
  FileUploadError
}

type ErrorEntity = {
  errorMessage?: string;
  errorCode?: any;
};

type DataObject = {
  statusCode: number;
  status?: string;
  data: any | any[];
  error: ErrorEntity;
};

type Response = {
  status?: number;
  data: DataObject;
};

export type ErrorObject = {
  response?: Response;
};

// Reducer
export enum ActionType {
  SHOW_SIDE_DRAWER,
  HIDE_SIDE_DRAWER,
  DISMISS_DELETE_CONFIRMATION,
  SHOW_DELETE_CONFIRMATION,
  SHOW_TOAST_SUCCESS,
  SHOW_TOAST_ERROR,
  SHOW_TOAST_API_ERROR,
  DISMISS_TOAST,
  SET_FILTER,
  CLEAR_FILTER,
  SHOW_TOAST_Delete_SUCCESS
}

export type Action = {
  action: ActionType;
  payload?: any;
};

export type AttachmentsNotesCommentsDetailsState<T> = {
  filteredValue: FilterType;
  showSideDrawer: boolean;
  showModal: boolean;
  correspondenceSelectedToDelete?: {
    documentId: string;
    correspondenceId: string;
    cmhcLoanAccountNumber: number;
    decisionTimestamp: string;
    userID: string;
  };
  showToast: boolean;
  sideDrawerComponent?: React.FC<any>;
  sideDrawerComponentFields?:
    | AddAttachmentsFormPropsFields
    | AddCommentFormPropsFields
    | AddInternalNoteFormPropsFields
    | DocumentRequestFormPropsFields;
  toastTitle?: string;
  toastContent?: string;
  toastType: ApplicationStates;
};
