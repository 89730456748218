import React from 'react';

import { useHBTFormActionsContext } from 'Feature/Claims/components/HBTFormContext';

import { ProgressBarItemStatusIndicatorProps } from './types';
import styles from './styles.module.scss';

const ProgressBarItemStatusIndicator: React.FC<ProgressBarItemStatusIndicatorProps> = ({
  current,
  fieldToValidate
}: ProgressBarItemStatusIndicatorProps) => {
  const hbtFormActions = useHBTFormActionsContext();
  return (
    <span
      className={
        hbtFormActions?.isCardComplete(fieldToValidate) && !current
          ? `${styles.progressbarItemStatusIndicator} ${styles.progressbarItemStatusIndicatorOuterCompleted}`
          : styles.progressbarItemStatusIndicator
      }
    >
      {hbtFormActions?.isCardComplete(fieldToValidate) && !current && (
        <span className={styles.progressbarItemStatusIndicatorCompleted} />
      )}
      {current && <span className={styles.progressbarItemStatusIndicatorCurrent} />}
    </span>
  );
};

export { ProgressBarItemStatusIndicator };
export type { ProgressBarItemStatusIndicatorProps };
