import * as React from 'react';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import {
  isEditorActive,
  withSitecoreContext,
  LayoutServiceData
} from '@sitecore-jss/sitecore-jss-react';
// @ts-ignore
import { AuthenticationProvider } from 'Foundation/Authentication';
import { UserTypeAuthorization } from 'Foundation/Authorization';
import { HydrateSitecoreContext } from 'Foundation/HydrateSitecoreContext';
import i18n from 'i18next';
import Layout from 'Project/Website/components/Layout';
import { Redirect } from 'react-router-dom';
// @ts-ignore
import config from 'temp/config';
import { layoutServiceFactory } from '../../../Foundation/LayoutService/factories/LayoutServiceFactory';
import { RouteHandlerProps, RouteHandlerState, SsrState } from './types';

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static React routing isn't enough -
// we need to be able to load dynamic route data from Sitecore after the client side route changes.
// So react-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

let ssrInitialState: SsrState = null;

const siteLanguages: string[] = ['en', 'fr'];

class RouteHandler extends React.Component<any, RouteHandlerState> {
  public state: RouteHandlerState = {
    notFound: true,
    routeData: ssrInitialState as LayoutServiceData, // null when client-side rendering
    defaultLanguage: config.defaultLanguage
  };

  private componentIsMounted: Readonly<boolean> = false;
  private languageIsChanging: Readonly<boolean> = false;

  constructor(props: RouteHandlerProps) {
    super(props);

    if (ssrInitialState && ssrInitialState.sitecore && ssrInitialState.sitecore.route) {
      // set the initial sitecore context data if we got SSR initial state
      const context = this.props.sitecoreContext;
      this.props.updateSitecoreContext({
        ...context,
        route: ssrInitialState.sitecore.route,
        itemId: ssrInitialState.sitecore.route.itemId,
        ...ssrInitialState.sitecore.context
      });
    }

    // route data from react-router - if route was resolved, it's not a 404
    if (props.routeProps !== null) {
      this.state.notFound = false;
    }

    // if we have an initial SSR state, and that state doesn't have a valid route data,
    // then this is a 404 route.
    if (ssrInitialState && (!ssrInitialState.sitecore || !ssrInitialState.sitecore.route)) {
      this.state.notFound = true;
    }

    // if we have an SSR state, and that state has language data, set the current language
    // (this makes the language of content follow the Sitecore context language cookie)
    // note that a route-based language (i.e. /de-DE) will override this default; this is for home.
    if (ssrInitialState && ssrInitialState.context && ssrInitialState.context.language) {
      this.state.defaultLanguage = ssrInitialState.context.language;
    }

    // once we initialize the route handler, we've "used up" the SSR data,
    // if it existed, so we want to clear it now that it's in react state.
    // future route changes that might destroy/remount this component should ignore any SSR data.
    // EXCEPTION: Unless we are still SSR-ing. Because SSR can re-render the component twice
    // (once to find GraphQL queries that need to run, the second time to refresh the view with
    // GraphQL query results)
    // We test for SSR by checking for Node-specific process.env variable.
    if (typeof window !== 'undefined') {
      ssrInitialState = null;
    }

    // tell i18next to sync its current language with the route language
    this.updateLanguage();
  }

  public componentDidMount(): void {
    // if no existing routeData is present (from SSR), get Layout Service fetching the route data
    if (!this.state.routeData) {
      this.updateLayoutData();
    }

    this.componentIsMounted = true;
  }

  public componentWillUnmount(): void {
    this.componentIsMounted = false;
  }

  public componentDidUpdate(previousProps: RouteHandlerProps): void {
    const existingRoute = previousProps?.routeProps?.match?.url;
    const newRoute = this.props?.routeProps?.match?.url;
    // don't change state (refetch route data) if the route has not changed
    if (existingRoute === newRoute) {
      return;
    }

    // if in experience editor (also checks browser has loaded) - force reload instead of route data update
    // avoids confusing Sitecore's editing JS
    if (isEditorActive()) {
      window.location.assign(newRoute as string);
      return;
    }

    this.updateLanguage();
    this.updateLayoutData();
  }

  public render(): JSX.Element | null {
    const layoutData = this.props.sitecoreContext;
    const { notFound, routeData } = this.state;

    // no route data for the current route in Sitecore - show not found component.
    // Note: this is client-side only 404 handling. Server-side 404 handling is the responsibility
    // of the server being used (i.e. node-headless-ssr-proxy and Sitecore intergrated rendering know how to send 404 status codes).
    if (notFound) {
      // Check to see if user is already redirected to error page to prevent same page redirect
      const isOnErrorPage = isServer() === false ? window.location.pathname === '/404' : true;
      return isOnErrorPage ? null : <Redirect to="/404" />;
    }

    // Don't render anything if the route data or dictionary data is not fully loaded yet.
    // This is a good place for a "Loading" component, if one is needed.
    if (!routeData || this.languageIsChanging) {
      return null;
    }

    // Render the app's root structural layout
    return (
      <AuthenticationProvider
        Redirect={Redirect}
        sitecoreRouteName={layoutData.route.name}
        sitecoreContextFactory={this.props}
        route={layoutData.route}
      >
        <HydrateSitecoreContext sitecoreContextFactory={this.props}>
          <UserTypeAuthorization>
            <Layout route={layoutData.route} />
          </UserTypeAuthorization>
        </HydrateSitecoreContext>
      </AuthenticationProvider>
    );
  }

  /**
   * Loads route data from Sitecore Layout Service into state.routeData
   */
  private updateLayoutData() {
    let sitecoreRoutePath = this.props?.routeProps?.match?.params?.sitecoreRoute || '/';
    if (!sitecoreRoutePath.startsWith('/')) {
      sitecoreRoutePath = `/${sitecoreRoutePath}`;
    }

    let language = 'en';

    if (!isServer()) {
      language =
        this.props?.routeProps?.match?.params?.lang ||
        localStorage.getItem('userSavedLang') ||
        navigator.language.substring(0, 2);
    }

    if (!siteLanguages.includes(language)) {
      language = 'en';
    }

    localStorage.setItem('userSavedLang', language);

    // instantiate the dictionary service.
    const layoutServiceInstance = layoutServiceFactory.create();

    // get the route data for the new route
    layoutServiceInstance.fetchLayoutData(sitecoreRoutePath, language).then((routeData) => {
      if (routeData !== null && routeData.sitecore && routeData.sitecore.route) {
        // set the sitecore context data and push the new route
        const context = this.props.sitecoreContext;
        this.props.updateSitecoreContext({
          ...context,
          route: routeData.sitecore.route,
          itemId: routeData.sitecore.route.itemId,
          language,
          ...routeData.sitecore.context
        });
        this.setState({ routeData, notFound: false });
      } else {
        this.setState({ routeData, notFound: true });
      }
    });
  }

  /**
   * Updates the current app language to match the route data.
   */
  private updateLanguage(): void {
    let language = 'en';
    let newLanguage = null;

    if (!isServer()) {
      language = navigator.language.substring(0, 2);

      if (!siteLanguages.includes(language)) {
        language = 'en';
      }
      newLanguage =
        this.props?.routeProps?.match?.params?.lang ||
        localStorage.getItem('userSavedLang') ||
        language;
    }

    if (i18n.language !== newLanguage) {
      this.languageIsChanging = true;

      i18n.changeLanguage(newLanguage, () => {
        this.languageIsChanging = false;

        // if the component is not mounted, we don't care
        // (next time it mounts, it will render with the right language context)
        if (this.componentIsMounted) {
          // after we change the i18n language, we need to force-update React,
          // since otherwise React won't know that the dictionary has changed
          // because it is stored in i18next state not React state
          this.forceUpdate();
        }
      });
    }
  }
}

export default withSitecoreContext({ updatable: true })(RouteHandler);

/**
 * Sets the initial state provided by server-side rendering.
 * Setting this state will bypass initial route data fetch calls.
 * @param {object} ssrState
 */
export function setServerSideRenderingState(ssrState: SsrState): void {
  ssrInitialState = ssrState;
}
