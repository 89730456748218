import { useState } from 'react';
import { ReportType } from '@hobt/constants';

import { TabItemProps } from 'Components/Navigation/Tabs';
import { TabMapping, TabType } from 'Feature/Arrears/models/types';
import { ComparisonOperators } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import useArrearsSummaryReportsClient from './arrearsSummaryReportsClient';
import { ArrearsSummaryReportInventoryRecords, ArrearsSummaryReportPropsFields } from './types';
import { config } from '../../../../config';
import useArrearsFileListFunctions from '../ArrearsFileList/useArrearsFileListFunctions';
import { DocumentDetails } from '../ArrearsFileList/types';

const useArrearSummaryReportFunctions = (fields: ArrearsSummaryReportPropsFields) => {
  const authenticationContext = useAuthenticationContext();

  const [subTotals, setSubTotals] = useState<any>({});
  const [items] = useState<TabItemProps[]>([
    {
      displayText: fields.reportedText,
      name: TabMapping.get(TabType.Reported)!
    },
    {
      displayText: fields.notReportedText,
      name: TabMapping.get(TabType.Unreported)!
    }
  ]);
  const [isTab, setIsTab] = useState<TabItemProps>(items[0]);
  const [isSummaryReportToast, setIsSummaryReportToast] = useState<boolean>(false);
  const [isSummaryReportNotReady, setIsSummaryReportNotReady] = useState<boolean>(false);
  const [isInventoryToast, setInventoryToast] = useState<boolean>(false);

  const tabQueryFilterLookup = {
    reported: `Reported ${ComparisonOperators.EQ} ${TabType.Reported}`,
    notReported: `Reported ${ComparisonOperators.EQ} ${TabType.Unreported}`
  };

  const { reportDownload } = useArrearsSummaryReportsClient(authenticationContext, {
    timeout: config.arrearsApi.requestTimeout
  });

  const { onFileDownload } = useArrearsFileListFunctions();

  const onTabClick = (tab: TabItemProps) => setIsTab(tab);

  // TODO: Handle API Error
  const onApiErrorCallback = () => setInventoryToast(true);

  const setSubTotal = (rowData: ArrearsSummaryReportInventoryRecords[]) => {
    setSubTotals(rowData.pop());
  };

  const onDownloadHandler = async () => {
    try {
      const response: DocumentDetails | null = await reportDownload();

      if (response != null) {
        if (response.latestReportFlag == false) {
          setIsSummaryReportNotReady(true);
        }
        if (response.documentID) {
          onFileDownload(response.documentID, response.reportingPeriodID, ReportType.SummaryReport);
        } else {
          throw new Error('No summary report to be retrieved from the database');
        }
      } else {
        throw new Error('No Summary Report present to download');
      }
    } catch (errors) {
      setIsSummaryReportToast(true);
    }
  };

  const onToastCloseHandler = () => {
    setIsSummaryReportToast(false);
    setInventoryToast(false);
    setIsSummaryReportNotReady(false);
  };

  return {
    items,
    isTab,
    subTotals,
    isInventoryToast,
    isSummaryReportToast,
    tabQueryFilterLookup,
    isSummaryReportNotReady,
    onTabClick,
    setSubTotal,
    onDownloadHandler,
    onApiErrorCallback,
    onToastCloseHandler
  };
};

export default useArrearSummaryReportFunctions;
