import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import FormDatepicker from 'Components/Inputs/FormDatepicker';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

import styles from './styles.module.scss';
import { AdditionalDocumentationPropsFields } from '../types';

const AdditionalDocumentation: React.FC<AdditionalDocumentationPropsFields> = (
  props: AdditionalDocumentationPropsFields
) => {
  const {
    heading,
    titleTransferDocument,
    completeClaimReceivedDate,
    assigmentOfMortgage,
    isReadOnlyView,
    isClaimStatusPostAdjudicationStatus,
    judgmentInformationReceivedDateLabel,
    informationSenttoNRC
  } = props;
  const { claimData } = useHBTFormContext();
  const { setValue } = useFormContext();

  React.useEffect(() => {
    setValue(
      `mandatoryDocumentation.titleTransferDocumentReceivedDate`,
      claimData?.mandatoryDocumentation?.titleTransferDocumentReceivedDate
    );
    setValue(
      'mandatoryDocumentation.completeClaimReceivedDate',
      claimData?.mandatoryDocumentation?.completeClaimReceivedDate
    );
    setValue(
      'mandatoryDocumentation.mortgageAssignmentDocumentReceivedDate',
      claimData?.mandatoryDocumentation?.mortgageAssignmentDocumentReceivedDate
    );
    setValue(
      'mandatoryDocumentation.judgmentBankruptcyInformationReceivedDate',
      claimData?.mandatoryDocumentation?.judgmentBankruptcyInformationReceivedDate
    );
    setValue(
      'mandatoryDocumentation.informationSentToNationalRecoveriesCentreDate',
      claimData?.mandatoryDocumentation?.informationSentToNationalRecoveriesCentreDate
    );
  }, []);

  return (
    <section className={styles.suspenseSections}>
      <h3 className={styles.heading}>
        <Text field={heading} />
      </h3>
      <div className={`row ${styles.marginBottom35}`}>
        <div className="col-5">
          <FormDatepicker
            className={styles.halfLeft}
            name="mandatoryDocumentation.titleTransferDocumentReceivedDate"
            label={titleTransferDocument}
            isReadOnly={isReadOnlyView}
          />
        </div>
        <div className="col-5">
          <FormDatepicker
            className={styles.halfLeft}
            name="mandatoryDocumentation.completeClaimReceivedDate"
            label={completeClaimReceivedDate}
            isReadOnly={isReadOnlyView || isClaimStatusPostAdjudicationStatus}
          />
        </div>
      </div>
      <div className={`row ${styles.marginBottom35}`}>
        <div className="col-5">
          <FormDatepicker
            className={styles.halfLeft}
            name="mandatoryDocumentation.mortgageAssignmentDocumentReceivedDate"
            label={assigmentOfMortgage}
            isReadOnly={isReadOnlyView}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-5">
          <FormDatepicker
            className={styles.halfLeft}
            label={judgmentInformationReceivedDateLabel}
            name={`mandatoryDocumentation.judgmentBankruptcyInformationReceivedDate`}
          />
        </div>

        <div className="col-5">
          <FormDatepicker
            className={styles.half}
            label={informationSenttoNRC}
            name={`mandatoryDocumentation.informationSentToNationalRecoveriesCentreDate`}
          />
        </div>
      </div>
    </section>
  );
};

export default AdditionalDocumentation;
