// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useRef, useEffect, useReducer, useMemo } from 'react';
import { ApproverReview } from '@hobt/arrears-domain';
import { CancellationReason } from '@hobt/claim-domain';
import { LanguageShort, NotificationScenario } from '@hobt/constants';
import { compareTimestamps } from '@hobt/utils';
import { defaultEmptyString } from 'Components/Common/Api/utils/HandleEmpty';
import { TabItemProps } from 'Components/Navigation/Tabs';
import { sortByOptionalObjectProperties } from 'Constants/Utils/SortByOptionalObjectProperties/sortByOptionalObjectProperties';
import { validString } from 'Constants/Utils/ValidString/validString';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { notificationsReducer } from 'Feature/Header/components/Notifications/notificationsReducer';
import { useNotificationApi } from 'Feature/Header/components/Notifications/useNotificationApi';
import { useAuthenticationContext } from 'Foundation/Authentication';
import i18n from 'i18next';
import moment from 'moment-timezone';
import { config } from '../../../../config';
import {
  NotificationProps,
  NotificationActionType,
  NotificationsState,
  NotificationStatus,
  NotificationScenarioLookup,
  NotificationListItem,
  Notification,
  Payee,
  TokenReplacement
} from './types';

const INTERNAL_ONLY_CANCELLATION_REASONS = [
  CancellationReason.AssignmentOfMortgageNoAttemptAtSaleOfProperty,
  CancellationReason.InputError,
  CancellationReason.LenderNegligenceMisrepresentation,
  CancellationReason.NoAmountPayable,
  CancellationReason.PolicyNotInForce,
  CancellationReason.SupplementaryClaimReceivedIsLessThan$250,
  CancellationReason.TitleDefect
];

const CLAIM_APPROVAL_TYPE = 'Claims';

const MISSING_PAYEE_INFO_FIELDS_FOR_FINANCIAL_DATA = ['partyID', 'locationID'];

const PRIMARY_APPROVAL_NOTIFICATION_SCENARIOS = [
  NotificationScenario.PrimaryApproved,
  NotificationScenario.PrimaryNeedsReview
];

const initialReducerState: NotificationsState = {
  statusFilter: NotificationStatus.UNREAD,
  showNotificationSidebar: false,
  showNotificationContents: false,
  unreadNotifications: 0,
  notifications: []
};

const useInterval = (callback: () => void, delay: number, onFinished?: () => void) => {
  const callbackRef = useRef<() => void>();

  const next = () => {
    if (callbackRef.current != null) {
      callbackRef.current();
    }
  };

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const interval = setInterval(next, delay);

    return () => {
      if (onFinished != null) {
        onFinished();
      }
      clearInterval(interval);
    };
  }, [callback, delay]);
};

export const useNotificationFunctions = ({ fields }: NotificationProps) => {
  const isExternalPortal = config.app.siteType === FeatureFlags.EXTERNAL;
  const authContext = useAuthenticationContext();
  const {
    errorCount,
    getNotifications: getNotificationData,
    updateNotifications: updateNotificationsFromApi
  } = useNotificationApi(authContext);

  const {
    approvalDecisionCodesList,
    cancellationList,
    claimStatusList,
    declineReasonList,
    documentRequestCodeCategoryList,
    typeOfClaimList
  } = fields;

  const [state, dispatch] = useReducer(notificationsReducer, initialReducerState);

  const scenarioMap: NotificationScenarioLookup = useMemo(() => {
    const map: NotificationScenarioLookup = {};

    const scenarios = fields?.notificationScenario?.fields?.listItems ?? [];

    scenarios.forEach((scenario) => {
      const { contentTemplate, contentTitle, serviceCode } = scenario?.fields ?? {};

      if (serviceCode?.value != null) {
        map[serviceCode.value.toString()] = {
          title: contentTitle ?? {},
          content: contentTemplate
        };
      }
    });

    return map;
  }, [fields.notificationScenario.fields.listItems]);

  const tabs: TabItemProps[] = useMemo(
    () => [
      {
        name: 'unread',
        displayText: fields.unread,
        count: state.unreadNotifications === 0 ? undefined : state.unreadNotifications
      },
      { name: 'read', displayText: fields.read },
      { name: 'all', displayText: fields.all }
    ],
    [state.unreadNotifications]
  );

  const formatDateForList = (date?: string): string => {
    if (date != null) {
      const timezone = moment.tz.guess();

      const timestamp = moment(date).tz('America/Toronto');
      const momentDate = moment.tz(timezone).isAfter(timestamp, 'day');

      if (i18n.language === LanguageShort.French) {
        return timestamp.format(momentDate ? 'DD/MM/YYYY' : 'hh:mm');
      }

      return timestamp.format(momentDate ? 'DD/MM/YYYY' : 'h:mm a');
    }
    return '';
  };

  const getDocumentRequestCategoryCodes = (documentRequestCategoryCodes: string[]) => {
    const documentCodes = documentRequestCategoryCodes.map(
      (document) =>
        documentRequestCodeCategoryList?.fields?.listItems?.find(
          (item) => item?.fields?.itemValue?.value === document
        )?.fields?.itemName?.value
    );
    return documentCodes.length > 0 ? documentCodes.join(',') : undefined;
  };

  const getLatestApproverReview = (approverReviews?: ApproverReview[]) => {
    if (Array.isArray(approverReviews) && approverReviews.length > 0) {
      return approverReviews.reduce((prev: ApproverReview, cur: ApproverReview) =>
        compareTimestamps(prev.decisionTimestamp, cur.decisionTimestamp) ? prev : cur
      );
    }

    return null;
  };

  const getApprovalDecisionCodeContent = (approvalDecisionCode?: number) => {
    if (approvalDecisionCode == null || Number.isNaN(approvalDecisionCode)) {
      return '';
    }

    return (
      approvalDecisionCodesList?.fields?.listItems?.find(
        (item) => Number(item?.fields?.itemValue?.value) === approvalDecisionCode
      )?.fields?.itemName?.value ?? ''
    );
  };

  const getCancellationReason = (cancellationReasonCode?: string) => {
    if (cancellationReasonCode == null || cancellationReasonCode === '') {
      return '';
    }

    if (
      isExternalPortal &&
      INTERNAL_ONLY_CANCELLATION_REASONS.includes(
        cancellationReasonCode as unknown as CancellationReason
      )
    ) {
      // We return 'Other' as the default option on the external portal if the cancellation reason selected by an internal user
      // does not exist on the external portal
      return cancellationList?.fields?.listItems?.slice(-1)?.[0]?.fields?.itemName?.value ?? '';
    }

    return (
      cancellationList?.fields?.listItems?.find(
        (item) => item?.fields?.itemValue?.value === cancellationReasonCode
      )?.fields?.itemName?.value ?? ''
    );
  };

  const getTokenReplacements = (notification: Notification): TokenReplacement => {
    let tokenReplacements = {
      ...notification.elementData
    } as TokenReplacement;

    const { elementData, scenarioID } = notification;

    const cmhcAccountNumber = elementData?.cmhcLoanAccountNumber ?? '';
    const sequenceNumber = elementData?.sequenceNumber?.toString() ?? '';
    const claimId = `${sequenceNumber}-${cmhcAccountNumber}`;

    const claimStatus =
      claimStatusList?.fields?.listItems?.find(
        (item) => Number(item?.fields?.itemValue?.value) === elementData?.claimStatus
      )?.fields?.itemName?.value ?? '';

    const cancellationReason = getCancellationReason(elementData?.cancellationReasonCode);
    const declineReason =
      declineReasonList?.fields?.listItems?.find(
        (item) => Number(item?.fields?.itemValue?.value) === elementData?.declineReasonCode
      )?.fields?.itemName?.value ?? '';

    const documentationRequestNotes =
      elementData?.documentRequestCategoryCode != null &&
      elementData.documentRequestCategoryCode.length > 0
        ? getDocumentRequestCategoryCodes(elementData.documentRequestCategoryCode)
        : undefined;

    const remarkText = elementData?.remarkText ?? '';

    const formatDate = (date?: string): string => {
      if (date != null) {
        const timestamp = moment(date).tz('America/Toronto');
        return timestamp.format('DD/MM/YYYY, h:mm a');
      }
      return '';
    };

    let cancellationNote = '';
    let declineNote = '';

    if (cancellationReason !== '') {
      cancellationNote = elementData?.remarkText ?? '';
    } else if (declineReason !== '') {
      declineNote = elementData?.remarkText ?? '';
    }

    const lenderReferenceNumber = elementData?.approvedLenderReferenceNumber ?? '';
    const link = elementData?.link ?? '';

    let approvalType = '';
    let primaryApprovalDecision = '';
    let primaryApproverName = '';
    let primaryDecisionTimestamp = '';
    let primaryApproverRole = fields?.arrearsPrimaryApproverRole?.value ?? '';

    if (PRIMARY_APPROVAL_NOTIFICATION_SCENARIOS.includes(scenarioID)) {
      if (elementData?.approvalType != null && elementData.approvalType === CLAIM_APPROVAL_TYPE) {
        approvalType = fields?.claimApprovalType?.value ?? '';
        primaryApprovalDecision = getApprovalDecisionCodeContent(elementData?.approvalDecisionCode);
        primaryApproverName = elementData?.approverName ?? '';
        primaryDecisionTimestamp = formatDateForList(elementData?.decisionTimestamp);
        primaryApproverRole =
          fields?.userLevelList?.fields?.listItems?.find(
            (item: FieldListItem) =>
              item?.fields?.itemValue?.value === elementData?.baseApprovalTitleTypeCode?.toString()
          )?.fields?.itemName?.value ?? '';
      } else if (getLatestApproverReview(elementData?.approverReviews) !== null) {
        approvalType = fields?.arrearsApprovalType?.value ?? '';
        primaryApproverName =
          getLatestApproverReview(elementData?.approverReviews)?.approverName ?? '';
        primaryDecisionTimestamp = formatDateForList(
          getLatestApproverReview(elementData?.approverReviews)?.decisionTimestamp ?? ''
        );
      } else {
        approvalType = fields?.arrearsApprovalType?.value ?? '';
        primaryApproverName = elementData?.approverName ?? '';
        primaryDecisionTimestamp = formatDate(elementData?.decisionTimestamp ?? '');
      }
    }

    const level2DecisionTimestamp = formatDate(
      getLatestApproverReview(elementData?.approverReviews)?.decisionTimestamp
    );

    const level2ApprovalDecision = getApprovalDecisionCodeContent(
      getLatestApproverReview(elementData?.approverReviews)?.approvalDecisionCode
    );
    const level2ApproverName = getLatestApproverReview(elementData?.approverReviews)?.approverName;

    const typeOfClaim =
      typeOfClaimList?.fields?.listItems?.find(
        (item) => item?.fields?.itemValue.value === elementData?.claimTypeCode
      )?.fields?.itemName?.value ?? '';

    tokenReplacements = {
      ...tokenReplacements,
      approvalType,
      cancellationNote,
      cancellationReason,
      cmhcAccountNumber,
      claimId,
      claimStatus,
      declineNote,
      declineReason,
      documentationRequestNotes,
      remarkText,
      lenderReferenceNumber,
      level2ApproverName,
      level2ApprovalDecision,
      level2DecisionTimestamp,
      link,
      primaryApprovalDecision,
      primaryApproverName,
      primaryApproverRole,
      primaryDecisionTimestamp,
      typeOfClaim
    };

    // extract ficode, lender, transit
    if (elementData?.payeeList != null) {
      const lenderFiTransitGroup = elementData.payeeList.reduce(
        (prev, { approvedLenderCode, financialInstitutionCode, transitNumber }) => {
          return `${prev}${approvedLenderCode}, ${financialInstitutionCode}, ${transitNumber}; `;
        },
        ''
      );

      if (scenarioID === NotificationScenario.InvalidFinancialData) {
        const lenderToMissingPayeeInfoMapping = sortByOptionalObjectProperties(
          elementData?.payeeList,
          'approvedLenderCode'
        )?.reduce((payeesWithMissingData, payee) => {
          // check which exact field is missing data so that can be displayed later
          const lenderMissingPayeeInfo = MISSING_PAYEE_INFO_FIELDS_FOR_FINANCIAL_DATA.reduce(
            (missingPayeeInfo, idName) => {
              const totalMissingPayeeInfo =
                missingPayeeInfo.length === 0 ? missingPayeeInfo : `${missingPayeeInfo}, `;

              return !validString(payee[idName as keyof Payee])
                ? `${totalMissingPayeeInfo}${idName}: ${payee[idName as keyof Payee]}`
                : missingPayeeInfo;
            },
            ''
          );

          return lenderMissingPayeeInfo.length > 0
            ? `${payeesWithMissingData} <li>
                ${payee.approvedLenderCode}, ${payee.financialInstitutionCode}, ${payee.transitNumber} - ${lenderMissingPayeeInfo} </li>
                `
            : payeesWithMissingData;
        }, '');

        tokenReplacements = {
          ...tokenReplacements,
          ...(lenderToMissingPayeeInfoMapping !== '' && { lenderToMissingPayeeInfoMapping })
        };
      }

      tokenReplacements = { ...tokenReplacements, lenderFiTransitGroup };
    }

    return tokenReplacements;
  };

  const renderNotificationContents = (template: string, replaceValues: TokenReplacement) =>
    template.replaceAll(
      /{[^}]+}/g,
      (matched) => replaceValues[matched.substring(1, matched.length - 1)] ?? matched
    );

  const processNotifications = (rNotifications: Notification[]): NotificationListItem[] => {
    const notificationListItems: NotificationListItem[] = [];

    rNotifications
      .filter((notification) =>
        state.statusFilter === NotificationStatus.ALL
          ? notification
          : notification.statusCode === state.statusFilter ||
            notification.notificationID === state.selectedNotification?.notificationID
      )
      .forEach((notification: Notification) => {
        const { scenarioID, notificationID, issuedTimestamp, statusCode, elementData } =
          notification;
        if (scenarioID != null && notificationID != null && statusCode != null) {
          const scenarioId = scenarioID.toString(10);

          const title = renderNotificationContents(
            scenarioMap[scenarioId]?.title?.value ?? '',
            getTokenReplacements(notification)
          );
          const date = formatDateForList(issuedTimestamp);

          notificationListItems.push({
            id: notificationID,
            scenarioId,
            lender: elementData?.approvedLenderReferenceNumber ?? undefined,
            title,
            date,
            status: statusCode,
            tokenData: elementData,
            cmhcLoanAccountNumber: elementData?.cmhcLoanAccountNumber
          });
        }
      });

    return notificationListItems;
  };

  const getNotifications = async () => {
    try {
      const notificationData: Notification[] = await getNotificationData();
      dispatch({
        action: NotificationActionType.UPDATE_NOTIFICATIONS,
        payload: notificationData.slice(0, 50)
      });
    } catch (e) {
      /* eslint-disable no-console */
      console.log(e);
    }
  };

  const pollNotificationData = async () => {
    if (errorCount <= 3) {
      await getNotifications();
    }
  };

  const toggleNotificationSidebar = () => {
    dispatch({
      action: state.showNotificationSidebar
        ? NotificationActionType.HIDE_NOTIFICATION_SIDEBAR
        : NotificationActionType.SHOW_NOTIFICATION_SIDEBAR
    });
  };

  const updateNotification = (notificationId?: string) => {
    updateNotificationsFromApi(notificationId)
      .then(() => {
        getNotifications().catch((e) => /* eslint-disable no-console */ console.log(e));
      })
      .catch((e) => /* eslint-disable no-console */ console.log(e));
  };

  const handleNotificationItemClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    const notificationId = (e.currentTarget as Element).getAttribute('data-notificationId');

    if (notificationId != null) {
      const selectedNotification = state.notifications?.find(
        (n) => n.notificationID === notificationId
      );

      const scenarioId = selectedNotification?.scenarioID;

      if (selectedNotification != null && scenarioId != null) {
        const notificationsContent = scenarioMap[scenarioId];
        const timezone = moment.tz.guess();

        const tokenReplacements = getTokenReplacements(selectedNotification);
        const notificationContent = {
          title: renderNotificationContents(
            notificationsContent.title.value ?? '',
            tokenReplacements
          ),
          date:
            selectedNotification.issuedTimestamp != null
              ? moment(selectedNotification.issuedTimestamp)
                  .tz(timezone)
                  .format('MM/DD/YYYY, h:mm a')
              : defaultEmptyString,
          lender: tokenReplacements.lenderReferenceNumber ?? undefined,
          cmhcLoanAccountNumber: tokenReplacements.cmhcLoanAccountNumber,
          content: renderNotificationContents(
            notificationsContent.content.value ?? '',
            tokenReplacements
          )
        };

        dispatch({
          action: NotificationActionType.UPDATE_NOTIFICATION_CONTENT,
          payload: {
            selectedNotification,
            notificationContent
          }
        });

        // If selected notification is unread, then update to read.
        if (selectedNotification.statusCode === NotificationStatus.UNREAD) {
          updateNotification(notificationId);
        }
      }
    }
  };

  const closeNotificationContent = () => {
    dispatch({ action: NotificationActionType.HIDE_NOTIFICATION_CONTENT });
  };

  const handleTabClick = async (tab: TabItemProps) => {
    const tabNameToStatusMap: { [key: string]: NotificationStatus } = {
      read: NotificationStatus.READ,
      unread: NotificationStatus.UNREAD
    };

    dispatch({
      action: NotificationActionType.UPDATE_FILTER,
      payload: tabNameToStatusMap[tab.name] ?? NotificationStatus.ALL
    });
  };

  const handleMarkAllRead = () => {
    dispatch({
      action: NotificationActionType.UPDATE_NOTIFICATIONS,
      payload: state.notifications.map((n) => ({
        ...n,
        notificationStatus: NotificationStatus.READ
      }))
    });
    updateNotification();
  };

  const handleRefreshButtonClick = () => {
    dispatch({ action: NotificationActionType.SET_BUTTON_LOADING, payload: true });
    getNotifications().then(() => {
      dispatch({ action: NotificationActionType.SET_BUTTON_LOADING, payload: false });
    });
  };

  useInterval(pollNotificationData, config.notificationApi.pollingTimeout ?? 30000);

  return {
    currentTab: state.statusFilter,
    showMarkAllReadButton: state.statusFilter !== NotificationStatus.READ,
    refreshButtonLoading: state.buttonLoading,
    showNotificationContent: state.showNotificationContents,
    notificationContent: state.notificationContent,
    selectedNotification: state.selectedNotification,
    scenarioMap,
    notifications: processNotifications(state.notifications),
    tabs,
    hasUnreadNotifications: state.unreadNotifications > 0,
    showNotificationSidebar: state.showNotificationSidebar,
    handleMarkAllRead,
    getNotifications,
    handleTabClick,
    handleRefreshButtonClick,
    toggleNotificationSidebar,
    handleNotificationItemClick,
    closeNotificationContent,
    renderNotificationContents
  };
};
