import { useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { AxiosError, AxiosResponse } from 'axios';

import { propertyRepairSchema } from '@hobt/claim-domain';
import { HttpResponseStatusCodes, HbtServiceErrorCodes } from '@hobt/constants';
import { hbtResolver } from '@hobt/schema-validator';
import { deepCopy } from '@hobt/utils';

import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { ErrorObject } from 'Feature/UserManagement/models/types';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import { config } from '../../../../../config';

import { PropertyRepairsEditFormData, PropertyRepairsSubmitFormData } from './types';

const API_CLIENT_CONFIG: ApiClientConfig = {
  timeout: Number(config.defaultApi.requestTimeout) || 2000
};

const usePropertyRepairFunctions = (
  onSuccessCallback?: Function,
  setIsLoadingCallback?: React.Dispatch<React.SetStateAction<Spinner>>,
  onErrorCallback?: Function,
  isEdit: boolean = false
) => {
  const [showValidationError, setShowValidationError] = useState<boolean>(false);

  const authContext = useAuthenticationContext();
  const { postWithAuth, putWithAuth } = ApiClient(authContext, API_CLIENT_CONFIG);

  const createPropertyRepairRequest = (data: PropertyRepairsSubmitFormData) => {
    setIsLoadingCallback?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description',
      isFlyout: true
    });

    postWithAuth(config.preClaimPropertyRepairsApi.urls.submit, { ...data })
      .then((responseData: AxiosResponse<PropertyRepairsSubmitFormData>) => {
        if (onSuccessCallback != null) {
          onSuccessCallback(responseData);
        }
        setIsLoadingCallback?.({
          isLoading: false
        });
      })
      .catch((e: AxiosError<any>) => {
        if (
          e.response?.status === HttpResponseStatusCodes.ServerError &&
          e.response?.data?.error?.errorCode ===
            HbtServiceErrorCodes.HBT_ERR_DUPLICATE_LOAN_NUMBER.code
        ) {
          setShowValidationError(true);
        } else if (onErrorCallback != null) {
          onErrorCallback();
        }

        setIsLoadingCallback?.({
          isLoading: false
        });
      });
  };

  const editPropertyRepairRequest = (data: PropertyRepairsEditFormData) => {
    setIsLoadingCallback?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description',
      isFlyout: true
    });

    putWithAuth(config.preClaimPropertyRepairsApi.urls.update, { ...data })
      .then((responseData: AxiosResponse<PropertyRepairsSubmitFormData>) => {
        if (onSuccessCallback != null) {
          onSuccessCallback(responseData);
        }
        setIsLoadingCallback?.({
          isLoading: false
        });
      })
      .catch((e: ErrorObject) => {
        if (
          e.response?.status === HttpResponseStatusCodes.BadRequest &&
          e.response?.data?.error?.errorCode === HbtServiceErrorCodes.HBT_ERR_VALIDATION.code
        ) {
          setShowValidationError(true);
        } else if (onErrorCallback != null) {
          onErrorCallback();
        }

        setIsLoadingCallback?.({
          isLoading: false
        });
      });
  };

  const hookForm = useForm({
    resolver: hbtResolver(propertyRepairSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  } as Record<string, any>);

  const handleSubmit = (data: PropertyRepairsSubmitFormData | any) => {
    const editData = deepCopy(data);

    if (isEdit === true) {
      delete editData.borrowerChargeCode;
      editPropertyRepairRequest(editData);
    } else {
      createPropertyRepairRequest(data);
    }
  };

  const onSubmitErrors: any = (errs: Record<string, Object>) => {
    // TODO: Remove once validations are in place
    // eslint-disable-next-line no-console
    console.log('FORM ERRORS: ', errs);
  };

  const onCloseValidationErrors = () => {
    setShowValidationError(false);
  };

  return {
    hookForm,
    showValidationError,
    handleSubmit,
    onCloseValidationErrors,
    onSubmitErrors
  };
};

export default usePropertyRepairFunctions;
