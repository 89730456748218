import * as React from 'react';

export interface ButtonProps {
  id: string;
  displayText?: string;
  icon?: string;
  name: string;
  buttonType: ButtonType;
  disabled?: boolean;
  isDropDown?: boolean;
  isIcon?: boolean;
  iconSize?: number;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  otherProps?: any;
  ariaText?: string;
  additionalStyles?: string;
  type?: string;
}

export enum ButtonType {
  PRIMARY,
  SECONDARY,
  CONTEXTUAL,
  TEXT
}
