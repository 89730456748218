import React from 'react';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

import { LinedCardProps } from './LinedCard.types';

export const LinedCard: React.FC<LinedCardProps> = ({
  id,
  testId,
  image,
  title,
  linePosition,
  lineColor,
  children,
  role
}) => (
  <div
    data-testid={testId}
    id={id}
    className={`accordion card card--${linePosition}-${lineColor}`}
    role={role}
    // aria-labelledby={`${id}Accordion_headerTitle`}
  >
    {(image || title) && (
      <header className="card-header card__header">
        {image && <Image field={image} className="card__header-image" />}
        {title && (
          <h2 className="card__header-title">
            <Text field={title.field} />
          </h2>
        )}
      </header>
    )}
    {children}
  </div>
);
