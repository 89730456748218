export const useStringTemplateEngine = () => {
  const stringReplace = (
    templateString: string,
    stringValues: { [field: string]: string }
  ): string => {
    let returnedString = templateString;

    Object.entries(stringValues).forEach(([token, value]) => {
      returnedString = returnedString.toString().replace(token, value);
    });

    return returnedString;
  };

  return {
    stringReplace
  };
};
