import { useAuthenticationContext } from 'Foundation/Authentication';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import SignInAreaProps from '../../models/SignInAreaProps';

const SignInArea: React.FC<SignInAreaProps> = ({ fields }): ReactElement => {
  const authenticationContext = useAuthenticationContext();
  const history = useHistory();
  const siteLanguages: string[] = ['en', 'fr'];

  const onClick = () => {
    authenticationContext.signInWithRedirect();
  };

  if (!isServer()) {
    if (localStorage.getItem('userSavedLang') === null) {
      let language = navigator.language.substring(0, 2);
      if (!siteLanguages.includes(language)) {
        language = 'en';
      }
      localStorage.setItem('userSavedLang', language);
      history.push(`/${language}`);
    } else if (window.location.pathname === '/') {
      const language = localStorage.getItem('userSavedLang');
      history.push(`/${language}`);
    }
  }

  return (
    <div className="landing">
      <div className="row">
        <section className="col-12">
          <div className="card">
            <div className="card-body card__body card__body--image-card">
              <div className="row card__body-row">
                <div className="col-6 card__body-content--image-card">
                  <h2 className="card__body-title">
                    <Text field={fields.title} />
                  </h2>
                  <RichText field={fields.description} className="card__body-text" />
                  <button
                    id="loginButton"
                    className="btn btn-primary card__body-button card__body-button"
                    onClick={onClick}
                    aria-label={`${fields.loginButtonText.value}`}
                  >
                    <Text field={fields.loginButtonText} />
                  </button>
                </div>
                <div className="col-6">
                  <Image
                    field={fields.loginImage}
                    className="card__body-image landing-page-main-image"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SignInArea;
