import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { NotificationButtonProps } from 'Feature/Header/components/NotificationFlyoutsComponent/NotificationButton/types';

import styles from './styles.module.scss';

const NotificationButton: React.FC<NotificationButtonProps> = ({
  text,
  ariaText,
  hasUnread,
  onClick
}: NotificationButtonProps) => (
  <button
    className={styles.notificationButton}
    onClick={onClick}
    aria-label={ariaText}
    data-testid="headerNotificationButtonTest"
  >
    <div
      className={`${styles.notificationIconContainer} ${hasUnread ? styles.hasUnread : ''}`.trim()}
    >
      <span className="material-icons-outlined icon-24 icon--v-align-middle">notifications</span>
    </div>

    <Text tag="p" field={text} />
  </button>
);
export default NotificationButton;
