import * as React from 'react';
import { RichText, DateField, Text } from '@sitecore-jss/sitecore-jss-react';
import { AnnouncementProps } from 'Feature/DashboardComponents/components/Announcement/types';

import styles from './styles.module.scss';

const Announcement: React.FC<AnnouncementProps> = ({ locale, item }: AnnouncementProps) => (
  <div className={styles.announcementItem}>
    <Text tag="h3" field={item.fields.title} />
    <RichText className={styles.announcementContent} field={item.fields.description} />
    <DateField
      className={styles.timestamp}
      field={item.fields.dateTime}
      tag="time"
      render={(date: Date | null) =>
        date?.toLocaleDateString(locale, {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: 'numeric',
          minute: '2-digit'
        })
      }
    />
  </div>
);

export default Announcement;
