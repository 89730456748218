import React, { FC } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { ButtonProps } from './types';
import type { BaseButtonProps } from './types';
import styles from './styles.module.scss';

export const Button: FC<ButtonProps> = ({
  disabled,
  secondaryButton,
  contextualButton,
  textButton,
  text,
  ariaText,
  name,
  onClick,
  onMouseDown,
  responsive,
  type,
  icon,
  loading,
  className
}: ButtonProps) => {
  let classes: string = styles.primaryButton;

  if (secondaryButton) classes = styles.secondaryButton;
  if (contextualButton) classes = styles.contextualButton;
  if (textButton) classes = styles.textualButton;

  return (
    <button
      name={name}
      className={`${classes} ${responsive ? styles.responsive : ''} ${
        icon && !loading ? styles.withIcon : ''
      } ${className || ''}`.trim()}
      disabled={disabled}
      aria-label={ariaText?.value}
      onClick={onClick}
      onMouseDown={onMouseDown}
      data-testid={`${name}Test`}
      type={type}
    >
      {icon !== undefined && loading === undefined && (
        <span
          aria-hidden={true}
          className={`${styles.icon} ${contextualButton ? styles.contextualButtonIcon : ''}`}
        >
          {icon()}
        </span>
      )}
      {icon !== undefined && loading !== undefined && icon()}
      <span>
        <Text field={text} />
      </span>
    </button>
  );
};

/* Simplified Buttons */

export const BaseButton: FC<BaseButtonProps> = ({ className, children, ...buttonProps }) => {
  return (
    <button className={`${styles.buttonBase} ${className ?? ''}`.trim()} {...buttonProps}>
      {children}
    </button>
  );
};

export const PrimaryButton: FC<BaseButtonProps> = ({ className, ...props }) =>
  BaseButton({ ...props, className: `${styles.primaryButton} ${className ?? ''}`.trim() });

export const SecondaryButton: FC<BaseButtonProps> = ({ className, ...props }) =>
  BaseButton({ ...props, className: `${styles.secondaryButton} ${className ?? ''}`.trim() });

export const ContextualButton: FC<BaseButtonProps> = ({ className, ...props }) =>
  BaseButton({ ...props, className: `${styles.contextualButton} ${className ?? ''}`.trim() });
