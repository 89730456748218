import { useEffect, useState } from 'react';

import { ArrearsPermissions, StageIndicator } from '@hobt/arrears-domain';
import { ClaimColumnName, ClaimPermissions, InternalClaimStatus } from '@hobt/claim-domain';
import { ApprovalLevelIndicator, ModuleMapping } from '@hobt/constants';

import { ApiClient, ComparisonOperators } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { ApprovalsFinancialAuthPropsFields } from 'Feature/ApprovalsFinancialAuthority/models/ApprovalsFinancialAuthProps';
import { TabItemProps } from 'Components/Navigation/Tabs';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import {
  ApprovalsInventoryTabEnum,
  Status
} from 'Feature/ApprovalsFinancialAuthority/models/types';
import { config } from '../../../../config';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const useFinancialAuthFunctions = (fields: ApprovalsFinancialAuthPropsFields) => {
  const authenticationContext = useAuthenticationContext();
  const { getWithAuth } = ApiClient(authenticationContext, {
    timeout: config.claimApi.requestTimeout
  });

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;

  const arrearsPermission = isUserInRoles(
    ModuleMapping.arrears,
    ArrearsPermissions.ReadArrearsApprovals.roles,
    moduleRoleMapping
  );
  const claimsPermission = isUserInRoles(
    ModuleMapping.claim,
    ClaimPermissions.FinalClaim.ReadApprovals.roles,
    moduleRoleMapping
  );

  const finalClaimCountsUrl = config.claimApi.urls.getApprovalsCounts;
  const arrearsCountsUrl = config.arrearsApi.urls.getApprovalsCounts;

  const arrearsTabs: TabItemProps[] = arrearsPermission
    ? [
        {
          displayText: fields.arrearsInReviewTab,
          name: 'arrearsInReview',
          count: 0
        },
        {
          displayText: fields.arrearsApprovedTab,
          name: 'arrearsApproved',
          count: 0
        }
      ]
    : [];

  const claimsTabs: TabItemProps[] = claimsPermission
    ? [
        {
          displayText: fields.claimsInReviewTab,
          name: 'approvalRequired',
          count: 0
        },
        {
          displayText: fields.claimsApprovedTab,
          name: 'approved',
          count: 0
        }
      ]
    : [];

  const [tabItems, setTabItems] = useState<TabItemProps[]>(arrearsTabs.concat(claimsTabs));
  const [currTab, setCurrTab] = useState<TabItemProps>(tabItems[0]);
  const [approverLevel, setApproverLevel] = useState<number>(0);
  const [isArrears, setIsArrears] = useState<boolean>(false);
  const [isClaimApproved, setIsClaimApproved] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [approvalId, setApprovalId] = useState<string>('');
  const [isInventoryToast, setInventoryToast] = useState<boolean>(false);

  const getClaimTabCounts = async () => {
    const response = claimsPermission ? await getWithAuth(finalClaimCountsUrl) : Promise.reject();
    return response;
  };

  const getArrearsTabCounts = async () => {
    const response = arrearsPermission ? await getWithAuth(arrearsCountsUrl) : Promise.reject();
    return response;
  };

  const getTabCounts = async () => {
    const claimResponse = getClaimTabCounts();
    const arrearResponse = getArrearsTabCounts();

    const responseCollection = [claimResponse, arrearResponse];

    const responses = await Promise.allSettled(responseCollection).then((values) => values);
    const response = responses
      .map((responseData) =>
        responseData.status === Status.FULFILLED
          ? responseData.value?.data?.data ?? []
          : responseData.reason?.response?.data?.data ?? []
      )
      .flat();

    setTabItems((currentState: TabItemProps[]) => {
      return currentState.map((item: TabItemProps) => {
        const newCount = response?.find((counts: any) => counts.key === item.name);
        return {
          ...item,
          count: newCount?.count ?? 0
        };
      });
    });
  };

  useEffect(() => {
    getTabCounts();
  }, []);

  const queryLookup = {
    arrearsInReview: `StageIndicator ${ComparisonOperators.IN} (\
      ${StageIndicator.Primary},\
      ${StageIndicator.Secondary})`,

    arrearsApproved: `StageIndicator ${ComparisonOperators.EQ} ${StageIndicator.Approved}`,

    approvalRequired: `${ClaimColumnName.ClaimStatusCode} ${ComparisonOperators.EQ} ${InternalClaimStatus.ApprovalRequired}`,

    approved: `${ClaimColumnName.ClaimStatusCode} ${ComparisonOperators.IN} (\
      ${InternalClaimStatus.Paid},\
      ${InternalClaimStatus.ApprovedAuto},\
      ${InternalClaimStatus.PaymentFailed},\
      ${InternalClaimStatus.ApprovedManual},\
      ${InternalClaimStatus.NoClaimPayable},\
      ${InternalClaimStatus.CmhcReimbursed},\
      ${InternalClaimStatus.RefundRequested},\
      ${InternalClaimStatus.ClaimPaymentInProgress})`
  };

  // TODO: Handle API Error
  const onApiErrorCallback = () => setInventoryToast(true);

  const onToastCloseHandler = () => setInventoryToast(false);

  const onTabClick = (tab: TabItemProps): void => {
    if (tab.name === ApprovalsInventoryTabEnum.ClaimsApproved) {
      setIsClaimApproved(true);
    } else {
      setIsClaimApproved(false);
    }

    setCurrTab(tab);
    getTabCounts();
  };

  const onRowClick = (row?: any) => {
    setIsActive(!isActive);

    if (row != null) {
      setIsArrears(row.approvalID != null);
      setApproverLevel(row.claimID == null ? row.stageIndicator : ApprovalLevelIndicator.Primary);
      setApprovalId(row.claimID || row.approvalID);
      window.sessionStorage.setItem('arrearsApprovalData', JSON.stringify(row));
    }
  };

  return {
    currTab,
    isActive,
    isClaimApproved,
    tabItems,
    isArrears,
    approvalId,
    queryLookup,
    approverLevel,
    isInventoryToast,
    arrearsPermission,
    onTabClick,
    onRowClick,
    onApiErrorCallback,
    onToastCloseHandler
  };
};

export default useFinancialAuthFunctions;
