// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone';
import React, { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
// @ts-ignore -- `useController` exists but isn't in the exported types..
import { useFormContext, useController } from 'react-hook-form';

import FormInputWrapper, {
  Props as FormInputWrapperProps
} from 'Components/Inputs/FormInputWrapper';
import { format } from 'Constants/format';
import { checkDateFormat } from 'Constants/Utils/CheckDateFormat';
import convertUTCToLocalDate from 'Constants/Utils/ConvertUTCToLocalDate';
import get from 'Constants/Utils/GetPathInObject';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import FormText from '../FormText';
import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

type Props = {
  label: FormInputWrapperProps['label'];
  className: string;
  name: string;
  watch?: FormInputWrapperProps['watch'];
  isReadOnly?: boolean;
  tabIndex?: number;
  startYear?: string;
};
type LabelledByProps = Omit<Props, 'label' | 'className'> & {
  label: string;
  className?: string;
  defaultValue?: string; // For use in FieldArrays only
  isReadOnly?: boolean;
};

type TUseControllerProps = {
  field: {
    ref: any;
    name: string;
    onBlur: () => void;
    onChange: (...event: any[]) => void;
  };
};

// TODO: Datepicker isn't accessible.. Need to use its customInput prop. instead

const FormDatepicker: FC<Props> = ({
  name,
  label,
  className,
  watch,
  isReadOnly,
  tabIndex,
  startYear
}) => {
  const {
    formState: { errors },
    setValue,
    getValues,
    watch: watchValue,
    register
  } = useFormContext();
  const message = get(errors, `${name}.message`);
  const {
    field: { onBlur, onChange, ref, ...field }
  }: TUseControllerProps = useController({ name, defaultValue: getValues(name) });

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory.sitecoreContext as HbtSitecoreContextType;

  const value = watchValue(name);
  const min = startYear || '1000-01-01';

  const [triggerError, setTriggerError] = useState(false);

  // Init String Value
  useEffect(() => {
    if (moment(value, format.defaultDate).isValid() === true) {
      setValue(name, value);
    } else if (value != null) {
      setValue(name, null);
    }
  }, []);

  useEffect(() => {
    if (ref?.current?.input == null) return;
    // Set ARIA attributes
    [
      ['aria-errormessage', `${name}_error_message`],
      ['aria-invalid', Boolean(message)]
    ].forEach(([key, attr]) => ref.current.input.setAttribute(key, attr));
  }, [ref?.current?.input, name, message]);

  const handleDateChangeRaw = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const lastChar = e.target.value.slice(-1);
      if (/[a-zA-Z]/.test(e.target.value) || lastChar === ' ') {
        e.preventDefault();
        return;
      }
      if (triggerError) {
        setTriggerError(false);
      }
      if (e.target.value) {
        if (/^\d+$/.test(e.target.value) && e.target.value.length === 8) {
          const temp = `${e.target.value.slice(0, 4)}-${e.target.value.slice(4)}`;
          const formatedDate = `${temp.slice(0, 7)}-${temp.slice(7)}`;
          e.target.value = formatedDate;

          if (parseInt(formatedDate.slice(5, 6)) > 1 || parseInt(formatedDate.slice(8, 9)) > 3) {
            e.target.value = '';
            setTriggerError(true);
            return;
          }

          if (parseInt(formatedDate.slice(5, 6)) === 1 && parseInt(formatedDate.slice(6, 7)) >= 3) {
            e.target.value = '';
            setTriggerError(true);
            return;
          }

          if (parseInt(formatedDate.slice(8, 9)) === 3 && parseInt(formatedDate.slice(9, 10)) > 1) {
            e.target.value = '';
            setTriggerError(true);
            return;
          }
          setTriggerError(false);
          e.target.value = formatedDate;
        }

        if (e.target.value.length === 10) {
          if (
            parseInt(e.target.value.slice(5, 6)) > 1 ||
            parseInt(e.target.value.slice(8, 9)) > 3
          ) {
            e.target.value = '';
            setTriggerError(true);
          }
          if (
            parseInt(e.target.value.slice(5, 6)) === 1 &&
            parseInt(e.target.value.slice(6, 7)) >= 3
          ) {
            e.target.value = '';
            setTriggerError(true);
            return;
          }

          if (
            parseInt(e.target.value.slice(8, 9)) === 3 &&
            parseInt(e.target.value.slice(9, 10)) > 1
          ) {
            e.target.value = '';
            setTriggerError(true);
          }
        }
      }
    }
  };

  if (isReadOnly === true) {
    // Manually register as we won't bind to an element to satisfy accessibility requirements
    register(name);

    return (
      <FormInputWrapper
        name={name}
        watch={watch}
        label={label}
        className={`${className} ${styles['date-picker-wrapper']}${' readonly'}`}
      >
        <FormText
          name={field.name}
          label={field.name}
          className={''}
          isReadOnly={true}
          value={value ?? ''}
          dontRegister={true}
          tabIndex={tabIndex}
        />
      </FormInputWrapper>
    );
  }
  return (
    <FormInputWrapper
      name={name}
      watch={watch}
      label={label}
      className={`${className} ${styles['date-picker-wrapper']}`}
      formatError={triggerError}
    >
      <DatePicker
        locale={sitecoreContext?.language ?? 'en'}
        name={field.name}
        // ref={ref}
        className={styles['date-picker']}
        selected={
          value != null ? convertUTCToLocalDate(checkDateFormat(value, format.date)) : value
        }
        dateFormat={format.date}
        placeholderText={sitecoreContext?.language === 'en' ? 'YYYY-MM-DD' : 'AAAA-MM-JJ'}
        minDate={new Date(min)}
        maxDate={new Date('9999-12-31')}
        onChange={(date) => {
          // Set date to ISOString to be compatible with JSON schema date types
          if (triggerError) {
            setTriggerError(false);
          }
          const dt = date && (date as Date).toISOString().substring(0, 10);
          onChange(dt);
        }}
        onChangeRaw={handleDateChangeRaw}
        onBlur={onBlur}
        showYearDropdown
        dropdownMode="select"
        autoComplete="off"
      />
    </FormInputWrapper>
  );
};

const LabelledBy: FC<LabelledByProps> = ({
  name,
  label,
  className,
  watch,
  defaultValue,
  isReadOnly
}) => {
  const {
    formState: { errors },
    setValue,
    watch: watchValue,
    register
  } = useFormContext();
  const message = get(errors, `${name}.message`);
  const {
    field: { onBlur, onChange, ref, ...field }
  }: TUseControllerProps = useController({ name, defaultValue });
  const value = watchValue(name);

  // Init String Value
  useEffect(() => {
    if (moment(value, format.defaultDate).isValid() === true) {
      setValue(name, value);
    } else if (value != null) {
      setValue(name, null);
    }
  }, []);

  useEffect(() => {
    if (ref?.current?.input == null) return;
    // Set ARIA attributes
    [
      ['aria-errormessage', `${name}_error_message`],
      ['aria-invalid', Boolean(message)],
      ['aria-labelledby', label]
    ].forEach(([key, attr]) => ref.current.input.setAttribute(key, attr));
  }, [ref?.current?.input, name, message, label]);

  useEffect(() => {
    if (ref?.current?.input != null)
      ref.current.input[isReadOnly === true ? 'setAttribute' : 'removeAttribute']('readonly', '');
  }, [ref?.current?.input, isReadOnly]);

  if (isReadOnly === true) {
    // Manually register as we won't bind to an element to satisfy accessibility requirements
    register(name);

    return (
      <FormInputWrapper
        name={name}
        watch={watch}
        label={label}
        className={`${className} ${styles['date-picker-wrapper']}${' readonly'}`}
      >
        <FormText
          name={field.name}
          label={field.name}
          className={''}
          isReadOnly={true}
          value={value ?? ''}
          dontRegister={true}
        />
      </FormInputWrapper>
    );
  }
  return (
    <FormInputWrapper
      name={name}
      watch={watch}
      label={label}
      className={`${className} ${styles['date-picker-wrapper']}${' readonly'}`}
    >
      <DatePicker
        name={field.name}
        // ref={ref}
        className={styles['date-picker']}
        selected={
          value != null ? convertUTCToLocalDate(checkDateFormat(value, format.date)) : value
        }
        dateFormat={format.date}
        minDate={new Date('1000-01-01')}
        maxDate={new Date('9999-12-31')}
        onBlur={onBlur}
        onChange={(date) => {
          // Set date to ISOString to be compatible with JSON schema date types
          const dt = date && (date as Date).toISOString().substring(0, 10);
          onChange(dt);
        }}
        showYearDropdown
        dropdownMode="select"
        autoComplete="off"
      />
    </FormInputWrapper>
  );
};

export default FormDatepicker;
export { LabelledBy };
