import { HttpResponseStatusCodes } from '@hobt/constants';
import { AxiosResponse } from 'axios';

import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { config } from '../../../../config';

const useArrearsSummaryReportsClient = (authContext: any, apiClientConfig: ApiClientConfig) => {
  const reportDownload = async () => {
    const reportRoute: string = config.arrearsApi.urls.summaryReportDownload;

    try {
      const response: AxiosResponse = await ApiClient(authContext, apiClientConfig).getWithAuth(
        reportRoute
      );

      return response.data.statusCode === HttpResponseStatusCodes.OK && response.data.data[0];
    } catch (errors) {
      return null;
    }
  };

  return { reportDownload };
};

export default useArrearsSummaryReportsClient;
