import React, { useEffect, useState } from 'react';
import { useFeature } from 'flagged';
import { FormProvider } from 'react-hook-form';
import i18n from 'i18next';

import { ApprovalLevelIndicator, Module, ModuleMapping, UserRole } from '@hobt/constants';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import AssessmentCalculationsProps, {
  AssessmentCalculationsContextType,
  ToastErrorType
} from 'Feature/Claims/components/Details/AssessmentCalculations/types';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { ErrorNotifications } from 'Feature/Claims/components/Details/AssessmentCalculations/ErrorNotifications';
import { AssessmentFooter } from 'Feature/Claims/components/Details/AssessmentCalculations/AssessmentFooter';
import { ClaimAssessment } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment';
import {
  useHBTFormActionsContext,
  useHBTFormContext
} from 'Feature/Claims/components/HBTFormContext';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import ApprovalForm from 'Feature/ApprovalsFinancialAuthority/components/ApprovalForm';
import { Button } from 'Components/Common/Button';
import { AddOtherAdjustments } from 'Feature/Claims/components/Details/AssessmentCalculations/AddOtherAdjustments';
import { isButtonEnabled, ClaimButton } from '../isButtonEnabled';
import { useAssessmentCalculationsFunctions } from './useAssessmentCalculationsFunctions';

import styles from './styles.module.scss';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

export const AssessmentCalculationsContext = React.createContext<AssessmentCalculationsContextType>(
  {
    dmpErrors: [],
    errorNotification: ToastErrorType.NoError,
    isEditing: false,
    toggleAddOtherAdjustments: () => {},
    toggleIsEditing: () => {},
    resetErrorNotification: () => {}
  }
);

const AssessmentCalculations = ({ fields }: AssessmentCalculationsProps) => {
  const hbtFormContext = useHBTFormContext();
  const { disableEditMode } = useHBTFormActionsContext();

  const isInternalSite = useFeature(FeatureFlags.INTERNAL);
  const [displayFooter, setDisplayFooter] = useState<boolean>(true);
  const { isClaimStatusPostAdjudicationStatus, isMasterUser, isReadOnlyView } = hbtFormContext;

  const {
    save,
    approveBtn,
    sideDrawer,
    assessmentContext,
    addOtherAdjustmentsVisible,
    handleAutoDecision,
    handleDownloadClaimCalculationLetter,
    hookForm,
    previewCalculations,
    onAddOtherAdjustmentError,
    onSuccess,
    onSuccessToastDismiss,
    showSuccessToast
  } = useAssessmentCalculationsFunctions(fields, hbtFormContext);

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isBusinessUser = isUserInRoles(
    ModuleMapping.claim,
    [UserRole.BusinessUser],
    moduleRoleMapping
  );
  const autoDecisionButtonIsEnabled = isButtonEnabled(
    ClaimButton.AUTO_DECISION,
    hbtFormContext.claimData?.profile?.claimStatus?.code,
    sitecoreContext?.user?.moduleRoleMapping[Module.Claim]
  );
  const isMasterOrBusinessUser = isBusinessUser || isMasterUser;

  useEffect(() => {
    const finalClaim = hbtFormContext?.claimData;

    for (let i = 0; i < finalClaim?.borrowerCharges?.length; i += 1) {
      const item = `borrowerCharges.${i}`;

      hookForm.register(`${item}.code`);
      hookForm.register(`${item}.comment`);
      hookForm.register(`${item}.indexNumber`);
      hookForm.register(`${item}.previouslyApprovedAmount`);
      hookForm.register(`${item}.previouslyApprovedComment`);
      hookForm.register(`${item}.receivedAmount`);
      hookForm.register(`${item}.receivedDate`);
      hookForm.register(`${item}.originalClaimSequenceNumber`);
    }

    for (let j = 0; j < finalClaim?.cashReceipts?.length; j += 1) {
      const item = `cashReceipts.${j}`;
      hookForm.register(`${item}.indexNumber`);
      hookForm.register(`${item}.typeCode`);
      hookForm.register(`${item}.receivedAmount`);
      hookForm.register(`${item}.receivedDate`);
      hookForm.register(`${item}.previouslyApprovedAmount`);
      hookForm.register(`${item}.previouslyApprovedDate`);

      hookForm.setValue(`${item}.indexNumber`, j + 1);
    }

    hookForm.setValue('assessment', finalClaim.assessment);
    hookForm.setValue('borrowerCharges', finalClaim.borrowerCharges);
    hookForm.setValue('cashReceipts', finalClaim.cashReceipts);
  }, [hbtFormContext?.claimData]);

  return (
    <AssessmentCalculationsContext.Provider value={assessmentContext}>
      <ErrorNotifications />
      <ToastNotification
        type={ApplicationStates.SUCCESS}
        isActive={showSuccessToast}
        title={i18n.t('Globals-Toast-Success-Title')}
        content={{ value: i18n.t('Globals-Toast-Success-Item-Saved') }}
        onCloseCallback={onSuccessToastDismiss}
      />
      <div className={styles.assessmentCalculationsWrapper}>
        <FormProvider {...hookForm}>
          <form
            className={assessmentContext.isEditing ? styles.formWrapperIsEditing : ''}
            onSubmit={hookForm.handleSubmit(save)}
          >
            <ClaimAssessment
              setDisplay={setDisplayFooter}
              handleDownloadClaimCalculationLetterCallback={handleDownloadClaimCalculationLetter}
            />
            {isClaimStatusPostAdjudicationStatus === false && (
              <fieldset disabled={assessmentContext.isEditing || isReadOnlyView}>
                <div className={styles.decisioningButtonsFooter}>
                  <div className={styles.decisioningButtonsFooterScaffold}>
                    <Button
                      name="autoDecisionButton"
                      text={fields.autoDecisionBtn}
                      ariaText={fields.autoDecisionBtn}
                      onClick={handleAutoDecision}
                      type="button"
                      responsive
                      disabled={
                        isReadOnlyView ||
                        !autoDecisionButtonIsEnabled ||
                        !isMasterOrBusinessUser ||
                        isMasterUser ||
                        assessmentContext.isEditing
                      }
                    />
                    <Button
                      name="approveButton"
                      text={fields.approveBtn}
                      ariaText={fields.approveBtn}
                      onClick={approveBtn}
                      type="button"
                      disabled={
                        isReadOnlyView ||
                        !autoDecisionButtonIsEnabled ||
                        !isMasterOrBusinessUser ||
                        isMasterUser ||
                        assessmentContext.isEditing
                      }
                      responsive
                      secondaryButton
                    />
                  </div>
                </div>
              </fieldset>
            )}
            {displayFooter && (
              <fieldset className={`${styles.background} ${styles.assessmentFooterWrapper}`}>
                <AssessmentFooter
                  amountPayableLabel={fields.amountPayable}
                  previousClaimPaidLabel={fields.previousClaimPaid}
                  subTotalGrossClaimLabel={fields.subtotalOfGrossClaim}
                  subTotalGrossClaim={
                    hbtFormContext.claimData?.financialTransaction?.grossClaimPaymentAmount
                  }
                  amountPayable={
                    hbtFormContext.claimData?.financialTransaction?.currentClaimPaymentAmount
                  }
                  previousClaimPaid={
                    hbtFormContext.claimData?.financialTransaction?.previousClaimPaidAmount
                  }
                  previewCalculationsCallback={previewCalculations}
                  cancelCallback={() => {
                    hookForm.reset();
                    disableEditMode();
                    if (hbtFormContext.updateClaimData != null) {
                      hbtFormContext.updateClaimData();
                    }
                  }}
                  isReadOnlyView={isReadOnlyView}
                />
              </fieldset>
            )}
          </form>
        </FormProvider>

        <SideDrawer
          isActive={addOtherAdjustmentsVisible}
          handleOutsideClick={() => {
            assessmentContext.toggleAddOtherAdjustments();
          }}
        >
          <AddOtherAdjustments
            onSuccess={onSuccess}
            onCancel={() => {}}
            onError={onAddOtherAdjustmentError}
          />
        </SideDrawer>

        {/*
        pass the loading function and state as props because approval form is built to accept
        those as props. This is because approvalForm compoonent is used elsewhere where loadingStatus
        is not passed down as context but as props
      */}

        {isInternalSite && (
          <>
            {React.createElement(ApprovalForm, {
              approverLevel: ApprovalLevelIndicator.Primary,
              isActive: sideDrawer,
              setIsLoadingCallback: hbtFormContext.setLoadingStatus,
              sideMenuClickHandler: approveBtn,
              onSubmitCallback: hbtFormContext.updateClaimData,
              fields: fields.approvalFormFlyout.fields
            })}
          </>
        )}
      </div>
    </AssessmentCalculationsContext.Provider>
  );
};

export default AssessmentCalculations;
