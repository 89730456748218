export default interface SubmissionListProps {
  fields: SubmissionListPropsFields;
}

export interface SubmissionListPropsFields {
  location: FieldValue;
  pageTitle: FieldValue;
  lenderName: FieldValue;
  submittedBy: FieldValue;
  dateSubmitted: FieldValue;
  reportingPeriod: FieldValue;
  validationResult: FieldValue;
  validationResultErrorLabel: FieldValue;
  validationResultPassLabel: FieldValue;
}

// TODO: Map missing properties: lenderName, intitution #, location
export interface SubmissionListInventoryRecords {
  statusCode: number;
  lenderName?: string;
  submissionID: string;
  contactName: string;
  reportingPeriod: string;
  lenderFrenchName?: string;
  lenderEnglishName?: string;
  recordCreatedTimestamp: string;
}

// validation result
export enum StatusCode {
  Pass = 1,
  Error = 2
}

export enum SubmissionListFieldNames {
  Status = 'StatusCode'
}
