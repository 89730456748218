import { useFeature } from 'flagged';
import React, { useEffect } from 'react';
import i18n from 'i18next';
import { UserRole, Module } from '@hobt/constants';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useDefaultFormActionsContext } from 'Feature/DefaultsInventory/components/DefaultFormContext';

import { LinedCard, AccordionContainer } from '../../../../CommonComponents/AccordionComponents';
import { MonitoringStrategyProps } from './MonitoringStrategy.types';
import FormInputMultiLineText from '../../FormInputMultiLineText';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

export const MonitoringStrategy: React.FC<MonitoringStrategyProps> = ({
  register,
  errors,
  setValueHandler,
  control,
  watch,
  getValues,
  ...props
}) => {
  const reasonsCharacterLimitText: string = i18n
    .t('DefaultSubmission-Card-ReasonsCharacterLimitText')
    .replace('{textLimit}', props.maxLength.toString());

  const monitoringStrategy = watch && watch('managementInformation.monitoringStrategy');

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const defaultFormActions = useDefaultFormActionsContext();

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  useEffect(() => {
    if (defaultFormActions.isCardComplete('monitoringStrategy')) {
      if (!monitoringStrategy) {
        defaultFormActions?.removeCompleteCard('monitoringStrategy');
      }
    } else if (monitoringStrategy) {
      defaultFormActions?.addCompleteCard('monitoringStrategy');
    }
  }, [monitoringStrategy]);

  return (
    <LinedCard
      id={props.id}
      testId={props.testId}
      linePosition={props.linePosition}
      lineColor={props.accordionLineColor}
    >
      <AccordionContainer accordionId={`${props.id}Accordion`} title={props.title.field}>
        <div className="row card__body-row">
          <div className="form__element col-12">
            <FormInputMultiLineText
              id={`${props.id}Description`}
              name="managementInformation.monitoringStrategy"
              title={{
                value: i18n.t('DefaultSubmission-CardL-MonitoringStrategyTextAreaHeading')
              }}
              textAreaLimit={{
                value: reasonsCharacterLimitText
              }}
              hideLimit={props.hideLimit}
              maxLength={props.maxLength}
              errors={errors}
              register={register}
              getValues={getValues}
              limitCountText={`/ ${props.maxLength}`}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
      </AccordionContainer>
    </LinedCard>
  );
};
