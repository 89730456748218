import * as React from 'react';
import { PropsWithChildren } from 'react';
import { DataTableHeaderProps } from './types';
import styles from '../styles.module.scss';

export const DataTableHeader: React.FC<DataTableHeaderProps> = ({
  children
}: PropsWithChildren<DataTableHeaderProps>) => (
  <thead className={styles.thead}>
    <tr>{children}</tr>
  </thead>
);
