import { ImageProps } from '@sitecore-jss/sitecore-jss-react/types/components/Image';
import { LinkProps } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import { TextProps } from '@sitecore-jss/sitecore-jss-react/types/components/Text';
import { PortfolioInsuranceCardPropFields } from '../HbtDashboardComponent/types';

export interface HbtDashboardCardProps {
  cardId: string;
  fields: PortfolioInsuranceCardPropFields;
}

export interface HbtDashboardCardPropFields {
  cardTitle?: TextProps['field'];
  cardItem: FieldWrapper;
}

export interface FieldWrapper {
  icon: ImageProps['field'];
  navItem: LinkProps['field'];
}

export enum CardType {
  USER_CARD = 'user',
  PI_TOOLS = 'piTools',
  PI_RESOURCES = 'piResources'
}
