import React, { useContext } from 'react';
import i18n from 'i18next';
import { useFeature } from 'flagged';

import { FinalClaim } from '@hobt/claim-domain';
import { ModuleMapping, UserRole } from '@hobt/constants';

import { ActionButton } from 'Components/Common/ButtonDropdown/types';
import { Modal } from 'Components/Common/Modal';
import { ModalProps } from 'Components/Common/Modal/types';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';

import { SitecoreContentContext } from 'Feature/Claims/components/Details';
import { AdjudicationActionButtons } from 'Feature/Claims/components/Details/AdjudicationCardHeader/AdjudicationActionButtons';
import { AdjudicationAssignmentStatus } from 'Feature/Claims/components/Details/AdjudicationCardHeader/AdjudicationAssignmentStatus';
import { AdjudicationCardHeaderTitle } from 'Feature/Claims/components/Details/AdjudicationCardHeader/AdjudicationCardHeaderTitle';
import { isButtonEnabled, ClaimButton } from 'Feature/Claims/components/Details/isButtonEnabled';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

import { CancelClaimForm } from './CancelClaim';
import { ContestClaimForm } from './ContestClaim';
import { DeclineClaimForm } from './DeclineClaim/index';
import useAdjudicationCardHeader from './useAdjudicationCardHeader';
import useAdjudicationClaimDelete from './useAdjudicationClaimDelete';

import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export const AdjudicationCardHeader: React.FC = () => {
  const { fields } = useContext(SitecoreContentContext);
  const {
    claimData,
    isInEditMode,
    isReadOnlyView,
    isReadOnlyAccessUser,
    isMasterUser,
    loadingStatus,
    setLoadingStatus,
    updateClaimData
  } = useHBTFormContext();
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const businessUser = isUserInRoles(
    ModuleMapping.claim,
    [UserRole.BusinessUser],
    moduleRoleMapping
  );
  const masterAndBusinessUser = businessUser || isMasterUser;

  const {
    onAddDeclineClaimForm,
    onDeclineClaimCancelForm,
    onAddCancelClaimForm,
    onCancelClaimCancelForm,
    onCancelClaimSuccessSubmit,
    onCancelClaimErrorSubmit,
    showCancelClaimStatusToast,
    declineClaimShowSideDrawer,
    cancelClaimShowSideDrawer,
    showDeclineClaimStatusToast,
    closeCancelToast,
    closeDeclineToast,
    onDeclineClaimSuccessSubmit,
    onDeclineClaimErrorSubmit,
    showStatusToast,
    showSideDrawer,
    closeToast,
    onAddContestClaimForm,
    onSuccessSubmit,
    onCancelForm,
    onErrorSubmit
  } = useAdjudicationCardHeader();
  const {
    showPageModal,
    showDeleteClaimToast,
    isDeletingClaim,
    onDeleteClick,
    primaryCallback,
    secondaryCallback,
    setShowPageModal,
    closeDeleteClaimToast
  } = useAdjudicationClaimDelete();
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const pageModal: ModalProps = {
    type: ApplicationStates.SUCCESS,
    style: 'success',
    title: fields.deleteClaimModal?.fields.heading?.value || '',
    content: fields.deleteClaimModal?.fields.content?.value,
    isActive: false,
    onCloseCallback: () => {
      setShowPageModal(false);
    }
  };

  const internalActionItems: ActionButton[] = [
    {
      name: fields.cancelEllipsisBtn.value,
      text: fields.cancelEllipsisBtn,
      disable:
        isInEditMode ||
        !isButtonEnabled(
          ClaimButton.CANCEL_CLAIM,
          claimData?.profile?.claimStatus?.code,
          moduleRoleMapping[ModuleMapping.claim]
        ) ||
        isReadOnlyView,
      onClickCallback: () => {
        onAddCancelClaimForm();
      }
    }
  ];

  const externalActionItems: ActionButton[] = siteTypeIsInternal
    ? []
    : [
        {
          name: fields.contestClaimButton.value,
          text: fields.contestClaimButton,
          disable: !isButtonEnabled(
            ClaimButton.CONTEST_CLAIM,
            claimData?.profile?.claimStatus?.code,
            moduleRoleMapping[ModuleMapping.claim]
          ),
          onClickCallback: () => {
            onAddContestClaimForm();
          }
        },
        {
          name: fields.cancelEllipsisBtn.value,
          text: fields.cancelEllipsisBtn,
          disable: !isButtonEnabled(
            ClaimButton.CANCEL_CLAIM,
            claimData?.profile?.claimStatus?.code,
            moduleRoleMapping[ModuleMapping.claim]
          ),
          onClickCallback: () => {
            onAddCancelClaimForm();
          }
        }
      ];

  const emailLink = `mailto:${
    claimData?.lender?.authorizedOfficers[0].emailID
  }?subject=${fields?.emailToLenderSubject?.value
    ?.replace('{loanNumber}', claimData?.cmhcLoanAccountNumber)
    .replace('{referenceNumber}', claimData?.approvedLenderReferenceNumber)}`;

  const addEmailLink = () => {
    window.open(emailLink);
  };

  if (
    isUserInRoles(ModuleMapping.claim, [UserRole.MasterUser], moduleRoleMapping) &&
    siteTypeIsInternal
  ) {
    internalActionItems?.push({
      name: fields.deleteButton?.value,
      text: fields.deleteButton,
      disable: !isButtonEnabled(
        ClaimButton.DELETE,
        claimData?.profile?.claimStatus?.code,
        moduleRoleMapping[ModuleMapping.claim]
      ),
      onClickCallback: () => {
        onDeleteClick();
      }
    });
  }

  const dropdownActionItems: ActionButton[] = siteTypeIsInternal
    ? internalActionItems?.filter?.((item) => item?.disable === false)
    : externalActionItems?.filter?.((item) => item?.disable === false);

  const getToastNotificationTitle = () => {
    if (
      showStatusToast === ApplicationStates.SUCCESS ||
      showCancelClaimStatusToast === ApplicationStates.SUCCESS ||
      showDeclineClaimStatusToast === ApplicationStates.SUCCESS ||
      showDeleteClaimToast === ApplicationStates.SUCCESS
    ) {
      return 'Globals-Toast-Success-Title';
    }

    if (
      showStatusToast === ApplicationStates.ERROR ||
      showCancelClaimStatusToast === ApplicationStates.ERROR ||
      showDeclineClaimStatusToast === ApplicationStates.ERROR ||
      showDeleteClaimToast === ApplicationStates.ERROR
    ) {
      return 'Globals-Toast-Error-Title';
    }
    return '';
  };

  const getToastNotificationContent = () => {
    if (showCancelClaimStatusToast === ApplicationStates.SUCCESS) {
      return 'CancelClaim-SuccessToast-Cancel';
    }

    if (showDeclineClaimStatusToast === ApplicationStates.SUCCESS) {
      return 'DeclineClaim-SuccessToast-Decline';
    }

    if (showDeleteClaimToast === ApplicationStates.SUCCESS) {
      return 'DeleteClaim-SuccessToast-Delete';
    }

    if (showStatusToast === ApplicationStates.SUCCESS) {
      return 'Globals-Toast-Success-Item-Saved';
    }

    if (
      showDeclineClaimStatusToast === ApplicationStates.ERROR ||
      showCancelClaimStatusToast === ApplicationStates.ERROR ||
      showDeleteClaimToast === ApplicationStates.ERROR
    ) {
      return 'Globals-Toast-Error-Action-NotComplete';
    }

    if (showStatusToast === ApplicationStates.ERROR) {
      return 'ClaimsDetails-ErrorToast-Contest-Claim';
    }

    return '';
  };

  return (
    <>
      <ToastNotification
        type={showStatusToast}
        isActive={showStatusToast}
        title={i18n.t(getToastNotificationTitle())}
        content={{
          value: i18n.t(getToastNotificationContent())
        }}
        onCloseCallback={closeToast}
      />
      <ToastNotification
        type={showDeclineClaimStatusToast}
        isActive={showDeclineClaimStatusToast}
        title={i18n.t(getToastNotificationTitle())}
        content={{
          value: i18n.t(getToastNotificationContent())
        }}
        onCloseCallback={closeDeclineToast}
      />
      <ToastNotification
        type={showCancelClaimStatusToast}
        isActive={showCancelClaimStatusToast}
        title={i18n.t(getToastNotificationTitle())}
        content={{
          value: i18n.t(getToastNotificationContent())
        }}
        onCloseCallback={closeCancelToast}
      />
      <div
        className={`${styles.cardHeader} ${
          siteTypeIsInternal
            ? styles.claimDetailsCardHeaderInternal
            : styles.claimDetailsCardHeaderExternal
        }`.trim()}
      >
        <div className={styles.titleContainer}>
          <AdjudicationCardHeaderTitle
            preTitleLabel={fields.lenderReference}
            preTitle={claimData?.approvedLenderReferenceNumber}
            titleLabel={fields.cmhcLoan}
            title={claimData?.cmhcLoanAccountNumber}
          />
        </div>
        <div className={styles.actionButtonsContainer}>
          <AdjudicationActionButtons
            {...(siteTypeIsInternal
              ? {
                  primaryButton: {
                    name: 'emailButton',
                    text: fields.emailButton,
                    onClickCallback: () => {
                      addEmailLink();
                    }
                  },
                  primaryButtonDisabled: isReadOnlyAccessUser,
                  secondaryButton: {
                    name: 'declineButton',
                    text: fields.declineButton,
                    onClickCallback: () => {
                      onAddDeclineClaimForm();
                    }
                  },
                  secondaryButtonDisabled:
                    !isButtonEnabled(
                      ClaimButton.DECLINE_CLAIM,
                      claimData?.profile?.claimStatus?.code,
                      moduleRoleMapping[ModuleMapping.claim]
                    ) || !masterAndBusinessUser
                }
              : '')}
            actionDropdownItems={dropdownActionItems}
          />
        </div>
        <div className={styles.statusOwnerContainer}>
          <AdjudicationAssignmentStatus siteTypeIsInternal={!!siteTypeIsInternal} />
        </div>
      </div>
      {siteTypeIsInternal && (
        <fieldset className={`${styles.declineClaimSideDrawer}`}>
          <SideDrawer
            isActive={declineClaimShowSideDrawer}
            handleOutsideClick={onDeclineClaimCancelForm}
          >
            <DeclineClaimForm
              onCancelCallback={onDeclineClaimCancelForm}
              onSuccessCallback={(data: FinalClaim) => {
                onDeclineClaimSuccessSubmit();
                updateClaimData?.(data);
              }}
              onErrorCallback={onDeclineClaimErrorSubmit}
              fields={fields.declineClaimFlyout.fields}
            />
          </SideDrawer>
        </fieldset>
      )}
      {!siteTypeIsInternal && (
        <fieldset className={`${styles.sideDrawerContestClaim}`}>
          <SideDrawer isActive={showSideDrawer} handleOutsideClick={onCancelForm}>
            <ContestClaimForm
              onCancelCallback={onCancelForm}
              onSuccessCallback={() => {
                onSuccessSubmit();
              }}
              onErrorCallback={onErrorSubmit}
              fields={fields?.addContestClaimFlyout.fields}
            />
          </SideDrawer>
        </fieldset>
      )}
      <fieldset className={`${styles.sideDrawerCancelClaim}`}>
        <SideDrawer
          isActive={cancelClaimShowSideDrawer}
          handleOutsideClick={onCancelClaimCancelForm}
        >
          <CancelClaimForm
            onCancelCallback={onCancelClaimCancelForm}
            onSuccessCallback={(data: FinalClaim) => {
              onCancelClaimSuccessSubmit();
              updateClaimData?.(data);
            }}
            loadingStatus={loadingStatus}
            setIsLoadingCallback={setLoadingStatus}
            onErrorCallback={onCancelClaimErrorSubmit}
            fields={fields?.cancelClaimFlyout.fields}
          />
        </SideDrawer>
      </fieldset>
      <ToastNotification
        type={showDeleteClaimToast}
        isActive={showDeleteClaimToast}
        title={i18n.t(getToastNotificationTitle())}
        content={{
          value: i18n.t(getToastNotificationContent())
        }}
        onCloseCallback={closeDeleteClaimToast}
      />
      <Modal
        type={pageModal.type}
        style={pageModal.style}
        isActive={showPageModal}
        title={pageModal.title}
        onCloseCallback={() => setShowPageModal(false)}
        onPrimaryCallback={() => primaryCallback()}
        onSecondaryCallback={secondaryCallback}
        content={pageModal.content}
        fields={{
          saveButton: {
            value: fields.deleteClaimModal?.fields.acceptButton.value
          },
          cancelButton: { value: fields.deleteClaimModal?.fields.cancelButton.value }
        }}
        isLoading={isDeletingClaim}
      />
    </>
  );
};
