import React, { useEffect } from 'react';
import { CommentType } from '@hobt/claim-domain';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { AttachmentsView } from 'Components/Common/AttachmentsView';
import { Button } from 'Components/Common/Button';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormTextArea from 'Components/Inputs/FormTextArea';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useFeature } from 'flagged';
import i18n from 'i18next';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormProvider } from 'react-hook-form';
import { config } from '../../../../../../config';
import { useCorrespondenceForm } from '../useCorrespondenceForm';
import styles from './styles.module.scss';
import { AddAttachmentsFormProps, AllowedFileTypes } from './types';

const sectionId = 'ClaimsDetailsAddAttachment';

export const AddAttachmentsForm: React.FC<AddAttachmentsFormProps> = ({
  fields,
  onSuccessCallback,
  onErrorCallback,
  onCancelCallback
}: AddAttachmentsFormProps) => {
  const isExternal = useFeature(FeatureFlags.EXTERNAL);
  const { uuid: claimId, isReadOnlyAccessUser } = useHBTFormContext();

  const { hookForm, onSubmit, onCancel, onError, disableSubmitBtn } = useCorrespondenceForm(
    onSuccessCallback,
    onErrorCallback,
    onCancelCallback,
    claimId
  );

  useEffect(() => {
    if (isExternal) {
      hookForm.register('attachmentFlag');
      hookForm.setValue('attachmentFlag', true);
    }
    hookForm.setValue('commentTypeCode', CommentType[isExternal ? 'Comment' : 'Attachment']);
  }, []);

  return (
    <Scrollbars>
      <div className={styles.headerWrapper}>
        <Text tag="h2" field={fields.formTitle} />
        <div className={styles.closeButton}>
          <button
            type="button"
            data-testid="addAttachmentsFormCloseButton"
            onClick={onCancel}
            aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
          >
            <span className="material-icons align-self-center">close</span>
          </button>
        </div>
      </div>

      <FormProvider {...hookForm}>
        <form
          id={`${sectionId}Form`}
          className={styles.documentRequestWrapper}
          onSubmit={hookForm.handleSubmit(onSubmit, onError)}
          noValidate
        >
          <p className={styles.textWrapper}>
            <Text field={fields.content} />
          </p>

          <div className={styles.categoriesWrapper}>
            <FormDropdown
              className=""
              name="adjudicationAttachmentCategoryCode"
              label={fields.categoryLabel}
              options={fields.categoryList?.fields?.listItems}
            />
          </div>

          <div className={styles.templateWrapper}>
            <FormTextArea
              name="remarkText"
              className={styles.templateTextArea}
              label={fields.description}
              textAreaHelperText={fields.characterLimit.value}
              charLimit={parseInt(fields.limitNumber.value || '0', 10)}
            />
          </div>

          {React.createElement(AttachmentsView, {
            registeredVal: 'attachments',
            isAllowed: !isReadOnlyAccessUser,
            fields: fields.attachmentsView.fields,
            endpoint: config.claimApi.urls.upload,
            allowedFileTypes: AllowedFileTypes
          })}

          <div className={styles.buttonWrapper}>
            <Button
              loading
              responsive
              type="submit"
              name={'submitButton'}
              text={fields.submitBtn}
              ariaText={fields.submitBtn}
              disabled={disableSubmitBtn}
            />
            <Button
              responsive
              type="button"
              secondaryButton
              name={`cancelButton`}
              onClick={onCancel}
              text={fields.cancelBtn}
              ariaText={fields.cancelBtn}
            />
          </div>
        </form>
      </FormProvider>
    </Scrollbars>
  );
};
