import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { ClickAwayListener, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import { Tooltip } from 'Components/Core/Tooltip';
import { TooltipPosition } from 'Components/Core/Tooltip/types';
import useEnterOrSpaceKey from 'Components/Hooks/UseEnterOrSpaceKey';
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { BreadcrumbDropdownProps, BreadcrumbItem } from './type';

const BreadcrumbDropdown: React.FC<BreadcrumbDropdownProps> = ({ items }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const handleToggle = useCallback(() => {
    setDropdownVisible(!dropdownVisible);
  }, [dropdownVisible]);

  const handleMenuItemClick = (item: BreadcrumbItem) => {
    history.push(item.href);
  };

  const handleMouseDown = useCallback(() => {
    setIsActive(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsActive(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsActive(false);
  }, []);

  const ellipsisStyles = useMemo(
    () => `${styles.overflowEllipses} ${isActive ? styles.active : ''}`,
    [isActive]
  );

  return (
    <Tooltip
      label="More pages"
      position={TooltipPosition.BOTTOM_RIGHT}
      iconSize={HbtIconSize.X_LARGE}
      customStyles={{ top: '70px', left: '3px' }}
    >
      <ClickAwayListener onClickAway={() => setDropdownVisible(false)}>
        <div style={{ padding: 0, margin: 0 }}>
          <span
            role="button"
            className={ellipsisStyles}
            tabIndex={0}
            ref={anchorRef}
            data-testid="breadcrumb-ellipses"
            aria-label={i18n.t('BreadcrumbEllipsisLabel') ?? 'See more options'}
            onClick={() => setDropdownVisible(!dropdownVisible)}
            onKeyDown={useEnterOrSpaceKey(handleToggle)}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onBlur={handleBlur}
          />
          <Popper
            open={dropdownVisible}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            className={styles.dropdownMenu}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [152, 7]
                }
              }
            ]}
            data-testid="breadcrumb-menu-list"
          >
            <Paper className={styles.menuFrame}>
              <MenuList className={styles.menuList}>
                {items.map((item, index) => (
                  <MenuItem
                    className={styles.menuItem}
                    key={index}
                    data-testid="breadcrumb-menu-item"
                    onClick={() => handleMenuItemClick(item)}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>
    </Tooltip>
  );
};

export default memo(BreadcrumbDropdown);
