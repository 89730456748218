import React, { ChangeEvent, useEffect, useState } from 'react';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { AxiosError, AxiosResponse } from 'axios';
import SitecoreListItem, { SitecoreDropdownList } from 'Constants/Types/SitecoreListItem';
import { Modal, ModalProps, ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { Column, TableRowData } from 'Feature/CommonComponents/DataTable';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { DefaultApiCallHandler } from 'Feature/DefaultsInventory/components/api/ApiCallHandlers';
import { DefaultModalForm } from 'Feature/DefaultsInventory/components/DefaultModalForm/DefaultModalForm';
import { InventoryRowMapper } from 'Feature/DefaultsInventory/components/DefaultsInventoryTable/InventorySubmissionRow.mapper';
import InventoryTablePresentationExternal from 'Feature/DefaultsInventory/components/DefaultsInventoryTable/InventoryTableExternal.presentation';
import DefaultsInventoryTableProps, {
  DefaultInventoryCounts
} from 'Feature/DefaultsInventory/models/DefaultsInventoryTableProps';
import { FormDropdownOption } from 'Feature/DefaultSubmissionForm/components/FormInputDropdownText/FormInputDropdownText.types';
import { LanguageSwitchTypeCode } from 'Feature/DefaultSubmissionForm/models/typeCode.types';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useFeature } from 'flagged';
import {
  ApiClient,
  ApiClientConfig,
  ComparisonOperators,
  DefaultRequestStatus,
  FilterInternalColumnNames,
  InventoryCountItem,
  InventorySubmissionItem,
  LogicalOperators,
  Order,
  QueryParams,
  FilterExternalColumnNames,
  InternalColumnNames
} from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { config } from '../../../../config';
import {
  DecisioningFormModalData,
  SubmissionDecisionResponse,
  SubmissionDecisionResponseData
} from '../api/SubmissionDecisionPayload.type';
import InventoryTablePresentation from './InventoryTable.presentation';

const DefaultInventoryTable: React.FC<any> = (props: DefaultsInventoryTableProps) => {
  const cancelOptions: FormDropdownOption[] = [];
  props.fields.cancelReasons.forEach((reasons: SitecoreDropdownList) => {
    reasons.fields.listItems.forEach((reason: SitecoreListItem) => {
      cancelOptions.push({
        label: reason.fields.itemName.value ?? '',
        value: reason.fields.itemValue.value ?? ''
      });
    });
  });

  const history = useHistory();

  const siteType = useFeature(FeatureFlags.INTERNAL);

  let initialTab = DefaultRequestStatus[DefaultRequestStatus.Unassigned] || 'Unassigned';

  if (isServer() === false) {
    initialTab =
      window.localStorage.getItem('DefaultsInventoryTab') || (siteType ? 'Unassigned' : 'all');

    window.localStorage.removeItem('DefaultsInventoryTab'); // remove from localStorage after use
  }

  const numFilesPerPageOptions: FormDropdownOption[] = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
  ];

  // Auth Context
  const authenticationContext = useAuthenticationContext();

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext;

  // Sort by column
  const [activeSortColumn, setActiveSortColumn] = useState<string | undefined>();
  const [activeSortDirection, setActiveSortDirection] = useState<Order>(Order.Descending);

  // Tabs
  const [activeFilterTab, setActiveFilterTab] = useState<DefaultRequestStatus | undefined>(
    initialTab === 'all' ? undefined : DefaultRequestStatus.Unassigned
  );
  const [defaultTab] = useState<string>(
    initialTab === 'all' ? 'all' : DefaultRequestStatus[DefaultRequestStatus.Unassigned]
  );
  // Is Loading
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Pager
  const [pagerRangeStart, setPagerRangeStart] = useState<number>(1);
  const [pagerRangeEnd, setPagerRangeEnd] = useState<number>(10);
  const [pagerTotalItems, setPagerTotalItems] = useState<number>(0);
  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // Number of submissions to display per page
  const [numFilesPerPage, setNumFilesPerPage] = useState<number>(20);

  // Row Data
  const [tableHeaders, setTableHeaders] = useState<Column[]>();
  const [rows, setRows] = useState<TableRowData[]>([]);
  const [selectedRows, setSelectedRows] = useState<TableRowData[]>([]);
  const [rowsData, setRowsData] = useState([]);
  const [tabCounts, setTabsCounts] = useState<DefaultInventoryCounts>();
  const [rowIdForRequest, setRowIdForRequest] = useState<string>('');

  // Toast Notifications
  const [showToastNotification, setShowToastNotification] = useState<boolean>(false);
  const [toastNotificationTitle, setToastNotificationTitle] = useState<string>(
    i18n.t('DefaultsInventoryTable-SuccessToastMessage-Title')
  );
  const [toastNotificationMsg, setToastNotificationMsg] = useState<string>(
    i18n.t('DefaultsInventoryTable-SuccessToastMessage-Message')
  );
  const [toastNotificationType, setToastNotificationType] = useState<ApplicationStates>(
    ApplicationStates.DEFAULT
  );

  // Button States
  const [showOwnInventory, setShowOwnInventory] = useState<boolean>(false);
  const [isCancelRequestButtonDisabled, setIsCancelRequestButtonDisabled] = useState<boolean>(true);
  const [isInactivateRequestButtonDisabled, setIsInactivateRequestButtonDisabled] =
    useState<boolean>(true);

  // Table Caption
  const [dataTableCaption, setDataTableCaption] = useState<string>('');

  // Modal
  const [showPageModal, setShowPageModal] = useState<boolean>(false);
  const [pageModal, setPageModal] = useState<ModalProps>({
    type: ApplicationStates.DEFAULT,
    title: '',
    isActive: false,
    onCloseCallback: () => {}
  });

  // API Client Config
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  // Api Client
  const { getWithAuth, cancelTokenSource } = ApiClient(authenticationContext, apiClientConfig);

  const showServerErrorNotification = () => {
    setToastNotificationTitle(i18n.t('DefaultsInventoryTable-SystemErrorToast-Title'));
    setToastNotificationMsg(i18n.t('DefaultsInventoryTable-SystemErrorToast-Message'));
    setToastNotificationType(ApplicationStates.ERROR);
    setShowToastNotification(true);
  };

  const appendToFilterBy = (currentFilterBy: string | null, newFilter: string): string => {
    if (currentFilterBy === null) {
      return `${QueryParams.FilterBy}=${newFilter}`;
    }

    return `${currentFilterBy} ${LogicalOperators.AND} ${newFilter}`;
  };

  const getResourceUrl = (): string => {
    const tabQueries = {
      // Unassigned is used only in the internal site
      [DefaultRequestStatus.Unassigned]: `${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${DefaultRequestStatus.PendingCmhcReview}
            ${LogicalOperators.AND}
                ValidLoan ${ComparisonOperators.EQ} "1"`,
      [DefaultRequestStatus.Approved]: `(${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${DefaultRequestStatus.Approved}
            ${LogicalOperators.OR}
                ${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${DefaultRequestStatus.ApprovedWithModifications}
            ) ${LogicalOperators.AND}
                ValidLoan ${ComparisonOperators.EQ} "1"`,
      [DefaultRequestStatus.Declined]: `${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${DefaultRequestStatus.Declined}
            ${LogicalOperators.AND} ValidLoan ${ComparisonOperators.EQ} "1"`,
      [DefaultRequestStatus.Cancelled]: `${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${DefaultRequestStatus.Cancelled}`,
      [DefaultRequestStatus.InReview]: `(${FilterInternalColumnNames.DefaultStatus} ${
        ComparisonOperators.EQ
      } ${DefaultRequestStatus.InReview}${
        !siteType
          ? ` ${LogicalOperators.OR} ${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${DefaultRequestStatus.PendingCmhcReview}`
          : ''
      })
            ${siteType ? ` ${LogicalOperators.AND} ValidLoan ${ComparisonOperators.EQ} "1"` : ''}`,
      [DefaultRequestStatus.IncompleteSubmission]: `(${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${DefaultRequestStatus.PendingCmhcReview}
            ${LogicalOperators.OR}
                ${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${DefaultRequestStatus.InReview}
            ) ${LogicalOperators.AND}
                ValidLoan ${ComparisonOperators.EQ} "0"`, // TODO: Update common api to include this value
      default: `${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ}`
    };

    if (config.defaultApi.urls.inventory) {
      const baseUrl = config.defaultApi.urls.inventory;
      let filterBy = null;
      let sortBy = null;
      let offset = null;
      const limit = `${QueryParams.Limit}=${numFilesPerPage}`;

      if (activeFilterTab !== undefined) {
        const newFilter =
          activeFilterTab === DefaultRequestStatus.Unassigned ||
          activeFilterTab === DefaultRequestStatus.Approved ||
          activeFilterTab === DefaultRequestStatus.Declined ||
          activeFilterTab === DefaultRequestStatus.Cancelled ||
          activeFilterTab === DefaultRequestStatus.InReview ||
          activeFilterTab === DefaultRequestStatus.IncompleteSubmission
            ? tabQueries[activeFilterTab]
            : `${tabQueries.default} ${activeFilterTab}`;

        filterBy = appendToFilterBy(filterBy, newFilter);
      } else if (siteType) {
        // Gets triggered if the user clicks the "All" tab
        filterBy = appendToFilterBy(
          filterBy,
          `(ValidLoan ${ComparisonOperators.EQ} "1" ${LogicalOperators.OR} (ValidLoan ${ComparisonOperators.EQ} "0" ${LogicalOperators.AND} (${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${DefaultRequestStatus.Cancelled} ${LogicalOperators.OR} ${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${DefaultRequestStatus.Inactive})))`
        );
      }

      if (showOwnInventory && activeFilterTab !== DefaultRequestStatus.Unassigned) {
        const newFilter = `${FilterInternalColumnNames.CmhcOwnerID} ${ComparisonOperators.EQ} '${
          sitecoreContext.userID || ''
        }'`;

        filterBy = appendToFilterBy(filterBy, newFilter);
      }

      if (activeSortColumn !== undefined) {
        sortBy = `${QueryParams.OrderBy}=${activeSortColumn} ${activeSortDirection.toUpperCase()}`;
      }

      if (currentPage >= 1) {
        offset = `${QueryParams.Offset}=${numFilesPerPage * (currentPage - 1)}`;
      }

      return baseUrl.concat('?', [filterBy, sortBy, offset, limit].filter(Boolean).join('&'));
    }
    throw Error('Could not find Resource URL or Resource Path');
  };

  // TODO: update api response type
  const handlePaginationResponse = (apiResponse: any) => {
    const offset: number =
      typeof apiResponse.offset === 'number' ? apiResponse.offset : Number(apiResponse.offset);
    const limit: number =
      typeof apiResponse.limit === 'number' ? apiResponse.limit : Number(apiResponse.limit);
    const totalRecordCount: number =
      typeof apiResponse.totalRecordCount === 'number'
        ? apiResponse.totalRecordCount
        : Number(apiResponse.totalRecordCount);
    const proposedEndRange: number = offset + limit;

    setNumPages(Math.ceil(apiResponse.totalRecordCount / apiResponse.limit));
    setPagerRangeStart(offset + 1);
    setPagerRangeEnd(proposedEndRange >= totalRecordCount ? totalRecordCount : proposedEndRange);
    setPagerTotalItems(totalRecordCount);
  };

  const getRowsRaw = async (url: string) => {
    setIsLoading(true);

    getWithAuth(url)
      .then((res: AxiosResponse) => {
        const { data, page } = res.data;

        handlePaginationResponse(page);

        const updatedRowData: any = {};
        const updatedRows: TableRowData[] = data.map((row: InventorySubmissionItem) => {
          // @ts-ignore
          updatedRowData[row.cmhcDefaultAccountID] = row.defaultStatus;

          if (siteType) {
            return InventoryRowMapper().getRow(row);
          }
          return InventoryRowMapper().getRowExternal(row);
        });

        setRowsData(updatedRowData);
        setRows(updatedRows);
        setIsLoading(false);
      })
      .catch(() => showServerErrorNotification());
  };

  // Gets Data Rows from API
  const getRows = async () => {
    try {
      getRowsRaw(getResourceUrl());
    } catch (e) {
      showServerErrorNotification();
    }
  };

  const parseAndSetTabCounts = (inventoryTabCounts: InventoryCountItem[]) => {
    const tabCountsDto: DefaultInventoryCounts = {
      allCount: 0,
      statusCounts: []
    };

    inventoryTabCounts.forEach((tc: InventoryCountItem) => {
      if (tc.key === 'total') {
        if (!siteType) {
          tabCountsDto.allCount = tc.count;
        }
      } else {
        const statusNumber = Number(tc.query.split(' = ')[1]);
        switch (tc.query) {
          case `(${FilterInternalColumnNames.DefaultStatus} = ${DefaultRequestStatus.Approved} OR ${FilterInternalColumnNames.DefaultStatus} = ${DefaultRequestStatus.ApprovedWithModifications}) AND ValidLoan = '1'`:
            tabCountsDto.statusCounts[DefaultRequestStatus.Approved] = tc.count;
            break;

          case `${FilterInternalColumnNames.DefaultStatus} = ${DefaultRequestStatus.Declined} AND ValidLoan = '1'`:
            tabCountsDto.statusCounts[DefaultRequestStatus.Declined] = tc.count;
            break;

          // Used only in the internal site
          case `${FilterInternalColumnNames.DefaultStatus} = ${DefaultRequestStatus.PendingCmhcReview} AND ValidLoan = '1'`:
            tabCountsDto.statusCounts[DefaultRequestStatus.Unassigned] = tc.count;
            break;

          case `(${FilterInternalColumnNames.DefaultStatus} = ${DefaultRequestStatus.InReview}${
            !siteType
              ? ` OR ${FilterInternalColumnNames.DefaultStatus} = ${DefaultRequestStatus.PendingCmhcReview}`
              : ''
          })${siteType ? ` AND ValidLoan = '1'` : ''}`:
            tabCountsDto.statusCounts[DefaultRequestStatus.InReview] = tc.count;
            break;

          case `${FilterInternalColumnNames.DefaultStatus} = ${DefaultRequestStatus.PendingCmhcReview} AND (${FilterInternalColumnNames.CmhcOwnerID} = '' OR ${FilterInternalColumnNames.CmhcOwnerID} IS NULL)`:
            tabCountsDto.statusCounts[DefaultRequestStatus.Unassigned] = tc.count;
            break;

          // TODO: Update common pkg to include ValidLoan in FilterInternalColumn
          case `(${FilterInternalColumnNames.DefaultStatus} = ${DefaultRequestStatus.PendingCmhcReview} OR ${FilterInternalColumnNames.DefaultStatus} = ${DefaultRequestStatus.InReview}) AND ValidLoan = '0'`:
            tabCountsDto.statusCounts[DefaultRequestStatus.IncompleteSubmission] = tc.count;
            break;

          case `ValidLoan = '1' OR (ValidLoan = '0' AND (${FilterInternalColumnNames.DefaultStatus} = ${DefaultRequestStatus.Cancelled} OR ${FilterInternalColumnNames.DefaultStatus} = ${DefaultRequestStatus.Inactive}))`:
            if (siteType) {
              tabCountsDto.allCount = tc.count;
            }
            break;

          default:
            tabCountsDto.statusCounts[statusNumber] = tc.count;
        }
      }
    });

    setTabsCounts(tabCountsDto);
  };

  // Get Counts from API
  const getTabCounts = async () => {
    const resourceUrl = `${config.defaultApi.urls.counts}?${QueryParams.FilterBy}={(${
      FilterInternalColumnNames.DefaultStatus
    } ${ComparisonOperators.EQ} ${DefaultRequestStatus.Approved} ${LogicalOperators.OR} ${
      FilterInternalColumnNames.DefaultStatus
    } ${ComparisonOperators.EQ} ${DefaultRequestStatus.ApprovedWithModifications}) ${
      LogicalOperators.AND
    } ValidLoan ${ComparisonOperators.EQ} "1"},
      {${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${
      DefaultRequestStatus.Declined
    } ${LogicalOperators.AND} ValidLoan ${ComparisonOperators.EQ} "1"},
      ${
        siteType
          ? `{${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${DefaultRequestStatus.PendingCmhcReview} ${LogicalOperators.AND} ValidLoan ${ComparisonOperators.EQ} "1"},`
          : ''
      }
      {${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${
      DefaultRequestStatus.Cancelled
    }},

      {(${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${
      DefaultRequestStatus.InReview
    }${
      !siteType
        ? ` ${LogicalOperators.OR} ${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${DefaultRequestStatus.PendingCmhcReview}`
        : ''
    })${siteType ? ` ${LogicalOperators.AND} ValidLoan ${ComparisonOperators.EQ} "1"` : ''}},

      {ValidLoan ${ComparisonOperators.EQ} "1" ${LogicalOperators.OR} (ValidLoan ${
      ComparisonOperators.EQ
    } "0" ${LogicalOperators.AND} (${FilterInternalColumnNames.DefaultStatus} ${
      ComparisonOperators.EQ
    } ${DefaultRequestStatus.Cancelled} ${LogicalOperators.OR} ${
      FilterInternalColumnNames.DefaultStatus
    } ${ComparisonOperators.EQ} ${DefaultRequestStatus.Inactive}))},

      {(${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${
      DefaultRequestStatus.PendingCmhcReview
    } ${LogicalOperators.OR} ${FilterInternalColumnNames.DefaultStatus} ${ComparisonOperators.EQ} ${
      DefaultRequestStatus.InReview
    }) ${LogicalOperators.AND} ValidLoan ${ComparisonOperators.EQ} "0"}`;

    getWithAuth(resourceUrl.replace(/\s\s+/g, ' ') || '')
      .then((res: AxiosResponse) => {
        parseAndSetTabCounts(res.data.data);
      })
      .catch((_e: AxiosError) => {
        // TODO: Implement Error Handling for counts
      });
  };

  // Filter bar/tabs Event Handlers
  const handleOnTabClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    let status: string = e.currentTarget.getAttribute('data-tab-name') || '';

    status = status.charAt(0).toUpperCase() + status.slice(1);

    if (status) {
      setActiveFilterTab(DefaultRequestStatus[status as keyof typeof DefaultRequestStatus]);
    }
  };

  // Update Table Headers on Active Filter Change (Use effect below)
  const updateTableHeaders = () => {
    // Update table header sort by application status
    if (tableHeaders) {
      const idx: number = tableHeaders?.findIndex(
        (c) => c.fieldName === FilterInternalColumnNames.DefaultStatus
      );

      if (idx) {
        // Reset Sort on Header
        const updatedTableHeaders = tableHeaders.map((tableHeader: Column) => ({
          ...tableHeader,
          isSortActive: false,
          sortDirection: Order.Descending
        }));
        updatedTableHeaders[idx].isSortable =
          activeFilterTab === undefined ||
          activeFilterTab === DefaultRequestStatus.IncompleteSubmission;

        setTableHeaders(updatedTableHeaders);
        setActiveSortColumn((prev: string | undefined) =>
          activeSortColumn === FilterInternalColumnNames.DefaultStatus ? undefined : prev
        );
        setActiveSortDirection((prev: Order) =>
          activeSortColumn === FilterInternalColumnNames.DefaultStatus ? Order.Descending : prev
        );
      }
    }
  };

  // Header Sort and All Click Event Handlers
  const handleOnHeaderSort = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const sortField: string | null = e.currentTarget.getAttribute('data-fieldname');

    if (tableHeaders && sortField) {
      const idx: number = tableHeaders?.findIndex((c) => c.fieldName === sortField);

      const updatedTableHeaders: Column[] = tableHeaders.map((col) => ({
        ...col,
        isSortActive: false,
        sortDirection: Order.Descending
      }));

      updatedTableHeaders[idx].isSortActive = true;
      updatedTableHeaders[idx].sortDirection =
        updatedTableHeaders[idx].fieldName === activeSortColumn &&
        activeSortDirection === Order.Descending
          ? Order.Ascending
          : Order.Descending;

      setActiveSortDirection(updatedTableHeaders[idx].sortDirection);
      setActiveSortColumn(sortField);
      setTableHeaders(updatedTableHeaders);
    }
  };

  // Table Rows Event Handlers
  const handleOnRowSelect = (e: React.MouseEvent<HTMLInputElement>) => {
    const rowId: string = e.currentTarget.getAttribute('data-row-id') || '';
    let shouldReEvalButtonStates = false;
    let numberRowsSelected = selectedRows.length;

    const updatedRows = rows.map((row) => {
      if ((typeof row.uuid === 'number' ? row.uuid.toString() : row.uuid) === rowId) {
        if (!row.isSelected) {
          setRowIdForRequest(rowId);
          numberRowsSelected += 1;
        } else {
          numberRowsSelected -= 1;
        }
        shouldReEvalButtonStates = true;
        return { ...row, isSelected: !row.isSelected };
      }
      return row;
    });
    const updatedSelectedRows = updatedRows.filter((row) => row.isSelected);

    if (shouldReEvalButtonStates) {
      setIsCancelRequestButtonDisabled(true);
      setIsInactivateRequestButtonDisabled(true);

      const rowsThatDisablesBoth = updatedSelectedRows.filter(
        (row) => rowsData[row.uuid as number] === DefaultRequestStatus.Cancelled
      );

      const rowsThatDisablesInactivateRequest = updatedSelectedRows.filter(
        (row) => rowsData[row.uuid as number] === DefaultRequestStatus.Inactive
      );

      setIsCancelRequestButtonDisabled(
        rowsThatDisablesBoth.length > 0 ||
          updatedSelectedRows.length === 0 ||
          numberRowsSelected !== 1
      );
      setIsInactivateRequestButtonDisabled(
        rowsThatDisablesInactivateRequest.length > 0 ||
          rowsThatDisablesBoth.length > 0 ||
          updatedSelectedRows.length === 0 ||
          numberRowsSelected === 0
      );

      shouldReEvalButtonStates = false;
    }

    // Update States
    setRows(updatedRows);
    setSelectedRows(updatedSelectedRows);
  };

  const navigateToDetails = (
    e: React.KeyboardEvent<HTMLTableRowElement> | React.MouseEvent<HTMLTableRowElement>
  ) => {
    const recordUuid: string | null = e.currentTarget.getAttribute('data-row-id');

    if (recordUuid) {
      history.push(`/${i18n.language}/default-inventory/details?uuid=${recordUuid}`);
    }
  };

  const handleOnRowKeyDown = (e: React.KeyboardEvent<HTMLTableRowElement>) => {
    if (e.key === 'Enter') {
      navigateToDetails(e);
    }
  };

  const handleOnRowClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
    navigateToDetails(e);
  };

  const handleClosePageModal = () => {
    setShowPageModal(false);
  };

  const resetInventoryPageToDefault = () => {
    setRows([]);
    getTabCounts();
    setSelectedRows([]);
    setRowIdForRequest('');
    setIsCancelRequestButtonDisabled(true);
    setIsInactivateRequestButtonDisabled(true);
  };

  const {
    updateSubmissionStatus,
    inactivateRequestMulti,
    cancelTokenSource: defaultApiCallsCancelToken
  } = DefaultApiCallHandler(rowIdForRequest, authenticationContext, apiClientConfig);

  const handleStatusUpdateResponseSuccess = (newStatus: DefaultRequestStatus) => {
    const toastMsgToShow: { [key: number]: string } = {
      [DefaultRequestStatus.Cancelled]:
        'DefaultsInventoryTable-DecisioningToastMessage-Cancel-Message',
      [DefaultRequestStatus.Inactive]:
        'DefaultsInventoryTable-DecisioningToastMessage-Inactive-Message'
    };

    if (!isServer()) {
      window.localStorage.setItem(
        'DefaultsInventoryDecisioningToastMsg',
        toastMsgToShow[newStatus]
      );
      // Set tab once on default-inventory
      window.localStorage.setItem('DefaultsInventoryTab', 'all');

      // show toast and reset page
      getRows();
      setShowToastNotification(true);
      if (localStorage.getItem('DefaultsInventoryDecisioningToastMsg')) {
        const decisioningToastMessage = localStorage.getItem(
          'DefaultsInventoryDecisioningToastMsg'
        );
        setToastNotificationTitle(i18n.t('DefaultsInventoryTable-DecisioningToastMessage-Title'));
        if (decisioningToastMessage !== null) {
          setToastNotificationMsg(i18n.t(decisioningToastMessage));
        }
        localStorage.removeItem('DefaultsInventoryDecisioningToastMsg');
      }
      resetInventoryPageToDefault();
    }
  };

  const handleStatusUpdateResponsePartialFailure = (data: SubmissionDecisionResponseData[]) => {
    const failureIds: string[] = data
      .filter((itm: any) => 'error' in itm)
      .map((itm: any) => itm.cmhcDefaultAccountID);
    const failureIdsString: string = failureIds.join();

    setToastNotificationTitle(
      i18n.t('DefaultActions-SystemPartialInactivateErrorToast-Action-Title')
    );
    setToastNotificationMsg(
      `${i18n.t(
        'DefaultActions-SystemPartialInactivateErrorToast-Action-Message'
      )} ${failureIdsString}`
    );
    setShowPageModal(true);
  };

  const inactivateInventoryItems = (formModalData: DecisioningFormModalData) => {
    inactivateRequestMulti(
      selectedRows.map((row: TableRowData) => row.uuid.toString()),
      formModalData
    )
      .then((res: SubmissionDecisionResponse) => {
        if (res !== undefined && res.data !== undefined) {
          if (res.statusCode === 200) {
            handleStatusUpdateResponseSuccess(DefaultRequestStatus.Inactive);
          } else {
            handleStatusUpdateResponsePartialFailure(res.data);
          }
        } else {
          throw Error('Could not read server response');
        }
      })
      .catch();

    setShowPageModal(false);
  };

  const handleDecisioningInventory = (
    newStatus: DefaultRequestStatus,
    formModalData: DecisioningFormModalData
  ) => {
    updateSubmissionStatus(newStatus, formModalData, { cancelToken: cancelTokenSource.token })
      .then((res) => {
        if (res !== undefined && res.data !== undefined) {
          handleStatusUpdateResponseSuccess(newStatus);
        } else {
          throw Error('Could not read server response');
        }
      })
      .catch(() => showServerErrorNotification());

    setShowPageModal(false);
  };

  const handleOnCancelButtonClick = () => {
    const updatedModal: ModalProps = {
      type: ApplicationStates.CANCEL,
      title: i18n.t('DefaultsInventoryDetails-Modal-Cancel-Title'),
      isActive: true,
      onCloseCallback: handleClosePageModal,
      // eslint-disable-next-line react/display-name
      content: () => (
        <DefaultModalForm
          submitCallback={(formModalData: DecisioningFormModalData) =>
            handleDecisioningInventory(DefaultRequestStatus.Cancelled, formModalData)
          }
          submitButtonText={i18n.t('DefaultsInventoryDetails-Modal-Cancel-ButtonText')}
          disableSubmitOnEmptySelect={true}
          dropdownProps={{
            id: 'modal-cancel-submission-button',
            name: 'modal-cancel-submission-button',
            title: { value: i18n.t('DefaultsInventoryDetails-Modal-Cancel-ReasonDropdown-Label') },
            options: cancelOptions
          }}
          multiTextProps={{
            id: 'modal-cancel-submission-multiline-text',
            name: 'modal-cancel-submission-multiline-text',
            title: { value: i18n.t('DefaultsInventoryDetails-Modal-Cancel-ReasonMultiline-Label') },
            textAreaLimit: { value: '' },
            limitCountText: `/${i18n.t('Globals-MultilineText-MaxLength')}`,
            maxLength: Number(i18n.t('Globals-MultilineText-MaxLength'))
          }}
        />
      )
    };

    setPageModal(updatedModal);
    setShowPageModal(true);
  };

  const handleOnInactivateButtonClick = () => {
    const updatedModal: ModalProps = {
      type: ApplicationStates.INACTIVE,
      title: i18n.t('DefaultsInventoryDetails-Modal-Inactivate-Title'),
      isActive: true,
      onCloseCallback: handleClosePageModal,
      // eslint-disable-next-line react/display-name
      content: () => (
        <DefaultModalForm
          submitCallback={(formModalData: DecisioningFormModalData) =>
            inactivateInventoryItems(formModalData)
          }
          submitButtonText={i18n.t('DefaultsInventoryDetails-Modal-Inactivate-ButtonText')}
          disableSubmitOnEmptyMultilineText={false}
          multiTextProps={{
            id: 'modal-inactivate-button',
            name: 'modal-inactivate-button',
            title: { value: i18n.t('DefaultsInventoryDetails-Modal-Inactivate-Multiline-Label') },
            textAreaLimit: { value: '' },
            limitCountText: `/${i18n.t('Globals-MultilineText-MaxLength')}`,
            maxLength: Number(i18n.t('Globals-MultilineText-MaxLength'))
          }}
        />
      )
    };

    setPageModal(updatedModal);
    setShowPageModal(true);
  };

  const handleOnShowOwnInventoryClick = () => {
    // Can't show own inventory when a file is unassigned.
    // TODO: Deactivate slider when on this tab
    if (activeFilterTab !== DefaultRequestStatus.Unassigned) {
      setShowOwnInventory(!showOwnInventory);
    }
  };

  const handleOnPagerClick = (e: object, page: number) => setCurrentPage(page);

  const handleSelectNumResultsPerPage = (event: ChangeEvent<HTMLFormElement>) =>
    setNumFilesPerPage(event.currentTarget.value);

  const initTableHeaders = () => {
    let updatedTableHeaders: Column[];

    if (siteType) {
      updatedTableHeaders = [
        {
          displayText: 'DefaultsInventoryTable-Header-LoanAccountNumber',
          isVisible: true,
          isSortable: true,
          fieldName: FilterInternalColumnNames.CmhcLoanAccountNumber,
          isSortActive: false,
          sortDirection: Order.Descending
        },
        {
          displayText: 'files',
          isVisible: false,
          isSortable: false,
          fieldName: 'files',
          isSortActive: false,
          sortDirection: Order.Descending
        },
        {
          displayText: 'DefaultsInventoryTable-Header-ApplicationStatus',
          isVisible: true,
          isSortable:
            activeFilterTab === undefined ||
            activeFilterTab === DefaultRequestStatus.IncompleteSubmission,
          fieldName: FilterInternalColumnNames.DefaultStatus,
          isSortActive: false,
          sortDirection: Order.Descending
        },
        {
          displayText: 'DefaultsInventoryTable-Header-LenderName',
          isVisible: true,
          isSortable: true,
          fieldName: FilterInternalColumnNames.ApprovedLenderName,
          isSortActive: false,
          sortDirection: Order.Descending
        },
        {
          displayText: 'DefaultsInventoryTable-Header-LastModification',
          isVisible: true,
          isSortable: false,
          fieldName: FilterInternalColumnNames.LastRecordUpdatedTimestamp,
          isSortActive: false,
          sortDirection: Order.Descending
        },
        {
          displayText: 'DefaultsInventoryTable-Header-CmhcOwner',
          isVisible: true,
          isSortable: true,
          fieldName: FilterInternalColumnNames.CmhcOwnerLastName,
          isSortActive: false,
          sortDirection: Order.Descending
        },
        {
          displayText: 'DefaultsInventoryTable-Header-ReceivedDate',
          isVisible: true,
          isSortable: true,
          fieldName: FilterInternalColumnNames.RequestReceivedTimestamp,
          isSortActive: false,
          sortDirection: Order.Descending
        },
        {
          displayText: 'DefaultsInventoryTable-Header-SpecifiedLenderLanguage',
          isVisible: true,
          isSortable: false,
          fieldName: 'lang',
          isSortActive: false,
          sortDirection: Order.Descending
        },
        {
          displayText: 'DefaultsInventoryTable-Header-ProvinceOfProperty',
          isVisible: true,
          isSortable: false,
          fieldName: 'province',
          isSortActive: false,
          sortDirection: Order.Descending
        }
      ];
    } else {
      updatedTableHeaders = [
        {
          displayText: 'DefaultsInventoryTable-Header-LoanAccountNumber',
          isVisible: true,
          isSortable: true,
          fieldName: FilterInternalColumnNames.CmhcLoanAccountNumber,
          isSortActive: false,
          sortDirection: Order.Descending
        },
        {
          displayText: 'files',
          isVisible: false,
          isSortable: false,
          fieldName: 'files',
          isSortActive: false,
          sortDirection: Order.Descending
        },
        {
          displayText: 'DefaultsInventoryTable-Header-LenderReferenceNumber',
          isVisible: true,
          isSortable: true,
          fieldName: FilterExternalColumnNames.ApprovedLenderReferenceNumber,
          isSortActive: false,
          sortDirection: Order.Descending
        },
        {
          displayText: 'DefaultsInventoryTable-Header-ApplicationStatus',
          isVisible: true,
          isSortable:
            activeFilterTab === undefined ||
            activeFilterTab === DefaultRequestStatus.IncompleteSubmission,
          fieldName: FilterInternalColumnNames.DefaultStatus,
          isSortActive: false,
          sortDirection: Order.Descending
        },
        {
          displayText: 'DefaultsInventoryTable-Header-DateSubmitted',
          isVisible: true,
          isSortable: true,
          fieldName: FilterInternalColumnNames.RequestReceivedTimestamp,
          isSortActive: false,
          sortDirection: Order.Descending
        },

        {
          displayText: 'DefaultsInventoryTable-Header-LastUpdated',
          isVisible: true,
          isSortable: true,
          fieldName: FilterInternalColumnNames.LastRecordUpdatedTimestamp,
          isSortActive: false,
          sortDirection: Order.Descending
        },
        {
          displayText: 'DefaultsInventoryTable-Header-LenderName',
          isVisible: true,
          isSortable: true,
          fieldName: FilterInternalColumnNames.ApprovedLenderName,
          isSortActive: false,
          sortDirection: Order.Descending
        }
      ];
    }

    setTableHeaders(updatedTableHeaders);
  };

  const initToastNotificationsOnFormSubmit = () => {
    if (isServer() === false) {
      if (
        localStorage.getItem('submitWithErrors') ||
        localStorage.getItem('DefaultsInventoryDecisioningToastMsg')
      ) {
        setShowToastNotification(true);

        if (localStorage.getItem('submitWithErrors')) {
          if (localStorage.getItem('submitWithErrors') === 'true') {
            setToastNotificationType(ApplicationStates.ERROR);
            setToastNotificationTitle(i18n.t('DefaultsInventoryTable-SubmitErrorToast-Title'));
            setToastNotificationMsg(i18n.t('DefaultsInventoryTable-SubmitErrorToast-Message'));
          } else {
            setToastNotificationType(ApplicationStates.SUCCESS);
            setToastNotificationTitle(i18n.t('DefaultsInventoryTable-SuccessToastMessage-Title'));
            setToastNotificationMsg(i18n.t('DefaultsInventoryTable-SuccessToastMessage-Message'));
          }
          localStorage.removeItem('submitWithErrors');
        } else if (localStorage.getItem('DefaultsInventoryDecisioningToastMsg')) {
          const decisioningToastMessage = localStorage.getItem(
            'DefaultsInventoryDecisioningToastMsg'
          );
          setToastNotificationTitle(i18n.t('DefaultsInventoryTable-DecisioningToastMessage-Title'));
          if (decisioningToastMessage !== null) {
            setToastNotificationMsg(i18n.t(decisioningToastMessage));
          }
          localStorage.removeItem('DefaultsInventoryDecisioningToastMsg');
          const decisioningToastIcon = localStorage.getItem('ToastIcon');
          localStorage.removeItem('ToastIcon');
          if (decisioningToastIcon === '8') {
            setToastNotificationType(ApplicationStates.SUCCESS);
          }
        }
      }
    }
  };

  const handleOnSearchByLoanKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (config.defaultApi.urls.inventory) {
        if (e.currentTarget.value) {
          const query = `?${QueryParams.FilterBy}=${FilterInternalColumnNames.CmhcLoanAccountNumber} ${ComparisonOperators.EQ} ${e.currentTarget.value}`;
          getRowsRaw(config.defaultApi.urls.inventory + query);
        } else {
          // Create the query and getRowsRaw for blank CmhcLoanAccountNumber
          getRowsRaw(getResourceUrl());
        }
      }
    }
  };

  const updateDataTableCaption = () => {
    // 'Data table showing files with the ${filtersApplied} filter applied showing ${numberOfResults} results on page ${currentPage} of ${totalPages} pages'
    const templateString: string = i18n.t('DefaultsInventoryTable-caption') || '';

    const currentFilter: string | undefined = DefaultRequestStatus[activeFilterTab || -1] as string;
    const filterAppliedProc = currentFilter
      ? currentFilter.replace(/([A-Z])/g, ' $1').trim()
      : 'All';

    let numResultsPerPage: string;

    if (tabCounts !== undefined) {
      const numResults: number | undefined =
        activeFilterTab !== undefined
          ? tabCounts.statusCounts[activeFilterTab]
          : tabCounts.allCount;
      numResultsPerPage = (numResults < numFilesPerPage ? numResults : numFilesPerPage).toString();
    } else {
      numResultsPerPage = '';
    }

    /* eslint-disable no-template-curly-in-string */
    const updateCaption: string = templateString
      .replace('${filtersApplied}', filterAppliedProc)
      .replace('${numberOfResults}', numResultsPerPage)
      .replace('${currentPage}', currentPage.toString())
      .replace('${totalPages}', numPages.toString());
    /* eslint-enable no-template-curly-in-string */

    setDataTableCaption(updateCaption);
  };

  useEffect(() => {
    if (isServer() === false) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    // Reset selected rows on tab changes
    setSelectedRows([]);
    updateTableHeaders();
    updateDataTableCaption();
  }, [activeFilterTab]);

  useEffect(() => {
    if (selectedRows.length === 0) {
      setIsCancelRequestButtonDisabled(true);
      setIsInactivateRequestButtonDisabled(true);
    }
  }, [selectedRows]);

  useEffect(() => {
    getRows();
    updateDataTableCaption();
  }, [
    activeSortDirection,
    activeSortColumn,
    activeFilterTab,
    currentPage,
    numFilesPerPage,
    showOwnInventory
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeFilterTab]);

  useEffect(() => {
    updateDataTableCaption();
  }, [tabCounts, numPages, activeFilterTab, numFilesPerPage, currentPage]);

  // Run On componentDidMount
  useEffect(() => {
    initToastNotificationsOnFormSubmit();
    initTableHeaders();
    getTabCounts();
    updateDataTableCaption();

    return () => {
      cancelTokenSource.cancel();
      defaultApiCallsCancelToken.cancel();
    };
  }, []);

  return (
    <>
      <Modal
        type={pageModal.type}
        title={pageModal.title}
        content={pageModal.content}
        onCloseCallback={pageModal.onCloseCallback}
        isActive={showPageModal}
      />
      <ToastNotification
        type={toastNotificationType}
        isActive={showToastNotification}
        title={toastNotificationTitle}
        content={toastNotificationMsg}
        onCloseCallback={() => setShowToastNotification(false)}
      />
      <InventoryTablePresentation
        activeSortColumn={activeSortColumn || ''}
        activeSortDirection={activeSortDirection || Order.Descending}
        handleOnCancelButtonClick={handleOnCancelButtonClick}
        cancelButtonIsDisabled={isCancelRequestButtonDisabled}
        handleOnInactivateButtonClick={handleOnInactivateButtonClick}
        inactivateButtonIsDisabled={isInactivateRequestButtonDisabled}
        handleOnTabClick={handleOnTabClick}
        handleOnRowClick={handleOnRowClick}
        handleOnRowSelect={handleOnRowSelect}
        handleOnHeaderSort={handleOnHeaderSort}
        handleOnPaginationChange={handleOnPagerClick}
        handleOnShowOwnInventoryClick={handleOnShowOwnInventoryClick}
        handleOnRowKeydown={handleOnRowKeyDown}
        isLoading={isLoading}
        pagerRangeEnd={pagerRangeEnd}
        pagerRangeStart={pagerRangeStart}
        pagerTotalItems={pagerTotalItems}
        headers={tableHeaders || []}
        rows={rows}
        selectedRows={selectedRows}
        showOwnInventory={showOwnInventory}
        tabCounts={tabCounts}
        numPages={numPages}
        resultsPerPageSelectorDefaultValue={numFilesPerPage}
        resultsPerPageSelectorOptions={numFilesPerPageOptions}
        resultsPerPageSelectorOnChangeHandler={handleSelectNumResultsPerPage}
        searchOnKeyDown={handleOnSearchByLoanKeyDown}
        defaultTab={defaultTab}
        currentPage={currentPage}
        dataTableCaption={dataTableCaption}
        activeTab={activeFilterTab}
      />
      <InventoryTablePresentationExternal
        activeSortColumn={activeSortColumn || ''}
        activeSortDirection={activeSortDirection || Order.Descending}
        handleOnCancelButtonClick={handleOnCancelButtonClick}
        cancelButtonIsDisabled={isCancelRequestButtonDisabled}
        handleOnHeaderSort={handleOnHeaderSort}
        handleOnInactivateButtonClick={handleOnInactivateButtonClick}
        inactivateButtonIsDisabled={isInactivateRequestButtonDisabled}
        handleOnRowClick={handleOnRowClick}
        handleOnRowSelect={handleOnRowSelect}
        handleOnRowKeydown={handleOnRowKeyDown}
        handleOnShowOwnInventoryClick={handleOnShowOwnInventoryClick}
        handleOnTabClick={handleOnTabClick}
        handleOnPaginationChange={handleOnPagerClick}
        isLoading={isLoading}
        pagerRangeEnd={pagerRangeEnd}
        pagerRangeStart={pagerRangeStart}
        pagerTotalItems={pagerTotalItems}
        headers={tableHeaders || []}
        rows={rows}
        selectedRows={selectedRows}
        showOwnInventory={showOwnInventory}
        tabCounts={tabCounts}
        numPages={numPages}
        resultsPerPageSelectorDefaultValue={numFilesPerPage}
        resultsPerPageSelectorOptions={numFilesPerPageOptions}
        resultsPerPageSelectorOnChangeHandler={handleSelectNumResultsPerPage}
        searchOnKeyDown={handleOnSearchByLoanKeyDown}
        currentPage={currentPage}
        dataTableCaption={dataTableCaption}
      />
    </>
  );
};

export default withTranslation()(DefaultInventoryTable);
