const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10
  }
});

export const colourStyles = {
  input: (defaultStyles: any) => ({ ...defaultStyles, ...dot() }),
  singleValue: (defaultStyles: any, { data }: any) => ({ ...defaultStyles, ...dot(data.color) })
};
