export function setPathInObject(object: any, path: string, value: any): any {
  path
    .replace(/\[/g, '.')
    .replace(/\]/g, '')
    .split('.')
    .reduce((obj: any, name: string, index: number, paths: any[]) => {
      if (obj[name] === undefined || obj[name] == null) {
        obj[name] = {};
      }

      if (index === paths.length - 1) {
        obj[name] = value;
      }

      return obj[name];
    }, object);

  return object;
}
