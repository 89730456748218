import React, { createContext, useContext, useState, ReactNode } from 'react';

import { DefaultFormContextType, DefaultFormActionsContextType } from './types';

const DefaultFormContext = createContext<DefaultFormContextType>({});
export const useDefaultFormContext = () => useContext<DefaultFormContextType>(DefaultFormContext);

export const DefaultFormActionsContext = createContext<DefaultFormActionsContextType>({
  setCompleteCards: () => {},
  isCardComplete: () => false,
  addCompleteCard: () => {},
  removeCompleteCard: () => {},
  clearCompleteCards: () => {}
});
export const useDefaultFormActionsContext = () =>
  useContext<DefaultFormActionsContextType>(DefaultFormActionsContext);

const DefaultFormConfiguredProvider = (props: {
  children?: ReactNode;
  value?: DefaultFormContextType;
}) => {
  const [completeCards, setCompleteCards] = useState<Array<string>>(
    props.value?.completeCards ?? []
  );

  const isCardComplete = (name: string | undefined): boolean => {
    return name != null ? completeCards.includes(name) : false;
  };

  const addCompleteCard = (name: string | undefined) => {
    if (name != null) {
      setCompleteCards((currentState: string[]) => {
        return currentState.indexOf(name) === -1 ? [...currentState, name] : currentState;
      });
    }
  };

  const removeCompleteCard = (name: string | undefined) => {
    setCompleteCards((currentState: string[]) =>
      currentState.filter((cardName: string) => cardName !== name)
    );
  };

  const clearCompleteCards = () => setCompleteCards([]);

  return (
    <DefaultFormContext.Provider
      value={{
        completeCards
      }}
    >
      <DefaultFormActionsContext.Provider
        value={{
          setCompleteCards,
          isCardComplete,
          addCompleteCard,
          removeCompleteCard,
          clearCompleteCards
        }}
      >
        {props.children}
      </DefaultFormActionsContext.Provider>
    </DefaultFormContext.Provider>
  );
};

export default DefaultFormConfiguredProvider;
