import { TextProps } from '@sitecore-jss/sitecore-jss-react/types/components/Text';

export enum ProgressBarStepStatus {
  DEFAULT = 'default',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error'
}

export enum SectionType {
  SectionC = 'SectionC',
  SectionI = 'SectionI'
}

export interface ProgressBarItemProps {
  index?: number;
  name: TextProps['field'];
  type?: SectionType;
  status?: ProgressBarStepStatus;
  clickHandler?(e: React.MouseEvent<HTMLAnchorElement>): void;
  arialabelScreenReader?: string;
  validationKey?: string;
}
