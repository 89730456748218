import React, { useState } from 'react';
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { UserRole, ModuleMapping } from '@hobt/constants';

import { handleEmpty } from 'Components/Common/Api/utils/HandleEmpty';
import { Button } from 'Components/Common/Button';
import { PageCardContainer } from 'Components/PageComponents/PageCardContainer';
import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { DataTableFooter } from 'Components/Common/DataTableFooter';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { pathNames } from 'Constants/pathNames';
import { CommentCell } from 'Components/Common/DataTable/DataTableRow/CommentCell';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { useDataGridEngine } from 'Components/Hooks/DataGridEngine';

import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import DelegateAuthorityProps, {
  DelegateAuthorityInventoryRecords
} from '../../models/DelegateAuthorityProps';

import styles from './styles.module.scss';
import { config } from '../../../../config';

const DelegateAuthority = (props: DelegateAuthorityProps) => {
  const history = useHistory();
  const [isInventoryToast, setInventoryToast] = useState<boolean>(false);

  const onApiErrorCallback = () => setInventoryToast(true);

  const onToastCloseHandler = () => setInventoryToast(false);

  const {
    rowData,
    currentPage,
    itemsPerPage,
    onItemsPerPageChange,
    totalItems,
    totalPages,
    itemRangeFrom,
    itemRangeTo,
    isLoading: isInventoryLoading,
    onPagerClick,
    onSort,
    getSortDirectionForField
  } = useDataGridEngine<DelegateAuthorityInventoryRecords>({
    apiBaseUrl: config.delegateApi.urls.inventory,
    onApiErrorCallback,
    defaultItemsPerPage: 10
  });

  const onRowSelect = (row: DelegateAuthorityInventoryRecords) => {
    history.push(`/${i18n.language}${pathNames.delegateAuth}?id=${row.delegationID}`);
  };

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean =
    isUserInRoles(ModuleMapping.claim, [UserRole.ReadOnly], moduleRoleMapping) &&
    isUserInRoles(ModuleMapping.arrears, [UserRole.ReadOnly], moduleRoleMapping);

  const getRole = (row: DelegateAuthorityInventoryRecords) => {
    return props?.fields?.userLevelList?.fields?.listItems?.find(
      (item: FieldListItem) =>
        item?.fields?.itemValue?.value === row?.delegation?.actingUserTitleTypeCode?.toString()
    )?.fields?.itemName?.value;
  };

  return (
    <>
      <ToastNotification
        isActive={isInventoryToast}
        type={ApplicationStates.ERROR}
        title={i18n.t('Globals-Toast-Server-Error-Title')}
        content={{
          value: i18n.t('DefaultsInventoryTable-SystemErrorToast-Message')
        }}
        onCloseCallback={onToastCloseHandler}
      />
      <div className={styles.card}>
        <PageCardContainer name="delegateAuthority">
          <div className={`${styles.layoutContainer}`}>
            <div className={`${styles.titleContainer}`}>
              <Text tag="h2" field={props.fields.pageTitle} />
            </div>
            <div className={`${styles.buttonsContainer}`}>
              <Button
                name="addDelegateAuthority"
                ariaText={props.fields.addDelegateBtn}
                text={props.fields.addDelegateBtn}
                onClick={() => history.push(`/${i18n.language}${pathNames.delegateAuth}`)}
                disabled={isReadOnlyUser}
              />
            </div>
          </div>
          <div className={styles.dataTable}>
            <DataTable
              name="delegateAuthority"
              isLoading={isInventoryLoading}
              scrollOnHorizontalOverflow
              caption={{ value: 'Table Caption' }}
              tableClass={styles.tableClass}
            >
              <DataTableHeader>
                <DataTableHeaderItem
                  sortable
                  onSortCallback={onSort}
                  sortDirection={getSortDirectionForField('fullName')}
                  name="fullName"
                  displayText={props.fields.user}
                />
                <DataTableHeaderItem name="role" displayText={props.fields.role} />
                <DataTableHeaderItem name="startDate" displayText={props.fields.startDate} />
                <DataTableHeaderItem name="endDate" displayText={props.fields.endDate} />
                <DataTableHeaderItem name="comment" displayText={props.fields.comment} />
              </DataTableHeader>

              <DataTableBody zebra>
                {rowData != null &&
                  rowData.map((row: DelegateAuthorityInventoryRecords) => (
                    <DataTableRow
                      name={row.delegationID}
                      key={row.delegationID}
                      onClick={() => onRowSelect(row)}
                    >
                      <TextCell
                        name="user"
                        text={handleEmpty(row.fullName)}
                        subtext={handleEmpty(row.emailID)}
                      />
                      <TextCell name="role" text={handleEmpty(getRole(row))} />
                      <TextCell name="startDate" text={handleEmpty(row.delegation?.startDate)} />
                      <TextCell name="endDate" text={handleEmpty(row.delegation?.endDate)} />
                      {row.delegation?.comment != null ? (
                        <CommentCell name="comment" comment={row.delegation.comment} />
                      ) : (
                        <TextCell name="no-comment" text={''} />
                      )}
                    </DataTableRow>
                  ))}
              </DataTableBody>
            </DataTable>
          </div>

          <div className={styles.dataTableFooter}>
            <DataTableFooter
              name="delegateAuthorityList"
              currentPage={currentPage}
              totalPages={totalPages || 1}
              itemsPerPageLabel={{
                value: i18n.t('DefaultsInventoryTable-ResultsPerPageDropdown-Title')
              }}
              // TODO: Replace with actual values when API is wired in.
              itemsXofYText={`${itemRangeFrom || 0} - ${itemRangeTo || 0} ${i18n.t(
                'Globals-Grid-ResultsPerPageDropdown-Of'
              )}`}
              totalItems={totalItems}
              pagerClickCallback={onPagerClick}
              itemsPerPageOnChangeCallback={onItemsPerPageChange}
              itemsPerPageValue={itemsPerPage.toString()}
            />
          </div>
        </PageCardContainer>
      </div>
    </>
  );
};

export default DelegateAuthority;
