import i18n from 'i18next';

export const getReasonsDefaultManagementProps = () => {
  const reasonsDefaultManagementProps = {
    testId: 'reasonsDefaultManagementTest',
    accordionLineColor: 'grey',
    linePosition: 'vertical',
    title: {
      field: {
        value: i18n.t('DefaultSubmission-Card-DefaultManagementHeading')
      }
    },
    inputFields: {
      dateHardshipBegan: {
        field: {
          value: i18n.t('DefaultSubmission-Card-ReasonsDateHardshipBegan')
        }
      },
      causeOfDefault: {
        field: {
          value: i18n.t('DefaultSubmission-Card-ReasonsCauseOfDefault')
        }
      },
      description: {
        field: {
          value: i18n.t('DefaultSubmission-Card-Description')
        }
      },
      maxLength: Number(i18n.t('Globals-MultilineText-MaxLength'))
    }
  };

  return reasonsDefaultManagementProps;
};
