import { withFeature } from 'flagged';
import i18n from 'i18next';
import React from 'react';
import { Pagination } from '@mui/lab';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { ModuleMapping, UserRole } from '@hobt/constants';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { Card, CardHeader } from 'Feature/CommonComponents/ContentComponents';
import { Table } from 'Feature/CommonComponents/DataTable/';
import { CardType } from 'Feature/CommonComponents/Enums';
import {
  Button,
  ButtonType,
  Toggle,
  Tabs,
  LoaderAnimation
} from 'Feature/CommonComponents/UserControls';
import { InventoryTableProps } from 'Feature/DefaultsInventory/models/DefaultsInventoryTable.type';
import FormInputDropdownText from 'Feature/DefaultSubmissionForm/components/FormInputDropdownText';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { DefaultRequestStatus } from 'Foundation/Api';

import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const InventoryTablePresentation: React.FC<InventoryTableProps> = (props: InventoryTableProps) => {
  const itemsArray = [
    {
      displayText: i18n.t('DefaultsInventoryTable-FilterTab-Unassigned'),
      tabName: `${DefaultRequestStatus[DefaultRequestStatus.Unassigned]}`,
      count: props.tabCounts && props.tabCounts.statusCounts[DefaultRequestStatus.Unassigned]
    },
    {
      displayText: i18n.t('DefaultsInventoryTable-FilterTab-All'),
      tabName: 'all',
      count: props.tabCounts && props.tabCounts.allCount
    },
    {
      displayText: i18n.t('DefaultsInventoryTable-FilterTab-InReview'),
      tabName: `${DefaultRequestStatus[DefaultRequestStatus.InReview]}`,
      count: props.tabCounts && props.tabCounts.statusCounts[DefaultRequestStatus.InReview]
    },
    {
      displayText: i18n.t('DefaultsInventoryTable-FilterTab-Approved'),
      tabName: `${DefaultRequestStatus[DefaultRequestStatus.Approved]}`,
      count: props.tabCounts && props.tabCounts.statusCounts[DefaultRequestStatus.Approved]
    },
    {
      displayText: i18n.t('DefaultsInventoryTable-FilterTab-Declined'),
      tabName: `${DefaultRequestStatus[DefaultRequestStatus.Declined]}`,
      count: props.tabCounts && props.tabCounts.statusCounts[DefaultRequestStatus.Declined]
    },
    {
      displayText: i18n.t('DefaultsInventoryTable-FilterTab-Cancelled'),
      tabName: `${DefaultRequestStatus[DefaultRequestStatus.Cancelled]}`,
      count: props.tabCounts && props.tabCounts.statusCounts[DefaultRequestStatus.Cancelled]
    },
    {
      displayText: i18n.t('DefaultsInventoryTable-FilterTab-IncompleteSubmissions'),
      tabName: `${DefaultRequestStatus[DefaultRequestStatus.IncompleteSubmission]}`,
      count:
        props.tabCounts && props.tabCounts.statusCounts[DefaultRequestStatus.IncompleteSubmission]
    }
  ];
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.default,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );
  return (
    <div id="defaultsInventory" className="defaultsInventory">
      <section className="col-12 dataVis">
        <Card type={CardType.HORIZONTAL_BLUE}>
          <CardHeader title={i18n.t('DefaultsInventoryTable-LabelText-Title')} />
          <div className="card-body card__body card__inventory-table">
            <div>
              <Tabs
                buttonTabs
                tabGroupName={i18n.t('DefaultsInventoryDetails-TabGroupName-DetailsTabs')}
                items={itemsArray}
                onTabClick={props.handleOnTabClick}
                defaultActiveItemFieldName={props.defaultTab}
              />
            </div>
            <div className={styles.layoutContainer}>
              <div>
                <Button
                  id="submissionButtonCancel"
                  name="submission.cancel"
                  buttonType={ButtonType.CONTEXTUAL}
                  displayText={i18n.t('DefaultsInventoryTable-ButtonText-CancelRequest')}
                  icon="clear"
                  onClick={props.handleOnCancelButtonClick}
                  disabled={isReadOnlyUser || props.cancelButtonIsDisabled}
                />
                <span className="show-on-disable sr-only" tabIndex={0}>{`${i18n.t(
                  'ButtonFor'
                )} ${i18n.t('DefaultsInventoryTable-ButtonText-CancelRequest')}, ${i18n.t(
                  'CurrentlyDisabled'
                )}`}</span>
                <Button
                  id="submissionButtonActivate"
                  name="submission.inactivate"
                  buttonType={ButtonType.CONTEXTUAL}
                  displayText={i18n.t('DefaultsInventoryTable-ButtonText-InactivateRequest')}
                  icon="block"
                  disabled={isReadOnlyUser || props.inactivateButtonIsDisabled}
                  onClick={props.handleOnInactivateButtonClick}
                />
                <span className="show-on-disable sr-only" tabIndex={0}>{`${i18n.t(
                  'ButtonFor'
                )} ${i18n.t('DefaultsInventoryTable-ButtonText-InactivateRequest')}, ${i18n.t(
                  'CurrentlyDisabled'
                )}`}</span>
              </div>
              <div className={styles.filterContainer}>
                <div>
                  {props.activeTab !== DefaultRequestStatus.Unassigned && (
                    <Toggle
                      toggleText={i18n.t('DefaultsInventoryTable-LabelText-OnlyShowMyInventory')}
                      isChecked={props.showOwnInventory}
                      onClick={props.handleOnShowOwnInventoryClick}
                      id="inventoryToggle"
                      testId="inventoryToggleTest"
                    />
                  )}
                </div>
                <div>
                  <div className="form inventorySearchForm" role="search">
                    <div className={`form__element inventory__search align-items-center`}>
                      <label
                        htmlFor="searchByLoanNumber"
                        id="searchByLoanNumberLabel"
                        className="sr-only"
                      >
                        {i18n.t('DefaultsInventoryTable-SearchByLoadNumber')}
                      </label>
                      <input
                        name="search-by-loan-number"
                        className={`${styles.inventorySearch} inventory__search--input`}
                        id="searchByLoanNumber"
                        aria-labelledby="searchByLoanNumberLabel"
                        placeholder={i18n.t('DefaultsInventoryTable-SearchLoanNumbers')}
                        maxLength={8}
                        onKeyDown={(e) => {
                          if (e.key === '.') {
                            e.preventDefault();
                          }
                          props.searchOnKeyDown && props.searchOnKeyDown(e);
                        }}
                        onInput={(e) => {
                          e.currentTarget.value = e.currentTarget.value
                            .replace(/[^0-9]*/g, '')
                            .substr(0, 8);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row card__body-row card__body-row--fluid">
              <div className="col-12">
                {props.isLoading && <LoaderAnimation />}
                {!props.isLoading && props.rows!.length !== 0 && (
                  <Table
                    name="defaultsInventory"
                    tableHeaderColumns={props.headers}
                    tableRows={props.rows || []}
                    onRowClick={(e) => props.handleOnRowClick(e)}
                    onRowSelect={(e) => props.handleOnRowSelect(e)}
                    onRowKeyDown={(e) => props.handleOnRowKeydown(e)}
                    onHeaderColumnSort={props.handleOnHeaderSort}
                    title={i18n.t('DefaultsInventoryTable-Row-Title')}
                    tableCaption={props.dataTableCaption || ''}
                    scrollOnHorizontalOverflow
                  />
                )}
                {!props.isLoading && (props.rows == null || props.rows.length === 0) && (
                  <div className={styles.noSearchResultsMessage}>
                    <Text field={{ value: i18n.t('Globals-Table-No-Search-To-Display') }} />
                  </div>
                )}
              </div>
            </div>

            <div className="row card__body-row">
              <div className="col-12 justify-right table__page-actions--bottom">
                <div className={`pagination ${styles.inventoryPagination}`}>
                  {`${props.pagerRangeStart} - ${props.pagerRangeEnd}
                  ${i18n.t('DefaultsInventoryTable-LabelText-OutOf')}
                  ${props.pagerTotalItems}
                  ${i18n.t('DefaultsInventoryTable-LabelText-Items')}`}
                </div>
                <div className="pager">
                  <FormInputDropdownText
                    id="numberOfItemsPerPageDropdownSelector"
                    name="number-of-items-per-page-dropdown-selector"
                    title={{
                      value: i18n.t('DefaultsInventoryTable-ResultsPerPageDropdown-Title')
                    }}
                    options={props.resultsPerPageSelectorOptions || []}
                    handleChange={props.resultsPerPageSelectorOnChangeHandler}
                    value={props.resultsPerPageSelectorDefaultValue || 20}
                    hideError={true}
                  />
                </div>
                <div className="pagintaion">
                  <Pagination
                    count={props.numPages}
                    variant="outlined"
                    shape="rounded"
                    page={props.currentPage || 1}
                    onChange={props.handleOnPaginationChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
      </section>
    </div>
  );
};

export default withFeature(FeatureFlags.INTERNAL)(InventoryTablePresentation);
