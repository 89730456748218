import React, { useEffect } from 'react';
import { UserRole, Module } from '@hobt/constants';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { useDefaultFormActionsContext } from 'Feature/DefaultsInventory/components/DefaultFormContext';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useFeature } from 'flagged';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';
import { LinedCard, AccordionContainer } from '../../../../CommonComponents/AccordionComponents';
import { FormInputRadioType } from '../../../models/typeCode.types';
import FormInputMultiLineText from '../../FormInputMultiLineText';
import FormInputRadio from '../../FormInputRadio';
import FormInputTextInput from '../../FormInputTextInput';
import { LenderAcknowledgementProps } from './LenderAcknowledgment.types';
import './LenderAcknowledgment.css';
import { scrollIntoView } from '../../CardReuseableFunctions';

export const LenderAcknowledgment: React.FC<LenderAcknowledgementProps> = ({
  register,
  errors,
  setValueHandler,
  control,
  watch,
  getValues,
  ...props
}) => {
  const commonCardProps = {
    register,
    errors,
    setValueHandler,
    control,
    watch,
    getValues
  };

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);
  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;

  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  const scrollToCardN = (e: React.MouseEvent) => {
    e.preventDefault();
    scrollIntoView('attachmentsAccordionAccordionHeader');
  };

  const reasonsCharacterLimitText: string = i18n
    .t('DefaultSubmission-Card-ReasonsCharacterLimitText')
    .replace('{textLimit}', props.maxLength.toString());

  const watchPreApprovalRequired = watch && watch('managementInformation.preApprovalRequired');

  const lenderAcknowledgementSignatureFullName =
    watch && watch('managementInformation.lenderAcknowledgementSignatureFullName');
  const lenderAcknowledgementSignatureTitle =
    watch && watch('managementInformation.lenderAcknowledgementSignatureTitle');
  const lenderAcknowledgementAdditionalDetails =
    watch && watch('managementInformation.lenderAcknowledgementAdditionalDetails');

  const defaultFormActions = useDefaultFormActionsContext();

  useEffect(() => {
    if (register != null && setValueHandler != null && watchPreApprovalRequired != null) {
      // register('managementInformation.preApprovalRequired');
      setValueHandler(
        'managementInformation.preApprovalRequired',
        watchPreApprovalRequired.toString()
      );
      setValueHandler(
        'managementInformation.lenderAcknowledgementAdditionalDetails',
        lenderAcknowledgementAdditionalDetails
      );
    }
  }, [watchPreApprovalRequired]);

  useEffect(() => {
    if (defaultFormActions.isCardComplete('lenderAcknowledgement')) {
      if (
        !(
          !!watchPreApprovalRequired &&
          !!lenderAcknowledgementSignatureFullName &&
          !!lenderAcknowledgementSignatureTitle
        )
      ) {
        defaultFormActions?.removeCompleteCard('lenderAcknowledgement');
      }
    } else if (
      !!watchPreApprovalRequired &&
      !!lenderAcknowledgementSignatureFullName &&
      !!lenderAcknowledgementSignatureTitle
    ) {
      if (watchPreApprovalRequired === 'true') {
        if (lenderAcknowledgementAdditionalDetails) {
          defaultFormActions?.addCompleteCard('lenderAcknowledgement');
        }
      } else {
        defaultFormActions?.addCompleteCard('lenderAcknowledgement');
      }
    }
  }, [
    watchPreApprovalRequired,
    lenderAcknowledgementSignatureFullName,
    lenderAcknowledgementSignatureTitle
  ]);

  return (
    <LinedCard
      id={props.id}
      testId={props.testId}
      linePosition={props.linePosition}
      lineColor={props.accordionLineColor}
    >
      <AccordionContainer accordionId={`${props.id}Accordion`} title={props.title.field}>
        <div className="row card__body-row">
          <div className="col-12">
            <FormInputRadio
              {...commonCardProps}
              id={`${props.id}PreAprovalRadio`}
              name={`managementInformation.preApprovalRequired`}
              title={{ value: i18n.t('DefaultSubmission-CardK-LenderAcknowledgementIntro') }}
              options={[
                {
                  label: {
                    value: i18n.t('DefaultSubmission-CardK-LenderAcknowledgementNoPre')
                  },
                  value: FormInputRadioType.No
                },
                {
                  label: {
                    value: i18n.t('DefaultSubmission-CardK-LenderAcknowledgementPre')
                  },
                  value: FormInputRadioType.Yes
                }
              ]}
              verticalLayout={true}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
        <div className="row card__body-row">
          <div className="form__element col-12">
            <FormInputMultiLineText
              {...commonCardProps}
              id={`${props.id}AdditionalDetails`}
              name={`managementInformation.lenderAcknowledgementAdditionalDetails`}
              title={{
                value: i18n.t('DefaultSubmission-CardK-AdditionalDetails')
              }}
              textAreaLimit={{
                value: reasonsCharacterLimitText
              }}
              hideLimit={props.hideLimit}
              maxLength={props.maxLength}
              limitCountText={`/ ${props.maxLength}`}
              cardName={props.title.field.value}
              isDisabled={isReadOnlyUser}
              isRequired={true}
            />
          </div>
        </div>
        <p className="card__body-subHeading">
          <Text
            field={{
              value: i18n.t('DefaultSubmission-CardK-AdditionalDetailsDescription')
            }}
          />{' '}
          <a
            href={props.ifCommentRequiredLinkURL.value.href}
            onClick={(e: React.MouseEvent) => {
              scrollToCardN(e);
            }}
          >
            {props.ifCommentRequiredLinkURL.value.text}
          </a>
        </p>
        <h3 className="card__body-heading">
          <Text field={{ value: i18n.t('DefaultSubmission-CardK-Signature') }} />
        </h3>
        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <FormInputTextInput
              {...commonCardProps}
              id={`${props.id}FinObligationsFullName`}
              name={`managementInformation.lenderAcknowledgementSignatureFullName`}
              title={{ value: i18n.t('DefaultSubmission-CardK-FullName') }}
              type="text"
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputTextInput
              {...commonCardProps}
              id={`${props.id}FinObligationsPersonalTitle`}
              name={`managementInformation.lenderAcknowledgementSignatureTitle`}
              title={{ value: i18n.t('DefaultSubmission-CardK-Title') }}
              type="text"
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
      </AccordionContainer>
    </LinedCard>
  );
};
