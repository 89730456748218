import React, { FC, memo, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Autocomplete, FormControl, InputAdornment, TextField } from '@mui/material';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Tooltip } from 'Components/Core/Tooltip';
import { TooltipPosition } from 'Components/Core/Tooltip/types';
import i18n from 'i18next';
import { HbtIcon } from '../HbtIcon';
import { HbtIconSize } from '../HbtIcon/types';
import { getComboboxOptionsReactNode } from './ComboboxOptions';
import getComboboxSx from './comboboxSx';
import CustomPaper from './CustomPaper';
import getListboxPropsSx from './listboxPropsSx';
import styles from './styles.module.scss';
import getTextFieldSx from './textFieldSx';
import { ComboboxOption, ComboboxProps } from './types';

/**
 * @param {ComboboxProps} props - list out the props.
 * @param {FieldValue} props.ariaText - aria label for the Combobox
 * @param {string} props.className - class to override styles of the combobox container
 * @param {boolean} props.error - indicator of any error on combobox. There is an error when this value is true.
 * @param {string} props.errorMessage - contains the error message to be displayed below the combobox container when error is true
 * @param {FieldValue} props.helpText - helper text to be displayed below the combobox
 * @param {string} props.value - contains the value of any option that needs to be set on the combobox
 * @param {Label} props.label - contains label text and label info icon tool tip text; label will be displayed above the combobox when this input is provided
 * @param {ComboboxOption[]} props.options - options to be displayed in the dropdown of the combobox. It containsr the label, value, icon and group name for each option.
 * @param {JSX.Element} props.placeholderIcon - any icon to be displayed in the combobox input field by default when there is no option selected
 * @param {string} props.placeholderText - any text to be displayed in the combobox input field when there is no option selected
 * @param {string} props.noOptionsText - text to be displayed when there are no options matching the typed in search criteria
 * @param {boolean} props.readOnly - specifies whether the combobox is readonly
 * @returns {Combobox} Rendered Combobox component.
 */

/**
 * Combobox component
 *
 * This is the Combobox Dropdown component written using storybook.
 * The user can filter the drop down contents.  It handles different states, variants.
 * This combobox allows only a single selection.
 */

export const Combobox: FC<ComboboxProps> = memo(
  ({
    ariaText,
    className,
    error,
    errorMessage,
    helpText,
    value,
    label,
    options,
    placeholderIcon,
    placeholderText,
    noOptionsText,
    readOnly = false,
    onChange
  }: ComboboxProps) => {
    const [selectedOption, setSelectedOption] = useState<ComboboxOption | null>(null);

    useEffect(() => {
      const selectedValue = options.find((option) => option?.value === value);
      setSelectedOption(selectedValue || null);
    }, [value]);

    const [isOpen, setIsOpen] = useState(false);
    const [openedByKeyboard, setOpenedByKeyboard] = useState(false);

    const inputIcon = selectedOption?.icon ?? placeholderIcon;
    const placeholder = placeholderText ?? 'Select an option';
    const noOptions = noOptionsText ?? i18n.t('Dropdown-Option-NoResultsFound', 'No results found');
    const isInputIcon = inputIcon !== null && inputIcon != undefined;

    const handleChange = useCallback(
      (event: React.SyntheticEvent, value: ComboboxOption | null, reason: string) => {
        setSelectedOption(value);
        setIsOpen(false);

        if (onChange) {
          onChange(event, value, reason);
        }
      },
      [JSON.stringify(options)]
    );

    // Workaround to remove autofocus on first option that always happens even when menu is opened through click; this is a workaround/fix for bug in material UI library (https://github.com/mui/material-ui/issues/23747).
    const handleMenuOpen = useCallback((event: SyntheticEvent | KeyboardEvent) => {
      if (
        (event as KeyboardEvent).key === 'ArrowDown' ||
        (event as KeyboardEvent).key === 'Enter'
      ) {
        setOpenedByKeyboard(true);
      } else {
        setIsOpen(true);
      }
    }, []);

    const handleMenuClose = useCallback(() => {
      setOpenedByKeyboard(false);
      setIsOpen(false);
    }, []);

    useEffect(() => {
      if (openedByKeyboard) {
        setIsOpen(true);
      }
    }, [openedByKeyboard]);

    return (
      <FormControl
        data-testid="combobox-wrapper"
        variant="standard"
        className={`${styles.container} ${className}`}
      >
        {label?.text && (
          <div className={styles.labelContainer}>
            <label className={styles.labelText}>
              <Text field={label.text} />
            </label>
            {label?.infoHelperText?.value && (
              <span className={styles.labelIcon}>
                <Tooltip
                  label={label.infoHelperText.value}
                  position={TooltipPosition.RIGHT}
                  iconSize={HbtIconSize.SMALL}
                />
              </span>
            )}
          </div>
        )}
        <Autocomplete
          readOnly={readOnly}
          data-testid="combobox-root"
          options={options.sort(
            (a, b) => -b.groupName?.localeCompare(a.groupName) || -b.label?.localeCompare(a.label)
          )}
          value={selectedOption}
          noOptionsText={noOptions}
          popupIcon={
            !readOnly ? <HbtIcon size={HbtIconSize.SMALL} type="icon_chevron_down" /> : null
          }
          clearIcon={
            !readOnly ? <HbtIcon size={HbtIconSize.SMALL} type="icon_clear_circle" /> : null
          }
          PaperComponent={CustomPaper}
          renderOption={getComboboxOptionsReactNode}
          getOptionLabel={(option) => option.label ?? ''}
          ListboxProps={getListboxPropsSx()}
          renderInput={(params) => {
            const { inputProps, InputProps } = params;
            return (
              <div ref={params.InputProps.ref}>
                <TextField
                  data-role="input-text"
                  {...params}
                  placeholder={placeholder}
                  inputProps={{
                    ...inputProps,
                    'aria-label': ariaText?.value,
                    'aria-invalid': error ? 'true' : 'false',
                    'aria-errormessage': 'error-combobox',
                    'aria-expanded': isOpen ? 'true' : 'false'
                  }}
                  InputProps={{
                    ...InputProps,
                    startAdornment: <InputAdornment position="start">{inputIcon}</InputAdornment>
                  }}
                  sx={getTextFieldSx(isOpen, readOnly, error, isInputIcon)}
                />
              </div>
            );
          }}
          componentsProps={{
            popupIndicator: {
              tabIndex: 0,
              disableRipple: true
            },
            clearIndicator: {
              tabIndex: 0,
              disableRipple: true
            }
          }}
          classes={{
            popper: styles.popper,
            noOptions: styles.noOptionsText
          }}
          sx={getComboboxSx(isOpen, readOnly)}
          onChange={handleChange}
          onOpen={handleMenuOpen}
          onClose={handleMenuClose}
          groupBy={(option: ComboboxOption) => option.groupName}
        />
        {helpText && (
          <span className={styles.helperText}>
            <Text field={helpText} />
          </span>
        )}
        {error && errorMessage && (
          <div className={styles.errorContainer}>
            <HbtIcon
              size={HbtIconSize.SMALL}
              type="icon_error_outlined"
              className={styles.errorInfoIcon}
            />
            <span className={styles.errorMessageText} id="error-combobox" role="alert">
              {errorMessage}
            </span>
          </div>
        )}
      </FormControl>
    );
  }
);
