export default function getListboxPropsSx() {
  return {
    sx: {
      paddingTop: '0',
      paddingBottom: '0',
      maxHeight: '230px !important',
      '& .MuiAutocomplete-option': {
        padding: '0'
      },
      '& li.Mui-focused': {
        backgroundColor: 'var(--Violet-90W, #edecfa) !important'
      },
      '& li[aria-selected="true"]': {
        backgroundColor: 'var(--Slate-90W, #F0F2F3) !important'
      }
    }
  };
}
