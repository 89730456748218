import * as React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { useHistory } from 'react-router-dom';

import { ApplicationErrorState, ApplicationErrorProps } from '../../models/ApplicationErrorProps';

const ApplicationError: React.FC<ApplicationErrorProps> = (props: ApplicationErrorProps) => {
  const history = useHistory();
  let errorMessage = '';

  if (history.location && history.location.state) {
    const state = history.location.state as ApplicationErrorState;

    if (state) {
      errorMessage = state.message;
    }
  }

  return (
    <div className="content-min-height">
      <div className="row justify-content-md-center">
        <div className="col-6 page__error--holder">
          <section>
            <h2>{errorMessage}</h2>
            <RichText field={props.fields.content} />
          </section>
        </div>
      </div>
    </div>
  );
};

export default ApplicationError;
