import { FinalClaim } from '@hobt/claim-domain';
import { HbtValidationErrorCodes, HttpResponseStatusCodes } from '@hobt/constants';

import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { AuthenticationContextType } from 'Foundation/Authentication';
import {
  ApprovalPayload,
  ApprovalsSubmitResponse,
  ArrearsApprovalPayload,
  ErrorEntity
} from './types';
import { config } from '../../../../config';

const finalClaimUrl = config.claimApi.urls.finalClaim;
const finalClaimApproveUrl = config.claimApi.urls.finalClaimApprove;
const arrearsSendApprovalUrl: string = config.arrearsApi.urls.sendApproval;
const userEntityUrl = config.userApi.urls.getUser;

const apiClientConfig: ApiClientConfig = {
  timeout: config.defaultApi.requestTimeout
};

const approvalErrorCatch = (err: ErrorEntity) => {
  if (err?.errorCode == null) {
    return ApprovalsSubmitResponse.Error;
  }

  switch (err.errorCode) {
    case HbtValidationErrorCodes.HBT_ERR_BELOW_MINIMUM_APPROVAL_ROLE.code:
      return ApprovalsSubmitResponse.ClaimMinimumRole;
    case HbtValidationErrorCodes
      .HBT_VAL_ERR_BELOW_MINIMUM_ARREARS_PRIMARY_APPROVAL_FINANCIAL_AUTHORITY.code:
    case HbtValidationErrorCodes
      .HBT_VAL_ERR_BELOW_MINIMUM_ARREARS_SECONDARY_APPROVAL_FINANCIAL_AUTHORITY.code:
      return ApprovalsSubmitResponse.ArrearsMinimumRole;
    case HbtValidationErrorCodes.HBT_VAL_ERR_FINALCLAIM_PAYEE_ERP.code:
      return ApprovalsSubmitResponse.MissingPayeeDetails;
    case HbtValidationErrorCodes.HBT_VAL_ERR_SECONDARY_APPROVAL_SAME_USER.code:
      return ApprovalsSubmitResponse.SameUserApproval;
    case HbtValidationErrorCodes.HBT_ERR_APPROVAL_FROM_PROBATIONARY_OFFICER.code:
      return ApprovalsSubmitResponse.ProbationaryUserNotAuthorized;
    case HbtValidationErrorCodes.HBT_VAL_ERR_ATTACHMENT_REQUIRED_PRIMARY_APPROVAL.code:
      return ApprovalsSubmitResponse.MissingAttachment;
    case HbtValidationErrorCodes.HBT_ERR_APPROVE_BEHALF_OF_OTHER_PERSON_DOCUMENTID_REQUIRED.code:
      return ApprovalsSubmitResponse.BehalfOfOther;
    default:
      return ApprovalsSubmitResponse.ServerError;
  }
};

export const errorCatch = (err: any): number => {
  if (err?.response?.status == null) {
    return ApprovalsSubmitResponse.Error;
  }

  switch (err.response.status) {
    case HttpResponseStatusCodes.BadRequest:
      return ApprovalsSubmitResponse.BadRequest;
    case HttpResponseStatusCodes.Unauthorized:
      return ApprovalsSubmitResponse.NotAuthorized;
    case HttpResponseStatusCodes.ServerError:
      return approvalErrorCatch(err.response?.data?.error);
    default:
      return ApprovalsSubmitResponse.Error;
  }
};

export const submitApprovalForm = async (
  authContext: AuthenticationContextType,
  claimID: string,
  data: ApprovalPayload,
  onSubmitCallback: ((data: FinalClaim | undefined) => void) | undefined
): Promise<{ data: FinalClaim; response: ApprovalsSubmitResponse } | number> => {
  const url = `${finalClaimApproveUrl}/${claimID}`;
  try {
    const response = await ApiClient(authContext, apiClientConfig).postWithAuth(url, data);

    if (response?.status === HttpResponseStatusCodes.OK) {
      onSubmitCallback?.(response?.data?.data);
      return ApprovalsSubmitResponse.Success;
    }

    // Fail gracefully
    return ApprovalsSubmitResponse.Error;
  } catch (err) {
    return errorCatch(err);
  }
};

export const submitArrearsApproval = async (
  authContext: AuthenticationContextType,
  data: ArrearsApprovalPayload
): Promise<number> => {
  const url = `${arrearsSendApprovalUrl}`;
  try {
    await ApiClient(authContext, apiClientConfig).postWithAuth(url, data);
    return ApprovalsSubmitResponse.Success;
  } catch (err) {
    return errorCatch(err);
  }
};

export const getClaimApprovalInfo = async (
  authContext: AuthenticationContextType,
  claimID: string
): Promise<FinalClaim> => {
  const url = `${finalClaimUrl}/${claimID}`;
  const response = await ApiClient(authContext, apiClientConfig).getWithAuth(url);
  return response?.data?.data?.[0];
};

export const getUserEntity = async (authContext: AuthenticationContextType, userID: string) => {
  const response = await await ApiClient(authContext, apiClientConfig).getWithAuth(
    `${userEntityUrl}?id=${userID}`
  );
  return response;
};
