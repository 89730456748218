import * as React from 'react';
import { useState } from 'react';
import i18n from 'i18next';
import { TabItem, TabsProps } from './Tabs.types';

export const Tabs: React.FC<TabsProps> = (props: TabsProps) => {
  const [activeFilter, setActiveFilter] = useState(
    props.defaultActiveItemFieldName || props.items[0].tabName
  );

  const handleTabClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const filterValue = e.currentTarget.getAttribute('data-tab-name') || '';
    setActiveFilter(filterValue);
    props.onTabClick(e);
  };

  const tabType = props.buttonTabs
    ? {
        groupRole: 'group',
        listRole: 'none presentation',
        role: 'button',
        idType: 'Button',
        controlType: '',
        tableId: 'filter'
      }
    : {
        groupRole: 'tablist',
        listRole: 'none presentation',
        role: 'tab',
        idType: 'Tab',
        controlType: '',
        tableID: ''
      };

  return (
    <ul className="nav nav-tabs" role={tabType.groupRole} aria-label={`${props.tabGroupName}`}>
      {props.items.map((item: TabItem) => {
        const tabCount = item.count != null ? `(${item.count})` : '';
        const buttonAttrs = {
          id: `${item.tabName}${tabType.idType}`,
          role: tabType.role,
          onClick: handleTabClick,
          'aria-label': `${item.displayText} ${tabCount}`.trim(),
          'aria-controls': `${tabType.tableId ? tabType.tableId : `(${item.tabName})`}Content`,
          'data-tab-name': item.tabName,
          className: `nav-link ${item.tabName === activeFilter && 'active'}`,
          key: `filterBar-filterItem-${item.tabName}-item`
        };
        const ariaSelcted = !props.buttonTabs
          ? {
              'aria-selected': item.tabName === activeFilter
            }
          : {};

        return (
          <li
            className="nav-item"
            role={tabType.listRole}
            key={`filterBar-filterItem-${item.tabName}`}
          >
            {React.createElement(
              'button',
              {
                type: 'button',
                ...buttonAttrs,
                ...ariaSelcted
              },
              `${item.displayText} ${tabCount}`.trim()
            )}
          </li>
        );
      })}
    </ul>
  );
};
