import React, { useEffect, useState } from 'react';
import { UserType } from '@hobt/constants';
import { ApplicationStates } from '@hobt/data-vis-components';
import { frontEndAddExternalUserSchema } from '@hobt/form-schema-validators';
import { joiResolver } from '@hookform/resolvers/joi';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Button } from 'Components/Core/Button';
import { ButtonVariant } from 'Components/Core/Button/types';
import FormToggle from 'Components/Inputs/FormToggle';
import { pathNames } from 'Constants/pathNames';
import {
  ToastNotification,
  ToastNotificationProps
} from 'Feature/CommonComponents/ContentComponents';
import { SubmissionType } from 'Feature/CommonComponents/Enums';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';
import { useAuthenticationContext } from 'Foundation/Authentication';
import i18n from 'i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router-dom';
import { Element } from 'react-scroll';
import { UserApiResponse, toastMessageMap } from '../../models/types';
import HbtExternalPIUserAdminContactInfo from '../HbtCardsExternal/ExternalUserAdminContactInfo';
import HbtExternalPIUserLevelAccess from '../HbtCardsExternal/ExternalUserLevelAccess';
import { UserAccessRecord } from '../HbtCardsExternal/ExternalUserLevelAccess/types';
import { addExternalUserRequest } from '../HbtUserAccessManagementApi/HbtUserAccessApiHandler.service';
import styles from './styles.module.scss';
import HbtAddExternalPIUserProps from './types';

const HbtAddExternalPIUser = ({ fields, rendering }: HbtAddExternalPIUserProps) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const authenticationContext = useAuthenticationContext();
  const [showUserActive, setShowUserActive] = useState<boolean>(true);
  const [addAccess, setAddAccess] = useState<boolean>(false);
  let [formPayloadData, setFormPayloadData] = useState<Object>({});
  const [toast, setToast] = useState<boolean>(false);
  const [toastMsg, setToastMessage] = useState<string>('');
  const [isSubmitFailed, setSubmitFailed] = useState<boolean>(false);
  const [isBypass, setBypass] = useState<boolean>(false);

  const externalSectionsMap: Record<string, React.FC<any>> = {
    HbtExternalPIUserAdminContactInfo,
    HbtExternalPIUserLevelAccess
  };
  const sectionsLookup = externalSectionsMap;

  const methods = useForm({
    resolver: joiResolver(frontEndAddExternalUserSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      emailID: '',
      contact: {
        firstName: '',
        lastName: '',
        extensionPhoneNumber: '',
        phoneNumber: ''
      },
      userAccesses: [
        {
          allowedTransitNumbers: [],
          defaultFinancialInstitutionCode: '',
          defaultTransitNumber: '',
          financialInstitutionCode: '',
          lenderRoleCode: '',
          userAuthorizationScope: [],
          statusCode: '',
          access: {
            admin: {
              activeFlag: false
            },
            arrears: {
              activeFlag: false
            },
            claim: {
              activeFlag: false
            },
            default: {
              activeFlag: false
            },
            piRequest: {
              activeFlag: false
            },
            piPurposeTest: {
              activeFlag: false
            },
            piFileShare: {
              activeFlag: false
            }
          }
        }
      ]
    }
  });

  const onToggleShowUserActive = () => {
    setShowUserActive(!showUserActive);
  };

  const handleAddAccessRow = () => {
    setAddAccess(!addAccess);
  };

  useEffect(() => {
    methods.register('userTypeCode' as any);
    methods.setValue('userTypeCode' as any, UserType.External);
    if (isServer() === false) {
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, []);

  const onApiError = async (err?: any): Promise<void> => {
    handleToastAction(SubmissionType.ERROR);
    setIsLoading(false);
    if (err != null && typeof err === 'number') {
      setToastMessage(toastMessageMap.get(err) ?? '');
    } else {
      setToastMessage(toastMessageMap.get(UserApiResponse.Error) ?? '');
    }
  };

  const handleToastAction = (type: SubmissionType) => {
    setBypass(type !== SubmissionType.ERROR);
    setSubmitFailed(type === SubmissionType.ERROR);
    setToast(type !== SubmissionType.OTHER);
  };

  const onAddUserApiCall = async () => {
    setIsLoading(true);
    const result = await addExternalUserRequest(authenticationContext, formPayloadData);

    switch (result) {
      case UserApiResponse.Created: // User added successfully
        setToastMessage(toastMessageMap.get(UserApiResponse.Created) ?? '');
        handleToastAction(SubmissionType.SUBMIT);
        setIsLoading(false);
        break;
      default:
        onApiError(result);
        break;
    }
  };

  const onSubmit = async (formData: any): Promise<void> => {
    const { userAccesses, ...contactInfo } = formData;
    let userAccessPayload: UserAccessRecord[] = [];
    userAccesses?.forEach((element: UserAccessRecord) => {
      // Create a new object without the userAccessID property
      const { userAccessID, ...elementWithoutID } = element;

      // Update other properties as needed
      elementWithoutID.access = {
        ...elementWithoutID.access,
        admin: { activeFlag: false }
      };
      elementWithoutID.lenderRoleCode === 101
        ? (elementWithoutID.access.admin.activeFlag = true)
        : (elementWithoutID.access.admin.activeFlag = false);

      userAccessPayload.push(elementWithoutID);
    });
    formPayloadData = {
      users: [{ ...contactInfo, userAccesses: userAccessPayload }]
    };
    setFormPayloadData({
      users: [{ ...contactInfo, userAccesses: userAccessPayload }]
    });
    onAddUserApiCall();
  };

  const toastNotificationProps: ToastNotificationProps = {
    isActive: toast,
    title: i18n.t(
      `Default${
        isSubmitFailed ? 'Submission-ErrorToast' : 'sInventoryTable-DecisioningToastMessage-'
      }Title`
    ),
    type: ApplicationStates[isSubmitFailed ? 'ERROR' : 'SUCCESS'],
    content: i18n.t(toastMsg),
    onCloseCallback: () => {
      setToast(false);
      if (!isSubmitFailed) {
        history.push(`/${i18n.language}${pathNames.userManagement}`);
      }
    }
  };

  const { dirtyFields } = methods.formState;

  // isDirty check
  useEffect(() => {
    if (isServer() === false) {
      // onbeforeunload should trigger on back, forward, refresh, and link button clicks
      window.onbeforeunload = () => {
        // trigger browser warning
        if (Object.keys(dirtyFields).length > 0 && !isBypass) {
          // returning anything, will trigger the prompt,
          // return string only read on IE:
          return i18n.t('DefaultSubmission-IsDirty');
        }
        return null;
      };
    }
  }, [dirtyFields, isBypass]);

  return (
    <>
      <ContentLoadingModal
        display={isLoading}
        fields={{
          heading: { value: i18n.t('Globals-Saving-Heading') },
          description: { value: i18n.t('Globals-Saving-Description') }
        }}
      />
      <ToastNotification {...toastNotificationProps} />

      <Prompt
        when={Object.keys(dirtyFields).length > 0 && !isBypass}
        message={i18n.t('DefaultSubmission-IsDirty')}
      />

      <div className={styles.addExternalPIUserMain}>
        <div className={styles.addExternalPIUserMainContainer}>
          <div className={styles.addExternalPIUserMainContent}>
            <div className={styles.addExternalPIUserHeaderContainer}>
              <h2 className={styles.addExternalPIUserTitle}>
                <Text field={fields.pageTitle} />
              </h2>
            </div>
            <div className={styles.addExternalPIUserFormContainer}>
              <FormProvider {...methods}>
                <form
                  id="addUserForm"
                  className="form add-user-form"
                  onSubmit={methods.handleSubmit(onSubmit)}
                  noValidate
                >
                  <div className={styles.addExternalPIUserFormBody}>
                    <div className="row">
                      <section className="col-12">
                        {rendering?.placeholders?.sections?.map((card: any, index: number) => (
                          <Element name={card.componentName} key={card.componentName + index}>
                            {sectionsLookup[card.componentName] != null &&
                              React.createElement(sectionsLookup[card.componentName], {
                                fields: card.fields,
                                key: index
                              })}
                          </Element>
                        ))}
                        <div className={styles.actionButtons}>
                          <div>
                            <Button
                              variant={ButtonVariant.PRIMARY}
                              name="addUserAccessBtn"
                              text={fields.userAccessBtnText}
                              ariaText={fields.userAccessBtnText}
                              onClick={handleAddAccessRow}
                              type="button"
                              disabled={!showUserActive}
                            />
                          </div>
                        </div>
                        <div className={styles.userActiveToggleContainer}>
                          <FormToggle
                            className={styles.externalUserActiveToggle}
                            onChange={onToggleShowUserActive}
                            isChecked={showUserActive}
                            id="showUserActive"
                            testId="showUserActive"
                            staticText={fields.activeUserBtnText?.value}
                          />
                        </div>
                        {rendering?.placeholders?.sectionAuth?.map((card: any, index: number) => (
                          <Element name={card.componentName} key={card.componentName + index}>
                            {sectionsLookup[card.componentName] != null &&
                              React.createElement(sectionsLookup[card.componentName], {
                                fields: card.fields,
                                key: index,
                                addAccessRow: addAccess,
                                handleAddAccessRow,
                                showUserActive
                              })}
                          </Element>
                        ))}
                      </section>
                    </div>
                    <div className={styles.addExternalPIUserFormActionBtnContainer}>
                      <div className={styles.addButtons}>
                        <Button
                          variant={ButtonVariant.PRIMARY}
                          name="submitBtn"
                          text={fields.submitBtnText}
                          ariaText={fields.submitBtnText}
                          type="submit"
                        />
                      </div>
                      <div className={styles.addButtons}>
                        <Button
                          variant={ButtonVariant.SECONDARY}
                          name="addUserCancelBtn"
                          text={fields.cancelBtnText}
                          ariaText={fields.cancelBtnText}
                          onClick={() =>
                            history.push(`/${i18n.language}${pathNames.userManagement}`)
                          }
                          type="button"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HbtAddExternalPIUser;
