import i18n from 'i18next';

export const defaultClaimSubmissionData = {
  // TODO: Review default values for borrowers card
  borrowers: Array(1).fill({
    birthDate: undefined,
    claimBorrowerCategoryID: '',
    defaultReasonCode: '',
    financialAssessmentCompletedFlag: false,
    firstName: '',
    judgmentActionTypeCode: '',
    judgmentBankruptcyInformationReceivedDate: undefined,
    informationSentToNationalRecoveriesCenterDate: undefined,
    lastName: '',
    otherDefaultReasonDescription: undefined,
    otherReasonNotPursuedDescription: undefined,
    reasonNotPursuedTypeCode: ''
  }),
  borrowerCharges: Array(5).fill({
    code: i18n.t('Dropdown-Option-PleaseSelect'),
    receivedDate: undefined,
    comment: ''
  }),
  exceptionToListingFlag: 'false',
  indicator: {
    claimProcessingUnitTypeCode: 1,
    enhancedAcppFlag: 'true',
    editsAndAllowancesFlag: 'true'
  },
  authorizedOfficers: Array(1).fill({}),
  listings: Array(1).fill({}),
  netOperatingIncomesRentalIncomes: Array(5).fill({
    receivedDate: undefined,
    comment: ''
  }),
  partialPayments: Array(5).fill({
    receivedDate: undefined,
    comment: ''
  })
};
