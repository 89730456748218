import i18n from 'i18next';
import React, { useState, useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { FinalBorrowerCharge, FinalListing } from '@hobt/claim-domain';
import { deepCopy } from '@hobt/utils';

import { pathNames } from 'Constants/pathNames';

import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';
import EntityInformationProps, { EntityInformationPropsFields } from './types';
import styles from './styles.module.scss';

const ARREARS_FILE_URL = pathNames.arrearsSubmittedReports;
const ARREARS_REPORTS_URL = pathNames.arrearsSummaryReports;

function mappedDescendingDateSort(firstListing: FinalListing, secondListing: FinalListing) {
  const firstDate = firstListing.appraisalDate.split('-').reverse().join('');
  const secondDate = secondListing.appraisalDate.split('-').reverse().join('');
  return secondDate.localeCompare(firstDate);
}

const EntityInformation: React.FC<EntityInformationProps> = ({
  fields,
  data,
  isArrears
}: EntityInformationProps) => {
  const [entityObj, setEntityObj] = useState<EntityInformationPropsFields>();
  const { toCurrency } = useTranslationHelpers();

  const getAppraisalValue = (listings: FinalListing) => {
    const listingsCopy = deepCopy(listings);
    if (Array.isArray(listingsCopy) && listingsCopy.length > 0) {
      if (listingsCopy.length === 1) {
        return listingsCopy[0].appraisalValue;
      }

      // Get the most recent appraisal date
      listingsCopy.sort(mappedDescendingDateSort);
      return listingsCopy[0].appraisalValue;
    }
  };

  const getTotalCharges = (borrowerCharges?: FinalBorrowerCharge[]) => {
    return borrowerCharges
      ?.map((charge: FinalBorrowerCharge) => Number(charge?.adjustedAmount))
      .reduce((prev: number, next: number) => prev + next);
  };

  useEffect(() => {
    if (data != null && !isArrears) {
      setEntityObj({
        appraisalValue: {
          value:
            data.listings != null
              ? toCurrency(getAppraisalValue(data.listings))
              : i18n.t('Global-Not-Available')
        },
        claimType: {
          value:
            data.claimTypeIndicator != null
              ? fields?.claimTypeIndicator?.fields?.listItems?.find?.(
                  (item) => Number(item?.fields?.itemValue?.value) === data.claimTypeIndicator
                )?.fields?.itemName?.value
              : i18n.t('Global-Not-Available')
        },
        claimFile: { value: `${pathNames.claimsAdjudication}?id=${data.claimID}` },
        claimPaid: {
          value:
            data.financialTransaction?.previousClaimPaidAmount != null
              ? toCurrency(data.financialTransaction.previousClaimPaidAmount)
              : i18n.t('Global-Not-Available')
        },
        loanNumber: { value: data.cmhcLoanAccountNumber ?? i18n.t('Global-Not-Available') },
        lenderLoanAmount: {
          value:
            data.loanDetail?.lenderLoanAmount != null
              ? toCurrency(data?.loanDetail?.lenderLoanAmount)
              : i18n.t('Global-Not-Available')
        },
        totalApproved: {
          value:
            Array.isArray(data?.borrowerCharges) && data?.borrowerCharges?.length > 0
              ? toCurrency(getTotalCharges(data.borrowerCharges))
              : i18n.t('Global-Not-Available')
        },
        paymentAmount: {
          value:
            data.financialTransaction?.netClaimPaymentAmount != null
              ? toCurrency(data?.financialTransaction?.netClaimPaymentAmount)
              : i18n.t('Global-Not-Available')
        },
        primaryApproverReview: { value: i18n.t('Global-Not-Available') },
        typeOfClaimLabel: {
          value:
            data.detail?.claimTypeCode != null
              ? fields?.typeOfClaim?.fields?.listItems?.find?.(
                  (item) => item?.fields?.itemValue.value === data.detail.claimTypeCode
                )?.fields?.itemName?.value
              : i18n.t('Global-Not-Available')
        }
      });
    }
  }, [data]);

  return (
    <div className="m-4 form">
      <div className="mb-3 form__element">
        <h4 className={styles.header4}>{fields.title?.value}</h4>
      </div>

      <div className="form-row">
        <div className="col">
          <small>
            <Text tag="b" field={(isArrears ? fields.arrearsFile : fields.claimFile) ?? {}} />
          </small>
        </div>
        <p className="col">
          <small>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={isArrears === true ? ARREARS_FILE_URL : entityObj?.claimFile?.value}
            >
              <Text
                tag="u"
                field={
                  (isArrears ? fields.arrearsFile : fields.claimFile) || {
                    value: i18n.t('Global-Not-Available')
                  }
                }
              />
            </a>
          </small>
        </p>
      </div>

      <hr className="form__divider"></hr>

      {isArrears === true && (
        <>
          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.arrearsReports ?? {}} />
              </small>
            </div>
            <p className="col">
              <small>
                <a target="_blank" rel="noopener noreferrer" href={ARREARS_REPORTS_URL}>
                  <Text tag="u" field={fields.arrearsReports ?? {}} />
                </a>
              </small>
            </p>
          </div>
          <hr className="form__divider"></hr>
        </>
      )}

      {!isArrears && (
        <>
          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.loanNumber} />
              </small>
            </div>
            <p className="col">
              <small>
                <Text field={entityObj?.loanNumber ?? {}} />
              </small>
            </p>
          </div>

          <hr className="form__divider"></hr>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields?.typeOfClaimLabel ?? {}} />
              </small>
            </div>
            <p className="col">
              <small>
                <Text field={entityObj?.typeOfClaimLabel ?? {}} />
              </small>
            </p>
          </div>

          <hr className="form__divider"></hr>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.claimType} />
              </small>
            </div>
            <p className="col">
              <small>
                <Text field={entityObj?.claimType ?? {}} />
              </small>
            </p>
          </div>

          <hr className="form__divider"></hr>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.paymentAmount} />
              </small>
            </div>
            <p className="col">
              <small>
                <Text field={entityObj?.paymentAmount ?? {}} />
              </small>
            </p>
          </div>

          <hr className="form__divider"></hr>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.claimPaid} />
              </small>
            </div>
            <p className="col">
              <Text tag="small" field={entityObj?.claimPaid ?? {}} />
            </p>
          </div>

          <hr className="form__divider"></hr>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.totalApproved} />
              </small>
            </div>
            <p className="col">
              <Text tag="small" field={entityObj?.totalApproved ?? {}} />
            </p>
          </div>

          <hr className="form__divider"></hr>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.appraisalValue} />
              </small>
            </div>
            <p className="col">
              <Text tag="small" field={entityObj?.appraisalValue ?? {}} />
            </p>
          </div>

          <hr className="form__divider"></hr>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.lenderLoanAmount} />
              </small>
            </div>
            <p className="col">
              <Text tag="small" field={entityObj?.lenderLoanAmount ?? {}} />
            </p>
          </div>

          <hr className="form__divider"></hr>
        </>
      )}
    </div>
  );
};

export default EntityInformation;
