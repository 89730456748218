import * as React from 'react';

import { ModuleMapping, UserRole, ModuleName } from '@hobt/constants';
import DashboardCardsProps from 'Feature/DashboardComponents/models/DashboardCardsProps';
import DashboardNavigationCardProps from 'Feature/DashboardComponents/models/DashboardNavigationCardProps';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import DashboardNavigationCard from '../DashboardNavigationCard/index';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const DashboardCards = (props: DashboardCardsProps) => {
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;

  const admin = isUserInRoles(ModuleMapping.admin, [UserRole.CmhcAdministrator], moduleRoleMapping);
  const lenderAdmin = isUserInRoles(
    ModuleMapping.admin,
    [UserRole.LenderAdministrator],
    moduleRoleMapping
  );

  const cards: DashboardNavigationCardProps[] = props.fields.cards?.filter(
    (card: DashboardNavigationCardProps) => {
      const cardName = card.fields.name?.value;

      if (cardName === 'users') {
        return admin || lenderAdmin;
      }
      if (moduleRoleMapping != null) {
        return (
          Object.keys(moduleRoleMapping)?.indexOf(
            ModuleMapping[cardName as ModuleName]?.toString(10)
          ) !== -1
        );
      }

      return false;
    }
  );

  return (
    <>
      {cards?.map((card: DashboardNavigationCardProps, index: number) => (
        <div key={index} className="mb-4">
          <DashboardNavigationCard {...card} cardId={`card-${index}`} />
        </div>
      ))}
    </>
  );
};

export default DashboardCards;
