import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFeature } from 'flagged';

import { ModuleKeyword, UserRole, ModuleMapping } from '@hobt/constants';

import getPathInObject from 'Constants/Utils/GetPathInObject';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

interface ModuleActiveState {
  type: ModuleKeyword;
  activeFlag: boolean;
}

export const useLevelAccessFunctions = (
  editableModules: ModuleKeyword[],
  isModify: boolean | undefined,
  userData: any
) => {
  const { watch, setValue, getValues, register, unregister } = useFormContext();

  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  // TODO: Manually tracking active flags since the FormToggle component doesn't register
  const [moduleActiveStates, setModuleActiveStates] = useState<ModuleActiveState[]>([]);

  const [showProbationaryInput, setShowProbationaryInput] = useState<boolean>(false);

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;

  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.admin,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  useEffect(() => {
    if (!isModify) {
      // Default all to false when adding a new user
      setModuleActiveStates(editableModules.map((x) => ({ type: x, activeFlag: false })));

      // TODO: Manually registering active flags since the FormToggle component doesn't register
      editableModules.forEach((x) => {
        register(`userAccesses[0].access.${x}.activeFlag`);
        setValue(`userAccesses[0].access.${x}.activeFlag`, false);
      });

      // TODO: Admin access required for now by schema.  Remove once schema/backend is updated.
      register(`userAccesses[0].access.${ModuleKeyword.Admin}.activeFlag`);
      setValue(`userAccesses[0].access.${ModuleKeyword.Admin}.activeFlag`, false);
    }
  }, []);

  useEffect(() => {
    if (userData != null) {
      const existingActiveModules: any = [];

      editableModules.forEach((x) => {
        const userAccess = getPathInObject(userData.userAccesses[0].access, x);
        existingActiveModules.push({ type: x, activeFlag: userAccess.activeFlag });
      });

      setModuleActiveStates(existingActiveModules);
    }
  }, userData);

  const toggleModuleActive = (accessType: ModuleKeyword) => {
    const currentAccess = moduleActiveStates?.find((x) => x.type === accessType);

    if (currentAccess !== undefined) {
      const newAccess = !currentAccess.activeFlag; // toggle

      setValue(`userAccesses[0].access.${accessType}`, { activeFlag: newAccess });

      setModuleActiveStates(
        moduleActiveStates.map((x) => (x.type === accessType ? { ...x, activeFlag: newAccess } : x))
      );
    }
  };

  const toggleShowProbationaryInput = () => {
    if (siteTypeIsInternal) {
      const claimActiveState = moduleActiveStates?.find((x) => x.type === ModuleKeyword.Claim);

      if (claimActiveState != null) {
        if (claimActiveState.activeFlag === true) {
          const claimRole = getValues('userAccesses[0].access.claim.roleCode');

          if (claimRole === UserRole.BusinessUser.toString()) {
            setShowProbationaryInput(true);
          } else {
            setShowProbationaryInput(false);
          }
        } else {
          setShowProbationaryInput(false);
        }
      }
    }
  };

  useEffect(() => {
    toggleShowProbationaryInput();
  }, [
    watch('userAccesses[0].access.claim.activeFlag'),
    watch('userAccesses[0].access.claim.roleCode')
  ]);

  useEffect(() => {
    const probation = userData?.userAccesses[0]?.access?.claim?.probationaryUserFlag;

    if (showProbationaryInput === true && probation != null) {
      register('userAccesses[0].access.claim.probationaryUserFlag');
      setValue('userAccesses[0].access.claim.probationaryUserFlag', probation.toString());
    }

    if (showProbationaryInput === false) {
      unregister('userAccesses[0].access.claim.probationaryUserFlag');
    }
  }, [showProbationaryInput]);

  return {
    isReadOnlyUser,
    moduleActiveStates,
    toggleModuleActive,
    showProbationaryInput
  };
};
