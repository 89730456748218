import { AxiosError, AxiosResponse } from 'axios';
import { ApiClient, ApiClientConfig } from '../../../../Foundation/Api';
import { config } from '../../../../config';
import { DataObject, ErrorObject, NotificationApiResponse, ReadStatus } from '../../models/types';

const callNotificationUrl: string = config.notificationApi.urls.notification;

const apiClientConfig: ApiClientConfig = { timeout: config.notificationApi.requestTimeout };
const pathError = new Error('Invalid configuration. Could not read URL or path for API calls.');

export const errorCatch = (err: ErrorObject): number => {
  if (err && err.response && err.response.status) {
    switch (err.response.status) {
      case 400:
        return NotificationApiResponse.BadRequest;
      case 401:
        return NotificationApiResponse.NotAuthorized;
      case 500:
      default:
        return NotificationApiResponse.Error;
    }
  } else {
    // err.response.status is undefined
    return NotificationApiResponse.Error;
  }
};

export const getNotificationRequest = async (
  authContext: any,
  id?: ReadStatus
): Promise<DataObject> => {
  const route = `${callNotificationUrl}${
    id !== undefined ? `?filter_by=NotificationStatus=${id}` : ''
  }`;

  if (route !== undefined) {
    return ApiClient(authContext, apiClientConfig)
      .getWithAuth(route)
      .then((res: AxiosResponse<DataObject>) => res.data)
      .catch((err: AxiosError) => Promise.reject(err));
  }
  throw pathError;
};

export const updateNotificationRequest = (authContext: any, formData: any): Promise<number> => {
  const route = `${callNotificationUrl}/${formData}`;

  if (route !== undefined && formData !== undefined) {
    return ApiClient(authContext, apiClientConfig)
      .putWithAuth(route, formData)
      .then(() => {
        return NotificationApiResponse.Updated;
      })
      .catch((err: ErrorObject) => {
        Promise.reject(err);
        return errorCatch(err);
      });
  }
  throw pathError;
};

export const updateAllNotificationRequest = (authContext: any): Promise<number> => {
  const route = callNotificationUrl;

  if (route !== undefined) {
    return ApiClient(authContext, apiClientConfig)
      .putWithAuth(route, '')
      .then(() => {
        return NotificationApiResponse.Updated;
      })
      .catch((err: ErrorObject) => {
        Promise.reject(err);
        return errorCatch(err);
      });
  }
  throw pathError;
};
