import * as React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import ContentBlockInCardProps from 'Feature/PageComponents/models/ContentBlockInCardProps';

const ContentBlockInCard = (props: ContentBlockInCardProps) => (
  <div className="card__header--tabbedSection">
    <h2 className="card__header-title">
      <Text field={props.fields.heading.field} />
    </h2>
    {props.fields.subheading &&
      `${(<Text tag="h6" field={props.fields.subheading.field} />)}<hr />`}
    <RichText field={props.fields.content.field} />
  </div>
);

export default ContentBlockInCard;
