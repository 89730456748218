import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthenticationContextType } from 'Foundation/Authentication';
import { config } from '../../config';
import { ApiClientConfig } from './models/ApiClientConfig.type';

const scopes = [config.api.hbtApiScope!];

export const ApiClient = (
  authenticationContext: AuthenticationContextType,
  apiClientConfig: ApiClientConfig
) => {
  const axiosInstance = axios.create(apiClientConfig);
  const cancelTokenSource = axios.CancelToken.source();

  /**
   * Get Function with Authentication
   * @param resourceUrl Url to send the GET request to
   * @returns { Promise<AxiosResponse> } Returns a promise with and AxiosResponse by default. In the case where an error is thrown from the api request, a rejected promise is thrown with an AxiosError in the reason.
   */
  const getWithAuth = async (
    resourceUrl: string,
    requestConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    try {
      const token = await authenticationContext.getToken(scopes);

      const additionalConfig = requestConfig || { cancelToken: cancelTokenSource.token };

      const fullRequestConfig = {
        ...additionalConfig,
        headers: { Authorization: `Bearer ${token}` }
      };

      const res: AxiosResponse = await axiosInstance.get(resourceUrl, fullRequestConfig);

      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  /**
   * Get Function with Authentication
   * @param resourceUrl Url to send the GET request to
   * @param data get payload
   * @param requestConfig
   * @returns {Promise<AxiosResponse>} Returns a promise with and AxiosResponse by default. In the case where an error is thrown from the api request, a rejected promise is thrown with an AxiosError in the reason.
   */
  const getWithAuthAndBody = async (
    resourceUrl: string,
    data: any,
    requestConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    try {
      const token = await authenticationContext.getToken(scopes);

      const additionalConfig = requestConfig || { cancelToken: cancelTokenSource.token };

      const fullRequestConfig = {
        ...additionalConfig,
        headers: { Authorization: `Bearer ${token}` }
      };

      const res: AxiosResponse = await axiosInstance.get(resourceUrl, {
        ...fullRequestConfig,
        data
      });

      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  /**
   * Post Submission with Authentication
   * @param resourceUrl Url to send the POST request
   * @param data Data sent in the POST Request
   * @param requestConfig Request config
   * @returns {Promise<AxiosResponse>} If response is anything other than a 200, returns a rejected promise with an AxiosError as the reason.
   */
  const postWithAuth = async (
    resourceUrl: string,
    data: any,
    requestConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    try {
      const token = await authenticationContext.getToken(scopes);

      const additionalConfig = requestConfig || { cancelToken: cancelTokenSource.token };

      const fullRequestConfig = {
        ...additionalConfig,
        headers: { Authorization: `Bearer ${token}` }
      };

      const res: AxiosResponse = await axiosInstance.post(resourceUrl, data, fullRequestConfig);

      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  };
  const postWithHeaderAuth = async (
    resourceUrl: string,
    data: any,
    isUserFile: boolean,
    requestConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    try {
      const token = await authenticationContext.getToken(scopes);

      const additionalConfig = requestConfig || { cancelToken: cancelTokenSource.token };

      const fullRequestConfig: any = {
        ...additionalConfig,
        headers: isUserFile
          ? { Authorization: `Bearer ${token}`, token: `Bearer ${token}` }
          : { Authorization: `Bearer ${token}` }
      };

      const res: AxiosResponse = await axiosInstance.post(resourceUrl, data, fullRequestConfig);

      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const putWithAuth = async (
    resourceUrl: string,
    data: any,
    requestConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    try {
      const token = await authenticationContext.getToken(scopes);

      const additionalConfig = requestConfig || { cancelToken: cancelTokenSource.token };

      const fullRequestConfig = {
        ...additionalConfig,
        headers: { Authorization: `Bearer ${token}` }
      };

      const res: AxiosResponse = await axiosInstance.put(resourceUrl, data, fullRequestConfig);

      return res;
    } catch (e) {
      console.log('error ', e);
      return Promise.reject(e);
    }
  };

  const patchWithAuth = async (
    resourceUrl: string,
    data: any,
    requestConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    try {
      const token = await authenticationContext.getToken(scopes);

      const additionalConfig = requestConfig || { cancelToken: cancelTokenSource.token };

      const fullRequestConfig = {
        ...additionalConfig,
        headers: { Authorization: `Bearer ${token}` }
      };

      const res: AxiosResponse = await axiosInstance.patch(resourceUrl, data, fullRequestConfig);

      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  /**
   * Update (PUT) Submission with Authentication
   * @param resourceUrl Url to send the POST request
   * @param data Data sent in the POST Request
   * @param requestedScopes Requested scopes used to get token to send in headers (auth)
   * @returns {Promise<AxiosResponse>} If response is anything other than a 200, returns a rejected promise with an AxiosError as the reason.
   */
  const putUpdate = async (
    resourceUrl: string,
    data: any,
    requestConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    try {
      const token = await authenticationContext.getToken(scopes);

      const additionalConfig = requestConfig || { cancelToken: cancelTokenSource.token };

      const fullRequestConfig = {
        ...additionalConfig,
        headers: { Authorization: `Bearer ${token}` }
      };

      const res: AxiosResponse = await axiosInstance.put(resourceUrl, data, fullRequestConfig);

      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  };
  const deleteWithAuth = async (
    resourceUrl: string,
    data?: any,
    requestConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    try {
      const token = await authenticationContext.getToken(scopes);

      const additionalConfig = requestConfig || { cancelToken: cancelTokenSource.token };

      const fullRequestConfig = {
        ...additionalConfig,
        headers: { Authorization: `Bearer ${token}` },
        data
      };

      const res: AxiosResponse = await axiosInstance.delete(resourceUrl, fullRequestConfig);

      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  };
  return {
    deleteWithAuth,
    getWithAuth,
    postWithAuth,
    putWithAuth,
    patchWithAuth,
    putUpdate,
    getWithAuthAndBody,
    cancelTokenSource,
    postWithHeaderAuth
  };
};
