import { HttpResponseStatusCodes } from '@hobt/constants';

import {
  SitecoreCardNameField,
  ValidationObjectNamespace
} from 'Feature/Claims/components/Cards/DisplayNotificationCard';
import { FieldErrors, UseFormReturn } from 'react-hook-form';

export enum ArrearsSubmitResponse {
  Error,
  Success,
  BadRequest,
  ServerError,
  NotAuthorized,
  FileUploadError,
  NoAccessToFiCode,
  NoAccessToTransit,
  InvalidFiCode,
  InvalidTransitForFiCode,
  MissingFileParam,
  MaxFileCountReached
}

type ErrorEntity = {
  errorCode?: any;
  errorMessage?: string;
};

export type DataObject = {
  status?: HttpResponseStatusCodes;
  data: any | any[];
  error: ErrorEntity;
  statusCode: number;
};

type Response = {
  status?: number | string;
  data: DataObject;
};

export type ErrorObject = {
  response?: Response;
};

type Contact = {
  name: string;
  emailID: string;
  extensionPhoneNumber?: string;
  phoneNumber: string;
  approvedLenderCode: string;
  correspondingLanguageCode: number;
  financialInstitutionCode: string;
};

export type ArrearsFileData = {
  fileName: string;
  documentID: string;
  fileByteCount: number;
  submitterUserID: string;
  submitterUserTypeCode: number;
  recordCreatedTimestamp: string;
};

export type ArrearsFilesObject = {
  attachment: ArrearsFileData;
  transitNumber: string;
};

export type ArrearsSubmissionObject = {
  contact: Contact;
  arrearsUploadRemark?: string;
};

export type SubmittedArrearsType = {
  arrearsFiles: ArrearsFilesObject[];
  submission?: ArrearsSubmissionObject;
};

export const sitecoreComponentToFieldNameMap: Record<
  SitecoreCardNameField,
  ValidationObjectNamespace
> = {
  contactDetails: 'contact',
  arrearsReport: 'arrearsReport',
  additionalRemarks: 'remarkText'
};
