import React from 'react';

export interface LinkProps {
  href?: string;
  ariaLabel?: string;
  size?: LinkSize;
  leadingIcon?: () => JSX.Element;
  trailingIcon?: () => JSX.Element;
  className?: string;
  children?: React.ReactNode;
}

export enum LinkSize {
  NORMAL = 'normal',
  SMALL = 'small'
}
