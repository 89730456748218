import React, { useState, useEffect, useRef } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { Element } from 'react-scroll';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useFeature } from 'flagged';
import i18n from 'i18next';
import { ImageProps, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { approvalFrontendSchema } from '@hobt/arrears-domain';
import { approveFinalClaimSchema, FinalClaim, StageIndicator } from '@hobt/claim-domain';
import { HbtValidationErrorCodes, ModuleMapping, UserRole } from '@hobt/constants';
import { hbtResolver } from '@hobt/schema-validator';
import { getCurrentTimestampDate10Decimal6 } from '@hobt/utils';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { Button } from 'Components/Common/Button';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { NotificationCard, ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { ApplicationStates, CardType } from 'Feature/CommonComponents/Enums';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import {
  getClaimApprovalInfo,
  submitApprovalForm,
  submitArrearsApproval
} from './ApprovalForm.service';
import EntityInformation from '../Cards/EntityInformation';
import ApproverReview from '../Cards/ApproverReview';
import ApprovalStage from '../Cards/ApprovalStage';
import ApprovalFormProps, {
  ApprovalComponentProps,
  ApprovalPayload,
  ApprovalsSubmitResponse,
  AttachmentDetails,
  SchemaErrorObject
} from './types';
import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const formMapping: Record<string, React.FC<any>> = {
  approvalStage: ApprovalStage,
  entityInformation: EntityInformation,
  approverReview: ApproverReview
};

const ARREARS_APPROVED = 3;

const ApprovalForm = ({
  fields,
  isActive,
  isArrears,
  isClaimApproved,
  approvalId,
  approverLevel,
  loadingStatus,
  setIsLoadingCallback,
  sideMenuClickHandler,
  onSubmitCallback
}: ApprovalFormProps) => {
  const authContext = useAuthenticationContext();
  const { claimData, uuid } = useHBTFormContext();
  const isInternalSite = useFeature(FeatureFlags.INTERNAL);
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  let isReadOnlyUser: boolean | undefined;

  if (isInternalSite === true) {
    const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;

    isReadOnlyUser =
      isArrears === true
        ? isUserInRoles(ModuleMapping.arrears, [UserRole.ReadOnly], moduleRoleMapping)
        : isUserInRoles(ModuleMapping.claim, [UserRole.ReadOnly], moduleRoleMapping);
  }

  const [formInfo, setFormInfo] = useState<any>();
  const [toastMsg, setToastMsg] = useState<string>('');
  const [isSubmitFailed, setSubmitFailed] = useState<boolean>(false);
  const [isApprovalsToast, setApprovalsToast] = useState<boolean>(false);
  const [isAuthorizationError, setIsAuthorizationError] = useState<boolean>(false);

  const errorNotificationRef = useRef<HTMLDivElement | null>(null);

  const methods = useForm({
    resolver: hbtResolver(isArrears === true ? approvalFrontendSchema : approveFinalClaimSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  } as Record<string, any>);

  const claimID = approvalId || uuid || '';

  useEffect(() => {
    if (isActive) {
      if (!isArrears) {
        if (Array.isArray(claimData) && claimData.length === 0) {
          getClaimApprovalInfo(authContext, claimID).then((res: FinalClaim) => setFormInfo(res));
        } else {
          setFormInfo(claimData);
        }
      }
    }
  }, [isActive]);
  const sideMenuOnClose = () => {
    sideMenuClickHandler();
    setIsAuthorizationError(false);
    window.sessionStorage.removeItem('arrearsApprovalData');
  };

  const setAuthorizationError = () => {
    setIsAuthorizationError(true);
    setIsLoadingCallback?.({
      isLoading: false
    });

    if (errorNotificationRef?.current != null) {
      errorNotificationRef.current.scrollIntoView();
    }
  };

  const getErrorMessage = (result: ApprovalsSubmitResponse) => {
    switch (result) {
      case ApprovalsSubmitResponse.ClaimMinimumRole:
        return `ApprovalForm-ErrorToast-${claimID != null ? 'DelegatedAuth' : 'ClaimMinimumRole'}`;
      case ApprovalsSubmitResponse.ArrearsMinimumRole:
        return `ApprovalForm-ErrorToast-ArrearsMinimumRole`;
      case ApprovalsSubmitResponse.MissingPayeeDetails:
        return 'ApprovalForm-ErrorToast-MissingPayeeDetails';
      case ApprovalsSubmitResponse.NotAuthorized:
        return 'ApprovalForm-ErrorToast-DelegatedAuth';
      case ApprovalsSubmitResponse.Error:
      case ApprovalsSubmitResponse.BadRequest:
        return 'Globals-Toast-Server-Error-Title';
      default:
        return `ApprovalForm-ErrorToast-${ApprovalsSubmitResponse[result]}`;
    }
  };

  const onError = (result: ApprovalsSubmitResponse) => {
    if (result === ApprovalsSubmitResponse.ProbationaryUserNotAuthorized) {
      setAuthorizationError();
    } else {
      setSubmitFailed(true);
      setApprovalsToast(true);
      sideMenuClickHandler();
      setToastMsg(`${getErrorMessage(result)}`);
      setIsLoadingCallback?.({
        isLoading: false
      });
    }
  };

  const onSubmit = async () => {
    let documentIDs: string[] = [];
    const formData: ApprovalPayload = methods.getValues();

    const { approverReview, attachments } = formData;

    if (Array.isArray(attachments) && attachments?.length > 0) {
      documentIDs = attachments.map((attachment: AttachmentDetails) => attachment.documentID);
    }

    const submitPayload = {
      ...(Array.isArray(attachments) && attachments?.length > 0 && { attachments }),
      approverReview: {
        ...approverReview,
        ...(documentIDs.length > 0 && { documentIDs }),
        decisionTimestamp: getCurrentTimestampDate10Decimal6(),
        approvalDecisionCode: Number(approverReview?.approvalDecisionCode),
        ...(!isArrears && {
          approvalOnBehalfOfOtherFlag: approverReview?.approvalOnBehalfOfOtherFlag === true
        })
      }
    };

    setIsLoadingCallback?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description',
      isFlyout: true
    });

    let result: any;
    if (isArrears === true) {
      result = await submitArrearsApproval(authContext, submitPayload);
    } else {
      result = await submitApprovalForm(authContext, claimID, submitPayload, onSubmitCallback);
    }

    switch (result) {
      case ApprovalsSubmitResponse.Success:
        setToastMsg('ApprovalForm-SuccessToast-ThankYou');
        setApprovalsToast(true);
        setSubmitFailed(false);
        sideMenuOnClose();
        setIsLoadingCallback?.({
          isLoading: false
        });
        methods.reset();
        break;
      default:
        onError(result);
        break;
    }
  };

  const onInvalid: any = async (err: SchemaErrorObject): Promise<void> => {
    // TODO: Remove console.log statements in the nearby future
    // Only added this to help troubleshoot any issues
    /* eslint-disable no-console */
    console.log('FORM ERRORS: ', err);
    console.log('Values', methods.getValues());

    if (
      err.approverReview?.message?.indexOf(
        HbtValidationErrorCodes.HBT_ERR_APPROVE_BEHALF_OF_OTHER_PERSON_DOCUMENTID_REQUIRED.code
      ) !== -1 &&
      err.approverReview?.type === 'errorMessage'
    ) {
      onError(ApprovalsSubmitResponse.BehalfOfOther);
    }
  };

  return (
    <>
      <ToastNotification
        type={ApplicationStates[isSubmitFailed ? 'ERROR' : 'SUCCESS']}
        isActive={isApprovalsToast}
        title={i18n.t(`Globals-Toast-${isSubmitFailed ? 'Error' : 'Success'}-Title`)}
        content={{ value: i18n.t(toastMsg) }}
        onCloseCallback={() => setApprovalsToast(false)}
      />
      <SideDrawer isActive={isActive} handleOutsideClick={sideMenuOnClose}>
        <Scrollbars>
          <div className={styles.headerWrapper}>
            <div>
              <Text tag="h2" field={fields.title} />

              <div className={styles.closeButton}>
                <button
                  data-testid={`ApprovalFormCloseButton`}
                  type="button"
                  onClick={sideMenuOnClose}
                  aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
                >
                  <span className="material-icons align-self-center">close</span>
                </button>
              </div>
            </div>
            {approverLevel !== ARREARS_APPROVED && (
              <div className={styles.headerWrapperSubtext}>
                <Text
                  tag="small"
                  field={
                    fields[
                      approverLevel === StageIndicator.Secondary ? 'level2Label' : 'level1Label'
                    ]
                  }
                />
              </div>
            )}
          </div>

          {isAuthorizationError && (
            <div className={`${styles.notificationCard} col-12`} ref={errorNotificationRef}>
              <NotificationCard
                id="errorCardId"
                notificationType={CardType.VERTICAL_ERROR}
                formWarningIcon={fields?.iconError?.value as ImageProps}
                message={[fields?.ToastMessageAuthorization?.value ?? '']}
                title={fields?.titleToastMessageAuthorization?.value ?? ''}
              />
            </div>
          )}

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit, onInvalid)}>
              <div className={`col-12 ${styles.contentDividerHr}`}>
                {fields?.formContent?.map((card: ApprovalComponentProps, index: number) => {
                  if (
                    card?.fields?.name?.value != null &&
                    typeof formMapping[card.fields.name.value] !== 'undefined'
                  ) {
                    return (
                      <Element name={card.fields.name.value}>
                        {React.createElement(formMapping[card.fields.name.value], {
                          isArrears,
                          isClaimApproved,
                          key: index,
                          approverLevel,
                          data: formInfo,
                          fields: card.fields
                        })}
                      </Element>
                    );
                  }
                  return null;
                })}
              </div>

              <hr className={styles.contentDivider} />

              <div className={`footer-buttons ${styles.footerMargin} col p-3`}>
                <div className="col-6">
                  <Button
                    type="submit"
                    name="primary-button"
                    text={fields?.submitBtn ?? {}}
                    ariaText={fields?.submitBtn ?? {}}
                    disabled={
                      isReadOnlyUser ||
                      approverLevel === ARREARS_APPROVED ||
                      isClaimApproved === true ||
                      (loadingStatus?.isLoading && loadingStatus?.isFlyout)
                    }
                  />
                </div>
                <div className="col-6">
                  <Button
                    type="button"
                    secondaryButton
                    name="secondary-button"
                    onClick={sideMenuOnClose}
                    text={fields?.cancelBtn ?? {}}
                    ariaText={fields?.cancelBtn ?? {}}
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        </Scrollbars>
      </SideDrawer>
    </>
  );
};

export default ApprovalForm;
