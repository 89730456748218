import * as React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Left8Right4Props from './types';
import styles from './styles.module.scss';

const Left8Right4 = (props: Left8Right4Props) => (
  <div className={styles.layoutContainer}>
    <div>
      <Placeholder name="hbt-left-8" rendering={props.rendering} />
    </div>
    <div>
      <Placeholder name="hbt-right-4" rendering={props.rendering} />
    </div>
  </div>
);

export default Left8Right4;
