import * as React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Button } from 'Components/Common/Button';
import { PageCardHeaderWithAddButtonProps } from './types';
import styles from './styles.module.scss';

export const PageCardHeaderWithAddButton: React.FC<PageCardHeaderWithAddButtonProps> = ({
  title,
  addButtonText,
  addButtonIsSecondary,
  addButtonCallback,
  type,
  disabled,
  cssClassName
}: PageCardHeaderWithAddButtonProps) => (
  <div
    className={`${cssClassName ? styles[cssClassName ?? ''] : ''.trim()}  ${
      styles.cardHeader
    }`.trim()}
  >
    <div>
      <Text tag="h2" field={title} />
    </div>
    <div>
      <Button
        text={addButtonText}
        ariaText={addButtonText}
        name={
          addButtonText.value !== undefined
            ? `${addButtonText.value.replace(/\s/g, '')}Button`
            : 'cardHeaderAddButton'
        }
        onClick={addButtonCallback}
        secondaryButton={addButtonIsSecondary}
        icon={() => <span className="material-icons align-self-center">control_point</span>}
        type={type}
        disabled={disabled}
      />
    </div>
  </div>
);

export type { PageCardHeaderWithAddButtonProps };
