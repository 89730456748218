import * as React from 'react';
import i18n from 'i18next';
import { Column, TableHeaderProps } from './TableHeader.types';
import { SortIndicator } from '../SortIndicator/SortIndicator';

export const TableHeader: React.FC<TableHeaderProps> = (props: TableHeaderProps) => {
  const handleSortClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    props.onSortHandle(e);
  };

  return (
    <tr className="table__row">
      <th className="table__header" key={`th-col-select`} scope="col">
        <span className="sr-only">
          `${i18n.t('DefaultsInventoryTable-HeaderFirstColumnHiddenText')}
        </span>
      </th>
      {props.columns.map((col: Column) => {
        const tableHeadAttributesBase = {
          className: 'table__header',
          scope: 'col'
        };

        const tableHeadAttributes = col.isVisible
          ? {
              ...tableHeadAttributesBase,
              'aria-label':
                i18n.t('DefaultsInventoryTable-HeaderSortAriaLabel') + i18n.t(col.displayText)
            }
          : tableHeadAttributesBase;

        return (
          <th {...tableHeadAttributes} key={`th-col-${col.fieldName}`}>
            {/* If the column is visible and sortable */}
            {col.isSortable && col.isVisible && (
              <React.Fragment>
                <a
                  href="#"
                  onClick={handleSortClick}
                  data-fieldname={col.fieldName}
                  className={col.isSortActive ? 'sortLink active' : 'sortLink'}
                  role="button"
                  aria-label={`${i18n.t('DefaultsInventoryTable-HeaderSortAriaLabel')} ${i18n.t(
                    col.displayText
                  )}`}
                >
                  {i18n.t(col.displayText)}
                  {col.isSortActive ? (
                    <SortIndicator indicatorState={col.sortDirection} />
                  ) : (
                    <SortIndicator />
                  )}
                </a>
              </React.Fragment>
            )}
            {col.isVisible && !col.isSortable && i18n.t(col.displayText)}
          </th>
        );
      })}
    </tr>
  );
};
