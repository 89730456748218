import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AssessmentCalculationsContext } from 'Feature/Claims/components/Details/AssessmentCalculations';
import { TabItemProps } from 'Components/Navigation/Tabs';
import UserComments from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/LetterComments/UserComments';
import SystemComments from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/LetterComments/SystemComments';

const useLetterComments = (onClaimCalculationCancel?: Function) => {
  const { sitecoreContent } = useContext(AssessmentCalculationsContext);

  const userCommentsTitle =
    sitecoreContent?.letterCommentsFlyout?.fields?.userCommentsTab?.fields?.userTitleTab;
  const systemCommentsTitle =
    sitecoreContent?.letterCommentsFlyout?.fields?.systemCommentsTab?.fields?.systemTitleTab;

  const tabItems: TabItemProps[] = [
    {
      displayText: userCommentsTitle ?? { value: '' },
      name: 'UserComments'
    },
    {
      displayText: systemCommentsTitle ?? { value: '' },
      name: 'SystemComments'
    }
  ];

  const [currentTab, setCurrentTab] = useState<TabItemProps>(tabItems[0]);
  const onTabClick = (tab: TabItemProps) => setCurrentTab(tab);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hookForm = useForm({
    // resolver: hbtResolver(),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  });

  const tabContentLookup: Record<string, React.FC<any>> = {
    UserComments,
    SystemComments
  };

  // Render tab content function
  const renderTabContent = () => {
    if (currentTab != null) {
      return React.createElement(tabContentLookup[currentTab.name], currentTab);
    }
    return null;
  };

  const cancelFlyout = () => {
    hookForm.reset();
    if (onClaimCalculationCancel != null) {
      onClaimCalculationCancel();
    }
  };

  return {
    tabItems,
    cancelFlyout,
    onClaimCalculationCancel,
    hookForm,
    onTabClick,
    isLoading,
    renderTabContent
  };
};

export default useLetterComments;
