import React, { ChangeEventHandler, FC } from 'react';
import { useFormContext } from 'react-hook-form';
import i18next from 'i18next';
import get from 'Constants/Utils/GetPathInObject';
import FormRadioWrapper, {
  Props as FormRadioWrapperProps
} from 'Components/Inputs/FormRadioWrapper';

import styles from './styles.module.scss';

type Props = {
  label: FormRadioWrapperProps['label'];
  className: string;
  name: string;
  watch?: FormRadioWrapperProps['watch'];
  isReadOnly?: boolean;
  testid?: string;
  handleChange?: ChangeEventHandler;
};

const FormYesNoRadio: FC<Props> = ({
  name,
  label,
  className,
  testid,
  isReadOnly,
  handleChange
}) => {
  const {
    register,
    formState: { errors },
    getValues
  } = useFormContext();
  const message = get(errors, `${name}.message`);
  const radioLabels = [
    i18next.t('DefaultSubmission-Card-RadioButtonYes'),
    i18next.t('DefaultSubmission-Card-RadioButtonNo')
  ];

  const getValueValue = getValues(name);
  const selectedValue = getValueValue === 'true' || getValueValue === true;

  if (isReadOnly === true) {
    // Manually register as we won't bind to an element to satisfy accessibility requirements
    register(name);

    return (
      <FormRadioWrapper name={name} label={label} className={className}>
        {[...radioLabels].map((choice, index) => (
          <label key={choice} className={styles['radio-label-wrapper']}>
            <div
              className={styles['radio-wrapper']}
              role="radio"
              aria-labelledby={`${name}_${choice}`}
              tabIndex={selectedValue === (index === 0) ? 0 : -1}
              aria-checked={selectedValue === (index === 0)}
              aria-disabled="true"
              aria-setsize={radioLabels.length}
            >
              <span className={styles.radio} aria-hidden="true" />
            </div>
            <div id={`${name}_${choice}`} className={styles['radio-label']}>
              {choice}
            </div>
          </label>
        ))}
      </FormRadioWrapper>
    );
  }
  if (name === 'indicator.manualReviewRequiredFlag') {
    return (
      <FormRadioWrapper name={name} label={label} className={className}>
        {[...radioLabels].map((choice, index) => (
          <label key={choice} className={styles['radio-label-wrapper']}>
            {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
            <input
              className="sr-only"
              type="radio"
              value={(index === 0).toString()}
              checked={selectedValue === (index === 0)}
              aria-errormessage={`${name}_error_message`}
              aria-invalid={Boolean(message)}
              data-testid={testid}
              onChange={handleChange}
            />
            <span className={styles.radio} />
            <span className={styles['radio-label']}>{choice}</span>
          </label>
        ))}
      </FormRadioWrapper>
    );
  }
  return (
    <FormRadioWrapper name={name} label={label} className={className}>
      {[...radioLabels].map((choice, index) => (
        <label key={choice} className={styles['radio-label-wrapper']}>
          {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
          <input
            className="sr-only"
            type="radio"
            value={(index === 0).toString()}
            aria-errormessage={`${name}_error_message`}
            aria-invalid={Boolean(message)}
            {...register(name || ' ', {
              onChange: handleChange
            })}
            data-testid={testid}
            // onChange={handleChange}
          />
          <span className={styles.radio} />
          <span className={styles['radio-label']}>{choice}</span>
        </label>
      ))}
    </FormRadioWrapper>
  );
};

export default FormYesNoRadio;
