export function sortByOptionalObjectProperties<T extends object, K extends keyof T>(
  arr: T[],
  sortingProperty: K
): T[] {
  return arr.sort((firstObject, secondObject) => {
    if (firstObject[sortingProperty] != null && secondObject[sortingProperty] != null) {
      return firstObject[sortingProperty] > secondObject[sortingProperty] ? 1 : -1;
    }
    return firstObject[sortingProperty] != null ? 1 : -1;
  });
}
