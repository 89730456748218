import React, { FC, memo } from 'react';
import { LinkFieldValue } from '@sitecore-jss/sitecore-jss-manifest';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Link from 'Components/Core/Link';
import { LinkSize } from 'Components/Core/Link/types';
import { Link as RouterLink } from 'react-router-dom';
import styles from './styles.module.scss';
import { FooterNavLink } from './types';

export const HbtFooterNavLink: FC<{ footerNavLink: FooterNavLink }> = memo(({ footerNavLink }) => {
  const processedLink: FooterNavLink =
    'value' in footerNavLink ? (footerNavLink.value as LinkFieldValue) : footerNavLink;

  return processedLink?.href?.startsWith('/') ? (
    <RouterLink to={processedLink.href}>
      <span className={styles.links} id="footer-route-text">
        <Text field={{ value: processedLink.text }} />
      </span>
    </RouterLink>
  ) : (
    <div className={styles.links} id="footer-link-text">
      <Link
        size={LinkSize.SMALL}
        href={processedLink.href ?? '/'}
        children={processedLink.text}
        ariaLabel={processedLink.text}
      />
    </div>
  );
});
