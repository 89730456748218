import * as React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import PageHeaderComponentProps from 'Feature/Claims/models/PageHeaderComponentProps';

const PageHeaderComponent = (props: PageHeaderComponentProps) => (
  <div>
    <p>PageHeaderComponent Component</p>
    <Text field={props.fields.heading} />
  </div>
);

export default PageHeaderComponent;
