/* eslint-disable */
import { useEffect } from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { submittedClaimSchema } from '@hobt/claim-domain';
import { hbtResolver } from '@hobt/schema-validator';
import { jsonReplaceToNull } from '../../../../Constants';
import { AuthorizedOfficer } from './types';
import { useHBTFormContext, useHBTFormActionsContext } from './index';

export default (name: string | undefined) => {
  const formContext: any = useFormContext();
  const { isClaimsDetails, uuid, claimData } = useHBTFormContext();
  const { addCompleteCard, removeCompleteCard } = useHBTFormActionsContext();

  const isDraftClaim =
    (isClaimsDetails == null || isClaimsDetails === false) && uuid !== '';
  const claimsLenderDetails = 'lender';
  const claimsBorrowerCharges = 'borrowerCharges';
  const validator = hbtResolver(submittedClaimSchema);

  const checkBorrowerCharges = (borrowerCharges: []) => {
    for (let i = 0; i < borrowerCharges?.length; i++) {
      if (borrowerCharges[i]) {
        if (Object.keys(borrowerCharges[i])?.length > 0) {
          return false;
        }
      }
    }
    return true;
  };

  const checkLenderDetails = () => {
    if (
      name === claimsLenderDetails &&
      formContext?.formState?.errors?.lender != null &&
      Object.keys?.(formContext?.formState?.errors?.lender)?.length === 1
    ) {
      formContext?.formState?.errors?.lender?.authorizedOfficers?.map?.(
        (authorizedOfficerData: AuthorizedOfficer) => {
          if (
            Object.keys(authorizedOfficerData)?.length === 1 &&
            authorizedOfficerData?.emailID?.message === ''
          ) {
            delete formContext?.formState?.errors?.lender;
          }
        }
      );
    }
  };

  // On initial render for the draft claim page, we call the hbtResolver instead of re-rendering the form context
  // This will prevent any infinite loops
  useEffect(() => {
    if (isDraftClaim) {
      const formContextValues = formContext.getValues();
      formContextValues.borrowers = claimData?.borrowers;

      if (
        !claimData.borrowerCharges ||
        checkBorrowerCharges(claimData.borrowerCharges)
      ) {
        formContextValues.borrowerCharges = [{}];
      }
      const values: FieldErrors | any = validator(formContextValues);
      if (name === claimsLenderDetails) {
        values?.errors?.lender?.authorizedOfficers?.map?.(
          (authorizedOfficerData: AuthorizedOfficer) => {
            if (
              Object.keys(authorizedOfficerData)?.length === 1 &&
              authorizedOfficerData?.emailID?.message === ''
            ) {
              delete values?.errors?.lender;
            }
          }
        );
      }
      if (name != null && values?.errors?.[name] == null) {
        addCompleteCard(name);
      }
    }
  }, [name]);

  // After the initial render for the draft claim submission form, we subscribe to changes in the formContext to update our completed cards
  // and update our progress bar
  useEffect(() => {
    if (isDraftClaim && name != null) {
      checkLenderDetails();
      const formContextValues = formContext.getValues();
      // Prepare cards validation
      const values: FieldErrors | any = validator(formContextValues);
      if (
        formContextValues?.borrowerCharges != null &&
        formContextValues?.borrowerCharges?.length > 0
      ) {
        const borrowerData = JSON.parse(
          JSON.stringify(formContextValues?.borrowerCharges, jsonReplaceToNull)
        );
        if (!borrowerData || checkBorrowerCharges(borrowerData)) {
          removeCompleteCard(claimsBorrowerCharges);
        }
      }
      if (
        formContext?.formState?.touchedFields?.[name] != null &&
        formContext?.formState?.errors?.[name] == null
      ) {
        addCompleteCard(name);
      } else if (formContext?.formState?.errors?.[name] != null) {
        removeCompleteCard(name);
      }

      // cards validation
      if (name != null && values?.errors?.[name] == null) {
        addCompleteCard(name);
      }

      // Check array of attachment is empty
      if (formContextValues?.attachments?.length === 0) {
        removeCompleteCard('attachments');
      }
    }
  }, [formContext]);

  useEffect(() => {
    // We prevent any additional rendering if the user is on the draft claim submission form page
    if (!isDraftClaim) {
      checkLenderDetails();

      let isComplete: boolean = false;

      if (name != null && formContext != null && uuid !== '') {
        isComplete = formContext?.formState?.errors?.[name] == null;
      } else if (name != null && formContext != null) {
        isComplete =
          formContext?.formState?.touchedFields?.[name] != null &&
          formContext?.formState?.errors?.[name] == null;
      }
      // cards validation
      const formContextValues = formContext.getValues();
      const values: FieldErrors | any = validator(formContextValues);
      if (
        formContextValues?.borrowerCharges != null &&
        formContextValues?.borrowerCharges?.length > 0
      ) {
        const borrowerData = JSON.parse(
          JSON.stringify(formContextValues?.borrowerCharges, jsonReplaceToNull)
        );
        if (!borrowerData || checkBorrowerCharges(borrowerData)) {
          removeCompleteCard(claimsBorrowerCharges);
        }
      }

      if (name === claimsLenderDetails) {
        values?.errors?.lender?.authorizedOfficers?.map?.(
          (authorizedOfficerData: AuthorizedOfficer) => {
            if (
              Object.keys(authorizedOfficerData)?.length === 1 &&
              authorizedOfficerData?.emailID?.message === ''
            ) {
              delete values?.errors?.lender;
            }
          }
        );
      }
      if (uuid != '') {
        if (name != null && isComplete) {
          addCompleteCard(name);
        } else if (name != null && !isComplete) {
          removeCompleteCard(name);
        }
      } else if (name != null && values?.errors?.[name] == null) {
        addCompleteCard(name);
      } else if (name != null && !isComplete) {
        removeCompleteCard(name);
      }
    }
  }, [
    uuid,
    formContext?.formState?.errors,
    formContext?.formState?.touchedFields,
    formContext?.formState?.isValidating
  ]);
};
