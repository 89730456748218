export function countNestedNamedProperty(obj: any, prop: string): number {
  if (Array.isArray(obj)) {
    return obj.map((v) => countNestedNamedProperty(v, prop)).reduce((a, b) => a + b, 0);
  }
  if (typeof obj === 'object') {
    // eslint-disable-next-line no-prototype-builtins
    return Number(obj.hasOwnProperty(prop)) + countNestedNamedProperty(Object.values(obj), prop);
  }
  return 0;
}
