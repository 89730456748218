import React, { useState } from 'react';
import { AxiosError } from 'axios';
import i18n from 'i18next';

import { LanguageShort, ModuleMapping, UserRole } from '@hobt/constants';
import { useFeature } from 'flagged';

import { PageCardContainer } from 'Components/PageComponents/PageCardContainer';
import { PageCardHeaderWithAddButton } from 'Components/PageComponents/PageCardHeaderWithAddButton';
import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { ActionButtonCell } from 'Components/Common/DataTable/DataTableRow/ActionButtonCell';
import { DataTableFooter } from 'Components/Common/DataTableFooter';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import { useDataGridEngine } from 'Components/Hooks/DataGridEngine';
import { MenuItemProps } from 'Components/Common/ActionMenu/types';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';

import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { SearchBar } from 'Components/PageComponents/SearchBar';
import PreClaimsPropertyRepairProps, { PropertyGridRow } from './types';
import { AddPropertyRepairsForm } from './AddPropertyRepairsForm/index';
import usePreclaimsPropertyRepairs from './usePreclaimsPropertyRepairs';

import { config } from '../../../../config';
import styles from './styles.module.scss';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const PreApprovedPropertyRepairs = ({ fields }: PreClaimsPropertyRepairProps) => {
  const onApiErrorCallback = (e: AxiosError) => {
    // TODO: Handle API Error
    // eslint-disable-next-line no-console
    console.log(e);
  };

  const { toCurrency } = useTranslationHelpers();

  const {
    showSuccessToast,
    isEdit,
    showSideDrawer,
    onSuccessSubmit,
    setShowSuccessToast,
    setShowErrorToast,
    setShowSideDrawer,
    setIsEdit,
    onCancelForm,
    onErrorSubmit,
    onAddPropertyRepairsButtonClick,
    onSuccessToastDismiss
  } = usePreclaimsPropertyRepairs();

  const {
    rowData,
    totalItems,
    totalPages,
    itemsPerPage,
    itemRangeFrom,
    itemRangeTo,
    isLoading: isInventoryLoading,
    onPagerClick,
    onSearch,
    onSearchReset,
    currentPage,
    refresh,
    onItemsPerPageChange,
    getTableAccessibilityText
  } = useDataGridEngine<PropertyGridRow>({
    apiBaseUrl: config.preClaimPropertyRepairsApi.urls.inventory,
    defaultItemsPerPage: 20,
    onApiErrorCallback,
    captionText: fields.tableCaptionText?.value
  });

  const [editableItem, setEditableItem] = useState<PropertyGridRow | undefined>();
  const [loadingStatus, setLoadingStatus] = useState<Spinner>({
    isLoading: false
  });

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);
  const isReadOnlyAccessUser: boolean =
    isUserInRoles(ModuleMapping.claim, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  const onEditClick = (id: number | string) => {
    const editableObj: PropertyGridRow | undefined = rowData?.find(
      (item) => item.preApprovedPropertyRepairID === id
    );
    setShowSideDrawer(true);
    setIsEdit(true);
    setEditableItem(editableObj);
    setShowSuccessToast(false);
    setShowErrorToast(false);
  };

  const rowActionMenu: MenuItemProps[] = [
    {
      name: 'editButton',
      icon: 'edit',
      displayText: { value: fields.editButtonText.value },
      onClickCallback: onEditClick,
      actionItemId: 0
    }
  ];

  return (
    <>
      <ContentLoadingModal
        display={loadingStatus?.isLoading}
        fields={{
          heading: { value: i18n.t(loadingStatus?.spinnerHeading ?? 'Globals-InProgress-Heading') },
          description: {
            value: i18n.t(loadingStatus?.spinnerDescription ?? 'Globals-InProgress-Description')
          }
        }}
      />
      <ToastNotification
        type={ApplicationStates.SUCCESS}
        isActive={showSuccessToast}
        title={i18n.t(`Globals-Toast-Success-Title`)}
        content={fields.propertyRepairSuccessUpdated?.value}
        onCloseCallback={onSuccessToastDismiss}
      />
      <PageCardContainer name="preClaimsPropertyRepairs">
        <div
          className={`${styles.preClaimsPropertyRepairsContainer} ${
            i18n.language === LanguageShort.French
              ? styles.preClaimsPropertyRepairsContainerFrench
              : ''
          }`.trim()}
        >
          <PageCardHeaderWithAddButton
            title={fields.heading}
            addButtonText={fields.addPreApproved}
            addButtonIsSecondary={false}
            addButtonCallback={onAddPropertyRepairsButtonClick}
            disabled={isReadOnlyAccessUser}
          />
        </div>
        <div className={styles.searchContainer}>
          <SearchBar
            name="PropertyRepairsSearchBar"
            fieldLabelAriaText={fields.searchFilterLabel?.value}
            searchButtonAriaText={fields.searchButtonAriaLabel?.value}
            clearButtonAriaText={fields.searchButtonClearAriaLabel?.value}
            placeholderText={fields.searchPlaceholderTextPrefix?.value}
            onSearch={onSearch}
            onClear={onSearchReset}
            searchFieldOptions={[
              {
                displayText: fields.cmhcLoanNumber?.value || '',
                value: 'cmhcLoanAccountNumber',
                type: 'number'
              }
            ]}
            count={totalItems}
          />
        </div>
        <div className={styles.dataTable}>
          <DataTable
            name="preClaimsPropertyRepairsDataTable"
            isLoading={isInventoryLoading}
            caption={{ value: getTableAccessibilityText() ?? '' }}
          >
            <DataTableHeader>
              {!isReadOnlyAccessUser && (
                <DataTableHeaderItem
                  name="cmhcLoan"
                  displayText={fields.cmhcLoanNumber}
                  customStyles={styles.actionMenuHeader}
                />
              )}
              <DataTableHeaderItem
                name="cmhcLoan"
                displayText={fields.cmhcLoanNumber}
                customStyles={styles.loanNumber}
              />
              <DataTableHeaderItem
                name="repairAmount"
                displayText={fields.approvedAmount}
                customStyles={styles.approvedAmount}
              />
              <DataTableHeaderItem name="code" displayText={fields.code} />
            </DataTableHeader>

            <DataTableBody zebra>
              {rowData?.map(
                (row: PropertyGridRow): JSX.Element => (
                  <DataTableRow
                    name={row.preApprovedPropertyRepairID}
                    key={row.preApprovedPropertyRepairID}
                  >
                    {!isReadOnlyAccessUser && (
                      <ActionButtonCell
                        name="rowActionMenu"
                        rowActionItems={rowActionMenu?.map((i) => ({
                          ...i,
                          actionItemId: row.preApprovedPropertyRepairID
                        }))}
                        rowId={row.preApprovedPropertyRepairID}
                      />
                    )}
                    <TextCell
                      name="cmhcLoanAccountNumber"
                      text={
                        row.cmhcLoanAccountNumber ? row.cmhcLoanAccountNumber.toString(10) : '--'
                      }
                    />
                    <TextCell name="amount" text={toCurrency(row.amount)} />
                    <TextCell name="borrowerChargeCode" text={row.borrowerChargeCode || '--'} />
                  </DataTableRow>
                )
              )}
            </DataTableBody>
          </DataTable>
        </div>
        <DataTableFooter
          name="PropertyRepairsTableFooter"
          currentPage={currentPage}
          totalPages={totalPages || 1}
          itemsPerPageLabel={{
            value: i18n.t('DefaultsInventoryTable-ResultsPerPageDropdown-Title')
          }}
          itemsXofYText={`${itemRangeFrom} - ${itemRangeTo}
          ${i18n.t('Globals-Grid-ResultsPerPageDropdown-Of')}`}
          totalItems={totalItems}
          pagerClickCallback={onPagerClick}
          itemsPerPageOnChangeCallback={onItemsPerPageChange}
          itemsPerPageOptions={[
            { fields: { itemName: { value: '10' }, itemValue: { value: '10' } } },
            { fields: { itemName: { value: '20' }, itemValue: { value: '20' } } },
            { fields: { itemName: { value: '50' }, itemValue: { value: '50' } } },
            { fields: { itemName: { value: '100' }, itemValue: { value: '100' } } }
          ]}
          itemsPerPageValue={itemsPerPage.toString()}
        />
      </PageCardContainer>
      <SideDrawer isActive={showSideDrawer} handleOutsideClick={onCancelForm}>
        <AddPropertyRepairsForm
          name={`AddPropertyRepairButton`}
          onErrorCallback={onErrorSubmit}
          setIsLoadingCallback={setLoadingStatus}
          onSuccessCallback={() => {
            onSuccessSubmit();
            refresh();
          }}
          onCancelCallback={onCancelForm}
          fields={fields.addEditPropertyRepairs?.fields}
          duplicateCMHCLoanNumber={fields.propertyRepairErrorAccount.value}
          edit={isEdit}
          editableItem={editableItem}
        />
      </SideDrawer>
    </>
  );
};

export default PreApprovedPropertyRepairs;
