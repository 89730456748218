import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';

import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

import { HttpResponseStatusCodes } from '@hobt/constants';
import { SystemCommentsRequestData, SystemGeneratedCommentTypeProps } from './types';
import { config } from '../../../../../../../../config';

function mappedAscendingDateSort(
  a: SystemGeneratedCommentTypeProps,
  b: SystemGeneratedCommentTypeProps
) {
  return new Date(a.fields.time).valueOf() - new Date(b.fields.time).valueOf();
}

function mapBackendCommentFormatToUIFormat(remark: any) {
  return {
    editButton: false,
    fields: {
      code: remark?.code,
      remark: remark?.text,
      time: remark?.recordCreatedTimestamp,
      includedInClaimLetter: remark?.includeInLetterFlag,
      indexNumber: remark?.indexNumber
    }
  };
}

const useSystemGeneratedCommentsFunction = (
  onSuccessCallback?: Function,
  onErrorCallback?: Function,
  claimIdProps?: string
) => {
  const { uuid, setLoadingStatus } = useHBTFormContext();
  const authContext = useAuthenticationContext();

  // API Client Config
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  const { postWithAuth } = ApiClient(authContext, apiClientConfig);

  const hookForm = useForm({
    // resolver: hbtResolver(),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  });

  const [data, setData] = useState<SystemGeneratedCommentTypeProps[]>([]);

  const getClaimLetterComments = async (authenticationContext: any): Promise<any | null> => {
    try {
      let claimID = uuid;
      if (claimID == null || claimID === '') {
        claimID = claimIdProps ?? '';
      }
      const response: AxiosResponse = await ApiClient(
        authenticationContext,
        apiClientConfig
      ).getWithAuth(`${config.claimApi.urls.finalClaimGetComments}/${claimID}`);

      if (response.data.statusCode === HttpResponseStatusCodes.OK) {
        return response?.data?.data?.[0];
      }
      return null;
    } catch {
      return null;
    }
  };

  // Initialize the System Generated Comments
  useEffect(() => {
    (async () => {
      const comments = await getClaimLetterComments(authContext);
      if (comments !== null) {
        setData(
          comments.adjudicationComments
            ?.map(mapBackendCommentFormatToUIFormat)
            ?.sort(mappedAscendingDateSort)
            .reverse()
        );
      }
    })();
  }, []);

  const onEditClick = (index: number) => {
    const updated = data[index];
    updated.editButton = !data[index].editButton;
    data[index] = updated;
    setData([...data]);
  };

  const handleUpdate = (index: number) => {
    let claimID = uuid;
    if (claimID == null || claimID === '') {
      claimID = claimIdProps ?? '';
    }

    const payloadData = {
      claimID,
      remarkPayload: {
        adjudicationComment: {
          // @ts-ignore
          indexNumber: data[index].fields.indexNumber,
          code: data[index].fields.code,
          text: hookForm.getValues(`comment[${index}]`) || data[index].fields.remark,
          includeInLetterFlag: data[index].fields.includedInClaimLetter
        }
      }
    };

    const url = config.claimApi.urls.finalClaimComments;

    setLoadingStatus?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description',
      isFlyout: true
    });

    postWithAuth(url, payloadData)
      .then((responseData: AxiosResponse<SystemCommentsRequestData>) => {
        if (onSuccessCallback) {
          const patchedData = data;
          patchedData[index].fields.remark = payloadData.remarkPayload.adjudicationComment.text;
          setData(patchedData);
          onSuccessCallback(index, responseData);
        }

        setLoadingStatus?.({
          isLoading: false
        });
      })
      .catch(() => {
        if (onErrorCallback) {
          onErrorCallback();
        }

        setLoadingStatus?.({
          isLoading: false
        });
      });
  };

  const onIncludeInLetterToggle = (index: number) => {
    const updated = data[index];
    updated.fields.includedInClaimLetter = !data[index].fields.includedInClaimLetter;
    data[index] = updated;
    setData([...data]);
    handleUpdate(index);
  };

  const [sortByDescendig, setSortByDescendig] = useState<boolean>(true);
  const onDateSortToggle = () => {
    data.sort(mappedAscendingDateSort);

    // This reads backwards because this is inside a Toggle
    // i.e., we set Ascending (data) when `sortByDescending`
    //  because we are Toggling it.
    setData(sortByDescendig ? data : data.reverse());

    setSortByDescendig(!sortByDescendig);
  };

  const onSubmitErrors = (errs: Record<string, Object>) => {
    // TODO: Remove once validations are in place
    // eslint-disable-next-line no-console
    console.log('FORM ERRORS: ', errs);
  };

  return {
    handleUpdate,
    onSubmitErrors,
    hookForm,
    sortByDescendig,
    onDateSortToggle,
    onEditClick,
    onIncludeInLetterToggle,
    data,
    getClaimLetterComments
  };
};

export default useSystemGeneratedCommentsFunction;
