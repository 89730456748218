import React, { useContext, useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { ExternalAssignmentStatusProps } from 'Feature/Claims/components/Details/AdjudicationCardHeader/AdjudicationAssignmentStatus/ExternalAssignmentStatus/types';
import { SitecoreContentContext } from 'Feature/Claims/components/Details';
import { defaultEmptyString } from 'Components/Common/Api/utils/HandleEmpty';
import { useExternalAssignmentStatusFunctions } from 'Feature/Claims/components/Details/AdjudicationCardHeader/AdjudicationAssignmentStatus/ExternalAssignmentStatus/useExternalAssignmentStatusFunctions';
import styles from './styles.module.scss';

export const ExternalAssignmentStatus: React.FC<ExternalAssignmentStatusProps> = ({
  status,
  assignedTo
}: ExternalAssignmentStatusProps) => {
  const { fields } = useContext(SitecoreContentContext);

  const { getStatus } = useExternalAssignmentStatusFunctions(
    fields.claimsStatusList?.fields?.listItems
  );

  const [statusString, setStatusString] = useState<string>();

  useEffect(() => {
    setStatusString(getStatus(status));
  }, [status]);

  return (
    <div className={styles.assignedStatusContainer}>
      <div className={styles.assignedToContainer}>
        <div className={styles.label}>
          <Text field={fields.ownerLabel} />:
        </div>
        <div className={`${styles.valueWrapper} ${styles.assignedToValueContainer}`}>
          <span className="material-icons align-self-center">person_outline</span>
          <span>{assignedTo ?? defaultEmptyString}</span>
        </div>
      </div>
      <div className={styles.statusContainer}>
        <span className={styles.label}>
          <Text field={fields.statusLabel} />:
        </span>
        <span className={styles.valueWrapper}>
          <span className={`${styles.statusIndicator} ${styles[`status-${status ?? 0}`]}`.trim()} />
          <span>{statusString ?? defaultEmptyString}</span>
        </span>
      </div>
    </div>
  );
};
