import * as React from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';

import { MenuItemProps } from 'Components/Common/ActionMenu/types';
import styles from './styles.module.scss';

export const ActionMenuItem: React.FC<MenuItemProps> = ({
  name,
  displayText,
  icon,
  onClickCallback,
  actionItemId,
  disable
}: MenuItemProps) => (
  <button
    name={`${name}ActionMenuItem`}
    aria-label={displayText.value}
    disabled={disable}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClickCallback(actionItemId);
    }}
    data-testid={`${name}ActionMenuItem`}
  >
    <span className={styles.actionMenuItemIconWrapper}>
      {typeof icon === 'string' ? (
        <span className={`material-icons align-self-center ${styles.icon}`}>{icon}</span>
      ) : (
        <Image field={icon} className={`${styles.imageSize}`} />
      )}
    </span>
    <span className={styles.actionMenuItemText}>
      <Text field={displayText} />
    </span>
  </button>
);
