import React, { memo, useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { bytesToMegabytes } from 'Components/Core/Helpers';
import i18n from 'i18next';
import styles from './styles.module.scss';
import { DocumentEntity, AttachmentTableProps } from './types';

/**
 * @param completedAttachments - array of files that are uploaded
 * @param deleteFile - function to delete file
 */
const AttachmentTable: React.FC<AttachmentTableProps> = ({
  attachmentContent,
  completedAttachments,
  deleteFile
}) => {
  const { noAttachmentTableLabel } = attachmentContent ?? {};
  const attachmentNameTableLabel = i18n.t('Attachment-Table-File-Name') ?? 'File Name';
  const sizeTableLabel = i18n.t('Attachment-Table-File-Size') ?? 'Size';
  const actionTableLabel = i18n.t('Attachment-Table-File-Action') ?? 'Action';
  const noAttachmentTableFallbackLabel =
    i18n.t('Attachment-Table-No-Attachments') ??
    'No attachments added. Add an attachment to see it here.';

  async function handleDelete(index: number) {
    setAnimationStates((prevState) => ({ ...prevState, [index]: styles.fadeExit }));
    setTimeout(() => {
      deleteFile(index);
    }, 300);
  }

  const [animationStates, setAnimationStates] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    const newAnimationStates = completedAttachments.map((_, index) => styles.fadeEnter);
    setAnimationStates(newAnimationStates);
  }, [completedAttachments]);

  return (
    <div>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableHeader}>
            <td className={styles.tableHeaderCell} tabIndex={0}>
              <div>{attachmentNameTableLabel}</div>
            </td>
            <td className={styles.tableHeaderCell} tabIndex={0}>
              <div>{sizeTableLabel}</div>
            </td>
            <td className={styles.tableHeaderCell} tabIndex={0}>
              <div>{actionTableLabel}</div>
            </td>
          </tr>
        </thead>

        <tbody data-testid="attachment-table-body">
          {completedAttachments?.map((file: DocumentEntity, index: number) => (
            <tr className={`${styles.tableRow} ${animationStates[index]}`} key={index}>
              <td className={styles.tableDataFileName} tabIndex={0}>
                <div className={styles.tbodayFilName}>{file.fileName}</div>
              </td>
              <td className={styles.tableData} tabIndex={0}>
                {bytesToMegabytes(file.fileSize ? file.fileSize : 0)}&nbsp;
                {i18n.t('Attachment-Table-FileSize-Label', 'MB')}
              </td>
              <td className={styles.tableData}>
                <div>
                  <div
                    className={styles.deleteButton}
                    onClick={() => handleDelete(index)}
                    tabIndex={0}
                  >
                    <Text
                      field={{
                        value: i18n.t('Attachment-Table-RemoveBtn') ?? 'Remove'
                      }}
                    />
                  </div>
                </div>
              </td>
            </tr>
          ))}

          {completedAttachments.length == 0 && (
            <tr className={completedAttachments.length == 0 ? styles.fadeEnter : styles.fadeExit}>
              <td colSpan={3}>
                <div className={styles.noAttachment}>
                  <Text
                    field={
                      noAttachmentTableLabel?.value && noAttachmentTableLabel.value.length > 0
                        ? noAttachmentTableLabel
                        : ({
                            value: noAttachmentTableFallbackLabel
                          } as FieldValue)
                    }
                  />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default memo(AttachmentTable);
