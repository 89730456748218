import DefaultSubmissionFormProps, {
  AttachmentValuesField
} from 'Feature/DefaultSubmissionForm/models/DefaultSubmissionFormProps';
import i18n from 'i18next';
import { FormDropdownOption } from '../../FormInputDropdownText/FormInputDropdownText.types';

export const getLoanDetailsProps = (props: DefaultSubmissionFormProps) => {
  const provinceOptions: FormDropdownOption[] = [{ label: '', value: '' }];
  props.fields.provinceOptions[0]?.fields.listItems.map((option: AttachmentValuesField) => {
    provinceOptions.push({
      label: (option?.fields?.itemName?.value as string) || 'No label set',
      value: option?.fields?.itemValue?.value || -1
    });
  });

  const loanDetailsData = {
    testId: 'loanDetailsTest',
    accordionLineColor: 'grey',
    linePosition: 'vertical',
    title: {
      field: {
        value: i18n.t('DefaultSubmission-Card-LoanDetailsHeading')
      }
    },
    labelDelete: {
      field: {
        value: i18n.t('DefaultSubmission-Card-Delete')
      }
    },
    labelDuplicate: {
      field: {
        value: i18n.t('DefaultSubmission-Card-Duplicate')
      }
    },
    provinceOptions,
    currentNumberOfYears: {
      field: {
        value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-NumberOfYears')
      }
    },
    currentNumberOfMonths: {
      field: {
        value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-NumberOfMonths')
      }
    },
    inputFields: {
      cmhcLoanNumber: {
        field: {
          value: i18n.t('DefaultSubmission-Card-CMHCLoanNumber')
        }
      },
      overrideCheckbox: {
        field: {
          value: i18n.t('DefaultSubmission-Card-LoanDetails-OverrideCheckbox')
        }
      },
      propertyAddressStNumber: {
        field: {
          value: i18n.t('DefaultSubmission-Card-LoanDetails-insuredPropertyAddressStreetNumber')
        }
      },
      propertyAddressUnitNumber: {
        field: {
          value: i18n.t('DefaultSubmission-Card-LoanDetails-insuredPropertyAddressUnitNumber')
        }
      },
      streetName: {
        field: {
          value: i18n.t('DefaultSubmission-Card-StreetName')
        }
      },
      streetType: {
        field: {
          value: i18n.t('DefaultSubmission-Card-StreetType')
        }
      },
      streetDirection: {
        field: {
          value: i18n.t('DefaultSubmission-Card-StreetDirection')
        }
      },
      city: {
        field: {
          value: i18n.t('DefaultSubmission-Card-TownCity')
        }
      },
      province: {
        field: {
          value: i18n.t('DefaultSubmission-Card-Province')
        }
      },
      postalCode: {
        field: {
          value: i18n.t('DefaultSubmission-Card-PostalCode')
        }
      },
      radioButtonYes: {
        field: {
          value: 'Yes'
        }
      },
      radioButtonNo: {
        field: {
          value: 'No'
        }
      },
      purchasePriceOfHome: {
        field: {
          value: i18n.t('DefaultSubmission-Card-PurchasePriceOfHome')
        }
      },
      purchasePriceOfHomeDescription: {
        field: {
          value: i18n.t('DefaultSubmission-Card-PurchasePriceOfHomeDescription')
        }
      },
      annualTaxes: {
        field: {
          value: i18n.t('DefaultSubmission-Card-AnnualTaxes')
        }
      },
      currentMarketValue: {
        field: {
          value: i18n.t('DefaultSubmission-Card-CurrentMarketValue')
        }
      },
      updatedPropertyTaxes: {
        field: {
          value: i18n.t('DefaultSubmission-Card-PropertyTaxUpToDate')
        }
      },
      updatedPropertyInsurance: {
        field: {
          value: i18n.t('DefaultSubmission-Card-PropertyInsuranceUpToDate')
        }
      },
      amountPropertyTaxes: {
        field: {
          value: i18n.t('DefaultSubmission-CardE-Amount')
        }
      },
      amountPropertyInsurance: {
        field: {
          value: i18n.t('DefaultSubmission-CardE-Amount')
        }
      },
      amountUtilities: {
        field: {
          value: i18n.t('DefaultSubmission-CardE-Amount')
        }
      },
      amountCondoFee: {
        field: {
          value: i18n.t('DefaultSubmission-CardE-Amount')
        }
      },
      updatedUtilities: {
        field: {
          value: i18n.t('DefaultSubmission-Card-UtilitiesUpToDate')
        }
      },
      updatedCondominiumFees: {
        field: {
          value: i18n.t('DefaultSubmission-Card-CondominiumFeeUpToDate')
        }
      },
      mortgageOccupancyType: {
        field: {
          value: i18n.t('DefaultSubmission-Card-OccupancyType')
        }
      },
      mortgageRankingPriority: {
        field: {
          value: i18n.t('DefaultSubmission-Card-RankingPriority')
        }
      },
      mortgageUnpaidBalance: {
        field: {
          value: i18n.t('DefaultSubmission-Card-UnpaidPrincipalBalance')
        }
      },
      mortgageRequiredMonthlyPayment: {
        field: {
          value: i18n.t('DefaultSubmission-Card-RequiredMonthlyPayment')
        }
      },
      mortgageInterestRate: {
        field: {
          value: i18n.t('DefaultSubmission-Card-InterestRate')
        }
      },
      mortgageNextRenewalDate: {
        field: {
          value: i18n.t('DefaultSubmission-Card-NextRenewalDate')
        }
      },
      registeredMortgageHolder: {
        field: {
          value: i18n.t('DefaultSubmission-Card-RegisteredMortgageHolder')
        }
      },
      registeredMortgageHolderDescription: {
        field: {
          value: i18n.t('DefaultSubmission-Card-RegisteredMortgageHolderSubDescription')
        }
      },
      mortgageCurrentArrears: {
        field: {
          value: i18n.t('DefaultSubmission-Card-CurrentArrears')
        }
      },
      currentAmortizationPeriod: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-CurrentAmortznPeriod')
        }
      },
      originalAmortizationPeriod: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-OldAmortznPeriod')
        }
      }
    }
  };

  return loanDetailsData;
};
