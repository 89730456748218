import { useFeature } from 'flagged';
import React from 'react';
import { RichText, Image, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { Module, UserRole } from '@hobt/constants';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { FormCardHeaderProps } from './FormCardHeader.types';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const FormCardHeader: React.FC<FormCardHeaderProps> = ({ introCardProps }) => {
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  return (
    <fieldset disabled={isReadOnlyUser}>
      <div className="card card--horizontal-blue">
        <header className="card-header card__header card__form-header">
          <Image className="card__header-image" alt="" field={introCardProps.image} />
          <h2 className="card__header-title">{introCardProps.title.value}</h2>
        </header>
        <div className="card-body card__body card__body-alignTitle">
          <div className="col-12">
            <RichText field={introCardProps.cardContent} />
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default FormCardHeader;
