import i18next from 'i18next';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { LanguageShort } from '@hobt/constants';

import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';
import { FormFieldCell } from 'Components/Common/DataTable/DataTableRow/FormFieldCell';
import { getCurrencyFormat, currencyFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormDatepicker from 'Components/Inputs/FormDatepicker';
import FormNumber from 'Components/Inputs/FormNumber';

import { AssessmentCalculationsContext } from 'Feature/Claims/components/Details/AssessmentCalculations';
import { CashReceiptRow } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/CashReceipts/types';

import { useCashReceiptsFunctions } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/CashReceipts/useCashReceiptsFunctions';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { CommentCell } from 'Components/Common/DataTable/DataTableRow/CommentCell';
import { TrendableCell } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/TrendableCell';
import { ScrollableDataTableContainer } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/ScrollableDataTableContainer';
import { BuildDropDownDictionary } from 'Components/Common/Helpers';
import styles from './styles.module.scss';

export const getCashReceiptMax = (receivedAmount?: number, previouslyApprovedAmount?: number) => {
  const receivedApprovedArr: number[] = [];

  if (receivedAmount != null) {
    receivedApprovedArr.push(receivedAmount);
  }

  if (previouslyApprovedAmount != null) {
    receivedApprovedArr.push(previouslyApprovedAmount);
  }

  return Math.max(...receivedApprovedArr);
};

export const CashReceipts: React.FC = () => {
  const { toCurrency, toLocaleDate } = useTranslationHelpers();
  const assessmentCalculationsContext = useContext(AssessmentCalculationsContext);
  const { parseCashReceiptData } = useCashReceiptsFunctions();
  const { claimData, isClaimStatusPostAdjudicationStatus } = useHBTFormContext();
  const { watch, getValues } = useFormContext();

  const formData = watch('cashReceipts');
  const cashReceiptData = parseCashReceiptData(claimData);

  const cashReceiptsFields =
    assessmentCalculationsContext?.sitecoreContent?.cashReceiptsCardLabels?.fields;

  const codeRowDictionary = BuildDropDownDictionary(cashReceiptsFields?.codeRows.fields.listItems);

  return (
    <div className={styles.content}>
      <ScrollableDataTableContainer className={styles.dataTable} name="cashReceipts">
        <fieldset
          className={
            !assessmentCalculationsContext.isEditing || isClaimStatusPostAdjudicationStatus === true
              ? styles.fieldset
              : ''
          }
        >
          <DataTable
            name="cashReceipts"
            caption={cashReceiptsFields?.captionTextCashReceipts ?? {}}
            addTableWrapper={false}
          >
            <DataTableHeader>
              <DataTableHeaderItem
                name="natureOfReceipts"
                displayText={cashReceiptsFields?.natureOfReceipts}
              />
              <DataTableHeaderItem
                name="previouslyAssessedDate"
                displayText={cashReceiptsFields?.previouslyAssessedDate}
              />
              <DataTableHeaderItem
                name="previouslyAssessedAmount"
                displayText={cashReceiptsFields?.previouslyAssessedAmmount}
              />
              <DataTableHeaderItem
                name="submittedDate"
                displayText={cashReceiptsFields?.submittedDate}
              />
              <DataTableHeaderItem
                name="submittedAmount"
                displayText={cashReceiptsFields?.submittedAmount}
              />
              <DataTableHeaderItem
                name="admissibleDate"
                displayText={cashReceiptsFields?.admissibleDate}
              />
              <DataTableHeaderItem
                name="admissibleAmount"
                displayText={cashReceiptsFields?.admissibleAmount}
              />
              <DataTableHeaderItem
                name="differenceAmount"
                displayText={cashReceiptsFields?.differenceAmount}
              />
              <DataTableHeaderItem name="comments" />
            </DataTableHeader>
            <DataTableBody zebra>
              {cashReceiptData?.rows?.map((row: CashReceiptRow) => {
                const maxForTrend = getCashReceiptMax(
                  row.receivedAmount,
                  row.previouslyApprovedAmount
                );

                return (
                  <DataTableRow
                    name={`cashReceipts-${row.typeCode}`}
                    key={`cashReceipts-${row.typeCode}-${row.idx}`}
                  >
                    <TextCell
                      text={`${row.typeCode && codeRowDictionary.get(row.typeCode.toString())}`}
                      name={`cashReceipt-natureOfReceipt-row-${row.typeCode}-${row.idx}`}
                    />
                    <TextCell
                      text={toLocaleDate(row.previouslyApprovedDate)}
                      name={`cashReceipt-previousAssessedDate-row-${row.typeCode}-${row.idx}`}
                    />
                    <TextCell
                      text={toCurrency(row.previouslyApprovedAmount)}
                      name={`cashReceipt-previousAssessedAmount-row-${row.typeCode}-${row.idx}`}
                    />
                    <TextCell
                      text={toLocaleDate(row.receivedDate)}
                      name={`cashReceipt-submittedDate-row-${row.typeCode}-${row.idx}`}
                    />
                    <TextCell
                      text={toCurrency(row.receivedAmount)}
                      name={`cashReceipt-submittedAmount-row-${row.typeCode}-${row.idx}`}
                    />

                    <FormFieldCell>
                      <FormDatepicker
                        name={`cashReceipts[${row.idx}].admissibleDate`}
                        className={`${styles.tableInput} ${styles.admissibleDate}`}
                        label={{ value: 'defaultDateAdjusted' }}
                        isReadOnly={
                          !assessmentCalculationsContext.isEditing ||
                          isClaimStatusPostAdjudicationStatus === true
                        }
                      />
                    </FormFieldCell>

                    <FormFieldCell>
                      <TrendableCell
                        name={`cashReceipts[${row.idx}].admissibleAmountTrend`}
                        acceptable={maxForTrend}
                        adjusted={row.idx != null ? formData[row.idx]?.admissibleAmount : undefined}
                        received={maxForTrend}
                      />
                      <FormNumber
                        name={`cashReceipts[${row.idx}].admissibleAmount`}
                        className={`${styles.tableInput} ${styles.trendable}`}
                        label={{ value: `cashReceipt-admissibleAmount-row-${row.typeCode}` }}
                        formatProps={getCurrencyFormat(
                          getValues(`cashReceipts[${row.idx}].admissibleAmount`),
                          { ...currencyFormat, thousandSeparator: true },
                          i18next.language as LanguageShort
                        )}
                        isReadOnly={
                          !assessmentCalculationsContext.isEditing ||
                          isClaimStatusPostAdjudicationStatus === true
                        }
                        tabIndex={
                          !assessmentCalculationsContext.isEditing ||
                          isClaimStatusPostAdjudicationStatus === true
                            ? -1
                            : undefined
                        }
                      />
                    </FormFieldCell>

                    <TextCell
                      text={toCurrency(row.differenceAmount)}
                      name={`cashReceipt-differenceAmount-row-${row.typeCode}-${row.idx}`}
                    />
                    {row.comment ? (
                      <CommentCell
                        name={`comment-${row.typeCode}-${row.idx}`}
                        comment={row.comment}
                      />
                    ) : (
                      <TextCell text={''} name={`noComment-${row.typeCode}-${row.idx}`} />
                    )}
                  </DataTableRow>
                );
              })}
              <DataTableRow name="cashReceiptsSubtotals" subtotal>
                <TextCell
                  text={cashReceiptsFields?.subtotal.value ?? ''}
                  name={`cashReceiptsSubtotal`}
                  className="subtotalBg"
                />
                <TextCell text="" name="subtotalPreviousAssessDateEmpty" />
                <TextCell
                  text={toCurrency(cashReceiptData?.columnTotals?.previouslyApprovedAmount)}
                  name="subtotalPreviousAssessAmount"
                />
                <TextCell text="" name="subtotalSubmittedDateEmpty" />
                <TextCell
                  text={toCurrency(cashReceiptData?.columnTotals?.receivedAmount)}
                  name="subtotalSubmittedAmount"
                />
                <TextCell text="" name="subtotalAdmissibleDateEmpty" />
                <TextCell
                  text={toCurrency(cashReceiptData?.columnTotals?.admissibleAmount)}
                  name="subtotalAdmissibleAmount"
                />
                <TextCell
                  text={toCurrency(cashReceiptData?.columnTotals.differenceAmount)}
                  name="subtotalDifferenceAmountEmpty"
                />
                <TextCell text={''} name={`noComment-subtotal`} />
              </DataTableRow>
            </DataTableBody>
          </DataTable>
        </fieldset>
      </ScrollableDataTableContainer>
    </div>
  );
};
