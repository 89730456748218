import i18n from 'i18next';
import { useFeature } from 'flagged';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DocumentType,
  InternalClaimStatus,
  PostAdjudicationClaimStatuses
} from '@hobt/claim-domain';
import {
  HbtServiceErrorCodes,
  HttpResponseStatusCodes,
  ModuleMapping,
  UserRole
} from '@hobt/constants';
import { ApiClient } from 'Foundation/Api';
import { TabItemProps } from 'Components/Navigation/Tabs';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import DetailsProps, { DetailsTab } from 'Feature/Claims/components/Details/types';
import { FinalClaimResponseData } from 'Feature/Claims/models/ClaimsSubmissionDetailsProps';
import { convertBooleanValuesToStringInJsonObject } from 'Constants/Utils/JSONConverters/convertBooleanValuesToStringInJsonObject';
import { pathNames } from 'Constants/pathNames';
import ClaimsSubmissionDetails from 'Feature/Claims/components/Details/ClaimsSubmissionDetails';
import LoanDetails from 'Feature/Claims/components/Details/LoanDetails';
import RefundManagement from 'Feature/Claims/components/Details/RefundManagement';
import SuspenseConditions from 'Feature/Claims/components/Details/SuspenseConditions';
import AttachmentsNotesCommentsDetails from 'Feature/Claims/components/Details/AttachmentsNotesCommentsDetails';
import AssessmentCalculations from 'Feature/Claims/components/Details/AssessmentCalculations';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { config } from '../../../../config';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext/HydrateSitecoreContext.types';

export const useClaimAdjudicationFunction = (
  claimID: string,
  authContext: any,
  sitecoreContent: DetailsProps
) => {
  const history = useHistory();

  const tabContentLookup: Record<string, React.FC<any>> = {
    ClaimsSubmissionDetails,
    LoanDetails,
    RefundManagement,
    SuspenseConditions,
    AttachmentsNotesCommentsDetails,
    AssessmentCalculations
  };

  const finalClaimUrl = `${config.claimApi.urls.finalClaim}/${claimID}/`;
  const autoAdjudicateUrl = `${config.claimApi.urls.autoAdjudicate}/${claimID}`;
  const documentDownloadUrl = config.documentApi.urls.get;

  const { getWithAuth, putWithAuth } = ApiClient(authContext, {
    timeout: config.claimApi.requestTimeout
  });

  const tabs: TabItemProps[] = (
    sitecoreContent.rendering.placeholders.hbtTabs as DetailsTab[]
  )?.map((tab: DetailsTab) => ({
    displayText: tab.fields.heading,
    name: tab.componentName,
    fields: tab.fields
  }));

  const [currentTabProps, setCurrentTabProps] = useState<TabItemProps>(tabs[0]);
  const [pendingTab, setPendingTab] = useState<TabItemProps | null>(null);
  const [tabsLocked, setTabsLocked] = useState<boolean>(false);
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [isTabLoading, setIsTabLoading] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = useState<Spinner>({
    isLoading: false
  });

  const [isInitialTabOpening, setIsInitialTabOpening] = useState<boolean>(true);
  const [finalClaimData, setFinalClaimData] = useState<FinalClaimResponseData>();

  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);
  const SitecoreContextFactory = useSitecoreContext();
  const sitecoreContentDetails = SitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const moduleRoleMapping = sitecoreContentDetails?.user?.moduleRoleMapping;
  const isReadOnlyAccessUser: boolean =
    isUserInRoles(ModuleMapping.claim, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  const isMasterUser =
    isUserInRoles(ModuleMapping.claim, [UserRole.MasterUser], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  const isClaimStatusPostAdjudicationStatus = PostAdjudicationClaimStatuses.includes(
    finalClaimData?.profile?.claimStatus?.code as InternalClaimStatus
  );

  const isExternalUser =
    isUserInRoles(
      ModuleMapping.claim,
      [UserRole.ApprovedLender, UserRole.ServiceProvider, UserRole.LenderAdministrator],
      moduleRoleMapping
    ) && siteTypeIsInternal === false;
  const isInternalUser =
    isUserInRoles(
      ModuleMapping.claim,
      [UserRole.BusinessUser, UserRole.Clerk, UserRole.CmhcAdministrator],
      moduleRoleMapping
    ) && siteTypeIsInternal === true;

  const isMasterUserEditingPostAdjudicationClaim: boolean =
    isMasterUser && isClaimStatusPostAdjudicationStatus;
  const isInternalUserAndReadOnlyView: boolean =
    isInternalUser && isClaimStatusPostAdjudicationStatus;
  const isReadOnlyView: boolean =
    isReadOnlyAccessUser || isExternalUser || isInternalUserAndReadOnlyView;

  // Tabs functions
  const lockTabs = (lockState: boolean) => {
    setTabsLocked(lockState);
  };

  const cancelTabNavigation = () => {
    setPendingTab(null);
  };

  const proceedWithTabNavigation = () => {
    if (pendingTab != null) {
      setIsInEditMode(false);
      setCurrentTabProps(pendingTab);
      setTabsLocked(false);
      setPendingTab(null);
    }
  };

  const handleTabClick = (tab: TabItemProps): void => {
    if (tabsLocked) {
      setPendingTab(tab);
    } else {
      setCurrentTabProps(tab);
    }
  };

  // Final Claim get and update functions
  const getFinalClaimData = async () => {
    if (isInitialTabOpening) {
      setIsPageLoading(true);
      setIsInitialTabOpening(false);
    }

    try {
      const { data } = await getWithAuth(finalClaimUrl);
      setFinalClaimData(convertBooleanValuesToStringInJsonObject(data.data[0]));
      setIsPageLoading(false);
    } catch (e: any) {
      setIsPageLoading(false);

      if (
        (e?.response?.status === HttpResponseStatusCodes.ServerError &&
          e?.response?.data?.error?.errorCode ===
            HbtServiceErrorCodes.HBT_ERR_NOT_AUTHORIZED.code) ||
        e?.response?.status === HttpResponseStatusCodes.Unauthorized
      ) {
        const message = i18n.t('Globals-Unauthorized-Action');
        history.push(`/${i18n.language}${pathNames.errorApplication}`, {
          state: { message }
        });
      } else {
        throw new Error('Invalid configuration. Could not read URL or path for API calls.');
      }
    }
  };

  const updateClaimData = async (claimData?: FinalClaimResponseData[]) => {
    if (claimData != null) {
      setFinalClaimData(convertBooleanValuesToStringInJsonObject(claimData[0]));
    } else {
      await getFinalClaimData();
    }
  };

  // Render tab content function
  const renderTabContent = () => {
    if (currentTabProps != null) {
      return React.createElement(tabContentLookup[currentTabProps.name], currentTabProps);
    }

    return null;
  };

  const autoDecision = async () => {
    setLoadingStatus({
      isLoading: true,
      spinnerHeading: 'Globals-Loading-Heading',
      spinnerDescription: 'Globals-Loading-Description'
    });

    const { data } = await putWithAuth(autoAdjudicateUrl, {});
    setFinalClaimData(convertBooleanValuesToStringInJsonObject(data.data[0]));
    if (updateClaimData != null) {
      updateClaimData(data?.data[0]);
    }
    setLoadingStatus({
      isLoading: false
    });
  };

  const downloadClaimCalculationLetter = async (documentId: string) => {
    const response = await getWithAuth(
      `${documentDownloadUrl}${documentId}?module=claim&entityID=${claimID}&documentType=${DocumentType.ClaimCalculationLetterBeforeApproval}`
    );
    return response;
  };

  useEffect(() => {
    getFinalClaimData();
  }, []);

  return {
    currentTabProps,
    pendingTab,
    tabsLocked,
    isInEditMode,
    setIsInEditMode,
    tabs,
    finalClaimData,
    isClaimStatusPostAdjudicationStatus,
    isMasterUserEditingPostAdjudicationClaim,
    isMasterUser,
    isReadOnlyAccessUser,
    isReadOnlyView,
    isPageLoading,
    loadingStatus,
    isTabLoading,
    setIsTabLoading,
    setIsPageLoading,
    setLoadingStatus,
    lockTabs,
    cancelTabNavigation,
    proceedWithTabNavigation,
    handleTabClick,
    renderTabContent,
    updateClaimData,
    autoDecision,
    downloadClaimCalculationLetter
  };
};
