import React, { FC, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormTextArea from 'Components/Inputs/FormTextArea';

import styles from './styles.module.scss';

type Props = {
  name: string;
  className?: string;
  hidden: boolean;
  label?: string;
  updateLabel?: string;
  cancelLabel?: string;
  characterLimitLabel?: string;
  onClick: ($event: React.MouseEvent) => void;
};

const ExpandedComment: FC<Props> = ({
  name,
  hidden,
  className,
  characterLimitLabel,
  cancelLabel,
  updateLabel,
  label,
  onClick
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { getValues, setValue } = useFormContext();
  const [original, setChange] = useState(getValues(name));
  const commit = ($event: React.MouseEvent) => {
    setChange(getValues(name));
    onClick($event);
  };
  const clear = ($event: React.MouseEvent) => {
    setValue(name, original || '');
    onClick($event);
  };

  // Note: Using querySelector for specific use case to access child element and focus on it.
  React.useEffect(() => {
    if (!hidden && ref.current?.firstElementChild != null) {
      ref.current.firstElementChild.querySelector('textarea')?.focus();
    }
  }, [ref.current, hidden]);

  return (
    <div
      className={hidden ? 'd-none' : `d-flex flex-wrap ${styles['form-wrapper']} ${className}`}
      ref={ref}
      tabIndex={0}
    >
      <FormTextArea
        name={name}
        label={{ value: label }}
        className="w-100"
        charLimit={40}
        textAreaHelperText={characterLimitLabel}
        attributeProps={{ rows: 1 }}
      />
      <button type="button" className={styles['update-button']} onClick={commit}>
        {updateLabel}
      </button>
      <button type="button" className={styles['cancel-button']} onClick={clear}>
        {cancelLabel}
      </button>
    </div>
  );
};

export default ExpandedComment;
