import i18n from 'i18next';
import { ValidationErrorMessageType } from 'Feature/DefaultSubmissionForm/models/typeCode.types';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

/*
    Error Messages (errMsg examples):
    "lender.approvedLenderName" is not allowed to be empty
    "lender.transitNumber" must be less than or equal to 99999
    "lender.transitNumber" must be greater than or equal to 0
    "lender.contactName" length must be less than or equal to 35 characters long
*/

function CustomErrorMessage(
  numberLimitString: string,
  customErrorMessage: ValidationErrorMessageType
) {
  switch (customErrorMessage) {
    case ValidationErrorMessageType.MaxString:
      return i18n
        .t('DefaultSubmission-Card-ErrorMessage-MaxString')
        .replace('{count}', numberLimitString.length.toString());
    case ValidationErrorMessageType.LoanNumberOverride:
      return i18n
        .t('DefaultSubmission-Card-ErrorMessage-ExactCount')
        .replace('{count}', numberLimitString.length.toString());
    default:
      return 'Custom error message not found';
  }
}

function FormatNumMaxErrorMessage(
  defaultErrorMsg: string,
  customErrorMessage?: ValidationErrorMessageType
) {
  const maxNumString = defaultErrorMsg.substring(
    defaultErrorMsg.indexOf('equal to') + 'equal to'.length + 1,
    defaultErrorMsg.length
  );

  if (customErrorMessage !== undefined) {
    return CustomErrorMessage(maxNumString, customErrorMessage);
  }

  // Round up to nearest whole number
  let unformattedMaxNum = Math.ceil(Number(maxNumString));

  // In the event that the number did not round up previously (due to not having decimal), this is to ensure the value is number + 1
  if (maxNumString === unformattedMaxNum.toString()) {
    unformattedMaxNum += 1;
  }

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const maxNum = unformattedMaxNum.toLocaleString(sitecoreContext.language);

  return i18n.t('DefaultSubmission-Card-ErrorMessage-MaxNumber').replace('{count}', maxNum);
}

function FormatNumMinErrorMessage(
  defaultErrorMsg: string,
  customErrorMessage?: ValidationErrorMessageType
) {
  let minNumString = defaultErrorMsg.substring(
    defaultErrorMsg.indexOf('equal to') + 'equal to'.length + 1,
    defaultErrorMsg.length
  );

  if (customErrorMessage !== undefined) {
    /* TODO: Hard-coding for now to over-ride message to continue showing "Must be 8 characters" for the Loan Number
        even though you only need 7 (text message doesn't actual reflect schema validation - requested by client). */
    if (customErrorMessage === ValidationErrorMessageType.LoanNumberOverride) {
      minNumString += '0';
    }

    return CustomErrorMessage(minNumString, customErrorMessage);
  }

  return i18n.t('DefaultSubmission-Card-ErrorMessage-MinNumber').replace('{count}', minNumString);
}

function FormatStringMaxErrorMessage(defaultErrorMsg: string) {
  const maxNum = defaultErrorMsg.substring(
    defaultErrorMsg.indexOf('equal to') + 'equal to'.length + 1,
    defaultErrorMsg.indexOf('characters long') - 1
  );

  return i18n.t('DefaultSubmission-Card-ErrorMessage-MaxString').replace('{count}', maxNum);
}

function FormatStringMinErrorMessage(defaultErrorMsg: string) {
  const minNum = defaultErrorMsg.substring(
    defaultErrorMsg.indexOf('at least') + 'at least'.length + 1,
    defaultErrorMsg.indexOf('characters long') - 1
  );

  return i18n.t('DefaultSubmission-Card-ErrorMessage-MinString').replace('{count}', minNum);
}

/**
 * TODO: Use a less hacky approach. Perhaps have the JOI schema return custom error messages for the mapping.
 * Otherwise find a cleaner way to extract the min and max value
 */

export default function GetErrorMessage(
  errType: string,
  errMsg: string,
  customErrorMessage?: ValidationErrorMessageType,
  fieldLabel?: string
) {
  // Handles custom error messages mapping with Sitecore content:
  let match = null;
  if (errMsg !== undefined && errMsg !== null && errMsg !== '') {
    const result = errMsg.split('failed custom validation because ');
    match = result.pop()!.match(/\[HBT_VAL_ERR_([^\]]*)\]/);
  }
  if (match !== null) {
    const errorCode = `HBT_VAL_ERR_${match[1]}`;
    let mappedErrMsg = i18n.t(`Errors-${errorCode}`);
    mappedErrMsg = mappedErrMsg.replace('{FieldLabel}', fieldLabel || '');
    return mappedErrMsg;
  }
  switch (errType) {
    case 'number.base':
    case 'any.required':
    case 'string.empty':
    case 'boolean.base':
    case 'date.format':
    case 'array.min':
    case 'date.base':
      return i18n.t('DefaultSubmission-Card-ErrorMessage-Required');
    case 'date.max':
      return i18n.t('DefaultSubmission-Card-ErrorMessage-MaxDate');
    case 'number.unsafe':
      return i18n.t('DefaultSubmission-Card-ErrorMessage-UnsafeNumber');
    case 'number.max':
      return FormatNumMaxErrorMessage(errMsg, customErrorMessage);
    case 'number.min':
      return FormatNumMinErrorMessage(errMsg, customErrorMessage);
    case 'string.max':
      return FormatStringMaxErrorMessage(errMsg);
    case 'string.min':
      return FormatStringMinErrorMessage(errMsg);
    case 'string.email':
      return i18n.t('DefaultSubmission-Card-ErrorMessage-InvalidEmail');
    case 'any.only':
      return i18n.t('DefaultSubmission-Card-ErrorMessage-Required');
    default:
      return '';
  }
}
