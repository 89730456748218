import i18n from 'i18next';

export const getLenderAcknowledgmentProps = () => {
  const lenderAcknowledgementProps = {
    testId: 'lenderAcknowledgmentTest',
    accordionLineColor: 'grey',
    linePosition: 'vertical',
    title: {
      field: {
        value: i18n.t('DefaultSubmission-Card-LenderAcknowledgementHeading')
      }
    },
    maxLength: Number(i18n.t('Globals-MultilineText-MaxLength')),
    ifCommentRequiredLinkURL: {
      value: {
        href: '#attachments',
        text: i18n.t('DefaultSubmission-CardM-SectionNLink')
      }
    }
  };

  return lenderAcknowledgementProps;
};
