import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';

import {
  ClaimColumnName,
  DraftClaimColumnName,
  DraftClaimStatus,
  InternalClaimStatus
} from '@hobt/claim-domain';

import { useFeature } from 'flagged';

import {
  ClaimsInventoryProps,
  ClaimsInventoryTabEnum,
  FinalClaimCount
} from 'Feature/Claims/components/ClaimsInventory/types';

import { ApiClient, ComparisonOperators, LogicalOperators } from 'Foundation/Api';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { TabItemProps } from 'Components/Navigation/Tabs';
import { pathNames } from 'Constants';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import { useDraftClaimClient } from 'Feature/Claims/components/ClaimsSubmissionForm/draftClaimClient';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { config } from '../../../../config';

import { CancelClaimFlyoutData } from '../Details/AdjudicationCardHeader/CancelClaim/types';

const DRAFT_CLAIM_TABS = [ClaimsInventoryTabEnum.Draft, ClaimsInventoryTabEnum.ProcessInMics];

const useClaimsInventoryFunctions = ({ fields }: ClaimsInventoryProps) => {
  const history = useHistory();
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);
  const authenticationContext = useAuthenticationContext();
  const { getWithAuth } = ApiClient(authenticationContext, {
    timeout: config.claimApi.requestTimeout
  });

  const claimInventoryCountsUrl = config.claimApi.urls.claimInventoryCounts;

  const [hasSearched, setHasSearched] = React.useState<boolean>(false);
  const [tabCountsLoading, setTabCountsLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<Spinner>({
    isLoading: false
  });

  const [items, setItems] = useState<TabItemProps[]>(
    siteTypeIsInternal
      ? [
          {
            displayText: fields.unassigned,
            name: 'unassigned'
          },
          {
            displayText: fields.all,
            name: 'all'
          },
          {
            displayText: fields.inReview ?? { value: '' },
            name: 'inReview'
          },
          {
            displayText: fields.approved,
            name: 'approved'
          },
          {
            displayText: fields.declinedCancelled,
            name: 'declinedCancelled'
          },
          {
            displayText: fields.processinMICS ?? { value: '' },
            name: 'processInMics'
          },
          {
            displayText: fields.drafts,
            name: 'activeDrafts'
          }
        ]
      : [
          {
            displayText: fields.all,
            name: 'all'
          },
          {
            displayText: fields.inProgress,
            name: 'inProgress'
          },
          {
            displayText: fields.approved,
            name: 'approved'
          },
          {
            displayText: fields.declinedCancelled,
            name: 'declinedCancelled'
          },
          {
            displayText: fields.refundRequest,
            name: 'refundRequests'
          },
          {
            displayText: fields.processInEmili,
            name: 'processInMics'
          },
          {
            displayText: fields.drafts,
            name: 'activeDrafts'
          }
        ]
  );

  const [cancelClaimShowSideDrawer, setCancelClaimShowSideDrawer] = useState<boolean>(false);
  const [showCancelClaimStatusToast, setCancelClaimStatusToast] = useState<boolean>(false);
  const [showCancelClaimState, setCancelClaimState] = useState<ApplicationStates>(
    ApplicationStates.DEFAULT
  );
  const [cancelClaimFlyoutData, setCancelClaimFlyoutData] = useState<CancelClaimFlyoutData>();
  const [currentTab, setCurrentTab] = useState<TabItemProps>(
    siteTypeIsInternal ? items[1] : items[0]
  );
  const [showFinalClaimInventory, setShowFinalClaimInventory] = useState<boolean>(false);
  const getTabCounts = async (): Promise<FinalClaimCount[]> => {
    try {
      setTabCountsLoading(true);
      const response = await getWithAuth(claimInventoryCountsUrl, {
        timeout: config.claimApi.sessionTimeoutClaimInventory
      });

      setTabCountsLoading(false);
      return response?.data?.data;
    } catch (e) {
      onApiErrorCallback(e);
      return Promise.reject(e);
    }
  };

  const setTabCounts = (counts: FinalClaimCount[]) => {
    setItems((currentState: TabItemProps[]) => {
      return currentState?.map((item: TabItemProps) => {
        const newCount = counts?.find((count: FinalClaimCount) => count.key === item.name);
        return {
          ...item,
          count: newCount?.count ?? 0
        };
      });
    });
  };

  const showCancelClaimForm = () => {
    setCancelClaimShowSideDrawer(true);
    setCancelClaimStatusToast(false);
  };

  const hideCancelClaimForm = () => {
    setCancelClaimShowSideDrawer(false);
  };

  const closeCancelToast = () => {
    setCancelClaimStatusToast(false);
  };
  const onCancelClaimErrorSubmit = () => {
    setCancelClaimState(ApplicationStates.ERROR);
    setCancelClaimStatusToast(true);
    setCancelClaimShowSideDrawer(false);
  };

  const onCancelClaimSuccessSubmit = () => {
    setCancelClaimState(ApplicationStates.SUCCESS);
    setCancelClaimStatusToast(true);
    setCancelClaimShowSideDrawer(false);
  };

  const onCreateNew = () => {
    history.push(`/${i18n.language}${pathNames.claim}`);
  };

  const onTabClick = (tab: TabItemProps): void => {
    setCurrentTab(tab);
  };

  const onApiErrorCallback = (e: unknown) => {
    // TODO: Handle API Error
    // eslint-disable-next-line
    setShowFinalClaimInventory(true);
    console.log(e);
  };

  const { deleteDraftClaim } = useDraftClaimClient(authenticationContext, {
    timeout: config.claimApi.requestTimeout
  });

  const navigateToClaimsAdjudication = (
    e: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>
  ) => {
    const recordId: string | null = e.currentTarget.getAttribute('data-rowId');

    if (recordId != null) {
      history.push(`/${i18n.language}${pathNames.claimsAdjudication}?id=${recordId}`);
    }
  };

  const navigateToClaimSubmissionForm = (
    e: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>
  ) => {
    const recordId: string | null = e.currentTarget.getAttribute('data-rowId');

    if (recordId != null) {
      history.push(`/${i18n.language}${pathNames.claim}?id=${recordId}`);
    }
  };

  const onRowClick = (e: React.MouseEvent<HTMLElement>) => {
    if (DRAFT_CLAIM_TABS.includes(currentTab.name as ClaimsInventoryTabEnum)) {
      navigateToClaimSubmissionForm(e);
    } else {
      navigateToClaimsAdjudication(e);
    }
  };

  const tabFilterQueryLookupInternal = {
    all: null,

    inReview: `${ClaimColumnName.ClaimStatusCode} ${ComparisonOperators.IN} (\
    '${InternalClaimStatus.Pending}', '${InternalClaimStatus.ReviewRequired}', '${InternalClaimStatus.InProgress}',\
    '${InternalClaimStatus.ApprovalRequired}')`,

    approved: `${ClaimColumnName.ClaimStatusCode} ${ComparisonOperators.IN} (\
    '${InternalClaimStatus.ApprovedAuto}',\
    '${InternalClaimStatus.ApprovedManual}',\
    '${InternalClaimStatus.ClaimPaymentInProgress}',\
    '${InternalClaimStatus.PaymentFailed}',\
    '${InternalClaimStatus.NoClaimPayable}',\
    '${InternalClaimStatus.Paid}',\
    '${InternalClaimStatus.RefundRequested}',\
    '${InternalClaimStatus.CmhcReimbursed}')`,

    declinedCancelled: `${ClaimColumnName.ClaimStatusCode} ${ComparisonOperators.IN} (\
    '${InternalClaimStatus.Declined}',\
    '${InternalClaimStatus.Cancelled}')`,

    activeDrafts: null,

    processInMics: `${DraftClaimColumnName.StatusCode} ${ComparisonOperators.EQ} ${DraftClaimStatus.ProcessInMics}`,

    unassigned: `${ClaimColumnName.ClaimStatusCode} ${ComparisonOperators.IN} (\
    '${InternalClaimStatus.New}',\
    '${InternalClaimStatus.ReviewRequired}',\
    '${InternalClaimStatus.Pending}'\
    ) ${LogicalOperators.AND} ${ClaimColumnName.AdjudicatorUserID} ${ComparisonOperators.IS} NULL`
  };

  const tabFilterQueryLookupExternal = {
    all: null,
    activeDrafts: null,
    inProgress: `${ClaimColumnName.ClaimStatusCode} ${ComparisonOperators.IN} (${InternalClaimStatus.New},${InternalClaimStatus.ReviewRequired},${InternalClaimStatus.InProgress},${InternalClaimStatus.Pending},${InternalClaimStatus.ApprovalRequired})`,
    approved: `${ClaimColumnName.ClaimStatusCode} ${ComparisonOperators.IN} (${InternalClaimStatus.Paid},${InternalClaimStatus.ApprovedAuto},${InternalClaimStatus.ApprovedManual},${InternalClaimStatus.ClaimPaymentInProgress},${InternalClaimStatus.PaymentFailed},${InternalClaimStatus.NoClaimPayable})`,
    declinedCancelled: `${ClaimColumnName.ClaimStatusCode} ${ComparisonOperators.IN} (${InternalClaimStatus.Cancelled},${InternalClaimStatus.Declined})`,
    refundRequests: `${ClaimColumnName.ClaimStatusCode} ${ComparisonOperators.IN} (${InternalClaimStatus.CmhcReimbursed},${InternalClaimStatus.RefundRequested})`,
    processInMics: `${DraftClaimColumnName.StatusCode} ${ComparisonOperators.EQ} ${DraftClaimStatus.ProcessInMics}`
  };

  return {
    currentTab,
    cancelClaimShowSideDrawer,
    hasSearched,
    items,
    showCancelClaimStatusToast,
    showCancelClaimState,
    siteTypeIsInternal,
    tabFilterQueryLookupInternal,
    tabFilterQueryLookupExternal,
    tabCountsLoading,
    isLoading,
    setIsLoading,
    onApiErrorCallback,
    onCancelClaimErrorSubmit,
    onCancelClaimSuccessSubmit,
    onCreateNew,
    onRowClick,
    onTabClick,
    closeCancelToast,
    deleteDraftClaim,
    setCurrentTab,
    setHasSearched,
    getTabCounts,
    setTabCounts,
    showCancelClaimForm,
    hideCancelClaimForm,
    cancelClaimFlyoutData,
    setCancelClaimFlyoutData,
    showFinalClaimInventory
  };
};

export default useClaimsInventoryFunctions;
