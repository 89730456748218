// Importing Libraries
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { ClaimTypeCode } from '@hobt/claim-domain';

// Importing Common components and form components
import { ButtonType } from 'Feature/CommonComponents/UserControls';
import DynamicHeading from 'Components/Common/DynamicHeading';
import FormCard from 'Components/Common/FormCard';
import { currencyFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormYesNoRadios from 'Components/Inputs/FormYesNoRadios';
import { formatDate } from 'Components/Common/Api/utils/DateParser';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

// Importing props, types and child components
import styles from './styles.module.scss';
import type ClaimsListingPeriodProps from './types';
import ListingPeriodDetails from './ListingPeriodDetails';
import { GlossaryNames } from '../../../../PageComponents/components/GlossaryComponent/types';
import { TListingPeriodArray } from './types';

const TYPES_OF_CLAIMS_EXEMPT_FROM_REQUIRED_FIELDS = [
  ClaimTypeCode.EnhancedClaimService,
  ClaimTypeCode.WalkAway
];

const ClaimsListingPeriod: React.FC<ClaimsListingPeriodProps> = (
  props: ClaimsListingPeriodProps
) => {
  const { trigger, setValue, watch } = useFormContext();
  const { claimData, isClaimsDetails, isFieldDisabled } = useHBTFormContext();
  const {
    fields: listings,
    append,
    remove
  }: TListingPeriodArray = useFieldArray({ name: 'listings' });
  const exceptionToListing = useWatch({ name: 'exceptionToListingFlag' }) as string;

  const claimTypeCode = watch('detail.claimTypeCode');
  const isECSOrWalkawayClaimType =
    TYPES_OF_CLAIMS_EXEMPT_FROM_REQUIRED_FIELDS.includes(claimTypeCode);
  const listingPeriodId = watch('listings');

  useEffect(() => {
    // @ts-ignore
    const setPeriodId = (index, id) => setValue(`listings[${index}].periodID`, id);
    // @ts-ignore
    listingPeriodId?.forEach((_, index) => setPeriodId(index, index + 1));
  }, [listingPeriodId]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    listings?.map((_item, index) => {
      if (listings[index].appraisalDate != null) {
        setValue(`listings[${index}].appraisalDate`, formatDate(listings[index].appraisalDate));
      }

      if (listings[index].date != null) {
        setValue(`listings[${index}].date`, formatDate(listings[index].date));
      }

      if (listings[index].expiryDate != null) {
        setValue(`listings[${index}].expiryDate`, formatDate(listings[index].expiryDate));
      }

      if (listings[index].comparativeMarketAnalysisDate != null) {
        setValue(
          `listings[${index}].comparativeMarketAnalysisDate`,
          formatDate(listings[index].comparativeMarketAnalysisDate)
        );
      }

      if (listings[index].appraisalValue != null) {
        setValue(`listings[${index}].appraisalValue`, Number(listings[index].appraisalValue));
      }

      if (listings[index].comparativeMarketAnalysisValue != null) {
        setValue(
          `listings[${index}].comparativeMarketAnalysisValue`,
          Number(listings[index].comparativeMarketAnalysisValue)
        );
      }

      if (listings[index].minimumAcceptableSalePriceAmount != null) {
        setValue(
          `listings[${index}].minimumAcceptableSalePriceAmount`,
          Number(listings[index].minimumAcceptableSalePriceAmount)
        );
      }

      if (listings[index].priceAmount != null) {
        setValue(`listings[${index}].priceAmount`, Number(listings[index].priceAmount));
      }

      if (listings[index].revisedPriceAmount != null) {
        setValue(
          `listings[${index}].revisedPriceAmount`,
          Number(listings[index].revisedPriceAmount)
        );
      }
    });
  }, [listings]);

  const [showSecondaryListing, setShowSecondaryListing] = useState(false);

  const addSecondaryListing = () => {
    append({});
    setShowSecondaryListing(true);
  };

  useEffect(() => {
    if (isClaimsDetails === true && listings?.length > 1) {
      setShowSecondaryListing(true);
    }
  }, [isClaimsDetails]);

  useEffect(() => {
    if (isECSOrWalkawayClaimType) {
      setValue('exceptionToListingFlag', 'false');

      if (listings.length > 1) {
        remove(listings.length - 1);
      }
    } else if (!isECSOrWalkawayClaimType && claimData?.listings != null) {
      setValue('listings', claimData.listings);
    }
  }, [claimTypeCode]);

  return (
    <>
      <FormCard
        title={props.fields.cardTitle}
        headingLevel={2}
        sectionId="claims-listing-body"
        fieldToValidate="listings"
        toolTipButton={{
          id: 'listingPeriodGlossaryTooltip',
          name: 'listingPeriodGlossaryTooltip',
          ariaText: props.fields.cardGlossaryAriaText?.value ?? '',
          onClick: () => {
            props?.fields?.openGlossary?.(GlossaryNames.ListingPeriod);
          },
          buttonType: ButtonType.TEXT
        }}
      >
        <DynamicHeading headingLevel={3} className={styles.subHeading}>
          <Text field={props.fields.firstListingText} />
        </DynamicHeading>
        <FormYesNoRadios
          testid="exceptionToListingFlag"
          name="exceptionToListingFlag"
          label={props.fields.exceptiontoListing}
          className={styles.full}
          isReadOnly={isFieldDisabled === true || isECSOrWalkawayClaimType}
        />
        {listings?.map((_item, index) => (
          <ListingPeriodDetails
            key={index}
            {...props}
            exceptionToListing={exceptionToListing}
            index={index}
            remove={remove}
            showSecondaryListing={index === 0 ? false : showSecondaryListing}
            currencyFormat={currencyFormat}
          />
        ))}
        {listings.length === 1 && !isECSOrWalkawayClaimType && <hr className={styles.separator} />}
        {listings.length === 1 && !isECSOrWalkawayClaimType && (
          <div>
            <button
              className={exceptionToListing === 'false' ? 'btn-add-color' : 'btn-disabled-color '}
              disabled={exceptionToListing !== 'false' || isFieldDisabled}
              onClick={addSecondaryListing}
              name="Add"
              type="button"
            >
              <i aria-hidden={true} className="material-icons icon--size-32 icon--v-align-middle">
                add_circle_outline
              </i>
              <Text field={props.fields.additionalListingPeriod} />
            </button>
          </div>
        )}
      </FormCard>
    </>
  );
};

export default ClaimsListingPeriod;
