import React from 'react';
import { ItemWrapper } from 'Feature/Footer/components/FooterComponent/types';
import { FooterNavProps } from 'Feature/Footer/components/FooterComponent/FooterNav/types';
import { Link as RouterLink } from 'react-router-dom';
import { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import { LinkFieldValue } from '@sitecore-jss/sitecore-jss-manifest';
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';

import styles from './styles.module.scss';

const FooterNavLink: React.FC<{ link: LinkField | LinkFieldValue }> = ({ link }) => {
  const processedLink: any = 'value' in link ? (link.value as LinkFieldValue) : link;

  return processedLink.href.startsWith('/') ? (
    <RouterLink to={processedLink.href}>
      <span className={styles.footerTextStyling}>
        <Text field={{ value: processedLink.text }} />
      </span>
    </RouterLink>
  ) : (
    <Link field={processedLink} />
  );
};

export const FooterNav: React.FC<FooterNavProps> = ({ links }: FooterNavProps) => (
  <nav className={styles.footerLinks}>
    <ul>
      {links.map((link: ItemWrapper, idx) => (
        <li key={`footer-nav-item-${idx}`}>
          <FooterNavLink link={link.fields.navItem as LinkField | LinkFieldValue} />
        </li>
      ))}
    </ul>
  </nav>
);
