export enum DividerSpacingType {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Variable = 'variable'
}

export type VerticalMargins = Record<DividerSpacingType, number[]>;

export interface DividerProps {
  spacingType: DividerSpacingType;
  variableMargins?: number[];
  screenReaderText: string;
}
