import React, { PropsWithChildren, useRef } from 'react';
import { TableShiftButton } from 'Components/Common/DataTable/TableShiftButton';
import { useScrollableDataContainerFunctions } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/ScrollableDataTableContainer/useScrollableDataContainerFunctions';

import styles from './styles.module.scss';

export const ScrollableDataTableContainer: React.FC<
  PropsWithChildren<{ className?: string; name?: string }>
> = ({ className, name, children }) => {
  const scrollXRef = useRef<HTMLDivElement>(null);

  const { onScroll, tableScrolled, onRightScrollButton, onLeftScrollButton, visibleShiftButtons } =
    useScrollableDataContainerFunctions(scrollXRef);

  return (
    <div className={styles.scrollableWrapper}>
      <div
        className={`${styles.dataTableWrapper} ${className} ${
          tableScrolled ? styles.scrolled : ''
        }`.trim()}
        ref={scrollXRef}
        onScroll={onScroll}
        id={`scrollableContainer_${name ?? Math.random().toString(20).substr(2, 6)}`}
      >
        {children}
      </div>
      <TableShiftButton
        onClickCallback={onLeftScrollButton}
        ariaControls={`scrollableContainer`}
        isVisible={visibleShiftButtons.left}
        shiftLeft
        hideForDesktop
      />
      <TableShiftButton
        onClickCallback={onRightScrollButton}
        ariaControls={`scrollableContainer`}
        isVisible={visibleShiftButtons.right}
        hideForDesktop
      />
    </div>
  );
};
