// Importing Libraries
import React, { useEffect, useState } from 'react';
import { LanguageShort } from '@hobt/constants';
import { isNullOrDefault } from '@hobt/utils';
import { Text } from '@sitecore-jss/sitecore-jss-react';

// Importing Forms and common components
import DynamicHeading from 'Components/Common/DynamicHeading';
import FormCard from 'Components/Common/FormCard';
import { postalCodeFormat, telephoneFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormNumber from 'Components/Inputs/FormNumber';
import FormText, { Formatted as FormattedInput } from 'Components/Inputs/FormText';
import { TooltipIcon } from 'Components/Inputs/TooltipIcon';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { ButtonType } from 'Feature/CommonComponents/UserControls';
import i18n from 'i18next';
import { UseFieldArrayRemove, useFieldArray, useFormContext } from 'react-hook-form';

// Importing styles, props and types
import { GlossaryNames } from '../../../../PageComponents/components/GlossaryComponent/types';
import styles from './styles.module.scss';
import { ClaimsLenderDetailsProps, ClaimPayee } from './types';

const CLAIM_PAYEE_TRANSIT_NUMBER = 'lender.claimPayee.transitNumber';

interface TAuthorizedOfficers {
  positionTypeCode?: string | number | null;
  id?: string | any;
}
interface TAuthorizedOfficersArray {
  fields: TAuthorizedOfficers[];
  append: any;
  remove: UseFieldArrayRemove;
}

const ClaimsLenderDetails: React.FC<ClaimsLenderDetailsProps> = (
  props: ClaimsLenderDetailsProps
) => {
  const { setValue, trigger, register, watch, getValues } = useFormContext();
  const {
    fields: authorizedOfficers,
    append,
    remove
  }: TAuthorizedOfficersArray = useFieldArray({
    name: 'authorizedOfficers'
  });
  const {
    claimData,
    isClaimsDetails,
    isFieldDisabled,
    isInEditMode,
    isMasterUser,
    isMasterUserEditingPostAdjudicationClaim
  } = useHBTFormContext();

  const [transitNumberOptions, setTransitNumberOptions] = useState([
    {
      fields: {
        itemName: {
          value:
            isClaimsDetails === false && claimData != null
              ? claimData?.claimPayees[0]?.transitNumber
              : ''
        },
        itemValue: { value: claimData?.lender?.claimPayee?.transitNumber }
      }
    }
  ]);

  if (getValues('lender.claimPayee.transitNumber') === null) {
    setValue('lender.claimPayee.transitNumber', claimData?.claimPayees[0]?.transitNumber);
  }

  const [claimPayeeNameOptions, setClaimPayeeNameOptions] = useState([
    {
      fields: { itemName: { value: '' }, itemValue: { value: '' } }
    }
  ]);
  const transitNumberValue = watch(CLAIM_PAYEE_TRANSIT_NUMBER);

  // We set the payee ID to the first element
  // The on change handler as well as the useEffect below will handle any changes and set the payee ID accordingly
  register('lender.claimPayee.payeeID');
  const payeeID = watch('lender.claimPayee.payeeID');
  if (claimData?.claimPayees?.[0]?.payeeID != null && isNullOrDefault(payeeID)) {
    setValue('lender.claimPayee.payeeID', claimData?.claimPayees?.[0]?.payeeID);
  }

  register('lender.claimPayee.name');
  const payeeName = watch('lender.claimPayee.name');
  if (
    (i18n.language === LanguageShort.English
      ? claimData?.claimPayees?.[0]?.approvedLenderEnglishName != null
      : claimData?.claimPayees?.[0]?.approvedLenderFrenchName != null) &&
    isNullOrDefault(payeeID)
  ) {
    setValue(
      'lender.claimPayee.name',
      i18n.language === LanguageShort.English
        ? claimData?.claimPayees?.[0]?.approvedLenderEnglishName
        : claimData?.claimPayees?.[0]?.approvedLenderFrenchName
    );
  }

  if (claimData?.lenderName != null) {
    setValue(
      'lender.approvedLenderName',
      i18n.language === LanguageShort.English
        ? claimData.lenderName.approvedLenderEnglishName
        : claimData.lenderName.approvedLenderFrenchName
    );
  }

  useEffect(() => {
    authorizedOfficers.forEach((_item, index) => {
      setValue(
        `lender.authorizedOfficers[${index}].positionTypeCode`,
        (authorizedOfficers[index].positionTypeCode as number) ?? index + 1
      );
    });
  }, [authorizedOfficers]);

  // Master User can edit approved lender reference number field
  useEffect(() => {
    if (
      claimData?.approvedLenderReferenceNumber != null &&
      isMasterUserEditingPostAdjudicationClaim === true
    ) {
      setValue('lender.approvedLenderReferenceNumber', claimData.approvedLenderReferenceNumber);
    }
  }, [isMasterUserEditingPostAdjudicationClaim]);

  useEffect(() => {
    let transitNumbers;
    let payeeNames;

    // Claim Submission Form logic - Draft Claim view
    if (isClaimsDetails === false && claimData != null) {
      if (Array.isArray(claimData?.claimPayees) && claimData?.claimPayees?.length > 0) {
        transitNumbers = claimData.claimPayees?.map((claimPayee: ClaimPayee) => ({
          fields: {
            itemName: { value: claimPayee.transitNumber },
            itemValue: { value: claimPayee.transitNumber }
          }
        }));
        payeeNames = claimData.claimPayees?.map((claimPayee: ClaimPayee) => ({
          fields: {
            itemName: {
              value:
                i18n.language === LanguageShort.English
                  ? claimPayee.approvedLenderEnglishName
                  : claimPayee.approvedLenderFrenchName
            },
            itemValue: {
              value:
                i18n.language === LanguageShort.English
                  ? claimPayee.approvedLenderEnglishName
                  : claimPayee.approvedLenderFrenchName
            }
          }
        }));
      }

      if (claimData?.lender?.claimPayee != null) {
        setValue(CLAIM_PAYEE_TRANSIT_NUMBER, claimData.lender.claimPayee.transitNumber);
        setValue('lender.claimPayee.name', claimData.lender.claimPayee.name);
        setValue('lender.claimPayee.payeeID', claimData.lender.claimPayee.payeeID);
      }

      setTransitNumberOptions(transitNumbers);
      setClaimPayeeNameOptions(payeeNames);
    }

    // If a user is opening up a final claim, then we display the value from the final claim
    else if (isClaimsDetails === true && claimData != null) {
      transitNumbers = [
        {
          fields: {
            itemName: { value: claimData?.lender?.claimPayee?.transitNumber },
            itemValue: { value: claimData?.lender?.claimPayee?.transitNumber }
          }
        }
      ];
      payeeNames = [
        {
          fields: {
            itemName: { value: claimData?.lender?.claimPayee?.name },
            itemValue: { value: claimData?.lender?.claimPayee?.name }
          }
        }
      ];
      setTransitNumberOptions(transitNumbers);
      setClaimPayeeNameOptions(payeeNames);
      setValue('lender.claimPayee.payeeID', claimData.lender?.claimPayee?.payeeID);
    }
  }, [isClaimsDetails, claimData]);

  useEffect(() => {
    if (transitNumberValue != null && claimPayeeNameOptions?.[0]?.fields?.itemName?.value !== '') {
      const claimPayeeIndex = transitNumberOptions?.findIndex(
        (transitNumber) => transitNumberValue === transitNumber.fields.itemName.value
      );

      if (claimPayeeIndex > -1) {
        setValue(
          'lender.claimPayee.name',
          claimPayeeNameOptions?.[claimPayeeIndex]?.fields?.itemName?.value ?? ''
        );
      }
    }
  }, [transitNumberValue]);

  const setAllowedClaimPayeeOptions = (selectedTransitNumber: string) => {
    if (Array.isArray(claimData?.claimPayees) && claimData.claimPayees.length > 0) {
      const allowedPayeeOptions = claimData.claimPayees?.filter?.(
        (claimPayee: ClaimPayee) => claimPayee.transitNumber === selectedTransitNumber
      );

      if (Array.isArray(allowedPayeeOptions) && allowedPayeeOptions.length === 1) {
        setValue('lender.claimPayee.payeeID', allowedPayeeOptions[0].payeeID);

        const allowedPayeeName = [
          {
            fields: {
              itemName: {
                value:
                  i18n.language === LanguageShort.English
                    ? allowedPayeeOptions[0].approvedLenderEnglishName
                    : allowedPayeeOptions[0].approvedLenderFrenchName
              },
              itemValue: {
                value:
                  i18n.language === LanguageShort.English
                    ? allowedPayeeOptions[0].approvedLenderEnglishName
                    : allowedPayeeOptions[0].approvedLenderFrenchName
              }
            }
          }
        ];
        setClaimPayeeNameOptions(allowedPayeeName);
      }
    }
  };

  const handleTransitNumberChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.currentTarget.value != null && event.currentTarget.value !== '') {
      setValue(CLAIM_PAYEE_TRANSIT_NUMBER, event.currentTarget.value);
      setAllowedClaimPayeeOptions(event.currentTarget.value);
    }
  };

  return (
    <FormCard
      title={props.fields.cardTitle}
      headingLevel={2}
      sectionId="lender-details-body"
      fieldToValidate="lender"
      toolTipButton={{
        id: 'lenderDetailsGlossaryTooltip',
        name: 'lenderDetailsGlossaryTooltip',
        ariaText: props.fields.cardGlossaryAriaText?.value ?? '',
        onClick: () => {
          props?.fields?.openGlossary?.(GlossaryNames.LenderDetails);
        },
        buttonType: ButtonType.TEXT
      }}
    >
      <p className="pb-3">
        <Text field={props.fields.lenderNote} />
      </p>
      <FormText
        name="lender.approvedLenderName"
        className={styles.halfLeft}
        label={props.fields.lenderName}
        isReadOnly={true}
      />
      <FormText
        name="lender.approvedLenderCode"
        className={styles.half}
        label={props.fields.lenderCode}
        isReadOnly={!(isMasterUser === true && isInEditMode === true)}
      />
      <FormDropdown
        name="lender.claimPayee.transitNumber"
        className={styles.halfLeft}
        label={props.fields?.claimPayeeTransitId}
        options={transitNumberOptions}
        setAsNumber={false}
        addDefaultSelect={false}
        handleChange={handleTransitNumberChange}
        isDisabled={isFieldDisabled}
      />
      <FormDropdown
        name="lender.claimPayee.typeCode"
        className={`${styles.half} ${
          i18n.language === LanguageShort.French ? `${styles.pullDown} ${styles.adjustMargin}` : ''
        }`.trim()}
        label={props.fields.claimPayeeTypeLabel}
        options={props?.fields?.claimPayeeType?.fields?.listItems}
        isDisabled={isFieldDisabled}
      />
      <FormDropdown
        name="lender.claimPayee.name"
        className={styles.halfLeft}
        label={props.fields?.claimPayeeName}
        options={claimPayeeNameOptions}
        isDisabled={true}
        setAsNumber={false}
        addDefaultSelect={false}
      />
      <FormDropdown
        name="lender.correspondenceLanguageCode"
        className={`${styles.half} ${
          i18n.language === LanguageShort.French ? `${styles.pullDown}` : ''
        }`.trim()}
        label={props.fields.languagePreferClaimLabel}
        options={props?.fields?.languagePreferClaim?.fields?.listItems}
        isDisabled={isFieldDisabled}
      />
      {isMasterUserEditingPostAdjudicationClaim === true && (
        <FormText
          name="lender.approvedLenderReferenceNumber"
          className={styles.halfLeft}
          label={props.fields.lenderReferenceNumber}
          isReadOnly={isFieldDisabled}
        />
      )}
      <hr className={styles.separator} />
      <FormText
        name="lender.address.lineOneText"
        className={styles.full}
        label={props.fields.lenderAddress}
        isReadOnly={isFieldDisabled}
      />
      <FormText
        name="lender.address.lineTwoText"
        className={styles.full}
        label={props.fields.lenderAddress2}
        isReadOnly={isFieldDisabled}
      />
      <FormText
        name="lender.address.municipalityName"
        className={styles.oneThirdLeft}
        label={props.fields.townCity}
        isReadOnly={isFieldDisabled}
      />
      <FormDropdown
        name="lender.address.provinceCode"
        className={styles.oneThirdLeft}
        label={props.fields.provinceLabel}
        options={props?.fields?.province?.fields?.listItems}
        setAsNumber={false}
        isDisabled={isFieldDisabled}
      />
      <FormattedInput
        name="lender.address.postalCode"
        formatProps={postalCodeFormat}
        className={styles.oneThird}
        label={props.fields.postalCode}
        isReadOnly={isFieldDisabled}
      />
      <hr className={styles.separator} />
      <DynamicHeading headingLevel={3} className={styles.subHeading}>
        {<Text field={props.fields.contactInfo} />}
      </DynamicHeading>

      {authorizedOfficers?.map((_item, index) => (
        <React.Fragment key={_item?.id}>
          {index >= 1 && (
            <div className={styles.full}>
              <hr className={styles.separator} />
              <DynamicHeading headingLevel={3} className={styles.subHeading}>
                <Text field={props.fields.secondaryContactInfomation} />
              </DynamicHeading>
            </div>
          )}
          <FormText
            className={styles.halfLeft}
            name={`lender.authorizedOfficers.${index}.firstName`}
            label={props.fields.firstName}
            isReadOnly={isFieldDisabled}
          />
          <FormText
            className={styles.half}
            name={`lender.authorizedOfficers.${index}.lastName`}
            label={props.fields.lastName}
            isReadOnly={isFieldDisabled}
          />
          <FormNumber
            className={styles.halfLeft}
            name={`lender.authorizedOfficers.${index}.phoneNumber`}
            label={props.fields.phoneNumber}
            formatProps={telephoneFormat}
            isReadOnly={isFieldDisabled}
          />
          <FormText
            className={styles.half}
            name={`lender.authorizedOfficers.${index}.extensionPhoneNumber`}
            label={props.fields.extension}
            isReadOnly={isFieldDisabled}
          />
          <FormNumber
            className={styles.halfLeft}
            name={`lender.authorizedOfficers.${index}.faxNumber`}
            label={props.fields.faxNumber}
            formatProps={telephoneFormat}
            isReadOnly={isFieldDisabled}
          />
          <FormattedInput
            className={styles.half}
            name={`lender.authorizedOfficers.${index}.emailID`}
            label={props.fields.emailAddress}
            isReadOnly={isFieldDisabled}
          />
          <FormNumber
            className={styles.half}
            name={`lender.authorizedOfficers.${index}.positionTypeCode`}
            label={props.fields.position}
            watch={{ when: false, is: [true], className: 'd-none' }}
            isReadOnly={isFieldDisabled}
          />
          {index >= 1 && (
            <div className={styles.fullRight}>
              <button
                className="btn btn--icon btn__delete"
                name="delete"
                onClick={() => remove(index)}
              >
                <TooltipIcon
                  icon={props.fields.deleteIcon}
                  text={i18n.t('DefaultSubmission-Card-Delete')}
                  className={'icon-24'}
                />
              </button>
            </div>
          )}
        </React.Fragment>
      ))}

      {authorizedOfficers.length < 2 && (
        <>
          <hr className={styles.separator} />
          <button
            data-testid="addAuthorizedOfficers"
            className="btn btn-add-color"
            onClick={(event) => {
              event.stopPropagation();
              append({});
            }}
            disabled={isFieldDisabled}
          >
            <i className="material-icons icon--size-32 icon--v-align-middle" aria-hidden={true}>
              add_circle_outline
            </i>
            <Text field={props.fields.addAuthorizeOffice} />
          </button>
        </>
      )}
    </FormCard>
  );
};

export default ClaimsLenderDetails;
