import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/system/Stack';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Spinner } from '../Spinner';
import { SpinnerSize, SpinnerVariant } from '../Spinner/types';
import styles from './styles.module.scss';
import type { BaseButtonProps } from './types';
import { ButtonProps, ButtonSize, ButtonVariant } from './types';

/**
 * @param {ButtonProps} props - list out the props.
 * @param {boolean} props.disabled - disables the component.
 * @param {ButtonVariant} props.variant - the type of the button - primary, secondary, tertiary...
 * @param {FieldValue} props.text - the text to be displayed.
 * @param {FieldValue} props.ariaText - the accessibility text to be added to the component.
 * @param {string} props.name - the name of the component.
 * @param {ButtonSize} props.size - the size of the component.
 * @param {function} props.onClick - the click event handler.
 * @param {function} props.onMouseDown - the mouse down event handler.
 * @param {boolean} props.responsize - enables the component to be responsive across various screens.
 * @param {string} props.type - the type of the button element.
 * @param {React.JSX.Element} props.icon - the icon that should be rendered and centered in the component.
 * @param {React.JSX.Element} props.leadingIcon - the leading icon that should be rendered in the component.
 * @param {React.JSX.Element} props.trailingIcon - the trailing icon that should be rendered in the component.
 * @param {boolean} props.loading - enables the component's loading state.
 * @param {string} props.className - the trailing icon that should be rendered in the component.
 * @returns {Button} Rendered Button component.
 */

/**
 * Button component
 *
 * This is the Button component written using storybook. It handles different hover, focused and active states.
 */

export const Button: FC<ButtonProps> = ({
  id,
  variant,
  text,
  ariaText,
  name,
  size,
  responsive,
  type,
  icon,
  leadingIcon,
  trailingIcon,
  loading,
  className,
  ...props
}: ButtonProps) => {
  let classes: string = styles.primaryButton;

  switch (variant) {
    case undefined:
    case ButtonVariant.PRIMARY:
      classes = loading ? styles.primaryLoadingButton : styles.primaryButton;
      break;
    case ButtonVariant.SECONDARY:
      classes = loading ? styles.secondaryLoadingButton : styles.secondaryButton;
      break;
    case ButtonVariant.TERTIARY:
      if (!loading) {
        classes = styles.tertiaryButton;
      }
      break;
    default:
      classes = styles.primaryButton;
  }

  if (variant === 'icon') {
    return (
      <button
        {...props}
        id={id}
        name={name}
        type={type}
        className={`${styles.iconButton} ${responsive ? styles.responsive : ''} 
        ${className || ''}`.trim()}
        aria-label={ariaText?.value}
        data-testid={`${name}Test`}
        role="button"
      >
        {icon && icon()}
      </button>
    );
  }

  const getSizeClass = (size: ButtonSize, variant: string): string => {
    if (size === ButtonSize.SMALL) {
      return variant === ButtonVariant.TERTIARY ? styles.lg : styles.sm;
    }
    return styles.lg;
  };

  return (
    <button
      {...props}
      id={id}
      name={name}
      type={type}
      className={`${classes} ${responsive ? styles.responsive : ''} 
          ${getSizeClass(size as ButtonSize, variant as ButtonVariant)}
          ${className || ''}`.trim()}
      aria-label={ariaText?.value}
      data-testid={`${name}Test`}
    >
      <Stack direction="row" justifyContent="center" alignContent="center">
        {loading && (variant === ButtonVariant.PRIMARY || variant === ButtonVariant.SECONDARY) ? (
          <Box sx={{ width: '100%' }} justifyContent="center">
            <Spinner
              ariaText={`${ariaText} loading`}
              size={SpinnerSize.MEDIUM}
              type={
                variant === ButtonVariant.PRIMARY
                  ? SpinnerVariant.SECONDARY
                  : SpinnerVariant.PRIMARY
              }
            />
          </Box>
        ) : (
          <>
            {leadingIcon && (
              <span aria-hidden={true} className={styles.withLeadingIcon}>
                {leadingIcon()}
              </span>
            )}
            <span style={{ textAlign: leadingIcon ? 'left' : undefined }}>
              <Text field={text} />
            </span>
            {trailingIcon && (
              <span aria-hidden={true} className={styles.withTrailingIcon}>
                {trailingIcon()}
              </span>
            )}
          </>
        )}
      </Stack>
    </button>
  );
};

/* Simplified Buttons */

export const BaseButton: FC<BaseButtonProps> = ({ className, children, ...buttonProps }) => {
  return (
    <button className={`${styles.buttonBase} ${className ?? ''}`.trim()} {...buttonProps}>
      {children}
    </button>
  );
};

const createButton =
  (style: string): FC<BaseButtonProps> =>
  ({ className, ...props }) =>
    BaseButton({ ...props, className: `${style} ${className ?? ''}`.trim() });

export const PrimaryButton = createButton(styles.primaryButton);
export const SecondaryButton = createButton(styles.secondaryButton);
export const ContextualButton = createButton(styles.contextualButton);
export const TertiaryButton = createButton(styles.tertiaryButton);
