import React from 'react';
import styles from './styles.module.scss';
import { DividerProps, DividerSpacingType, VerticalMargins } from './types';

/**
 * @param spacingType - the spacing type of the divider, offering three set spacing and one variant type for custom spacing.
 * @param variableMargins  - the custom spacing (margin top and down) for the divider.
 */

/**
 * Divider component
 *
 * This is the Divider component written using storybook. It generates a divider line with different spacing variants.
 * Developer can customize the spacing of the divider line by passing the spacing prop.
 */

const Divider: React.FC<DividerProps> = ({
  spacingType,
  variableMargins,
  screenReaderText = 'New section'
}) => {
  const defaultMargins = [48, 48];
  const effectiveMargins =
    variableMargins?.length === 1
      ? [variableMargins[0], 48]
      : variableMargins?.length === 0
      ? defaultMargins
      : variableMargins?.slice(0, 2) ?? defaultMargins;

  const verticalMarginsMapping: VerticalMargins = {
    [DividerSpacingType.Small]: defaultMargins,
    [DividerSpacingType.Medium]: [48, 48],
    [DividerSpacingType.Large]: [80, 80],
    [DividerSpacingType.Variable]: effectiveMargins
  };

  const marginTop = `${verticalMarginsMapping[spacingType][0]}px`;
  const marginBottom = `${verticalMarginsMapping[spacingType][1]}px`;

  return (
    <div
      className={styles.divider}
      style={{ marginTop, marginBottom }}
      data-testid="divider"
      role="separator"
      aria-describedby="screenReader"
    >
      <div id="screenReader" className={styles.screenReader}>
        {screenReaderText}
      </div>
    </div>
  );
};

export default Divider;
