import React, { useEffect, useRef } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { NotificationContentProps } from 'Feature/Header/components/Notifications/NotificationContent/types';
import { convertTimestampforNoti } from 'Feature/Header/models/types';
import { useFeature } from 'flagged';
import i18n from 'i18next';
import { CSSTransition } from 'react-transition-group';
import styles from './styles.module.scss';

export const NotificationContent: React.FC<NotificationContentProps> = ({
  isActive,
  onClose,
  content,
  fields,
  showLenderReferenceNumber,
  isApprovalRequiredNotification
}: NotificationContentProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const isInternalSite = useFeature(FeatureFlags.INTERNAL);
  useEffect(() => {
    if (buttonRef.current != null) {
      buttonRef.current.focus();
    }
  }, [buttonRef.current]);
  return (
    <div className={styles.notificationContentOuterWrapper}>
      <CSSTransition
        in={isActive}
        classNames={{
          enterActive: styles.onEnter,
          exitActive: styles.onExit
        }}
        timeout={450}
        unmountOnExit={true}
        mountOnEnter={true}
      >
        <div className={`${styles.notificationContentWrapper}`}>
          <div className={styles.notificationContentHeader}>
            <RichText tag="h2" field={{ value: content?.title }} />
            <button onClick={onClose} aria-label={fields.closeNotification.value} ref={buttonRef}>
              <span aria-hidden="true" className="material-icons-outlined icon--size-24">
                arrow_forward_ios
              </span>
            </button>
          </div>
          <time>{content?.date != null && convertTimestampforNoti(content?.date)}</time>
          <p className={styles.lender}>
            {isInternalSite ? (
              <span>
                <Text
                  field={
                    { value: i18n.t('DefaultsInventoryDetails-Title-CmhcLoanNumberText') } ?? {
                      value: ''
                    }
                  }
                />
                : {content?.cmhcLoanAccountNumber}
              </span>
            ) : (
              showLenderReferenceNumber && (
                <span>
                  <Text field={fields.lenderNumber ?? { value: '' }} />
                  {content?.lender}
                </span>
              )
            )}

            {isApprovalRequiredNotification === true && (
              <span>
                <Text field={fields.currentDate ?? { value: '' }} />
                {new Date().toLocaleDateString()}
              </span>
            )}
          </p>
          <RichText tag="div" field={{ value: content?.content }} />
        </div>
      </CSSTransition>
    </div>
  );
};
