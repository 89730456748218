import i18n from 'i18next';

import { ApiClient } from 'Foundation/Api';
import { AuthenticationContextType } from 'Foundation/Authentication';
import { Lender } from './LenderDetails.types';

import { config } from '../../../../../config';

export const getLenderDetailsProps = () => ({
  id: 'lenderDetails',
  testId: 'lenderDetailsTest',
  title: {
    field: {
      value: i18n.t('DefaultSubmission-Card-LenderDetailsHeading')
    }
  },
  accordionLineColor: 'grey',
  linePosition: 'vertical',
  lenderCodeDescription: {
    field: {
      value: i18n.t('DefaultSubmission-Card-LenderCodeDescription')
    }
  },
  inputFields: {
    approvedLenderName: {
      field: {
        value: i18n.t('DefaultSubmission-Card-ApprovedLenderName')
      }
    },
    lenderCode: {
      field: {
        value: i18n.t('DefaultSubmission-Card-LenderCode')
      }
    },
    lenderReferenceNumber: {
      field: {
        value: i18n.t('DefaultSubmission-Card-LenderReferenceNumber')
      }
    },
    transitNumber: {
      field: {
        value: i18n.t('DefaultSubmission-Card-TransitNumber')
      }
    },
    institutionNumber: {
      field: {
        value: i18n.t('DefaultSubmission-Card-InstitutionNumber')
      }
    },
    lenderContactName: {
      field: {
        value: i18n.t('DefaultSubmission-Card-LenderContactName')
      }
    },
    lenderContactPhoneNumber: {
      field: {
        value: i18n.t('DefaultSubmission-Card-LenderContactPhoneNumber')
      }
    },
    lenderExtension: {
      field: {
        value: i18n.t('DefaultSubmission-CardA-Extensions')
      }
    },
    lenderContactEmail: {
      field: {
        value: i18n.t('DefaultSubmission-Card-LenderContactEmail')
      }
    },
    languageOfCorrespondence: {
      field: {
        value: i18n.t('DefaultSubmission-Card-LanguageOfCorrespondence')
      }
    }
  }
});

export const getLenderDetails = async (
  authContext: AuthenticationContextType,
  financialInstitutionCode: string,
  transitNumber: string
): Promise<Lender | undefined | null> => {
  try {
    let requestUrl = config.lenderApi.urls.lenderDetails;
    requestUrl = requestUrl.replace('{financialInstitutionCode}', financialInstitutionCode);
    requestUrl = requestUrl.replace('{transitNumber}', transitNumber);

    const apiClientConfig = {
      timeout: config.lenderApi.requestTimeout
    };

    const response = await ApiClient(authContext, apiClientConfig).getWithAuth(requestUrl);
    const responseData = response.data?.data?.[0];

    const lender: Lender = {
      approvedLenderCode: responseData?.approvedLenderCode,
      approvedLenderEnglishName: responseData?.approvedLenderEnglishName,
      approvedLenderFrenchName: responseData?.approvedLenderFrenchName,
      financialInstitutionCode: responseData?.financialInstitutionCode,
      transitNumber: responseData?.transitNumber
    };

    return lender;
  } catch (err) {
    return null;
  }
};
