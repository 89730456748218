import React, { memo, useCallback, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Tag } from './Tag';
import { TagGroupProps } from './types';

export const TagGroup: React.FC<TagGroupProps> = memo(({ tags: initialTags }) => {
  const [tags, setTags] = useState(initialTags);

  const handleDelete = useCallback(
    (index: number) => () => {
      const tagToDelete = tags[index];
      if (tagToDelete && tagToDelete.onDelete) {
        tagToDelete.onDelete(tagToDelete.id);
      }
      setTags((prevTags) => {
        const updatedTags = prevTags.filter((_, i) => i !== index);
        return updatedTags;
      });
    },
    [setTags]
  );

  return (
    <div className={styles.tagGroupContainer} data-testid="tagGroup-test">
      {tags.map((tag, index) => (
        <Tag
          key={index}
          label={tag.label}
          variant={tag.variant}
          onDelete={handleDelete(index)}
          leadingIcon={tag.leadingIcon}
          statusIndicator={tag.statusIndicator}
        />
      ))}
    </div>
  );
});
