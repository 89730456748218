import {
  NotificationStatus,
  NotificationActionType,
  NotificationAction,
  NotificationsState,
  UpdateNotificationContentPayload,
  Notification
} from 'Feature/Header/components/Notifications/types';

export const notificationsReducer = (
  state: NotificationsState,
  { action, payload }: NotificationAction
) => {
  const newState = { ...state };

  const reducers = {
    [NotificationActionType.UPDATE_NOTIFICATION_CONTENT]: () => {
      const receivedPayload = payload as UpdateNotificationContentPayload;
      newState.showNotificationContents = true;
      newState.selectedNotification = receivedPayload.selectedNotification;
      newState.notificationContent = receivedPayload.notificationContent;
      const selectedNotificationIdx = state.notifications?.findIndex(
        (n) => n.notificationID === receivedPayload.selectedNotification?.notificationID
      );

      if (selectedNotificationIdx !== -1) {
        newState.notifications[selectedNotificationIdx].statusCode = NotificationStatus.READ;
      }

      newState.unreadNotifications = newState.notifications.filter(
        (n) => n.statusCode === NotificationStatus.UNREAD
      ).length;
    },
    [NotificationActionType.SHOW_NOTIFICATION_SIDEBAR]: () => {
      newState.showNotificationSidebar = true;
    },
    [NotificationActionType.HIDE_NOTIFICATION_CONTENT]: () => {
      newState.showNotificationContents = false;
      newState.selectedNotification = undefined;
    },
    [NotificationActionType.HIDE_NOTIFICATION_SIDEBAR]: () => {
      newState.showNotificationSidebar = false;
      newState.statusFilter = NotificationStatus.UNREAD;
      newState.selectedNotification = undefined;
      newState.showNotificationContents = false;
      newState.notificationContent = undefined;
    },
    [NotificationActionType.UPDATE_FILTER]: () => {
      newState.statusFilter =
        payload != null ? (payload as NotificationStatus) : NotificationStatus.ALL;
      newState.selectedNotification = undefined;
      newState.showNotificationContents = false;
      newState.notificationContent = undefined;
    },
    [NotificationActionType.UPDATE_NOTIFICATIONS]: () => {
      const notifications = payload as Notification[];
      newState.notifications = notifications;
      newState.unreadNotifications = notifications.filter(
        (n) => n.statusCode === NotificationStatus.UNREAD
      ).length;
    },
    [NotificationActionType.SET_BUTTON_LOADING]: () => {
      newState.buttonLoading = payload as boolean;
    },
    [NotificationActionType.SET_DATA_LOADING]: () => {
      newState.dataLoading = payload as boolean;
    }
  };

  reducers[action]();

  return newState;
};
