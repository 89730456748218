import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { FormInputCheckboxProps } from './FormInputCheckbox.types';
import GetErrorMessage from '../ErrorMessage';
import _ from 'lodash-es';

const FormInputCheckbox: React.FC<FormInputCheckboxProps> = (props) => {
  const errors = _.get(props.errors, `${props.name}`);
  return (
    <div className="form-displaybox form__element--2-column form__element--checkbox checkbox">
      <input
        id={`${props.id}CheckboxInput`}
        aria-labelledby={`${props.id}Label`}
        type="checkbox"
        className={_.get(props.errors, `${props.name}`) && 'form-error__field'}
        onChange={props.onChange}
        {...(props.register &&
          props.register(props.name ?? '', {
            onChange: props.onChange,
            required: props.isRequired
          }))}
        disabled={props.isDisabled}
        aria-describedby={`${props.id}ErrorDesc`}
      />
      {props.description && <small>{props.description.value}</small>}
      <label
        htmlFor={`${props.id}CheckboxInput`}
        id={`${props.id}Label`}
        className={_.get(props.errors, `${props.name}`) && 'form-error__label'}
      >
        <Text field={props.title} />
      </label>
      {!props.hideError && (
        <div className="form__fieldNotificationHolder">
          {errors && (
            <span
              className="form-error__sub-text"
              aria-live="assertive"
              id={`${props.id}ErrorDesc`}
            >
              {GetErrorMessage(errors.type as string, errors.message as string)}
              <input
                type="hidden"
                className="thisFieldHasErrors"
                id={props.id}
                value={props.cardName && props.cardName}
              />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default FormInputCheckbox;
