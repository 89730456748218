import i18n from 'i18next';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SearchFieldOptions } from 'Components/PageComponents/SearchBar/types';
import { pathNames } from 'Constants/pathNames';

import { SubmissionListInventoryRecords, SubmissionListPropsFields } from './types';

const useSubmissionListFunctions = (fields: SubmissionListPropsFields) => {
  const history = useHistory();
  const [isToastActive, setToastActive] = useState<boolean>(false);

  const toastHandler = () => setToastActive(!isToastActive);

  // TODO: Handle API Error. Keeping console.log for future toast integrations.
  const onApiErrorCallback = () => toastHandler();

  const onRowClick = (row: SubmissionListInventoryRecords) => {
    history.push(
      `/${i18n.language}${pathNames.arrearsSubmissionListDetails}?id=${row.submissionID}`
    );
  };

  const searchByFields: SearchFieldOptions[] = [
    {
      displayText: fields.lenderName?.value ?? '',
      value: 'ApprovedLenderName',
      type: 'text'
    }
  ];

  return {
    searchByFields,
    isToastActive,
    onApiErrorCallback,
    onRowClick,
    toastHandler
  };
};

export default useSubmissionListFunctions;
