import React from 'react';
import { Paper } from '@mui/material';

export default function CustomPaper(props: any) {
  return (
    <Paper
      sx={{
        '& .MuiAutocomplete-option': {
          color: 'var(--Slate-30B, ##4A545D) !important',
          fontFamily: 'var(--Font-Family-Text, Roboto) !important',
          fontSize: 'var(--Font-Small, 14px) !important',
          fontWeight: 'var(--Weight-Regular, 400) !important',
          lineHeight: 'var(--Line-Height-Small, 20px) !important',
          fontStyle: 'normal !important',
          opacity: '1'
        },
        '& .MuiAutocomplete-groupLabel': {
          color: 'var(--text-links-text-header, #202428) !important',
          fontFamily: 'var(--Font-Family-Text, Roboto) !important',
          fontSize: 'var(--Font-Small, 14px) !important',
          fontWeight: 'var(--Weight-Bold, 700) !important',
          lineHeight: 'var(--Line-Height-Small, 20px) !important',
          fontStyle: 'normal !important'
        }
      }}
      {...props}
    />
  );
}
