import React from 'react';
import { SvgIconProps } from '@mui/material';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import styles from '../styles.module.scss';

export const getDropdownChevronIconComponent: (
  param: boolean
) => (props: SvgIconProps) => React.JSX.Element = (isOpen: boolean) => {
  return ({ className }: SvgIconProps) => {
    return (
      <HbtIcon
        size={HbtIconSize.SMALL}
        type="icon_chevron_down"
        className={`${className} ${isOpen ? styles.chevronIconOpen : styles.chevronIcon}`}
      />
    );
  };
};
