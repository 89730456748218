import { ToastNotificationProps } from 'Feature/CommonComponents/ContentComponents';

export default interface HbtModifyExternalPIUserProps {
  fields: HbtModifyExternalPIUserFields;
  rendering?: any;
}

interface HbtModifyExternalPIUserFields {
  pageTitle: FieldValue;
  userAccessBtnText: FieldValue;
  reSyncUserBtnText: FieldValue;
  reInviteUserBtnText: FieldValue;
  activeUserBtnText: FieldValue;
  submitBtnText: FieldValue;
  cancelBtnText: FieldValue;
  deleteAccount: DeleteAccountProps;
}

export interface DeleteAccountProps {
  fields: DeleteAccountPropsFields;
}

export interface DeleteAccountPropsFields {
  heading: FieldValue;
  content: FieldValue;
  acceptButton: FieldValue;
  cancelButton: FieldValue;
}

export type HbtModifyExternalPIUserContextType = {
  userData: any;
};

export type HbtModifyExternalPIUserReducerState = {
  modalActive: boolean;
  toastProps: ToastNotificationProps;
  isLoading: boolean;
  isModalLoading: boolean;
  isPageLoading: boolean;
  isBypass: boolean; // Prompt bypass used when submission successful
  updateModalActive: boolean;
};

export type HbtModifyExternalPIUserActionValuePayload = {
  value: boolean;
};

export type HbtModifyExternalPIUserActionFunctionPayload = {
  function: () => void;
};

export enum HbtModifyExternalPIUserActionType {
  DISMISS_MODAL,
  DISMISS_TOAST,
  ON_ERROR,
  SET_IS_MODAL_LOADING,
  SET_IS_PAGE_LOADING,
  SET_IS_LOADING,
  SHOW_MODAL,
  SUBMIT_SUCCESS,
  SHOW_UPDATE_MODAL,
  DISMISS_UPDATE_MODAL
}

export type HbtModifyExternalPIUserAction = {
  action: HbtModifyExternalPIUserActionType;
  payload?:
    | HbtModifyExternalPIUserActionValuePayload
    | HbtModifyExternalPIUserActionFunctionPayload;
};
