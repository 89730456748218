import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Tabs } from '@mui/material';
import Divider from '../Divider';
import { DividerSpacingType } from '../Divider/types';
import { Dropdown } from '../Dropdown';
import styles from './styles.module.scss';
import { TabProps, TabsType } from './types';

export const Tab: React.FC<TabProps> = memo(
  ({
    tabs,
    tabsType,
    initalActiveTabIndex,
    preventTabChange = false,
    isEqualTabWidthRequired = false,
    onTabChange
  }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [isTablet, setIsTablet] = useState(false);
    const tabRefs = useRef<HTMLDivElement[]>([]);
    const [largestWidth, setLargestWidth] = useState(0);

    useEffect(() => {
      if (isEqualTabWidthRequired) {
        const widths = tabRefs.current.map((ref) => ref?.getBoundingClientRect().width || 0);
        const maxWidth = Math.ceil(Math.max(...widths));
        setLargestWidth(maxWidth);
      }
    }, [tabs, isEqualTabWidthRequired]);

    useEffect(() => {
      const handleResize = () => {
        if (typeof window !== 'undefined') {
          setIsTablet(window.innerWidth < 768);
        }
      };
      if (typeof window !== 'undefined') {
        window.addEventListener('resize', handleResize);
      }

      return () => {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', handleResize);
        }
      };
    }, []);

    useEffect(() => {
      setActiveTab(initalActiveTabIndex || 0);
    });

    const handleTabChange = (newValue: number) => {
      if (preventTabChange) {
        setActiveTab(activeTab);
      } else {
        setActiveTab(newValue);
      }
      if (onTabChange) onTabChange(newValue);
    };

    const onKeyDownHandleTabChange = useCallback(
      (e: React.KeyboardEvent<HTMLDivElement>, newValue: number) => {
        const ENTER_KEY = 'Enter';
        const SPACE_KEY = ' ';

        if (e.key === ENTER_KEY || e.key === SPACE_KEY) {
          e.preventDefault();
          handleTabChange(newValue);
        }
      },
      [handleTabChange]
    );

    const renderTabsOrDropdown = () => {
      if (isTablet) {
        return (
          <Dropdown
            placeholderText={tabs[0].label.value}
            className={styles.dropdownStyle}
            options={tabs.map((tab, index) => ({
              value: index.toString(),
              label: tab.label.value as string
            }))}
          />
        );
      }
      return (
        <Tabs
          TabIndicatorProps={{
            style: { transition: 'none', backgroundColor: 'transparent' }
          }}
          value={activeTab}
          onChange={(event: any, newValue: number) => handleTabChange(newValue)}
          variant="scrollable"
          sx={{
            '&.MuiTabs-root': {
              width: tabsType === TabsType.FULL_WIDTH ? '100%' : '768px'
            },
            '.Mui-disabled': {
              display: 'none'
            },
            '.MuiTabs-flexContainer': {
              display: tabsType === TabsType.FULL_WIDTH ? 'flex' : '-webkit-inline-box'
            },
            '.MuiTabs-scrollButtons': {
              width: '52px',
              color: 'rgba(40, 40, 40, 1)',
              backgroundColor: 'white',
              '& .MuiTouchRipple-root': {
                display: 'none'
              }
            },
            '.MuiSvgIcon-root': {
              fontSize: '24px'
            },
            '.MuiTabs-scrollButtons:first-of-type': {
              borderRight: '1px solid #E1E4E7',
              zIndex: '1',
              boxShadow: '2px 0 4px 0 rgba(0, 0, 0, 0.15)',
              '&:hover': {
                color: 'rgba(72, 63, 205, 1)'
              }
            },
            '.MuiTabs-scrollButtons:last-of-type': {
              borderLeft: '1px solid #E1E4E7',
              boxShadow: '-2px 0 4px 0 rgba(0, 0, 0, 0.15)',
              '&:hover': {
                color: 'rgba(72, 63, 205, 1)'
              }
            }
          }}
        >
          {tabs.map((tab, index) => (
            <div
              tabIndex={0}
              ref={(el) => {
                if (el) tabRefs.current[index] = el;
              }}
              style={{ minWidth: isEqualTabWidthRequired ? largestWidth : 'auto' }}
              key={index}
              className={getTabStyle(index)}
              onClick={() => handleTabChange(index)}
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                onKeyDownHandleTabChange(e, index)
              }
            >
              <div className={styles.tabLabel} aria-label={tab.label.value}>
                {tab.label.value}
              </div>
            </div>
          ))}
        </Tabs>
      );
    };

    const getTabStyle = (tabIndex: number) => {
      switch (tabsType) {
        case TabsType.LEFT_ALIGNED:
          return tabIndex === activeTab ? styles.leftAlignedTabActive : styles.leftAlignedTab;
        case TabsType.FULL_WIDTH:
          return tabIndex === activeTab ? styles.fullWidthTabActive : styles.fullWidthTab;
        default:
          return TabsType.LEFT_ALIGNED;
      }
    };

    return (
      <div className={styles.tabContainer}>
        <div data-testid="tab-test" className={styles.tabButtons}>
          {renderTabsOrDropdown()}
        </div>
        {!isTablet && (
          <Divider
            spacingType={DividerSpacingType.Variable}
            variableMargins={[0, 10]}
            screenReaderText={''}
          />
        )}
        <div className="tab-content">{tabs[activeTab].content}</div>
      </div>
    );
  }
);
