import * as React from 'react';
import { TableShiftButtonProps } from 'Components/Common/DataTable/TableShiftButton/types';
import styles from './styles.module.scss';

export const TableShiftButton: React.FC<TableShiftButtonProps> = ({
  shiftLeft,
  onClickCallback,
  isVisible,
  ariaControls,
  hideForDesktop
}: TableShiftButtonProps) => (
  <div
    className={`${
      shiftLeft !== undefined ? styles.shiftLeftButtonWrapper : styles.shiftRightButtonWrapper
    } ${isVisible ? styles.show : styles.hide} ${
      hideForDesktop ? styles.hideForDesktop : ''
    }`.trim()}
  >
    <button
      onClick={onClickCallback}
      aria-hidden={!isVisible}
      // TODO: Replace with Dictionary Key
      aria-label={`${shiftLeft ? 'chevron_left' : 'chevron_right'}`}
      type="button"
      disabled={!isVisible}
      aria-controls={ariaControls}
      data-testid={`${ariaControls}${shiftLeft !== undefined ? 'Left' : 'Right'}TableShiftButton`}
    >
      <span className={`material-icons ${styles.icon}`}>
        {shiftLeft !== undefined ? 'chevron_left' : 'chevron_right'}
      </span>
    </button>
  </div>
);
