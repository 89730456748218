// Importing Libraries
/* eslint-disable */
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import i18n from 'i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { ClaimTypeCode } from '@hobt/claim-domain';
import { LanguageShort } from '@hobt/constants';

// Importing Common components and form components
import { getCurrencyFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormDatepicker from 'Components/Inputs/FormDatepicker';
import FormNumber from 'Components/Inputs/FormNumber';
import DynamicHeading from 'Components/Common/DynamicHeading';
import { TooltipIcon } from 'Components/Inputs/TooltipIcon';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

// Importing props, types and child components
import ClaimsListingPeriodProps from './types';
import styles from './styles.module.scss';

const TYPES_OF_CLAIMS_FOR_REVISED_PRICE_LIST = [
  ClaimTypeCode.AssignmentOfMortgage,
  ClaimTypeCode.DeficiencySale,
  ClaimTypeCode.TitleTransfer,
];

const TYPES_OF_CLAIMS_EXEMPT_FROM_REQUIRED_FIELDS = [
  ClaimTypeCode.EnhancedClaimService,
  ClaimTypeCode.WalkAway,
];

const ListingPeriodDetails = (props: ClaimsListingPeriodProps) => {
  const { exceptionToListing } = props;
  const { unregister, getValues } = useFormContext();
  const { isMasterUserEditingPostAdjudicationClaim, isFieldDisabled } =
    useHBTFormContext();

  const typeOfClaim = useWatch({ name: 'detail.claimTypeCode' });
  useEffect(() => {
    if (props.index > 0 && exceptionToListing === 'true') {
      unregister(`listings[${props.index}].periodID`);
      props.remove(props.index);
    }
  }, [exceptionToListing]);

  return (
    <>
      {props.showSecondaryListing && (
        <div className={styles.full}>
          <hr className={styles.separator} />
          <DynamicHeading headingLevel={3} className={styles.subHeading}>
            <Text field={props.fields.secondaryListingText} />
          </DynamicHeading>
        </div>
      )}

      {((exceptionToListing === 'false' &&
        !TYPES_OF_CLAIMS_EXEMPT_FROM_REQUIRED_FIELDS.includes(
          typeOfClaim as ClaimTypeCode
        )) ||
        isMasterUserEditingPostAdjudicationClaim === true) && (
        <>
          <FormDatepicker
            className={`${styles.halfLeft}  ${
              i18n.language === LanguageShort.French ? styles.halfEnd : ''
            }`.trim()}
            name={`listings[${props.index}].date`}
            label={props.fields.approvedLendersDate}
            isReadOnly={isFieldDisabled}
          />

          <FormDatepicker
            className={styles.half}
            name={`listings[${props.index}].expiryDate`}
            label={props.fields.approvedLendersExpiryDate}
            isReadOnly={isFieldDisabled}
          />
          <FormNumber
            className={styles.halfLeft}
            name={`listings[${props.index}].priceAmount`}
            label={props.fields.approvedLendersPrice}
            formatProps={getCurrencyFormat(
              getValues(`listings[${props.index}].priceAmount`),
              props.currencyFormat,
              i18n.language as LanguageShort
            )}
            allowNegative={false}
            isReadOnly={isFieldDisabled}
          />
          {TYPES_OF_CLAIMS_FOR_REVISED_PRICE_LIST.includes(
            typeOfClaim as ClaimTypeCode
          ) && (
            <FormNumber
              className={styles.half}
              name={`listings[${props.index}].revisedPriceAmount`}
              label={props.fields.revisedPrice}
              formatProps={getCurrencyFormat(
                getValues(`listings[${props.index}].revisedPriceAmount`),
                props.currencyFormat,
                i18n.language as LanguageShort
              )}
              allowNegative={false}
              isReadOnly={isFieldDisabled}
            />
          )}
        </>
      )}
      <FormDatepicker
        className={styles.halfLeft}
        name={`listings[${props.index}].appraisalDate`}
        label={props.fields.appraisalDate}
        isReadOnly={isFieldDisabled}
      />
      <FormNumber
        className={styles.half}
        name={`listings[${props.index}].appraisalValue`}
        label={props.fields.appraisalValue}
        formatProps={getCurrencyFormat(
          getValues(`listings[${props.index}].appraisalValue`),
          props.currencyFormat,
          i18n.language as LanguageShort
        )}
        allowNegative={false}
        isReadOnly={isFieldDisabled}
      />
      {((exceptionToListing === 'false' &&
        !TYPES_OF_CLAIMS_EXEMPT_FROM_REQUIRED_FIELDS.includes(
          typeOfClaim as ClaimTypeCode
        )) ||
        isMasterUserEditingPostAdjudicationClaim === true) && (
        <>
          <FormDatepicker
            className={styles.halfLeft}
            name={`listings[${props.index}].comparativeMarketAnalysisDate`}
            label={props.fields.comparativeMarketDate}
            isReadOnly={isFieldDisabled}
          />
          <FormNumber
            className={styles.half}
            name={`listings[${props.index}].comparativeMarketAnalysisValue`}
            label={props.fields.comparativeMarketValue}
            formatProps={getCurrencyFormat(
              getValues(
                `listings[${props.index}].comparativeMarketAnalysisValue`
              ),
              props.currencyFormat,
              i18n.language as LanguageShort
            )}
            allowNegative={false}
            isReadOnly={isFieldDisabled}
          />
          <FormNumber
            className={styles.halfLeft}
            name={`listings[${props.index}].minimumAcceptableSalePriceAmount`}
            label={props.fields.minimunAcceptableSale}
            formatProps={getCurrencyFormat(
              getValues(
                `listings[${props.index}].minimumAcceptableSalePriceAmount`
              ),
              props.currencyFormat,
              i18n.language as LanguageShort
            )}
            allowNegative={false}
            isReadOnly={isFieldDisabled}
          />

          <FormNumber
            className={styles.halfLeft}
            name={`listings[${props.index}].periodID`}
            label={props.fields.approvedLenderListingPeriod}
            formatProps={getCurrencyFormat(
              getValues(`listings[${props.index}].periodID`),
              props.currencyFormat,
              i18n.language as LanguageShort
            )}
            watch={{ when: false, is: [true], className: 'd-none' }}
            allowNegative={false}
            isReadOnly={isFieldDisabled}
          />
        </>
      )}
      {props.showSecondaryListing && (
        <div className={styles.fullRight}>
          <button
            className='btn btn--icon btn__delete'
            type='button'
            onClick={() => {
              unregister(`listings[${props.index}].periodID`);
              props.remove(props.index);
            }}
            name='delete'
            disabled={isFieldDisabled}
          >
            <TooltipIcon
              icon={props.fields.deleteIcon}
              text={i18n.t('DefaultSubmission-Card-Delete')}
              className={'icon-24'}
            />
          </button>
        </div>
      )}
    </>
  );
};

export default ListingPeriodDetails;
