import React from 'react';
import { FormControl } from '@mui/material';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { isNullOrUndefined } from 'Components/Common/Api/utils/EmptyValues';
import { Tooltip } from 'Components/Core/Tooltip';
import { TooltipPosition } from 'Components/Core/Tooltip/types';
import { HbtIconSize } from '../../HbtIcon/types';
import styles from '../styles.module.scss';
import { DropdownBaseProps } from '../types';

export const wrappedDropdown = <T extends DropdownBaseProps>(DropdownComponent: React.FC<T>) => {
  const wrappedDropdownComponent = (props: T) => {
    return (
      <FormControl
        data-testid="dropdown-wrapper"
        variant="standard"
        className={`${styles.container} ${props.className}`}
      >
        {getHeaderLabelElement(props.label?.text, props?.label?.infoHelperText?.value)}
        <DropdownComponent {...(props as T)} />
      </FormControl>
    );
  };
  return wrappedDropdownComponent;
};

const getHeaderLabelElement = (
  fieldValue: FieldValue | undefined,
  text: string | undefined
): JSX.Element | null => {
  return !isNullOrUndefined(fieldValue) ? (
    <div className={styles.labelContainer}>
      <label className={styles.labelText}>
        <Text field={fieldValue} />
      </label>
      {getInfoTextElement(text)}
    </div>
  ) : null;
};

const getInfoTextElement = (text: string | undefined): JSX.Element | null => {
  return !isNullOrUndefined(text) ? (
    <span className={styles.labelIcon}>
      <Tooltip
        label={text as string}
        position={TooltipPosition.RIGHT}
        iconSize={HbtIconSize.SMALL}
      />
    </span>
  ) : null;
};
