import i18n from 'i18next';

export const getGdsTdsCalculationsProps = () => {
  const gdsTdsCalculationsData = {
    testId: 'gdsTdsCalculationsTest',
    accordionLineColor: 'grey',
    linePosition: 'vertical',
    title: {
      field: {
        value: i18n.t('DefaultSubmission-Card-GDS-TDS-Heading')
      }
    },
    gdsSubHeading1: {
      field: {
        value: i18n.t('DefaultSubmission-CardF-MonthlyHouseholdHousingExpenses')
      }
    },
    gdsSubHeading2: {
      field: {
        value: i18n.t('DefaultSubmission-CardF-MonthlyHouseholdUnsecuredDebt')
      }
    },
    inputFields: {
      firstMortgagePayment: {
        field: {
          value: i18n.t('DefaultSubmission-CardF-FirstMortgagePayment')
        }
      },
      secondMortgagePayment: {
        field: {
          value: i18n.t('DefaultSubmission-CardF-SecondMortgagePayment')
        }
      },
      propertyTaxes: {
        field: {
          value: i18n.t('DefaultSubmission-CardF-PropertyTaxes')
        }
      },
      heatingCosts: {
        field: {
          value: i18n.t('DefaultSubmission-CardF-HeatingCosts')
        }
      },
      percentageOfCondoFee: {
        field: {
          value: i18n.t('DefaultSubmission-CardF-PercentageOfCondoFee')
        }
      },
      totalMonthlyHousingExpenses: {
        field: {
          value: i18n.t('DefaultSubmission-CardF-TotalMonthlyHousingExpenses')
        }
      },
      gDSRatio: {
        field: {
          value: i18n.t('DefaultSubmission-CardF-GDSRatio')
        }
      },
      personalLoans: {
        field: {
          value: i18n.t('DefaultSubmission-CardF-PersonalLoans')
        }
      },
      linesOfCredit: {
        field: {
          value: i18n.t('DefaultSubmission-CardF-LinesOfCredit')
        }
      },
      creditCards: {
        field: {
          value: i18n.t('DefaultSubmission-CardF-CreditCards')
        }
      },
      gDSOther: {
        field: {
          value: i18n.t('DefaultSubmission-Card-OtherOptional')
        }
      },
      TotalMonthlyExpenses: {
        field: {
          value: i18n.t('DefaultSubmission-CardF-TotalMonthlyExpenses')
        }
      },
      tDSRatio: {
        field: {
          value: i18n.t('DefaultSubmission-CardF-TdsRatio')
        }
      }
    }
  };

  return gdsTdsCalculationsData;
};
