export type TrendValueCellProp = {
  trend?: Trend;
  arrowDirection?: Trend;
  name: string;
};

export enum Trend {
  NEUTRAL = 0,
  POSITIVE = 1,
  NEGATIVE = -1
}
