import * as React from 'react';
import FormTextArea from 'Components/Inputs/FormTextArea';
import { LinedCard, AccordionContainer } from 'Feature/CommonComponents/AccordionComponents';
import ArrearsAdditionalRemarksProps from 'Feature/Arrears/models/ArrearsAdditionalRemarksProps';
import { UserRole, ModuleMapping } from '@hobt/constants';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const cardId = 'ArrearsFormRemarks';

const ArrearsAdditionalRemarks: React.FC<ArrearsAdditionalRemarksProps> = ({
  fields,
  isModify
}: ArrearsAdditionalRemarksProps) => {
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.arrears,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  return (
    <LinedCard id={cardId} testId={cardId} role="form" linePosition="vertical" lineColor="grey">
      <AccordionContainer accordionId={`${cardId}Accordion`} title={fields.cardTitle}>
        <div className="row card__body-row">
          <div className="form__element col-12">
            <FormTextArea
              className=""
              name="submission.remarkText"
              label={fields.additionalRemarks}
              charLimit={Number(fields.limitNumber.value)}
              textAreaHelperText={fields.characterLimit.value}
              isReadOnly={isReadOnlyUser || isModify === true}
            />
          </div>
        </div>
      </AccordionContainer>
    </LinedCard>
  );
};

export default ArrearsAdditionalRemarks;
