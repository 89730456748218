import React from 'react';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';

export enum TooltipPosition {
  TOP_CENTER = 'top-center',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_CENTER = 'bottom-center',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  LEFT = 'left',
  RIGHT = 'right'
}

export interface TooltipProps {
  children?: React.ReactNode;
  customStyles?: React.CSSProperties;
  iconSize?: HbtIconSize;
  label: string;
  position: TooltipPosition;
}
