import React from 'react';
import { useLocation } from 'react-router-dom';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';

import { pathNames } from 'Constants/pathNames';
import HeaderComponentProps from 'Feature/Header/models/HeaderComponentProps';
import { useAuthenticationContext } from 'Foundation/Authentication';

import LanguageSwitch from '../LanguageSwitcher';
import Notifications from '../Notifications';
import SideMenu from '../SideMenu';
import RoutableSitecoreLink from '../../../RoutableSitecoreLink';

import styles from './styles.module.scss';

const HeaderComponent = (props: HeaderComponentProps) => {
  const location = useLocation();
  const authenticationContext = useAuthenticationContext();
  const isApplicationOr500Error = !isServer()
    ? window.location.pathname.endsWith(pathNames.errorApplication) ||
      window.location.pathname.endsWith(pathNames.error500)
    : false;

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarContentContainer}>
        <header className={styles.brandingContainer} data-testid="brandingContainer">
          <a
            className={styles.hideNavigation}
            href={
              location.pathname.includes(props?.fields?.headerHomeLink?.value.href)
                ? '#card-0AccordionAccordionHeader'
                : '#homeBreadcrumb'
            }
          >
            <u>{props?.fields?.skipContentLabel?.value ?? ''}</u>
          </a>
          <RoutableSitecoreLink link={props.fields.headerHomeLink}>
            <div className={styles.brandingLogo} data-testid="brandingLogoContainer">
              <Image field={props.fields.headerLogo} />
            </div>
            <div className={styles.brandingSiteTitle} data-testid="brandingTitle">
              <Text tag="small" field={props.fields.headerHeading} />
              <Text tag="h1" field={props.fields.headerSubTitle} />
            </div>
          </RoutableSitecoreLink>
        </header>

        <ul className={styles.topNavContainer} id="navbarText">
          <li>
            <LanguageSwitch />
          </li>
          {authenticationContext.isAuthenticated &&
            (authenticationContext.displayTermsOfUseFlag === false ||
              authenticationContext.isTermsAndConditionsAccepted() ||
              isApplicationOr500Error === true) && (
              <>
                {isApplicationOr500Error === false && (
                  <li>
                    <Notifications
                      buttonText={props.fields.notificationsText ?? { value: '' }}
                      fields={props.fields.notificationsFlyout?.fields}
                    />
                  </li>
                )}
                <li>
                  <SideMenu
                    text={props.fields.menuText}
                    labelText={props.fields.sideMenuItems?.[0]?.fields?.labelText ?? ''}
                    sideMenuItems={
                      isApplicationOr500Error === false
                        ? props.fields.sideMenuItems?.[0]?.fields?.subMenu ?? ''
                        : []
                    }
                    logoutLabel={props.fields.sideMenuItems?.[0]?.fields?.logoutLabel ?? ''}
                  />
                </li>
              </>
            )}
        </ul>
      </div>
    </nav>
  );
};

export default HeaderComponent;
