export const pathNames = {
  // General
  dashboard: '/dashboard',
  error500: '/500',
  error404: '/404',
  errorApplication: '/application-error',
  landing: '/',
  termsAndConditions: '/terms',

  // Claims
  claim: '/claims',
  claimsAdjudication: '/claims-inventory/details',
  claimsInventory: '/claims-inventory',

  // User Management
  userInventory: '/users',
  createUser: '/users/add',
  createUserExternal: '/users/addExternal',
  updateUser: '/users/update',
  getOneUser: '/users/details',
  getOneExternalUser: '/users/detailsExternal',

  // Delegate Authority
  delegateInventory: '/delegate',
  delegateAuth: '/delegate/create',

  // Arrears
  arrearsHistoricalRecords: '/arrears-historical-records',
  arrearsSummaryReports: '/arrears-summary-report',
  arrearsSubmissionList: '/arrears-submission-list',
  arrearsSubmissionListDetails: '/arrears-submission-list/details',
  arrearsSubmittedReports: '/arrears-submitted-reports',
  arrearsErrorRecord: '/arrears-submitted-reports/errors',

  // Portfolio Insurance User Access Management
  userManagement: '/user-management',
  createInternalPIUser: '/user-management/addInternalPIUser',
  createExternalPIUser: '/user-management/addExternalPIUser',
  updateSelectedPIUser: '/users/update',
  getSelectedInternalPIUser: '/user-management/internalUserdetails',
  getSelectedExternalPIUser: '/user-management/externalUserdetails'
};
