import React from 'react';
import Dropzone from 'react-dropzone';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';

import GetErrorMessage from 'Constants/Utils/ErrorMessage/errorMessage';
import FormInputWrapper from 'Components/Inputs/FormInputWrapper';
import { FileInputDropChooseProps } from '../types';
import styles from './styles.module.scss';

export const FileInputDropChoose: React.FC<FileInputDropChooseProps> = ({
  fields,
  errors,
  registeredVal,
  sectionId,
  isAllowed = true,
  isArrears = false,
  handleAttachmentDrop,
  handleAttachmentInput
}: FileInputDropChooseProps) => (
  <div className="row card__body-row">
    <div className="form__element col-12">
      <div className="form__element form__element--attachment">
        <div className="mb-1">
          <fieldset disabled={!isAllowed} className={styles.fileDrop}>
            <Dropzone data-testid="handleAttachmentDrop" onDrop={handleAttachmentDrop}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({
                    onClick: (e) => {
                      e.stopPropagation();
                    }
                  })}
                  className={`form__attachment${
                    errors?.[registeredVal] != null || errors?.arrearsFiles?.[0]?.attachment != null
                      ? ' form-error'
                      : ''
                  }`}
                >
                  <Image
                    field={isAllowed ? fields.uploadIcon : fields.disabledUploadIcon}
                    className="form__attachment-icon"
                    aria-hidden="true"
                  />
                  <FormInputWrapper name={`${sectionId}Wrapper`}>
                    <div className="form__attachment-text">
                      <label
                        htmlFor={`${sectionId}UploadFiles`}
                        id={`${sectionId}UploadFilesLabel`}
                        className={'input-upload-label'}
                      >
                        <Text field={fields.uploadInstructions1} />
                        <input
                          {...getInputProps()}
                          type="file"
                          name="filename"
                          multiple={!isArrears}
                          id={`${sectionId}UploadFiles`}
                          onChange={handleAttachmentInput}
                          data-testid="handleAttachmentInput"
                          className="form__attachment-manual-upload"
                        />
                        <span className={`upload-span ${isAllowed ? '' : styles.grey}`}>
                          {fields.uploadInstructions2 && fields.uploadInstructions2.value}
                        </span>
                        {fields.uploadInstructions3 != null && (
                          <Text field={fields.uploadInstructions3} />
                        )}
                      </label>
                    </div>
                  </FormInputWrapper>
                  <div className="form__attachment-sub-text">
                    <Text
                      field={isArrears ? fields.supportedFormatsOther : fields.supportedFormatsMain}
                    />
                  </div>
                </div>
              )}
            </Dropzone>
          </fieldset>
          {
            <div className="form__fieldNotificationHolder">
              {
                <span className={`form-error__sub-text`} aria-live="assertive" id={`ErrorDesc`}>
                  {GetErrorMessage(
                    errors?.arrearsFiles && errors.arrearsFiles[0]?.attachment?.type,
                    errors?.arrearsFiles && errors.arrearsFiles[0]?.attachment?.message
                  )}
                </span>
              }
            </div>
          }
        </div>
      </div>
    </div>
  </div>
);
