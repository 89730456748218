import { UserAccessStatus, UserRole, Module } from '@hobt/constants';
import { Access } from '@hobt/user-domain';

export enum DelegateApiResponse {
  Success,
  Created,
  BadRequest,
  Updated,
  NotAuthorized,
  DelegatorMissingModuleMasterAccess,
  FromUserMissingModuleAccess,
  ToUserMissingModuleAccess,
  ProbationaryUserNotAuthorized,
  Error
}

export type ErrorObject = {
  response?: Response;
};
export type Response = {
  status?: number;
  data: DataObject;
};

export type DataObject = {
  statusCode: number;
  status?: string;
  data: any | any[];
  error: ErrorEntity;
};

export type ErrorEntity = {
  errorMessage?: string;
  errorCode?: any;
};

export const DelegateAccess = {
  arrears: Module.Arrears,
  claim: Module.Claim,
  default: Module.Default
};

export enum DelegationRoles {
  SeniorOfficer = 2,
  Manager,
  SeniorSpecialist,
  Director,
  SeniorVP
}

export const DelegationRolesEnum: Record<DelegationRoles, string> = {
  [DelegationRoles.SeniorOfficer]: 'Senior Officer, Operations',
  [DelegationRoles.Manager]: 'Manager, Programs',
  [DelegationRoles.SeniorSpecialist]: 'Senior Specialist, Claims and Recoveries',
  [DelegationRoles.Director]: 'Director, Homeowner Operations',
  [DelegationRoles.SeniorVP]: 'Senior Vice-President, Client Operations'
};

export interface DelegationPayload {
  actingUserTitleTypeCode: number;
  comment?: string;
  delegationID?: string;
  endDate: string;
  fromUserID: string;
  moduleName: Access;
  startDate: string;
  toUserID: string;
}

export interface User {
  userID: string;
  emailID: string;
  recordUpdatedTimestamp: string;
  fullName: string;
  phoneNumber: string;
  extensionPhoneNumber?: string;
  arrearsRoleCode: UserRole | null;
  claimRoleCode: UserRole | null;
  defaultRoleCode: UserRole | null;
  titleName: string;
  loginTimestamp: string;
  statusCode: UserAccessStatus;
}

export interface ToastMessageType {
  title: string;
  content: string;
}
