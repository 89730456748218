import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useFeature } from 'flagged';
import i18n from 'i18next';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import {
  AdjudicationNoteCategoryInternal,
  CommentType,
  CorrespondenceType
} from '@hobt/claim-domain';
import { UserInfo } from '@hobt/user-domain';

import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { Button } from 'Components/Common/Button';
import FormTextArea from 'Components/Inputs/FormTextArea';
import FormDropdown from 'Components/Inputs/FormDropdown';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

import styles from './styles.module.scss';
import { StatusChangeReasonProps } from './types';

export const StatusChangeReason: React.FC<StatusChangeReasonProps> = ({
  onCancelCallback,
  onErrorCallback,
  proceedSubmit,
  hookForm,
  fields
}: StatusChangeReasonProps) => {
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  let internalUserDetails: UserInfo | undefined;
  const isInternal = useFeature(FeatureFlags.INTERNAL);
  const { loadingStatus } = useHBTFormContext();

  if (isInternal === true) {
    internalUserDetails = sitecoreContext?.user;
  }

  useEffect(() => {
    hookForm.register('submitterUserID');
    hookForm.register('submitterUserTypeCode');
    hookForm.register('correspondenceTypeCode');
    hookForm.register('commentTypeCode');
    hookForm.register('adjudicationNoteCategoryCode');

    hookForm.setValue('commentTypeCode', CommentType.InternalNote);
    hookForm.setValue('submitterUserID', internalUserDetails?.userID);
    hookForm.setValue('submitterUserTypeCode', internalUserDetails?.userTypeCode);
    hookForm.setValue('correspondenceTypeCode', CorrespondenceType.Internal);
    hookForm.setValue(
      'adjudicationNoteCategoryCode',
      AdjudicationNoteCategoryInternal.ClaimStatusOverride
    );
  }, []);

  return (
    <Scrollbars>
      <div className={styles.statusChangeReasonFormHeader} data-testid={`statusChangeReasonForm`}>
        <div className={styles.textHeader}>
          <Text tag="h2" field={fields.title} />
        </div>
        <div className={styles.closeButton}>
          <button
            type="button"
            onClick={onCancelCallback}
            data-testid="statusChangeReasonCloseButton"
            aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
          >
            <span className="material-icons align-self-center">close</span>
          </button>
        </div>
      </div>
      <div className={styles.statusChangeReasonFormBody}>
        <div className={styles.textStyle}>
          <Text tag="p" field={fields.description} />
        </div>
        <FormProvider {...hookForm}>
          <form
            className={styles.statusChangeReasonForm}
            onSubmit={hookForm.handleSubmit(proceedSubmit, onErrorCallback)}
            id="statusChangeReasonForm"
          >
            <div>
              <FormDropdown
                name="claimStatusOverrideTypeCode"
                label={fields.categoryLabel}
                options={fields.categoryList.fields.listItems}
              />
              <FormTextArea
                name="remarkText"
                label={fields.commentsLabel}
                className={styles.templateTextArea}
                charLimit={Number(fields.limitNumber.value)}
                textAreaHelperText={fields.characterLimit.value}
              />
            </div>
            <div className={styles.buttons}>
              <Button
                name="submitButton"
                responsive
                loading
                type="submit"
                className={styles.halfLeft}
                ariaText={fields.submitButtonLabel}
                text={fields.submitButtonLabel}
                disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
                icon={
                  loadingStatus?.isLoading && loadingStatus?.isFlyout
                    ? () => (
                        <span className={styles.saveIconWrapper}>
                          <span className={`material-icons align-self-center`}>loop</span>
                        </span>
                      )
                    : undefined
                }
              />
              <Button
                name={`CancelButton`}
                className={styles.half}
                secondaryButton
                responsive
                onClick={onCancelCallback}
                ariaText={{}}
                text={fields.cancelButtonLabel}
                type="button"
                disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </Scrollbars>
  );
};
