import * as React from 'react';
import { StatusCellProps } from 'Components/Common/DataTable/DataTableRow/StatusCell/types';
import styles from './styles.module.scss';

export const StatusCell: React.FC<StatusCellProps> = ({
  className,
  name,
  statusText
}: StatusCellProps) => (
  <td data-testid={`${name}StatusCell`}>
    <span className={`${styles.status} ${styles[className]}`}>{statusText}</span>
  </td>
);
