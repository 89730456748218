/* eslint-disable */
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  borrowerDetailsSchema,
  DefaultReason,
  JudgmentAction,
  ReasonNotPursued,
} from '@hobt/claim-domain';

import FormDatepicker from 'Components/Inputs/FormDatepicker';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormText from 'Components/Inputs/FormText';
import FormTextArea from 'Components/Inputs/FormTextArea';
import FormYesNoRadios from 'Components/Inputs/FormYesNoRadios';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { ClaimsBorrowerDetailsPropsExtended } from 'Feature/Claims/models/ClaimsBorrowerDetailsProps';

import styles from './styles.module.scss';

const OPTIONAL_DROPDOWN_FIELDS_MASTER_USER_POST_ADJUDICATION = [
  'reasonNotPursuedTypeCode',
];

const BorrowerDetails = (props: ClaimsBorrowerDetailsPropsExtended) => {
  const { watch, setValue } = useFormContext();
  const {
    isInEditMode,
    isFieldDisabled,
    isMasterUserEditingPostAdjudicationClaim,
  } = useHBTFormContext();

  const reasonforBorrowerDefault = watch(
    `borrowers[${props.index}].defaultReasonCode`,
    ''
  );
  const reasonforJudgementAction = watch(
    `borrowers[${props.index}].judgmentActionTypeCode`
  );
  const reasonforNotPursued = watch(
    `borrowers[${props.index}].reasonNotPursuedTypeCode`
  );

  const defaultReasonCode=watch(`borrowers[${props.index}].defaultReasonCode`)

  const otherDefaultReasonDescription = watch(`borrowers[${props.index}].otherDefaultReasonDescription`);

  const otherReasonNotPursuedDescription = watch(`borrowers[${props.index}].otherReasonNotPursuedDescription`);


  useEffect(() => {
    if (
      reasonforJudgementAction === '01' ||
      reasonforJudgementAction === '02' ||
      reasonforJudgementAction === '03' ||
      reasonforJudgementAction === '05'
    ) {
      setValue(`borrowers[${props.index}].otherReasonNotPursuedDescription`, null);
      setValue(`borrowers[${props.index}].reasonNotPursuedTypeCode`, null);
    } else if(reasonforNotPursued !== '04' && (otherReasonNotPursuedDescription === ''
      ||otherReasonNotPursuedDescription?.length!==0)){
        setValue(`borrowers[${props.index}].otherReasonNotPursuedDescription`, null);
    }
  }, [isInEditMode, reasonforNotPursued, reasonforJudgementAction, otherReasonNotPursuedDescription]);

  useEffect(() => {
    if ( defaultReasonCode !== '07' && (otherDefaultReasonDescription === ''||otherDefaultReasonDescription?.length!==0)) {
      setValue(`borrowers[${props.index}].otherDefaultReasonDescription`, null);
    }
  }, [isInEditMode, otherDefaultReasonDescription, defaultReasonCode]);


  if (
    isMasterUserEditingPostAdjudicationClaim === true &&
    isInEditMode === true
  ) {
    // Temporarily add to list of enums to allow to skip schema validations
    OPTIONAL_DROPDOWN_FIELDS_MASTER_USER_POST_ADJUDICATION.forEach((field) => {
      if (!borrowerDetailsSchema.properties?.[field]?.enum?.includes('')) {
        borrowerDetailsSchema.properties?.[field]?.enum?.push('');
      }
    });
  }

  return (
    <>
      <FormText
        className={styles.halfLeft}
        label={props.fields.firstNameLabel}
        name={`borrowers[${props.index}].firstName`}
        isReadOnly={isFieldDisabled}
      />
      <FormText
        className={styles.half}
        label={props.fields.lastNameLabel}
        name={`borrowers[${props.index}].lastName`}
        isReadOnly={isFieldDisabled}
      />

      <FormDatepicker
        className={styles.halfLeft}
        label={props.fields.dateofBirthLabel}
        name={`borrowers[${props.index}].birthDate`}
        isReadOnly={isFieldDisabled}
      />
      <FormDropdown
        className={styles.half}
        label={props.fields.typeofBorrowerLabel}
        name={`borrowers[${props.index}].claimBorrowerCategoryID`}
        options={props.fields.typeofBorrower[0].fields.listItems}
        isDisabled={isFieldDisabled}
        setAsNumber={false}
      />

      <FormDropdown
        className={styles.full}
        label={props.fields.reasonforBorrowerDefaultLabel}
        name={`borrowers[${props.index}].defaultReasonCode`}
        options={props.fields.reasonforBorrowerDefault[0].fields.listItems}
        isDisabled={isFieldDisabled}
        setAsNumber={false}
      />
      {(reasonforBorrowerDefault === DefaultReason.Other ||
        isMasterUserEditingPostAdjudicationClaim === true) && (
        <FormTextArea
          className={styles.full}
          label={props.fields.specifyOther}
          name={`borrowers[${props.index}].otherDefaultReasonDescription`}
          charLimit={100}
          textAreaHelperText={props.fields?.characterLimit?.value}
          isReadOnly={isFieldDisabled}
        />
      )}
      <FormDropdown
        className={styles.full}
        label={props.fields.judgmentActionLabel}
        name={`borrowers[${props.index}].judgmentActionTypeCode`}
        options={props.fields.judgmentAction?.[0].fields.listItems}
        setAsNumber={false}
        isDisabled={isFieldDisabled}
      />

      <FormDropdown
        className={styles.full}
        label={props.fields.reasonForNotPursueLabel}
        name={`borrowers[${props.index}].reasonNotPursuedTypeCode`}
        options={props.fields.reasonForNotPursue[0].fields.listItems}
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : {
                when: reasonforJudgementAction,
                is: [JudgmentAction.NotPursued],
                className: 'd-none',
              }
        }
        isDisabled={isFieldDisabled}
        setAsNumber={false}
      />
      {((reasonforJudgementAction === JudgmentAction.NotPursued &&
        reasonforNotPursued === ReasonNotPursued.Other) ||
        isMasterUserEditingPostAdjudicationClaim === true) && (
        <FormTextArea
          className={styles.full}
          label={props.fields.specifynotPursuedReasonOther}
          name={`borrowers[${props.index}].otherReasonNotPursuedDescription`}
          charLimit={100}
          textAreaHelperText={props.fields?.characterLimit?.value}
          isReadOnly={isFieldDisabled}
        />
      )}
      <FormYesNoRadios
        className={styles['radio-group']}
        label={props.fields.financialAssessment}
        name={`borrowers[${props.index}].financialAssessmentCompletedFlag`}
        isReadOnly={isFieldDisabled}
      />
    </>
  );
};

export default BorrowerDetails;
