import React, { memo } from 'react';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import HbtTermsAndConditionsPlaceHolderProps from './types';

const HbtTermsAndConditionsPlaceHolderComponent: React.FC<
  HbtTermsAndConditionsPlaceHolderProps
> = ({ rendering }: HbtTermsAndConditionsPlaceHolderProps) => {
  const sitecoreContextFactory = useSitecoreContext();

  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  return (
    <>
      {sitecoreContext && sitecoreContext.isPortfolioSite ? (
        <Placeholder name="hbt-shared-v2-terms-and-conditions" rendering={rendering} />
      ) : (
        <Placeholder name="hbt-shared-terms-and-conditions" rendering={rendering} />
      )}
    </>
  );
};

export default memo(HbtTermsAndConditionsPlaceHolderComponent);
