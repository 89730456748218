import React from 'react';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { useFeature } from 'flagged';
import i18n from 'i18next';

import { Card } from 'Components/Card';
import { Tabs } from 'Components/Navigation/Tabs';
import HBTFormContextProvider from 'Feature/Claims/components/HBTFormContext';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { AdjudicationCardHeader } from 'Feature/Claims/components/Details/AdjudicationCardHeader';
import { Modal } from 'Components/Common/Modal';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { ToastProvider } from 'Feature/CommonComponents/ContentComponents/ToastNotification/ToastNotificationHook';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';
import { LoaderAnimation } from 'Feature/CommonComponents/UserControls';
import DetailsProps from './types';
import { useClaimAdjudicationFunction } from './useClaimAdjudicationFunction';

import styles from './styles.module.scss';

export const SitecoreContentContext = React.createContext<DetailsProps>({
  fields: {
    editButton: {},
    editButtonAriaText: {},
    previewCalculationsButton: {},
    previewCalculationsButtonAriaText: {},
    setClaimStatusLabel: {},
    cancelButton: {},
    cancelButtonAriaText: {},
    saveButton: {},
    saveButtonAriaText: {},
    deleteButton: {},
    deleteButtonAriaText: {},
    lenderReference: {},
    cmhcLoan: {},
    claimStatus: [],
    claimsSubmission: {},
    loanDetails: {},
    assessmentCalculations: {},
    suspenseConditions: {},
    attachmentsNotesComments: {},
    refundManagement: {},
    emailButton: {},
    declineButton: {},
    contestClaimButton: {},
    ellipsisButton: {},
    cancelEllipsisBtn: {},
    components: [],
    ownerLabel: {},
    assignOwnerPlaceholderText: {},
    noOptionsText: {},
    warningModal: {
      fields: {
        acceptButton: {},
        cancelButton: {},
        content: {},
        heading: {}
      }
    },
    tabLockModal: {
      fields: {
        acceptButton: {},
        cancelButton: {},
        content: {},
        heading: {}
      }
    },
    claimsStatusList: {
      fields: {
        listName: {},
        listItems: []
      }
    },
    statusLabel: {},
    addContestClaimFlyout: {
      fields: {
        formTitle: {},
        content: {},
        description: {},
        characterLimit: {},
        submitBtn: {},
        cancelBtn: {},
        limitNumber: {},
        attachmentsView: {
          fields: {
            name: {},
            heading: {},
            attachmentLabel: {},
            attachmentLabelOptional: {},
            cancelIcon: {},
            deleteIcon: {},
            supportedFormatsMain: {},
            supportedFormatsOther: {},
            uploadIcon: {},
            disabledUploadIcon: {},
            uploadInstructions1: {},
            uploadInstructions2: {},
            uploadInstructions3: {},
            fileCountWarningText: {},
            uploadFileCountText: {},
            warningIcon: {}
          }
        }
      }
    },
    emailToLenderSubject: {},
    declineClaimFlyout: {
      fields: {
        formTitle: {},
        content: {},
        categoryLabel: {},
        languageLabel: {},
        languageOptions: [],
        categoryListFrench: {
          fields: {
            listName: { value: '' },
            listItems: [
              {
                fields: {
                  itemName: { value: 'Lender employee fraud' },
                  itemValue: { value: '1' }
                }
              }
            ]
          }
        },
        notificationTemplateEnglish: { field: {} },
        notificationTemplateFrench: { field: {} },
        typeOfClaimToken: {},
        cmhcAccountToken: {},
        declineReasonEnglish: {},
        declineReasonFrench: {},
        declineNoteEnglish: {},
        declineNoteFrench: {},
        categoryPlaceholder: {},
        categoryList: { fields: { listName: {}, listItems: [] } },
        note: {},
        notificationTemplateLabel: {},
        notePlaceholder: {},
        characterLimit: {},
        submitBtn: {},
        cancelBtn: {},
        limitNumber: {},
        attachmentsView: {
          fields: {
            name: {},
            heading: {},
            attachmentLabel: {},
            attachmentLabelOptional: {},
            cancelIcon: {},
            deleteIcon: {},
            supportedFormatsMain: {},
            supportedFormatsOther: {},
            uploadIcon: {},
            disabledUploadIcon: {},
            uploadInstructions1: {},
            uploadInstructions2: {},
            uploadInstructions3: {},
            fileCountWarningText: {},
            uploadFileCountText: {},
            warningIcon: {}
          }
        }
      }
    },
    cancelClaimFlyout: {
      fields: {
        attachmentsView: {
          fields: {
            attachmentLabel: {},
            attachmentLabelOptional: {},
            cancelIcon: {},
            deleteIcon: {},
            heading: {},
            name: {},
            supportedFormatsMain: {},
            supportedFormatsOther: {},
            uploadIcon: {},
            disabledUploadIcon: {},
            uploadInstructions1: {},
            uploadInstructions2: {},
            uploadInstructions3: {},
            fileCountWarningText: {},
            uploadFileCountText: {},
            warningIcon: {}
          }
        },
        cancellationNoteToken: {},
        cancellationReasonToken: {},
        notificationTemplateTitle: {},
        cancelBtn: {},
        cancellationTemplateLabel: {},
        categoryLabel: {},
        categoryList: {
          fields: {
            listItems: [],
            listName: {}
          }
        },
        categoryPlaceholder: {},
        characterLimit: {},
        content: {},
        formTitle: {},
        helpText: {},
        limitNumber: {},
        note: {},
        notePlaceholder: {},
        notificationTemplate: { field: {} },
        proceedBtn: {},
        submitBtn: {},
        uploadIcon: {}
      }
    },
    statusChangeFlyout: {
      fields: {
        title: {},
        description: {},
        categoryLabel: {},
        categoryList: {
          fields: {
            listItems: [],
            listName: {}
          }
        },
        commentsLabel: {},
        characterLimit: {},
        submitButtonLabel: {},
        cancelButtonLabel: {},
        limitNumber: {}
      }
    },
    deleteClaimModal: {
      fields: {
        heading: {},
        content: {},
        acceptButton: {},
        cancelButton: {}
      }
    }
  },
  rendering: {}
});

const Details = ({ fields, rendering }: DetailsProps) => {
  const authContext = useAuthenticationContext();
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const urlParams = new URLSearchParams(!isServer() ? window.location.search : '');
  const uuid = urlParams.get('id');
  const claimID = uuid != null ? uuid : '';

  const {
    currentTabProps,
    pendingTab,
    tabsLocked,
    isClaimStatusPostAdjudicationStatus,
    isInEditMode,
    isMasterUserEditingPostAdjudicationClaim,
    isMasterUser,
    isReadOnlyView,
    isReadOnlyAccessUser,
    tabs,
    isPageLoading,
    loadingStatus,
    finalClaimData,
    setIsPageLoading,
    setLoadingStatus,
    setIsInEditMode,
    lockTabs,
    cancelTabNavigation,
    proceedWithTabNavigation,
    handleTabClick,
    renderTabContent,
    updateClaimData,
    autoDecision,
    downloadClaimCalculationLetter
  } = useClaimAdjudicationFunction(claimID, authContext, {
    fields,
    rendering
  });

  if (isPageLoading) {
    return <LoaderAnimation />;
  }

  if (finalClaimData == null) {
    return null;
  }

  return (
    <HBTFormContextProvider
      value={{
        uuid: claimID,
        rendering,
        claimData: finalClaimData,
        isPageLoading,
        loadingStatus,
        completeCards: [],
        isClaimStatusPostAdjudicationStatus,
        isInEditMode,
        isClaimsDetails: true,
        isReadOnlyView,
        isReadOnlyAccessUser,
        isMasterUser,
        isMasterUserEditingPostAdjudicationClaim,
        setIsPageLoading,
        setLoadingStatus,
        setIsInEditMode,
        lockTabs,
        updateClaimData,
        autoDecision,
        downloadClaimCalculationLetter
      }}
    >
      <SitecoreContentContext.Provider value={{ fields, rendering }}>
        <ContentLoadingModal
          display={loadingStatus.isLoading}
          fields={{
            heading: {
              value: i18n.t(loadingStatus.spinnerHeading ?? 'Globals-InProgress-Heading')
            },
            description: {
              value: i18n.t(loadingStatus.spinnerDescription ?? 'Globals-InProgress-Description')
            }
          }}
        />
        <Modal
          type={ApplicationStates.WARNING}
          style=""
          title={fields.tabLockModal.fields.heading.value ?? ''}
          content={fields.tabLockModal.fields.content.value ?? ''}
          isActive={pendingTab !== null && tabsLocked}
          onCloseCallback={cancelTabNavigation}
          onPrimaryCallback={proceedWithTabNavigation}
          onSecondaryCallback={cancelTabNavigation}
          fields={{
            saveButton: fields.tabLockModal.fields.acceptButton,
            cancelButton: fields.tabLockModal.fields.cancelButton
          }}
        />
        <ToastProvider>
          <Card expandToMatchViewportHeight>
            <AdjudicationCardHeader />

            <div
              className={`${styles.tabs} ${
                !siteTypeIsInternal ? styles.externalTabsWidth : ''
              }`.trim()}
            >
              <Tabs
                items={tabs}
                tabOnClickCallback={handleTabClick}
                ariaControlsName="tabContent"
                currentTab={currentTabProps}
                label={fields.sectionLabel}
              />
            </div>

            <div className={styles.tabContent} id="tabContent">
              {renderTabContent()}
            </div>
          </Card>
        </ToastProvider>
      </SitecoreContentContext.Provider>
    </HBTFormContextProvider>
  );
};

export default Details;
