import React, { useEffect, useRef, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { TextProps } from '@sitecore-jss/sitecore-jss-react/types/components/Text';

type StepHeaderProps = {
  title: TextProps['field'];
};

export const StepHeader = (props: StepHeaderProps) => (
  <div className="row">
    <div className="col-12">
      <h2>
        <Text field={props.title} />
      </h2>
    </div>
  </div>
);
