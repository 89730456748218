import FocusLock from 'react-focus-lock';
import React, { useEffect } from 'react';

import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import { NotificationActionBar } from 'Feature/Header/components/Notifications/NotificationActionBar';
import { NotificationContent } from 'Feature/Header/components/Notifications/NotificationContent';
import { NotificationHeader } from 'Feature/Header/components/Notifications/NotificationHeader';
import { NotificationList } from 'Feature/Header/components/Notifications/NotificationList';
import {
  APPROVAL_NOTIFICATION_SCENARIOS,
  ARREARS_SUBMISSION_SCENARIOS,
  CLAIM_SUBMISSION_SCENARIOS,
  CONTACT_UPDATE_SCENARIOS,
  CRM_PAYEE_NOTIFICATION_SCENARIOS,
  NotificationProps,
  NotificationStatus
} from 'Feature/Header/components/Notifications/types';
import { useNotificationFunctions } from 'Feature/Header/components/Notifications/useNotificationFunctions';

import { NotificationButton } from './NotificationButton';

import styles from './styles.module.scss';

const Notifications: React.FC<NotificationProps> = ({ fields, buttonText }: NotificationProps) => {
  const {
    currentTab,
    showMarkAllReadButton,
    hasUnreadNotifications,
    notifications,
    tabs,
    showNotificationSidebar,
    selectedNotification,
    notificationContent,
    showNotificationContent,
    refreshButtonLoading,
    handleNotificationItemClick,
    getNotifications,
    closeNotificationContent,
    handleRefreshButtonClick,
    toggleNotificationSidebar,
    handleTabClick,
    handleMarkAllRead
  } = useNotificationFunctions({ fields, buttonText });

  const missingNotificationTextMap = {
    [NotificationStatus.ALL]: fields?.noNotificationsMessages?.value ?? '',
    [NotificationStatus.READ]: fields?.noReadNotificationsMessages?.value ?? '',
    [NotificationStatus.UNREAD]: fields?.noUnreadNotificationsMessages?.value ?? ''
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const isApprovalRequiredNotificationScenario = APPROVAL_NOTIFICATION_SCENARIOS.slice(
    0,
    3
  ).includes(selectedNotification?.scenarioID ?? 0);

  const isLenderReferenceNumberHidden = [
    ...APPROVAL_NOTIFICATION_SCENARIOS,
    ...ARREARS_SUBMISSION_SCENARIOS,
    ...CONTACT_UPDATE_SCENARIOS,
    ...CRM_PAYEE_NOTIFICATION_SCENARIOS,
    ...CLAIM_SUBMISSION_SCENARIOS
  ].includes(selectedNotification?.scenarioID ?? 0);

  return (
    <>
      <NotificationButton
        text={buttonText}
        hasNewItems={hasUnreadNotifications}
        label={fields.title ?? { value: '' }}
        newItemText={fields.unreadNotificationText ?? { value: '' }}
        onClick={toggleNotificationSidebar}
      />
      {showNotificationSidebar && (
        <FocusLock>
          <SideDrawer
            isActive={showNotificationSidebar}
            classes={styles.sideDrawer}
            handleOutsideClick={toggleNotificationSidebar}
            isFocusTrapPaused={showNotificationContent}
          >
            <div className={styles.listingSideDrawer}>
              <NotificationHeader
                title={fields.title}
                refreshLoading={refreshButtonLoading}
                onClose={toggleNotificationSidebar}
                onRefresh={handleRefreshButtonClick}
              />
              <NotificationActionBar
                showMarkAllButton={showMarkAllReadButton}
                tabs={tabs}
                tabsControlId="notificationList"
                markAllAsRead={fields.markAll}
                onTabClick={handleTabClick}
                onMarkAllReadClick={handleMarkAllRead}
                notificationTitle={fields.title}
              />
              {notifications?.length > 0 ? (
                <NotificationList
                  fields={fields}
                  notifications={notifications}
                  onItemClick={handleNotificationItemClick}
                  selectedNotificationId={selectedNotification?.notificationID}
                />
              ) : (
                <p className={styles.missingNotificationMessage}>
                  {missingNotificationTextMap[currentTab]}
                </p>
              )}
            </div>

            {showNotificationContent && (
              <NotificationContent
                fields={fields}
                isActive={showNotificationContent}
                onClose={closeNotificationContent}
                content={notificationContent}
                showLenderReferenceNumber={!isLenderReferenceNumberHidden}
                isApprovalRequiredNotification={isApprovalRequiredNotificationScenario}
              />
            )}
          </SideDrawer>
        </FocusLock>
      )}
    </>
  );
};

export default Notifications;
