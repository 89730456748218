import React, { FC, memo, useMemo } from 'react';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import { validateHbtDataTableCellValue } from '../../Helper';
import { MemoizedHbtDataTableCellProps } from './types';

export const MemoizedHbtDataTableCell: FC<MemoizedHbtDataTableCellProps> = memo(
  ({ dataValue, cellContent, iconClassName }) => {
    const isCellValueValid = useMemo(() => validateHbtDataTableCellValue(dataValue), [dataValue]);

    return !isCellValueValid ? (
      <>{cellContent}</>
    ) : (
      <HbtIcon type="icon_indeterminate" className={iconClassName} size={HbtIconSize.MEDIUM} />
    );
  }
);
