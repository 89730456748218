import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';

import { UserRole, ModuleMapping, Module } from '@hobt/constants';
import { hbtResolver } from '@hobt/schema-validator';
import { delegationFrontendSchema, DelegationInventory } from '@hobt/user-domain';

import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { ImageProps, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import {
  NotificationCard,
  ToastNotification,
  ToastNotificationProps
} from 'Feature/CommonComponents/ContentComponents';
import { DataObject } from 'Feature/Header/models/types';
import { CardType, ApplicationStates, SubmissionType } from 'Feature/CommonComponents/Enums';
import { Card } from 'Components/Card';
import { pathNames } from 'Constants/pathNames';
import FormText from 'Components/Inputs/FormText';
import { Button } from 'Components/Common/Button';
import { CardBody } from 'Components/Card/CardBody';
import { CardHeader } from 'Components/Card/CardHeader';
import { CardFooter } from 'Components/Card/CardFooter';
import FormTextArea from 'Components/Inputs/FormTextArea';
import FormDropdown from 'Components/Inputs/FormDropdown';
import { FormCheckbox } from 'Components/Inputs/FormCheckbox';
import FormDatepicker from 'Components/Inputs/FormDatepicker';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import FormCheckboxListWrapper from 'Components/Inputs/FormCheckboxListWrapper';
import formCheckboxListStyles from 'Components/Inputs/FormCheckboxList/styles.module.scss';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import {
  DelegationPayload,
  DelegateApiResponse,
  User,
  ToastMessageType
} from 'Feature/DelegateAuthority/models/types';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';
import { LoaderAnimation } from 'Feature/CommonComponents/UserControls';

import {
  createDelegateRequest,
  getDelegateRequest,
  getUserRequest,
  updateDelegateRequest
} from '../api/DelegateApiHandler.service';
import AddDelegateAuthorityProps from '../../models/AddDelegateAuthorityProps';
import styles from './styles.module.scss';

let isModuleTypeCodesDirty = false;
const DEFAULT_ERROR_MESSAGE = 'DefaultSubmission-ErrorToastTitle';
const DELEGATION_ERROR_MESSAGE_PREFIX = 'Delegation-Users-ErrorToastMessage-HBT_ERROR_';
const DEFAULT_SUBMISSION_OTHER_ERROR = 'DefaultSubmission-OtherErrors';

const AddDelegateAuthority = ({ fields }: AddDelegateAuthorityProps) => {
  const history = useHistory();
  const authenticationContext = useAuthenticationContext();

  const [toast, setToast] = useState<boolean>(false);
  const [isBypass, setBypass] = useState<boolean>(false);
  const [isModify, setIsModify] = useState<boolean>(false);
  const [isDataBind, setIsDataBind] = useState<boolean>(false);
  const [delegateData, setDelegateData] = useState<DelegationInventory | null>(null);
  const [isSubmitFailed, setSubmitFailed] = useState<boolean>(false);
  const [delegationAuthData, setDelegationAuthData] = useState<User[]>([]);
  const [isAuthorizationError, setIsAuthorizationError] = useState<boolean>(false);
  const [moduleTypeCodes, setModuleTypeCodes] = useState<Module[]>([]);
  const [toUserTitle, setToUserTitle] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<ToastMessageType>({
    title: '',
    content: ''
  });
  const [actingTitleTypeCode, setActingTitleTypeCode] = useState<string>('');

  const errorNotificationRef = useRef<HTMLDivElement | null>(null);

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;

  const urlParams = new URLSearchParams(!isServer() ? window.location.search : '');
  const id = urlParams.get('id') ?? '';

  const isReadOnlyUser: boolean =
    isUserInRoles(ModuleMapping.claim, [UserRole.ReadOnly], moduleRoleMapping) &&
    isUserInRoles(ModuleMapping.arrears, [UserRole.ReadOnly], moduleRoleMapping);

  const methods = useForm({
    resolver: hbtResolver(delegationFrontendSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  } as Record<string, any>);

  const {
    formState: { dirtyFields, isDirty }
  } = methods;

  // isDirty check
  useEffect(() => {
    if (isServer() === false) {
      // onbeforeunload should trigger on back, forward, refresh, and link button clicks
      window.onbeforeunload = () => {
        // trigger browser warning
        if (Object.keys(dirtyFields).length > 0 && !isBypass) {
          // returning anything, will trigger the prompt,
          // return string only read on IE:
          return i18n.t('DefaultSubmission-IsDirty');
        }
        return null;
      };
    }
  }, [dirtyFields, isBypass]);

  const getData = async () => {
    try {
      setIsPageLoading(true);
      const delegationUsersOptions: DataObject = await getUserRequest(authenticationContext);
      setDelegationAuthData(delegationUsersOptions?.data);

      if (id.length > 0) {
        const delegationRecord: DataObject = await getDelegateRequest(authenticationContext, id);
        setIsModify(true);
        setIsDataBind(true);

        setDelegateData(delegationRecord?.data?.[0]);
      }

      setIsPageLoading(false);
    } catch (_err) {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    methods.register('moduleTypeCodes');
    getData();
    if (isServer() === false) {
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, []);

  useEffect(() => {
    methods.setValue('moduleTypeCodes', moduleTypeCodes);

    if (delegateData != null && isModify && isDataBind) {
      methods.setValue('fromUserID', delegateData?.delegation?.fromUserID ?? '');
      methods.setValue('toUserID', delegateData?.delegation?.toUserID ?? '');
      methods.setValue('comment', delegateData?.delegation?.comment ?? '');
      methods.setValue('startDate', delegateData?.delegation?.startDate ?? '');
      methods.setValue('endDate', delegateData?.delegation?.endDate ?? '');
    }
    if (isModuleTypeCodesDirty === true && isDirty === true) {
      methods.trigger('moduleTypeCodes');
    }
  }, [moduleTypeCodes]);

  useEffect(() => {
    if (delegateData != null && isModify) {
      methods.setValue('fromUserID', delegateData.delegation?.fromUserID ?? '');
      methods.setValue('toUserID', delegateData.delegation?.toUserID ?? '');
      methods.register('actingUserTitleTypeCode');
      methods.setValue(
        'actingUserTitleTypeCode',
        delegateData.delegation?.actingUserTitleTypeCode ?? 0
      );
      methods.setValue('comment', delegateData.delegation?.comment ?? '');
      methods.setValue('startDate', delegateData.delegation?.startDate ?? '');
      methods.setValue('endDate', delegateData.delegation?.endDate ?? '');

      setModuleTypeCodes(delegateData.delegation?.moduleTypeCodes ?? []);
      setActingTitleTypeCode(
        fields?.userLevelList?.fields?.listItems?.find(
          (item: FieldListItem) =>
            item?.fields?.itemValue?.value ===
            delegateData.delegation?.actingUserTitleTypeCode?.toString()
        )?.fields?.itemName?.value ?? ''
      );

      delegationAuthData.forEach((element: any) => {
        if (element.userID === delegateData.delegation?.toUserID) {
          const title =
            element.titleTypeCode != null
              ? fields?.userLevelList?.fields?.listItems?.find(
                  (item: FieldListItem) =>
                    item?.fields?.itemValue?.value === element.titleTypeCode.toString()
                )?.fields?.itemName?.value
              : i18n.t('Global-Not-Available');
          setToUserTitle(title ?? i18n.t('Global-Not-Available'));
        }
        return null;
      });
    }
  }, [delegateData]);

  const handleToastAction = (type: SubmissionType) => {
    setBypass(type !== SubmissionType.ERROR);
    setSubmitFailed(type === SubmissionType.ERROR);
    setToast(type !== SubmissionType.OTHER);
  };

  const toastNotificationProps: ToastNotificationProps = {
    isActive: toast,
    title: i18n.t(toastMessage.title),
    type: ApplicationStates[isSubmitFailed ? 'ERROR' : 'SUCCESS'],
    content: i18n.t(toastMessage.content),
    onCloseCallback: () => {
      handleToastAction(SubmissionType.OTHER);
      if (!isSubmitFailed) {
        history.push(`/${i18n.language}${pathNames.delegateInventory}`);
      }
    }
  };

  const onError = (): void => {
    setIsLoading(false);
    handleToastAction(SubmissionType.ERROR);
  };

  const onFormError = (): void => {
    setToastMessage({
      title: DEFAULT_ERROR_MESSAGE,
      content: DEFAULT_SUBMISSION_OTHER_ERROR
    });
    onError();
  };

  const setAuthorizationError = () => {
    setIsLoading(false);
    setIsAuthorizationError(true);
    if (errorNotificationRef?.current != null) {
      errorNotificationRef.current.scrollIntoView();
    }
  };

  const onSubmit: any = async (formData: DelegationPayload): Promise<void> => {
    let result: number;

    setIsLoading(true);

    if (isModify) {
      const updateFormData: DelegationPayload = { ...formData };
      updateFormData.delegationID = id;

      result = await updateDelegateRequest(authenticationContext, updateFormData);
    } else {
      result = await createDelegateRequest(authenticationContext, formData);
    }

    switch (result) {
      case DelegateApiResponse.Created:
      case DelegateApiResponse.Updated:
        setIsAuthorizationError(false);
        setToastMessage({
          title: 'DefaultsInventoryTable-DecisioningToastMessage-Title',
          content: 'DelegateAuthority-CreateDelegate-SuccessToastMessage'
        });
        handleToastAction(SubmissionType.SUBMIT);
        setIsLoading(false);
        break;
      case DelegateApiResponse.DelegatorMissingModuleMasterAccess:
        setToastMessage({
          title: DEFAULT_ERROR_MESSAGE,
          content: `${DELEGATION_ERROR_MESSAGE_PREFIX}9001`
        });
        onError();
        break;
      case DelegateApiResponse.ToUserMissingModuleAccess:
        setToastMessage({
          title: DEFAULT_ERROR_MESSAGE,
          content: `${DELEGATION_ERROR_MESSAGE_PREFIX}9002`
        });
        onError();
        break;
      case DelegateApiResponse.FromUserMissingModuleAccess:
        setToastMessage({
          title: DEFAULT_ERROR_MESSAGE,
          content: `${DELEGATION_ERROR_MESSAGE_PREFIX}9003`
        });
        onError();
        break;
      case DelegateApiResponse.ProbationaryUserNotAuthorized:
        setAuthorizationError();
        break;
      default:
        setToastMessage({
          title: DEFAULT_ERROR_MESSAGE,
          content: DEFAULT_SUBMISSION_OTHER_ERROR
        });
        onError();
    }
  };

  const onHandleChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    type: string,
    titleFieldName?: string
  ) => {
    delegationAuthData.forEach((element: any) => {
      if (element.userID === event.target.value) {
        const title =
          element.titleTypeCode != null
            ? fields?.userLevelList?.fields?.listItems?.find(
                (item: FieldListItem) =>
                  item?.fields?.itemValue?.value === element.titleTypeCode.toString()
              )?.fields?.itemName?.value
            : i18n.t('Global-Not-Available');

        if (type === 'from' && titleFieldName != null) {
          setActingTitleTypeCode(title ?? i18n.t('Global-Not-Available'));
          methods.setValue(titleFieldName, element.titleTypeCode);
        }

        if (type === 'to') {
          setToUserTitle(title ?? i18n.t('Global-Not-Available'));
        }
      }
      return null;
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDataBind(false);
    const valueNum = parseInt(event.target.value, 10);
    isModuleTypeCodesDirty = true;

    if (event.target.checked === false) {
      setModuleTypeCodes((prevModuleTypeCodes: Module[]) => {
        return prevModuleTypeCodes.filter((moduleTypeCode: Module) => moduleTypeCode !== valueNum);
      });
    }

    if (event.target.checked === true && !moduleTypeCodes.includes(valueNum)) {
      setModuleTypeCodes((prevModuleTypeCodes: Module[]) => {
        return [...prevModuleTypeCodes, valueNum as Module];
      });
    }
  };

  if (isPageLoading) {
    return <LoaderAnimation />;
  }

  return (
    <>
      <ContentLoadingModal
        display={isLoading}
        fields={{
          heading: { value: i18n.t('Globals-Saving-Heading') },
          description: { value: i18n.t('Globals-Saving-Description') }
        }}
      />
      <ToastNotification {...toastNotificationProps} />

      {isAuthorizationError && (
        <div className="row" ref={errorNotificationRef}>
          <div className="col-12">
            <NotificationCard
              id="errorCardId"
              notificationType={CardType.VERTICAL_ERROR}
              title={fields.titleToastMessageAuthorization?.value!}
              message={[fields.ToastMessageAuthorization?.value!]}
              formWarningIcon={fields.iconError?.value! as ImageProps}
            />
          </div>
        </div>
      )}
      <Card>
        <CardHeader separator>
          <div className="row">
            <div className="col-12">
              <h2 className="card-title">
                <Text field={fields.pageTitle} />
              </h2>
            </div>
          </div>
        </CardHeader>
        <FormProvider {...methods}>
          <form
            id="delegateAuthorityForm"
            className="form delegate-authority-form"
            onSubmit={methods.handleSubmit(onSubmit, onFormError)}
            noValidate
          >
            <CardBody>
              <p>
                <Text field={fields.description} />
              </p>
              <div className={styles.cardItem}>
                <div className="row">
                  <section className="col-12">
                    <div className="delegationFromSection">
                      <h2 className="card-title">
                        <Text field={fields.delegationFrom} />
                      </h2>
                      <div className="row card__body-row">
                        <div className="form__element form__element--2-column col-6">
                          <FormDropdown
                            label={fields.userFrom}
                            name="fromUserID"
                            options={delegationAuthData.map((item: any) => ({
                              fields: {
                                itemName: { value: item.fullName },
                                itemValue: { value: item.userID }
                              }
                            }))}
                            handleChange={(e) =>
                              onHandleChange(e, 'from', 'actingUserTitleTypeCode')
                            }
                            setAsNumber={false}
                            isDisabled={isReadOnlyUser}
                          />
                        </div>
                        <div className="form__element form__element--2-column col-6">
                          <fieldset disabled>
                            <FormText
                              className=""
                              name=""
                              dontRegister={true}
                              value={actingTitleTypeCode}
                              label={fields.role}
                            />
                          </fieldset>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div className="delegationToSection">
                      <h2 className="card-title">
                        <Text field={fields.delegationTo} />
                      </h2>

                      <div className="row card__body-row">
                        <div className="form__element form__element--2-column col-6">
                          <FormDropdown
                            label={fields.userTo}
                            name="toUserID"
                            options={delegationAuthData.map((item: any) => ({
                              fields: {
                                itemName: { value: item.fullName },
                                itemValue: { value: item.userID }
                              }
                            }))}
                            handleChange={(e) => onHandleChange(e, 'to')}
                            setAsNumber={false}
                            isDisabled={isReadOnlyUser}
                          />
                        </div>
                        <div className="form__element form__element--2-column col-6">
                          <fieldset disabled>
                            {/* Todo: Update FormText component to have an option to not register fieldss */}
                            <FormText
                              className=""
                              name=""
                              dontRegister={true}
                              value={toUserTitle}
                              label={fields.role}
                            />
                          </fieldset>
                        </div>
                      </div>

                      <div className="card__body-row form__element">
                        <FormCheckboxListWrapper
                          name="moduleTypeCodes"
                          label={fields.applicationAccessLabel}
                          className={formCheckboxListStyles.checkBoxListWrapper}
                        >
                          {fields?.applicationAccessList?.[0]?.fields?.listItems?.map(
                            (checkbox: FieldListItem) => (
                              <FormCheckbox
                                label={checkbox?.fields?.itemName}
                                name=""
                                dontRegister={true}
                                key={`${checkbox?.fields?.itemValue?.value}`}
                                value={checkbox?.fields?.itemValue?.value}
                                checked={moduleTypeCodes.includes(
                                  Number(checkbox?.fields?.itemValue?.value) as unknown as Module
                                )}
                                onChange={handleCheckboxChange}
                                isReadOnly={isReadOnlyUser}
                              />
                            )
                          )}
                        </FormCheckboxListWrapper>
                      </div>

                      <div className="row card__body-row mt-4">
                        <div className="form__element form__element--2-column col-6">
                          <FormDatepicker
                            className=""
                            label={fields.startDate}
                            name="startDate"
                            isReadOnly={isReadOnlyUser}
                          />
                        </div>
                        <div className="form__element form__element--2-column col-6">
                          <FormDatepicker
                            className=""
                            label={fields.endDate}
                            name="endDate"
                            isReadOnly={isReadOnlyUser}
                          />
                        </div>
                      </div>

                      <div className="card__body-row form__element">
                        <FormTextArea
                          className=""
                          name="comment"
                          label={fields.comments}
                          charLimit={Number(fields.maxCharacter.value)}
                          textAreaHelperText={fields.characterLimit.value}
                          isReadOnly={isReadOnlyUser}
                        />
                      </div>
                    </div>
                  </section>
                </div>
                <CardFooter separator>
                  <div className={styles.addButtons}>
                    <Button
                      responsive
                      name="delegateAuthoritySuccessBtn"
                      text={fields.delegateBtn}
                      ariaText={fields.delegateBtn}
                      type="submit"
                      disabled={isReadOnlyUser}
                    />
                  </div>
                  <div className={styles.addButtons}>
                    <Button
                      secondaryButton
                      responsive
                      name="delegateAuthorityCancelBtn"
                      text={fields.cancelBtn}
                      ariaText={fields.cancelBtn}
                      onClick={() => {
                        if (Object.keys(dirtyFields).length > 0) {
                          if (window.confirm(i18n.t('DefaultSubmission-IsDirty'))) {
                            history.push(`/${i18n.language}${pathNames.dashboard}`);
                          }
                        } else {
                          history.push(`/${i18n.language}${pathNames.dashboard}`);
                        }
                      }}
                      type="button"
                    />
                  </div>
                </CardFooter>
              </div>
            </CardBody>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default AddDelegateAuthority;
