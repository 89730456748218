import * as React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import { CheckboxProps } from './Checkbox.types';

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  return (
    <div className="form__element form__element--2-column form__element--checkbox checkbox">
      <input
        data-row-id={props.dataRowId}
        id={`${props.id}CheckboxInput`}
        aria-labelledby={`${props.id}Label`}
        name={props.name}
        type="checkbox"
        defaultChecked={props.checked}
        onClick={props.onClick}
        disabled={props.isDisabled}
        aria-live="assertive"
      />
      <label htmlFor={`${props.id}CheckboxInput`} id={`${props.id}Label`}>
        <span className={'sr-only'}>
          <Text field={{ value: props.title }} />
        </span>
      </label>
      {props.checked && (
        <div className="sr-only" aria-live="assertive">{`${i18n.t(
          'DefaultsInventoryTable-CheckBoxDecisionButtonsShowing'
        )}`}</div>
      )}
    </div>
  );
};
