import i18next from 'i18next';

export const getDefaultHistoryProps = () => {
  const defaultHistoryProps = {
    testId: 'defaultHistoryTest',
    accordionLineColor: 'grey',
    title: {
      field: {
        value: i18next.t('DefaultSubmission-Card-DefaultHistoryHeading')
      }
    },
    textAreaLimit: {
      field: {
        value: i18next.t('DefaultSubmission-Card-ExistingTextAreaCharacterLimit')
      }
    },
    maxLength: Number(i18next.t('Globals-MultilineText-MaxLength')),
    ifCommentRequiredLinkURL: {
      value: {
        href: '#attachments',
        text: i18next.t('DefaultSubmission-CardM-SectionNLink')
      }
    }
  };
  return defaultHistoryProps;
};
