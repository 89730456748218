import React, { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import get from 'Constants/Utils/GetPathInObject';

import FormRadioWrapper from 'Components/Inputs/FormRadioWrapper';
import { FormRadioGroupProps } from './types';

import styles from './styles.module.scss';

const FormRadioGroup: FC<FormRadioGroupProps> = ({
  name,
  label,
  className,
  isReadOnly = false,
  radioLabels,
  defaultSelection,
  setAsNumber = true
}) => {
  const {
    register,
    formState: { errors },
    setValue,
    control
  } = useFormContext();
  const message = get(errors, `${name}.message`);
  const [selectedValue, setSelectedValue] = useState<number | string | undefined>(defaultSelection);

  const handleChange = (fieldName: string, value: number | string) => {
    setSelectedValue(value);
    register(fieldName);
    setValue(fieldName, value);
  };

  useEffect(() => {
    setValue(name, setAsNumber ? Number(defaultSelection) : defaultSelection?.toString());
  }, []);

  return (
    <Controller
      control={control}
      {...register(name || ' ', { valueAsNumber: setAsNumber })}
      render={() => (
        <FormRadioWrapper name={name} label={label} className={className}>
          {radioLabels &&
            radioLabels?.[0]?.fields?.listItems?.map((item: SitecoreListItem) => (
              <label
                key={`RadioList${item.fields.itemValue.value}`}
                className={`${styles['radio-label-wrapper']} ${
                  isReadOnly === true ? styles['read-only'] : ''
                }`.trim()}
              >
                <input
                  className="sr-only"
                  type="radio"
                  name={name}
                  checked={
                    setAsNumber
                      ? selectedValue === Number(item?.fields?.itemValue?.value)
                      : selectedValue === item?.fields?.itemValue?.value
                  }
                  onChange={() =>
                    handleChange(
                      name,
                      setAsNumber
                        ? Number(item?.fields?.itemValue?.value)
                        : item?.fields?.itemValue?.value?.toString() ?? ''
                    )
                  }
                  value={
                    setAsNumber
                      ? Number(item?.fields?.itemValue?.value)
                      : item?.fields?.itemValue?.value?.toString() ?? ''
                  }
                  aria-disabled={isReadOnly}
                  // eslint-disable-next-line jsx-a11y/role-supports-aria-props
                  aria-errormessage={`${name}_error_message`}
                  aria-invalid={Boolean(message)}
                />
                <span className={styles.radio} />
                <span className={styles['radio-label']}>{item.fields.itemName.value}</span>
              </label>
            ))}
        </FormRadioWrapper>
      )}
    />
  );
};

export default FormRadioGroup;
