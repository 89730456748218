import React, { memo, useCallback, useEffect, useState } from 'react';
import { config } from '../../../../../config';
import styles from './styles.module.scss';
import { HbtSessionTimeoutModalBodyProps } from './types';

export const SessionTimeOutModalBody = memo(
  ({ fields, secondsSinceActivity }: HbtSessionTimeoutModalBodyProps) => {
    const { sessionExpiryMessagePartOne, sessionExpiryMessagePartTwo } = fields;

    const prettyPrintRemainingTime = useCallback(
      (seconds: number) => {
        const { minutesLabel, oneMinute, secondLabel, secondsLabel, and } = fields;
        if (seconds > 119)
          return `${Math.floor(seconds / 60)} ${minutesLabel.value} ${and.value} ${seconds % 60} ${
            seconds % 60 === 1 ? secondLabel.value : secondsLabel.value
          }`;
        if (seconds > 59)
          return `${oneMinute.value} ${and.value} ${seconds % 60} ${
            seconds % 60 === 1 ? secondLabel.value : secondsLabel.value
          }`;
        if (seconds <= 0) return `0 ${secondsLabel.value}`;
        return `${seconds} ${seconds === 1 ? secondLabel.value : secondsLabel.value}`;
      },
      [fields]
    );

    const [ariaLiveTimeText, setAriaLiveTimeText] = useState('');
    const [ariaLiveMode, setAriaLiveMode] = useState<'polite' | 'assertive'>('polite');
    const [lastAnnouncedMinute, setLastAnnouncedMinute] = useState<number | null>(null);

    useEffect(() => {
      const interval = setInterval(() => {
        const remainingSeconds =
          parseInt(config.app.sessionLengthInSeconds!) - secondsSinceActivity;
        const remainingMinutes = Math.floor(remainingSeconds / 60);

        if (remainingSeconds > 60) {
          if (remainingMinutes !== lastAnnouncedMinute) {
            if (lastAnnouncedMinute !== null) {
              setAriaLiveMode('polite');
              setAriaLiveTimeText(
                `${sessionExpiryMessagePartOne.value} ${
                  remainingSeconds > 60 && remainingSeconds < 120
                    ? fields.oneMinute.value
                    : `${remainingMinutes} ${fields.minutesLabel.value}`
                } ${sessionExpiryMessagePartTwo.value}`
              );
              setTimeout(() => setAriaLiveTimeText(''), 2000);
            }
            setLastAnnouncedMinute(remainingMinutes);
          }
        } else if (remainingSeconds > 0) {
          setAriaLiveMode('assertive');
          setAriaLiveTimeText(prettyPrintRemainingTime(remainingSeconds));
        } else {
          setAriaLiveMode('assertive');
          setAriaLiveTimeText(prettyPrintRemainingTime(0));
        }
      }, 1000);
      return () => clearInterval(interval);
    }, [secondsSinceActivity, lastAnnouncedMinute, prettyPrintRemainingTime]);

    return (
      <div className="session-timeout-modal-message">
        {sessionExpiryMessagePartOne.value}{' '}
        <span className="session-timeout-modal-time-remaining">
          {prettyPrintRemainingTime(
            parseInt(config.app.sessionLengthInSeconds!) - secondsSinceActivity
          )}
        </span>
        . {sessionExpiryMessagePartTwo.value}
        <span className={styles.screenReaderOnly} aria-live={ariaLiveMode} role="status">
          {ariaLiveTimeText}
        </span>
      </div>
    );
  }
);
