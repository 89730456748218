export function uppercaseFirstLetter(str: string): string | null {
  if (str == null) {
    return null;
  }

  if (str.trim() === '') {
    return '';
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
