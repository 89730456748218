import { ToastNotificationProps } from 'Feature/CommonComponents/ContentComponents';

export default interface HbtModifyInternalPIUserProps {
  fields: HbtModifyInternalPIUserFields;
  rendering?: any;
}

interface HbtModifyInternalPIUserFields {
  pageTitle: FieldValue;
  cancelBtnText: FieldValue;
  updateBtnText: FieldValue;
  resyncBtnText: FieldValue;
  reInviteUserBtnText: FieldValue;
  deleteBtnText: FieldValue;
  deleteAccount: DeleteAccountProps;
  confirmationModal: ConfirmationModalProps;
}

export interface DeleteAccountProps {
  fields: DeleteAccountPropsFields;
}

export interface ConfirmationModalProps {
  fields: ConfirmationModalPropsFields;
}

export interface DeleteAccountPropsFields {
  heading: FieldValue;
  content: FieldValue;
  acceptButton: FieldValue;
  cancelButton: FieldValue;
}
export interface ConfirmationModalPropsFields {
  heading: FieldValue;
  content: FieldValue;
  acceptButton: FieldValue;
  cancelButton: FieldValue;
}

export type HbtModifyInternalPIUserContextType = {
  userData: any;
};

export type HbtModifyInternalPIUserReducerState = {
  modalActive: boolean;
  toastProps: ToastNotificationProps;
  isLoading: boolean;
  isModalLoading: boolean;
  isPageLoading: boolean;
  isBypass: boolean; // Prompt bypass used when submission successful
  updateModalActive: boolean;
};

export type HbtModifyInternalPIUserActionValuePayload = {
  value: boolean;
};

export type HbtModifyInternalPIUserActionFunctionPayload = {
  function: () => void;
};

export enum HbtModifyInternalPIUserActionType {
  DISMISS_MODAL,
  DISMISS_TOAST,
  ON_ERROR,
  SET_IS_MODAL_LOADING,
  SET_IS_PAGE_LOADING,
  SET_IS_LOADING,
  SHOW_MODAL,
  SUBMIT_SUCCESS,
  SHOW_UPDATE_MODAL,
  DISMISS_UPDATE_MODAL
}

export type HbtModifyInternalPIUserAction = {
  action: HbtModifyInternalPIUserActionType;
  payload?:
    | HbtModifyInternalPIUserActionValuePayload
    | HbtModifyInternalPIUserActionFunctionPayload;
};
