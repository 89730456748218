import React, { useContext, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';

import { InternalClaimStatus } from '@hobt/claim-domain';

import { Button } from 'Components/Common/Button';
import { CollapsibleCard } from 'Components/Card/CollapsibleCard';
import { PrincipalBalance } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/PrincipalBalance';
import { AssessmentCalculationsContext } from 'Feature/Claims/components/Details/AssessmentCalculations';
import { BorrowerCharge } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/BorrowerCharge';
import { CashReceipts } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/CashReceipts';
import { OtherAdjustments } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/OtherAdjustments';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

import styles from '../styles.module.scss';
import { ClaimAssessmentProps, ClaimLetterFlyoutDisplay } from './types';
import LetterComments from './LetterComments';

const POST_ADJUDICATION_APPROVED_STATUSES = [
  InternalClaimStatus.ApprovedAuto,
  InternalClaimStatus.ApprovedManual,
  InternalClaimStatus.Cancelled,
  InternalClaimStatus.ClaimPaymentInProgress,
  InternalClaimStatus.CmhcReimbursed,
  InternalClaimStatus.Declined,
  InternalClaimStatus.Paid,
  InternalClaimStatus.PaymentFailed
];

export const ClaimAssessment: React.FC<ClaimLetterFlyoutDisplay & ClaimAssessmentProps> = ({
  handleDownloadClaimCalculationLetterCallback,
  ...onDisplayStickyFooter
}) => {
  const [allCollapsed, setAllCollapsed] = useState<boolean | undefined>(false);
  const [isAllCollapsedOrExpanded, setIsAllCollapsedOrExpanded] = useState<boolean>(false);
  const [claimCalculationSideDrawer, setClaimCalculationSideDrawer] = useState<boolean>(false);

  const { claimData } = useHBTFormContext();

  const onClaimCalculationCancelForm = () => {
    setClaimCalculationSideDrawer(false);
    onDisplayStickyFooter.setDisplay(true);
  };

  const onAddClaimCalculationForm = () => {
    setClaimCalculationSideDrawer(true);
    onDisplayStickyFooter.setDisplay(false);
  };

  // const onCancelClaimSuccessSubmit = () => {
  //   setClaimCalculationSideDrawer(false);
  // };

  const toggleCollapseAll = () => {
    setAllCollapsed(!allCollapsed);
    setIsAllCollapsedOrExpanded(true);
  };

  const onCardLevelCollapsed = () => {
    setIsAllCollapsedOrExpanded(false);
  };

  const { sitecoreContent } = useContext(AssessmentCalculationsContext);

  const isRefundRequestedStatus =
    claimData?.profile?.claimStatus?.code === InternalClaimStatus.RefundRequested;
  const isClaimApproved = POST_ADJUDICATION_APPROVED_STATUSES.includes(
    claimData?.profile?.claimStatus?.code
  );

  const disabledClaimCalculationDetailButton =
    isRefundRequestedStatus === true ||
    isClaimApproved === true ||
    claimData?.calculationDetail?.documentID == null;

  const getDownloadButtonText = () => {
    if (
      claimData?.calculationDetail?.documentID != null ||
      isRefundRequestedStatus === true ||
      isClaimApproved === true
    ) {
      return sitecoreContent?.downloadCalculationButton;
    }

    return sitecoreContent?.generatingCalculationButton;
  };

  return (
    <>
      <div className={styles.headerBlock}>
        <span>
          <span className={styles.collapseToggle}>
            <Button
              text={
                allCollapsed
                  ? sitecoreContent?.expandAllBtnLabel ?? {}
                  : sitecoreContent?.collapseAllBtnLabel ?? {}
              }
              ariaText={allCollapsed ? i18n.t('Expand-All') ?? {} : i18n.t('Collapse-All') ?? {}}
              name="collapseAllButton"
              contextualButton
              icon={() => (
                <span
                  aria-hidden={true}
                  className="material-icons icon--v-align-middle"
                >{`keyboard_arrow_${allCollapsed ? 'down' : 'up'}`}</span>
              )}
              onClick={toggleCollapseAll}
              type="button"
            />
          </span>
        </span>

        <span className={styles.addCommentButton}>
          <Button
            text={sitecoreContent?.viewClaimButton ?? {}}
            ariaText={sitecoreContent?.viewClaimButton ?? {}}
            name="viewClaimButton"
            contextualButton
            icon={() => (
              <span aria-hidden={true} className="material-icons icon--v-align-middle">
                add
              </span>
            )}
            type="button"
            onClick={onAddClaimCalculationForm}
          />
          <Button
            text={getDownloadButtonText() ?? {}}
            ariaText={getDownloadButtonText() ?? {}}
            name="downloadCalculationDetailButton"
            contextualButton
            icon={() => (
              <span aria-hidden={true} className="material-icons icon--v-align-middle">
                download
              </span>
            )}
            type="button"
            disabled={disabledClaimCalculationDetailButton}
            onClick={() => handleDownloadClaimCalculationLetterCallback()}
          />
        </span>
      </div>

      <div className={styles.editableValueTables}>
        <div>
          <CollapsibleCard
            name="principalBalance"
            onCollapseToggleCallback={onCardLevelCollapsed}
            collapsed={isAllCollapsedOrExpanded ? allCollapsed : undefined}
            title={() => (
              <Text
                field={sitecoreContent?.principalBalanceCardLabels?.fields?.heading ?? {}}
                tag="h3"
              />
            )}
          >
            <PrincipalBalance />
          </CollapsibleCard>
        </div>
        <div>
          <CollapsibleCard
            name="borrowersCharge"
            onCollapseToggleCallback={onCardLevelCollapsed}
            collapsed={isAllCollapsedOrExpanded ? allCollapsed : undefined}
            title={() => (
              <Text
                field={sitecoreContent?.borrowerChargeCardLabels?.fields?.heading ?? {}}
                tag="h3"
              />
            )}
          >
            <BorrowerCharge />
          </CollapsibleCard>
        </div>
        <div>
          <CollapsibleCard
            name="cashReceipts"
            onCollapseToggleCallback={onCardLevelCollapsed}
            collapsed={isAllCollapsedOrExpanded ? allCollapsed : undefined}
            title={() => (
              <Text
                field={sitecoreContent?.cashReceiptsCardLabels?.fields?.heading ?? {}}
                tag="h3"
              />
            )}
          >
            <CashReceipts />
          </CollapsibleCard>
        </div>
        <div>
          <CollapsibleCard
            name="otherAdjustments"
            onCollapseToggleCallback={onCardLevelCollapsed}
            collapsed={isAllCollapsedOrExpanded ? allCollapsed : undefined}
            title={() => (
              <Text
                field={sitecoreContent?.otherAdjustmentCardLabels?.fields?.heading ?? {}}
                tag="h3"
              />
            )}
          >
            <OtherAdjustments />
          </CollapsibleCard>
        </div>
        <fieldset className={`${styles.sideDrawerCancelClaim}`}>
          <SideDrawer
            isActive={claimCalculationSideDrawer}
            handleOutsideClick={onClaimCalculationCancelForm}
          >
            <LetterComments onClaimCalculationCancel={onClaimCalculationCancelForm} />
          </SideDrawer>
        </fieldset>
      </div>
    </>
  );
};
