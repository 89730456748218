import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import i18n from 'i18next';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

import { checkIfDateIsAfterComparedDate, getCurrentDate } from '@hobt/utils';

import NotificationFlyoutsComponentProps, {
  NotificationRecords
} from 'Feature/Header/models/NotificationFlyoutsComponentProps';
import { useAuthenticationContext } from 'Foundation/Authentication';
import NotificationButton from 'Feature/Header/components/NotificationFlyoutsComponent/NotificationButton';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import { TabItemProps, Tabs } from 'Components/Navigation/Tabs';
import { Button } from 'Components/Common/Button';

import {
  getNotificationRequest,
  updateAllNotificationRequest,
  updateNotificationRequest
} from '../api/HeaderFunctions';
import {
  convertTimestampforNoti,
  convertTimestampToDate,
  convertTimestampToMinandHrs,
  NotificationApiResponse,
  ReadStatus,
  ReadStatusEnum,
  ReadStatusMap
} from '../../models/types';
import styles from './styles.module.scss';

const NotificationBell: React.FC<NotificationFlyoutsComponentProps> = ({
  fields,
  text,
  isActive,
  onChangeHandler
}) => {
  const authenticationContext = useAuthenticationContext();
  const [rowData, setRowData] = useState<any>();
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [hasUnread, setHasUnread] = useState<boolean>(false);
  const [lenderString, setLenderString] = useState<string>('');
  const [isTab, setIsTab] = useState<string>(ReadStatusEnum[2]);
  const [notifBodyText, setNotifBodyText] = useState<string>('');
  const [notificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [notificationData, setNotificationData] = useState<NotificationRecords[] | null>([]);

  // TODO: Send object to type.ts
  const scenarioMap = new Map<
    number,
    { contentTemplate: string; contentTitle?: string; emailSubjectLine?: string }
  >();

  const getNotificationData = async (id?: string) => {
    try {
      const res: any = await getNotificationRequest(
        authenticationContext,
        ReadStatusMap.get(id || '')
      );
      if (res.data.length >= 0) {
        setNotificationData(res.data);
        if (id === ReadStatusEnum[2]) {
          setUnreadCount(res.data?.length ?? unreadCount);
          setHasUnread(res.data?.length !== 0);
        }
      } else if (res.error.length > 0) {
        throw Error(res.error);
      }
    } catch (error) {
      console.log('err on get notif data', error);
    }
  };

  const getNotificationStatus = async () => {
    try {
      const res: any = await getNotificationRequest(authenticationContext, ReadStatus.Unread);
      if (res.data.length > 0) {
        setHasUnread(true);
      } else if (res.data.length === 0 && res.error.length === 0) {
        setHasUnread(false);
      } else if (res.error.length > 0) {
        throw Error(res.error);
      }
    } catch (error) {
      console.log('err on get notif status', error);
    }
  };

  const onUpdate = async (notifId: string): Promise<void> => {
    const result = await updateNotificationRequest(authenticationContext, notifId);
    switch (result) {
      case NotificationApiResponse.Updated:
        console.log('Updated Notification Successfully');
        break;
      default:
        console.dir('Unidentified Error on Notification Update Only');
        break;
    }
  };

  const onUpdateAll = async (): Promise<void> => {
    const result = await updateAllNotificationRequest(authenticationContext);
    switch (result) {
      case NotificationApiResponse.Updated:
        console.log('Updated All Notifications Successfully');
        break;
      default:
        console.dir('Unidentified Error on Notification Update All');
        break;
    }
  };

  if (fields.notificationScenario.fields) {
    fields.notificationScenario.fields.listItems.forEach((item) => {
      if (item.fields.serviceCode.value && item.fields.contentTemplate.value) {
        scenarioMap.set(parseInt(item.fields.serviceCode.value, 10), {
          contentTemplate: item.fields.contentTemplate.value,
          contentTitle: item.fields.contentTitle?.value || '',
          emailSubjectLine: item.fields.emailSubjectLine?.value || ''
        });
      }
    });
  }

  useEffect(() => {
    getNotificationStatus();
    const interval = setInterval(() => {
      getNotificationStatus();
    }, 120000);
    return () => clearInterval(interval);
  }, []);

  const items: TabItemProps[] = [
    {
      displayText: { value: fields.unread.value?.replace(' {messages}', '') },
      name: ReadStatusEnum[2],
      count: parseInt(unreadCount.toString(), 10)
    },
    {
      displayText: fields.read,
      name: ReadStatusEnum[1]
    },
    {
      displayText: fields.all,
      name: ReadStatusEnum[0]
    }
  ];

  const onCloseHandler = () => {
    setRowData(null);
    onChangeHandler();
    setIsTab(ReadStatusEnum[2]);
    setUnreadCount(0);
    setIsNotificationOpen(false);
  };

  const onRowClick = (dataRow: object) => {
    setRowData(dataRow);
    setIsNotificationOpen(true);
  };

  const handleNotifBody = (scenario: number) => {
    if (scenario < 1500 && scenarioMap) {
      setLenderString(
        scenarioMap
          .get(scenario)
          ?.contentTemplate.substring(
            0,
            (scenarioMap.get(scenario)?.contentTemplate || '').indexOf('}') + 1
          )
          .replace(
            '{lenderReferenceNumber}',
            rowData?.notificationElementData?.approvedLenderReferenceNumber
          )
          // Change value according to updated payload
          .replace('{cmhcAccountNumber}', rowData?.notificationElementData?.cmhcLoanAccountNumber)
          .replace('{userId}', rowData?.notificationElementData?.approvedLenderRefNumber) || ''
      );
    } else setLenderString('');

    setNotifBodyText(
      scenarioMap
        .get(scenario)
        ?.contentTemplate.replace('Lender #: {lenderReferenceNumber} ', '')
        .replace('CMHC #: {cmhcAccountNumber} ', '')
        .replace('User ID: {userId} ', '')
        .replace('{typeOfClaim}', rowData?.notificationElementData?.claimTypeCode || '')
        .replace('{cancellationNote}', rowData?.notificationElementData?.remarkText || '')
        .replace('{claimID}', rowData?.notificationElementData?.cancellationReasonCode || '')
        .replace(
          '{cancellationReason}',
          rowData?.notificationElementData?.cancellationReasonCode || ''
        )
        // TODO: Replace 'replacement' string with data upon payload update
        .replace('{fiCode}', '{fiCode}')
        .replace('{category}', '{category}')
        .replace('{transitNo}', '{transitNo}')
        .replace('{lenderCode}', '{lenderCode}')
        .replace('{claimStatus}', '{claimStatus}')
        .replace('{declineNote}', '{declineNote}')
        .replace('{approvalType}', '{approvalType}')
        .replace('{declineReason}', '{declineReason}')
        .replace('<Month and Year>', '<Month and Year>')
        .replace('{cmhcEmailAddress}', '{cmhcEmailAddress}')
        .replace('{primaryApproverName}', '{primaryApproverName}')
        .replace('{primaryApproverRole}', '{primaryApproverRole}')
        .replace('<Total Arrears Amount>', '<Total Arrears Amount>')
        .replace('{secondaryApproverName}', '{secondaryApproverName}')
        .replace('{secondaryApproverRole}', '{secondaryApproverRole}')
        .replace('{primaryApprovalDecision}', '{primaryApprovalDecision}')
        .replace('<No. of records submitted>', '<No. of records submitted>')
        .replace('{primaryDecisionTimestamp}', '{primaryDecisionTimestamp}')
        .replace('{documentationRequestNotes}', '{documentationRequestNotes}')
        .replace('{remarkText}', '{remarkText}')
        .replace('{secondaryApprovalDecision}', '{secondaryApprovalDecision}')
        .replace('{secondaryDecisionTimestamp}', '{secondaryDecisionTimestamp}')
        .replace('{listOfLendersRecordsFromCRM}', '{listOfLendersRecordsFromCRM}')
        .replace('{primaryAndSecondaryApproverRole}', '{primaryAndSecondaryApproverRole}')
        .replace('(Arrears validation error message)', '(Arrears validation error message)')
        .replace(
          '{displayTheFieldThatIsMissingPartyIdLocationID}',
          '{displayTheFieldThatIsMissingPartyIdLocationID}'
        )
        .replace(
          '<Current Reporting Month and Year of submission>',
          '<Current Reporting Month and Year of submission>'
        )
        .replace(
          '<column names with incorrect data mentioned here>',
          '<column names with incorrect data mentioned here>'
        )
        .replace(
          '<List of CMHC Loan #s with duplicates displayed below>',
          '<List of CMHC Loan #s with duplicates displayed below>'
        ) || ''
    );
  };

  useEffect(() => {
    if (rowData && rowData.notificationID) {
      if (
        isTab === ReadStatusEnum[2] ||
        (isTab === ReadStatusEnum[0] && rowData.notificationStatus === ReadStatus.Unread.toString())
      ) {
        onUpdate(rowData.notificationID);
      }
      handleNotifBody(rowData.notificationScenarioID);
    }
  }, [rowData]);

  const onTabClick = (e: TabItemProps) => {
    setIsNotificationOpen(false);
    setIsTab(e.name);
    setRowData(null);

    if (e.name === ReadStatusEnum[0]) {
      getNotificationData();
    } else getNotificationData(e.name);
  };

  const onChevronClick = () => {
    setIsNotificationOpen(false);
    setRowData(null);
  };

  return (
    <>
      <NotificationButton
        onClick={() => {
          getNotificationData(ReadStatusEnum[2]);
          onChangeHandler();
        }}
        ariaText={text.value ?? ''}
        text={text}
        hasUnread={hasUnread}
      />

      <div
        className={`${styles.notificationDrawerSize} ${styles.top} ${
          notificationOpen ? '' : `${styles.close}`
        }`}
      >
        <SideDrawer isActive={isActive} handleOutsideClick={onCloseHandler}>
          <div className={`row ${styles.notificationFlyout}`}>
            <section className="col-6">
              {/* Maybe replaced with SideDrawer later */}
              {notificationOpen && (
                <div>
                  <div className={styles.headerWrapperFlyout}>
                    {/* Change header with scenarioId Map */}
                    <Text
                      tag="h2"
                      field={{
                        value: scenarioMap.get(rowData?.notificationScenarioID)?.contentTitle
                      }}
                    />
                    <div className={styles.chevronButton}>
                      <button
                        data-testid="addAttachmentsFormChevronButton"
                        onClick={onChevronClick}
                      >
                        <span className="material-icons align-middle">chevron_right</span>
                      </button>
                    </div>
                  </div>

                  <div className={styles.smallText}>
                    <Text
                      tag="small"
                      field={{
                        value: convertTimestampforNoti(rowData?.notificationIssuedTimestamp)
                      }}
                    />
                  </div>

                  <div className={styles.bodyWrapper}>
                    {lenderString && (
                      <Text
                        field={{
                          value: lenderString
                        }}
                      />
                    )}

                    <div className={styles.bodyMessage}>
                      <RichText
                        field={{
                          value: notifBodyText
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </section>

            <aside className={`col-6 ${notificationOpen ? '' : `${styles.open}`}`}>
              <Scrollbars>
                <div>
                  <div className={styles.headerWrapperMain}>
                    <Text tag="h2" field={fields.title} />
                    <div className={styles.closeButton}>
                      <button
                        data-testid="addAttachmentsFormSyncButton"
                        onClick={() => {
                          getNotificationData(ReadStatusEnum[2]);
                          setIsTab(ReadStatusEnum[2]);
                          setIsNotificationOpen(false);
                        }}
                      >
                        <span className="material-icons align-middle">sync</span>
                      </button>
                      <button
                        type="button"
                        data-testid="addAttachmentsFormCloseButton"
                        onClick={onCloseHandler}
                        aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
                      >
                        <span className="material-icons align-middle">close</span>
                      </button>
                    </div>
                  </div>

                  <div className={`row ${styles.tabBar}`}>
                    <div className={`${styles.tabs} ${styles.alignBottomLine}`}>
                      <Tabs
                        ariaControlsName="temp"
                        items={items}
                        tabOnClickCallback={onTabClick}
                        initialActiveTabName={ReadStatusEnum[2]}
                      />
                    </div>
                    {isTab === ReadStatusEnum[2] && (
                      <div className={`${styles['action-icons-buttons']}`}>
                        <Button
                          name="markRead"
                          ariaText={fields.markAll}
                          text={fields.markAll}
                          icon={() => (
                            <span
                              className={`material-icons-outlined icon--v-align-middle ${styles['action-icons']} ${styles.readIcon}`}
                            >
                              {'drafts'}
                            </span>
                          )}
                          contextualButton
                          onClick={onUpdateAll}
                          responsive
                        />
                      </div>
                    )}
                  </div>

                  <section>
                    {notificationData &&
                      notificationData.map((item: NotificationRecords) => {
                        return (
                          <div
                            className={`row ${styles.contentRow}${
                              notificationOpen && item.notificationID === rowData.notificationID
                                ? ` ${styles.rowColour}`
                                : ' '
                            }`}
                            key={item.notificationID}
                            onClick={() => onRowClick(item)}
                            onKeyDown={() => onRowClick(item)}
                            role="button"
                            tabIndex={0}
                          >
                            <div
                              className={`${styles.icon} ${
                                (notificationOpen &&
                                  item.notificationID === rowData.notificationID) ||
                                item.notificationStatus === ReadStatus.Read
                                  ? ` ${styles.hide}`
                                  : ''
                              } `}
                            >
                              <span
                                className={`material-icons icon--v-align-middle ${styles['icon-colour']}`}
                              >
                                {`fiber_manual_record`}
                              </span>
                            </div>

                            <div className={`col-7 ${styles.contentDetails}`}>
                              <Text
                                className={`${
                                  item.notificationStatus === ReadStatus.Read
                                    ? ''
                                    : `${styles.contentHeading}`
                                }`}
                                tag="p"
                                field={{
                                  value:
                                    scenarioMap.get(item.notificationScenarioID || -1)
                                      ?.contentTitle || ''
                                }}
                              />
                              <span className={styles.contentDescription}>
                                <Text field={fields.lenderNumber} />
                                <Text
                                  field={{
                                    value:
                                      item.notificationElementData?.approvedLenderReferenceNumber
                                  }}
                                />
                              </span>
                            </div>

                            <div className={`col-3 text-right`}>
                              <Text
                                tag="p"
                                className={styles.dateTime}
                                field={{
                                  value: checkIfDateIsAfterComparedDate(
                                    item.notificationIssuedTimestamp!,
                                    getCurrentDate()
                                  )
                                    ? convertTimestampToMinandHrs(item.notificationIssuedTimestamp!)
                                    : convertTimestampToDate(item.notificationIssuedTimestamp!)
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </section>
                </div>
              </Scrollbars>
            </aside>
          </div>
        </SideDrawer>
      </div>
    </>
  );
};

export default NotificationBell;
