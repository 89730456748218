import React, { FC, useState, PropsWithChildren } from 'react';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { IndicatorProps } from 'react-select/src/components/indicators';
import { useFeature } from 'flagged';
import { Button } from 'Components/Common/Button';
import { ButtonDropdown } from 'Components/Common/ButtonDropdown';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import AttachmentsNotesCommentsDetailsProps, { DrawerType } from '../types';
import styles from './styles.module.scss';
import { selectCustomStyles } from './selectCustomStyles';

const ActionPanel = ({
  fields,
  onFlyout,
  setFilteredValue,
  isReadOnlyAccessUser
}: AttachmentsNotesCommentsDetailsProps) => {
  const {
    filterList,
    documentRequestBtn,
    addAttachmentBtn,
    sendCommentBtn,
    addNoteBtn,
    categoryLabel
  } = fields;

  const isInternal = useFeature(FeatureFlags.INTERNAL);
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState<boolean>(false);

  const Options = filterList.fields.listItems?.map((item) => ({
    label: item.fields.itemName.value || '',
    value: item.fields.itemValue.value || ''
  }));

  const filterOptions = Options?.filter?.((item) => {
    if (isInternal) {
      return item;
    }
    if (item.label !== 'Internal Notes' && item.label !== 'Note interne') {
      return item;
    }
  });

  const DropdownIndicator: FC<DropdownIndicatorProps<{ label: string; value: string }, boolean>> = (
    props: DropdownIndicatorProps<{ label: string; value: string }, boolean>
    // props: any
  ) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <span className="material-icons">arrow_drop_down</span>
        </components.DropdownIndicator>
      )
    );
  };

  const actionItems = [
    {
      name: addAttachmentBtn.value,
      text: addAttachmentBtn,
      onClickCallback: () => onFlyout(DrawerType.AddAttachmentsForm)
    },
    {
      name: documentRequestBtn.value,
      text: documentRequestBtn,
      onClickCallback: () => onFlyout(DrawerType.DocumentRequestForm)
    }
  ];

  return (
    <div className={styles.actionMenu}>
      <div
        role="button"
        onClick={(_e) => {
          setIsFilterDropdownOpen((prevIsFilterDropdownOpen) => !prevIsFilterDropdownOpen);
        }}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key === 'Enter') {
            setIsFilterDropdownOpen((prevIsFilterDropdownOpen) => !prevIsFilterDropdownOpen);
          }
        }}
      >
        <Select
          name="filterDropdown"
          id="filterDropdown"
          defaultValue={filterOptions[0]}
          options={filterOptions}
          onChange={setFilteredValue}
          menuIsOpen={isFilterDropdownOpen}
          isSearchable={false}
          styles={selectCustomStyles}
          components={{ DropdownIndicator } as any}
          aria-label={categoryLabel?.value ?? ''}
          className={styles.filterMenu}
        />
      </div>
      <div className={styles.actionButtons}>
        {isInternal && (
          <Button
            text={documentRequestBtn}
            ariaText={documentRequestBtn}
            name="documentRequestBtn"
            contextualButton
            icon={() => (
              <span
                className={`material-icons-outlined icon--v-align-middle ${styles.actionIcons}`}
              >
                description
              </span>
            )}
            onClick={() => onFlyout(DrawerType.DocumentRequestForm)}
            type="button"
            className={styles.showDesktop}
            disabled={isReadOnlyAccessUser}
          />
        )}
        <Button
          text={addAttachmentBtn}
          ariaText={addAttachmentBtn}
          name="addAttachmentBtn"
          contextualButton
          icon={() => (
            <span className={`material-icons-outlined icon--v-align-middle ${styles.actionIcons}`}>
              attach_file
            </span>
          )}
          onClick={() => onFlyout(DrawerType.AddAttachmentsForm)}
          type="button"
          className={isInternal ? styles.showDesktop : ''}
          disabled={isReadOnlyAccessUser}
        />
        {isInternal && (
          <Button
            text={addNoteBtn}
            ariaText={addNoteBtn}
            name="addNoteBtn"
            contextualButton
            icon={() => (
              <span
                className={`material-icons-outlined icon--v-align-middle ${styles.actionIcons}`}
              >
                add
              </span>
            )}
            onClick={() => onFlyout(DrawerType.AddInternalNoteForm)}
            type="button"
            disabled={isReadOnlyAccessUser}
          />
        )}
        <Button
          text={sendCommentBtn}
          ariaText={sendCommentBtn}
          name="sendCommentBtn"
          contextualButton
          icon={() => (
            <span className={`material-icons-outlined icon--v-align-middle ${styles.actionIcons}`}>
              comment
            </span>
          )}
          onClick={() => onFlyout(DrawerType.AddCommentForm)}
          type="button"
          disabled={isReadOnlyAccessUser}
        />
        {isInternal && (
          <ButtonDropdown
            toggleIcon="more_vert"
            className={`${styles.hideDesktop} ${styles.buttonDropdown}`}
            actionItems={actionItems}
            disabled={isReadOnlyAccessUser}
          />
        )}
      </div>
    </div>
  );
};

export default ActionPanel;
