import { HttpResponseStatusCodes } from '@hobt/constants';
import { ApiClient, ApiClientConfig } from 'Foundation/Api';

import { config } from '../../../../../../config';

export const useArrearsHistoryFunction = <T extends unknown>(
  authContext: any,
  apiClientConfig: ApiClientConfig
) => {
  const getArrearsHistory = async (cmhcLoanAccountNumber: string): Promise<T> => {
    const arrearsHistoryUrl = `${config.arrearsApi.urls.claim}/${cmhcLoanAccountNumber}/data`;
    const response = await ApiClient(authContext, apiClientConfig).getWithAuth(arrearsHistoryUrl);
    return response.data.statusCode === HttpResponseStatusCodes.OK && response.data.data[0];
  };
  return { getArrearsHistory };
};
