import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';

import {
  ClaimTypeCode,
  ClaimTypeIndicator,
  deficiencySaleClosingProvinces,
  detailSchema,
  LegalActionType,
  LegalSystemType,
  representativeToObtainKeyClaimType,
  PropertyOccupancyStatus
} from '@hobt/claim-domain';
// @ts-ignore
import { LanguageShort, StatisticsCanadaProvinceID } from '@hobt/constants';

import {
  currencyFormat,
  getCurrencyFormat,
  integerFormat,
  percentageFormat,
  telephoneFormat
} from 'Components/Inputs/CommonFormFieldFormats';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormYesNoRadios from 'Components/Inputs/FormYesNoRadios';
import FormDatepicker from 'Components/Inputs/FormDatepicker';
import FormNumber from 'Components/Inputs/FormNumber';
import FormText from 'Components/Inputs/FormText';
import FormTextArea from 'Components/Inputs/FormTextArea';
import FormCard from 'Components/Common/FormCard';
import DynamicHeading from 'Components/Common/DynamicHeading';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import ClaimsDetailsProps from 'Feature/Claims/models/ClaimsDetailsProps';
import { ButtonType } from 'Feature/CommonComponents/UserControls';

import { LanguageSwitchTypeCode } from '../ClaimsLenderDetails/types';

import styles from './styles.module.scss';
import { GlossaryNames } from '../../../../PageComponents/components/GlossaryComponent/types';

const OPTIONAL_DROPDOWN_FIELDS_MASTER_USER_POST_ADJUDICATION = [
  'propertyOccupancyStatusCode',
  'propertySoldByTypeCode',
  'propertyTransferredByTypeCode',
  'propertyVacatedByTypeCode'
];

const CLAIM_TYPES_WITH_LEGAL_ACTION_WALKAWAY = [
  ClaimTypeCode.EnhancedClaimService,
  ClaimTypeCode.WalkAway
];

const TaxAccountBalanceFields = (fields: ClaimsDetailsProps['fields']) => {
  const { isFieldDisabled } = useHBTFormContext();
  const { getValues } = useFormContext();

  return (
    <>
      <DynamicHeading headingLevel={3} className={styles.subHeading}>
        <Text field={fields?.taxAccountBalanceLabel} />
      </DynamicHeading>
      <FormNumber
        className={styles.halfLeft}
        label={fields?.amountLabel}
        name="detail.receivedTaxAccountBalanceAmount"
        formatProps={getCurrencyFormat(
          getValues('detail.receivedTaxAccountBalanceAmount'),
          currencyFormat,
          i18n.language as LanguageShort
        )}
        allowNegative={false}
        isReadOnly={isFieldDisabled}
      />
      <FormDropdown
        className={styles.half}
        label={fields?.typeLabel}
        name="detail.taxAccountBalanceTypeCode"
        options={fields?.taxAccountBalanceType?.fields?.listItems}
        isDisabled={isFieldDisabled}
      />
    </>
  );
};

const DeficiencySaleFields = (fields: ClaimsDetailsProps['fields']) => {
  const { getValues, watch } = useFormContext();
  const { isMasterUserEditingPostAdjudicationClaim, isFieldDisabled } = useHBTFormContext();
  const claimTypeCode = watch('detail.claimTypeCode', '');

  return (
    <>
      <FormDatepicker
        className={styles.halfLeft}
        label={fields?.deficiencySaleClosingDateLabel}
        name="detail.receivedDeficiencySaleClosingDate"
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : { when: claimTypeCode, is: [ClaimTypeCode.DeficiencySale], className: 'd-none' }
        }
        isReadOnly={isFieldDisabled}
        startYear="1960-01-02"
      />
      <FormNumber
        className={styles.half}
        label={fields?.deficiencySalePrice}
        name="detail.receivedDeficiencySalePriceAmount"
        allowNegative={false}
        formatProps={getCurrencyFormat(
          getValues('detail.receivedDeficiencySalePriceAmount'),
          currencyFormat,
          i18n.language as LanguageShort
        )}
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : { when: claimTypeCode, is: [ClaimTypeCode.DeficiencySale], className: 'd-none' }
        }
        isReadOnly={isFieldDisabled}
      />
      <FormNumber
        className={styles.halfLeft}
        label={fields?.realEstateCommisionPaid}
        name="detail.receivedDeficiencySaleCommissionAmount"
        allowNegative={false}
        formatProps={getCurrencyFormat(
          getValues('detail.receivedDeficiencySaleCommissionAmount'),
          currencyFormat,
          i18n.language as LanguageShort
        )}
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : { when: claimTypeCode, is: [ClaimTypeCode.DeficiencySale], className: 'd-none' }
        }
        isReadOnly={isFieldDisabled}
      />
      <FormDatepicker
        className={styles.half}
        label={fields?.dateOfferAcceptedLabel}
        name="detail.offerAcceptedDate"
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : { when: claimTypeCode, is: [ClaimTypeCode.DeficiencySale], className: 'd-none' }
        }
        isReadOnly={isFieldDisabled}
        startYear="1960-01-02"
      />
    </>
  );
};

const RepresentativeContactFields = (fields: ClaimsDetailsProps['fields']) => {
  const { watch } = useFormContext();
  const { isMasterUserEditingPostAdjudicationClaim, isFieldDisabled } = useHBTFormContext();
  const claimTypeCode = watch('detail.claimTypeCode', '');

  return (
    <>
      <DynamicHeading headingLevel={3} className={styles.subHeading}>
        <Text field={fields?.representativeContactLabel} />
      </DynamicHeading>
      <FormText
        className={styles.halfLeft}
        label={fields?.nameLabel}
        name="detail.representativeToObtainKey.fullName"
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : {
                when: claimTypeCode,
                is: [ClaimTypeCode.TitleTransfer, ClaimTypeCode.AssignmentOfMortgage],
                className: 'd-none'
              }
        }
        isReadOnly={isFieldDisabled}
      />
      <FormNumber
        className={styles.half}
        label={fields?.phoneNumber}
        name="detail.representativeToObtainKey.phoneNumber"
        formatProps={telephoneFormat}
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : {
                when: claimTypeCode,
                is: [ClaimTypeCode.TitleTransfer, ClaimTypeCode.AssignmentOfMortgage],
                className: 'd-none'
              }
        }
        isReadOnly={isFieldDisabled}
      />
    </>
  );
};

const ClaimsDetails = ({ fields }: ClaimsDetailsProps) => {
  const { watch, trigger, setValue, getValues, register, unregister } = useFormContext();
  const {
    claimData,
    isInEditMode,
    isFieldDisabled,
    isMasterUserEditingPostAdjudicationClaim,
    isMasterUser
  } = useHBTFormContext();
  const claimTypeCode = watch('detail.claimTypeCode', '');
  const propertyOccupancyStatusCode = watch('detail.propertyOccupancyStatusCode');
  const propertyVacatedByTypeCode = watch('detail.propertyVacatedByTypeCode');
  const typeofLegalSystem = watch('detail.legalSystemTypeCode');
  const provinceCode = watch('property.address.provinceCode');
  const propertyTransferredByTypeCode = watch('detail.propertyTransferredByTypeCode');
  const propertySoldByTypeCode = watch('detail.propertySoldByTypeCode');
  const receivedDefaultInterestRatePercentValue = watch(
    'detail.receivedDefaultInterestRatePercent'
  );
  const receivedOutstandingPrincipalBalanceAmountValue = watch(
    'detail.receivedOutstandingPrincipalBalanceAmount'
  );
  const representativePhoneNumber = watch('detail.representativeToObtainKey.phoneNumber');
  const receivedTaxAccountBalanceAmount = watch('detail.receivedTaxAccountBalanceAmount');
  const receivedDeficiencySalePriceAmount = watch('detail.receivedDeficiencySalePriceAmount');

  const receivedDeficiencySaleCommissionAmount = watch(
    'detail.receivedDeficiencySaleCommissionAmount'
  );
  const typeOfClaim = watch('claimTypeIndicator');
  const legalActionCompletedDate = watch('detail.legalActionCompletedDate');
  const legalActionTypeCode = watch('detail.legalActionTypeCode');

  const interestAdjustmentDate = watch('detail.interestAdjustmentDate');
  const receivedLastCompleteInstallmentPaidDueDate = watch(
    'detail.receivedLastCompleteInstallmentPaidDueDate'
  );

  // Add watch for "calendarDaysDeferredCount"
  const calendarDaysDeferredCount = watch('detail.calendarDaysDeferredCount');

  const [legalSystemOptions, setLegalSystemOptions] = React.useState<SitecoreListItem[]>(
    fields?.legalSystem?.fields?.listItems ?? []
  );

  // TODO: Default values are overriden on rerenders. so added this to reflect on default value for YesNo radios.
  if (getValues('detail.claimFilingExtensionObtainedFlag') == null) {
    setValue('detail.claimFilingExtensionObtainedFlag', 'false');
  }
  if (getValues('detail.contestedFlag') == null) {
    setValue('detail.contestedFlag', 'false');
  }
  if (getValues('detail.evictionFlag') == null) {
    setValue('detail.evictionFlag', 'false');
  }

  const getLegalActionOptions = () => {
    if (
      !isMasterUserEditingPostAdjudicationClaim &&
      typeofLegalSystem === LegalSystemType.CommonLaw
    ) {
      let legalActionOptions = fields?.typeofLegalAction?.fields?.listItems?.filter?.(
        (item) => Number(item?.fields?.itemValue?.value) < LegalActionType.TakingInPaymentByForce
      );

      if (claimTypeCode !== '' && !CLAIM_TYPES_WITH_LEGAL_ACTION_WALKAWAY.includes(claimTypeCode)) {
        legalActionOptions = legalActionOptions?.filter?.(
          (item) => Number(item?.fields?.itemValue?.value) !== LegalActionType.WalkAway
        );
      }
      return legalActionOptions;
    }

    if (
      !isMasterUserEditingPostAdjudicationClaim &&
      typeofLegalSystem === LegalSystemType.CivilLaw
    ) {
      let legalActionOptions = fields?.typeofLegalAction?.fields?.listItems?.filter?.(
        (item) =>
          Number(item?.fields?.itemValue?.value) === LegalActionType.WalkAway ||
          Number(item?.fields?.itemValue?.value) > LegalActionType.AssignmentOfMortgage
      );

      if (claimTypeCode !== '' && !CLAIM_TYPES_WITH_LEGAL_ACTION_WALKAWAY.includes(claimTypeCode)) {
        legalActionOptions = legalActionOptions?.filter?.(
          (item) => Number(item?.fields?.itemValue?.value) !== LegalActionType.WalkAway
        );
      }

      return legalActionOptions;
    }

    return fields?.typeofLegalAction?.fields?.listItems;
  };

  if (isMasterUserEditingPostAdjudicationClaim === true && isInEditMode) {
    // Temporarily add to list of enums to allow to skip schema validations
    OPTIONAL_DROPDOWN_FIELDS_MASTER_USER_POST_ADJUDICATION.forEach((field) => {
      if (
        detailSchema.properties?.[field]?.type?.[0] === 'number' &&
        !detailSchema.properties?.[field]?.enum?.includes(NaN)
      ) {
        detailSchema.properties?.[field]?.enum?.push(NaN);
      } else if (!detailSchema.properties?.[field]?.enum?.includes('')) {
        detailSchema.properties?.[field]?.enum?.push('');
      }
    });
  }

  useEffect(() => {
    unregister('indicator.eddFemsiCodes');
  }, []);

  useEffect(() => {
    if (isMasterUserEditingPostAdjudicationClaim === true) {
      return;
    }

    if (provinceCode) {
      if (provinceCode?.toString() === StatisticsCanadaProvinceID.Quebec) {
        setLegalSystemOptions(
          fields?.legalSystem?.fields?.listItems?.filter?.(
            (item) => Number(item?.fields?.itemValue?.value) !== LegalSystemType.CommonLaw
          )
        );
      } else {
        setLegalSystemOptions(
          fields?.legalSystem?.fields?.listItems?.filter?.(
            (item) => Number(item?.fields?.itemValue?.value) !== LegalSystemType.CivilLaw
          )
        );
      }
    }

    if (provinceCode != null && typeof provinceCode === 'number') {
      setValue('property.address.provinceCode', provinceCode.toString());
    }
  }, [provinceCode]);

  useEffect(() => {
    if (
      receivedDefaultInterestRatePercentValue != null &&
      typeof receivedDefaultInterestRatePercentValue === 'string'
    ) {
      setValue(
        'detail.receivedDefaultInterestRatePercent',
        Number(receivedDefaultInterestRatePercentValue)
      );
    }

    if (
      receivedOutstandingPrincipalBalanceAmountValue != null &&
      typeof receivedOutstandingPrincipalBalanceAmountValue === 'string'
    ) {
      setValue(
        'detail.receivedOutstandingPrincipalBalanceAmount',
        Number(receivedOutstandingPrincipalBalanceAmountValue)
      );
    }
  }, [receivedDefaultInterestRatePercentValue, receivedOutstandingPrincipalBalanceAmountValue]);

  useEffect(() => {
    if (representativePhoneNumber != null && typeof representativePhoneNumber === 'number') {
      setValue(
        'detail.representativeToObtainKey.phoneNumber',
        representativePhoneNumber.toString()
      );
    }
  }, [representativePhoneNumber]);

  useEffect(() => {
    if (
      receivedTaxAccountBalanceAmount != null &&
      typeof receivedTaxAccountBalanceAmount === 'string'
    ) {
      setValue('detail.receivedTaxAccountBalanceAmount', Number(receivedTaxAccountBalanceAmount));
    }
  }, [receivedTaxAccountBalanceAmount]);
  useEffect(() => {
    if (
      (claimTypeCode === ClaimTypeCode.TitleTransfer ||
        claimTypeCode === ClaimTypeCode.AssignmentOfMortgage) &&
      (propertyOccupancyStatusCode === null || propertyOccupancyStatusCode === undefined)
    ) {
      setValue('detail.propertyOccupancyStatusCode', '');
      setValue(
        'detail.propertyTransferredByTypeCode',
        propertyTransferredByTypeCode === null ? 1 : propertyTransferredByTypeCode
      );
    }

    if (
      claimTypeCode !== ClaimTypeCode.TitleTransfer &&
      claimTypeCode !== ClaimTypeCode.AssignmentOfMortgage &&
      propertyOccupancyStatusCode === ''
    ) {
      setValue('detail.propertyOccupancyStatusCode', null);
      setValue('detail.propertyTransferredByTypeCode', null);
    }

    if (claimTypeCode === ClaimTypeCode.DeficiencySale) {
      setValue(
        'detail.propertySoldByTypeCode',
        propertySoldByTypeCode === null ? '' : propertySoldByTypeCode
      );
    }
  }, [
    claimTypeCode,
    propertyOccupancyStatusCode,
    propertyTransferredByTypeCode,
    propertySoldByTypeCode
  ]);

  useEffect(() => {
    if (propertyOccupancyStatusCode === PropertyOccupancyStatus.Vacant) {
      setValue(
        'detail.propertyVacatedByTypeCode',
        propertyVacatedByTypeCode === null ? '' : propertyVacatedByTypeCode
      );
    } else if (
      propertyOccupancyStatusCode === PropertyOccupancyStatus.TenantOccupied ||
      propertyOccupancyStatusCode === PropertyOccupancyStatus.OccupiedByTheBorrower ||
      propertyOccupancyStatusCode === PropertyOccupancyStatus.OccupiedByARelativeOfTheBorrower
    ) {
      setValue(
        'detail.propertyVacatedByTypeCode',
        propertyVacatedByTypeCode === '' ? null : propertyVacatedByTypeCode
      );
    }
  }, [propertyOccupancyStatusCode, propertyVacatedByTypeCode]);

  useEffect(() => {
    if (
      receivedDeficiencySalePriceAmount != null &&
      typeof receivedDeficiencySalePriceAmount === 'string'
    ) {
      setValue(
        'detail.receivedDeficiencySalePriceAmount',
        Number(receivedDeficiencySalePriceAmount)
      );
    }
  }, [receivedDeficiencySalePriceAmount]);

  useEffect(() => {
    if (
      receivedDeficiencySaleCommissionAmount != null &&
      typeof receivedDeficiencySaleCommissionAmount === 'string'
    ) {
      setValue(
        'detail.receivedDeficiencySaleCommissionAmount',
        Number(receivedDeficiencySaleCommissionAmount)
      );
    }
  }, [receivedDeficiencySaleCommissionAmount]);

  useEffect(() => {
    if (legalActionCompletedDate == null) {
      setValue('detail.legalActionCompletedDate', undefined);
    }
  }, [legalActionCompletedDate]);

  useEffect(() => {
    if (legalActionTypeCode == null && typeofLegalSystem === LegalSystemType.Other) {
      setValue('detail.legalActionTypeCode', undefined);
    }
  }, [legalActionTypeCode, typeofLegalSystem]);

  useEffect(() => {
    if (
      claimData?.detail?.interestAdjustmentDate !== interestAdjustmentDate ||
      claimData?.detail?.receivedLastCompleteInstallmentPaidDueDate !==
        receivedLastCompleteInstallmentPaidDueDate
    ) {
      setValue('indicator.paymentCount', 0);
    }
  }, [interestAdjustmentDate, receivedLastCompleteInstallmentPaidDueDate]);

  const processClaimInMicsResultFlag = watch('validationResult.processClaimInMicsResultFlag');
  const cmhcLoanAccountNumberMatchFlag = watch('validationResult.cmhcLoanAccountNumberMatchFlag');
  const formCompletenessResultFlag = watch('validationResult.formCompletenessResultFlag');
  // const prope

  useEffect(() => {
    setValue(
      'validationResult.processClaimInMicsResultFlag',
      processClaimInMicsResultFlag === 'true'
    );
    setValue(
      'validationResult.cmhcLoanAccountNumberMatchFlag',
      cmhcLoanAccountNumberMatchFlag === 'true'
    );
    setValue('validationResult.formCompletenessResultFlag', formCompletenessResultFlag === 'true');
  }, [processClaimInMicsResultFlag, cmhcLoanAccountNumberMatchFlag, formCompletenessResultFlag]);
  // Update string to Number for "calendarDaysDeferredCount"
  useEffect(() => {
    if (calendarDaysDeferredCount != null && typeof calendarDaysDeferredCount === 'string') {
      setValue('detail.calendarDaysDeferredCount', Number(calendarDaysDeferredCount));
    }
  }, [calendarDaysDeferredCount]);

  return (
    <FormCard
      title={fields?.cardTitle}
      headingLevel={2}
      sectionId="claims-details-body"
      fieldToValidate="detail"
      toolTipButton={{
        id: 'claimsDetailsGlossaryTooltip',
        name: 'claimsDetailsGlossaryTooltip',
        ariaText: fields.cardGlossaryAriaText?.value ?? '',
        onClick: () => {
          fields?.openGlossary?.(GlossaryNames.ClaimDetails);
        },
        buttonType: ButtonType.TEXT
      }}
    >
      <FormDropdown
        className={`${styles.halfLeft} ${styles.pullDown}`}
        label={fields?.claimTypeLabels}
        name="detail.claimTypeCode"
        options={fields?.typeofClaim?.fields?.listItems}
        isDisabled={
          isFieldDisabled ||
          (!isMasterUser && typeOfClaim === ClaimTypeIndicator.Supplementary) ||
          claimData?.validationResult?.processClaimInMicsResultFlag === 'true'
        }
        setAsNumber={false}
      />
      <FormDropdown
        className={styles.half}
        label={fields?.propertyBeingTransferedLabel}
        name="detail.propertyTransferredByTypeCode"
        options={
          isMasterUserEditingPostAdjudicationClaim === true
            ? fields?.propertyBeingTransfered?.fields?.listItems
            : fields?.propertyBeingTransfered?.fields?.listItems?.slice(
                ...(claimTypeCode === ClaimTypeCode.TitleTransfer ? [0, 2] : [2])
              )
        }
        isDisabled={isFieldDisabled}
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : {
                when: claimTypeCode,
                is: [ClaimTypeCode.TitleTransfer, ClaimTypeCode.AssignmentOfMortgage],
                className: 'd-none'
              }
        }
      />
      <FormDropdown
        className={`${styles.halfLeft} ${styles.pullDown}`}
        label={fields?.propertySoldByLabel}
        name="detail.propertySoldByTypeCode"
        options={fields?.propertySoldBy?.fields?.listItems}
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : {
                when: claimTypeCode,
                is: [ClaimTypeCode.DeficiencySale],
                className: 'd-none'
              }
        }
        isDisabled={isFieldDisabled}
        setAsNumber={false}
      />
      <FormDatepicker
        className={`${styles.half} ${
          i18n.language === LanguageSwitchTypeCode.French ? styles.pullDown : ''
        }`.trim()}
        label={fields?.dateLenderNotification}
        name="detail.receivedLenderNotificationDate"
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : {
                when: claimTypeCode,
                is: [ClaimTypeCode.EnhancedClaimService, ClaimTypeCode.WalkAway],
                className: 'd-none'
              }
        }
        isReadOnly={isFieldDisabled}
        startYear="1960-01-02"
      />
      <FormYesNoRadios
        className={styles.halfLeft}
        label={fields?.mobileHomeLabel}
        name="detail.mobileHomeOnLeasedLandFlag"
        isReadOnly={isFieldDisabled}
      />
      <FormDropdown
        className={styles.half}
        label={fields?.additonalSecuritiesHeldLabel}
        name="detail.additionalSecuritiesHeldByBorrowerTypeCode"
        options={fields?.additonalSecuritiesHeld?.fields?.listItems}
        isDisabled={isFieldDisabled}
        optional={true}
        disableDefaultSelect={false}
      />
      <FormDropdown
        className={styles.full}
        label={fields?.typeofWarrantyLabel}
        name="detail.warrantyTypeCode"
        options={fields?.typeofWarranty?.fields?.listItems}
        isDisabled={isFieldDisabled}
        setAsNumber={false}
      />

      <FormDropdown
        className={styles.full}
        label={fields?.legalSystemLabel}
        name="detail.legalSystemTypeCode"
        options={
          isMasterUserEditingPostAdjudicationClaim === true
            ? fields?.legalSystem?.fields?.listItems
            : legalSystemOptions
        }
        isDisabled={isFieldDisabled}
      />
      <FormDropdown
        className={styles.full}
        label={fields?.typeofLegalActionLabel}
        name="detail.legalActionTypeCode"
        options={getLegalActionOptions()}
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : {
                when: typeofLegalSystem,
                is: [LegalSystemType.CommonLaw, LegalSystemType.CivilLaw],
                className: 'd-none'
              }
        }
        isDisabled={isFieldDisabled}
      />
      <FormTextArea
        className={styles.full}
        label={fields?.pleaseAddDescriptionLabel}
        name="detail.otherLegalActionTypeDescription"
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : { when: typeofLegalSystem, is: [LegalSystemType.Other], className: 'd-none' }
        }
        charLimit={60}
        textAreaHelperText={fields?.characterLimit?.value ?? ''}
        isReadOnly={isFieldDisabled}
      />
      <FormDatepicker
        className={styles.halfLeft}
        label={fields?.dateLegalActionStartedLabel}
        name="detail.legalActionStartedDate"
        isReadOnly={isFieldDisabled}
        startYear="1960-01-02"
      />
      <FormDatepicker
        className={styles.halfEnd}
        label={fields?.dateLegalActionCompletedLabel}
        name="detail.legalActionCompletedDate"
        isReadOnly={isFieldDisabled}
        startYear="1960-01-02"
      />
      {(deficiencySaleClosingProvinces.includes(provinceCode) ||
        isMasterUserEditingPostAdjudicationClaim === true) && (
        <FormDatepicker
          className={styles.halfLeft}
          label={fields?.britishColumbiaYukonSaleClosingLabel}
          name="detail.britishColumbiaYukonSaleClosingDate"
          watch={
            isMasterUserEditingPostAdjudicationClaim === true
              ? undefined
              : {
                  when: claimTypeCode,
                  is: [ClaimTypeCode.TitleTransfer],
                  className: 'd-none'
                }
          }
          isReadOnly={isFieldDisabled}
          startYear="1960-01-02"
        />
      )}

      <span className={styles.full} />
      <FormYesNoRadios
        className={styles['radio-group']}
        label={fields?.extensionObtained}
        name="detail.claimFilingExtensionObtainedFlag"
        isReadOnly={isFieldDisabled}
      />
      <FormYesNoRadios
        className={styles['radio-group']}
        label={fields?.eviction}
        name="detail.evictionFlag"
        isReadOnly={isFieldDisabled}
      />
      <FormYesNoRadios
        className={styles['radio-group-full']}
        label={fields?.contested}
        name="detail.contestedFlag"
        isReadOnly={isFieldDisabled}
      />

      <FormDatepicker
        className={`${styles.halfLeft} ${
          i18n.language === LanguageSwitchTypeCode.French ? styles.pullDown : ''
        }`.trim()}
        label={fields?.interestAdjustmentDateLabel}
        name="detail.interestAdjustmentDate"
        isReadOnly={isFieldDisabled}
        startYear="1960-01-02"
      />
      <FormNumber
        className={styles.half}
        label={fields?.interestRate}
        name="detail.receivedDefaultInterestRatePercent"
        formatProps={percentageFormat}
        allowNegative={false}
        isReadOnly={isFieldDisabled}
      />
      <FormDatepicker
        className={styles.halfLeft}
        label={fields?.dueDateLastPaidLabel}
        name="detail.receivedLastCompleteInstallmentPaidDueDate"
        isReadOnly={isFieldDisabled}
        startYear="1960-01-02"
      />
      <FormNumber
        className={styles.full}
        label={fields?.unpaidPrincipalBalance}
        name="detail.receivedOutstandingPrincipalBalanceAmount"
        formatProps={getCurrencyFormat(
          getValues('detail.receivedOutstandingPrincipalBalanceAmount'),
          currencyFormat,
          i18n.language as LanguageShort
        )}
        allowNegative={false}
        isReadOnly={isFieldDisabled}
      />
      <hr className={styles.separator} />
      <TaxAccountBalanceFields {...fields} />
      <hr className={styles.separator} />
      {(claimTypeCode === ClaimTypeCode.DeficiencySale ||
        isMasterUserEditingPostAdjudicationClaim === true) && <DeficiencySaleFields {...fields} />}
      <FormNumber
        className={styles.full}
        label={fields?.calendarDaysDeferred}
        name="detail.calendarDaysDeferredCount"
        formatProps={integerFormat}
        isReadOnly={isFieldDisabled}
      />
      {(representativeToObtainKeyClaimType.includes(claimTypeCode) ||
        isMasterUserEditingPostAdjudicationClaim === true) && (
        <>
          <hr className={styles.separator} />
          <RepresentativeContactFields {...fields} />
        </>
      )}
      <hr className={styles.separator} />
      <FormDropdown
        className={styles.halfLeft}
        label={fields?.propertyOccupancyStatusLabel}
        setAsNumber={false}
        name="detail.propertyOccupancyStatusCode"
        options={fields?.propertyOccupancyStatus?.fields?.listItems}
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : {
                when: claimTypeCode,
                is: [ClaimTypeCode.TitleTransfer, ClaimTypeCode.AssignmentOfMortgage],
                className: 'd-none'
              }
        }
        isDisabled={isFieldDisabled}
      />
      <FormDatepicker
        className={styles.half}
        label={fields?.propertyVacancyDate}
        name="detail.propertyVacancyDate"
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : {
                when: propertyOccupancyStatusCode,
                is: [PropertyOccupancyStatus.Vacant],
                className: 'd-none'
              }
        }
        isReadOnly={isFieldDisabled}
        startYear="1960-01-02"
      />
      <FormDropdown
        className={styles.halfLeft}
        label={fields?.propertyVacatedByLabel}
        setAsNumber={false}
        name="detail.propertyVacatedByTypeCode"
        options={fields?.propertyVacatedBy?.fields?.listItems}
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : {
                when: propertyOccupancyStatusCode,
                is: [PropertyOccupancyStatus.Vacant],
                className: 'd-none'
              }
        }
        isDisabled={isFieldDisabled}
      />
      <FormDropdown
        className={[1].includes(propertyOccupancyStatusCode) ? styles.half : styles.halfLeft}
        label={fields?.mortgageTypeLabel}
        name="detail.mortgageTypeCode"
        options={fields?.mortgageType?.fields?.listItems}
        isDisabled={isFieldDisabled}
      />
    </FormCard>
  );
};

export default ClaimsDetails;
