import * as React from 'react';
import { SortIndicatorProps } from 'Components/Common/DataTable/TableHeader/types';
import { SortDirection } from 'Components/Common/Api/CommonEnums';
import styles from './styles.module.scss';

export const SortIndicator: React.FC<SortIndicatorProps> = ({ direction }: SortIndicatorProps) => {
  if (direction !== undefined) {
    if (direction === SortDirection.ASC) {
      return (
        <span className={styles.sortDirectionWrapper}>
          <span className="material-icons align-self-center">arrow_drop_up</span>
        </span>
      );
    }
    if (direction === SortDirection.DESC) {
      return (
        <span className={styles.sortDirectionWrapper}>
          <span className="material-icons align-self-center">arrow_drop_down</span>
        </span>
      );
    }
  }
  return (
    <span className={`${styles.sortDirectionWrapper} ${styles.sortDirectionDefaultWrapper}`}>
      <span className="material-icons align-self-center">arrow_drop_up</span>
      <span className="material-icons align-self-center">arrow_drop_down</span>
    </span>
  );
};
