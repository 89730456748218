import i18n from 'i18next';
import DefaultSubmissionFormProps from 'Feature/DefaultSubmissionForm/models/DefaultSubmissionFormProps';

export const getfinancialObligationsProps = (props: DefaultSubmissionFormProps) => {
  const financialObligationsProps = {
    testId: 'otherFinancialObligationsTest',
    accordionLineColor: 'grey',
    linePosition: 'vertical',
    deleteIcon: props.fields.deleteIcon,
    title: {
      field: {
        value: i18n.t('DefaultSubmission-Card-OtherFinancialObligationsHeading')
      }
    },
    primaryObligation: {
      field: {
        value: i18n.t('DefaultSubmission-Card-PrimaryObligation')
      }
    },
    additionalObligation: {
      field: {
        value: i18n.t('DefaultSubmission-Card-AdditionalObligation')
      }
    },
    AddObligationText: {
      field: {
        value: i18n.t('DefaultSubmission-Card-AddAdditionalObligation')
      }
    },
    labelDelete: {
      field: {
        value: i18n.t('DefaultSubmission-Card-Delete')
      }
    },
    inputFields: {
      owingTo: {
        field: {
          value: i18n.t('DefaultSubmission-Card-OwingTo')
        }
      },
      natureOfDebt: {
        field: {
          value: i18n.t('DefaultSubmission-Card-NatureOfDebt')
        }
      },
      currentBalance: {
        field: {
          value: i18n.t('DefaultSubmission-Card-CurrentBalance')
        }
      },
      requiredMonthlyPayment: {
        field: {
          value: i18n.t('DefaultSubmission-Card-RequiredMonthlyPayment')
        }
      },
      currentArrears: {
        field: {
          value: i18n.t('DefaultSubmission-Card-CurrentArrears')
        }
      }
    }
  };

  return financialObligationsProps;
};
