import React from 'react';
import i18n from 'i18next';

import { PageCardContainer } from 'Components/PageComponents/PageCardContainer';
import { CardHeader, ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { SearchBar } from 'Components/PageComponents/SearchBar';
import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { DataTableFooter } from 'Components/Common/DataTableFooter';
import { StatusCell } from 'Components/Common/DataTable/DataTableRow/StatusCell';
import { defaultEmptyString, handleEmpty } from 'Components/Common/Api/utils/HandleEmpty';
import { RowStatus, Status } from 'Feature/Arrears/models/types';
import { useDataGridEngine } from 'Components/Hooks/DataGridEngine';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';
import { ArrearsHistoricalRecordSelector } from 'Feature/Arrears/components/ArrearsHistoricalRecords/ArrearsHistoricalRecordSelector';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';
import ArrearsHistoricalRecordsProps, { ArrearsHistoricalRecordsInventoryRecords } from './types';
import useArrearsHistoricalRecordsFunctions from './useArrearsHistoricalRecordsFunctions';
import ArrearsHistoricalFlyout from '../ArrearsHistoricalFlyout';

import { config } from '../../../../config';
import styles from './styles.module.scss';

const ArrearsHistoricalRecords = ({ fields }: ArrearsHistoricalRecordsProps) => {
  const { toCurrency } = useTranslationHelpers();

  const onApiErrorCallback = () => {
    onToastCloseCallback();
  };

  const {
    rowData,
    currentPage,
    itemsPerPage,
    onItemsPerPageChange,
    totalItems,
    totalPages,
    itemRangeFrom,
    itemRangeTo,
    isLoading: isInventoryLoading,
    onPagerClick,
    onSort,
    getSortDirectionForField,
    onSearch,
    onSearchReset,
    onSetCustomFilterParams
  } = useDataGridEngine<ArrearsHistoricalRecordsInventoryRecords>({
    apiBaseUrl: config.arrearsApi.urls.arrears,
    onApiErrorCallback,
    defaultItemsPerPage: 10
  });

  const {
    isActive,
    isLoading,
    flyoutData,
    isToastActive,
    isSubmitFailed,
    searchByFields,
    isInventoryToast,
    latestMonthRecord,
    latestYearRecord,
    onBtnClick,
    onRowClick,
    onErrorCallback,
    onSuccessCallback,
    onToastCloseCallback,
    handleOnDateSelection,
    setIsLoading
  } = useArrearsHistoricalRecordsFunctions(fields, rowData, onSetCustomFilterParams);

  const ExternalStatus: Record<RowStatus, Status> = {
    [RowStatus.ACTIVE]: { textKey: i18n.t('Global-Status-Active'), class: 'success' },
    [RowStatus.INACTIVE]: { textKey: i18n.t('Global-Status-Inactive'), class: 'grey20' }
  };

  const displayTable = latestMonthRecord != null && latestYearRecord != null;

  return (
    <>
      <ContentLoadingModal
        display={isLoading.isLoading}
        fields={{
          heading: { value: i18n.t(isLoading.spinnerHeading ?? 'Globals-Saving-Heading') },
          description: {
            value: i18n.t(isLoading.spinnerDescription ?? 'Globals-Saving-Description')
          }
        }}
      />
      <ToastNotification
        isActive={isInventoryToast}
        type={ApplicationStates.ERROR}
        title={i18n.t('Globals-Toast-Server-Error-Title')}
        content={{
          value: i18n.t('DefaultsInventoryTable-SystemErrorToast-Message')
        }}
        onCloseCallback={onToastCloseCallback}
      />
      <ToastNotification
        isActive={isToastActive}
        onCloseCallback={onToastCloseCallback}
        title={i18n.t(
          `Default${
            isSubmitFailed ? 'Submission-ErrorToast' : 'sInventoryTable-DecisioningToastMessage-'
          }Title`
        )}
        type={ApplicationStates[isSubmitFailed ? 'ERROR' : 'SUCCESS']}
        content={i18n.t(
          isSubmitFailed
            ? 'DefaultSubmission-OtherErrors'
            : 'ArrearsHistoricalFlyout-SuccessToastMessage'
        )}
      />
      <PageCardContainer name="arrearsHistoricalRecords">
        <CardHeader title={fields.title.value} tagText={fields.description.value} />

        <ArrearsHistoricalRecordSelector
          helpText={fields.selectorInstructions}
          monthLabel={fields.monthLabel}
          yearLabel={fields.yearLabel}
          selectButtonText={fields.monthYearSelectorButtonText}
          mostRecentReportMonth={latestMonthRecord}
          mostRecentReportYear={latestYearRecord}
          onSubmit={handleOnDateSelection}
        />

        <div className={styles.searchBar}>
          <SearchBar
            name="arrearsHistoricalRecordsSearchBar"
            fieldLabelAriaText={i18n.t('SearchBar-SearchField')}
            clearButtonAriaText={i18n.t('SearchBar-ClearButton')}
            searchButtonAriaText={i18n.t('SearchBar-SearchButton')}
            placeholderText={i18n.t('SearchBar-PlaceholderText')}
            searchFieldOptions={searchByFields}
            onClear={onSearchReset}
            onSearch={onSearch}
            count={totalItems}
          />
        </div>

        <div className={styles.dataTable}>
          <DataTable
            name="arrearsHistoricalRecords"
            caption={{ value: 'TestCaption' }}
            isLoading={isInventoryLoading}
            scrollOnHorizontalOverflow
          >
            <DataTableHeader>
              <DataTableHeaderItem
                name="cmhcLoanAccountNumber"
                displayText={fields.cmhcLoanNumberLabel}
              />
              <DataTableHeaderItem
                sortable
                onSortCallback={onSort}
                sortDirection={getSortDirectionForField('statusCode')}
                name="statusCode"
                displayText={fields.statusLabel}
              />
              <DataTableHeaderItem
                sortable
                onSortCallback={onSort}
                sortDirection={getSortDirectionForField('reportingPeriod')}
                name="reportingPeriod"
                displayText={fields.reportingPeriodLabel}
              />
              <DataTableHeaderItem
                name="lastCompleteInstallmentPaidDueDate"
                displayText={fields.dueDateOfLastInstallmentLabel}
              />
              <DataTableHeaderItem
                name="totalArrearsAmount"
                displayText={fields.arrearsAmountLabel}
              />
              <DataTableHeaderItem name="transitNumber" displayText={fields.transitNumberLabel} />
              <DataTableHeaderItem
                name="financialInstitutionCode"
                displayText={fields.institutionNumberLabel}
              />
              <DataTableHeaderItem name="approvedLenderCode" displayText={fields.lenderCodeLabel} />
            </DataTableHeader>

            <DataTableBody zebra>
              {displayTable &&
                rowData?.map((row: ArrearsHistoricalRecordsInventoryRecords) => (
                  <DataTableRow name={row.recordID} key={row.recordID} onClick={onRowClick}>
                    <TextCell name="cmhcLoanAccountNumber" text={row.CmhcLoanAccountNumber} />
                    <StatusCell
                      name="statusCode"
                      statusText={
                        row.status
                          ? ExternalStatus[row.status as RowStatus]?.textKey
                          : defaultEmptyString
                      }
                      className={
                        row.status ? ExternalStatus[row.status as RowStatus]?.class : 'grey20'
                      }
                    />
                    <TextCell name="reportingPeriod" text={row.reportingPeriod} />
                    <TextCell
                      name="lastCompleteInstallmentPaidDueDate"
                      text={handleEmpty(row.dueDateOfLastInstallment)}
                    />
                    <TextCell
                      name="totalArrearsAmount"
                      text={toCurrency(handleEmpty(row.arrearsAmount))}
                    />
                    <TextCell name="transitNumber" text={handleEmpty(row.transitNumber)} />
                    <TextCell
                      name="financialInstitutionCode"
                      text={handleEmpty(row.institutionNumber)}
                    />
                    <TextCell name="approvedLenderCode" text={handleEmpty(row.lenderCode)} />
                  </DataTableRow>
                ))}
            </DataTableBody>
          </DataTable>
        </div>

        <div className={styles.dataTableFooter}>
          <DataTableFooter
            name="arrearsHistoricalRecordsList"
            currentPage={currentPage}
            totalPages={totalPages || 1}
            itemsPerPageLabel={{
              value: i18n.t('DefaultsInventoryTable-ResultsPerPageDropdown-Title')
            }}
            itemsXofYText={`${itemRangeFrom || 0} - ${itemRangeTo || 0} ${i18n.t(
              'Globals-Grid-ResultsPerPageDropdown-Of'
            )}`}
            totalItems={totalItems}
            pagerClickCallback={onPagerClick}
            itemsPerPageOnChangeCallback={onItemsPerPageChange}
            itemsPerPageValue={itemsPerPage.toString()}
          />
        </div>
      </PageCardContainer>
      {fields.historicalFlyout?.fields && (
        <SideDrawer isActive={isActive} handleOutsideClick={onBtnClick}>
          <ArrearsHistoricalFlyout
            name="historicalList"
            fields={fields.historicalFlyout.fields}
            onCancelCallback={onBtnClick}
            flyoutData={flyoutData}
            onErrorCallback={onErrorCallback}
            onSuccessCallback={onSuccessCallback}
            setIsLoadingCallback={setIsLoading}
          />
        </SideDrawer>
      )}
    </>
  );
};

export default ArrearsHistoricalRecords;
