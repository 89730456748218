import React, { useContext } from 'react';
import { FormProvider } from 'react-hook-form';

import { AssessmentCalculationsContext } from 'Feature/Claims/components/Details/AssessmentCalculations';
import FormTextArea from 'Components/Inputs/FormTextArea';
import FormToggle from 'Components/Inputs/FormToggle';
import { ContextualButton } from 'Components/Common/Button';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

import {
  toastSuccess,
  toastError
} from 'Feature/CommonComponents/ContentComponents/ToastNotification/ToastNotificationHook';

import useSystemGeneratedCommentsFunction from './useSystemGeneratedCommentsFunction';

import styles from './styles.module.scss';

import { SystemCommentsProps } from './types';

function isToday(date: Date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

const SystemComments: React.FC<SystemCommentsProps> = () => {
  const { sitecoreContent } = useContext(AssessmentCalculationsContext);

  const systemCommentsContentFields =
    sitecoreContent?.letterCommentsFlyout?.fields?.systemCommentsTab?.fields;

  const {
    handleUpdate,
    // onSubmitErrors,
    data,
    onEditClick,
    onIncludeInLetterToggle,
    sortByDescendig,
    hookForm,
    onDateSortToggle
  } = useSystemGeneratedCommentsFunction(
    (index: number) => {
      if (data[index].editButton === true) {
        onEditClick(index);
      }
      toastSuccess(
        systemCommentsContentFields?.successfulUpdateToastNotification?.value ?? 'Comment Updated'
      );
    },
    () =>
      toastError(
        systemCommentsContentFields?.errorUpdateToastNotification?.value ?? 'Error Updating Comment'
      )
  );
  const { loadingStatus } = useHBTFormContext();

  React.useEffect(() => {
    if (data?.length > 0) {
      data.forEach((item, index) => {
        hookForm.setValue(`comment[${index}]`, item.fields.remark ?? '');
      });
    }
  }, [data]);

  return (
    <FormProvider {...hookForm}>
      <ContextualButton
        data-testid="systemCommentsSortDateButton"
        type="button"
        className={styles.dateSort}
        onClick={onDateSortToggle}
      >
        {systemCommentsContentFields?.dateCreated?.value ?? ''}
        <span className="material-icons icon--v-align-middle">{`keyboard_arrow_${
          sortByDescendig ? 'down' : 'up'
        }`}</span>
      </ContextualButton>
      <hr />
      {data?.length > 0 &&
        data?.map((item, index) => (
          <React.Fragment key={index}>
            <div className={styles.commentWrapper}>
              <p className={styles.commentCode}>{item.fields.code}</p>
              {item.editButton === true ? (
                <>
                  <FormTextArea
                    name={`comment[${index}]`}
                    label={item.fields.remark}
                    className={styles.commentInput}
                    charLimit={Number(500)}
                    textAreaHelperText={
                      sitecoreContent?.letterCommentsFlyout?.fields?.characterLimit?.value ?? ''
                    }
                  />
                  <div className={styles.buttonWrapper}>
                    <ContextualButton
                      data-testid={`applySystemCommentsUpdate_${index}`}
                      type="button"
                      onClick={() => handleUpdate(index)}
                      disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
                    >
                      {loadingStatus?.isLoading && loadingStatus?.isFlyout ? (
                        <span className={`material-icons align-self-center`}>loop</span>
                      ) : (
                        <>
                          <span className="material-icons align-self-center">check</span>&nbsp;
                          {systemCommentsContentFields?.applyButton?.value ?? ''}
                        </>
                      )}
                    </ContextualButton>
                    <ContextualButton
                      data-testid={`cancelSystemCommentsUpdate_${index}`}
                      type="button"
                      className={styles.cancelButton}
                      onClick={() => onEditClick(index)}
                    >
                      {systemCommentsContentFields?.cancelButton?.value ?? ''}
                    </ContextualButton>
                  </div>
                </>
              ) : (
                <>
                  <p className={styles.comment}>{item.fields.remark}</p>
                  <p title={new Date(item.fields.time).toLocaleString()}>
                    {isToday(new Date(item.fields.time))
                      ? new Date(item.fields.time)
                          .toLocaleTimeString([], {
                            hour: 'numeric',
                            minute: 'numeric'
                          })
                          .toLocaleLowerCase()
                          .replace(' ', '')
                      : new Date(item.fields.time).toLocaleDateString()}
                  </p>
                  <FormToggle
                    className={`${styles.formToggle}`}
                    onChange={() => onIncludeInLetterToggle(index)}
                    isChecked={item.fields.includedInClaimLetter}
                    id={`includeInLetterToggle_${index}`}
                    testId={`includeInLetterToggle_${index}`}
                    staticText={systemCommentsContentFields?.toggleOn?.value ?? ''}
                  />
                  <ContextualButton
                    data-testid={`systemCommentEditButton_${index}`}
                    type="button"
                    className={styles.editButton}
                    aria-label="Edit Comment"
                    onClick={() => onEditClick(index)}
                  >
                    <span className="material-icons align-self-center">edit</span>
                  </ContextualButton>
                </>
              )}
            </div>
            <hr />
          </React.Fragment>
        ))}
    </FormProvider>
  );
};

export default SystemComments;
