import React, { memo, useCallback, useEffect, useState } from 'react';
import { Modal } from 'Components/Core/Modal';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { debounce } from 'lodash';
import { config } from '../../../../../config';
import { SessionTimeOutModalBody } from './HbtSessionTimeoutModalBody';
import HbtSessionTimeoutModalComponentProps from './types';

const HbtSessionTimeoutModalComponent = (props: HbtSessionTimeoutModalComponentProps) => {
  const authenticationContext = useAuthenticationContext();

  const [secondsSinceActivity, setSecondsSinceActivity] = useState<number>(0);
  const [showSessionTimeoutModal, setShowSessionTimeoutModal] = useState<boolean>(false);

  const incrementSecondsSinceActivity = useCallback(() => {
    setSecondsSinceActivity((prevSecondsSinceActivity) => {
      if (
        prevSecondsSinceActivity ===
        parseInt(config.app.sessionLengthInSeconds!) -
          parseInt(config.app.sessionTimeoutModalCountdownLengthInSeconds!)
      ) {
        setShowSessionTimeoutModal(true);
      } else if (prevSecondsSinceActivity === parseInt(config.app.sessionLengthInSeconds!)) {
        authenticationContext.signOut();
      }
      return prevSecondsSinceActivity + 1;
    });
  }, [authenticationContext]);

  // Detect activity anywhere on the page. Activity is defined as any of
  // the events captured below.
  useEffect(() => {
    if (authenticationContext.isAuthenticated) {
      const interval = setInterval(incrementSecondsSinceActivity, 1000);
      return () => clearInterval(interval);
    }
  }, [authenticationContext, incrementSecondsSinceActivity]);

  const resetTimer = useCallback(() => setSecondsSinceActivity(0), []);

  useEffect(() => {
    const TAB_KEY = 'Tab';
    const debounceResetTimer = debounce(resetTimer, 200);
    document.addEventListener('click', debounceResetTimer);
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key === TAB_KEY) debounceResetTimer();
    });
    document.addEventListener('uploadprogress', debounceResetTimer);

    return () => {
      document.removeEventListener('click', debounceResetTimer);
      document.removeEventListener('keydown', debounceResetTimer);
      document.removeEventListener('uploadprogress', debounceResetTimer);
    };
  }, [resetTimer]);

  return (
    <Modal
      id="session-timeout-modal"
      isActive={showSessionTimeoutModal}
      title={props.fields.title}
      onDismissalCallback={() => setShowSessionTimeoutModal(false)}
      bodyContent={
        <SessionTimeOutModalBody {...props} secondsSinceActivity={secondsSinceActivity} />
      }
      modalButtons={{
        primaryButton: {
          name: props.fields.continueLabel?.value ?? 'Continue Session',
          text: props.fields.continueLabel ?? { value: 'Continue Session' },
          ariaText: props.fields.continueLabel ?? { value: 'Continue Session' },
          onClick: () => {
            setSecondsSinceActivity(0);
            setShowSessionTimeoutModal(false);
          }
        }
      }}
      shouldCloseOnBackgroundClick={true}
    />
  );
};

export default memo(HbtSessionTimeoutModalComponent);
