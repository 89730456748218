import * as React from 'react';
import { DataTableBodyProps } from './types';
import styles from './styles.module.scss';

export const DataTableBody: React.FC<DataTableBodyProps> = ({
  zebra,
  children
}: React.PropsWithChildren<DataTableBodyProps>) => (
  <tbody className={`${zebra ? styles.zebra : ''}`}>{children}</tbody>
);
