export function areObjectArraysEqual<T>(
  a: T[],
  b: T[],
  jsonReplacer?: (key: string, value: any) => any
): boolean {
  if (a == null || b == null || a.length !== b.length) {
    return false;
  }
  return a.reduce<boolean>((equal, obj, index, array) => {
    if (equal === false) {
      array.splice(0);
      return equal;
    }
    return JSON.stringify(obj, jsonReplacer) === JSON.stringify(b[index], jsonReplacer);
  }, true);
}
