import { useFeature } from 'flagged';
import i18next from 'i18next';
import i18n from 'i18next';
import React, { useState, useEffect, useRef } from 'react';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { UserRole, Module } from '@hobt/constants';

import { useDefaultFormActionsContext } from 'Feature/DefaultsInventory/components/DefaultFormContext';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { postalCodeFormat } from 'Components/Inputs/CommonFormFieldFormats';
import { TooltipIcon } from 'Components/Inputs/TooltipIcon';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

import { LinedCard, AccordionContainer } from '../../../../CommonComponents/AccordionComponents';
import { deleteCardRepopulateValues, scrollIntoView } from '../../CardReuseableFunctions';
import FormInputDropdownText from '../../FormInputDropdownText';
import FormInputTextInput, {
  Formatted as FormInputFormattedTextInput
} from '../../FormInputTextInput';
import FormInputCurrency from '../../FormInputCurrency';
import FormInputCheckbox from '../../FormInputCheckbox';
import { BorrowerTypeCode } from '../../../models/typeCode.types';
import { MortgageProps } from './MortgageObligations.types';
import './MortgageObligations.css';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const MortgageObligationsContainer: React.FC<MortgageProps> = ({
  index,
  register,
  errors,
  setValueHandler,
  control,
  watch,
  getValues,
  ...props
}) => {
  const commonCardProps = {
    register,
    errors,
    setValueHandler,
    control,
    watch,
    getValues
  };

  const [formFSections, setFormFSections] = useState({
    borrowerType: ''
  });

  const handleDropdownChange = (
    event: React.ChangeEvent<Element>,
    field: string,
    name?: string
  ) => {
    const { target } = event;
    const { value } = target as HTMLInputElement;
    setFormFSections({
      ...formFSections,
      [field]: value
    });
    name && setValueHandler && value && setValueHandler(`${name}`, value);
  };
  const defaultFormActions = useDefaultFormActionsContext();

  const streetNumber = (watch && watch(`mortgageObligation[${index}].streetNumber`)) || 0;
  const streetName = (watch && watch(`mortgageObligation[${index}].streetName`)) || 0;
  const streetType = (watch && watch(`mortgageObligation[${index}].streetType`)) || 0;
  const municipalityName = (watch && watch(`mortgageObligation[${index}].municipalityName`)) || 0;
  const provinceCode = (watch && watch(`mortgageObligation[${index}].provinceCode`)) || 0;
  const postalCode = (watch && watch(`mortgageObligation[${index}].postalCode`)) || 0;

  const borrowerTypeID = (watch && watch(`mortgageObligation[${index}].borrowerTypeID`)) || 0;
  const registeredMortgageHolder =
    (watch && watch(`mortgageObligation[${index}].registeredMortgageHolder`)) || 0;

  const currentMarketValue =
    (watch && watch(`mortgageObligation[${index}].currentMarketValue`)) || 0;
  const annualTax = (watch && watch(`mortgageObligation[${index}].annualTax`)) || 0;
  const unpaidBalance = (watch && watch(`mortgageObligation[${index}].unpaidBalance`)) || 0;
  const requiredMonthlyPaymentPrincipalInterest =
    (watch && watch(`mortgageObligation[${index}].requiredMonthlyPaymentPrincipalInterest`)) || 0;

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  useEffect(() => {
    if (defaultFormActions.isCardComplete('mortgageObligations')) {
      if (
        !(
          !!streetNumber &&
          !!streetName &&
          !!streetType &&
          !!municipalityName &&
          !!provinceCode &&
          !!postalCode &&
          !!borrowerTypeID &&
          !!registeredMortgageHolder &&
          !!currentMarketValue &&
          !!annualTax &&
          !!unpaidBalance &&
          !!requiredMonthlyPaymentPrincipalInterest
        )
      ) {
        defaultFormActions?.removeCompleteCard('mortgageObligations');
      }
    } else if (
      !!streetNumber &&
      !!streetName &&
      !!streetType &&
      !!municipalityName &&
      !!provinceCode &&
      !!postalCode &&
      !!borrowerTypeID &&
      !!registeredMortgageHolder &&
      !!currentMarketValue &&
      !!annualTax &&
      !!unpaidBalance &&
      !!requiredMonthlyPaymentPrincipalInterest
    ) {
      defaultFormActions?.addCompleteCard('mortgageObligations');
    }
  }, [
    streetNumber,
    streetName,
    streetType,
    municipalityName,
    provinceCode,
    postalCode,
    borrowerTypeID,
    registeredMortgageHolder,
    currentMarketValue,
    annualTax,
    unpaidBalance,
    requiredMonthlyPaymentPrincipalInterest
  ]);

  return (
    <div className="card_body-section assets-section-1" id={`mortgageObligations-${index || 0}`}>
      <>
        <h3 className="card__body-heading">
          {`${i18next.t('DefaultSubmission-Card-MortgageDetails')} ${index ? index + 1 : 1}`}
        </h3>
        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <FormInputTextInput
              {...commonCardProps}
              id={`${props.id}PropertyAddressStreet${index}`}
              name={`mortgageObligation[${index}].streetNumber`}
              title={props.inputFields.propertyAddressStreetNumber.field}
              cardName={props.title.field.value}
              isRequired={true}
              autoFocus={props.isAutoFocus}
              isDisabled={isReadOnlyUser}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputTextInput
              {...commonCardProps}
              id={`${props.id}PropertyAddressUnit${index}`}
              name={`mortgageObligation[${index}].unitNumber`}
              title={props.inputFields.propertyAddressUnitNumber.field}
              cardName={props.title.field.value}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <FormInputTextInput
              {...commonCardProps}
              id={`${props.id}StreetName${index}`}
              name={`mortgageObligation[${index}].streetName`}
              title={props.inputFields.streetName.field}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputTextInput
              {...commonCardProps}
              id={`${props.id}StreetType${index}`}
              name={`mortgageObligation[${index}].streetType`}
              title={props.inputFields.streetType.field}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <FormInputTextInput
              {...commonCardProps}
              id={`${props.id}StreetDirection${index}`}
              name={`mortgageObligation[${index}].streetDirection`}
              title={props.inputFields.streetDirection.field}
              cardName={props.title.field.value}
              isDisabled={isReadOnlyUser}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputTextInput
              {...commonCardProps}
              id={`${props.id}TownCity${index}`}
              name={`mortgageObligation[${index}].municipalityName`}
              title={props.inputFields.city.field}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
        <div className="row card__body-row">
          {/* Dropdown */}
          <div className="form__element form__element--2-column col-6">
            <FormInputDropdownText
              {...commonCardProps}
              id={`${props.id}Province${index}`}
              name={`mortgageObligation[${index}].provinceCode`}
              title={props.inputFields.province.field}
              options={props.provinceOptions}
              handleChange={(e) =>
                handleDropdownChange(e, 'province', `mortgageObligation[${index}].provinceCode`)
              }
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputFormattedTextInput
              {...commonCardProps}
              id={`${props.id}PostalCode${index}`}
              name={`mortgageObligation[${index}].postalCode`}
              formatProps={postalCodeFormat}
              title={props.inputFields.postalCode.field}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
        <hr className="card__divider" />
        <div className="row card__body-row mortgage-first-row">
          <div className="form__element form__element--2-column col-6">
            <FormInputDropdownText
              {...commonCardProps}
              id={`${props.id}BorrowerType${index}`}
              name={`mortgageObligation[${index}].borrowerTypeID`}
              title={props.inputFields.mortgageBorrowerType.field}
              options={[
                { label: '', value: '' },
                { label: props.borrower, value: BorrowerTypeCode.Borrower },
                { label: props.coBorrower, value: BorrowerTypeCode.CoBorrower },
                { label: props.guarantor, value: BorrowerTypeCode.Guarantor }
              ]}
              handleChange={(e) =>
                handleDropdownChange(
                  e,
                  'borrowerType',
                  `mortgageObligation[${index}].borrowerTypeID`
                )
              }
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputTextInput
              {...commonCardProps}
              id={`${props.id}RegisteredMortgageHolder${index}`}
              name={`mortgageObligation[${index}].registeredMortgageHolder`}
              title={props.inputFields.mortgageRegisteredMortgageHolder.field}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              {...commonCardProps}
              id={`${props.id}RequiredCurrentMarketValue${index}`}
              name={`mortgageObligation[${index}].currentMarketValue`}
              title={props.inputFields.mortgageCurrentMarketValue.field}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              {...commonCardProps}
              id={`${props.id}RequiredAnnualTaxes${index}`}
              name={`mortgageObligation[${index}].annualTax`}
              title={props.inputFields.mortgageRequiredAnnualTaxes.field}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              {...commonCardProps}
              id={`${props.id}UnpaidPrincipal${index}`}
              name={`mortgageObligation[${index}].unpaidBalance`}
              title={props.inputFields.mortgageUnpaidBalance.field}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              {...commonCardProps}
              id={`${props.id}RequiredMonthlyPayment${index}`}
              name={`mortgageObligation[${index}].requiredMonthlyPaymentPrincipalInterest`}
              title={props.inputFields.mortgageRequiredMonthlyPayment.field}
              cardName={props.title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          </div>
        </div>
        <div className="row card__body-row mt-3">
          <div className="form__element form__element--2-column col-6">
            <FormInputCheckbox
              {...commonCardProps}
              id={`${props.id}nationalHousingActInsured${index}`}
              name={`mortgageObligation[${index}].nationalHousingActInsured`}
              title={props.inputFields.nationalHousingActInsured.field}
              cardName={props.title.field.value}
              isDisabled={isReadOnlyUser}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            {typeof index === 'number' ? (
              <div className="col-12 card__actionButtons">
                <button
                  className="ml-4 btn btn--icon btn__delete"
                  type="button"
                  onClick={function () {
                    props.removeCard && props.removeCard(index);
                  }}
                  disabled={isReadOnlyUser || props.disableDelete}
                >
                  <span>
                    <TooltipIcon
                      icon={props.deleteIcon}
                      text={i18n.t('DefaultSubmission-Card-Delete')}
                      className={'icon-24'}
                    />
                  </span>
                </button>
              </div>
            ) : null}
          </div>
        </div>
        <hr className="card__divider card__divider--thick" />
      </>
    </div>
  );
};

export const MortgageObligations: React.FC<MortgageProps> = (props) => {
  const initialIdsArray = [];
  const cardCount = props.cardCount || 0;
  for (let i = 0; i < cardCount; i++) {
    initialIdsArray.push(i);
  }
  const [idsArray, setIdsArray] = useState<number[]>(initialIdsArray);
  const [id, setId] = useState<number>(cardCount - 1);
  const [isAutoFocus, setAutoFocus] = useState<boolean>(false);
  const [lastIndexPreDelete, setLastIndexPreDelete] = useState<number>(cardCount - 1);
  const [disableDelete, setDisableDelete] = useState(false);
  const isInitialMount = useRef(true);
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const isReadOnlyUser =
    sitecoreContext?.user?.moduleRoleMapping[Module.Default][0] === UserRole.ReadOnly;

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setIdsArray(idsArray.concat(id));
    }
  }, [id]);

  useEffect(() => {
    // update last index, after adding/deleting a card instance
    setLastIndexPreDelete(idsArray.length - 1);
    setDisableDelete(false); // re-enable delete after delete operation is completed
  }, [JSON.stringify(idsArray)]);

  function addMortgage() {
    setId(id + 1);
    setAutoFocus(true);
  }

  function removeCard(index: number) {
    // manually setting own isDirty check for card deletions on details form
    props.setCardDeleted && props.setCardDeleted(true);

    if (props.getValues && props.setValueHandler && props.watch && props.reset) {
      // prepopulate cards after delete using react-hook-form functions
      deleteCardRepopulateValues(
        props.getValues,
        props.setValueHandler,
        props.watch,
        props.reset,
        index,
        lastIndexPreDelete,
        `mortgageObligation`,
        props.register
      );
    }

    // Update cards' id array
    const numbersArrayCopy = idsArray || [];
    numbersArrayCopy.splice(index || 0, 1);
    setIdsArray(numbersArrayCopy);

    // Scroll into view after delete
    scrollIntoView(`mortgageObligations-${lastIndexPreDelete - 1}`);

    // Disable multiclick
    setDisableDelete(true);
  }

  return (
    <LinedCard
      id={props.id}
      testId={props.testId}
      linePosition={props.linePosition}
      lineColor={props.accordionLineColor}
    >
      <AccordionContainer accordionId={`${props.id}Accordion`} title={props.title.field}>
        {idsArray.map((id, index) => (
          <MortgageObligationsContainer
            {...props}
            key={id}
            index={index}
            lastIndexPreDelete={lastIndexPreDelete}
            removeCard={removeCard}
            disableDelete={disableDelete}
            setDisableDelete={setDisableDelete}
            isAutoFocus={isAutoFocus}
          />
        ))}
        <div className="row card__body-row">
          <div className="col-12">
            <button
              className="btn btn__add"
              type="button"
              onClick={addMortgage}
              disabled={isReadOnlyUser}
            >
              <i
                className="material-icons icon--size-32 icon--v-align-middle"
                title={i18next.t('DefaultSubmission-Card-AddMortgage')}
                aria-hidden={true}
              >
                add_circle_outline
              </i>
              <Text field={{ value: i18next.t('DefaultSubmission-Card-AddMortgage') }} />
            </button>
          </div>
        </div>
      </AccordionContainer>
    </LinedCard>
  );
};
