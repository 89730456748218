import axios from 'axios';
import i18n from 'i18next';
import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { FormProvider, useForm, useFormContext, UseFormReturn } from 'react-hook-form';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

import { attachmentSchema, ClaimDocumentType, draftClaimSchema } from '@hobt/claim-domain';
import {
  HttpResponseStatusCodes,
  HbtServiceErrorCodes,
  HbtFileExtensionType,
  ModuleKeyword
} from '@hobt/constants';
import { hbtResolver } from '@hobt/schema-validator';

import FormCard from 'Components/Common/FormCard';
import { CommentPopout } from 'Components/Common/CommentPopout';
import { useAttachmentClient } from 'Components/Hooks/AttachmentClient';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormTextArea from 'Components/Inputs/FormTextArea';
import { TooltipIcon } from 'Components/Inputs/TooltipIcon';
import estimateRemainingTime from 'Constants/Utils/EstimateRemainingTime';
import { ApiClient } from 'Foundation/Api';
import { ApiClientConfig } from 'Foundation/Api/models/ApiClientConfig.type';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { ButtonType } from 'Feature/CommonComponents/UserControls';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { sitecoreCardNameToValidationFieldKeyMapCreate } from 'Feature/Claims/components/Cards/DisplayNotificationCard';
import {
  useHBTFormActionsContext,
  useHBTFormContext
} from 'Feature/Claims/components/HBTFormContext';
import { GlossaryNames } from 'Feature/PageComponents/components/GlossaryComponent/types';

import { config } from '../../../../../config';
import { ClaimsAttachmentsProps, AttachmentWrapper, AttachmentData } from './types';

import styles from './styles.module.scss';

let attachmentCounter = 0;
const elapsedUploadTimes: number[] = [];
const isAttachmentUploading: boolean[] = [];
const AllowedFileTypes = [
  HbtFileExtensionType.CSV,
  HbtFileExtensionType.XLSX,
  HbtFileExtensionType.XLSM,
  HbtFileExtensionType.XLSB,
  HbtFileExtensionType.XLTX,
  HbtFileExtensionType.XLTM,
  HbtFileExtensionType.XLS,
  HbtFileExtensionType.XLT,
  HbtFileExtensionType.XML,
  HbtFileExtensionType.XLAM,
  HbtFileExtensionType.XLA,
  HbtFileExtensionType.XLW,
  HbtFileExtensionType.XLR,
  HbtFileExtensionType.PDF,
  HbtFileExtensionType.JPG,
  HbtFileExtensionType.DOC,
  HbtFileExtensionType.DOCX,
  HbtFileExtensionType.TXT,
  HbtFileExtensionType.PNG,
  HbtFileExtensionType.JPEG
];

const ClaimsAttachments = (props: ClaimsAttachmentsProps) => {
  const { claimData, isFieldDisabled } = useHBTFormContext();

  const cardId = 'claim-attachment';
  const [attachments, setAttachments] = useState<AttachmentWrapper[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number[]>([]);
  const [uploadTrigger, setUploadTrigger] = useState(false);
  const [showDescriptionById, setShowDescriptionById] = useState(-1);
  const [showMaxFilesError, setShowMaxFilesError] = useState(false);

  const [showAttachmentToast, setShowAttachmentToast] = useState(false);
  const [attachmentToastTitle, setAttachmentToastTitle] = useState('');
  const [attachmentToastMsg, setAttachmentToastMsg] = useState('');

  const apiClientConfig: ApiClientConfig = { timeout: config.documentApi.requestTimeout };
  const authContext = useAuthenticationContext();

  const { getDownloadDocumentUrl } = useAttachmentClient(ModuleKeyword.Claim, authContext);

  const { setValue, register } = useFormContext();
  register('attachments');

  const methods = useForm({
    resolver: hbtResolver(attachmentSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  } as Record<string, any>);

  const attachmentType = methods.watch('documentTypeCode');
  const attachmentDescription = methods.watch('description', '');
  const numberOfAttachments = draftClaimSchema.properties?.attachments?.maxItems as number;
  const getAttachmentTypeName = (attachmentTypeSelected: string): string => {
    const attachmentTypeObj = props.fields.attachmentType[0].fields.listItems?.find(
      (option) => option.fields.itemValue.value === attachmentTypeSelected
    );
    return attachmentTypeObj?.fields.itemName.value ?? '';
  };

  const { addCompleteCard, removeCompleteCard } = useHBTFormActionsContext();

  const formContext = useFormContext();
  const formValid = formContext.formState.isValid;

  useEffect(() => {
    if (attachments.length > 0) {
      addCompleteCard(sitecoreCardNameToValidationFieldKeyMapCreate.claimsAttachments);
    } else {
      removeCompleteCard(sitecoreCardNameToValidationFieldKeyMapCreate.claimsAttachments);
    }
  }, [formValid, attachments]);

  const updateAttachmentData = (wrappedAttachments: AttachmentWrapper[]) => {
    const attachmentsData: AttachmentData[] = [];
    wrappedAttachments
      ?.filter?.((attachmentWrapper: AttachmentWrapper) => attachmentWrapper.isUploaded)
      .forEach((attachment: AttachmentWrapper) => {
        attachmentsData.push({
          documentID: attachment.documentID,
          description: attachment.attachmentDescription,
          documentTypeCode: attachment.attachmentType,
          fileName: attachment.fileName,
          fileByteCount: attachment.fileSize,
          uploadStatusCode: attachment.documentTransferStatus
        });
      });
    setValue('attachments', attachmentsData);
  };

  useEffect(() => {
    attachmentCounter = 0;
    const wrappedAttachments: AttachmentWrapper[] = claimData?.attachments?.map(
      (attachment: AttachmentData) => {
        attachmentCounter += 1;
        return {
          documentID: attachment.documentID,
          attachmentDescription: attachment.description,
          attachmentType: attachment.documentTypeCode,
          fileName: attachment.fileName,
          fileSize: Number(attachment.fileByteCount),
          documentTransferStatus: attachment.uploadStatusCode,
          id: attachmentCounter,
          attachmentTypeName: getAttachmentTypeName(attachment.documentTypeCode),
          isUploaded: true
        };
      }
    );

    setAttachments(wrappedAttachments ?? []);
    setValue('attachments', claimData?.attachments ?? []);
  }, [claimData?.attachments]);

  useEffect(() => {
    attachments
      ?.filter?.(
        (attachmentWrapper: AttachmentWrapper) =>
          !attachmentWrapper.isUploaded &&
          !attachmentWrapper.isError &&
          !isAttachmentUploading[attachmentWrapper.id]
      )
      .forEach((attachmentWrapper: AttachmentWrapper) => {
        const formData = new FormData();
        const cancelTokenSource = axios.CancelToken.source();

        formData.append(
          'uploadedFileObject',
          attachmentWrapper.attachment ?? '',
          attachmentWrapper.fileName
        );
        formData.append('description', attachmentDescription);
        formData.append('documentTypeCode', getAttachmentTypeName(attachmentType));
        formData.append('attachmentType', attachmentType);
        formData.append('cmhcLoanAccountNumber', claimData.cmhcLoanAccountNumber);

        elapsedUploadTimes[attachmentWrapper.id] = 0;
        setInterval(() => {
          elapsedUploadTimes[attachmentWrapper.id] += 1;
        }, 1000);
        isAttachmentUploading[attachmentWrapper.id] = true;

        ApiClient(authContext, apiClientConfig)
          .postWithAuth(config.claimApi.urls.upload, formData, {
            cancelToken: cancelTokenSource.token,
            onUploadProgress: (progressEvent: ProgressEvent) => {
              setUploadProgress((prevUploadProgress: number[]) => {
                const uploadProgressCopy = [...prevUploadProgress];
                uploadProgressCopy[attachmentWrapper.id] =
                  (progressEvent.loaded / attachmentWrapper.fileSize) * 100;
                return uploadProgressCopy;
              });
            }
          })
          .then((response: any) => {
            const attachmentIndex = attachments?.findIndex(
              (attachmentWrapperCopy) => attachmentWrapper.id === attachmentWrapperCopy.id
            );
            if (attachmentIndex !== -1) {
              attachments[attachmentIndex].isUploaded = true;
              // Don't actually need to set this back to false; it's just to be semantically tidy
              isAttachmentUploading[attachmentWrapper.id] = false;
              attachments[attachmentIndex].documentID = response.data.data[0].documentId;
              attachments[attachmentIndex].documentLink = response.data.data[0].documentLink;
              attachments[attachmentIndex].documentTransferStatus =
                response.data.data[0].documentTransferStatus;
              delete attachments[attachmentIndex].attachment; // don't need this anymore; only need the document ID
            }
            setAttachments(attachments);

            methods.reset();
            methods.setValue('documentTypeCode', '');
            methods.setValue('description', '');
            methods.setValue('attachmentType', '');
            methods.setValue('cmhcLoanAccountNumber', '');
            updateAttachmentData(attachments);
          })
          .catch((error: any) => {
            const attachmentIndex = attachments?.findIndex(
              (attachmentWrapperCopy) => attachmentWrapper.id === attachmentWrapperCopy.id
            );
            if (
              error.response?.status === HttpResponseStatusCodes.BadRequest &&
              error.response?.data?.error?.errorCode ===
                HbtServiceErrorCodes.HBT_ERR_FILE_NAME_SPECIAL_CHARACTERS.code
            ) {
              setAttachmentToastTitle(
                i18n.t('DefaultActions-SystemPartialInactivateErrorToast-Action-Title')
              );
              setAttachmentToastMsg(i18n.t('Errors-HBT_ERR_6013'));
              setShowAttachmentToast(true);
            }
            if (attachmentIndex !== -1) {
              attachments[attachmentIndex].isError = true;
              isAttachmentUploading[attachmentWrapper.id] = false;
              if (error?.response?.data?.error != null) {
                const errorMessage = i18n.t(`Errors-${error.response.data.error.errorCode}`);
                if (errorMessage !== `Errors-${error.response.data.error.errorCode}`) {
                  // Replaces {maxSize} with the size if {maxSize} is present
                  // in the error message; otherwise replace() does nothing
                  attachments[attachmentIndex].errorMessage = errorMessage.replace(
                    '{maxSize}',
                    String(
                      parseInt(
                        config.documentApi.maxFileSize ? config.documentApi.maxFileSize : '',
                        10
                      ) /
                        1024 ** 2
                    )
                  );
                  attachments[attachmentIndex].errorMessage = errorMessage.replace(
                    '{fileName}',
                    attachments[attachmentIndex].fileName
                  );
                } else {
                  attachments[attachmentIndex].errorMessage = i18n.t('Errors-HBT_ERR_DEFAULT');
                }
              } else {
                attachments[attachmentIndex].errorMessage = i18n.t('Errors-HBT_ERR_DEFAULT');
              }
            }
            setAttachments(attachments);
            setUploadProgress([]);
          });
      });
  }, [uploadTrigger]);

  let errorMessage = '';

  const checkFile = (size: number, name: string) => {
    // check size
    if (size > parseInt(config.documentApi.maxFileSize!, 10)) {
      errorMessage = i18n
        .t('Errors-HBT_ERR_6004')
        .replace('{maxSize}', String(parseInt(config.documentApi.maxFileSize!, 10) / 1024 ** 2));
      return true;
    }

    // check type
    if (
      AllowedFileTypes !== undefined &&
      AllowedFileTypes.length > 0 &&
      !AllowedFileTypes.includes(
        name
          .slice((Math.max(0, name.lastIndexOf('.')) || Infinity) + 1)
          .toLowerCase() as HbtFileExtensionType
      )
    ) {
      errorMessage = i18n.t('Errors-HBT_ERR_6003');
      return true;
    }
    return false;
  };
  const handleAttachmentUpload = (rawAttachments: File[]) => {
    isAttachmentUploading[attachmentCounter] = false;
    const newAttachments = rawAttachments?.map((attachment: File) => {
      attachmentCounter += 1;
      return {
        attachment,
        attachmentType,
        attachmentTypeName: getAttachmentTypeName(attachmentType),
        attachmentDescription,
        isUploaded: false,
        id: attachmentCounter,
        fileName: attachment.name,
        fileSize: attachment.size,
        isError: checkFile(attachment.size, attachment.name),
        errorMessage,
        documentID: '',
        documentLink: '',
        documentTransferStatus: 0
      };
    });

    setAttachments(attachments.concat(newAttachments));

    // We pull this trigger to indicate that a file is being uploaded. The
    // value of the trigger is irrelevant; the point is that the value
    // changes, triggering useEffect(). We chose to make the trigger a
    // boolean and toggle it back and forth, but anything else would
    // have done equally well.
    setUploadTrigger(!uploadTrigger);
  };

  const handleAttachmentDrop = (rawAttachments: File[]) => {
    const totalAttachmentCount = attachments.length + rawAttachments.length;
    if (rawAttachments.length > 0) {
      if (attachmentType == null || attachmentType === '') {
        // @ts-ignore
        methods.setError('documentTypeCode', {
          type: 'manual',
          message: i18n.t('Required')
        });
      } else if (
        attachmentType === ClaimDocumentType.Miscellaneous &&
        attachmentDescription === ''
      ) {
        // @ts-ignore
        methods.setError('description', {
          type: 'manual',
          message: i18n.t('Required')
        });
      } else if (totalAttachmentCount > numberOfAttachments) {
        setShowMaxFilesError(true);
      } else {
        setShowMaxFilesError(false);
        handleAttachmentUpload(rawAttachments);
      }
    }
  };

  const handleAttachmentInput = (event: any) => {
    const totalAttachmentCount = attachments.length + event.target.files.length;
    if (event.target.files.length < 1) {
      return;
    }
    if (attachmentType == null || attachmentType === '') {
      // @ts-ignore
      methods.setError('documentTypeCode', {
        type: 'manual',
        message: i18n.t('Required')
      });
    } else if (attachmentType === ClaimDocumentType.Miscellaneous && attachmentDescription === '') {
      // @ts-ignore
      methods.setError('description', {
        type: 'manual',
        message: i18n.t('Required')
      });
    } else if (totalAttachmentCount > numberOfAttachments) {
      setShowMaxFilesError(true);
    } else {
      setShowMaxFilesError(false);
      handleAttachmentUpload(
        Object.keys(event.target.files)?.map((key: string) => event.target.files[key])
      );
    }
    (document.getElementById(`${cardId}UploadFiles`) as HTMLInputElement).value = '';
  };

  const deleteAttachment = (attachmentId: number, documentId: string, loanNumber: number) => {
    if (documentId === '' || documentId == null) {
      const newAttachments = attachments?.filter?.(
        (attachmentWrapper: AttachmentWrapper) =>
          attachmentWrapper != null && attachmentWrapper.id !== attachmentId
      );
      setAttachments(newAttachments);
    } else if (loanNumber == null) {
      setAttachmentToastMsg(i18n.t('DefaultSubmission-BadRequest'));
      setShowAttachmentToast(true);
    } else {
      const removeClaimDocumentUrl: string = config.claimApi.urls.removeClaimDocument
        .replace('{cmhcLoanAccountNumber}', String(loanNumber))
        .replace('{documentID}', String(documentId));

      ApiClient(authContext, apiClientConfig)
        .deleteWithAuth(removeClaimDocumentUrl)
        .then(() => {
          const newAttachments = attachments?.filter?.(
            (attachmentWrapper: AttachmentWrapper) =>
              attachmentWrapper && attachmentWrapper.id !== attachmentId
          );
          setAttachments(newAttachments);
          updateAttachmentData(newAttachments);
        })
        .catch((error) => {
          if (error.response?.status === HttpResponseStatusCodes.ServerError) {
            setAttachmentToastMsg(i18n.t('DefaultSubmission-OtherErrors'));
            setShowAttachmentToast(true);
          }
        });
    }
  };

  const handleCancelUpload = (attachmentId: number, documentId: string, loanNumber: number) => {
    isAttachmentUploading[attachmentId] = false;
    deleteAttachment(attachmentId, documentId, loanNumber);
  };

  const downloadAttachment = async (documentID: string) => {
    if (documentID == null) {
      setAttachmentToastTitle(i18n.t('Errors-HBT_VAL_ERR_20107'));
      setAttachmentToastMsg(i18n.t('Errors-HBT_VAL_ERR_20105'));
      setShowAttachmentToast(true);
    } else if (claimData.draftClaimID != null || claimData.draftClaimRecordID != null) {
      const claimID = claimData.draftClaimID ?? claimData.draftClaimRecordID;
      try {
        const documentResponse = await getDownloadDocumentUrl(claimID, documentID);

        if (documentResponse.status === HttpResponseStatusCodes.OK) {
          const downloadElement = document.createElement('a');
          downloadElement.setAttribute(
            'href',
            documentResponse.data.data.documentEntityJson.documentLink
          );
          downloadElement.click();
          downloadElement.remove();
        }
      } catch (error) {
        // @ts-ignore
        if (error?.response?.status === HttpResponseStatusCodes.ServerError) {
          setAttachmentToastTitle(i18n.t('Errors-HBT_VAL_ERR_20107'));
          setAttachmentToastMsg(i18n.t('Errors-HBT_VAL_ERR_20105'));
          setShowAttachmentToast(true);
        }
      }
    }
  };

  return (
    <>
      <ToastNotification
        type={ApplicationStates.ERROR}
        isActive={showAttachmentToast}
        title={attachmentToastTitle}
        content={attachmentToastMsg}
        onCloseCallback={() => setShowAttachmentToast(false)}
      />
      <FormCard
        title={props.fields.cardTitle}
        headingLevel={2}
        sectionId="claims-attachments-body"
        fieldToValidate="attachments"
        toolTipButton={{
          id: 'attachmentGlossaryTooltip',
          name: 'attachmentGlossaryTooltip',
          ariaText: props.fields.cardGlossaryAriaText?.value ?? '',
          onClick: () => {
            props?.fields?.openGlossary?.(GlossaryNames.Attachments);
          },
          buttonType: ButtonType.TEXT,
          disabled: isFieldDisabled
        }}
      >
        {attachments.length > 0 &&
          attachments.some(
            (attachmentWrapper: AttachmentWrapper) => attachmentWrapper.isUploaded
          ) && (
            <table className={styles.attachmentsTable}>
              <tbody>
                <tr>
                  <th
                    className={[
                      styles.attachmentsTableHeaderCell,
                      styles.attachmentsTableType
                    ].join(' ')}
                  >
                    <Text field={props.fields.attachmentTypeLabel} />
                  </th>
                  <th
                    className={[
                      styles.attachmentsTableHeaderCell,
                      styles.attachmentsTableDescription
                    ].join(' ')}
                  >
                    <Text field={props.fields.description} />
                  </th>
                  <th
                    className={[
                      styles.attachmentsTableHeaderCell,
                      styles.attachmentsTableFileName
                    ].join(' ')}
                  >
                    <Text field={props.fields.fileName} />
                  </th>
                  <th
                    className={[
                      styles.attachmentsTableHeaderCell,
                      styles.attachmentsTableAction
                    ].join(' ')}
                  ></th>
                </tr>
              </tbody>
              {attachments.length > 0 &&
                attachments?.map((attachmentWrapper: AttachmentWrapper, index: number) => {
                  return !attachmentWrapper.isUploaded ? null : (
                    <React.Fragment key={attachmentWrapper.id}>
                      <tbody>
                        <tr key={index * 3}>
                          <td
                            className={[
                              styles.attachmentsTableBodyCell,
                              styles.attachmentsTableType
                            ].join(' ')}
                          >
                            {attachmentWrapper.attachmentTypeName}
                          </td>
                          <td
                            className={[
                              styles.attachmentsTableBodyCell,
                              styles.attachmentsTableDescription
                            ].join(' ')}
                          >
                            <div
                              className={styles.attachementDescriptionContainer}
                              onClick={() => {
                                setShowDescriptionById(attachmentWrapper.id);
                              }}
                              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                                if (e.key === 'Enter') {
                                  setShowDescriptionById(attachmentWrapper.id);
                                }
                              }}
                              role="button"
                              tabIndex={0}
                            >
                              <Image field={props.fields.viewIcon} className="icon-16" />
                              <Text
                                className={styles.attachementDescriptionViewText}
                                field={props.fields.view}
                              />
                            </div>
                            <CommentPopout
                              isActive={showDescriptionById === attachmentWrapper.id}
                              title={props.fields.description.value}
                              className={styles.attachmentCommentPopout}
                              content={() => <>{attachmentWrapper.attachmentDescription}</>}
                              onCloseCallback={() => {
                                setShowDescriptionById(-1);
                              }}
                            />
                          </td>
                          <td
                            className={[
                              styles.attachmentsTableBodyCell,
                              styles.attachmentsTableFileName
                            ].join(' ')}
                          >
                            <span
                              className={styles.attachmentDownloadLink}
                              onClick={() => downloadAttachment(attachmentWrapper.documentID)}
                              onKeyDown={(e: React.KeyboardEvent) => {
                                if (e.key === 'Enter') {
                                  downloadAttachment(attachmentWrapper.documentID);
                                }
                              }}
                              role="button"
                              tabIndex={0}
                            >
                              {attachmentWrapper.fileName}
                            </span>
                          </td>
                          <td
                            className={[
                              styles.attachmentsTableBodyCell,
                              styles.attachmentsTableAction
                            ].join(' ')}
                          >
                            <div>
                              <button
                                className="btn btn--icon btn__delete"
                                data-testid="deleteBtn"
                                onClick={(e: React.MouseEvent) => {
                                  e.preventDefault();
                                  deleteAttachment(
                                    attachmentWrapper.id,
                                    attachmentWrapper.documentID,
                                    claimData.cmhcLoanAccountNumber
                                  );
                                }}
                                tabIndex={0}
                              >
                                <TooltipIcon
                                  icon={props.fields.deleteIcon}
                                  text={i18n.t('IconAlt-delete')}
                                  className="icon-16"
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </React.Fragment>
                  );
                })}
            </table>
          )}

        <FormProvider {...methods}>
          <form id="attachmentForm" className={styles.formContainer} noValidate>
            <p className={styles.subTitleText}>{props.fields.subtitleCard.value}</p>
            <FormDropdown
              className={styles.full}
              label={props.fields.attachmentTypeLabel}
              name="documentTypeCode"
              options={props.fields.attachmentType[0].fields.listItems}
              isDisabled={isFieldDisabled}
              setAsNumber={false}
            />
            <FormTextArea
              className={styles.full}
              label={props.fields.description}
              name="description"
              textAreaHelperText={props.fields.characterLimit.value}
              charLimit={Number(props.fields.limitNumber.value)}
              isReadOnly={isFieldDisabled}
            />

            <div className={styles.full}>
              <Dropzone
                aria-labelledby={`${cardId}DropFilesLabel`}
                data-testid="handleAttachmentDrop"
                onDrop={handleAttachmentDrop}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps({
                      onClick: (e) => {
                        e.stopPropagation();
                      }
                    })}
                    className={styles.attachmentInputArea}
                  >
                    <Image
                      field={props.fields.uploadIcon}
                      className={styles.attachmentInputAreaIcon}
                      aria-hidden="true"
                    />
                    <div>
                      <label htmlFor={`${cardId}UploadFiles`} id={`${cardId}UploadFilesLabel`}>
                        <Text field={props.fields.uploadMessage} />
                        <input
                          {...getInputProps()}
                          type="file"
                          id={`${cardId}UploadFiles`}
                          aria-labelledby={`${cardId}UploadFilesLabel`}
                          data-testid="handleAttachmentInput"
                          name="filename"
                          className={styles.attachmentInputAreaManualUpload}
                          onChange={handleAttachmentInput}
                          multiple
                          readOnly={isFieldDisabled}
                        />
                        <span className={styles.attachmentInputAreaUploadLabel}>
                          {props.fields.chooseMessage.value}
                        </span>
                      </label>
                    </div>
                    <div className={styles.attachmentInputAreaSupportedText}>
                      <Text field={props.fields.supportedFormats} />
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </form>
        </FormProvider>

        {attachments.length > 0 &&
          attachments?.map(
            ({
              id,
              documentID,
              isUploaded,
              isStaged,
              fileName,
              isError,
              errorMessage: attachmentErrorMessage
            }: AttachmentWrapper) =>
              !isUploaded &&
              (isError ? (
                <div className={styles.full}>
                  <div className={styles.uploadProgressBarError}>
                    <div className={styles.uploadProgressBarErrorMain}>
                      <div className={styles.uploadProgressBarErrorIcon}>
                        <Image field={props.fields.warningIcon} className="icon-24" />
                      </div>
                      <div className={styles.uploadProgressBarErrorFileName}>{fileName}</div>
                      <div className={styles.uploadProgressBarErrorErrorMessage}>
                        {i18n.t('Errors-HBT_VAL_ERR_20106')}
                        {attachmentErrorMessage}
                      </div>
                      <div className={styles.uploadProgressBarErrorCancel}>
                        <Image
                          field={props.fields.deleteIcon}
                          className="icon-24"
                          onClick={() =>
                            deleteAttachment(id, documentID, claimData.cmhcLoanAccountNumber)
                          }
                        />
                      </div>
                    </div>
                    <div className={styles.uploadProgressBarErrorBottomBorder}></div>
                  </div>
                </div>
              ) : (
                <div key={id * 10} className={styles.full}>
                  <div className={styles.uploadProgressBar}>
                    <div className={styles.uploadProgressBarMain}>
                      <div className={styles.uploadProgressBarText}>
                        {isStaged
                          ? fileName
                          : `${props.fields.uploadingMessage.value} ${fileName}...`}
                      </div>
                      <div className={styles.uploadProgressBarPercentage}>
                        {Number.isNaN(uploadProgress[id]) ? 0 : Math.round(uploadProgress[id])} % -{' '}
                        {estimateRemainingTime(uploadProgress[id], elapsedUploadTimes[id])}
                      </div>
                      <div className={styles.uploadProgressBarCancel}>
                        {isStaged ? (
                          <Image
                            field={props.fields.deleteIcon}
                            className="icon-24"
                            onClick={() =>
                              deleteAttachment(id, documentID, claimData.cmhcLoanAccountNumber)
                            }
                          />
                        ) : (
                          <span
                            className="material-icons-outlined"
                            onClick={() =>
                              handleCancelUpload(id, documentID, claimData.cmhcLoanAccountNumber)
                            }
                            onKeyDown={() =>
                              handleCancelUpload(id, documentID, claimData.cmhcLoanAccountNumber)
                            }
                            role="button"
                            tabIndex={0}
                          >
                            cancel
                          </span>
                        )}
                      </div>
                      <div
                        className={styles.uploadProgressBarProgressFill}
                        style={{ width: `${Math.round(uploadProgress[id])}%` }}
                      ></div>
                    </div>
                    <div
                      className={styles.uploadProgressBarProgressBorder}
                      style={{ width: `${Math.round(uploadProgress[id])}%` }}
                    ></div>
                  </div>
                </div>
              ))
          )}

        {showMaxFilesError && (
          <p className={styles.maxFilesError}>
            {i18n
              .t('Errors-HBT_VAL_ERR_20104')
              .replace('{maxNumberOfFiles}', String(numberOfAttachments))}
          </p>
        )}

        <p>
          <span className={styles.noteAttachmentHeading}>{props.fields.noteHeading.value}</span>
          <span className={styles.noteAttachmentText}>{props.fields.note.value}</span>
        </p>
      </FormCard>
    </>
  );
};

export default ClaimsAttachments;
