import React, { FC, memo, ReactElement, useEffect, useRef, useState } from 'react';
import { Image, ImageFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { ResponsiveImageProps } from './types';

const ResponsiveImage: FC<ResponsiveImageProps> = ({
  desktopImage,
  tabletImage,
  responsiveClassName,
  onLoadingChange
}): ReactElement => {
  const desktopImagePath = desktopImage?.value as ImageFieldValue;
  const tabletImagePath = tabletImage?.value as ImageFieldValue;
  const altText = desktopImage?.value as ImageFieldValue;
  const [isLoading, setImageLoad] = useState(true);
  const onLoadHandlerSet = useRef(false);

  useEffect(() => {
    if (onLoadHandlerSet.current) {
      onLoadingChange(isLoading);
    }
  }, [isLoading, onLoadingChange]);

  useEffect(() => {
    onLoadHandlerSet.current = true;
  }, []);

  return (
    <picture>
      <source srcSet={tabletImagePath?.src} media="(max-width: 1024px)" />
      <source srcSet={desktopImagePath?.src} media="(min-width: 1025px)" />
      <Image
        field={desktopImagePath}
        alt={altText?.alt}
        className={responsiveClassName}
        loading="lazy"
        onLoad={() => setImageLoad(false)}
      />
    </picture>
  );
};

export default memo(ResponsiveImage);
