import * as React from 'react';
import { memo } from 'react';
import i18n from 'i18next';
import { ButtonProps, ButtonType } from './Button.types';

// eslint-disable-next-line react/display-name
export const Button: React.FC<ButtonProps> = memo((props: ButtonProps) => {
  return (
    <button
      id={props.id}
      className={`btn
        ${props.buttonType === ButtonType.PRIMARY ? 'btn-primary ' : ''}
        ${props.buttonType === ButtonType.SECONDARY ? 'btn-secondary ' : ''}
        ${
          props.buttonType === ButtonType.CONTEXTUAL
            ? 'btn-contextual icon-button btn-outline '
            : ''
        }
        ${props.isIcon ? 'btn-icon ' : ''}
        ${props.isDropDown ? 'btn-dropdown ' : ''}
        ${props.additionalStyles ? props.additionalStyles : ''}`}
      aria-label={`${props.ariaText ? props.ariaText : props.name}`}
      onClick={props.onClick}
      onMouseDown={props.onMouseDown}
      disabled={props.disabled}
      name={props.name}
      type={props.type}
      tabIndex="0"
      {...props.otherProps}
    >
      <div className="btn__content-wrapper">
        {props.icon && (
          <i
            aria-hidden={true}
            className={`material-icons icon--size-${
              props.iconSize ? props.iconSize : 16
            } icon--v-align-middle`}
          >
            {props.icon}
          </i>
        )}
        <span className="button__text">{props.displayText}</span>
        {props.isDropDown && (
          <i
            className="material-icons icon-size-16 icon--v-align-middle"
            style={{ marginTop: '-4px', marginLeft: '3px' }}
            aria-hidden={true}
          >
            arrow_drop_down
          </i>
        )}
      </div>
    </button>
  );
});
