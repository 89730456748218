import React from 'react';

export enum TagType {
  INTERACTIVE = 'interactive',
  STATIC = 'static'
}

export interface TagProps {
  id?: string;
  label: string;
  variant: TagType;
  statusIndicator?: string;
  leadingIcon?: React.JSX.Element;
  onDelete?: (identifier?: string) => void;
}

export interface TagGroupProps {
  tags: TagProps[];
}
