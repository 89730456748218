import { FC } from 'react';
import { ReactComponent as IconAddCircleOutlined } from './icons/AddCircleOutlined.svg';
import { ReactComponent as IconArrears } from './icons/Arrears.svg';
import { ReactComponent as IconArrowDown } from './icons/ArrowDown.svg';
import { ReactComponent as IconArrowUp } from './icons/ArrowUp.svg';
import { ReactComponent as IconAttachment } from './icons/Attachment.svg';
import { ReactComponent as IconCheckboxChecked } from './icons/CheckboxCheckedIcon.svg';
import { ReactComponent as IconCheckboxDisabledChecked } from './icons/CheckboxDisabledChecked.svg';
import { ReactComponent as IconCheckboxDisabledUnchecked } from './icons/CheckboxDisabledUnchecked.svg';
import { ReactComponent as IconCheckboxError } from './icons/CheckboxError.svg';
import { ReactComponent as IconCheckbox } from './icons/CheckboxIcon.svg';
import { ReactComponent as IconCheckboxIndeterminate } from './icons/CheckboxIndetermine.svg';
import { ReactComponent as IconCheckboxDisabledIndeterminate } from './icons/CheckboxIndetermineDisabled.svg';
import { ReactComponent as IconCheckCircle } from './icons/CheckCircle.svg';
import { ReactComponent as IconCheckCircleGreen } from './icons/CheckGreenCircle.svg';
import { ReactComponent as IconChevronDown } from './icons/ChevronDown.svg';
import { ReactComponent as IconChevronLeft } from './icons/ChevronLeft.svg';
import { ReactComponent as IconChevronRight } from './icons/ChevronRight.svg';
import { ReactComponent as IconChevronUp } from './icons/ChevronUp.svg';
import { ReactComponent as IconClearCircle } from './icons/ClearCircle.svg';
import { ReactComponent as IconClose } from './icons/Close.svg';
import { ReactComponent as IconCloudUpload } from './icons/CloudUpload.svg';
import { ReactComponent as IconCloudUploadGrey } from './icons/CloudUploadGrey.svg';
import { ReactComponent as IconDecline } from './icons/Decline.svg';
import { ReactComponent as IconDelete } from './icons/Delete.svg';
import { ReactComponent as IconDocument } from './icons/Document.svg';
import { ReactComponent as IconEdit } from './icons/Edit.svg';
import { ReactComponent as IconEllipses } from './icons/Ellipses.svg';
import { ReactComponent as IconEmailLender } from './icons/EmailLender.svg';
import { ReactComponent as IconError } from './icons/Error.svg';
import { ReactComponent as IconErrorOutlined } from './icons/ErrorOutlined.svg';
import { ReactComponent as IconExternalLink } from './icons/ExternalLink.svg';
import { ReactComponent as IconFilter } from './icons/Filter.svg';
import { ReactComponent as IconIndeterminate } from './icons/IndeterminateIcon.svg';
import { ReactComponent as IconInfoOutlined } from './icons/InfoOutlined.svg';
import { ReactComponent as IconMenu } from './icons/Menu.svg';
import { ReactComponent as IconMoreHorizontal } from './icons/MoreHorizontal.svg';
import { ReactComponent as IconNotificationNew } from './icons/NotificationNew.svg';
import { ReactComponent as IconNotificationNone } from './icons/NotificationNone.svg';
import { ReactComponent as IconPerson } from './icons/Person.svg';
import { ReactComponent as IconPersonOutlined } from './icons/PersonOutlined.svg';
import { ReactComponent as IconSearch } from './icons/Search.svg';
import { ReactComponent as IconSortDefault } from './icons/SortDefault.svg';
import { ReactComponent as IconSortDown } from './icons/SortDown.svg';
import { ReactComponent as IconSortUp } from './icons/SortUp.svg';
import { ReactComponent as IconStatusFilled } from './icons/StatusFilled.svg';
import { ReactComponent as IconBlueStatus } from './icons/StatusIcons/BlueStatus.svg';
import { ReactComponent as IconGreenStatus } from './icons/StatusIcons/GreenStatus.svg';
import { ReactComponent as IconGreyStatus } from './icons/StatusIcons/GreyStatus.svg';
import { ReactComponent as IconOrangeStatus } from './icons/StatusIcons/OrangeStatus.svg';
import { ReactComponent as IconRedStatus } from './icons/StatusIcons/RedStatus.svg';
import { ReactComponent as IconWarning } from './icons/Warning.svg';

const iconSvgMapping: { [key: string]: FC } = {
  icon_add_circle_outlined: IconAddCircleOutlined,
  icon_arrears: IconArrears,
  icon_arrow_down: IconArrowDown,
  icon_arrow_up: IconArrowUp,
  icon_attachment: IconAttachment,
  icon_blue_status: IconBlueStatus,
  icon_check_circle: IconCheckCircle,
  icon_check_circle_green: IconCheckCircleGreen,
  icon_checkbox_checked: IconCheckboxChecked,
  icon_checkbox_default: IconCheckbox,
  icon_checkbox_disabled: IconCheckboxDisabledChecked,
  icon_checkbox_disabled_indeterminate: IconCheckboxDisabledIndeterminate,
  icon_checkbox_disabled_unchecked: IconCheckboxDisabledUnchecked,
  icon_checkbox_error: IconCheckboxError,
  icon_checkbox_indeterminate: IconCheckboxIndeterminate,
  icon_chevron_down: IconChevronDown,
  icon_chevron_left: IconChevronLeft,
  icon_chevron_right: IconChevronRight,
  icon_chevron_up: IconChevronUp,
  icon_clear_circle: IconClearCircle,
  icon_close: IconClose,
  icon_cloud_upload: IconCloudUpload,
  icon_cloud_upload_grey: IconCloudUploadGrey,
  icon_decline: IconDecline,
  icon_delete: IconDelete,
  icon_document: IconDocument,
  icon_edit: IconEdit,
  icon_email_lender: IconEmailLender,
  icon_error: IconError,
  icon_error_outlined: IconErrorOutlined,
  icon_external_link: IconExternalLink,
  icon_filter: IconFilter,
  icon_green_status: IconGreenStatus,
  icon_grey_status: IconGreyStatus,
  icon_info_outlined: IconInfoOutlined,
  icon_menu: IconMenu,
  icon_ellipses: IconEllipses,
  icon_more_horizontal: IconMoreHorizontal,
  icon_notification_new: IconNotificationNew,
  icon_notification_none: IconNotificationNone,
  icon_orange_status: IconOrangeStatus,
  icon_person: IconPerson,
  icon_person_outlined: IconPersonOutlined,
  icon_red_status: IconRedStatus,
  icon_search: IconSearch,
  icon_sort_default: IconSortDefault,
  icon_sort_down: IconSortDown,
  icon_sort_up: IconSortUp,
  icon_warning: IconWarning,
  icon_status_filled: IconStatusFilled,
  icon_indeterminate: IconIndeterminate
};

export default iconSvgMapping;
