import React, { memo } from 'react';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import HbtSessionTimeoutModalPlaceHolderProps from './types';

const HbtSessionTimeoutModalPlaceHolderComponent: React.FC<
  HbtSessionTimeoutModalPlaceHolderProps
> = ({ rendering }: HbtSessionTimeoutModalPlaceHolderProps) => {
  const sitecoreContextFactory = useSitecoreContext();

  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  return (
    <>
      {sitecoreContext.isPortfolioSite ? (
        <Placeholder name="hbt-shared-v2-session-timeout-modal" rendering={rendering} />
      ) : (
        <Placeholder name="hbt-shared-session-timeout-modal" rendering={rendering} />
      )}
    </>
  );
};

export default memo(HbtSessionTimeoutModalPlaceHolderComponent);
