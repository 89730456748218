import React from 'react';
import { useFeature } from 'flagged';

import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import FormCard from 'Components/Common/FormCard';
import ClaimsIndicatorDetailsFields from 'Feature/Claims/components/IndicatorDetailsFields';
import { ButtonType } from 'Feature/CommonComponents/UserControls';
import ItmFlagsTable from 'Feature/Claims/components/Details/ITMFlagsTable';
import { GlossaryNames } from 'Feature/PageComponents/components/GlossaryComponent/types';

import ClaimsIndicatorDetailsAdjudicationProps from './types';
import { useHBTFormContext } from '../../HBTFormContext';

const ClaimsIndicatorDetailsAdjudication = ({
  fields
}: ClaimsIndicatorDetailsAdjudicationProps) => {
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);
  const { isFieldDisabled } = useHBTFormContext();
  return (
    <>
      {siteTypeIsInternal && (
        <FormCard
          title={fields.cardTitle}
          headingLevel={2}
          sectionId="claims-indicator-details-body"
          fieldToValidate="indicator"
          toolTipButton={{
            id: 'indicatorDetailsGlossaryTooltip',
            name: 'indicatorDetailsGlossaryTooltip',
            ariaText: fields.cardGlossaryAriaText?.value ?? '',
            onClick: () => {
              fields?.openGlossary?.(GlossaryNames.IndicatorDetails);
            },
            buttonType: ButtonType.TEXT
          }}
        >
          <ClaimsIndicatorDetailsFields fields={fields} />
          <ItmFlagsTable fields={fields} />
        </FormCard>
      )}
    </>
  );
};

export default ClaimsIndicatorDetailsAdjudication;
