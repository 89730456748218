import React, { memo, useCallback, useMemo, useState } from 'react';
import {
  Box,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Paper,
  Popper,
  Typography,
  Skeleton
} from '@mui/material';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import { Language, LanguageSwitchProps } from 'Components/Core/Header/types';
import useEnterOrSpaceKey from 'Components/Hooks/UseEnterOrSpaceKey';
import i18n from 'i18next';
import styles from './styles.module.scss';

const LanguageSwitch: React.FC<LanguageSwitchProps> = ({
  open,
  anchorRef,
  isSkeletonLoaderRequired,
  language,
  isMediumUp,
  popperModifiers = [
    {
      name: 'offset',
      options: {
        offset: [140, 42]
      }
    }
  ],
  onToggle,
  onChange
}) => {
  // We need fallback value for language names in case of missing translations or i18n is not initialized
  const LanguageNames: Record<Language, string[]> = {
    [Language.EN]: ['English', 'English', 'Select Language'],
    [Language.FR]: ['French', 'Français', 'Sélectionnez votre langue']
  };
  const [announcement, setAnnouncement] = useState('');

  const linkStyles = useMemo(
    () => `${styles.link} ${!isMediumUp ? styles.tabletLink : ''}`,
    [isMediumUp]
  );

  const handleToggle = useCallback(() => {
    onToggle(!open);
  }, [open]);

  const handleLanguageChange = useCallback(
    (lang: Language) => {
      onChange(lang);
      setAnnouncement(`${i18n.t('LanguageSwitchDropDownAccessibility')} ${LanguageNames[lang][1]}`);
    },
    [onChange, LanguageNames]
  );

  return (
    <>
      {!isSkeletonLoaderRequired || (isSkeletonLoaderRequired && language) ? (
        <ClickAwayListener onClickAway={() => onToggle(false)}>
          <div>
            <Box
              className={linkStyles}
              tabIndex={0}
              onClick={handleToggle}
              onKeyDown={useEnterOrSpaceKey(handleToggle)}
              ref={anchorRef}
            >
              <HbtIcon
                size={HbtIconSize.MEDIUM}
                type={open ? 'icon_chevron_up' : 'icon_chevron_down'}
              />
              <Typography
                variant="body1"
                className={styles.linkText}
                data-testid="current-language"
              >
                <span aria-hidden="true">{language.toUpperCase()}</span>
                <span className={styles.hidden}>
                  {i18n.t('LanguageSwitchDropDownAccessibility') + LanguageNames[language][1]}
                </span>
              </Typography>
            </Box>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              placement="bottom-end"
              className={styles.dropdownMenu}
              modifiers={popperModifiers}
              disablePortal={true}
              data-testid="language-menu-list"
            >
              <Paper className={styles.menuFrame}>
                <Box className={styles.menuHeaderWrapper}>
                  <Typography variant="h6" className={styles.menuHeader}>
                    {i18n.t('SelectLanguage') ?? LanguageNames[language][2]}
                  </Typography>
                </Box>
                <MenuList className={styles.menuList}>
                  {Object.values(Language).map((lang) => (
                    <MenuItem
                      className={styles.menuItem}
                      key={lang}
                      tabIndex={0}
                      data-testid="language-menu-item"
                      onClick={() => handleLanguageChange(lang)}
                    >
                      {i18n.t(`Language-Dropdown-Label-${LanguageNames[lang][0]}`) ??
                        LanguageNames[lang][1]}
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </Popper>
          </div>
        </ClickAwayListener>
      ) : (
        <Skeleton
          sx={{
            display: 'flex',
            height: '24px',
            width: '110px',
            borderRadius: '4px',
            backgroundColor: '#F0F1F3',
            color: '#F0F1F3'
          }}
          animation={false}
        />
      )}
      <div aria-live="polite" aria-atomic="true" className={styles.hidden}>
        {announcement}
      </div>
    </>
  );
};

export default memo(LanguageSwitch);
