import React, { useContext } from 'react';
import i18n from 'i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { Tabs } from 'Components/Navigation/Tabs';
import { AssessmentCalculationsContext } from '../..';

import useLetterComments from './useLetterComments';
import { LetterCommentsProps } from './types';
import styles from './styles.module.scss';

const LetterComments: React.FC<LetterCommentsProps> = ({ onClaimCalculationCancel }: any) => {
  const { cancelFlyout, tabItems, onTabClick, renderTabContent } =
    useLetterComments(onClaimCalculationCancel);

  const { sitecoreContent } = useContext(AssessmentCalculationsContext);

  return (
    <div className={styles.scrollBar}>
      <div className={styles.claimFormHeader} data-testid={`claimForm`}>
        <Text
          className={`${styles.textHeader} ${styles.header}`}
          tag="h2"
          field={sitecoreContent?.letterCommentsFlyout?.fields?.formTitle ?? { value: '' }}
        />
        <div className={styles.closeButton}>
          <button
            type="button"
            data-testid="addClaimLetterCloseButton"
            onClick={cancelFlyout}
            aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
          >
            <span className="material-icons align-self-center">close</span>
          </button>
        </div>
      </div>
      <div className={styles.tabs}>
        <Tabs
          items={tabItems}
          initialActiveTabName="UserComments"
          tabOnClickCallback={onTabClick}
          ariaControlsName="temp"
        />
      </div>
      <div className={`${styles.claimCalculationFormBody}`}>
        <div className={styles.tabContent} id="tabContent">
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default LetterComments;
