import React, { memo, useCallback, useMemo, useState } from 'react';
import { ModuleMapping, UserRole, Module } from '@hobt/constants';
import { useMediaQuery, useTheme } from '@mui/material';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { FlyoutContainer } from 'Components/Core/Flyout/FlyoutContainer';
import HeaderActionLink from 'Components/Core/Header/HeaderActionLink';
import Menu from 'Components/Core/Menu';
import { MenuItem } from 'Components/Core/Menu/types';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import i18n from 'i18next';
import { HbtHeaderSideMenuProps } from './types';

const HbtHeaderSideMenu: React.FC<HbtHeaderSideMenuProps> = ({
  text,
  sideMenuItems,
  logoutLabel
}) => {
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'));

  const authenticationContext = useAuthenticationContext();
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const user = useMemo(() => sitecoreContext?.user, []);
  const [isActive, setIsActive] = useState(false);

  const userModuleAccess = useMemo(
    () =>
      user != null && user.moduleRoleMapping != null
        ? Object.keys(user.moduleRoleMapping).map((itm) => parseInt(itm, 10))
        : undefined,
    [user]
  );

  const moduleMap: { [key: string]: number } = {
    piFileShare: ModuleMapping.piFileShare,
    piPurposeTest: ModuleMapping.piPurposeTest,
    piRequest: ModuleMapping.piRequest,
    user: ModuleMapping.admin
  };

  const globalMenuAccesses: { [key: string]: boolean } = {
    portfolioInsurance: sitecoreContext.isPortfolioSite,
    support: sitecoreContext.isPortfolioSite
  };

  const sideMenuClickHandler = useCallback(
    (e?: React.MouseEvent | React.KeyboardEvent) => {
      if (e) {
        e.stopPropagation();
        setIsActive(!isActive);
      }
    },
    [isActive]
  );

  const filterParentMenuItems = useCallback(
    ({ fields: { name, navItem } }: MenuItem): boolean => {
      const parentItemName = name?.value;
      if (navItem && navItem.value.href?.indexOf(i18n.language) === -1) {
        navItem.value.href = `/${i18n.language}${navItem.value.href}`;
      }
      return !!(parentItemName != null ? globalMenuAccesses[parentItemName] : undefined);
    },
    [user, globalMenuAccesses]
  );

  const filterSubMenuItems = useCallback(
    (subMenu: MenuItem[]): MenuItem[] => {
      if (!userModuleAccess || userModuleAccess.length === 0) return [];

      return subMenu.filter((subItem) => {
        const itemName = subItem.fields.name?.value;
        if (subItem.fields.navItem.value.href?.indexOf(i18n.language) === -1) {
          subItem.fields.navItem.value.href = `/${i18n.language}${subItem.fields.navItem.value.href}`;
        }

        if (
          itemName != null &&
          userModuleAccess?.findIndex((access) => access === moduleMap[itemName]) >= 0
        ) {
          // check user lenderAdministrator or cmhcAdministrator
          if (
            user !== null &&
            moduleMap[itemName] === Module.Admin &&
            isUserInRoles(Module.Admin, [UserRole.CmhcAdministrator], user.moduleRoleMapping) ===
              false &&
            isUserInRoles(Module.Admin, [UserRole.LenderAdministrator], user.moduleRoleMapping) ===
              false
          ) {
            return false;
          }
          return true;
        }
      });
    },
    [userModuleAccess, user, moduleMap]
  );

  const processedSubMenuItems = useCallback(
    (menuItem: MenuItem): MenuItem => {
      const filteredSubMenu = filterSubMenuItems(menuItem.fields.subMenu ?? []);
      return {
        ...menuItem,
        fields: {
          ...menuItem.fields,
          subMenu: filteredSubMenu
        }
      };
    },
    [filterSubMenuItems]
  );

  const processedSideMenuItems = useMemo(
    () =>
      (sideMenuItems: MenuItem[]): MenuItem[] =>
        sideMenuItems.filter(filterParentMenuItems).map(processedSubMenuItems),
    [sideMenuItems, filterParentMenuItems, processedSubMenuItems]
  );

  return (
    <>
      <HeaderActionLink
        iconType="icon_menu"
        isMediumUp={isMediumUp}
        text={text?.value ?? 'Menu'}
        onToggle={sideMenuClickHandler}
      />
      <FlyoutContainer
        id="header-menu-flyout-container"
        isHeaderRequired={false}
        open={isActive}
        content={
          <Menu.InFlyout
            hasBackIcon={false}
            hasChevron={true}
            headerText={text}
            isLogoutOptionRequired={authenticationContext.isAuthenticated}
            logoutLabel={logoutLabel}
            items={processedSideMenuItems(sideMenuItems)}
            onClickClose={sideMenuClickHandler}
            onNavLinkClick={sideMenuClickHandler}
            onClickLogout={authenticationContext.signOut}
          />
        }
      />
    </>
  );
};

export default memo(HbtHeaderSideMenu);
