import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { SideMenuButtonProps } from 'Feature/Header/components/SideMenu/SideMenuButton/types';

import styles from './styles.module.scss';

export const SideMenuButton: React.FC<SideMenuButtonProps> = ({
  onClick,
  text
}: SideMenuButtonProps) => (
  <button className={styles.sideMenuButton} onClick={onClick} aria-label={text.value ?? ''}>
    <svg
      className={styles.menuIcon}
      width="30px"
      height="18px"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <g strokeWidth="0.16rem" stroke="#282828FF" strokeLinecap="round">
        <line x1="4" x2="18" y1="2" y2="2" />
        <line x1="4" x2="24" y1="8" y2="8" />
        <line x1="4" x2="18" y1="14" y2="14" />
      </g>
    </svg>
    <Text tag="p" field={text} />
  </button>
);
