import i18n from 'i18next';
import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { EnumDictionary } from 'Feature/CommonComponents/Enums/EnumDictionary';
import { Button, ButtonType } from 'Feature/CommonComponents/UserControls';

import { ToastNotificationProps } from './ToastNotification.types';

export const ToastNotification: React.FC<ToastNotificationProps> = ({
  title,
  isActive,
  type,
  content,
  onCloseCallback
}) => {
  let timeOut: number | undefined;

  useEffect(() => {
    if (isActive) {
      const stringContent = typeof content === 'string' ? content : content && content.value;
      if (stringContent && stringContent.replace(/ /g, '').length <= 150) {
        timeOut = setTimeout(() => {
          onCloseCallback();
        }, 20000) as unknown as number;
      }
    }

    // clear timeout on unmount to prevent the callback from running later
    return () => {
      if (isServer() === false) {
        if (timeOut != null) {
          clearTimeout(timeOut);
        }
      }
    };
  }, [isActive]);

  const toastNotificationType: EnumDictionary<ApplicationStates, string> = {
    [ApplicationStates.ERROR]: 'error_outline',
    [ApplicationStates.SUCCESS]: 'check_circle_outline',
    [ApplicationStates.WARNING]: 'warning',
    [ApplicationStates.DEFAULT]: 'announcement',
    [ApplicationStates.INACTIVE]: 'pause_circle_outline',
    [ApplicationStates.CANCEL]: 'error_outline'
  };

  return (
    <CSSTransition
      in={isActive}
      timeout={450}
      classNames="toast-notification"
      mountOnEnter={true}
      unmountOnExit={true}
    >
      <div className="toast-notification">
        <div className="toast-notification__wrapper">
          <div className="toast-notification__wrapper__icon">
            <i className="material-icons icon--size-24 icon--v-align-middle">
              {toastNotificationType[type]}
            </i>
          </div>
          <div className="toast-notification__wrapper__content" role="alert">
            <div className="toast-notification__wrapper__content__title">
              <h2>{title}</h2>
            </div>
            {content && (
              <div className="toast-notification__wrapper__content__text">
                {typeof content === 'string' ? <p>{content}</p> : <RichText field={content} />}
              </div>
            )}
          </div>
          <div className="toast-notification__close-button">
            <Button
              id="buttonModalClose"
              name="modal-close-button"
              buttonType={ButtonType.TEXT}
              icon="close"
              isIcon={true}
              onClick={onCloseCallback}
              iconSize={16}
            ></Button>
            <label className="sr-only">{i18n.t('DefaultCloseToast')}</label>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
