import React from 'react';
import { TrendValueCell } from 'Components/Common/DataTable/DataTableRow/TrendValueCell';
import { TrendableProps } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/TrendableCell/types';
import { Trend } from 'Components/Common/DataTable/DataTableRow/TrendValueCell/types';

export const TrendableCell: React.FC<TrendableProps> = ({
  adjusted,
  received,
  acceptable,
  name
}: TrendableProps) => {
  let trend = Trend.NEUTRAL;
  let arrowDirection = Trend.NEUTRAL;

  if (adjusted != null && received != null && acceptable != null) {
    if (adjusted !== received) {
      const adjustedGreaterThanOthers: boolean = adjusted > received && adjusted > acceptable;
      trend = adjustedGreaterThanOthers ? Trend.NEGATIVE : Trend.NEUTRAL;
      arrowDirection = adjustedGreaterThanOthers ? Trend.POSITIVE : Trend.NEGATIVE;
    }
  }

  return <TrendValueCell {...{ name, trend, arrowDirection }} />;
};
