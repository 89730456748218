import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';
import { BreadcrumbItemProps } from './type';

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({ item, isLastItem }) => {
  return (
    <div
      className={`${styles.breadcrumbItem} ${item.isCurrentPage ? styles.currentPage : ''}`}
      aria-label={item.label}
      data-testid="breadcrumb-item"
    >
      {!isLastItem ? (
        <NavLink
          className={styles.breadcrumbLink}
          to={item.href ?? '#'}
          data-testid="menuLink"
          aria-label={item.label}
        >
          {item.label}
        </NavLink>
      ) : (
        <div className={styles.currentPageLabel} tabIndex={0}>
          {item.label}
        </div>
      )}
    </div>
  );
};

export default memo(BreadcrumbItem);
