import * as React from 'react';
import { ToggleProps } from './types';
import styles from './styles.module.scss';

const FormToggle: React.FC<ToggleProps> = ({
  onChange,
  isChecked,
  id,
  className,
  testId,
  staticText,
  dynamicText,
  isDisabled
}) => {
  return (
    <div className={`${styles.switch} ${className ?? ''}`.trim()} id={testId}>
      <div className={styles.input}>
        <div>
          <label className={styles.label} id={`${id}Label`} htmlFor={`${id}Input`}>
            <input
              onChange={onChange}
              checked={isChecked === true}
              className={styles.inputCheckbox}
              type="checkbox"
              data-testid={testId}
              id={`${id}Input`}
              aria-labelledby={`${id}Label`}
              {...(isDisabled != null && { disabled: isDisabled })}
            />
            {dynamicText && (isChecked === true ? dynamicText[0] : dynamicText[1])}
            {staticText}
            <fieldset className={styles.slider} disabled={isDisabled} />
          </label>
        </div>
      </div>
    </div>
  );
};

export default FormToggle;
