import { ImageProps } from '@sitecore-jss/sitecore-jss-react/types/components/Image';

import { FinalClaim } from '@hobt/claim-domain';
import {
  ApprovalDecisionCode,
  ApprovalLevelIndicator,
  HttpResponseStatusCodes
} from '@hobt/constants';

import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';

export interface ApprovalComponentPropsFields {
  name: FieldValue;
  heading: FieldValue;
}

export interface ApprovalComponentProps {
  fields: ApprovalComponentPropsFields;
}

export default interface ApprovalFormProps {
  isActive: boolean;
  approvalId?: string;
  isArrears?: boolean;
  isClaimApproved?: boolean;
  approverLevel?: number;
  loadingStatus?: Spinner;
  fields: ApprovalFormPropsFields;
  setIsLoadingCallback?: React.Dispatch<React.SetStateAction<Spinner>>;
  sideMenuClickHandler: (e?: React.MouseEvent) => void;
  onSubmitCallback?: (updatedClaimData?: FinalClaim) => void;
}

export interface ApprovalFormPropsFields {
  name: FieldValue;
  title: FieldValue;
  submitBtn: FieldValue;
  cancelBtn: FieldValue;
  level1Label: FieldValue;
  level2Label: FieldValue;
  formContent: ApprovalComponentProps[];
  titleToastMessageAuthorization: FieldValue;
  ToastMessageAuthorization: FieldValue;
  iconError: ImageProps;
}

export enum LevelIndicator {
  LevelOne = 1,
  LevelTwo = 2
}

export enum ApprovalsReview {
  Approve = 1,
  NeedsReview = 2
}

export enum ApprovalsSubmitResponse {
  Error,
  Success,
  BadRequest,
  ClaimMinimumRole,
  ArrearsMinimumRole,
  ServerError,
  NotAuthorized,
  BehalfOfOther,
  FileUploadError,
  SameUserApproval,
  MissingAttachment,
  ProbationaryUserNotAuthorized,
  MissingPayeeDetails
}

export type ErrorEntity = {
  errorCode?: any;
  errorMessage?: string;
};

export type DataObject = {
  status?: HttpResponseStatusCodes;
  data: any | any[];
  statusCode: number;
  error: ErrorEntity;
};

type Response = {
  status?: number;
  data: DataObject;
};

export type ErrorObject = {
  response?: Response;
};

export interface AttachmentDetails {
  documentID: string;
  description?: string;
  fileName: string;
  fileByteCount: number;
  fileType?: string;
  submitterUserID: string;
  submitterUserTypeCode: number;
  recordCreatedTimestamp: string;
}

export type ApprovalPayload = {
  attachments?: AttachmentDetails[];
  approverReview?: {
    approvalDecisionCode?: ApprovalDecisionCode;
    approvalOnBehalfOfOtherFlag?: boolean;
    approverLevelIndicator?: ApprovalLevelIndicator;
    approverName?: string;
    approvalRoleDescription?: string;
    approverUserID?: string;
    decisionComment?: string;
    decisionTimestamp?: string;
    documentIDs?: string[];
  };
};

export type ArrearsApprovalPayload = Omit<
  ApprovalPayload,
  'approverReview.approvalOnBehalfOfOtherFlag'
>;

export type SchemaErrorObject = {
  [key: string]: {
    message: string;
    type: string;
  };
};
