import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  AssessmentFields,
  CashReceipt,
  CashReceiptFields,
  ClaimTypeCode,
  ClaimTypeIndicator,
  FinalBorrowerCharge,
  FinalBorrowerChargeFields,
  OtherAdjustment
} from '@hobt/claim-domain';

import {
  HbtServiceErrorCodes,
  HbtValidationErrorCodes,
  HttpResponseStatusCodes,
  OtherAdjustmentCode
} from '@hobt/constants';
import { deepCopy } from '@hobt/utils';

import { ApiClient } from 'Foundation/Api';
import {
  AssessmentAndCalculationFormData,
  AssessmentCalculationsContextType,
  ToastErrorType,
  AssessmentCalculationsPropsFields
} from 'Feature/Claims/components/Details/AssessmentCalculations/types';
import { HBTFormContextType } from 'Feature/Claims/components/HBTFormContext/types';
import { useHBTFormActionsContext } from 'Feature/Claims/components/HBTFormContext';
import { useAuthenticationContext } from 'Foundation/Authentication';

import { convertStringValuesToBoolean } from 'Constants/Utils/JSONConverters/convertStringToBoolean';
import { replaceMissingFieldsWithNull } from 'Constants/Utils/ReplaceMissingFieldsWithNull/replaceMissingFieldsWithNull';

import { config } from '../../../../../config';
import { ErrorObject } from 'Feature/UserManagement/models/types';

const CHECK_MISSING_BORROWER_CHARGES_FIELDS = [
  FinalBorrowerChargeFields.AdjustedAmount,
  FinalBorrowerChargeFields.AdjustedDate
];
const CHECK_MISSING_CASH_RECEIPTS_FIELDS = [
  CashReceiptFields.AdmissibleAmount,
  CashReceiptFields.AdmissibleDate
];
const CHECK_MISSING_ASSESSMENTS_FIELDS = [
  AssessmentFields.AdjustedDefaultInterestRatePercent,
  AssessmentFields.AdjustedLastCompleteInstallmentPaidDueDate,
  AssessmentFields.AdjustedOutstandingPrincipalBalanceAmount,
  AssessmentFields.AdjustedTaxAccountBalanceAmount,
  AssessmentFields.AdjustedConveyancingDateLenderNotificationDate,
  AssessmentFields.AdjustedDeficiencySaleClosingDate,
  AssessmentFields.AdjustedDeficiencySalePriceAmount,
  AssessmentFields.AdjustedDeficiencySaleCommissionAmount
];

export const useAssessmentCalculationsFunctions = (
  fields: AssessmentCalculationsPropsFields,
  hbtFormContext: HBTFormContextType
) => {
  const authContext = useAuthenticationContext();
  const { disableEditMode } = useHBTFormActionsContext();

  const { putWithAuth, postWithAuth } = ApiClient(authContext, {
    timeout: config.claimApi.requestTimeout
  });

  const [sideDrawer, setSideDrawer] = useState<boolean>(false);
  const [addOtherAdjustmentsVisible, setAddOtherAdjustmentsVisible] = useState<boolean>(false);
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);

  const [assessmentContext, setAssessmentContext] = useState<AssessmentCalculationsContextType>({
    isEditing: false,
    toggleIsEditing: () => {
      setAssessmentContext((prev) => ({
        ...assessmentContext,
        isEditing: !prev.isEditing,
        previewCalculationsButtonClicked: undefined
      }));
    },
    sitecoreContent: fields,
    errorNotification: ToastErrorType.NoError,
    resetErrorNotification: () => {
      setAssessmentContext({ ...assessmentContext, errorNotification: ToastErrorType.NoError });
    },
    toggleAddOtherAdjustments: () => {
      setAddOtherAdjustmentsVisible((prev) => !prev);
    },
    dmpErrors: []
  });
  const {
    autoDecision,
    claimData,
    downloadClaimCalculationLetter,
    lockTabs,
    uuid,
    updateClaimData,
    setLoadingStatus
  } = hbtFormContext;

  useEffect(() => {
    if (lockTabs != null) {
      lockTabs(assessmentContext.isEditing);
    }
  }, [assessmentContext.isEditing]);

  const callFinalClaimApi = async (
    data: AssessmentAndCalculationFormData,
    endPoint: string,
    previewCalc: boolean = false,
    onSuccessCallback?: (res: any) => void,
    onErrorCallback?: (e: any) => void
  ) => {
    try {
      setLoadingStatus?.({
        isLoading: true,
        spinnerHeading: 'Globals-Saving-Heading',
        spinnerDescription: 'Globals-Saving-Description'
      });

      const apiOp = previewCalc ? postWithAuth : putWithAuth;
      const res = await apiOp(endPoint, data);

      if (res != null && onSuccessCallback != null) {
        const { data: responseData } = res.data;

        onSuccessCallback(responseData);
      }

      setLoadingStatus?.({
        isLoading: false
      });
    } catch (e) {
      if (onErrorCallback != null) {
        onErrorCallback(e);
      }

      setLoadingStatus?.({
        isLoading: false
      });
    }
  };

  const onSuccessToastDismiss = () => {
    setShowSuccessToast(false);
  };

  const getMaxIndexNumber = (otherAdjustments: OtherAdjustment[]) =>
    Math.max(...otherAdjustments?.map((otherAdjustment) => otherAdjustment.indexNumber as number));

  const convertStringToBooleanValuesInObj = <T extends unknown>(obj: T) => {
    if (obj == null) {
      return null;
    }
    return JSON.parse(JSON.stringify(obj, convertStringValuesToBoolean));
  };

  const mergeOtherAdjustmentsData = (otherAdjustments: OtherAdjustment[]) => {
    const updatedOtherAdjustmentsCopy = otherAdjustments.map((item) => {
      if (
        (item.previousAdjustmentDate && Number.isInteger(parseInt(item.previousAdjustmentDate))) ||
        (typeof item.previousAdjustmentDate === 'number' && item.previousAdjustmentDate === 0)
      ) {
        return { ...item, previousAdjustmentDate: null };
      }
      return item;
    });
    // We should only send a new object if the user has edited it and there are prev 501 or 503
    const otherAdjustmentsCopy = deepCopy(updatedOtherAdjustmentsCopy);

    let prevLateFilingOtherAdjustmentCodes = [];

    if (claimData?.detail?.claimTypeCode !== ClaimTypeCode.DeficiencySale) {
      prevLateFilingOtherAdjustmentCodes = claimData?.otherAdjustments?.filter?.(
        (field: OtherAdjustment) =>
          field.code === OtherAdjustmentCode.LateFiling &&
          field.originalClaimSequenceNumber !== claimData?.sequenceNumber
      );
    }

    const prevLegalActionDelayFeeOtherAdjustmentCodes = claimData?.otherAdjustments?.filter?.(
      (field: OtherAdjustment) =>
        field.code === OtherAdjustmentCode.LegalActionDelayFee &&
        field.originalClaimSequenceNumber !== claimData?.sequenceNumber
    );

    if (
      prevLateFilingOtherAdjustmentCodes.length > 0 ||
      prevLegalActionDelayFeeOtherAdjustmentCodes.length > 0
    ) {
      if (prevLateFilingOtherAdjustmentCodes.length === 0) {
        return [
          ...otherAdjustmentsCopy,
          convertStringToBooleanValuesInObj(
            prevLegalActionDelayFeeOtherAdjustmentCodes?.[0] ?? null
          )
        ];
      }

      if (prevLegalActionDelayFeeOtherAdjustmentCodes.length === 0) {
        return [
          ...otherAdjustmentsCopy,
          convertStringToBooleanValuesInObj(prevLateFilingOtherAdjustmentCodes?.[0] ?? null)
        ];
      }

      return [
        ...otherAdjustmentsCopy,
        convertStringToBooleanValuesInObj(prevLateFilingOtherAdjustmentCodes?.[0] ?? null),
        convertStringToBooleanValuesInObj(prevLegalActionDelayFeeOtherAdjustmentCodes?.[0] ?? null)
      ];
    }

    return otherAdjustmentsCopy;
  };

  const getMinSequenceNumber = (otherAdjustments: OtherAdjustment[]) =>
    Math.min(
      ...otherAdjustments?.map(
        (otherAdjustment: OtherAdjustment) => otherAdjustment.originalClaimSequenceNumber ?? 0
      )
    );

  const parseOtherAdjustmentsForDeficencySaleClaims = (otherAdjustments: OtherAdjustment[]) => {
    const newOtherAdjustments: OtherAdjustment[] = [];

    const prevLegalActionDelayFeeOtherAdjustments = otherAdjustments?.filter?.(
      (field: OtherAdjustment) =>
        field.code === OtherAdjustmentCode.LegalActionDelayFee &&
        field.originalClaimSequenceNumber !== claimData.sequenceNumber
    );
    const currLegalActionDelayFeeOtherAdjustments = otherAdjustments?.filter?.(
      (field: OtherAdjustment) =>
        field.code === OtherAdjustmentCode.LegalActionDelayFee &&
        field.originalClaimSequenceNumber === claimData.sequenceNumber
    );

    const maxIndexNumber = getMaxIndexNumber(otherAdjustments);

    let legalActionDelayFeeOtherAdjustmentsCount = 0;

    otherAdjustments.forEach((otherAdjustment) => {
      if (otherAdjustment.code === OtherAdjustmentCode.LateFiling) {
        newOtherAdjustments.push({
          ...otherAdjustment,
          numberOfDaysCount:
            (otherAdjustment.numberOfDaysCount ?? 0) -
            (otherAdjustment.previousAdjustmentNumberOfDaysCount ?? 0),
          previousAdjustmentDate:
            otherAdjustment.numberOfDaysCount != null
              ? String(otherAdjustment.numberOfDaysCount)
              : undefined
        });
      } else if (otherAdjustment.code === OtherAdjustmentCode.LegalActionDelayFee) {
        if (
          prevLegalActionDelayFeeOtherAdjustments.length > 0 &&
          currLegalActionDelayFeeOtherAdjustments.length === 0
        ) {
          newOtherAdjustments.push({
            code: OtherAdjustmentCode.LegalActionDelayFee,
            amountOverrideFlag: false,
            numberOfDaysOverrideFlag: false,
            originalClaimSequenceNumber: claimData.sequenceNumber,
            indexNumber: maxIndexNumber + 1,
            previousAdjustmentDate:
              prevLegalActionDelayFeeOtherAdjustments[0]?.numberOfDaysCount != null
                ? String(prevLegalActionDelayFeeOtherAdjustments[0].numberOfDaysCount)
                : undefined
          });
        } else if (
          prevLegalActionDelayFeeOtherAdjustments.length > 0 &&
          currLegalActionDelayFeeOtherAdjustments.length > 0
        ) {
          if (legalActionDelayFeeOtherAdjustmentsCount === 0) {
            newOtherAdjustments.push({
              ...currLegalActionDelayFeeOtherAdjustments[0],
              previousAdjustmentDate:
                prevLegalActionDelayFeeOtherAdjustments[0]?.numberOfDaysCount != null
                  ? String(prevLegalActionDelayFeeOtherAdjustments[0].numberOfDaysCount)
                  : undefined
            });
            legalActionDelayFeeOtherAdjustmentsCount += 1;
          }
        }
      } else {
        newOtherAdjustments.push(otherAdjustment);
      }
    });

    return newOtherAdjustments;
  };

  const parseOtherAdjustmentsData = () => {
    const { detail, otherAdjustments, sequenceNumber } = claimData;
    let maxIndexNumber = getMaxIndexNumber(otherAdjustments);

    const newOtherAdjustments = deepCopy(otherAdjustments);

    if (detail.claimTypeCode === ClaimTypeCode.DeficiencySale) {
      return parseOtherAdjustmentsForDeficencySaleClaims(newOtherAdjustments);
    }

    // Get prev late filing other adjustments
    const prevLateFilingOtherAdjustmentCodes = newOtherAdjustments?.filter?.(
      (field: OtherAdjustment) =>
        field.code === OtherAdjustmentCode.LateFiling &&
        field.originalClaimSequenceNumber !== sequenceNumber
    );

    if (prevLateFilingOtherAdjustmentCodes.length > 0) {
      const minSequenceNumber = getMinSequenceNumber(prevLateFilingOtherAdjustmentCodes);

      const prevLateFilingIndex = newOtherAdjustments?.findIndex(
        (otherAdjustment: OtherAdjustment) =>
          otherAdjustment.code === OtherAdjustmentCode.LateFiling &&
          otherAdjustment.originalClaimSequenceNumber === minSequenceNumber
      );

      if (prevLateFilingIndex > -1) {
        const prevLateFilingOtherAdjustment = newOtherAdjustments[prevLateFilingIndex];

        newOtherAdjustments.splice(prevLateFilingIndex, 1);

        const currClaimLateFilingOtherAdjustment = newOtherAdjustments?.findIndex(
          (otherAdjustment: OtherAdjustment) =>
            otherAdjustment.code === OtherAdjustmentCode.LateFiling &&
            otherAdjustment.originalClaimSequenceNumber === sequenceNumber
        );

        if (currClaimLateFilingOtherAdjustment === -1) {
          maxIndexNumber += 1;
          newOtherAdjustments.push({
            code: OtherAdjustmentCode.LateFiling,
            amount: prevLateFilingOtherAdjustment?.amount ?? undefined,
            numberOfDaysCount: prevLateFilingOtherAdjustment?.numberOfDaysCount ?? undefined,
            amountOverrideFlag: false,
            numberOfDaysOverrideFlag: false,
            originalClaimSequenceNumber: sequenceNumber,
            indexNumber: maxIndexNumber,
            previousAdjustmentDate: prevLateFilingOtherAdjustment?.numberOfDaysCount ?? undefined
          });
        } else {
          newOtherAdjustments[currClaimLateFilingOtherAdjustment].previousAdjustmentDate =
            prevLateFilingOtherAdjustment?.numberOfDaysCount ?? undefined;
        }
      }
    }

    const prevLegalActionDelayFeeOtherAdjustmentCodes = newOtherAdjustments?.filter?.(
      (field: OtherAdjustment) =>
        field.code === OtherAdjustmentCode.LegalActionDelayFee &&
        field.originalClaimSequenceNumber !== sequenceNumber
    );

    if (prevLegalActionDelayFeeOtherAdjustmentCodes.length > 0) {
      const minSequenceNumber = getMinSequenceNumber(prevLegalActionDelayFeeOtherAdjustmentCodes);

      const prevLegalActionDelayFeeIndex = newOtherAdjustments?.findIndex(
        (otherAdjustment: OtherAdjustment) =>
          otherAdjustment.code === OtherAdjustmentCode.LegalActionDelayFee &&
          otherAdjustment.originalClaimSequenceNumber === minSequenceNumber
      );

      if (prevLegalActionDelayFeeIndex > -1) {
        const prevLegalActionDelayFeeOtherAdjustment =
          newOtherAdjustments[prevLegalActionDelayFeeIndex];

        newOtherAdjustments.splice(prevLegalActionDelayFeeIndex, 1);

        const currClaimLegalActionDelayFeeOtherAdjustment = newOtherAdjustments?.findIndex(
          (otherAdjustment: OtherAdjustment) =>
            otherAdjustment.code === OtherAdjustmentCode.LegalActionDelayFee &&
            otherAdjustment.originalClaimSequenceNumber === sequenceNumber
        );

        if (currClaimLegalActionDelayFeeOtherAdjustment === -1) {
          maxIndexNumber += 1;
          newOtherAdjustments.push({
            code: OtherAdjustmentCode.LegalActionDelayFee,
            amount: prevLegalActionDelayFeeOtherAdjustment?.amount ?? undefined,
            numberOfDaysCount:
              prevLegalActionDelayFeeOtherAdjustment?.numberOfDaysCount ?? undefined,
            amountOverrideFlag: false,
            numberOfDaysOverrideFlag: false,
            originalClaimSequenceNumber: sequenceNumber,
            indexNumber: maxIndexNumber,
            previousAdjustmentDate:
              prevLegalActionDelayFeeOtherAdjustment?.numberOfDaysCount ?? undefined
          });
        } else {
          newOtherAdjustments[currClaimLegalActionDelayFeeOtherAdjustment].previousAdjustmentDate =
            prevLegalActionDelayFeeOtherAdjustment?.numberOfDaysCount ?? undefined;
        }
      }
    }

    return newOtherAdjustments.sort((prev: OtherAdjustment, curr: OtherAdjustment) => {
      return Number(prev.code) - Number(curr.code);
    });
  };

  const handleAssessCalcMissingValues = (data: AssessmentAndCalculationFormData) => {
    const updatedBorrowerCharges = data.borrowerCharges?.map(
      (borrowerCharge: FinalBorrowerCharge) =>
        replaceMissingFieldsWithNull(
          CHECK_MISSING_BORROWER_CHARGES_FIELDS,
          borrowerCharge
        ) as FinalBorrowerCharge
    );

    const updatedCashReceipts = data.cashReceipts?.map(
      (cashReceipt: CashReceipt) =>
        replaceMissingFieldsWithNull(CHECK_MISSING_CASH_RECEIPTS_FIELDS, cashReceipt) as CashReceipt
    );

    const updatedAssessments = replaceMissingFieldsWithNull(
      CHECK_MISSING_ASSESSMENTS_FIELDS,
      data.assessment
    );

    return {
      ...data,
      assessment: updatedAssessments,
      borrowerCharges: updatedBorrowerCharges,
      cashReceipts: updatedCashReceipts
    };
  };

  const handleMissingFields = (data: OtherAdjustment[]) => {
    const otherAdjustmentsTemp = data;
    const filterCodeWithComment = claimData?.otherAdjustments?.filter?.(
      (otherAdjustment: OtherAdjustment) => otherAdjustment?.comment
    );
    const filterCode500 = claimData?.otherAdjustments?.filter?.(
      (otherAdjustment: OtherAdjustment) => otherAdjustment?.code === '500'
    );

    if (otherAdjustmentsTemp) {
      for (let i = 0; i < otherAdjustmentsTemp.length; i += 1) {
        if (otherAdjustmentsTemp[i].code) {
          filterCodeWithComment.forEach((otherAdjustment: OtherAdjustment) => {
            if (otherAdjustmentsTemp) {
              if (
                otherAdjustment.indexNumber === otherAdjustmentsTemp[i].indexNumber &&
                otherAdjustment.code === otherAdjustmentsTemp[i].code
              ) {
                otherAdjustmentsTemp[i].comment = otherAdjustment.comment;
              }
            }
          });
          filterCode500.forEach((otherAdjustment: OtherAdjustment) => {
            if (otherAdjustmentsTemp) {
              if (
                otherAdjustment.indexNumber === otherAdjustmentsTemp[i].indexNumber &&
                otherAdjustment.code === otherAdjustmentsTemp[i].code
              ) {
                if (otherAdjustment.previousAdjustmentDate) {
                  otherAdjustmentsTemp[i].previousAdjustmentDate =
                    otherAdjustment.previousAdjustmentDate;
                }
              }
            }
          });
        }
      }
    }

    return otherAdjustmentsTemp;
  };

  const save = async (data: AssessmentAndCalculationFormData) => {
    const onSaveSuccess = (res: any) => {
      setShowSuccessToast(true);
      if (res != null && updateClaimData != null) {
        updateClaimData(res.data);
        disableEditMode();
        assessmentContext.toggleIsEditing();
      }
    };

    let requestData = deepCopy(data);
    requestData = handleAssessCalcMissingValues(requestData);

    const onSaveError = (err: ErrorObject) => {
      if (
        err?.response?.status === HttpResponseStatusCodes.ServerError &&
        err?.response?.data?.error?.errorCode ===
          HbtValidationErrorCodes.HBT_VAL_ERR_ADJUSTMENT_CODE_MAX_OCCURRENCE.code
      ) {
        setAssessmentContext({
          ...assessmentContext,
          errorNotification: ToastErrorType.AddOtherAdjustmentsError
        });
      } else {
        setAssessmentContext({ ...assessmentContext, errorNotification: ToastErrorType.SaveError });
      }
    };

    if (requestData.otherAdjustments) {
      requestData.otherAdjustments = handleMissingFields(requestData.otherAdjustments);
    }

    if (
      claimData?.claimTypeIndicator === ClaimTypeIndicator.Supplementary &&
      requestData.otherAdjustments != null
    ) {
      requestData.otherAdjustments = mergeOtherAdjustmentsData(requestData.otherAdjustments);
    }

    await callFinalClaimApi(
      requestData,
      `${config.claimApi.urls.finalClaim}/${uuid}/`,
      false,
      onSaveSuccess,
      onSaveError
    );
  };

  const previewCalculations = async (data: AssessmentAndCalculationFormData) => {
    const onPreviewCalcSuccess = (res: any) => {
      setAssessmentContext((prev) => ({
        ...prev,
        previewCalculationsButtonClicked: (prev.previewCalculationsButtonClicked ?? 0) + 1
      }));
      if (res != null && updateClaimData != null) {
        updateClaimData(res);
      }
    };

    let requestData = deepCopy(data);

    requestData = handleAssessCalcMissingValues(requestData);

    const onPreviewCalcError = (e: any) => {
      // TODO: handle preview calc error, remove when implemented
      /* eslint-disable no-console */
      console.log(e);
    };
    if (requestData.otherAdjustments) {
      requestData.otherAdjustments = handleMissingFields(requestData.otherAdjustments);
    }

    if (
      claimData?.claimTypeIndicator === ClaimTypeIndicator.Supplementary &&
      requestData.otherAdjustments != null
    ) {
      requestData.otherAdjustments = mergeOtherAdjustmentsData(requestData.otherAdjustments);
    }
    await callFinalClaimApi(
      requestData,
      `${config.claimApi.urls.finalClaim}/${`preview-calculations`}/${uuid}/`,
      true,
      onPreviewCalcSuccess,
      onPreviewCalcError
    );
  };

  const approveBtn = () => {
    setSideDrawer(!sideDrawer);
  };

  const hookForm: any = useForm({
    defaultValues: {
      assessment: hbtFormContext.claimData?.assessment,
      borrowerCharges: hbtFormContext.claimData?.borrowerCharges,
      cashReceipts: hbtFormContext.claimData?.cashReceipts,
      otherAdjustments:
        claimData.claimTypeIndicator === ClaimTypeIndicator.Main
          ? hbtFormContext.claimData?.otherAdjustments
          : parseOtherAdjustmentsData()
    }
  });

  useEffect(() => {
    hookForm.reset({
      assessment: hbtFormContext.claimData?.assessment,
      borrowerCharges: hbtFormContext.claimData?.borrowerCharges,
      cashReceipts: hbtFormContext.claimData?.cashReceipts,
      otherAdjustments:
        claimData.claimTypeIndicator === ClaimTypeIndicator.Main
          ? hbtFormContext.claimData?.otherAdjustments
          : parseOtherAdjustmentsData()
    });
  }, [hbtFormContext?.claimData]);

  const onSuccess = (data?: object) => {
    setShowSuccessToast(true);
    setLoadingStatus?.({
      isLoading: false
    });

    if (data != null) {
      if (updateClaimData != null) {
        updateClaimData([data]);
      }
      hookForm.reset({ ...hookForm.getValues(), ...data });
    }
  };

  const handleAutoDecision = async () => {
    try {
      if (autoDecision != null) {
        setLoadingStatus?.({
          isLoading: true,
          spinnerHeading: 'Globals-InProgress-Loading',
          spinnerDescription: 'Globals-InProgress-Loading'
        });

        await autoDecision();
        onSuccess();
      }
    } catch (err: any) {
      // TODO: How to handle unauthorized errors?
      if (
        err?.response?.status === HttpResponseStatusCodes.ServerError &&
        err?.response?.data?.error?.errorCode ===
          HbtValidationErrorCodes.HBT_VAL_ERR_FINALCLAIM_PAYEE_ERP.code
      ) {
        setAssessmentContext({
          ...assessmentContext,
          errorNotification: ToastErrorType.MissingPayeeDetails
        });
      }
      if (
        err.response?.status === HttpResponseStatusCodes.ServerError &&
        err.response?.data?.error?.errorCode ===
          HbtServiceErrorCodes.HBT_ERR_AUTO_ADJUDICATION_DECLINED.code
      ) {
        setAssessmentContext({
          ...assessmentContext,
          errorNotification: ToastErrorType.AutoApproveError
        });
      }

      setLoadingStatus?.({
        isLoading: false
      });
    }
  };

  const onAddOtherAdjustmentError = (isMaxError: boolean = false) => {
    setAssessmentContext({
      ...assessmentContext,
      errorNotification: isMaxError
        ? ToastErrorType.AddOtherAdjustmentsError
        : ToastErrorType.SaveError
    });
  };

  const handleDownloadClaimCalculationLetter = async () => {
    if (claimData?.calculationDetail?.documentID == null) {
      setAssessmentContext({
        ...assessmentContext,
        errorNotification: ToastErrorType.DownloadError
      });
      return;
    }
    try {
      if (downloadClaimCalculationLetter != null) {
        const documentResponse = await downloadClaimCalculationLetter(
          claimData?.calculationDetail.documentID
        );

        if (documentResponse.status === HttpResponseStatusCodes.OK) {
          const downloadElement = document.createElement('a');
          downloadElement.setAttribute(
            'href',
            documentResponse.data.data?.documentEntityJson?.documentLink
          );
          downloadElement.click();
          downloadElement.remove();
        }
      }
    } catch (err: any) {
      if (err.response?.status === HttpResponseStatusCodes.ServerError) {
        setAssessmentContext({
          ...assessmentContext,
          errorNotification: ToastErrorType.DownloadError
        });
      }
    }
  };

  return {
    save,
    sideDrawer,
    addOtherAdjustmentsVisible,
    approveBtn,
    assessmentContext,
    handleAutoDecision,
    handleDownloadClaimCalculationLetter,
    hookForm,
    previewCalculations,
    onAddOtherAdjustmentError,
    onSuccess,
    onSuccessToastDismiss,
    parseOtherAdjustmentsData,
    showSuccessToast
  };
};
