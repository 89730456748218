import { useState, useEffect } from 'react';
import {
  CommentType,
  CorrespondenceType,
  correspondenceSchema,
  ClaimTypeCode
} from '@hobt/claim-domain';
import { HttpResponseStatusCodes, HbtServiceErrorCodes, Language } from '@hobt/constants';
import { hbtResolver } from '@hobt/schema-validator';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { RichTextProps } from '@sitecore-jss/sitecore-jss-react/types/components/RichText';
import { AxiosError, AxiosResponse } from 'axios';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import { ExternalTypeOfClaim } from 'Feature/Claims/components/ClaimsInventory/types';
import { DataObject } from 'Feature/Claims/components/Details/types';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { useForm, UseFormReturn } from 'react-hook-form';
import { config } from '../../../../../../config';
import { DeclineClaimPropsFields, DeclineClaimRequestData } from './types';

const useDeclineClaimFunctions = (
  fields: DeclineClaimPropsFields,
  onSuccessCallback?: Function,
  onErrorCallback?: Function,
  onCancelCallback?: Function,
  typeOfClaim?: ClaimTypeCode,
  cmhcAccountNumber?: number
) => {
  const hookForm = useForm({
    resolver: hbtResolver(correspondenceSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  } as Record<string, any>);

  const { claimData, setLoadingStatus } = useHBTFormContext();

  const watchLanguage: number = hookForm.watch(
    'languageCode',
    claimData?.lender?.correspondenceLanguageCode
  );
  const watchNotes: string = hookForm.watch('remarkText');
  const watchDeclineReason = hookForm.watch('declineReasonCode');

  const claimType = fields.typeOfClaimToken?.value ?? '';
  const cmhcAccount = fields.cmhcAccountToken?.value ?? '';
  const declineReasonEnglish = fields.declineReasonEnglish?.value ?? '';
  const declineReasonFrench = fields.declineReasonFrench?.value ?? '';
  const declineNoteEnglish = fields.declineNoteEnglish?.value ?? '';
  const declineNoteFrench = fields.declineNoteFrench?.value ?? '';
  const categoryListFrench = fields.categoryListFrench?.fields.listItems;
  const categoryListEnglish = fields.categoryList?.fields.listItems;
  const initRequestTemplate = (language: number): string => {
    if (language === Language.French) {
      const declineReasons = categoryListFrench?.find(
        (item: SitecoreListItem) => Number(item?.fields.itemValue?.value) === watchDeclineReason
      )?.fields.itemName?.value;
      return (fields?.notificationTemplateFrench?.field?.value as string)
        ?.replace(claimType, typeOfClaim ? ExternalTypeOfClaim[typeOfClaim as ClaimTypeCode] : '')
        ?.replace(cmhcAccount, cmhcAccountNumber?.toString() ?? '')
        ?.replace(declineReasonFrench, declineReasons ?? declineReasonFrench);
    }

    const declineReasons = categoryListEnglish?.find(
      (item: SitecoreListItem) => Number(item?.fields.itemValue?.value) === watchDeclineReason
    )?.fields.itemName?.value;
    return (fields?.notificationTemplateFrench?.field?.value as string)
      ?.replace(claimType, typeOfClaim ? ExternalTypeOfClaim[typeOfClaim as ClaimTypeCode] : '')
      ?.replace(cmhcAccount, cmhcAccountNumber?.toString() ?? '')
      ?.replace(declineReasonEnglish, declineReasons ?? declineReasonEnglish);
  };

  const [showValidationError, setShowValidationError] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');
  const [displayedRequestTemplate, setDisplayedRequestTemplate] = useState<RichTextProps>({
    field: { value: initRequestTemplate(watchLanguage) }
  });

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const userDetails = sitecoreContext?.user;

  useEffect(() => {
    hookForm.register('submitterUserID');
    hookForm.register('submitterUserTypeCode');
    hookForm.register('correspondenceTypeCode');
    hookForm.register('commentTypeCode');

    hookForm.setValue('commentTypeCode', CommentType.Decline);
    hookForm.setValue('submitterUserID', userDetails?.userID);
    hookForm.setValue('submitterUserTypeCode', userDetails?.userTypeCode);
    hookForm.setValue('correspondenceTypeCode', CorrespondenceType.External);
  }, []);

  const onDeclineReasonChange = (reason: number) => {
    let newRequestTemplate: string = initRequestTemplate(watchLanguage);
    const declineReasons = fields.categoryList?.fields.listItems?.find(
      (item: SitecoreListItem) => Number(item?.fields.itemValue?.value) === reason
    )?.fields.itemName?.value;
    // if (declineReasons && declineReasons.length > 0)
    //   newRequestTemplate = newRequestTemplate
    //     .replace(declineReasonEnglish, declineReasons)
    //     .replace(declineReasonFrench, declineReasons);
    // if (notes && notes.length > 0)
    //   newRequestTemplate = newRequestTemplate
    //     .replace(declineNoteEnglish, notes)
    //     .replace(declineNoteFrench, notes);
    setDisplayedRequestTemplate({ field: { value: newRequestTemplate } });
  };

  const onNotesChange = (updateNotes: string) => {
    setNotes(updateNotes);
    let newRequestTemplate: string = initRequestTemplate(watchLanguage);
    const declineReasons = fields.categoryList?.fields.listItems?.find(
      (item: SitecoreListItem) => Number(item?.fields.itemValue?.value) === watchDeclineReason
    )?.fields.itemName?.value;
    // if (declineReasons && declineReasons.length > 0)
    //   newRequestTemplate = newRequestTemplate
    //     .replace(declineReasonEnglish, declineReasons)
    //     .replace(declineReasonFrench, declineReasons);
    // if (updateNotes && updateNotes.length > 0)
    //   newRequestTemplate = newRequestTemplate
    //     .replace(declineNoteEnglish, updateNotes)
    //     .replace(declineNoteFrench, updateNotes);
    setDisplayedRequestTemplate({ field: { value: newRequestTemplate } });
  };

  useEffect(() => {
    onNotesChange(watchNotes);
  }, [watchNotes]);

  useEffect(() => {
    setDisplayedRequestTemplate({ field: { value: initRequestTemplate(watchLanguage) } });
    onDeclineReasonChange(watchDeclineReason);
    onNotesChange(notes);
  }, [watchLanguage]);

  useEffect(() => {
    onDeclineReasonChange(watchDeclineReason);
  }, [watchDeclineReason]);

  // API Client Config
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  const authContext = useAuthenticationContext();
  const { postWithAuth } = ApiClient(authContext, apiClientConfig);

  const { uuid } = useHBTFormContext();
  const handleSubmit: any = (data: DeclineClaimRequestData) => {
    const payloadData = {
      claimID: uuid,
      declinePayload: {
        commentTypeCode: CommentType.Decline,
        correspondenceTypeCode: CorrespondenceType.External,
        declineReasonCode: data.declineReasonCode,
        remarkText: data?.remarkText,
        submitterUserID: userDetails.userID,
        submitterUserTypeCode: userDetails.userTypeCode,
        attachments: data.attachments
      }
    };

    const url = config.claimApi.urls.declineClaim;

    setLoadingStatus?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Heading',
      isFlyout: true
    });

    postWithAuth(url, { ...payloadData })
      .then((responseData: AxiosResponse<DataObject>) => {
        if (onSuccessCallback) {
          onSuccessCallback(responseData?.data?.data);
        }

        setLoadingStatus?.({
          isLoading: false
        });
      })
      .catch((error: AxiosError<any>) => {
        if (
          error.response?.status === HttpResponseStatusCodes.BadRequest &&
          error.response?.data.error.errorCode === HbtServiceErrorCodes.HBT_ERR_VALIDATION
        ) {
          setShowValidationError(true);
        } else if (onErrorCallback) {
          onErrorCallback();
        }

        setLoadingStatus?.({
          isLoading: false
        });
      });
  };

  const onSubmitErrors: any = (errs: Record<string, Object>) => {
    // TODO: Remove once validations are in place
    // eslint-disable-next-line no-console
    console.log('FORM ERRORS: ', errs);
  };

  const onCloseValidationErrors = () => {
    setShowValidationError(false);
  };

  const cancelFlyout = () => {
    hookForm.reset();
    if (onCancelCallback) {
      onCancelCallback();
    }
  };

  return {
    handleSubmit,
    onSubmitErrors,
    cancelFlyout,
    onCloseValidationErrors,
    onDeclineReasonChange,
    onCancelCallback,
    showValidationError,
    displayedRequestTemplate,
    hookForm,
    onNotesChange,
    watchNotes,
    watchDeclineReason
  };
};

export default useDeclineClaimFunctions;
