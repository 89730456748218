import React from 'react';
import i18n from 'i18next';

import { useDefaultFormActionsContext } from 'Feature/DefaultsInventory/components/DefaultFormContext';
import { ProgressBarItemProps, SectionType, ProgressBarStepStatus } from './ProgressBarItem.types';
import { LanguageSwitchTypeCode } from '../../../DefaultSubmissionForm/models/typeCode.types';
import styles from './styles.module.scss';

export const ProgressBarItem: React.FC<ProgressBarItemProps> = (props: ProgressBarItemProps) => {
  const appLang = i18n.language && i18n.language === LanguageSwitchTypeCode.French;
  const frSectionC = appLang && props.type === SectionType.SectionC;
  const frSectionI = appLang && props.type === SectionType.SectionI;
  const defaultFormActions = useDefaultFormActionsContext();

  return (
    <div className="form-progress__items form-progress__items--">
      <div
        className={`form-progress__items-status
        ${frSectionC && 'form-progress__items--section-C'}
        ${frSectionI && 'form-progress__items--section-I'}
      `}
      />
      <span
        className={`${styles.progressbarItemStatusIndicator} 
        ${
          defaultFormActions?.isCardComplete(props.validationKey) &&
          ` ${styles.progressbarItemStatusIndicatorOuterCompleted}`
        }
        ${
          props.status === ProgressBarStepStatus.PENDING &&
          ` ${styles.progressbarItemStatusIndicatorOuterUncompleted}`
        }
      `}
      >
        {defaultFormActions?.isCardComplete(props.validationKey) && (
          <span className={styles.progressbarItemStatusIndicatorCompleted} />
        )}

        {props.status === ProgressBarStepStatus.PENDING && (
          <span className={styles.progressbarItemStatusIndicatorCurrent} />
        )}
      </span>
      <a
        href="#"
        onClick={props.clickHandler}
        className="form-progress__items-link"
        id={`progress-indicator-${props.index || '0'}`}
      >
        <span className="sr-only">{`${props.arialabelScreenReader}`}</span>
        {props?.name?.value}
      </a>
    </div>
  );
};
