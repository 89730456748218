import { useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';

import { HttpResponseStatusCodes, HbtServiceErrorCodes } from '@hobt/constants';

import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { pathNames } from 'Constants';
import { config } from '../../../../../config';
import { ErrorObject } from 'Feature/UserManagement/models/types';

const useDeleteClaimFunctions = () => {
  const [showDeleteClaimToast, setShowDeleteClaimToast] = useState<any | boolean>(false);
  const [showPageModal, setShowPageModal] = useState<boolean>(false);
  const { uuid } = useHBTFormContext();
  const [isDeletingClaim, setIsDeletingClaim] = useState<boolean>(false);

  const claimID: number | string = uuid;
  const history = useHistory();

  // API Client Config
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  const authContext = useAuthenticationContext();
  const { deleteWithAuth } = ApiClient(authContext, apiClientConfig);

  const onDeleteClaim = () => {
    const url = `${config.claimApi.urls.finalClaim}/${claimID}`;
    setIsDeletingClaim(true);

    deleteWithAuth(url)
      .then((_res: AxiosResponse) => {
        onSuccessCallback();
        setIsDeletingClaim(false);
      })
      .catch((error: ErrorObject) => {
        if (
          error?.response?.status === HttpResponseStatusCodes.NotFound &&
          error?.response?.data?.error?.errorCode === HbtServiceErrorCodes.HBT_ERR_RECORD_NOT_FOUND
        ) {
          onErrorCallback();
        }

        setIsDeletingClaim(false);
      });
  };

  const onSuccessCallback = () => {
    setShowDeleteClaimToast(ApplicationStates.SUCCESS);
    history.push(`/${i18n.language}${pathNames.claimsInventory}`);
  };
  const onErrorCallback = () => {
    setShowDeleteClaimToast(ApplicationStates.ERROR);
  };
  const closeDeleteClaimToast = () => {
    setShowDeleteClaimToast(false);
  };
  const primaryCallback = () => {
    onDeleteClaim();
    setShowPageModal(false);
  };

  const onDeleteClick = () => {
    setShowPageModal(true);
  };

  const secondaryCallback = () => {
    setShowPageModal(false);
  };
  return {
    showPageModal,
    isDeletingClaim,
    showDeleteClaimToast,
    setIsDeletingClaim,
    onDeleteClick,
    primaryCallback,
    secondaryCallback,
    setShowPageModal,
    closeDeleteClaimToast
  };
};

export default useDeleteClaimFunctions;
