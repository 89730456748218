import React, { FC } from 'react';
import { useForm, FormProvider, UseFormReturn } from 'react-hook-form';

import { receivedAdjustmentSchema } from '@hobt/claim-domain';
import { hbtResolver } from '@hobt/schema-validator';

import RefundManagementProps from 'Feature/Claims/models/RefundManagementProps';

import RefundRequestLetter from './RefundRequestLetter';
import { RefundLetterProvider } from './RefundRequestLetter/RefundLetterContext';
import RefundReason from './Refund Reason';
import ReceivedRefund from './Received Refund';
import FinalizedRefund from './Finalized Refund Balance';

import styles from './styles.module.scss';

const RefundManagement: FC<RefundManagementProps> = ({
  fields: {
    refundBalanceTitle,
    refundBalanceMessage,
    refundReasonComponent,
    refundReceivedComponent,
    requestLetterFlyout,
    finalizedRefundBalanceComponent,
    refundLetterGeneratedToastMessage,
    refundLetterSentToastMessage
  }
}) => {
  const refundFormMethods = useForm({
    resolver: hbtResolver({
      type: 'object',
      properties: {
        receivedAdjustments: {
          type: 'array',
          items: receivedAdjustmentSchema
        },
        refundReason: {
          type: 'number'
        }
      }
    }),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    shouldUnregister: false
  } as Record<string, any>);

  const languages = {
    english: requestLetterFlyout.fields.english,
    french: requestLetterFlyout.fields.french
  };

  return (
    <RefundLetterProvider
      refundReasons={refundReasonComponent.fields.reasonRefund[0].fields.listItems}
      languages={languages}
    >
      <FormProvider {...refundFormMethods}>
        <form
          id="refundReasonTestForm"
          noValidate
          onSubmit={($event) => $event.preventDefault()}
          className={`${styles.refundReasonForm} form`}
        >
          <h2>{refundBalanceTitle.value}</h2>
          <p>{refundBalanceMessage.value}</p>
          <RefundReason {...refundReasonComponent.fields} />
          <hr />
          <ReceivedRefund {...refundReceivedComponent.fields} />

          <FinalizedRefund
            {...finalizedRefundBalanceComponent}
            refundLetterSentToastMessage={refundLetterSentToastMessage}
          />
        </form>
      </FormProvider>
      <RefundRequestLetter
        {...requestLetterFlyout}
        refundLetterGeneratedToastMessage={refundLetterGeneratedToastMessage}
      />
    </RefundLetterProvider>
  );
};

export default RefundManagement;
