import React, { FC } from 'react';

const ConditionalRender: FC<{
  condition: boolean;
  children?: React.ReactNode;
}> = ({ condition, children }) => {
  if (condition) {
    return children as JSX.Element;
  }
  return null;
};

export default ConditionalRender;
