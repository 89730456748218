import React, { useContext } from 'react';
import i18next from 'i18next';

import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { AssessmentCalculationsContext } from 'Feature/Claims/components/Details/AssessmentCalculations';
import { ToastErrorType } from 'Feature/Claims/components/Details/AssessmentCalculations/types';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';

export const ErrorNotifications: React.FC = () => {
  const { errorNotification, resetErrorNotification } = useContext(AssessmentCalculationsContext);

  const getErrorMessage = (errorType: ToastErrorType) => {
    switch (errorType) {
      case ToastErrorType.SaveError:
        return 'Globals-Toast-Error-Item-Not-Saved';
      case ToastErrorType.AutoApproveError:
        return 'ClaimDetails-ErrorToast-AutoApprove';
      case ToastErrorType.DownloadError:
        return 'DefaultSubmission-Card-DownloadAttachmentErrorMessage';
      case ToastErrorType.AddOtherAdjustmentsError:
        return 'ClaimsDetails-ErrorToast-AddOtherAdjustments';
      case ToastErrorType.MissingPayeeDetails:
        return 'ApprovalForm-ErrorToast-MissingPayeeDetails';
      default:
        return 'Globals-UnknownErrorMessage';
    }
  };

  return (
    <ToastNotification
      type={ApplicationStates.ERROR}
      isActive={errorNotification !== ToastErrorType.NoError}
      title={i18next.t('Globals-Toast-Error-Title')}
      content={i18next.t(`${getErrorMessage(errorNotification)}`)}
      onCloseCallback={resetErrorNotification}
    />
  );
};
