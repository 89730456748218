import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useController, useFormContext } from 'react-hook-form';
import styles from './styles.module.scss';
import FormInputWrapper from 'Components/Inputs/FormInputWrapper';
import { DropdownOptionType, SelectDropdownProps } from './types';
import { ValueType } from 'react-select/src/types';

const SearchboxDropdown: React.FC<SelectDropdownProps> = ({
  name,
  label,
  options = [],
  selectedValue = '', // Change to single value
  placeholder,
  isDisabled = false,
  typedVal,
  className,
  handleTitleTypeName
}) => {
  const { register, setValue, watch } = useFormContext();
  const [internalSelectedValues, setInternalSelectedValues] = useState<string>(selectedValue || '');
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    selectedValue && setInternalSelectedValues(selectedValue);
  }, [selectedValue]);

  const {
    field: { onChange, ref, ...field }
  } = useController({
    name
  });

  const handleChange = (
    selectedOption?: ValueType<DropdownOptionType, false>,
    triggeredAction?: any
  ) => {
    handleTitleTypeName && handleTitleTypeName(selectedOption?.label);
    if (triggeredAction.action === 'clear') {
      setInternalSelectedValues('');
    }
    if (selectedOption) {
      const newSelectedValue = selectedOption.value;
      setInternalSelectedValues(newSelectedValue);
      setValue(name, newSelectedValue);
      setIsLoading(false);
    }
  };

  const handleInputChange = (inputValue: string) => {
    typedVal(inputValue);
  };

  const handleFocus = () => {
    setIsLoading(true);
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsLoading(false);
    setIsFocused(false);
  };
  return (
    <FormInputWrapper name={name} label={label} className={className}>
      <Select
        id={name}
        {...field}
        options={options}
        value={options.filter((option) => option.value === internalSelectedValues)} // Find the selected option
        {...register}
        isDisabled={isDisabled}
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        aria-label={label}
        placeholder={placeholder}
        onChange={(s, triggereAction) => handleChange(s, triggereAction)}
        // @ts-ignore
        watch={watch}
        onInputChange={handleInputChange}
        // isLoading={isLoading}
        onBlur={handleBlur}
        onFocus={handleFocus}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            minHeight: '50px',
            borderColor: state.isFocused ? '#005C84' : '#CCCCCC'
          }),
          placeholder: (defaultStyles) => {
            return {
              ...defaultStyles,
              color: '#000000'
            };
          }
        }}
      />
    </FormInputWrapper>
  );
};

export default SearchboxDropdown;
