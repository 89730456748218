import { useEffect, useState } from 'react';
import { RecordColumnName } from '@hobt/arrears-domain';

import { SearchFieldOptions } from 'Components/PageComponents/SearchBar/types';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import {
  ArrearsHistoricalRecordsInventoryRecords,
  ArrearsHistoricalRecordsPropsFields
} from './types';

const useArrearsHistoricalRecordsFunctions = (
  fields: ArrearsHistoricalRecordsPropsFields,
  rowData: ArrearsHistoricalRecordsInventoryRecords[] | null,
  onSetCustomFilterParams: Function
) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const [selectedMonth, setSelectedMonth] = useState<number>();
  const [selectedYear, setSelectedYear] = useState<number>();

  const [isLoading, setIsLoading] = useState<Spinner>({
    isLoading: false
  });
  const [flyoutData, setFlyoutData] = useState<any>({});
  const [isToastActive, setToastActive] = useState<boolean>(false);
  const [isSubmitFailed, setSubmitFailed] = useState<boolean>(false);
  const [isInventoryToast, setInventoryToast] = useState<boolean>(false);

  const [latestYearRecord, setLatestYearRecord] = useState<number | undefined>();
  const [latestMonthRecord, setlatestMonthRecord] = useState<number | undefined>();

  const searchByFields: SearchFieldOptions[] = [
    {
      displayText: fields.cmhcLoanNumberLabel.value || '',
      value: 'CmhcLoanAccountNumber',
      type: 'number'
    }
  ];

  const onRowClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const recordId: string | null = event.currentTarget.getAttribute('data-rowId');
    if (recordId != null && rowData != null) {
      const data = rowData.filter(
        (row: ArrearsHistoricalRecordsInventoryRecords) => row.recordID === recordId
      );

      setFlyoutData(data[0]);
      setIsActive(!isActive);
    }
  };

  const onBtnClick = () => {
    onSetCustomFilterParams([
      {
        paramName: RecordColumnName.YearMonthReportingPeriod,
        paramValue: `${selectedYear?.toString(10)}-${selectedMonth?.toString(10).padStart(2, '0')}`
      }
    ]);
    setIsActive(!isActive);
  };

  const onSuccessCallback = () => {
    setSubmitFailed(false);
    setToastActive(true);
  };

  const onErrorCallback = () => {
    setSubmitFailed(true);
    setToastActive(true);
  };

  const onToastCloseCallback = () => {
    setInventoryToast(false);
    setToastActive(false);
  };

  const handleOnDateSelection = (data: { month: number; year: number }) => {
    setSelectedMonth(data.month);
    setSelectedYear(data.year);

    onSetCustomFilterParams([
      {
        paramName: RecordColumnName.YearMonthReportingPeriod,
        paramValue: `${data.year.toString(10)}-${data.month.toString(10).padStart(2, '0')}`
      }
    ]);
  };

  useEffect(() => {
    if (latestYearRecord == null && latestMonthRecord == null) {
      if (rowData != null && rowData.length > 0) {
        const { reportingPeriod } = rowData[0];
        const reportingPeriodDateSplit = reportingPeriod.split('-');
        setLatestYearRecord(
          reportingPeriodDateSplit[0].trim().match(/\d+/) != null
            ? parseInt(reportingPeriodDateSplit[0], 10)
            : undefined
        );
        setlatestMonthRecord(
          reportingPeriodDateSplit[1]?.trim().match(/\d+/) != null
            ? parseInt(reportingPeriodDateSplit[1], 10)
            : undefined
        );
      }
    }
  }, [rowData]);

  return {
    isActive,
    isLoading,
    flyoutData,
    isToastActive,
    isSubmitFailed,
    searchByFields,
    isInventoryToast,
    latestYearRecord,
    latestMonthRecord,
    onBtnClick,
    onRowClick,
    onErrorCallback,
    onSuccessCallback,
    onToastCloseCallback,
    handleOnDateSelection,
    setIsLoading
  };
};

export default useArrearsHistoricalRecordsFunctions;
