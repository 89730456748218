import {
  GraphQLLayoutService,
  RestLayoutService,
  constants
} from '@sitecore-jss/sitecore-jss-react';
// @ts-ignore
import config from 'temp/config';

export class LayoutServiceFactory {
  // eslint-disable-next-line class-methods-use-this
  create() {
    return process.env.REACT_APP_FETCH_WITH === constants.FETCH_WITH.GRAPHQL
      ? new GraphQLLayoutService({
          endpoint: config.graphQLEndpoint,
          apiKey: config.sitecoreApiKey,
          siteName: config.jssAppName
        })
      : new RestLayoutService({
          apiHost: config.sitecoreApiHost,
          apiKey: config.sitecoreApiKey,
          siteName: config.jssAppName,
          configurationName: 'jss'
        });
  }
}

export const layoutServiceFactory = new LayoutServiceFactory();
