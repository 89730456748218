import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { ApplicationException } from 'Foundation/Error';
import { UserTypeAuthorizationProps } from './UserTypeAuthorization.types';
import { userType } from '../../Constants';
import { config } from '../../config';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { FC } from 'react';

// @ts-ignore
export const UserTypeAuthorization: FC<UserTypeAuthorizationProps> = ({
  children
}: UserTypeAuthorizationProps): React.ReactNode | null => {
  const authenticationContext = useAuthenticationContext();
  const sitecoreContextFactory = useSitecoreContext();

  const isExternalPortal = config.app.siteType === FeatureFlags.EXTERNAL;

  if (authenticationContext.doesCurrentRouteRequireAuthentication()) {
    const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

    // This component must be a child of HydrateSitecoreContext, but just in case it isn't
    // throw a standard exception to display the standard server error page.
    // HydrateSitecoreContext would have already verified that the user API has a correct
    // user.
    if (!sitecoreContext.user) {
      throw new ApplicationException(
        'No user profile.  Please ensure UserTypeAuthorization is a child of HydrateSitecoreContext'
      );
    }

    const signedInUserType = sitecoreContext.user.userTypeCode;

    if (isExternalPortal && signedInUserType !== userType.external) {
      throw new ApplicationException('Errors-AnalystInExternalPortal');
    } else if (!isExternalPortal && signedInUserType !== userType.internal) {
      throw new ApplicationException('Errors-LenderInInternalPortal');
    }
  }

  return children;
};
