import React from 'react';
import {
  TrendValueCellProp,
  Trend
} from 'Components/Common/DataTable/DataTableRow/TrendValueCell/types';
import styles from './styles.module.scss';

export const TrendValueCell: React.FC<TrendValueCellProp> = ({
  trend,
  arrowDirection,
  name
}: TrendValueCellProp) => {
  const getTrendClass = () => {
    if (trend !== Trend.NEUTRAL && trend !== undefined) {
      return trend === Trend.POSITIVE ? styles.positive : styles.negative;
    }
    return '';
  };

  return (
    <div
      data-testId={`${name}Test`}
      className={`${getTrendClass()} ${styles.trendIconWrapper}`.trim()}
    >
      {arrowDirection !== Trend.NEUTRAL && arrowDirection !== undefined && (
        <div
          className={`${styles.trendIcon}  ${
            trend === Trend.POSITIVE || trend === Trend.NEUTRAL ? styles.positiveDownArrow : ''
          }`}
        >
          <span className="material-icons">
            {`arrow_drop_${arrowDirection === Trend.POSITIVE ? 'up' : 'down'}`}
          </span>
        </div>
      )}
    </div>
  );
};
