import { ApprovalFormPropsFields } from 'Feature/ApprovalsFinancialAuthority/components/ApprovalForm/types';
import { CashReceipt, FinalBorrowerCharge, OtherAdjustment, Assessment } from '@hobt/claim-domain';
import { BorrowerChargesPropsFields } from './ClaimAssessment/BorrowerCharge/types';
import { CashReceiptPropsFields } from './ClaimAssessment/CashReceipts/types';
import { AddOtherAdjustmentsPropsFields } from './ClaimAssessment/OtherAdjustments/AddOtherAdjustments/types';
import { OtherAdjustmentsPropsFields } from './ClaimAssessment/OtherAdjustments/types';
import { PrincipalBalancePropsFields } from './ClaimAssessment/PrincipalBalance/types';
import { LetterCommentsPropsFields } from './ClaimAssessment/LetterComments/types';

export default interface AssessmentCalculationsProps {
  fields: AssessmentCalculationsPropsFields;
}

export interface AssessmentCalculationsPropsFields {
  heading: FieldValue;
  lenderReference: FieldValue;
  cmhcLoan: FieldValue;
  reviewedRequired: FieldValue;
  emailLenderBtn: FieldValue;
  declineBtn: FieldValue;
  claimAssessment: FieldValue;
  collapseAllBtnLabel: FieldValue;
  expandAllBtnLabel: FieldValue;
  collapseAllBtnAriaText: FieldValue;
  expandAllBtnAriaText: FieldValue;
  addClaimLabel: FieldValue;
  downloadCalculationButton: FieldValue;
  viewClaimButton: FieldValue;
  generatingCalculationButton: FieldValue;
  totalClaim: FieldValue;
  totalCollapseAllBtnLabel: FieldValue;
  totalCollapseAllAriaText: FieldValue;
  totalExpandAllBtnLabel: FieldValue;
  totalExpandAllAriaText: FieldValue;
  autoDecisionBtn: FieldValue;
  approveBtn: FieldValue;
  subtotalOfGrossClaim: FieldValue;
  previousClaimPaid: FieldValue;
  amountPayable: FieldValue;
  otherAdjustmentCardLabels: {
    fields: OtherAdjustmentsPropsFields;
  };
  borrowerChargeCardLabels: {
    fields: BorrowerChargesPropsFields;
  };
  cashReceiptsCardLabels: {
    fields: CashReceiptPropsFields;
  };
  principalBalanceCardLabels: {
    fields: PrincipalBalancePropsFields;
  };
  claimAssessmentList: ClaimAssessmentItem[];
  addOtherAdjustmentFlyout: {
    fields: AddOtherAdjustmentsPropsFields;
  };
  approvalFormFlyout: {
    fields: ApprovalFormPropsFields;
  };
  letterCommentsFlyout: {
    fields: LetterCommentsPropsFields;
  };
}

export interface ClaimAssessmentItem {
  fields: {
    heading: FieldValue;
    [label: string]: FieldValue | CodeRow[];
  };
}

export interface CodeRow {
  fields: {
    listName: FieldValue;
    listItem: CodeRowItem[];
  };
}

export interface CodeRowItem {
  fields: {
    itemName: FieldValue;
    itemValue: number;
  };
}

export interface AssessmentAndCalculationFormData {
  borrowerCharges?: FinalBorrowerCharge[];
  otherAdjustments?: OtherAdjustment[];
  cashReceipts?: CashReceipt[];
  assessment?: Assessment;
}

export enum ToastErrorType {
  NoError,
  SaveError,
  AutoApproveError,
  DefaultError,
  DownloadError,
  AddOtherAdjustmentsError,
  MissingPayeeDetails
}

export type AssessmentCalculationsContextType = {
  isEditing: boolean;
  toggleIsEditing: () => void;
  sitecoreContent?: AssessmentCalculationsPropsFields;
  errorNotification: ToastErrorType;
  resetErrorNotification: () => void;
  dmpErrors: any[];
  previewCalculationsButtonClicked?: number;
  toggleAddOtherAdjustments: () => void;
};
