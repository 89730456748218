import React, { useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { FormProvider } from 'react-hook-form';
import i18n from 'i18next';

import { LanguageShort } from '@hobt/constants';

import { Button } from 'Components/Common/Button';
import FormNumber from 'Components/Inputs/FormNumber';
import { NotificationBanner } from 'Components/Common/Notifications/NotificationBanner';

import CSSTransition from 'react-transition-group/CSSTransition';
import { LanguageSwitchTypeCode } from 'Feature/Claims/components/Cards/ClaimsLenderDetails/types';
import FormText from 'Components/Inputs/FormText';
import { currencyFormat, getCurrencyFormat } from 'Components/Inputs/CommonFormFieldFormats';
import { AddPropertyRepairFormProps } from './types';
import usePropertyRepairFunctions from './usePropertyRepairFunctions';

import styles from './styles.module.scss';

export const AddPropertyRepairsForm: React.FC<AddPropertyRepairFormProps> = ({
  name,
  fields,
  onSuccessCallback,
  onErrorCallback,
  onCancelCallback,
  setIsLoadingCallback,
  loadingStatus,
  edit,
  editableItem
}: AddPropertyRepairFormProps) => {
  const { handleSubmit, showValidationError, hookForm, onCloseValidationErrors, onSubmitErrors } =
    usePropertyRepairFunctions(onSuccessCallback, setIsLoadingCallback, onErrorCallback, edit);

  const cancelFlyout = () => {
    hookForm.reset();
    if (onCancelCallback) {
      onCancelCallback();
    }
  };

  useEffect(() => {
    if (edit) {
      hookForm.setValue('cmhcLoanAccountNumber', editableItem?.cmhcLoanAccountNumber);
      hookForm.setValue('amount', editableItem?.amount);
    }
  }, []);

  return (
    <div className={styles.modalScroll}>
      <div className={styles.addPropertyRepairForm} data-testid={`${name}PropertyRepairForm`}>
        <Text
          tag="h2"
          field={edit === true ? fields.editHeading : fields.addHeading}
          className={styles.headingWidth}
        />
        <div className={styles.closeButton}>
          <button
            type="button"
            onClick={cancelFlyout}
            data-testid="closeButton"
            aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
          >
            <span className="material-icons align-self-center">close</span>
          </button>
        </div>

        <FormProvider {...hookForm}>
          <form onSubmit={hookForm.handleSubmit(handleSubmit, onSubmitErrors)} id="addFlagItmForm">
            <Text field={fields.subheading} />
            <CSSTransition
              in={showValidationError}
              classNames={{
                enterActive: styles.validationErrorEnterActive,
                exitActive: styles.validationErrorExitActive
              }}
              timeout={450}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              <NotificationBanner
                error
                title={fields?.propertyRepairErrorAccount}
                content={{ value: i18n.t('Globals-Toast-Error-Item-Not-Saved') }}
                onCloseCallback={onCloseValidationErrors}
              />
            </CSSTransition>
            <div className={`${styles.twoColumns} ${styles.formRow}`}>
              <FormNumber
                name="cmhcLoanAccountNumber"
                label={fields.cmhcLoan}
                className={`${styles.half}`}
                isReadOnly={!!edit}
              />
              <FormNumber
                name="amount"
                label={fields.repairAmount}
                className={`${styles.halfLeft} ${
                  i18n.language === LanguageSwitchTypeCode.French ? styles.pullDown : ''
                }`.trim()}
                formatProps={getCurrencyFormat(
                  hookForm.getValues('amount'),
                  { ...currencyFormat, thousandSeparator: true },
                  i18n.language as LanguageShort
                )}
              />
            </div>
            <div className={`${styles.twoColumns} ${styles.formRow}`}>
              <fieldset disabled={true}>
                <FormText
                  name="borrowerChargeCode"
                  label={fields.codeLabel}
                  className={`${styles.half} ${styles.halfFieldWidth}`}
                  value={fields.codeNumber.value}
                />
              </fieldset>
            </div>
            <div className={`${styles.twoColumns} ${styles.formButtonsWrapper}`}>
              <div>
                <Button
                  responsive
                  loading
                  type="submit"
                  ariaText={fields.saveButton}
                  name="submitButton"
                  text={fields.saveButton}
                  disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
                  icon={
                    loadingStatus?.isLoading && loadingStatus?.isFlyout
                      ? () => (
                          <span className={styles.saveIconWrapper}>
                            <span className={`material-icons align-self-center`}>loop</span>
                          </span>
                        )
                      : undefined
                  }
                />
              </div>
              <div>
                <Button
                  secondaryButton
                  responsive
                  onClick={cancelFlyout}
                  ariaText={fields.cancelButton}
                  name={`CancelButton`}
                  text={fields.cancelButton}
                  type="button"
                  disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
