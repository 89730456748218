import React, { useMemo, useEffect } from 'react';
import { ModuleMapping, UserRole } from '@hobt/constants';
import { UserAndAccesses, UserAccess } from '@hobt/user-domain';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { pathNames } from 'Constants/pathNames';
import i18n from 'i18next';
import HbtInternalPIUserDetails from '../HbtCardsInternal/InternalUserDetails';
import HbtInternalPIUserLevelAccess from '../HbtCardsInternal/InternalUserLevelAccess';
import { HbtModifyInternalPIUserActionType, HbtModifyInternalPIUserAction } from './types';

export const HbtUseModifyInternalPIUserFunctions = (
  formMethod: { reset: Function; setValue: Function; register: Function },
  isInternal: boolean,
  dispatch: React.Dispatch<HbtModifyInternalPIUserAction>,
  push: Function,
  userData: UserAndAccesses | null,
  sitecoreContext?: any
) => {
  const isReadOnlyUser = useMemo(() => {
    const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
    return isInternal && isUserInRoles(ModuleMapping.admin, [UserRole.ReadOnly], moduleRoleMapping);
  }, []);

  const sectionsLookup: Record<string, React.FC<any>> = {
    HbtInternalPIUserDetails,
    HbtInternalPIUserLevelAccess
  };

  const registerAndSet = (fieldName: string, value: unknown) => {
    formMethod.register(fieldName);
    formMethod.setValue(fieldName, value);
  };

  const initializeFormValues = () => {
    if (userData != null) {
      formMethod.reset(userData);

      registerAndSet('userTypeCode', userData.userTypeCode);

      // shouldn't register emailID each time because this field is using the useController
      formMethod.setValue('emailID', userData.emailID);

      if (isInternal) {
        userData?.userAccesses?.forEach((userAccess, index) => {
          const userAccessPath = `userAccesses[${index}]`;
          registerAndSet(`${userAccessPath}.access.default`, { activeFlag: false });
          registerAndSet(`${userAccessPath}.access.claim`, { activeFlag: false });
          registerAndSet(`${userAccessPath}.access.arrears`, { activeFlag: false });
          registerAndSet(`${userAccessPath}.access.admin`, userAccess?.access?.admin);
          registerAndSet(`${userAccessPath}.access.piRequest`, userAccess?.access?.piRequest);
          registerAndSet(
            `${userAccessPath}.access.piPurposeTest`,
            userAccess?.access?.piPurposeTest
          );
          registerAndSet(`${userAccessPath}.access.piFileShare`, userAccess?.access?.piFileShare);

          if (userAccess?.access?.piRequest?.activeFlag) {
            registerAndSet(
              `${userAccessPath}.access.piRequest.roleCode`,
              userAccess?.access?.piRequest?.roleCode?.toString()
            );
          }

          if (userAccess?.access?.piPurposeTest?.activeFlag) {
            registerAndSet(
              `${userAccessPath}.access.piPurposeTest.roleCode`,
              userAccess?.access?.piPurposeTest?.roleCode
            );
          }

          if (userAccess?.access?.piFileShare?.activeFlag) {
            registerAndSet(
              `${userAccessPath}.access.piFileShare.roleCode`,
              userAccess?.access?.piFileShare?.roleCode
            );
          }
        });

        registerAndSet('userAccesses[0].access.piRequest', {
          activeFlag: userData.userAccesses?.[0]?.access?.piRequest?.activeFlag ?? false
        });
        registerAndSet('userAccesses[0].access.piPurposeTest', {
          activeFlag: userData.userAccesses?.[0]?.access?.piPurposeTest?.activeFlag ?? false
        });
        registerAndSet('userAccesses[0].access.piFileShare', {
          activeFlag: userData.userAccesses?.[0]?.access?.piFileShare?.activeFlag ?? false
        });
      }

      if (!isInternal) {
        userData.userAccesses?.forEach((userAccess, index) => {
          const access = `userAccesses[${index}].access.admin`;
          const defaultFinancialInstitutionCode = `userAccesses[${index}].defaultFinancialInstitutionCode`;
          const defaultTransitNumber = `userAccesses[${index}].defaultTransitNumber`;
          const financialInstitutionCodes = `userAccesses[${index}].financialInstitutionCodes`;
          const allowedTransitNumbers = `userAccesses[${index}].allowedTransitNumbers`;
          const statusCode = `userAccesses[${index}].statusCode`;

          registerAndSet(access, {
            activeFlag: userAccess?.access?.admin?.activeFlag ?? false
          });

          registerAndSet(
            defaultFinancialInstitutionCode,
            userAccess?.defaultFinancialInstitutionCode ?? '000'
          );

          registerAndSet(defaultTransitNumber, userAccess?.defaultTransitNumber ?? '00000');

          registerAndSet(
            financialInstitutionCodes,
            userData?.userAccesses?.map(
              (userinfo: UserAccess) => userinfo?.financialInstitutionCode
            )
          );

          registerAndSet(allowedTransitNumbers, userAccess?.allowedTransitNumbers ?? []);

          registerAndSet(statusCode, userAccess?.statusCode);
        });
      }
    }
  };

  const isEditingOwnProfile = sitecoreContext?.user?.userID === userData?.userID;

  useEffect(() => {
    formMethod.register('emailID');
  }, []);

  useEffect(() => {
    initializeFormValues();
  }, [userData]);

  return {
    isEditingOwnProfile,
    isReadOnlyUser,
    sectionsLookup,
    initializeFormValues,
    onCancel: () => {
      push(`/${i18n.language}${pathNames.userManagement}`);
    },
    onDeleteAccountButtonClick: () => {
      dispatch({ action: HbtModifyInternalPIUserActionType.SHOW_MODAL });
    },
    onDismissToast: () => {
      dispatch({ action: HbtModifyInternalPIUserActionType.DISMISS_TOAST });
    },
    onDismissModal: () => {
      dispatch({ action: HbtModifyInternalPIUserActionType.DISMISS_MODAL });
    },
    onDismissUpdateModal: () => {
      dispatch({ action: HbtModifyInternalPIUserActionType.DISMISS_UPDATE_MODAL });
    }
  };
};
