import { convertDateForUi } from 'Components/Common/Api/utils/DateParser';
import i18n from 'i18next';
import { LanguageShort } from '@hobt/constants';

export const convertTimestampToShortDate = (timestamp: string): string => {
  return convertDateForUi(timestamp, i18n.language as LanguageShort, true);
};

export enum ApprovalsInventoryTabEnum {
  ArrearsInReview = 'arrearsInReview',
  ArrearsApproved = 'arrearsApproved',
  ClaimsInReview = 'approvalRequired',
  ClaimsApproved = 'approved'
}

export enum Status {
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected'
}
