import React, { PropsWithChildren, useContext } from 'react';
import Select, { components } from 'react-select';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { dropdownStyles } from 'Feature/Claims/components/Details/AdjudicationCardHeader/AdjudicationAssignmentStatus/dropdownStyles';
import { Modal } from 'Components/Common/Modal';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { useAssignClaim } from 'Feature/Claims/components/Details/AdjudicationCardHeader/AdjudicationAssignmentStatus/AdjudicationAssignment/useAssignClaim';

import { SitecoreContentContext } from 'Feature/Claims/components/Details';
import { AdjudicationAssignmentProps } from 'Feature/Claims/components/Details/AdjudicationCardHeader/AdjudicationAssignmentStatus/AdjudicationAssignment/types';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import styles from './styles.module.scss';

const Control: React.FC = ({ children, ...props }: PropsWithChildren<any>) => {
  return (
    <components.Control {...props}>
      <div className={styles.assignmentUserIconContainer}>
        <span className="material-icons align-self-center">person_outline</span>
      </div>
      {children}
    </components.Control>
  );
};

export const AdjudicationAssignment: React.FC<AdjudicationAssignmentProps> = ({
  onErrorDefaultCallback
}: AdjudicationAssignmentProps) => {
  const authContext = useAuthenticationContext();
  const { isReadOnlyAccessUser, updateClaimData } = useHBTFormContext();
  const {
    currentOwner,
    modalIsActive,
    users,
    hideConfirmationModal,
    onAssignChange,
    onConfirmOwnerChange
  } = useAssignClaim(null, authContext, updateClaimData, onErrorDefaultCallback);

  const { fields } = useContext(SitecoreContentContext);

  return (
    <div className={styles.assignmentWrapper}>
      <Modal
        type={ApplicationStates.WARNING}
        style=""
        title={fields.warningModal.fields.heading?.value ?? ''}
        isActive={modalIsActive}
        onCloseCallback={hideConfirmationModal}
        onPrimaryCallback={onConfirmOwnerChange}
        onSecondaryCallback={hideConfirmationModal}
        content={fields.warningModal.fields.content?.value}
        fields={{
          cancelButton: fields.warningModal.fields.cancelButton,
          saveButton: fields.warningModal.fields.acceptButton
        }}
      />
      <label htmlFor="assignmentTo" className={styles.inlineLabel}>
        <span className={styles.labelText}>
          <Text field={fields.ownerLabel} />
        </span>
        <Select
          className={styles.assignmentDropdown}
          name="assignmentTo"
          id="assignmentTo"
          aria-label="assignmentTo"
          styles={dropdownStyles}
          placeholder={fields.assignOwnerPlaceholderText.value ?? ''}
          components={{ Control }}
          maxMenuHeight={294}
          options={users}
          onChange={onAssignChange}
          value={currentOwner}
          noOptionsMessage={() => fields.noOptionsText.value ?? ''}
          isDisabled={isReadOnlyAccessUser}
        />
      </label>
    </div>
  );
};
