import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useFeature } from 'flagged';
import i18n from 'i18next';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { RichTextProps } from '@sitecore-jss/sitecore-jss-react/types/components/RichText';

import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { AttachmentsView } from 'Components/Common/AttachmentsView';
import FormInputWrapper from 'Components/Inputs/FormInputWrapper';
import FormDropdown from 'Components/Inputs/FormDropdown';
import { Button } from 'Components/Common/Button';
import FormTextArea from 'Components/Inputs/FormTextArea';

import { config } from '../../../../../../config';
import useCancelClaimFunctions from './useCancelClaim';
import { AllowedFileTypes, CancelClaimProps } from './types';
import styles from './styles.module.scss';

export const CancelClaimForm: React.FC<CancelClaimProps> = ({
  onCancelCallback,
  onSuccessCallback,
  onErrorCallback,
  setIsLoadingCallback,
  loadingStatus,
  fields,
  cancelClaimData
}: CancelClaimProps) => {
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);
  const {
    hookForm,
    handleSubmit,
    onSubmitErrors,
    onCancelReasonChange,
    watchNotes,
    onNotesChange,
    displayedRequestTemplate,
    watchCancelReason,
    cancelFlyout
  } = useCancelClaimFunctions(
    onSuccessCallback,
    onErrorCallback,
    onCancelCallback,
    (fields?.notificationTemplate?.value as string) || '',
    fields.categoryList,
    cancelClaimData,
    fields.cancellationNoteToken?.value || '',
    fields.cancellationReasonToken?.value || '',
    setIsLoadingCallback
  );
  const [notificationTemplate, setNotificationTemplate] = useState<RichTextProps>({
    field: { value: '' }
  });

  useEffect(() => {
    onNotesChange(watchNotes);
  }, [watchNotes]);

  useEffect(() => {
    onCancelReasonChange();
  }, [watchCancelReason]);

  useEffect(() => {
    hookForm.setValue('cancelNotificationTemplate', displayedRequestTemplate);
    const updatedTemplate: RichTextProps = {
      field: {
        value: displayedRequestTemplate
      }
    };
    setNotificationTemplate(updatedTemplate);
  }, [displayedRequestTemplate]);

  return (
    <Scrollbars>
      <div className={styles.cancelClaimFormHeader} data-testid={`cancelClaimForm`}>
        <Text
          className={`${styles.textHeader} ${styles.header}`}
          tag="h2"
          field={fields.formTitle}
        />
        <div className={styles.closeButton}>
          <button
            type="button"
            data-testid="addCancelClaimCloseButton"
            onClick={cancelFlyout}
            aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
          >
            <span className="material-icons align-self-center">close</span>
          </button>
        </div>
      </div>
      <div className={`${styles.cancelClaimFormBody}`}>
        <Text
          className={`${styles.textStyle}`}
          tag="p"
          field={siteTypeIsInternal ? fields.content : { value: '' }}
        />

        <FormProvider {...hookForm}>
          <form
            className={styles.cancelForm}
            onSubmit={hookForm.handleSubmit(handleSubmit, onSubmitErrors)}
            id="cancelClaimForm"
          >
            <div className={styles.templateWrapper}>
              <FormDropdown
                name="cancellationReasonCode"
                label={fields.categoryLabel}
                options={
                  siteTypeIsInternal === true
                    ? fields.categoryList?.fields?.listItems
                    : fields.categoryList?.fields?.listItems?.slice(0, 3)
                }
                setAsNumber={false}
              />
              {siteTypeIsInternal && (
                <div className={styles.notificationTemplate}>
                  <FormInputWrapper
                    name="notificationTemplateLabel"
                    label={fields.notificationTemplateTitle}
                  />
                  <RichText
                    field={notificationTemplate.field}
                    className={styles.notificationTemplateWrapper}
                    name="cancelNotificationTemplate"
                  />
                </div>
              )}
              <RichText field={fields.helpText ?? { value: '' }} />
              <FormTextArea
                name="remarkText"
                label={fields.note}
                className={styles.cancelNotificationTemplate}
                placeholder={fields.notePlaceholder.value}
                charLimit={Number(100)}
                textAreaHelperText={fields?.characterLimit?.value ?? ''}
              />
            </div>
            {React.createElement(AttachmentsView, {
              isOptional: true,
              registeredVal: 'attachments',
              fields: fields.attachmentsView.fields,
              endpoint: config.claimApi.urls.upload,
              allowedFileTypes: AllowedFileTypes
            })}

            <div className={`${styles.twoColumns} ${styles.buttonWrapper}`}>
              <Button
                name="submitButton"
                responsive
                loading
                type="submit"
                ariaText={fields.submitBtn}
                text={fields.submitBtn}
                disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
                icon={
                  loadingStatus?.isLoading && loadingStatus?.isFlyout
                    ? () => (
                        <span className={styles.saveIconWrapper}>
                          <span className={`material-icons align-self-center`}>loop</span>
                        </span>
                      )
                    : undefined
                }
              />
              <Button
                secondaryButton
                responsive
                onClick={cancelFlyout}
                name={`CancelButton`}
                text={fields.cancelBtn}
                type="button"
                disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </Scrollbars>
  );
};
