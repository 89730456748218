import { SitecoreDropdownList } from 'Constants/Types/SitecoreListItem';
import { HbtFileExtensionType } from '@hobt/constants';
import { AttachmentsViewPropsFields } from 'Components/Common/AttachmentsView/types';
import { ImageProps } from '@sitecore-jss/sitecore-jss-react/types/components/Image';

export default interface ManageUsersInternalProps {
  fields: ManageUsersInternalPropsFields;
  isActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ManageUsersInternalPropsFields {
  email: FieldValue;
  name: FieldValue;
  admin: FieldValue;
  errorCode: FieldValue;
  errorMessage: FieldValue;
  heading: FieldValue;
  pageTitle: FieldValue;
  userLabel: FieldValue;
  cmhcLevel: FieldValue;
  addUserBtn: FieldValue;
  downloadBtn: FieldValue;
  uploadBtn: FieldValue;
  description: FieldValue;
  statusLabel: FieldValue;
  claimsLabel: FieldValue;
  arrearsLabel: FieldValue;
  defaultLabel: FieldValue;
  telephoneLabel: FieldValue;
  extensionLabel: FieldValue;
  lastLogInLabel: FieldValue;
  typeOfUserLabel: FieldValue;
  internalText: FieldValue;
  externalText: FieldValue;
  tableAccessibilityText: FieldValue;
  accountStatus: SitecoreDropdownList;
  years: FieldValue;
  months: FieldValue;
  userRoles: SitecoreDropdownList;
  userTitles: SitecoreDropdownList;
  downloadUserReport: DownloadReportTypeProps;
  uploadFiles: UploadFileTypeProps;
  internalTemplateBtn: FieldValue;
  externalTemplateBtn: FieldValue;
  internalTemplateLink: MediaDocumentProps;
  externalTemplateLink: MediaDocumentProps;
  externalTab: UsersExternalPropsFields;
}

export interface UsersExternalPropsFields {
  fields: {
    admin: FieldValue;
    email: FieldValue;
    name: FieldValue;
    firstName: FieldValue;
    lastName: FieldValue;
  };
}
export interface UploadFileTypeProps {
  fields: {
    email: FieldValue;
    errorCode: FieldValue;
    errorMessage: FieldValue;
    modalTitle: FieldValue;
    uploadBtn: FieldValue;
    cancelBtn: FieldValue;
    downloadBtn: FieldValue;
    uploadFileNameLabel: FieldValue;
    deleteIcon: ImageProps;
    attachmentsView: AttachmentsViewComponentProps;
  };
  tabName: String;
  isActive: () => void;
}
interface MediaDocumentProps {
  fields: {
    title: GenericLinkItem;
  };
}
interface GenericLinkItem {
  value: {
    href: string;
    text: string;
  };
}
export interface DownloadReportTypeProps {
  fields: {
    modalTitle: FieldValue;
    submitBtn: FieldValue;
    cancelBtn: FieldValue;
    years: FieldValue;
    months: FieldValue;
  };
  isActive: () => void;
  isSuccess: (result: any) => any;
}

export interface BulkUserUploadErrorResp {
  emailID: string;
  error: UploadError;
}

interface UploadError {
  appErrorMessage: string;
  appErrorName: string;
  code: string;
  httpCode: number;
  location: string;
  name: string;
}

export interface ExternalTabUserInventoryRecords {
  userID: string;
  emailID?: string;
  firstName?: string;
  lastName?: string;
}

export interface UserMangementInternalInventoryRecords {
  userID: string;
  claimRoleCode?: number;
  titleName?: string;
  statusCode?: number;
  arrearsRoleCode?: number;
  defaultRoleCode?: number;
  fullName?: string;
  extensionPhoneNumber?: string;
  phoneNumber?: string;
  emailID?: string;
  loginTimestamp?: string;
  recordUpdatedTimestamp?: string;
  titleTypeCode?: number;
}

export interface AttachmentsViewComponentProps {
  fields: AttachmentsViewPropsFields;
}

export const AllowedFileTypes = [HbtFileExtensionType.XLSX, HbtFileExtensionType.XLS];

export enum Tab {
  internal = 1,
  external = 2
}
