export interface ToastNotificationProps {
  actionable: boolean;
  header: FieldValue;
  icon?: string;
  open: boolean;
  subHeader: FieldValue;
  variant: ToastNotificationVariant;
  onClose: () => void;
}

export enum ToastNotificationVariant {
  INFO = 'info',
  SUCCESS = 'success'
}
