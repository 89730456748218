/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import ConditionalRender from 'Components/Common/ConditionalRender';
import FormCard from 'Components/Common/FormCard';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormTextArea from 'Components/Inputs/FormTextArea';
import FormYesNoRadios from 'Components/Inputs/FormYesNoRadios';

import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

import styles from './styles.module.scss';
import { useFeature } from 'flagged';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

const ManualReview = (props: any) => {
  const { isFieldDisabled } = useHBTFormContext();
  const { register, setValue, unregister } = useFormContext();
  const isInternalSite = useFeature(FeatureFlags.INTERNAL);

  const manualReviewRequiredFlag = useWatch({
    name: 'indicator.manualReviewRequiredFlag'
  });
  const manualReviewRequiredReasonTypeCode = useWatch({
    name: 'indicator.manualReviewRequiredReasonTypeCode'
  });
  const [isOptionDisabled, setIsOptionDisabled] = useState(false);

  useEffect(() => {
    if (!isOptionDisabled) {
      register('indicator.manualReviewRequiredFlag');
      setValue(
        'indicator.manualReviewRequiredFlag',
        manualReviewRequiredFlag === 'true' || manualReviewRequiredFlag === true
      );
    }
    if (isInternalSite) {
      setValue(
        'indicator.manualReviewRequiredFlag',
        manualReviewRequiredFlag === 'true' || manualReviewRequiredFlag === true
      );
      if (
        manualReviewRequiredFlag === 'false' ||
        manualReviewRequiredFlag === false
      ) {
        setValue('indicator.manualReviewRequiredReasonTypeCode', null);
      }
    }
  }, [manualReviewRequiredFlag]);

  return (
    <>
      <FormCard
        title={props.fields.cardTitle}
        headingLevel={2}
        sectionId="claims-manual-review-body"
        fieldToValidate="manualReview"
      >
        <FormYesNoRadios
          className={styles.full}
          testid="indicator.manualReviewRequiredFlag"
          data-testid="indicator.manualReviewRequiredFlag"
          label={props.fields.subtitleCard}
          name="indicator.manualReviewRequiredFlag"
          handleChange={(e) => {
            e.preventDefault;
            setIsOptionDisabled(true);
            register('indicator.manualReviewRequiredFlag');
            // @ts-ignore
            setValue('indicator.manualReviewRequiredFlag', e.target.value === 'true');
            // @ts-ignore
            if (e.target?.value === 'false' || e.target?.value === false) {
              unregister('indicator.manualReviewRequiredReasonTypeCode');
            }
          }}
          isReadOnly={isFieldDisabled}
        />

        <ConditionalRender
          condition={manualReviewRequiredFlag === true || manualReviewRequiredFlag === 'true'}
        >
          <FormDropdown
            className={styles.full}
            label={{ value: props.fields.manualReviewReasonLabel.value }}
            name="indicator.manualReviewRequiredReasonTypeCode"
            options={props.fields.manualReviewReasonOptions[0].fields.listItems}
            isDisabled={isFieldDisabled}
            setAsNumber={false}
          />
        </ConditionalRender>

        {manualReviewRequiredReasonTypeCode === '07' &&
          (manualReviewRequiredFlag === true || manualReviewRequiredFlag === 'true') && (
            <FormTextArea
              className={styles.full}
              label={{ value: props.fields.description.value }}
              name="indicator.otherManualReviewRequiredReasonDescription"
              textAreaHelperText={props.fields.characterLimit.value}
              charLimit={Number(props.fields.limitNumber.value)}
              isReadOnly={isFieldDisabled}
            />
          )}
      </FormCard>
    </>
  );
};

export default ManualReview;
