import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import Select, { components, OptionProps, DropdownIndicatorProps } from 'react-select';
import { Scrollbars } from 'react-custom-scrollbars-2';
import i18n from 'i18next';

import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { Button } from 'Components/Common/Button';
import FormTextArea from 'Components/Inputs/FormTextArea';
import FormInputWrapper from 'Components/Inputs/FormInputWrapper';
import FormRadioGroup from 'Components/Inputs/FormRadioGroup';

import { DocumentRequestFormProps, Category } from './types';
import useDocumentRequestForm from './useDocumentRequestForm';
import { customStyles } from './selectCustomStyles';
import styles from './styles.module.scss';
import { IndicatorProps } from 'react-select/src/components/indicators';

export const DocumentRequestForm: React.FC<DocumentRequestFormProps> = ({
  fields,
  onCancelCallback,
  onSuccessCallback
}: DocumentRequestFormProps) => {
  const { claimData, loadingStatus } = useHBTFormContext();

  const { handleSubmit, onSubmitErrors, onCategoriesChange, hookForm, requestTemplateContent } =
    useDocumentRequestForm(
      fields,
      claimData?.claimTypeIndicator,
      claimData?.approvedLenderReferenceNumber,
      onSuccessCallback
    );

  // Needed to remove the built in clear button that appears beside each item in the select text Box.
  const MultiValueRemove: FC = () => <></>;
  const DropdownIndicator: FC<DropdownIndicatorProps<Category, boolean>> = (
    props: DropdownIndicatorProps<Category, boolean>
  ) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <span className="material-icons expand-more">expand_more</span>
        </components.DropdownIndicator>
      )
    );
  };

  const Option: FC<OptionProps<Category, boolean>> = (props: OptionProps<Category, boolean>) => {
    return (
      components.Option && (
        <components.Option {...props}>
          <div className={styles.selectOption}>
            <input
              type="checkbox"
              id={`${props.label}${props.getValue()}`}
              name={`${props.getValue()}`}
              value={`${props.getValue()}`}
              checked={props.isSelected}
              readOnly
            />
            <span className="checkmark" />
            <label htmlFor={`${props.label}${props.getValue()}`}>{props.label}</label>
          </div>
        </components.Option>
      )
    );
  };

  const options = fields.categoryList?.fields?.listItems?.map(
    (listItem) =>
      ({
        label: listItem.fields.itemName.value,
        value: listItem.fields.itemValue.value
      } as Category)
  );

  return (
    <Scrollbars>
      <div className={styles.headerWrapper}>
        <Text tag="h2" field={fields.header} />
        <div className={styles.closeButton}>
          <button
            type="button"
            data-testid="documentRequestFormCloseButton"
            onClick={() => {
              hookForm.reset();
              if (onCancelCallback) onCancelCallback();
            }}
            aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
          >
            <span className="material-icons align-self-center">close</span>
          </button>
        </div>
      </div>

      <FormProvider {...hookForm}>
        <form
          onSubmit={hookForm.handleSubmit(handleSubmit, onSubmitErrors)}
          id="documentRequestForm"
          className={styles.documentRequestWrapper}
        >
          <p className={styles.textWrapper}>
            <Text field={fields.subheader} />
          </p>

          <div className={styles.radioWrapper}>
            <FormRadioGroup
              name="languageCode"
              className=""
              label={fields.languageSelectionLabel}
              radioLabels={fields.languageOptions}
              defaultSelection={claimData?.lender?.correspondenceLanguageCode}
              setAsNumber={true}
            />
          </div>

          <div className={styles.categoriesWrapper}>
            <FormInputWrapper name="documentRequestCategoryCode" label={fields.categoryLabel}>
              <Select
                id="documentRequestCategoryCodeSelect"
                isMulti
                isClearable={true}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                components={{ MultiValueRemove, DropdownIndicator, Option }}
                styles={customStyles}
                onChange={onCategoriesChange}
                placeholder={fields.categorySelectLabel?.value || ''}
                options={options}
              />
            </FormInputWrapper>
          </div>

          <div className={styles.notesWrapper}>
            <FormInputWrapper
              name="requestTemplateLabel"
              label={fields.documentationRequestNotes.value}
            >
              <FormTextArea
                name="remarkText"
                className={styles.notesTextArea}
                label={fields.documentationRequestNotesLabel}
                textAreaHelperText={fields.characterLimit?.value || ''}
                charLimit={1000}
              />
            </FormInputWrapper>
          </div>

          <div className={styles.templateWrapper}>
            <FormInputWrapper name="requestTemplateLabel" label={fields.requestTemplateLabel} />
            <RichText
              field={requestTemplateContent.field}
              className={styles.requestTemplateContentWrapper}
              name="declineNotificationTemplate"
            />
          </div>

          <div className={styles.buttonWrapper}>
            <Button
              name={'submitButton'}
              type="submit"
              responsive
              loading
              ariaText={fields.submitButton}
              text={fields.submitButton}
              disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
              icon={
                loadingStatus?.isLoading && loadingStatus?.isFlyout
                  ? () => (
                      <span className={styles.saveIconWrapper}>
                        <span className={`material-icons align-self-center`}>loop</span>
                      </span>
                    )
                  : undefined
              }
            />
            <Button
              name={`cancelButton`}
              type="button"
              responsive
              secondaryButton
              text={fields.cancelButton}
              ariaText={fields.cancelButton}
              disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
              onClick={() => {
                hookForm.reset();
                if (onCancelCallback) onCancelCallback();
              }}
            />
          </div>
        </form>
      </FormProvider>
    </Scrollbars>
  );
};
