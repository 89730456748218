import * as React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import FooterProps from 'Feature/Footer/components/FooterComponent/types';
import { FooterNav } from 'Feature/Footer/components/FooterComponent/FooterNav';

import styles from './styles.module.scss';

const FooterComponent = ({ fields }: FooterProps) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <FooterNav links={fields.links} />
        <Image field={fields.canadaLogo} />
      </div>
    </footer>
  );
};

export default FooterComponent;
