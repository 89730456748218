import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import i18n from 'i18next';
import {
  HbtModifyInternalPIUserReducerState,
  HbtModifyInternalPIUserAction,
  HbtModifyInternalPIUserActionType,
  HbtModifyInternalPIUserActionValuePayload,
  HbtModifyInternalPIUserActionFunctionPayload
} from './types';

export const HbtModifyInternalPIUserReducer = (
  state: HbtModifyInternalPIUserReducerState,
  { action, payload }: HbtModifyInternalPIUserAction
): HbtModifyInternalPIUserReducerState => {
  const newState = { ...state };

  const reducers = {
    [HbtModifyInternalPIUserActionType.SET_IS_MODAL_LOADING]: () => {
      newState.isModalLoading = (payload as HbtModifyInternalPIUserActionValuePayload).value;
    },
    [HbtModifyInternalPIUserActionType.SET_IS_LOADING]: () => {
      newState.isLoading = (payload as HbtModifyInternalPIUserActionValuePayload).value;
    },
    [HbtModifyInternalPIUserActionType.SET_IS_PAGE_LOADING]: () => {
      newState.isPageLoading = (payload as HbtModifyInternalPIUserActionValuePayload).value;
    },
    [HbtModifyInternalPIUserActionType.SUBMIT_SUCCESS]: () => {
      newState.isBypass = true;
      newState.isLoading = false;
      newState.modalActive = false;
      newState.toastProps = {
        isActive: true,
        title: i18n.t('Globals-Toast-Success-Title'),
        type: ApplicationStates.SUCCESS,
        content: i18n.t('UserManagement-ModifyUsers-SuccessToastMessage'),
        onCloseCallback: (payload as HbtModifyInternalPIUserActionFunctionPayload).function
      };
    },

    [HbtModifyInternalPIUserActionType.ON_ERROR]: () => {
      newState.isBypass = false;
      newState.isLoading = false;
      newState.toastProps = {
        isActive: true,
        title: i18n.t('Globals-Toast-Error-Title'),
        type: ApplicationStates.ERROR,
        content: i18n.t('Globals-UnknownErrorMessage'),
        onCloseCallback: (payload as HbtModifyInternalPIUserActionFunctionPayload).function
      };
    },

    [HbtModifyInternalPIUserActionType.DISMISS_MODAL]: () => {
      newState.modalActive = false;
    },

    [HbtModifyInternalPIUserActionType.SHOW_MODAL]: () => {
      newState.isBypass = false;
      newState.modalActive = true;
    },
    [HbtModifyInternalPIUserActionType.SHOW_UPDATE_MODAL]: () => {
      newState.isBypass = false;
      newState.updateModalActive = true;
    },
    [HbtModifyInternalPIUserActionType.DISMISS_UPDATE_MODAL]: () => {
      newState.updateModalActive = false;
    },

    [HbtModifyInternalPIUserActionType.DISMISS_TOAST]: () => {
      newState.toastProps.isActive = false;
    }
  };

  reducers[action]();

  return newState;
};
