import * as React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import ContentBlockProps from 'Feature/PageComponents/models/ContentBlockProps';

const ContentBlock = (props: ContentBlockProps) => (
  <div className="card" style={{ borderRadius: '12px', padding: '20px 25px' }}>
    <div className="card-body">
      <h2 className="card-title" style={{ marginBottom: '25px', marginTop: '50px' }}>
        <Text field={props.fields.heading} />
      </h2>
      <Text tag="h6" field={props.fields.subheading} />
      <hr />
      <RichText field={props.fields.content} />
    </div>
  </div>
);

export default ContentBlock;
