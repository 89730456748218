import React, { useCallback, useMemo, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import {
  FlyoutMenuProps,
  MenuItemComponentProps,
  MenuProps,
  NestedMenuType
} from 'Components/Core/Menu/types';
import useEnterOrSpaceKey from 'Components/Hooks/UseEnterOrSpaceKey';
import i18n from 'i18next';
import Scrollbars from 'react-custom-scrollbars-2';
import { NavLink, useHistory } from 'react-router-dom';
import styles from './styles.module.scss';

/**
 * @param hasChevron - whether to show the chevron icon or not, default to true.
 * @param hasWrapperBorder - whether to show the border around the menu or not, default to true for OnPage menu.
 * @param isOnPage - whether the menu is OnPage or InFlyout. Developer doesn't need to pass this prop, it's used internally.
 * @param isLogoutOptionRequired - whether to show logout button in header side menu.
 * @param items - array of menu items that follows the MenuItem type.
 * @param hasBackIcon - whether to show the back icon or not, default to true for InFlyout menu.
 * @param headerText - the header text for the InFlyout menu.
 * @param maxSubMenuLayers - the maximum number of sub menu layers to show, default to 3.
 * @param logoutLabel - the label text of the logout button.
 * @param onClickLogout - the event handler for the logout button.
 * @param onClickBackIcon - the event handler for the back icon button.
 * @param onClickClose - the event handler for the close icon button.
 */

/**
 * Menu component
 *
 * This is the Menu component written using storybook, which has two types of menu: OnPage and InFlyout.
 */

export const MenuDimensionsMapping: Record<NestedMenuType, React.CSSProperties> = {
  [NestedMenuType.OnPage]: {
    width: '421px',
    height: '732px'
  },
  [NestedMenuType.InFlyout]: {
    width: '620px',
    height: '1024px'
  }
};

const OnPageMenu: React.FC<MenuProps> = React.memo(
  ({
    items,
    hasChevron = true,
    hasWrapperBorder = true,
    isOnPage = true,
    isLogoutOptionRequired,
    logoutLabel,
    maxSubMenuLayers = 3,
    onClickLogout,
    onNavLinkClick
  }) => {
    const dimensions = useMemo(
      () =>
        isOnPage
          ? MenuDimensionsMapping[NestedMenuType.OnPage]
          : MenuDimensionsMapping[NestedMenuType.InFlyout],
      [isOnPage]
    );

    const handleLogout = useCallback(() => {
      if (onClickLogout) onClickLogout();
    }, [onClickLogout]);

    return (
      <Scrollbars style={dimensions} autoHide>
        <div className={hasWrapperBorder ? styles.menuWrapper : ''}>
          <ul className={styles.menu}>
            {items.map((item, index) => (
              <MenuItemComponent
                key={index}
                item={item}
                hasChevron={hasChevron}
                maxSubMenuLayers={maxSubMenuLayers}
                currentLayer={1}
                onNavLinkClick={onNavLinkClick}
              />
            ))}
            {isLogoutOptionRequired && (
              <li
                className={styles.menuItem}
                data-testid="menuItem"
                role="menuitem"
                aria-label={logoutLabel?.value}
              >
                <a
                  tabIndex={0}
                  className={`${styles.menuLink} ${styles.logoutBtnLink}`}
                  data-testid="menuLink"
                  onClick={onClickLogout}
                  onKeyDown={useEnterOrSpaceKey(handleLogout)}
                >
                  <span className={styles.label}>{logoutLabel?.value}</span>
                </a>
              </li>
            )}
          </ul>
        </div>
      </Scrollbars>
    );
  }
);

const InFlyoutMenu: React.FC<FlyoutMenuProps> = React.memo((props) => {
  const titlePadding = useMemo(
    () => (props.hasBackIcon ? styles.titlePadding : ''),
    [props.hasBackIcon]
  );
  const titleContainer = useMemo(() => `${styles.titleWrapper} ${titlePadding}`, [titlePadding]);

  return (
    <div className={styles.flyout}>
      <div className={styles.headerWrapper} data-testid="headerWrapper" aria-labelledby="menuTitle">
        {props.hasBackIcon && (
          <div className={styles.headerButton}>
            <button
              type="button"
              aria-label={i18n.t('Accessibility-Back-Button') ?? 'Back'}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (props.onClickBackIcon) props.onClickBackIcon(e);
              }}
            >
              <HbtIcon type={'icon_chevron_left'} size={HbtIconSize.LARGE} />
            </button>
          </div>
        )}
        <div className={titleContainer} id="menuTitle">
          <Text className={styles.titleText} tag="h2" field={props.headerText} />
        </div>
        <div className={styles.headerButton}>
          <button
            type="button"
            aria-label={`${i18n.t('Accessibility-Close-Button', 'Close')} ${
              props.headerText?.value
            }`}
            onClick={props.onClickClose}
          >
            <HbtIcon type={'icon_close'} size={HbtIconSize.LARGE} />
          </button>
        </div>
      </div>
      <OnPageMenu
        hasWrapperBorder={false}
        isOnPage={false}
        {...props}
        onNavLinkClick={props.onNavLinkClick}
      />
    </div>
  );
});

const MenuItemComponent: React.FC<MenuItemComponentProps> = React.memo(
  ({ item, hasChevron, maxSubMenuLayers, currentLayer, onNavLinkClick }) => {
    const history = useHistory();
    const itemHref =
      item.fields.subMenu && item.fields.subMenu.length > 0
        ? '#'
        : item.fields.navItem?.value?.href || '#';
    const [isOpen, setIsOpen] = useState(false);

    const hasSubMenu = item.fields.subMenu && item.fields.subMenu.length > 0;
    const labelStyles = useMemo(
      () => `${styles.label} ${isOpen || currentLayer == 1 ? styles.boldFont : ''}`,
      [isOpen, currentLayer]
    );

    const handleToggle = useCallback(() => {
      setIsOpen((prev) => !prev);
    }, []);

    const handleNavLinkKeydown = useEnterOrSpaceKey(
      useCallback(
        (e?: React.KeyboardEvent) => {
          if (onNavLinkClick) {
            onNavLinkClick(e);
          }
          if (itemHref && itemHref !== '#') {
            history.push(item.fields.navItem.value.href);
          }
        },
        [onNavLinkClick, history, itemHref]
      )
    );

    const menuLabelStyles = useMemo(() => {
      if (isOpen) {
        return `${styles.menuLabel} ${styles.menuOpen}`;
      }
      return styles.menuLabel;
    }, [isOpen]);

    return (
      <li className={styles.menuItem} data-testid="menuItem" aria-label={item.fields.text.value}>
        {hasSubMenu && currentLayer < maxSubMenuLayers ? (
          <div>
            <div
              role="button"
              aria-expanded={isOpen ? 'true' : 'false'}
              onClick={handleToggle}
              className={menuLabelStyles}
              data-testid="menuLabel"
              tabIndex={0}
              onKeyDown={useEnterOrSpaceKey(handleToggle)}
            >
              <span className={labelStyles}>{item.fields.text.value}</span>
              {hasChevron && (
                <span className={styles.chevronContainer}>
                  <HbtIcon
                    type={isOpen ? 'icon_chevron_up' : 'icon_chevron_down'}
                    size={HbtIconSize.MEDIUM}
                  />
                </span>
              )}
            </div>
            {isOpen && item.fields.subMenu && (
              <ul className={styles.subMenu}>
                {item.fields.subMenu.map((subItem, index) => (
                  <MenuItemComponent
                    key={index}
                    item={subItem}
                    hasChevron={hasChevron}
                    maxSubMenuLayers={maxSubMenuLayers}
                    currentLayer={currentLayer + 1}
                    onNavLinkClick={onNavLinkClick}
                  />
                ))}
              </ul>
            )}
          </div>
        ) : (
          <NavLink
            to={item.fields.navItem.value.href || '#'}
            className={styles.menuLink}
            data-testid="menuLink"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              if (onNavLinkClick) onNavLinkClick(e);
            }}
            onKeyDown={handleNavLinkKeydown}
          >
            <span className={styles.label}>{item.fields.text.value}</span>
          </NavLink>
        )}
      </li>
    );
  }
);

const Menu = {
  OnPage: OnPageMenu,
  InFlyout: InFlyoutMenu
};

export default Menu;
