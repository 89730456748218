import { useFeature } from 'flagged';
import i18n from 'i18next';
import React, { useState, useEffect, useRef } from 'react';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { UserRole, Module } from '@hobt/constants';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useDefaultFormActionsContext } from 'Feature/DefaultsInventory/components/DefaultFormContext';

import { TooltipIcon } from 'Components/Inputs/TooltipIcon';
import { LinedCard, AccordionContainer } from '../../../../CommonComponents/AccordionComponents';
import { deleteCardRepopulateValues, scrollIntoView } from '../../CardReuseableFunctions';
import { OtherFinancialObligationsProps } from './OtherFinancialObligations.types';
import { ComponentType } from '../../../models/typeCode.types';
import FormInputCurrency from '../../FormInputCurrency';
import FormInputTextInput from '../../FormInputTextInput';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const FormContainer: React.FC<OtherFinancialObligationsProps> = ({
  index,
  register,
  errors,
  setValueHandler,
  control,
  watch,
  getValues,
  ...props
}) => {
  const commonCardProps = {
    register,
    errors,
    setValueHandler,
    control,
    watch,
    getValues
  };

  const [formSection, setFormSection] = useState({
    owingTo: '',
    natureOfDebt: ''
  });

  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string,
    name?: string
  ) => {
    const { value } = event.target;
    setFormSection({
      ...formSection,
      [field]: value
    });
    name && setValueHandler && value && setValueHandler(`${name}`, value);
  };

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const defaultFormActions = useDefaultFormActionsContext();

  const natureOfDebt = (watch && watch(`financialObligation[${index}].natureOfDebt`)) || 0;
  const owingTo = (watch && watch(`financialObligation[${index}].owingTo`)) || 0;
  const currentBalance = (watch && watch(`financialObligation[${index}].currentBalance`)) || 0;
  const requiredMonthlyPaymentPrincipalInterest =
    (watch && watch(`financialObligation[${index}].requiredMonthlyPaymentPrincipalInterest`)) || 0;
  const currentArrears = (watch && watch(`financialObligation[${index}].currentArrears`)) || 0;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  useEffect(() => {
    if (defaultFormActions.isCardComplete('otherFinancialObligations')) {
      if (
        !(
          !!natureOfDebt &&
          !!owingTo &&
          !!currentBalance &&
          !!requiredMonthlyPaymentPrincipalInterest &&
          !!currentArrears
        )
      ) {
        defaultFormActions?.removeCompleteCard('otherFinancialObligations');
      }
    } else if (
      !!natureOfDebt &&
      !!owingTo &&
      !!currentBalance &&
      !!requiredMonthlyPaymentPrincipalInterest &&
      !!currentArrears
    ) {
      defaultFormActions?.addCompleteCard('otherFinancialObligations');
    }
  }, [
    natureOfDebt,
    owingTo,
    currentBalance,
    requiredMonthlyPaymentPrincipalInterest,
    currentArrears
  ]);

  return (
    <div
      className="card_body-section obligation-section-1"
      id={`financialObligations-${index || 0}`}
    >
      {index === 0 ? (
        <h3 className="card__body-heading">{props?.primaryObligation?.field?.value}</h3>
      ) : (
        <h3 className="card__body-heading">
          {props?.additionalObligation?.field?.value} {index}
        </h3>
      )}

      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.id}OwingTo${index}`}
            name={`financialObligation[${index}].owingTo`}
            title={props.inputFields.owingTo.field}
            type={ComponentType.Text}
            handleChange={(e) =>
              handleTextChange(e, 'owingTo', `financialObligation[${index}].owingTo`)
            }
            cardName={props.title.field.value}
            isRequired={true}
            autoFocus={props.isAutoFocus}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.id}NatureOfDebt${index}`}
            name={`financialObligation[${index}].natureOfDebt`}
            title={props.inputFields.natureOfDebt.field}
            type={ComponentType.Text}
            handleChange={(e) =>
              handleTextChange(e, 'natureOfDebt', `financialObligation[${index}].natureOfDebt`)
            }
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}CurrentBalance${index}`}
            name={`financialObligation[${index}].currentBalance`}
            title={props.inputFields.currentBalance.field}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}RequiredMonthlyPayment${index}`}
            name={`financialObligation[${index}].requiredMonthlyPaymentPrincipalInterest`}
            title={props.inputFields.requiredMonthlyPayment.field}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}CurrentArrears${index}`}
            name={`financialObligation[${index}].currentArrears`}
            title={props.inputFields.currentArrears.field}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      {index && index > 0 ? (
        <div className="row card__body-row mt-3">
          <div className="col-12 card__actionButtons">
            <button
              className="ml-4 btn btn--icon btn__delete"
              type="button"
              onClick={() => {
                props.removeCard && props.removeCard(index);
              }}
              disabled={isReadOnlyUser || props.disableDelete}
            >
              <span>
                <TooltipIcon
                  icon={props.deleteIcon}
                  text={i18n.t('DefaultSubmission-Card-Delete')}
                  className={'icon-24'}
                />
              </span>
            </button>
          </div>
        </div>
      ) : null}
      <hr className="card__divider card__divider--thick" />
    </div>
  );
};

export const OtherFinancialObligations: React.FC<OtherFinancialObligationsProps> = (props) => {
  const initialIdsArray = [];
  const cardCount = props.cardCount || 1;
  for (let i = 0; i < cardCount; i++) {
    initialIdsArray.push(i);
  }
  const [idsArray, setIdsArray] = useState<number[]>(initialIdsArray);
  const [id, setId] = useState<number>(cardCount - 1);
  const [isAutoFocus, setAutoFocus] = useState<boolean>(false);
  const [lastIndexPreDelete, setLastIndexPreDelete] = useState<number>(cardCount - 1);
  const [disableDelete, setDisableDelete] = useState(false);
  const isInitialMount = useRef(true);

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setIdsArray(idsArray.concat(id));
    }
  }, [id]);

  useEffect(() => {
    // update last index, after adding/deleting a card instance
    setLastIndexPreDelete(idsArray.length - 1);
    setDisableDelete(false); // re-enable delete after delete operation is completed
  }, [JSON.stringify(idsArray)]);

  function addAdditionalGuarantor() {
    setId(id + 1);
    setAutoFocus(true);
  }

  function removeCard(index: number) {
    // manually setting own isDirty check for card deletions on details form
    props.setCardDeleted && props.setCardDeleted(true);

    if (props.getValues && props.setValueHandler && props.watch && props.reset) {
      // prepopulate cards after delete using react-hook-form functions
      deleteCardRepopulateValues(
        props.getValues,
        props.setValueHandler,
        props.watch,
        props.reset,
        index,
        lastIndexPreDelete,
        `financialObligation`,
        props.register
      );
    }

    // Update cards' id array
    const numbersArrayCopy = idsArray || [];
    numbersArrayCopy.splice(index || 0, 1);
    setIdsArray(numbersArrayCopy);

    // Scroll into view after delete
    scrollIntoView(`financialObligations-${lastIndexPreDelete - 1}`);

    // Disable multiclick
    setDisableDelete(true);
  }

  return (
    <LinedCard
      id={props.id}
      testId={props.testId}
      linePosition={props.linePosition}
      lineColor={props.accordionLineColor}
    >
      <AccordionContainer accordionId={`${props.id}Accordion`} title={props.title.field}>
        {idsArray.map((id, index) => (
          <FormContainer
            {...props}
            key={id}
            index={index}
            lastIndexPreDelete={lastIndexPreDelete}
            removeCard={removeCard}
            disableDelete={disableDelete}
            setDisableDelete={setDisableDelete}
            isAutoFocus={isAutoFocus}
          />
        ))}

        <div className="row card__body-row">
          <div className="col-12">
            <button
              className="btn btn__add"
              onClick={addAdditionalGuarantor}
              type="button"
              disabled={isReadOnlyUser}
            >
              <i
                className="material-icons icon--size-32 icon--v-align-middle"
                title={props?.AddObligationText?.field?.value}
                aria-hidden={true}
              >
                add_circle_outline
              </i>
              <Text field={props.AddObligationText.field} />
            </button>
          </div>
        </div>
      </AccordionContainer>
    </LinedCard>
  );
};
