// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone';

export const checkDateFormat = (date: Date | string | null, format: string) => {
  if (moment(date, format).isValid() === true) {
    return date as Date;
  }

  return null;
};
