export type StatusFields = {
  label: string | undefined;
  value: string | undefined;
  color: string | undefined;
};

export enum ToastErrorType {
  AutoAdjudicationDeclined,
  CannotAutoApprove,
  CannotUpdateToAutoApprove
}
