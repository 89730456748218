import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { Scrollbars } from 'react-custom-scrollbars-2';
import i18n from 'i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { CommentType, CorrespondenceType } from '@hobt/claim-domain';

import FormTextArea from 'Components/Inputs/FormTextArea';
import { Button } from 'Components/Common/Button';
import { AttachmentsView } from 'Components/Common/AttachmentsView';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

import { config } from '../../../../../../config';
import { AllowedFileTypes, ContestClaimProps } from './types';
import useContestClaimFunctions from './useContestClaim';
import styles from './styles.module.scss';

export const ContestClaimForm: React.FC<ContestClaimProps> = ({
  onCancelCallback,
  onSuccessCallback,
  onErrorCallback,
  fields
}: ContestClaimProps) => {
  const { hookForm, handleSubmit, onSubmitErrors, userDetails, cancelFlyout } =
    useContestClaimFunctions(onSuccessCallback, onErrorCallback, onCancelCallback);
  const { loadingStatus } = useHBTFormContext();

  useEffect(() => {
    hookForm.register('submitterUserID');
    hookForm.register('submitterUserTypeCode');
    hookForm.register('correspondenceTypeCode');
    hookForm.register('commentTypeCode');

    hookForm.setValue('commentTypeCode', CommentType.ContestDecision);
    hookForm.setValue('submitterUserID', userDetails?.userID);
    hookForm.setValue('submitterUserTypeCode', userDetails?.userTypeCode);
    hookForm.setValue('correspondenceTypeCode', CorrespondenceType.External);
  }, []);

  return (
    <Scrollbars>
      <div className={styles.contestClaimFormHeader} data-testid={`contestClaimForm`}>
        <div className={`${styles.textHeader}`}>
          <Text tag="h2" field={fields.formTitle} />
        </div>
        <div className={styles.closeButton}>
          <button
            type="button"
            data-testid="addContestCloseButton"
            onClick={cancelFlyout}
            aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
          >
            <span className="material-icons align-self-center">close</span>
          </button>
        </div>
      </div>
      <div className={`${styles.contestClaimFormBody}`}>
        <div className={`${styles.textStyle}`}>
          <Text tag="h6" field={fields.content} />
        </div>
        <FormProvider {...hookForm}>
          <form
            className={styles.contestForm}
            onSubmit={hookForm.handleSubmit(handleSubmit, onSubmitErrors)}
            id="contestClaimForm"
          >
            <div className={styles.templateWrapper}>
              <FormTextArea
                name="remarkText"
                label={fields.description}
                textAreaHelperText={fields.characterLimit.value}
                charLimit={parseInt(fields.limitNumber.value || '0', 10)}
                className={styles.templateTextArea}
              />
            </div>
            {React.createElement(AttachmentsView, {
              isOptional: true,
              registeredVal: 'attachments',
              fields: fields.attachmentsView.fields,
              endpoint: config.claimApi.urls.upload,
              allowedFileTypes: AllowedFileTypes
            })}

            <div className={`${styles.twoColumns} ${styles.formButtonsWrapper}`}>
              <div>
                <Button
                  name="submitButton"
                  responsive
                  loading
                  type="submit"
                  ariaText={fields.submitBtn}
                  text={fields.submitBtn}
                  disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
                />
              </div>
              <div>
                <Button
                  secondaryButton
                  responsive
                  onClick={cancelFlyout}
                  ariaText={fields.cancelBtn}
                  name={`cancelButton`}
                  text={fields.cancelBtn}
                  type="button"
                  disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </Scrollbars>
  );
};
