import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { HttpResponseStatusCodes, HbtServiceErrorCodes } from '@hobt/constants';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { config } from '../../../../config';
import { ErrorType } from './types';
import { ErrorObject } from 'Feature/UserManagement/models/types';

const useDeleteClaimFunctions = () => {
  const [showDeleteClaimToast, setShowDeleteClaimToast] = useState<any | boolean>(false);
  const [showServerErrorToast, setShowServerErrorToast] = useState<boolean>(false);
  const [showPageModal, setShowPageModal] = useState<boolean>(false);
  const [deleteClaimID, setDeleteClaimID] = useState<number | string>('');
  const [errorType, setErrorType] = useState<ErrorType | null>(null);
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

  // API Client Config
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  const authContext = useAuthenticationContext();
  const { deleteWithAuth } = ApiClient(authContext, apiClientConfig);

  const onSuccessCallback = (refresh: Function) => {
    setShowDeleteClaimToast(ApplicationStates.SUCCESS);
    refresh();
  };

  const onErrorCallback = (typeOfError?: ErrorType) => {
    if (typeOfError != null) {
      setErrorType(typeOfError);
    }
    setShowDeleteClaimToast(ApplicationStates.ERROR);
  };

  const closeDeleteClaimToast = () => {
    setShowDeleteClaimToast(false);
    setShowServerErrorToast(false);
  };

  const onDeleteClick = (id: number | string) => {
    setDeleteClaimID(id);
    setShowPageModal(true);
  };

  const onDeleteClaim = (claimID: number | string, refresh: Function) => {
    const url = `${config.claimApi.urls.finalClaim}/${claimID}`;

    setIsModalLoading(true);

    deleteWithAuth(url)
      .then((_res: AxiosResponse) => {
        onSuccessCallback(refresh);
        setIsModalLoading(false);
      })
      .catch((error: ErrorObject) => {
        if (
          error?.response?.status === HttpResponseStatusCodes.NotFound &&
          error?.response?.data?.error?.errorCode ===
            HbtServiceErrorCodes.HBT_ERR_RECORD_NOT_FOUND.code
        ) {
          onErrorCallback();
        } else if (
          error?.response?.data?.error?.errorCode ===
          HbtServiceErrorCodes.HBT_ERR_CANNOT_DELETE_CLAIM_OUT_OF_ORDER.code
        ) {
          onErrorCallback(ErrorType.ClaimOutOfOrder);
        } else {
          setShowServerErrorToast(true);
        }

        setIsModalLoading(false);
      });
  };

  const claimDeleteCallBack = (refresh: Function) => {
    onDeleteClaim(deleteClaimID, refresh);
  };
  const onDraftDeleteSuccessCallback = (refresh: Function) => {
    onSuccessCallback(refresh);
  };

  const primaryCallback = (refresh: Function) => {
    claimDeleteCallBack(refresh);
    setShowPageModal(false);
  };
  const secondaryCallback = () => {
    setShowPageModal(false);
  };

  return {
    deleteClaimID,
    showPageModal,
    showDeleteClaimToast,
    isModalLoading,
    setIsModalLoading,
    onDraftDeleteSuccessCallback,
    onDeleteClick,
    primaryCallback,
    secondaryCallback,
    setShowPageModal,
    closeDeleteClaimToast,
    showServerErrorToast,
    errorType
  };
};

export default useDeleteClaimFunctions;
