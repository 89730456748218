import * as React from 'react';
import { PageCardContainerProps } from 'Components/PageComponents/PageCardContainer/types';
import { PropsWithChildren } from 'react';
import styles from './styles.module.scss';

export const PageCardContainer: React.FC<PageCardContainerProps> = ({
  name,
  children
}: PropsWithChildren<PageCardContainerProps>) => (
  <section className={styles.card} data-testid={`${name}Test`}>
    {children}
  </section>
);
