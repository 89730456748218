import i18next from 'i18next';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { LanguageShort } from '@hobt/constants';

import { ContextualButton } from 'Components/Common/Button';
import { currencyFormat, getCurrencyFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormDatepicker from 'Components/Inputs/FormDatepicker';
import { LabelledBy as FormNumberLabelledBy } from 'Components/Inputs/FormNumber';

import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';

import { FormFieldCell } from 'Components/Common/DataTable/DataTableRow/FormFieldCell';
import styles from '../styles.module.scss';
import type { CalculationRowProps } from './types';

const CalculationRow: FC<CalculationRowProps> = ({
  name,
  index,
  editMode,
  selected,
  field,
  receivedPayment,
  editButton,
  deleteButton,
  edit,
  remove,
  isRefundCalcRowFieldsValid
}) => {
  const { formState, getValues } = useFormContext();
  useEffect(() => {
    if (isRefundCalcRowFieldsValid) {
      isRefundCalcRowFieldsValid(formState.isValid);
    }
  }, [formState.isValid]);

  return (
    <DataTableRow name={'topMost'}>
      <TextCell name={'Payment'} text={receivedPayment.value ?? ''} />
      <FormFieldCell>
        <FormNumberLabelledBy
          label={'adjustment-amount'}
          className={`${styles.entryCol}`}
          name={`${name}[${index}].amount`}
          formatProps={getCurrencyFormat(
            getValues(`${name}[${index}].amount`),
            { ...currencyFormat, thousandSeparator: true },
            i18next.language as LanguageShort
          )}
          defaultValue={field.amount === 0 ? undefined : field.amount || 0}
          isReadOnly={!editMode || !selected}
        />
      </FormFieldCell>
      <FormFieldCell>
        <FormDatepicker
          label={'adjustment-date'}
          className={`${styles.entryColDate}`}
          name={`${name}[${index}].addedDate`}
          isReadOnly={!editMode || !selected}
        />
      </FormFieldCell>
      <TextCell name={''} text={''} />
      <td>
        {!editMode && (
          <ContextualButton
            className={`${styles.actionIcon}`}
            data-testid="editReceivedAdjustment"
            aria-label={editButton.value}
            onClick={() => edit(index)}
          >
            <span className={`material-icons align-self-center`}>edit</span>
          </ContextualButton>
        )}
      </td>
      <td>
        {!editMode && (
          <ContextualButton
            className={`${styles.actionIcon}`}
            data-testid="deleteReceivedAdjustment"
            aria-label={deleteButton.value}
            onClick={() => remove(index)}
          >
            <span className={`material-icons align-self-center`}>delete_outline</span>
          </ContextualButton>
        )}
      </td>
    </DataTableRow>
  );
};

export default CalculationRow;
