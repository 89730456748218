export default function getComboboxSx(isOpen: boolean, readOnly: boolean) {
  return {
    width: '100% !important',
    '& .MuiFormControl-root': {
      backgroundColor: !readOnly ? 'var(--White, #ffffff)' : 'var(--Slate-90W, #F0F2F3)'
    },
    '& .MuiButtonBase-root.MuiAutocomplete-popupIndicator': {
      margin: '0 var(--Spacing-05, 16px) !important',
      boxSizing: 'content-box !important',
      fontFamily: 'var(--Font-Family-Text, Roboto) !important'
    },
    '& .MuiButtonBase-root.MuiIconButton-root:hover': {
      backgroundColor: 'transparent !important'
    },
    '& .MuiAutocomplete-clearIndicator': {
      visibility: 'visible'
    },
    '& .MuiAutocomplete-clearIndicator .MuiSvgIcon-root': {
      background: 'var(--White, #ffffff) !important',
      boxSizing: 'content-box !important',
      fontFamily: 'var(--Font-Family-Text, Roboto) !important'
    },
    '& .MuiAutocomplete-clearIndicator .MuiSvgIcon-root:hover': {
      backgroundColor: 'transparent !important'
    },
    '& .MuiAutocomplete-endAdornment .MuiButtonBase-root.MuiIconButton-root': {
      borderRadius: '4px !important',
      '&:focus': {
        outline: '2px solid var(--Violet-20W, #6D65D7) !important'
      }
    }
  };
}
