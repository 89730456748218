import * as React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import CancellationReasonProps from 'Feature/PageComponents/models/CancellationReasonComponentProps';

const CancellationReasonComponent = (props: CancellationReasonProps) => (
  <div>
    <p>CancellationReason Component</p>
    <p>
      <Text field={props.fields.heading} />
    </p>
    <p>
      <Text field={props.fields.description} />
    </p>
    <p>
      <Text field={props.fields.reasonLabel} />
    </p>
    <p>
      <Text field={props.fields.comments} />
    </p>
  </div>
);

export default CancellationReasonComponent;
