import * as React from 'react';
import { CollapsibleCardProps } from 'Components/Card/types';
import { PropsWithChildren, useState, useEffect } from 'react';

import styles from './styles.module.scss';

export const CollapsibleCard: React.FC<CollapsibleCardProps> = ({
  title,
  name,
  collapsed,
  onCollapseToggleCallback,
  children
}: PropsWithChildren<CollapsibleCardProps>) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(collapsed ?? false);

  const onCollapsedClicked = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (collapsed !== undefined) {
      setIsCollapsed(collapsed);
    }
  }, [collapsed]);

  useEffect(() => {
    if (onCollapseToggleCallback) {
      onCollapseToggleCallback();
    }
  }, [isCollapsed]);

  return (
    <div className={`${styles.collapsibleCard}`}>
      <div className={styles.collapsibleCardHeader} id={`${name}Card`}>
        {title()}{' '}
        <button
          onClick={onCollapsedClicked}
          aria-label={`Toggle Button for collapsing/expanding Card`}
          aria-controls={`${name}Card`}
          data-testid={`${name}CardToggleButton`}
          className={styles.collapsibleCardButton}
          type="button"
        >
          <span aria-hidden={true} className="material-icons icon--size-32">{`keyboard_arrow_${
            isCollapsed ? 'down' : 'up'
          }`}</span>
        </button>
      </div>
      <div className={`${styles.collapsibleCardBody}  d-${isCollapsed ? 'none' : 'flex'}`}>
        {children}
      </div>
    </div>
  );
};
