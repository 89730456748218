import SitecoreListItem from '../../Types/SitecoreListItem';

export const convertToSitecoreListItem = (
  label: string | number,
  value?: string | number
): SitecoreListItem => ({
  fields: {
    itemName: { value: label.toString() },
    itemValue: { value: value != null ? value.toString() : label.toString() }
  }
});
