import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import i18next from 'i18next';

import { LanguageSwitchTypeCode } from 'Feature/DefaultSubmissionForm/models/typeCode.types';
import { FormNumberInputProps } from './FormNumberInput.types';
import GetErrorMessage from '../ErrorMessage';
import _ from 'lodash-es';

const FormNumberInput: React.FC<FormNumberInputProps> = ({ ...props }) => {
  const errors = _.get(props.errors, `${props.name}`);

  return (
    <div className="form__element form__element--2-column">
      <label htmlFor={props.id} className={errors && 'form-error__label'}>
        <Text field={props.title} />
      </label>
      {props.description && <small>{props.description.value}</small>}
      <Controller
        control={props.control}
        name={props.name}
        defaultValue={(props.getValues && props.getValues(props.name)) || ''}
        render={({ field: { onBlur, value } }) => (
          <NumberFormat
            id={props.id}
            name={`${props.name}_formatted`}
            value={value}
            data-type="number"
            disabled={props.isDisabled === undefined ? false : props.isDisabled}
            decimalSeparator={i18next.language === LanguageSwitchTypeCode.English ? '.' : ','}
            thousandSeparator={i18next.language === LanguageSwitchTypeCode.English ? ',' : ' '}
            decimalScale={props.decimalScale || 2}
            displayType="input"
            allowLeadingZeros={false}
            {...props.register}
            onValueChange={(values) =>
              values.floatValue &&
              props.setValueHandler &&
              props.setValueHandler(props.name, values.floatValue, { shouldDirty: true })
            }
            onBlur={onBlur}
            className={errors && 'form-error__field'}
            {...(props.isRequired && { required: props.isRequired })}
            aria-describedby={`${props.id}ErrorDesc`}
            autoComplete={props.autoComplete || 'off'}
            onChange={props.handleChange}
          />
        )}
      />
      {!props.hideError && (
        <div className="form__fieldNotificationHolder">
          {_.get(props.errors, `${props.name}`) && (
            <span
              className="form-error__sub-text"
              aria-live="assertive"
              id={`${props.id}ErrorDesc`}
            >
              {GetErrorMessage(errors?.type as string, errors?.message as string)}
              <input type="hidden" className="thisFieldHasErrors" value={props?.cardName} />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default FormNumberInput;
