interface BaseProps {
  attachmentContent?: AttachmentContent;
  uploadErrorMessage: string;
  fileUploadUnavailable: boolean;
  isRequired: boolean;
  filesRemovedIndexes: number[];
  disable: boolean;
  uploadFiles: (rawAttachments: any[]) => void;
}

export interface AttachmentFile {
  description: string;
  documentID: string;
  errorMessage: string;
  fileName: string;
  fileType: string;
  fileByteCount: number;
  fileSize?: number;
  percentage: number;
  timeLeft: number;
  uploadStatusCode: number;
}
export interface Attachment {
  attachment: File;
  fileName: string;
  fileSize: number;
  fileType: string;
  errorMessage: string;
  percentage: number;
  timeLeft: number;
  uploadStatusCode: number;
}

export interface AttachmentProps extends BaseProps {
  attachments: Attachment[];
  completedAttachments: DocumentEntity[];
  stopUploading: (id: number, stopUploading: number) => void;
  deleteFile: (id: number) => void;
}

export interface AttachmentContent {
  attachmentsTitle?: FieldValue;
  attachmentsTitleInfo?: FieldValue;
  supportedFileFormatText?: FieldValue;
  attachmentInputBrowserLabel?: FieldValue;
  attachmentInputBrowserLabelText?: FieldValue;
  attachmentRequiredErrorMessage?: FieldValue;
  noAttachmentTableLabel?: FieldValue;
}

export interface DocumentEntity {
  documentId: string;
  description?: string;
  documentLink: string;
  documentStatus: number;
  fileName: string;
  fileSize: number;
  fileType: string;
  recordCreatedTimestamp: string;
  recordCreatedUserID: string;
  recordUpdatedTimestamp: string;
  recordUpdatedUserID: string;
  uniqueFileName: string;
  portfolioToolType: number;
}

export enum FileIconTypes {
  PDF = 'pdf',
  CSV = 'csv',
  XLS = 'xls',
  XLSM = 'xlsm',
  XLSX = 'xlsx',
  TXT = 'txt',
  ZIP = 'zip'
}

export enum UploadStatus {
  Success = 2,
  Error = 3
}

export enum KeyboardEvents {
  EnterKey = 'Enter',
  SpaceKey = ' '
}

export interface UploadAttachmentWindowProps extends BaseProps {}

export interface ProgressBarItemProps
  extends Pick<AttachmentProps, 'attachments' | 'stopUploading' | 'filesRemovedIndexes'> {}

export interface AttachmentTableProps
  extends Pick<AttachmentProps, 'attachmentContent' | 'completedAttachments' | 'deleteFile'> {}
