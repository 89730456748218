import React from 'react';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  id?: string;
  name: string;
  ariaText?: FieldValue;
  text: FieldValue;
  icon?: () => React.JSX.Element;
  leadingIcon?: () => React.JSX.Element;
  trailingIcon?: () => React.JSX.Element;
  size?: ButtonSize;
  variant?: ButtonVariant;
  loading?: boolean;
  responsive?: boolean;
}

/* Simplified Buttons */
export type BaseButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  dataTestid: string;
};

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  ICON = 'icon'
}

export enum ButtonSize {
  LARGE = 'large',
  SMALL = 'small'
}
