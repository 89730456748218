/*eslint-disable*/

export const menuEntries = {
  "helpNavigation": [
    {
      "name": "Help & Support",
      "path": "/help",
      "children": null
    },
    {
      "name": "Arrears Module",
      "path": "/help/Arrears-Module",
      "children": [
        {
          "path": "/help/Arrears-Module/Arrears-Help-1",
          "name": "Arrears Help 1",
          "children": []
        },
        {
          "path": "/help/Arrears-Module/Arrears-Help-2",
          "name": "Arrears Help 2",
          "children": []
        }
      ]
    },
    {
      "name": "Default Module",
      "path": "/help/Default-Module",
      "children": [
        {
          "path": "/help/Default-Module/Default-Help-Module-1",
          "name": "Default Help Module 1",
          "children": []
        },
        {
          "path": "/help/Default-Module/Default-Help-Module-2",
          "name": "Default Help Module 2",
          "children": []
        }
      ]
    },
    {
      "name": "General Portal Functions",
      "path": "/help/General-Portal-Functions",
      "children": [
        {
          "path": "/help/General-Portal-Functions/Dashboard-Features-Functions",
          "name": "Dashboard Features & Functions",
          "children": []
        },
        {
          "path": "/help/General-Portal-Functions/Managing-Your-Account",
          "name": "Managing Your Account",
          "children": []
        }
      ]
    },
    {
      "name": "Homeowner Claim Module",
      "path": "/help/Homeowner-Claim-Module",
      "children": [
        {
          "path": "/help/Homeowner-Claim-Module/Homeowner-Claim-Help-Module-1",
          "name": "Homeowner Claim Help Module 1",
          "children": []
        },
        {
          "path": "/help/Homeowner-Claim-Module/Homeowner-Claim-Help-Module-2",
          "name": "Homeowner Claim Help Module 2",
          "children": []
        }
      ]
    }
  ]
}