import { useState } from 'react';

import { ApplicationStates } from 'Components/Enums/ApplicationStatus';

const useAdjudicationCardHeader = () => {
  const [declineClaimShowSideDrawer, setDeclineClaimShowSideDrawer] = useState<boolean>(false);
  const [cancelClaimShowSideDrawer, setCancelClaimShowSideDrawer] = useState<boolean>(false);
  const [showDeclineClaimStatusToast, setDeclineClaimStatusToast] = useState<any | boolean>(false);
  const [showCancelClaimStatusToast, setCancelClaimStatusToast] = useState<any | boolean>(false);
  const [showStatusToast, setShowStatusToast] = useState<any | boolean>(false);
  const [showSideDrawer, setShowSideDrawer] = useState<boolean>(false);

  const onDeclineClaimErrorSubmit = () => {
    setDeclineClaimStatusToast(ApplicationStates.ERROR);
    setDeclineClaimShowSideDrawer(false);
  };

  const onDeclineClaimCancelForm = () => {
    setDeclineClaimShowSideDrawer(false);
  };

  const onDeclineClaimSuccessSubmit = () => {
    setDeclineClaimStatusToast(ApplicationStates.SUCCESS);
    setDeclineClaimShowSideDrawer(false);
  };

  const onAddDeclineClaimForm = () => {
    setDeclineClaimShowSideDrawer(true);
    setDeclineClaimStatusToast(false);
  };

  const closeDeclineToast = () => {
    setDeclineClaimStatusToast(false);
  };

  const onCancelClaimErrorSubmit = () => {
    setCancelClaimStatusToast(ApplicationStates.ERROR);
    setCancelClaimShowSideDrawer(false);
  };

  const onCancelClaimCancelForm = () => {
    setCancelClaimShowSideDrawer(false);
  };

  const onCancelClaimSuccessSubmit = () => {
    setCancelClaimStatusToast(ApplicationStates.SUCCESS);
    setCancelClaimShowSideDrawer(false);
  };

  const onAddCancelClaimForm = () => {
    setCancelClaimShowSideDrawer(true);
    setCancelClaimStatusToast(false);
  };

  const closeCancelToast = () => {
    setCancelClaimStatusToast(false);
  };

  const onErrorSubmit = () => {
    setShowStatusToast(ApplicationStates.ERROR);
    setShowSideDrawer(false);
  };

  const closeToast = () => {
    setShowStatusToast(false);
  };

  const onCancelForm = () => {
    setShowSideDrawer(false);
  };

  const onSuccessSubmit = () => {
    setShowStatusToast(ApplicationStates.SUCCESS);
    setShowSideDrawer(false);
  };

  const onAddContestClaimForm = () => {
    setShowSideDrawer(true);
    setShowStatusToast(false);
  };

  return {
    declineClaimShowSideDrawer,
    cancelClaimShowSideDrawer,
    onAddDeclineClaimForm,
    onDeclineClaimSuccessSubmit,
    setDeclineClaimShowSideDrawer,
    onDeclineClaimCancelForm,
    onDeclineClaimErrorSubmit,
    closeDeclineToast,
    onCancelClaimErrorSubmit,
    onCancelClaimCancelForm,
    onCancelClaimSuccessSubmit,
    closeCancelToast,
    onAddCancelClaimForm,
    showCancelClaimStatusToast,
    showDeclineClaimStatusToast,
    showStatusToast,
    showSideDrawer,
    closeToast,
    onAddContestClaimForm,
    onSuccessSubmit,
    setShowSideDrawer,
    onCancelForm,
    onErrorSubmit
  };
};

export default useAdjudicationCardHeader;
