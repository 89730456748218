import React, { createContext, useContext, ReactNode } from 'react';
import { HbtModifyExternalPIUserContextType } from './types';

const HbtModifyExternalPIUserContext = createContext<HbtModifyExternalPIUserContextType>({
  userData: []
});
export const useHbtModifyExternalPIUserContext = () =>
  useContext<HbtModifyExternalPIUserContextType>(HbtModifyExternalPIUserContext);

const HbtModifyExternalPIUserContextProvider = (props: {
  children?: ReactNode;
  value?: HbtModifyExternalPIUserContextType;
}) => {
  return (
    <HbtModifyExternalPIUserContext.Provider
      value={{
        userData: props.value?.userData
      }}
    >
      {props.children}
    </HbtModifyExternalPIUserContext.Provider>
  );
};

export default HbtModifyExternalPIUserContextProvider;
