import * as React from 'react';
import { PropsWithChildren } from 'react';
import styles from './styles.module.scss';
import { CardProps } from './types';

export const Card: React.FC<CardProps> = ({
  expandToMatchViewportHeight,
  children
}: PropsWithChildren<CardProps>) => {
  return (
    <div className={`${styles.card} ${expandToMatchViewportHeight ? styles.expandHeight : ''}`}>
      {children}
    </div>
  );
};
