import React from 'react';
import { DeleteRowCellProps } from 'Components/Common/DataTable/DataTableRow/DeleteRowCell/types';
import { Image } from '@sitecore-jss/sitecore-jss-react';

import i18next from 'i18next';
import styles from './styles.module.scss';
import DeleteIcon from './deleteIcon.svg';

export const DeleteRowCell: React.FC<DeleteRowCellProps> = ({
  name,
  idx,
  onClickCallback,
  hideButton,
  icon
}: DeleteRowCellProps) => (
  <td>
    {!hideButton && (
      <div className={styles.deleteCell}>
        <button
          type="button"
          onClick={() => onClickCallback(idx)}
          className={styles.deleteButton}
          name={`${name}Button`}
          data-testid={`${name}Button`}
          aria-label={i18next.t('Accessibility-Delete-Button')}
        >
          {icon != null ? (
            <Image field={icon} />
          ) : (
            <img src={DeleteIcon} alt={i18next.t('Accessibility-Delete-Button-Icon')} />
          )}
        </button>
      </div>
    )}
  </td>
);
