// Importing Libraries
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import i18n from 'i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { BuildingType } from '@hobt/constants';

import { ButtonType } from 'Feature/CommonComponents/UserControls';

// Importing Form Inputs and Common Components
import { integerFormat, postalCodeFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormNumber from 'Components/Inputs/FormNumber';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormTextArea from 'Components/Inputs/FormTextArea';
import FormText, { Formatted as FormattedInput } from 'Components/Inputs/FormText';
import FormCard from 'Components/Common/FormCard';
import DynamicHeading from 'Components/Common/DynamicHeading';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { GlossaryNames } from 'Feature/PageComponents/components/GlossaryComponent/types';
import { LanguageSwitchTypeCode } from '../ClaimsLenderDetails/types';

// Importing Types and Styles

import styles from './styles.module.scss';
import ClaimsPropertyDetailsProps from './types';

const SINGLE_BUILDING_TYPE_BED_UNIT_COUNT_THRESHOLD = 2;

const ClaimsPropertyDetails: React.FC<ClaimsPropertyDetailsProps> = (
  props: ClaimsPropertyDetailsProps
) => {
  const {
    cardTitle,
    streetNumber,
    streetName,
    streetTypeLabel,
    streetType,
    streetDirection,
    streetDirectionLabel,
    unitNumber,
    town,
    provinceLabel,
    province,
    postalCode,
    additionalStreet,
    numberOfBeds,
    numberOfUnit,
    buildingTypeLabel,
    buildingType,
    completeLegalDescription,
    township,
    block,
    lot,
    level,
    additionalDescription,
    concession,
    plan,
    unit,
    legalDescriptionLimitCount,
    legalDescriptionLimitCountText
  } = props.fields;

  const { setValue, trigger, register, watch } = useFormContext();
  const { isMasterUserEditingPostAdjudicationClaim } = useHBTFormContext();

  const streetTypeOptions = streetType[0]?.fields?.listItems;
  const streetDirectionOptions = streetDirection[0]?.fields?.listItems;
  const provinceOptions = province[0]?.fields?.listItems;
  const buildingTypeOptions = buildingType[0]?.fields?.listItems;

  const { isFieldDisabled } = useHBTFormContext();

  const handleStreetType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.currentTarget.value !== '') {
      register('property.address.streetTypeCode');
      setValue('property.address.streetTypeCode', Number(event.target.value));
    }
  };

  const handleStreetDirection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.currentTarget.value !== '') {
      register('property.address.streetDirectionTypeCode');
      setValue('property.address.streetDirectionTypeCode', Number(event.target.value));
    }
  };

  const bedCount = watch('property.bedCount');
  const unitCount = watch('property.unitCount');

  useEffect(() => {
    if (isMasterUserEditingPostAdjudicationClaim === true) {
      return;
    }

    let bedAndUnitCount = 0;

    if (bedCount != null && unitCount != null) {
      bedAndUnitCount = Number(bedCount) + Number(unitCount);
    } else if (bedCount != null && unitCount == null) {
      bedAndUnitCount = Number(bedCount);
    } else if (bedCount == null && unitCount != null) {
      bedAndUnitCount = Number(unitCount);
    }

    if (
      !Number.isNaN(bedAndUnitCount) &&
      bedAndUnitCount <= SINGLE_BUILDING_TYPE_BED_UNIT_COUNT_THRESHOLD
    ) {
      setValue('property.buildingTypeCode', BuildingType.Single);
    } else {
      setValue('property.buildingTypeCode', BuildingType.Multiple);
    }
  }, [bedCount, unitCount]);

  return (
    <FormCard
      title={cardTitle}
      headingLevel={2}
      sectionId="claims-property-body"
      fieldToValidate="property"
      toolTipButton={{
        id: 'propertyGlossaryTooltip',
        name: 'propertyGlossaryTooltip',
        ariaText: props.fields.cardGlossaryAriaText?.value ?? '',
        onClick: () => {
          props?.fields?.openGlossary?.(GlossaryNames.PropertyDetails);
        },
        buttonType: ButtonType.TEXT
      }}
    >
      <FormText
        name="property.address.streetNumber"
        label={streetNumber}
        className={styles.halfLeft}
        isReadOnly={isFieldDisabled}
      />
      <FormText
        name="property.address.streetName"
        label={streetName}
        className={styles.half}
        isReadOnly={isFieldDisabled}
      />
      <FormDropdown
        name="property.address.streetTypeCode"
        label={streetTypeLabel}
        className={styles.halfLeft}
        options={streetTypeOptions}
        handleChange={handleStreetType}
        optional={true}
        isDisabled={isFieldDisabled}
        disableDefaultSelect={false}
      />
      <FormDropdown
        name="property.address.streetDirectionTypeCode"
        label={streetDirectionLabel}
        className={`${styles.half} ${
          i18n.language === LanguageSwitchTypeCode.French ? styles.pullDown : ''
        }`.trim()}
        options={streetDirectionOptions}
        handleChange={handleStreetDirection}
        optional={true}
        isDisabled={isFieldDisabled}
        disableDefaultSelect={false}
      />
      <FormText
        name="property.address.unitNumber"
        isReadOnly={isFieldDisabled}
        label={unitNumber}
        className={styles.halfLeft}
      />
      <FormText
        name="property.address.municipalityName"
        isReadOnly={isFieldDisabled}
        label={town}
        className={styles.half}
      />
      <FormDropdown
        name="property.address.provinceCode"
        label={provinceLabel}
        options={provinceOptions}
        className={styles.halfLeft}
        setAsNumber={false}
        isDisabled={isFieldDisabled}
      />
      <FormattedInput
        name="property.address.postalCode"
        formatProps={postalCodeFormat}
        isReadOnly={isFieldDisabled}
        label={postalCode}
        className={styles.half}
      />
      <FormText
        name="property.address.additionalStreetNumber"
        label={additionalStreet}
        className={styles.halfLeft}
        isReadOnly={isFieldDisabled}
      />
      <FormNumber
        name="property.bedCount"
        label={numberOfBeds}
        formatProps={{
          ...integerFormat,
          thousandSeparator: true,
          thousandsGroupStyle: 'thousand'
        }}
        className={`${styles.half} ${
          i18n.language === LanguageSwitchTypeCode.French ? styles.pullDown : ''
        }`.trim()}
        isReadOnly={isFieldDisabled}
      />
      <FormNumber
        name="property.unitCount"
        label={numberOfUnit}
        formatProps={integerFormat}
        className={styles.halfLeft}
        isReadOnly={isFieldDisabled}
      />
      <FormDropdown
        name="property.buildingTypeCode"
        label={buildingTypeLabel}
        options={buildingTypeOptions}
        className={styles.half}
        isDisabled={true}
        addDefaultSelect={false}
        setAsNumber={false}
      />
      <hr className={styles.separator} />
      <DynamicHeading headingLevel={5} className={styles.subHeading} ariaHidden="true">
        <Text field={completeLegalDescription} />
      </DynamicHeading>
      <FormText
        name="property.legalTownshipDescription"
        label={township}
        className={styles.halfLeft}
        isReadOnly={isFieldDisabled}
      />
      <FormText
        name="property.legalConcessionDescription"
        label={concession}
        className={styles.half}
        isReadOnly={isFieldDisabled}
      />
      <FormText
        name="property.legalBlockDescription"
        isReadOnly={isFieldDisabled}
        label={block}
        className={styles.halfLeft}
      />
      <FormText
        name="property.legalPlanDescription"
        isReadOnly={isFieldDisabled}
        label={plan}
        className={styles.half}
      />
      <FormText
        name="property.legalLotDescription"
        isReadOnly={isFieldDisabled}
        label={lot}
        className={styles.halfLeft}
      />
      <FormText
        name="property.legalUnitDescription"
        isReadOnly={isFieldDisabled}
        label={unit}
        className={styles.half}
      />
      <FormText
        name="property.legalLevelDescription"
        isReadOnly={isFieldDisabled}
        label={level}
        className={styles.halfLeft}
      />
      <FormTextArea
        name="property.completeLegalDescription"
        label={additionalDescription}
        textAreaHelperText={legalDescriptionLimitCountText.value}
        charLimit={Number(legalDescriptionLimitCount.value)}
        className={styles.full}
        isReadOnly={isFieldDisabled}
      />
    </FormCard>
  );
};

export default ClaimsPropertyDetails;
