export enum ComparisonOperators {
  EQ = '-EQ',
  NE = '-NE',
  GT = '-GT',
  GE = '-GE',
  LT = '-LT',
  LE = '-LE',
  IN = '-IN',
  BETWEEN = '-BETWEEN',
  IS = '-IS',
  LIKE = '-LIKE'
}
