import * as React from 'react';
import i18n from 'i18next';
import { ActionMenuProps } from 'Components/Common/ActionMenu/types';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import styles from './styles.module.scss';

export const ActionMenu: React.FC<ActionMenuProps> = ({
  name,
  toggleButtonDisplayText,
  children
}: PropsWithChildren<ActionMenuProps>) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const actionMenuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (isActive && actionMenuRef?.current?.contains(e.target as Node) === false) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  return (
    <div
      className={`${styles.actionMenu} dropdown`}
      data-testid={`${name}ActionMenuTest`}
      ref={actionMenuRef}
    >
      <button
        data-toggle="dropdown"
        onClick={(e) => {
          e.stopPropagation();
          setIsActive(!isActive);
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      >
        {toggleButtonDisplayText ? (
          <>
            <Text field={toggleButtonDisplayText} />
            <span className="material-icons align-self-center">arrow_drop_down</span>
          </>
        ) : (
          <div aria-label={i18n.t('Global-MoreAction-Aria')}>
            <span className="material-icons align-self-center" aria-hidden={true}>
              more_vert
            </span>
          </div>
        )}
      </button>
      {isActive && <div className={`${styles.subNavContainer}`}>{children}</div>}
    </div>
  );
};
