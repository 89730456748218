import { withFeature, useFeature } from 'flagged';
import i18n from 'i18next';
import React from 'react';
import { Pagination } from '@mui/lab';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { Card, CardHeader } from 'Feature/CommonComponents/ContentComponents';
import { Table } from 'Feature/CommonComponents/DataTable';
import { CardType } from 'Feature/CommonComponents/Enums';
import { LoaderAnimation, Tabs } from 'Feature/CommonComponents/UserControls';
import { InventoryTableProps } from 'Feature/DefaultsInventory/models/DefaultsInventoryTable.type';
import FormInputDropdownText from 'Feature/DefaultSubmissionForm/components/FormInputDropdownText';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { DefaultRequestStatus } from 'Foundation/Api';

import './InventoryTableExternal.scss';
import styles from './styles.module.scss';

const InventoryTablePresentationExternal: React.FC<InventoryTableProps> = (
  props: InventoryTableProps
) => {
  const isInternalSite = useFeature(FeatureFlags.INTERNAL);

  let sitecoreContext;
  let approvedLenderName = '';
  let approvedLenderCode = '';

  if (isInternalSite != undefined || isInternalSite === false) {
    const sitecoreContextFactory = useSitecoreContext();
    sitecoreContext = sitecoreContextFactory?.sitecoreContext as any;

    if (
      sitecoreContext != undefined &&
      sitecoreContext.user != undefined &&
      sitecoreContext.user.lender != undefined &&
      sitecoreContext.user.lender.length > 0
    ) {
      approvedLenderName =
        sitecoreContext.language === 'en'
          ? sitecoreContext.user.lender[0].approvedLenderEnglishName
          : sitecoreContext.user.lender[0].approvedLenderFrenchName;
      approvedLenderCode = sitecoreContext.user.lender[0].approvedLenderCode;
    }
  }

  return (
    <div className="main-content">
      <div id="defaultsInventory" className="defaultsInventory">
        <div className="row">
          <section className="col-12 dataVis">
            <Card type={CardType.HORIZONTAL_BLUE}>
              <CardHeader
                title={i18n.t('DefaultsInventoryTable-LabelText-Title')}
                subTitle={`
                ${i18n.t('DefaultsInventoryTable-Title-LendingOrganization')}:
                 ${approvedLenderName} (${approvedLenderCode})
                `}
              />

              <div className="card-body card__body card__inventory-table">
                <div className="row card__body-row card__body-row--fluid">
                  <div className="col-12">
                    <Tabs
                      tabGroupName={i18n.t('DefaultsInventoryDetails-TabGroupName-DetailsTabs')}
                      items={[
                        {
                          displayText: i18n.t('DefaultsInventoryTable-FilterTab-All'),
                          tabName: 'all',
                          count: props.tabCounts && props.tabCounts.allCount
                        },
                        {
                          displayText: i18n.t('DefaultsInventoryTable-FilterTab-InReview'),
                          tabName: `${DefaultRequestStatus[DefaultRequestStatus.InReview]}`,
                          count:
                            props.tabCounts &&
                            props.tabCounts.statusCounts[DefaultRequestStatus.InReview]
                        },
                        {
                          displayText: i18n.t('DefaultsInventoryTable-FilterTab-Approved'),
                          tabName: `${DefaultRequestStatus[DefaultRequestStatus.Approved]}`,
                          count:
                            props.tabCounts &&
                            props.tabCounts.statusCounts[DefaultRequestStatus.Approved]
                        },
                        {
                          displayText: i18n.t('DefaultsInventoryTable-FilterTab-Declined'),
                          tabName: `${DefaultRequestStatus[DefaultRequestStatus.Declined]}`,
                          count:
                            props.tabCounts &&
                            props.tabCounts.statusCounts[DefaultRequestStatus.Declined]
                        },
                        {
                          displayText: i18n.t('DefaultsInventoryTable-FilterTab-Cancelled'),
                          tabName: `${DefaultRequestStatus[DefaultRequestStatus.Cancelled]}`,
                          count:
                            props.tabCounts &&
                            props.tabCounts.statusCounts[DefaultRequestStatus.Cancelled]
                        }
                      ]}
                      onTabClick={props.handleOnTabClick}
                      defaultActiveItemFieldName="all"
                    />
                  </div>
                </div>
                <div className="row card__body-row inventory__actionBar align-items-center justify-right">
                  <div className="col-5 inventory__actions--rightSide">
                    <div
                      className="form inventorySearchForm"
                      role="search"
                      style={{ paddingLeft: '64px' }}
                    >
                      <div className="form__element inventory__search align-items-center">
                        <label
                          htmlFor="searchByLoanNumber"
                          id="searchByLoanNumberLabel"
                          className="sr-only"
                        >
                          {i18n.t('DefaultsInventoryTable-SearchByLoadNumber')}
                        </label>
                        <input
                          name="search-by-loan-number"
                          className="inventory__search--input"
                          id="searchByLoanNumber"
                          aria-labelledby="searchByLoanNumberLabel"
                          placeholder={i18n.t('DefaultsInventoryTable-SearchLoanNumbers')}
                          maxLength={8}
                          onKeyDown={(e) => {
                            if (e.key === '.') {
                              e.preventDefault();
                            }
                            props.searchOnKeyDown && props.searchOnKeyDown(e);
                          }}
                          onInput={(e) => {
                            e.currentTarget.value = e.currentTarget.value
                              .replace(/[^0-9]*/g, '')
                              .substr(0, 8);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row card__body-row card__body-row--fluid">
                  <div className="col-12 external-inventory-table">
                    {props.isLoading && <LoaderAnimation />}
                    {!props.isLoading && props.rows!.length != 0 && (
                      <Table
                        name="defaultsInventoryExternal"
                        tableHeaderColumns={props.headers}
                        tableRows={props.rows || []}
                        onRowClick={(e) => props.handleOnRowClick(e)}
                        onRowKeyDown={(e) => props.handleOnRowKeydown(e)}
                        onHeaderColumnSort={props.handleOnHeaderSort}
                        title={i18n.t('DefaultsInventoryTable-Row-Title')}
                        tableCaption={props.dataTableCaption || ''}
                        scrollOnHorizontalOverflow
                      />
                    )}
                    {!props.isLoading && (props.rows == null || props.rows.length === 0) && (
                      <div className={styles.noSearchResultsMessage}>
                        <Text field={{ value: i18n.t('Globals-Table-No-Search-To-Display') }} />
                      </div>
                    )}
                  </div>
                </div>

                <div className="row card__body-row">
                  <div className="col-12 justify-right table__page-actions--bottom">
                    <div className={`pagination ${styles.inventoryPagination}`}>
                      {`${props.pagerRangeStart} - ${props.pagerRangeEnd}
                      ${i18n.t('DefaultsInventoryTable-LabelText-OutOf')}
                      ${props.pagerTotalItems}
                      ${i18n.t('DefaultsInventoryTable-LabelText-Items')}`}
                    </div>
                    <div className="pager">
                      <FormInputDropdownText
                        id="numberOfItemsPerPageDropdownSelector"
                        name="number-of-items-per-page-dropdown-selector"
                        title={{
                          value: i18n.t('DefaultsInventoryTable-ResultsPerPageDropdown-Title')
                        }}
                        options={props.resultsPerPageSelectorOptions || []}
                        handleChange={props.resultsPerPageSelectorOnChangeHandler}
                        value={props.resultsPerPageSelectorDefaultValue || 20}
                        hideError={true}
                      />
                    </div>
                    <Pagination
                      count={props.numPages}
                      variant="outlined"
                      page={props.currentPage || 1}
                      shape="rounded"
                      onChange={props.handleOnPaginationChange}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </section>
        </div>
      </div>
    </div>
  );
};

export default withFeature(FeatureFlags.EXTERNAL)(InventoryTablePresentationExternal);
