export function validString(str: string | undefined): boolean {
  if (str == null) {
    return false;
  }

  if (typeof str !== 'string') {
    return false;
  }

  return str.trim() !== '';
}
