import { ActionMenu } from 'Components/Common/ActionMenu';
import { ActionMenuItem } from 'Components/Common/ActionMenu/ActionMenuItem';
import { MenuItemProps } from 'Components/Common/ActionMenu/types';
import { RowActionButtonCellProps } from 'Components/Common/DataTable/DataTableRow/ActionButtonCell/types';
import * as React from 'react';

export const ActionButtonCell: React.FC<RowActionButtonCellProps> = ({
  name,
  rowActionItems
}: RowActionButtonCellProps) => (
  <td>
    <ActionMenu name={`${name}ActionMenuCell`}>
      {rowActionItems &&
        rowActionItems.map((actionItem: MenuItemProps) => (
          <ActionMenuItem
            key={`${name}ActionMenuCellActionMenu${actionItem.name}`}
            {...actionItem}
          />
        ))}
    </ActionMenu>
  </td>
);
