import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import i18n from 'i18next';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

import { Button } from 'Components/Common/Button';
import { NotificationBanner } from 'Components/Common/Notifications/NotificationBanner';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import {
  useHBTFormActionsContext,
  useHBTFormContext
} from 'Feature/Claims/components/HBTFormContext';

import { ModuleMapping, UserRole } from '@hobt/constants';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';

import AdditionalDocumentation from './AdditionalDocumentation';
import PendingCodes from './PendingCodes';
import SuspenseReasons from './SuspenseReasons';
import useSuspenseConditions from './useSuspenseConditionHooks';
import SuspenseConditionsProps, {
  AdditionalDocumentationPropsFields,
  PendingCodesPropsFields,
  SuspenseReasonsPropsFields
} from './types';
import styles from './styles.module.scss';
import { LoaderAnimation } from 'Feature/CommonComponents/UserControls';

const SuspenseConditions = (props: SuspenseConditionsProps) => {
  const {
    handleSubmit,
    handleErrors,
    showErrorToast,
    showSuccessToast,
    setShowSuccessToast,
    setShowErrorToast,
    onChangeSuspenseReason,
    hookForm,
    onNotificationDismiss,
    showPendingNotification
  } = useSuspenseConditions();

  const { suspenseConditionsCards } = props.fields;
  const {
    formState: { isDirty }
  } = hookForm;
  const {
    isClaimStatusPostAdjudicationStatus,
    isMasterUser,
    isReadOnlyView,
    isReadOnlyAccessUser,
    claimData,
    loadingStatus
  } = useHBTFormContext();
  const { disableEditMode, enableEditMode } = useHBTFormActionsContext();
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isDirty === true) {
      enableEditMode();
    }
  }, [isDirty]);

  useEffect(() => {
    hookForm.register('indicator.claimReceivedDate' as any);
    hookForm.setValue(
      'indicator.claimReceivedDate' as never,
      claimData?.indicator?.claimReceivedDate
    );
  }, []);

  const additionalDocumentationProps = suspenseConditionsCards[0]
    .fields as AdditionalDocumentationPropsFields;
  const pendingCodeProps = suspenseConditionsCards[1].fields as PendingCodesPropsFields;
  const pendingReasonsProps = suspenseConditionsCards[2].fields as SuspenseReasonsPropsFields;

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const isBusinessUser = isUserInRoles(
    ModuleMapping.claim,
    [UserRole.BusinessUser],
    moduleRoleMapping
  );
  const isClerkUser = isUserInRoles(ModuleMapping.claim, [UserRole.Clerk], moduleRoleMapping);
  const isBusinessOrMasterUser = isBusinessUser || isMasterUser;

  const isSaveButtonDisabled = () => {
    // We always enable it for clerks, business users and master users
    if (isClerkUser || isBusinessOrMasterUser) {
      return false;
    }

    if (isReadOnlyView === true) {
      return true;
    }

    return false;
  };

  if (isPageLoading) {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 1000);
    return <LoaderAnimation />;
  }

  return (
    <>
      <ToastNotification
        type={ApplicationStates.SUCCESS}
        isActive={showSuccessToast}
        title={i18n.t('Globals-Toast-Success-Title')}
        content={i18n.t('Globals-Toast-Success-Changes-Saved')}
        onCloseCallback={() => {
          setShowSuccessToast(false);
          setIsPageLoading(true);
        }}
      />
      <ToastNotification
        type={ApplicationStates.ERROR}
        isActive={showErrorToast}
        title={i18n.t('Globals-Toast-Error-Title')}
        content={{ value: i18n.t('Globals-Toast-Error-Action-NotComplete') }}
        onCloseCallback={() => {
          setShowErrorToast(false);
          setIsPageLoading(false);
        }}
      />
      <div className={styles.container}>
        {showPendingNotification && (
          <NotificationBanner
            warning
            title={props.fields.notificationSuspenseTitle}
            content={props.fields.notificationSuspenseText}
            onCloseCallback={() => {
              onNotificationDismiss();
            }}
          />
        )}
        <FormProvider {...hookForm}>
          <form
            onSubmit={hookForm.handleSubmit(handleSubmit, handleErrors)}
            id="suspenseConditionForm"
            className="form"
          >
            <AdditionalDocumentation
              {...additionalDocumentationProps}
              isReadOnlyView={isReadOnlyAccessUser}
              isClaimStatusPostAdjudicationStatus={isClaimStatusPostAdjudicationStatus}
            />
            <fieldset disabled={isClaimStatusPostAdjudicationStatus || isClerkUser}>
              <PendingCodes {...pendingCodeProps} isReadOnlyView={isReadOnlyView || isClerkUser} />
            </fieldset>
            <SuspenseReasons
              {...pendingReasonsProps}
              onChangeSuspenseReason={onChangeSuspenseReason}
              isReadOnlyView={isReadOnlyView || isClerkUser}
            />

            <footer className={styles.footerButtons}>
              <span className={styles.saveButton}>
                <Button
                  text={props.fields.saveButton}
                  ariaText={props.fields.saveButton}
                  name={`${props.fields.saveButton}`}
                  type="submit"
                  disabled={isSaveButtonDisabled()}
                />
              </span>
              <Button
                secondaryButton
                text={props.fields.discardChangesButton}
                ariaText={props.fields.discardChangesButton}
                name={`${props.fields.discardChangesButton}`}
                onClick={() => {
                  disableEditMode();
                  hookForm.reset();
                }}
                type="button"
                loading={loadingStatus?.isLoading}
                disabled={isReadOnlyView}
              />
            </footer>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default SuspenseConditions;
