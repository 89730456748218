import { useFeature } from 'flagged';
import i18next from 'i18next';
import React, { useEffect, useState, useRef } from 'react';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { UserRole, Module } from '@hobt/constants';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useDefaultFormActionsContext } from 'Feature/DefaultsInventory/components/DefaultFormContext';
import { postalCodeFormat } from 'Components/Inputs/CommonFormFieldFormats';
import { AccordionContainer, LinedCard } from '../../../../CommonComponents/AccordionComponents';

import {
  FormInputRadioType,
  ComponentType,
  ProvinceCode,
  ValidationErrorMessageType
} from '../../../models/typeCode.types';
import { LoanDetailsProps } from './LoanDetails.types';

import FormInputDropdownText from '../../FormInputDropdownText';
import FormInputTextInput, {
  Formatted as FormInputFormattedTextInput
} from '../../FormInputTextInput';
import FormInputCurrency from '../../FormInputCurrency';
import FormInputRadio from '../../FormInputRadio';
import FormInputCalendar from '../../FormInputCalendar';
import FormInputWholeNumber from '../../FormInputWholeNumber';
import FormInputCheckbox from '../../FormInputCheckbox';
import './LoanDetails.css';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const LoanDetailsContainer = (props: LoanDetailsProps) => {
  const {
    register,
    errors,
    setValueHandler,
    control,
    watch,
    getValues,
    reset,
    inputFields,
    title
  } = props;

  const commonCardProps = {
    register,
    errors,
    setValueHandler,
    control,
    watch,
    getValues
  };

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const defaultFormActions = useDefaultFormActionsContext();

  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  const [showCheckbox, setCheckbox] = useState(false);

  const watchDetail = (watch && watch('detail')) || {};
  const watchAcctNum = watchDetail.cmhcLoanAccountNumber;
  const isPropertyTaxUpToDate = watchDetail.updatedPropertyTax;
  const isPropertyInsured = watchDetail.updatedPropertyInsurance;
  const isUtilitiesUpToDate = watchDetail.updatedUtilities;
  const isCondoFeeUpToDate = watchDetail.updatedCondominiumFee;

  const { firstLoan } = watchDetail;
  const { currentMarketValue } = watchDetail;
  const { insuredPropertyMunicipalityName } = watchDetail;
  const { insuredPropertyPostalCode } = watchDetail;
  const { insuredPropertyProvinceCode } = watchDetail;
  const { insuredPropertyStreetName } = watchDetail;
  const { insuredPropertyStreetNumber } = watchDetail;
  const { insuredPropertyStreetType } = watchDetail;
  const { purchasePriceOfHome } = watchDetail;

  // checkbox is only show/hide on initial render
  useEffect(() => {
    const watchValidLoan = watch && watch('other.validLoan');
    const watchValidDetail = watch && watch('detail');

    setCheckbox(watchValidDetail?.cmhcLoanAccountNumber && !watchValidLoan);

    // Initially set calculated field to a default value to pass submission and then it will be updated in the onSubmit handler
    register && register('detail.firstLoan.currentAmortizationPeriod');
    setValueHandler && setValueHandler('detail.firstLoan.currentAmortizationPeriod', '0000');

    register && register('detail.firstLoan.originalAmortizationPeriod');
    setValueHandler && setValueHandler('detail.firstLoan.originalAmortizationPeriod', '0000');
  }, []);

  useEffect(() => {
    register && register('detail.firstLoan.currentAmortizationPeriod');
    setValueHandler && setValueHandler('detail.firstLoan.currentAmortizationPeriod', '0000');

    register && register('detail.firstLoan.originalAmortizationPeriod');
    setValueHandler && setValueHandler('detail.firstLoan.originalAmortizationPeriod', '0000');
  }, [JSON.stringify(firstLoan)]);
  useEffect(() => {
    if (defaultFormActions.isCardComplete('loanDetails')) {
      if (
        !(
          !!watchAcctNum &&
          !!currentMarketValue &&
          !!firstLoan &&
          !!insuredPropertyMunicipalityName &&
          !!insuredPropertyPostalCode &&
          !!insuredPropertyProvinceCode &&
          !!insuredPropertyStreetName &&
          !!insuredPropertyStreetNumber &&
          !!insuredPropertyStreetType &&
          !!purchasePriceOfHome &&
          !!isCondoFeeUpToDate &&
          !!isPropertyInsured &&
          !!isPropertyTaxUpToDate &&
          !!isUtilitiesUpToDate
        )
      ) {
        defaultFormActions?.removeCompleteCard('loanDetails');
      }
    } else if (
      !!watchAcctNum &&
      !!currentMarketValue &&
      !!firstLoan &&
      !!insuredPropertyMunicipalityName &&
      !!insuredPropertyPostalCode &&
      !!insuredPropertyProvinceCode &&
      !!insuredPropertyStreetName &&
      !!insuredPropertyStreetNumber &&
      !!insuredPropertyStreetType &&
      !!purchasePriceOfHome &&
      !!isCondoFeeUpToDate &&
      !!isPropertyInsured &&
      !!isPropertyTaxUpToDate &&
      !!isUtilitiesUpToDate
    ) {
      defaultFormActions?.addCompleteCard('loanDetails');
    }
  }, [
    watchAcctNum,
    currentMarketValue,
    firstLoan,
    insuredPropertyMunicipalityName,
    insuredPropertyPostalCode,
    insuredPropertyProvinceCode,
    insuredPropertyStreetName,
    insuredPropertyStreetNumber,
    insuredPropertyStreetType,
    purchasePriceOfHome,
    isCondoFeeUpToDate,
    isPropertyInsured,
    isPropertyTaxUpToDate,
    isUtilitiesUpToDate
  ]);

  return (
    <>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputWholeNumber
            {...commonCardProps}
            id={`${props.id}CMHCLoanNumber`}
            name="detail.cmhcLoanAccountNumber"
            title={inputFields.cmhcLoanNumber.field}
            cardName={title.field.value}
            customErrorMessage={ValidationErrorMessageType.LoanNumberOverride}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6 form__element--override-checkbox">
          {showCheckbox && (
            <FormInputCheckbox
              {...commonCardProps}
              id={`${props.id}OverrideCheckbox`}
              name={`other.validLoan`}
              title={inputFields.overrideCheckbox.field}
              cardName={title.field.value}
              isDisabled={isReadOnlyUser}
            />
          )}
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6 pullDown">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.id}PropertyStreetNum`}
            name={`detail.insuredPropertyStreetNumber`}
            title={inputFields.propertyAddressStNumber.field}
            type={ComponentType.Text}
            cardName={title.field.value}
            isRequired={false}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.id}BorrowerPropertyUnitNum`}
            name={`detail.insuredPropertyUnitNumber`}
            title={inputFields.propertyAddressUnitNumber.field}
            type={ComponentType.Text}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.id}BorrowerPropertyStreetName`}
            name={`detail.insuredPropertyStreetName`}
            title={inputFields.streetName.field}
            type={ComponentType.Text}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.id}BorrowerPropertyStreetType`}
            name={`detail.insuredPropertyStreetType`}
            title={inputFields.streetType.field}
            type={ComponentType.Text}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.id}BorrowerPropertyStreetDir`}
            name={`detail.insuredPropertyStreetDirection`}
            title={inputFields.streetDirection.field}
            type={ComponentType.Text}
            cardName={title.field.value}
            isRequired={false}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.id}BorrowerPropertyTownCity`}
            name={`detail.insuredPropertyMunicipalityName`}
            title={inputFields.city.field}
            type={ComponentType.Text}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputDropdownText
            {...commonCardProps}
            id={`${props.id}BorrowerPropertyProvince`}
            name={`detail.insuredPropertyProvinceCode`}
            title={inputFields.province.field}
            options={props.provinceOptions}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputFormattedTextInput
            {...commonCardProps}
            id={`${props.id}BorrowerPropertyPostalCode`}
            name={`detail.insuredPropertyPostalCode`}
            title={inputFields.postalCode.field}
            type={ComponentType.Text}
            cardName={title.field.value}
            formatProps={postalCodeFormat}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>

      <hr className="card__divider" />

      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}PurchasePrice`}
            name={`detail.purchasePriceOfHome`}
            title={inputFields.purchasePriceOfHome.field}
            description={inputFields.purchasePriceOfHomeDescription.field}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6 pullDown">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}CurrentMarketValue`}
            name={`detail.currentMarketValue`}
            title={inputFields.currentMarketValue.field}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputRadio
            {...commonCardProps}
            id={`${props.id}PropertyTax`}
            name={`detail.updatedPropertyTax`}
            title={inputFields.updatedPropertyTaxes.field}
            options={[
              {
                label: { value: i18next.t('DefaultSubmission-Card-RadioButtonYes') },
                value: FormInputRadioType.Yes
              },
              {
                label: { value: i18next.t('DefaultSubmission-Card-RadioButtonNo') },
                value: FormInputRadioType.No
              }
            ]}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputRadio
            {...commonCardProps}
            id={`${props.id}PropertyInsurance`}
            name={`detail.updatedPropertyInsurance`}
            title={inputFields.updatedPropertyInsurance.field}
            options={[
              {
                label: { value: i18next.t('DefaultSubmission-Card-RadioButtonYes') },
                value: FormInputRadioType.Yes
              },
              {
                label: { value: i18next.t('DefaultSubmission-Card-RadioButtonNo') },
                value: FormInputRadioType.No
              }
            ]}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          {isPropertyTaxUpToDate === 'false' && (
            <FormInputCurrency
              {...commonCardProps}
              id={`${props.id}PropertyTaxAmount`}
              name={`detail.amountPropertyTax`}
              title={inputFields.amountPropertyTaxes.field}
              cardName={title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          )}
        </div>
        <div className="form__element form__element--2-column col-6">
          {isPropertyInsured === 'false' && (
            <FormInputCurrency
              {...commonCardProps}
              id={`${props.id}InsuranceAmount`}
              name={`detail.amountPropertyInsurance`}
              title={inputFields.amountPropertyInsurance.field}
              cardName={title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          )}
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputRadio
            {...commonCardProps}
            id={`${props.id}Utilities`}
            name={`detail.updatedUtilities`}
            title={inputFields.updatedUtilities.field}
            options={[
              {
                label: { value: i18next.t('DefaultSubmission-Card-RadioButtonYes') },
                value: FormInputRadioType.Yes
              },
              {
                label: { value: i18next.t('DefaultSubmission-Card-RadioButtonNo') },
                value: FormInputRadioType.No
              }
            ]}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputRadio
            {...commonCardProps}
            id={`${props.id}CondominiumFee`}
            name={`detail.updatedCondominiumFee`}
            title={inputFields.updatedCondominiumFees.field}
            options={[
              {
                label: { value: i18next.t('DefaultSubmission-Card-RadioButtonYes') },
                value: FormInputRadioType.Yes
              },
              {
                label: { value: i18next.t('DefaultSubmission-Card-RadioButtonNo') },
                value: FormInputRadioType.No
              }
            ]}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          {isUtilitiesUpToDate === 'false' && (
            <FormInputCurrency
              {...commonCardProps}
              id={`${props.id}UtilitiesAmount`}
              name={`detail.amountUtilities`}
              title={inputFields.amountUtilities.field}
              cardName={title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          )}
        </div>
        <div className="form__element form__element--2-column col-6">
          {isCondoFeeUpToDate === 'false' && (
            <FormInputCurrency
              {...commonCardProps}
              id={`${props.id}CondoFeeAmount`}
              name={`detail.amountCondominiumFee`}
              title={inputFields.amountCondoFee.field}
              cardName={title.field.value}
              isRequired={true}
              isDisabled={isReadOnlyUser}
            />
          )}
        </div>
      </div>

      <hr className="card__divider" />

      <h3 className="card__body-heading">{i18next.t('DefaultSubmission-Card-MortgageDetails')}</h3>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          {/* TODO: Check occupancy type mappings */}
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.id}OccupancyType`}
            name={`detail.firstLoan.occupancyType`}
            title={inputFields.mortgageOccupancyType.field}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.id}RankingPriority`}
            name={`detail.firstLoan.rankingPriority`}
            title={inputFields.mortgageRankingPriority.field}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}UnpaidPrincipal1`}
            name={`detail.firstLoan.unpaidBalance`}
            title={inputFields.mortgageUnpaidBalance.field}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}RequiredMonthlyPayment`}
            name={`detail.firstLoan.requiredMonthlyPaymentPrincipalInterest`}
            title={inputFields.mortgageRequiredMonthlyPayment.field}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}RequiredInterestRate`}
            name={`detail.firstLoan.interestRate`}
            title={inputFields.mortgageInterestRate.field}
            disablePlaceholder={true}
            decimalPlace={4}
            enableSeparator={false}
            disablePrefix={true}
            disableSuffix={true}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputCalendar
            {...commonCardProps}
            id={`${props.id}RenewalDate`}
            name={`detail.firstLoan.nextRenewalDate`}
            title={inputFields.mortgageNextRenewalDate.field}
            cardName={title.field.value}
            disabled={isReadOnlyUser || props.disableCalendar}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.id}ApprovedLenderName1`}
            name={`detail.firstLoan.registeredMortgageHolder`}
            title={inputFields.registeredMortgageHolder.field}
            description={inputFields.registeredMortgageHolderDescription.field}
            cardName={title.field.value}
            isRequired={false}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6 pullDown">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}CurrentArrears`}
            name={`detail.firstLoan.currentArrears`}
            title={inputFields.mortgageCurrentArrears.field}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <label>
            <Text field={inputFields.originalAmortizationPeriod.field} />
          </label>
          <FormInputWholeNumber
            {...commonCardProps}
            id={`${props.id}detail.firstLoan.originalAmortizationPeriodYears`}
            name={`detail.firstLoan.originalAmortizationPeriodYears`}
            title={props.currentNumberOfYears.field}
            cardName={title.field.value}
            isRequired={false}
            isDisabled={isReadOnlyUser}
            titleAsSubheading={true}
          />
        </div>
        <div className="form__element form__element--2-column col-6 pullDown">
          <FormInputWholeNumber
            {...commonCardProps}
            id={`${props.id}detail.firstLoan.originalAmortizationPeriodMonths`}
            name={`detail.firstLoan.originalAmortizationPeriodMonths`}
            title={props.currentNumberOfMonths.field}
            cardName={title.field.value}
            isRequired={false}
            isDisabled={isReadOnlyUser}
            titleAsSubheading={true}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <label>
            <Text field={inputFields.currentAmortizationPeriod.field} />
          </label>
          <FormInputWholeNumber
            {...commonCardProps}
            id={`${props.id}CurrentAmortizationPeriodYears`}
            name={`detail.firstLoan.currentAmortizationPeriodYears`}
            title={props.currentNumberOfYears.field}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
            titleAsSubheading={true}
          />
        </div>
        <div className="form__element form__element--2-column col-6 pullDown">
          <FormInputWholeNumber
            {...commonCardProps}
            id={`${props.id}detail.firstLoan.currentAmortizationPeriodMonths`}
            name={`detail.firstLoan.currentAmortizationPeriodMonths`}
            title={props.currentNumberOfMonths.field}
            cardName={title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
            titleAsSubheading={true}
          />
        </div>
      </div>
      <hr className="card__divider" />
      <h3 className="card__body-heading">
        {i18next.t('DefaultSubmission-Card-LoanDetails-Mortgage2Details')}
      </h3>
      <div className="row card__body-row mt-3">
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}UnpaidPrincipal2`}
            name={`detail.secondLoan.unpaidBalance`}
            title={inputFields.mortgageUnpaidBalance.field}
            cardName={title.field.value}
            isRequired={false}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.id}RequiredMonthlyPayment2`}
            name={`detail.secondLoan.requiredMonthlyPaymentPrincipalInterest`}
            title={inputFields.mortgageRequiredMonthlyPayment.field}
            cardName={title.field.value}
            isRequired={false}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputTextInput
            {...commonCardProps}
            id={`${props.id}ApprovedLenderName2`}
            name={`detail.secondLoan.registeredMortgageHolder`}
            title={inputFields.registeredMortgageHolder.field}
            description={inputFields.registeredMortgageHolderDescription.field}
            cardName={title.field.value}
            isRequired={false}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
    </>
  );
};

export const LoanDetails: React.FC<LoanDetailsProps> = (props) => {
  const [numbersArray, setNumbersArray] = useState([0]);
  const [number, setNumber] = useState(0);
  const isInitialMount = useRef(true);
  const [borrowerGrossIncomeArray, setBorrowerGrossIncomeArray] = useState([0]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setNumbersArray(numbersArray.concat(number));
    }
  }, [number]);

  return (
    <LinedCard
      id={props.id}
      testId={props.testId}
      linePosition={props.linePosition}
      lineColor={props.accordionLineColor}
    >
      <AccordionContainer accordionId={`${props.id}Accordion`} title={props.title.field}>
        <LoanDetailsContainer {...props} />
      </AccordionContainer>
    </LinedCard>
  );
};
