import i18next from 'i18next';

import { ProgressBarStepStatus, ProgressBarItemProps, SectionType } from './ProgressBarItem';

export const getProgressBarData = (cardRefs: React.MutableRefObject<HTMLSpanElement>[]) => {
  const initialProgressBarItems: ProgressBarItemProps[] = [
    {
      name: {
        value: i18next.t('DefaultSubmission-Card-LenderDetailsHeading')
      },
      status: ProgressBarStepStatus.DEFAULT,
      arialabelScreenReader: i18next.t('DefaultGoToCard'),
      validationKey: 'lender'
    },
    {
      name: {
        value: i18next.t('DefaultSubmission-Card-LoanDetailsHeading')
      },
      status: ProgressBarStepStatus.DEFAULT,
      arialabelScreenReader: i18next.t('DefaultGoToCard'),
      validationKey: 'loanDetails'
    },
    {
      name: {
        value: i18next.t('DefaultSubmission-Card-BorrowersHeading')
      },
      type: SectionType.SectionC,
      status: ProgressBarStepStatus.DEFAULT,
      arialabelScreenReader: i18next.t('DefaultGoToCard'),
      validationKey: 'borrower'
    },
    {
      name: {
        value: i18next.t('DefaultSubmission-Card-AssetsHeading')
      },
      status: ProgressBarStepStatus.DEFAULT,
      arialabelScreenReader: i18next.t('DefaultGoToCard'),
      validationKey: 'assets'
    },
    {
      name: {
        value: i18next.t('DefaultSubmission-Card-LiabilitiesHeading')
      },
      status: ProgressBarStepStatus.DEFAULT,
      arialabelScreenReader: i18next.t('DefaultGoToCard'),
      validationKey: 'liabilities'
    },
    {
      name: {
        value: i18next.t('DefaultSubmission-Card-MortgageObligationsHeading')
      },
      status: ProgressBarStepStatus.DEFAULT,
      arialabelScreenReader: i18next.t('DefaultGoToCard'),
      validationKey: 'mortgageObligations'
    },
    {
      name: {
        value: i18next.t('DefaultSubmission-Card-GDS-TDS-Heading')
      },
      status: ProgressBarStepStatus.DEFAULT,
      arialabelScreenReader: i18next.t('DefaultGoToCard'),
      validationKey: 'GDS&TDS'
    },
    {
      name: {
        value: i18next.t('DefaultSubmission-Card-OtherFinancialObligationsHeading')
      },
      status: ProgressBarStepStatus.DEFAULT,
      arialabelScreenReader: i18next.t('DefaultGoToCard'),
      validationKey: 'otherFinancialObligations'
    },
    {
      name: {
        value: i18next.t('DefaultSubmission-Card-DefaultManagementHeading')
      },
      type: SectionType.SectionI,
      status: ProgressBarStepStatus.DEFAULT,
      arialabelScreenReader: i18next.t('DefaultGoToCard'),
      validationKey: 'defaultManagement'
    },
    {
      name: {
        value: i18next.t('DefaultSubmission-Card-DefaultManagementToolsHeading')
      },
      status: ProgressBarStepStatus.DEFAULT,
      arialabelScreenReader: i18next.t('DefaultGoToCard'),
      validationKey: 'defaultManagementTools'
    },
    {
      name: {
        value: i18next.t('DefaultSubmission-Card-LenderAcknowledgementHeading')
      },
      status: ProgressBarStepStatus.DEFAULT,
      arialabelScreenReader: i18next.t('DefaultGoToCard'),
      validationKey: 'lenderAcknowledgement'
    },
    {
      name: {
        value: i18next.t('DefaultSubmission-Card-MonitoringStrategyHeading')
      },
      status: ProgressBarStepStatus.DEFAULT,
      arialabelScreenReader: i18next.t('DefaultGoToCard'),
      validationKey: 'monitoringStrategy'
    },
    {
      name: {
        value: i18next.t('DefaultSubmission-Card-DefaultHistoryHeading')
      },
      status: ProgressBarStepStatus.DEFAULT,
      arialabelScreenReader: i18next.t('DefaultGoToCard'),
      validationKey: 'defaultHistory'
    },
    {
      name: {
        value: i18next.t('DefaultSubmission-Card-AttachmentsHeading')
      },
      status: ProgressBarStepStatus.DEFAULT,
      arialabelScreenReader: i18next.t('DefaultGoToCard'),
      validationKey: 'attachments'
    }
  ];

  return {
    progressHeaderTitle: {
      value: i18next.t('DefaultSubmission-Card-ProgressBarHeading')
    },
    progressBarItems: initialProgressBarItems,
    itemReferences: cardRefs
  };
};
