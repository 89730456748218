import React from 'react';

// TODO: Albert, review this component and create a new notification card instead of using older components
import { NotificationCard } from 'Feature/CommonComponents/ContentComponents';
import { CardType } from 'Constants/Types/CardType';
import { ImageField } from '@sitecore-jss/sitecore-jss-react/types/components/Image';

const loanAndClaimValidationErrorCard = {
  id: 'validationErrorNotification',
  notificationType: CardType.VERTICAL_ERROR,
  title: 'Validation Error',
  message: [],
  formWarningIcon: {
    src: '/data/media/image/icons/icon_error_red.svg',
    alt: 'Form Warning'
  }
};

const LoanNotificationCard: React.FC<{
  errors: string[];
  errorIcon: ImageField;
}> = ({ errors, errorIcon }) => {
  const warningIcon = {
    src: errorIcon.value?.src,
    alt: errorIcon.value?.alt
  };

  const notificationCard = {
    ...loanAndClaimValidationErrorCard,
    message: errors,
    formWarningIcon: warningIcon
  };

  return (
    <div className="row">
      <div className="col-8">
        <NotificationCard {...notificationCard} />
      </div>
    </div>
  );
};

export default LoanNotificationCard;
