import React, { HTMLAttributes } from 'react';
import { AutocompleteRenderOptionState } from '@mui/material';
import styles from './styles.module.scss';
import { ComboboxOption } from './types';

export const getComboboxOptionsReactNode = (
  props: HTMLAttributes<HTMLLIElement>,
  option: ComboboxOption,
  state: AutocompleteRenderOptionState
): JSX.Element => {
  const key = `listItem-${state}-${option.value}`;
  return (
    <li {...props} key={key} value={option.value} className={styles.menuListItem}>
      {option.icon && <span className={styles.menuListItemIcon}>{option.icon}</span>}
      <span className={styles.menuListItemText}>{option.label}</span>
    </li>
  );
};
