import * as React from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import FocusLock from 'react-focus-lock';

import { ModalProps } from 'Feature/CommonComponents/ContentComponents/Modal/Modal.types';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { ButtonType, Button } from 'Feature/CommonComponents/UserControls';

import i18n from 'i18next';

export const Modal: React.FC<ModalProps> = ({
  type,
  title,
  isActive,
  content,
  linkButton,
  buttons,
  onCloseCallback,
  shouldCloseOnBackgroundClick,
  noBottomSpace,
  alignButtonsLeft,
  displayDivider
}) => {
  return (
    <CSSTransition
      in={isActive}
      classNames="modal"
      timeout={450}
      unmountOnExit={true}
      mountOnEnter={true}
    >
      <FocusLock className="modal" noFocusGuards>
        <div
          className="modal__bg"
          data-testid="modal-bg"
          aria-label="modal-bg"
          role="button"
          tabIndex={0}
          onClick={(e: any) => {
            if (shouldCloseOnBackgroundClick) onCloseCallback(e);
          }}
          onKeyDown={(e: any) => {
            if (shouldCloseOnBackgroundClick) onCloseCallback(e);
          }}
        />
        <div
          className={`card modal__card ${
            noBottomSpace ? 'modal__card__lessBottomPadding' : 'modal__card__bottomPadding'
          } ${type === ApplicationStates.DEFAULT ? 'modal__card--default' : ''}${
            type === ApplicationStates.ERROR ? 'modal__card--error' : ''
          }${type === ApplicationStates.SUCCESS ? 'modal__card--success' : ''}${
            type === ApplicationStates.WARNING ? 'modal__card--warning' : ''
          }
            ${type === ApplicationStates.INACTIVE ? 'modal__card--inactive' : ''}
            ${type === ApplicationStates.CANCEL ? 'modal__card--error' : ''}`}
        >
          <div className="card-header card__header modal__card__header">
            <div className="modal__card__header__icon">
              <i className="material-icons icon--size-40 icon--v-align-middle">
                {type === ApplicationStates.ERROR && 'highlight_off'}
                {type === ApplicationStates.SUCCESS && 'check_circle_outline'}
                {type === ApplicationStates.WARNING && 'error_outline'}
                {type === ApplicationStates.DEFAULT && 'announcement'}
                {type === ApplicationStates.INACTIVE && 'pause_circle_outline'}
                {type === ApplicationStates.CANCEL && 'error_outline'}
              </i>
            </div>
            <div className="modal__card__header__title">
              <h2>{title}</h2>
            </div>
            <div className="modal__card__close-button">
              <Button
                id="buttonModalClose"
                name="modal-close-button"
                buttonType={ButtonType.TEXT}
                icon="close"
                isIcon={true}
                onClick={(e) => onCloseCallback(e)}
                iconSize={24}
                otherProps={{ 'data-testid': 'modal-close-button' }}
              ></Button>
              <label className="sr-only">{i18n.t('DefaultCloseModal')}</label>
            </div>
          </div>
          <div className={`card-body ${noBottomSpace ? '' : 'card__body modal__card__body'}`}>
            {content && content()}
          </div>
          <hr className={`${displayDivider ? 'card_divider' : ''}`}></hr>
          <div
            className={`modal__card__footer  ${
              alignButtonsLeft
                ? 'modal__card__footer--buttons_left'
                : 'modal__card__footer--buttons'
            }`}
          >
            <div className="modal__card__footer__link-button">{linkButton && linkButton()}</div>
            <div className="modal__card__footer__buttons">{buttons && buttons()}</div>
          </div>
        </div>
      </FocusLock>
    </CSSTransition>
  );
};
