import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import { CommentType, correspondenceSchema, CorrespondenceType } from '@hobt/claim-domain';
import { HbtServiceErrorCodes, HttpResponseStatusCodes } from '@hobt/constants';
import { hbtResolver } from '@hobt/schema-validator';
import { UserInfo } from '@hobt/user-domain';

import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

import { ContestClaimRequestPayload } from './types';
import { config } from '../../../../../../config';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const useContestClaimFunctions = (
  onSuccessCallback?: Function,
  onErrorCallback?: Function,
  onCancelCallback?: Function
) => {
  const [showValidationError, setShowValidationError] = useState<boolean>(false);
  const { setLoadingStatus } = useHBTFormContext();

  // API Client Config
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  const authContext = useAuthenticationContext();
  const { postWithAuth } = ApiClient(authContext, apiClientConfig);
  const { uuid } = useHBTFormContext();
  const claimID = uuid;
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const userDetails: UserInfo | undefined = sitecoreContext?.user;

  const hookForm = useForm({
    resolver: hbtResolver(correspondenceSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  } as Record<string, any>);

  const handleSubmit: any = (data: ContestClaimRequestPayload) => {
    const url = config.claimApi.urls.correspondences;
    const payloadData = {
      claimID,
      correspondencePayload: {
        commentTypeCode: CommentType.ContestDecision,
        correspondenceTypeCode: CorrespondenceType.External,
        remarkText: data.remarkText,
        submitterUserID: userDetails?.userID,
        submitterUserTypeCode: userDetails?.userTypeCode,
        attachments: data.attachments
      }
    };

    setLoadingStatus?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description',
      isFlyout: true
    });

    postWithAuth(url, payloadData)
      .then((responseData: AxiosResponse<ContestClaimRequestPayload>) => {
        if (onSuccessCallback) {
          onSuccessCallback(responseData);
        }

        setLoadingStatus?.({
          isLoading: false
        });
      })
      .catch((e: AxiosError<any>) => {
        if (
          e.response?.status === HttpResponseStatusCodes.ServerError &&
          e.response?.data?.error?.errorCode === HbtServiceErrorCodes.HBT_ERR_VALIDATION.code
        ) {
          setShowValidationError(true);
        } else if (onErrorCallback != null) {
          onErrorCallback();
        }

        setLoadingStatus?.({
          isLoading: false
        });
      });
  };

  const onSubmitErrors: any = (errs: Record<string, Object>) => {
    // TODO: Remove once validations are in place
    // eslint-disable-next-line no-console
    console.log('FORM ERRORS: ', errs);
  };

  const onCloseValidationErrors = () => {
    setShowValidationError(false);
  };

  const cancelFlyout = () => {
    hookForm.reset();
    if (onCancelCallback != null) {
      onCancelCallback();
    }
  };

  return {
    handleSubmit,
    onSubmitErrors,
    cancelFlyout,
    onCloseValidationErrors,
    userDetails,
    onCancelCallback,
    showValidationError,
    hookForm
  };
};

export default useContestClaimFunctions;
