import * as React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Error500Props from '../../models/Error500Props';

const Error500: React.FC<any> = (
  props: Error500Props,
  { context = { site: { name: '' }, language: '' } }
) => (
  <div className="content-min-height">
    <div className="row justify-content-md-center">
      <div className="col-6 page__error--holder">
        <section>
          <h2>
            <Text field={props.fields.heading} />
          </h2>
          <RichText field={props.fields.content} />
        </section>
      </div>
    </div>
  </div>
);

export default Error500;
