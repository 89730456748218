import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import get from 'Constants/Utils/GetPathInObject';
import FormError from 'Components/Inputs/FormError';

import type { Props } from './types';
import styles from './styles.module.scss';

const FormCheckboxListWrapper: FC<Props> = ({ name, label, className, children }) => {
  const {
    formState: { errors }
  } = useFormContext();
  const { message, type } = get(errors, name) || {};
  return (
    <fieldset
      className={`d-flex flex-wrap justify-content-start align-items-start ${className}${
        message ? ' error' : ''
      }`}
      role="group"
    >
      <legend className={styles['checkbox-label']}>
        <Text field={label} />
      </legend>
      {children}
      <FormError className={styles.full} name={name} message={message} type={type} />
    </fieldset>
  );
};

export default FormCheckboxListWrapper;
export type { Props };
