import i18n from 'i18next';
import { checkExactLengthPatternExists } from '@hobt/constants';
import { ValidationErrorMessageType } from 'Feature/DefaultSubmissionForm/models/typeCode.types';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

/*
    Error Messages (errMsg examples):
    "lender.approvedLenderName" is not allowed to be empty
    "lender.transitNumber" must be less than or equal to 99999
    "lender.transitNumber" must be greater than or equal to 0
    "lender.contactName" length must be less than or equal to 35 characters long
*/

// TODO: Remove all joi helper functions once all the forms are using hbtResolver
export enum CustomErrorFields {
  cmhcLoanAccountNumber = 'cmhcLoanAccountNumber'
}

function CustomErrorMessage(
  numberLimitString: string,
  customErrorMessage: ValidationErrorMessageType
) {
  switch (customErrorMessage) {
    case ValidationErrorMessageType.MaxString:
      return i18n
        .t('DefaultSubmission-Card-ErrorMessage-MaxString')
        .replace('{count}', numberLimitString.length.toString());
    case ValidationErrorMessageType.LoanNumberOverride:
      return i18n
        .t('DefaultSubmission-Card-ErrorMessage-ExactCount')
        .replace('{count}', numberLimitString.length.toString());
    default:
      return 'Custom error message not found';
  }
}

function FormatNumMaxErrorMessage(
  defaultErrorMsg: string,
  customErrorMessage?: ValidationErrorMessageType
) {
  const maxNumString = defaultErrorMsg.substring(
    defaultErrorMsg.indexOf('equal to') + 'equal to'.length + 1,
    defaultErrorMsg.length
  );

  if (customErrorMessage != null) {
    return CustomErrorMessage(maxNumString, customErrorMessage);
  }

  // Round up to nearest whole number
  let unformattedMaxNum = Math.ceil(Number(maxNumString));

  // In the event that the number did not round up previously (due to not having decimal), this is to ensure the value is number + 1
  if (maxNumString === unformattedMaxNum.toString()) {
    unformattedMaxNum += 1;
  }

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const maxNum = unformattedMaxNum.toLocaleString(sitecoreContext?.language);

  return i18n.t('DefaultSubmission-Card-ErrorMessage-MaxNumber').replace('{count}', maxNum);
}

function FormatNumMinErrorMessage(
  defaultErrorMsg: string,
  customErrorMessage?: ValidationErrorMessageType
) {
  let minNumString = defaultErrorMsg.substring(
    defaultErrorMsg.indexOf('equal to') + 'equal to'.length + 1,
    defaultErrorMsg.length
  );

  if (customErrorMessage != null) {
    /* TODO: Hard-coding for now to over-ride message to continue showing "Must be 8 characters" for the Loan Number
        even though you only need 7 (text message doesn't actual reflect schema validation - requested by client). */
    if (customErrorMessage === ValidationErrorMessageType.LoanNumberOverride) {
      minNumString += '0';
    }

    return CustomErrorMessage(minNumString, customErrorMessage);
  }

  return i18n.t('DefaultSubmission-Card-ErrorMessage-MinNumber').replace('{count}', minNumString);
}

function FormatStringMaxErrorMessage(defaultErrorMsg: string) {
  const maxNum = defaultErrorMsg.substring(
    defaultErrorMsg.indexOf('equal to') + 'equal to'.length + 1,
    defaultErrorMsg.indexOf('characters long') - 1
  );

  return i18n.t('DefaultSubmission-Card-ErrorMessage-MaxString').replace('{count}', maxNum);
}

function FormatStringMinErrorMessage(defaultErrorMsg: string) {
  const minNum = defaultErrorMsg.substring(
    defaultErrorMsg.indexOf('at least') + 'at least'.length + 1,
    defaultErrorMsg.indexOf('characters long') - 1
  );

  return i18n.t('DefaultSubmission-Card-ErrorMessage-MinString').replace('{count}', minNum);
}

/**
 * AJV Error Mapping Functions
 */
function formatMinimumErrorMessage(errorMsg: string, name?: string) {
  const minNumString = errorMsg.substring(
    errorMsg.indexOf('>=') + '>='.length + 1,
    errorMsg.length
  );

  if (name === CustomErrorFields.cmhcLoanAccountNumber) {
    return i18n
      .t('DefaultSubmission-Card-ErrorMessage-ExactCount')
      .replace('{count}', minNumString.length.toString());
  }

  return i18n.t('DefaultSubmission-Card-ErrorMessage-MinNumber').replace('{count}', minNumString);
}

function formatMaximumErrorMessage(errorMsg: string, name?: string) {
  const maxNumString = errorMsg.substring(
    errorMsg.indexOf('<=') + '<='.length + 1,
    errorMsg.length
  );

  if (name === CustomErrorFields.cmhcLoanAccountNumber) {
    return i18n
      .t('DefaultSubmission-Card-ErrorMessage-ExactCount')
      .replace('{count}', maxNumString.length.toString());
  }

  // Round up to nearest whole number
  let unformattedMaxNum = Math.ceil(Number(maxNumString));

  // In the event that the number did not round up previously (due to not having decimal), this is to ensure the value is number + 1
  if (maxNumString === unformattedMaxNum.toString()) {
    unformattedMaxNum += 1;
  }

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const maxNum = unformattedMaxNum.toLocaleString(sitecoreContext?.language);

  return i18n.t('DefaultSubmission-Card-ErrorMessage-MaxNumber').replace('{count}', maxNum);
}

function formatMaxLengthErrorMessage(errorMsg: string) {
  const maxStringLength = errorMsg.substring(
    errorMsg.indexOf('longer than') + 'longer than'.length + 1,
    errorMsg.length - 'characters'.length
  );

  return i18n
    .t('DefaultSubmission-Card-ErrorMessage-MaxString')
    .replace('{count}', maxStringLength);
}

function formatMinLengthErrorMessage(errorMsg: string) {
  const minStringLength = errorMsg.substring(
    errorMsg.indexOf('shorter than') + 'shorter than'.length + 1,
    errorMsg.length - 'characters'.length
  );

  if (minStringLength != null && Number(minStringLength) === 1) {
    return i18n.t('DefaultSubmission-Card-ErrorMessage-Required');
  }

  return i18n
    .t('DefaultSubmission-Card-ErrorMessage-MinString')
    .replace('{count}', minStringLength);
}

function getFormatErrorMessage(errorMsg: string) {
  const formatErrType = errorMsg
    .substring('should match format'.length + 1, errorMsg.length)
    .replace(/["]+/g, '');
  switch (formatErrType) {
    case 'email':
      return i18n.t('DefaultSubmission-Card-ErrorMessage-InvalidEmail');
    default:
      return '';
  }
}

function formatPatternErrorMessage(errorMsg: string) {
  // check if pattern is exact length pattern
  const matchExactLenPattern = checkExactLengthPatternExists();
  const exactLength = errorMsg.match(new RegExp(matchExactLenPattern))?.[1];

  if (exactLength != null && exactLength.length > 0) {
    return i18n.t('DefaultSubmission-Card-ErrorMessage-ExactCount').replace('{count}', exactLength);
  }

  /*
    if there was not HBT err thrown or no pattern matches check,
    throw a genereic err instead of schema error
  */
  return i18n.t('DefaultSubmission-Card-ErrorMessage-InvalidInput');
}

/**
 * TODO: Use a less hacky approach. Perhaps have the JOI schema return custom error messages for the mapping.
 * Otherwise find a cleaner way to extract the min and max value
 */

export default function GetErrorMessage(
  errType: any,
  errMsg: string,
  customErrorMessage?: ValidationErrorMessageType,
  fieldLabel?: string,
  name?: string
) {
  // Handles custom error messages mapping with Sitecore content:
  let match = null;
  if (errMsg !== undefined && errMsg !== null && errMsg !== '') {
    const result = errMsg.split('failed custom validation because ');
    match = result.pop()!.match(/\[HBT_VAL_ERR_([^\]]*)\]/);
  }
  if (match !== null) {
    const errorCode = `HBT_VAL_ERR_${match[1]}`;
    let mappedErrMsg = i18n.t(`Errors-${errorCode}`);
    mappedErrMsg = mappedErrMsg.replace('{FieldLabel}', fieldLabel || '');
    return mappedErrMsg;
  }

  if (errType?.startsWith('number') && errMsg?.startsWith('should be number')) {
    return i18n.t('DefaultSubmission-Card-ErrorMessage-Required');
  }

  // TODO: Remove joi keywords once we finalize all of the forms to use hbtResolver
  switch (errType) {
    case 'required':
    case 'enum':
    case 'isNotEmpty':
    case 'number.base':
    case 'any.required':
    case 'string.empty':
    case 'boolean.base':
    case 'date.format':
    case 'array.min':
    case 'any.invalid':
    case 'array.includesRequiredUnknowns':
    case 'date.base':
    case 'string':
    case 'boolean,string':
    case 'minItems':
    case 'any.only':
    case 'integer':
    case 'oneOf':
      return i18n.t('DefaultSubmission-Card-ErrorMessage-Required');
    case 'format':
      return getFormatErrorMessage(errMsg);
    case 'number.unsafe':
      return i18n.t('DefaultSubmission-Card-ErrorMessage-UnsafeNumber');
    case 'number.max':
      return FormatNumMaxErrorMessage(errMsg, customErrorMessage);
    case 'maximum':
      return formatMaximumErrorMessage(errMsg, name);
    case 'number.min':
      return FormatNumMinErrorMessage(errMsg, customErrorMessage);
    case 'minimum':
      return formatMinimumErrorMessage(errMsg, name);
    case 'string.max':
      return FormatStringMaxErrorMessage(errMsg);
    case 'maxLength':
      return formatMaxLengthErrorMessage(errMsg);
    case 'string.min':
      return FormatStringMinErrorMessage(errMsg);
    case 'minLength':
      return formatMinLengthErrorMessage(errMsg);
    case 'pattern':
      return formatPatternErrorMessage(errMsg);
    case 'string.email':
      return i18n.t('DefaultSubmission-Card-ErrorMessage-InvalidEmail');
    default:
      return '';
  }
}
