import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import i18n from 'i18next';
import {
  HbtModifyExternalPIUserReducerState,
  HbtModifyExternalPIUserAction,
  HbtModifyExternalPIUserActionType,
  HbtModifyExternalPIUserActionValuePayload,
  HbtModifyExternalPIUserActionFunctionPayload
} from './types';

export const HbtModifyExternalPIUserReducer = (
  state: HbtModifyExternalPIUserReducerState,
  { action, payload }: HbtModifyExternalPIUserAction
): HbtModifyExternalPIUserReducerState => {
  const newState = { ...state };

  const reducers = {
    [HbtModifyExternalPIUserActionType.SET_IS_MODAL_LOADING]: () => {
      newState.isModalLoading = (payload as HbtModifyExternalPIUserActionValuePayload).value;
    },
    [HbtModifyExternalPIUserActionType.SET_IS_LOADING]: () => {
      newState.isLoading = (payload as HbtModifyExternalPIUserActionValuePayload).value;
    },
    [HbtModifyExternalPIUserActionType.SET_IS_PAGE_LOADING]: () => {
      newState.isPageLoading = (payload as HbtModifyExternalPIUserActionValuePayload).value;
    },
    [HbtModifyExternalPIUserActionType.SUBMIT_SUCCESS]: () => {
      newState.isBypass = true;
      newState.isLoading = false;
      newState.modalActive = false;
      newState.toastProps = {
        isActive: true,
        title: i18n.t('Globals-Toast-Success-Title'),
        type: ApplicationStates.SUCCESS,
        content: i18n.t('UserManagement-ModifyUsers-SuccessToastMessage'),
        onCloseCallback: (payload as HbtModifyExternalPIUserActionFunctionPayload).function
      };
    },

    [HbtModifyExternalPIUserActionType.ON_ERROR]: () => {
      newState.isBypass = false;
      newState.isLoading = false;
      newState.toastProps = {
        isActive: true,
        title: i18n.t('Globals-Toast-Error-Title'),
        type: ApplicationStates.ERROR,
        content: i18n.t('Globals-UnknownErrorMessage'),
        onCloseCallback: (payload as HbtModifyExternalPIUserActionFunctionPayload).function
      };
    },

    [HbtModifyExternalPIUserActionType.DISMISS_MODAL]: () => {
      newState.modalActive = false;
    },

    [HbtModifyExternalPIUserActionType.SHOW_MODAL]: () => {
      newState.isBypass = false;
      newState.modalActive = true;
    },
    [HbtModifyExternalPIUserActionType.SHOW_UPDATE_MODAL]: () => {
      newState.isBypass = false;
      newState.updateModalActive = true;
    },
    [HbtModifyExternalPIUserActionType.DISMISS_UPDATE_MODAL]: () => {
      newState.updateModalActive = false;
    },

    [HbtModifyExternalPIUserActionType.DISMISS_TOAST]: () => {
      newState.toastProps.isActive = false;
    }
  };

  reducers[action]();

  return newState;
};
