import React, { FC } from 'react';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import { TooltipPosition, TooltipProps } from 'Components/Core/Tooltip/types';
import styles from './styles.module.scss';

/**
 * @param {label} - Text to be displayed in the tooltip.
 * @param {position} - Position of the tooltip, 8 options available, default is top-center.
 * @param {iconSize} - Size of the icon, 4 options available, default is small.
 * @param {customStyles} - Custom styles to be applied to the tooltip, default is null.
 * @param {children} - The children to be rendered inside the tooltip. If it exists, we'll not render the icon.
 * @returns {Tooltip} Rendered Tooltip component.
 */

/**
 * Tooltip component
 *
 * This is the Tooltip component written using storybook. It handles different tooltip positions and icon sizes
 */

const iconSizeMapping: { [key in HbtIconSize]: string } = {
  [HbtIconSize.SMALL]: 'icon-sm',
  [HbtIconSize.MEDIUM]: 'icon-md',
  [HbtIconSize.LARGE]: 'icon-lg',
  [HbtIconSize.X_LARGE]: 'icon-xl'
};

export const Tooltip: FC<TooltipProps> = ({
  children,
  label,
  position,
  iconSize,
  customStyles
}) => {
  const iconSizeClass = iconSizeMapping[iconSize ?? HbtIconSize.SMALL];
  const wrapperClass = `${styles.tooltipWrapper} ${styles[iconSizeClass]}`;

  const tooltipPosition = position ?? TooltipPosition.TOP_CENTER;
  const tooltipClass = `${styles.tooltip} ${styles[tooltipPosition]} ${styles[iconSizeClass]}`;

  return (
    <div className={wrapperClass}>
      {children ? (
        <div className="tooltip-children">{children}</div>
      ) : (
        <HbtIcon
          className={styles.iconStyle}
          size={iconSize ?? HbtIconSize.SMALL}
          type="icon_info_outlined"
        />
      )}

      <div
        className={tooltipClass}
        style={customStyles}
        data-testid="tooltip-label"
        role="tooltip"
        aria-describedby="tooltip-content"
      >
        <div id="tooltip-content" className={styles.tooltipLabel}>
          {label}
        </div>
      </div>
    </div>
  );
};
