import React, { useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import i18n from 'i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { SearchBarProps, SearchFieldOptions } from 'Components/PageComponents/SearchBar/types';
import styles from './styles.module.scss';

export const SearchBar: React.FC<SearchBarProps> = ({
  name,
  fieldLabelAriaText,
  placeholderText,
  searchButtonAriaText,
  clearButtonAriaText,
  onSearch,
  onClear,
  count,
  searchFieldOptions,
  displaySearchResultMessage = true
}: SearchBarProps) => {
  const { reset, register, handleSubmit, watch, getValues, formState } = useForm();
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [currentSelectedField, setCurrentSelectedField] = useState<SearchFieldOptions>();

  const searchValue = watch('value');
  const searchField = watch('field');

  const handleSearch: SubmitHandler<any> = (data: { field: string; value: string }) => {
    const { field, value } = data;

    if (value && value.length !== 0) {
      if (onSearch) {
        onSearch(field, value);
      }
      setHasSearched(true);
    }
  };

  const handleReset = () => {
    if (onClear) {
      onClear();
    }
    reset();
    setHasSearched(false);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      const values = getValues(['field', 'value']);
      handleSearch({ field: values[0], value: values[1] });
    }
  };

  useEffect(() => {
    if (searchValue === '' && formState.dirtyFields.value === true) {
      if (onClear) {
        onClear();
      }
      setHasSearched(false);
    }
  }, [searchValue]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setCurrentSelectedField(searchFieldOptions?.find((itm) => itm?.value === searchField));
  }, [searchField]);

  return (
    <>
      <div className={styles.searchBarContainer} key={name}>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className={styles.searchFieldValueWrapper}>
            <div
              className={`${styles.searchFieldWrapper} ${
                searchFieldOptions != null && searchFieldOptions.length === 1
                  ? styles.singleSearchField
                  : ''
              }`}
            >
              <div>
                <label htmlFor="searchFieldSelect" className="sr-only">
                  {fieldLabelAriaText}
                </label>
                <select
                  id="searchFieldSelect"
                  {...(register('field') as any)}
                  defaultValue={searchFieldOptions?.[0]?.value ?? ''}
                >
                  {searchFieldOptions?.map((itm: SearchFieldOptions) => (
                    <option value={itm.value || undefined} key={`${itm.value}Option`}>
                      {itm.displayText}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className={styles.searchTextBoxWrapper}>
              <label htmlFor="searchTextBox" className="sr-only">
                {placeholderText} {currentSelectedField?.displayText}
              </label>
              <input
                id="searchTextBox"
                type={currentSelectedField?.type}
                placeholder={`${placeholderText} ${currentSelectedField?.displayText ?? ''}`}
                {...register('value')}
                data-testid={`${name}SearchInput`}
              />
            </div>
          </div>

          <div className={styles.searchButton}>
            <button
              disabled={searchValue == null || searchValue.length === 0}
              type="button"
              onClick={hasSearched ? handleReset : handleSubmit(handleSearch)}
              aria-label={hasSearched ? clearButtonAriaText : searchButtonAriaText}
              data-testid={hasSearched ? `${name}SearchResetButton` : `${name}SearchButton`}
            >
              <span className="material-icons align-self-center">
                {hasSearched ? 'clear' : 'search'}
              </span>
            </button>
          </div>
        </form>
      </div>
      {displaySearchResultMessage && hasSearched && count != null && count > 0 ? (
        <div className={styles.noSearchResultsMessage}>
          <Text field={{ value: `${count}  ${i18n.t('Globals-Table-Search-To-Display')}` }} />
        </div>
      ) : (
        ''
      )}
      {displaySearchResultMessage && count === 0 && (
        <div className={styles.noSearchResultsMessage}>
          <Text field={{ value: i18n.t('Globals-Table-No-Search-To-Display') }} />
        </div>
      )}
    </>
  );
};
