import * as React from 'react';
import { PropsWithChildren } from 'react';
import { DataTableRowProps } from 'Components/Common/DataTable/DataTableRow/types';
import styles from './styles.module.scss';

export const DataTableRow: React.FC<DataTableRowProps> = ({
  name,
  children,
  onClick,
  subtotal,
  testId
}: PropsWithChildren<DataTableRowProps>) => (
  <tr
    className={`${styles.tableRow}${subtotal ? ` ${styles.subtotal}` : ''}`}
    data-testid={testId != null && testId.length > 0 ? testId : `${name}Row`}
    data-rowid={`${name}`}
    onClick={(e: React.MouseEvent) => {
      if (onClick) {
        e.preventDefault();
        onClick(e as React.MouseEvent<HTMLButtonElement>);
      }
    }}
    onKeyDown={(e: React.KeyboardEvent) => {
      if (onClick && e.key === 'Enter') {
        e.preventDefault();
        onClick(e as React.BaseSyntheticEvent as React.MouseEvent<HTMLButtonElement>);
      }
    }}
    tabIndex={0}
  >
    {children}
  </tr>
);
