import React, { useState, useRef, useCallback, useMemo, memo } from 'react';
import {
  Box,
  MenuItem,
  ClickAwayListener,
  Popper,
  Paper,
  MenuList,
  Typography
} from '@mui/material';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import {
  NumberOfItemsOnPageOption,
  optionsOfItemsOnPage,
  PaginationItemsOnPageProps,
  PopperModifiers
} from 'Components/Core/Pagination/types';
import useEnterOrSpaceKey from 'Components/Hooks/UseEnterOrSpaceKey';
import i18n from 'i18next';
import styles from './styles.module.scss';

const PaginationItemsOnPage: React.FC<PaginationItemsOnPageProps> = ({
  numberOfItemsOnPage,

  totalItemNumber: totalItemsNumber,
  currentPageNumber,
  onItemsPerPageChange
}) => {
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleItemsPerPageChange = useCallback(
    (itemsPerPage: number) => {
      onItemsPerPageChange(itemsPerPage < 10 ? 10 : itemsPerPage);
      setOpen(false);
    },
    [onItemsPerPageChange]
  );

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClickAway = useCallback(() => {
    setOpen(false);
  }, []);

  const handleMouseDown = useCallback(() => {
    setIsActive(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsActive(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsActive(false);
  }, []);

  const pickerStyles = useMemo(
    () => `${styles.paginationDropdownValueContainer} ${isActive ? styles.active : ''}`,
    [isActive]
  );

  const popperModifiers: PopperModifiers = useMemo(
    () => [
      {
        name: 'offset',
        options: {
          offset: [0, 5]
        }
      }
    ],
    []
  );

  const startNumber = useMemo(
    () => (currentPageNumber - 1) * numberOfItemsOnPage + 1,
    [currentPageNumber, numberOfItemsOnPage]
  );
  const endNumber = useMemo(
    () => Math.min(currentPageNumber * numberOfItemsOnPage, totalItemsNumber),
    [currentPageNumber, numberOfItemsOnPage, totalItemsNumber]
  );

  const numberRangeOfItems = useMemo(
    () =>
      i18n.t('Pagination-Item-Range', { startNumber, endNumber, totalItemsNumber }) ??
      `${startNumber} to ${endNumber} of ${totalItemsNumber} items`,
    [startNumber, endNumber, totalItemsNumber]
  );

  return (
    <Box className={styles.paginationItemsOnPageWrapper}>
      <Box className={styles.paginationTextContainer}>
        <span data-testid="pagination-text">{numberRangeOfItems}</span>
        <span data-testid="pagination-text">{i18n.t('Pagination-Show') ?? 'Show'}</span>
      </Box>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <Box
            className={pickerStyles}
            ref={anchorRef}
            data-testid="pagination-select"
            tabIndex={0}
            onClick={handleToggle}
            onKeyDown={useEnterOrSpaceKey(handleToggle)}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onBlur={handleBlur}
          >
            <Typography variant="body1">{numberOfItemsOnPage}</Typography>
            <HbtIcon
              size={HbtIconSize.MEDIUM}
              type={open ? 'icon_chevron_up' : 'icon_chevron_down'}
            />
          </Box>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement="bottom"
            className={styles.paginationDropdownMenu}
            modifiers={popperModifiers}
          >
            <Paper>
              <MenuList className={styles.paginationDropdownList}>
                {optionsOfItemsOnPage.map((value: NumberOfItemsOnPageOption) => (
                  <MenuItem
                    className={styles.paginationDropdownItem}
                    key={value}
                    tabIndex={0}
                    onClick={() => handleItemsPerPageChange(value)}
                  >
                    {value}
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>
    </Box>
  );
};

export default memo(PaginationItemsOnPage);
