import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ButtonType } from 'Feature/CommonComponents/UserControls';

import FormCard from 'Components/Common/FormCard';
import FormDatepicker from 'Components/Inputs/FormDatepicker';
import FormTextArea from 'Components/Inputs/FormTextArea';
import FormYesNoRadios from 'Components/Inputs/FormYesNoRadios';
import ClaimsDefaultManagementProps from 'Feature/Claims/models/ClaimsDefaultManagement';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

import { GlossaryNames } from 'Feature/PageComponents/components/GlossaryComponent/types';
import styles from './styles.module.scss';

const ClaimsDefaultManagement = (props: ClaimsDefaultManagementProps) => {
  const { watch } = useFormContext();
  const { isMasterUserEditingPostAdjudicationClaim, isFieldDisabled } = useHBTFormContext();
  const managementTool = watch('defaultManagement.toolAppliedFlag');

  return (
    <FormCard
      title={props.fields.cardTitle}
      headingLevel={2}
      sectionId="claims-default-management-body"
      fieldToValidate="defaultManagement"
      toolTipButton={{
        id: 'defaultGlossaryTooltip',
        name: 'defaultGlossaryTooltip',
        ariaText: props.fields.cardGlossaryAriaText?.value ?? '',
        onClick: () => {
          props?.fields?.openGlossary?.(GlossaryNames.DefaultManagement);
        },
        buttonType: ButtonType.TEXT
      }}
    >
      <FormYesNoRadios
        testid="defaultManagement.toolApplied"
        className={styles.full}
        label={props.fields.managementTool}
        name="defaultManagement.toolAppliedFlag"
        isReadOnly={isFieldDisabled}
      />
      <FormDatepicker
        className={styles.halfLeft}
        label={props.fields.defaultSubmissionDate}
        name="defaultManagement.approvedDate"
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : { when: managementTool, is: ['true'], className: 'd-none' }
        }
        isReadOnly={isFieldDisabled}
      />
      <FormTextArea
        charLimit={parseInt(props.fields.limitNumber.value ?? '', 10)}
        className={styles.full}
        label={props.fields.detailsDefaultManagement}
        name="defaultManagement.toolDescription"
        textAreaHelperText={props.fields.characterLimit?.value?.replace('{limit}', '')}
        watch={
          isMasterUserEditingPostAdjudicationClaim === true
            ? undefined
            : { when: managementTool, is: ['true'], className: 'd-none' }
        }
        isReadOnly={isFieldDisabled}
      />
    </FormCard>
  );
};

export default ClaimsDefaultManagement;
