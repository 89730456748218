import React, { RefObject } from 'react';
import { PopperOwnProps } from '@mui/base/Popper/Popper.types';
import { BoxProps } from '@mui/material';

export enum Language {
  EN = 'en',
  FR = 'fr'
}

type PopperModifiers = PopperOwnProps['modifiers'];

export interface LanguageSwitchProps {
  anchorRef: RefObject<HTMLDivElement>;
  open: boolean;
  isSkeletonLoaderRequired?: boolean;
  language: Language;
  popperModifiers?: PopperModifiers;
  isMediumUp: boolean;
  onToggle: (value: boolean) => void;
  onChange: (lang: Language) => void;
}

export interface HeaderActionLinkProps extends BoxProps {
  iconType: string;
  text: string;
  isMediumUp: boolean;
  onToggle: (e?: React.MouseEvent | React.KeyboardEvent) => void;
}

export interface HeaderLogoAndTextProps {
  headerHomeLink: GenericLinkItem;
  headerText: FieldValue;
  headerLogo: React.ReactNode;
  headerSubtitleText: FieldValue;
}

export interface HeaderProps {
  fields: HeaderPropsFields;
}

export interface HeaderPropsFields extends HeaderLogoAndTextProps {
  hasNewNotifications?: boolean;
  isOnLogInPage?: boolean;
  isNotificationEnabled?: boolean;
  menuText: FieldValue;
  notificationsText: FieldValue;
  onChosenLanguage: (lang: Language) => void;
  onClickMenu: () => void;
  onClickNotification: () => void;
}
