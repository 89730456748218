import React, { useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useFormContext } from 'react-hook-form';

import { PendingConditionCode } from '@hobt/claim-domain';

import FormTextArea from 'Components/Inputs/FormTextArea';
import { FormCheckbox } from 'Components/Inputs/FormCheckbox';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import FormCheckboxListWrapper from 'Components/Inputs/FormCheckboxListWrapper';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

import { PendingCodesPropsFields, PendingCodeType } from '../types';
import styles from './styles.module.scss';

const PendingCodes = (props: PendingCodesPropsFields) => {
  const { characterLimitText, heading, isReadOnlyView, otherSpecifyTitle, pendingCodesList } =
    props;
  const { register, setValue } = useFormContext();
  const { claimData } = useHBTFormContext();

  const [codeList, setCodeList] = useState<string[]>([]);
  const [otherCodeIndex, setOtherCodeIndex] = useState<number>(-1);
  const { pendingConditions } = claimData;

  let pendingConditionsList: PendingCodeType[] = [];

  register('pendingConditions');

  const checkBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const codeFlag = codeList.includes(e.target.value);

    if (e.target.checked && !codeFlag) {
      setCodeList([...codeList, e.target.value]);
    } else if (!e.target.checked && codeFlag) {
      setCodeList(codeList?.filter?.((code) => code !== e.target.value));
    }
  };

  const checkBooleanValue = (item: string): boolean => {
    if (codeList?.includes(item)) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    pendingConditions?.forEach((pendingCondition: PendingCodeType, index: number) => {
      codeList.push(pendingCondition.code);

      if (pendingCondition.code === PendingConditionCode.Other) {
        setOtherCodeIndex(index);
      }
    });
  }, []);

  useEffect(() => {
    if (otherCodeIndex !== -1 && pendingConditions?.[otherCodeIndex]?.otherDescription != null) {
      register(`pendingConditions.${otherCodeIndex}.otherDescription`);
      setValue(
        `pendingConditions.${otherCodeIndex}.otherDescription`,
        pendingConditions?.[otherCodeIndex]?.otherDescription
      );
    }
  }, [otherCodeIndex]);

  useEffect(() => {
    pendingConditionsList = codeList?.map((code: string, index: number) => {
      return code === PendingConditionCode.Other
        ? {
            code,
            otherDescription: pendingConditions?.[index]?.otherDescription
          }
        : { code };
    });

    setOtherCodeIndex(codeList?.findIndex((code: string) => code === PendingConditionCode.Other));

    setValue('pendingConditions', pendingConditionsList);
  }, [codeList]);

  return (
    <section className={styles.suspenseSections}>
      <h3 className={styles.heading}>
        <Text field={heading} />
      </h3>
      <FormCheckboxListWrapper
        name="pendingCodeList"
        label={{ value: '' }}
        className={styles.formCheckboxList}
      >
        <div className={styles.codesCheckBoxListWrapper}>
          {pendingCodesList?.fields?.listItems?.map((item: SitecoreListItem) => (
            <div key={`pendingCodeList${item?.fields?.itemValue?.value}`}>
              <FormCheckbox
                label={{ value: item?.fields?.itemName?.value ?? '' }}
                className=""
                name={''}
                dontRegister={true}
                value={item?.fields?.itemValue?.value}
                checked={checkBooleanValue(item?.fields?.itemValue?.value ?? '')}
                onChange={checkBoxChange}
              />
            </div>
          ))}
        </div>
      </FormCheckboxListWrapper>
      <div className="row">
        <div className="col-9">
          {otherCodeIndex !== -1 && (
            <FormTextArea
              name={`pendingConditions.${otherCodeIndex}.otherDescription`}
              label={otherSpecifyTitle}
              textAreaHelperText={characterLimitText.value}
              charLimit={200}
              className={styles.full}
              isReadOnly={isReadOnlyView}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default PendingCodes;
