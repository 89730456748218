import { CSSProperties } from '@mui/material/styles/createTypography';
import { FC, createElement } from 'react';

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

type Props = {
  headingLevel: HeadingLevel;
  className?: string;
  style?: CSSProperties;
  ariaHidden?: string;
  children?: React.ReactNode;
};

const DynamicHeading: FC<Props> = ({ headingLevel, className, style, children, ariaHidden }) =>
  createElement(`h${headingLevel}`, { className, style, children, 'aria-hidden': ariaHidden });

export default DynamicHeading;
