import React, { memo, useCallback } from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import useEnterOrSpaceKey from 'Components/Hooks/UseEnterOrSpaceKey';
import { useAuthenticationContext } from 'Foundation/Authentication';
import i18n from 'i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import { HbtDashboardSideNavigationProps, SideMenuItemProps } from './types';

const SideNavMenuItemComponent: React.FC<SideMenuItemProps> = React.memo(({ fields }) => {
  const location = useLocation();
  const navItemHref = fields.navItem?.value?.href;
  const isActive = location.pathname.indexOf(navItemHref) > -1;

  return (
    <li className={styles.sideNavMenuItem} role="menuitem">
      <RouterLink
        to={`/${i18n.language}${fields.navItem.value.href}`}
        className={`${styles.menuLink} ${isActive ? styles.activeLink : ''}`}
      >
        <div className={`${styles.moduleContainer}`}>
          <div className={styles.iconTextWrapper}>
            <Image field={fields.icon} alt={fields.text.value || 'Icon'} />
            <span className={styles.label}>{fields.text.value}</span>
          </div>
        </div>
      </RouterLink>
    </li>
  );
});

const HbtDashboardSideNavigation = (props: HbtDashboardSideNavigationProps) => {
  const authenticationContext = useAuthenticationContext();

  const handleLogout = useCallback(() => {
    authenticationContext.signOut();
  }, [authenticationContext]);

  return (
    <div className={styles.mainSideNavigation}>
      <div className={styles.sideNavContainer}>
        <span className={styles.sideNavContainerHeader}>{props.fields.title.value}</span>
        <ul className={styles.sideNavMenu} role="menu">
          {props.fields.sideMenuItems.map((item, index) => (
            <SideNavMenuItemComponent key={index} {...item} />
          ))}
        </ul>
      </div>
      <div className={styles.sideNavContainer}>
        <span className={styles.sideNavContainerHeader}>{props.fields.accountTitle.value}</span>
        <div className={styles.accountContainer}>
          <ul className={styles.sideNavMenu} role="menu">
            {props.fields.accountHelp && <SideNavMenuItemComponent {...props.fields.accountHelp} />}
            <li className={styles.sideNavMenuItem} role="menuitem">
              <a
                tabIndex={0}
                data-testid="logOutLink"
                className={`${styles.menuLink} ${styles.sideNavLogoutLink}`}
                onClick={handleLogout}
                onKeyDown={useEnterOrSpaceKey(handleLogout)}
              >
                <div className={styles.moduleContainer}>
                  <div className={styles.iconTextWrapper}>
                    <Image
                      field={props.fields.logoutIcon}
                      alt={props.fields.logoutLabel.value || 'Log Out'}
                    />
                    <span className={styles.label}>{props.fields.logoutLabel.value}</span>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default memo(HbtDashboardSideNavigation);
