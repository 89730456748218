import React, { FC, useEffect, useState } from 'react';
import i18n from 'i18next';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

import { ModuleMapping, UserRole } from '@hobt/constants';

import {
  deleteLetter,
  refundLetterDetails,
  sendLetter,
  useRefundLetter
} from 'Feature/Claims/components/Details/RefundManagement/RefundRequestLetter/RefundLetterContext';
import { LoaderAnimation } from 'Feature/CommonComponents/UserControls';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';

import { ContextualButton } from 'Components/Common/Button';
import FormToggle from 'Components/Inputs/FormToggle';
import { ActionButtonCell } from 'Components/Common/DataTable/DataTableRow/ActionButtonCell';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { MenuItemProps } from 'Components/Common/ActionMenu/types';
import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';

import FinalizedRefundProps, { FinalizedNetRefundBalance } from './types';

import styles from './styles.module.scss';

function parseFieldValues<T extends Record<K, FieldValue>, K extends keyof T>(
  obj: T
): Record<K, string | undefined> {
  return Object.fromEntries<string | undefined>(
    Object.entries<FieldValue>(obj)?.map(([key, { value }]) => [key, value])
  ) as Record<K, string | undefined>;
}

const FinalizedRefund: FC<FinalizedRefundProps> = ({ fields, refundLetterSentToastMessage }) => {
  const {
    heading,
    finalizedRefundMessage,
    refundReason,
    dateFinalized,
    refundOwed,
    letterGenerated,
    language,
    sentToLender,
    deleteButton
  } = parseFieldValues(fields);
  const { state, dispatch } = useRefundLetter();
  const authenticationContext = useAuthenticationContext();
  const { uuid, isMasterUser, updateClaimData, setLoadingStatus, isPageLoading } =
    useHBTFormContext();
  const [refundSentToLenderIndex, setRefundSentToLenderIndex] = useState<number | undefined>(
    undefined
  );
  const { downloadLetter } = refundLetterDetails(setLoadingStatus);
  const { toCurrency } = useTranslationHelpers();

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const isClerkOrBusinessUser = isUserInRoles(
    ModuleMapping.claim,
    [UserRole.BusinessUser, UserRole.Clerk],
    moduleRoleMapping
  );

  useEffect(() => {
    const index = state.finalizedRefunds.findIndex(
      (row: FinalizedNetRefundBalance) => row.sentToLender === true
    );
    if (index > -1) {
      setRefundSentToLenderIndex(index);
    } else {
      setRefundSentToLenderIndex(undefined);
    }
  }, [state.finalizedRefunds]);

  if (state.finalizedRefunds.length === 0) {
    return null;
  }

  const deleteItem = async (requestLetterID: string | number) => {
    await deleteLetter(uuid, authenticationContext, requestLetterID);
  };

  const internalRowActionMenu: MenuItemProps[] = [
    {
      name: 'delete',
      icon: 'delete',
      displayText: fields.deleteButton,
      onClickCallback: deleteItem,
      actionItemId: 0
    }
  ];

  const isToggleDisabled = (row: FinalizedNetRefundBalance) => {
    if (!isMasterUser && !isClerkOrBusinessUser) {
      return true;
    }

    if (refundSentToLenderIndex == null) {
      return false;
    }

    if (isMasterUser === true && row.sentToLender) {
      return false;
    }

    return true;
  };
  if (isPageLoading) {
    return <LoaderAnimation />;
  }

  const onSuccessCallback = () => {
    dispatch({
      type: 'toast',
      value: {
        isActive: true,
        type: ApplicationStates.SUCCESS,
        title: i18n.t('Globals-Toast-Success-Title'),
        content: refundLetterSentToastMessage?.value ?? ''
      }
    });
  };

  // eslint-disable-next-line no-console
  console.log('Toast Message Changes');

  return (
    <>
      <ToastNotification
        {...state.toast}
        onCloseCallback={() => dispatch({ type: 'toast', value: { isActive: false } })}
      />
      <hr />
      <div className={styles.header}>
        <h3>{heading}</h3>
        <p>{finalizedRefundMessage}</p>
      </div>

      <div className={styles.card}>
        <div className={styles.balances}>
          <span className={styles['spacefill-sr-only']}>More Actions</span>
          <span className={styles.thText}>{refundReason}</span>
          <span className={styles.thText}>{dateFinalized}</span>
          <span className={styles.thText}>{refundOwed}</span>
          <span className={styles.thText}>{letterGenerated}</span>
          <span className={styles.thText}>{language}</span>
          <span className={styles.thText}>{sentToLender}</span>
        </div>
        {state.finalizedRefunds.map((row: FinalizedNetRefundBalance, index: number) => (
          <div key={index} className={styles.balances}>
            <ContextualButton
              className={styles.moreActions}
              data-testid={`moreActions_${index}`}
              title={deleteButton}
              disabled={row.sentToLender}
              tabIndex={-1}
            >
              {<span data-testid={row?.documentId}></span>}
              <fieldset disabled={row.sentToLender}>
                <ActionButtonCell
                  name={'rowActionButton'}
                  rowId={row?.documentId ?? ''}
                  rowActionItems={internalRowActionMenu?.map((i) => ({
                    ...i,
                    actionItemId: row.requestLetterID ?? ''
                  }))}
                />
              </fieldset>
            </ContextualButton>
            <span className={styles.tdText}>{row.refundReason}</span>
            <span className={styles.tdText}>{row.dateFinalized}</span>
            <span className={styles.tdText}>{toCurrency(row.refundOwed)}</span>
            <span
              tabIndex={0}
              role="button"
              className={`${styles.tdText} ${styles.letterDownloadLink}`}
              onClick={() =>
                downloadLetter(uuid, authenticationContext, row.documentId ?? null, index)
              }
              onKeyPress={({ key }) => {
                if ([' ', 'Enter'].includes(key)) {
                  downloadLetter(uuid, authenticationContext, row.documentId ?? null, index);
                }
              }}
            >
              {row.documentId != null
                ? row.letterGenerated?.split('?')[0]
                : i18n.t('Refund-Letter-Not-Generated')}
            </span>
            <span className={styles.tdText}>{row.language}</span>
            <span className={`${styles.tdText}`}>
              <FormToggle
                isChecked={row.sentToLender}
                onChange={() =>
                  sendLetter(
                    uuid,
                    authenticationContext,
                    index,
                    !row.sentToLender,
                    onSuccessCallback
                  )
                }
                id={`sentToLender_${index}`}
                className={styles.letterSent}
                testId={`sentToLender_${index}`}
                dynamicText={[i18n.t('Refund-Letter-Sent'), i18n.t('Refund-Letter-Not-Sent')]}
                isDisabled={isToggleDisabled(row)}
              />
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default FinalizedRefund;
