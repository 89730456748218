import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { HttpResponseStatusCodes, LanguageShort, Module, UserRole } from '@hobt/constants';
import { Status as UploadStatusCode } from '@hobt/portfolio-domain';
import { FormControl } from '@mui/material';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Attachment from 'Components/Core/Attachment';
import { Button } from 'Components/Core/Button';
import { ButtonVariant } from 'Components/Core/Button/types';
import { Combobox } from 'Components/Core/Combobox';
import { ComboboxOption } from 'Components/Core/Combobox/types';
import { LargeFormField } from 'Components/Core/LargeFormField';
import { LargeFormFieldStatus } from 'Components/Core/LargeFormField/types';
import { Modal } from 'Components/Core/Modal';
import { MouseOrKeyboardEvent } from 'Components/Core/Modal/types';
import { ToastNotification } from 'Components/Core/Notifications/ToastNotification';
import { ToastNotificationVariant } from 'Components/Core/Notifications/ToastNotification/types';
import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { Location } from 'history';
import i18n from 'i18next';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { config } from '../../../../../config';
import { useHbtPIFileTransferPageContext } from '../HbtPIFileTransferPage/HbtPIFileTransferPageContext';
import { NotificationBannerType } from '../HbtPIFileTransferPage/types';
import { getLenders } from './FileTransferUploadTabService';
import styles from './styles.module.scss';
import {
  AllowedFileTypes,
  AttachmentFile,
  DocumentEntity,
  FileShareType,
  HbtPIFileTransferUploadTabViewProps,
  PortfolioToolType
} from './types';

const apiClientConfig: ApiClientConfig = { timeout: config.defaultApi.requestTimeout };

const maxSize = 150000000;
const maxFilesCount = 10;

const uploadFilesUrl: string = config.uploadFilesApi.urls.uploadFiles;
const uploadFilesTimeout: number = config.uploadFilesApi.requestTimeout;
const submitRequestUrl: string = config.fileTransferApi.urls.submitFileTransferRequest;
const submitRequestTimeout: number = config.fileTransferApi.requestTimeout;

const HbtPIFileTransferUploadTabView = ({ fields }: HbtPIFileTransferUploadTabViewProps) => {
  const authenticationContext = useAuthenticationContext();

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [isRemoveAllFilesModalActive, setIsRemoveAllFilesModalActive] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);
  const [nextPath, setNextPath] = useState<Location | null>(null);

  const [attachments, setAttachments] = useState<AttachmentFile[]>([]);
  const [completedAttachments, setCompletedAttachments] = useState<DocumentEntity[]>([]);
  const [filesRemovedIndexes, setFilesRemovedIndexes] = useState<number[]>([]);
  const [isUploadCompleted, setIsUploadCompleted] = useState(false);

  const [lenderOptionData, setLenderOptionData] = useState<ComboboxOption[]>([]);
  const [hasMoreApprovedLenderCodes, setHasMoreApprovedLenderCodes] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const RemoveAllBtnText = i18n.t('PI-File-Transfer-Upload-Tab-RemoveAllFilesButton', 'Remove all');
  const SubmitBtnText = i18n.t('PI-File-Transfer-Upload-Tab-Form-SubmitButton', 'Submit');
  const RemoveAllFileModalBtnText = i18n.t(
    'PI-File-Transfer-Upload-Tab-RemoveAllFiles-Confirmation-Modal-RemoveAllFilesButton',
    'Remove all files'
  );
  const CancelBtnText = i18n.t('PI-File-Transfer-Upload-Tab-Modal-CancelButton', 'Cancel');
  const leaveBtnText = i18n.t('PI-File-Transfer-Upload-Tab-Unsubmitted-Modal-LeaveButton', 'Leave');
  const fileSizeErrorMessage = i18n.t(
    'PI-File-Transfer-Upload-Tab-File-Upload-Max-FileSize-Exceeds-Error',
    'The file exceeds the maximum size of 150 MB.'
  );
  const multipleFileSizeErrorMessage = i18n.t(
    'PI-File-Transfer-Upload-Tab-MultiFile-Upload-Max-FileSize-Exceeds-Error',
    'The files exceed the maximum total size of 150 MB.'
  );
  const fileCountMaxError = i18n.t(
    'PI-File-Transfer-Upload-Tab-File-Upload-Max-FileCount-Error',
    'You have exceeded the number of files you can upload at one time.'
  );
  const fileTypeErrorMessage = i18n.t(
    'PI-File-Transfer-Upload-Tab-File-Upload-Incorrect-FileType-Error',
    'The file type you are trying to upload is not supported.'
  );
  const requiredErrorMessage = i18n.t(
    'PI-File-Transfer-Upload-Tab-Attachments-Field-Required-Error',
    'Please attach a file before attempting to submit.'
  );
  const requiredDropDownErrorMessage = i18n.t(
    'PI-File-Transfer-Upload-Tab-LenderName-Dropdown-Required-Error',
    'Please make a selection'
  );

  useEffect(() => {
    (async function () {
      await getLenderOptionsList();
    })();
  }, [i18n?.language]);

  const toggleRemoveAllFilesModal = useCallback(
    (event: MouseOrKeyboardEvent) => {
      event.preventDefault();
      setIsRemoveAllFilesModalActive(!isRemoveAllFilesModalActive);
      setIsModalActive(false);
    },
    [isRemoveAllFilesModalActive, setIsRemoveAllFilesModalActive, isModalActive, setIsModalActive]
  );

  const removeAllFiles = useCallback(
    (event: MouseOrKeyboardEvent) => {
      event.preventDefault();
      setCompletedAttachments([]);
      setIsRemoveAllFilesModalActive(!isRemoveAllFilesModalActive);
      setIsModalActive(false);
    },
    [isRemoveAllFilesModalActive, setIsRemoveAllFilesModalActive, isModalActive, setIsModalActive]
  );

  const { fileTransferPageContextData } = useHbtPIFileTransferPageContext();
  const {
    setShowNotificationBanner,
    setNotificationBannerInfo,
    hasUnSavedChanges,
    setHasUnSavedChanges
  } = fileTransferPageContextData;

  const isUserPortfolioAnalyst =
    (sitecoreContext?.user?.moduleRoleMapping[Module.PiFileShare][0] as UserRole) ===
    UserRole.PortfolioAnalyst;

  useEffect(() => {
    if (sitecoreContext.user.lenderCodes) {
      const haveMoreLenderCodes =
        sitecoreContext.user.lenderCodes.length > 1 &&
        sitecoreContext?.user?.moduleRoleMapping[Module.PiFileShare][0] === UserRole.ApprovedLender;
      setHasMoreApprovedLenderCodes(haveMoreLenderCodes);
    }
  }, []);

  const getLenderOptionsList = async () => {
    try {
      let lenderOptions: ComboboxOption[] = [];

      let data = await getLenders(authenticationContext);
      if (data && data?.lenders && Array.isArray(data?.lenders)) {
        data?.lenders.forEach((item: any) => {
          lenderOptions.push({
            value: item?.lenderCode,
            label:
              i18n.language === LanguageShort.English
                ? item?.approvedLenderEnglishName
                : item?.approvedLenderFrenchName,
            isGroup: false,
            groupName: ''
          });
        });
      }
      setLenderOptionData(lenderOptions);
    } catch (err) {
      console.error('Error when fetching lender options', err);
    }
  };

  const formHookMethods = useForm();

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    if (attachments.length === 0) {
      setFilesRemovedIndexes([]);
      setIsUploadCompleted(false);
    }

    if (
      attachments.length <= maxFilesCount &&
      attachments.some(
        (file) =>
          file.uploadStatusCode === UploadStatusCode.UploadFailed && file.errorMessage !== ''
      )
    ) {
      const updatedAttachments = attachments.map((file) => ({
        ...file,
        uploadStatusCode: UploadStatusCode.Cancelled,
        errorMessage: ''
      }));

      setAttachments(updatedAttachments);

      (async () => {
        await uploadFilesToStorage(updatedAttachments);
      })();
    }
  }, [attachments.length, completedAttachments.length]);

  const removeFile = async (index: number) => {
    const arr = completedAttachments.filter(function (item, ind) {
      return ind !== index;
    });
    setCompletedAttachments([...arr]);
  };

  const stopUploading = async (index: number, uploadStatusCode: UploadStatusCode) => {
    if (uploadStatusCode === UploadStatusCode.UploadFailed && isUploadCompleted) {
      const arr = attachments.filter(function (item, ind) {
        return ind !== index;
      });
      setAttachments([...arr]);
    } else {
      let arr = filesRemovedIndexes;
      arr.push(index);
      setFilesRemovedIndexes(arr);
    }
  };

  const uploadFilesToStorage = async (files: AttachmentFile[]) => {
    for (let i = 0; i < files.length; i++) {
      const duplicateFiles = completedAttachments.filter(
        (attachment: DocumentEntity) => attachment.fileName === files[i].attachment.name
      );
      // check if there are duplicate files
      if (duplicateFiles.length > 0) {
        setToastVisible(true);
        return;
      }
    }

    let tempFiles = files;
    setAttachments([]);
    setIsUploadCompleted(false);

    const attachmentsSum = tempFiles.reduce((n, { fileSize }) => n + fileSize, 0);
    const completedAttachmentsSum = completedAttachments.reduce(
      (n, { fileSize }) => n + fileSize,
      0
    );
    const filesSizeSum = attachmentsSum + completedAttachmentsSum;
    const filesCount = tempFiles.length + completedAttachments.length;

    if (filesSizeSum > maxSize) {
      for (let i = 0; i < tempFiles.length; i++) {
        if (tempFiles.length >= 1) {
          tempFiles[i].uploadStatusCode = UploadStatusCode.UploadFailed;
          tempFiles[i].errorMessage =
            tempFiles.length + completedAttachments.length > 1
              ? multipleFileSizeErrorMessage
              : fileSizeErrorMessage;
        }
      }
      setAttachments([...tempFiles]);
      setIsUploadCompleted(true);

      return;
    }

    if (filesCount > maxFilesCount) {
      for (let i = 0; i < tempFiles.length; i++) {
        if (tempFiles.length >= 1) {
          tempFiles[i].uploadStatusCode = UploadStatusCode.UploadFailed;
          tempFiles[i].errorMessage = fileCountMaxError;
        }
      }
      setAttachments([...tempFiles]);
      setIsUploadCompleted(true);
      return;
    }

    setAttachments([...tempFiles]);

    let isMockUploadDone = false;

    for (let j = 0; j < 3; j++) {
      if (filesRemovedIndexes.length === tempFiles.length) {
        setAttachments([]);
        break;
      }

      for (let i = 0; i < tempFiles.length; i++) {
        if (
          AllowedFileTypes !== undefined &&
          AllowedFileTypes.length > 0 &&
          !AllowedFileTypes.includes(
            tempFiles[i].attachment.name
              .slice((Math.max(0, tempFiles[i].attachment.name.lastIndexOf('.')) || Infinity) + 1)
              .toLowerCase()
          )
        ) {
          tempFiles[i].uploadStatusCode = UploadStatusCode.UploadFailed;
          tempFiles[i].errorMessage = fileTypeErrorMessage;
        } else if (
          tempFiles.length >= 1 &&
          tempFiles[i].uploadStatusCode !== UploadStatusCode.UploadFailed
        ) {
          tempFiles[i].percentage += 30;
          tempFiles[i].timeLeft -= 3;
        }
      }
      // eslint-disable-next-line no-await-in-loop
      await delay(3000);
      setAttachments([...tempFiles]);

      if (j === 2) {
        isMockUploadDone = true;
      }
    }

    if (isMockUploadDone) {
      const formData = new FormData();
      tempFiles = tempFiles.filter(
        (attachment: AttachmentFile, index: number) => !filesRemovedIndexes.includes(index)
      );

      let attachmentsError: AttachmentFile[] = [];
      tempFiles.forEach((attachmentWrapper: AttachmentFile) => {
        if (attachmentWrapper.uploadStatusCode !== UploadStatusCode.UploadFailed) {
          formData.append('uploadedFileObject', attachmentWrapper.attachment!);
        } else {
          attachmentsError.push(attachmentWrapper);
        }
      });
      const formValues = formHookMethods.getValues();

      formData.append('portfolioToolType', PortfolioToolType.PiFileShare.toString());
      formData.append(
        'description',
        formValues.fileDetailCommentField ? formValues.fileDetailCommentField : ''
      );

      if (formData.has('uploadedFileObject')) {
        ApiClient(authenticationContext, apiClientConfig)
          .postWithHeaderAuth(uploadFilesUrl, formData, false, { timeout: uploadFilesTimeout })
          .then((response: any) => {
            if (response.data.statusCode === HttpResponseStatusCodes.OK) {
              setIsUploadCompleted(true);
              setCompletedAttachments([...completedAttachments, ...response.data.data]);
            }
            if (attachmentsError.length > 0) {
              setAttachments(attachmentsError);
              setIsUploadCompleted(true);
            } else {
              setAttachments([]);
            }
          })
          .catch((error: any) => {
            const errorObject = error.response.data.error;
            setAttachments([]);
            setShowNotificationBanner(true);
            setFilesRemovedIndexes([]);
            if (errorObject && errorObject.errorCode === 'HBT_ERR_6003') {
              return setNotificationBannerInfo({
                notificationBannerHeader: i18n.t(
                  'PI-File-Transfer-Upload-Tab-File-Upload-Incorrect-FileType-Error'
                ),
                notificationBannerBody: '',
                notificationBannerType: NotificationBannerType.ERROR
              });
            }
            return setNotificationBannerInfo({
              notificationBannerHeader: i18n.t('Attachment-Files-Upload-Unavailable'),
              notificationBannerBody: '',
              notificationBannerType: NotificationBannerType.ERROR
            });
          });
      } else if (attachmentsError.length > 0) {
        setAttachments(attachmentsError);
        setIsUploadCompleted(true);
      } else {
        setAttachments([]);
      }
    }
  };

  const onSubmit = async (data: any) => {
    if (completedAttachments.length > 0 && attachments.length === 0) {
      const formValues = formHookMethods.getValues();
      let attachmentIds: string[] = [];
      completedAttachments.forEach((doc) => attachmentIds.push(doc.documentId));
      if (lenderOptionData.length === 0) {
        setShowNotificationBanner(true);
        return setNotificationBannerInfo({
          notificationBannerHeader: i18n.t(
            'PI-File-Transfer-Upload-Tab-File-Submission-Error-NotificationBanner-Title'
          ),
          notificationBannerBody: i18n.t(
            'PI-File-Transfer-Upload-Tab-Lender-Code-Missing-Error-NotificationBanner-Body'
          ),
          notificationBannerType: NotificationBannerType.ERROR
        });
      }
      const data = {
        description: formValues.fileDetailCommentField,
        approvedLenderCode:
          lenderOptionData?.length === 1
            ? lenderOptionData?.[0]?.value
            : formValues.lenderNameDropdown,
        fileShareType: isUserPortfolioAnalyst
          ? FileShareType.FileSharedWithPortfolioAnalyst
          : FileShareType.FileSharedWithApprovedLender,
        attachmentIds
      };

      setIsSubmitting(true);
      ApiClient(authenticationContext, apiClientConfig)
        .postWithAuth(submitRequestUrl, data, { timeout: submitRequestTimeout })
        .then((response: any) => {
          setNotificationBannerInfo({
            notificationBannerHeader:
              attachmentIds.length === 1
                ? i18n.t(
                    'PI-File-Transfer-Upload-Tab-File-Submitted-Success-NotificationBanner-Title'
                  )
                : i18n.t(
                    'PI-File-Transfer-Upload-Tab-MultiFile-Submitted-Success-NotificationBanner-Title'
                  ),
            notificationBannerBody: isUserPortfolioAnalyst
              ? ''
              : i18n.t(
                  'PI-File-Transfer-Upload-Tab-File-Submitted-Success-NotificationBanner-Body'
                ),
            notificationBannerType: NotificationBannerType.SUCCESS
          });
          setShowNotificationBanner(true);
          setIsSubmitted(true);
          setIsSubmitting(false);
          setCompletedAttachments([]);
          formHookMethods.reset();
          setHasUnSavedChanges(false);
        })
        .catch((error: any) => {
          setIsSubmitting(false);
          setShowNotificationBanner(true);
          setNotificationBannerInfo({
            notificationBannerHeader: i18n.t(
              'PI-File-Transfer-Upload-Tab-File-Submission-Error-NotificationBanner-Title'
            ),
            notificationBannerBody: i18n.t(
              'PI-File-Transfer-Upload-Tab-File-Submission-Error-NotificationBanner-Body'
            ),
            notificationBannerType: NotificationBannerType.ERROR
          });
        });
    }
  };
  const history = useHistory();
  const unblockRef = useRef<Function | null>(null);

  useEffect(() => {
    const unblock = history.block((nextLocation: Location) => {
      if (hasUnSavedChanges) {
        setIsModalActive(true);
        setNextPath(nextLocation);
        return false;
      }
      return true;
    });

    unblockRef.current = unblock;

    return () => {
      unblock();
    };
  }, [hasUnSavedChanges, history]);

  const confirmNavigation = useCallback(
    (event: MouseOrKeyboardEvent) => {
      event.preventDefault();
      if (nextPath) {
        setCompletedAttachments([]);
        setIsModalActive(false);
        setHasUnSavedChanges(false);
        if (unblockRef.current) {
          unblockRef.current();
          unblockRef.current = null;
        }
        history.push(nextPath.pathname);
      }
    },
    [nextPath, history, setHasUnSavedChanges]
  );

  const cancelNavigation = useCallback((event: MouseOrKeyboardEvent) => {
    event.preventDefault();
    setIsModalActive(false);
    setNextPath(null);
  }, []);

  useEffect(() => {
    if (completedAttachments.length > 0 && !isSubmitted) {
      setHasUnSavedChanges(true);
    }
  }, [completedAttachments, isSubmitted]);

  const { dirtyFields } = formHookMethods.formState;

  return (
    <FormProvider {...formHookMethods}>
      <ToastNotification
        actionable
        icon="icon_check_circle"
        open={toastVisible}
        header={{
          value: i18n.t(
            'PI-File-Transfer-Upload-Tab-File-Submission-Duplicated-Header',
            'File Already Uploaded'
          )
        }}
        variant={ToastNotificationVariant.INFO}
        onClose={() => {
          setToastVisible(false);
        }}
        subHeader={{
          value: i18n.t(
            'PI-File-Transfer-Upload-Tab-File-Submission-Duplicated-Sub-Header',
            'Please remove the existing file or rename it, then try again'
          )
        }}
      />
      <form onSubmit={formHookMethods.handleSubmit(onSubmit)}>
        <div className={styles.fileTransferUploadViewMain}>
          {(isUserPortfolioAnalyst || hasMoreApprovedLenderCodes) && (
            <div className={styles.fileTransferLenderNameContainer}>
              <h4 className={styles.lenderNameStepTitle}>
                <Text
                  field={
                    {
                      value:
                        fields.fileTransferLenderNameStepTitle?.value ??
                        'Step 1: Select the Approved Lender Name'
                    } as FieldValue
                  }
                />
              </h4>
              <Controller
                control={formHookMethods.control}
                name="lenderNameDropdown"
                defaultValue=""
                rules={{
                  required: i18n.t(
                    'PI-File-Transfer-Upload-Tab-LenderName-Dropdown-Required-Error',
                    'Please make a selection'
                  )
                }}
                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Combobox
                      ariaText={{
                        value: `${i18n.t(
                          'PI-File-Transfer-Upload-Tab-LenderName-Dropdown-PlaceholderText',
                          'Select'
                        )} ${fields.fileTransferLenderNameStepTitle?.value}`
                      }}
                      value={formHookMethods.getValues('lenderNameDropdown')}
                      options={lenderOptionData}
                      placeholderText={i18n.t(
                        'PI-File-Transfer-Upload-Tab-LenderName-Dropdown-PlaceholderText',
                        'Select'
                      )}
                      error={
                        !!error ||
                        (Object.keys(dirtyFields).length > 0 &&
                          formHookMethods.getValues('lenderNameDropdown') === '')
                      }
                      errorMessage={error?.message ?? requiredDropDownErrorMessage}
                      onChange={(e: any | null, data) => {
                        if (data) {
                          field.onChange(data.value);
                        } else if (data == null) {
                          formHookMethods.setValue('lenderNameDropdown', '');
                        }
                      }}
                    />
                  </FormControl>
                )}
              />
            </div>
          )}
          <div className={styles.fileInstructionInputFieldContainer}>
            <h4 className={styles.fileInstructionStepTitle}>
              <Text
                field={
                  hasMoreApprovedLenderCodes
                    ? fields.fileInstructionInputFieldExternalLabel
                    : fields.fileInstructionInputFieldLabel
                }
              />
            </h4>
            <Controller
              control={formHookMethods.control}
              name="fileDetailCommentField"
              defaultValue=""
              rules={{
                maxLength: {
                  value: 1000,
                  message: i18n.t(
                    'PI-File-Transfer-Upload-Tab-Provide-Details-Field-MaxCharCount-Error',
                    'You’ve exceeded the maximum character count'
                  )
                }
              }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <FormControl error={!!error} fullWidth>
                  <LargeFormField
                    {...field}
                    id="file-instruction-input-field"
                    value={formHookMethods.getValues('fileDetailCommentField')}
                    ariaText={
                      fields.fileInstructionInputFieldLabel.value ??
                      'Step 1: Special Instructions (Optional)'
                    }
                    maxLength={1000}
                    status={error ? LargeFormFieldStatus.ERROR : undefined}
                    helperText={error?.message}
                  />
                </FormControl>
              )}
            />
          </div>
          <div className={styles.fileUploadContainer}>
            <Controller
              control={formHookMethods.control}
              name="file-transfer-attachments"
              defaultValue={[]}
              rules={{
                required: i18n.t(
                  'PI-File-Transfer-Upload-Tab-Attachments-Field-Required-Error',
                  'Please attach a file before attempting to submit.'
                )
              }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <FormControl error={!!error} fullWidth>
                  <Attachment
                    attachmentContent={{
                      attachmentsTitle: hasMoreApprovedLenderCodes
                        ? fields.fileTransferAttachmentsExternalTitle
                        : fields.fileTransferAttachmentsTitle,
                      attachmentsTitleInfo: fields.fileTransferAttachmentsTitleInfo,
                      supportedFileFormatText: fields.fileTransferAttachmentSupportedFileFormatText,
                      attachmentInputBrowserLabelText:
                        fields.fileTransferAttachmentInputBrowserLabelText,
                      attachmentInputBrowserLabel: fields.fileTransferAttachmentInputBrowserLabel,
                      attachmentRequiredErrorMessage: {
                        value: i18n.t(
                          'PI-File-Transfer-Upload-Tab-Attachments-Field-Required-Error',
                          'Please attach a file before attempting to submit.'
                        )
                      } as FieldValues,
                      noAttachmentTableLabel: fields.fileTransferNoAttachmentTableMessage
                    }}
                    isRequired={false}
                    fileUploadUnavailable={false}
                    disable={!!attachments.length}
                    uploadErrorMessage={
                      completedAttachments.length > 0
                        ? ''
                        : error?.message
                        ? requiredErrorMessage
                        : ''
                    }
                    attachments={attachments}
                    completedAttachments={completedAttachments}
                    filesRemovedIndexes={filesRemovedIndexes}
                    stopUploading={(index, uploadStatusCode) =>
                      stopUploading(index, uploadStatusCode)
                    }
                    deleteFile={(index) => removeFile(index)}
                    uploadFiles={(data) => uploadFilesToStorage(data)}
                  />
                </FormControl>
              )}
            />
            <Modal
              id="file-transfer-unsubmitted-files-confirmation-modal"
              isActive={isModalActive}
              title={
                {
                  value: i18n.t(
                    'PI-File-Transfer-Upload-Tab-Unsubmitted-Modal-Title',
                    'Unsubmitted Files'
                  )
                } as FieldValue
              }
              onDismissalCallback={cancelNavigation}
              bodyContent={
                <span>
                  <Text
                    field={
                      {
                        value: i18n.t(
                          'PI-File-Transfer-Upload-Tab-Unsubmitted-Modal-Body',
                          'You have files in the queue. If you navigate away, your files will be deleted. Do you want to leave without submitting your files?'
                        )
                      } as FieldValue
                    }
                  />
                </span>
              }
              modalButtons={{
                primaryButton: {
                  name: CancelBtnText,
                  text: { value: CancelBtnText } as FieldValue,
                  ariaText: { value: CancelBtnText } as FieldValue,
                  onClick: cancelNavigation,
                  onKeyDown: cancelNavigation
                },
                secondaryButton: {
                  name: leaveBtnText,
                  text: { value: leaveBtnText } as FieldValue,
                  ariaText: { value: leaveBtnText } as FieldValue,
                  onClick: confirmNavigation,
                  onKeyDown: confirmNavigation
                }
              }}
              shouldCloseOnBackgroundClick={true}
            />
          </div>
          <div className={styles.fileTransferActionBtnsContainer}>
            {completedAttachments.length > 0 && (
              <div className={styles.fileTransferRemoveFilesBtnContainer}>
                <Button
                  className={styles.removeAllFilesBtn}
                  variant={ButtonVariant.TERTIARY}
                  name={RemoveAllBtnText}
                  text={{ value: RemoveAllBtnText } as FieldValue}
                  ariaText={{ value: RemoveAllBtnText } as FieldValue}
                  onClick={toggleRemoveAllFilesModal}
                  onKeyDown={toggleRemoveAllFilesModal}
                />
                <Modal
                  id="remove-all-files-confirmation-modal"
                  isActive={isRemoveAllFilesModalActive}
                  title={
                    {
                      value: i18n.t(
                        'PI-File-Transfer-Upload-Tab-RemoveAllFiles-Confirmation-Modal-Title',
                        'Remove All Files Confirmation'
                      )
                    } as FieldValue
                  }
                  onDismissalCallback={toggleRemoveAllFilesModal}
                  bodyContent={
                    <span>
                      <Text
                        field={
                          {
                            value: i18n.t(
                              'PI-File-Transfer-Upload-Tab-RemoveAllFiles-Confirmation-Modal-Body',
                              'Do you want to remove all the files? This action cannot be undone.'
                            )
                          } as FieldValue
                        }
                      />
                    </span>
                  }
                  modalButtons={{
                    primaryButton: {
                      name: CancelBtnText,
                      text: { value: CancelBtnText } as FieldValue,
                      ariaText: { value: CancelBtnText } as FieldValue,
                      onClick: toggleRemoveAllFilesModal,
                      onKeyDown: toggleRemoveAllFilesModal
                    },
                    secondaryButton: {
                      name: RemoveAllFileModalBtnText,
                      text: { value: RemoveAllFileModalBtnText } as FieldValue,
                      ariaText: { value: RemoveAllFileModalBtnText } as FieldValue,
                      onClick: removeAllFiles,
                      onKeyDown: removeAllFiles
                    }
                  }}
                  shouldCloseOnBackgroundClick={true}
                />
              </div>
            )}
            <div className={styles.fileTransferUploadViewSubmitBtnContainer}>
              <Button
                type="submit"
                name={SubmitBtnText}
                text={{ value: SubmitBtnText } as FieldValue}
                ariaText={{ value: SubmitBtnText } as FieldValue}
                loading={isSubmitting}
                disabled={isSubmitting}
                variant={ButtonVariant.PRIMARY}
                onClick={onSubmit}
              />
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default memo(HbtPIFileTransferUploadTabView);
