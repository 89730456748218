import axios from 'axios';
import { useState, useEffect } from 'react';

import { config } from '../../../../config';

export const useNotificationApi = (authContext: any) => {
  const scopes = [config.api.hbtApiScope];
  const endpointUrl = config.notificationApi.urls.notification;
  const timeout = config.notificationApi.requestTimeout;
  const cancelSource = axios.CancelToken.source();

  const [errorCount, setErrorCount] = useState(0);
  const [authToken, setAuthToken] = useState();

  const getToken = async () => {
    try {
      return await authContext.getToken(scopes);
    } catch (e: any) {
      throw new Error(e);
    }
  };

  const api = axios.create({
    timeout
  });

  const getNotifications = async () => {
    let reqAuthtoken = authToken;

    if (reqAuthtoken === undefined) {
      reqAuthtoken = await getToken();
      setAuthToken(reqAuthtoken);
    }

    try {
      const { data: responseData } = await api({
        method: 'get',
        url: endpointUrl,
        cancelToken: cancelSource.token,
        headers: { Authorization: `Bearer ${reqAuthtoken}` }
      });

      if (errorCount > 0) {
        setErrorCount(0);
      }
      return responseData.data;
    } catch (err) {
      getToken()
        .then((token) => {
          setAuthToken(token);
        })
        .catch((_e) => {
          // Should log failure to get token
        })
        .finally(() => setErrorCount((prev) => prev + 1));

      throw err;
    }
  };

  const updateNotifications = async (notificationId?: string) => {
    let reqAuthtoken = authToken;

    if (reqAuthtoken === undefined) {
      reqAuthtoken = await getToken();
      setAuthToken(reqAuthtoken);
    }

    const notificationIdParam = notificationId != null ? `/${notificationId}` : '';
    api({
      method: 'put',
      url: `${endpointUrl}${notificationIdParam}`.trim(),
      headers: { Authorization: `Bearer ${reqAuthtoken}` }
    });
  };

  useEffect(() => {
    return () => {
      cancelSource.cancel();
    };
  }, []);

  return { errorCount, getNotifications, updateNotifications };
};
