import i18n from 'i18next';

import { LoanValidationErrorCode, LoanValidationResponse } from '@hobt/loan-domain';

const ERRORS_PREFIX = 'Errors-';

export function getLoanValidationError(error: any) {
  switch (error) {
    case LoanValidationResponse.InvalidMlgLoan:
      return i18n.t(`${ERRORS_PREFIX}${LoanValidationErrorCode.InvalidMlgLoanErrorCode}`);

    case LoanValidationResponse.InvalidLoanNumber:
      return i18n.t(`${ERRORS_PREFIX}${LoanValidationErrorCode.InvalidLoanNumberErrorCode}`);

    case LoanValidationResponse.InvalidInsurancePolicyLoan:
      return i18n.t(
        `${ERRORS_PREFIX}${LoanValidationErrorCode.InvalidInsurancePolicyLoanErrorCode}`
      );

    case LoanValidationResponse.InvalidRentalAndSocialHousingLoan:
      return i18n.t(
        `${ERRORS_PREFIX}${LoanValidationErrorCode.InvalidRentalAndSocialHousingLoanErrorCode}`
      );

    case LoanValidationResponse.InvalidLoanStatus:
      return i18n.t(`${ERRORS_PREFIX}${LoanValidationErrorCode.InvalidLoanStatusErrorCode}`);

    case LoanValidationResponse.InvalidPremiumsNotFullyPaidLoan:
      return i18n.t(
        `${ERRORS_PREFIX}${LoanValidationErrorCode.InvalidPremiumsNotFullyPaidLoanErrorCode}`
      );

    case LoanValidationResponse.CannotBeAcceptedAtLeastOneUnpaidClaimInProgress:
      return i18n.t(
        `${ERRORS_PREFIX}${LoanValidationErrorCode.CannotBeAcceptedAtLeastOneUnpaidClaimInProgressErrorCode}`
      );

    case LoanValidationResponse.MainClaimPaidOrInProgress:
      return i18n.t(
        `${ERRORS_PREFIX}${LoanValidationErrorCode.MainClaimPaidOrInProgressErrorCode}`
      );

    case LoanValidationResponse.MissingOrActiveMainClaim:
      return i18n.t(
        `${ERRORS_PREFIX}${LoanValidationErrorCode.MainClaimDoesNotExistOrIsCurrentlyInProgressErrorCode}`
      );

    case LoanValidationResponse.InvalidEnhancedAcpp:
      return i18n.t(`${ERRORS_PREFIX}${LoanValidationErrorCode.InvalidEnhancedAcppErrorCode}`);

    case LoanValidationResponse.InvalidMainClaimForSuppClaim:
      return i18n.t(
        `${ERRORS_PREFIX}${LoanValidationErrorCode.InvalidMainClaimForSuppClaimErrorCode}`
      );

    case LoanValidationResponse.DuplicateDraftClaim:
      return i18n.t(`${ERRORS_PREFIX}${LoanValidationErrorCode.DuplicateDraftClaimErrorCode}`);

    case LoanValidationResponse.ProcessInMicsClaim:
      return i18n.t(`${ERRORS_PREFIX}${LoanValidationErrorCode.ProcessInMicsClaimErrorCode}`);

    case LoanValidationResponse.LenderInformationMismatch:
      return i18n.t(
        `${ERRORS_PREFIX}${LoanValidationErrorCode.LenderInformationMismatchErrorCode}`
      );

    // TODO: Add a default error message from sitecore that indicates why it failed, and to contact CMHC
    default:
      return i18n.t(`${ERRORS_PREFIX}${LoanValidationErrorCode.InvalidLoanNumberErrorCode}`);
  }
}
