import { AxiosResponse } from 'axios';

import { DocumentType } from '@hobt/claim-domain';
import { HttpResponseStatusCodes } from '@hobt/constants';

import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { AuthenticationContextType } from 'Foundation/Authentication';

import { config } from '../../../../../config';
import { PrefillData, RefundDetail, RefundDocument } from './RefundRequestLetter/types';
import { RefundAdjustmentDetail } from './Received Refund/Calculations/types';

const refundPrefillUrl = config.claimApi.urls.refundPrefill;
const refundUrl = config.claimApi.urls.refund;
const refundAdjustmentUrl = config.claimApi.urls.refundAdjustment;
const downloadUrl = config.documentApi.urls.download; // TODO: (Sh|C)ould this be somewhere else?

const apiClientConfig: ApiClientConfig = { timeout: config.defaultApi.requestTimeout };

export const getPrefillDetails = async (
  claimId: string,
  authenticationContext: AuthenticationContextType
): Promise<PrefillData | null> => {
  try {
    const response: AxiosResponse = await ApiClient(
      authenticationContext,
      apiClientConfig
    ).getWithAuth(`${refundPrefillUrl}/${claimId}`);

    return response?.data?.statusCode === HttpResponseStatusCodes.OK && response?.data?.data?.[0];
  } catch (error) {
    return null;
  }
};

export const getRefundDetails = async (
  claimId: string,
  authenticationContext: AuthenticationContextType
): Promise<RefundDetail | null> => {
  try {
    const response: AxiosResponse = await ApiClient(
      authenticationContext,
      apiClientConfig
    ).getWithAuth(`${refundUrl}/${claimId}`);

    return response?.data?.statusCode === HttpResponseStatusCodes.OK && response?.data?.data?.[0];
  } catch (error) {
    return null;
  }
};

export const updateRefundLetter = async (
  claimId: string,
  authenticationContext: AuthenticationContextType,
  data: RefundDetail
): Promise<RefundDetail | null> => {
  try {
    const response: AxiosResponse = await ApiClient(
      authenticationContext,
      apiClientConfig
    ).putWithAuth(`${refundUrl}/${claimId}`, data);

    return response?.data?.statusCode === HttpResponseStatusCodes.OK && response?.data?.data?.[0];
  } catch (error) {
    return null;
  }
};

export const updateRefundAdjustments = async (
  claimId: string,
  authenticationContext: AuthenticationContextType,
  data: RefundAdjustmentDetail
): Promise<RefundAdjustmentDetail | null> => {
  try {
    const response: AxiosResponse = await ApiClient(
      authenticationContext,
      apiClientConfig
    ).putWithAuth(`${refundAdjustmentUrl}/${claimId}`, data);

    return response?.data?.statusCode === HttpResponseStatusCodes.OK && response?.data?.data?.[0];
  } catch (error) {
    return null;
  }
};

export const getRefundDocument = async (
  documentId: string,
  claimId: string,
  authenticationContext: AuthenticationContextType
): Promise<RefundDocument | null> => {
  try {
    const response: AxiosResponse = await ApiClient(
      authenticationContext,
      apiClientConfig
    ).getWithAuth(
      `${downloadUrl}/${documentId}?module=claim&entityID=${claimId}&documentType=${DocumentType.Refund}`
    );

    return response?.data?.statusCode === HttpResponseStatusCodes.OK && response?.data?.data;
  } catch (error) {
    return null;
  }
};

export const deleteRefundLetter = async (
  uuid: string,
  authenticationContext: AuthenticationContextType,
  requestLetterID: string | number
): Promise<RefundDetail | null> => {
  try {
    const url = `${refundUrl}/${uuid}/${requestLetterID}`;
    const response: AxiosResponse = await ApiClient(
      authenticationContext,
      apiClientConfig
    ).deleteWithAuth(url);

    return response?.data?.statusCode === HttpResponseStatusCodes.OK && response?.data?.data?.[0];
  } catch (error) {
    return null;
  }
};
