import React from 'react';
import SideMenuLink from '../SideMenuLink';
import { SubMenuProps } from '../SideMenu.types';

const SideMenuList: React.FC<SubMenuProps> = ({
  item,
  index,
  iterated,
  onLinkClick,
  className
}) => {
  return (
    <ul
      id={`collapse-${index}${iterated ? '-iterated' : ''}`}
      key={`collapse-${index}${iterated ? '-iterated' : ''}`}
      className={`side-menu__${className} collapse in`}
    >
      {item.fields.subMenu?.map((subItem: any, subItemIndex: number) => (
        <li
          className="side-menu__subitem"
          id={`collapse-${index}-subItem-${subItemIndex}${iterated ? '-iterated' : ''}`}
          key={`collapse-${index}-subItem-${subItemIndex}${iterated ? '-iterated' : ''}`}
        >
          <SideMenuLink
            item={subItem}
            index={subItemIndex}
            iterated={iterated}
            onLinkClick={onLinkClick}
            className={'subitem-link'}
          />
          {subItem.fields.subMenu?.length > 0 && (
            <SideMenuList
              item={subItem}
              index={subItemIndex}
              onLinkClick={onLinkClick}
              iterated={true}
              className={'subsubMenu-container'}
            />
          )}
        </li>
      ))}
    </ul>
  );
};

export default SideMenuList;
