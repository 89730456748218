import React, { useState } from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

import ClaimsPaymentCentreProps from 'Feature/Claims/models/ClaimsPaymentCentreProps';

const ClaimsPaymentCentre = (props: ClaimsPaymentCentreProps) => {
  const [display, setDisplay] = useState(false);

  const toggleDetails = () => {
    setDisplay(!display);
  };

  return (
    <>
      <div role="region" className={`claims-payment-center`} aria-label="claims_payment_center">
        <h3>
          <Text field={props.fields.cardTitle} />
        </h3>
        <div className="claims-payment-content">
          <RichText field={props.fields.content} />
        </div>
        {props.fields.moreContent?.value?.trim().length !== 0 &&
          props.fields.moreContent != null && (
            <>
              <div
                className={`claims-payment-content ${display == true ? 'showPara' : 'hidePara'}`}
              >
                <RichText field={props.fields.moreContent} />
              </div>
              <button className="claim-payment-center-btn" onClick={toggleDetails}>
                {display ? (
                  <Text field={props.fields.viewLess} />
                ) : (
                  <Text field={props.fields.viewMore} />
                )}
              </button>
            </>
          )}
      </div>
    </>
  );
};

export default ClaimsPaymentCentre;
