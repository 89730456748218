import React, { useEffect } from 'react';
import { useFeature } from 'flagged';
import { useFormContext } from 'react-hook-form';
import { UserRole, Module, ModuleMapping } from '@hobt/constants';
import FormText, { Formatted as FormattedInput } from 'Components/Inputs/FormText';
import { telephoneFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormNumber from 'Components/Inputs/FormNumber';
import FormDropdown from 'Components/Inputs/FormDropdown';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import ArrearsContactDetailsProps, {
  ReportingPeriodField
} from 'Feature/Arrears/models/ArrearsContactDetailsProps';
import { LinedCard, AccordionContainer } from 'Feature/CommonComponents/AccordionComponents';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { getActiveReportingPeriod } from '../../ArrearsSubmissionForm/ArrearsSubmissionService';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const ArrearsContactDetails: React.FC<ArrearsContactDetailsProps> = ({
  fields,
  isModify,
  errorCode,
  setReportData
}: ArrearsContactDetailsProps) => {
  const {
    cardTitle,
    contactName,
    contactEmail,
    contactPhone,
    institutionNum,
    reportingPeriod,
    contactExtension,
    languagePreferArrears,
    correspondenceLanguage
  } = fields;

  const authContext = useAuthenticationContext();
  const cardId = 'ArrearsFormDetails';
  const { register, setValue, setError, watch } = useFormContext();
  const isInternalSite = useFeature(FeatureFlags.INTERNAL);

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const userDetails = sitecoreContext && sitecoreContext.user;
  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.arrears,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  let fiCodeOptions = [
    {
      fields: {
        itemName: { value: '' },
        itemValue: { value: '' }
      }
    }
  ];

  if (
    userDetails?.moduleFiTransitMapping != null &&
    Object.keys(userDetails.moduleFiTransitMapping).length > 0
  ) {
    fiCodeOptions = Object.keys(userDetails.moduleFiTransitMapping[Module.Arrears])?.map(
      (code) => ({
        fields: {
          itemName: { value: code },
          itemValue: { value: code }
        }
      })
    );
  }

  const [reportPeriodData, setReportPeriodData] = React.useState<ReportingPeriodField[]>([]);

  useEffect(() => {
    if (setReportData) {
      setReportData(reportPeriodData);
    }
  }, [reportPeriodData]);

  const getReportingPeriod = async () => {
    try {
      const response: any = await getActiveReportingPeriod(authContext);
      if (response) {
        setReportPeriodData(
          response.data
            ?.map((row: ReportingPeriodField) => ({
              reportingPeriod: row.reportingPeriod,
              reportingPeriodID: row.reportingPeriodID
            }))
            ?.sort((a: ReportingPeriodField, b: ReportingPeriodField) =>
              a?.reportingPeriod > b?.reportingPeriod ? 1 : -1
            )
        );
      } else {
        throw new Error('No response retrieved from db');
      }
    } catch (errors) {
      console.log('Errors: ', errors);
    }
  };
  useEffect(() => {
    getReportingPeriod();
    register('submission.reportingPeriodID');
    if (!isInternalSite && !isModify) {
      if (userDetails != null && userDetails.contact != null) {
        const {
          contact: { emailID, extensionPhoneNumber, firstName, lastName, phoneNumber }
        } = userDetails;
        const fullName = firstName != null || lastName != null ? `${firstName} ${lastName}` : '';

        setValue('submission.contact.name', fullName);
        setValue('submission.contact.emailID', emailID ?? '');
        setValue('submission.contact.phoneNumber', phoneNumber ?? '');
        setValue('submission.contact.extensionPhoneNumber', extensionPhoneNumber ?? '');
      }
    }
  }, []);

  useEffect(() => {
    if (errorCode === 'ficode') {
      // @ts-ignore
      setError('submission.contact.financialInstitutionCode', {
        type: 'manual',
        message: 'Invalid FI Code'
      });
    }
  }, [errorCode]);

  const handleDropdownChange = (e: any) => {
    const watchRP: ReportingPeriodField | undefined = reportPeriodData?.find(
      (element: any) => element?.reportingPeriod === e.target.value
    );
    setValue('submission.reportingPeriod', watchRP?.reportingPeriod);

    setValue('submission.reportingPeriodID', watchRP?.reportingPeriodID);
  };

  return (
    <LinedCard id={cardId} testId={cardId} role="form" linePosition="vertical" lineColor="grey">
      <AccordionContainer accordionId={`${cardId}Accordion`} title={cardTitle}>
        <div className="row card__body-row mb-3">
          <div className="form__element form__element--2-column col-6">
            <FormText
              className=""
              label={contactName}
              isReadOnly={isReadOnlyUser || isModify === true}
              name="submission.contact.name"
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormattedInput
              className=""
              label={contactEmail}
              isReadOnly={isReadOnlyUser || isModify === true}
              name="submission.contact.emailID"
            />
          </div>
        </div>

        <div className="row card__body-row mb-3">
          <div className="form__element form__element--2-column col-6">
            <FormNumber
              className=""
              label={contactPhone}
              isReadOnly={isReadOnlyUser || isModify === true}
              name="submission.contact.phoneNumber"
              formatProps={telephoneFormat}
            />
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormText
              className=""
              label={contactExtension}
              isReadOnly={isReadOnlyUser || isModify === true}
              name="submission.contact.extensionPhoneNumber"
            />
          </div>
        </div>

        <div className="row card__body-row mb-3">
          <div className="form__element form__element--2-column col-6">
            {isInternalSite ? (
              <FormText
                className=""
                label={institutionNum}
                isReadOnly={isReadOnlyUser || isModify === true}
                name="submission.contact.financialInstitutionCode"
              />
            ) : (
              <FormDropdown
                className=""
                required={true}
                setAsNumber={false}
                label={institutionNum}
                options={fiCodeOptions}
                isDisabled={isReadOnlyUser || isModify === true}
                name="submission.contact.financialInstitutionCode"
              />
            )}
          </div>
          <div className="form__element form__element--2-column col-6">
            <FormDropdown
              className=""
              required={true}
              setAsNumber={false}
              label={reportingPeriod}
              isDisabled={isReadOnlyUser || isModify === true}
              name="submission.reportingPeriod"
              options={reportPeriodData?.map(
                (row: ReportingPeriodField): SitecoreListItem => ({
                  fields: {
                    itemName: { value: row.reportingPeriod },
                    itemValue: { value: row.reportingPeriod }
                  }
                })
              )}
              handleChange={handleDropdownChange}
            />
          </div>
        </div>

        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <FormDropdown
              isDisabled={isReadOnlyUser || isModify === true}
              label={correspondenceLanguage}
              options={languagePreferArrears?.fields?.listItems}
              name="submission.contact.correspondenceLanguageCode"
              disableDefaultSelect={false}
            />
          </div>
        </div>
      </AccordionContainer>
    </LinedCard>
  );
};

export default ArrearsContactDetails;
