import { Status as UploadStatusCode } from '@hobt/portfolio-domain';
import { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';

export const InternalAllowedFileTypes = ['xlsm', 'xls', 'xlsx'];
export const ExternalAllowedFileTypes = ['xlsm', 'xls'];

export interface HbtPIRequestPageProps {
  fields: HbtPIRequestFields;
}

export interface HbtPIRequestFields {
  piRequestTitle: FieldValue;
  piRequestCmhcSupportBtn: FieldValue;
  piRequestCmhcSupportInfoContent: FieldValue;
  piRequestUserGuideAccessLabel: FieldValue;
  piRequestUserGuideLink: PIUserGuideFields[];
  piRequestResourcesDropdownBtnLabel: FieldValue;
  piRequestResourcesDropdownItems: PIRequestResourcesDropdownItems[];
  piRequestInputFieldLabel: FieldValue;
  piRequestAttachmentsTitle: FieldValue;
  piRequestAttachmentsTitleInfo: FieldValue;
  piRequestExternalAttachmentSupportedFileFormatText: FieldValue;
  piRequestInternalAttachmentSupportedFileFormatText: FieldValue;
  piRequestAttachmentInputBrowserLabelText: FieldValue;
  piRequestAttachmentInputBrowserLabel: FieldValue;
  piRequestAttachmentRequiredErrorMessage: FieldValue;
  piRequestNoAttachmentTableMessage: FieldValue;
}

export interface PIUserGuideFields {
  fields: {
    guideName: FieldValue;
    guideHref: LinkField;
  };
}
export interface PIRequestResourcesDropdownItems {
  fields: {
    itemName: FieldValue;
    itemValue: FieldValue;
    itemHref: LinkField;
  };
}

export interface NotificationBannerInfo {
  notificationBannerHeader: string;
  notificationBannerType: NotificationBannerType;
  notificationBannerBody: string;
}

export enum NotificationBannerType {
  SUCCESS = 'Success',
  ERROR = 'Error',
  INFO = 'Info',
  WARNING = 'Warning'
}

export type ErrorEntity = {
  errorMessage?: string;
  errorCode?: any;
};

export type DataObject = {
  statusCode: number;
  status?: string;
  data: any | any[];
  error: ErrorEntity;
};

export type Response = {
  status?: number;
  data: DataObject;
};

export type ErrorObject = {
  response?: Response;
};

export enum PortfolioToolType {
  PiPurposeTest = 1,
  PiFileShare = 2,
  PiRequest = 3
}

export interface AttachmentFile {
  attachment: File;
  fileName: string;
  fileSize: number;
  fileType: string;
  errorMessage: string;
  percentage: number;
  timeLeft: number;
  uploadStatusCode: UploadStatusCode;
}
export interface DocumentEntity {
  documentId: string;
  description?: string;
  documentLink: string;
  documentStatus: number;
  fileName: string;
  fileSize: number;
  fileType: string;
  recordCreatedTimestamp: string;
  recordCreatedUserID: string;
  recordUpdatedTimestamp: string;
  recordUpdatedUserID: string;
  uniqueFileName: string;
  portfolioToolType: number;
}
