import React, { createContext, useContext, ReactNode } from 'react';
import { HbtModifyInternalPIUserContextType } from './types';

const HbtModifyInternalPIUserContext = createContext<HbtModifyInternalPIUserContextType>({
  userData: []
});
export const useHbtModifyInternalPIUserContext = () =>
  useContext<HbtModifyInternalPIUserContextType>(HbtModifyInternalPIUserContext);

const HbtModifyInternalPIUserContextProvider = (props: {
  children?: ReactNode;
  value?: HbtModifyInternalPIUserContextType;
}) => {
  return (
    <HbtModifyInternalPIUserContext.Provider
      value={{
        userData: props.value?.userData
      }}
    >
      {props.children}
    </HbtModifyInternalPIUserContext.Provider>
  );
};

export default HbtModifyInternalPIUserContextProvider;
