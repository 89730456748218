import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { HbtIcon } from '../HbtIcon';
import { HbtIconSize } from '../HbtIcon/types';
import styles from './styles.module.scss';
import { LargeFormFieldProps, LargeFormFieldStatus } from './types';
import './overrides.scss';

/**
 * @param {LargeFormFieldProps} props - list out the props.
 * @param {string} props.id - the id of the component.
 * @param {string} props.name - the name of the component.
 * @param {string} props.label - the text label of the component.
 * @param {string} props.initialValue - the value of the component.
 * @param {string} props.helperText - the helper text at the bottom component.
 * @param {boolean} props.isReadOnly - determines if the component is read-only.
 * @param {FieldValue} props.ariaText - the accessibility text to be added to the component.
 * @param {number} props.maxLength - the amount of characters allowed.
 * @param {LargeFormFieldStatus} props.status - the name of the component.
 * @param {function} props.onChange - the change event handler.
 * @param {string} props.className - the trailing icon that should be rendered in the component.
 * @param {number} props.tabIndex - enables the component's loading state.
 * @returns {LargeFormField} Rendered FormField component.
 */

/**
 * Form Field component
 *
 * This is the Form field component written using storybook. It handles data input and various states.
 */

export const LargeFormField: FC<LargeFormFieldProps> = memo(
  ({
    id,
    name,
    label,
    initialValue = '',
    helperText,
    placeholder,
    ariaText,
    rows,
    maxLength,
    isReadOnly,
    onChange,
    status,
    className,
    value
  }: LargeFormFieldProps) => {
    const [formFieldValue, setValue] = useState<string>(initialValue);

    useEffect(() => {
      setValue(value || '');
    }, [value]);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
      if (onChange) {
        onChange(event);
      }
    }, []);

    return (
      <>
        <Typography data-testid="core-form-field-label" className={styles.formLabel}>
          {label}
        </Typography>
        <div
          data-testid="core-form-field"
          className={`${styles.form} ${isReadOnly && styles.readOnly} ${
            status === LargeFormFieldStatus.ERROR && styles.error
          } ${className || ''} ${styles.textareaWrapper}`.trim()}
        >
          <textarea
            id={id}
            name={name}
            value={formFieldValue}
            role="textbox"
            rows={rows || 5}
            readOnly={isReadOnly}
            aria-label={ariaText}
            aria-multiline="true"
            aria-invalid={status === LargeFormFieldStatus.ERROR ? 'true' : 'false'}
            aria-errormessage="error-form"
            onChange={handleChange}
            placeholder={placeholder}
            className={`${isReadOnly && styles.readOnly}`}
          ></textarea>
        </div>
        <Stack
          spacing={2}
          direction="row"
          justifyContent="space-between"
          data-testid="core-form-field-helper-container"
        >
          <Stack direction="row" className={styles.formHelperContainer}>
            {status === LargeFormFieldStatus.ERROR && (
              <Box className={styles.formHelperIconContainer}>
                <HbtIcon
                  size={HbtIconSize.SMALL}
                  type="icon_info_outlined"
                  className={`${styles.formHelperIcon} ${styles.formHelperError}`}
                />
              </Box>
            )}
            <Box
              role="alert"
              id="error-message"
              data-testid="core-form-field-helper"
              className={
                status === LargeFormFieldStatus.ERROR ? styles.formHelperError : styles.formHelper
              }
            >
              {helperText && helperText}
            </Box>
          </Stack>
          <Box
            role="alert"
            id="error-form"
            data-testid="core-form-field-text-count"
            className={
              status === LargeFormFieldStatus.ERROR
                ? styles.formHelperError
                : styles.formHelperTextCount
            }
          >
            {formFieldValue && maxLength && `${formFieldValue?.length}/${maxLength}`}
          </Box>
        </Stack>
      </>
    );
  }
);
