import React, { FC } from 'react';
import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import styles from './styles.module.scss';
import {
  SizeAndThickness,
  SpinnerProps,
  SpinnerSize,
  SpinnerSizeMapping,
  SpinnerVariant
} from './types';

/**
 * @param {SpinnerProps} props - list out the props.
 * @param {FieldValue} props.ariaText - the accessibility text to be added to the component.
 * @param {SpinnerSize} props.size - the size of the component.
 * @param {string} props.type - the type of the element - primary or secondary.
 * @param {string} props.spinnerClassName - the class name of the spinner, used to customize the styles of the spinner's container
 * @returns {Spinner} Rendered component.
 */

/**
 * Spinner component
 *
 * This is the Spinner component written using storybook. It displays the loading state on a page.
 */

export const Spinner: FC<SpinnerProps> = ({
  ariaText,
  modifiers,
  type,
  size,
  spinnerClassName,
  ...props
}) => {
  const defaultSizeAndThickness: SizeAndThickness = [24, 4];
  let effectiveSizeAndThickness: SizeAndThickness = defaultSizeAndThickness;

  if (modifiers?.size && modifiers?.thickness) {
    let size = typeof modifiers.size === 'string' ? parseInt(modifiers.size) : modifiers.size;
    effectiveSizeAndThickness = [size, modifiers.thickness];
  }

  const spinnerSizeMapping: SpinnerSizeMapping = {
    [SpinnerSize.LARGE]: [48, 7],
    [SpinnerSize.MEDIUM]: defaultSizeAndThickness,
    [SpinnerSize.SMALL]: [20, 3],
    [SpinnerSize.VARIABLE]: effectiveSizeAndThickness
  };

  const spinnerFirstRingStyle = {
    [SpinnerVariant.PRIMARY]: styles.primarySpinnerFirstRing,
    [SpinnerVariant.SECONDARY]: styles.secondarySpinnerFirstRing
  };

  const spinnerSecondRingStyle = {
    [SpinnerVariant.PRIMARY]: styles.primarySpinnerSecondRing,
    [SpinnerVariant.SECONDARY]: styles.secondarySpinnerSecondRing
  };

  return (
    <Box
      sx={{ position: 'relative', display: 'flex' }}
      className={spinnerClassName}
      data-testid="core-spinner"
    >
      <CircularProgress
        aria-label={ariaText}
        variant="determinate"
        className={spinnerSecondRingStyle[type!]}
        value={100}
        size={spinnerSizeMapping[size][0]}
        thickness={spinnerSizeMapping[size][1]}
        {...props}
      />
      <CircularProgress
        aria-label={ariaText}
        variant="indeterminate"
        disableShrink
        className={spinnerFirstRingStyle[type!]}
        sx={{
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: { strokeLinecap: 'round' }
        }}
        size={spinnerSizeMapping[size][0]}
        thickness={spinnerSizeMapping[size][1]}
        {...props}
      />
    </Box>
  );
};
