import * as React from 'react';
import { useState } from 'react';
import { ButtonDropdownProps } from './ButtonDropdown.types';
import { Button } from '../Button/Button';

export const ButtonDropdown: React.FC<ButtonDropdownProps> = ({
  dropdownButton,
  dropdownItems,
  otherDropdownButtonProps,
  otherDropdownProps
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const handleDropdownClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsActive(!isActive);
  };

  return (
    <div className={`dropdown-button dropdown`} {...otherDropdownButtonProps}>
      <Button
        id={dropdownButton.id}
        name={dropdownButton.name}
        ariaText={dropdownButton.displayText}
        buttonType={dropdownButton.buttonType}
        onClick={handleDropdownClick}
        displayText={dropdownButton.displayText}
        isDropDown={!dropdownButton.isIcon}
        icon={dropdownButton.icon}
        iconSize={dropdownButton.iconSize}
        disabled={dropdownButton.disabled}
        isIcon={dropdownButton.isIcon}
        otherProps={{
          'data-toggle': 'dropdown',
          'aria-expanded': false,
          'aria-controls': `${dropdownButton.id}Menu`
        }}
      />

      <div
        id={`${dropdownButton.id}Menu`}
        className="dropdown-menu dropdown-menu-right"
        {...otherDropdownProps}
        aria-expanded={isActive}
        aria-labelledby={dropdownButton.id}
      >
        {dropdownItems.map((item, index) => (
          <Button
            id={`${dropdownButton.id}Item${index}`}
            name={item.name}
            key={`${dropdownButton.id}Item${index}`}
            buttonType={item.buttonType}
            displayText={item.displayText}
            disabled={item.disabled}
            icon={item.icon}
            iconSize={item.iconSize}
            onClick={item.onClick}
            otherProps={item.otherProps}
          />
        ))}
      </div>
    </div>
  );
};
