import React, { useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import ContentLoadingModalProps from 'Feature/PageComponents/models/ContentLoadingModalProps';

const ContentLoadingModal = ({ fields, display, delay = 2000 }: ContentLoadingModalProps) => {
  const [isShown, setIsShown] = React.useState(false);
  let timeout: NodeJS.Timeout | undefined;

  useEffect(() => {
    if (display === true) {
      if (timeout != null) {
        clearTimeout(timeout);
      }

      if (isShown === true) {
        setIsShown(false);
      }

      timeout = setTimeout(() => {
        setIsShown(true);
      }, delay);
    }
  }, [display]);

  return display && isShown ? (
    <div className="content-loading-modal__overlay">
      <div className="loading-modal">
        <div className="loading-icon">
          <div className="spinning-loader--wrapper">
            <div className="spinning-loader" />
          </div>
        </div>
        <div className="loading-title">
          <Text field={fields.heading} />
        </div>
        <div className="loading-description">
          <Text field={fields.description} />
        </div>
      </div>
    </div>
  ) : null;
};

export default ContentLoadingModal;
