import React, { createContext, useContext, ReactNode } from 'react';
import {
  HbtPIFileTransferPageContextType,
  NotificationBannerInfo,
  NotificationBannerType
} from './types';

const HbtPIFileTransferPageContext = createContext<HbtPIFileTransferPageContextType>({
  fileTransferPageContextData: {
    showNotificationBanner: false,
    setShowNotificationBanner: (newValue: boolean) => {
      return newValue;
    },
    notificationBannerInfo: {
      notificationBannerHeader: '',
      notificationBannerType: '' as NotificationBannerType,
      notificationBannerBody: ''
    },
    setNotificationBannerInfo: (newValue: NotificationBannerInfo) => {
      return newValue;
    },
    hasUnSavedChanges: false,
    setHasUnSavedChanges(newValue) {
      return newValue;
    }
  }
});
export const useHbtPIFileTransferPageContext = () =>
  useContext<HbtPIFileTransferPageContextType>(HbtPIFileTransferPageContext);

const HbtPIFileTransferPageContextProvider = (props: {
  children?: ReactNode;
  value: HbtPIFileTransferPageContextType;
}) => {
  return (
    <HbtPIFileTransferPageContext.Provider
      value={{
        fileTransferPageContextData: props.value.fileTransferPageContextData
      }}
    >
      {props?.children}
    </HbtPIFileTransferPageContext.Provider>
  );
};

export default HbtPIFileTransferPageContextProvider;
