import i18n from 'i18next';
import DefaultSubmissionFormProps from 'Feature/DefaultSubmissionForm/models/DefaultSubmissionFormProps';

export const getDefaultManagementToolsProps = (props: DefaultSubmissionFormProps) => {
  const defaultManagementToolsProps = {
    deleteIcon: props.fields.deleteIcon,
    title: {
      field: {
        value: i18n.t('DefaultSubmission-Card-DefaultManagementToolsHeading')
      }
    },
    addToolText: {
      field: {
        value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-AddToolText')
      }
    },
    stage1: {
      stage1Title: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-EarlyIntervention')
        }
      },
      checkboxBlock1: {
        checkboxLabel: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-ConversnRepayArrange')
          }
        },
        blockSubText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-ConversnRepayArrangeST')
          }
        },
        blockLenderAuthority: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LenderAuthority')
          }
        },
        blockLenderAuthorityText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LABlockText1')
          }
        }
      },
      checkboxBlock2: {
        checkboxLabel: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-PrePayAndReBorrow')
          }
        },
        blockSubText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-PrePayReBorrowSubText')
          }
        },
        blockLenderAuthority: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LenderAuthority')
          }
        },
        blockLenderAuthorityText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LABlockText1')
          }
        }
      },
      checkboxBlock3: {
        checkboxLabel: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-MortgagePaymentDeferral')
          }
        },
        blockSubText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-MortgPayDefSubText')
          }
        },
        blockLenderAuthority: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LenderAuthority')
          }
        },
        blockLenderAuthorityText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LABlockText2')
          }
        }
      }
    },
    stage2: {
      stage2Title: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LongTermFinanceDiff')
        }
      },
      checkboxBlock1: {
        checkboxLabel: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-ExtdMortgagePayDeferral')
          }
        },
        blockSubText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-ExtdMortgPayDefSubText')
          }
        },
        blockLenderAuthority: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LenderAuthority')
          }
        },
        blockLenderAuthorityText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LABlockText2')
          }
        }
      },
      checkboxBlock2: {
        checkboxLabel: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-ExtensionAmortztnPeriod')
          }
        },
        blockSubText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-ExtAmortztnPeriodSubText')
          }
        },
        blockLenderAuthority: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LenderAuthority')
          }
        },
        blockLenderAuthorityText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LABlockText3')
          }
        }
      },
      checkboxBlock3: {
        checkboxLabel: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-SpecialPayArrangement')
          }
        },
        blockSubText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-SpclPayArrangmntSubText')
          }
        },
        blockLenderAuthority: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LenderAuthority')
          }
        },
        blockLenderAuthorityText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LABlockText4')
          }
        },
        blockBottomText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LABlockBottomText')
          }
        }
      },
      checkboxBlock4: {
        checkboxLabel: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-InterestOnlyPayments')
          }
        },
        blockSubText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-InterestOnlyPaySubText')
          }
        },
        blockLenderAuthority: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LenderAuthority')
          }
        },
        blockLenderAuthorityText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LABlockText5')
          }
        }
      },
      checkboxBlock5: {
        checkboxLabel: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-Capitalization')
          }
        },
        blockSubText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-CapitalizationSubText')
          }
        },
        blockLenderAuthority: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LenderAuthority')
          }
        },
        blockLenderAuthorityText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LABlockText6')
          }
        }
      }
    },
    stage3: {
      stage3Title: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-MoreComplexSituations')
        }
      },
      checkboxBlock1: {
        checkboxLabel: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-CombinationMgmtTools')
          }
        },
        blockSubText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-CombiMgmtToolsSubText')
          }
        },
        blockLenderAuthority: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LenderAuthority')
          }
        },
        blockLenderAuthorityText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LABlockText7')
          }
        }
      }
    },
    stage4: {
      stage4Title: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LossMitigation')
        }
      },
      checkboxBlock1: {
        checkboxLabel: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-SaleByBorrower')
          }
        },
        blockSubText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-SaleByBorrowerSubText')
          }
        },
        blockLenderAuthority: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LenderAuthority')
          }
        },
        blockLenderAuthorityText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LABlockText1')
          }
        }
      },
      checkboxBlock2: {
        checkboxLabel: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-RelocatnAssistExchg')
          }
        },
        blockSubText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-RAExchgSubText')
          }
        },
        blockLenderAuthority: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LenderAuthority')
          }
        },
        blockLenderAuthorityText: {
          field: {
            value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-LABlockText8')
          }
        }
      }
    },
    inputFields: {
      stage1NewRate: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-NewRate')
        }
      },
      stage1EffectiveDate: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-EffectiveDate')
        }
      },
      stage1TotalPrePay: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-TotalPrePay')
        }
      },
      stage1TotalReBorrowed: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-TotalReBorrowed')
        }
      },
      stage1TotalMonthsDeferred: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-TotalMonthsDeferred')
        }
      },
      stage1TotalDeferredAmount: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-TotalDeferredAmount')
        }
      },
      stage2TotalMonthsDeferred: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-TotalMonthsDeferred')
        }
      },
      stage2TotalDeferredAmount: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-TotalDeferredAmount')
        }
      },
      stage2CurrentNumberOfYears: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-NumberOfYears')
        }
      },
      stage2CurrentNumberOfMonths: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-NumberOfMonths')
        }
      },
      stage2NewAmortizationPeriod: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-NewAmortizationPeriod')
        }
      },
      stage2ADateAccountPosition: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-SpecialPayDateAccCurrent')
        }
      },
      stage2BProposedMonthlyPaymentChange: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-SpclPayPrpsdMthlyPayChng')
        }
      },
      stage2EffectiveDate: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-SpclPayEffDatePrpsdPay')
        }
      },
      stage2CalculateGDS_SpecialPayment: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-SpecialPayCalculateGds')
        }
      },
      stage2CalculateTDS_SpecialPayment: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-SpecialPayCalculateTds')
        }
      },
      stage2InterestOnly: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-InterestOnlyPaytAmnt')
        }
      },
      stage2Date: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-Date')
        }
      },
      stage2AProposedMonthlyPaymentChange: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-CapitalPrpsdPayChng')
        }
      },
      stage2CalculateGDS_Capitalization: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-CapitalizationCalGds')
        }
      },
      stage2CalculateTDS_Capitalization: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-CapitalizationCalTds')
        }
      },
      stage2AmountOfCapitalization: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-AmountOfCapitalization')
        }
      },
      stage2PurposeOfFunds: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-PurposeOfFunds')
        }
      },
      stage3Tool: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-ToolText')
        }
      },
      stage3Amount: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-AmountText')
        }
      },
      stage4ListPrice: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-ListPrice')
        }
      },
      stage4AppraisalValue: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-AppraisalValue')
        }
      },
      stage4AmountRequested: {
        field: {
          value: i18n.t('DefaultSubmission-Card-DefaultManagementTools-AmountRequested')
        }
      }
    }
  };

  return defaultManagementToolsProps;
};
