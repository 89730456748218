import React, { memo, useCallback, useEffect, useRef } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Button } from 'Components/Core/Button';
import { ButtonVariant } from 'Components/Core/Button/types';
import Checkbox from 'Components/Core/Checkbox/Checkbox';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import useEnterOrSpaceKey from 'Components/Hooks/UseEnterOrSpaceKey';
import { pathNames } from 'Constants/index';
import { useCheckboxKeyDownHandler } from 'Feature/V2/Helper';
import { useAppInsightsContext } from 'Foundation/Analytics/AppInsightsContext';
import useCustomEvent from 'Foundation/Analytics/useCustomEvent';
import { useAuthenticationContext } from 'Foundation/Authentication';
import i18n from 'i18next';
import Helmet from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import HbtTermsAndConditionsComponentProps, { CheckboxFormField, FormInputs } from './types';

const HbtTermsAndConditionsComponent = ({ fields }: HbtTermsAndConditionsComponentProps) => {
  const history = useHistory();
  const authenticationContext = useAuthenticationContext();

  const reactPlugin = useAppInsightsContext();
  const trackTermsAndConditions = useCustomEvent(reactPlugin, 'Terms and Conditions', {});

  const {
    control,
    formState: { errors },
    handleSubmit,
    trigger
  } = useForm<FormInputs>();
  const errorRef = useRef<HTMLSpanElement | null>(null);

  const onSubmit = useCallback(() => {
    trackTermsAndConditions({ decision: 'approved' });
    authenticationContext.acceptTermsAndConditions();
    history.push(`/${i18n.language}${pathNames.dashboard}`);
  }, [history, authenticationContext, trackTermsAndConditions]);

  const handleDecline = useCallback(() => {
    trackTermsAndConditions({ decision: 'declined' });
    authenticationContext.signOut();
    history.push(pathNames.landing);
  }, [history, authenticationContext, trackTermsAndConditions]);

  const handleAcknowledgementCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, field: CheckboxFormField) => {
      field.onChange(e.target.checked);
      trigger('termsAndConditionsAcknowledge');
    },
    [trigger]
  );

  const handleCheckboxKeyDown = useCheckboxKeyDownHandler();

  useEffect(() => {
    if (errors.termsAndConditionsAcknowledge && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      errorRef.current.focus();
    }
  }, [errors.termsAndConditionsAcknowledge]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language || 'en' }}>
        <title>
          {fields.piTermsAndConditionsPageTitle?.value || 'Portfolio Insurance Terms of Use'}
        </title>
      </Helmet>
      <div className={styles.piTermsAndConditionsMainBody}>
        <div className={styles.piTermsAndConditionsMainContainer}>
          <div className={styles.piTermsAndConditionsHeaderContainer}>
            <h2 className={styles.piTermsAndConditionsHeader}>
              <Text field={fields.heading} />
            </h2>
            <span className={styles.piTermsAndConditionsSubHeader}>
              <Text field={fields.subHeader} />
            </span>
          </div>
          <div className={styles.piTermsAndConditionsContentContainer}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.piTermsAndConditionsContentInfo}>
                <RichText field={fields.content} />
                <div
                  className={styles.acknowledgedCheckboxContainer}
                  aria-invalid={Boolean(errors.termsAndConditionsAcknowledge)}
                >
                  <Controller
                    name="termsAndConditionsAcknowledge"
                    control={control}
                    defaultValue={false}
                    rules={{
                      required:
                        fields.acknowledgementErrorText.value ??
                        'Please accept the terms of use before continuing.'
                    }}
                    render={({ field: { ref, ...field } }) => (
                      <>
                        <Checkbox
                          {...field}
                          checked={field.value}
                          defaultChecked={false}
                          label={{
                            value:
                              fields.acknowledgementText.value ??
                              'I have read, understood, and accept the above Terms of Use.'
                          }}
                          errorCheckbox={!!errors.termsAndConditionsAcknowledge}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleAcknowledgementCheckboxChange(e, field)
                          }
                          onKeyDown={handleCheckboxKeyDown}
                        />
                        {!!errors.termsAndConditionsAcknowledge && (
                          <span
                            ref={errorRef}
                            id="error-checkbox"
                            role="alert"
                            aria-atomic="true"
                            aria-live="polite"
                            className={styles.checkboxError}
                          >
                            <HbtIcon
                              className={styles.error}
                              size={HbtIconSize.SMALL}
                              type="icon_error"
                            />
                            {fields.acknowledgementErrorText.value}
                          </span>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className={styles.piTermsAndConditionsActionBtnContainer}>
                <Button
                  name={fields.declineButtonText.value ?? 'Decline'}
                  text={fields.declineButtonText}
                  ariaText={{ value: `${fields.declineButtonText.value}` }}
                  variant={ButtonVariant.SECONDARY}
                  onClick={handleDecline}
                  onKeyDown={useEnterOrSpaceKey(handleDecline)}
                />
                <Button
                  name={fields.acceptButtonText.value ?? 'Accept'}
                  text={fields.acceptButtonText}
                  ariaText={{ value: `${fields.acceptButtonText.value}` }}
                  variant={ButtonVariant.PRIMARY}
                  type="submit"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(HbtTermsAndConditionsComponent);
