import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { AdjudicationCardHeaderTitleProps } from 'Feature/Claims/components/Details/AdjudicationCardHeader/AdjudicationCardHeaderTitle/types';

import styles from './styles.module.scss';

export const AdjudicationCardHeaderTitle: React.FC<AdjudicationCardHeaderTitleProps> = ({
  preTitleLabel,
  preTitle,
  titleLabel,
  title
}: AdjudicationCardHeaderTitleProps) => (
  <h2 className={styles.cardTitleWrapper}>
    <span className={styles.preTitle}>
      {preTitleLabel && <Text field={preTitleLabel} />} {preTitle}
    </span>
    {titleLabel && <Text field={titleLabel} />} {title}
  </h2>
);
