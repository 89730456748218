import * as React from 'react';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import BreadcrumbProps from 'Feature/PageComponents/models/BreadcrumbProps';
import { pathNames } from 'Constants/pathNames';
import styles from './styles.module.scss';

const Breadcrumb = ({ fields }: BreadcrumbProps) => {
  return (
    <ul className={styles.breadcrumbs}>
      <li key="breadcrumbHome">
        <Link to={{ pathname: pathNames.dashboard }}>{i18n.t('Global-Breadcrumb-Dashboard')}</Link>
        <span className={`material-icons icon--size-16 icon--v-align-middle ${styles.chevronIcon}`}>
          chevron_right
        </span>
      </li>
      {fields?.data?.allpath?.breadcrumbs?.map((itm, idx) => (
        <li key={`breadcrumb-link-${idx}`}>
          {fields?.data?.allpath?.breadcrumbs?.length - 1 !== idx ? (
            <>
              <Link to={itm.path}>{`${itm.name}` ?? ''}</Link>
              <span
                className={`material-icons icon--size-16 icon--v-align-middle ${styles.chevronIcon}`}
              >
                chevron_right
              </span>
            </>
          ) : (
            <>{itm.name ?? ''}</>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumb;
