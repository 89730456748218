import React, { memo } from 'react';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import HbtFooterPlaceholderProps from './types';

const HbtFooterPlaceHolderComponent: React.FC<HbtFooterPlaceholderProps> = ({
  rendering
}: HbtFooterPlaceholderProps) => {
  const sitecoreContextFactory = useSitecoreContext();

  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  return (
    <>
      {sitecoreContext.isPortfolioSite ? (
        <Placeholder name="hbt-shared-v2-footer" rendering={rendering} />
      ) : (
        <Placeholder name="hbt-shared-footer" rendering={rendering} />
      )}
    </>
  );
};

export default memo(HbtFooterPlaceHolderComponent);
