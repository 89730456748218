import i18n from 'i18next';

export const getHomeownerLoanCheckProps = () => {
  const homeownerLoanCheckProps = {
    testId: 'defaultsHomeownerLoanCheck',
    accordionLineColor: 'grey',
    title: {
      field: {
        value: i18n.t('DefaultSubmission-Card-HomeownerLoanCheckTitle')
      }
    },
    inputFields: {
      homeownerloanQ: {
        field: {
          value: i18n.t('DefaultSubmission-Card-HomeownerLoanCheckLoanQ')
        }
      },
      smallDescription: {
        field: {
          value: i18n.t('DefaultSubmission-Card-HomeownerLoanCheckDescription')
        }
      },
      radioButtonYes: {
        field: {
          value: i18n.t('DefaultSubmission-Card-RadioButtonYes')
        }
      },
      radioButtonNo: {
        field: {
          value: i18n.t('DefaultSubmission-Card-RadioButtonNo')
        }
      }
    }
  };

  return homeownerLoanCheckProps;
};
