import React, { memo } from 'react';
import { Box } from '@mui/material';
import { Button } from 'Components/Core/Button';
import { ButtonVariant } from 'Components/Core/Button/types';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import { PaginationPageButtonsProps } from 'Components/Core/Pagination/types';
import i18n from 'i18next';
import styles from './styles.module.scss';

const PaginationPageButtons: React.FC<PaginationPageButtonsProps> = ({
  currentPageNumber,
  totalPageNumber,
  paginationRange,
  onPageChange
}) => {
  const paginationLabel = i18n.t('Pagination') ?? 'Pagination';
  const previousPageIconLabel = i18n.t('Pagination-Previous-Page') ?? 'Previous Page';
  const nextPageIconLabel = i18n.t('Pagination-Next-Page') ?? 'Next Page';

  return (
    <Box className={styles.paginationButtonContainer}>
      {currentPageNumber > 1 && (
        <Button
          className={styles.paginationChevron}
          variant={ButtonVariant.ICON}
          name="paginationButton"
          ariaText={{ value: paginationLabel }}
          text={{ value: previousPageIconLabel }}
          icon={() => <HbtIcon size={HbtIconSize.MEDIUM} type="icon_chevron_left" />}
          disabled={currentPageNumber === 1}
          data-testid="pagination-button"
          onClick={() => onPageChange(currentPageNumber - 1)}
        />
      )}
      {paginationRange.map((page, index) =>
        typeof page === 'number' ? (
          page === currentPageNumber ? (
            <Button
              className={styles.currentPageButton}
              key={index}
              name="paginationButton"
              text={{ value: page.toString() }}
              ariaText={{ value: page.toString() }}
              data-testid="pagination-button"
              disabled={true}
            />
          ) : (
            <Button
              className={styles.pageButton}
              variant={ButtonVariant.TERTIARY}
              key={index}
              name="paginationButton"
              text={{ value: page.toString() }}
              ariaText={{ value: paginationLabel }}
              data-testid="pagination-button"
              onClick={() => onPageChange(page)}
            />
          )
        ) : (
          <Box key={index}>
            <span className={styles.paginationEllipsis} data-testid="pagination-ellipsis" />
          </Box>
        )
      )}
      {currentPageNumber < totalPageNumber && (
        <Button
          className={styles.paginationChevron}
          variant={ButtonVariant.ICON}
          name="paginationButton"
          ariaText={{ value: paginationLabel }}
          text={{ value: nextPageIconLabel }}
          icon={() => <HbtIcon size={HbtIconSize.MEDIUM} type="icon_chevron_right" />}
          disabled={currentPageNumber === totalPageNumber}
          data-testid="pagination-button"
          onClick={() => onPageChange(currentPageNumber + 1)}
        />
      )}
    </Box>
  );
};

export default memo(PaginationPageButtons);
