import React, { memo, useState, useEffect } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import { CSSTransition } from 'react-transition-group';
import { Button } from '../Button';
import { ButtonSize, ButtonVariant } from '../Button/types';
import { HbtIcon } from '../HbtIcon';
import { HbtIconSize } from '../HbtIcon/types';
import styles from './styles.module.scss';
import { NotificationBannerProps } from './types';

const NotificationBanner: React.FC<NotificationBannerProps> = ({
  showNotificationBanner,
  headerText,
  message,
  onCloseNotificationBanner,
  variant
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(showNotificationBanner);
  }, [showNotificationBanner]);

  useEffect(() => {
    if (visible) {
      const banner = document.getElementById('notification-banner');
      banner?.focus();
    }
  }, [visible]);

  const BannerNotificationMapping: Record<string, string> = {
    Info: 'icon_info_outlined',
    Warning: 'icon_warning',
    Error: 'icon_error_outlined',
    Success: 'icon_check_circle_green'
  };

  const leadingIcon = (
    <HbtIcon size={HbtIconSize.MEDIUM} type={BannerNotificationMapping[variant]} />
  );

  return (
    <CSSTransition
      in={visible}
      timeout={300}
      classNames={{
        enter: styles.notificationEnter,
        enterActive: styles.notificationEnterActive
      }}
      unmountOnExit
    >
      <div
        className={`${styles.notificationBanner} ${styles[variant.toLowerCase()]}`}
        data-testid="notificationBanner"
        role="alertdialog"
        aria-atomic="true"
        aria-live="polite"
        id="notification-banner"
        tabIndex={-1}
        aria-describedby="notification-banner-message"
      >
        <div className={styles.topContent}>
          <div className={styles.leftSide}>
            <div className={styles.leadingIconStyle}>{leadingIcon}</div>
            <div>
              <div className={styles.headerStyle}>
                <Text field={headerText} />
              </div>
            </div>
          </div>
          <Button
            className={styles.closeButton}
            variant={ButtonVariant.ICON}
            size={ButtonSize.SMALL}
            icon={() => <HbtIcon size={HbtIconSize.MEDIUM} type="icon_close" />}
            ariaText={{ value: i18n.t('Accessibility-Close-Button') ?? 'Close' }}
            name="Close"
            text={{ value: 'Close' }}
            onClick={onCloseNotificationBanner}
          />
        </div>
        <div className={styles.messageStyle} id="notification-banner-message">
          <RichText field={message} />
        </div>
      </div>
    </CSSTransition>
  );
};

export default memo(NotificationBanner);
