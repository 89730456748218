import { useState, useEffect } from 'react';

import { ApiClient } from 'Foundation/Api';
import { AuthenticationContextType } from 'Foundation/Authentication';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { config } from '../../../../../../../config';

export const useAssignClaim = (
  initialOwner: { label: string; value: string } | null,
  authContext: AuthenticationContextType,
  onAssignmentCallback?: Function,
  onErrorDefault?: Function
) => {
  const [currentOwner, setCurrentOwner] = useState<{ label: string; value: string } | null>(
    initialOwner
  );
  const [proposedOwner, setProposedOwner] = useState<{ label: string; value: string } | null>();
  const [modalIsActive, setModalIsActive] = useState<boolean>(false);
  const [users, setUsers] = useState<{ label: string; value: string }[]>([]);

  const apiClientConfig = {
    timeout: config.claimApi.requestTimeout
  };

  const { getWithAuth, postWithAuth } = ApiClient(authContext, apiClientConfig);
  const { uuid: claimId, claimData } = useHBTFormContext();
  const adjudicatorUserID = claimData?.profile?.adjudicatorUserID;

  const assignOwner = () => {
    postWithAuth(config.claimApi.urls.assign, {
      claimID: claimId,
      adjudicatorUserID: proposedOwner?.value
    })
      .then((response) => {
        setCurrentOwner(proposedOwner ?? null);
        setProposedOwner(null);

        if (onAssignmentCallback) {
          onAssignmentCallback(response?.data?.data);
        }
      })
      .catch(() => {
        if (onErrorDefault) {
          onErrorDefault();
        }
      });
  };

  const getUsers = async () => {
    try {
      const { data } = await getWithAuth(config.claimApi.urls.claimAdjudicators);
      const formattedData = data.data?.map((user: { fullName: string; userID: string }) => ({
        label: user.fullName,
        value: user.userID
      }));
      setUsers(formattedData);
      const thisCurrentOwner = formattedData?.find(
        (user: { label: string; value: string }) => user.value === adjudicatorUserID
      );
      if (thisCurrentOwner != null) setCurrentOwner(thisCurrentOwner);
    } catch (err) {
      if (onErrorDefault) {
        onErrorDefault();
      }
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (proposedOwner && currentOwner) {
      setModalIsActive(true);
    } else if (proposedOwner && currentOwner === null) {
      assignOwner();
    } else {
      setModalIsActive(false);
    }
  }, [proposedOwner]);

  useEffect(() => {
    if (currentOwner === proposedOwner) {
      setProposedOwner(null);
    }
  }, [currentOwner]);

  const hideConfirmationModal = () => {
    setProposedOwner(null);
  };

  const onConfirmOwnerChange = () => {
    assignOwner();
  };

  const onAssignChange = (e: { label: string; value: string } | null) => {
    setProposedOwner(e);
  };

  return {
    currentOwner,
    modalIsActive,
    users,
    proposedOwner,

    onConfirmOwnerChange,
    hideConfirmationModal,
    onAssignChange
  };
};
