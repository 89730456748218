import i18n from 'i18next';

const estimateRemainingTime = (percentUploaded: number, elapsedUploadTime: number) => {
  const estimate = Math.max(
    0,
    Math.round(elapsedUploadTime / (percentUploaded * 0.01) - elapsedUploadTime)
  );
  if (typeof estimate !== 'number' || Number.isNaN(estimate)) {
    return i18n.t('ClaimsSubmission-Attachments-RemainingTime');
  }
  if (estimate === 1) {
    return i18n.t('ClaimsSubmission-Attachments-SecondsLeft');
  }
  if (estimate === 0 && percentUploaded < 90) {
    return i18n.t('ClaimsSubmission-Attachments-RemainingTime');
  }
  if (estimate > 59) {
    return i18n
      .t('ClaimsSubmission-Attachments-CountMinutes')
      .replace('{minutesCount}', Math.floor(estimate / 60).toString())
      .replace('{secondsCount}', (estimate % 60).toString());
  }
  return i18n
    .t('ClaimsSubmission-Attachments-CountSeconds')
    .replace('{secondsCount}', estimate.toString());
};

export default estimateRemainingTime;
