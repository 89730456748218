import React, { PropsWithChildren } from 'react';
import FormToggle from 'Components/Inputs/FormToggle';

import styles from './styles.module.scss';

export type SystemValueOverrideCellProps = {
  name: string;
  systemValueOverridden: boolean;
  switchLabelText: string[];
  onToggle: () => void;
  className?: string;
  disabled?: boolean;
};

export const SystemValueOverrideCell: React.FC<PropsWithChildren<SystemValueOverrideCellProps>> = ({
  name,
  systemValueOverridden,
  switchLabelText,
  onToggle,
  className,
  children,
  disabled = false
}: PropsWithChildren<SystemValueOverrideCellProps>) => {
  return (
    <td>
      <div className={`${styles.wrapper} ${className}`}>
        <FormToggle
          dynamicText={switchLabelText}
          id={`${name}FormToggle`}
          testId={`${name}FormToggleTest`}
          isChecked={systemValueOverridden}
          onChange={onToggle}
          className={styles.formToggle}
          isDisabled={disabled === true ? disabled : undefined}
        />
        {children}
      </div>
    </td>
  );
};
