import { InternalClaimStatus } from '@hobt/claim-domain';
import { UserRole } from '@hobt/constants';

export enum ClaimButton {
  // Claim Action Buttons
  APPROVE_CLAIM,
  AUTO_DECISION,
  CANCEL_CLAIM,
  CONTEST_CLAIM,
  DECLINE_CLAIM,
  DELETE,
  PREVIEW_CALC,
  RE_ASSIGN,
  SAVE_CHANGES,

  // Edit Functionality Buttons
  EDIT_CLAIM_SUBMISSION,
  EDIT_SUSPENSE,
  EDIT_LOAN_DETAILS
}

type EnablementMatrix = {
  [claimButton in ClaimButton]?: {
    [claimStatus in InternalClaimStatus]?:
      | {
          [userRole in UserRole]?: boolean;
        }
      | boolean;
  };
};

export const isButtonEnabled = (
  button: ClaimButton,
  claimStatus: InternalClaimStatus,
  userRoles: UserRole[]
): boolean => {
  const enablementMatrix: EnablementMatrix = {
    [ClaimButton.PREVIEW_CALC]: {
      [InternalClaimStatus.InProgress]: true,
      [InternalClaimStatus.Pending]: true
    },
    [ClaimButton.SAVE_CHANGES]: {
      [InternalClaimStatus.InProgress]: true,
      [InternalClaimStatus.Pending]: true
    },
    [ClaimButton.APPROVE_CLAIM]: {
      [InternalClaimStatus.InProgress]: true
    },
    [ClaimButton.DECLINE_CLAIM]: {
      [InternalClaimStatus.InProgress]: true,
      [InternalClaimStatus.Pending]: true
    },
    [ClaimButton.AUTO_DECISION]: {
      [InternalClaimStatus.ApprovalRequired]: true,
      [InternalClaimStatus.InProgress]: true
    },
    [ClaimButton.CANCEL_CLAIM]: {
      [InternalClaimStatus.New]: true,
      [InternalClaimStatus.InProgress]: true,
      [InternalClaimStatus.Pending]: true,
      [InternalClaimStatus.ApprovedAuto]: true,
      [InternalClaimStatus.ApprovedManual]: true,
      [InternalClaimStatus.NoClaimPayable]: true,
      [InternalClaimStatus.Paid]: true,
      [InternalClaimStatus.RefundRequested]: true,
      [InternalClaimStatus.CmhcReimbursed]: true
    },
    [ClaimButton.CONTEST_CLAIM]: {
      [InternalClaimStatus.Declined]: true
    },
    [ClaimButton.DELETE]: {
      [InternalClaimStatus.Cancelled]: {
        [UserRole.MasterUser]: true
      },
      [InternalClaimStatus.New]: {
        [UserRole.MasterUser]: true
      }
    },
    [ClaimButton.RE_ASSIGN]: {
      [InternalClaimStatus.ReviewRequired]: true,
      [InternalClaimStatus.InProgress]: true,
      [InternalClaimStatus.Pending]: true,
      [InternalClaimStatus.Declined]: true,
      [InternalClaimStatus.ApprovedAuto]: true,
      [InternalClaimStatus.ApprovedManual]: true,
      [InternalClaimStatus.NoClaimPayable]: true,
      [InternalClaimStatus.ClaimPaymentInProgress]: true,
      [InternalClaimStatus.Paid]: true,
      [InternalClaimStatus.PaymentFailed]: true
    },
    [ClaimButton.EDIT_CLAIM_SUBMISSION]: {
      [InternalClaimStatus.ReviewRequired]: true,
      [InternalClaimStatus.InProgress]: true,
      [InternalClaimStatus.Pending]: true
    },
    [ClaimButton.EDIT_SUSPENSE]: {
      [InternalClaimStatus.Cancelled]: true,
      [InternalClaimStatus.New]: true,
      [InternalClaimStatus.ReviewRequired]: true,
      [InternalClaimStatus.InProgress]: true,
      [InternalClaimStatus.Pending]: true
    }
  };

  const buttonEnablementByClaimStatus = enablementMatrix[button]?.[claimStatus];

  if (buttonEnablementByClaimStatus == null) {
    return false;
  }

  if (typeof buttonEnablementByClaimStatus === 'boolean') {
    return buttonEnablementByClaimStatus;
  }

  const buttonEnablementByClaimStatusAndRoleWhereTrue = userRoles
    ?.map((role) => buttonEnablementByClaimStatus[role])
    ?.find((val) => val === true);

  return buttonEnablementByClaimStatusAndRoleWhereTrue !== undefined;
};
