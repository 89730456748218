import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { FormRadioInput, FormRadioInputOption } from './FormInputRadio.types';
import GetErrorMessage from '../ErrorMessage';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash-es';

const FormInputRadio: React.FC<FormRadioInput> = (props) => {
  const errors = _.get(props.errors, `${props.name}`);
  return (
    <div className="form__element form__element--2-column">
      <fieldset>
        {props.title && (
          <legend
            id={`${props.id}Label`}
            className={`${_.get(props.errors, `${props.name}`) && 'form-error__field'}`}
            style={{ border: 'none' }}
          >
            <Text field={props.title} />
          </legend>
        )}
        {props.inputDescription && <small>{props.inputDescription.value}</small>}
        {
          <div
            role="radiogroup"
            aria-labelledby={`${props.id}Label`}
            id={`${props.id}Radiogroup`}
            className={
              'form__radio-group form__radio-group-' +
              `${props.verticalLayout ? 'vertical' : 'horizontal'}`
            }
          >
            {props.options &&
              props.options.map((option: FormRadioInputOption) => (
                <React.Fragment key={option.value}>
                  <label
                    className={
                      'form__label--muted ' +
                      `${_.get(props.errors, `${props.name}`) && 'form-error__label'}`
                    }
                    id={`${props.id}Label${option.value}`}
                    htmlFor={`${props.id}_${option.value}`}
                  >
                    <input
                      type="radio"
                      id={`${props.id}_${option.value}`}
                      data-testid={props.testid}
                      aria-labelledby={`${props.id}Label${option.value}`}
                      name={`${props.name}`}
                      value={option.value}
                      onChange={props.handleChange}
                      {...(props.register &&
                        props.register(`${props.name}`, { required: props.isRequired }))}
                      aria-describedby={`${props.id}ErrorDesc`}
                      disabled={props.isDisabled}
                    />
                    <Text field={option.label} />
                  </label>
                </React.Fragment>
              ))}
          </div>
        }
        {!props.hideError && (
          <div className="form__fieldNotificationHolder">
            {errors && (
              <span
                className="form-error__sub-text"
                aria-live="assertive"
                id={`${props.id}ErrorDesc`}
              >
                {GetErrorMessage(errors.type as string, errors.message as string)}
                <input
                  type="hidden"
                  className="thisFieldHasErrors"
                  value={props.cardName && props.cardName}
                />
              </span>
            )}
          </div>
        )}
      </fieldset>
    </div>
  );
};

export default FormInputRadio;
