import React, { FC } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { isNullOrUndefined } from 'Components/Common/Api/utils/EmptyValues';
import styles from '../styles.module.scss';
import { DropdownHelpMessageProps } from '../types';

export const DropdownHelpMessage: FC<DropdownHelpMessageProps> = ({
  helpText
}: DropdownHelpMessageProps) => {
  const hasHelpText = !isNullOrUndefined(helpText);

  return hasHelpText ? (
    <span className={styles.helperText}>
      <Text field={helpText} />
    </span>
  ) : null;
};
