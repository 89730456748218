import React, { FC } from 'react';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import { TagGroup } from '../../Tag/TagGroup';
import { TagProps, TagType } from '../../Tag/types';
import { DropdownTagGroupProps } from '../types';

const tagCheckIcon = (
  <HbtIcon size={HbtIconSize.SMALL} type="icon_check_circle" className="tagCheckIcon" />
);

export const DropdownTagGroup: FC<DropdownTagGroupProps> = ({
  selectedOptions,
  setSelectedOptions
}: DropdownTagGroupProps) => {
  const onDeleteTag = (id?: string) => {
    console.log(`sdvsdvs On delete called: ${id}`);
    if (!id) {
      return;
    }
    const newSelections = selectedOptions?.filter((opt) => opt.value != id);
    setSelectedOptions(newSelections ?? null);
  };

  const getTagsKey = () => {
    let tags = getTagGroupProps();
    return tags
      .map(function (tag: TagProps) {
        return tag.id;
      })
      .join(',');
  };

  const getTagGroupProps: () => TagProps[] = () => {
    let props = selectedOptions?.map((option) => {
      return {
        id: option.value,
        label: option.label,
        variant: TagType.INTERACTIVE,
        leadingIcon: tagCheckIcon,
        statusIndicator: 'Green',
        onDelete: onDeleteTag
      } as TagProps;
    });
    return props ?? [];
  };

  return <TagGroup key={getTagsKey()} tags={getTagGroupProps()} />;
};
