import React from 'react';
import { NavLink } from 'react-router-dom';

import { pathNames } from 'Constants/pathNames';
import { SubMenuProps } from '../SideMenu.types';

const SideMenuLink: React.FC<SubMenuProps> = ({
  item,
  index,
  iterated,
  onLinkClick,
  className
}) => {
  return (
    <NavLink
      to={item.fields?.navItem.value.href ?? pathNames.dashboard}
      className={`side-menu__${className} ${
        item.fields?.subMenu && item.fields?.subMenu?.length > 0 ? 'has-children' : ''
      }`}
      {...(item.fields?.subMenu == null || item.fields?.subMenu?.length === 0
        ? {
            onClick: () => onLinkClick?.()
          }
        : { onClick: (e) => e.preventDefault() })}
      data-toggle={`${item.fields?.subMenu && item.fields.subMenu?.length > 0 ? 'collapse' : ''}`}
      data-target={`#collapse-${index}${iterated ? '-iterated' : ''}`}
    >
      {item.fields.text.value}
    </NavLink>
  );
};
export default SideMenuLink;
