import { useState } from 'react';
import { AxiosError } from 'axios';
import { UseFormReturn, useForm } from 'react-hook-form';

import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import { CreateUnderwritingReviewFormData } from 'Feature/PreClaims/components/PreClaimsUnderwritingReview/AddUnderwritingReviewForm/types';
import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { underwritingReviewSchema } from '@hobt/claim-domain';

import { hbtResolver } from '@hobt/schema-validator';
import { config } from '../../../../../config';

const useAddUnderReviewFlagFunctions = (
  onSuccessCallback?: Function,
  setIsLoadingCallback?: React.Dispatch<React.SetStateAction<Spinner>>,
  onErrorCallback?: Function
) => {
  const [showValidationError, setShowValidationError] = useState<boolean>(false);

  // API Client Config
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  const authContext = useAuthenticationContext();
  const { postWithAuth } = ApiClient(authContext, apiClientConfig);
  const hookForm: UseFormReturn<Record<string, any>> = useForm({
    resolver: hbtResolver(underwritingReviewSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  } as Record<string, any>);

  const handleSubmit: any = (data: CreateUnderwritingReviewFormData) => {
    const requestData = {
      cmhcLoanAccountNumber: data.cmhcLoanAccountNumber,
      discloserIndicator: data.discloserIndicator
        ? Number(data.discloserIndicator)
        : data.discloserIndicator
    };

    setIsLoadingCallback?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description',
      isFlyout: true
    });

    postWithAuth(config.preClaimUnderwritingReviewApi.urls.submit, requestData)
      .then(() => {
        hookForm.reset();
        if (onSuccessCallback) {
          onSuccessCallback();
        }
        setIsLoadingCallback?.({
          isLoading: false
        });
      })
      .catch((e: AxiosError | any) => {
        setIsLoadingCallback?.({
          isLoading: false
        });
        if (e.response?.status === 500 && e?.response?.data.error.errorCode === 'HBT_ERR_5008') {
          setShowValidationError(true);
        } else if (onErrorCallback) {
          hookForm.reset();
          onErrorCallback();
        }
      });
  };

  const onSubmitErrors: any = (errs: Record<string, Object>) => {
    // TODO: Remove once validations are in place
    console.log('FORM ERRORS: ', errs);
  };

  const onCloseValidationErrors = () => {
    setShowValidationError(false);
  };

  return {
    hookForm,
    handleSubmit,
    onSubmitErrors,
    onCloseValidationErrors,
    showValidationError
  };
};

export default useAddUnderReviewFlagFunctions;
