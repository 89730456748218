import React, { useContext } from 'react';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useFormContext } from 'react-hook-form';

import { ModuleMapping, UserRole } from '@hobt/constants';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';

import { StickyFooter } from 'Components/PageComponents/StickyFooter';
import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';
import { Button } from 'Components/Common/Button';

import { AssessmentCalculationsContext } from 'Feature/Claims/components/Details/AssessmentCalculations';
import { AssessmentFooterProps } from 'Feature/Claims/components/Details/AssessmentCalculations/AssessmentFooter/types';
import {
  useHBTFormActionsContext,
  useHBTFormContext
} from 'Feature/Claims/components/HBTFormContext';

import styles from './styles.module.scss';
import { SitecoreContentContext } from '../..';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

export const AssessmentFooter: React.FC<AssessmentFooterProps> = ({
  amountPayable,
  amountPayableLabel,
  previousClaimPaid,
  previousClaimPaidLabel,
  subTotalGrossClaim,
  subTotalGrossClaimLabel,
  previewCalculationsCallback,
  cancelCallback,
  isReadOnlyView
}: AssessmentFooterProps) => {
  const { toCurrency } = useTranslationHelpers();
  const assessmentCalculationsContext = useContext(AssessmentCalculationsContext);

  const { getValues } = useFormContext();
  const sitecoreContext = useContext(SitecoreContentContext);
  const sitecoreFields = sitecoreContext.fields;

  const { isClaimStatusPostAdjudicationStatus, isMasterUser } = useHBTFormContext();
  const { enableEditMode } = useHBTFormActionsContext();

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContextValue = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContextValue && sitecoreContextValue?.user?.moduleRoleMapping;
  const businessUser = isUserInRoles(
    ModuleMapping.claim,
    [UserRole.BusinessUser, UserRole.Clerk],
    moduleRoleMapping
  );
  const masterAndBusinessUser = businessUser || isMasterUser;

  return (
    <StickyFooter>
      <div className={styles.assessmentFooterContent}>
        <div>
          <div className={styles.assessmentFooterSubTotals}>
            <div>
              <Text field={subTotalGrossClaimLabel} />
            </div>
            <div>{toCurrency(subTotalGrossClaim)}</div>
            <div>
              <Text field={previousClaimPaidLabel} />
            </div>
            <div>{toCurrency(previousClaimPaid)}</div>
          </div>
          <div className={styles.assessmentFooterTotals}>
            <div>
              <Text field={amountPayableLabel} />
            </div>
            <div>{toCurrency(amountPayable)}</div>
          </div>
        </div>
      </div>
      <div className={styles.editingButtonsWrapper}>
        <div className={styles.editingButtonsContent}>
          {assessmentCalculationsContext.isEditing ? (
            <>
              {isClaimStatusPostAdjudicationStatus === false && (
                <Button
                  text={sitecoreFields.previewCalculationsButton}
                  ariaText={sitecoreFields.previewCalculationsButtonAriaText}
                  name="previewCalculations"
                  type="button"
                  onClick={() => {
                    console.log('previewCalculationsCallback', getValues());
                    previewCalculationsCallback(getValues());
                  }}
                />
              )}
              <Button
                text={sitecoreFields.saveButton}
                ariaText={sitecoreFields.saveButtonAriaText}
                name="saveChanges"
                type="submit"
                disabled={
                  isClaimStatusPostAdjudicationStatus === true
                    ? false
                    : assessmentCalculationsContext.previewCalculationsButtonClicked == null
                }
              />
              <Button
                text={sitecoreFields.cancelButton}
                ariaText={sitecoreFields.cancelButtonAriaText}
                name="cancelChanges"
                onClick={() => {
                  assessmentCalculationsContext.toggleIsEditing();
                  cancelCallback();
                }}
                textButton
                type="button"
              />
            </>
          ) : (
            <div className={styles.noEditWrapper}>
              <Button
                text={sitecoreFields.editButton}
                ariaText={sitecoreFields.editButtonAriaText}
                name="editAssessmentAndCalc"
                type="button"
                onClick={() => {
                  assessmentCalculationsContext.toggleIsEditing();
                  enableEditMode();
                }}
                disabled={isReadOnlyView || !masterAndBusinessUser}
              />
            </div>
          )}
        </div>
      </div>
    </StickyFooter>
  );
};
