import React, { useState, useEffect } from 'react';
import GlossaryComponentProps, {
  GlossaryCard,
  GlossaryItem
} from 'Feature/PageComponents/models/GlossaryComponentProps';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import { Link, LinkFieldValue } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import styles from './styles.module.scss';

const GlossaryComponent = (props: GlossaryComponentProps) => {
  const { data, isActive, closeCallback, startOnGlossary } = props.fields;
  const glossaryCards = data?.glossary?.glossaryItemNavigation?.glossaryCards || [];
  const sortedGlossaryCards = glossaryCards.sort((a, b) => (a.section > b.section ? 1 : -1));
  const [currentGlossary, setCurrentGlossary] = useState<GlossaryCard | undefined>(undefined);

  const findGlossary = (changeToName: string) => {
    return glossaryCards?.find((card) => card.section === changeToName);
  };

  const changeGlossary = (changeToName: string) => {
    setCurrentGlossary(findGlossary(changeToName));
  };

  useEffect(() => {
    changeGlossary(startOnGlossary || '');
  }, [startOnGlossary]);

  const mainGlossaryPage = (
    <>
      {sortedGlossaryCards.map((card: GlossaryCard, index: number) => (
        <li key={index} className={styles.glossaryCard}>
          <button
            className={styles.glossaryCategory}
            type="button"
            aria-label={card.name}
            onClick={() => {
              changeGlossary(card.section);
            }}
          >
            {card.name}
            <div className={styles.flexAuto}>
              <div className={styles.categorySelectbtn}></div>
            </div>
          </button>
        </li>
      ))}
    </>
  );

  const currentGlossaryPage = (
    <>
      {currentGlossary &&
        currentGlossary.glossaryItems.map((item: GlossaryItem, index) => {
          const linkField: LinkFieldValue = {
            text: item?.name,
            href: item?.path || ''
          };
          return (
            <li key={index} className={styles.glossaryCard}>
              <Link
                field={linkField}
                className={`${styles.glossaryItem} ${styles.hasChildren}`}
                data-toggle={'collapse'}
                data-target={`#collapse-glossary-${index}`}
              ></Link>
              <div
                id={`collapse-glossary-${index}`}
                key={`collapse-glossary-${index}`}
                className={`${styles.glossaryItemContentContainer} collapse`}
              >
                {item.content && (
                  <div
                    className={styles.contentContainer}
                    dangerouslySetInnerHTML={{ __html: item.content || '' }}
                  />
                )}
              </div>
            </li>
          );
        })}
    </>
  );

  const backButton = (
    <span className={styles.flexContent}>
      <button
        tabIndex={0}
        aria-label={i18n.t('DefaultSubmission-ModalBackButton')}
        className={styles.backbtn}
        onClick={() => {
          setCurrentGlossary(undefined);
        }}
      ></button>
    </span>
  );

  return (
    <SideDrawer
      isActive={isActive}
      handleOutsideClick={() => {
        closeCallback();
      }}
    >
      <div className={styles.scrollable}>
        <div className={`${styles.header} row`}>
          {currentGlossary === undefined ? `` : backButton}
          <h2 className={styles.glossaryTitle}>
            {currentGlossary === undefined
              ? props.fields.name?.value || 'Glossary'
              : currentGlossary.name}
          </h2>
          <span className={styles.flexAuto}>
            <button
              className={styles.closebtn}
              type="button"
              onClick={() => {
                closeCallback();
              }}
              aria-label={i18n.t('Accessibility-Close-Button')}
            ></button>
          </span>
        </div>
        <div className={styles.glossary}>
          <ul className={styles.glossaryList}>
            {currentGlossary === undefined ? mainGlossaryPage : currentGlossaryPage}
          </ul>
        </div>
      </div>
    </SideDrawer>
  );
};

export default GlossaryComponent;
