import i18n from 'i18next';

export function jsonReplaceToNull(_key: string, value: any) {
  if (value === '' || value === i18n.t('Dropdown-Option-PleaseSelect')) {
    return null;
  }
  if (value == null || value.length < 1) {
    return undefined;
  }
  const containsNonEmptyObjects = (arr: any) =>
    arr.some(
      (item: any) => typeof item === 'object' && item !== null && Object.keys(item).length > 0
    );
  if (typeof value === 'object' && Object.keys(value).length < 1) {
    return {};
  }
  if (Array.isArray(value) && value?.length > 0) {
    if (containsNonEmptyObjects(value)) {
      return value.map((v) => JSON.parse(JSON.stringify(v, jsonReplaceToNull)));
    }
  }
  if (value === 'true' || value === 'false') {
    return value[0] === 't';
  }
  return value;
}

export function jsonReplaceBoolToString(_key: string, value: any) {
  if (typeof value === 'boolean') {
    return value.toString();
  }
  return value;
}

export function jsonReplacerRemoveIdField(key: string, value: any) {
  if (key === 'id') {
    return undefined;
  }
  return value;
}
