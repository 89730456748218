import React, { memo, useCallback, useState, useMemo, useEffect, useRef } from 'react';
import { HttpResponseStatusCodes, Module, UserRole } from '@hobt/constants';
import { Status as UploadStatusCode } from '@hobt/portfolio-domain';
import { FormControl } from '@mui/material';
import { RichText, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Attachment from 'Components/Core/Attachment';
import NotificationBanner from 'Components/Core/BannerNotification';
import { Button } from 'Components/Core/Button';
import { ButtonVariant } from 'Components/Core/Button/types';
import { ActionDropdown } from 'Components/Core/Dropdown';
import { ActionItemOption } from 'Components/Core/Dropdown/types';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import { LargeFormField } from 'Components/Core/LargeFormField';
import { LargeFormFieldStatus } from 'Components/Core/LargeFormField/types';
import { Modal } from 'Components/Core/Modal';
import { MouseOrKeyboardEvent } from 'Components/Core/Modal/types';
import { ToastNotification } from 'Components/Core/Notifications/ToastNotification';
import { ToastNotificationVariant } from 'Components/Core/Notifications/ToastNotification/types';
import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import i18n from 'i18next';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { config } from '../../../../../config';
import styles from './styles.module.scss';
import {
  AttachmentFile,
  DocumentEntity,
  ExternalAllowedFileTypes,
  HbtPIRequestPageProps,
  InternalAllowedFileTypes,
  PIRequestResourcesDropdownItems,
  NotificationBannerInfo,
  NotificationBannerType,
  PortfolioToolType
} from './types';

const apiClientConfig: ApiClientConfig = { timeout: config.defaultApi.requestTimeout };
const maxSize = 125000000;
const maxFilesCount = 10;

const uploadFilesUrl: string = config.uploadFilesApi.urls.uploadFiles;
const uploadFilesTimeout: number = config.uploadFilesApi.requestTimeout;
const submitRequestUrl: string = config.piRequestApi.urls.submitRequest;
const submitRequestTimeout: number = config.piRequestApi.requestTimeout;

const HbtPIRequestPage = ({ fields }: HbtPIRequestPageProps) => {
  const authenticationContext = useAuthenticationContext();
  const SubmitBtnText = i18n.t('PI-Request-Form-SubmitButton', 'Submit');
  const RemoveAllBtnText = i18n.t('PI-Request-RemoveAllFilesButton', 'Remove all');
  const RemoveAllFileModalBtnText = i18n.t(
    'PI-Request-RemoveAllFiles-Confirmation-Modal-RemoveAllFilesButton',
    'Remove all files'
  );
  const CancelBtnText = i18n.t('PI-Request-Modal-CancelButton', 'Cancel');
  const leaveBtnText = i18n.t('PI-Request-Unsubmitted-Modal-LeaveButton', 'Leave');

  const fileSizeErrorMessage = i18n.t(
    'PI-Request-File-Upload-Max-FileSize-Exceeds-Error',
    'The file exceeds the maximum size of 125 MB.'
  );
  const multipleFileSizeErrorMessage = i18n.t(
    'PI-Request-MultiFile-Upload-Max-FileSize-Exceeds-Error',
    'The files exceed the maximum total size of 125 MB.'
  );
  const fileCountMaxError = i18n.t(
    'PI-Request-File-Upload-Max-FileCount-Error',
    'You have exceeded the number of files you can upload at one time.'
  );

  const fileTypeErrorMessage = i18n.t(
    'PI-Request-File-Upload-Incorrect-FileType-Error',
    'The file type you are trying to upload is not supported.'
  );
  const requiredErrorMessage = i18n.t(
    'PI-Request-Attachments-Field-Required-Error',
    'Please attach a file before attempting to submit.'
  );

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const isUserPortfolioAnalyst =
    (sitecoreContext?.user?.moduleRoleMapping[Module.PiRequest][0] as UserRole) ===
    UserRole.PortfolioAnalyst;
  const [toastVisible, setToastVisible] = useState<boolean>(false);

  const [isRemoveAllFilesModalActive, setIsRemoveAllFilesModalActive] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);
  const [nextPath, setNextPath] = useState<Location | null>(null);

  const [attachments, setAttachments] = useState<AttachmentFile[]>([]);
  const [completedAttachments, setCompletedAttachments] = useState<DocumentEntity[]>([]);
  const [filesRemovedIndexes, setFilesRemovedIndexes] = useState<number[]>([]);

  const [isUploadCompleted, setIsUploadCompleted] = useState(false);

  const [isCmhcHelpInfoVisible, setIsCmhcHelpInfoVisible] = useState(false);
  const [isResourcesMoreMenuOpen, setIsResourcesMoreMenuOpen] = useState(false);
  const [showNotificationBanner, setShowNotificationBanner] = useState(false);
  const [notificationBannerInfo, setNotificationBannerInfo] = useState<NotificationBannerInfo>({
    notificationBannerHeader: '',
    notificationBannerType: '' as NotificationBannerType,
    notificationBannerBody: ''
  });
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const toggleRemoveAllFilesModal = useCallback(
    (event: MouseOrKeyboardEvent) => {
      event.preventDefault();
      setIsRemoveAllFilesModalActive(!isRemoveAllFilesModalActive);
      setIsModalActive(false);
    },
    [isRemoveAllFilesModalActive, setIsRemoveAllFilesModalActive, isModalActive, setIsModalActive]
  );

  const removeAllFiles = useCallback(
    (event: MouseOrKeyboardEvent) => {
      event.preventDefault();
      setCompletedAttachments([]);
      setIsRemoveAllFilesModalActive(!isRemoveAllFilesModalActive);

      setIsModalActive(false);
    },
    [isRemoveAllFilesModalActive, setIsRemoveAllFilesModalActive, isModalActive, setIsModalActive]
  );

  const toggleNeedHelpInfoContent = useCallback(() => {
    setIsCmhcHelpInfoVisible((prev) => !prev);
  }, []);

  const handleResourceClick = (fileName: string, url: string) => {
    const downloadElement = document.createElement('a');
    downloadElement.setAttribute('href', url);
    downloadElement.setAttribute('download', fileName);
    downloadElement.click();

    // Clean up
    downloadElement.remove();
    window.URL.revokeObjectURL(url);
  };

  const resourcesDropdownOptions: ActionItemOption[] = useMemo(() => {
    return fields.piRequestResourcesDropdownItems.map(
      ({ fields }: PIRequestResourcesDropdownItems, index: number) => ({
        label: fields.itemName?.value as string,
        value: `${index}`,
        icon: <HbtIcon size={HbtIconSize.SMALL} type="icon_document" testID="-option1" />,
        onClick: () =>
          handleResourceClick(
            fields.itemName?.value as string,
            fields.itemHref.value?.href as string
          )
      })
    );
  }, [fields]);

  const history = useHistory();
  const unblockRef = useRef<Function | null>(null);

  useEffect(() => {
    const unblock = history.block((nextLocation: Location) => {
      if (completedAttachments.length > 0 && !isSubmitted) {
        setIsModalActive(true);
        setNextPath(nextLocation);
        return false;
      }
      return true;
    });

    unblockRef.current = unblock;

    return () => {
      unblock();
    };
  }, [completedAttachments, isSubmitted, history]);

  const confirmNavigation = useCallback(
    (event: MouseOrKeyboardEvent) => {
      event.preventDefault();
      if (nextPath) {
        setCompletedAttachments([]);
        setIsModalActive(false);
        if (unblockRef.current) {
          unblockRef.current();
          unblockRef.current = null;
        }
        history.push(nextPath.pathname);
      }
    },
    [nextPath, history]
  );

  const cancelNavigation = useCallback((event: MouseOrKeyboardEvent) => {
    event.preventDefault();
    setIsModalActive(false);
    setNextPath(null);
  }, []);

  const formHookMethods = useForm();

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    if (attachments.length === 0) {
      setFilesRemovedIndexes([]);
      setIsUploadCompleted(false);
    }

    if (
      attachments.length <= maxFilesCount &&
      attachments.some(
        (file) =>
          file.uploadStatusCode === UploadStatusCode.UploadFailed && file.errorMessage !== ''
      )
    ) {
      const updatedAttachments = attachments.map((file) => ({
        ...file,
        uploadStatusCode: UploadStatusCode.Cancelled,
        errorMessage: ''
      }));

      setAttachments(updatedAttachments);

      (async () => {
        await uploadFilesToStorage(updatedAttachments);
      })();
    }
  }, [attachments.length, completedAttachments.length]);

  const removeFile = async (index: number) => {
    const arr = completedAttachments.filter(function (item, ind) {
      return ind !== index;
    });
    setCompletedAttachments([...arr]);
  };

  const stopUploading = async (index: number, uploadStatusCode: UploadStatusCode) => {
    if (uploadStatusCode === UploadStatusCode.UploadFailed && isUploadCompleted) {
      const arr = attachments.filter(function (item, ind) {
        return ind !== index;
      });
      setAttachments([...arr]);
    } else {
      let arr = filesRemovedIndexes;
      arr.push(index);
      setFilesRemovedIndexes(arr);
    }
  };

  const uploadFilesToStorage = async (files: AttachmentFile[]) => {
    for (let i = 0; i < files.length; i++) {
      const duplicateFiles = completedAttachments.filter(
        (attachment: DocumentEntity) => attachment.fileName === files[i].attachment.name
      );
      // check if there are duplicate files
      if (duplicateFiles.length > 0) {
        setToastVisible(true);
        return;
      }
    }
    let tempFiles = files;
    setAttachments([]);
    setIsUploadCompleted(false);

    const attachmentsSum = tempFiles.reduce((n, { fileSize }) => n + fileSize, 0);
    const completedAttachmentsSum = completedAttachments.reduce(
      (n, { fileSize }) => n + fileSize,
      0
    );
    const filesSizeSum = attachmentsSum + completedAttachmentsSum;
    const filesCount = tempFiles.length + completedAttachments.length;

    if (filesSizeSum > maxSize) {
      for (let i = 0; i < tempFiles.length; i++) {
        if (tempFiles.length >= 1) {
          tempFiles[i].uploadStatusCode = UploadStatusCode.UploadFailed;
          tempFiles[i].errorMessage =
            tempFiles.length + completedAttachments.length > 1
              ? multipleFileSizeErrorMessage
              : fileSizeErrorMessage;
        }
      }
      setAttachments([...tempFiles]);
      setIsUploadCompleted(true);

      return;
    }

    if (filesCount > maxFilesCount) {
      for (let i = 0; i < tempFiles.length; i++) {
        if (tempFiles.length >= 1) {
          tempFiles[i].uploadStatusCode = UploadStatusCode.UploadFailed;
          tempFiles[i].errorMessage = fileCountMaxError;
        }
      }
      setAttachments([...tempFiles]);
      setIsUploadCompleted(true);

      return;
    }

    setAttachments([...tempFiles]);

    let isMockUploadDone = false;
    const allowedFileTypes = isUserPortfolioAnalyst
      ? InternalAllowedFileTypes
      : ExternalAllowedFileTypes;

    for (let j = 0; j < 3; j++) {
      if (filesRemovedIndexes.length === tempFiles.length) {
        setAttachments([]);
        break;
      }

      for (let i = 0; i < tempFiles.length; i++) {
        if (
          allowedFileTypes !== undefined &&
          allowedFileTypes.length > 0 &&
          !allowedFileTypes.includes(
            tempFiles[i].attachment.name
              .slice((Math.max(0, tempFiles[i].attachment.name.lastIndexOf('.')) || Infinity) + 1)
              .toLowerCase()
          )
        ) {
          tempFiles[i].uploadStatusCode = UploadStatusCode.UploadFailed;
          tempFiles[i].errorMessage = fileTypeErrorMessage;
        } else if (
          tempFiles.length >= 1 &&
          tempFiles[i].uploadStatusCode !== UploadStatusCode.UploadFailed
        ) {
          tempFiles[i].percentage += 30;
          tempFiles[i].timeLeft -= 3;
        }
      }
      // eslint-disable-next-line no-await-in-loop
      await delay(3000);
      setAttachments([...tempFiles]);

      if (j === 2) {
        isMockUploadDone = true;
      }
    }

    if (isMockUploadDone) {
      const formData = new FormData();
      tempFiles = tempFiles.filter(
        (attachment: AttachmentFile, index: number) => !filesRemovedIndexes.includes(index)
      );

      let attachmentsError: AttachmentFile[] = [];
      tempFiles.forEach((attachmentWrapper: AttachmentFile) => {
        if (attachmentWrapper.uploadStatusCode !== UploadStatusCode.UploadFailed) {
          formData.append('uploadedFileObject', attachmentWrapper.attachment!);
        } else {
          attachmentsError.push(attachmentWrapper);
        }
      });
      const formValues = formHookMethods.getValues();

      formData.append('portfolioToolType', PortfolioToolType.PiRequest.toString());
      formData.append(
        'description',
        formValues.piRequestFileDetailCommentField ? formValues.piRequestFileDetailCommentField : ''
      );

      if (formData.has('uploadedFileObject')) {
        ApiClient(authenticationContext, apiClientConfig)
          .postWithHeaderAuth(uploadFilesUrl, formData, false, { timeout: uploadFilesTimeout })
          .then((response: any) => {
            if (response.data.statusCode === HttpResponseStatusCodes.OK) {
              setIsUploadCompleted(true);
              setCompletedAttachments([...completedAttachments, ...response.data.data]);
            }
            if (attachmentsError.length > 0) {
              setAttachments(attachmentsError);
              setIsUploadCompleted(true);
            } else {
              setAttachments([]);
            }
          })
          .catch((error: any) => {
            const errorObject = error.response.data.error;
            setAttachments([]);
            setShowNotificationBanner(true);
            setFilesRemovedIndexes([]);
            if (errorObject && errorObject.errorCode === 'HBT_ERR_6003') {
              return setNotificationBannerInfo({
                notificationBannerHeader: i18n.t('PI-Request-File-Upload-Incorrect-FileType-Error'),
                notificationBannerBody: '',
                notificationBannerType: NotificationBannerType.ERROR
              });
            }
            return setNotificationBannerInfo({
              notificationBannerHeader: i18n.t('Attachment-Files-Upload-Unavailable'),
              notificationBannerBody: '',
              notificationBannerType: NotificationBannerType.ERROR
            });
          });
      } else if (attachmentsError.length > 0) {
        setAttachments(attachmentsError);
        setIsUploadCompleted(true);
      } else {
        setAttachments([]);
      }
    }
  };
  const onSubmit = async (data: any) => {
    if (completedAttachments.length > 0 && attachments.length === 0) {
      const formValues = formHookMethods.getValues();
      let attachmentIds: string[] = [];
      completedAttachments.forEach((doc) => attachmentIds.push(doc.documentId));

      const data = {
        description: formValues.piRequestFileDetailCommentField,
        attachmentIds
      };

      setIsSubmitting(true);
      ApiClient(authenticationContext, apiClientConfig)
        .postWithAuth(submitRequestUrl, data, { timeout: submitRequestTimeout })
        .then((response: any) => {
          setNotificationBannerInfo({
            notificationBannerHeader:
              attachmentIds.length === 1
                ? i18n.t('PI-Request-File-Submitted-Success-NotificationBanner-Title')
                : i18n.t('PI-Request-MultiFile-Submitted-Success-NotificationBanner-Title'),
            notificationBannerBody: isUserPortfolioAnalyst
              ? ''
              : i18n.t('PI-Request-File-Submitted-Success-NotificationBanner-Body'),
            notificationBannerType: NotificationBannerType.SUCCESS
          });
          setShowNotificationBanner(true);
          setIsSubmitted(true);
          setIsSubmitting(false);
          setCompletedAttachments([]);
          formHookMethods.reset();
        })
        .catch((error: any) => {
          setIsSubmitting(false);
          setShowNotificationBanner(true);
          setNotificationBannerInfo({
            notificationBannerHeader: i18n.t(
              'PI-Request-File-Submission-Error-NotificationBanner-Title'
            ),
            notificationBannerBody: i18n.t(
              'PI-Request-File-Submission-Error-NotificationBanner-Body'
            ),
            notificationBannerType: NotificationBannerType.ERROR
          });
        });
    }
  };

  return (
    <>
      {showNotificationBanner && (
        <div className={styles.piRequestNotificationBannerContainer}>
          <NotificationBanner
            showNotificationBanner={showNotificationBanner}
            onCloseNotificationBanner={() => setShowNotificationBanner(!showNotificationBanner)}
            headerText={{ value: notificationBannerInfo.notificationBannerHeader }}
            message={{ value: notificationBannerInfo.notificationBannerBody }}
            variant={notificationBannerInfo.notificationBannerType}
          />
        </div>
      )}
      <ToastNotification
        actionable
        icon="icon_check_circle"
        open={toastVisible}
        header={{
          value: i18n.t('PI-Request-File-Submission-Duplicated-Header', 'File Already Uploaded')
        }}
        variant={ToastNotificationVariant.INFO}
        onClose={() => {
          setToastVisible(false);
        }}
        subHeader={{
          value: i18n.t(
            'PI-Request-File-Submission-Duplicated-Sub-Header',
            'Please remove the existing file or rename it, then try again'
          )
        }}
      />
      <div className={styles.piRequestMainBody}>
        <div className={styles.piRequestMainContainer}>
          <div className={styles.piRequestMainContent}>
            <div className={styles.piRequestHeaderContainer}>
              <div className={styles.piRequestHeaderContent}>
                <div className={styles.piRequestHeaderTitleInfoWrapper}>
                  <h1 className={styles.piRequestTitle} id="piRequestTitle" tabIndex={0}>
                    <Text field={fields.piRequestTitle} />
                  </h1>
                </div>
              </div>
              <div className={styles.piRequestCmhcSupportContainer}>
                <Button
                  name={fields.piRequestCmhcSupportBtn.value ?? 'CMHC support?'}
                  text={fields.piRequestCmhcSupportBtn}
                  ariaText={{ value: `${fields.piRequestCmhcSupportBtn.value}` }}
                  variant={ButtonVariant.TERTIARY}
                  onClick={toggleNeedHelpInfoContent}
                />
                {isCmhcHelpInfoVisible && (
                  <ul className={styles.piRequestCmhcSupportListContainer}>
                    <li>
                      <span className={styles.piRequestUserGuideContent}>
                        <Text field={fields.piRequestUserGuideAccessLabel} />
                        &nbsp;
                        <a
                          href={
                            fields.piRequestUserGuideLink?.[0]?.fields?.guideHref?.value?.href ||
                            '#'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <RichText field={fields.piRequestUserGuideLink[0].fields.guideName} />
                          <HbtIcon
                            className={styles.piRequestUserGuideExternalIconLink}
                            size={HbtIconSize.SMALL}
                            type="icon_external_link"
                          />
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className={styles.piRequestCmhcHelpInfoContent}>
                        <RichText field={fields.piRequestCmhcSupportInfoContent} />
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div className={styles.resourcesDropdownContainer}>
              <ActionDropdown
                className={styles.piRequestResourcesActionDropdown}
                isOptionALink={true}
                open={isResourcesMoreMenuOpen}
                onToggle={setIsResourcesMoreMenuOpen}
                options={resourcesDropdownOptions}
                trigger={
                  <Button
                    variant={ButtonVariant.TERTIARY}
                    text={fields.piRequestResourcesDropdownBtnLabel}
                    ariaText={fields.piRequestResourcesDropdownBtnLabel}
                    name={fields.piRequestResourcesDropdownBtnLabel.value ?? 'Resources'}
                    leadingIcon={() => (
                      <HbtIcon size={HbtIconSize.SMALL} type="icon_more_horizontal" />
                    )}
                  />
                }
              />
            </div>
          </div>
          <div className={styles.piRequestContainer}>
            <FormProvider {...formHookMethods}>
              <form onSubmit={formHookMethods.handleSubmit(onSubmit)}>
                <div className={styles.piRequestUploadViewMain}>
                  <div className={styles.piRequestInputFieldContainer}>
                    <h2 className={styles.piRequestStepTitle}>
                      <Text field={fields.piRequestInputFieldLabel as FieldValue} />
                    </h2>
                    <Controller
                      control={formHookMethods.control}
                      name="piRequestFileDetailCommentField"
                      defaultValue=""
                      rules={{
                        maxLength: {
                          value: 1000,
                          message: i18n.t(
                            'PI-Request-Provide-Details-Field-MaxCharCount-Error',
                            'You have exceeded the maximum number of characters.'
                          )
                        }
                      }}
                      render={({ field: { ref, ...field }, fieldState: { error } }) => (
                        <FormControl error={!!error} fullWidth>
                          <LargeFormField
                            {...field}
                            id="file-instruction-input-field"
                            value={formHookMethods.getValues('piRequestFileDetailCommentField')}
                            ariaText={
                              fields.piRequestInputFieldLabel.value ??
                              'Step 1: Special Instructions (Optional)'
                            }
                            maxLength={1000}
                            status={error ? LargeFormFieldStatus.ERROR : undefined}
                            helperText={error?.message}
                          />
                        </FormControl>
                      )}
                    />
                  </div>
                  <div className={styles.fileUploadContainer}>
                    <Controller
                      control={formHookMethods.control}
                      name="piRequestAttachments"
                      defaultValue={[]}
                      rules={{ required: 'Required' }}
                      render={({ field: { ref, ...field }, fieldState: { error } }) => (
                        <FormControl error={!!error} fullWidth>
                          <Attachment
                            attachmentContent={{
                              attachmentsTitle: fields.piRequestAttachmentsTitle as FieldValues,
                              attachmentsTitleInfo:
                                fields.piRequestAttachmentsTitleInfo as FieldValues,
                              supportedFileFormatText: isUserPortfolioAnalyst
                                ? (fields.piRequestInternalAttachmentSupportedFileFormatText as FieldValues)
                                : (fields.piRequestExternalAttachmentSupportedFileFormatText as FieldValues),
                              attachmentInputBrowserLabelText:
                                fields.piRequestAttachmentInputBrowserLabelText as FieldValues,
                              attachmentInputBrowserLabel:
                                fields.piRequestAttachmentInputBrowserLabel as FieldValues,
                              attachmentRequiredErrorMessage:
                                fields.piRequestAttachmentRequiredErrorMessage as FieldValues,
                              noAttachmentTableLabel:
                                fields.piRequestNoAttachmentTableMessage as FieldValues
                            }}
                            isRequired={false}
                            fileUploadUnavailable={false}
                            disable={!!attachments.length}
                            uploadErrorMessage={
                              completedAttachments.length > 0
                                ? ''
                                : error?.message
                                ? requiredErrorMessage
                                : ''
                            }
                            attachments={attachments}
                            completedAttachments={completedAttachments}
                            filesRemovedIndexes={filesRemovedIndexes}
                            stopUploading={(index, uploadStatusCode) =>
                              stopUploading(index, uploadStatusCode)
                            }
                            deleteFile={(index) => removeFile(index)}
                            uploadFiles={(data) => uploadFilesToStorage(data)}
                          />
                        </FormControl>
                      )}
                    />
                    <Modal
                      id="pi-request-unsubmitted-files-confirmation-modal"
                      isActive={isModalActive}
                      title={
                        {
                          value: i18n.t('PI-Request-Unsubmitted-Modal-Title', 'Unsubmitted Files')
                        } as FieldValue
                      }
                      onDismissalCallback={cancelNavigation}
                      bodyContent={
                        <span>
                          <Text
                            field={
                              {
                                value: i18n.t(
                                  'PI-Request-Unsubmitted-Modal-Body',
                                  'You have files in the queue. If you navigate away, your files will be deleted. Do you want to leave without submitting your files?'
                                )
                              } as FieldValue
                            }
                          />
                        </span>
                      }
                      modalButtons={{
                        primaryButton: {
                          name: CancelBtnText,
                          text: { value: CancelBtnText } as FieldValue,
                          ariaText: { value: CancelBtnText } as FieldValue,
                          onClick: cancelNavigation,
                          onKeyDown: cancelNavigation
                        },
                        secondaryButton: {
                          name: leaveBtnText,
                          text: { value: leaveBtnText } as FieldValue,
                          ariaText: { value: leaveBtnText } as FieldValue,
                          onClick: confirmNavigation,
                          onKeyDown: confirmNavigation
                        }
                      }}
                      shouldCloseOnBackgroundClick={true}
                    />
                  </div>
                  <div className={styles.piRequestActionBtnsContainer}>
                    {completedAttachments.length > 0 && (
                      <div className={styles.piRequestRemoveFilesBtnContainer}>
                        <Button
                          className={styles.removeAllFilesBtn}
                          variant={ButtonVariant.TERTIARY}
                          name={RemoveAllBtnText}
                          text={{ value: RemoveAllBtnText } as FieldValue}
                          ariaText={{ value: RemoveAllBtnText } as FieldValue}
                          onClick={toggleRemoveAllFilesModal}
                          onKeyDown={toggleRemoveAllFilesModal}
                        />
                        <Modal
                          id="pi-request-remove-all-files-confirmation-modal"
                          isActive={isRemoveAllFilesModalActive}
                          title={
                            {
                              value: i18n.t(
                                'PI-Request-RemoveAllFiles-Confirmation-Modal-Title',
                                'Remove All Files Confirmation'
                              )
                            } as FieldValue
                          }
                          onDismissalCallback={toggleRemoveAllFilesModal}
                          bodyContent={
                            <span>
                              <Text
                                field={
                                  {
                                    value: i18n.t(
                                      'PI-Request-RemoveAllFiles-Confirmation-Modal-Body',
                                      'Do you want to remove all the files? This action cannot be undone.'
                                    )
                                  } as FieldValue
                                }
                              />
                            </span>
                          }
                          modalButtons={{
                            primaryButton: {
                              name: CancelBtnText,
                              text: { value: CancelBtnText } as FieldValue,
                              ariaText: { value: CancelBtnText } as FieldValue,
                              onClick: toggleRemoveAllFilesModal,
                              onKeyDown: toggleRemoveAllFilesModal
                            },
                            secondaryButton: {
                              name: RemoveAllFileModalBtnText,
                              text: { value: RemoveAllFileModalBtnText } as FieldValue,
                              ariaText: { value: RemoveAllFileModalBtnText } as FieldValue,
                              onClick: removeAllFiles,
                              onKeyDown: removeAllFiles
                            }
                          }}
                          shouldCloseOnBackgroundClick={true}
                        />
                      </div>
                    )}
                    <div className={styles.piRequestUploadViewSubmitBtnContainer}>
                      <Button
                        type="submit"
                        name={SubmitBtnText}
                        text={{ value: SubmitBtnText } as FieldValue}
                        ariaText={{ value: SubmitBtnText } as FieldValue}
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        variant={ButtonVariant.PRIMARY}
                        onClick={onSubmit}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(HbtPIRequestPage);
