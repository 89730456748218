import React, { memo } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { HbtIcon } from 'Components/Core/HbtIcon';
import Error from 'Components/Core/HbtIcon/icons/Error.svg';
import useEnterOrSpaceKey from 'Components/Hooks/UseEnterOrSpaceKey';
import i18n from 'i18next';
import Dropzone from 'react-dropzone';
import styles from './styles.module.scss';
import { UploadAttachmentWindowProps } from './types';

/**
 * @param isRequired - boolean to check if the attachment upload is required
 * @param uploadErrorMessage - string that contains an error message
 * @param fileUploadUnavailable - boolean to check if attachment upload is available
 * @param uploadFiles - function to upload files
 */

const UploadAttachmentWindow: React.FC<UploadAttachmentWindowProps> = ({
  attachmentContent,
  isRequired,
  uploadErrorMessage,
  fileUploadUnavailable,
  disable,
  uploadFiles
}) => {
  const {
    attachmentsTitle,
    attachmentsTitleInfo,
    supportedFileFormatText,
    attachmentInputBrowserLabel,
    attachmentInputBrowserLabelText,
    attachmentRequiredErrorMessage
  } = attachmentContent ?? {};

  async function handleAttachmentDrop(rawAttachments: File[]) {
    if (rawAttachments.length === 1) {
      handleAttachmentUpload(rawAttachments);
    } else {
      handleAttachmentUpload(rawAttachments);
    }
  }

  async function handleAttachmentUpload(rawAttachments: File[]) {
    const newAttachments = rawAttachments.map((attachment: File) => {
      return {
        attachment,
        fileName: attachment.name,
        fileSize: attachment.size,
        fileType: attachment.type,
        uploadStatusCode: 2,
        percentage: 0,
        timeLeft: 10,
        errorMessage: ''
      };
    });
    uploadFiles(newAttachments);
  }

  function handleAttachmentInput(event: any) {
    const emptyFile = new File([''], 'empty');
    if (event.target.files != null) {
      handleAttachmentUpload(
        Object.keys(event.target.files).map((key: any) =>
          event.target.files ? event.target.files[key] : emptyFile
        )
      );
    }
    event.target.value = null;
  }

  return (
    <div className={styles.uploadWindow}>
      <div className={styles.uploadTitleWrapper}>
        <h4 className={styles.uploadTitle}>
          <Text
            field={
              attachmentsTitle?.value && attachmentsTitle.value.length > 0
                ? attachmentsTitle
                : ({
                    value: i18n.t('Attachment-Upload-Files', 'Upload Files')
                  } as FieldValue)
            }
          />
        </h4>
        <span className={styles.uploadDescription}>
          <Text
            field={
              attachmentsTitleInfo?.value && attachmentsTitleInfo.value.length > 0
                ? attachmentsTitleInfo
                : ({
                    value: i18n.t(
                      'Attachment-Upload-Files-Description',
                      'Files can be up to 50 MB. Supported file types are .xls, .csv, .pdf, .docx, and .jpgs.'
                    )
                  } as FieldValue)
            }
          />
        </span>
      </div>
      {fileUploadUnavailable ? (
        <div className={styles.uploadContent}>
          <div>
            <HbtIcon
              className={styles.uploadIcon}
              type="icon_cloud_upload_grey"
              ariaText={{ value: i18n.t('Attachment-Upload-Icon') } as FieldValue}
            />
          </div>
          <div className={styles.uploadDetails}>
            <span>
              {i18n.t('Attachment-Files-Upload-Unavailable') ?? 'File upload unavailable.'}
            </span>
            <br />
            <span>
              (
              {i18n.t('Attachment-Files-Upload-Unavailable-instruction') ??
                'include instructional copy for solution'}
              )
            </span>
          </div>
        </div>
      ) : (
        <Dropzone onDrop={handleAttachmentDrop}>
          {({ getRootProps, getInputProps }) => (
            <>
              <div
                {...getRootProps({
                  onClick: (e) => {
                    e.stopPropagation();
                  }
                })}
                className={
                  isRequired || uploadErrorMessage.length !== 0
                    ? styles.uploadBoxError
                    : styles.uploadBox
                }
              >
                <div className={styles.uploadContent} data-testid="upload-content">
                  <div className={styles.imgContainer}>
                    <HbtIcon
                      className={styles.uploadIcon}
                      type="icon_cloud_upload"
                      ariaText={{ value: i18n.t('Attachment-Upload-Files') } as FieldValue}
                    />
                  </div>
                  <div>
                    <span className={styles.uploadDetails}>
                      <Text
                        field={
                          attachmentInputBrowserLabelText?.value &&
                          attachmentInputBrowserLabelText.value.length > 0
                            ? attachmentInputBrowserLabelText
                            : ({
                                value: i18n.t('Attachment-Drop-Files', 'Drop files to attach or')
                              } as FieldValue)
                        }
                      />
                      &nbsp;
                    </span>
                    <label className={styles.browser}>
                      <input
                        {...getInputProps()}
                        type="file"
                        id="uploadFiles"
                        aria-label="Upload Files Label"
                        aria-invalid={isRequired ? 'true' : 'false'}
                        aria-errormessage={
                          isRequired ? 'error-attachment-required' : 'error-attachment'
                        }
                        name="filename"
                        multiple={true}
                        onChange={(e) => handleAttachmentInput(e)}
                        onKeyDown={useEnterOrSpaceKey(() => handleAttachmentDrop)}
                        disabled={disable}
                      />
                      <Text
                        field={
                          attachmentInputBrowserLabel?.value &&
                          attachmentInputBrowserLabel.value.length > 0
                            ? attachmentInputBrowserLabel
                            : ({
                                value: i18n.t('Attachment-Choose-Files', 'choose files')
                              } as FieldValue)
                        }
                      />
                    </label>
                    <br />
                    <span className={styles.supportedFileFormatText}>
                      <Text
                        field={
                          supportedFileFormatText?.value && supportedFileFormatText.value.length > 0
                            ? supportedFileFormatText
                            : ({
                                value:
                                  '(Supported formats: .xlsx, .xls, .xlsm, .txt, .pdf, .csv, .zip)'
                              } as FieldValue)
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>

              {uploadErrorMessage.length !== 0 && !isRequired && (
                <div className={styles.errorMessage} id="error-attachment" role="alert">
                  <img className={styles.errorIcon} src={Error} alt={'error'} />
                  <div>{uploadErrorMessage}</div>
                </div>
              )}
              {isRequired && (
                <div className={styles.errorMessage} id="error-attachment-required" role="alert">
                  <img className={styles.errorIcon} src={Error} alt={'error'} />
                  <Text
                    field={
                      attachmentRequiredErrorMessage?.value &&
                      attachmentRequiredErrorMessage.value.length > 0
                        ? attachmentRequiredErrorMessage
                        : ({
                            value: i18n.t('Attachment-Required', 'Required')
                          } as FieldValue)
                    }
                  />
                </div>
              )}
            </>
          )}
        </Dropzone>
      )}
    </div>
  );
};

export default memo(UploadAttachmentWindow);
