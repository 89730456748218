import React from 'react';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import { AccordionContainerProps } from './AccordionContainer.types';

import styles from './styles.module.scss';

export const AccordionContainer: React.FC<AccordionContainerProps> = ({
  collapsible = true,
  accordionId,
  title,
  logo,
  children
}) => {
  return (
    <div id={`${accordionId}_parent`}>
      <a
        className={`card-header card__header ${collapsible ? 'card__header--collapsible' : ''}`}
        id={`${accordionId}AccordionHeader`}
        data-toggle={collapsible ? 'collapse' : ''}
        data-target={`#${accordionId}`}
        aria-expanded="true"
        role="button"
        aria-controls={accordionId}
        href={`#${accordionId}`}
      >
        {logo && (
          <>
            <Image field={logo} className="card__header-image" alt="" />
            &nbsp;&nbsp;
          </>
        )}
        {title && (
          <h2
            className={`card__header-title ${styles.dashboardTitle}`}
            id={`${accordionId}_headerTitle`}
          >
            <span className="sr-only">{i18n.t('ScreenReader-CardTitle-Group')}</span>
            <Text field={title} />
          </h2>
        )}
      </a>
      <div
        id={accordionId}
        className="card-body card__body collapse show"
        data-parent={`#${accordionId}_parent`}
      >
        {children}
      </div>
    </div>
  );
};
