import React, { useState, useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { FormDropdownProps, FormDropdownOption } from './FormInputDropdownText.types';
import GetErrorMessage from '../ErrorMessage';
import _ from 'lodash-es';

const FormInputDropdownText: React.FC<FormDropdownProps> = (props) => {
  const [selection, setSelection] = useState(props.value);
  const errors = _.get(props.errors, `${props.name}`);

  useEffect(() => {
    setSelection(props.value);
  }, [props.resetTrigger]);

  function handleChange(event: any) {
    // eslint-disable-next-line no-unused-expressions
    props.handleChange && props.handleChange(event);
    // eslint-disable-next-line no-unused-expressions
    props.resetTrigger !== undefined && setSelection(event.target.value);
  }

  return (
    <div className="form__element form__element--2-column">
      <label
        htmlFor={`${props.id}DropdownInput`}
        id={`${props.id}Label`}
        className={_.get(props.errors, `${props.name}`) && 'form-error__label'}
      >
        <Text field={props.title} />
      </label>
      {props.description && <small>{props.description.value}</small>}
      <div className="select-container">
        <select
          className={`
            dropdown-select col-12 select-element
            ${errors && 'form-error__field'}
          `}
          tabIndex={0}
          id={`${props.id}DropdownInput`}
          data-testid={`${props.id}`}
          name={props.name}
          {...((props.handleChange || props.resetTrigger) && { onChange: handleChange })}
          defaultValue={selection as string}
          {...(props.register &&
            props.register(`${props.name}`, {
              required: props.isRequired,
              onChange: handleChange
            }))}
          {...(props.resetTrigger !== undefined && {
            value: selection as string
          })}
          aria-labelledby={`${props.id}Label`}
          aria-describedby={`${props.id}ErrorDesc`}
          disabled={props.isDisabled || false}
        >
          {props.options &&
            props.options.map((option: FormDropdownOption) => (
              // eslint-disable-next-line
              <option
                value={option.value}
                key={option.label}
                data-testid={`${props.id}Option`}
                {...(option.selected && { selected: option.selected })}
              >
                {option.label}
              </option>
            ))}
        </select>
      </div>
      {!props.hideError && (
        <div className="form__fieldNotificationHolder">
          {errors && (
            <span
              className="form-error__sub-text"
              aria-live="assertive"
              id={`${props.id}ErrorDesc`}
            >
              {GetErrorMessage(errors.type as string, errors.message as string)}
              <input
                type="hidden"
                className="thisFieldHasErrors"
                value={props.cardName && props.cardName}
              />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default FormInputDropdownText;
