export interface Status {
  textKey: string;
  class: string;
}

export enum LanguageTypeCode {
  English = 1,
  French = 2
}

export enum LanguageSwitchTypeCode {
  English = 'en',
  French = 'fr'
}

export enum SubmissionType {
  SUBMIT = 0,
  ERROR = 1,
  OTHER = 2
}

export enum RowStatus {
  ACTIVE = 1,
  INACTIVE = 2
}

export enum TabType {
  Reported = 1,
  Unreported = 2
}

export const TabMapping = new Map<TabType, string>([
  [TabType.Reported, 'reported'],
  [TabType.Unreported, 'notReported']
]);
