/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { PropsWithChildren } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import FocusLock from 'react-focus-lock';

import { SideDrawerProps } from 'Components/PageComponents/SideDrawer/types';

import styles from './styles.module.scss';

export const SideDrawer: React.FC<SideDrawerProps> = React.memo(
  ({
    isActive,
    hideBackground,
    isFocusTrapPaused,
    classes,
    children,
    handleOutsideClick
  }: PropsWithChildren<SideDrawerProps>) => (
    <CSSTransition
      in={isActive}
      classNames={{
        enterActive: styles.sideDrawerContainerEnterActive,
        exitActive: styles.sideDrawerContainerExitActive
      }}
      timeout={450}
      unmountOnExit={true}
      mountOnEnter={true}
    >
      <FocusLock disabled={isFocusTrapPaused}>
        <div className={`${styles.sideDrawerContainer}`}>
          {!hideBackground && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              className={styles.background}
              onClick={() => {
                if (handleOutsideClick != null) {
                  handleOutsideClick();
                }
              }}
            />
          )}
          <aside className={`${styles.sideDrawer} ${classes != null ? classes : ''}`.trim()}>
            {children}
          </aside>
        </div>
      </FocusLock>
    </CSSTransition>
  )
);
