import { useState, useEffect } from 'react';
import {
  FlagRow,
  PagerData,
  ItmStatusUpdateRequestPayload
} from 'Feature/PreClaims/components/PreClaimsItmFlags/types';
import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { AxiosResponse } from 'axios';
import { config } from '../../../../config';

export const usePreclaimsFlags = () => {
  const [itmFlags, setItmFlags] = useState<FlagRow[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numFlagsPerPage, setNumFlagsPerPage] = useState<number>(10);
  const [showSideDrawer, setShowSideDrawer] = useState<boolean>(false);
  const [totalRecordCount, setTotalRecordCount] = useState<number | null>(null);
  const [pagerData, setPagerData] = useState<PagerData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [showPageModal, setShowPageModal] = useState<boolean>(false);
  const [clickedEle, setClickedEle] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);

  // API Client Config
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  const authContext = useAuthenticationContext();
  const { getWithAuth } = ApiClient(authContext, apiClientConfig);
  const { putWithAuth } = ApiClient(authContext, apiClientConfig);

  const composeInventoryUri = () => {
    const baseUri = config.preClaimItmApi.urls.inventory;
    const pageParams = `?offset=${numFlagsPerPage * (currentPage - 1)}&limit=${numFlagsPerPage}`;

    return baseUri + pageParams;
  };

  const getTableData = () => {
    getWithAuth(composeInventoryUri())
      .then((res: AxiosResponse) => {
        const responseData = res.data;
        setItmFlags(responseData.data);
        setTotalRecordCount(responseData.page.totalRecordCount);
      })
      .catch(() => {
        setShowErrorToast(true);
      });
  };

  const onCancelForm = () => {
    setShowSideDrawer(false);
  };

  const onSuccessSubmit = () => {
    setShowSideDrawer(false);
    getTableData();
    setShowSuccessToast(true);
  };

  const secondaryCallback = () => {
    setClickedEle(null as any);
    setShowPageModal(false);
  };

  const onErrorSubmit = () => {
    setShowSideDrawer(false);
    setShowErrorToast(true);
  };

  const onSuccessToastDismiss = () => {
    setShowSuccessToast(false);
  };

  const onErrorToastDismiss = () => {
    setShowErrorToast(false);
  };

  const onAddItemFlagButtonClick = () => {
    setIsEdit(false);
    setShowSideDrawer(true);
    setShowSuccessToast(false);
    setShowErrorToast(false);
  };

  const onPagerChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const onItemsPerPageChange = (itmsPerPage: number | string) => {
    if (totalRecordCount && (itmsPerPage as number) >= totalRecordCount) {
      setCurrentPage(1);
    }
    setNumFlagsPerPage(itmsPerPage as number);
  };

  useEffect(() => {
    getTableData();
  }, [numFlagsPerPage, currentPage]);

  useEffect(() => {
    if (totalRecordCount) {
      const baseOffset = numFlagsPerPage * (currentPage - 1);

      // TODO: Use sitecore content for 'of'
      setPagerData({
        xOfYText: `${Number(baseOffset + 1)} - ${
          baseOffset + numFlagsPerPage >= totalRecordCount
            ? totalRecordCount
            : Number(baseOffset + numFlagsPerPage)
        } of ${totalRecordCount}`,
        totalPages: Math.ceil(totalRecordCount / numFlagsPerPage)
      });
    }
  }, [totalRecordCount, numFlagsPerPage, currentPage]);

  // Updating ITM Toggle Status
  const composeITMStatusUpdateUri = () => {
    const statusBaseUri = config.preClaimItmApi.urls.updateITMStatus;
    return statusBaseUri;
  };

  const updateITMStatus = (
    data: ItmStatusUpdateRequestPayload,
    onSuccessCallback: Function,
    onFailureCallBack: Function
  ) => {
    setIsLoading(true);
    putWithAuth(composeITMStatusUpdateUri(), data)
      .then((_res: AxiosResponse) => {
        setIsLoading(false);
        onSuccessCallback();
      })
      .catch(() => {
        setShowErrorToast(true);
        setIsLoading(false);
        onFailureCallBack();
      });
  };

  const onErrorITMUpdate = () => {};

  return {
    itmFlags,
    currentPage,
    showSideDrawer,
    pagerData,
    isLoading,
    clickedEle,
    showPageModal,
    showErrorToast,
    showSuccessToast,
    isEdit,
    setIsEdit,
    setShowSuccessToast,
    onAddItemFlagButtonClick,
    setShowPageModal,
    setShowSideDrawer,
    setClickedEle,
    onCancelForm,
    onSuccessSubmit,
    secondaryCallback,
    onErrorSubmit,
    onSuccessToastDismiss,
    onErrorToastDismiss,
    setShowErrorToast,
    onPagerChange,
    onItemsPerPageChange,
    onErrorITMUpdate,
    updateITMStatus
  };
};
