import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFeature } from 'flagged';
import i18n from 'i18next';

import { UserType } from '@hobt/constants';

import ConditionalRender from 'Components/Common/ConditionalRender';
import { FormCheckbox } from 'Components/Inputs/FormCheckbox';
import FormDatepicker from 'Components/Inputs/FormDatepicker';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormNumber from 'Components/Inputs/FormNumber';
import FormYesNoRadios from 'Components/Inputs/FormYesNoRadios';
import ClaimsIndicatorDetailsSubmissionProps from 'Feature/Claims/components/Cards/IndicatorDetailsSubmission/types';
import FormCheckboxListWrapper from 'Components/Inputs/FormCheckboxListWrapper';

import {
  useHBTFormActionsContext,
  useHBTFormContext
} from 'Feature/Claims/components/HBTFormContext';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { sitecoreCardNameToValidationFieldKeyMapCreate } from 'Feature/Claims/components/Cards/DisplayNotificationCard';
import { LanguageSwitchTypeCode } from '../Cards/ClaimsLenderDetails/types';

import { useIndicatorDetailsFields } from './useIndicatorDetailsFields';

import styles from './styles.module.scss';

const ClaimsIndicatorDetailsFields = ({ fields }: ClaimsIndicatorDetailsSubmissionProps) => {
  const { convertStringFlags } = useIndicatorDetailsFields();

  const { watch, getValues, setValue } = useFormContext();
  const { addCompleteCard } = useHBTFormActionsContext();

  const formContext = useFormContext();
  const formValid = formContext.formState.isValid;
  useEffect(() => {
    addCompleteCard(sitecoreCardNameToValidationFieldKeyMapCreate.claimsIndicatorDetails);
  }, [formValid]);

  const { claimData, isInEditMode, isMasterUserEditingPostAdjudicationClaim, isFieldDisabled } =
    useHBTFormContext();
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  // TODO: Default values are overriden on rerenders. so added this to reflect on default value for YesNo radios.
  if (getValues('indicator.enhancedAcppFlag') == null) {
    setValue('indicator.enhancedAcppFlag', 'true');
  }
  if (getValues('indicator.editsAndAllowancesFlag') == null) {
    setValue('indicator.editsAndAllowancesFlag', 'true');
  }
  const underwritingReview = watch('indicator.underwritingReviewFlag', '');
  const indicatorDetailsFlags = claimData?.indicator;

  return (
    <>
      <FormDropdown
        className={styles.oneThirdLeft}
        label={fields.claimsProcessingUnitLabels}
        name="indicator.claimProcessingUnitTypeCode"
        options={fields.claimsProcessingUnit[0].fields.listItems}
        addDefaultSelect={true}
        setAsNumber={false}
        isDisabled={isFieldDisabled}
      />
      <FormNumber
        className={`${styles.oneThirdLeft} ${
          i18n.language === LanguageSwitchTypeCode.French ? styles.pullDown : ''
        }`.trim()}
        label={fields.numberofPayments}
        name="indicator.paymentCount"
        isReadOnly={true}
      />
      <FormDatepicker
        className={styles.oneThird}
        label={fields.claimDateLabel}
        name="indicator.claimReceivedDate"
        isReadOnly={
          (siteTypeIsInternal &&
            claimData?.profile?.submitterUserTypeCode === UserType.External &&
            isInEditMode) ||
          isFieldDisabled
        }
      />

      <span className={styles.halfLeft}>
        <FormYesNoRadios
          className={styles.full}
          label={fields.enhancedAcpp}
          name="indicator.enhancedAcppFlag"
          isReadOnly={true}
        />

        <FormYesNoRadios
          className={styles.full}
          label={fields.editAndAllowancesLabel}
          name="indicator.editsAndAllowancesFlag"
          isReadOnly={true}
        />

        <FormYesNoRadios
          testid="underwritingReview"
          className={styles.full}
          label={fields.underWritingReviewLabel}
          name="indicator.underwritingReviewFlag"
          isReadOnly={isFieldDisabled}
        />
      </span>

      <span className={styles.half}>
        <FormCheckboxListWrapper
          name={'applicableIfChecked'}
          label={fields.applicableOptions}
          className={''}
        >
          <FormCheckbox
            className={styles.full}
            label={fields.indicatorOptions[0].fields.listItems[0].fields.itemName}
            name="indicator.rffiFlag"
            isReadOnly={true}
            checked={convertStringFlags(indicatorDetailsFlags?.rffiFlag)}
          />
          <FormCheckbox
            className={styles.full}
            label={fields.indicatorOptions[0].fields.listItems[1].fields.itemName}
            name="indicator.notepadCommentFlag"
            isReadOnly={true}
            checked={convertStringFlags(indicatorDetailsFlags?.notepadCommentFlag)}
          />
          <FormCheckbox
            className={styles.full}
            label={fields.indicatorOptions[0].fields.listItems[2].fields.itemName}
            name="indicator.eddFemsiFlag"
            isReadOnly={true}
            checked={convertStringFlags(indicatorDetailsFlags?.eddFemsiFlag)}
          />
          <FormCheckbox
            className={styles.full}
            label={fields.indicatorOptions[0].fields.listItems[3].fields.itemName}
            name="indicator.gvsPviiFlag"
            isReadOnly={true}
            checked={convertStringFlags(
              indicatorDetailsFlags && indicatorDetailsFlags?.gvsPviiFlag
                ? indicatorDetailsFlags?.gvsPviiFlag
                : false
            )}
          />
        </FormCheckboxListWrapper>
      </span>

      <ConditionalRender
        condition={
          underwritingReview === 'true' || isMasterUserEditingPostAdjudicationClaim === true
        }
      >
        <FormDatepicker
          className={`${styles.halfLeft} ${
            i18n.language === LanguageSwitchTypeCode.French ? styles.pullDown : ''
          }`.trim()}
          label={fields.underWritingStartDateLabel}
          name="indicator.underwritingReviewStartDate"
          isReadOnly={isFieldDisabled}
        />
        <FormDatepicker
          className={styles.half}
          label={fields.underWritingReceivedDateLabel}
          name="indicator.underwritingInformationReceivedDate"
          isReadOnly={isFieldDisabled}
        />

        <FormDatepicker
          className={styles.halfLeft}
          label={fields.underWritingRequestDateLabel}
          name="indicator.underwritingInformationRequestedDate"
          isReadOnly={isFieldDisabled}
        />
        <FormDatepicker
          className={`${styles.half} ${
            i18n.language === LanguageSwitchTypeCode.French ? styles.pullDown : ''
          }`.trim()}
          label={fields.underWritingReviewEndDateLabel}
          name="indicator.underwritingReviewEndDate"
          isReadOnly={isFieldDisabled}
        />
      </ConditionalRender>
    </>
  );
};

export default ClaimsIndicatorDetailsFields;
