import * as React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import HelpContentComponentProps from 'Feature/PageComponents/models/HelpContentComponentProps';

const HelpContentComponent = (props: HelpContentComponentProps) => (
  <div>
    <p>HelpContentComponent Component</p>
    <Text field={props.fields.heading} />
  </div>
);

export default HelpContentComponent;
