export default function GetErrorCount() {
  const listOfErrorEle = document.querySelectorAll('[class^=thisFieldHasError]');
  const arrayOfErrorElements = Array.prototype.slice.call(listOfErrorEle);
  const listOfErrorMessages: string[] = [];
  arrayOfErrorElements.map((x: HTMLInputElement) => {
    if (!listOfErrorMessages.includes(x.value) && x.value) {
      listOfErrorMessages.push(x.value);
    }
  });

  return listOfErrorMessages;
}
