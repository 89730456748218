import type { AxiosResponse } from 'axios';

import { HttpResponseStatusCodes } from '@hobt/constants';

import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { AuthenticationContextType } from 'Foundation/Authentication';
import { config } from '../../../../../../config';

import type { DefaultRequest, DefaultRequestData } from './types';

const emptyDefaultRequestResponse: DefaultRequest = {
  defaultDecision: 'N/A',
  decisionDate: 'N/A',
  managementTool: 'N/A'
};

const latestDefaultRequestsUrl: string = config.defaultRequestsApi.urls.latest;
const apiClientConfig: ApiClientConfig = { timeout: config.defaultApi.requestTimeout };

export const getLatestDefaultRequests = async (
  cmhcLoanAccountNumber: string | number,
  authenticationContext: AuthenticationContextType
): Promise<DefaultRequest[]> => {
  try {
    const response: AxiosResponse<DefaultRequestData> = await ApiClient(
      authenticationContext,
      apiClientConfig
    ).getWithAuth(`${latestDefaultRequestsUrl}/${cmhcLoanAccountNumber}`);

    if (
      response?.status === HttpResponseStatusCodes.OK &&
      response?.data?.data?.defaultRequests?.length > 0
    ) {
      return response?.data?.data?.defaultRequests;
    }
    // Fail gracefully
    return [emptyDefaultRequestResponse];
  } catch (err) {
    return [emptyDefaultRequestResponse];
  }
};
