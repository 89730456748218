import React, { useMemo } from 'react';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';

import HelpSupportSideMenuComponentProps from 'Feature/HelpSupport/models/HelpSupportSideMenuComponentProps';
import { HelpTopic } from 'Feature/HelpSupport/models/HelpSupportHelpTopic';
import { Card } from 'Components/Card';
import HelpSupportSideMenuLink from '../HelpSupportSideMenuLink';
import { menuEntries } from './menuEntries';

import styles from './styles.module.scss';

const HelpSupportSideMenuComponent: React.FC<HelpSupportSideMenuComponentProps> = (
  props: HelpSupportSideMenuComponentProps
) => {
  const pathArray: string[] = useMemo(() => {
    if (!isServer()) {
      const { pathname } = window.location;

      const arr = pathname.split('/').filter((entry) => entry !== '');

      if (arr.length > 0) {
        if (['en', 'fr'].indexOf(arr[0]) !== -1) {
          arr.splice(0, 1);
        }
      }
      return arr;
    }
    return [];
  }, []);

  return (
    <Card>
      {menuEntries && (
        <ul className={styles.sideMenu}>
          {menuEntries.helpNavigation.map((topic, idx: number) => (
            <HelpSupportSideMenuLink
              level={0}
              pathArray={pathArray}
              topic={topic as HelpTopic}
              key={idx}
            />
          ))}
        </ul>
      )}
    </Card>
  );
};
export default HelpSupportSideMenuComponent;
