import i18n from 'i18next';
import React, { useContext } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { FormProvider } from 'react-hook-form';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { LanguageShort } from '@hobt/constants';

import { AssessmentCalculationsContext } from 'Feature/Claims/components/Details/AssessmentCalculations';
import { useAddOtherAdjustmentsFunctions } from 'Feature/Claims/components/Details/AssessmentCalculations/AddOtherAdjustments/useAddOtherAdjustmentsFunctions';
import { Button } from 'Components/Common/Button';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormNumber from 'Components/Inputs/FormNumber';
import { currencyFormat, getCurrencyFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormTextArea from 'Components/Inputs/FormTextArea';

import { AddOtherAdjustmentsProps } from './types';
import styles from './styles.module.scss';

export const AddOtherAdjustments: React.FC<AddOtherAdjustmentsProps> = ({
  onCancel,
  onError,
  onSuccess
}: AddOtherAdjustmentsProps) => {
  const { sitecoreContent } = useContext(AssessmentCalculationsContext);
  const sitecoreFlyoutContent = sitecoreContent?.addOtherAdjustmentFlyout.fields;

  const { hookForm, cancel, onSubmitErrors, save } = useAddOtherAdjustmentsFunctions(
    onSuccess,
    onCancel,
    onError
  );

  return (
    <Scrollbars>
      <FormProvider {...hookForm}>
        <form onSubmit={hookForm.handleSubmit(save, onSubmitErrors)} className={styles.formWrapper}>
          <div className={styles.formHeader}>
            <Text tag="h2" field={sitecoreFlyoutContent?.formTitle ?? {}} />
            <button
              type="button"
              data-testid="addOtherAdjustmentsCloseButton"
              onClick={cancel}
              aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
            >
              <span className="material-icons align-self-center">close</span>
            </button>
          </div>
          <div className={styles.formBody}>
            <FormDropdown
              name="code"
              label={sitecoreFlyoutContent?.categoryLabel}
              className={styles.half}
              options={sitecoreFlyoutContent?.categoryList?.fields.listItems ?? []}
              setAsNumber={false}
            />
            <FormNumber
              className={styles.halfLeft}
              name="amount"
              label={sitecoreFlyoutContent?.chargeLabel}
              formatProps={getCurrencyFormat(
                hookForm.getValues('amount'),
                currencyFormat,
                i18n.language as LanguageShort
              )}
            />
            <FormTextArea
              name="comment"
              label={sitecoreFlyoutContent?.description}
              textAreaHelperText={sitecoreFlyoutContent?.characterLimit.value}
              charLimit={parseInt(sitecoreFlyoutContent?.limitNumber.value || '', 10)}
              className={styles.templateTextArea}
            />
          </div>
          <div className={styles.formFooter}>
            <Button
              name="saveButton"
              type="submit"
              ariaText={sitecoreFlyoutContent?.submitBtnAriaText}
              text={sitecoreFlyoutContent?.submitBtn ?? {}}
              responsive
              loading
            />
            <Button
              name="cancel"
              text={sitecoreFlyoutContent?.cancelBtn ?? {}}
              ariaText={sitecoreFlyoutContent?.cancelBtnAriaText}
              onClick={cancel}
              type="button"
              secondaryButton
              responsive
            />
          </div>
        </form>
      </FormProvider>
    </Scrollbars>
  );
};
