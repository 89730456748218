import SitecoreListItem from 'Constants/Types/SitecoreListItem';

export const useExternalAssignmentStatusFunctions = (statusItems?: SitecoreListItem[]) => {
  const getStatus = (statusId?: number): string | undefined => {
    if (statusId == null) {
      return undefined;
    }

    const statusList: Array<[number, string]> =
      statusItems?.map((item) => [
        Number(item.fields.itemValue.value),
        item.fields.itemName.value ?? ''
      ]) ?? [];

    const statusMap: Map<number, string> = new Map<number, string>(statusList);

    return statusMap.get(statusId);
  };

  return {
    getStatus
  };
};
