import React, { useEffect, useState } from 'react';
import { ModuleMapping, UserRole } from '@hobt/constants';
import { frontEndAddUserSchema } from '@hobt/form-schema-validators';
import { joiResolver } from '@hookform/resolvers/joi';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { Button } from 'Components/Core/Button';
import { ButtonVariant } from 'Components/Core/Button/types';
import { Modal } from 'Components/Core/Modal';
import { pathNames } from 'Constants';
import {
  ToastNotification,
  ToastNotificationProps
} from 'Feature/CommonComponents/ContentComponents';
import { ApplicationStates, SubmissionType } from 'Feature/CommonComponents/Enums';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';
import { useFeature } from 'flagged';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import i18n from 'i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router-dom';
import { Element } from 'react-scroll';
import { toastMessageMap, UserApiResponse, UserType } from '../../models/types';
import HbtInternalPIUserDetails from '../HbtCardsInternal/InternalUserDetails';
import HbtInternalPIUserLevelAccess from '../HbtCardsInternal/InternalUserLevelAccess';
import { addUserRequest } from '../HbtUserAccessManagementApi/HbtUserAccessApiHandler.service';
import styles from './styles.module.scss';
import HbtAddInternalPIUserProps from './types';

const internalSectionsMap: Record<string, React.FC<any>> = {
  HbtInternalPIUserDetails,
  HbtInternalPIUserLevelAccess
};

const HbtAddInternalPIUser = ({ fields, rendering }: HbtAddInternalPIUserProps) => {
  const history = useHistory();
  const isInternal = useFeature(FeatureFlags.INTERNAL);
  const authenticationContext = useAuthenticationContext();
  const sectionsLookup = internalSectionsMap;
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean =
    isInternal && isUserInRoles(ModuleMapping.admin, [UserRole.ReadOnly], moduleRoleMapping);

  const [isBypass, setBypass] = useState<boolean>(false);
  const [isSubmitFailed, setSubmitFailed] = useState<boolean>(false);
  const [toast, setToast] = useState<boolean>(false);
  const [toastMsg, setToastMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  let [formPayloadData, setFormPayloadData] = useState<Object>();

  const methods: any = useForm({
    resolver: joiResolver(frontEndAddUserSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      contact: {
        firstName: '',
        lastName: '',
        extensionPhoneNumber: '',
        phoneNumber: '',
        faxNumber: ''
      },
      userAccesses: [
        {
          access: {
            arrears: {
              activeFlag: false
            },
            claim: {
              activeFlag: false
            },
            default: {
              activeFlag: false
            },
            piRequest: {
              activeFlag: false
            },
            piPurposeTest: {
              activeFlag: false
            },
            piFileShare: {
              activeFlag: false
            },
            admin: {
              activeFlag: false
            }
          }
        }
      ]
    }
  });

  useEffect(() => {
    methods.register('userTypeCode');
    if (!isInternal) {
      methods.register('userAccesses[0].allowedTransitNumbers');
    }
    methods.setValue('userTypeCode', UserType[isInternal ? 'Internal' : 'External']);

    if (isServer() === false) {
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, []);

  // TODO: remove after issue in environment found
  if (!isInternal) console.log('formdata-watcher', methods.getValues());

  const handleToastAction = (type: SubmissionType) => {
    setBypass(type !== SubmissionType.ERROR);
    setSubmitFailed(type === SubmissionType.ERROR);
    setToast(type !== SubmissionType.OTHER);
  };

  const toastNotificationProps: ToastNotificationProps = {
    isActive: toast,
    title: i18n.t(
      `Default${
        isSubmitFailed ? 'Submission-ErrorToast' : 'sInventoryTable-DecisioningToastMessage-'
      }Title`
    ),
    type: ApplicationStates[isSubmitFailed ? 'ERROR' : 'SUCCESS'],
    content: i18n.t(toastMsg),
    onCloseCallback: () => {
      setToast(false);
      if (!isSubmitFailed) {
        history.push(`/${i18n.language}${pathNames.userManagement}`);
      }
    }
  };

  // TODO: need to handle error state for add user
  const onError = async (err?: any): Promise<void> => {
    handleToastAction(SubmissionType.ERROR);
    setIsLoading(false);
    console.log('formerr-submiterr', err);
    console.log('formerr-getval', methods.getValues());
    if (err != null && typeof err === 'number') {
      setToastMessage(toastMessageMap.get(err) ?? '');
    } else {
      setToastMessage(toastMessageMap.get(UserApiResponse.Error) ?? '');
    }
  };

  const onAddUserApiCall = async () => {
    setIsLoading(true);
    const result = await addUserRequest(authenticationContext, formPayloadData, sitecoreContext);
    switch (result) {
      case UserApiResponse.Created: // User added successfully
        setToastMessage(toastMessageMap.get(UserApiResponse.Created) ?? '');
        handleToastAction(SubmissionType.SUBMIT);
        setIsLoading(false);
        break;
      default:
        onError(result);
        break;
    }
  };

  const onSubmit: any = async (formData: any): Promise<void> => {
    formPayloadData = formData;
    console.log(formData, 'formData');
    setFormPayloadData(formData);
    if (!isInternal) {
      setIsModalActive(true);
    } else {
      onAddUserApiCall();
    }
  };

  const onUserAdd = () => {
    setIsModalActive(false);
    onAddUserApiCall();
  };

  const onDismissModal = () => {
    setIsModalActive(false);
  };

  const { dirtyFields } = methods.formState;

  // isDirty check
  useEffect(() => {
    if (isServer() === false) {
      // onbeforeunload should trigger on back, forward, refresh, and link button clicks
      window.onbeforeunload = () => {
        // trigger browser warning
        if (Object.keys(dirtyFields).length > 0 && !isBypass) {
          // returning anything, will trigger the prompt,
          // return string only read on IE:
          return i18n.t('DefaultSubmission-IsDirty');
        }
        return null;
      };
    }
  }, [dirtyFields, isBypass]);

  return (
    <>
      <ContentLoadingModal
        display={isLoading}
        fields={{
          heading: { value: i18n.t('Globals-Saving-Heading') },
          description: { value: i18n.t('Globals-Saving-Description') }
        }}
      />
      <ToastNotification {...toastNotificationProps} />
      <Prompt
        when={Object.keys(dirtyFields).length > 0 && !isBypass}
        message={i18n.t('DefaultSubmission-IsDirty')}
      />

      <div className={styles.addInternalPIUserMain}>
        <div className={styles.addInternalPIUserMainContainer}>
          <div className={styles.addInternalPIUserMainContent}>
            <div className={styles.addInternalPIUserHeaderContainer}>
              <h2 className={styles.addInternalPIUserTitle}>
                <Text field={fields.pageTitle} />
              </h2>
            </div>
            <div className={styles.addInternalPIUserFormContainer}>
              <FormProvider {...methods}>
                <form
                  id="addUserForm"
                  className="form add-user-form"
                  onSubmit={methods.handleSubmit(onSubmit)}
                  noValidate
                >
                  <div className={styles.addInternalPIUserFormBody}>
                    <div className="row">
                      <section className="col-12">
                        {rendering?.placeholders?.sections?.map((card: any, index: number) => (
                          <Element name={card.componentName} key={card.componentName + index}>
                            {sectionsLookup[card.componentName] != null &&
                              React.createElement(sectionsLookup[card.componentName], {
                                fields: card.fields,
                                key: index
                              })}
                          </Element>
                        ))}
                      </section>
                    </div>
                    <div className={styles.addInternalPIUserFormActionBtnContainer}>
                      <div className={styles.addButtons}>
                        <Button
                          variant={ButtonVariant.PRIMARY}
                          name="addUserCreateBtn"
                          text={fields.createBtnText}
                          ariaText={fields.createBtnText}
                          type="submit"
                          disabled={isReadOnlyUser}
                        />
                      </div>
                      <div className={styles.addButtons}>
                        <Button
                          variant={ButtonVariant.SECONDARY}
                          name="addUserCancelBtn"
                          text={fields.cancelBtnText}
                          ariaText={fields.cancelBtnText}
                          onClick={() =>
                            history.push(`/${i18n.language}${pathNames.userManagement}`)
                          }
                          type="button"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>

      <Modal
        id="user-add-confirmation-modal"
        isActive={isModalActive}
        title={fields.confirmationModal?.fields?.heading || ''}
        onDismissalCallback={onDismissModal}
        bodyContent={
          <span>
            <Text field={fields.confirmationModal?.fields.content} />
          </span>
        }
        modalButtons={{
          primaryButton: {
            name: fields.confirmationModal?.fields.acceptButton.value ?? '',
            text: { value: fields.confirmationModal?.fields.acceptButton.value } as FieldValue,
            ariaText: { value: fields.confirmationModal?.fields.acceptButton.value } as FieldValue,
            onClick: onUserAdd,
            onKeyDown: onUserAdd
          },
          secondaryButton: {
            name: fields.confirmationModal?.fields.cancelButton?.value ?? '',
            text: { value: fields.confirmationModal?.fields.cancelButton?.value } as FieldValue,
            ariaText: { value: fields.confirmationModal?.fields.cancelButton?.value } as FieldValue,
            onClick: onDismissModal,
            onKeyDown: onDismissModal
          }
        }}
        shouldCloseOnBackgroundClick={true}
      />
    </>
  );
};
export default HbtAddInternalPIUser;
