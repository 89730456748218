import React from 'react';
import i18n from 'i18next';

import WordIcon from './icons/Word.svg';
import DownloadIcon from './icons/Download.svg';
import ExcelIcon from './icons/Excel.svg';
import OneNoteIcon from './icons/OneNote.svg';
import DocumentIcon from './icons/Document.svg';
import DeleteIcon from './icons/Delete.svg';
import CSVIcon from './icons/CSV.svg';

export type HbtIconProps = {
  type: string;
};

export const HbtIcon: React.FC<HbtIconProps> = ({ type }: HbtIconProps) => {
  const iconLookup: { [lookupKey: string]: JSX.Element } = {
    file_word: <img src={WordIcon} alt={i18n.t('IconAlt-file-word')} />,
    file_excel: <img src={ExcelIcon} alt={i18n.t('IconAlt-file-excel')} />,
    file_oneNote: <img src={OneNoteIcon} alt={i18n.t('IconAlt-file-oneNote')} />,
    file_generic: <img src={DocumentIcon} alt={i18n.t('IconAlt-file-generic')} />,
    icon_download: <img src={DownloadIcon} alt={i18n.t('IconAlt-download')} />,
    icon_delete: <img src={DeleteIcon} alt={i18n.t('IconAlt-delete')} />,
    icon_csv: <img src={CSVIcon} alt={i18n.t('IconAlt-CSV')} />,
    icon_pdf: (
      <span
        className="material-icons align-self-center icon--size-40"
        role="img"
        aria-label={i18n.t('IconAlt-pdf')}
      >
        picture_as_pdf
      </span>
    ),
    icon_jpeg: (
      <span
        className="material-icons align-self-center icon--size-40"
        role="img"
        aria-label={i18n.t('IconAlt-jpeg')}
      >
        image
      </span>
    )
  };

  return iconLookup[type] ?? null;
};
