import React, { createContext, FC, useContext, useReducer } from 'react';
import i18n from 'i18next';

import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { ToastNotification } from './ToastNotification';

import { ToastNotificationProps } from './ToastNotification.types';

type ToastState = Omit<ToastNotificationProps, 'onCloseCallback'>;
type Action =
  | { type: 'toast'; value: Partial<Omit<ToastNotificationProps, 'onCloseCallback'>> }
  | { type: 'success'; value: ToastNotificationProps['content'] }
  | { type: 'error'; value: ToastNotificationProps['content'] }
  | { type: 'close' };

type Dispatch = (action: Action) => void;
type ToastContextType = {
  state: ToastState;
  dispatch: Dispatch;
};

const initialState: ToastState = {
  type: ApplicationStates.DEFAULT,
  isActive: false,
  title: '',
  content: ''
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);

let localContext: ToastContextType = { state: initialState, dispatch: () => {} };

function toastReducer(state: ToastState, action: Action) {
  switch (action.type) {
    case 'toast': {
      return { ...state, ...action.value };
    }
    case 'success': {
      return {
        type: ApplicationStates.SUCCESS,
        isActive: true,
        title: i18n.t('Globals-Toast-Success-Title'),
        content: action.value ?? ''
      };
    }
    case 'error': {
      return {
        type: ApplicationStates.ERROR,
        isActive: true,
        title: i18n.t('Globals-Toast-Error-Title'),
        content: action.value ?? ''
      };
    }
    case 'close': {
      return { ...state, isActive: false };
    }
    default: {
      // @ts-ignore
      throw new Error(`ToastContext: Unhandled Action Type: ${action!.type}`);
    }
  }
}

const ToastProvider: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(toastReducer, initialState);
  localContext = { state, dispatch };

  return (
    <ToastContext.Provider value={localContext}>
      <ToastNotification {...state} onCloseCallback={() => dispatch({ type: 'close' })} />
      {children}
    </ToastContext.Provider>
  );
};

function useToastNotification() {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToastNotification must be used within a ToastProvider');
  }
  return context;
}

// Helper Functions //

function toastSuccess(content?: ToastNotificationProps['content']) {
  localContext.dispatch({
    type: 'success',
    value: content
  });
}

function toastError(content?: ToastNotificationProps['content']) {
  localContext.dispatch({
    type: 'error',
    value: content
  });
}

// For any other helper functions re: toast type:
// 1/ Add the Action above
// 2/ Handle Action in the Reducer above
// 3/ Add the helper function (c&p, update type)
// 4/ Export the helper function

export { ToastProvider, useToastNotification, toastSuccess, toastError };
