import * as React from 'react';
import { ToggleProps } from './Toggle.types';

export const Toggle: React.FC<ToggleProps> = ({ toggleText, onClick, isChecked, id, testId }) => (
  <div className="switch" id={testId}>
    <div className="switch__input">
      <div>
        <label className="switch__label" id={`${id}Label`} htmlFor={`${id}Input`}>
          <input
            className="switch__input-checkbox"
            type="checkbox"
            defaultChecked={isChecked}
            onClick={onClick}
            id={`${id}Input`}
            aria-labelledby={`${id}Label`}
          />
          {toggleText}
          <span className="switch__slider round" />
        </label>
      </div>
    </div>
  </div>
);
