import { StylesConfig } from 'react-select';

import { IsMulti, OptionType } from './types';

export const selectCustomStyles: StylesConfig<OptionType, IsMulti> = {
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none'
  }),
  control: (base: any, state: any) => ({
    ...base,
    border: state.isFocused ? '' : 'none',
    boxShadow: state.isFocused ? '' : 'none',
    justifyContent: 'flex-start'
  }),
  dropdownIndicator: () => ({
    display: 'flex',
    alignItems: 'center'
  }),
  valueContainer: (base: any) => ({
    ...base,
    flex: 'none'
  }),
  singleValue: () => ({})
};
