import {
  BuildingType,
  StreetDirection,
  StreetType,
  CmhcProvinceCode,
  ProvinceIDCodeMapping
} from '@hobt/constants';
import { isNullOrDefault } from '@hobt/utils';

import { RentalIncome } from 'Feature/Claims/components/Cards/IncomeDetails/types';
import {
  Borrower,
  BorrowerCharge,
  DraftClaimResponseData,
  PartialPayment
} from 'Feature/Claims/components/ClaimsSubmissionForm/types';
import getPathInObject from '../GetPathInObject';
import { setPathInObject } from '../SetPathInObject/setPathInObject';
import { uppercaseFirstLetter } from '../StringConverters/uppercaseFirstLetter';
import { validString } from '../ValidString/validString';

const SINGLE_BUILDING_TYPE_BED_UNIT_COUNT_THRESHOLD = 2;

const REGEX = /"\s+|\s+"/g;

const detailDropdownForms = [
  'detail.additionalSecuritiesHeldByBorrowerTypeCode',
  'detail.claimTypeCode',
  'detail.legalSystemTypeCode',
  'detail.legalActionTypeCode',
  'detail.mortgageTypeCode',
  'detail.propertyOccupancyStatusCode',
  'detail.propertyVacatedByTypeCode',
  'detail.propertyTransferredByTypeCode',
  'detail.propertySoldByTypeCode',
  'detail.taxAccountBalanceTypeCode',
  'detail.warrantyTypeCode'
];

const lenderDropdownForms = [
  'lender.claimPayee.typeCode',
  'lender.correspondenceLanguageCode',
  'lender.address.provinceCode'
];

const propertyBuildingType = 'property.buildingTypeCode';
const propertyProvinceCode = 'property.address.provinceCode';
const propertyStreetDirection = 'property.address.streetDirectionTypeCode';
const propertyStreetType = 'property.address.streetTypeCode';
const propertyNumberOfBeds = 'property.bedCount';
const propertyNumberOfUnits = 'property.unitCount';

const propertyDropdownForms = [
  propertyBuildingType,
  propertyProvinceCode,
  propertyStreetDirection,
  propertyStreetType
];

export const claimDropdownForms = [
  ...detailDropdownForms,
  ...lenderDropdownForms,
  ...propertyDropdownForms
];

export function setBuildingType<DraftClaimResponseData>(data: DraftClaimResponseData) {
  if (
    (getPathInObject(data, propertyNumberOfBeds) ??
      0 + getPathInObject(data, propertyNumberOfUnits) ??
      0) <= SINGLE_BUILDING_TYPE_BED_UNIT_COUNT_THRESHOLD
  ) {
    setPathInObject(data, propertyBuildingType, BuildingType.Single);
  } else {
    setPathInObject(data, propertyBuildingType, BuildingType.Multiple);
  }
}

function convertPropertyCardData(data: DraftClaimResponseData) {
  const numberOfBedsValue = getPathInObject(data, propertyNumberOfBeds);
  const numberOfUnitsValue = getPathInObject(data, propertyNumberOfUnits);
  const propertyProvinceCodeValue = getPathInObject(data, propertyProvinceCode);
  const propertyStreetTypeValue = getPathInObject(data, propertyStreetType);
  const propertyStreetDirectionValue = getPathInObject(data, propertyStreetDirection);

  if (
    numberOfBedsValue != null &&
    typeof numberOfBedsValue === 'string' &&
    numberOfBedsValue !== ''
  ) {
    setPathInObject(data, propertyNumberOfBeds, Number(numberOfBedsValue));
  }

  if (
    numberOfUnitsValue != null &&
    typeof numberOfUnitsValue === 'string' &&
    numberOfUnitsValue !== ''
  ) {
    setPathInObject(data, propertyNumberOfUnits, Number(numberOfUnitsValue));
  }

  setBuildingType(data);

  if (validString(propertyStreetTypeValue)) {
    setPathInObject(
      data,
      propertyStreetType,
      StreetType[<keyof typeof StreetType>uppercaseFirstLetter(propertyStreetTypeValue)]
    );
  }

  if (validString(propertyStreetDirectionValue)) {
    setPathInObject(
      data,
      propertyStreetDirection,
      StreetDirection[
        <keyof typeof StreetDirection>uppercaseFirstLetter(propertyStreetDirectionValue)
      ]
    );
  }

  if (
    validString(propertyProvinceCodeValue) &&
    Object.values(CmhcProvinceCode).includes(propertyProvinceCodeValue)
  ) {
    setPathInObject(
      data,
      propertyProvinceCode,
      Object.keys(ProvinceIDCodeMapping)?.find(
        (key) => ProvinceIDCodeMapping[key] === propertyProvinceCodeValue
      )
    );
  }

  return data;
}

function mapBorrowerDropdownForms(borrower: Borrower) {
  const borrowerCategoryIDValue = getPathInObject(borrower, 'claimBorrowerCategoryID');
  const defaultReasonValue = getPathInObject(borrower, 'defaultReasonCode');
  const judgmentActionValue = getPathInObject(borrower, 'judgmentActionTypeCode');
  const reasonNotPursuedValue = getPathInObject(borrower, 'reasonNotPursuedTypeCode');

  if (isNullOrDefault(borrowerCategoryIDValue)) {
    setPathInObject(borrower, `claimBorrowerCategoryID`, undefined);
  }

  if (isNullOrDefault(defaultReasonValue)) {
    setPathInObject(borrower, `defaultReasonCode`, undefined);
  }

  if (isNullOrDefault(judgmentActionValue)) {
    setPathInObject(borrower, `judgmentActionTypeCode`, undefined);
  }

  if (isNullOrDefault(reasonNotPursuedValue)) {
    setPathInObject(borrower, `reasonNotPursuedTypeCode`, undefined);
  }
}

function convertStringAmountToNumber(
  charge: BorrowerCharge | RentalIncome | PartialPayment,
  data: DraftClaimResponseData,
  index: number,
  path: string
) {
  if (typeof charge?.receivedAmount === 'string') {
    setPathInObject(data, `${path}.${index}.receivedAmount`, Number(charge?.receivedAmount));
  }
}

export function draftClaimJsonReplacer(draftClaimData: DraftClaimResponseData) {
  let transformedDraftClaimData = JSON.parse(JSON.stringify(draftClaimData).replace(REGEX, '"'));
  transformedDraftClaimData = convertPropertyCardData(transformedDraftClaimData);

  claimDropdownForms.forEach((field: string) => {
    if (isNullOrDefault(getPathInObject(draftClaimData, field))) {
      setPathInObject(transformedDraftClaimData, field, null);
    }
  });

  const { borrowers, borrowerCharges, partialPayments, netOperatingIncomesRentalIncomes } =
    transformedDraftClaimData;

  if (borrowers != null) {
    borrowers.forEach((borrower: Borrower) => {
      mapBorrowerDropdownForms(borrower);
    });
  }

  if (borrowerCharges != null) {
    borrowerCharges.forEach((charge: BorrowerCharge, index: number) => {
      convertStringAmountToNumber(charge, transformedDraftClaimData, index, 'borrowerCharges');
    });
  }

  if (partialPayments != null) {
    partialPayments.forEach((charge: PartialPayment, index: number) => {
      convertStringAmountToNumber(charge, transformedDraftClaimData, index, 'partialPayments');
    });
  }

  if (netOperatingIncomesRentalIncomes != null) {
    netOperatingIncomesRentalIncomes.forEach((charge: RentalIncome, index: number) => {
      convertStringAmountToNumber(
        charge,
        transformedDraftClaimData,
        index,
        'netOperatingIncomesRentalIncomes'
      );
    });
  }

  return transformedDraftClaimData;
}
