import React from 'react';
import i18n from 'i18next';

import { DefaultRequestStatus } from 'Foundation/Api';

import { DefaultModalForm } from 'Feature/DefaultsInventory/components/DefaultModalForm/DefaultModalForm';
import { FormDropdownOption } from 'Feature/DefaultSubmissionForm/components/FormInputDropdownText/FormInputDropdownText.types';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { ModalProps } from 'Feature/CommonComponents/ContentComponents';
import { DecisioningFormModalData } from '../api/SubmissionDecisionPayload.type';

export const DefaultSubmissionModals = (
  submitCallBack: Function,
  closeCallBack: Function,
  cancelOptions: FormDropdownOption[],
  declineOptions: FormDropdownOption[]
) => {
  const getApproveLenderSubmission = (): ModalProps => ({
    type: ApplicationStates.SUCCESS,
    title: i18n.t('DefaultsInventoryDetails-Modal-ApproveLenderSubmission-Title'),
    isActive: true,
    onCloseCallback: (e) => closeCallBack(e),
    // eslint-disable-next-line react/display-name
    content: () => (
      <DefaultModalForm
        submitCallback={(formModalData: DecisioningFormModalData) =>
          submitCallBack(DefaultRequestStatus.Approved, formModalData)
        }
        submitButtonText={i18n.t(
          'DefaultsInventoryDetails-Modal-ApproveLenderSubmission-ButtonText'
        )}
        disableSubmitOnEmptyMultilineText={true}
        multiTextProps={{
          id: 'DefaultsInventoryDetails-Modal-ApproveLenderSubmission-MultiText',
          name: 'DefaultsInventoryDetails-Modal-ApproveLenderSubmission-MultiText',
          title: {
            value: i18n.t('DefaultsInventoryDetails-Modal-ApproveLenderSubmission-Multiline-Label')
          },
          textAreaLimit: { value: '' },
          limitCountText: `/${i18n.t('Globals-MultilineText-MaxLength')}`,
          maxLength: Number(i18n.t('Globals-MultilineText-MaxLength'))
        }}
      />
    )
  });

  const getApproveCmhcWorkoutPlan = (): ModalProps => ({
    type: ApplicationStates.SUCCESS,
    title: i18n.t('DefaultsInventoryDetails-Modal-ApproveCmhcWorkoutPlan-Title'),
    isActive: true,
    onCloseCallback: (e) => closeCallBack(e),
    // eslint-disable-next-line react/display-name
    content: () => (
      <DefaultModalForm
        submitCallback={(formModalData: DecisioningFormModalData) =>
          submitCallBack(DefaultRequestStatus.ApprovedWithModifications, formModalData)
        }
        submitButtonText={i18n.t(
          'DefaultsInventoryDetails-Modal-ApproveCmhcWorkoutPlan-ButtonText'
        )}
        disableSubmitOnEmptyMultilineText={true}
        multiTextProps={{
          id: 'DefaultsInventoryDetails-Modal-ApproveCmhcWorkoutPlan-MultilineText',
          name: 'DefaultsInventoryDetails-Modal-ApproveCmhcWorkoutPlan-MultilineText',
          title: {
            value: i18n.t('DefaultsInventoryDetails-Modal-ApproveCmhcWorkoutPlan-Multiline-Label')
          },
          textAreaLimit: { value: '' },
          limitCountText: `/${i18n.t('Globals-MultilineText-MaxLength')}`,
          maxLength: Number(i18n.t('Globals-MultilineText-MaxLength'))
        }}
      />
    )
  });

  const getDecline = (): ModalProps => ({
    type: ApplicationStates.ERROR,
    title: i18n.t('DefaultsInventoryDetails-Modal-Decline-Title'),
    isActive: true,
    onCloseCallback: (e) => closeCallBack(e),
    // eslint-disable-next-line react/display-name
    content: () => (
      <DefaultModalForm
        submitCallback={(formModalData: DecisioningFormModalData) =>
          submitCallBack(DefaultRequestStatus.Declined, formModalData)
        }
        submitButtonText={i18n.t('DefaultsInventoryDetails-Modal-Decline-ButtonText')}
        disableSubmitOnEmptySelect={true}
        dropdownProps={{
          id: 'DefaultsInventoryDetails-Modal-Decline-Dropdown',
          name: 'DefaultsInventoryDetails-Modal-Decline-Dropdown',
          title: { value: i18n.t('DefaultsInventoryDetails-Modal-Decline-ReasonDropdown-Label') },
          options: declineOptions
        }}
        multiTextProps={{
          id: 'DefaultsInventoryDetails-Modal-Decline-MultilineText',
          name: 'DefaultsInventoryDetails-Modal-Decline-MultilineText',
          title: { value: i18n.t('DefaultsInventoryDetails-Modal-Decline-ReasonMultiline-Label') },
          textAreaLimit: { value: '' },
          limitCountText: `/${i18n.t('Globals-MultilineText-MaxLength')}`,
          maxLength: Number(i18n.t('Globals-MultilineText-MaxLength'))
        }}
      />
    )
  });

  const getCancel = (): ModalProps => ({
    type: ApplicationStates.CANCEL,
    title: i18n.t('DefaultsInventoryDetails-Modal-Cancel-Title'),
    isActive: true,
    onCloseCallback: (e) => closeCallBack(e),
    // eslint-disable-next-line react/display-name
    content: () => (
      <DefaultModalForm
        submitCallback={(formModalData: DecisioningFormModalData) =>
          submitCallBack(DefaultRequestStatus.Cancelled, formModalData)
        }
        submitButtonText={i18n.t('DefaultsInventoryDetails-Modal-Cancel-ButtonText')}
        disableSubmitOnEmptySelect={true}
        dropdownProps={{
          id: 'DefaultsInventoryDetails-Modal-Cancel-Dropdown',
          name: 'DefaultsInventoryDetails-Modal-Cancel-Dropdown',
          title: { value: i18n.t('DefaultsInventoryDetails-Modal-Cancel-ReasonDropdown-Label') },
          options: cancelOptions
        }}
        multiTextProps={{
          id: 'DefaultsInventoryDetails-Modal-Cancel-MultilineText',
          name: 'DefaultsInventoryDetails-Modal-Cancel-MultilineText',
          title: { value: i18n.t('DefaultsInventoryDetails-Modal-Cancel-ReasonMultiline-Label') },
          textAreaLimit: { value: '' },
          limitCountText: `/${i18n.t('Globals-MultilineText-MaxLength')}`,
          maxLength: Number(i18n.t('Globals-MultilineText-MaxLength'))
        }}
      />
    )
  });

  const getInactivate = (): ModalProps => ({
    type: ApplicationStates.INACTIVE,
    title: i18n.t('DefaultsInventoryDetails-Modal-Inactivate-Title'),
    isActive: true,
    onCloseCallback: (e) => closeCallBack(e),
    // eslint-disable-next-line react/display-name
    content: () => (
      <DefaultModalForm
        submitCallback={(formModalData: DecisioningFormModalData) =>
          submitCallBack(DefaultRequestStatus.Inactive, formModalData)
        }
        submitButtonText={i18n.t('DefaultsInventoryDetails-Modal-Inactivate-ButtonText')}
        disableSubmitOnEmptyMultilineText={false}
        multiTextProps={{
          id: 'DefaultsInventoryDetails-Modal-Inactivate-MultilineText',
          name: 'DefaultsInventoryDetails-Modal-Inactivate-MultilineText',
          title: { value: i18n.t('DefaultsInventoryDetails-Modal-Inactivate-Multiline-Label') },
          textAreaLimit: { value: '' },
          limitCountText: `/${i18n.t('Globals-MultilineText-MaxLength')}`,
          maxLength: Number(i18n.t('Globals-MultilineText-MaxLength'))
        }}
      />
    )
  });

  return {
    getApproveLenderSubmission,
    getApproveCmhcWorkoutPlan,
    getDecline,
    getCancel,
    getInactivate
  };
};
