import { useFeature } from 'flagged';
import React, { useEffect } from 'react';
import i18n from 'i18next';
import { UserRole, Module } from '@hobt/constants';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useDefaultFormActionsContext } from 'Feature/DefaultsInventory/components/DefaultFormContext';

import { LinedCard, AccordionContainer } from '../../../../CommonComponents/AccordionComponents';
import { ReasonsDefaultManagementProps } from './ReasonsDefaultManagement.types';
import FormInputTextInput from '../../FormInputTextInput';
import FormInputCalendar from '../../FormInputCalendar';
import FormInputMultiLineText from '../../FormInputMultiLineText';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

export const ReasonsDefaultManagement: React.FC<ReasonsDefaultManagementProps> = ({
  register,
  errors,
  setValueHandler,
  control,
  watch,
  getValues,
  reset,
  ...props
}) => {
  const commonCardProps = {
    register,
    errors,
    setValueHandler,
    control,
    watch,
    getValues
  };

  const reasonsCharacterLimitText: string = i18n
    .t('DefaultSubmission-Card-ReasonsCharacterLimitText')
    .replace('{textLimit}', props.maxLength.toString());

  const dateHardshipBegan = (watch && watch(`managementInformation.dateHardshipBegan`)) || 0;

  const causeOfDefault = (watch && watch(`managementInformation.causeOfDefault`)) || 0;

  const description = (watch && watch(`managementInformation.description`)) || 0;

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const defaultFormActions = useDefaultFormActionsContext();

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  useEffect(() => {
    if (defaultFormActions.isCardComplete('defaultManagement')) {
      if (!(!!dateHardshipBegan && !!causeOfDefault && !!description)) {
        defaultFormActions?.removeCompleteCard('defaultManagement');
      }
    } else if (!!dateHardshipBegan && !!causeOfDefault && !!description) {
      defaultFormActions?.addCompleteCard('defaultManagement');
    }
  }, [dateHardshipBegan, causeOfDefault, description]);

  return (
    <LinedCard
      id={props.id}
      testId={props.testId}
      linePosition={props.linePosition}
      lineColor={props.accordionLineColor}
    >
      <AccordionContainer accordionId={`${props.id}Accordion`} title={props.title.field}>
        <div className="card_body-section obligation-section-1">
          <div className="row card__body-row">
            <div className="form__element form__element--2-column col-6">
              <FormInputCalendar
                {...commonCardProps}
                id={`${props.id}dateHardshipBegan`}
                name="managementInformation.dateHardshipBegan"
                title={{ value: i18n.t('DefaultSubmission-CardH-ReasonsDateHardshipBegan') }}
                language={i18n.language || 'en'}
                cardName={props.title.field.value}
                disabled={props.disableCalendar}
                isRequired={true}
                isDisabled={isReadOnlyUser}
              />
            </div>
            <div className="form__element form__element--2-column col-6">
              <FormInputTextInput
                {...commonCardProps}
                id={`${props.id}CauseOfDefault`}
                name={`managementInformation.causeOfDefault`}
                title={{
                  value: i18n.t('DefaultSubmission-CardH-ReasonsCauseOfDefault')
                }}
                type="text"
                cardName={props.title.field.value}
                isRequired={true}
                isDisabled={isReadOnlyUser}
              />
            </div>
          </div>
          <div className="row card__body-row">
            <div className="form__element col-12">
              <FormInputMultiLineText
                {...commonCardProps}
                id={`${props.id}Description`}
                name="managementInformation.description"
                title={{
                  value: i18n.t('DefaultSubmission-CardH-Description')
                }}
                textAreaLimit={{
                  value: reasonsCharacterLimitText
                }}
                hideLimit={props.hideLimit}
                maxLength={props.maxLength}
                limitCountText={`/ ${props.maxLength}`}
                cardName={props.title.field.value}
                isRequired={true}
                isDisabled={isReadOnlyUser}
              />
            </div>
          </div>
        </div>
      </AccordionContainer>
    </LinedCard>
  );
};
