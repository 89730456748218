import React, { FC } from 'react';
import { jsonReplaceBoolToString } from 'Constants/Utils/JSONReplacers';

import type { Props } from './types';

import styles from './styles.module.scss';

const KeyValuePairDisplay: FC<Props> = ({ heading, dictionary, placeholder }) => {
  const transformedDictionary: Props['dictionary'] = JSON.parse(
    JSON.stringify(dictionary, jsonReplaceBoolToString)
  );
  return (
    <>
      {heading}
      <ul className={styles['field-list']}>
        {Object.entries(transformedDictionary).map(([key, value]) => (
          <li key={key} className={styles.item}>
            <span className={styles.key}>{key}</span>
            <span className={styles.value}>{value ?? placeholder}</span>
          </li>
        ))}
      </ul>
    </>
  );
};

export default KeyValuePairDisplay;
