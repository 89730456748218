import { defaultEmptyString } from 'Components/Common/Api/utils/HandleEmpty';
import * as React from 'react';
import { TextCellProps } from 'Components/Common/DataTable/DataTableRow/TextCell/types';
import styles from './styles.module.scss';

export const TextCell: React.FC<TextCellProps> = ({
  name,
  text,
  subtext,
  className
}: TextCellProps) => (
  <td data-testid={`${name}TextCell`} className={className}>
    <span className={styles.primaryText} data-testid={`${name}TextCellText`}>
      {text ?? defaultEmptyString}
    </span>
    <span className={styles.subText} data-testid={`${name}TextCellSubText`}>
      {subtext}
    </span>
  </td>
);
