import React, { memo, useMemo } from 'react';
import { FormControlLabel } from '@mui/material';
import MUICheckbox from '@mui/material/Checkbox';
import { HbtIcon } from '../HbtIcon';
import styles from './styles.module.scss';
import { HBTCheckboxProps } from './types';

const Checkbox: React.FC<HBTCheckboxProps> = ({
  label,
  defaultChecked,
  disabled,
  indeterminate,
  errorCheckbox,
  checked,
  onChange,
  onKeyDown
}: HBTCheckboxProps) => {
  const uncheckedIcon = useMemo(
    () =>
      errorCheckbox ? (
        <HbtIcon type="icon_checkbox_error" />
      ) : (
        <HbtIcon type="icon_checkbox_default" />
      ),
    [errorCheckbox]
  );

  const checkedIcon = <HbtIcon type="icon_checkbox_checked" />;

  const indeterminateIcon = <HbtIcon type="icon_checkbox_indeterminate" />;

  const disabledIcon = <HbtIcon type="icon_checkbox_disabled_unchecked" />;

  const disabledCheckedIcon = <HbtIcon type="icon_checkbox_disabled" />;

  const disabledIndeterminateIcon = <HbtIcon type="icon_checkbox_disabled_indeterminate" />;

  return (
    <FormControlLabel
      className={styles.labelText}
      control={
        <MUICheckbox
          className={errorCheckbox ? styles.errorCheckbox : styles.baseCheckbox}
          data-testid="checkbox-test"
          inputProps={{
            tabIndex: 0,
            'aria-invalid': errorCheckbox ? 'true' : 'false',
            'aria-errormessage': 'error-checkbox'
          }}
          defaultChecked={defaultChecked}
          checked={checked}
          disabled={disabled}
          disableRipple
          icon={disabled ? disabledIcon : uncheckedIcon}
          checkedIcon={disabled ? disabledCheckedIcon : checkedIcon}
          indeterminateIcon={disabled ? disabledIndeterminateIcon : indeterminateIcon}
          indeterminate={indeterminate}
          onChange={onChange}
          onKeyDown={onKeyDown}
          sx={{
            '& .MuiSvgIcon-root': {
              height: '16px !important',
              width: '16px !important',
              color: 'transparent'
            },
            '&:focus-within .MuiSvgIcon-root': {
              outline: '2px auto #6D65D7',
              outlineOffset: '2px'
            }
          }}
        />
      }
      label={label?.value}
      sx={{
        '.MuiFormControlLabel-label': {
          fontSize: '14px !important',
          color: '#4A545D !important',
          alignSelf: 'center',
          paddingLeft: '3px !important',
          '&:focus-within': {
            outline: '2px auto #6D65D7',
            outlineOffset: '2px'
          }
        }
      }}
    />
  );
};

export default memo(Checkbox);
