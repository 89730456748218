import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { InternalClaimStatus } from '@hobt/claim-domain';

import FormDropdown from 'Components/Inputs/FormDropdown';

import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';

import { useRefundLetter } from '../RefundRequestLetter/RefundLetterContext';

import { RefundReasonPropsFields } from './types';

import styles from './styles.module.scss';

const RefundReason: FC<RefundReasonPropsFields> = ({ reasonRefundLabel, reasonRefund }) => {
  const { setValue, watch } = useFormContext();
  const {
    state: { reasonCode },
    dispatch
  } = useRefundLetter();

  const { claimData } = useHBTFormContext();

  const watchedReason = watch('refundReason', reasonCode);

  // Default Value and RefundLetterContext sync
  useEffect(() => {
    if (reasonCode == null) {
      return;
    }

    setValue('refundReason', reasonCode, { shouldDirty: true });
  }, [reasonCode]);

  // Push update to context from UI change
  useEffect(() => {
    dispatch({ type: 'updateReasonCode', value: watchedReason });
  }, [watchedReason]);

  const refundReasonDisabled = ![
    InternalClaimStatus.Paid,
    InternalClaimStatus.NoClaimPayable
  ].includes(claimData?.profile?.claimStatus?.code);

  return (
    <FormDropdown
      isDisabled={refundReasonDisabled}
      name={'refundReason'}
      label={reasonRefundLabel}
      className={styles.select}
      options={reasonRefund[0]?.fields?.listItems ?? []}
      disableDefaultSelect={false}
    />
  );
};

export default RefundReason;
