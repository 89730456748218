import SitecoreListItem from 'Constants/Types/SitecoreListItem';

function PadWithLeadingZero(value: number | string): string {
  if (value == null || value === '' || value < 0) {
    return '';
  }

  return value >= 0 && value < 10 ? String(value).padStart(2, '0') : value.toString();
}

export function ConcatenateAmortizationValuesToString(
  year: number | string,
  month: number | string
) {
  return (year == null && month == null) || (year === '' && month === '')
    ? ''
    : PadWithLeadingZero(year) + PadWithLeadingZero(month);
}

export function BuildDropDownDictionary(listItems: SitecoreListItem[] | undefined) {
  const dictionary = new Map<string, string>();

  if (listItems != null) {
    listItems.forEach((item: SitecoreListItem) => {
      if (item.fields.itemValue.value != null && item.fields.itemName.value != null) {
        dictionary.set(item.fields.itemValue.value, item.fields.itemName.value);
      }
    });
  }

  return dictionary;
}
