import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';

import FormInputCalendar from '../FormInputCalendar';
import FormInputCurrency from '../FormInputCurrency';
import { RangeStrategy, FormInputRangeProps } from './FormInputRange.types';
import './FormInputRange.css';
import GetErrorMessage from '../ErrorMessage';
import _ from 'lodash-es';

const getTitle = (props: FormInputRangeProps, elementName: string) => {
  const titleString =
    elementName === props.rangeFromName
      ? i18n.t('DefaultManagementTools-Range-From')
      : i18n.t('DefaultManagementTools-Range-To');

  return {
    value: titleString
  };
};

const currencyRange = (props: FormInputRangeProps, elementName: string) => (
  <FormInputCurrency
    id={elementName}
    name={elementName}
    title={getTitle(props, elementName)}
    control={props.control}
    setValueHandler={props.setValueHandler}
    register={props.register}
    hideTitle={true}
    errors={props.errors}
    disablePlaceholder={props.disablePlaceholder}
    enableSeparator={props.enableSeparator}
    disablePrefix={props.disablePrefix}
    disableSuffix={props.disableSuffix}
    {...(props.isRequired && { required: props.isRequired })}
  />
);

const dateRange = (props: FormInputRangeProps, elementName: string) => (
  <FormInputCalendar
    id={elementName}
    name={elementName}
    title={getTitle(props, elementName)}
    setValueHandler={props.setValueHandler}
    register={props.register}
    control={props.control}
    hideTitle={true}
    errors={props.errors}
    language={i18n.language || 'en'}
    disabled={props.disableCalendarRange}
    {...(props.isRequired && { required: props.isRequired })}
  />
);

const FormInputRange: React.FC<FormInputRangeProps> = (props) => {
  const rangeStrategies: RangeStrategy = {
    currency: currencyRange,
    date: dateRange
  };

  const handleRange = (props: FormInputRangeProps, elementName: string) => {
    // const transformer = lodash.get(rangeStrategies, props.rangeType);
    const transformer = _.get(rangeStrategies, props.rangeType);

    return transformer(props, elementName);
  };

  const rangeErrors =
    // lodash.get(props.errors, props.rangeFromName) || lodash.get(props.errors, props.rangeToName);
    _.get(props.errors, props.rangeFromName) || _.get(props.errors, props.rangeToName);

  return (
    <React.Fragment>
      <div className="form__element form__element--2-column">
        <fieldset>
          <legend id={`${props.id}Label`} className={rangeErrors && 'form-error__label'}>
            <Text field={props.title} />
          </legend>
          <div className="row card__body-row">
            <div
              id={`${props.id}Range`}
              className="form__element form__element--2-column col-5 form__element--range"
              aria-describedby={`${props.id}ErrorDesc`}
            >
              {handleRange(props, props.rangeFromName)}
            </div>
            <div className="form__element form__element--2-column col-2 form__element--range">
              <span className="form__element--range--to">
                {i18n.t('DefaultManagementTools-Range-To').toLocaleLowerCase()}
              </span>
            </div>
            <div
              className="form__element form__element--2-column col-5 form__element--range"
              aria-describedby={`${props.id}ErrorDesc`}
            >
              {handleRange(props, props.rangeToName)}
            </div>
          </div>
          {!props.hideError && (
            <div className="form__fieldNotificationHolder">
              {rangeErrors && (
                <span
                  className={`form-error__sub-text`}
                  aria-live="assertive"
                  id={`${props.id}ErrorDesc`}
                >
                  {GetErrorMessage(rangeErrors.type as string, rangeErrors.message as string)}
                  <input
                    type="hidden"
                    className="thisFieldHasErrors"
                    value={props.cardName && props.cardName}
                  />
                </span>
              )}
            </div>
          )}
        </fieldset>
      </div>
    </React.Fragment>
  );
};

export default FormInputRange;
