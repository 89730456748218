import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Element } from 'react-scroll';
import { Prompt, useHistory } from 'react-router-dom';
import i18n from 'i18next';
import { useFeature } from 'flagged';
import { FormProvider, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';

import { Card } from 'Components/Card';
import { Button } from 'Components/Common/Button';
import { CardBody } from 'Components/Card/CardBody';
import { CardHeader } from 'Components/Card/CardHeader';
import { CardFooter } from 'Components/Card/CardFooter';
import { Modal } from 'Components/Common/Modal';

import { LoaderAnimation } from 'Feature/CommonComponents/UserControls';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';
import { modifyUserReducer } from 'Feature/UserManagement/components/ModifyUser/modifyUserReducer';
import { ModifyUserReducerState } from 'Feature/UserManagement/components/ModifyUser/types';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';

import ModifyExternalUserContextProvider from './ModifyExternalUserContext';
import { useModifyExternalUserFunctions } from './useModifyExternalUserFunctions';

import styles from './styles.module.scss';
import ModifyExternalUserProps from 'Feature/UserManagement/models/ModifyExternalUserProps';
import { useExternalUserApiFunctions } from './useExternalUserApiFunctions';
import { reInviteUserRequest, reSyncUserRequest } from '../api/UserApiHandler.service';
import { UserApiResponse } from 'Feature/UserManagement/models/types';
import FormToggle from 'Components/Inputs/FormToggle';
import { frontEndAddExternalUserSchema } from '@hobt/form-schema-validators';
import { UserAccessRecord } from 'Feature/UserManagement/models/UserAccessProps';
import { UserType } from '@hobt/constants';

// Ignoring next block as it is used to init reducer state
/* istanbul ignore next */
const initialReducerState: ModifyUserReducerState = {
  isBypass: false,
  modalActive: false,
  isLoading: false,
  isModalLoading: false,
  isPageLoading: false,
  toastProps: {
    isActive: false,
    type: ApplicationStates.DEFAULT,
    title: '',
    content: '',
    onCloseCallback: () => true
  },
  updateModalActive: false
};

const ModifyExternalUser = ({ fields, rendering }: ModifyExternalUserProps) => {
  const [addAccess, setAddAccess] = useState<boolean>(false);
  const [toastState, setToastState] = useState<ApplicationStates>(ApplicationStates.DEFAULT);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string>('');
  const [isSubmitFailed, setSubmitFailed] = useState<boolean>(false);

  const isServerSSR = isServer();

  const userId = useMemo(() => {
    const urlParams = new URLSearchParams(!isServerSSR ? window.location.search : '');
    return urlParams.get('id') ?? undefined;
  }, []);

  const isInternal = useFeature(FeatureFlags.INTERNAL);
  const authenticationContext = useAuthenticationContext();
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const { push } = useHistory();

  const methods = useForm({
    resolver: joiResolver(frontEndAddExternalUserSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  });

  const [state, dispatch] = useReducer(modifyUserReducer, initialReducerState);

  const { isLoading, isPageLoading, isModalLoading, modalActive, isBypass, toastProps } = state;

  const { userData, onUserDelete, onSubmit, onError } = useExternalUserApiFunctions(
    dispatch,
    push,
    authenticationContext,
    userId
  );

  const {
    isReadOnlyUser,
    isUserDisabled,
    sectionsLookup,
    isEditingOwnProfile,
    initializeFormValues,
    onDismissModal,
    onDeleteAccountButtonClick,
    onCancel,
    onDismissUpdateModal
  } = useModifyExternalUserFunctions(
    {
      reset: methods.reset,
      setValue: methods.setValue,
      register: methods.register
    },
    isInternal as boolean,
    dispatch,
    push,
    userData,
    sitecoreContext
  );
  const [showUserActive, setShowUserActive] = useState<boolean>(true);
  const { dirtyFields } = methods.formState;

  // unregister invalid feilds while submit
  function unRegisterUserAccessesFieldsNotInSchema() {
    if (userData && userData.userAccesses) {
      userData.userAccesses.forEach((userAccess, index) => {
        const fieldsToUnregister = [
          `userAccesses[${index}].access.arrears.roleCode`,
          `userAccesses[${index}].access.claim.roleCode`,
          `userAccesses[${index}].access.default.roleCode`,
          `userAccesses[${index}].financialInstitutionCode`,
          `userAccesses[${index}].recordCreatedTimestamp`,
          `userAccesses[${index}].recordCreatedUserID`,
          `userAccesses[${index}].recordUpdatedTimestamp`,
          `userAccesses[${index}].recordUpdatedUserID`,
          `userAccesses[${index}].userAccessID`,
          `userAccesses[${index}].userAuthorizationScope`,
          `userAccesses[${index}].userID`
        ];

        fieldsToUnregister.forEach((field) => {
          methods.unregister(field);
        });
      });
    }
  }

  const fieldsToUnregister = [
    'adOID',
    'adContact',
    'operation',
    'recordCreatedTimestamp',
    'recordCreatedUserID',
    'recordUpdatedBy',
    'recordUpdatedTimestamp',
    'recordUpdatedUserID',
    'internalContact.employeeID'
  ];

  function unRegisterFieldsNotInSchema() {
    fieldsToUnregister.forEach((field) => {
      methods.unregister(field);
    });
  }

  useEffect(() => {
    unRegisterFieldsNotInSchema();
    unRegisterUserAccessesFieldsNotInSchema();
  }, [dirtyFields]);

  useEffect(() => {
    if (isServer() === false) {
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, []);

  // isDirty check
  useEffect(() => {
    // onbeforeunload should trigger on back, forward, refresh, and link button clicks
    if (!isServerSSR) {
      window.onbeforeunload = () => {
        // trigger browser warning
        // returning anything, will trigger the prompt,
        // return string only read on IE:
        return Object.keys(dirtyFields).length > 0 && !isBypass
          ? i18n.t('DefaultSubmission-IsDirty')
          : null;
      };
    }
  }, [dirtyFields, isBypass]);

  const updateValidationData = () => {
    userData &&
      userData.userAccesses.length > 0 &&
      userData.userAccesses.map((access, index) => {
        methods.unregister(`userAccesses[${index}].userAccessID`);
      });
  };

  useEffect(() => {
    updateValidationData();
    methods.register('userTypeCode');
    methods.setValue('userTypeCode', UserType.External);
  }, [methods.handleSubmit]);

  useEffect(() => {
    initializeFormValues;
    setShowUserActive(!isUserDisabled);
  }, [userData]);

  if (isPageLoading) {
    return <LoaderAnimation />;
  }

  const handleAddAccessRow = () => {
    setAddAccess(!addAccess);
  };

  const onToggleShowUserActive = () => {
    setShowUserActive(!showUserActive);
    showUserActive && onDeleteAccountButtonClick();
  };

  const onReSyncUser = async () => {
    const user = [methods.getValues('emailID')];
    const result = await reSyncUserRequest(authenticationContext, user);
    if (result === UserApiResponse.Success) {
      setShowToast(true);
      setToastMsg(i18n.t('UserManagement-Users-ResyncSuccessToast'));
      setToastState(ApplicationStates.SUCCESS);
    } else {
      setShowToast(true);
      setToastMsg(i18n.t('UserManagement-Users-ResyncErrorToast'));
      setToastState(ApplicationStates.ERROR);
    }
  };

  const onReinviteUser = async () => {
    const user = {
      emailID: methods.getValues('emailID')
    };
    const result = await reInviteUserRequest(authenticationContext, user);
    if (result === UserApiResponse.Success) {
      setShowToast(true);
      setToastMsg(i18n.t('UserManagement-Users-ReInviteSuccessToast'));
      setToastState(ApplicationStates.SUCCESS);
    } else {
      setShowToast(true);
      setToastMsg(i18n.t('UserManagement-Users-ReInviteErrorToast'));
      setToastState(ApplicationStates.ERROR);
    }
  };

  const onToastCloseHandler = () => setShowToast(false);

  const onRowDeleteUpdateUser = (id: string | undefined) => {
    // Get the current form data
    const formData = methods.getValues();
    // Find the index of the row to delete in the form data
    const indexToDelete = formData.userAccesses.findIndex(
      (row: UserAccessRecord) => row.userAccessID === id
    );
    // If found, remove it from the form data
    if (indexToDelete !== -1) {
      formData.userAccesses.splice(indexToDelete, 1);
      methods.reset(formData); // Reset the form data with the updated array
    }
  };

  const onDismissDeactivateModal = () => {
    setShowUserActive(true);
    onDismissModal();
  };

  return (
    <>
      <ContentLoadingModal
        display={isLoading}
        fields={{
          heading: { value: i18n.t('Globals-Saving-Heading') },
          description: { value: i18n.t('Globals-Saving-Description') }
        }}
      />
      <ToastNotification
        type={toastState}
        isActive={showToast}
        title={
          toastState === ApplicationStates.ERROR
            ? i18n.t('DefaultSubmission-ErrorToastTitle')
            : i18n.t('DefaultActions-SystemSuccessToast-Action-Title')
        }
        content={toastMsg}
        onCloseCallback={() => onToastCloseHandler()}
      />
      <Card>
        <CardHeader separator>
          <Text
            tag="h2"
            field={{
              value: fields.pageTitle?.value?.replace(
                '{username}',
                `${userData?.contact?.firstName} ${userData?.contact?.lastName}`
              )
            }}
          />
        </CardHeader>

        <ModifyExternalUserContextProvider
          value={{
            userData: userData?.userAccesses
          }}
        >
          <FormProvider {...methods}>
            <form
              id="accountInformationForm"
              onSubmit={methods.handleSubmit(onSubmit, onError)}
              noValidate
              className={`form add-user-form ${styles.formWrapper}`}
            >
              <fieldset disabled={isEditingOwnProfile} role="group" aria-label="field-group">
                <CardBody>
                  <div className={styles.cardItem}>
                    <div className="row">
                      <section className="col-12">
                        {rendering?.placeholders?.sections?.map((card: any, index: number) => (
                          <Element name={card.componentName} key={card.componentName + index}>
                            {sectionsLookup[card.componentName] != null &&
                              React.createElement(sectionsLookup[card.componentName], {
                                fields: card.fields,
                                key: index,
                                isModify: true
                              })}
                          </Element>
                        ))}
                        <div className={styles.actionButtons}>
                          <div>
                            <Button
                              responsive
                              name="addUserAccessBtn"
                              text={fields.userAccessBtnText}
                              ariaText={fields.userAccessBtnText}
                              onClick={handleAddAccessRow}
                              type="button"
                              disabled={!showUserActive}
                            />
                          </div>
                          <div>
                            <Button
                              secondaryButton
                              name="reSyncUserBtn"
                              text={fields.reSyncUserBtnText}
                              ariaText={fields.reSyncUserBtnText}
                              onClick={onReSyncUser}
                              type="button"
                              disabled={!showUserActive}
                            />
                          </div>
                          <div>
                            <Button
                              secondaryButton
                              name="reInviteUserBtn"
                              text={fields.reInviteUserBtnText}
                              ariaText={fields.reInviteUserBtnText}
                              onClick={onReinviteUser}
                              type="button"
                              disabled={!showUserActive}
                            />
                          </div>
                        </div>
                        <div className={styles.showUserActive}>
                          <FormToggle
                            onChange={onToggleShowUserActive}
                            isChecked={showUserActive}
                            id="showUserActive"
                            testId="showUserActive"
                            staticText={fields.activeUserBtnText?.value}
                          />
                        </div>
                      </section>
                    </div>
                    <div>
                      {rendering?.placeholders?.sectionAuth?.map((card: any, index: number) => (
                        <Element name={card.componentName} key={card.componentName + index}>
                          {sectionsLookup[card.componentName] != null &&
                            React.createElement(sectionsLookup[card.componentName], {
                              fields: card.fields,
                              key: index,
                              addAccessRow: addAccess,
                              handleAddAccessRow,
                              showUserActive,
                              isModify: true,
                              onRowDeleteUpdateUser
                            })}
                        </Element>
                      ))}
                    </div>
                    <CardFooter separator>
                      <div>
                        <div className={styles.addButtons}>
                          <Button
                            responsive
                            name="submitBtn"
                            text={fields.submitBtnText}
                            ariaText={fields.submitBtnText}
                            type="submit"
                            disabled={isReadOnlyUser}
                          />
                        </div>
                        <div className={styles.addButtons}>
                          <Button
                            secondaryButton
                            responsive
                            name="cancelBtn"
                            text={fields.cancelBtnText}
                            ariaText={fields.cancelBtnText}
                            onClick={() => onCancel('external')}
                            type="button"
                          />
                        </div>
                      </div>
                    </CardFooter>
                  </div>
                </CardBody>
              </fieldset>
            </form>
          </FormProvider>
        </ModifyExternalUserContextProvider>
      </Card>

      <Prompt
        when={Object.keys(dirtyFields)?.length > 0 && !isBypass}
        message={i18n.t('DefaultSubmission-IsDirty')}
      />

      <ToastNotification
        isActive={toastProps.isActive}
        type={toastProps.type}
        title={toastProps.title}
        content={toastProps.content}
        onCloseCallback={toastProps.onCloseCallback}
      />
      <Modal
        type={ApplicationStates.SUCCESS}
        style="success"
        isActive={modalActive}
        title={fields.deleteAccount?.fields.heading.value || ''}
        content={fields.deleteAccount?.fields?.content?.value}
        isLoading={isModalLoading}
        onCloseCallback={onDismissDeactivateModal}
        onPrimaryCallback={onUserDelete}
        onSecondaryCallback={onDismissDeactivateModal}
        fields={{
          saveButton: {
            value: fields.deleteAccount?.fields?.acceptButton?.value
          },
          cancelButton: {
            value: fields.deleteAccount?.fields.cancelButton?.value
          }
        }}
      />
    </>
  );
};
export default ModifyExternalUser;
