import React, { useState, useEffect } from 'react';
import {
  ExternalTabUserInventoryRecords,
  UsersExternalPropsFields
} from 'Feature/UserManagement/models/ManageUsersInternalProps';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { useDataGridEngine } from 'Components/Hooks/DataGridEngine';
import { DataTableFooter } from 'Components/Common/DataTableFooter';
import { SearchBar } from 'Components/PageComponents/SearchBar';
import { SearchFieldOptions } from 'Components/PageComponents/SearchBar/types';
import FormToggle from 'Components/Inputs/FormToggle';
import styles from './styles.module.scss';
import i18n from 'i18next';
import { config } from '../../../../../config';
import { useHistory } from 'react-router-dom';
import { pathNames } from 'Constants/pathNames';

export const ExternalTabUserInventory = ({ fields }: UsersExternalPropsFields) => {
  const [rowInfo, setRowInfo] = useState<any>();
  const [isAdminActive, setIsAdminActive] = useState(false);
  const history = useHistory();

  const onApiErrorCallback = () => {};

  const {
    rowData,
    currentPage,
    itemsPerPage,
    onItemsPerPageChange,
    totalItems,
    totalPages,
    itemRangeFrom,
    itemRangeTo,
    isLoading: isInventoryLoading,
    onPagerClick,
    onSort,
    onSearch,
    onSearchReset,
    getSortDirectionForField,
    getTableAccessibilityText
  } = useDataGridEngine<ExternalTabUserInventoryRecords>({
    apiBaseUrl: config.userApi.urls.externalInventory,
    onApiErrorCallback,
    defaultItemsPerPage: 10,
    isAdmin: isAdminActive,
    isUserModule: true
  });
  const [hasSearched, setHasSearched] = React.useState<boolean>(false);
  const searchByFields: SearchFieldOptions[] = [
    {
      displayText: fields.email.value ?? '',
      value: 'EmailID',
      type: 'text'
    },
    {
      displayText: fields.name.value ?? '',
      value: 'FullName',
      type: 'text'
    }
  ];

  const onRowSelect = (row: any) => {
    history.push(`/${i18n.language}${pathNames.getOneExternalUser}?id=${row.userID}`);
  };

  useEffect(() => {
    if (rowData && rowData.length > 0) setRowInfo(rowData);
  }, [rowData]);

  const onToggleShowAdminActive = () => {
    setIsAdminActive(!isAdminActive);
  };
  return (
    <div>
      <div className={styles.searchBarWrapper}>
        <div className={styles.searchBarWidth}>
          <SearchBar
            name="userInventorySearchBar"
            fieldLabelAriaText={i18n.t('SearchBar-SearchField')}
            clearButtonAriaText={i18n.t('SearchBar-ClearButton')}
            searchButtonAriaText={i18n.t('SearchBar-SearchButton')}
            placeholderText={i18n.t('SearchBar-PlaceholderText')}
            searchFieldOptions={searchByFields}
            onClear={() => {
              setHasSearched(false);
              onSearchReset();
            }}
            onSearch={(field: string, value: string) => {
              setHasSearched(true);
              onSearch(field, value);
            }}
            count={totalItems}
            displaySearchResultMessage={false}
          />
        </div>
        <div className={styles.adminToggle}>
          <FormToggle
            id={`adminFormToggle`}
            testId={`adminFormToggle`}
            staticText={fields.admin?.value}
            isChecked={isAdminActive}
            onChange={onToggleShowAdminActive}
          />
        </div>
      </div>
      {hasSearched && totalItems != null && totalItems > 0 ? (
        <div className={styles.noSearchResultsMessage}>
          <Text field={{ value: `${totalItems}  ${i18n.t('Globals-Table-Search-To-Display')}` }} />
        </div>
      ) : (
        ''
      )}
      {totalItems === 0 && (
        <div className={styles.noSearchResultsMessage}>
          <Text field={{ value: i18n.t('Globals-Table-No-Search-To-Display') }} />
        </div>
      )}
      {totalItems > 0 && (
        <div className={styles.dataTable}>
          <DataTable
            name="manageUsers"
            isLoading={isInventoryLoading}
            caption={{ value: getTableAccessibilityText() ?? '' }}
            scrollOnHorizontalOverflow
          >
            <DataTableHeader>
              <DataTableHeaderItem
                sortable
                onSortCallback={onSort}
                sortDirection={getSortDirectionForField('emailId')}
                name="emailId"
                displayText={fields.email}
              />
              <DataTableHeaderItem
                sortable
                onSortCallback={onSort}
                sortDirection={getSortDirectionForField('FirstName')}
                name="FirstName"
                displayText={fields.firstName}
              />
              <DataTableHeaderItem
                sortable
                onSortCallback={onSort}
                sortDirection={getSortDirectionForField('LastName')}
                name="LastName"
                displayText={fields.lastName}
              />
            </DataTableHeader>
            <DataTableBody zebra>
              {rowInfo &&
                rowInfo.map((row: ExternalTabUserInventoryRecords, index: Number) => (
                  <DataTableRow
                    name={row.userID}
                    key={`record${index}`}
                    onClick={() => onRowSelect(row)}
                  >
                    <TextCell name="emailId" text={row.emailID ?? ''} />
                    <TextCell name="firstName" text={row.firstName ?? ''} />
                    <TextCell name="lastName" text={row.lastName ?? ''} />
                  </DataTableRow>
                ))}
            </DataTableBody>
          </DataTable>
          <div className={`${styles.dataTableFooter} row col-12 justify-content-end`}>
            <DataTableFooter
              name="manageUsersList"
              currentPage={currentPage}
              totalPages={totalPages || 1}
              itemsPerPageLabel={{
                value: i18n.t('DefaultsInventoryTable-ResultsPerPageDropdown-Title')
              }}
              itemsXofYText={`${itemRangeFrom || 0} - ${itemRangeTo || 0} ${i18n.t(
                'Globals-Grid-ResultsPerPageDropdown-Of'
              )}`}
              totalItems={totalItems}
              pagerClickCallback={onPagerClick}
              itemsPerPageOnChangeCallback={onItemsPerPageChange}
              itemsPerPageValue={itemsPerPage.toString()}
            />
          </div>
        </div>
      )}
    </div>
  );
};
