import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { LanguageShort, ModuleMapping, UserRole } from '@hobt/constants';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';

import { PageCardContainer } from 'Components/PageComponents/PageCardContainer';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import { Button } from 'Components/Common/Button';
import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { DataTableFooter } from 'Components/Common/DataTableFooter';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { useDataGridEngine } from 'Components/Hooks/DataGridEngine';
import { defaultEmptyString, handleEmpty } from 'Components/Common/Api/utils/HandleEmpty';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { BuildDropDownDictionary } from 'Components/Common/Helpers';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';
import ArrearsErrorRecordProps, {
  ArrearsErrorRecordInventoryRecord,
  ArrearsErrorRecordInventoryRecordItem
} from './types';
import useArrearsErrorRecordFunctions from './useArrearsErrorRecordFunctions';
import ReplaceArrears from '../ReplaceArrears';
import { ArrearsSubmitResponse } from '../ArrearsSubmissionForm/types';
import { config } from '../../../../config';
import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const ArrearsErrorRecord = (props: ArrearsErrorRecordProps) => {
  const history = useHistory();
  const fileId = '{fileID}';
  const urlParams = new URLSearchParams(!isServer() ? window.location.search : '');
  const id = urlParams.get('id') || '';
  const inventoryRecord = config.arrearsApi.urls.validationResult.replace(fileId, id);

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory.sitecoreContext as HbtSitecoreContextType;
  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.arrears,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );
  const userDetails = sitecoreContext && sitecoreContext.user;

  const onApiErrorCallback = () => {
    failInventoryHandler();
  };

  const [toastErrorMessage, setToastErrorMessage] = useState<
    string | TemplateStringsArray | (string | TemplateStringsArray)[]
  >('Globals-UnknownErrorMessage');

  const {
    rowData,
    currentPage,
    itemsPerPage,
    onItemsPerPageChange,
    totalItems,
    totalPages,
    itemRangeFrom,
    itemRangeTo,
    onPagerClick,
    onSort,
    getSortDirectionForField
  } = useDataGridEngine<ArrearsErrorRecordInventoryRecord>({
    apiBaseUrl: inventoryRecord,
    onApiErrorCallback,
    defaultItemsPerPage: 10
  });

  const {
    isActive,
    pageTitle,
    flyoutData,
    isInventoryToast,
    showToast,
    toastState,
    errorCode,
    loadingState: { isLoading, spinnerHeading, spinnerDescription },
    setLoadingState,
    onBtnClick,
    onChevronClick,
    onErrorhandler,
    fileDownloadBtn,
    onSuccessHandler,
    replaceAttachment,
    setPageTitleValue,
    onToastCloseHandler,
    failInventoryHandler
  } = useArrearsErrorRecordFunctions(history, id, userDetails, rowData);

  useEffect(() => {
    if (rowData != null) {
      setPageTitleValue(rowData[0]?.fileName, props.fields.pageTitle.value);
    }
  }, [rowData]);

  useEffect(() => {
    switch (errorCode) {
      case ArrearsSubmitResponse.BadRequest:
        setToastErrorMessage('DefaultSubmission-BadRequest');
        break;
      case ArrearsSubmitResponse.NotAuthorized:
        setToastErrorMessage('DefaultSubmission-NotAuthorized');
        break;
      case ArrearsSubmitResponse.FileUploadError:
        setToastErrorMessage('Errors-HBT_ERR_6006');
        break;
      case ArrearsSubmitResponse.MissingFileParam:
        setToastErrorMessage('Errors-HBT_ERR_6001');
        break;
      case ArrearsSubmitResponse.MaxFileCountReached:
        setToastErrorMessage('Errors-HBT_ERR_6004');
        break;
      case ArrearsSubmitResponse.ServerError:
        setToastErrorMessage('Globals-Toast-Server-Error-Title');
        break;
      default:
        setToastErrorMessage('Globals-UnknownErrorMessage');
    }
  }, [errorCode]);

  const validationMessagesDictionary = BuildDropDownDictionary(
    props?.fields?.listOfValidationMessages?.fields?.listItems
  );

  const errorCodes = BuildDropDownDictionary(props?.fields?.arrearsErrorCodes?.fields?.listItems);

  return (
    <>
      <ContentLoadingModal
        display={isLoading}
        fields={{
          heading: { value: i18n.t(spinnerHeading ?? 'Globals-InProgress-Heading') },
          description: { value: i18n.t(spinnerDescription ?? 'Globals-InProgress-Description') }
        }}
      />

      <ToastNotification
        isActive={isInventoryToast}
        type={ApplicationStates.ERROR}
        title={i18n.t(`Globals-Toast-Server-Error-Title`)}
        content={{
          value: i18n.t('DefaultsInventoryTable-SystemErrorToast-Message')
        }}
        onCloseCallback={onToastCloseHandler}
      />
      <ToastNotification
        type={toastState}
        isActive={showToast}
        title={i18n.t(
          toastState === ApplicationStates.SUCCESS
            ? 'Globals-Toast-Success-Title'
            : toastState === ApplicationStates.ERROR
            ? 'Globals-Toast-Error-Title'
            : ''
        )}
        content={{
          value: i18n.t(
            toastState === ApplicationStates.SUCCESS
              ? 'Globals-Toast-Success-Changes-Saved'
              : toastState === ApplicationStates.ERROR
              ? toastErrorMessage
              : ''
          )
        }}
        onCloseCallback={onToastCloseHandler}
      />
      <PageCardContainer name="ArrearsErrorRecord">
        <div className={`${styles.layoutContainer}`}>
          <div className={`${styles.titleContainer}`}>
            <div className={styles.titleWrapper}>
              <button
                data-testid="addAttachmentsFormChevronButton"
                onClick={onChevronClick}
                className={styles.chevronButton}
              >
                <span className="material-icons align-middle icon--size-32">chevron_left</span>
              </button>
              <div>
                <Text tag="h2" field={{ value: pageTitle }} />
              </div>
            </div>
            <Text
              tag="h3"
              field={{
                value: handleEmpty(
                  i18n.language === LanguageShort.English
                    ? rowData != null && rowData?.[0]?.englishLenderName
                    : rowData != null && rowData?.[0]?.frenchLenderName
                )
              }}
            />
          </div>
          <div className={`${styles.buttonsContainer}`}>
            <Button
              name="submitBtn"
              ariaText={props.fields.replaceBtn}
              text={props.fields.replaceBtn}
              onClick={replaceAttachment}
              disabled={isReadOnlyUser}
            />
            <Button
              name="downloadBtn"
              ariaText={props.fields.downloadBtn}
              text={props.fields.downloadBtn}
              onClick={fileDownloadBtn}
              disabled={isReadOnlyUser}
              secondaryButton
            />
          </div>
        </div>
        <div className={styles.emptyDiv}></div>
        {rowData?.[0]?.validationResults && rowData?.[0].validationResults.length > 0 && (
          <>
            <div className={styles.dataTable}>
              <DataTable name="arrearsErrorRecord" caption={props.fields.tableCaption}>
                <DataTableHeader>
                  <DataTableHeaderItem
                    sortable
                    onSortCallback={onSort}
                    sortDirection={getSortDirectionForField('cmhcLoanAccountNumber')}
                    name="cmhcLoanAccountNumber"
                    displayText={props.fields.loanNumber}
                  />
                  <DataTableHeaderItem
                    name="validationResults"
                    displayText={props.fields.validationResults}
                  />
                  <DataTableHeaderItem
                    name="validationMessage"
                    displayText={props.fields.validationMessage}
                  />
                </DataTableHeader>

                <DataTableBody zebra>
                  {rowData &&
                    rowData[0]?.validationResults?.map(
                      (row: ArrearsErrorRecordInventoryRecordItem, index: number) => (
                        <DataTableRow
                          name={`${row.cmhcLoanAccountNumber}-${index}`}
                          key={`${row.cmhcLoanAccountNumber}-${index}`}
                        >
                          <TextCell
                            name="cmhcLoanAccountNumber"
                            text={row.cmhcLoanAccountNumber.toString()}
                          />
                          <TextCell
                            name="validationResults"
                            text={
                              row.problemTypeCode != null
                                ? errorCodes.get(row.problemTypeCode.toString())
                                : defaultEmptyString
                            }
                          />
                          <TextCell
                            name="validationMessage"
                            text={handleEmpty(
                              validationMessagesDictionary.get(
                                row.problemCode != null
                                  ? row.problemCode.toString()
                                  : defaultEmptyString
                              )
                            )}
                          />
                        </DataTableRow>
                      )
                    )}
                </DataTableBody>
              </DataTable>
            </div>

            <div className={styles.dataTableFooter}>
              <DataTableFooter
                name="arrearsErrorRecord"
                currentPage={currentPage}
                totalPages={totalPages || 1}
                itemsPerPageLabel={{
                  value: i18n.t('Globals-Grid-ResultsPerPageDropdown-Show')
                }}
                itemsXofYText={`${itemRangeFrom || 0} - ${itemRangeTo || 0} ${i18n.t(
                  'Globals-Grid-ResultsPerPageDropdown-Of'
                )}`}
                totalItems={totalItems}
                pagerClickCallback={onPagerClick}
                itemsPerPageOnChangeCallback={onItemsPerPageChange}
                itemsPerPageValue={itemsPerPage.toString()}
              />
            </div>
          </>
        )}
        {!rowData?.[0]?.validationResults ||
          (rowData?.[0]?.validationResults.length === 0 && (
            <div className={styles.noSearchResultsMessage}>
              <Text field={{ value: i18n.t('Globals-Table-No-Search-To-Display') }} />
            </div>
          ))}
      </PageCardContainer>
      <SideDrawer isActive={isActive} handleOutsideClick={onBtnClick}>
        <ReplaceArrears
          flyoutData={flyoutData}
          name="replaceArrearsFlyoutForm"
          fields={props?.fields?.replaceArrearsFlyout?.fields}
          setIsLoadingCallback={setLoadingState}
          onCancelCallback={onBtnClick}
          onErrorCallback={onErrorhandler}
          onSuccessCallback={onSuccessHandler}
        />
      </SideDrawer>
    </>
  );
};

export default ArrearsErrorRecord;
