import React, { memo, useCallback, useState } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import ResponsiveImage from '../../../Shared/ResponsiveImageLoader';
import HbtDashboardCard from '../HbtDashboardCard';
import styles from './styles.module.scss';
import { HbtDashboardComponentProps, PortfolioInsuranceCardProps } from './types';

const PI_TOOLS = 'piTools';
const PI_RESOURCES = 'piResources';

const HbtDashboardComponent = ({ fields }: HbtDashboardComponentProps) => {
  const toolCards = fields.cards.filter((card) => {
    const cardType = card.fields.cardType.value;
    return cardType === PI_TOOLS;
  });

  const resourceCards = fields.cards.filter((card) => {
    const cardType = card.fields.cardType.value;
    return cardType === PI_RESOURCES;
  });

  const initialImageLoadingStates = [true, true, true];
  const [loadingStates, setIsLoadingStates] = useState(initialImageLoadingStates);
  const handleImageLoadChange = useCallback(
    (index: number, loadingState: boolean) => {
      setIsLoadingStates((prevStates) => {
        if (prevStates[index] !== loadingState) {
          const newStates = [...prevStates];
          newStates[index] = loadingState;
          return newStates;
        }
        return prevStates;
      });
    },
    [loadingStates]
  );

  return (
    <div className={styles.dashboardMain}>
      <div className={styles.headerImageContainer}>
        <div className={styles.headerTextWrapper} tabIndex={-1} id="dashboard-header-text">
          <h1 className={styles.title}>
            <Text field={fields.title} />
          </h1>
        </div>
        <div className={styles.imageWrapper}>
          <ResponsiveImage
            desktopImage={fields.dashboardMainBgImageLg}
            tabletImage={fields.dashboardMainBgImageMd}
            responsiveClassName={`${styles.dashboardMainImage}`}
            onLoadingChange={(loadingState) => handleImageLoadChange(0, loadingState)}
          />
        </div>
      </div>
      <div className={styles.dashboardToolsMainContainer}>
        <div className={styles.dashboardToolsHeaderTextWrapper}>
          <h2 className={styles.toolsHeader}>
            <Text field={fields.toolsHeader} />
          </h2>
          <span className={styles.toolsSubHeader}>
            <RichText field={fields.toolsSubHeader} />
          </span>
        </div>
        {fields.cards &&
          toolCards.map((card: PortfolioInsuranceCardProps, index: number) => (
            <HbtDashboardCard {...card} cardId={`tool-card-${index}`} key={index} />
          ))}
      </div>
      <div className={styles.dashboardResourcesMainContainer}>
        <div className={styles.dashboardResourcesHeaderTextWrapper}>
          <h2 className={styles.resourcesHeader}>
            <Text field={fields.resourcesHeader} />
          </h2>
          <span className={styles.resourcesSubHeader}>
            <Text field={fields.resourcesSubHeader} />
          </span>
        </div>
        {fields.cards &&
          resourceCards.map((card: PortfolioInsuranceCardProps, index: number) => (
            <HbtDashboardCard {...card} cardId={`resource-card-${index}`} key={index} />
          ))}
      </div>
    </div>
  );
};

export default memo(HbtDashboardComponent);
