import { ModuleKeyword } from '@hobt/constants';

import { ApiClient } from 'Foundation/Api';
import { AuthenticationContextType } from 'Foundation/Authentication';

import { config } from '../../../config';

export const useAttachmentClient = (
  module: ModuleKeyword,
  authContext: AuthenticationContextType
) => {
  const { getWithAuth } = ApiClient(authContext, {
    timeout: config.documentApi.requestTimeout
  });

  const documentDownloadUrl = config.documentApi.urls.get;

  const getDownloadDocumentUrl = async (entityID: string, documentID: string) => {
    const response = await getWithAuth(
      `${documentDownloadUrl}${documentID}?module=${module}&entityID=${entityID}`
    );
    return response;
  };

  return {
    getDownloadDocumentUrl
  };
};
