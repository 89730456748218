import * as React from 'react';
import { PropsWithChildren } from 'react';

import { CardProps } from 'Feature/CommonComponents/ContentComponents/Card/Card.types';
import { CardType } from 'Feature/CommonComponents/Enums';

export const Card: React.FC<CardProps> = (props: PropsWithChildren<CardProps>) => {
  let cardClasses = 'card';

  switch (props.type) {
    case CardType.HORIZONTAL_BLUE:
      cardClasses += ' card--horizontal-blue';
      break;
    case CardType.VERTICAL_BLUE:
      cardClasses += ' card--vertical-blue';
      break;
    case CardType.VERTICAL_GREY:
      cardClasses += ' card--vertical-grey';
      break;
    case CardType.VERTICAL_SUCCESS:
      cardClasses += ' card--vertical-success';
      break;
    case CardType.VERTICAL_WARNING:
      cardClasses += ' card--vertical-warning';
      break;
    case CardType.VERTICAL_ERROR:
      cardClasses += ' card--vertical-error';
      break;
    default: // do nothing
  }

  return <div className={cardClasses}>{props.children}</div>;
};
