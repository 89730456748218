import { AnnouncementItemProps } from 'Feature/DashboardComponents/components/Announcement/types';
import { AnnouncementsColumnPropsFields } from 'Feature/DashboardComponents/components/AnnouncementsColumn/types';
import { useState } from 'react';

export const useAnnouncementFunctions = (fields: AnnouncementsColumnPropsFields) => {
  const [showAllItems, setShowAllItems] = useState<boolean>(false);

  const sortByDate = (date1: AnnouncementItemProps, date2: AnnouncementItemProps) =>
    Date.parse(date2.fields.dateTime.value ?? '') - Date.parse(date1.fields.dateTime.value ?? '');

  const getAnnouncements = (): AnnouncementItemProps[] => {
    return Array.isArray(fields.announcements) && fields.announcements.length > 0
      ? fields.announcements.sort(sortByDate)
      : [];
  };

  const toggleAllItems = () => {
    setShowAllItems((prev) => !prev);
  };

  return { showAllItems, toggleAllItems, getAnnouncements };
};
