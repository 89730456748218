import { useFeature } from 'flagged';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import i18n from 'i18next';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { StageIndicator } from '@hobt/claim-domain';
import { Module, Services, UserRole, UserType } from '@hobt/constants';
import { UserInfo } from '@hobt/user-domain';
import { convertTimestampToDate, getCurrentTimestampDate10Decimal6 } from '@hobt/utils';

import { AttachmentsView } from 'Components/Common/AttachmentsView';
import { useDocumentDownloader } from 'Components/Hooks/DocumentDownloader';
import FormYesNoRadio from 'Components/Inputs/FormYesNoRadios';
import FormRadioGroup from 'Components/Inputs/FormRadioGroup';
import FormTextArea from 'Components/Inputs/FormTextArea';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { BuildDropDownDictionary } from 'Components/Common/Helpers';

import { AttachmentObject } from 'Components/Common/AttachmentsView/types';
import ApproverReviewProps, { AllowedFileTypes } from './types';

import { config } from '../../../../../config';

import styles from './styles.module.scss';

const ARREARS_APPROVED = 3;

const ApproverReview: React.FC<ApproverReviewProps> = ({
  fields,
  isArrears,
  isClaimApproved,
  approverLevel
}: ApproverReviewProps) => {
  const [dataRow, setDataRow] = useState<any>({});

  const { register, setValue, unregister } = useFormContext();
  const isInternalSite = useFeature(FeatureFlags.INTERNAL);
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  let internalUserDetails: UserInfo | undefined;

  if (isInternalSite === true) {
    internalUserDetails = sitecoreContext?.user;
  }
  const isReadOnlyUser =
    internalUserDetails?.moduleRoleMapping[Module.Claim][0] === UserRole.ReadOnly &&
    internalUserDetails?.moduleRoleMapping[Module.Arrears][0] === UserRole.ReadOnly;

  const { downloadDocument } = useDocumentDownloader(Services.ARREARS.toLowerCase());

  const approvalTypeDictionary = BuildDropDownDictionary(fields.approvalType?.fields.listItems);

  useEffect(() => {
    register('attachments');
    register('approverReview.documentIDs');
    setValue('approverReview.documentIDs', []);
    register('approverReview.approvalDecisionCode');
    register('approverReview.approverLevelIndicator');
    register('approverReview.approverName');

    if (isArrears !== true) {
      register('approverReview.approvalOnBehalfOfOtherFlag');
    }

    register('approverReview.approverTitleTypeCode');
    register('approverReview.approverUserID');
    register('approverReview.decisionComment');
    register('approverReview.decisionTimestamp');
    setValue('approverReview.decisionTimestamp', getCurrentTimestampDate10Decimal6());

    if (internalUserDetails != null) {
      setValue('approverReview.approverUserID', internalUserDetails.userID ?? '');
      setValue('approverReview.approverLevelIndicator', approverLevel);

      setValue(
        'approverReview.approverName',
        `${internalUserDetails.contact?.firstName ?? ''} ${
          internalUserDetails.contact?.lastName ?? ''
        }`
      );
      setValue(
        'approverReview.approverTitleTypeCode',
        internalUserDetails.internalContact?.titleTypeCode ?? 10
      );
    }
  }, []);

  const [documentIds, setDocumentIds] = useState<string[]>([]);

  const onSuccessfulUpload = (item: AttachmentObject, index: number) => {
    const attachment = item;

    delete attachment.fileType;
    if (isArrears === true) {
      delete attachment.description;
    }

    unregister(`attachments[${index}].fileType`);
    register(`attachments[${index}].submitterUserID`);
    register(`attachments[${index}].submitterUserTypeCode`);
    setValue(`attachments[${index}].submitterUserID`, internalUserDetails?.userID ?? '');
    setValue(
      `attachments[${index}].submitterUserTypeCode`,
      internalUserDetails?.userTypeCode ?? UserType.Internal
    );

    setDocumentIds([...documentIds, item.documentID]);
  };

  const getApproverRole = (role: number) => {
    return fields?.userLevelList?.fields?.listItems?.find(
      (item: FieldListItem) => item?.fields?.itemValue?.value === role?.toString()
    )?.fields?.itemName?.value;
  };

  useEffect(() => {
    setValue('approverReview.documentIDs', documentIds);
  }, [documentIds]);

  useEffect(() => {
    if (
      isServer() === false &&
      window.sessionStorage.getItem('arrearsApprovalData') != null &&
      isArrears === true
    ) {
      const arrearsData = JSON.parse(window.sessionStorage.getItem('arrearsApprovalData') ?? '');
      setDataRow({
        ...(approverLevel !== StageIndicator.Primary &&
          arrearsData.approverReviews[0] && {
            primaryName: arrearsData.primaryApproverName,
            primaryRole: arrearsData.primaryApproverRole,
            primaryAttachments: arrearsData.primaryDocumentIds,
            primaryComments: arrearsData.primaryDecisionComment ?? fields.notAvailable?.value,
            primaryDate: convertTimestampToDate(arrearsData.primaryApproverDate),
            primaryDecision: approvalTypeDictionary.get(
              arrearsData.approverReviews[0].approvalDecisionCode.toString()
            )
          }),
        ...(approverLevel === ARREARS_APPROVED &&
          arrearsData.approverReviews[1] && {
            primaryName: arrearsData.primaryApproverName,
            secondaryName: arrearsData.secondaryApproverName,
            secondaryRole: arrearsData.secondaryApproverRole,
            secondaryAttachments: arrearsData.secondaryDocumentIds || fields.notAvailable?.value,
            secondaryComments: arrearsData.secondaryDecisionComment || fields.notAvailable?.value,
            secondaryDate: convertTimestampToDate(arrearsData.secondaryApproverDate),
            secondaryDecision: approvalTypeDictionary.get(
              arrearsData.approverReviews[1].approvalDecisionCode.toString()
            )
          })
      });
    }
  }, [window.sessionStorage]);

  const isFieldDisabled =
    isReadOnlyUser || approverLevel === ARREARS_APPROVED || isClaimApproved === true;

  const downloadFile = async (documentID: string) => {
    const arrearsData = JSON.parse(window.sessionStorage.getItem('arrearsApprovalData') ?? '');

    try {
      await downloadDocument(documentID, arrearsData.approvalID);
    } catch (err) {
      /* eslint-disable no-console */
      console.log('download err', err);
    }
  };

  return (
    <div className="m-4 form">
      {approverLevel !== StageIndicator.Primary && (
        <>
          <div className="mb-3 form__element">
            <Text tag="h5" field={fields.titleL1} />
          </div>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.approverNameL1} />
              </small>
            </div>
            <p className="col">
              <Text
                tag="small"
                field={{ value: dataRow?.primaryName ?? i18n.t('Global-Not-Available') }}
              />
            </p>
          </div>

          <hr className="form__divider"></hr>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.approverRoleL1} />
              </small>
            </div>
            <p className="col">
              <Text
                tag="small"
                field={{
                  value: dataRow?.primaryRole
                    ? getApproverRole(dataRow?.primaryRole)
                    : i18n.t('Global-Not-Available')
                }}
              />
            </p>
          </div>

          <hr className="form__divider"></hr>
          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.approvalDecisionL1} />
              </small>
            </div>
            <p className="col">
              <Text
                tag="small"
                field={{ value: dataRow?.primaryDecision ?? i18n.t('Global-Not-Available') }}
              />
            </p>
          </div>

          <hr className="form__divider"></hr>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.approvalDateL1} />
              </small>
            </div>
            <p className="col">
              <Text
                tag="small"
                field={{ value: dataRow?.primaryDate ?? i18n.t('Global-Not-Available') }}
              />
            </p>
          </div>

          <hr className="form__divider"></hr>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.commentsLabel} />
              </small>
            </div>
            <p className="col">
              <Text
                tag="small"
                field={{ value: dataRow?.primaryComments ?? i18n.t('Global-Not-Available') }}
              />
            </p>
          </div>

          <hr className="form__divider"></hr>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.attachmentsLabel} />
              </small>
            </div>
            <div className="col">
              {dataRow?.primaryAttachments?.map((documentID: string, index: number) => (
                <button
                  className={styles.buttonLink}
                  key={index}
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.preventDefault();
                    downloadFile(documentID);
                  }}
                >
                  <Text
                    tag="small"
                    field={{ value: `${fields.approvalAttachmentsL1?.value} ${index + 1}` }}
                    className={styles.attachmentText}
                  />
                </button>
              ))}
            </div>
          </div>

          <hr className="form__divider"></hr>
        </>
      )}

      {approverLevel === ARREARS_APPROVED && (
        <>
          <div className="mb-3 form__element">
            <Text tag="h5" field={fields.titleL2} />
          </div>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.approverNameL2} />
              </small>
            </div>
            <p className="col">
              <Text
                tag="small"
                field={{ value: dataRow?.secondaryName ?? i18n.t('Global-Not-Available') }}
              />
            </p>
          </div>

          <hr className="form__divider"></hr>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.approverRoleL2} />
              </small>
            </div>
            <p className="col">
              <Text
                tag="small"
                field={{
                  value: dataRow?.secondaryRole
                    ? getApproverRole(dataRow?.secondaryRole)
                    : i18n.t('Global-Not-Available')
                }}
              />
            </p>
          </div>

          <hr className="form__divider"></hr>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.approvalDecisionL2} />
              </small>
            </div>
            <p className="col">
              <Text
                tag="small"
                field={{ value: dataRow?.secondaryDecision ?? i18n.t('Global-Not-Available') }}
              />
            </p>
          </div>

          <hr className="form__divider"></hr>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.approvalDateL2} />
              </small>
            </div>
            <p className="col">
              <Text
                tag="small"
                field={{ value: dataRow?.secondaryDate ?? i18n.t('Global-Not-Available') }}
              />
            </p>
          </div>

          <hr className="form__divider"></hr>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.commentsLabel} />
              </small>
            </div>
            <p className="col">
              <Text
                tag="small"
                field={{ value: dataRow?.secondaryComments ?? i18n.t('Global-Not-Available') }}
              />
            </p>
          </div>

          <hr className="form__divider"></hr>

          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.attachmentsLabel} />
              </small>
            </div>
            <div className="col">
              {Array.isArray(dataRow?.secondaryAttachments) &&
              dataRow?.secondaryAttachments.length > 0 ? (
                dataRow.secondaryAttachments.map((file: any, index: number) => (
                  <button
                    className={styles.buttonLink}
                    key={index}
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      e.preventDefault();
                      downloadFile(file);
                    }}
                  >
                    <Text
                      tag="small"
                      field={{ value: `${fields.approvalAttachmentsL2?.value} ${index + 1}` }}
                      className={styles.attachmentText}
                    />
                  </button>
                ))
              ) : (
                <Text tag="small" field={{ value: i18n.t('Global-Not-Available') }} />
              )}
            </div>
          </div>
        </>
      )}

      {approverLevel === StageIndicator.Secondary && (
        <div className="mb-3 form__element">
          <Text tag="h5" field={fields.titleL2} />
        </div>
      )}

      {!isArrears && (
        <>
          <div className={`mb-4 mt-3 ${styles.reduceFont} ${styles.emptyDiv}`}>
            <FormYesNoRadio
              className=""
              isReadOnly={isFieldDisabled}
              name="approverReview.approvalOnBehalfOfOtherFlag"
              label={
                approverLevel === StageIndicator.Secondary
                  ? fields.approvalBehalfL2
                  : fields.approvalBehalfL1
              }
            />
          </div>
        </>
      )}

      {approverLevel !== ARREARS_APPROVED && (
        <div className={`${styles.reduceFont} ${styles.emptyDiv}`}>
          <FormRadioGroup
            className=""
            name="approverReview.approvalDecisionCode"
            label={
              approverLevel === StageIndicator.Secondary
                ? fields.approvalDecisionL2
                : fields.approvalDecisionL1
            }
            radioLabels={fields.radioButtonApproveOptions}
            isReadOnly={isFieldDisabled}
          />
        </div>
      )}

      {approverLevel !== ARREARS_APPROVED && (
        <div className={`form__element ${styles.emptyDivArea}`}>
          <FormTextArea
            className=""
            name="approverReview.decisionComment"
            label={
              approverLevel === StageIndicator.Secondary
                ? fields.commentsOptionalL2
                : fields.commentsOptionalL1
            }
            charLimit={Number(fields?.limitNumber?.value)}
            textAreaHelperText={fields?.characterLimit?.value}
            isReadOnly={isFieldDisabled}
          />
        </div>
      )}

      {approverLevel !== ARREARS_APPROVED && (
        <div className={styles.emptyDivAttachment}>
          {React.createElement(AttachmentsView, {
            isOptional: !StageIndicator.Primary,
            isAllowed: !isFieldDisabled,
            registeredVal: 'attachments',
            fields: fields.attachmentsView.fields,
            allowedFileTypes: AllowedFileTypes,
            endpoint: config.claimApi.urls.upload,
            onUploadSuccessCallback: onSuccessfulUpload
          })}
        </div>
      )}
    </div>
  );
};

export default ApproverReview;
