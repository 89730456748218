import React from 'react';
import i18n from 'i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { ClaimDocumentType } from '@hobt/claim-domain';

import { DocumentAttachmentProps } from 'Feature/Claims/components/Details/AttachmentsNotesCommentsDetails/DocumentAttachment/types';
import { HbtIcon } from 'Components/Common/HbtIcon';

import styles from './styles.module.scss';

export const DocumentAttachment: React.FC<DocumentAttachmentProps> = ({
  attachment,
  onDownloadClick,
  documentTypeLabel,
  entityId,
  correspondenceId
}: DocumentAttachmentProps) => {
  const fileTypeToIconName: { [fileType: string]: string } = {
    xlsx: 'file_excel',
    docx: 'file_word',
    one: 'file_oneNote',
    generic: 'file_generic',
    pdf: 'icon_pdf',
    jpg: 'icon_jpeg',
    csv: 'icon_csv'
  };

  return (
    <div className={styles.documentDownloadWrapper}>
      <HbtIcon
        type={
          attachment.fileType != null
            ? fileTypeToIconName[attachment.fileType]
            : fileTypeToIconName.generic
        }
        key={`${attachment.uniqueFileName}FileIcon`}
      />
      <div className={styles.documentInfo}>
        <p>{attachment.fileName}</p>
        <p>
          <Text field={documentTypeLabel} tag="span" />
          {attachment.documentTypeCode != null &&
            ClaimDocumentType[
              attachment.documentTypeCode as unknown as keyof typeof ClaimDocumentType
            ]}
        </p>
      </div>
      <button
        type="button"
        onClick={() => onDownloadClick(attachment.documentID ?? '', entityId, correspondenceId)}
        aria-label={`${i18n.t('Globals-Download')} ${attachment.fileName}`}
        className={styles.documentDownloadButton}
        data-testid={`${attachment.uniqueFileName}DownloadButton`}
      >
        <HbtIcon type="icon_download" key={`${attachment.uniqueFileName}Download`} />
      </button>
    </div>
  );
};
