import { useFeature } from 'flagged';
import i18n from 'i18next';
import React, { useState, useEffect } from 'react';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { HttpResponseStatusCodes } from '@hobt/constants';
import { StageIndicator } from '@hobt/claim-domain';
import { User, UserInfo } from '@hobt/user-domain';

import FormText from 'Components/Inputs/FormText';
import { getUserEntity } from 'Feature/ApprovalsFinancialAuthority/components/ApprovalForm/ApprovalForm.service';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

import ApprovalStageProps, { ApprovalStageObject } from './types';
import styles from './styles.module.scss';

const DEFAULT_STAGE_OBJECT_VALUES = {
  secondaryApprover: { value: i18n.t('Global-Not-Available') },
  secondaryApproverRole: { value: i18n.t('Global-Not-Available') }
};

const ApprovalStage: React.FC<ApprovalStageProps> = ({
  data,
  fields,
  isArrears,
  approverLevel
}: ApprovalStageProps) => {
  const authContext = useAuthenticationContext();
  const [stageObj, setStageObj] = useState<ApprovalStageObject>();

  const isInternalSite = useFeature(FeatureFlags.INTERNAL);

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  let internalUserDetails: UserInfo | undefined;

  if (isInternalSite === true) {
    internalUserDetails = sitecoreContext?.user;
  }

  const getUserInfo = async (userID: string): Promise<User | null> => {
    if (userID != null && userID !== '') {
      try {
        const response = await getUserEntity(authContext, userID);

        if (response?.status === HttpResponseStatusCodes.OK) {
          return response?.data?.data?.[0];
        }

        // Fail gracefully
        return null;
      } catch (err) {
        return null;
      }
    }
    return null;
  };

  useEffect(() => {
    (async () => {
      if (data != null && !isArrears) {
        const userResponse = await getUserInfo(data.profile?.adjudicatorUserID ?? '');

        setStageObj({
          ...DEFAULT_STAGE_OBJECT_VALUES,
          analyzedBy: {
            value:
              userResponse != null
                ? `${userResponse.contact?.firstName ?? ''} ${userResponse.contact?.lastName ?? ''}`
                : i18n.t('Global-Not-Available')
          },
          approvalType: {
            value: fields?.claimApprovalType?.value ?? i18n.t('Global-Not-Available')
          },
          primaryApprover: { value: i18n.t('Global-Not-Available') },
          primaryApproverRole: {
            value:
              internalUserDetails?.internalContact?.titleTypeCode != null
                ? fields?.userLevelList?.fields?.listItems?.find(
                    (item: FieldListItem) =>
                      item?.fields?.itemValue?.value ===
                      internalUserDetails?.internalContact?.titleTypeCode?.toString()
                  )?.fields?.itemName?.value
                : i18n.t('Global-Not-Available')
          }
        });
      }
    })();
  }, [data]);

  useEffect(() => {
    if (
      isServer() === false &&
      window.sessionStorage.getItem('arrearsApprovalData') != null &&
      isArrears === true
    ) {
      const arrearsData = JSON.parse(window.sessionStorage.getItem('arrearsApprovalData') ?? '');
      setStageObj({
        ...DEFAULT_STAGE_OBJECT_VALUES,
        approvalType: {
          value: fields?.arrearsApprovalType?.value ?? i18n.t('Global-Not-Available')
        },
        primaryApprover: {
          value: arrearsData?.primaryApproverName ?? i18n.t('Global-Not-Available')
        }
      });
    }
  }, [window.sessionStorage]);

  return (
    <div className="m-4 form">
      <div className="mb-3 form__element">
        {approverLevel === StageIndicator.Secondary && (
          <div className={`mb-4 ${styles.reduceFont} ${styles.emptyDiv}`}>
            <FormText
              className=""
              name=""
              dontRegister={true}
              label={fields.primaryApprover}
              value={stageObj?.primaryApprover?.value ?? ''}
              isReadOnly={true}
            />
          </div>
        )}
        <Text tag="h5" field={fields.title ?? {}} />
      </div>

      {!isArrears && (
        <>
          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="strong" field={fields?.primaryApproverRole ?? {}} />
              </small>
            </div>
            <p className="col">
              <Text tag="small" field={stageObj?.primaryApproverRole ?? {}} />
            </p>
          </div>
          <hr className="form__divider"></hr>
        </>
      )}

      <div className="form-row">
        <div className="col">
          <small>
            <Text tag="b" field={fields.approvalType} />
          </small>
        </div>
        <p className="col">
          <Text tag="small" field={stageObj?.approvalType ?? {}} />
        </p>
      </div>

      <hr className="form__divider"></hr>

      {!isArrears && (
        <>
          <div className="form-row">
            <div className="col">
              <small>
                <Text tag="b" field={fields.analyzedBy} />
              </small>
            </div>
            <p className="col">
              <Text tag="small" field={stageObj?.analyzedBy ?? {}} />
            </p>
          </div>

          <hr className="form__divider"></hr>
        </>
      )}
    </div>
  );
};
export default ApprovalStage;
