import { AxiosResponse } from 'axios';

import { HttpResponseStatusCodes } from '@hobt/constants';

import type { FinalClaimResponseData } from 'Feature/Claims/models/ClaimsSubmissionDetailsProps';
import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { AuthenticationContextType } from 'Foundation/Authentication';

import { config } from '../../../../../../config';

const finalClaimUrl: string = config.claimApi.urls.finalClaim;
const apiClientConfig: ApiClientConfig = { timeout: config.claimApi.requestTimeout };
const underwritingLoanStatusUrl: string = config.claimApi.urls.underwritingLoanStatus;

export const updateFinalClaim = async (
  claimID: string,
  data: any,
  authenticationContext: AuthenticationContextType
): Promise<FinalClaimResponseData | null> => {
  try {
    const response: AxiosResponse<{ data: FinalClaimResponseData[] }> = await ApiClient(
      authenticationContext,
      apiClientConfig
    ).putWithAuth(`${finalClaimUrl}/${claimID}`, data);

    if (response?.status === HttpResponseStatusCodes.OK) {
      return response?.data?.data?.[0];
    }
    // Fail gracefully
    return null;
  } catch (err) {
    console.log('Error ', err);
    return null;
  }
};

export const getUnderwritingLoanStatus = async (
  cmhcLoanAccountNumber: number,
  authenticationContext: AuthenticationContextType
) => {
  try {
    const response = await ApiClient(authenticationContext, apiClientConfig).getWithAuth(
      underwritingLoanStatusUrl.replace('{cmhcLoanAccountNumber}', String(cmhcLoanAccountNumber))
    );

    if (response?.status === HttpResponseStatusCodes.OK) {
      return response?.data?.data?.[0]?.loanStatusCode;
    }
    // Fail gracefully
    return null;
  } catch (err) {
    return null;
  }
};
