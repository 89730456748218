import React, { ChangeEvent, FC } from 'react';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import type { DropdownProps } from './types';
import styles from './styles.module.scss';

function toLabelValuePair({ fields }: SitecoreListItem): {
  label: string | undefined;
  value: string | undefined;
} {
  return {
    label: fields.itemName.value,
    value: fields.itemValue.value
  };
}

const Dropdown: FC<DropdownProps> = ({
  name,
  label,
  options,
  isDisabled,
  className,
  onChangeCallback,
  value
}: DropdownProps) => (
  <div className={`${styles['form-select-wrapper']} ${className}`}>
    <label aria-label={`${label?.value}`} htmlFor={`${name}Dropdown`}>
      {label && <Text field={label} />}
    </label>
    {/* eslint-disable-next-line jsx-a11y/no-onchange */}
    <select
      className={styles['form-select']}
      id={`${name}Dropdown`}
      name={name}
      data-testid={`${name}Select`}
      {...(isDisabled === true && { 'aria-disabled': isDisabled })}
      aria-label={label ? label.value : name}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => onChangeCallback(e.currentTarget.value)}
      value={value}
    >
      {options &&
        // eslint-disable-next-line no-shadow
        options.map(toLabelValuePair).map(({ label, value }) => (
          <option key={value} value={value} {...(isDisabled === true && { disabled: isDisabled })}>
            {label}
          </option>
        ))}
    </select>
  </div>
);

export default Dropdown;
