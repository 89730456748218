import { Module, UserRole } from '@hobt/constants';

const checkUserInRole = (role: UserRole, moduleRoles: number[]) => {
  return moduleRoles.filter((roleValue: number) => roleValue === role).length > 0;
};

export const isUserInRoles = (
  module: Module,
  roles: UserRole[],
  moduleMappingObject: { [key: string]: number[] }
) => {
  if (
    moduleMappingObject &&
    moduleMappingObject[module] &&
    moduleMappingObject[module].length > 0
  ) {
    for (const role of roles) {
      const isUserInRole = checkUserInRole(role, moduleMappingObject[module]);

      if (isUserInRole) {
        return true;
      }
    }
  }

  return false;
};
