import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CSVIcon from 'Components/Core/HbtIcon/icons/CSVIcon.svg';
import DocumentGenericIcon from 'Components/Core/HbtIcon/icons/DocumentGenericIcon.svg';
import ErrorIcon from 'Components/Core/HbtIcon/icons/ErrorIconLarge.svg';
import MSExcel from 'Components/Core/HbtIcon/icons/MSExcel.svg';
import PDFIcon from 'Components/Core/HbtIcon/icons/PDFIcon.svg';
import ZIPIcon from 'Components/Core/HbtIcon/icons/ZIPIcon.svg';
import i18n from 'i18next';
import { HbtIcon } from '../HbtIcon';
import styles from './styles.module.scss';
import {
  Attachment,
  ProgressBarItemProps,
  FileIconTypes,
  KeyboardEvents,
  UploadStatus
} from './types';

const ProgressBar = (props: { percentage: number }) => {
  return (
    <div className={styles.progressBar}>
      <div
        className={props.percentage === 100 ? styles.fillerDone : styles.filler}
        style={{ width: `${props.percentage}%` }}
      />
    </div>
  );
};

/**
 * @param attachments - array of files that are uploading
 * @param stopUploading - function to stop uploading files
 */

const ProgressBarItem: React.FC<ProgressBarItemProps> = ({
  attachments,
  filesRemovedIndexes,
  stopUploading
}) => {
  const fileIconMap: { [key in FileIconTypes]: string } = {
    [FileIconTypes.XLSX]: MSExcel,
    [FileIconTypes.XLS]: MSExcel,
    [FileIconTypes.XLSM]: MSExcel,
    [FileIconTypes.TXT]: DocumentGenericIcon,
    [FileIconTypes.PDF]: PDFIcon,
    [FileIconTypes.CSV]: CSVIcon,
    [FileIconTypes.ZIP]: ZIPIcon
  };

  const [removedIndexes, setRemovedIndexes] = useState<number[]>([]);
  const firstErrorCardCloseBtnRef = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(
    async (id: number, uploadStatusCode: number) => {
      // Note: the removedIndexes just helps to reload the component
      setRemovedIndexes((prev) => [...prev, id]);
      stopUploading(id, uploadStatusCode);
    },
    [stopUploading]
  );

  const handleKeyDownClose = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>, index: number, uploadStatusCode: number) => {
      if (e.key === KeyboardEvents.EnterKey || e.key === KeyboardEvents.SpaceKey) {
        e.preventDefault();
        handleClose(index, uploadStatusCode);
      }
    },
    [handleClose]
  );

  const firstErrorIndex = useMemo(() => {
    return attachments.findIndex((file) => file.uploadStatusCode === UploadStatus.Error);
  }, [attachments]);

  useEffect(() => {
    if (firstErrorCardCloseBtnRef.current) {
      firstErrorCardCloseBtnRef.current.focus();
    }
  }, [firstErrorIndex]);

  return (
    <div className={styles.gridContainer}>
      {attachments?.map((file: Attachment, index: number) => {
        const isFirstError = index === firstErrorIndex;
        return filesRemovedIndexes.includes(index) ? null : (
          <div
            className={
              file.uploadStatusCode === UploadStatus.Error
                ? styles.uploadCardError
                : styles.uploadCard
            }
            key={index}
            tabIndex={0}
            role="region"
            aria-labelledby={`file-${index}-title`}
            aria-describedby={`file-${index}-status`}
          >
            <div className={styles.imageContent}>
              <div className={styles.imageTitleWrapper}>
                <div className={styles.image}>
                  {file.uploadStatusCode === UploadStatus.Error ? (
                    <img src={ErrorIcon} alt={i18n.t('Attachment-Error-Icon')} />
                  ) : (
                    <img
                      src={
                        fileIconMap[
                          file.fileName
                            .slice((Math.max(0, file.fileName.lastIndexOf('.')) || Infinity) + 1)
                            .toLowerCase() as FileIconTypes
                        ]
                      }
                      alt={i18n.t('Attachment-Excel-Icon')}
                    />
                  )}
                </div>
                <div className={styles.imageTitle}>
                  <p className={styles.imageTitleInfo} id={`file-${index}-title`}>
                    {file.fileName}
                    <br />
                    {file.uploadStatusCode === UploadStatus.Error ? (
                      <span
                        className={styles.loadingError}
                        role="alert"
                        aria-live="assertive"
                        id={`file-${index}-status`}
                      >
                        {file.errorMessage}
                      </span>
                    ) : file.uploadStatusCode === UploadStatus.Success ? (
                      <span className={styles.loadingStatus} aria-live="polite">
                        {i18n.t('Attachment-Uploaded') ?? 'Uploading '} &nbsp;
                        {file.percentage}% - {file.timeLeft} &nbsp;
                        {i18n.t('Attachment-Seconds-Left') ?? 'seconds left'}
                      </span>
                    ) : (
                      <span className={styles.loadingStatus}>
                        {file.fileSize}&nbsp;{i18n.t('Attachment-Table-FileSize-Label', 'MB')}
                      </span>
                    )}
                  </p>
                </div>
              </div>
              {file.percentage < 89 && (
                <div
                  ref={isFirstError ? firstErrorCardCloseBtnRef : null}
                  role="button"
                  aria-label={i18n.t('Accessibility-Close-Button', 'Close')}
                  className={
                    file.uploadStatusCode === UploadStatus.Error
                      ? styles.deleteIconError
                      : styles.deleteIcon
                  }
                  onClick={() => handleClose(index, file.uploadStatusCode)}
                  onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                    handleKeyDownClose(e, index, file.uploadStatusCode)
                  }
                  tabIndex={0}
                >
                  <HbtIcon type="icon_close" />
                </div>
              )}
            </div>
            <div className={styles.progressBarContainer}>
              {file.uploadStatusCode !== UploadStatus.Error && (
                <ProgressBar percentage={file.percentage} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default memo(ProgressBarItem);
