import { HbtValidationErrorCodes } from '@hobt/constants';
import { AxiosError, AxiosResponse } from 'axios';
import { AuthenticationContextType } from 'Foundation/Authentication';
import { config } from '../../../../config';

import { ApiClient, ApiClientConfig } from '../../../../Foundation/Api';
import { DataObject, DelegateApiResponse, ErrorObject } from '../../models/types';

const getUserUrl = config.userApi.urls.getUsersToDelegate;
const getDelegateUrl = config.delegateApi.urls.inventory;
const addDelegateUrl = config.delegateApi.urls.create;
const updateDelegateUrl = config.delegateApi.urls.modify;

const apiClientConfig: ApiClientConfig = { timeout: config.notificationApi.requestTimeout };
const pathError = new Error('Invalid configuration. Could not read URL or path for API calls.');

export const errorCatch = (err: ErrorObject): number => {
  switch (err?.response?.data?.error?.errorCode) {
    case 400:
      return DelegateApiResponse.BadRequest;
    case 401:
      return DelegateApiResponse.NotAuthorized;
    case HbtValidationErrorCodes.HBT_ERR_CREATE_DELEGATION_TO_USER_MISSING_ACCESS.code:
      return DelegateApiResponse.ToUserMissingModuleAccess;
    case HbtValidationErrorCodes.HBT_ERR_CREATE_DELEGATION_FROM_USER_MISSING_ACCESS.code:
      return DelegateApiResponse.FromUserMissingModuleAccess;
    case HbtValidationErrorCodes.HBT_ERR_CREATE_DELEGATION_DELEGATOR_MISSING_MASTER_ACCESS.code:
      return DelegateApiResponse.DelegatorMissingModuleMasterAccess;
    case HbtValidationErrorCodes.HBT_ERR_AUTHORITY_DELEGATION_TO_PROBATIONARY_OFFICER.code:
      return DelegateApiResponse.ProbationaryUserNotAuthorized;
    case 500:
    default:
      return DelegateApiResponse.Error;
  }
};

export const getUserRequest = async (
  authContext: AuthenticationContextType
): Promise<DataObject> => {
  const route = getUserUrl;

  if (route !== undefined) {
    return ApiClient(authContext, apiClientConfig)
      .getWithAuth(route)
      .then((res: AxiosResponse<DataObject>) => res.data)
      .catch((err: AxiosError) => Promise.reject(err));
  }
  throw pathError;
};

export const getDelegateRequest = async (
  authContext: AuthenticationContextType,
  id?: string
): Promise<DataObject> => {
  const route = id ? `${getDelegateUrl}?delegationID=${id}` : getDelegateUrl;

  if (route !== undefined) {
    return ApiClient(authContext, apiClientConfig)
      .getWithAuth(route)
      .then((res: AxiosResponse<DataObject>) => res.data)
      .catch((err: AxiosError) => Promise.reject(err));
  }
  throw pathError;
};

export const createDelegateRequest = async (
  authContext: AuthenticationContextType,
  formData: any
): Promise<number> => {
  try {
    await ApiClient(authContext, apiClientConfig).postWithAuth(addDelegateUrl, formData);
    return DelegateApiResponse.Created;
  } catch (err: any) {
    return errorCatch(err);
  }
};

export const updateDelegateRequest = async (
  authContext: AuthenticationContextType,
  formData: any
): Promise<number> => {
  try {
    await ApiClient(authContext, apiClientConfig).putWithAuth(updateDelegateUrl, formData);
    return DelegateApiResponse.Updated;
  } catch (err: any) {
    return errorCatch(err);
  }
};
