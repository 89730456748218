import { SvgIconProps } from '@mui/material/SvgIcon';

export interface HbtIconProps extends SvgIconProps {
  type?: string;
  size?: HbtIconSize;
  ariaText?: FieldValue;
  className?: string;
  testID?: string;
}

export enum HbtIconSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  X_LARGE = 'x-large'
}
