import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import { HttpResponseStatusCodes, HbtServiceErrorCodes } from '@hobt/constants';
import { CorrespondencePayload, correspondenceSchema } from '@hobt/claim-domain';
import { hbtResolver } from '@hobt/schema-validator';

import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';

import { config } from '../../../../../../../config';
import { ToastErrorType } from './types';
import { ErrorObject } from 'Feature/UserManagement/models/types';

const useAdjudicationStatusFunctions = (resetStatus: Function) => {
  const { uuid, setLoadingStatus } = useHBTFormContext();
  const claimID = uuid;
  const [showStatusMessageToast, setShowStatusMessageToast] = useState<boolean>(false);
  const [showStatusErrorToast, setShowStatusErrorToast] = useState<boolean>(false);
  const [showStatusToast, setShowStatusToast] = useState<ApplicationStates>(
    ApplicationStates.DEFAULT
  );
  const [showStatusErrorCode, setShowStatusErrorCode] = useState<ToastErrorType>();
  const [statusChangeShowSideDrawer, setStatusChangeShowSideDrawer] = useState<boolean>(false);

  // API Client Config
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  const authContext = useAuthenticationContext();
  const { postWithAuth } = ApiClient(authContext, apiClientConfig);

  const hookForm = useForm({
    resolver: hbtResolver(correspondenceSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      remarkText: ''
    }
  } as Record<string, any>);

  const cancelFlyout = () => {
    hookForm.reset();
    resetStatus();
    setStatusChangeShowSideDrawer(false);
  };

  const onSuccessCallback = () => {
    setStatusChangeShowSideDrawer(false);
    setShowStatusToast(ApplicationStates.SUCCESS);
    setShowStatusMessageToast(true);
  };

  const onHobtErrorCallback = (errorType: ToastErrorType) => {
    cancelFlyout();
    setShowStatusErrorCode(errorType);
    setShowStatusErrorToast(true);
  };

  const onUnknownErrorCallback = () => {
    cancelFlyout();
    setShowStatusToast(ApplicationStates.ERROR);
    setShowStatusMessageToast(true);
  };

  const onSubmitErrors = (errs: Record<string, Object>) => {
    // TODO: Remove once validations are in place
    // eslint-disable-next-line no-console
    console.log('FORM ERRORS: ', errs);
  };

  const onCancelToast = () => {
    setShowStatusMessageToast(false);
    setShowStatusErrorToast(false);
  };

  const updateStatus = (internalClaimStatus: number) => {
    const url = `${config.claimApi.urls.statusUpdate}/${claimID}/${internalClaimStatus}`;

    postWithAuth(url, undefined)
      .then((_resp: AxiosResponse) => {
        setLoadingStatus?.({ isLoading: false });
        onSuccessCallback();
      })
      .catch((error: ErrorObject) => {
        setLoadingStatus?.({ isLoading: false });
        if (error.response?.status === HttpResponseStatusCodes.ServerError) {
          if (
            error.response?.data.error.errorCode ===
            HbtServiceErrorCodes.HBT_ERR_FINAL_CLAIM_CANNOT_AUTO_APPROVE.code
          ) {
            onHobtErrorCallback(ToastErrorType.CannotAutoApprove);
          } else if (
            error.response?.data.error.errorCode ===
            HbtServiceErrorCodes.HBT_ERR_FINAL_CLAIM_CANNOT_UPDATE_TO_AUTO_APPROVE.code
          ) {
            onHobtErrorCallback(ToastErrorType.CannotUpdateToAutoApprove);
          } else if (
            error.response?.data.error.errorCode ===
            HbtServiceErrorCodes.HBT_ERR_AUTO_ADJUDICATION_DECLINED.code
          ) {
            onHobtErrorCallback(ToastErrorType.AutoAdjudicationDeclined);
          } else if (
            error.response?.data.error.errorCode === HbtServiceErrorCodes.HBT_ERR_SERVER.code
          ) {
            onUnknownErrorCallback();
          }
        } else {
          onUnknownErrorCallback();
        }
      });
  };

  const handleSubmit = (data: CorrespondencePayload, internalClaimStatus: number) => {
    const url = config.claimApi.urls.correspondences;
    const payloadData = {
      claimID,
      correspondencePayload: {
        adjudicationNoteCategoryCode: data.adjudicationNoteCategoryCode,
        commentTypeCode: data.commentTypeCode,
        correspondenceTypeCode: data.correspondenceTypeCode,
        remarkText: data.remarkText,
        claimStatusOverrideTypeCode: Number(data.claimStatusOverrideTypeCode),
        submitterUserID: data.submitterUserID,
        submitterUserTypeCode: data.submitterUserTypeCode
      }
    };

    setLoadingStatus?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description',
      isFlyout: true
    });

    postWithAuth(url, payloadData)
      .then((_resp: AxiosResponse) => {
        updateStatus(internalClaimStatus);
      })
      .catch((_e: AxiosError) => {
        setLoadingStatus?.({ isLoading: false });
        onUnknownErrorCallback();
      });
  };

  return {
    hookForm,
    showStatusToast,
    showStatusMessageToast,
    showStatusErrorCode,
    showStatusErrorToast,
    statusChangeShowSideDrawer,
    cancelFlyout,
    handleSubmit,
    onCancelToast,
    onHobtErrorCallback,
    onSubmitErrors,
    setShowStatusErrorCode,
    setStatusChangeShowSideDrawer,
    updateStatus
  };
};

export default useAdjudicationStatusFunctions;
