import { CircularProgressProps } from '@mui/material/CircularProgress';

export interface SpinnerProps extends CircularProgressProps {
  ariaText?: string;
  spinnerClassName?: string;
  modifiers?: SpinnerModifiers;
  size: SpinnerSize;
  type?: SpinnerVariant;
}

export type SpinnerModifiers = Pick<CircularProgressProps, 'size' | 'thickness'>;
export type SizeAndThickness = number[];
export type SpinnerSizeMapping = Record<SpinnerSize, number[]>;

export enum SpinnerVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export enum SpinnerSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  VARIABLE = 'variable'
}
