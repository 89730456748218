import React, { memo, useMemo } from 'react';
import { Box } from '@mui/material';
import PaginationItemsOnPage from './PaginationItemsOnPage';
import PaginationPageButtons from './PaginationPageButtons';
import styles from './styles.module.scss';
import { PaginationProps, PaginationPosition, PaginationRange } from './types';

/**
 * @param currentPageNumber - the current page number.
 * @param totalPageNumber - the total number of pages.
 * @param isCondensed - the flag to show the condensed pagination.
 * @param onPageChange - the function to handle the page change.
 * @param numberOfItemsOnPage - the number of items on the page.
 * @param onItemsPerPageChange - the function to handle the items per page change.
 * @param totalItemNumber - the total number of items.
 */

/**
 * Pagination component
 *
 * This is the Pagination component written using storybook.
 * It generates a pagination with page buttons and items per page selection,
 * And used in DataTable / DataGrid component.
 */

const Pagination: React.FC<PaginationProps> = ({
  currentPageNumber,
  totalPageNumber,
  isCondensed = true,
  isOnTablet,
  paginationPosition,
  onPageChange,
  numberOfItemsOnPage,
  onItemsPerPageChange,
  totalItemNumber
}) => {
  const position = paginationPosition ?? PaginationPosition.CENTER;

  const getPaginationRange = useMemo(
    () => (): PaginationRange => {
      const range: PaginationRange = [];
      const visiblePages = isCondensed ? (isOnTablet ? 1 : 4) : 2;
      const totalVisiblePages = visiblePages * 2 + 1;

      if (totalPageNumber <= totalVisiblePages) {
        for (let i = 1; i <= totalPageNumber; i++) {
          range.push(i);
        }
      } else {
        range.push(1);

        const leftEllipsis = currentPageNumber - visiblePages > 2;
        const rightEllipsis = currentPageNumber + visiblePages < totalPageNumber - 1;

        if (leftEllipsis) {
          range.push('...');
        }

        const startPage = Math.max(2, currentPageNumber - visiblePages);
        const endPage = Math.min(totalPageNumber - 1, currentPageNumber + visiblePages);

        for (let i = startPage; i <= endPage; i++) {
          range.push(i);
        }

        if (rightEllipsis) {
          range.push('...');
        }

        range.push(totalPageNumber);
      }

      return range;
    },
    [currentPageNumber, totalPageNumber, isCondensed]
  );

  const paginationRange = useMemo(() => getPaginationRange(), [getPaginationRange]);

  return (
    <Box role="navigation" className={`${styles.pagination} ${styles[position]}`}>
      <PaginationItemsOnPage
        numberOfItemsOnPage={numberOfItemsOnPage}
        onItemsPerPageChange={onItemsPerPageChange}
        totalItemNumber={totalItemNumber}
        currentPageNumber={currentPageNumber}
      />
      <PaginationPageButtons
        currentPageNumber={currentPageNumber}
        totalPageNumber={totalPageNumber}
        paginationRange={paginationRange}
        onPageChange={onPageChange}
      />
    </Box>
  );
};

export default memo(Pagination);
