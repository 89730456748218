import React, { memo, useEffect, useState } from 'react';
import { pathNames } from 'Constants/pathNames';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useAuthenticationContext } from 'Foundation/Authentication';
import i18n from 'i18next';
import { Redirect } from 'react-router-dom';
import { config } from '../../../../config';
import { LoaderAnimation } from '../../../CommonComponents/UserControls/LoaderAnimation/LoaderAnimation';
import styles from './styles.module.scss';
import { SignInCompleteProps } from './types';

const SignInComplete: React.FC<SignInCompleteProps> = ({ fields }: SignInCompleteProps) => {
  const { getWithAuth, isAuthenticated } = useAuthenticationContext();
  const [displayTermsFlag, setDisplayTermsFlag] = useState<boolean | null>(null);
  const enableTerms = config.app.siteType === FeatureFlags.EXTERNAL;

  useEffect(() => {
    if (isAuthenticated === true) {
      getWithAuth(config.userApi.urls.getTermsOfUseDisplayFlag)
        .then((termsDisplayResponse) => {
          setDisplayTermsFlag(
            termsDisplayResponse?.data?.data?.[0]?.displayTermsOfUseAcceptance ?? true
          );
        })
        .catch((_err) => {
          // display just loading page if not sure what the value of flag is due to a backend err
          setDisplayTermsFlag(true);
        });
    }
  }, [isAuthenticated]);

  /*
   If the user is already authenticated, no need to show this page
   The terms acceptance validation will redirect to terms if necessary
 */
  if (
    isAuthenticated &&
    (displayTermsFlag === false ||
      enableTerms === false ||
      config.auth.validateTermsAndConditionsAcceptance === false)
  ) {
    return <Redirect push to={`/${i18n.language}${pathNames.dashboard}`} />;
  }
  if (isAuthenticated && enableTerms === true && displayTermsFlag === true) {
    return <Redirect push to={`/${i18n.language}${pathNames.termsAndConditions}`} />;
  }

  return (
    <div className="row">
      <section className={`${styles['loading-icon']} col-12`}>
        <LoaderAnimation fields={fields} />
      </section>
    </div>
  );
};

export default memo(SignInComplete);
