import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { FormInputWholeNumberProps } from './FormInputWholeNumber.types';
import GetErrorMessage from '../ErrorMessage';
import _ from 'lodash-es';

const FormInputWholeNumber: React.FC<FormInputWholeNumberProps> = ({ ...props }) => {
  const errors = _.get(props.errors, `${props.name}`);

  return (
    <div className="form__element form__element--2-column">
      <label
        htmlFor={`${props.id}NumberInput`}
        id={`${props.id}Label`}
        className={`${props.titleAsSubheading ? 'subHeading' : ''} ${
          _.get(props.errors, `${props.name}`) && 'form-error__label'
        }`}
      >
        <Text field={props.title} />
      </label>
      {props.description && <small>{props.description.value}</small>}
      <Controller
        control={props.control}
        name={props.name}
        defaultValue={(props.getValues && props.getValues(props.name)) || ''}
        render={({ field: { onBlur, value } }) => (
          <NumberFormat
            id={`${props.id}NumberInput`}
            data-testid={`${props.id}`}
            aria-labelledby={`${props.id}Label`}
            name={`${props.name}_formatted`}
            disabled={props.isDisabled === undefined ? false : props.isDisabled}
            decimalScale={props.decimalScale || 0}
            displayType="input"
            value={value}
            {...(props.register && props.register(`${props.name}`))}
            onValueChange={(values) => {
              if (
                props.name === 'lender.approvedLenderCode' ||
                props.name === 'lender.transitNumber' ||
                props.name === 'lender.financialInstitutionCode'
              ) {
                // conditional because above fields are coming from JOI schema as string types
                // eslint-disable-next-line no-unused-expressions
                props.setValueHandler &&
                  props.setValueHandler(props.name, values.value, { shouldDirty: true });
              } else {
                // eslint-disable-next-line no-unused-expressions
                props.setValueHandler &&
                  props.setValueHandler(props.name, values.floatValue, { shouldDirty: true });
              }
            }}
            onBlur={onBlur}
            className={_.get(props.errors, `${props.name}`) && 'form-error__field'}
            {...(props.isRequired && { required: props.isRequired })}
            aria-describedby={`${props.id}ErrorDesc`}
            allowLeadingZeros={props.allowLeadingZeros || false}
            autoComplete={props.autoComplete || 'off'}
            placeholder={props.placeholder || ''}
          />
        )}
      />
      {!props.hideError && (
        <div className="form__fieldNotificationHolder">
          {_.get(props.errors, `${props.name}`) && (
            <span
              className="form-error__sub-text"
              aria-live="assertive"
              id={`${props.id}ErrorDesc`}
            >
              {GetErrorMessage(
                errors?.type as string,
                errors?.message as string,
                props?.customErrorMessage
              )}
              <input
                type="hidden"
                className="thisFieldHasErrors"
                value={props.cardName && props.cardName}
              />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default FormInputWholeNumber;
