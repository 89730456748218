import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import {
  ModifyUserReducerState,
  ModifyUserAction,
  ModifyUserActionType,
  ModifyUserActionValuePayload,
  ModifyUserActionFunctionPayload
} from 'Feature/UserManagement/components/ModifyUser/types';
import i18n from 'i18next';

export const modifyUserReducer = (
  state: ModifyUserReducerState,
  { action, payload }: ModifyUserAction
): ModifyUserReducerState => {
  const newState = { ...state };

  const reducers = {
    [ModifyUserActionType.SET_IS_MODAL_LOADING]: () => {
      newState.isModalLoading = (payload as ModifyUserActionValuePayload).value;
    },
    [ModifyUserActionType.SET_IS_LOADING]: () => {
      newState.isLoading = (payload as ModifyUserActionValuePayload).value;
    },
    [ModifyUserActionType.SET_IS_PAGE_LOADING]: () => {
      newState.isPageLoading = (payload as ModifyUserActionValuePayload).value;
    },
    [ModifyUserActionType.SUBMIT_SUCCESS]: () => {
      newState.isBypass = true;
      newState.isLoading = false;
      newState.modalActive = false;
      newState.toastProps = {
        isActive: true,
        title: i18n.t('Globals-Toast-Success-Title'),
        type: ApplicationStates.SUCCESS,
        content: i18n.t('UserManagement-ModifyUsers-SuccessToastMessage'),
        onCloseCallback: (payload as ModifyUserActionFunctionPayload).function
      };
    },

    [ModifyUserActionType.ON_ERROR]: () => {
      newState.isBypass = false;
      newState.isLoading = false;
      newState.toastProps = {
        isActive: true,
        title: i18n.t('Globals-Toast-Error-Title'),
        type: ApplicationStates.ERROR,
        content: i18n.t('Globals-UnknownErrorMessage'),
        onCloseCallback: (payload as ModifyUserActionFunctionPayload).function
      };
    },

    [ModifyUserActionType.DISMISS_MODAL]: () => {
      newState.modalActive = false;
    },

    [ModifyUserActionType.SHOW_MODAL]: () => {
      newState.isBypass = false;
      newState.modalActive = true;
    },
    [ModifyUserActionType.SHOW_UPDATE_MODAL]: () => {
      newState.isBypass = false;
      newState.updateModalActive = true;
    },
    [ModifyUserActionType.DISMISS_UPDATE_MODAL]: () => {
      newState.updateModalActive = false;
    },

    [ModifyUserActionType.DISMISS_TOAST]: () => {
      newState.toastProps.isActive = false;
    }
  };

  reducers[action]();

  return newState;
};
