import i18n from 'i18next';
import DefaultSubmissionFormProps, {
  AttachmentValuesField
} from 'Feature/DefaultSubmissionForm/models/DefaultSubmissionFormProps';
import { FormDropdownOption } from '../../FormInputDropdownText/FormInputDropdownText.types';

export const getAttachmentFormProps = (props: DefaultSubmissionFormProps) => {
  const attachmentOptions: FormDropdownOption[] = [];
  props.fields.attachmentTypeOptions[0].fields.listItems.map((option: AttachmentValuesField) => {
    attachmentOptions.push({
      label: (option?.fields?.itemName?.value as string) || 'No label set',
      value: option?.fields?.itemValue?.value || -1
    });
  });

  const attachmentFormProps = {
    testId: 'attachmentsAccordionTest',
    accordionLineColor: 'grey',
    linePosition: 'vertical',
    title: {
      field: {
        value: i18n.t('DefaultSubmission-Card-AttachmentsHeading')
      }
    },
    defaultRequestId: props.fields.defaultRequestId,
    downloadMode: props.fields.downloadMode,
    downloadIcon: props.fields.downloadIcon,
    deleteIcon: props.fields.deleteIcon,
    warningIcon: props.fields.warningIcon,
    cancelIcon: props.fields.cancelIcon,
    downloadAllLabel: {
      field: {
        value: i18n.t('DefaultSubmission-Card-DownloadAllLabel')
      }
    },
    attachmentTypeTitle: {
      field: {
        value: i18n.t('DefaultSubmission-Card-AttachmentType')
      }
    },
    fileNameTitle: {
      field: {
        value: i18n.t('DefaultSubmission-Card-FileName')
      }
    },
    fileSizeTitle: {
      field: {
        value: i18n.t('DefaultSubmission-Card-FileSize')
      }
    },
    supportingDocumentsInstructions: {
      field: {
        value: i18n.t('DefaultSubmission-Card-SupportingDocuments')
      }
    },
    additionalSupportingDocumentsInstructions: {
      field: {
        value: i18n.t('DefaultSubmission-Card-AdditionalSupportingDocuments')
      }
    },
    descriptionTitle: {
      field: {
        value: i18n.t('DefaultSubmission-Card-Description')
      }
    },
    showMore: {
      field: {
        value: i18n.t('DefaultSubmission-Card-ShowMore')
      }
    },
    dropFilesLabel: {
      field: {
        value: i18n.t('DefaultSubmission-Card-DropFilesLabel')
      }
    },
    uploadFilesLabel: {
      field: {
        value: i18n.t('DefaultSubmission-Card-UploadFilesLabel')
      }
    },
    uploadInstructions1: {
      field: {
        value: i18n.t('DefaultSubmission-Card-UploadInstructions-1')
      }
    },
    uploadInstructions2: {
      field: {
        value: i18n.t('DefaultSubmission-Card-UploadInstructions-2')
      }
    },
    uploadInstructionsButtonLabel: {
      field: {
        value: i18n.t('DefaultSubmission-Card-UploadInstructionsButtonLabel')
      }
    },
    supportedFormats: {
      field: {
        value: i18n.t('DefaultSubmission-Card-SupportedFormats')
      }
    },
    attachmentTypes: [
      i18n.t('DefaultSubmission-Card-AttachmentType-LenderRecommendationLabel'),
      i18n.t('DefaultSubmission-Card-AttachmentType-RecentCreditReportLabel'),
      i18n.t('DefaultSubmission-Card-AttachmentType-RecentConfirmationOfIncomeLabel'),
      i18n.t('DefaultSubmission-Card-AttachmentType-BuildingInspectionReportLabel'),
      i18n.t('DefaultSubmission-Card-AttachmentType-AppraisalReportLabel'),
      i18n.t('DefaultSubmission-Card-AttachmentType-TaxStatementLabel'),
      i18n.t('DefaultSubmission-Card-AttachmentType-LocalContractorQuotesLabel'),
      i18n.t('DefaultSubmission-Card-AttachmentType-OtherLabel')
    ],
    uploading: i18n.t('DefaultSubmission-Card-Uploading'),
    calculatingRemainingTime: i18n.t('DefaultSubmission-Card-CalculatingRemainingTime'),
    oneSecondLeft: i18n.t('DefaultSubmission-Card-OneSecondLeft'),
    estimatedMinutesAndSeconds: i18n.t('DefaultSubmission-Card-EstimatedMinutesAndSeconds'),
    estimatedSeconds: i18n.t('DefaultSubmission-Card-EstimatedSeconds'),
    uploadError: i18n.t('DefaultSubmission-Card-UploadError'),
    defaultErrorMessage: i18n.t('Errors-HBT_ERR_DEFAULT'),
    downloadAttachmentErrorTitle: i18n.t('DefaultSubmission-Card-DownloadAttachmentErrorTitle'),
    downloadAttachmentErrorMessage: i18n.t('DefaultSubmission-Card-DownloadAttachmentErrorMessage'),
    attachmentTypeOptions: attachmentOptions
  };

  return attachmentFormProps;
};
