export enum TabsType {
  LEFT_ALIGNED = 'Left aligned',
  FULL_WIDTH = 'Full Width',
  SMALL_SCREEN = 'Small screen'
}

export interface TabFields {
  label: FieldValue;
  content: React.ReactNode;
}

export interface TabProps {
  tabs: TabFields[];
  tabsType: TabsType;
  initalActiveTabIndex?: number;
  isEqualTabWidthRequired?: boolean;
  preventTabChange?: boolean;
  onTabChange?: (newValue: number) => void;
}
