import React, { useEffect, useState } from 'react';
import { ClaimInventoryViewType, UserType } from '@hobt/constants';
import { ApplicationStates } from '@hobt/data-vis-components';
import { frontEndAddExternalUserSchema } from '@hobt/form-schema-validators';
import { joiResolver } from '@hookform/resolvers/joi';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Card } from 'Components/Card';
import { CardBody } from 'Components/Card/CardBody';
import { CardFooter } from 'Components/Card/CardFooter';
import { CardHeader } from 'Components/Card/CardHeader';
import { Button } from 'Components/Common/Button';
import FormToggle from 'Components/Inputs/FormToggle';
import { pathNames } from 'Constants/pathNames';
import {
  ToastNotification,
  ToastNotificationProps
} from 'Feature/CommonComponents/ContentComponents';
import { SubmissionType } from 'Feature/CommonComponents/Enums';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';
import AddExternalUserProps from 'Feature/UserManagement/models/AddExternalUserProps';
import { UserApiResponse, toastMessageMap } from 'Feature/UserManagement/models/types';
import { UserAccessRecord } from 'Feature/UserManagement/models/UserAccessProps';
import { useAuthenticationContext } from 'Foundation/Authentication';
import i18n from 'i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Element } from 'react-scroll';
import { addExternalUserRequest } from '../api/UserApiHandler.service';
import UserAccess from '../CardsExternal/UserAccess';
import UserAdminContactInfo from '../CardsExternal/UserAdminContactInfo';
import styles from './styles.module.scss';

const AddExternalUser = ({ fields, rendering }: AddExternalUserProps) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const authenticationContext = useAuthenticationContext();
  const [showUserActive, setShowUserActive] = useState<boolean>(true);
  const [addAccess, setAddAccess] = useState<boolean>(false);
  let [formPayloadData, setFormPayloadData] = useState<Object>({});
  const [toast, setToast] = useState<boolean>(false);
  const [toastMsg, setToastMessage] = useState<string>('');
  const [isSubmitFailed, setSubmitFailed] = useState<boolean>(false);
  const [isBypass, setBypass] = useState<boolean>(false);

  const externalSectionsMap: Record<string, React.FC<any>> = {
    UserAdminContactInfo,
    UserAccess
  };
  const sectionsLookup = externalSectionsMap;

  const methods = useForm({
    resolver: joiResolver(frontEndAddExternalUserSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      emailID: '',
      contact: {
        firstName: '',
        lastName: '',
        extensionPhoneNumber: '',
        phoneNumber: ''
      },
      userAccesses: [
        {
          allowedTransitNumbers: [],
          defaultFinancialInstitutionCode: '',
          defaultTransitNumber: '',
          financialInstitutionCode: '',
          lenderRoleCode: '',
          userAuthorizationScope: [],
          statusCode: '',
          access: {
            admin: {
              activeFlag: false
            },
            arrears: {
              activeFlag: false
            },
            claim: {
              activeFlag: false
            },
            default: {
              activeFlag: false
            },
            piRequest: {
              activeFlag: false
            },
            piPurposeTest: {
              activeFlag: false
            },
            piFileShare: {
              activeFlag: false
            }
          }
        }
      ]
    }
  });

  const onToggleShowUserActive = () => {
    setShowUserActive(!showUserActive);
  };

  const handleAddAccessRow = () => {
    setAddAccess(!addAccess);
  };

  useEffect(() => {
    methods.register('userTypeCode' as any);
    methods.setValue('userTypeCode' as any, UserType.External);
    if (isServer() === false) {
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, []);

  const onApiError = async (err?: any): Promise<void> => {
    handleToastAction(SubmissionType.ERROR);
    setIsLoading(false);
    if (err != null && typeof err === 'number') {
      setToastMessage(toastMessageMap.get(err) ?? '');
    } else {
      setToastMessage(toastMessageMap.get(UserApiResponse.Error) ?? '');
    }
  };

  const handleToastAction = (type: SubmissionType) => {
    setBypass(type !== SubmissionType.ERROR);
    setSubmitFailed(type === SubmissionType.ERROR);
    setToast(type !== SubmissionType.OTHER);
  };

  const onAddUserApiCall = async () => {
    setIsLoading(true);
    const result = await addExternalUserRequest(authenticationContext, formPayloadData);

    switch (result) {
      case UserApiResponse.Created: // User added successfully
        setToastMessage(toastMessageMap.get(UserApiResponse.Created) ?? '');
        handleToastAction(SubmissionType.SUBMIT);
        setIsLoading(false);
        break;
      default:
        onApiError(result);
        break;
    }
  };

  const onSubmit = async (formData: any): Promise<void> => {
    const { userAccesses, ...contactInfo } = formData;
    let userAccessPayload: UserAccessRecord[] = [];
    userAccesses?.forEach((element: UserAccessRecord) => {
      // Create a new object without the userAccessID property
      const { userAccessID, ...elementWithoutID } = element;

      // Update other properties as needed
      elementWithoutID.access = {
        ...elementWithoutID.access,
        admin: { activeFlag: false }
      };
      elementWithoutID.lenderRoleCode === 101
        ? (elementWithoutID.access.admin.activeFlag = true)
        : (elementWithoutID.access.admin.activeFlag = false);

      userAccessPayload.push(elementWithoutID);
    });
    formPayloadData = {
      users: [{ ...contactInfo, userAccesses: userAccessPayload }]
    };
    setFormPayloadData({
      users: [{ ...contactInfo, userAccesses: userAccessPayload }]
    });
    onAddUserApiCall();
  };

  const toastNotificationProps: ToastNotificationProps = {
    isActive: toast,
    title: i18n.t(
      `Default${
        isSubmitFailed ? 'Submission-ErrorToast' : 'sInventoryTable-DecisioningToastMessage-'
      }Title`
    ),
    type: ApplicationStates[isSubmitFailed ? 'ERROR' : 'SUCCESS'],
    content: i18n.t(toastMsg),
    onCloseCallback: () => {
      setToast(false);
      if (!isSubmitFailed) {
        history.push(`/${i18n.language}${pathNames.userInventory}`);
      }
    }
  };

  return (
    <>
      <ContentLoadingModal
        display={isLoading}
        fields={{
          heading: { value: i18n.t('Globals-Saving-Heading') },
          description: { value: i18n.t('Globals-Saving-Description') }
        }}
      />
      <ToastNotification {...toastNotificationProps} />
      <Card>
        <CardHeader separator>
          <div className="row">
            <div className="col-12">
              <h2 className="card-title">
                <Text field={fields.pageTitle} />
              </h2>
            </div>
          </div>
        </CardHeader>
        <FormProvider {...methods}>
          <form
            id="addExternalUserForm"
            className="form add-user-form"
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
          >
            <CardBody>
              <div className={styles.cardItem}>
                <div className="row">
                  <section className="col-12">
                    {rendering?.placeholders?.sections?.map((card: any, index: number) => (
                      <Element name={card.componentName} key={card.componentName + index}>
                        {sectionsLookup[card.componentName] != null &&
                          React.createElement(sectionsLookup[card.componentName], {
                            fields: card.fields,
                            key: index
                          })}
                      </Element>
                    ))}
                    <div className={styles.actionButtons}>
                      <div>
                        <Button
                          responsive
                          name="addUserAccessBtn"
                          text={fields.userAccessBtnText}
                          ariaText={fields.userAccessBtnText}
                          onClick={handleAddAccessRow}
                          type="button"
                          disabled={!showUserActive}
                        />
                      </div>
                      <div>
                        <Button
                          secondaryButton
                          name="reSyncUserBtn"
                          text={fields.reSyncUserBtnText}
                          ariaText={fields.reSyncUserBtnText}
                          onClick={() => {}}
                          type="button"
                          disabled={true}
                        />
                      </div>
                      <div>
                        <Button
                          secondaryButton
                          name="reInviteUserBtn"
                          text={fields.reInviteUserBtnText}
                          ariaText={fields.reInviteUserBtnText}
                          onClick={() => {}}
                          type="button"
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className={styles.showUserActive}>
                      <FormToggle
                        onChange={onToggleShowUserActive}
                        isChecked={showUserActive}
                        id="showUserActive"
                        testId="showUserActive"
                        staticText={fields.activeUserBtnText?.value}
                      />
                    </div>
                  </section>
                </div>
                <div>
                  {rendering?.placeholders?.sectionAuth?.map((card: any, index: number) => (
                    <Element name={card.componentName} key={card.componentName + index}>
                      {sectionsLookup[card.componentName] != null &&
                        React.createElement(sectionsLookup[card.componentName], {
                          fields: card.fields,
                          key: index,
                          addAccessRow: addAccess,
                          handleAddAccessRow,
                          showUserActive
                        })}
                    </Element>
                  ))}
                </div>
                <CardFooter separator>
                  <div>
                    <div className={styles.addButtons}>
                      <Button
                        responsive
                        name="submitBtn"
                        text={fields.submitBtnText}
                        ariaText={fields.submitBtnText}
                        type="submit"
                      />
                    </div>
                    <div className={styles.addButtons}>
                      <Button
                        secondaryButton
                        responsive
                        name="cancelBtn"
                        text={fields.cancelBtnText}
                        ariaText={fields.cancelBtnText}
                        onClick={() => history.push(`/${i18n.language}${pathNames.userInventory}`)}
                        type="button"
                      />
                    </div>
                  </div>
                </CardFooter>
              </div>
            </CardBody>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};
export default AddExternalUser;
