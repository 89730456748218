import { HttpResponseStatusCodes } from '@hobt/constants';
import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { AuthenticationContextType } from 'Foundation/Authentication';
import { config } from '../../../../../config';

const apiClientConfig: ApiClientConfig = { timeout: config.fileTransferApi.requestTimeout };
const lendersUrl: string = config.fileTransferApi.urls.lenders;

export const getLenders = async (authenticationContext: AuthenticationContextType) => {
  try {
    const response = await ApiClient(authenticationContext, apiClientConfig).getWithAuth(
      lendersUrl
    );
    return response?.data?.statusCode === HttpResponseStatusCodes.OK && response?.data?.data?.[0];
  } catch (err) {
    // TODO: Needs to return another value or cater for errors on the frontend side.
    return null;
  }
};
