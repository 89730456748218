import { ToastNotificationProps } from 'Feature/CommonComponents/ContentComponents';

export type ModifyUserContextType = {
  userData: any;
};

export type ModifyUserReducerState = {
  modalActive: boolean;
  toastProps: ToastNotificationProps;
  isLoading: boolean;
  isModalLoading: boolean;
  isPageLoading: boolean;
  isBypass: boolean; // Prompt bypass used when submission successful
  updateModalActive: boolean;
};

export type ModifyUserActionValuePayload = {
  value: boolean;
};

export type ModifyUserActionFunctionPayload = {
  function: () => void;
};

export enum ModifyUserActionType {
  DISMISS_MODAL,
  DISMISS_TOAST,
  ON_ERROR,
  SET_IS_MODAL_LOADING,
  SET_IS_PAGE_LOADING,
  SET_IS_LOADING,
  SHOW_MODAL,
  SUBMIT_SUCCESS,
  SHOW_UPDATE_MODAL,
  DISMISS_UPDATE_MODAL
}

export type ModifyUserAction = {
  action: ModifyUserActionType;
  payload?: ModifyUserActionValuePayload | ModifyUserActionFunctionPayload;
};
