import React from 'react';
import i18n from 'i18next';
import { CommentPopoutProps } from 'Components/Common/CommentPopout/types';

import styles from './styles.module.scss';

export const CommentPopout: React.FC<CommentPopoutProps> = ({
  isActive,
  title,
  content,
  onCloseCallback,
  className = ''
}: CommentPopoutProps) => (
  <>
    {isActive && (
      <div className={`${styles.commentPopoutContainer} ${className}`.trim()}>
        <div className={styles.header}>
          <span>{title != null ? title : i18n.t('Comment-Popout-Title')}</span>
          <span>
            <button
              data-testid="commentPopoutCloseButtonTest"
              aria-label={'Close Button'}
              onClick={(e) => {
                e.stopPropagation();
                if (onCloseCallback != null) onCloseCallback();
              }}
              type="button"
            >
              <span className="material-icons-outlined icon--v-align-middle">close</span>
            </button>
          </span>
        </div>
        <div className={styles.content}>{content && content()}</div>
      </div>
    )}
  </>
);
