/* eslint-disable */
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import i18n from 'i18next';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { LanguageShort, ModuleMapping, UserRole } from '@hobt/constants';
import { ClaimTypeIndicator, DraftClaimStatus } from '@hobt/claim-domain';

import { Button } from 'Components/Common/Button';
import { Card } from 'Components/Card';
import { CardBody } from 'Components/Card/CardBody';
import { CardFooter } from 'Components/Card/CardFooter';
import { CardHeader } from 'Components/Card/CardHeader';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormNumber from 'Components/Inputs/FormNumber';
import FormText from 'Components/Inputs/FormText';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { getFiAndTransitDetails } from './claimValidation.service';
import { ClaimValidationProps } from './types';
import { useAuthenticationContext } from 'Foundation/Authentication';
import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { AxiosError, AxiosResponse } from 'axios';

const ClaimsValidation = (props: ClaimValidationProps) => {
  const { isValidLoanAndClaimType, siteType, updateLoanNumberValidationStatusOnDraftClaim } = props;
  const isInternalPortal = siteType === FeatureFlags.INTERNAL;
  const [showToast, setShowToast] = React.useState<boolean>(false);
  const { setValue, watch } = useFormContext();

  const claimTypeIndicator = watch('claimTypeIndicator');
  const cmhcLoanAccountNumber = watch('cmhcLoanAccountNumber');
  const [fiAndTransit, setFiAndTransit] = React.useState({ fiCode: '', transitNumber: '' });
  const authContext = useAuthenticationContext();
  const { claimData, isClaimsDetails, isFieldDisabled } = useHBTFormContext();
  let financialInstitutionOptions = [
    {
      fields: { itemName: { value: '' }, itemValue: { value: '' } }
    }
  ];

  let financialInstitutionCodes: any = [];
  const [transitIdOptions, setTransitIdOptions] = React.useState([
    {
      fields: { itemName: { value: '' }, itemValue: { value: '' } }
    }
  ]);

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const userDetails = sitecoreContext?.user;
  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyView: boolean =
    isUserInRoles(
      ModuleMapping.claim,
      [UserRole.ReadOnly, UserRole.BusinessUser, UserRole.MasterUser],
      moduleRoleMapping
    ) && isInternalPortal === true;

  if (
    userDetails?.moduleFiTransitMapping != null &&
    Object.keys(userDetails.moduleFiTransitMapping).length > 0
  ) {
    financialInstitutionOptions = Object.keys(
      userDetails.moduleFiTransitMapping[ModuleMapping.claim]
    )?.map((val) => ({
      fields: { itemName: { value: val }, itemValue: { value: val } }
    }));
    financialInstitutionCodes = Object.keys(
      userDetails.moduleFiTransitMapping[ModuleMapping.claim]
    ).map((val) => val);
  }
  React.useEffect(() => {
    if (
      (claimTypeIndicator == null || Number.isNaN(claimTypeIndicator)) &&
      claimData?.claimTypeIndicator != null
    ) {
      setValue('claimTypeIndicator', claimData?.claimTypeIndicator);
    }
  }, [claimTypeIndicator]);
  React.useEffect(()=>{
    if(claimData?.profile?.statusCode==DraftClaimStatus.InDraft){
      setFiAndTransit({fiCode:claimData.approvedLenderFinancialInstitutionCode,transitNumber:claimData.approvedLenderTransitNumber}
        )
     }
  },[])
 
  React.useEffect(() => {
    if (claimData != null && isClaimsDetails === false) {
      setValue('cmhcLoanAccountNumber', claimData.cmhcLoanAccountNumber);
      setValue('approvedLenderReferenceNumber', claimData.approvedLenderReferenceNumber);
      setValue(
        'approvedLenderFinancialInstitutionCode',
        claimData.approvedLenderFinancialInstitutionCode
      );

      if (isInternalPortal === false) {
        setTransitIdOptions([
          {
            fields: {
              itemName: {
                value: claimData.approvedLenderTransitNumber
              },
              itemValue: {
                value: claimData.approvedLenderTransitNumber
              }
            }
          }
        ]);
      }

      setValue('approvedLenderTransitNumber', claimData.approvedLenderTransitNumber);
      updateLoanNumberValidationStatusOnDraftClaim(true);
    }
  }, [claimData, isClaimsDetails]);

  React.useEffect(() => {
    if (!isInternalPortal && transitIdOptions[0].fields.itemName.value !== '') {
      setValue('approvedLenderTransitNumber', transitIdOptions[0].fields.itemValue.value);
    }
  }, [isInternalPortal, transitIdOptions]);

  const setAllowedTransitOptions = (selectedFinancialInstitutionCode: string) => {
    const transitMapping =
      userDetails?.moduleFiTransitMapping?.[ModuleMapping.claim]?.[
        selectedFinancialInstitutionCode
      ];

    if (transitMapping != null) {
      const { allowedTransitNumbers } = transitMapping;
      if (allowedTransitNumbers != null && Array.isArray(allowedTransitNumbers)) {
        const dynamicOptions = allowedTransitNumbers?.map((val) => ({
          fields: { itemName: { value: val }, itemValue: { value: val } }
        }));
        setTransitIdOptions(dynamicOptions);
        setValue('approvedLenderFinancialInstitutionCode', selectedFinancialInstitutionCode);
      }
    }
  };
  React.useEffect(() => {
    if (isInternalPortal === false) {
      if (financialInstitutionCodes.includes(fiAndTransit.fiCode)) {
        setValue('approvedLenderFinancialInstitutionCode', fiAndTransit.fiCode);
        const { allowedTransitNumbers } =
          userDetails.moduleFiTransitMapping[ModuleMapping.claim][fiAndTransit.fiCode];
        if (allowedTransitNumbers.includes(fiAndTransit.transitNumber)) {
          setTransitIdOptions([
            {
              fields: {
                itemName: {
                  value: fiAndTransit.transitNumber
                },
                itemValue: {
                  value: fiAndTransit.transitNumber
                }
              }
            }
          ]);
        }
      }
    }
    setValue('approvedLenderFinancialInstitutionCode', fiAndTransit.fiCode);
    setValue('approvedLenderTransitNumber', fiAndTransit.transitNumber);
  }, [fiAndTransit]);

  const getFiandTransit = async () => {
    if (
      claimTypeIndicator === ClaimTypeIndicator.Supplementary &&
      cmhcLoanAccountNumber !== undefined && claimData?.profile?.statusCode!==DraftClaimStatus.InDraft
    ) {
      getFiAndTransitDetails(authContext, cmhcLoanAccountNumber)
        .then((result: AxiosResponse) => {
          if (result !== null && result?.data?.length !== 0) {
            return setFiAndTransit(result?.data[0]);
          }
          setFiAndTransit({ fiCode: '', transitNumber: '' });
        })
        .catch((err: AxiosError) => {
          if (err.response?.status !== 200) setShowToast(true);
          setFiAndTransit({ fiCode: '', transitNumber: '' });
        });
    }
  };

  const onToastCloseHandler = () => {
    setShowToast(false);
  };

  const handleFinancialInstitutionCodeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.currentTarget.value !== '') {
      const selectedFinancialInstitutionCode = event.currentTarget.value;
      setAllowedTransitOptions(selectedFinancialInstitutionCode);
    }
  };

  return (
    <fieldset disabled={isReadOnlyView} role="group" aria-label="claim_submission_form">
      <Card>
        <CardHeader>
          <Text tag="h2" field={props.fields.cardTitle} />
        </CardHeader>
        <CardBody>
          <div className="row">
            <FormDropdown
              isDisabled={isValidLoanAndClaimType || isFieldDisabled === true}
              className={styles.halfLeft}
              label={props.fields.claimTypeIndicatorLabel}
              name="claimTypeIndicator"
              options={props.fields.claimTypeIndicator[0].fields.listItems}
              disableDefaultSelect={false}
            />
            <FormNumber
              isReadOnly={isValidLoanAndClaimType || isFieldDisabled === true}
              className={styles.half}
              label={props.fields.loanNumber}
              name="cmhcLoanAccountNumber"
              onBlur={getFiandTransit}
            />
          </div>
          <div className={`row ${styles.secondFieldRow}`}>
            <FormText
              className={styles.oneThird}
              label={props.fields.lenderReferenceNumber}
              name="approvedLenderReferenceNumber"
              isReadOnly={isFieldDisabled === true}
            />
            {isInternalPortal ? (
              <>
                <FormText
                  className={styles.oneThird}
                  label={props.fields.institutionCodeLabel}
                  name="approvedLenderFinancialInstitutionCode"
                  isReadOnly={isFieldDisabled === true}
                />
                <FormText
                  className={`${styles.oneThird} ${
                    i18n.language === LanguageShort.French ? styles.transitNumberField : ''
                  }`.trim()}
                  label={props.fields.transitIdLabel}
                  name="approvedLenderTransitNumber"
                  isReadOnly={isFieldDisabled === true}
                />
              </>
            ) : (
              <>
                <FormDropdown
                  className={styles.oneThird}
                  label={props.fields.institutionCodeLabel}
                  name="approvedLenderFinancialInstitutionCode"
                  options={financialInstitutionOptions}
                  handleChange={handleFinancialInstitutionCodeChange}
                  setAsNumber={false}
                  isDisabled={isFieldDisabled === true}
                />
                <FormDropdown
                  className={`${styles.oneThird} ${
                    i18n.language === LanguageShort.French ? styles.transitNumberField : ''
                  }`.trim()}
                  label={props.fields.transitIdLabel}
                  name="approvedLenderTransitNumber"
                  options={transitIdOptions}
                  setAsNumber={false}
                  isDisabled={
                    transitIdOptions[0].fields.itemValue.value === '' || isFieldDisabled === true
                  }
                />
              </>
            )}
          </div>
          <br></br>
        </CardBody>

        {!isValidLoanAndClaimType ? (
          <CardFooter separator>
            <div className={styles.createClaimButton}>
              <Button
                responsive
                name="createClaimButton"
                text={props.fields.createClaimLabel}
                ariaText={props.fields.createClaimLabel}
                type="submit"
              />
            </div>
          </CardFooter>
        ) : null}
      </Card>
      <ToastNotification
        type={ApplicationStates.ERROR}
        isActive={showToast}
        content={i18n.t('DefaultSubmission-OtherErrors')}
        title={i18n.t('DefaultSubmission-ErrorToastTitle')}
        onCloseCallback={() => onToastCloseHandler()}
      />
    </fieldset>
  );
};

export default ClaimsValidation;
