import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import UserAdminContactInfoProps from 'Feature/UserManagement/models/UserAdminContactInfoProps';
import { telephoneFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormText, { Formatted } from 'Components/Inputs/FormText';
import FormNumber from 'Components/Inputs/FormNumber';
import styles from './styles.module.scss';

const UserAdminContactInfo: React.FC<UserAdminContactInfoProps> = ({
  fields,
  isModify
}: UserAdminContactInfoProps) => {
  return (
    <div>
      <h2 aria-hidden="true" className="card-title">
        <Text field={fields.sectionTitle} />
      </h2>
      <p>
        <Text field={fields.sectionSubtitle} />
      </p>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <Formatted
            className={styles.emailIDReadOnly}
            name="emailID"
            label={fields.email}
            isReadOnly={isModify}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormText className="" name="contact.firstName" label={fields.firstName} />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormText className="" name="contact.lastName" label={fields.lastName} />
        </div>
      </div>

      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormNumber
            className=""
            name="contact.phoneNumber"
            label={fields?.phoneNumber}
            formatProps={telephoneFormat}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormText className="" name="contact.extensionPhoneNumber" label={fields.extension} />
        </div>
      </div>

      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormNumber
            className=""
            name="contact.faxNumber"
            label={fields.faxNumber}
            formatProps={telephoneFormat}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormDropdown
            name="contact.preferredLanguageCode"
            label={fields.preferredLanguage}
            options={fields.languagePreferUser?.fields?.listItems}
          />
        </div>
      </div>
    </div>
  );
};

export default UserAdminContactInfo;
