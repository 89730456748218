import React, { useEffect } from 'react';
import { CashReceipt, CashReceiptTypeCode } from '@hobt/claim-domain';
import { LanguageShort } from '@hobt/constants';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import ConditionalRender from 'Components/Common/ConditionalRender';
import FormCard from 'Components/Common/FormCard';
import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';
import { currencyFormat, getCurrencyFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormComment from 'Components/Inputs/FormComment';
import FormDatepicker from 'Components/Inputs/FormDatepicker';
import FormNumber from 'Components/Inputs/FormNumber';
import FormYesNoRadios from 'Components/Inputs/FormYesNoRadios';
import { TooltipIcon } from 'Components/Inputs/TooltipIcon';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import ClaimsIncomeDetailsProps from 'Feature/Claims/models/ClaimsIncomeDetailsProps';
import { ButtonType } from 'Feature/CommonComponents/UserControls';
import { GlossaryNames } from 'Feature/PageComponents/components/GlossaryComponent/types';
import i18n from 'i18next';
import { useFieldArray, useWatch, useFormContext } from 'react-hook-form';
import { LanguageSwitchTypeCode } from '../ClaimsLenderDetails/types';
import styles from './styles.module.scss';
import type { RentalIncome, TRentalIncomeArray } from './types';

const DEFAULT_RENTAL_INCOME: RentalIncome = {
  receivedDate: undefined,
  receivedAmount: 0,
  comment: ''
};

export default ({ fields }: ClaimsIncomeDetailsProps) => {
  const {
    claimData,
    isInEditMode,
    isMasterUserEditingPostAdjudicationClaim,
    isClaimsDetails,
    isFieldDisabled
  } = useHBTFormContext();
  const { getValues, setValue, register, unregister, watch } = useFormContext();
  const { toCurrency } = useTranslationHelpers();

  const partialPayments = watch('partialPayments');

  const {
    fields: netOperatingIncomesRentalIncomes,
    append,
    remove
  }: TRentalIncomeArray = useFieldArray({
    name: 'netOperatingIncomesRentalIncomes'
  });
  const rentalIncomeReceived = useWatch({ name: 'rentalIncomeReceivedFlag' }) as string;
  const netOperatingIncomesRentalIncomesWatch = useWatch({
    name: 'netOperatingIncomesRentalIncomes'
  }) as RentalIncome[];

  const totalIncomes = (netOperatingIncomesRentalIncomesWatch ?? [])
    ?.map((income) => income?.receivedAmount ?? 0)
    ?.reduce((total, income) => total + income, 0)
    ?.toFixed(2);

  const rowAdditions: Array<[number, FieldValue]> = [
    [1, fields.rowOne],
    [5, fields.rowFive],
    [10, fields.rowTen]
  ];

  useEffect(() => {
    // We use a setValue here since the remove function from useFieldArray deletes not just the specified index but other data/fields in a nested array field
    if (isClaimsDetails === true || isInEditMode === false) {
      const rentalIncomeData = claimData?.cashReceipts?.filter?.(
        (receipt: CashReceipt) =>
          receipt.typeCode === CashReceiptTypeCode.NetOperatingIncomeRentalIncome
      );

      if (rentalIncomeReceived === 'true') {
        setValue('netOperatingIncomesRentalIncomes', rentalIncomeData);

        if (Array.isArray(rentalIncomeData) && rentalIncomeData.length > 0) {
          rentalIncomeData.forEach((rentalIncome: RentalIncome, index: number) => {
            if (rentalIncome.indexNumber != null) {
              register(`netOperatingIncomesRentalIncomes.${index}.indexNumber`);
              setValue(
                `netOperatingIncomesRentalIncomes.${index}.indexNumber`,
                rentalIncome.indexNumber
              );
            }
          });
        }
      }
    }
  }, [isClaimsDetails, isInEditMode, rentalIncomeReceived]);

  useEffect(() => {
    if (isClaimsDetails === false) {
      // On claim submission form, claimData is a draft-claim which can have rental incomes.
      const rentalIncomeData = claimData?.netOperatingIncomesRentalIncomes;
      if (rentalIncomeReceived === 'true') {
        if (Array.isArray(rentalIncomeData) && rentalIncomeData.length > 0) {
          setValue('rentalIncomeReceivedFlag', 'true');
          rentalIncomeData.forEach((rentalIncome: RentalIncome, index: number) => {
            if (rentalIncome?.previouslyApprovedAmount != null) {
              register(`netOperatingIncomesRentalIncomes.${index}.previouslyApprovedAmount`);
              setValue(
                `netOperatingIncomesRentalIncomes.${index}.previouslyApprovedAmount`,
                rentalIncome?.previouslyApprovedAmount.toString()
              );
            }
            if (rentalIncome?.previouslyApprovedDate != null) {
              register(`netOperatingIncomesRentalIncomes.${index}.previouslyApprovedDate`);
              setValue(
                `netOperatingIncomesRentalIncomes.${index}.previouslyApprovedDate`,
                rentalIncome?.previouslyApprovedDate
              );
            }
          });
        }
      }
    }
  }, [netOperatingIncomesRentalIncomes, rentalIncomeReceived]);

  // If a user ever sets the flag to No, then we need to unregister indexNumber
  useEffect(() => {
    if (rentalIncomeReceived === 'false') {
      unregister('netOperatingIncomesRentalIncomes');
      netOperatingIncomesRentalIncomes.forEach((_item, index: number) => {
        unregister(`netOperatingIncomesRentalIncomes.${index}.indexNumber`);
      });
    }
  }, [rentalIncomeReceived]);

  useEffect(() => {
    if (
      Array.isArray(netOperatingIncomesRentalIncomes) &&
      netOperatingIncomesRentalIncomes.length > 0
    ) {
      if (
        netOperatingIncomesRentalIncomes.some((rentalIncome) => rentalIncome.previouslyApprovedDate)
      ) {
        setValue('rentalIncomeReceivedFlag', 'true');
      }
    }
  }, [netOperatingIncomesRentalIncomes]);

  const handleRemove = (index: number) => {
    if (
      Array.isArray(netOperatingIncomesRentalIncomesWatch) &&
      netOperatingIncomesRentalIncomesWatch.length >= 1
    ) {
      const hasPreviousCashFields =
        netOperatingIncomesRentalIncomesWatch[index]?.previouslyApprovedAmount != null &&
        netOperatingIncomesRentalIncomesWatch[index]?.previouslyApprovedDate != null;
      // Only Master Users can remove the last NOI
      // Only remove when there is no previous cash
      if (
        hasPreviousCashFields === false &&
        (isMasterUserEditingPostAdjudicationClaim === true ||
          netOperatingIncomesRentalIncomesWatch.length > 1)
      ) {
        remove(index);
      }
    }
  };

  return (
    <FormCard
      title={fields.cardTitle}
      headingLevel={2}
      sectionId="claims-rental-income"
      fieldToValidate="rentalIncomeReceivedFlag"
      toolTipButton={{
        id: 'incomeGlossaryTooltip',
        name: 'incomeGlossaryTooltip',
        ariaText: fields.cardGlossaryAriaText?.value ?? '',
        onClick: () => {
          fields?.openGlossary?.(GlossaryNames.RentalIncomeDetails);
        },
        buttonType: ButtonType.TEXT
      }}
    >
      <FormYesNoRadios
        name="rentalIncomeReceivedFlag"
        testid="rentalIncomeReceivedFlag"
        className={styles.full}
        label={fields.rentalIncome}
        isReadOnly={isFieldDisabled}
      />
      <ConditionalRender
        condition={
          rentalIncomeReceived === 'true' || isMasterUserEditingPostAdjudicationClaim === true
        }
      >
        <div className={styles.headings}>
          <span id="cri_datePaid" className={styles.heading}>
            <Text field={fields.datePaid} />
          </span>
          <span id="cri_amount" className={styles.heading}>
            <Text field={fields.amount} />
          </span>
          <span className="sr-only" id="cri_comment">
            <Text field={fields.comments} />
          </span>
          <span className="sr-only" id="cri_delete">
            Delete
          </span>
        </div>
        <hr className={styles.separator} />
        {netOperatingIncomesRentalIncomes?.map((field, index) => (
          <React.Fragment key={field.id}>
            <div className={styles['income-row']}>
              <FormDatepicker
                name={`netOperatingIncomesRentalIncomes[${index}].receivedDate`}
                className={`${styles.rentalReceivedDate} ${
                  i18n.language === LanguageSwitchTypeCode.French ? styles.datePaidPullDown : ''
                }`.trim()}
                label={fields.datePaid}
                isReadOnly={isFieldDisabled}
              />
              <FormNumber
                name={`netOperatingIncomesRentalIncomes[${index}].receivedAmount`}
                className={styles.rentalReceivedAmount}
                label={fields.amount}
                formatProps={getCurrencyFormat(
                  getValues(`netOperatingIncomesRentalIncomes[${index}].receivedAmount`),
                  {
                    ...currencyFormat,
                    allowNegative: false
                  },
                  i18n.language as LanguageShort
                )}
                isReadOnly={isFieldDisabled}
              />
              <FormComment
                name={`netOperatingIncomesRentalIncomes[${index}].comment`}
                className={styles['comment-row']}
                label={fields.comments?.value ?? ''}
                updateLabel={fields.updateButton?.value ?? ''}
                cancelLabel={fields.cancelButton?.value ?? ''}
                characterLimitLabel={fields.characterLimit?.value ?? ''}
                addCommentLabel={fields.addComment?.value ?? ''}
                editCommentLabel={fields.editComment?.value ?? ''}
                isDisabled={isFieldDisabled}
              />
              {(field?.previouslyApprovedAmount == null ||
                field?.previouslyApprovedDate == null) && (
                <button
                  type="button"
                  className={styles['delete-row-button']}
                  onClick={() => handleRemove(index)}
                  disabled={isFieldDisabled}
                >
                  <span>
                    <TooltipIcon
                      icon={fields.deleteIcon}
                      text={i18n.t('DefaultSubmission-Card-Delete')}
                      className="icon-16"
                    />
                  </span>
                </button>
              )}
            </div>
            <hr className={styles.separator} />
          </React.Fragment>
        ))}
        <div className={`${styles.full} d-flex flex-column align-items-end`}>
          <span>
            <Text field={fields.totalLabel} />
          </span>
          <output htmlFor="netOperatingIncomesRentalIncomes">
            {toCurrency(parseFloat(totalIncomes))}
          </output>
        </div>
        <hr className={styles['full-separator']} />
        <div className={`${styles.full} d-flex`}>
          {rowAdditions?.map(([count, fieldText]) => (
            <button
              key={count}
              className={styles['add-rows-button']}
              type="button"
              onClick={() => append(Array(count).fill(DEFAULT_RENTAL_INCOME))}
              disabled={
                isFieldDisabled ||
                (count === 10 &&
                  (partialPayments?.length ?? 0) + (netOperatingIncomesRentalIncomes?.length ?? 0) >
                    90) ||
                (count === 5 &&
                  (partialPayments?.length ?? 0) + (netOperatingIncomesRentalIncomes?.length ?? 0) >
                    95) ||
                (count === 1 &&
                  (partialPayments?.length ?? 0) + (netOperatingIncomesRentalIncomes?.length ?? 0) >
                    99)
              }
            >
              <span
                aria-hidden={true}
                className={`${styles['add-rows-button_icon']} material-icons`}
              >
                add_circle_outline
              </span>
              <Text field={fieldText} />
            </button>
          ))}
        </div>
      </ConditionalRender>
    </FormCard>
  );
};
