import i18n from 'i18next';
import React from 'react';

import {
  DraftClaimStatusCodeExternalClaimStatusMapping,
  ExternalClaimStatus,
  InternalClaimStatus,
  getExternalStatus
} from '@hobt/claim-domain';
import { LanguageShort, ModuleMapping, StreetType } from '@hobt/constants';

import { ActionButtonCell } from 'Components/Common/DataTable/DataTableRow/ActionButtonCell';
import { convertDateForUi, convertDateOnlyForUi } from 'Components/Common/Api/utils/DateParser';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { IconCell } from 'Components/Common/DataTable/DataTableRow/IconCell';
import { MenuItemProps } from 'Components/Common/ActionMenu/types';
import { StatusCell } from 'Components/Common/DataTable/DataTableRow/StatusCell';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';

import { ClaimsInventoryTabEnum } from 'Feature/Claims/components/ClaimsInventory/types';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { ClaimButton, isButtonEnabled } from '../Details/isButtonEnabled';

import { TableRowProps } from './types';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ExternalTableRow = ({
  currentTab,
  isDraftClaimTab,
  row,
  onRowClick,
  emptyValuePlaceholder,
  rowActionMenu,
  claimStatusTypes,
  claimType,
  claimTypeIndicator
}: TableRowProps) => {
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;

  const claimIdToUse =
    isDraftClaimTab === true ? row?.draftClaimRecordID : row?.claimID?.toString();

  // Disable Cancel Button on these claim statuses
  const actionMenuItemIndex = rowActionMenu?.findIndex((menuItem) => menuItem.name === 'cancel');

  if (actionMenuItemIndex > -1 && row?.claimStatus != null) {
    // TODO: no-param-reassign
    // eslint-disable-next-line no-param-reassign
    rowActionMenu[actionMenuItemIndex].disable = !isButtonEnabled(
      ClaimButton.CANCEL_CLAIM,
      row?.claimStatus,
      moduleRoleMapping[ModuleMapping.claim]
    );
  }

  const rowActionItems = rowActionMenu?.map((action: MenuItemProps) => ({
    ...action,
    actionItemId: claimIdToUse
  }));

  const StandardRow: React.FC = () => (
    <DataTableRow
      name={claimIdToUse}
      key={claimIdToUse}
      onClick={onRowClick}
      testId={`${currentTab.name}-${claimIdToUse}`}
    >
      <ActionButtonCell name="rowActionMenu" rowActionItems={rowActionItems} rowId={claimIdToUse} />
      <TextCell name="lenderReference" text={row.approvedLenderReferenceNumber} />
      <IconCell
        name="iconCell"
        icons={[
          row.newInternalAttachmentFlag === true ? 'attach_file' : '',
          row.newInternalCommentFlag === true ? 'mail_outline' : ''
        ]}
      />
      <TextCell name="cmhcLoan" text={row.cmhcLoanAccountNumber?.toString(10)} />
      <StatusCell
        name="status"
        statusText={
          claimStatusTypes?.find(
            (status) =>
              status.value ===
              (currentTab.name === ClaimsInventoryTabEnum.ProcessInMics
                ? DraftClaimStatusCodeExternalClaimStatusMapping[row?.statusCode!]
                : (getExternalStatus(
                    row?.claimStatus as InternalClaimStatus
                  ) as ExternalClaimStatus))
          )?.text ?? emptyValuePlaceholder
        }
        className={
          claimStatusTypes?.find(
            (status) =>
              status.value ===
              (currentTab.name === ClaimsInventoryTabEnum.ProcessInMics
                ? DraftClaimStatusCodeExternalClaimStatusMapping[row?.statusCode!]
                : (getExternalStatus(
                    row?.claimStatus as InternalClaimStatus
                  ) as ExternalClaimStatus))
          )?.className ?? 'grey20'
        }
      />
      <TextCell
        name="submittedDate"
        text={convertDateOnlyForUi(row.claimReceivedDate ?? '', i18n.language as LanguageShort)}
      />
      <TextCell
        name="claimTypeIndicator"
        text={
          claimTypeIndicator?.fields?.listItems?.find(
            (item: SitecoreListItem) =>
              Number(item?.fields?.itemValue?.value) === row.claimTypeIndicator
          )?.fields?.itemName?.value
        }
      />
      <TextCell
        name="lenderName"
        text={
          i18n.language === 'en'
            ? row?.approvedLenderEnglishName ?? emptyValuePlaceholder
            : row?.approvedLenderFrenchName ?? emptyValuePlaceholder
        }
        subtext={row?.approvedLenderCode ?? emptyValuePlaceholder}
      />
      <TextCell name="transitNumber" text={row.approvedLenderTransitNumber} />
      <TextCell
        name="typeOfClaim"
        text={
          row.claimTypeCode != null
            ? claimType?.find((item) => item?.fields?.itemValue.value === row.claimTypeCode)?.fields
                ?.itemName?.value ?? emptyValuePlaceholder
            : emptyValuePlaceholder
        }
      />
      <TextCell
        name="primaryBorrowerName"
        text={`${row.primaryBorrowerFirstName} ${row.primaryBorrowerLastName}`}
      />
      <TextCell
        name="propertyAddress"
        text={
          row.propertyAddress != null
            ? `${row.propertyAddress.streetNumber} ${row.propertyAddress.streetName} ${
                row.propertyAddress.streetType
                  ? StreetType[row.propertyAddress.streetType as StreetType]
                  : ''
              }, ${row.propertyAddress.municipalityName}, ${row.propertyAddress.provinceCode}, ${
                row.propertyAddress.postalCode
              }`
            : emptyValuePlaceholder
        }
      />
      <TextCell name="userName" text={row.recordCreatedUserFullName ?? emptyValuePlaceholder} />
    </DataTableRow>
  );

  const DraftRow: React.FC = () => (
    <DataTableRow
      name={claimIdToUse}
      key={claimIdToUse}
      onClick={onRowClick}
      testId={`${currentTab.name}-${claimIdToUse}`}
    >
      <ActionButtonCell
        name="rowActionMenu"
        rowActionItems={rowActionItems}
        rowId={row?.draftClaimRecordID}
      />
      <TextCell name="lenderReference" text={row.approvedLenderReferenceNumber} />
      <IconCell
        name="iconCell"
        icons={[
          row.newInternalAttachmentFlag === true ? 'attach_file' : '',
          row.newInternalCommentFlag === true ? 'mail_outline' : ''
        ]}
      />
      <TextCell name="cmhcLoan" text={row.cmhcLoanAccountNumber?.toString(10)} />
      <TextCell
        name="claimTypeIndicator"
        text={
          row.claimTypeIndicator != null
            ? claimTypeIndicator?.fields?.listItems?.find(
                (item: SitecoreListItem) =>
                  Number(item?.fields?.itemValue?.value) === row.claimTypeIndicator
              )?.fields?.itemName?.value ?? emptyValuePlaceholder
            : emptyValuePlaceholder
        }
      />
      <TextCell
        name="lenderName"
        text={
          i18n.language === 'en'
            ? row?.approvedLenderEnglishName ?? emptyValuePlaceholder
            : row?.approvedLenderFrenchName ?? emptyValuePlaceholder
        }
        subtext={row?.approvedLenderCode ?? emptyValuePlaceholder}
      />
      <TextCell
        name="transitNumber"
        text={row.approvedLenderTransitNumber ?? emptyValuePlaceholder}
      />
      <TextCell
        name="lastSaved"
        text={convertDateForUi(
          row.recordUpdatedTimestamp ?? '',
          i18n.language as LanguageShort,
          true
        )}
      />
      <TextCell name="userName" text={row.recordCreatedUserFullName ?? emptyValuePlaceholder} />
    </DataTableRow>
  );

  return currentTab.name === ClaimsInventoryTabEnum.Draft ? <DraftRow /> : <StandardRow />;
};

export default ExternalTableRow;
