import React, {
  KeyboardEvent,
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Alert } from '@mui/material';
import { Button } from 'Components/Core/Button';
import Checkbox from 'Components/Core/Checkbox/Checkbox';
import DataTableHeader from 'Components/Core/DataTable/DataTableHeader';
import {
  ContentAlignment,
  DataTableColumnProps,
  DataTableProps,
  ErrorType,
  SortIconPosition
} from 'Components/Core/DataTable/types';
import i18n from 'i18next';
import { debounce } from 'lodash';
import { useHotkeys } from 'react-hotkeys-hook';
import { ButtonVariant } from '../Button/types';
import { MouseOrKeyboardEvent } from '../Modal/types';
import styles from './styles.module.scss';

/**
 *
 * @param data - Data to be displayed in the table
 * @param columnResize - Whether the columns are resizeable
 * @param multipleRowSubmission - Whether multiple rows can be submitted
 * @param stickyFirstColumn - Whether the first column is sticky
 * @param sortedColumn - The column that is currently sorted
 * @param sortAscending - Whether the sort is ascending
 * @param submitButtonLabel - The label for the submit button
 * @param tableCaption - The caption for the table
 * @param onRowClick - Callback for when a row is clicked
 * @param onSort - Callback for when a column is sorted
 * @param onSubmit - Callback for when the submit button is clicked
 * @param children - The columns of the table
 * @param uniqueKey - A unique key to identify each row in the table
 * @param isLoading - Whether the table data is currently loading
 * @param isDeleteButtonLoading - Whether the delete button is currently loading
 * @param isSubmitButtonLoading - Whether the submit button is currently loading
 * @param clearSelectionRows - Whether the row selection should be cleared
 * @param deleteItem - Function to delete a selected item
 * @param deleteButtonLabel - The label for the delete button
 * @param footer - Content for the table's footer
 * @param emptyTableMessage - Message to display when the table has no data
 * @param errorType - Type of error to display, if any
 * @param onColumnsReady - Callback when the columns are ready
 * @param onDelete - Callback for when an item is deleted
 * @param onRowSelectionChange - Callback when the row selection changes
 * @param onRowSelectionCleared - Callback when the row selection is cleared
 * @param tableErrorMessage - Error message to display for the table
 */

/*
  This component is used to display a table of data. 
  It supports sorting, column resizing, and row selection.
  It also supports submitting multiple rows of data with checkboxes displayed on the first column.  
 */

const DataTable = <T extends object>({
  data,
  uniqueKey,
  isLoading,
  isDeleteButtonLoading = false,
  isSubmitButtonLoading = false,
  clearSelectionRows = false,
  columnResize = false,
  multipleRowSubmission = false,
  stickyFirstColumn = false,
  sortedColumn,
  deleteItem,
  deleteButtonLabel,
  footer,
  sortAscending,
  submitButtonLabel = i18n.t('DefaultSubmission-ModalSubmitButton') ?? 'Submit',
  tableCaption = 'HBT Data Table',
  emptyTableMessage,
  errorType = null,
  onColumnsReady,
  onDelete,
  onRowClick,
  onSort,
  onSubmit,
  children,
  tableErrorMessage,
  onRowSelectionChange,
  onRowSelectionCleared
}: DataTableProps<T>) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
  const [isHeaderCheckboxChecked, setIsHeaderCheckboxChecked] = useState(false);
  const [isHeaderCheckboxIndeterminate, setIsHeaderCheckboxIndeterminate] = useState(false);
  const [focusedCell, setFocusedCell] = useState<{ row: number; col: number } | null>(null);

  const memoizedChildren = useMemo(() => React.Children.toArray(children), [children]);

  const columns = useMemo(
    () => memoizedChildren as ReactElement<DataTableColumnProps<T>>[],
    [memoizedChildren]
  );

  const columnSettings = useMemo(
    () =>
      columns.map((column) => ({
        key: column.props.name as keyof T,
        displayName: column.props.displayName,
        width: column.props.width ?? 'auto',
        sortable: column.props.sortable ?? true,
        filterable: column.props.filterable ?? true,
        headerAlignment: column.props.headerAlignment ?? ContentAlignment.Start,
        rowAlignment: column.props.rowAlignment ?? ContentAlignment.Center,
        leadingIcon: column.props.leadingIcon,
        trailingIcon: column.props.trailingIcon,
        sortIconPosition: column.props.sortIconPosition ?? SortIconPosition.Trailing,
        render: column.props.children
      })),
    [columns]
  );

  const debouncedOnColumnsReady = useMemo(
    () => debounce((cols) => onColumnsReady && onColumnsReady(cols), 300),
    [onColumnsReady]
  );

  useEffect(() => {
    debouncedOnColumnsReady(columns);
  }, [columns, debouncedOnColumnsReady]);

  useEffect(() => {
    if (clearSelectionRows) {
      setSelectedRows(new Set());
      if (onRowSelectionCleared) {
        onRowSelectionCleared();
      }
    }
  }, [clearSelectionRows, onRowSelectionCleared]);

  useEffect(() => {
    const selectedCount = selectedRows.size;
    if (selectedCount === 0) {
      setIsHeaderCheckboxChecked(false);
      setIsHeaderCheckboxIndeterminate(false);
    } else if (selectedCount === data.length) {
      setIsHeaderCheckboxChecked(true);
      setIsHeaderCheckboxIndeterminate(false);
    } else {
      setIsHeaderCheckboxChecked(false);
      setIsHeaderCheckboxIndeterminate(true);
    }
  }, [selectedRows, data.length]);

  const handleCheckboxChange = useCallback(
    (item: T) => {
      const rowId = item[uniqueKey] as unknown as string;
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = new Set(prevSelectedRows);
        if (newSelectedRows.has(rowId)) {
          newSelectedRows.delete(rowId);
        } else {
          newSelectedRows.add(rowId);
        }
        const selectedData = Array.from(newSelectedRows).map((id) =>
          data.find((item) => item[uniqueKey] === id)
        );
        if (onRowSelectionChange) onRowSelectionChange(selectedData as T[]);
        return newSelectedRows;
      });
    },
    [onRowSelectionChange, data]
  );

  const handleHeaderCheckboxChange = useCallback(() => {
    setSelectedRows((prevSelectedRows) => {
      const allSelected = prevSelectedRows.size === data.length;

      const newSelectedRows: Set<string> = allSelected
        ? new Set()
        : new Set(data.map((item: T) => item[uniqueKey] as unknown as string));

      const selectedData = Array.from(newSelectedRows).map((id) =>
        data.find((item) => item[uniqueKey] === id)
      );

      if (onRowSelectionChange) {
        onRowSelectionChange(selectedData as T[]);
      }
      return newSelectedRows;
    });
  }, [data, onRowSelectionChange]);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent, index: number) => {
      if (!columnResize) return;

      const startX = e.pageX;
      const startWidth = tableRef.current!.querySelectorAll('th')[index].clientWidth;

      const cells = tableRef.current!.querySelectorAll(
        `th:nth-child(${index + 1}), td:nth-child(${index + 1})`
      );
      cells.forEach((cell) => cell.classList.add(styles.resizing));

      const onMouseMove = (e: MouseEvent) => {
        const newWidth = startWidth + (e.pageX - startX);
        tableRef.current!.querySelectorAll('th')[index].style.width = `${newWidth}px`;
        tableRef.current!.querySelectorAll(`td:nth-child(${index + 1})`).forEach((td) => {
          (td as HTMLElement).style.width = `${newWidth}px`;
        });
      };

      const onMouseUp = () => {
        cells.forEach((cell) => cell.classList.remove(styles.resizing));
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [columnResize]
  );

  const handleRowClick = useCallback(
    (rowItem: T) => {
      const rowId = rowItem[uniqueKey] as T[keyof T];

      if (multipleRowSubmission) {
        handleCheckboxChange(rowItem);
      }
      if (onRowClick) {
        onRowClick(rowId);
      }
    },
    [multipleRowSubmission, handleCheckboxChange, onRowClick, uniqueKey]
  );

  const handleDelete = useCallback(
    (e?: MouseOrKeyboardEvent) => {
      if (onDelete) onDelete(e);
    },
    [onDelete]
  );

  const handleSubmit = useCallback(() => {
    if (onSubmit) {
      const selectedData = Array.from(selectedRows).map((id) =>
        data.find((item) => item[uniqueKey] === id)
      );
      onSubmit(selectedData as T[]);
    }
  }, [onSubmit, selectedRows, data]);

  const rowStyle = useMemo(() => {
    return onRowClick || multipleRowSubmission ? styles.enableHover : '';
  }, [onRowClick, multipleRowSubmission]);

  const stickyStyle = useCallback(
    (colIndex: number, rowIndex: number) => {
      const cellBgColor =
        rowIndex % 2 === 1 ? styles.greyCellBackground : styles.whiteCellBackground;
      return !(colIndex === 0 && stickyFirstColumn && !multipleRowSubmission)
        ? ''
        : `${styles.sticky} ${cellBgColor}`;
    },
    [stickyFirstColumn, multipleRowSubmission]
  );

  const alignmentStyle = useCallback(
    (index: number) => styles[`align${columnSettings[index].rowAlignment}` as keyof typeof styles],
    [columnSettings]
  );

  const checkboxWrapperStyle = useCallback(
    (index: number) => {
      const cellBgColor = index % 2 === 1 ? styles.greyCellBackground : styles.whiteCellBackground;
      return `${styles.checkboxWidth} ${stickyFirstColumn ? styles.sticky : ''} ${cellBgColor}`;
    },
    [stickyFirstColumn]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTableElement>) => {
      if (!focusedCell) return;

      const { row, col } = focusedCell;

      switch (e.key) {
        case 'ArrowRight':
          e.preventDefault();
          if (col < columnSettings.length - 1) {
            setFocusedCell({ row, col: col + 1 });
          }
          break;
        case 'ArrowLeft':
          e.preventDefault();
          if (col > 0) {
            setFocusedCell({ row, col: col - 1 });
          }
          break;
        case 'ArrowDown':
          e.preventDefault();
          if (row < data.length - 1) {
            setFocusedCell({ row: row + 1, col });
          }
          break;
        case 'ArrowUp':
          e.preventDefault();
          if (row > 0) {
            setFocusedCell({ row: row - 1, col });
          }
          break;
        default:
          break;
      }
    },
    [focusedCell, columnSettings.length, data.length]
  );

  const handleCellFocus = useCallback((rowIndex: number, colIndex: number) => {
    setFocusedCell({ row: rowIndex, col: colIndex });
  }, []);

  useHotkeys('ctrl+alt+t', () => {
    if (tableRef.current) {
      tableRef.current.focus();
      setFocusedCell({ row: 0, col: 0 });
    }
  });

  useHotkeys('ctrl+shift+alt+t', () => {
    setFocusedCell(null);
    if (document.activeElement) (document.activeElement as HTMLElement).blur();
  });

  useEffect(() => {
    if (focusedCell && tableRef.current) {
      const { row, col } = focusedCell;
      const colIncrement = multipleRowSubmission ? 2 : 1;
      const cell = tableRef.current.querySelector(
        `tbody tr:nth-child(${row + 1}) td:nth-child(${col + colIncrement})`
      ) as HTMLElement;
      if (cell) {
        cell.focus();
      }
    }
  }, [focusedCell]);

  return (
    <div className={styles.dataTableMainContainer}>
      <div className={styles.tableContainer}>
        <table
          ref={tableRef}
          className={styles.dataTable}
          tabIndex={-1}
          role="grid"
          aria-rowcount={data.length}
          aria-colcount={columnSettings.length}
          aria-invalid={errorType ? 'true' : 'false'}
          aria-errormessage="error-datatable"
          data-testid="data-table"
          onKeyDown={handleKeyDown}
        >
          <caption className={styles.caption}>{tableCaption}</caption>
          <thead>
            <tr role="row">
              {multipleRowSubmission && (
                <th
                  role="columnheader"
                  aria-sort="none"
                  data-testid="table-header-cell"
                  className={checkboxWrapperStyle(0)}
                >
                  <div className={styles.checkboxCell}>
                    <Checkbox
                      indeterminate={isHeaderCheckboxIndeterminate}
                      checked={isHeaderCheckboxChecked}
                      onChange={handleHeaderCheckboxChange}
                    />
                  </div>
                </th>
              )}
              {columnSettings.map((column, index) => (
                <th
                  key={String(column.key)}
                  className={stickyStyle(index, 0)}
                  tabIndex={0}
                  role="columnheader"
                  aria-sort={
                    sortedColumn === column.key
                      ? sortAscending
                        ? 'ascending'
                        : 'descending'
                      : 'none'
                  }
                  data-testid="table-header-cell"
                  style={{ width: column.width }}
                >
                  <DataTableHeader
                    label={column.displayName}
                    alignment={column.headerAlignment}
                    sortable={column.sortable}
                    isSorting={sortedColumn === column.key}
                    sortAscending={sortAscending}
                    leadingIcon={column.leadingIcon}
                    trailingIcon={column.trailingIcon}
                    sortIconPosition={column.sortIconPosition}
                    onSort={() => onSort(column.key)}
                  />
                  {columnResize && (
                    <div
                      className={styles.resizeHandle}
                      onMouseDown={(e) => handleMouseDown(e, index)}
                    />
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              Array.from({ length: 10 }).map((_, index) => (
                <tr key={index} role="row">
                  <td
                    role="gridcell"
                    colSpan={columnSettings.length + (multipleRowSubmission ? 1 : 0)}
                  >
                    <div className={styles.loadingBar}></div>
                  </td>
                </tr>
              ))
            ) : data.length === 0 ? (
              <tr role="row">
                <td
                  role="gridcell"
                  colSpan={columnSettings.length + (multipleRowSubmission ? 1 : 0)}
                >
                  <div className={styles.noDataMessage}>
                    {emptyTableMessage ??
                      i18n.t('Globals-Table-No-Search-To-Display', 'No result to display')}
                  </div>
                </td>
              </tr>
            ) : (
              data.map((item, rowIndex) => (
                <tr key={rowIndex} role="row" className={rowStyle}>
                  {multipleRowSubmission && (
                    <td role="gridcell" className={checkboxWrapperStyle(rowIndex)}>
                      <div className={styles.checkboxCell}>
                        <Checkbox
                          errorCheckbox={
                            errorType === ErrorType.CannotDownload ||
                            errorType === ErrorType.NoSelectedCheckbox
                          }
                          checked={selectedRows.has(item[uniqueKey] as unknown as string)}
                          onChange={() => handleCheckboxChange(item)}
                        />
                      </div>
                    </td>
                  )}
                  {columnSettings.map((column, colIndex) => (
                    <td
                      key={String(column.key)}
                      tabIndex={0}
                      role="gridcell"
                      className={stickyStyle(colIndex, rowIndex)}
                      style={{ width: column.width }}
                      onClick={() => handleRowClick(item)}
                      onFocus={() => handleCellFocus(rowIndex, colIndex)}
                    >
                      <div className={alignmentStyle(colIndex)}>
                        {column.render
                          ? column.render(item[column.key], item)
                          : String(item[column.key])}
                      </div>
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
        {tableErrorMessage && (
          <Alert className={styles.alertBox} severity="error" id="error-datatable" role="alert">
            {tableErrorMessage}
          </Alert>
        )}
        <div>{footer && footer}</div>
      </div>
      {multipleRowSubmission && (
        <div className={styles.dataTableSubmitButtonContainer}>
          {deleteItem && (
            <Button
              name="table-delete-item"
              ariaText={{ value: deleteButtonLabel }}
              text={{ value: deleteButtonLabel }}
              loading={isDeleteButtonLoading}
              disabled={isDeleteButtonLoading}
              variant={ButtonVariant.SECONDARY}
              onClick={(e?: MouseOrKeyboardEvent) => handleDelete(e)}
            />
          )}
          <Button
            name="table-submit"
            ariaText={{ value: submitButtonLabel }}
            text={{ value: submitButtonLabel }}
            loading={isSubmitButtonLoading}
            disabled={isSubmitButtonLoading}
            variant={ButtonVariant.PRIMARY}
            onClick={handleSubmit}
          />
        </div>
      )}
    </div>
  );
};

export default memo(DataTable) as typeof DataTable;
