import * as React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import GlobalModalComponentProps from '../../models/GlobalModalComponentProps';

const ChangeStatusConfirmationComponent = (props: GlobalModalComponentProps) => (
  <div>
    <p>Change Status Confirmation Component</p>
    <Text field={props.fields.heading} />
    <Text field={props.fields.content} />
    <Text field={props.fields.acceptButton} />
    <Text field={props.fields.cancelButton} />
  </div>
);

export default ChangeStatusConfirmationComponent;
