import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';

import AnnouncementsColumnProps from 'Feature/DashboardComponents/components/AnnouncementsColumn/types';
import { useAnnouncementFunctions } from 'Feature/DashboardComponents/components/AnnouncementsColumn/useAnnouncementFunctions';
import Announcement from '../Announcement';

import styles from './styles.module.scss';

const AnnouncementsColumn: React.FC<AnnouncementsColumnProps> = ({
  fields
}: AnnouncementsColumnProps) => {
  const locale = i18n.language;

  const { showAllItems, toggleAllItems, getAnnouncements } = useAnnouncementFunctions(fields);

  return (
    <>
      <div className={styles.announcementHeader} data-testid="announcement-region_header">
        <Text tag="h2" field={fields.heading} />
        <button
          onClick={toggleAllItems}
          className={styles.showHideButton}
          aria-label={
            showAllItems
              ? fields.showLessButtonText?.value ?? ''
              : fields.showMoreButtonText?.value ?? ''
          }
        >
          <span className={`material-icons ${styles.buttonIcon}`} aria-hidden={true}>
            {showAllItems ? 'expand_less' : 'expand_more'}
          </span>
          <Text
            tag="span"
            field={showAllItems ? fields.showLessButtonText : fields.showMoreButtonText}
          />
        </button>
      </div>

      {fields.announcements.length > 0 ? (
        <ul className={`${styles.announcementList} ${showAllItems ? styles.showAll : ''}`.trim()}>
          {getAnnouncements().map((item, idx) => (
            <li key={`announcement-${idx}`}>
              <Announcement item={item} locale={locale} />
            </li>
          ))}
        </ul>
      ) : (
        <Text tag="p" field={fields.noAnnouncementsText!} />
      )}
    </>
  );
};

export default AnnouncementsColumn;
