import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { ClickAwayListener, MenuList, MenuItem, Paper, Popper, Box } from '@mui/material';
import styles from './styles.module.scss';
import { ActionDropdownProps, ActionItemOption } from './types';

/**
 *
 * @param {ActionDropdownProps} props - list out the props.
 * @param {boolean} props.open - opens the dropdown menu
 * @param {Partial<Modifier<any, any>>[]} props.popperModifiers - adjusts the position of dropdown menu relative to anchor as per need
 * @param {boolean} props.onToggle - callback function that changes open state of the dropdown menu
 * @param {Option[]} props.options - list of options for the dropdown menu which includes label, value, icon and the event to trigger when clicked
 * @param {JSX.Element} props.trigger - the anchor element which when clicked would toggle the dropdown menu
 * @returns {ActionDropdown} Rendered Action Menu Dropdown component.
 */

/**
 * Action Dropdown component
 *
 * This is the Action Menu Dropdown component written using storybook. It handles different hover, focused and active states.
 */

export const ActionDropdown: React.FC<ActionDropdownProps> = memo(
  ({
    open,
    isOptionALink,
    popperModifiers = [
      {
        name: 'offset',
        options: {
          offset: [0, 0]
        }
      }
    ],
    onToggle,
    options,
    trigger,
    className
  }) => {
    const anchorRef = useRef<HTMLDivElement>(null);
    // we only want to auto focus first elemnet when menu opened through keyboard
    const [autofocusMenuItem, setAutofocusMenuItem] = useState<boolean>(false);
    const [isMounted, setIsMounted] = useState(false);

    if (!options || options.length == 0) return null;

    const handleToggle = useCallback(() => {
      if (open) {
        setAutofocusMenuItem(false);
      }
      onToggle(!open);
    }, [open]);

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' || e.key === ' ') {
          setAutofocusMenuItem(!open);
          handleToggle();
          e.preventDefault();
        }
      },
      [handleToggle]
    );

    const handleItemClick = useCallback(
      (option: ActionItemOption, e: React.MouseEvent<HTMLElement>) => {
        option.onClick(e);
        handleToggle();
      },
      [handleToggle]
    );

    /* 
    In scenarios where menu is open initially (rare but possible) anchorRef.current won't be assigned trigger's
    DOM node fast enough to be populated for Popper to use it so it will still be null, meaning the Menu won't have
    anchor when it first loads and it will load on top left of the viewporet which we don't want
  */
    useEffect(() => {
      if (anchorRef.current) {
        setIsMounted(true);
      }
    }, []);

    return (
      <ClickAwayListener
        onClickAway={() => {
          setAutofocusMenuItem(false);
          onToggle(false);
        }}
      >
        <>
          <Box
            onClick={handleToggle}
            onKeyDown={handleKeyDown}
            ref={anchorRef}
            aria-haspopup="true"
          >
            {trigger}
          </Box>
          {isMounted && (
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              placement="bottom-start"
              className={`${styles.actionDropdownBody} ${className}`}
              modifiers={popperModifiers}
              data-testid="action-menu-list"
              disablePortal
            >
              <Paper className={styles.actionMenuPaper}>
                <MenuList className={styles.actionMenuList}>
                  {options.map((option, index) => (
                    <MenuItem
                      autoFocus={autofocusMenuItem && index == 0}
                      className={`${styles.actionMenuItem} ${
                        isOptionALink ? styles.actionMenuItemLink : ''
                      }`}
                      key={option.value}
                      tabIndex={0}
                      data-testid="action-menu-item"
                      onClick={(e: React.MouseEvent<HTMLElement>) => handleItemClick(option, e)}
                      disableRipple
                    >
                      {option.icon && (
                        <span className={styles.actionMenuListItemIcon}>{option.icon}</span>
                      )}
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </Popper>
          )}
        </>
      </ClickAwayListener>
    );
  }
);
