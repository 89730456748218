import { useState } from 'react';

export const useArrearsSubmissionFunctions = (completedCards: string[]) => {
  const [completeCards, setCompleteCards] = useState<Array<string>>(completedCards ?? []);

  const isCardComplete = (name: string | undefined): boolean => {
    return name != null ? completeCards.includes(name) : false;
  };

  const addCompleteCard = (name: string | undefined) => {
    if (name != null) {
      setCompleteCards((currentState: string[]) => {
        return currentState.indexOf(name) === -1 ? [...currentState, name] : currentState;
      });
    }
  };

  const removeCompleteCard = (name: string | undefined) => {
    setCompleteCards((currentState: string[]) =>
      currentState.filter((cardName: string) => cardName !== name)
    );
  };

  const clearCompleteCards = () => setCompleteCards([]);

  return {
    completeCards,
    setCompleteCards,
    isCardComplete,
    addCompleteCard,
    removeCompleteCard,
    clearCompleteCards
  };
};
