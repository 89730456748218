import React, { useState } from 'react';

/*       
  Ensure that when scroll bar is closely near the horizontal end,
  the buttons will be updated within that area rather than reaching the
  horizontal end fully. Value (range - 3%) is enough to take care of cases
  like when scrollLeft is a bit off due to scrollbar shape.
*/
const SCROLL_LEFT_SAFETY_FACTOR = 1.03;

export const useScrollableDataContainerFunctions = (ref?: React.RefObject<HTMLDivElement>) => {
  const [tableScrolled, setTableScrolled] = useState<boolean>(false);
  const [visibleShiftButtons, setVisibleShiftButtons] = useState<{ left: boolean; right: boolean }>(
    { left: false, right: true }
  );

  const scroll = ref?.current;

  const onScroll = () => {
    setTableScrolled(scroll?.scrollLeft != null && scroll?.scrollLeft > 1);
    setVisibleShiftButtons({
      left: scroll?.scrollLeft != null && scroll?.scrollLeft > 0,
      right:
        scroll?.scrollLeft != null &&
        SCROLL_LEFT_SAFETY_FACTOR * (scroll?.scrollLeft ?? 0) <
          (scroll?.scrollWidth ?? 0) - (scroll?.offsetWidth ?? 0)
    });
  };

  const onLeftScrollButton = () => {
    if (scroll) {
      scroll.scrollLeft = 0;
    }
  };

  const onRightScrollButton = () => {
    if (scroll) {
      scroll.scrollLeft = scroll.scrollWidth - scroll.offsetWidth;
    }
  };

  return {
    tableScrolled,
    onScroll,
    onRightScrollButton,
    onLeftScrollButton,
    visibleShiftButtons
  };
};
