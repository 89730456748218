import React, { useRef, useState } from 'react';
import { Link } from 'react-scroll';

import { getProgressBarNewScrollPosition } from 'Components/ProgressBar/UtilityFunctions';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

import { FormCardProps } from '../CommonTypes/FormCards';
import ProgressBarItem from './ProgressBarItem';
import { config } from '../../config';
import { ProgressStepsProps } from './types';
import styles from './styles.module.scss';

export const ProgressSteps: React.FC<ProgressStepsProps> = ({
  cards,
  onClickCallback,
  scrollDuration,
  cardNameToValidationFieldMap
}: ProgressStepsProps) => {
  const isExternalPortal = config.app.siteType === FeatureFlags.EXTERNAL;
  const claimsIndicatorDetailsCard = 'claimsIndicatorDetails';
  const [activeItem, setActiveItem] = useState(cards[0].fields.name.value);
  const [activationLock, setActivationLock] = useState(false);

  const progressBarScrollContainer = useRef<HTMLDivElement | null>(null);

  const handleSetActive = (itemName: string) => {
    if (progressBarScrollContainer.current) {
      progressBarScrollContainer.current.scrollTop = getProgressBarNewScrollPosition(
        progressBarScrollContainer.current,
        document.getElementById(itemName)
      );
    }

    if (!activationLock) setActiveItem(itemName);
  };

  const handleClick = (itemName: string) => {
    setActivationLock(true);
    setActiveItem(itemName);
    setTimeout(() => {
      setActivationLock(false);
    }, scrollDuration);
  };

  return (
    <div className={styles.scrollContainer} ref={progressBarScrollContainer}>
      <ul className={styles.progressbar}>
        <li>
          {cards.map((item: FormCardProps, cardIndex: number) =>
            isExternalPortal && item.fields.name.value === claimsIndicatorDetailsCard ? null : (
              <Link
                key={item.fields.name.value}
                to={item.fields.name.value!}
                spy={true}
                smooth={true}
                duration={scrollDuration}
                onSetActive={() => handleSetActive(item.fields.name.value!)}
                onClick={() => handleClick(item.fields.name.value!)}
                isDynamic={true}
              >
                <ProgressBarItem
                  text={item.fields.cardTitle}
                  fieldToValidate={
                    cardNameToValidationFieldMap
                      ? cardNameToValidationFieldMap[item.fields.name.value || '']
                      : ''
                  }
                  current={activeItem === item.fields.name.value}
                  name={item.fields.name}
                  onClick={onClickCallback}
                />
                {cardIndex !== cards.length - 1 && <div></div>}
              </Link>
            )
          )}
        </li>
      </ul>
    </div>
  );
};

export type { FormCardProps };
