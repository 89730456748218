import i18next from 'i18next';
import React, { useContext } from 'react';
import { FinalBorrowerCharge } from '@hobt/claim-domain';
import { useFormContext } from 'react-hook-form';

import { LanguageShort } from '@hobt/constants';

import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';
import { FormFieldCell } from 'Components/Common/DataTable/DataTableRow/FormFieldCell';
import { getCurrencyFormat, currencyFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormNumber from 'Components/Inputs/FormNumber';
import FormDatepicker from 'Components/Inputs/FormDatepicker';

import {
  BorrowerChargeRow,
  BorrowerChargeItem,
  BorrowerCharges
} from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/BorrowerCharge/types';
import { AssessmentCalculationsContext } from 'Feature/Claims/components/Details/AssessmentCalculations';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { useBorrowerChargeFunctions } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/BorrowerCharge/useBorrowerChargeFunctions';
import { CommentCell } from 'Components/Common/DataTable/DataTableRow/CommentCell';
import { TrendableCell } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/TrendableCell';
import { defaultEmptyString } from 'Components/Common/Api/utils/HandleEmpty';
import { ScrollableDataTableContainer } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/ScrollableDataTableContainer';

import { BuildDropDownDictionary } from 'Components/Common/Helpers';
import styles from './styles.module.scss';

const sum = (acc: number, val: number) => acc + val;

export const BorrowerCharge: React.FC = () => {
  const { toCurrency, toLocaleDate } = useTranslationHelpers();
  const { isEditing, sitecoreContent } = useContext(AssessmentCalculationsContext);
  const { watch, getValues } = useFormContext();
  const { claimData, isClaimStatusPostAdjudicationStatus } = useHBTFormContext();

  const finalClaim = claimData;
  const formData = watch('borrowerCharges');

  const { parseBorrowerChargesData } = useBorrowerChargeFunctions();

  const dataRows: BorrowerCharges = parseBorrowerChargesData(finalClaim);

  const borrowerChargeFields = sitecoreContent?.borrowerChargeCardLabels?.fields;

  const codeRowDictionary = BuildDropDownDictionary(
    borrowerChargeFields?.codeRows.fields.listItems
  );

  return (
    <div className={styles.content}>
      <ScrollableDataTableContainer className={styles.dataTable} name="borrowerCharge">
        <fieldset
          className={
            !isEditing || isClaimStatusPostAdjudicationStatus === true
              ? styles.fieldset
              : styles.hideDateIconFieldset
          }
        >
          <DataTable
            name="borrowerCharge"
            caption={borrowerChargeFields?.captionTextBorrowerCharge ?? {}}
            addTableWrapper={false}
          >
            <DataTableHeader>
              <DataTableHeaderItem
                name="borrowerChargeNatureOfCharge"
                displayText={borrowerChargeFields?.natureOfCharge}
              />
              <DataTableHeaderItem
                name="borrowerChargePreviouslyApproved"
                displayText={borrowerChargeFields?.previouslyApproved}
              />
              <DataTableHeaderItem
                name="borrowerChargeSubmitted"
                displayText={borrowerChargeFields?.submittedAmount}
              />
              <DataTableHeaderItem
                name="borrowerChargeAcceptable"
                displayText={borrowerChargeFields?.acceptableAmount}
              />
              <DataTableHeaderItem
                name="borrowerChargeAdjusted"
                displayText={borrowerChargeFields?.adjustedAmount}
              />
              <DataTableHeaderItem
                name="borrowerChargeDifferenceAmount"
                displayText={borrowerChargeFields?.differenceAmount}
              />
              <DataTableHeaderItem name="comments" />
            </DataTableHeader>
            <DataTableBody zebra>
              {dataRows.chargeRows?.map((row: BorrowerChargeRow) => {
                const natureOfChargeLabel = ''; // TODO: To be confirmed
                const rows = row.items?.map((item: BorrowerChargeItem, idx) => (
                  <DataTableRow
                    name={`borrowerCharge-${row.natureOfCharge}-${idx}`}
                    key={`borrowerCharge-${row.natureOfCharge}-${idx}`}
                  >
                    <TextCell
                      name={`borrowerCharge-${row.natureOfCharge}-${idx}-natureOfChargeLabel`}
                      text={`${codeRowDictionary.get(row.natureOfCharge.toString())}`}
                    />
                    <TextCell
                      text={toCurrency(item.previouslyApprovedAmount)}
                      subtext={
                        item.previouslyApprovedDate
                          ? toLocaleDate(item.previouslyApprovedDate)
                          : undefined
                      }
                      name={`borrowerCharge-${row.natureOfCharge}-PreviouslyApproved`}
                    />
                    <TextCell
                      text={toCurrency(item.receivedAmount)}
                      subtext={item.receivedDate ? toLocaleDate(item.receivedDate) : undefined}
                      name={`borrowerCharge-${row.natureOfCharge}-Submitted`}
                    />
                    <TextCell
                      text={toCurrency(item.acceptableAmount)}
                      subtext={item.acceptableDate ? toLocaleDate(item.acceptableDate) : undefined}
                      name={`borrowerCharge-${row.natureOfCharge}-Acceptable`}
                    />
                    {item.idx != null && formData?.[item.idx]?.previouslyApprovedAmount == null ? (
                      <FormFieldCell>
                        <div className={styles.adjustedValueWrapper}>
                          <div
                            className={
                              isEditing ? styles.trendValueWrapper : styles.trendValueWrapperActive
                            }
                          >
                            {item.idx != null &&
                              item.acceptableAmount != null &&
                              item.receivedAmount != null &&
                              formData?.[item.idx]?.adjustedAmount != null && (
                                <TrendableCell
                                  name={`borrowerCharges[${item.idx}].adjustedAmountTrend`}
                                  acceptable={item.acceptableAmount}
                                  adjusted={formData?.[item.idx]?.adjustedAmount}
                                  received={item.receivedAmount}
                                />
                              )}

                            <FormNumber
                              name={`borrowerCharges[${item.idx}].adjustedAmount`}
                              className={`${styles.tableInput} ${
                                item.idx != null &&
                                item.acceptableAmount != null &&
                                item.receivedAmount != null &&
                                formData?.[item.idx]?.adjustedAmount != null
                                  ? styles.trendable
                                  : ''
                              }`}
                              label={{ value: 'adjustedAmount' }}
                              formatProps={getCurrencyFormat(
                                getValues(`borrowerCharges[${item.idx}].adjustedAmount`),
                                currencyFormat,
                                i18next.language as LanguageShort
                              )}
                              isReadOnly={
                                !isEditing || isClaimStatusPostAdjudicationStatus === true
                              }
                              tabIndex={
                                !isEditing || isClaimStatusPostAdjudicationStatus === true
                                  ? -1
                                  : undefined
                              }
                            />
                          </div>

                          <FormDatepicker
                            name={`borrowerCharges[${item.idx}].adjustedDate`}
                            className={styles.tableInput}
                            label={{ value: 'adjustedDate' }}
                            isReadOnly={
                              !isEditing ||
                              isClaimStatusPostAdjudicationStatus === true ||
                              item.previouslyApprovedAmount != null
                            }
                          />
                        </div>
                      </FormFieldCell>
                    ) : (
                      <TextCell
                        text={defaultEmptyString}
                        name={`borrowerCharge-${row.natureOfCharge}-AdjustedDate`}
                      />
                    )}

                    <TextCell
                      text={toCurrency(item.differenceAmount)}
                      name={`borrowerCharge-${row.natureOfCharge}-DifferenceAmount`}
                    />
                    {item.comment ? (
                      <CommentCell
                        name={`comment-${row.natureOfCharge}-${item.idx}`}
                        comment={item.comment}
                      />
                    ) : (
                      <TextCell text={''} name={`noComment-${item.idx}`} />
                    )}
                  </DataTableRow>
                ));

                if (row.items?.length > 1) {
                  rows.push(
                    <DataTableRow
                      name={`borrowerCharge-${row.natureOfCharge}-subTotals`}
                      key={`borrowerCharge-${row.natureOfCharge}-subTotals`}
                      subtotal
                    >
                      <TextCell
                        text={`Subtotal ${row.natureOfCharge}: ${natureOfChargeLabel}`}
                        name={`borrowerCharge-${row.natureOfCharge}}-SubtotalLabel`}
                        className="subtotalBg"
                      />
                      <TextCell
                        text={toCurrency(
                          row.subtotals?.previouslyApprovedAmount,
                          undefined,
                          defaultEmptyString
                        )}
                        name={`borrowerCharge-${row.natureOfCharge}}-PreviouslyApprovedSubtotal`}
                      />
                      <TextCell
                        text={toCurrency(row.subtotals?.receivedAmount)}
                        name={`borrowerCharge-${row.natureOfCharge}}-SubmittedSubtotal`}
                      />
                      <TextCell
                        text={defaultEmptyString}
                        name={`borrowerCharge-${row.natureOfCharge}}-AcceptableSubtotal`}
                      />
                      <TextCell
                        text={toCurrency(
                          row.items?.map((item) => item.adjustedAmount ?? 0).reduce(sum)
                        )}
                        name={`borrowerCharge-${row.natureOfCharge}}-AdjustedSubtotal`}
                      />
                      <TextCell
                        text={toCurrency(
                          row.items
                            ?.map((item: BorrowerChargeItem) => item.differenceAmount ?? 0)
                            .reduce(sum)
                        )}
                        name={`borrowerCharge-${row.natureOfCharge}}-DifferenceAmountSubtotal`}
                      />
                      <TextCell
                        text={''}
                        name={`commentCellSubtotalSpacer-${row.natureOfCharge}`}
                      />
                    </DataTableRow>
                  );
                }

                return rows;
              })}
              <DataTableRow name="borrowerChargeSubTotals" subtotal>
                <TextCell
                  name="borrowerChargeSubTotalsLabel"
                  text={borrowerChargeFields?.subtotal.value ?? ''}
                  className="subtotalBg"
                />
                <TextCell
                  text={toCurrency(
                    dataRows.subTotals.previouslyApprovedAmount,
                    undefined,
                    defaultEmptyString
                  )}
                  name={`borrowerChargePreviouslyApprovedSubtotal`}
                />
                <TextCell
                  text={toCurrency(
                    dataRows.subTotals.receivedAmount,
                    undefined,
                    defaultEmptyString
                  )}
                  name={`borrowerChargeSubmittedSubtotal`}
                />
                <TextCell text={defaultEmptyString} name={`borrowerChargeAcceptableSubtotal`} />

                <TextCell
                  text={toCurrency(
                    formData
                      ?.map((charge: FinalBorrowerCharge) => charge.adjustedAmount ?? 0)
                      .reduce(sum, 0)
                  )}
                  name={`borrowerChargeAdjustedAmountSubtotal`}
                />

                <TextCell
                  text={toCurrency(
                    dataRows.chargeRows
                      ?.map(
                        (row: BorrowerChargeRow) =>
                          row.items
                            ?.map((item: BorrowerChargeItem) => item.differenceAmount ?? 0)
                            .reduce(sum) ?? 0
                      )
                      .reduce(sum, 0)
                  )}
                  name={`borrowerChargeDifferenceAmountSubtotal`}
                />
                <TextCell text={''} name={`commentCellSubtotalSpacerColumnTotal`} />
              </DataTableRow>
            </DataTableBody>
          </DataTable>
        </fieldset>
      </ScrollableDataTableContainer>
    </div>
  );
};
