/**
 * Function that determines if the src path array is on the target
 * @param {string[]} src The string array of the menu item path
 * @param {string[]} target The string array of the url
 * @param {boolean} stripFirstElement defines if the first match should be ignored (ie. the match for 'help')
 */
export const tracePath = (src: string[], target: string[], stripFirstElement = false) => {
  let pathMatchNumber = 0;

  const length = Math.min(src.length, target.length);

  for (let i = 0; i < length; i += 1) {
    if (src[i] !== target[i]) {
      break;
    }
    pathMatchNumber += 1;
  }

  return pathMatchNumber - (stripFirstElement ? 1 : 0);
};
