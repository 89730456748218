import React from 'react';
import i18n from 'i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { NotificationBannerProps } from 'Components/Common/Notifications/NotificationBanner/types';
import styles from './styles.module.scss';

export const NotificationBanner: React.FC<NotificationBannerProps> = ({
  title,
  content,
  error,
  success,
  warning,
  onCloseCallback
}: NotificationBannerProps) => (
  <div
    className={`${styles.cardWrapper} ${error ? styles.error : ''} ${
      success ? styles.success : ''
    } ${warning ? styles.warning : ''}`}
  >
    <div
      className={`${styles.icon} ${error ? styles.iconError : ''} ${
        success ? styles.iconSuccess : ''
      } ${warning ? styles.iconWarning : ''}`}
    >
      <span className="material-icons align-self-center">
        {warning ? 'warning_amber' : ''}
        {error ? 'error_outline' : ''}
        {success ? 'check_circle_outline' : ''}
        {!warning && !success && !error ? 'info_outline' : ''}
      </span>
    </div>
    <div className={styles.closeButton}>
      <button
        onClick={onCloseCallback}
        type="button"
        aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
      >
        <span className="material-icons align-self-center">close</span>
      </button>
    </div>
    <div className={styles.content}>
      <Text tag="h2" field={title} />
      <Text tag="li" field={content} />
    </div>
  </div>
);
