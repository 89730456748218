import React, { useState } from 'react';
import i18next from 'i18next';

import { getExternalStatus } from '@hobt/claim-domain';

import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { AdjudicationAssignmentStatusProps } from 'Feature/Claims/components/Details/AdjudicationCardHeader/AdjudicationAssignmentStatus/types';
import { ExternalAssignmentStatus } from 'Feature/Claims/components/Details/AdjudicationCardHeader/AdjudicationAssignmentStatus/ExternalAssignmentStatus';

import { AdjudicationAssignment } from './AdjudicationAssignment';
import { AdjudicationStatus } from './AdjudicationStatus';

import styles from './styles.module.scss';

export const AdjudicationAssignmentStatus: React.FC<AdjudicationAssignmentStatusProps> = ({
  siteTypeIsInternal
}: AdjudicationAssignmentStatusProps) => {
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
  const { claimData } = useHBTFormContext();

  return (
    <div className={styles.assignmentStatusWrapper}>
      <ToastNotification
        isActive={showErrorToast}
        onCloseCallback={() => setShowErrorToast(false)}
        type={ApplicationStates.ERROR}
        title={i18next.t('Globals-Toast-Server-Error-Title')}
        content={i18next.t('Globals-UnknownErrorMessage')}
      />
      {siteTypeIsInternal ? (
        <>
          <AdjudicationAssignment onErrorDefaultCallback={() => setShowErrorToast(true)} />
          <AdjudicationStatus />
        </>
      ) : (
        <ExternalAssignmentStatus
          status={getExternalStatus(claimData?.profile?.claimStatus?.code)}
          assignedTo={claimData?.ownerFullName}
        />
      )}
    </div>
  );
};
