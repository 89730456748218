import i18n from 'i18next';
import * as React from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { SuspenseConditionCode } from '@hobt/claim-domain';
import { LanguageShort } from '@hobt/constants';

import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { Button } from 'Components/Common/Button';
import { FormModal } from 'Components/Common/FormModal';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import FormTextArea from 'Components/Inputs/FormTextArea';
import { CommentCell } from 'Components/Common/DataTable/DataTableRow/CommentCell';
import { convertDateForUi } from 'Components/Common/Api/utils/DateParser';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { handleEmpty } from 'Components/Common/Api/utils/HandleEmpty';

import { FormModalProps } from 'Components/Common/FormModal/types';
import styles from './styles.module.scss';
import { SuspenseReasonsPropsFields, SuspenseConditionsData } from '../types';

const NON_OVERRIDABLE_SUSPENSE_CONDITION_CODES = [
  Number(SuspenseConditionCode.AdmissibleDateNeedsReview),
  Number(SuspenseConditionCode.CompleteClaimReceivedDateIsBlank),
  Number(SuspenseConditionCode.ITMNotApproved),
  Number(SuspenseConditionCode.MultipleReasons),
  Number(SuspenseConditionCode.NoApprovedAmountBorrowerCharges),
  Number(SuspenseConditionCode.NumberOfChargesClaimedExceedsSystemLimit),
  Number(SuspenseConditionCode.OtherBorrowerCharges),
  Number(SuspenseConditionCode.OutstandingPrincipalBalance),
  Number(SuspenseConditionCode.PropertyDamagedInsuranceSettlementInvolved)
];

const SuspenseReasons = (props: SuspenseReasonsPropsFields) => {
  const hookForm = useForm();
  const { watch, register, setValue } = useFormContext();
  const { claimData, isReadOnlyAccessUser } = useHBTFormContext();
  const suspenseReasonsData = claimData?.suspenseConditions;
  const [showOverrideModal, setShowOverrideModal] = React.useState<boolean>(false);
  const {
    heading,
    tableColHeaderSuspenseReason,
    tableColHeaderStartDate,
    tableColHeaderEndDate,
    tableColHeaderComments,
    captionApprovedPropertyRepair,
    onChangeSuspenseReason
  } = props;
  const [suspenseRowData, setSuspenseRowData] = React.useState<SuspenseConditionsData[]>([]);
  const [suspenseRow, setSuspenseRow] = React.useState<SuspenseConditionsData>();
  const [overrideReason, setOverrideReason] = React.useState('');

  register('suspenseConditions');
  setValue('suspenseConditions', suspenseRowData);

  const overrideReasonComment = watch(overrideReason, ' ');

  const overrideClick = (row: SuspenseConditionsData, i: number) => {
    setShowOverrideModal(true);
    setSuspenseRow(row);
    setOverrideReason(`suspenseConditions${i}.overrideReasonComment`);
  };

  const overrideDataSubmit = () => {
    const newRow = {
      code: suspenseRow?.code ?? '',
      overrideReasonComment
    };
    setSuspenseRowData((rowData) => {
      rowData.push(newRow);
      return rowData;
    });
    onChangeSuspenseReason(suspenseRowData);
    setShowOverrideModal(false);
  };

  const pageModal: FormModalProps = {
    type: ApplicationStates.SUCCESS,
    style: 'success',
    title: props?.overrideModalContent?.fields.title?.value ?? '',
    isActive: false,
    onCloseCallback: () => {
      setShowOverrideModal(false);
    }
  };

  const getEndDateValue = (row: SuspenseConditionsData) => {
    if (row?.codeOverrideTimestamp != null) {
      return row.codeOverrideTimestamp;
    }

    if (row?.codeResolvedTimestamp != null) {
      return row?.codeResolvedTimestamp;
    }

    return null;
  };

  return (
    <>
      <h3 className={styles.suspenseTableHeading}>
        <Text field={heading} />
      </h3>
      <section className={styles.suspenseTable}>
        <div className={styles.dataTable}>
          <DataTable
            name="suspenseReasonsDataTable"
            caption={captionApprovedPropertyRepair}
            addTableWrapper={false}
          >
            <DataTableHeader>
              <DataTableHeaderItem
                name="suspenseReason"
                displayText={tableColHeaderSuspenseReason}
              />
              <DataTableHeaderItem name="startDate" displayText={tableColHeaderStartDate} />
              <DataTableHeaderItem name="endDate" displayText={tableColHeaderEndDate} />
              <DataTableHeaderItem name="comments" displayText={tableColHeaderComments} />
              <DataTableHeaderItem name="actions" displayText={{ value: '' }} />
            </DataTableHeader>

            <DataTableBody zebra>
              {suspenseReasonsData?.map(
                (row: SuspenseConditionsData, index: number): JSX.Element => (
                  <DataTableRow name={row?.codeResolvedUserID || ''}>
                    <TextCell
                      name="suspenseReason"
                      text={handleEmpty(
                        props.suspenseReasons.fields.listItems?.find(
                          (item: SitecoreListItem) => item?.fields.itemValue?.value === row.code
                        )?.fields.itemName?.value
                      )}
                    />
                    <TextCell
                      name="startDate"
                      text={handleEmpty(
                        convertDateForUi(
                          row?.codeEffectiveTimestamp ?? '',
                          i18n.language as LanguageShort,
                          true
                        )
                      )}
                    />
                    <TextCell
                      name="endDate"
                      text={handleEmpty(
                        convertDateForUi(
                          getEndDateValue(row) ?? '',
                          i18n.language as LanguageShort,
                          true
                        )
                      )}
                    />
                    <CommentCell
                      disabled={row.overrideReasonComment == null || isReadOnlyAccessUser}
                      name={`comment`}
                      comment={row.overrideReasonComment}
                    />
                    <td className={styles.buttonHolder}>
                      {(row.codeResolvedTimestamp == null || row.codeOverrideTimestamp == null) &&
                        row.overrideReasonComment == null &&
                        !NON_OVERRIDABLE_SUSPENSE_CONDITION_CODES.includes(Number(row.code)) && (
                          <Button
                            text={props.overrideButton}
                            ariaText={props.overrideButton}
                            name={'Override'}
                            onClick={() => {
                              overrideClick(row, index);
                            }}
                            type={'button'}
                            disabled={isReadOnlyAccessUser}
                          />
                        )}
                    </td>
                  </DataTableRow>
                )
              )}
            </DataTableBody>
          </DataTable>
          {(suspenseReasonsData == null || suspenseReasonsData?.length === 0) && (
            <div className={styles.noSearchResultsMessage}>
              <Text field={{ value: i18n.t('Globals-Table-No-Search-To-Display') }} />
            </div>
          )}
        </div>
      </section>
      <FormModal
        isActive={showOverrideModal}
        type={pageModal.type}
        style={pageModal.style}
        title={pageModal.title || ''}
        onCloseCallback={() => {
          setShowOverrideModal(false);
        }}
      >
        <div className={styles.reasonTitle}>
          <Text field={props?.overrideModalContent?.fields.subtitle} />
        </div>
        <FormTextArea
          name={overrideReason}
          label={''}
          textAreaHelperText={props?.overrideModalContent?.fields.characterLimit?.value}
          charLimit={Number(props?.overrideModalContent?.fields.limitNumber?.value)}
          className={styles.full}
          isReadOnly={isReadOnlyAccessUser}
        />
        <span className={styles.overrideButton}>
          <Button
            text={props.overrideButton}
            ariaText={props.overrideButton}
            disabled={overrideReasonComment == null || isReadOnlyAccessUser}
            name={'OverrideSubmit'}
            type={'button'}
            onClick={overrideDataSubmit}
          />
        </span>
        <Button
          secondaryButton
          text={props?.overrideModalContent?.fields.cancelButton}
          ariaText={props?.overrideModalContent?.fields.cancelButton}
          name={'OverrideCancel'}
          type={'button'}
          onClick={() => {
            hookForm.reset();
            setShowOverrideModal(false);
          }}
          disabled={isReadOnlyAccessUser}
        />
      </FormModal>
    </>
  );
};

export default SuspenseReasons;
