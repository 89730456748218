import React, { useEffect } from 'react';
import i18n from 'i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { IssueToManagementStatus } from '@hobt/claim-domain';
import { LanguageShort } from '@hobt/constants';

import { DataTable } from 'Components/Common/DataTable';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { convertDateForUi } from 'Components/Common/Api/utils/DateParser';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import ClaimsIndicatorDetailsAdjudicationProps from '../IndicatorDetailsAdjudication/types';

import { itmFlagsGrid } from './types';
import { useItmFlagsTable } from './useItmFlagsTable';
import styles from './styles.module.scss';

const ItmFlagsTable = ({ fields }: ClaimsIndicatorDetailsAdjudicationProps) => {
  const { itmFlags, showErrorToast, onErrorToastDismiss, getItmFlagsTableData } =
    useItmFlagsTable();

  useEffect(() => {
    getItmFlagsTableData();
  }, []);

  return (
    <>
      <ToastNotification
        type={ApplicationStates.ERROR}
        isActive={showErrorToast}
        title={i18n.t('Globals-Toast-Error-Title')}
        content={{ value: i18n.t('DefaultActions-SystemErrorToast-Action-Message') }}
        onCloseCallback={onErrorToastDismiss}
      />
      <div className={styles.itmFlagsdataTable}>
        <hr />
        <Text tag="h3" field={fields.itmFlagCard?.fields.title} className={styles.titleText} />
        <DataTable
          name="itmFlagsTable"
          caption={fields.itmFlagCard?.fields.tableColLoansTableCaption}
        >
          <DataTableHeader>
            <DataTableHeaderItem
              name="itmType"
              displayText={fields.itmFlagCard?.fields.tableColItmType}
              customStyles={styles.itmType}
            />
            <DataTableHeaderItem
              name="reference"
              displayText={fields.itmFlagCard?.fields.tableColItmReference}
              customStyles={styles.reference}
            />
            <DataTableHeaderItem
              name="status"
              displayText={fields.itmFlagCard?.fields.tableColItmStatus}
              customStyles={styles.status}
            />
            <DataTableHeaderItem
              name="activeDate"
              displayText={fields.itmFlagCard?.fields.tableColActiveDate}
              customStyles={styles.activeDate}
            />
            <DataTableHeaderItem
              name="cancelDate"
              displayText={fields.itmFlagCard?.fields.tableColCancelledDate}
              customStyles={styles.cancelDate}
            />
          </DataTableHeader>

          <DataTableBody zebra>
            {Array.isArray(itmFlags) && itmFlags.length > 0 ? (
              itmFlags?.map(
                (row: itmFlagsGrid): JSX.Element => (
                  <DataTableRow
                    name={`${row.issueToManagementRecordID}-row`}
                    key={row.issueToManagementRecordID}
                  >
                    <TextCell
                      text={row.typeCodes
                        ?.map(
                          (itmFlag: number) =>
                            fields?.itmFlagCard?.fields?.itmflagList?.[0]?.fields?.listItems?.find?.(
                              (item: SitecoreListItem) =>
                                item?.fields?.itemValue?.value?.toString() === itmFlag?.toString()
                            )?.fields?.itemName?.value
                        )
                        .join(', ')}
                      name={`${row.issueToManagementRecordID}-itmType`}
                    />
                    <TextCell
                      text={row.referenceNumber?.toString(10) ?? '--'}
                      name={`${row.issueToManagementRecordID}-itmReference`}
                    />
                    <TextCell
                      text={
                        row?.statusCode === IssueToManagementStatus.Approved
                          ? fields?.itmFlagCard?.fields?.tableColStatusApproved?.value || '--'
                          : fields?.itmFlagCard?.fields?.tableColCancelledDate?.value || '--'
                      }
                      name={`${row.issueToManagementRecordID}-status`}
                    />
                    <TextCell
                      text={
                        row.approvedTimestamp
                          ? convertDateForUi(
                              row.approvedTimestamp ?? '',
                              i18n.language as LanguageShort,
                              true
                            )
                          : ''
                      }
                      name={`${row.issueToManagementRecordID}-activeDate`}
                    />
                    <TextCell
                      text={
                        row.notApprovedTimestamp
                          ? convertDateForUi(
                              row.notApprovedTimestamp ?? '',
                              i18n.language as LanguageShort,
                              true
                            )
                          : '--'
                      }
                      name={`${row.issueToManagementRecordID}-cancelledDate`}
                    />
                  </DataTableRow>
                )
              )
            ) : (
              <DataTableRow name="empty row">
                <TextCell text="" name="" />
                <TextCell text="" name="" />
                <TextCell text="" name="" />
                <TextCell text="" name="" />
                <TextCell text="" name="" />
              </DataTableRow>
            )}
          </DataTableBody>
        </DataTable>
      </div>
      {!itmFlags ||
        (itmFlags.length === 0 && (
          <div className={styles.noSearchResultsMessage}>
            <Text field={fields.itmFlagCard?.fields.emptyResult} />
          </div>
        ))}
    </>
  );
};

export default ItmFlagsTable;
