import React, { FC } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import ArrearsResourcesProps from 'Feature/Arrears/models/ArrearsResourcesProps';
import MediaDocument from 'Feature/CommonComponents/MediaDocument';
import MediaDocumentProps from 'Feature/CommonComponents/MediaDocument/MediaDocumentProps';
import { Card } from 'Components/Card';
import { CardHeader } from 'Components/Card/CardHeader';
import { CardBody } from 'Components/Card/CardBody';

import CollapsingSection from 'Components/Common/CollapsingSection';

import styles from './styles.module.scss';

const ArrearsResources: React.FC<ArrearsResourcesProps> = (props: ArrearsResourcesProps) => {
  const { heading, subHeading, mediaDocuments } = props.fields;

  const CollapsingWrapper: FC<{
    children?: React.ReactNode;
  }> = ({ children }) => {
    return (
      <div className={styles.hideForDesktop}>
        <CollapsingSection
          heading={heading.value ?? ''}
          headingLevel={2}
          sectionClass={styles.card}
          contentClass={styles.section}
        >
          {children}
        </CollapsingSection>
      </div>
    );
  };

  const NoCollapseWrapper: FC<{
    children?: React.ReactNode;
  }> = ({ children }) => {
    return (
      <div className={styles.showForDesktopOnly}>
        <CardHeader>
          <Text tag="h3" field={heading} className="resources__heading" />
        </CardHeader>
        <CardBody> {children} </CardBody>
      </div>
    );
  };

  const ResourceContent = (
    <div className={`${styles.layoutContainer}`}>
      <Text tag="small" field={subHeading} />
      <ul className={styles.templateList}>
        {mediaDocuments &&
          mediaDocuments?.map((document: MediaDocumentProps, index: number) => {
            return (
              <li key={index} className={styles.templateListItem}>
                <MediaDocument key={index} fields={document.fields} />
              </li>
            );
          })}
      </ul>
    </div>
  );

  return (
    <Card>
      <CollapsingWrapper> {ResourceContent} </CollapsingWrapper>
      <NoCollapseWrapper> {ResourceContent} </NoCollapseWrapper>
    </Card>
  );
};

export default ArrearsResources;
