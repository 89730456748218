import React, { memo } from 'react';
import BreadcrumbDropdown from './BreadcrumbDropdown';
import BreadcrumbItem from './BreadcrumbItem';
import styles from './styles.module.scss';
import { BreadcrumbProps } from './type';

/**
 * @param items - An array of BreadcrumbItem objects representing the page links in the breadcrumb.
 * @param condensed - A boolean value to determine if the breadcrumb should be condensed, i.e. with ellipsis and a dropdown menu.
 * @returns A Breadcrumb component
 */

/**
 * Breadcrumb component
 *
 * This is the Breadcrumb component written using storybook.
 */

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items = [], condensed = false }) => {
  const breadcrumbItems = Array.isArray(items) ? items : [];
  return (
    <nav
      className={`${styles.breadcrumb} ${condensed ? styles.condensed : ''}`}
      aria-label="breadcrumb"
    >
      {breadcrumbItems.slice(0, 2).map((item, index) => (
        <BreadcrumbItem key={index} item={item} isLastItem={index === breadcrumbItems.length - 1} />
      ))}
      {condensed && breadcrumbItems.length > 3 && (
        <>
          <BreadcrumbDropdown items={breadcrumbItems.slice(2, breadcrumbItems.length - 1)} />
          <BreadcrumbItem item={breadcrumbItems[breadcrumbItems.length - 2]} isLastItem={false} />
        </>
      )}
      {breadcrumbItems.length > 2 && (
        <BreadcrumbItem item={breadcrumbItems[breadcrumbItems.length - 1]} isLastItem={true} />
      )}
    </nav>
  );
};

export default memo(Breadcrumb);
