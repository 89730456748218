/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Element } from 'react-scroll';
import { useFeature } from 'flagged';
import i18n from 'i18next';

import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import BorrowerCharges from 'Feature/Claims/components/Cards/BorrowerCharges';
import BorrowerDetails from 'Feature/Claims/components/Cards/BorrowerDetails';
import ClaimsDetails from 'Feature/Claims/components/Cards/ClaimsDetails';
import ClaimsLenderDetails from 'Feature/Claims/components/Cards/ClaimsLenderDetails';
import DefaultManagement from 'Feature/Claims/components/Cards/DefaultManagement';
import DisplayNotificationCard from 'Feature/Claims/components/Cards/DisplayNotificationCard';
import IncomeDetails from 'Feature/Claims/components/Cards/IncomeDetails';
import ListingPeriod from 'Feature/Claims/components/Cards/ListingPeriod';
import PartialPaymentDetails from 'Feature/Claims/components/Cards/PartialPaymentDetails';
import ClaimsPropertyDetails from 'Feature/Claims/components/Cards/PropertyDetails';
import ManualReview from 'Feature/Claims/components/Cards/ManualReview';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { ClaimsSubmissionCardProps } from 'Feature/Claims/models/ClaimsSubmissionFormCardsProps';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { ProgressBar } from 'Components/ProgressBar';
import { ProgressBarProps } from 'Components/ProgressBar/types';
import { StickyContainer } from 'Components/StickyContainer';
import ClaimsSubmissionDetailsProps from 'Feature/Claims/models/ClaimsSubmissionDetailsProps';
import GlossaryComponent from 'Feature/PageComponents/components/GlossaryComponent';
import { GlossaryComponentPropsFields } from 'Feature/PageComponents/models/GlossaryComponentProps';

import ClaimsIndicatorDetailsAdjudication from '../IndicatorDetailsAdjudication';
import styles from './styles.module.scss';
import useClaimsSubmissionDetails from './useClaimsSubmissionDetails';

const claimSubmissionForm: Record<string, React.FC<any>> = {
  claimsIndicatorDetails: ClaimsIndicatorDetailsAdjudication,
  claimsLenderDetails: ClaimsLenderDetails,
  claimsBorrowerDetails: BorrowerDetails,
  claimsPropertyDetails: ClaimsPropertyDetails,
  claimDefaultManagement: DefaultManagement,
  claimsDetails: ClaimsDetails,
  claimsListingPeriod: ListingPeriod,
  claimsBorrowersCharges: BorrowerCharges,
  claimsIncomeDetails: IncomeDetails,
  claimsPartialPaymentDetails: PartialPaymentDetails,
  claimsManualReview: ManualReview
};

const cardNameToValidationFieldMap: Record<string, string> = {
  claimsIndicatorDetails: 'indicator',
  claimsLenderDetails: 'lender',
  claimsBorrowerDetails: 'borrowers',
  claimsPropertyDetails: 'property',
  claimDefaultManagement: 'defaultManagement',
  claimsDetails: 'detail',
  claimsListingPeriod: 'listings',
  claimsBorrowersCharges: 'borrowerCharges',
  claimsIncomeDetails: 'rentalIncomeReceivedFlag',
  claimsPartialPaymentDetails: 'partialPaymentReceivedFlag',
  claimsManualReview: 'manualReview'
};

const ClaimsSubmissionDetails: React.FC<ClaimsSubmissionDetailsProps> = ({ fields }) => {
  const { claimData, isInEditMode, isReadOnlyView, rendering } = useHBTFormContext();
  const [startOnGlossary, setStartOnGlossary] = useState<string>('');
  const [showGlossary, setShowGlossary] = useState<boolean>(false);

  const isExternalUser = useFeature(FeatureFlags.EXTERNAL);

  const {
    hookForm,
    showToast,
    toastState,
    toastMessage,
    isSaveButtonDisabled,
    handleSubmit,
    onSubmitErrors,
    onEditClick,
    onCancelClick,
    onCloseToast
  } = useClaimsSubmissionDetails();

  const progressBarCards = fields.leftContent[0].fields.cards?.filter?.((card: any) =>
      Object.keys(claimSubmissionForm).includes(card.fields.name.value)
  );

  const progressBar: ProgressBarProps = fields.rightContent?.[0]?.fields;

  useEffect(() => {
    hookForm.register('approvedLenderFinancialInstitutionCode');
    hookForm.register('approvedLenderReferenceNumber');
    hookForm.register('approvedLenderTransitNumber');
    hookForm.register('claimBusinessID');
    hookForm.register('claimTypeIndicator');
    hookForm.register('cmhcLoanAccountNumber');
    hookForm.setValue(
      'approvedLenderFinancialInstitutionCode',
      claimData.approvedLenderFinancialInstitutionCode
    );
    hookForm.setValue('approvedLenderReferenceNumber', claimData.approvedLenderReferenceNumber);
    hookForm.setValue('approvedLenderTransitNumber', claimData.approvedLenderTransitNumber);
    hookForm.setValue('claimBusinessID', claimData.claimBusinessID);
    hookForm.setValue('claimTypeIndicator', claimData.claimTypeIndicator);
    hookForm.setValue('cmhcLoanAccountNumber', claimData.cmhcLoanAccountNumber);
    const modifyPartialPaymentsData = hookForm?.getValues()?.partialPayments?.map?.((partialPayment: any) => {
      const { typeCode, admissibleAmount, admissibleDate, previouslyApprovedAmount, partialPaymentAppliedToInterestAmount, partialPaymentAppliedToPrincipalAmount, partialPaymentAppliedToTaxAmount, ...rest } = partialPayment;
      return rest;
    });
    if (hookForm?.getValues()?.partialPaymentReceivedFlag === 'true') {
      hookForm.setValue('partialPayments', modifyPartialPaymentsData);
    } else {
      hookForm.unregister('partialPayments');
    }
    const modifyBorrowerCharges = hookForm?.getValues()?.borrowerCharges?.map?.((borrowerCharge: any) => {
      const { accruedInterestCalculations, creditOfReceiptInterestCalculation, originalClaimSequenceNumber, acceptableAmount, ...rest } = borrowerCharge;
      return rest;
    });
    const checkReceivedAmountInBorrowerCharges = modifyBorrowerCharges?.filter?.((charge: any) => charge?.receivedAmount);

    hookForm.setValue(
      'borrowerCharges',
      checkReceivedAmountInBorrowerCharges?.length > 0
        ? checkReceivedAmountInBorrowerCharges
        : []
    );

    const modifynetOperatingIncomesRentalIncomes = hookForm
      ?.getValues()
      ?.netOperatingIncomesRentalIncomes?.map?.((rentalIncomes: any) => {
        const { admissibleAmount, admissibleDate, previouslyApprovedAmount, partialPaymentAppliedToInterestAmount, partialPaymentAppliedToPrincipalAmount, partialPaymentAppliedToTaxAmount, typeCode, ...rest} = rentalIncomes;
        return rest;
      });
    if (hookForm?.getValues()?.rentalIncomeReceivedFlag === 'true') {
      hookForm.setValue(
        'netOperatingIncomesRentalIncomes',
        modifynetOperatingIncomesRentalIncomes
      );
    } else {
      hookForm.unregister('netOperatingIncomesRentalIncomes');
    }
    hookForm.unregister('indicator.eddFemsiCodes');
    hookForm.unregister('detail.receivedDefaultRatePercent');
    hookForm.register('authorizedOfficers');
    hookForm.setValue(
      'authorizedOfficers',
      claimData?.lender?.authorizedOfficers
    );
  }, [isInEditMode]);

  console.log('Claim data ClaimSubmitionDetails', claimData);
  useEffect(() => {
    if (hookForm?.getValues()?.authorizedOfficers?.length === 1) {
      hookForm?.setValue(
        'lender.authorizedOfficers',
        hookForm?.getValues()?.lender?.authorizedOfficers?.slice(0, 1)
      );
    }
  }, [hookForm?.getValues()?.authorizedOfficers?.length]);

  const renderClaimsSubmissionCards = (
    card: ClaimsSubmissionCardProps,
    index: number
  ): JSX.Element => {
    return (
      <React.Fragment key={`${index}_${card.fields.name.value}`}>
        {card?.fields?.name?.value != null &&
          typeof claimSubmissionForm[card.fields.name.value] !== 'undefined' && (
            <Element name={card.fields.name.value}>
              {React.createElement(claimSubmissionForm[card.fields.name.value], {
                fields: {
                  ...card.fields,
                  openGlossary: (glossaryName: string) => {
                    setShowGlossary(true);
                    setStartOnGlossary(glossaryName);
                  }
                },
              })}
            </Element>
          )}
      </React.Fragment>
    );
  };

  const glossaryFields: GlossaryComponentPropsFields = {
    ...rendering?.placeholders?.glossary[0]?.fields,
    isActive: showGlossary,
    startOnGlossary,
    closeCallback: () => {
      setShowGlossary(false);
    }
  };

  console.log("Glossary",glossaryFields);

  return (
    <div className={styles.claimsSubmissionWrapper}>
      <ToastNotification
        onCloseCallback={onCloseToast}
        isActive={showToast}
        type={toastState}
        content={toastMessage}
        title={
          toastState === ApplicationStates.ERROR
            ? i18n.t('DefaultSubmission-ErrorToastTitle')
            : i18n.t('DefaultActions-SystemSuccessToast-Action-Title')
        }
      />
      <DisplayNotificationCard
        errors={hookForm.formState.errors}
        cards={fields.leftContent[0].fields.cards}
        errorIcon={fields.formErrorIcon}
        isInEditMode
      />
      <FormProvider {...hookForm}>
        <form
          id="claimsSubmissionFormDetails"
          className="form claim-submission-form"
          onSubmit={
            isInEditMode === false
              ? (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                onEditClick();
              }
              : hookForm.handleSubmit(handleSubmit, onSubmitErrors)
          }
          noValidate
        >
          <div className="row">
            <section className="col-xl-8 col-md-12">
              <fieldset role="group" aria-label="Claim_Submission_Details">
                {fields.leftContent[0].fields.cards.map(renderClaimsSubmissionCards)}
              </fieldset>
            </section>
            <aside className="col-xl-4 d-none d-xl-block">
              <StickyContainer
                shouldFillViewportHeight
                containerId="claimsProgressBar"
                topOffset={25}
                shouldFillParentWidth
              >
                <ProgressBar
                  bodyClassName="claims-progress-bar-body"
                  cards={progressBarCards}
                  title={{ value: progressBar?.heading?.value }}
                  isSecondaryButton={true}
                  scrollDuration={500}
                  cardNameToValidationFieldMap={cardNameToValidationFieldMap}
                  primaryButtonText={{
                    value: !isInEditMode
                      ? progressBar?.editButton?.value
                      : progressBar?.saveButton?.value
                  }}
                  secondaryButtonText={
                    !isInEditMode ? undefined : { value: progressBar?.cancelButton?.value }
                  }
                  secondaryButtonOnClickCallback={onCancelClick}
                  isDisabled={isReadOnlyView || isSaveButtonDisabled}
                  footerButtonsRequired={!isExternalUser}
                />
              </StickyContainer>
            </aside>
            <div className="col-md-12 d-none d-md-block d-lg-none">
              <ProgressBar
                bodyClassName="claims-progress-bar-body"
                cards={progressBarCards}
                title={{ value: progressBar?.heading?.value }}
                isSecondaryButton={true}
                scrollDuration={500}
                cardNameToValidationFieldMap={cardNameToValidationFieldMap}
                primaryButtonText={{
                  value: !isInEditMode
                    ? progressBar?.editButton?.value
                    : progressBar?.saveButton?.value
                }}
                secondaryButtonText={
                  !isInEditMode ? undefined : { value: progressBar?.cancelButton?.value }
                }
                secondaryButtonOnClickCallback={onCancelClick}
                isDisabled={isReadOnlyView}
              />
            </div>
          </div>
        </form>
        
      </FormProvider>
      {rendering?.placeholders?.glossary &&
          React.createElement(GlossaryComponent, {
            fields: glossaryFields
          })}
    </div>
  );
};

export default ClaimsSubmissionDetails;
