import * as React from 'react';
import { IconCellProps } from 'Components/Common/DataTable/DataTableRow/IconCell/types';
import styles from './styles.module.scss';

export const IconCell: React.FC<IconCellProps> = ({ name, icons, outlined }: IconCellProps) => (
  <td>
    <span className={styles.iconList}>
      {icons &&
        icons.map((i: string) => (
          <span
            key={`${name}${i}Icon`}
            className={`material-icons${outlined ? '-outlined' : ''} align-self-center ${
              styles.icon
            }`}
          >
            {i}
          </span>
        ))}
    </span>
  </td>
);
