import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { ClaimBorrowerType } from '@hobt/claim-domain';

import FormCard from 'Components/Common/FormCard';
import { TooltipIcon } from 'Components/Inputs/TooltipIcon';
import DynamicHeading from 'Components/Common/DynamicHeading';
import ClaimsBorrowerDetailsProps, {
  ClaimsBorrowerDetailsItem,
  TBorrowersFieldArray
} from 'Feature/Claims/models/ClaimsBorrowerDetailsProps';
import { ButtonType } from 'Feature/CommonComponents/UserControls';
import { GlossaryNames } from 'Feature/PageComponents/components/GlossaryComponent/types';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import BorrowerDetails from './BorrowerDetails';

import styles from './styles.module.scss';

const MAX_ALLOWED_BORROWERS: number = 10;

const ClaimsBorrowerDetails = (props: ClaimsBorrowerDetailsProps) => {
  const { trigger, setValue, register } = useFormContext();
  const { claimData, isClaimsDetails, isFieldDisabled, isInEditMode } = useHBTFormContext();
  const {
    fields: borrowers,
    append,
    remove
  }: TBorrowersFieldArray = useFieldArray({
    name: 'borrowers'
  });

  useEffect(() => {
    /* eslint-disable no-unused-expressions */
    borrowers?.forEach((_item, index) => {
      setValue(
        `borrowers[${index}].financialAssessmentCompletedFlag`,
        `${borrowers[index].financialAssessmentCompletedFlag}`
      );
    });
  }, [isFieldDisabled]);

  useEffect(() => {
    if (isClaimsDetails === true && isInEditMode === true) {
      setValue('borrowers', claimData?.borrowers);
    }
  }, [isInEditMode]);

  // TODO: Need to revisit the setValue section in future sprints
  useEffect(() => {
    /* eslint-disable no-unused-expressions */
    borrowers?.forEach((_item, index) => {
      register(`borrowers[${index}].claimBorrowerTypeID`);

      if (isClaimsDetails === true && borrowers?.[index]?.indexNumber != null) {
        register(`borrowers[${index}].indexNumber`);
        setValue(`borrowers[${index}].indexNumber`, borrowers[index].indexNumber);
      }

      let incrementedBorrowerTypeID = index + 1;
      for (let i = 2; i <= 10; i += 1) {
        if (borrowers?.find((borrower) => borrower.claimBorrowerTypeID !== i)) {
          incrementedBorrowerTypeID = i;
          break;
        }
      }
      const claimBorrowerTypeID =
        index < 1 ? ClaimBorrowerType.Borrower : incrementedBorrowerTypeID;

      setValue(
        `borrowers[${index}].claimBorrowerTypeID`,
        borrowers[index].claimBorrowerTypeID ?? claimBorrowerTypeID
      );
      setValue(
        `borrowers[${index}].financialAssessmentCompletedFlag`,
        `${borrowers[index].financialAssessmentCompletedFlag}`
      );

      register(`borrowers[${index}].firstName`);
      register(`borrowers[${index}].lastName`);
      setValue(`borrowers[${index}].firstName`, borrowers[index].firstName);
      setValue(`borrowers[${index}].lastName`, borrowers[index].lastName);

      setValue(`borrowers[${index}].birthDate`, borrowers[index].birthDate);
      setValue(
        `borrowers[${index}].claimBorrowerCategoryID`,
        borrowers[index].claimBorrowerCategoryID
      );
      setValue(`borrowers[${index}].defaultReasonCode`, borrowers[index].defaultReasonCode);
      setValue(
        `borrowers[${index}].otherDefaultReasonDescription`,
        borrowers[index].otherDefaultReasonDescription
      );
      setValue(
        `borrowers[${index}].judgmentActionTypeCode`,
        borrowers[index].judgmentActionTypeCode
      );
      setValue(
        `borrowers[${index}].otherReasonNotPursuedDescription`,
        borrowers[index].otherReasonNotPursuedDescription
      );
      setValue(
        `borrowers[${index}].reasonNotPursuedTypeCode`,
        borrowers[index].reasonNotPursuedTypeCode
      );
    });
  }, [borrowers]);

  return (
    <FormCard
      title={props.fields.cardTitle}
      headingLevel={2}
      sectionId="claims-borrowers-body"
      fieldToValidate="borrowers"
      toolTipButton={{
        id: 'borrowerDetailsGlossaryTooltip',
        name: 'borrowerDetailsGlossaryTooltip',
        ariaText: props.fields.cardGlossaryAriaText?.value ?? '',
        onClick: () => {
          props?.fields?.openGlossary?.(GlossaryNames.BorrowerDetails);
        },
        buttonType: ButtonType.TEXT
      }}
    >
      {borrowers?.map((item, index) => {
        return (
          <React.Fragment key={item.id}>
            {index > 0 && (
              <>
                <hr className={styles.separator} />
                <DynamicHeading headingLevel={3} className={styles.coborrowerTitle}>
                  <Text field={props.fields.coborrowerGuarantorLabel} />
                </DynamicHeading>
              </>
            )}

            <BorrowerDetails {...props} index={index} item={item as ClaimsBorrowerDetailsItem} />

            {index > 0 && (
              <>
                <div className={styles.coborrowerDelete}>
                  <button
                    className="btn btn--icon btn__delete float-right"
                    type="button"
                    onClick={() => remove(index)}
                    name="delete"
                    disabled={isFieldDisabled}
                  >
                    <TooltipIcon
                      icon={props.fields.deleteIcon}
                      text={props.fields.deleteLabel.value ?? ''}
                      className={'icon-24'}
                    />
                  </button>
                </div>
              </>
            )}
          </React.Fragment>
        );
      })}

      <hr className={styles.separator} />

      <button
        className="btn btn-add-color"
        type="button"
        disabled={borrowers?.length > MAX_ALLOWED_BORROWERS || isFieldDisabled}
        onClick={() =>
          append(
            {
              financialAssessmentCompletedFlag: 'false'
            },
            false
          )
        }
      >
        <i aria-hidden={true} className="material-icons icon--size-32 icon--v-align-middle">
          add_circle_outline
        </i>
        <Text field={props.fields.addCoborrowerLabel} />
      </button>
    </FormCard>
  );
};

export default ClaimsBorrowerDetails;
