import React, { useState, useEffect } from 'react';
import { AccountStatus, UserRole, ModuleMapping } from '@hobt/constants';
import { convertTimestampToDate } from '@hobt/utils';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { defaultEmptyString } from 'Components/Common/Api/utils/HandleEmpty';
import { DataTable } from 'Components/Common/DataTable';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { StatusCell } from 'Components/Common/DataTable/DataTableRow/StatusCell';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableFooter } from 'Components/Common/DataTableFooter';
import { BuildDropDownDictionary } from 'Components/Common/Helpers';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { Button } from 'Components/Core/Button';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { useDataGridEngine } from 'Components/Hooks/DataGridEngine';
import { Tabs, TabItemProps } from 'Components/Navigation/Tabs';
import { SearchBar } from 'Components/PageComponents/SearchBar';
import { SearchFieldOptions } from 'Components/PageComponents/SearchBar/types';
import { pathNames } from 'Constants/pathNames';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import i18n from 'i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { config } from '../../../../../config';
import { TabMapping, TabType, ExternalStatus } from '../../models/types';
import { HbtExternalUsersTabInventory } from './HbtExternalUsersTab/HbtExternalUsersTabInventory';
import styles from './styles.module.scss';
import HbtUserAccessManagementPageProps, { UserManagementInternalInventoryRecords } from './types';

const HbtUserAccessManagementPage = ({ fields }: HbtUserAccessManagementPageProps) => {
  const history = useHistory();
  const [rowInfo, setRowInfo] = useState<any>();
  const location = useLocation();
  const [locationData, setLocationData] = useState<any>(undefined);
  let dataLocation = location.state;
  const [isInventoryToast, setInventoryToast] = useState<boolean>(false);

  // TODO: Handle API Error
  const onApiErrorCallback = () => setInventoryToast(true);
  const onToastCloseHandler = () => setInventoryToast(false);
  const [isAdminActive, setIsAdminActive] = useState(false);

  const [items] = useState<TabItemProps[]>([
    {
      displayText: fields.internalText,
      name: TabMapping.get(TabType.Internal)!
    },
    {
      displayText: fields.externalText,
      name: TabMapping.get(TabType.External)!
    }
  ]);
  const [isTab, setIsTab] = useState<TabItemProps>(items[0]);

  const onTabClick = (tab: TabItemProps) => {
    if (tab.name == 'internal') {
      setLocationData(undefined);
    }
    setIsTab(tab);
    setHasSearched(false);
    setIsAdminActive(false);
    onSearchReset();
  };

  const [hasSearched, setHasSearched] = React.useState<boolean>(false);
  const {
    rowData,
    currentPage,
    itemsPerPage,
    onItemsPerPageChange,
    totalItems,
    totalPages,
    itemRangeFrom,
    itemRangeTo,
    isLoading: isInventoryLoading,
    onPagerClick,
    onSort,
    onSearch,
    onSearchReset,
    getSortDirectionForField,
    getTableAccessibilityText
  } = useDataGridEngine<UserManagementInternalInventoryRecords>({
    apiBaseUrl: config.userApi.urls.inventory,
    onApiErrorCallback,
    defaultItemsPerPage: 10,
    isAdmin: isAdminActive,
    isUserModule: true,
    isPIToolRequest: true
  });

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.admin,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  const accountStatusDictionary = BuildDropDownDictionary(fields?.accountStatus?.fields?.listItems);

  const userRolesDictionary = BuildDropDownDictionary(fields?.userRoles?.fields?.listItems);

  const searchByFields: SearchFieldOptions[] = [
    {
      displayText: fields?.email?.value ?? '',
      value: 'EmailID',
      type: 'text'
    },
    {
      displayText: fields?.name?.value ?? '',
      value: 'FullName',
      type: 'text'
    }
  ];

  // sets data as soon as we get it if it exists
  useEffect(() => {
    if (rowData && rowData.length > 0) setRowInfo(rowData);
  }, [rowData, isAdminActive]);

  const onRowSelect = (row: any) => {
    history.push(`/${i18n.language}${pathNames.getSelectedInternalPIUser}?id=${row.userID}`);
  };

  const createNewUser = () => {
    if (isTab.name === 'external') {
      return history.push(`/${i18n.language}${pathNames.createExternalPIUser}`);
    }
    return history.push(`/${i18n.language}${pathNames.createInternalPIUser}`);
  };

  useEffect(() => {
    if (dataLocation == 'external') {
      setLocationData(dataLocation);
      return onTabClick(items[1]);
    }
  }, [dataLocation]);

  return (
    <>
      <ToastNotification
        isActive={isInventoryToast}
        type={ApplicationStates.ERROR}
        title={i18n.t('Globals-Toast-Server-Error-Title')}
        content={{
          value: i18n.t('DefaultsInventoryTable-SystemErrorToast-Message')
        }}
        onCloseCallback={onToastCloseHandler}
      />
      <div className={styles.piUserAccessManagementMainBody} id="manageUsers">
        <div className={styles.piUserAccessManagementMainContainer}>
          <div className={styles.piUserAccessManagementHeaderContainer}>
            <div className={styles.piUserAccessManagementHeaderTitleInfoWrapper}>
              <h2
                className={styles.piUserAccessManagementTitle}
                id="piUserAccessManagementTitle"
                tabIndex={0}
              >
                <Text field={fields.pageTitle} />
              </h2>
              <span className={styles.piUserAccessManagementTitleInfo}>
                <Text field={fields.description} />
              </span>
            </div>
            <div className={styles.piAddUserButtonContainer}>
              <Button
                name="addUserBtn"
                text={fields.addUserBtn}
                ariaText={fields.addUserBtn}
                onClick={createNewUser}
                disabled={isReadOnlyUser}
              />
            </div>
          </div>
          <div className={styles.piUserAccessManagementTabContainer}>
            <Tabs
              currentTab={locationData == 'external' ? items[1] : isTab}
              items={items}
              initialActiveTabName={isTab.name}
              tabOnClickCallback={onTabClick}
              ariaControlsName="UserRoles"
              label={isTab.name === 'internal' ? fields.internalText : fields.externalText}
              className={styles.piUserAccessManagementTabs}
            />
          </div>

          {isTab.name === 'internal' && (
            <div className={styles.internalPIUserdataTable}>
              <div className={styles.searchBarWrapper}>
                <div className={styles.searchBarWidth}>
                  <SearchBar
                    name="userInventorySearchBar"
                    fieldLabelAriaText={i18n.t('SearchBar-SearchField')}
                    clearButtonAriaText={i18n.t('SearchBar-ClearButton')}
                    searchButtonAriaText={i18n.t('SearchBar-SearchButton')}
                    placeholderText={i18n.t('SearchBar-PlaceholderText')}
                    searchFieldOptions={searchByFields}
                    onClear={() => {
                      setHasSearched(false);
                      onSearchReset();
                    }}
                    onSearch={(field: string, value: string) => {
                      setHasSearched(true);
                      onSearch(field, value);
                    }}
                    count={totalItems}
                    displaySearchResultMessage={false}
                  />
                </div>
              </div>
              {hasSearched && totalItems != null && totalItems > 0 && (
                <div className={styles.noSearchResultsMessage}>
                  <Text
                    field={{
                      value: `${totalItems}  ${i18n.t('Globals-Table-Search-To-Display')}`
                    }}
                  />
                </div>
              )}
              {totalItems === 0 && (
                <div className={styles.noSearchResultsMessage}>
                  <Text
                    field={{
                      value: i18n.t('Globals-Table-No-Search-To-Display')
                    }}
                  />
                </div>
              )}
              {totalItems > 0 && (
                <DataTable
                  name="managePIUsers"
                  isLoading={isInventoryLoading}
                  caption={{ value: getTableAccessibilityText() ?? '' }}
                  scrollOnHorizontalOverflow
                >
                  <DataTableHeader>
                    <DataTableHeaderItem
                      sortable
                      onSortCallback={onSort}
                      sortDirection={getSortDirectionForField('fullName')}
                      name="fullName"
                      displayText={fields.userLabel}
                    />
                    <DataTableHeaderItem
                      sortable
                      onSortCallback={onSort}
                      sortDirection={getSortDirectionForField('emailID')}
                      name="emailID"
                      displayText={fields.email}
                    />
                    <DataTableHeaderItem
                      sortable
                      onSortCallback={onSort}
                      sortDirection={getSortDirectionForField('statusCode')}
                      name="statusCode"
                      displayText={fields.statusLabel}
                    />
                    <DataTableHeaderItem name="lastLogIn" displayText={fields.lastLogInLabel} />
                    <DataTableHeaderItem name="piRequestRole" displayText={fields.piRequestLabel} />
                    <DataTableHeaderItem
                      name="piPurpostTestRole"
                      displayText={fields.piPurpostTestLabel}
                    />
                    <DataTableHeaderItem
                      name="piFileTransferRole"
                      displayText={fields.piFileTransferLabel}
                    />
                  </DataTableHeader>

                  <DataTableBody zebra>
                    {rowInfo &&
                      rowInfo.map((row: UserManagementInternalInventoryRecords, index: Number) => (
                        <DataTableRow
                          name={row.userID}
                          key={`record${index}`}
                          onClick={() => onRowSelect(row)}
                        >
                          <TextCell name="fullName" text={row.fullName ?? defaultEmptyString} />
                          <TextCell name="emailID" text={row.emailID ?? defaultEmptyString} />
                          <StatusCell
                            name="adAccountStatusCode"
                            statusText={
                              row.statusCode != null
                                ? accountStatusDictionary.get(row.statusCode?.toString()) ??
                                  defaultEmptyString
                                : defaultEmptyString
                            }
                            className={
                              row.statusCode != null
                                ? ExternalStatus[row.statusCode as AccountStatus].color
                                : 'grey20'
                            }
                          />
                          <TextCell
                            name="lastLogIn"
                            text={
                              row?.loginTimestamp != null
                                ? convertTimestampToDate(row.loginTimestamp)!
                                : defaultEmptyString
                            }
                          />
                          <TextCell
                            name="piRequestRoleCode"
                            text={
                              row.piRequestRoleCode != null
                                ? userRolesDictionary.get(row.piRequestRoleCode.toString())
                                : defaultEmptyString
                            }
                          />
                          <TextCell
                            name="piPurposeTestRoleCode"
                            text={
                              row.piPurposeTestRoleCode != null
                                ? userRolesDictionary.get(row.piPurposeTestRoleCode.toString())
                                : defaultEmptyString
                            }
                          />
                          <TextCell
                            name="piFileShareRoleCode"
                            text={
                              row.piFileShareRoleCode != null
                                ? userRolesDictionary.get(row.piFileShareRoleCode.toString())
                                : defaultEmptyString
                            }
                          />
                        </DataTableRow>
                      ))}
                  </DataTableBody>
                </DataTable>
              )}
              {totalItems > 0 && (
                <div className={`${styles.dataTableFooter} row col-12 justify-content-end`}>
                  <DataTableFooter
                    name="manageUsersList"
                    currentPage={currentPage}
                    totalPages={totalPages || 1}
                    itemsPerPageLabel={{
                      value: i18n.t('DefaultsInventoryTable-ResultsPerPageDropdown-Title')
                    }}
                    itemsXofYText={`${itemRangeFrom || 0} - ${itemRangeTo || 0} ${i18n.t(
                      'Globals-Grid-ResultsPerPageDropdown-Of'
                    )}`}
                    totalItems={totalItems}
                    pagerClickCallback={onPagerClick}
                    itemsPerPageOnChangeCallback={onItemsPerPageChange}
                    itemsPerPageValue={itemsPerPage.toString()}
                  />
                </div>
              )}
            </div>
          )}
          {isTab.name === 'external' && (
            <HbtExternalUsersTabInventory fields={fields.externalTab.fields} />
          )}
        </div>
      </div>
    </>
  );
};

export default HbtUserAccessManagementPage;
