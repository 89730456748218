import i18n from 'i18next';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import getPathInObject from 'Constants/Utils/GetPathInObject';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import FormInputWrapper from 'Components/Inputs/FormInputWrapper';

import type { Props, LabelledByProps } from './types';
import styles from './styles.module.scss';

function toLabelValuePair({ fields }: SitecoreListItem): {
  label: string | undefined;
  value: string | undefined;
} {
  return {
    label: fields.itemName?.value,
    value: fields.itemValue?.value
  };
}

const FORM_SELECT = 'form-select';
const FORM_SELECT_WRAPPER = 'form-select-wrapper';

const FormDropdown: FC<Props> = ({
  name,
  label,
  options,
  optional,
  isDisabled,
  className,
  watch,
  setAsNumber = true,
  addDefaultSelect,
  required = false,
  handleChange,
  disableDefaultSelect = true
}) => {
  const {
    register,
    formState: { errors },
    watch: watchValue
  } = useFormContext();

  const message = getPathInObject(errors, `${name}.message`);
  const registerProps = !optional
    ? register(name, { valueAsNumber: setAsNumber, onChange: handleChange })
    : {};

  if (isDisabled === true) {
    // Manually register as we won't bind to an element to satisfy accessibility requirements
    register(name);

    const value = watchValue(name);

    return (
      <FormInputWrapper name={name} watch={watch} label={label} className={className}>
        <div className={styles[FORM_SELECT_WRAPPER]}>
          <input
            name={name}
            className={styles[FORM_SELECT]}
            {...(isDisabled && { 'aria-disabled': true, readOnly: true })}
            value={
              value != null
                ? options?.find?.((option) =>
                    setAsNumber === false
                      ? option?.fields?.itemValue?.value === value
                      : Number(option?.fields?.itemValue?.value) === value
                  )?.fields?.itemName?.value
                : `${i18n.t('Dropdown-Option-PleaseSelect')}`
            }
            aria-label={name}
            aria-errormessage={`${name}_error_message`}
          />
        </div>
      </FormInputWrapper>
    );
  }
  if (name === 'adjudicationAttachmentCategoryCode') {
    return (
      <FormInputWrapper label={label} className={className} name={name} watch={watch}>
        <div className={styles[FORM_SELECT_WRAPPER]}>
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            className={styles[FORM_SELECT]}
            data-testid={`select-${name}`}
            onChange={handleChange}
            {...registerProps}
            aria-errormessage={`${name}_error_message`}
            aria-invalid={Boolean(message)}
            required={required}
          >
            {(addDefaultSelect ?? true) && (
              <option disabled={disableDefaultSelect} selected value="">
                {i18n.t('Dropdown-Option-PleaseSelect')}
              </option>
            )}
            {/* eslint-disable-next-line no-shadow */}
            {options?.map?.(toLabelValuePair)?.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </FormInputWrapper>
    );
  }
  const value = watchValue(name);

  return (
    <FormInputWrapper label={label} className={className} name={name} watch={watch}>
      <div className={styles[FORM_SELECT_WRAPPER]}>
        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
        <select
          className={styles[FORM_SELECT]}
          data-testid={`select-${name}`}
          onChange={handleChange}
          {...registerProps}
          value={value}
          aria-label={name}
          aria-errormessage={`${name}_error_message`}
          aria-invalid={Boolean(message)}
          required={required}
        >
          {(addDefaultSelect ?? true) && (
            <option disabled={disableDefaultSelect} selected value="">
              {i18n.t('Dropdown-Option-PleaseSelect')}
            </option>
          )}
          {/* eslint-disable-next-line no-shadow */}
          {options?.map?.(toLabelValuePair).map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
    </FormInputWrapper>
  );
};

const LabelledBy: FC<LabelledByProps> = ({
  name,
  label,
  options,
  isDisabled,
  className,
  watch,
  disableDefaultSelect = true,
  defaultValue
}) => {
  const {
    register,
    formState: { errors },
    watch: watchValue
  } = useFormContext();
  const message = getPathInObject(errors, `${name}.message`);

  if (isDisabled === true) {
    // Manually register as we won't bind to an element to satisfy accessibility requirements
    register(name);

    const value = watchValue(name);

    return (
      <FormInputWrapper name={name} watch={watch} className={className}>
        <div className={styles[FORM_SELECT_WRAPPER]}>
          <input
            name={name}
            aria-label={name}
            className={styles[FORM_SELECT]}
            {...(isDisabled && { 'aria-disabled': true, readOnly: true })}
            value={
              value != null
                ? options?.find?.((option) => option?.fields?.itemValue?.value === value)?.fields
                    ?.itemName?.value
                : `${i18n.t('Dropdown-Option-PleaseSelect')}`
            }
            aria-errormessage={`${name}_error_message`}
          />
        </div>
      </FormInputWrapper>
    );
  }
  return (
    <FormInputWrapper className={className} name={name} watch={watch}>
      <div className={styles[FORM_SELECT_WRAPPER]}>
        <select
          defaultValue={defaultValue != null ? defaultValue : undefined}
          className={styles[FORM_SELECT]}
          {...register(name || ' ', { valueAsNumber: false })}
          aria-errormessage={`${name}_error_message`}
          aria-invalid={Boolean(message)}
          aria-labelledby={label}
        >
          <option disabled={disableDefaultSelect} selected value="">
            {i18n.t('Dropdown-Option-PleaseSelect')}
          </option>
          {/* eslint-disable-next-line no-shadow */}
          {options?.map?.(toLabelValuePair)?.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
    </FormInputWrapper>
  );
};

export default FormDropdown;
export { LabelledBy };
