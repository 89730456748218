import React, {
  FC,
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import { Icon, Skeleton } from '@mui/material';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Image, ImageFieldValue, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Button } from 'Components/Core/Button';
import { ButtonSize, ButtonVariant } from 'Components/Core/Button/types';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import { useAuthenticationContext } from 'Foundation/Authentication';
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import HbtLanguageSwitcher from '../../../HbtHeader/components/HbtLanguageSwitcher';
import ResponsiveImage from '../../../Shared/ResponsiveImageLoader';
import styles from './styles.module.scss';
import { LoginPageProps, Language, StyleProps } from './types';

const HbtLoginPage: FC<LoginPageProps> = ({ fields }): ReactElement => {
  const skeletonBaseStyles: StyleProps = {
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: '#F0F1F3',
    color: '#F0F1F3'
  };

  const authenticationContext = useAuthenticationContext();
  const history = useHistory();
  const siteLanguages: string[] = [Language.EN, Language.FR];
  const cmhcLogoAltText = fields.companyLogo?.value as ImageFieldValue;

  const [isLoadingRedirect, setLoadingRedirect] = useState(false);
  const onLoginClick = useCallback(() => {
    setLoadingRedirect(true);
    authenticationContext.signInWithRedirect();
  }, [authenticationContext]);

  if (!isServer()) {
    try {
      if (localStorage.getItem('userSavedLang') === null) {
        let language = navigator.language.substring(0, 2);
        if (!siteLanguages.includes(language)) {
          language = Language.EN;
        }
        localStorage.setItem('userSavedLang', language);
        history.push(`/${language}`);
      } else if (window.location.pathname === '/') {
        const language = localStorage.getItem('userSavedLang');
        history.push(`/${language}`);
      }
    } catch (error) {
      console.error(`Error accessing localStorage: ${error}`); // optional
      const fallBackLanguage = Language.EN;
      history.push(`/${fallBackLanguage}`);
    }
  }

  const initialImageLoadingStates = [true, true, true];
  const [loadingStates, setIsLoadingStates] = useState(initialImageLoadingStates);
  const handleImageLoadChange = useCallback(
    (index: number, loadingState: boolean) => {
      setIsLoadingStates((prevStates) => {
        if (prevStates[index] !== loadingState) {
          const newStates = [...prevStates];
          newStates[index] = loadingState;
          return newStates;
        }
        return prevStates;
      });
    },
    [loadingStates]
  );

  const [isExpanded, setIsExpanded] = useState(false);
  const [applyAnimation, setApplyAnimation] = useState(false);

  const onTroubleLoginClick = useCallback(
    (e: any) => {
      e.preventDefault();
      const timer = applyAnimation ? 200 : 0;
      setApplyAnimation(!applyAnimation);
      setTimeout(() => {
        setIsExpanded(!isExpanded);
        const troubleLoginHelpBtn = document.querySelector(
          '[class*="styles_appLayout"] #trouble-login-help-btn'
        ) as HTMLButtonElement;
        if (troubleLoginHelpBtn) {
          troubleLoginHelpBtn.focus();
        }
      }, timer);
    },
    [isExpanded, setIsExpanded]
  );

  useEffect(() => {
    const loginHelpCloseBtn = document.querySelector(
      '[class*="styles_appLayout"] #login-help-close-btn'
    ) as HTMLButtonElement;

    if (isExpanded && loginHelpCloseBtn) {
      loginHelpCloseBtn.focus();
    }
  }, [isExpanded]);

  const [topBorderLinePosition, setTopBorderLinePosition] = useState(0);
  const [borderBottomHeight, setBorderBottomHeight] = useState('');
  const [topNotchPosition, settopNotchPosition] = useState('');

  useLayoutEffect(() => {
    const updateTopBorderLinePosition = () => {
      const languageSwitch = document.querySelector('[class*="styles_appLayout"] #language-switch');
      const loginContainer = document.querySelector('[class*="styles_appLayout"] #login-container');
      const languageSwitchBottom = languageSwitch?.getBoundingClientRect().bottom;
      const loginContainerTop = loginContainer?.getBoundingClientRect().top;

      if (languageSwitchBottom && loginContainerTop) {
        const topBorderLinePosition =
          languageSwitchBottom + (loginContainerTop - languageSwitchBottom) / 2;
        setTopBorderLinePosition(topBorderLinePosition - 8);
        settopNotchPosition(`calc(30vh - ${topBorderLinePosition}px)`);
        const borderTopHeight = 0.3 * window.innerHeight;
        setBorderBottomHeight(`calc(100vh - ${borderTopHeight + 144}px)`);
      }
    };

    updateTopBorderLinePosition();

    window.addEventListener('resize', updateTopBorderLinePosition);

    return () => {
      window.removeEventListener('resize', updateTopBorderLinePosition);
    };
  }, [topBorderLinePosition]);

  const LoadingIcon = () => {
    return (
      <Icon>
        <Image src={fields.loginButtonLoadingIcon} />
      </Icon>
    );
  };

  const handleSkipToContent = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const loginContainer = document.querySelector('#login-container') as HTMLElement;
    if (loginContainer) {
      loginContainer.focus();
    }
  }, []);

  return (
    <div className={styles.Welcome}>
      <a
        href="#"
        className={styles.skipLink}
        onClick={handleSkipToContent}
        aria-label={fields?.skipContentLabel?.value || 'Skip to Content'}
      >
        {fields?.skipContentLabel?.value || 'Skip to Content'}
      </a>
      <div
        role="status"
        aria-live="polite"
        className={styles.LanguageContainer}
        id="language-switch"
      >
        <HbtLanguageSwitcher />
      </div>
      <div className={`${styles.leftContainer}`}>
        {fields.loginMainBgImageLg && fields.loginMainBgImageMd && (
          <ResponsiveImage
            desktopImage={{
              ...fields.loginMainBgImageLg,
              value: fields.loginMainBgImageLg.value
                ? {
                    ...fields.loginMainBgImageLg.value,
                    role: 'presentation'
                  }
                : undefined
            }}
            tabletImage={{
              ...fields.loginMainBgImageMd,
              value: fields.loginMainBgImageMd.value
                ? {
                    ...fields.loginMainBgImageMd.value,
                    role: 'presentation'
                  }
                : undefined
            }}
            responsiveClassName={`${styles.loginMainImageBg} ${
              applyAnimation ? styles.imagesUpAnimation : styles.imagesDownAnimation
            }`}
            onLoadingChange={(loadingState) => handleImageLoadChange(0, loadingState)}
          />
        )}
        <div
          className={`${styles.rightBorderContainer} ${
            loadingStates[1] ? styles.visibilityHidden : ''
          }`}
          style={{ top: `${topBorderLinePosition}px` }}
        >
          <div className={`${styles.borderChildOne}`} style={{ height: topNotchPosition }}></div>
          <div className={styles.borderChildTwo}>
            {fields.loginCmhcNotchLg && fields.loginCmhcNotchMd && (
              <ResponsiveImage
                desktopImage={{
                  ...fields.loginCmhcNotchLg,
                  value: fields.loginCmhcNotchLg.value
                    ? {
                        ...fields.loginCmhcNotchLg.value,
                        role: 'presentation'
                      }
                    : undefined
                }}
                tabletImage={{
                  ...fields.loginCmhcNotchMd,
                  value: fields.loginCmhcNotchMd.value
                    ? {
                        ...fields.loginCmhcNotchMd.value,
                        role: 'presentation'
                      }
                    : undefined
                }}
                responsiveClassName={`${styles.borderLogo}`}
                onLoadingChange={(loadingState) => handleImageLoadChange(1, loadingState)}
              />
            )}
          </div>
          <div
            className={`${styles.borderChildThree}`}
            style={{ height: borderBottomHeight }}
          ></div>
        </div>
        {fields.loginOverlayImageLg && fields.loginOverlayImageMd && (
          <ResponsiveImage
            desktopImage={{
              ...fields.loginOverlayImageLg,
              value: fields.loginOverlayImageLg.value
                ? {
                    ...fields.loginOverlayImageLg.value,
                    role: 'presentation'
                  }
                : undefined
            }}
            tabletImage={{
              ...fields.loginOverlayImageMd,
              value: fields.loginOverlayImageMd.value
                ? {
                    ...fields.loginOverlayImageMd.value,
                    role: 'presentation'
                  }
                : undefined
            }}
            responsiveClassName={`${styles.overlayWomanImage} ${
              applyAnimation ? styles.imagesUpAnimation : styles.imagesDownAnimation
            }`}
            onLoadingChange={(loadingState) => handleImageLoadChange(2, loadingState)}
          />
        )}
        {loadingStates[0] && loadingStates[2] && (
          <Skeleton
            variant="rectangular"
            sx={{
              height: '100%',
              width: '100%',
              ...skeletonBaseStyles
            }}
            animation={false}
          />
        )}
      </div>
      <div
        className={`${styles.rightContainer} ${loadingStates[1] ? styles.visibilityHidden : ''}`}
      >
        <div className={styles.loginContainer} id="login-container" tabIndex={0}>
          <div className={styles.logo}>
            <Image field={fields.companyLogo} alt={cmhcLogoAltText?.alt} />
          </div>
          <div className={styles.loginTextWrapper}>
            {fields.title ? (
              <h1 className={styles.title}>
                <Text field={fields.title} />
              </h1>
            ) : (
              <Skeleton
                sx={{
                  height: '36px',
                  width: '310px',
                  ...skeletonBaseStyles
                }}
                animation={false}
              />
            )}
            {fields.subTitle ? (
              <h2 className={styles.subTitle}>
                <Text field={fields.subTitle} />
              </h2>
            ) : (
              <Skeleton
                sx={{
                  height: '24px',
                  width: '110px',
                  ...skeletonBaseStyles
                }}
                animation={false}
              />
            )}
          </div>
          <div className={styles.loginBtn}>
            {fields.loginButtonText ? (
              <Button
                name={fields.loginButtonText.value ?? 'Log in'}
                loading={!!isLoadingRedirect}
                variant={ButtonVariant.PRIMARY}
                onClick={onLoginClick}
                leadingIcon={isLoadingRedirect ? () => <LoadingIcon /> : undefined}
                ariaText={{ value: `${fields.loginButtonText.value}` }}
                text={{ value: fields.loginButtonText.value }}
              />
            ) : (
              <Skeleton
                sx={{
                  height: '28px',
                  width: '310px',
                  ...skeletonBaseStyles
                }}
                animation={false}
              />
            )}
          </div>
          <div className={styles.loginHelpBtnContainer}>
            {fields.loginHelpText ? (
              <Button
                id="trouble-login-help-btn"
                className={styles.loginHelpBtn}
                variant={ButtonVariant.TERTIARY}
                name={fields.loginHelpText.value ?? 'Trouble logging in?'}
                onClick={onTroubleLoginClick}
                ariaText={{ value: `${fields.loginHelpText.value}` }}
                text={{ value: fields.loginHelpText.value }}
                aria-expanded={isExpanded}
                aria-controls="login-help-container"
              />
            ) : (
              <Skeleton
                sx={{
                  height: '24px',
                  width: '110px',
                  ...skeletonBaseStyles
                }}
                animation={false}
              />
            )}
          </div>
        </div>
      </div>
      {isExpanded && (
        <div
          className={`${styles.loginHelpContainer} ${
            applyAnimation ? styles.helpContainerSlideUp : styles.helpContainerSlideDown
          }`}
          id="login-help-container"
          role="dialog"
          aria-labelledby="help-title"
          aria-describedby="help-description"
        >
          <div className={styles.helpTextWrapper}>
            <div className={styles.helpTitle}>
              <h4 id="help-title">
                <Text field={fields.loginHelpText} />
              </h4>
            </div>
            <div id="help-description" className={styles.helpdescription}>
              <RichText field={fields.loginHelpDescription} />
            </div>
          </div>
          <div className={styles.helpClose}>
            <Button
              id="login-help-close-btn"
              className={styles.loginHelpCloseBtn}
              variant={ButtonVariant.ICON}
              size={ButtonSize.LARGE}
              icon={() => <HbtIcon size={HbtIconSize.X_LARGE} type="icon_close" />}
              ariaText={{ value: i18n.t('Accessibility-Close-Button') ?? 'Close' }}
              name="Close"
              text={{ value: 'Close' }}
              onClick={(e: any) => onTroubleLoginClick(e)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(HbtLoginPage);
