import * as React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Left3Right9Props from './types';
import styles from './styles.module.scss';

const Left3Right9 = (props: Left3Right9Props) => (
  <div className={styles.layoutContainer}>
    <div>
      <Placeholder name="hbt-left-3" rendering={props.rendering} />
    </div>
    <div>
      <Placeholder name="hbt-right-9" rendering={props.rendering} />
    </div>
  </div>
);

export default Left3Right9;
