import { HttpResponseStatusCodes } from '@hobt/constants';
import {
  LoanValidationErrorCode,
  LoanValidationResponse,
  LoanValidationResponseData
} from '@hobt/loan-domain';
import { AxiosResponse } from 'axios';
import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { config } from '../../../../../config';
import { ClaimValidationData, ErrorEntity, ErrorObject } from './types';

// TODO: rename LoanValidationErrorCode to HbtServerErrorCode
// TODO: rename LoanValidationResponse to CreateClaimResponse
const getFiTransitDetailsUrl = config.claimApi.urls.getFiTransitDetails;

export const loanValidationErrorCatch = (err: ErrorEntity) => {
  if (err?.errorCode == null) {
    return LoanValidationResponse.Error;
  }

  switch (err.errorCode) {
    case LoanValidationErrorCode.InvalidMlgLoanErrorCode:
      return LoanValidationResponse.InvalidMlgLoan;

    case LoanValidationErrorCode.InvalidLoanNumberErrorCode:
      return LoanValidationResponse.InvalidLoanNumber;

    case LoanValidationErrorCode.InvalidInsurancePolicyLoanErrorCode:
      return LoanValidationResponse.InvalidInsurancePolicyLoan;

    case LoanValidationErrorCode.InvalidRentalAndSocialHousingLoanErrorCode:
      return LoanValidationResponse.InvalidRentalAndSocialHousingLoan;

    case LoanValidationErrorCode.InvalidLoanStatusErrorCode:
      return LoanValidationResponse.InvalidLoanStatus;

    case LoanValidationErrorCode.InvalidPremiumsNotFullyPaidLoanErrorCode:
      return LoanValidationResponse.InvalidPremiumsNotFullyPaidLoan;

    case LoanValidationErrorCode.CannotBeAcceptedAtLeastOneUnpaidClaimInProgressErrorCode:
      return LoanValidationResponse.CannotBeAcceptedAtLeastOneUnpaidClaimInProgress;

    case LoanValidationErrorCode.MainClaimPaidOrInProgressErrorCode:
      return LoanValidationResponse.MainClaimPaidOrInProgress;

    case LoanValidationErrorCode.MainClaimDoesNotExistOrIsCurrentlyInProgressErrorCode:
      return LoanValidationResponse.MissingOrActiveMainClaim;

    case LoanValidationErrorCode.InvalidEnhancedAcppErrorCode:
    case LoanValidationErrorCode.InvalidLegacyFinancialInstitutionErrorCode:
    case LoanValidationErrorCode.InvalidLegacyLenderClaimProcessingTypeErrorCode:
    case LoanValidationErrorCode.InvalidApprovedLenderErrorCode:
      return LoanValidationResponse.InvalidEnhancedAcpp;

    case LoanValidationErrorCode.InvalidMainClaimForSuppClaimErrorCode:
      return LoanValidationResponse.InvalidMainClaimForSuppClaim;

    case LoanValidationErrorCode.DuplicateDraftClaimErrorCode:
      return LoanValidationResponse.DuplicateDraftClaim;

    case LoanValidationErrorCode.ProcessInMicsClaimErrorCode:
      return LoanValidationResponse.ProcessInMicsClaim;

    case LoanValidationErrorCode.LenderInformationMismatchErrorCode:
      return LoanValidationResponse.LenderInformationMismatch;

    default:
      return LoanValidationResponse.Error;
  }
};

export const errorCatch = (err: ErrorObject) => {
  if (err.response?.status == null) {
    return LoanValidationResponse.Error;
  }

  switch (err.response.status) {
    case HttpResponseStatusCodes.BadRequest:
      return LoanValidationResponse.BadRequest;
    case HttpResponseStatusCodes.Unauthorized:
      return LoanValidationResponse.NotAuthorized;
    case HttpResponseStatusCodes.ServerError:
      return loanValidationErrorCatch(err.response.data.error);
    default:
      return LoanValidationResponse.Error;
  }
};

export const useLoanValidationService = (
  authenticationContext: any,
  apiClientConfig: ApiClientConfig
) => {
  const validateLoanNumber = async (
    data: ClaimValidationData
  ): Promise<LoanValidationResponseData> => {
    try {
      const response: AxiosResponse = await ApiClient(
        authenticationContext,
        apiClientConfig
      ).postWithAuth(config.claimApi.urls.validate, data);

      const { claimPayees, cmhcLoanAccountNumber, draftClaim, lenderName } =
        response?.data?.data?.[0] ?? {};

      if (
        response?.status === HttpResponseStatusCodes.OK &&
        (cmhcLoanAccountNumber == null || cmhcLoanAccountNumber === false)
      ) {
        return {
          loanValidationResponse: LoanValidationResponse.Error
        } as LoanValidationResponseData;
      }

      return {
        loanValidationResponse: LoanValidationResponse.Success,
        draftClaim: { claimPayees, ...draftClaim, lenderName }
      } as LoanValidationResponseData;
    } catch (error) {
      return {
        loanValidationResponse: errorCatch(error as ErrorObject)
      } as LoanValidationResponseData;
    }
  };

  return { validateLoanNumber };
};

const apiClientConfig: ApiClientConfig = { timeout: config.userApi.requestTimeout };

export const getFiAndTransitDetails = async (
  authContext: any,
  cmhcLoanAccountNumber: string
): Promise<AxiosResponse> => {
  try {
    const response: AxiosResponse = await ApiClient(authContext, apiClientConfig).getWithAuth(
      `${getFiTransitDetailsUrl}/${cmhcLoanAccountNumber}`
    );
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
