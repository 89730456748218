import { SitecoreDropdownList } from 'Constants/Types/SitecoreListItem';
import { RichTextProps } from '@sitecore-jss/sitecore-jss-react/types/components/RichText';
import { HbtFileExtensionType } from '@hobt/constants';
import { FinalClaim } from '@hobt/claim-domain';

import { AttachmentsViewPropsFields } from 'Components/Common/AttachmentsView/types';

export interface DeclineClaimProps {
  onCancelCallback?: () => void;
  onSuccessCallback?: (data: FinalClaim) => void;
  onErrorCallback?: () => void;
  fields: DeclineClaimPropsFields;
}
export interface DeclineClaimPropsFields {
  formTitle: FieldValue;
  content: FieldValue;
  categoryLabel: FieldValue;
  categoryPlaceholder: FieldValue;
  categoryList: SitecoreDropdownList;
  notificationTemplateEnglish: RichTextProps;
  notificationTemplateFrench: RichTextProps;
  note: FieldValue;
  notePlaceholder: FieldValue;
  characterLimit: FieldValue;
  submitBtn: FieldValue;
  cancelBtn: FieldValue;
  limitNumber: FieldValue;
  notificationTemplateLabel: FieldValue;
  languageLabel: FieldValue;
  languageOptions: SitecoreDropdownList[];
  attachmentsView: AttachmentsViewComponentProps;
  typeOfClaimToken: FieldValue;
  cmhcAccountToken: FieldValue;
  declineReasonEnglish: FieldValue;
  declineReasonFrench: FieldValue;
  declineNoteEnglish: FieldValue;
  declineNoteFrench: FieldValue;
  categoryListFrench: SitecoreDropdownList;
}

interface AttachmentsViewComponentPropsFields extends AttachmentsViewPropsFields {
  name: FieldValue;
  heading: FieldValue;
}

export interface AttachmentsViewComponentProps {
  fields: AttachmentsViewComponentPropsFields;
}

export interface DeclineClaimRequestData {
  declineReasonCode: number;
  attachments: AttachementFields[];
  remarkText?: string;
}

export type AttachementFields = {
  documentID: string;
  metadata: {
    fileName: string;
    fileSize: number;
    description: string;
    documentType: number;
    uploadStatus: number;
  };
};

export const AllowedFileTypes = [
  HbtFileExtensionType.CSV,
  HbtFileExtensionType.XLSX,
  HbtFileExtensionType.XLSM,
  HbtFileExtensionType.XLSB,
  HbtFileExtensionType.XLTX,
  HbtFileExtensionType.XLTM,
  HbtFileExtensionType.XLS,
  HbtFileExtensionType.XLT,
  HbtFileExtensionType.XML,
  HbtFileExtensionType.XLAM,
  HbtFileExtensionType.XLA,
  HbtFileExtensionType.XLW,
  HbtFileExtensionType.XLR,
  HbtFileExtensionType.PDF,
  HbtFileExtensionType.JPG,
  HbtFileExtensionType.DOC,
  HbtFileExtensionType.DOCX
];
