import React, { useEffect } from 'react';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import styles from './styles.module.scss';

const LanguageSwitch = () => {
  const location = useLocation();
  const sitecoreContextFactory = useSitecoreContext();
  const context = sitecoreContextFactory?.sitecoreContext;

  let userSavedLang = 'en';
  const changeLng = (key: string) => {
    sitecoreContextFactory?.updateSitecoreContext?.({
      ...context,
      language: key,
      refresh: true
    });
  };
  const switchLanguage = (newLang: string, userDidClick: boolean) => {
    if (!isServer()) {
      if (newLang === 'fr') {
        localStorage.setItem('userSavedLang', 'fr');
        changeLng('fr');
      } else {
        localStorage.setItem('userSavedLang', 'en');
        changeLng('en');
      }

      if (userDidClick) {
        const langVal = location.pathname.replace(/^\/([^\/]*).*$/, '$1');
        const queryString = location.search;

        let pathWithNewLanguage: string;
        if (langVal !== null && langVal !== undefined && langVal !== '') {
          if (langVal === 'en' || langVal === 'fr') {
            pathWithNewLanguage = location.pathname.replace(
              location.pathname.replace(/^\/([^\/]*).*$/, '$1'),
              newLang
            );
          } else {
            pathWithNewLanguage = `/${newLang}${location.pathname}`;
          }
          pathWithNewLanguage += queryString;
        } else {
          pathWithNewLanguage = `/${newLang}`;
        }

        window.location.href = pathWithNewLanguage;
      }
    }
  };

  const detectLanguage = () => {
    if (userSavedLang) {
      switchLanguage(userSavedLang === 'fr' ? 'fr' : 'en', false);
    } else {
      const userLang = typeof window !== 'undefined' ? navigator.language.substring(0, 2) : 'en';
      switchLanguage(userLang === 'fr' ? 'fr' : 'en', false);
    }
  };

  useEffect(() => {
    if (!isServer()) {
      userSavedLang = localStorage.getItem('userSavedLang') || 'en';
      detectLanguage();
    }
  }, [isServer()]);

  return (
    <button
      data-testid="LanguageSwitch"
      className={styles.languageSwitcherButton}
      lang={context.language === 'en' ? 'fr' : 'en'}
      onClick={() => switchLanguage(context.language === 'en' ? 'fr' : 'en', true)}
      aria-label={i18n.t('LanguageToggle-Language')}
    >
      {i18n.t('LanguageToggle-Language')}
    </button>
  );
};

export default withTranslation()(LanguageSwitch);
