import { HttpResponseStatusCodes } from '@hobt/constants';
import { AxiosRequestConfig } from 'axios';
import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { AuthenticationContextType } from 'Foundation/Authentication';
import { config } from '../../../../../config';
import { PurposeTestFileReport } from './types';

const apiClientConfig: ApiClientConfig = { timeout: config.purposeTestReportApi.requestTimeout };
const inventoryUrl: string = config.purposeTestReportApi.urls.inventory;
const downloadUrl: string = config.purposeTestReportApi.urls.download;
const inventoryTimeout: number = config.purposeTestReportApi.requestTimeout;

export const getInventory = async (
  authenticationContext: AuthenticationContextType
): Promise<PurposeTestFileReport[]> => {
  try {
    const response = await ApiClient(authenticationContext, apiClientConfig).getWithAuth(
      inventoryUrl,
      { timeout: inventoryTimeout }
    );
    return response?.data?.statusCode === HttpResponseStatusCodes.OK && response?.data?.data;
  } catch (err) {
    console.error('Error downloading inventory:', err);
    return [];
  }
};

export const downloadInventory = async (
  data: object,
  authenticationContext: AuthenticationContextType,
  requestConfig?: AxiosRequestConfig
) => {
  try {
    return ApiClient(authenticationContext, apiClientConfig).postWithAuth(downloadUrl, data, {
      ...requestConfig,
      timeout: inventoryTimeout
    });
  } catch (err) {
    console.error('Error downloading inventory:', err);
    return null;
  }
};
