import * as React from 'react';
import { TabsProps, TabItemProps } from 'Components/Navigation/Tabs/types';
import { TabItem } from 'Components/Navigation/Tabs/TabItem';
import { useState, useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import styles from './styles.module.scss';

export const Tabs: React.FC<TabsProps> = ({
  items,
  tabOnClickCallback,
  initialActiveTabName,
  ariaControlsName,
  currentTab,
  className,
  label
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState<string>(initialActiveTabName || items?.[0]?.name);

  const onTabClick = (tab: TabItemProps) => {
    if (currentTab == null) {
      setActiveTab(tab.name);
    }
    tabOnClickCallback(tab);
  };

  const onTabSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const val = Number(e?.currentTarget?.value);

    if (!Number.isNaN(val)) {
      onTabClick(items[val]);
    }
  };

  useEffect(() => {
    if (currentTab != null) {
      setActiveTab(currentTab.name);
    }
  }, [currentTab]);

  return (
    <div className={`${styles.tabsWrapper} ${className || ''}`.trim()}>
      <ul className={styles.tabs}>
        {items?.map((tab: TabItemProps) => (
          <li key={`${tab.name}Item`}>
            <TabItem
              key={tab.name}
              count={tab.count}
              displayText={tab.displayText}
              isActive={tab.name === activeTab}
              name={tab.name}
              tabItemOnClickCallback={onTabClick}
              ariaControls={ariaControlsName}
              fields={tab.fields}
            />
          </li>
        ))}
      </ul>

      <div className={styles.selector}>
        {label?.value && (
          <label htmlFor={`selectControlFor-${ariaControlsName}`}>
            <Text field={label ?? {}} />
          </label>
        )}

        <div>
          {/* jsx-a11y/no-onchange deprecated, ignoring rule */}
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select id={`selectControlFor-${ariaControlsName}`} onChange={onTabSelect}>
            {items?.map((tab: TabItemProps, idx) => (
              <option key={tab.name} selected={tab.name === activeTab} value={idx}>
                {tab.displayText?.value ?? ''}
                {tab.count !== undefined && ` (${tab.count})`}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export type { TabsProps, TabItemProps };
