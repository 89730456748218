import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import get from 'Constants/Utils/GetPathInObject';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import FormError from 'Components/Inputs/FormError';
import i18n from 'i18next';

import type { Props } from './types';
import styles from './styles.module.scss';

const FormInputWrapper: FC<Props> = ({
  label,
  className,
  name,
  watch,
  children,
  disabled,
  formatError
}) => {
  const {
    formState: { errors }
  } = useFormContext();
  const { message, type } = (disabled !== true && get(errors, name)) || {};

  if (watch) {
    if (!watch.is.includes(watch.when)) {
      return <span className={watch.className || className} />;
    }
  }

  return (
    <label
      className={`${styles.formInputLabelStyle} d-flex flex-column ${className}${
        message || formatError ? ' error' : ''
      }`}
    >
      {typeof label === 'object' && label && (
        <span className={styles.formLabelTextStyle}>
          <Text field={label} />
        </span>
      )}
      {children}
      <FormError
        name={name}
        message={formatError ? i18n.t('Errors-HBT_ERR_DATE_VALIDATION') : message}
        type={formatError ? undefined : type}
        label={label}
      />
    </label>
  );
};

export default FormInputWrapper;
export type { Props };
