import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Button } from 'Components/Common/Button';
import FormDropdown from 'Components/Inputs/FormDropdown';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import { convertToSitecoreListItem } from 'Constants/Utils/ConvertToSitecoreListItem';
import { ArrearsHistoricalRecordSelectorProps } from 'Feature/Arrears/components/ArrearsHistoricalRecords/ArrearsHistoricalRecordSelector/types';
import i18n from 'i18next';
import React, { useMemo, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import styles from './styles.module.scss';

export const ArrearsHistoricalRecordSelector: React.FC<ArrearsHistoricalRecordSelectorProps> = ({
  helpText,
  monthLabel,
  yearLabel,
  mostRecentReportMonth,
  mostRecentReportYear,
  selectButtonText,
  onSubmit
}: ArrearsHistoricalRecordSelectorProps) => {
  const currentDate = new Date();

  const formMethods = useForm();
  const { year: selectedYear } = formMethods.watch();

  useEffect(() => {
    // initial load
    if (mostRecentReportYear != null && mostRecentReportMonth != null) {
      formMethods.setValue('year', mostRecentReportYear);
      formMethods.setValue('month', mostRecentReportMonth);

      // submit when data has loaded initially
      onSubmit({
        month: mostRecentReportMonth,
        year: mostRecentReportYear
      });
    }
  }, [mostRecentReportYear, mostRecentReportMonth]);

  const yearOptions: number[] = useMemo(() => {
    const targetYear =
      mostRecentReportYear != null && mostRecentReportYear > 1600
        ? mostRecentReportYear
        : currentDate.getFullYear();

    return Array.from(Array(5).keys())?.map((k) => targetYear - k);
  }, [mostRecentReportYear]);

  const monthOptions: SitecoreListItem[] = useMemo(() => {
    const { format } = new Intl.DateTimeFormat(i18n.language, { month: 'long' });

    return Array.from(Array(12).keys())?.map((m) =>
      convertToSitecoreListItem(format(Date.UTC(2021, m + 1)), m + 1)
    );
  }, [mostRecentReportYear, selectedYear]);

  return (
    <div className={styles.selectorWrapper}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit as any)}>
          <Text tag="p" field={helpText} />
          <div className={styles.monthYearDropdowns}>
            <FormDropdown label={monthLabel} name="month" options={monthOptions} />
            <FormDropdown
              label={yearLabel}
              name="year"
              options={yearOptions?.map((year) => convertToSitecoreListItem(year))}
            />
            <Button name="selectButton" text={selectButtonText} type="submit" />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
