import React, { FC, useEffect, useRef } from 'react';
import { IconButton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import i18n from 'i18next';
import { HbtIcon } from '../HbtIcon';
import { HbtIconSize } from '../HbtIcon/types';
import styles from './styles.module.scss';
import { FlyoutContainerProps } from './types';

/**
 * @param {FlyoutContainerProps} props - list out the props.
 * @param {string} props.id - the id of the component.
 * @param {boolean} props.open - determines if the flyout container should be open.
 * @param {function} props.onClose - the onClose event handler.
 * @param {string} props.headerText - header title for flyout container.
 * @param {boolean} props.isHeaderRequired - determines if header section is required.
 * @param {React.JSX.Element} props.leadingIcon - leadingIcon for header section.
 * @returns {FlyoutContainer} Rendered component.
 */

/**
 * Flyout Container component
 *
 * This is the flyout container component written using storybook. It brings in a drawer showing addionnal information on a page.
 */

export const FlyoutContainer: FC<FlyoutContainerProps> = ({
  id,
  headerText,
  isHeaderRequired,
  open,
  content,
  leadingIcon,
  onClose
}: FlyoutContainerProps) => {
  const flyoutRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      const timeoutId = setTimeout(() => {
        if (flyoutRef.current) {
          const firstFocusableElement = flyoutRef.current.querySelector(
            'a, button, input, select, [tabindex]:not([tabindex="-1"])'
          );
          if (firstFocusableElement) {
            (firstFocusableElement as HTMLElement).focus();
          }
        }
      }, 10);
      return () => clearTimeout(timeoutId);
    }
  }, [open]);

  const DrawerContent = (
    <Box role="presentation" className={styles.container} data-testid="core-flyout-container">
      {isHeaderRequired && (
        <>
          <Box>
            <Stack
              className={styles.headerContainer}
              direction="row"
              justifyContent="space-between"
              aria-labelledby="headerText"
            >
              <Box className={styles.header} id="headerText">
                {leadingIcon} {headerText}
              </Box>
              <Box>
                <IconButton
                  className={styles.flyoutContainerCloseBtn}
                  disableRipple
                  aria-label={`${i18n.t('Accessibility-Close-Button', 'Close')} ${headerText}`}
                  onClick={(e: React.MouseEvent) => {
                    if (onClose) onClose(e);
                  }}
                >
                  <HbtIcon size={HbtIconSize.LARGE} className={styles.icon} type="icon_close" />
                </IconButton>
              </Box>
            </Stack>
          </Box>
          <Divider />
        </>
      )}
      <Stack
        className={styles.actionsContainerCustom}
        direction="row"
        justifyContent="space-between"
      >
        {content}
      </Stack>
    </Box>
  );

  return (
    <Drawer id={id} anchor="right" open={open} onClose={onClose} ref={flyoutRef}>
      {DrawerContent}
    </Drawer>
  );
};
