import React from 'react';
import { FormCheckboxProps } from 'Components/Inputs/FormCheckbox/types';
import { useFormContext } from 'react-hook-form';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import styles from './styles.module.scss';

export const FormCheckbox: React.FC<FormCheckboxProps> = ({
  name,
  label,
  className,
  isReadOnly,
  value,
  checked,
  onChange,
  dontRegister = false
}: FormCheckboxProps) => {
  const { register } = useFormContext();

  return (
    <div className={`${styles.checkboxContainer} ${className ?? ''}`}>
      <input
        id={`${name}${value}Checkbox`}
        onChange={onChange}
        {...(dontRegister === false && register(`${name}` || ' ', { onChange }))}
        type="checkbox"
        readOnly={isReadOnly}
        aria-label={name}
        aria-live={'assertive'}
        value={value}
        checked={checked}
        data-testid={`${name}${value}Check`}
        {...(isReadOnly === true && { 'aria-disabled': true })}
      />
      <label htmlFor={`${name}${value}Checkbox`} id={`${name}${value}CheckboxLabel`}>
        <span>
          <Text field={label} />
        </span>
      </label>
    </div>
  );
};
