import React, { useState, useEffect } from 'react';
import { ModuleName, ModuleKeyword } from '@hobt/constants';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { FormCheckbox } from 'Components/Inputs/FormCheckbox';
import formCheckboxListStyles from 'Components/Inputs/FormCheckboxList/styles.module.scss';
import FormCheckboxListWrapper from 'Components/Inputs/FormCheckboxListWrapper';
import FormRadioGroup from 'Components/Inputs/FormRadioGroup';
import { UserAccess } from 'Feature/UserManagement/models/types';
import UserPermissionsProps from 'Feature/UserManagement/models/UserPermissionsProps';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { useFormContext } from 'react-hook-form';
import { useModifyUserContext } from '../../ModifyUser/ModifyUserContext';
import styles from './styles.module.scss';

// TODO: need to handle value for 'lenderRoleCode' radio on modify
const UserPermissions: React.FC<UserPermissionsProps> = ({
  fields,
  isModify
}: UserPermissionsProps) => {
  const { register, setValue, watch } = useFormContext();
  let { userData } = useModifyUserContext();
  userData = userData !== undefined && userData[0];
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const userAuthorizationScopeFIMapping =
    sitecoreContext && sitecoreContext?.user?.userAuthorizationScopeFIMapping;

  const selectedFiCodes = watch('userAccesses[0].financialInstitutionCodes');

  const [accessArrears, setAccessArrears] = useState<boolean>(false);
  const [accessDefault, setAccessDefault] = useState<boolean>(false);
  const [accessClaims, setAccessClaims] = useState<boolean>(false);
  const [accessPiRequest, setAccessPiRequest] = useState<boolean>(false);
  const [accessPiPurposeTest, setAccessPiPurposeTest] = useState<boolean>(false);
  const [accessPiFileShare, setAccessPiFileShare] = useState<boolean>(false);
  const [accessData, setAccessData] = useState<string[]>([]);

  useEffect(() => {
    const fiCodeList = accessData.concat.apply(
      [],
      selectedFiCodes?.map((val: string) => {
        if (userAuthorizationScopeFIMapping != null && val in userAuthorizationScopeFIMapping)
          return userAuthorizationScopeFIMapping[val].userAuthorizationScope;
      })
    );

    const accessDataTmp = fiCodeList?.filter(
      (item: string, index: number) => fiCodeList.indexOf(item) === index
    );
    setAccessData(accessDataTmp);

    if (!accessDataTmp.includes(`${ModuleKeyword.Arrears}`)) {
      setAccessArrears(false);
      setValue(`userAccesses[0].access.${ModuleKeyword.Arrears}`, {
        activeFlag: false
      });
    }
    if (!accessDataTmp.includes(`${ModuleKeyword.Default}`)) {
      setAccessDefault(false);
      setValue(`userAccesses[0].access.${ModuleKeyword.Default}`, {
        activeFlag: false
      });
    }
    if (!accessDataTmp.includes(`${ModuleKeyword.Claim}`)) {
      setAccessClaims(false);
      setValue(`userAccesses[0].access.${ModuleKeyword.Claim}`, {
        activeFlag: false
      });
    }
  }, [selectedFiCodes]);

  const userRadiosOptions = [
    {
      fields: {
        listName: { value: 'user radio' },
        listItems: fields.userTypeRadios
      }
    }
  ];

  const claimsRadiosOptions = [
    {
      fields: {
        listName: { value: 'claims radio' },
        listItems: fields.claimsOptionRadios
      }
    }
  ];

  const initAccessValues = (type: ModuleName, activeFlag: boolean) => {
    if (!isModify) register(`userAccesses[0].access.${type}`);
    setValue(`userAccesses[0].access.${type}`, { activeFlag });

    switch (type) {
      case ModuleKeyword.Arrears:
        setAccessArrears(activeFlag);
        break;
      case ModuleKeyword.Default:
        setAccessDefault(activeFlag);
        break;
      case ModuleKeyword.Claim:
        setAccessClaims(activeFlag);
        break;
      case ModuleKeyword.PiRequest:
        setAccessPiRequest(activeFlag);
        break;
      case ModuleKeyword.PiPurposeTest:
        setAccessPiPurposeTest(activeFlag);
        break;
      case ModuleKeyword.PiFileShare:
        setAccessPiFileShare(activeFlag);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (userData && isModify) {
      Object.keys(userData.access).forEach((key) => {
        initAccessValues(key as ModuleName, userData.access[key].activeFlag);
      });
    }
  }, [userData]);

  useEffect(() => {
    if (!isModify) {
      initAccessValues(ModuleKeyword.Arrears, false);
      initAccessValues(ModuleKeyword.Default, false);
      initAccessValues(ModuleKeyword.Claim, false);
      initAccessValues(ModuleKeyword.Admin, false);
      initAccessValues(ModuleKeyword.PiRequest, false);
      initAccessValues(ModuleKeyword.PiPurposeTest, false);
      initAccessValues(ModuleKeyword.PiFileShare, false);
    }
  }, []);

  const handleCheckboxValue = (event: any, valueObj: FieldValue) => {
    const valueNum = Number(valueObj.value);

    switch (valueNum) {
      case UserAccess.Arrears:
        setValue(`userAccesses[0].access.${ModuleKeyword.Arrears}`, {
          activeFlag: event.target.checked
        });
        setAccessArrears(event.target.checked);
        break;
      case UserAccess.Default:
        setValue(`userAccesses[0].access.${ModuleKeyword.Default}`, {
          activeFlag: event.target.checked
        });
        setAccessDefault(event.target.checked);
        break;
      case UserAccess.Claim:
        setValue(`userAccesses[0].access.${ModuleKeyword.Claim}`, {
          activeFlag: event.target.checked
        });
        setAccessClaims(event.target.checked);
        break;
      default:
        setValue(`userAccesses[0].access.${ModuleKeyword.Admin}`, {
          activeFlag: false
        });
        break;
    }
  };

  return (
    <>
      {((userData && isModify) || !isModify) && (
        <div>
          <h2 className="card-title">
            <Text field={fields.sectionTitle} />
          </h2>
          <p>
            <Text field={fields.sectionSubtitle} />
          </p>

          <div className="row card__body-row">
            <div className="form__element form__element--2-column col-6">
              <FormRadioGroup
                className=""
                label={fields.userTypeLabel}
                radioLabels={userRadiosOptions}
                defaultSelection={userData?.lenderRoleCode}
                name="userAccesses[0].lenderRoleCode"
              />
            </div>
            <div className="form__element form__element--2-column col-6">
              <FormCheckboxListWrapper
                name="userAccesses[0].access.arrears.activeFlag"
                label={fields.accessTypeLabel}
                className={`${formCheckboxListStyles.checkBoxListWrapper}`}
              >
                {accessData.includes(ModuleKeyword.Arrears) && (
                  <FormCheckbox
                    checked={accessArrears}
                    name="userAccesses[0].access.arrears.activeFlag"
                    label={fields.userAccessCheckboxes[0].fields.itemName}
                    onChange={(event: any) =>
                      handleCheckboxValue(event, fields.userAccessCheckboxes[0].fields.itemValue)
                    }
                  />
                )}
                {accessData.includes(ModuleKeyword.Default) && (
                  <FormCheckbox
                    checked={accessDefault}
                    name="userAccesses[0].access.default.activeFlag"
                    label={fields.userAccessCheckboxes[1].fields.itemName}
                    onChange={(event: any) =>
                      handleCheckboxValue(event, fields.userAccessCheckboxes[1].fields.itemValue)
                    }
                  />
                )}

                {accessData.includes(ModuleKeyword.Claim) && (
                  <>
                    <FormCheckbox
                      checked={accessClaims}
                      name="userAccesses[0].access.claim.activeFlag"
                      label={fields.userAccessCheckboxes[2].fields.itemName}
                      onChange={(event: any) =>
                        handleCheckboxValue(event, fields.userAccessCheckboxes[2].fields.itemValue)
                      }
                    />
                    {/* TODO: need to set vertical layout below */}
                    {accessClaims && (
                      <div className={styles.radioWrapper}>
                        <FormRadioGroup
                          className=""
                          label={{ value: '' }}
                          radioLabels={claimsRadiosOptions}
                          defaultSelection={userData?.access?.claim?.inventoryViewTypeCode}
                          name="userAccesses[0].access.claim.inventoryViewTypeCode"
                        />
                      </div>
                    )}
                  </>
                )}
              </FormCheckboxListWrapper>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserPermissions;
