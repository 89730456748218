import { DefaultRequestStatus, InventorySubmissionItem } from 'Foundation/Api';
import { TableRowData } from 'Feature/CommonComponents/DataTable';
import { LanguageTypeCode } from '../../../DefaultSubmissionForm/models/typeCode.types';

export const InventoryRowMapper = (): any => {
  const getLocalizedStatusString = (status: number): string => {
    return status >= 0 && status <= 8 ? status.toString() : '0';
  };

  const getValidatedStatus = (status: number | undefined): number => {
    return status !== undefined && status >= 0 && status <= 8
      ? status
      : DefaultRequestStatus.IncompleteSubmission;
  };

  const concatMultiFields = (...fields: string[]): string => {
    return fields.reduce((collector: string, item: string) =>
      collector.concat(item ? ` ${item}` : '')
    );
  };

  const getRow = (item: InventorySubmissionItem): TableRowData => {
    return {
      uuid: item.cmhcDefaultAccountID,
      referenceId: item.cmhcLoanAccountNumber,
      status: item.defaultStatus,
      isSelected: false,
      cols: [
        {
          textValue:
            item.cmhcLoanAccountNumber === undefined ? '' : item.cmhcLoanAccountNumber.toString()
        },
        {
          showAttachmentIcon: item.hasAttachments || false,
          showNoteIcon: false
        },
        {
          textValue: getLocalizedStatusString(getValidatedStatus(item.defaultStatus)),
          status: getValidatedStatus(item.defaultStatus)
        },
        {
          textValue: item.approvedLenderName || 'N/A',
          secondLineText: item.approvedLenderCode || ''
        },
        {
          textValue: concatMultiFields(
            item.lastRecordUpdatedUserFirstName || '',
            item.lastRecordUpdatedUserLastName || ''
          ),
          dateText: item.lastRecordUpdatedTimestamp,
          includeTime: true
        },
        {
          textValue: concatMultiFields(item.cmhcOwnerFirstName || '', item.cmhcOwnerLastName || '')
        },
        { dateText: item.requestReceivedTimestamp, includeTime: false },
        { languageIdentifier: item.correspondenceLanguage || LanguageTypeCode.English },
        { provinceCode: item.provinceCode || 0 }
      ]
    };
  };

  const getRowExternal = (item: InventorySubmissionItem): TableRowData | void => {
    return {
      uuid: item.cmhcDefaultAccountID,
      referenceId: item.cmhcLoanAccountNumber,
      isSelected: false,
      cols: [
        {
          textValue:
            item.cmhcLoanAccountNumber === undefined ? '' : item.cmhcLoanAccountNumber.toString()
        },
        {
          showAttachmentIcon: item.hasAttachments || false,
          showNoteIcon: false
        },
        { textValue: item.approvedLenderReferenceNumber || '' },
        {
          textValue: getLocalizedStatusString(getValidatedStatus(item.defaultStatus)),
          status: getValidatedStatus(item.defaultStatus)
        },
        { dateText: item.requestReceivedTimestamp, includeTime: false },
        { dateText: item.lastRecordUpdatedTimestamp, includeTime: true },
        {
          textValue: item.approvedLenderName || 'N/A',
          secondLineText: item.approvedLenderCode || ''
        }
      ]
    };
  };

  return { getRow, getRowExternal };
};
