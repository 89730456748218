export default function getPathInObject(object: any, path = ''): any {
  return path
    .replace(/\[/g, '.') // Convert index syntax to .-access syntax
    .replace(/\]/g, '')
    .split('.') // Split on the .-accessors
    .reduce((value, name, index, paths) => {
      // If we've hit an undefined already...
      if (value === undefined) {
        // Short-circuit the reduce to save operations
        paths.splice(index);
        // And just return undefined
        return undefined;
      }
      // Otherwise keep retrieving the nested value
      return value[name];
    }, object);
}
