import * as React from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import FocusLock from 'react-focus-lock';

import { ApplicationStates } from '../../Enums/ApplicationStatus';
import { FormModalProps } from './types';

import styles from './styles.module.scss';

export const FormModal: React.FC<FormModalProps> = ({
  type,
  icon = true,
  style,
  title,
  isActive,
  children,
  customStyle,
  onCloseCallback,
  shouldCloseOnBackgroundClick
}) => {
  const applicationState = {
    [ApplicationStates.ERROR]: { icon: 'highlight_off', className: styles.iconError },
    [ApplicationStates.SUCCESS]: { icon: 'warning_amber', className: styles.iconWarning },
    [ApplicationStates.WARNING]: { icon: 'error_outline', className: styles.iconWarning },
    [ApplicationStates.DEFAULT]: { icon: 'announcement', className: styles.iconDefault },
    [ApplicationStates.INACTIVE]: { icon: 'pause_circle_outline', className: styles.iconInactive },
    [ApplicationStates.CANCEL]: { icon: 'error_outline', className: styles.iconCancel }
  };

  return (
    <CSSTransition
      in={isActive}
      classNames={styles.formModal}
      timeout={450}
      unmountOnExit={true}
      mountOnEnter={true}
    >
      <FocusLock>
        <div className={styles.formModal}>
          <div
            className={styles.modalBg}
            data-testid="modal-bg"
            role="presentation"
            onClick={(e) => {
              if (shouldCloseOnBackgroundClick) {
                onCloseCallback(e);
              }
            }}
          />
          <div
            className={`
              ${styles.modalCard} 
              ${styles[style]}
              ${customStyle}
            `}
          >
            <div className={styles.modalHeader}>
              {icon && (
                <div className={`${styles.modalHeaderIcon} ${applicationState[type].className}`}>
                  <i className="material-icons icon--size-32 icon--v-align-middle">
                    {applicationState[type].icon}
                  </i>
                </div>
              )}
              <div className={styles.modalHeaderTitle}>
                <h3 aria-hidden="true">{title}</h3>
              </div>
              <button
                className={styles.closeIconWrapper}
                onClick={onCloseCallback}
                data-testid={'modal-close-button'}
                type="button"
              >
                <span className={`material-icons align-self-center`}>close</span>
              </button>
            </div>
            <div>{children}</div>
          </div>
        </div>
      </FocusLock>
    </CSSTransition>
  );
};
