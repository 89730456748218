import React, { PropsWithChildren, useEffect, useState } from 'react';
import stickybits from 'stickybits';
import styles from './styles.module.scss';

import { StickyContainerProps } from './types';

const StickyContainer: React.FC<StickyContainerProps> = ({
  containerId,
  topOffset,
  shouldFillParentWidth,
  children,
  shouldFillViewportHeight
}: PropsWithChildren<StickyContainerProps>) => {
  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    // Stickybits achieves "position: sticky" behaviour in IE with
    // "position: fixed", which causes the sticky element's width to behave
    // unpredictably. If the sticky element's width should be the same as the
    // parent's, we enforce this here.
    if (shouldFillParentWidth) {
      const parentElement = document.getElementById(`sticky-container-${containerId}`)!
        .parentElement!;
      const computedStyle = getComputedStyle(parentElement);
      setParentWidth(
        parentElement.clientWidth -
          parseFloat(computedStyle.paddingLeft) -
          parseFloat(computedStyle.paddingRight)
      );
    }
    stickybits(`#sticky-container-${containerId}`, {
      stickyBitStickyOffset: shouldFillViewportHeight ? 32 : topOffset
    });
  }, []);

  return (
    <div
      className={styles.stickycontainer}
      id={`sticky-container-${containerId}`}
      style={{
        top: `${topOffset}px`,
        height: shouldFillViewportHeight ? '100vh' : 'inherit',
        marginBottom: '-40px',
        paddingBottom: shouldFillViewportHeight ? '64px' : 'inherit',
        ...(parentWidth && { width: parentWidth })
      }}
    >
      {children}
    </div>
  );
};

export { StickyContainer };
export type { StickyContainerProps };
