import React, { FC } from 'react';
import i18n from 'i18next';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import get from 'Constants/Utils/GetPathInObject';
import FormInputWrapper, {
  Props as FormInputWrapperProps
} from 'Components/Inputs/FormInputWrapper';

import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';
import styles from './styles.module.scss';

type Props = {
  label: FormInputWrapperProps['label'];
  charLimit?: number;
  className: string;
  name: string;
  placeholder?: string;
  textAreaHelperText?: string;
  watch?: FormInputWrapperProps['watch'];
  attributeProps?: Record<string, any>;
  hideHelperText?: boolean;
  isReadOnly?: boolean;
};

const FormTextArea: FC<Props> = ({
  name,
  label,
  watch,
  className,
  charLimit,
  placeholder,
  textAreaHelperText,
  attributeProps,
  hideHelperText,
  isReadOnly
}) => {
  const {
    formState: { errors }
  } = useFormContext();
  const { toFormatNumber } = useTranslationHelpers();
  const { field } = useController({ name });
  const message = get(errors, `${name}.message`);
  const value = useWatch({ name, defaultValue: field.value || '' });
  let textAreaHelperTextLabel = textAreaHelperText;

  if (textAreaHelperTextLabel == null) {
    textAreaHelperTextLabel = i18n.t('Globals-CharLimitText');
  }

  const formattedCharLimit = toFormatNumber(charLimit);
  textAreaHelperTextLabel = textAreaHelperTextLabel
    ?.replace('{charLimit}', formattedCharLimit)
    .replace('{limit}', formattedCharLimit);

  return (
    <FormInputWrapper name={name} label={label} className={className} watch={watch}>
      <textarea
        {...field}
        className={styles['form-text']}
        aria-errormessage={`${name}_error_message`}
        aria-invalid={Boolean(message)}
        {...(isReadOnly === true && { readOnly: isReadOnly })}
        maxLength={charLimit}
        {...attributeProps}
        placeholder={placeholder}
      />
      {!hideHelperText && (
        <div className={styles['text-area-help-text']}>
          <span className={styles['text-area-help-text-limit']}>{textAreaHelperTextLabel}</span>
          <span className={styles['text-area-help-text-count']}>
            {value?.length}/{charLimit}
          </span>
        </div>
      )}
    </FormInputWrapper>
  );
};

export default FormTextArea;
