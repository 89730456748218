import * as React from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import FocusLock from 'react-focus-lock';

import { ApplicationStates } from '../../Enums/ApplicationStatus';
import { Button } from '../Button';
import { ModalProps } from './types';

import styles from './styles.module.scss';

export const Modal: React.FC<ModalProps> = ({
  fields,
  type,
  style,
  title,
  isActive,
  content,
  isLoading,
  onCloseCallback,
  shouldCloseOnBackgroundClick,
  onPrimaryCallback,
  onSecondaryCallback
}) => {
  const applicationState = {
    [ApplicationStates.ERROR]: { icon: 'highlight_off', className: styles.iconError },
    [ApplicationStates.SUCCESS]: { icon: 'warning_amber', className: styles.iconWarning },
    [ApplicationStates.WARNING]: { icon: 'error_outline', className: styles.iconWarning },
    [ApplicationStates.DEFAULT]: { icon: 'announcement', className: styles.iconDefault },
    [ApplicationStates.INACTIVE]: { icon: 'pause_circle_outline', className: styles.iconInactive },
    [ApplicationStates.CANCEL]: { icon: 'error_outline', className: styles.iconCancel }
  };

  return (
    <CSSTransition
      in={isActive}
      classNames={styles.modal}
      timeout={450}
      unmountOnExit={true}
      mountOnEnter={true}
    >
      <FocusLock>
        <div className={styles.modal}>
          <div
            className={styles.modalBg}
            data-testid="modal-bg"
            role="presentation"
            onClick={(e) => {
              if (shouldCloseOnBackgroundClick) {
                onCloseCallback(e);
              }
            }}
          />
          <div
            className={`
              ${styles.modalCard} 
              ${styles[style]}
            `}
          >
            <div className={styles.modalHeader}>
              <div className={`${styles.modalHeaderIcon} ${applicationState[type].className}`}>
                <i className="material-icons icon--size-32 icon--v-align-middle">
                  {applicationState[type].icon}
                </i>
              </div>
              <div className={styles.modalHeaderTitle}>
                <h3>{title ?? ''}</h3>
              </div>
              <button
                className={styles.closeIconWrapper}
                onClick={onCloseCallback}
                data-testid={'modal-close-button'}
                type="button"
              >
                <span className={`material-icons align-self-center`}>close</span>
              </button>
            </div>
            <div className={styles.modalContent}>{content}</div>
            <div className={styles.modalFooterButtons}>
              <Button
                loading
                type="submit"
                ariaText={fields?.saveButton ?? {}}
                name={`SubmitButton`}
                text={fields?.saveButton ?? {}}
                onClick={onPrimaryCallback}
                icon={
                  isLoading
                    ? () => (
                        <span className={styles.saveIconWrapper}>
                          <span className={`material-icons align-self-center`}>loop</span>
                        </span>
                      )
                    : undefined
                }
              />
              <Button
                secondaryButton
                ariaText={fields?.cancelButton ?? {}}
                name={`CancelButton`}
                text={fields?.cancelButton ?? {}}
                type="button"
                onClick={onSecondaryCallback}
              />
            </div>
          </div>
        </div>
      </FocusLock>
    </CSSTransition>
  );
};
