import React, { FC, useState, useMemo, useCallback, memo, useEffect, SyntheticEvent } from 'react';
import {
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  SvgIconProps
} from '@mui/material';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import { Tooltip } from 'Components/Core/Tooltip';
import { TooltipPosition } from 'Components/Core/Tooltip/types';
import styles from './styles.module.scss';
import { isGroupedOption, NormalizedNonGroup, NormalizedOption, DropdownProps } from './types';

/**
 *
 * @param {DropdownProps} props - list out the props.
 * @param {FieldValue} props.ariaText - aria label for the dropdown Select
 * @param {string} props.className - class to override styles of the dropdown container
 * @param {boolean} props.error - indicator of any error on dropdown. There is an error when this value is true.
 * @param {string} props.errorMessage - contains the error message to be displayed below the dropdown container when error is true
 * @param {FieldValue} props.helpText - helper text to be displayed in the dropdown container below Select to display any additional text
 * @param {string} props.initialOption - contains the value of any option that needs to be set on the dropdown when it initially loads
 * @param {Label} props.label - contains label text and label info icon tool tip text; label will be displayed (above Select bar) when this input is provided
 * @param {GroupedOption[] | Option[]} props.options - options that need to be displayed in the dropdown menu list. It container the label, value and icon for each option.
 * @param {JSX.Element} props.placeholderIcon - any icon to be displayed in the Select bar by default if there is no option selected
 * @param {string} props.placeholderText - any text to be displayed in the Select bar when there is no option selected
 * @param {boolean} props.readOnly - specifies whether the Select bar is readonly
 * @returns {Dropdown} Rendered Select Dropdown component.
 */

/**
 * Dropdown component
 *
 * This is the Select Dropdown component written using storybook. It handles different states, variants.
 */

export const Dropdown: FC<DropdownProps> = memo(
  ({
    ariaText,
    className,
    error,
    errorMessage,
    helpText,
    initialOption,
    label,
    options,
    placeholderIcon,
    placeholderText,
    readOnly = false,
    onChange
  }: DropdownProps) => {
    const normalizedOptions = useMemo(() => {
      const normalized: NormalizedOption[] = [];

      options.forEach((option) => {
        if (isGroupedOption(option)) {
          normalized.push({ isGroup: true, label: option.groupName });
          if (option.options) {
            option.options.forEach((opt) => normalized.push({ ...opt, isGroup: false }));
          }
        } else {
          normalized.push({ ...option, isGroup: false });
        }
      });

      return normalized;
    }, [JSON.stringify(options)]);

    // Ensure selectedOption is always NormalizedNonGroup
    const initialSelectedOption = normalizedOptions.find(
      (option) => !option.isGroup && option?.value === initialOption
    ) as NormalizedNonGroup;

    const [openedByKeyboard, setOpenedByKeyboard] = useState(false);
    const [selectedOption, setSelectedOption] = useState<NormalizedNonGroup | null>(
      initialSelectedOption ?? null
    );
    const [isOpen, setIsOpen] = useState(false);
    const selectBarIcon = selectedOption?.icon ?? placeholderIcon;

    const handleChange = useCallback(
      (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value;
        const newSelection = normalizedOptions.find(
          (option) => !option.isGroup && option?.value === selectedValue
        ) as NormalizedNonGroup;
        setSelectedOption(newSelection ?? null);
        setIsOpen(false);

        if (onChange) {
          onChange(event, newSelection);
        }
      },
      [JSON.stringify(normalizedOptions), onChange]
    );

    // Workaround to remove autofocus on first option that always happens even when menu is opened through click; this is a workaround/fix for bug in material UI library (https://github.com/mui/material-ui/issues/23747).
    const handleMenuOpen = useCallback((event: SyntheticEvent | KeyboardEvent) => {
      if (
        (event as KeyboardEvent).key === 'ArrowDown' ||
        (event as KeyboardEvent).key === 'Enter'
      ) {
        setOpenedByKeyboard(true);
      } else {
        setIsOpen(true);
      }
    }, []);

    const handleMenuClose = useCallback(() => {
      setOpenedByKeyboard(false);
      setIsOpen(false);
    }, []);

    useEffect(() => {
      if (openedByKeyboard) {
        setIsOpen(true);
      }
    }, [openedByKeyboard]);

    const menuStyleProps = useMemo(
      () => ({
        classes: {
          paper: styles.menuListWrapper,
          list: styles.menuList
        },
        sx: {
          '& .Mui-selected': {
            background: 'var(--Accent-Slate-90W, #F0F2F3) !important'
          },
          '& .Mui-focusVisible::before': {
            content: '""',
            position: 'absolute !important',
            top: 'var(--Spacing-02, 4px) !important',
            right: 'var(--Spacing-02, 4px) !important',
            bottom: 'var(--Spacing-02, 4px) !important',
            left: 'var(--Spacing-02, 4px) !important',
            border: 'var(--Spacing-01, 2px) solid var(--Accent-Violet-20W, #6D65D7) !important',
            borderRadius: 'var(--Spacing-01, 2px)',
            pointerEvents: 'none !important',
            zIndex: '0 !important'
          }
        },
        autoFocus: openedByKeyboard
      }),
      [openedByKeyboard]
    );

    return (
      <FormControl
        data-testid="dropdown-wrapper"
        variant="standard"
        className={`${styles.container} ${className}`}
      >
        {label?.text && (
          <div className={styles.labelContainer}>
            <label className={styles.labelText}>
              <Text field={label.text} />
            </label>
            {label?.infoHelperText?.value && (
              <span className={styles.labelIcon}>
                <Tooltip
                  label={label.infoHelperText.value}
                  position={TooltipPosition.RIGHT}
                  iconSize={HbtIconSize.SMALL}
                />
              </span>
            )}
          </div>
        )}
        <Select
          data-testid="dropdown-root"
          value={selectedOption?.value ?? ''}
          error={error}
          readOnly={readOnly}
          onChange={handleChange}
          onOpen={handleMenuOpen}
          onClose={handleMenuClose}
          inputProps={{
            'aria-label': ariaText?.value
          }}
          classes={{
            root: styles.selectRoot,
            select: `${styles.selectInput} ${isOpen ? styles.selectInputOpen : ''}`,
            error: styles.selectInputError
          }}
          MenuProps={menuStyleProps}
          sx={{
            '& .MuiInputBase-input': {
              '&.Mui-readOnly, &.Mui-readOnly:hover': {
                border: 'none !important',
                background: 'var(--Accent-Slate-90W, #F0F2F3) !important',
                '[data-role="select-text"]': {
                  color: 'var(--text-links-text-body, #454E56) !important'
                }
              }
            },
            '&.Mui-readOnly .MuiSelect-icon': {
              display: 'none !important',
              visibility: 'hidden !important'
            }
          }}
          IconComponent={(props: SvgIconProps) => (
            <HbtIcon
              size={HbtIconSize.SMALL}
              type="icon_chevron_down"
              className={`${props.className} ${
                isOpen ? styles.chevronIconOpen : styles.chevronIcon
              }`}
            />
          )}
          renderValue={() => {
            return (
              <div className={styles.textContainer}>
                {selectBarIcon && <span className={styles.textContainerIcon}>{selectBarIcon}</span>}
                <div data-role="select-text" className={styles.textContainerText}>
                  {selectedOption?.label ?? (placeholderText || 'Choose an option')}
                </div>
              </div>
            );
          }}
          displayEmpty
          disableUnderline
        >
          {normalizedOptions.map((option, index) =>
            option.isGroup ? (
              <ListSubheader
                key={`group-${option.label}-${index}`}
                className={styles.menuListSubheader}
              >
                {option.label}
              </ListSubheader>
            ) : (
              <MenuItem
                key={option.value}
                value={option.value}
                className={styles.menuListItem}
                disableRipple
              >
                {option.icon && <span className={styles.menuListItemIcon}>{option.icon}</span>}
                <span className={styles.menuListItemText}>{option.label}</span>
              </MenuItem>
            )
          )}
        </Select>
        {helpText && (
          <span className={styles.helperText}>
            <Text field={helpText} />
          </span>
        )}
        {error && errorMessage && (
          <div className={styles.errorContainer}>
            <HbtIcon
              size={HbtIconSize.SMALL}
              type="icon_error_outlined"
              className={styles.errorInfoIcon}
            />
            <span className={styles.errorMessageText}>{errorMessage}</span>
          </div>
        )}
      </FormControl>
    );
  }
);
