import * as React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { SortIndicator } from 'Components/Common/DataTable/TableHeader/SortIndicator';
import { SortDirection } from 'Components/Common/Api/CommonEnums';
import { DataTableHeaderItemProps } from './types';
import styles from './styles.module.scss';

export const DataTableHeaderItem: React.FC<DataTableHeaderItemProps> = ({
  displayText,
  name,
  sortable,
  sortDirection,
  onSortCallback,
  colspan,
  customStyles
}: DataTableHeaderItemProps) => (
  <th
    className={`${styles.th} ${customStyles || ''}`}
    data-testid={`${name}TableHeaderCell`}
    colSpan={colspan}
  >
    {sortable ? (
      <button
        onClick={(e) => {
          e.preventDefault();
          if (onSortCallback) {
            onSortCallback(name);
          }
        }}
        data-testid={`${name}TableHeaderColumnSortButton`}
      >
        <SortIndicator direction={sortDirection || SortDirection.DEFAULT} />
        {displayText && (
          <span className={`${styles.thText}`}>
            <Text field={displayText} />
          </span>
        )}
      </button>
    ) : (
      <>
        {displayText && (
          <span className={styles.thText}>
            <Text field={displayText} />
          </span>
        )}
      </>
    )}
  </th>
);
