import React, { useCallback } from 'react';

enum KeyboardKeys {
  ENTER_KEY = 'Enter'
}

export const useCheckboxKeyDownHandler = () => {
  return useCallback((e: React.KeyboardEvent) => {
    if (e.key === KeyboardKeys.ENTER_KEY) {
      e.preventDefault();
    }
  }, []);
};
