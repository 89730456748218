import i18next from 'i18next';

export const getMonitoringStrategyProps = () => {
  const monitoringStrategyProps = {
    testId: 'monitoringStrategyTest',
    accordionLineColor: 'grey',
    linePosition: 'vertical',
    title: {
      field: {
        value: i18next.t('DefaultSubmission-Card-MonitoringStrategyHeading')
      }
    },
    textAreaHeading: {
      field: {
        value: i18next.t('DefaultSubmission-CardL-MonitoringStrategyTextAreaHeading')
      }
    },
    maxLength: Number(i18next.t('Globals-MultilineText-MaxLength'))
  };
  return monitoringStrategyProps;
};
