import * as React from 'react';
import { useState, useEffect } from 'react';
import { DefaultModalFormProps } from 'Feature/DefaultsInventory/components/DefaultModalForm/DefaultModalForm.type';
import i18n from 'i18next';
import FormInputMultiLineText from 'Feature/DefaultSubmissionForm/components/FormInputMultiLineText';
import './DefaultModalForm.scss';
import FormInputDropdownText from 'Feature/DefaultSubmissionForm/components/FormInputDropdownText';
import { useForm } from 'react-hook-form';

export const DefaultModalForm = (props: DefaultModalFormProps) => {
  const { register, handleSubmit, watch } = useForm();

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

  const formFields = watch();
  const commentsMinLength: number = parseInt(
    i18n.t('DefaultsInventoryDetails-Modal-MultiLineText-MinChar')
  );

  useEffect(() => {
    const reasonEmpty: boolean =
      formFields.reason === undefined || formFields.reason === '' || formFields.reason === '0';

    const commentEmptyOrTooShort: boolean =
      formFields.comment === undefined ||
      formFields.comment.toString() === '' ||
      formFields.comment.toString().length < commentsMinLength;

    const reasonCheckedAndEmpty: boolean =
      (props.disableSubmitOnEmptySelect || false) && reasonEmpty;
    const commentCheckedAndInvalid: boolean =
      (props.disableSubmitOnEmptyMultilineText || false) && commentEmptyOrTooShort;
    setSubmitDisabled(reasonCheckedAndEmpty || commentCheckedAndInvalid);
  }, [formFields]);

  const reasonsCharacterLimitText: string = i18n
    .t('DefaultSubmission-Card-ReasonsCharacterLimitText')
    .replace('{textLimit}', props.multiTextProps!.maxLength.toString());

  return (
    <form onSubmit={handleSubmit(props.submitCallback)} noValidate>
      <div className="default-modal-form form">
        {props.dropdownProps && (
          <div className="form__element">
            <FormInputDropdownText
              id="reason"
              name="reason"
              title={props.dropdownProps.title}
              options={props.dropdownProps.options}
              register={register}
              errors={props.dropdownProps.errors}
              setValueHandler={props.dropdownProps.setValueHandler}
              hideError={true}
            />
          </div>
        )}

        {props.multiTextProps && (
          <div className="form__element">
            <FormInputMultiLineText
              id={props.multiTextProps.id}
              name="comment"
              title={{ value: props.multiTextProps!.title?.value }}
              textAreaLimit={{
                value: reasonsCharacterLimitText
              }}
              maxLength={props.multiTextProps!.maxLength}
              errors={props.multiTextProps!.errors}
              register={register}
              limitCountText={props.multiTextProps!.limitCountText}
              hideError={true}
            />
          </div>
        )}

        <div className="default-modal-form__submit-button">
          <input
            name={props.submitButtonText}
            type="submit"
            value={props.submitButtonText}
            title={props.submitButtonText}
            disabled={submitDisabled}
            className="btn btn-primary"
          />
        </div>
      </div>
    </form>
  );
};
