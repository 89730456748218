import React, { createContext, useContext, ReactNode } from 'react';
import { ModifyUserContextType } from './types';

const ModifyExternalUserContext = createContext<ModifyUserContextType>({
  userData: []
});
export const useModifyExternalUserContext = () =>
  useContext<ModifyUserContextType>(ModifyExternalUserContext);

const ModifyExternalUserContextProvider = (props: {
  children?: ReactNode;
  value?: ModifyUserContextType;
}) => {
  return (
    <ModifyExternalUserContext.Provider
      value={{
        userData: props.value?.userData
      }}
    >
      {props.children}
    </ModifyExternalUserContext.Provider>
  );
};

export default ModifyExternalUserContextProvider;
