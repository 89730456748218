import { useFeature } from 'flagged';
import i18n from 'i18next';
import React, { useState, useEffect, useRef } from 'react';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { UserRole, Module } from '@hobt/constants';

import {
  deleteCardRepopulateValues,
  prepopulateCalculations,
  scrollIntoView
} from 'Feature/DefaultSubmissionForm/components/CardReuseableFunctions';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { LinedCard, AccordionContainer } from 'Feature/CommonComponents/AccordionComponents';
import { TooltipIcon } from 'Components/Inputs/TooltipIcon';
import { OwnerType } from 'Feature/DefaultSubmissionForm/models/typeCode.types';
import FormInputCurrency from 'Feature/DefaultSubmissionForm/components/FormInputCurrency';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useDefaultFormActionsContext } from 'Feature/DefaultsInventory/components/DefaultFormContext';

import { AssetsProps } from './Assets.types';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const AssetsContainer: React.FC<AssetsProps> = ({
  index,
  register,
  errors,
  setValueHandler,
  control,
  watch,
  getValues,
  ...props
}) => {
  const commonCardProps = {
    register,
    errors,
    setValueHandler,
    control,
    watch,
    getValues
  };

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);
  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;
  const cardInstance = (watch && watch(`asset[${index}]`)) || {};
  const assetsPrimaryResidence = cardInstance.primaryResidence || 0;
  const assetsRentalProperty = cardInstance.rentalProperty || 0;
  const assetsVehicle = cardInstance.vehicle || 0;
  const cashAndCashEquivalents = cardInstance.cashEquivalents || 0;
  const assetsBankAccount = cardInstance.bankAccount || 0;
  const assetsSavingsAccount = cardInstance.savingAccount || 0;
  const assetsRRSP = cardInstance.rrspRespRrif || 0;
  const assetsOther = cardInstance.other || 0;

  const defaultFormActions = useDefaultFormActionsContext();

  useEffect(() => {
    // update calculations after input change
    const total =
      assetsPrimaryResidence +
      assetsRentalProperty +
      assetsVehicle +
      cashAndCashEquivalents +
      assetsBankAccount +
      assetsSavingsAccount +
      assetsRRSP +
      assetsOther;
    const totalAssetsArray = [...props.totalAssetsArray];
    if (typeof index === 'number') {
      totalAssetsArray[index] = total;
      props.calculateAssets && props.calculateAssets(totalAssetsArray);
    }
  }, [
    assetsPrimaryResidence,
    assetsRentalProperty,
    assetsVehicle,
    cashAndCashEquivalents,
    assetsBankAccount,
    assetsSavingsAccount,
    assetsRRSP,
    assetsOther
  ]);

  useEffect(() => {
    if (defaultFormActions.isCardComplete('assets')) {
      if (
        !(
          !!assetsPrimaryResidence &&
          !!assetsVehicle &&
          !!assetsBankAccount &&
          !!assetsSavingsAccount &&
          !!assetsRRSP
        )
      ) {
        defaultFormActions?.removeCompleteCard('assets');
      }
    } else if (
      !!assetsPrimaryResidence &&
      !!assetsVehicle &&
      !!assetsBankAccount &&
      !!assetsSavingsAccount &&
      !!assetsRRSP
    ) {
      defaultFormActions?.addCompleteCard('assets');
    }
  }, [assetsPrimaryResidence, assetsVehicle, assetsBankAccount, assetsSavingsAccount, assetsRRSP]);

  return (
    <div className="card_body-section assets-section-1" id={`${props.cardId}${index || 0}`}>
      {index === 0 ? (
        <>
          <h3 className="card__body-heading">{props?.primaryAssetSectionTitle?.field?.value}</h3>
          <input
            type="hidden"
            value={OwnerType.BorrowerCoborrower}
            {...register?.(`asset[${index}].owner`)}
            disabled={isReadOnlyUser}
          />
        </>
      ) : (
        <>
          <h3 className="card__body-heading">{props?.guarantorAssetsSection?.field?.value}</h3>
          <input
            type="hidden"
            value={OwnerType.Guarantor}
            {...register?.(`asset[${index}].owner`)}
            disabled={isReadOnlyUser}
          />
        </>
      )}

      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.cardId}PrimaryResidence${index}`}
            name={`asset[${index}].primaryResidence`}
            title={props.inputFields.primaryResidence.field}
            cardName={props?.title?.field?.value}
            isRequired={true}
            autoFocus={props.isAutoFocus}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.cardId}RentalProperty${index}`}
            name={`asset[${index}].rentalProperty`}
            title={props.inputFields.rentalProperty.field}
            cardName={props.title.field.value}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.cardId}Vehicle${index}`}
            name={`asset[${index}].vehicle`}
            title={props.inputFields.vehicle.field}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.cardId}CashEquivalents${index}`}
            name={`asset[${index}].cashEquivalents`}
            title={props.inputFields.cashCashEquivalents.field}
            cardName={props.title.field.value}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>

      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.cardId}BankAccount${index}`}
            name={`asset[${index}].bankAccount`}
            title={props.inputFields.bankAccount.field}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.cardId}SavingsAccount${index}`}
            name={`asset[${index}].savingAccount`}
            title={props.inputFields.savingsAccount.field}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      <div className="row card__body-row">
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.cardId}RrspResp${index}`}
            name={`asset[${index}].rrspRespRrif`}
            title={props.inputFields.rrsp.field}
            cardName={props.title.field.value}
            isRequired={true}
            isDisabled={isReadOnlyUser}
          />
        </div>
        <div className="form__element form__element--2-column col-6">
          <FormInputCurrency
            {...commonCardProps}
            id={`${props.cardId}Other${index}`}
            name={`asset[${index}].other`}
            title={props.inputFields.other.field}
            cardName={props.title.field.value}
            isDisabled={isReadOnlyUser}
          />
        </div>
      </div>
      {index && index > 0 ? (
        <div className="row card__body-row mt-3">
          <div className="col-12 card__actionButtons">
            <button
              className="ml-4 btn btn--icon btn__delete"
              type="button"
              onClick={function () {
                props.removeCard && props.removeCard(index);
              }}
              disabled={isReadOnlyUser || props.disableDelete || false}
            >
              <span>
                <TooltipIcon
                  icon={props.deleteIcon}
                  text={i18n.t('DefaultSubmission-Card-Delete')}
                  className={'icon-24'}
                />
              </span>
            </button>
          </div>
        </div>
      ) : null}
      <hr className="card__divider--thick" />
    </div>
  );
};

export const Assets: React.FC<AssetsProps> = (props) => {
  const initialIdsArray = [];
  const cardCount = props.cardCount || 1;
  for (let i = 0; i < cardCount; i++) {
    initialIdsArray.push(i);
  }
  const [idsArray, setIdsArray] = useState<number[]>(initialIdsArray);
  const [id, setId] = useState<number>(cardCount - 1);
  const [isAutoFocus, setAutoFocus] = useState<boolean>(false);
  const [lastIndexPreDelete, setLastIndexPreDelete] = useState<number>(cardCount - 1);
  const [disableDelete, setDisableDelete] = useState(false);
  const isInitialMount = useRef(true);

  // Readonly
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;

  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);
  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  useEffect(() => {
    // set props.totalAssetsArray on mount, for calculation based on pre-populated data
    const allCardValues = (props.watch && props.watch(`asset`)) || {};
    const totalAssetsArrayCopy = prepopulateCalculations(allCardValues);
    calculateAssets(totalAssetsArrayCopy);
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setIdsArray(idsArray.concat(id));
    }
  }, [id]);

  useEffect(() => {
    // update last index after adding/deleting a card
    setLastIndexPreDelete(idsArray.length - 1);
    setDisableDelete(false); // re-enable delete after delete operation is completed
  }, [JSON.stringify(idsArray)]);

  function addGuarantorAssets() {
    setId(id + 1);
    setAutoFocus(true);
  }

  function removeCard(index: number): void {
    // manually setting own isDirty check for card deletions on details form
    props.setCardDeleted && props.setCardDeleted(true);

    if (props.getValues && props.setValueHandler && props.watch && props.reset) {
      // prepopulate cards after delete using react-hook-form functions
      deleteCardRepopulateValues(
        props.getValues,
        props.setValueHandler,
        props.watch,
        props.reset,
        index,
        lastIndexPreDelete,
        `asset`,
        props.register
      );
    }

    // Update cards' id array
    const numbersArrayCopy = idsArray;
    numbersArrayCopy.splice(index, 1);
    setIdsArray(numbersArrayCopy);

    // Update calculated fields after deleting a card
    const totalAssetsArray = [...props.totalAssetsArray];
    totalAssetsArray.splice(index, 1); // remove value from assetsArray based on index of removed card
    calculateAssets(totalAssetsArray);

    // Scroll into view after delete
    scrollIntoView(props.cardId + (lastIndexPreDelete - 1));

    // Disable multiclick
    setDisableDelete(true);
  }

  function calculateAssets(totalAssetsArray: number[]): void {
    // set totalAssetsArray, and calculate cumulativeTotalAssets
    let cumulativeAssets = 0;
    totalAssetsArray.map((assetInstance) => {
      cumulativeAssets += assetInstance;
    });
    cumulativeAssets = Math.round(cumulativeAssets * 1e2) / 1e2; // Round to two decimals
    props.setCumulativeTotalAssets && props.setCumulativeTotalAssets(cumulativeAssets);
    props.setTotalAssetsArray(totalAssetsArray);
  }

  return (
    <LinedCard
      id={props.cardId}
      testId={props.testId}
      linePosition={props.linePosition}
      lineColor={props.accordionLineColor}
    >
      <AccordionContainer accordionId={`${props.cardId}Accordion`} title={props.title.field}>
        {idsArray.map((id, index) => (
          <AssetsContainer
            {...props}
            key={id}
            index={index}
            lastIndexPreDelete={lastIndexPreDelete}
            removeCard={removeCard}
            calculateAssets={calculateAssets}
            disableDelete={disableDelete}
            isAutoFocus={isAutoFocus}
          />
        ))}
        <div className="row card__body-row">
          <div className="form__element form__element--2-column col-6">
            <FormInputCurrency
              id={`${props.cardId}TotalAssets`}
              title={props.inputFields.totalAssets.field}
              isDisabled={isReadOnlyUser || true}
              value={props.cumulativeTotalAssets}
            />
          </div>
        </div>
        <hr className="card__divider--thick" />
        <div className="row card__body-row">
          <div className="col-12">
            <button
              className="btn btn__add"
              onClick={addGuarantorAssets}
              type="button"
              disabled={isReadOnlyUser}
            >
              <i
                className="material-icons icon--size-32 icon--v-align-middle"
                title={props.AddGuarantorText.field.value}
                aria-hidden={true}
              >
                add_circle_outline
              </i>
              <Text field={props.AddGuarantorText.field} />
            </button>
          </div>
        </div>
      </AccordionContainer>
    </LinedCard>
  );
};
