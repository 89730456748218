import { useState } from 'react';
import * as React from 'react';
import i18n from 'i18next';
import { withFeature } from 'flagged';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { ModuleMapping, UserRole } from '@hobt/constants';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import formTextStyles from 'Components/Inputs/FormText/styles.module.scss';
import styles from 'Feature/DefaultsInventory/components/DefaultInventoryDetails/DetailsCardHeader/styles.module.scss';
import { DefaultRequestStatus } from 'Foundation/Api';

import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { CardHeader } from 'Feature/CommonComponents/ContentComponents';
import { ButtonType } from 'Feature/CommonComponents/UserControls';
import {
  DECISION_RATIONALE_ENABLED_STATUSES,
  DefaultDetailsInternalHeaderProps,
  SitecoreItem
} from './DefaultDetailsInternalHeader.type';

const DefaultDetailsExternalCardHeader: React.FC<DefaultDetailsInternalHeaderProps> = ({
  detailsData,
  emailSubjectLine,
  cancelOptions,
  declineOptions
}) => {
  const [openSidedrawer, setSidedrawerOpen] = useState<boolean>(false);

  const enableDecisionRationale = DECISION_RATIONALE_ENABLED_STATUSES.includes(
    detailsData?.other?.defaultStatus
  );

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext: any = sitecoreContextFactory?.sitecoreContext;

  const sitecoreVariables = sitecoreContext?.route?.placeholders['hbt-main']?.find(
    (item: SitecoreItem) => {
      return item.componentName === 'DefaultInventoryDetails';
    }
  );

  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const { refusalReason } = sitecoreVariables.fields;
  const decisionRationaleTitle = sitecoreVariables.fields.decisionRationale.value;
  const { commentsTitle } = sitecoreVariables.fields;
  const viewDecisionRationale = sitecoreVariables.fields.viewDecisionRationale.value;

  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.default,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  const handleActionButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isServer() === false && e.currentTarget.name === 'emailCmhc') {
      e.preventDefault();
      const subjectLine = `${emailSubjectLine} ${detailsData.detail.cmhcLoanAccountNumber}`;
      window.location.href = `mailto:${i18n.t(
        'DefaultsInventoryDetails-Button-EmailCmhcAddress'
      )}?subject=${encodeURI(subjectLine)}`;
    }
  };

  return (
    <div className="dataVis">
      <CardHeader
        preTitle={`${i18n.t('DefaultsInventoryDetails-Title-LenderReferenceText')}: ${
          detailsData && detailsData.lender ? detailsData.lender.approvedLenderReferenceNumber : ''
        }`}
        title={`${i18n.t('DefaultsInventoryDetails-Title-CmhcAccountNumberText')}: ${
          detailsData ? detailsData.detail.cmhcLoanAccountNumber : ''
        }`}
        tagText={
          detailsData ? i18n.t(`Globals-ApplicationStatus-${detailsData.other.defaultStatus}`) : ''
        }
        actionButtons={[
          {
            id: 'buttonEmailCmhc',
            name: 'emailCmhc',
            buttonType: ButtonType.PRIMARY,
            displayText: i18n.t('DefaultsInventoryDetails-Button-EmailCmhcText') as string,
            disabled: false,
            onClick: (e) => {
              handleActionButtonClick(e);
            }
          },
          {
            dropdownButton: {
              id: 'buttonMoreActions',
              name: 'moreActions',
              buttonType: ButtonType.TEXT,
              isIcon: true,
              icon: 'more_vert',
              iconSize: 40,
              onClick: () => {}
            },
            dropdownItems: enableDecisionRationale
              ? [
                  {
                    id: 'buttonViewRationale',
                    buttonType: ButtonType.PRIMARY,
                    displayText: viewDecisionRationale,
                    name: 'viewRationale',
                    disabled: isReadOnlyUser || !enableDecisionRationale,
                    onClick: () => {
                      setSidedrawerOpen(true);
                    }
                  }
                ]
              : [],
            otherDropdownProps: { style: { minWidth: '100px' } }
          }
        ]}
      />

      {(!isReadOnlyUser || enableDecisionRationale) && (
        <SideDrawer
          isActive={openSidedrawer}
          handleOutsideClick={() => {
            setSidedrawerOpen(false);
          }}
        >
          <div className={styles.scrollable}>
            <div className={styles.header}>
              <h2 className={styles.title}>{decisionRationaleTitle}</h2>
              <button
                className={styles.closebtn}
                onClick={() => {
                  setSidedrawerOpen(false);
                }}
              />
            </div>
            <div className={styles.content}>
              {(detailsData?.other?.defaultStatus === DefaultRequestStatus.Cancelled ||
                detailsData?.other?.defaultStatus === DefaultRequestStatus.Declined) && (
                <Text tag="h3" field={refusalReason} />
              )}
              <input
                type="text"
                className={`${formTextStyles['form-text']} ${styles.full}`}
                value={
                  cancelOptions?.find(
                    (item) => Number(item.value) === detailsData?.other?.defaultStatusReason
                  )?.label ||
                  declineOptions?.find(
                    (item) => Number(item.value) === detailsData?.other?.defaultStatusReason
                  )?.label ||
                  ''
                }
                readOnly={true}
              />

              <Text tag="h3" field={commentsTitle} />

              <input
                type="text"
                className={`${formTextStyles['form-text']} ${styles.full}`}
                value={detailsData?.other?.defaultStatusComment ?? ''}
                readOnly={true}
              />
            </div>
          </div>
        </SideDrawer>
      )}
    </div>
  );
};

export default withFeature(FeatureFlags.EXTERNAL)(DefaultDetailsExternalCardHeader);
