import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { withFeature } from 'flagged';
import { Prompt } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { defaultsPortal } from '@hobt/form-schema-validators';
import {
  DefaultManagementTools,
  getDefaultManagementToolsProps
} from 'Feature/DefaultSubmissionForm/components/Cards/DefaultManagementTools';
import { TargetSection } from 'Feature/DefaultSubmissionForm/models/typeCode.types';
import ContentBlockInCard from 'Feature/PageComponents/components/ContentBlockInCard';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import {
  DefaultDetailSubmissionFormProps,
  SitecoreItem
} from './DefaultDetailSubmissionForm.types';

import styles from './styles.module.scss';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const DefaultDetailSubmissionFormWorkoutPlanExternal: React.FC<
  DefaultDetailSubmissionFormProps
> = ({ detailsData, defaultSubmissionFormProps }) => {
  const [isSidedrawerActive, setSidedrawerActive] = useState<boolean>(false);

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext: any = sitecoreContextFactory?.sitecoreContext;

  const drawerOpenLabel = sitecoreContext?.route?.placeholders['hbt-main']?.find(
    (item: SitecoreItem) => {
      return item.componentName === 'DefaultInventoryDetails';
    }
  ).fields.drawerOpenLabel.value;

  const {
    register,
    formState: { errors },
    setValue,
    control,
    watch,
    reset,
    formState
  } = useForm({
    resolver: joiResolver(defaultsPortal),
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  const mgmtInfo = detailsData.managementInformation;

  const resetData = () => {
    reset(detailsData);
    register('managementInformation.description');
    setValue('managementInformation.description', mgmtInfo.description);

    register('managementInformation.causeOfDefault');
    setValue('managementInformation.causeOfDefault', mgmtInfo.causeOfDefault);

    register('managementInformation.dateHardshipBegan');
    setValue('managementInformation.dateHardshipBegan', mgmtInfo.dateHardshipBegan);

    register('managementInformation.monitoringStrategy');
    setValue('managementInformation.monitoringStrategy', mgmtInfo.monitoringStrategy);

    register('managementInformation.submittedDefaultStage');
    setValue('managementInformation.submittedDefaultStage', mgmtInfo.submittedDefaultStage);

    register('managementInformation.lenderAcknowledgementSignatureTitle');
    setValue(
      'managementInformation.lenderAcknowledgementSignatureTitle',
      mgmtInfo.lenderAcknowledgementSignatureTitle
    );

    register('managementInformation.lenderAcknowledgementSignatureFullName');
    setValue(
      'managementInformation.lenderAcknowledgementSignatureFullName',
      mgmtInfo.lenderAcknowledgementSignatureFullName
    );

    register('managementInformation.lenderAcknowledgementAdditionalDetails');
    setValue(
      'managementInformation.lenderAcknowledgementAdditionalDetails',
      mgmtInfo.lenderAcknowledgementAdditionalDetails
    );

    register('managementInformation.preApprovalRequired');
    setValue('managementInformation.preApprovalRequired', mgmtInfo.preApprovalRequired);
  };

  useEffect(() => {
    resetData();
  }, [mgmtInfo]);

  const prepopToolsLength = () => {
    const stageThreeDataObj = mgmtInfo.submittedStageThree;
    return (
      stageThreeDataObj.combinationFlag &&
      stageThreeDataObj.toolDetail &&
      stageThreeDataObj.toolDetail.length
    );
  };
  const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
  const proposedStageThreeLength = () => {
    if (mgmtInfo.proposedStageThree !== undefined) {
      const stageThree = mgmtInfo.proposedStageThree;
      return stageThree.combinationFlag && stageThree.toolDetail.length - 1;
    }
    return 0;
  };

  return (
    <>
      {/* React Router prompt on route navigations */}
      <Prompt when={isDirtyAlt} message={i18n.t('DefaultSubmission-IsDirty')} />
      <form
        id="defaultInventoryDetailsFormWorkoutPlan"
        name="default-inventory-details-form"
        className="form default-submission-form"
        noValidate
      >
        <div>
          <div className={styles.headerText}>
            <ContentBlockInCard
              fields={{
                heading: {
                  field: {
                    value: i18n.t('DefaultDetailSubmissionFormWorkoutPlanHeader')
                  }
                },
                content: {
                  field: {
                    value: i18n.t('DefaultDetailSubmissionFormWorkoutPlanSubText')
                  }
                }
              }}
            />
          </div>
          <div className={`${styles.openSideDrawer} ${styles.hideForDesktop}`}>
            <button
              id="openSidedrawerButton"
              className={styles.openSideDrawerButton}
              onClick={(e) => {
                e.preventDefault();
                setSidedrawerActive(true);
              }}
            >
              <label htmlFor="openSidedrawerButton"> {drawerOpenLabel} </label>
            </button>
          </div>
          <div className={styles.workoutBodyLayout}>
            <div>
              <div
                className={`${styles.sideDrawer} ${
                  isSidedrawerActive ? '' : styles.hideSideDrawer
                }`}
              >
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    setSidedrawerActive(false);
                  }}
                ></div>
                <div>
                  <div
                    className={`${styles.hideForDesktop} ${styles.closeWrapper}`}
                    onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                      if (e.key === 'Enter') {
                        setSidedrawerActive(false);
                      }
                    }}
                  >
                    <span
                      className={styles.closebtn}
                      tabIndex={0}
                      aria-label={i18n.t('Accessibility-Close-Button')}
                      onClick={() => {
                        setSidedrawerActive(false);
                      }}
                      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                        if (e.key === 'Enter') {
                          setSidedrawerActive(false);
                        }
                      }}
                      role="button"
                    />
                  </div>
                  <fieldset disabled={true}>
                    <DefaultManagementTools
                      {...getDefaultManagementToolsProps(defaultSubmissionFormProps)}
                      id="defaultManagementToolsWorkoutPlanTabSection1"
                      title={{
                        field: {
                          value: i18n.t(
                            'DefaultSubmission-Card-DefaultManagementToolsSubmittedStageHeading'
                          )
                        }
                      }}
                      targetSection={TargetSection.DEFAULTSUBMISSION}
                      errors={errors}
                      register={register}
                      setValueHandler={setValue}
                      watch={watch}
                      control={control}
                      cardCount={prepopToolsLength()}
                      linedCardProps={{
                        id: 'defaultManagementToolsWorkoutPlanTabSection1_2',
                        testId: 'defaultManagementToolsTest1'
                      }}
                      disableCalendar={true}
                      expandRange={true}
                    />
                  </fieldset>
                </div>
              </div>
            </div>
            <div className={styles.cardNoMargin}>
              <fieldset disabled={true}>
                <DefaultManagementTools
                  {...getDefaultManagementToolsProps(defaultSubmissionFormProps)}
                  id="defaultManagementToolsWorkoutPlanTabSection2"
                  title={{
                    field: {
                      value: i18n.t(
                        'DefaultSubmission-Card-DefaultManagementToolsProposedStageHeading'
                      )
                    }
                  }}
                  targetSection={TargetSection.WORKOUTPLAN}
                  errors={errors}
                  register={register}
                  setValueHandler={setValue}
                  watch={watch}
                  control={control}
                  cardCount={proposedStageThreeLength()}
                  linedCardProps={{
                    id: 'defaultManagementToolsWorkoutPlanTabSection2_2',
                    testId: 'defaultManagementToolsTest2'
                  }}
                  disableCalendar={true}
                  expandRange={true}
                />
              </fieldset>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default withFeature(FeatureFlags.EXTERNAL)(DefaultDetailSubmissionFormWorkoutPlanExternal);
