import { NumberFormatProps } from 'react-number-format';

import { LanguageShort } from '@hobt/constants';

// Formattings
export const currencyFormat = {
  decimalScale: 2,
  allowLeadingZeros: false
};

export const getCurrencyFormat = (
  value: string | number,
  format: NumberFormatProps,
  language: LanguageShort | null
) => {
  const currFormat = { ...format };
  const isEmpty = value == null || value.toString().length === 0;

  if (language === LanguageShort.French) {
    return {
      ...currFormat,
      decimalSeparator: ',',
      thousandSeparator: ' ',
      suffix: isEmpty === true ? '$' : ' $',
      ...(isEmpty === true || value === 0
        ? { fixedDecimalScale: false }
        : { fixedDecimalScale: true })
    };
  }

  return {
    ...currFormat,
    thousandSeparator: ',',
    prefix: '$',
    ...(isEmpty === true || value === 0
      ? { fixedDecimalScale: false }
      : { fixedDecimalScale: true })
  };
};

export const telephoneFormat = {
  type: 'tel',
  format: '### ### ####',
  mask: '',
  isNumericString: false,
  allowEmptyFormatting: false
};

export const percentageFormat = {
  suffix: '%',
  decimalScale: 4,
  allowLeadingZeros: false
};

export const integerFormat = {
  decimalScale: 0,
  allowLeadingZeros: false
};

// text
export const postalCodeFormat = {
  delimiters: [' '],
  blocks: [3, 3]
};
