import React, { memo, useCallback } from 'react';
import { DataTableHeaderProps, SortIconPosition } from 'Components/Core/DataTable/types';
import { HbtIcon } from 'Components/Core/HbtIcon';
import { HbtIconSize } from 'Components/Core/HbtIcon/types';
import styles from './styles.module.scss';

type HeaderSortIconProps = Pick<DataTableHeaderProps, 'sortable' | 'isSorting' | 'sortAscending'>;

const SortIcon: React.FC<HeaderSortIconProps> = memo(
  ({ sortable = true, isSorting = false, sortAscending = true }) => {
    return (
      <>
        {sortable &&
          (isSorting ? (
            sortAscending ? (
              <HbtIcon size={HbtIconSize.SMALL} type="icon_sort_up" />
            ) : (
              <HbtIcon size={HbtIconSize.SMALL} type="icon_sort_down" />
            )
          ) : (
            <HbtIcon size={HbtIconSize.SMALL} type="icon_sort_default" />
          ))}
      </>
    );
  }
);

const DataTableHeader: React.FC<DataTableHeaderProps> = ({
  label,
  alignment,
  sortable = false,
  isSorting = false,
  sortAscending = false,
  leadingIcon,
  trailingIcon,
  sortIconPosition = SortIconPosition.Trailing,
  onSort
}) => {
  const handleOnSort = useCallback(() => {
    if (sortable) {
      onSort?.();
    }
  }, [sortable, onSort]);

  const alignmentStyle = styles[`align${alignment}` as keyof typeof styles];
  const thContentStyles = `${alignmentStyle} ${styles.thContent}`;

  return (
    <div className={thContentStyles} onClick={handleOnSort}>
      {SortIconPosition.Leading === sortIconPosition && (
        <SortIcon sortable={sortable} isSorting={isSorting} sortAscending={sortAscending} />
      )}
      {leadingIcon}
      {label}
      {trailingIcon}
      {SortIconPosition.Trailing === sortIconPosition && (
        <SortIcon sortable={sortable} isSorting={isSorting} sortAscending={sortAscending} />
      )}
    </div>
  );
};

export default memo(DataTableHeader);
